import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';

interface IProps {
  modalFlag: string;
}
const MemberListModal: React.FC<IProps> = ({ modalFlag }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const userAction = useUserActions(dispatch);
  const { isMemberListSaved, singleMemberList, isMemberListUpdated } = useSelector(
    (state: RootState) => {
      return {
        isMemberListSaved: state.users.isMemberListSaved,
        singleMemberList: state.users.singleMemberList,
        isMemberListUpdated: state.users.isMemberListUpdated
      };
    }
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = (data: any) => {
    if (modalFlag === 'edit') {
      userAction.updateMemberListRequest({ ...data, id: singleMemberList.id });
    } else {
      userAction.saveMemberListRequest(data);
    }
  };

  useEffect(() => {
    if (isMemberListSaved || isMemberListUpdated) {
      if (ref.current) {
        ref.current.click();
      }
      handleCloseClick();
    }
  }, [isMemberListSaved, isMemberListUpdated]);

  useEffect(() => {
    if (singleMemberList) {
      reset({ memberListName: singleMemberList.name });
    }
  }, [singleMemberList]);

  const handleCloseClick = () => {
    reset({
      memberListName: ''
    });
  };
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="memberListModal"
      aria-labelledby="memberListModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="memberListModalLabel">
              {modalFlag === 'edit' ? 'Update' : 'Add'} Member List
            </h5>
            <button
              ref={ref}
              onClick={handleCloseClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`form-group w-full`}>
                <label className="block mb-1"> Name </label>
                <input
                  type="text"
                  className="input-style"
                  id="memberListName"
                  {...register('memberListName', {
                    required: true
                  })}
                />
                {errors.memberListName?.type === 'required' && (
                  <span className="text-red-500 text-xs flex item-center error">Required</span>
                )}
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                  Submit
                </button>
                <button
                  onClick={handleCloseClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberListModal;
