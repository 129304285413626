import { all, put, takeEvery } from 'redux-saga/effects';
import { VideoLibraryActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncgetSpotLightRow(data: VideoLibraryActions.getSpotLightRowRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_SPOTLIGHTROW.url, data.payload);
        if (response.status === 200 && response.data.message === 'Videos found.') {
            yield put(VideoLibraryActions.getSpotLightRowRequestSuccess(response.data.result));
        } else {
            yield put(VideoLibraryActions.getSpotLightRowRequestSuccess([]));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getSpotLightRowRequestFailure());
    }
}
function* asyncSaveHighlightReel(data: VideoLibraryActions.saveHighlightReelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data);
        if (response.status === 200) {
            yield put(VideoLibraryActions.saveHighlightReelRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.saveHighlightReelRequestFailure());
    }
}

function* asyncSaveCustomizeThemeHeader(data: VideoLibraryActions.saveCustomizeThemeHeaderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data);
        if (response.status === 200) {
            yield put(VideoLibraryActions.saveCustomizeThemeHeaderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.saveCustomizeThemeHeaderRequestFailure());
    }
}

function* asyncSaveSpotlightRowsRecommendation(data: VideoLibraryActions.saveSpotlightRowsRecommendationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data);
        if (response.status === 200) {
            yield put(VideoLibraryActions.saveSpotlightRowsRecommendationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.saveSpotlightRowsRecommendationRequestFailure());
    }
}
function* asynceGetTotalRecords(data: VideoLibraryActions.getTotalRecordsRequestAction) {
    const HardCodedData = [
        {
            id: 1,
            channel: "Evolve: Renovated 2020",
            videoTitle: "18 mins movement session	"
        },
        {
            id: 2,
            channel: "Evolve: Renovated 2021",
            videoTitle: "21 mins movement session	"
        },
        {
            id: 3,
            channel: "Evolve: Renovated 2022",
            videoTitle: "22 mins movement session	"
        },
    ]
    try {
        // const response: AxiosResponse = yield ApiService.postData(``, data);
        // if (response.status === 200) {
        yield put(VideoLibraryActions.getTotalRecordsRequestSuccess(HardCodedData));
        // }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getTotalRecordsRequestFailure());
    }
}
function* asyncGetAuthentication(data: VideoLibraryActions.getAuthenticationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(VideoLibraryActions.getAuthenticationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getAuthenticationRequestFailure());
    }
}
function* asyncSaveAuthentication(data: VideoLibraryActions.saveAuthenticationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.saveAuthenticationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.saveAuthenticationRequestFailure());
    }
}


function* asyncGetRegistrationOption(data: VideoLibraryActions.getRegistrationOptionsRequestAction) {
    try {
        const HardCodedData = [
            {
                id: '1',
                name: 'text',
                defaultValue: true,
                enabled: true
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(VideoLibraryActions.getRegistrationOptionsRequestSuccess(response.data.result));
        // }
        yield put(VideoLibraryActions.getRegistrationOptionsRequestSuccess(HardCodedData));
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getRegistrationOptionsRequestFailure());
    }
}
function* asyncAddRegistrationOption(data: VideoLibraryActions.addRegistrationOptionsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.addRegistrationOptionsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.addRegistrationOptionsRequestFailure());
    }
}
function* asyncDeleteRegistrationOption(data: VideoLibraryActions.deleteRegistrationOptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData('');
        if (response.status === 200) {
            yield put(VideoLibraryActions.deleteRegistrationOptionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.deleteRegistrationOptionRequestFailure());
    }
}
function* asyncGetStandardFields() {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(VideoLibraryActions.getStandardFieldsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getStandardFieldsRequestFailure());
    }
}
function* asyncSaveStandardFields(data: VideoLibraryActions.saveStandardFieldsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.saveStandardFieldsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.saveStandardFieldsRequestFailure());
    }
}
function* asyncGetCustomFields() {
    try {
        const HardCodedData = [{
            id: '1',
            name: 'Organization',
            type: 'Textbox',
            required: false
        }]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(VideoLibraryActions.getCustomFieldsRequestSuccess(response.data.result));
        // }
        yield put(VideoLibraryActions.getCustomFieldsRequestSuccess(HardCodedData));
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getCustomFieldsRequestFailure());
    }
}

function* asyncAddCustomFields(data: VideoLibraryActions.addCustomFieldsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.addCustomFieldsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.addCustomFieldsRequestFailure());
    }
}

function* asyncDeleteCustomFields(data: VideoLibraryActions.deleteCustomFieldsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData('');
        if (response.status === 200) {
            yield put(VideoLibraryActions.deleteCustomFieldsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.deleteCustomFieldsRequestFailure());
    }
}
function* asyncUpdateVideoLibraryLayout(data: VideoLibraryActions.updateVideoLibraryLayoutRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateVideoLibraryLayoutRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateVideoLibraryLayoutRequestFailure());
    }
}

function* asyncGetRegistrationAccessList(data: VideoLibraryActions.getRegistrationAccessListRequestAction) {
    try {
        const hardcodedData = [
            {
                id: '1',
                email: 'jdavis@webcastcloud.com.au'
            },
            {
                id: '2',
                email: 'dean@webcastcloud.com.au'
            },
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(VideoLibraryActions.getRegistrationAccessListRequestSuccess(response.data.result));
        // }
        yield put(VideoLibraryActions.getRegistrationAccessListRequestSuccess(hardcodedData));
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getRegistrationAccessListRequestFailure());
    }
}
function* asyncAddRegistrationAccessList(data: VideoLibraryActions.addRegistrationAccessListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.addRegistrationAccessListRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.addRegistrationAccessListRequestFailure());
    }
}
function* asyncDeleteRegistrationAccessList(data: VideoLibraryActions.deleteRegistrationAccessListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData('');
        if (response.status === 200) {
            yield put(VideoLibraryActions.deleteRegistrationAccessListRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.deleteRegistrationAccessListRequestFailure());
    }
}
function* asyncGetLayoutStyle() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_LAYOUT_STYLE.url);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getLayoutStyleRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getLayoutStyleRequestFailure());
    }
}
function* asyncUpdateLayoutStyle(data: VideoLibraryActions.updateLayoutStyleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_LAYOUT_STYLE.url, data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateLayoutStyleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateLayoutStyleRequestFailure());
    }
}
function* asyncGetRecommendation() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_RECOMMENDATIONS.url);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getRecommendationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getRecommendationRequestFailure());
    }
}
function* asyncUpdateRecommendation(data: VideoLibraryActions.updateRecommendationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData('', data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateRecommendationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateRecommendationRequestFailure());
    }
}
function* asyncGetHighlightReelData(data: VideoLibraryActions.getHightlightReelDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_HIGHLIGHTREEL_DATA.url}/${data.payload?.highlightreelon}?pageNo=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getHightlightReelDataRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getHightlightReelDataRequestFailure());
    }
}
function* asyncGetLayoutContent() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_LAYOUT_CONTENT.url);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getLayoutContentRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getLayoutContentRequestFailure());
    }
}
function* asyncGetAdBanner() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ADBANNER.url);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getAdBannerRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getAdBannerRequestFailure());
    }
}
function* asyncUpdateAdBanner(data: VideoLibraryActions.updateAdBannerRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_ADBANNER.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateAdBannerRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateAdBannerRequestFailure());
    }
}
function* asyncGetSpotlightChannel(data: VideoLibraryActions.getSpotlightChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SPOTLIGHT_CHANNEL.url);
        if (response.status === 200) {
            yield put(VideoLibraryActions.getSpotlightChannelRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.getSpotlightChannelRequestFailure());
    }
}
function* asyncDeleteSpotlightChannel(data: VideoLibraryActions.deleteSpotlightChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_SPOTLIGHT_CHANNEL.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideoLibraryActions.deleteSpotlightChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.deleteSpotlightChannelRequestFailure());
    }
}
function* asyncUpdateSpotlightSettingOrder(data: VideoLibraryActions.updateSpotlightSettingOrderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_SETTING_ORDER.url, data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateSpotlightSettingOrderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateSpotlightSettingOrderRequestFailure());
    }
}
function* asyncUpdateLayoutContent(data: VideoLibraryActions.updateLayoutContentRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_LAYOUT_CONTENT.url, data.payload);
        if (response.status === 200) {
            yield put(VideoLibraryActions.updateLayoutContentRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.updateLayoutContentRequestFailure());
    }
}

function* asyncAddSpotlightChannel(data: VideoLibraryActions.addSpotlightChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_SPOTLIGHT_CHANNEL.url}/${data.payload?.contentId}?rowtype=${data.payload?.rowtype}`);
        if (response.status === 200) {
            yield put(VideoLibraryActions.addSpotlightChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideoLibraryActions.addSpotlightChannelRequestFailure());
    }
}
export function* videoLibrarySaga() {
    yield all([
        takeEvery(Constants.getSpotlightRowRequestActionType, asyncgetSpotLightRow),
        takeEvery(Constants.saveHighlightReelRequestActionType, asyncSaveHighlightReel),
        takeEvery(Constants.saveCustomizeThemeHeaderRequestActionType, asyncSaveCustomizeThemeHeader),
        takeEvery(Constants.saveSpotlightRowsRecommendationRequestActionType, asyncSaveSpotlightRowsRecommendation),
        takeEvery(Constants.getTotalRecordsRequestActionType, asynceGetTotalRecords),
        takeEvery(Constants.getAuthenticationRequestActionType, asyncGetAuthentication),
        takeEvery(Constants.saveAuthenticationRequestActionType, asyncSaveAuthentication),
        takeEvery(Constants.getRegistrationOptionsRequestActionType, asyncGetRegistrationOption),
        takeEvery(Constants.addRegistrationOptionsRequestActionType, asyncAddRegistrationOption),
        takeEvery(Constants.deleteRegistrationOptionRequestActionType, asyncDeleteRegistrationOption),
        takeEvery(Constants.getStandardFieldsRequestActionType, asyncGetStandardFields),
        takeEvery(Constants.saveStandardFieldsRequestActionType, asyncSaveStandardFields),
        takeEvery(Constants.getCustomFieldsRequestActionType, asyncGetCustomFields),
        takeEvery(Constants.addCustomFieldsRequestActionType, asyncAddCustomFields),
        takeEvery(Constants.deleteCustomFieldsRequestActionType, asyncDeleteCustomFields),
        takeEvery(Constants.updateVideoLibraryLayoutRequestActionType, asyncUpdateVideoLibraryLayout),
        takeEvery(Constants.getRegistrationAccessListRequestActionType, asyncGetRegistrationAccessList),
        takeEvery(Constants.addRegistrationAccessListRequestActionType, asyncAddRegistrationAccessList),
        takeEvery(Constants.deleteRegistrationAccessListRequestActionType, asyncDeleteRegistrationAccessList),
        takeEvery(Constants.getLayoutStyleRequestActionType, asyncGetLayoutStyle),
        takeEvery(Constants.updateLayoutStyleRequestActionType, asyncUpdateLayoutStyle),
        takeEvery(Constants.getRecommendationRequestActionType, asyncGetRecommendation),
        takeEvery(Constants.updateRecommendationRequestActionType, asyncUpdateRecommendation),
        takeEvery(Constants.getHightlightReelDataRequestActionType, asyncGetHighlightReelData),
        takeEvery(Constants.getLayoutContentRequestActionType, asyncGetLayoutContent),
        takeEvery(Constants.getAdBannerRequestActionType, asyncGetAdBanner),
        takeEvery(Constants.updateAdBannerRequestActionType, asyncUpdateAdBanner),
        takeEvery(Constants.getSpotlightChannelRequestActionType, asyncGetSpotlightChannel),
        takeEvery(Constants.deleteSpotlightChannelRequestActionType, asyncDeleteSpotlightChannel),
        takeEvery(Constants.updateSpotlightSettingOrderRequestActionType, asyncUpdateSpotlightSettingOrder),
        takeEvery(Constants.updateLayoutContentRequestActionType, asyncUpdateLayoutContent),
        takeEvery(Constants.addSpotlightChannelRequestActionType, asyncAddSpotlightChannel),
    ]);
}
