import React from 'react';

const AddEmailModal = () => {
  return (
    <div>
      <div
        className="modal fade"
        id="AddemailModal"
        aria-labelledby="AddemailModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddemailModalLabel">
                Add Email
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <div className={`form-group mb-3`}>
                  <p>Add emails seperated by a semicolon.</p>
                  <p>
                    <strong>E.g: {`'john@google.com;emma@yahoo.com;sam@webcastcloud.com'`}</strong>
                  </p>
                  <textarea className="input-style mt-4" placeholder="Enter Email"></textarea>
                </div>
              </form>
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                type="submit"
                className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                Add
              </button>
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmailModal;
