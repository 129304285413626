import React from 'react';
import Pagination from 'src/components/common/pagination';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import {
  convertSecondToHourMinuteSecond,
  defaultDateFormatter
} from 'src/components/utilities/utils';

interface IProps {
  memberId: string;
}

const MemberVideoAnalytics: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const { paginationMeta, memberVideoanalytics } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.users.paginationMeta,
      memberVideoanalytics: state.users.memberVideoanalytics
    };
  });
  const handlePageChange = (pageNumber: number | string) => {
    const data = { pageNumber, memberId };
    userAction.getMemberVideoAnalyticsRequest(data);
  };
  const getDarkBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 31, 31, 1)'
      : perc < 50
      ? 'rgba(255, 165, 31, 1)'
      : perc < 75
      ? 'rgba(31, 143, 255, 1)'
      : 'rgba(31, 255, 31, 1)';
  };
  const getLightBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 198, 184, 1)'
      : perc < 50
      ? 'rgba(255, 216, 158, 1)'
      : perc < 75
      ? 'rgba(191, 219, 254, 1)'
      : 'rgba(213, 255, 199, 1)';
  };
  return (
    <div className="my-6">
      <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
          <div className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold ">Video Analytics</h2>
          </div>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold   bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">TITLE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">LIVE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">ONDEMAND</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">PROGRESS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">HOURS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Last Watched</div>
                    </th>
                  </tr>
                </thead>
                {memberVideoanalytics && memberVideoanalytics.length ? (
                  <tbody className="text-sm divide-y divide-gray-100">
                    {memberVideoanalytics &&
                      memberVideoanalytics.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-2 truncate">
                              <div className="text-left">{item.videoName}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.liveViews}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.views}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.percentageWatched}%
                                <div
                                  className="w-full bg-blue-200 h-1 rounded-md mt-2"
                                  style={{
                                    backgroundColor: `${getLightBgColorStr(item.percentageWatched)}`
                                  }}>
                                  <div
                                    className="bg-blue-600 h-1 rounded-md"
                                    style={{
                                      width: `${item.percentageWatched}%`,
                                      backgroundColor: `${getDarkBgColorStr(
                                        item.percentageWatched
                                      )}`
                                    }}></div>
                                </div>
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">
                                {item.totalTimeWatched &&
                                  convertSecondToHourMinuteSecond(item.totalTimeWatched)}
                              </div>
                            </td>
                            <td>
                              <div className="text-center">
                                {item.lastView ? defaultDateFormatter(item.lastView) : ''}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="text-center">No data Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {!!paginationMeta && !!Object.keys(paginationMeta).length && memberVideoanalytics && (
                <Pagination
                  currentPage={paginationMeta.pageNumber}
                  totalCount={paginationMeta.totalCount}
                  pageSize={paginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberVideoAnalytics;
