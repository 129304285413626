import React, { useEffect, useState } from 'react';
import { useChannelActions } from 'src/store/channel/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ChannelModel, singleLevelArray } from 'src/models/channelModel';
import SortableTree from 'react-sortable-tree';
/* eslint-disable @typescript-eslint/no-var-requires */
const FileExplorerTheme = require('react-sortable-tree-theme-file-explorer');
const Tree = ({ setDeleteChannelId, data, setDragFlag, dragFlag, setRecent }: any) => {
  const [list, setList] = useState<ChannelModel[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const channelAction = useChannelActions(dispatch);
  const [showEditToolTips, setShowEditToolTips] = useState('');

  useEffect(() => {
    if (data.length) {
      setList(data);
    }
  }, [data]);

  const getSingleList = (channelList: ChannelModel[], singleLeveles: singleLevelArray[]) => {
    for (let i = 0; i < channelList.length; i++) {
      singleLeveles.push({
        id: channelList[i].id,
        parentid: channelList[i].parentid,
        level: channelList[i].level,
        title: channelList[i].title,
        order: channelList[i].order
      });
      if (channelList[i].children && channelList[i].children.length > 0) {
        getSingleList(channelList[i].children, singleLeveles);
      }
    }
  };
  const convertToSingleLevel = (multilevelArray: ChannelModel[]) => {
    const singleLevelArray: singleLevelArray[] = [];
    getSingleList(multilevelArray, singleLevelArray);
    return singleLevelArray;
  };
  const handleTreeOnChange = (e: ChannelModel[]) => {
    setList(e);
  };

  const handleEditClick = () => {
    if (dragFlag) {
      setDragFlag(false);
    } else {
      setDragFlag(true);
    }
  };
  const handleSubchild = (data: any) => {
    navigate(`/channels/${data.id}`, {
      state: { channelName: data.title, subChannelData: data.children ? data.children : [] }
    });
  };
  const handleMouseEvent = (e: any, id: string) => {
    e.preventDefault();
    setShowEditToolTips(id);
  };
  useEffect(() => {
    const handleClick = () => {
      setShowEditToolTips('');
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const handleEditChannelClick = (channelId: string) => {
    navigate(`/channels/edit/${channelId}`);
  };
  const handleDeleteChannelClick = (channelId: string) => {
    setDeleteChannelId(channelId);
  };
  const handleEditQuickChannelClick = (channelId: string) => {
    channelAction.getSingleChannelRequest(channelId);
  };
  const handleNodeKey = (channelData: any) => {
    if (channelData) {
      const payloadData = {
        parentId:
          channelData.nextParentNode && channelData.nextParentNode?.id
            ? channelData.nextParentNode.id
            : null,
        channelIds: !channelData.nextParentNode
          ? channelData.treeData.map((item: any) => item.id)
          : channelData.nextParentNode && channelData.nextParentNode
          ? channelData.nextParentNode?.children.map((item: any) => item.id)
          : []
      };
      const finalPayloadData = { channelId: channelData.node.id, payloadData };
      channelAction.sortedChannelRequest(finalPayloadData);
    }
  };

  const handleChannelClick = () => {
    navigate(`/channels`);
  };
  return (
    <ul className="d-flex d-tree-container flex-column">
      <div className="flex justify-between mt-4 mb-2 cursor-pointer pr-2">
        <h1 className="font-bold" onClick={handleChannelClick}>
          Channels
        </h1>
        <span onClick={handleEditClick}>
          {' '}
          {dragFlag ? <b>Done</b> : <i className="fas fa-edit"></i>}
        </span>
      </div>

      {list && (
        <SortableTree
          theme={FileExplorerTheme}
          treeData={list}
          onChange={handleTreeOnChange}
          onMoveNode={handleNodeKey}
          isVirtualized={false}
          canDrag={dragFlag ? true : false}
          generateNodeProps={({ node, path }) => ({
            title: dragFlag ? (
              ''
            ) : (
              <div
                className={`flex items-center relative  cursor-pointer ml-5 ${
                  id === node.id ? 'side-panel' : ''
                }`}
                onClick={() => {
                  handleSubchild(node), setRecent(false);
                }}
                onContextMenu={(e) => handleMouseEvent(e, node.id)}>
                {node.title}
              </div>
            ),
            buttons:
              showEditToolTips === node.id
                ? [
                    <div className={`absolute right-0 `} key={node.id}>
                      <div className="flex justify-center">
                        <ul className="dropdown-menu channel-edit">
                          <li
                            data-bs-toggle="modal"
                            onClick={() => handleEditChannelClick(node.id)}>
                            <span className="dropdown-item flex-items cursor-pointer p-1 px-2">
                              <i className="fas fa-edit w-6"></i>
                              <span>Edit</span>
                            </span>
                          </li>
                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#DeleteChannelModal"
                            onClick={() => handleDeleteChannelClick(node.id)}>
                            <span className="dropdown-item flex-items cursor-pointer text-red-500 p-1 px-2">
                              <i className="fas fa-trash w-6"></i>
                              <span>Delete</span>
                            </span>
                          </li>
                          <li
                            data-bs-toggle="modal"
                            data-bs-target="#SettingsModal"
                            onClick={() => handleEditQuickChannelClick(node.id)}>
                            <span className="dropdown-item flex-items cursor-pointer p-1 px-2">
                              <i className="fas fa-edit w-6"></i>
                              <span> Quick Edit</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ]
                : []
          })}
        />
      )}
    </ul>
  );
};

export default Tree;
