import React from 'react';

interface IProps {
  message: string;
}

const FormFieldError: React.FC<IProps> = ({ message }) => {
  return <span className="text-red-500 text-xs flex item-center error">{message}</span>;
};

export default FormFieldError;
