import React from 'react';
import './styles.scss';

const Screen4: React.FC = () => {
    return (
        <div >
            <div className="live-event-main">
                <div id="rightMenuContentMainDiv" className="">
                    <div id="dvStep1" className="ibox-content ">
                        <div id="subData1" className="flex justify-center items-center flex-col text-sm font-normal">
                            <h1 className="font-semibold text-center text-3xl text-black">Live Stream has Finished.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Screen4;