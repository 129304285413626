import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
import { errorMsg } from '../utilities/utils';
import ToastMessage from 'src/components/ToastContainer';

const VerifyMfa: React.FC = () => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState<string[]>(Array(6).fill(''));
    const dispatch = useDispatch();
    const userActions = useUserActions(dispatch);
    const localData = localStorage.getItem("userData");
    const userData = localData && JSON.parse(localData);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const loginType = Number(searchParams.get('sa'));
    const returnUrl = searchParams.get('returnUrl');

    const { isCodeVerified, message } = useSelector(
        (state: RootState) => {
            return {
                isCodeVerified: state.users.isCodeVerified,
                message: state.users.message,
            };
        }
    );

    const handleInputChange = (index: number, value: string) => {
        if (value.length > 1) {
            value = value.slice(0, 1);
        }

        const newInputs = [...inputs];
        newInputs[index] = value;

        setInputs(newInputs);

        if (value && index < inputs.length - 1) {
            document.getElementById(`otp-input-${index + 1}`)?.focus();
        }
    };

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !inputs[index]) {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`)?.focus();
            }
        }
        if (event.key === 'e') {
            event.preventDefault();
        }
        if (event.key === 'Enter') {
            verifyOTP();
        }
    };

    const verifyOTP = () => {
        const otp = inputs.join('');
        if (otp.length === 6) {
            const verificationData = {
                userId: userData?.UserId,
                otp: otp
            }
            userActions.VerifyMfaCodeRequest(verificationData);
        } else {
            errorMsg('Please enter a 6-digit OTP');
        }
    };

    useEffect(() => {
        if (isCodeVerified) {
           // navigate("/");
            if (returnUrl) {
                const pageUrl = returnUrl;
                 navigate(`${pageUrl}`);
            } else {
                const pageUrl = '/';
                navigate(`${pageUrl}`);
            }
        }
        else if (message === "Fail") {
            errorMsg('Invalid MFA Code or an error has occured.');
            userActions.resetMFAStateRequest();
        }
    }, [isCodeVerified, message])

    const handleBack = () => {
        navigate("/login");
    }

    useEffect(() => {
        document.getElementById('otp-input-0')?.focus();
    }, []);

    return (<>
        <ToastMessage />
        <div className="h-full">
            <section
                className="bg-gray-100 h-screen"
                style={{
                    backgroundImage: `url(../../assets/img/wcc-background-image.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}>
                <img
                    className="w-64 absolute top-4 left-4"
                    src="../../assets/img/wcc-logo.png"
                    alt="logo"
                />
                <div id="MFAScannerModal" className="h-full modal-wrap">
                    <div className="container m-auto h-full flex flex-1 flex-col overflow-auto">
                        <div className="flex justify-center h-full">
                            <div className="xl:w-3/5 lg:w-3/5 md:w-8/12 w-12/12 flex flex-col">
                                <div className="m-auto">
                                    <div className="block bg-white shadow-lg rounded-xl m-5">
                                        <div className="lg:flex-wrap g-0">
                                            <h1 className="font-black sm:text-2xl text-xl p-5 m-0 border-b">
                                                Log in using your authenticator app
                                            </h1>
                                            <div className="flex flex-col px-5 pt-5">
                                                <div className="flex flex-col">
                                                    <h3 className="font-black mb-4">
                                                        To continue, please enter the 6-digit verification code generated by your authenticator app.
                                                    </h3>
                                                    <div className="flex space-x-2 justify-center pb-4">
                                                        {inputs.map((input, index) => (
                                                            <input
                                                                key={index}
                                                                id={`otp-input-${index}`}
                                                                type="text"
                                                                maxLength={1}
                                                                value={input}
                                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                                autoComplete="off"
                                                                spellCheck="false"
                                                                className="w-10 h-10 md:w-12 md:h-12 border border-gray-300 rounded-lg text-center text-lg font-semibold focus:outline-none focus:ring-2 focus:ring-purple-500"
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='p-4 border-t flex items-center justify-end gap-4'>
                                                <button className="btn btn-primary m-0 h-10 font-bold tracking-widest px-3 py-1 w-auto" onClick={handleBack}>Back</button>
                                                <button className="btn btn-primary m-0 h-10 font-bold tracking-widest px-3 py-1 w-auto" onClick={verifyOTP} >Verify</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div></>
    );
};

export default VerifyMfa;