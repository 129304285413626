import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useParams } from 'react-router-dom';
interface IProps {
  openLiveEvent: boolean;
}
const LiveEventModal: React.FC<IProps> = ({ openLiveEvent }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const { liveEventUrl, videoMedia } = useSelector((state: RootState) => {
    return {
      liveEventUrl: state.videos.liveEventUrl,
      videoMedia: state.videos.videoMedia
    };
  });

  useEffect(() => {
    if (id) {
      videoAction.getLiveEventUrlRequest(id);
      videoAction.getVideoMediaRequest(id);
    }
  }, []);
  return (
    <>
      <div className={`w-2/6 ${openLiveEvent ? '' : 'hidden'} relative`}>
        <div className="modal-content h-full poll-popup-max live-studio-border">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md mt-4">
            <i className="fa fa-2x right-menu-head-icon fa-info-circle live-studio-heading"></i>
            <h5
              className="text-xl font-semibold leading-normal text-center w-full live-studio-heading"
              id="PollModalLabel">
              Live Event URLs
            </h5>
          </div>
          <div className="font-semibold text-base p-4">
            <h4>{liveEventUrl && liveEventUrl.liveMediaName}</h4>
          </div>
          <div className="p-4">
            <div>
              <div className="w-full">
                <label className="font-semibold">Playback URL</label>
                <div>
                  <input
                    disabled
                    value={`${liveEventUrl && liveEventUrl.liveMediaUrl}`}
                    className="w-full liveEventBorder"
                  />
                </div>
              </div>

              <div className="w-full mt-4">
                <label className="font-semibold">RTMP Ingest URL</label>
                <div>
                  <input
                    disabled
                    value={`${liveEventUrl && liveEventUrl.rtmpIngestURL}`}
                    className="w-full liveEventBorder"
                  />
                </div>
              </div>

              <div className="w-full mt-4">
                <label className="font-semibold">Stream Key</label>
                <div>
                  <input
                    disabled
                    value={`${liveEventUrl && liveEventUrl.streamKey}`}
                    className="w-full liveEventBorder"
                  />
                </div>
              </div>
            </div>
          </div>
          {videoMedia && videoMedia.isSecondary && (
            <>
              <div className="font-semibold text-base p-4">
                <h4>{liveEventUrl && liveEventUrl.secondaryLiveMediaName}</h4>
              </div>
              <div className="p-4">
                <div>
                  <div className="w-full">
                    <label className="font-semibold">Playback URL</label>
                    <div>
                      <input
                        disabled
                        value={`${liveEventUrl && liveEventUrl.secondaryLiveMediaUrl}`}
                        className="w-full liveEventBorder"
                      />
                    </div>
                  </div>

                  <div className="w-full mt-4">
                    <label className="font-semibold">RTMP Ingest URL</label>
                    <div>
                      <input
                        disabled
                        value={`${liveEventUrl && liveEventUrl.secondaryRTMPIngestURL}`}
                        className="w-full liveEventBorder"
                      />
                    </div>
                  </div>

                  <div className="w-full mt-4">
                    <label className="font-semibold">Stream Key</label>
                    <div>
                      <input
                        disabled
                        value={`${liveEventUrl && liveEventUrl.secondaryStreamKey}`}
                        className="w-full liveEventBorder"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LiveEventModal;
