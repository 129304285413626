import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginActions } from 'src/store/login/actions';
import { RootState } from 'src/store/reducers/state';

const ForgetPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const loginAction = useLoginActions(dispatch);
    const {
        forgetRestPassword,
    } = useSelector((state: RootState) => {
        return {
            forgetRestPassword: state.users.userDetails,
        };
    });
    const isButtonDisabled = !email.trim();
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleSendLink = () => {
        if (isValidEmail) {
            loginAction.forgetRestPasswordRequest(email);
            navigate(`/account/ConfirmForgetPassword`);
        }
    };

    const validateEmail = (input: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(input);
        setIsValidEmail(isValid);
    };

    return (
        <>
            <section className="bg-gray-100 h-screen">
                <div className="container px-6 h-full mx-auto">
                    <div className="flex justify-center items-center h-full">
                        <div className="xl:w-6/12 lg:w-6/12 md:w-8/12 w-12/12">
                            <div className="block bg-white shadow-lg">
                                <div className="lg:flex-wrap g-0">
                                    <div className=" px-4 md:px-0">
                                        <div className="md:px-8 px-5 py-16">
                                            <div className="flex justify-center">
                                                <img
                                                    className="w-64 mb-8"
                                                    src="../../assets/img/webcastcloud-logo.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <h3 className="text-2xl font-semibold mb-2 mb-10">Forgot your Password?</h3>
                                            <form>
                                                <div className="mb-4">
                                                    <label className="mb-1 block">Please enter the email you use to login to webcastcloud.</label>
                                                    <input
                                                        type="text"
                                                        className={`input-style ${isValidEmail ? '' : 'input-error'}`}
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            validateEmail(e.target.value);
                                                        }}
                                                    />
                                                    {!isValidEmail && <p className="error-message">Please enter a valid email address.</p>}
                                                </div>
                                                <div className="flex items-center justify-end text-center mb-4 mt-6">
                                                    <button
                                                        className="btn-primary m-0 px-16"
                                                        onClick={handleSendLink}
                                                        disabled={isButtonDisabled || !isValidEmail}
                                                        style={{ cursor: (isButtonDisabled || !isValidEmail) ? 'not-allowed' : 'pointer' }}>
                                                        Send Link
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ForgetPassword;