import React, { useState } from 'react';
import { Icons } from './Icons';
import './IconPicker.scss';

const IconPicker = ({ value, onChange }: { value: string; onChange: any }) => {
  const [SearchText, setSearchText] = useState('');
  const [IsOpen, setIsOpen] = useState(false);
  const [iconName, setIconName] = useState<string>('');
  //filter icons by search text
  const filteredIcons = Icons.filter((icon: any) =>
    icon.title.toLowerCase().includes(SearchText.toLowerCase())
  );

  //select icon
  const selectIcon = (icon: string) => {
    onChange(icon);
    setIsOpen(false);
    setIconName(icon);
  };
  //on search
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSearchClick = () => {
    if (IsOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  return (
    <div className="icon-picker cursor-pointer lg:w-56">
      <div className="p-rel">
        <div className="icon-picker-main" onClick={handleSearchClick}>
          <span className="m-l">Select Icon</span>
          <div>
            <i className={`${value} ml-3`}></i>
            <i className="fa fa-caret-down mr-1 ml-1"></i>
          </div>
        </div>

        {IsOpen && (
          <div className="pick-box-container">
            <div className="pick-box">
              <div className="pick-box-main">
                <form className="nosubmit">
                  <div className="popover-title">
                    <input
                      className="iconpicker-search"
                      type="search"
                      value={SearchText}
                      onChange={onSearch}
                      placeholder="Type to filter"
                    />
                  </div>
                </form>
              </div>
              <div className="icon-list">
                {filteredIcons.map((icon, index) => {
                  return (
                    <div
                      key={index}
                      className={`icon-item ${iconName === icon.title ? 'selectedColor' : ''}`}
                      onClick={() => selectIcon(icon.title)}>
                      <div className="icon">
                        <i className={icon.title}></i>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default IconPicker;
