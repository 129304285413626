import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { AdvanceSettingModal, FilterSettingModal, PlayPageSettingModel, SearchSettingModel, VideoCardModel, UserProfileModel, FontModel, ColorModel, SiteIdentityModel, UpdateAppearanceModel, PlayPageVideoModel, PlayPageNavigationModel, ChatStyleModel, NavigationModel, SaveNavigationModel, SingleNavigation, UpdateNavigationModel } from 'src/models/appearanceModel';
export namespace AppearanceActions {

    export type getSiteIdentityRequestAction = Action<typeof Constants.getSiteIdentityRequestActionType, null>
    export type getSiteIdentityRequestSuccessAction = Action<typeof Constants.getSiteIdentityRequestSuccessActionType, SiteIdentityModel>;
    export type getSiteIdentityRequestFailureAction = Action<typeof Constants.getSiteIdentityRequestFailureActionType, null>;

    export function getSiteIdentityRequest(): getSiteIdentityRequestAction {
        return ActionCreator(Constants.getSiteIdentityRequestActionType, null);
    }
    export function getSiteIdentityRequestSuccess(P: SiteIdentityModel): getSiteIdentityRequestSuccessAction {
        return ActionCreator(Constants.getSiteIdentityRequestSuccessActionType, P);
    }
    export function getSiteIdentityRequestFailure(): getSiteIdentityRequestFailureAction {
        return ActionCreator(Constants.getSiteIdentityRequestFailureActionType, null);
    }

    export type updateAppearanceRequestAction = Action<typeof Constants.updateAppearanceRequestActionType, UpdateAppearanceModel[]>
    export type updateAppearanceRequestSuccessAction = Action<typeof Constants.updateAppearanceRequestSuccessActionType, null>;
    export type updateAppearanceRequestFailureAction = Action<typeof Constants.updateAppearanceRequestFailureActionType, null>;

    export function updateAppearanceRequest(P: UpdateAppearanceModel[]): updateAppearanceRequestAction {
        return ActionCreator(Constants.updateAppearanceRequestActionType, P);
    }
    export function updateAppearanceRequestSuccess(): updateAppearanceRequestSuccessAction {
        return ActionCreator(Constants.updateAppearanceRequestSuccessActionType, null);
    }
    export function updateAppearanceRequestFailure(): updateAppearanceRequestFailureAction {
        return ActionCreator(Constants.updateAppearanceRequestFailureActionType, null);
    }

    export type getColorsRequestAction = Action<typeof Constants.getColorsRequestActionType, null>
    export type getColorsRequestSuccessAction = Action<typeof Constants.getColorsRequestSuccessActionType, ColorModel>;
    export type getColorsRequestFailureAction = Action<typeof Constants.getColorsRequestFailureActionType, null>;

    export function getColorsRequest(): getColorsRequestAction {
        return ActionCreator(Constants.getColorsRequestActionType, null);
    }
    export function getColorsRequestSuccess(P: ColorModel): getColorsRequestSuccessAction {
        return ActionCreator(Constants.getColorsRequestSuccessActionType, P);
    }
    export function getColorsRequestFailure(): getColorsRequestFailureAction {
        return ActionCreator(Constants.getColorsRequestFailureActionType, null);
    }

    export type getFontsRequestAction = Action<typeof Constants.getFontsRequestActionType, null>
    export type getFontsRequestSuccessAction = Action<typeof Constants.getFontsRequestSuccessActionType, FontModel>;
    export type getFontsRequestFailureAction = Action<typeof Constants.getFontsRequestFailureActionType, null>;

    export function getFontsRequest(): getFontsRequestAction {
        return ActionCreator(Constants.getFontsRequestActionType, null);
    }
    export function getFontsRequestSuccess(P: FontModel): getFontsRequestSuccessAction {
        return ActionCreator(Constants.getFontsRequestSuccessActionType, P);
    }
    export function getFontsRequestFailure(): getFontsRequestFailureAction {
        return ActionCreator(Constants.getFontsRequestFailureActionType, null);
    }

    export type getUserProfileRequestAction = Action<typeof Constants.getUserProfileRequestActionType, null>
    export type getUserProfileRequestSuccessAction = Action<typeof Constants.getUserProfileRequestSuccessActionType, UserProfileModel>;
    export type getUserProfileRequestFailureAction = Action<typeof Constants.getUserProfileRequestFailureActionType, null>;

    export function getUserProfileRequest(): getUserProfileRequestAction {
        return ActionCreator(Constants.getUserProfileRequestActionType, null);
    }
    export function getUserProfileRequestSuccess(P: UserProfileModel): getUserProfileRequestSuccessAction {
        return ActionCreator(Constants.getUserProfileRequestSuccessActionType, P);
    }
    export function getUserProfileRequestFailure(): getUserProfileRequestFailureAction {
        return ActionCreator(Constants.getUserProfileRequestFailureActionType, null);
    }

    export type getVideoCardRequestAction = Action<typeof Constants.getVideoCardRequestActionType, null>
    export type getVideoCardRequestSuccessAction = Action<typeof Constants.getVideoCardRequestSuccessActionType, VideoCardModel>;
    export type getVideoCardRequestFailureAction = Action<typeof Constants.getVideoCardRequestFailureActionType, null>;

    export function getVideoCardRequest(): getVideoCardRequestAction {
        return ActionCreator(Constants.getVideoCardRequestActionType, null);
    }
    export function getVideoCardRequestSuccess(P: VideoCardModel): getVideoCardRequestSuccessAction {
        return ActionCreator(Constants.getVideoCardRequestSuccessActionType, P);
    }
    export function getVideoCardRequestFailure(): getVideoCardRequestFailureAction {
        return ActionCreator(Constants.getVideoCardRequestFailureActionType, null);
    }

    export type resetAppearanceStateRequestAction = Action<typeof Constants.resetAppearanceStateRequestActionType, null>

    export function resetAppearanceStateRequest(): resetAppearanceStateRequestAction {
        return ActionCreator(Constants.resetAppearanceStateRequestActionType, null);
    }

    export type getPlayPageVideoRequestAction = Action<typeof Constants.getPlayPageVideoRequestActionType, null>
    export type getPlayPageVideoRequestSuccessAction = Action<typeof Constants.getPlayPageVideoRequestSuccessActionType, PlayPageVideoModel>;
    export type getPlayPageVideoRequestFailureAction = Action<typeof Constants.getPlayPageVideoRequestFailureActionType, null>;

    export function getPlayPageVideoRequest(): getPlayPageVideoRequestAction {
        return ActionCreator(Constants.getPlayPageVideoRequestActionType, null);
    }
    export function getPlayPageVideoRequestSuccess(P: PlayPageVideoModel): getPlayPageVideoRequestSuccessAction {
        return ActionCreator(Constants.getPlayPageVideoRequestSuccessActionType, P);
    }
    export function getPlayPageVideoRequestFailure(): getPlayPageVideoRequestFailureAction {
        return ActionCreator(Constants.getPlayPageVideoRequestFailureActionType, null);
    }

    export type getPlayPageNavigationRequestAction = Action<typeof Constants.getPlayPageNavigationRequestActionType, null>
    export type getPlayPageNavigationRequestSuccessAction = Action<typeof Constants.getPlayPageNavigationRequestSuccessActionType, PlayPageNavigationModel>;
    export type getPlayPageNavigationRequestFailureAction = Action<typeof Constants.getPlayPageNavigationRequestFailureActionType, null>;

    export function getPlayPageNavigationRequest(): getPlayPageNavigationRequestAction {
        return ActionCreator(Constants.getPlayPageNavigationRequestActionType, null);
    }
    export function getPlayPageNavigationRequestSuccess(P: PlayPageNavigationModel): getPlayPageNavigationRequestSuccessAction {
        return ActionCreator(Constants.getPlayPageNavigationRequestSuccessActionType, P);
    }
    export function getPlayPageNavigationRequestFailure(): getPlayPageNavigationRequestFailureAction {
        return ActionCreator(Constants.getPlayPageNavigationRequestFailureActionType, null);
    }

    export type getChatStyleRequestAction = Action<typeof Constants.getChatStyleRequestActionType, null>
    export type getChatStyleRequestSuccessAction = Action<typeof Constants.getChatStyleRequestSuccessActionType, ChatStyleModel>;
    export type getChatStyleRequestFailureAction = Action<typeof Constants.getChatStyleRequestFailureActionType, null>;

    export function getChatStyleRequest(): getChatStyleRequestAction {
        return ActionCreator(Constants.getChatStyleRequestActionType, null);
    }
    export function getChatStyleRequestSuccess(P: ChatStyleModel): getChatStyleRequestSuccessAction {
        return ActionCreator(Constants.getChatStyleRequestSuccessActionType, P);
    }
    export function getChatStyleRequestFailure(): getChatStyleRequestFailureAction {
        return ActionCreator(Constants.getChatStyleRequestFailureActionType, null);
    }

    export type updateChatStyleRequestAction = Action<typeof Constants.updateChatStyleRequestActionType, null>
    export type updateChatStyleRequestSuccessAction = Action<typeof Constants.updateChatStyleRequestSuccessActionType, null>;
    export type updateChatStyleRequestFailureAction = Action<typeof Constants.updateChatStyleRequestFailureActionType, null>;

    export function updateChatStyleRequest(): updateChatStyleRequestAction {
        return ActionCreator(Constants.updateChatStyleRequestActionType, null);
    }
    export function updateChatStyleRequestSuccess(): updateChatStyleRequestSuccessAction {
        return ActionCreator(Constants.updateChatStyleRequestSuccessActionType, null);
    }
    export function updateChatStyleRequestFailure(): updateChatStyleRequestFailureAction {
        return ActionCreator(Constants.updateChatStyleRequestFailureActionType, null);
    }

    export type getNavigationRequestAction = Action<typeof Constants.getNavigationRequestActionType, null>
    export type getNavigationRequestSuccessAction = Action<typeof Constants.getNavigationRequestSuccessActionType, NavigationModel[]>;
    export type getNavigationRequestFailureAction = Action<typeof Constants.getNavigationRequestFailureActionType, null>;

    export function getNavigationRequest(): getNavigationRequestAction {
        return ActionCreator(Constants.getNavigationRequestActionType, null);
    }
    export function getNavigationRequestSuccess(P: NavigationModel[]): getNavigationRequestSuccessAction {
        return ActionCreator(Constants.getNavigationRequestSuccessActionType, P);
    }
    export function getNavigationRequestFailure(): getNavigationRequestFailureAction {
        return ActionCreator(Constants.getNavigationRequestFailureActionType, null);
    }


    export type saveNavigationRequestAction = Action<typeof Constants.saveNavigationRequestActionType, SaveNavigationModel>
    export type saveNavigationRequestSuccessAction = Action<typeof Constants.saveNavigationRequestSuccessActionType, null>;
    export type saveNavigationRequestFailureAction = Action<typeof Constants.saveNavigationRequestFailureActionType, null>;

    export function saveNavigationRequest(P: SaveNavigationModel): saveNavigationRequestAction {
        return ActionCreator(Constants.saveNavigationRequestActionType, P);
    }
    export function saveNavigationRequestSuccess(): saveNavigationRequestSuccessAction {
        return ActionCreator(Constants.saveNavigationRequestSuccessActionType, null);
    }
    export function saveNavigationRequestFailure(): saveNavigationRequestFailureAction {
        return ActionCreator(Constants.saveNavigationRequestFailureActionType, null);
    }


    export type getSingleNavigationRequestAction = Action<typeof Constants.getSingleNavigationRequestActionType, string>
    export type getSingleNavigationRequestSuccessAction = Action<typeof Constants.getSingleNavigationRequestSuccessActionType, SingleNavigation>;
    export type getSingleNavigationRequestFailureAction = Action<typeof Constants.getSingleNavigationRequestFailureActionType, null>;

    export function getSingleNavigationRequest(P: string): getSingleNavigationRequestAction {
        return ActionCreator(Constants.getSingleNavigationRequestActionType, P);
    }
    export function getSingleNavigationRequestSuccess(P: SingleNavigation): getSingleNavigationRequestSuccessAction {
        return ActionCreator(Constants.getSingleNavigationRequestSuccessActionType, P);
    }
    export function getSingleNavigationRequestFailure(): getSingleNavigationRequestFailureAction {
        return ActionCreator(Constants.getSingleNavigationRequestFailureActionType, null);
    }

    export type updateNavigationRequestAction = Action<typeof Constants.updateNavigationRequestActionType, UpdateNavigationModel>
    export type updateNavigationRequestSuccessAction = Action<typeof Constants.updateNavigationRequestSuccessActionType, null>;
    export type updateNavigationRequestFailureAction = Action<typeof Constants.updateNavigationRequestFailureActionType, null>;

    export function updateNavigationRequest(P: UpdateNavigationModel): updateNavigationRequestAction {
        return ActionCreator(Constants.updateNavigationRequestActionType, P);
    }
    export function updateNavigationRequestSuccess(): updateNavigationRequestSuccessAction {
        return ActionCreator(Constants.updateNavigationRequestSuccessActionType, null);
    }
    export function updateNavigationRequestFailure(): updateNavigationRequestFailureAction {
        return ActionCreator(Constants.updateNavigationRequestFailureActionType, null);
    }

    export type deleteNavigationRequestAction = Action<typeof Constants.deleteNavigationRequestActionType, string>
    export type deleteNavigationRequestSuccessAction = Action<typeof Constants.deleteNavigationRequestSuccessActionType, string>;
    export type deleteNavigationRequestFailureAction = Action<typeof Constants.deleteNavigationRequestFailureActionType, null>;

    export function deleteNavigationRequest(P: string): deleteNavigationRequestAction {
        return ActionCreator(Constants.deleteNavigationRequestActionType, P);
    }
    export function deleteNavigationRequestSuccess(P: string): deleteNavigationRequestSuccessAction {
        return ActionCreator(Constants.deleteNavigationRequestSuccessActionType, P);
    }
    export function deleteNavigationRequestFailure(): deleteNavigationRequestFailureAction {
        return ActionCreator(Constants.deleteNavigationRequestFailureActionType, null);
    }

    export type getSearchSettingRequestAction = Action<typeof Constants.getSearchSettingRequestActionType, null>
    export type getSearchSettingRequestSuccessAction = Action<typeof Constants.getSearchSettingRequestSuccessActionType, SearchSettingModel>;
    export type getSearchSettingRequestFailureAction = Action<typeof Constants.getSearchSettingRequestFailureActionType, null>;

    export function getSearchSettingRequest(): getSearchSettingRequestAction {
        return ActionCreator(Constants.getSearchSettingRequestActionType, null);
    }
    export function getSearchSettingRequestSuccess(P: SearchSettingModel): getSearchSettingRequestSuccessAction {
        return ActionCreator(Constants.getSearchSettingRequestSuccessActionType, P);
    }
    export function getSearchSettingRequestFailure(): getSearchSettingRequestFailureAction {
        return ActionCreator(Constants.getSearchSettingRequestFailureActionType, null);
    }

    export type getPlayPageSettingRequestAction = Action<typeof Constants.getPlayPageSettingRequestActionType, null>
    export type getPlayPageSettingRequestSuccessAction = Action<typeof Constants.getPlayPageSettingRequestSuccessActionType, PlayPageSettingModel>;
    export type getPlayPageSettingRequestFailureAction = Action<typeof Constants.getPlayPageSettingRequestFailureActionType, null>;

    export function getPlayPageSettingRequest(): getPlayPageSettingRequestAction {
        return ActionCreator(Constants.getPlayPageSettingRequestActionType, null);
    }
    export function getPlayPageSettingRequestSuccess(P: PlayPageSettingModel): getPlayPageSettingRequestSuccessAction {
        return ActionCreator(Constants.getPlayPageSettingRequestSuccessActionType, P);
    }
    export function getPlayPageSettingRequestFailure(): getPlayPageSettingRequestFailureAction {
        return ActionCreator(Constants.getPlayPageSettingRequestFailureActionType, null);
    }

    export type getAdvanceSettingRequestAction = Action<typeof Constants.getAdvanceSettingRequestActionType, null>
    export type getAdvanceSettingRequestSuccessAction = Action<typeof Constants.getAdvanceSettingRequestSuccessActionType, AdvanceSettingModal>;
    export type getAdvanceSettingRequestFailureAction = Action<typeof Constants.getAdvanceSettingRequestFailureActionType, null>;

    export function getAdvanceSettingRequest(): getAdvanceSettingRequestAction {
        return ActionCreator(Constants.getAdvanceSettingRequestActionType, null);
    }
    export function getAdvanceSettingRequestSuccess(P: AdvanceSettingModal): getAdvanceSettingRequestSuccessAction {
        return ActionCreator(Constants.getAdvanceSettingRequestSuccessActionType, P);
    }
    export function getAdvanceSettingRequestFailure(): getAdvanceSettingRequestFailureAction {
        return ActionCreator(Constants.getAdvanceSettingRequestFailureActionType, null);
    }

    export type getFilterSettingRequestAction = Action<typeof Constants.getFilterSettingRequestActionType, null>
    export type getFilterSettingRequestSuccessAction = Action<typeof Constants.getFilterSettingRequestSuccessActionType, FilterSettingModal>;
    export type getFilterSettingRequestFailureAction = Action<typeof Constants.getFilterSettingRequestFailureActionType, null>;

    export function getFilterSettingRequest(): getFilterSettingRequestAction {
        return ActionCreator(Constants.getFilterSettingRequestActionType, null);
    }
    export function getFilterSettingRequestSuccess(P: FilterSettingModal): getFilterSettingRequestSuccessAction {
        return ActionCreator(Constants.getFilterSettingRequestSuccessActionType, P);
    }
    export function getFilterSettingRequestFailure(): getFilterSettingRequestFailureAction {
        return ActionCreator(Constants.getFilterSettingRequestFailureActionType, null);
    }
}

export type AppearanceActions = Omit<typeof AppearanceActions, 'Type'>;
export const useAppearanceActions = (dispatch: Dispatch) => {
    const { ...actions } = AppearanceActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as AppearanceActions;
};