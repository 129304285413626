import React, { useEffect, useState } from 'react';
import Editor from 'src/components/Editor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
const AdBanner = () => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const videoAction = useVideosActions(dispatch);
  const [editorState, setEditorState] = useState<string>('');
  const { adBanner, isAdBannerUpdateLoading, mediaUrl } = useSelector((state: RootState) => {
    return {
      adBanner: state.videoLibrary.adBanner,
      isAdBannerUpdateLoading: state.videoLibrary.isAdBannerUpdateLoading,
      mediaUrl: state.videos.mediaUrl
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm();

  useEffect(() => {
    if (adBanner && Object.keys(adBanner).length) {
      videoAction.getMediaUrlRequest(adBanner.adBanner);
    }
  }, [adBanner]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);

  useEffect(() => {
    if (adBanner) {
      reset(adBanner);
      setEditorState(adBanner.adBanner);
    }
  }, [adBanner]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'adBanner', value: ImageStringConverter(editorState) }
    ];
    videoLibraryAction.updateAdBannerRequest(payloadData);
  };
  return (
    <div>
      <div className="spotlight-rows">
        <form onSubmit={handleSubmit(onSubmit)}>
          {isAdBannerUpdateLoading && <Loader />}
          <button type="submit" className="btn-primary w-auto mx-0 ml-auto mb-4 mt-0">
            Save
          </button>
          <div className="shadow p-5 mt-6 border">
            <div className="px-5">
              <div className={`form-group mb-4`}>
                <div className="mb-3">
                  <h6 className="font-bold mb-1">Home Page Ad Banner</h6>
                  <p>
                    Display a custom message on your home page horizontal ad space,that will appear
                    below your highlight reel and above your search filter.
                  </p>
                  <p>Ideal for a welcome message and/or advertising space</p>
                </div>
                <Editor editorState={editorState} setEditorState={setEditorState} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdBanner;
