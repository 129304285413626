/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
const CKEditor = require('@ckeditor/ckeditor5-react').CKEditor;
// const ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
const ClassicEditor = require('ckeditor5-custom-build/build/ckeditor');
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
interface IProps {
  disabled?: boolean;
  data: any;
  setValue: any;
  index: any;
}

const Editor: React.FC<IProps> = ({ disabled, data, setValue, index }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [imageUploaded, setImageUploaded] = useState(false);

  useEffect(() => {
    if (imageUploaded) {
      const editorData = sessionStorage.getItem('editorData');
      if (editorData) {
        videoAction.getMediaUrlRequest(editorData);
      }
      setImageUploaded(false);
    }
  }, [imageUploaded]);

  const editorConfiguration = {
    extraPlugins: [uploadPlugin],
    fontFamily: {
      options: [
        'default',
        'Arial',
        'Arial Black',
        'Arial Narrow, sans-serif',
        'Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif',
        'Andale Mono, monospace',
        'Apple Chancery, cursive',
        'Bookman, URW Bookman L, serif',
        'Calibri, sans-serif',
        'Courier New, Courier, monospace',
        'Courier, monospace',
        'cursive',
        'Didot, serif',
        'fantasy',
        'FreeMono, monospace',
        'Gabriola',
        'Gill Sans, sans-serif',
        'Georgia, serif',
        'Helvetica',
        'Ink Free',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Noto Sans, sans-serif',
        'OCR A Std, monospace',
        'Optima, sans-serif',
        'Palatino, URW Palladio L, serif',
        'sans - serif',
        'Segoe Print',
        'Segoe Script',
        'Segoe UI',
        'Shruti',
        'SimSun',
        'system - ui',
        'Sylfaen',
        'Symbol',
        'Thai',
        'Tahoma, Geneva, sans-serif',
        'Times New Roman, Times, serif',
        'Trebuchet MS, Helvetica, sans-serif',
        'Trebuchet MS',
        'ui-serif',
        'ui-sans-serif',
        'ui-monospace',
        'ui-rounded',
        'fangsong',
        'Verdana, Geneva, sans-serif',
        'Vijaya',
        'Yu Gothic'
      ],
      supportAllValues: true
    },
    fontSize: {
      options: [8, 9, 11, 12, 14, 16, 18, 20, 24, 26, 28, 36, 48, 56],
      supportAllValues: true
    },
    toolbar: {
      items: [
        'heading',
        '|',
        'fontFamily',
        'fontSize',
        'FontColor',
        '|',
        'alignment',
        'indent',
        'outdent',
        '|',
        'imageUpload',
        'mediaEmbed',
        'insertTable',
        'HtmlEmbed',
        'blockQuote',
        'bold',
        'italic',
        'link',
        '|',
        'numberedList',
        'bulletedList',
        'todoList',
        '|',
        'undo',
        'redo',
        'horizontalLine'
      ]
    },
    image: {
      resizeOptions: [
        {
          name: 'resizeImage:original',
          value: null,
          label: 'Original'
        },
        {
          name: 'resizeImage:40',
          value: '40',
          label: '40%'
        },
        {
          name: 'resizeImage:60',
          value: '60',
          label: '60%'
        }
      ],
      toolbar: [
        'imageStyle:inline',
        {
          name: 'imageStyle:icons',
          items: ['imageStyle:alignLeft', 'imageStyle:side'],
          defaultItem: 'imageStyle:alignLeft'
        },
        {
          name: 'imageStyle:pictures',
          items: [
            'imageStyle:alignBlockLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignBlockRight'
          ],
          defaultItem: 'imageStyle:alignCenter'
        },
        '|',
        'resizeImage',
        '|',
        'toggleImageCaption',
        'imageTextAlternative'
      ]
    },
    link: {
      addTargetToExternalLinks: false,
      decorators: {
        openInNewTab: {
          mode: 'manual',
          label: 'Open in a new tab',
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer'
          }
        },
        buttonLink: {
          mode: 'manual',
          label: 'Style as button',
          defaultValue: false,
          attributes: {
            class: 'btn button m-0 rounded ck-custom-link-button w-auto'
          }
        }
      }
    },
    language: 'en',
    licenseKey: ''
  };

  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file: any) => {
            uploadFileToApi(file, resolve, reject);
          });
        });
      }
    };
  }

  function uploadFileToApi(file: any, resolve: any, reject: any) {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    const apiPath = '/v2/htmleditor/upload';
    const fileName = file.name;
    const fileType = file.type;
    const bearerToken = localStorage.getItem('token');

    const apiUrl = `${rootUrl}${apiPath}?fileName=${fileName}&fileType=${fileType}`;

    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        sessionStorage.setItem('imageUrl', data.result.resourceUrl);
        const preSignedUrl = data.result.signedUrl;
        fetch(preSignedUrl, {
          method: 'put',
          headers: {
            'Content-Type': `${file.type}`
          },
          body: file
        }).then((res) => {
          if (res) {
            resolve({ default: sessionStorage.getItem('imageUrl') });
            setImageUploaded(true);
          }
        });
      })
      .catch((error) => {
        // Handle errors during the fetch
        reject(error);
        console.error('Error during fetch:', error);
      });
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      disabled={disabled}
      data={data}
      onChange={(event: any, editor: any) => {
        const data = editor.getData();
        setValue(`reminders.${index}.emailReminderBody`, data);
      }}
    />
  );
};

export default Editor;
