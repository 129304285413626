import React from 'react';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

interface IProps {
  label?: string;
  type: string;
  fieldName: string;
  register: any;
  errors: any;
  isRequired: boolean;
  maxLength?: number;
  minLength?: number;
  pattern?: any;
  value?: string;
  handleChange?: any;
  name?: any;
  placeholder?: string;
  disable?: boolean;
}

const FormInputField: React.FC<IProps> = ({
  label,
  type,
  fieldName,
  register,
  errors,
  isRequired,
  maxLength,
  minLength,
  pattern,
  value,
  handleChange,
  name,
  placeholder,
  disable
}) => {
  return (
    <>
      <label className="block text-sm font-medium mb-1">
        {' '}
        {label} {isRequired ? label ? <span className="text-red-500">*</span> : '' : ''}
      </label>
      <input
        type={type}
        className={
          type === 'file'
            ? 'block w-full cursor-pointer py-0 text-sm input-style pl-0'
            : type === 'checkbox'
            ? 'checkbox-style'
            : 'input-style'
        }
        id={fieldName}
        name={name}
        {...register(fieldName, {
          required: isRequired,
          maxLength: maxLength,
          minLength: minLength,
          pattern: pattern,
          onChange: (e: any) => handleChange && handleChange(e)
        })}
        placeholder={placeholder}
        disabled={disable}
        autoComplete="new-password"
      />
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
      {errors[fieldName]?.type === 'maxLength' && (
        <FormFieldError message={AppConstants.maxLengthReached} />
      )}
      {errors.fieldName?.type === 'pattern' && (
        <FormFieldError message={AppConstants.notValidNumber} />
      )}
    </>
  );
};

export default FormInputField;
