import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const GoogleIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isIntegrationUpdated, googleIntegration, message, error } = useSelector(
    (state: RootState) => {
      return {
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        googleIntegration: state.setting.googleIntegration,
        message: state.setting.message,
        error: state.setting.error
      };
    }
  );

  useEffect(() => {
    if (googleIntegration) {
      reset(googleIntegration);
    }
  }, [googleIntegration]);

  useEffect(() => {
    settingAction.getGoogleIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    const payloadData = {
      name: 'Google',
      updateData: [{ op: 'replace', path: 'googleTagManagerId', value: data.googleTagManagerId }]
    };
    settingAction.updateIntegrationRequest(payloadData);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong>Google</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5 w-5/6">
        <div className="md:flex items-center mb-3 mt-3">
          <div className="md:w-4/12">
            <label className="font-semibold">Google Tag Manager Id</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'googleTagManagerId'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>

        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default GoogleIntegration;
