import { handleActions } from "redux-actions";
import { EzidebitActions } from "./actions";
import { RootState } from "../reducers/state";
import { EzidebitModel, EzidebitStateModel } from "src/models/ezidebitModel";
import { Actions } from "src/models/ezidebitModel";
import * as Constants from './constants'
const initialState: RootState.EzidebitModelState = {
    ezidebit: [],
    singleEzidebit: {} as EzidebitModel,
    isEzidebitSaved: false,
    isEzidebitUpdated: false,
    isEzidebitDeleted: false,
    success: false,
    error: false,
    loading: false,
};


function EzidebitReducer(state: EzidebitStateModel = initialState, action: Actions): EzidebitStateModel {
    switch (action.type) {
        case Constants.getEzidebitRequestActionType: return getEzidebitAction(state);
        case Constants.getEzidebitSuccessActionType: return getEzidebitSuccessAction(state, action);
        case Constants.getEzidebitFailureActionType: return getEzidebitFailureAction(state);

        case Constants.saveEzidebitRequestActionType: return saveEzidebitAction(state);
        case Constants.saveEzidebitSuccessActionType: return saveEzidebitSuccessAction(state);
        case Constants.saveEzidebitFailureActionType: return saveEzidebitFailureAction(state);

        case Constants.updateEzidebitRequestActionType: return updateEzidebitAction(state);
        case Constants.updateEzidebitSuccessActionType: return updateEzidebitSuccessAction(state);
        case Constants.updateEzidebitFailureActionType: return updateEzidebitFailureAction(state);

        case Constants.getSingleEzidebitRequestActionType: return getSingleEzidebitAction(state);
        case Constants.getSingleEzidebitSuccessActionType: return getSingleEzidebitSuccessAction(state, action);
        case Constants.getSingleEzidebitFailureActionType: return getSingleEzidebitFailureAction(state);

        case Constants.deleteEzidebitRequestActionType: return deleteEzidebitAction(state);
        case Constants.deleteEzidebitSuccessActionType: return deleteEzidebitSuccessAction(state);
        case Constants.deleteEzidebitFailureActionType: return deleteEzidebitFailureAction(state);

        default: return state;
    }

    function getEzidebitAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEzidebitSuccessAction(state: EzidebitStateModel, action: EzidebitActions.getEzidebitRequestSuccessAction): EzidebitStateModel {
        if (action.payload) {
            return {
                ...state,
                ezidebit: action.payload
            };
        }
        return state;
    }
    function getEzidebitFailureAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            success: false
        }
    }
    function saveEzidebitAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveEzidebitSuccessAction(state: EzidebitStateModel): EzidebitStateModel {

        return {
            ...state,
            isEzidebitSaved: true
        };
    }
    function saveEzidebitFailureAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            isEzidebitSaved: false
        }
    }
    function updateEzidebitAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateEzidebitSuccessAction(state: EzidebitStateModel): EzidebitStateModel {

        return {
            ...state,
            isEzidebitUpdated: true
        };
    }
    function updateEzidebitFailureAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            isEzidebitUpdated: false
        }
    }
    function getSingleEzidebitAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleEzidebitSuccessAction(state: EzidebitStateModel, action: EzidebitActions.getSingleEzidebitRequestSuccessAction): EzidebitStateModel {
        if (action.payload) {
            return {
                ...state,
                singleEzidebit: action.payload
            };
        }
        return state;
    }
    function getSingleEzidebitFailureAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            success: false
        }
    }
    function deleteEzidebitAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteEzidebitSuccessAction(state: EzidebitStateModel): EzidebitStateModel {

        return {
            ...state,
            isEzidebitDeleted: true
        };
    }
    function deleteEzidebitFailureAction(state: EzidebitStateModel): EzidebitStateModel {
        return {
            ...state,
            isEzidebitDeleted: false
        }
    }
}

export default EzidebitReducer
