import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoMonitor = ({ src, playerKey, setShowMessage }: any) => {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    const initializePlayer = () => {
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');
        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);

        const player = (playerRef.current = videojs(
          videoElement,
          {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            muted: true,
            sources: [
              {
                src,
                type: 'application/x-mpegURL'
              }
            ]
          },
          () => {
            videojs.log('player is ready');
          }
        ));
        // Check if the video is live
        player.on('timeupdate', () => {
          const isLive = playerRef?.current?.duration() === Infinity;
          if (isLive) {
            setShowMessage(false);
          } else {
            setShowMessage(true);
          }
        });
      }
    };

    initializePlayer();

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [src, playerKey]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoMonitor;
