import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { RootState } from 'src/store/reducers/state';
import Pagination from 'src/components/common/pagination';
import Loader from 'src/components/loader';
interface IProps {
  courseId: string;
  memberId: string;
}

const AnswerQuestion: React.FC<IProps> = ({ courseId, memberId }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);

  const { courseMemberQuestion, paginationMeta, isCourseMemberQuestionLoading } = useSelector(
    (state: RootState) => {
      return {
        courseMemberQuestion: state.analytics.courseMemberQuestion,
        paginationMeta: state.analytics.paginationMeta,
        isCourseMemberQuestionLoading: state.analytics.isCourseMemberQuestionLoading
      };
    }
  );

  useEffect(() => {
    if (courseId) {
      analyticsAction.getAnswerQuestionRequest({ courseId, memberId, pageNumber: 1, pageSize: 10 });
    }
  }, [courseId]);

  const handlePageChange = (pageNumber: number) => {
    analyticsAction.getAnswerQuestionRequest({ courseId, memberId, pageNumber, pageSize: 10 });
  };

  return (
    <>
      <div>
        <div
          className="modal fade"
          id="SingleCourseView"
          aria-labelledby="SingleCourseViewLabel"
          aria-hidden="true">
          <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
            <div className="modal-content">
              <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
                <button
                  type="button"
                  className="dismiss-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body relative py-4 px-10">
                <h6 className="text-lg leading-normal w-full font-semibold"></h6>
                <hr />
                {isCourseMemberQuestionLoading ? (
                  <Loader />
                ) : (
                  <>
                    {courseMemberQuestion && courseMemberQuestion.length ? (
                      <>
                        {courseMemberQuestion &&
                          courseMemberQuestion.map((item, index) => {
                            return (
                              <div key={index} className="mt-4">
                                <h4 className="text-xl font-semibold">
                                  <span>{index + 1}. </span>
                                  {item.questiontext}
                                </h4>
                                <div className="mt-2 flex">
                                  <i className="fa-regular fa-circle-check text-base text-green-500"></i>
                                  <p className="ml-3">{item.submittedanswertext}</p>
                                </div>
                              </div>
                            );
                          })}
                        {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                          <Pagination
                            currentPage={paginationMeta.pageNumber}
                            totalCount={paginationMeta.totalCount}
                            pageSize={paginationMeta.pageSize}
                            onPageChange={(page: any) => handlePageChange(page)}
                          />
                        )}
                      </>
                    ) : (
                      <div className="mt-4">
                        <h4 className="text-xl font-semibold">No data Found</h4>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnswerQuestion;
