import React, { useEffect, useState } from 'react';
import ResultComponent from './ResultComponent';
import BarChart from 'src/components/charts/barchart/BarChart';
import { RootState } from 'src/store/reducers/state';
import { useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
const ResultModal = () => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [resultData, setResultData] = useState<any>();
  const [chartData, setChartData] = useState<any>();

  const { pollsResult, isPollResultLoading } = useSelector((state: RootState) => {
    return {
      pollsResult: state.videos.pollsResult,
      isPollResultLoading: state.videos.isPollResultLoading
    };
  });
  const [barChartData, setBarChartData] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [''],
        backgroundColor: ['#4285F4']
      }
    ]
  });

  useEffect(() => {
    setBarChartData({
      labels: chartData && chartData.ratingForChartResultLabels['1']?.map((data: any) => data),
      datasets: [
        {
          label: '',
          data: chartData && chartData.ratingForChartResultValues['1']?.map((data: any) => data),
          backgroundColor: ['#4285F4']
        }
      ]
    });
  }, [chartData]);
  useEffect(() => {
    if (pollsResult) {
      if (pollsResult.pollType === 3) {
        setChartData(pollsResult);
      } else {
        setResultData(pollsResult);
      }
    }
  }, [pollsResult]);
  const handleRefreshClick = (pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
  };
  return (
    <>
      <div>
        {isPollResultLoading ? (
          <Loader />
        ) : (
          <div className={`w-full`}>
            <div className="flex w-full justify-end mb-2">
              <i
                onClick={() => handleRefreshClick(pollsResult && pollsResult.pollId)}
                className="fa fa-refresh pull-left cursor-pointer"></i>
            </div>
            <div>
              {pollsResult && pollsResult.pollType === 3 ? (
                <div>
                  <div className="flex justify-between">
                    <div>
                      <h5 className="font-bold">1. {pollsResult.pollTitle}</h5>
                    </div>
                    <div className="badge-pollAttended flex justify-center">
                      {pollsResult?.quizzes?.pollAttended}
                    </div>
                  </div>
                  <p>Max Value : {pollsResult?.quizzes?.questions[0]?.choices[0]?.answerText}</p>
                  <BarChart
                    chartData={barChartData}
                    options={{
                      indexAxis: 'y',
                      responsive: true
                    }}
                  />
                </div>
              ) : (
                <>
                  <ResultComponent resultData={resultData} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ResultModal;
