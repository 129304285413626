import { AxiosResponse } from "axios";
import { ApiService } from "src/components/utilities/api.service";
import * as Constants from './constants'
import { MonetizationActions } from './actions';
import { all, put, takeEvery } from 'redux-saga/effects';

function* asyncGetDiscounts() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/monetization/discount');
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getDiscountPoliciesSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getDiscountPoliciesFailure(error));
    }
}

function* asyncGetDiscountDetails(data: MonetizationActions.getDiscountDetailsAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/discount/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getDiscountDetailsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getDiscountDetailsFailure());
    }
}

function* asyncAddDiscount(data: MonetizationActions.addDiscountPolicyRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/monetization/discount', data.payload);
        if (response.data.statusCode == 200) {
            yield put(MonetizationActions.addDiscountPolicyRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.addDiscountPolicyRequestFailure());
    }
}

function* asyncEditDiscount(data: MonetizationActions.editDiscountRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/discount/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.status === 200) {
            yield put(MonetizationActions.editDiscountRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.editDiscountRequestFailure());
    }
}

function* asyncDeleteDiscount(data: MonetizationActions.deleteDiscountRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/discount/${data.payload}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteDiscountRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteDiscountRequestFailure());
    }
}

function* asyncAddDiscountCode(data: MonetizationActions.addDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/monetization/discount/${data.payload?.discountId}/code/${data.payload?.code}`, data);
        if (response.status === 200) {
            yield put(MonetizationActions.addDiscountCodeRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.addDiscountCodeRequestFailure());
    }
}

function* asyncDeleteDiscountCode(data: MonetizationActions.deleteDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/discount/${data.payload?.discountId}/code/${data.payload?.id}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteDiscountCodeRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteDiscountCodeRequestFailure());
    }
}

function* asyncAddDiscountMemberList(data: MonetizationActions.addDiscountMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/monetization/discount/${data.payload?.entityId}/memberlist/${data.payload?.id}`, null);
        if (response.status === 200) {
            yield put(MonetizationActions.addDiscountMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.addDiscountMemberListRequestFailure());
    }
}

function* asyncDeleteDiscountMemberList(data: MonetizationActions.deleteDiscountMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/discount/${data.payload?.entityId}/memberlist/${data.payload?.id}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteDiscountMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteDiscountMemberListRequestFailure());
    }
}

//Packages
function* asyncGetPackages() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/monetization/package');
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getPackagesSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getPackagesFailure(error));
    }
}

function* asyncGetPackageDetails(data: MonetizationActions.getPackageDetailsAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/package/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getPackageDetailsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getPackageDetailsFailure());
    }
}

function* asyncAddPackage(data: MonetizationActions.addPackageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/monetization/package', data.payload);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.addPackageRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.addPackageRequestFailure());
    }
}

function* asyncEditPackage(data: MonetizationActions.editPackageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/package/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.editPackageRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.editPackageRequestFailure());
    }
}

function* asyncDeletePackage(data: MonetizationActions.deletePackageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/package/${data.payload}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deletePackageRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.deletePackageRequestFailure());
    }
}

function* asyncAddPackageMemberList(data: MonetizationActions.addPackageMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/monetization/package/${data.payload?.entityId}/memberlist/${data.payload?.id}`, null);
        if (response.status === 200) {
            yield put(MonetizationActions.addPackageMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.addPackageMemberListRequestFailure());
    }
}

function* asyncDeletePackageMemberList(data: MonetizationActions.deletePackageMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/package/${data.payload?.entityId}/memberlist/${data.payload?.id}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deletePackageMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.deletePackageMemberListRequestFailure());
    }
}

//Vouchers
function* asyncGetVouchers() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/monetization/voucher');
        if (response.data.statusCode === 200) {
            console.log(response.data);
            yield put(MonetizationActions.getVouchersSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getVouchersFailure(error));
    }
}

function* asyncGetVoucherDetails(data: MonetizationActions.getVoucherDetailsAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/voucher/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getVoucherDetailsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getVoucherDetailsFailure());
    }
}

function* asyncAddVoucher(data: MonetizationActions.addVoucherRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/monetization/voucher', data.payload);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.addVoucherRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.addVoucherRequestFailure());
    }
}

function* asyncEditVoucher(data: MonetizationActions.editVoucherRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/voucher/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.editVoucherRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.editVoucherRequestFailure());
    }
}

function* asyncDeleteVoucher(data: MonetizationActions.deleteVoucherRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/voucher/${data.payload}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteVoucherRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteVoucherRequestFailure());
    }
}

function* asyncEditVoucherActiveFlag(data: MonetizationActions.editVoucherStatusRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/voucher/${data.payload?.voucherId}/activeflag?isActive=${data.payload?.voucherStatus}`, null);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.editVoucherStatusRequestSuccess(response.data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.editVoucherStatusRequestFailure());
    }
}

function* asyncGetVoucherUsge(data: MonetizationActions.getVoucherUsageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/voucher/${data.payload}/usage`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getVoucherUsageSuccess(response.data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.getVoucherUsageFailure());
    }
}

//Bundles
function* asyncGetBundles() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/monetization/bundle');
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getBundlesSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getBundlesFailure(error));
    }
}

function* asyncGetBundleDetails(data: MonetizationActions.getBundleDetailsAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/bundle/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getBundleDetailsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getBundleDetailsFailure());
    }
}

function* asyncAddBundle(data: MonetizationActions.addBundleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/monetization/bundle', data.payload);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.addBundleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.addBundleRequestFailure());
    }
}

function* asyncEditBundle(data: MonetizationActions.editBundleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/bundle/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.editBundleRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.editBundleRequestFailure());
    }
}

function* asyncDeleteBundle(data: MonetizationActions.deletePackageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/bundle/${data.payload}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteBundleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteBundleRequestFailure());
    }
}

function* asyncAddBundleMemberList(data: MonetizationActions.addBundleMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/monetization/bundle/${data.payload?.entityId}/memberlist/${data.payload?.id}`, null);
        if (response.status === 200) {
            yield put(MonetizationActions.addBundleMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.addBundleMemberListRequestFailure());
    }
}

function* asyncDeleteBundleMemberList(data: MonetizationActions.deleteBundleMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/bundle/${data.payload?.entityId}/memberlist/${data.payload?.id}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteBundleMemberListRequestSuccess(data));
        }
    }
    catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteBundleMemberListRequestFailure());
    }
}

//Subscriptions
function* asyncGetSubscriptions() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/monetization/subscription');
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getSubscriptionsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getSubscriptionsFailure(error));
    }
}

function* asyncGetSubscriptionDetails(data: MonetizationActions.getSubscriptionDetailsAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/subscription/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getSubscriptionDetailsSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getSubscriptionDetailsFailure());
    }
}

function* asyncAddSubscription(data: MonetizationActions.addSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/monetization/subscription', data.payload);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.addSubscriptionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.addSubscriptionRequestFailure());
    }
}

function* asyncEditSubscription(data: MonetizationActions.editSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/monetization/subscription/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.editSubscriptionRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.editSubscriptionRequestFailure());
    }
}

function* asyncDeleteSubscription(data: MonetizationActions.deleteSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/monetization/subscription/${data.payload}`);
        if (response.status === 200) {
            yield put(MonetizationActions.deleteSubscriptionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.deleteSubscriptionRequestFailure());
    }
}

function* asyncGetSubscriptionSubscribers(data: MonetizationActions.getSubscriptionSubscribersAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/monetization/subscription/${data.payload}/subscribers`);
        if (response.data.statusCode === 200) {
            yield put(MonetizationActions.getSubscriptionSubscribersSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(MonetizationActions.getSubscriptionSubscribersFailure());
    }
}

export function* monetizationSaga() {
    yield all([
        takeEvery(Constants.getDiscountPoliciesActionType, asyncGetDiscounts),
        takeEvery(Constants.getDiscountDetailRequestActionType, asyncGetDiscountDetails),
        takeEvery(Constants.addDiscountPolicyRequestActionType, asyncAddDiscount),
        takeEvery(Constants.editDiscountPolicyRequestActionType, asyncEditDiscount),
        takeEvery(Constants.deleteDiscountPolicyRequestActionType, asyncDeleteDiscount),

        takeEvery(Constants.addDiscountCodeRequestActionType, asyncAddDiscountCode),
        takeEvery(Constants.deleteDiscountCodeRequestActionType, asyncDeleteDiscountCode),

        takeEvery(Constants.addDiscountMemberListRequestActionType, asyncAddDiscountMemberList),
        takeEvery(Constants.deleteDiscountMemberListRequestActionType, asyncDeleteDiscountMemberList),

        //Packages
        takeEvery(Constants.getPackagesActionType, asyncGetPackages),
        takeEvery(Constants.getPackageDetailsRequestActionType, asyncGetPackageDetails),
        takeEvery(Constants.addPackageRequestActionType, asyncAddPackage),
        takeEvery(Constants.editPackageRequestActionType, asyncEditPackage),
        takeEvery(Constants.deletePackageRequestActionType, asyncDeletePackage),

        takeEvery(Constants.addPackageMemberListRequestActionType, asyncAddPackageMemberList),
        takeEvery(Constants.deletePackageMemberListRequestActionType, asyncDeletePackageMemberList),

        //Vouchers
        takeEvery(Constants.getVouchersActionType, asyncGetVouchers),
        takeEvery(Constants.getVoucherDetailsRequestActionType, asyncGetVoucherDetails),
        takeEvery(Constants.addVoucherRequestActionType, asyncAddVoucher),
        takeEvery(Constants.editVoucherRequestActionType, asyncEditVoucher),
        takeEvery(Constants.deleteVoucherRequestActionType, asyncDeleteVoucher),

        takeEvery(Constants.updateVoucherStatusRequestActionType, asyncEditVoucherActiveFlag),
        takeEvery(Constants.getVoucherUsageRequestActionType, asyncGetVoucherUsge),

        //Bundles
        takeEvery(Constants.getBundlesActionType, asyncGetBundles),
        takeEvery(Constants.getBundleDetailsRequestActionType, asyncGetBundleDetails),
        takeEvery(Constants.addBundleRequestActionType, asyncAddBundle),
        takeEvery(Constants.editBundleRequestActionType, asyncEditBundle),
        takeEvery(Constants.deleteBundleRequestActionType, asyncDeleteBundle),

        takeEvery(Constants.addBundleMemberListRequestActionType, asyncAddBundleMemberList),
        takeEvery(Constants.deleteBundleMemberListRequestActionType, asyncDeleteBundleMemberList),
        //Subscriptions
        takeEvery(Constants.getSubscriptionsActionType, asyncGetSubscriptions),
        takeEvery(Constants.getSubscriptionDetailsRequestActionType, asyncGetSubscriptionDetails),
        takeEvery(Constants.addSubscriptionRequestActionType, asyncAddSubscription),
        takeEvery(Constants.editSubscriptionRequestActionType, asyncEditSubscription),
        takeEvery(Constants.deleteSubscriptionRequestActionType, asyncDeleteSubscription),

        takeEvery(Constants.getSubscriptionSubscribersRequestActionType, asyncGetSubscriptionSubscribers),
    ]);
}