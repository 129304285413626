import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../admin/dashboard';
import Footer from '../../common/footer';
import Header from '../../common/header';
import Members from '../../admin/members/member-listing';
import MemberDetails from '../../admin/members/member-details';
import 'tw-elements';
import Sidebar from '../sidebar';
import Videos from '../../admin/videos/videos';
import VideoViews from 'src/components/admin/videos/videoViews';
import AddVideo from 'src/components/admin/videos/addVideo';
import AnalyticsVideo from 'src/components/admin/analytics/videos/AnalyticsVideo';
import UploadVideo from 'src/components/admin/videos/uploadVideo';
import SpotlightRows from 'src/components/admin/videoLibrary/spotlightRows';
import HighlightReel from 'src/components/admin/videoLibrary/highlightReel';
import VideoLibrary from 'src/components/admin/videoLibrary/videoLibraryLayout';
import LibraryRows from 'src/components/admin/videoLibrary/libraryRows';
import NotFound from 'src/components/notFound';
import UnAuthorized from 'src/components/unAuthorized';
import AddLiveStream from 'src/components/admin/videos/addLiveStream';
import ProtectedRoutes from 'src/ProtectedRoutes';
import VideoInfoAnalytics from 'src/components/admin/videos/components/VideoInfoAnalytics';
import InteractivityCreatePoll from 'src/components/admin/videos/components/InteractivityCreatePoll';
import CallToAction from 'src/components/admin/videos/components/CallToAction';
import UploadSlides from 'src/components/admin/videos/components/UploadSlides';
import ManageSlides from 'src/components/admin/videos/components/ManageSlides';
import PrimaryVideoReplace from 'src/components/admin/videos/components/PrimaryVideoReplace';
import InteractivityModerator from 'src/components/admin/videos/components/InteractivityModerator';
import Discounts from 'src/components/admin/monetization/discounts';
import Packages from 'src/components/admin/monetization/packages';
import Vouchers from 'src/components/admin/monetization/vouchers';
import Bundles from 'src/components/admin/monetization/bundles';
import Subscriptions from 'src/components/admin/monetization/subscriptions';
import SubscriptionsSubscribers from 'src/components/admin/monetization/subscribers';
import Users from 'src/components/admin/users/user-listing';
import UserDetails from 'src/components/admin/users/user-details';
import ChannelLayout from 'src/components/admin/videoLibrary/channelLayout';
import VideoCategories from 'src/components/admin/videos/videoCategories';
import Registration from 'src/components/admin/videoLibrary/registration';
import RegistrationAceessList from 'src/components/admin/videoLibrary/registrationAceessList';
import AnalyticsMember from 'src/components/admin/analytics/member/AnalyticsMember';
import SalesOrders from 'src/components/admin/analytics/salesOrder/SalesOrder';
import AuthComponent from 'src/components/HOC/AuthComponent';
import Courses from 'src/components/admin/courses/course';
import Events from 'src/components/admin/events/event';
import AddCourse from 'src/components/admin/courses/addcourse';
import EditEvent from 'src/components/admin/events/editevent';
import EventRegisteredUsers from 'src/components/admin/events/registeredusers';
import MemberList from 'src/components/admin/memberList';
import MemberListDetails from 'src/components/admin/memberList/memberlist-details';
import LiveStreaming from 'src/components/admin/settings/liveStreaming';
import APIConfig from 'src/components/admin/settings/apiKey';
import Saml from 'src/components/admin/settings/saml';
import Ecommerce from 'src/components/admin/settings/ecommerce';
import VideoLog from 'src/components/admin/settings/logs/videoLog';
import AnalyticsLog from 'src/components/admin/settings/logs/Analytics';
import ChannelLog from 'src/components/admin/settings/logs/channelLog';
import Integrations from 'src/components/admin/settings/integrations';
import Logs from 'src/components/admin/settings/logs';
import ImisIntegration from 'src/components/admin/settings/integrations/imisIntegration';
import MemberEvolutionIntegration from 'src/components/admin/settings/integrations/memberevolution';
import WicketIntegration from 'src/components/admin/settings/integrations/wicket';
import Dynamics365Integration from 'src/components/admin/settings/integrations/dynamics365';
import MicrosoftAzureIntegration from 'src/components/admin/settings/integrations/microsoftazure';
import NoviAMSIntegration from 'src/components/admin/settings/integrations/noviams';
import OAuth2Integration from 'src/components/admin/settings/integrations/oauth2';
import MemberClicksIntegration from 'src/components/admin/settings/integrations/memberclicks';
import PersonifyIntegration from 'src/components/admin/settings/integrations/personifyIntegration';
import NaylorIntegration from 'src/components/admin/settings/integrations/naylorIntegration';
import SpargoIntegration from 'src/components/admin/settings/integrations/spargoIntegration';
import MembesIntegration from 'src/components/admin/settings/integrations/membesIntegration';
import GoogleIntegration from 'src/components/admin/settings/integrations/googleIntegration';
import WebCourseIntegration from 'src/components/admin/settings/integrations/webCourseIntegration';
import ZoomIntegration from 'src/components/admin/settings/integrations/zoomIntegration';
import PaymentProvider from 'src/components/admin/settings/paymentProviders';
import PayPalPayFlow from 'src/components/admin/settings/paymentProviders/paypalPayflowProvider';
import LiveEvent from 'src/components/admin/videos/LiveEvent';
import EzidebitProvider from 'src/components/admin/settings/paymentProviders/ezidebitProvider';
import StripeProvider from 'src/components/admin/settings/paymentProviders/stripeProvider';
import PayPalProvider from 'src/components/admin/settings/paymentProviders/paypalProvider';
import QuickBooksProvider from 'src/components/admin/settings/paymentProviders/quickBooksProvider';
import BPointProvider from 'src/components/admin/settings/paymentProviders/bPointProvider';
import LivePayProvider from 'src/components/admin/settings/paymentProviders/livePayProvider';
import SettingPoll from 'src/components/admin/settings/pollAndQuiz';
import CourseTab from 'src/components/admin/courses/courseTabs';
import AnalyticsCourse from 'src/components/admin/analytics/course';
import AnalyticsQuiz from 'src/components/admin/analytics/quiz';
import SingleAnalyticsCourse from 'src/components/admin/analytics/course/singleAnalyticCourse';
import PollPopout from 'src/components/admin/videos/components/PollPopOut';
import SingleQuizQuestion from 'src/components/admin/analytics/quiz/SingleQuizQuestion';
import AccessList from 'src/components/admin/videoLibrary/accessList';
import SingleQuiz from 'src/components/admin/analytics/quiz/SingleQuiz';
import Layout from 'src/components/admin/videoLibrary/layout';
import AnalyticPolls from 'src/components/admin/analytics/polls';
import SinglePoll from 'src/components/admin/analytics/polls/SinglePoll';
import SinglePollQuestion from 'src/components/admin/analytics/polls/SinglePollQuestion';
import SingleVideoAnalytics from 'src/components/admin/analytics/videos/SingleVideoAnalytics';
import HlsComponent from 'src/components/admin/videos/components/HlsComponent';
import UnauthorizedAccess from 'src/components/admin/unauthorizedAccess';
import ChangePassword from 'src/components/admin/users/components/ChangePassword';
import AddResource from 'src/components/admin/videos/addResource';
import AddRecording from 'src/components/admin/videos/addRecording';
import ChapterList from 'src/components/admin/courses/courseTabs/components/ChaptersList';
import CheckpointsList from 'src/components/admin/courses/courseTabs/components/CheckpointsList';
import Collections from 'src/components/admin/collections';
import CollectionVideos from 'src/components/admin/collections/CollectionVideos';
const MainLayoutRoutes = () => {
  //toggle class on hover for sidebar
  const [isSidebarHovered, setIsSidebarHovered] = useState(true);
  const handleSidebarHover = () => setIsSidebarHovered(!isSidebarHovered);
  //Toggle class for Pin and Unpin main parent div
  const [isPinned, setIsPinned] = useState(true);

  const handleSidebarPinned = () => {
    if (isPinned) {
      setIsPinned(false);
    } else {
      setIsPinned(true);
    }
  };

  useEffect(() => {
    if (location.pathname.includes('videos/live-event')) {
      setIsPinned(false);
    }
  }, [location.pathname]);

  //Toggle class for Mobile menu sidebar
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const handleMobileMenu = () => {
    if (mobileSidebar) {
      setMobileSidebar(false);
    } else {
      setMobileSidebar(true);
    }
  };

  //Back to top button when scroll
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const handleSettingsModal = () => {
    const isModalOpen = document.getElementsByClassName('tsettings-modal');
    if (isModalOpen.length) {
      document.body.classList.remove('tsettings-modal');
    } else {
      document.body.classList.add('tsettings-modal');
    }
  };

  const isModeratorPage = location.pathname.includes('/moderator');

  return (
    <div
      className={`${isPinned ? 'pin' : 'unpin'} ${mobileSidebar ? 'bgoverlay' : 'bg-without-overlay'
        } ${isSidebarHovered ? 'show' : 'hide'} w-full flex justify-center`}>
      <div className="overlay hidden">
        <img
          src="../../assets/img/cross.svg"
          className="w-6 h-6 ml-auto mr-3 my-3 cursor-pointer"
          onClick={handleMobileMenu}
        />
      </div>
      {!isModeratorPage && (
        <Sidebar
          isPinned={isPinned}
          handleSidebarPinned={handleSidebarPinned}
          handleSidebarHover={handleSidebarHover}
          isSidebarHovered={isSidebarHovered}
          handleMobileMenu={handleMobileMenu}
        />
      )}

      <div id="main" className="pt-5 pb-10 lg:px-8 md:px-8 px-4 mb-16 overflow-hidden">
        <Header handleMobileMenu={handleMobileMenu} />
        <div className="mt-16 pt-2">
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/" element={AuthComponent(<Dashboard />)} />
              <Route path="members/list" element={AuthComponent(<Members />)} />
              <Route path="users/user-listing" element={AuthComponent(<Users />)} />
              <Route path="/member/:id" element={AuthComponent(<MemberDetails />)} />
              <Route path="/user-details/:id" element={AuthComponent(<UserDetails />)} />
              <Route path="/change-password/:id" element={AuthComponent(<ChangePassword />)} />
              <Route path="/videos/:id" element={AuthComponent(<Videos />)} />
              <Route path="/collections" element={AuthComponent(<Collections />)} />
              <Route path="/collections/:id" element={AuthComponent(<CollectionVideos />)} />
              <Route path="/channels" element={AuthComponent(<VideoViews />)} />
              <Route path="/channels/:id" element={AuthComponent(<VideoViews />)} />
              <Route path="/channels/edit/:id" element={AuthComponent(<ChannelLayout />)} />
              <Route path="/add-video/:status" element={AuthComponent(<AddVideo />)} />
              <Route path="/record/:id" element={AuthComponent(<AddRecording />)} />
              <Route path="/add-resource" element={AuthComponent(<AddResource />)} />
              <Route path="/addcourse/edit/:id" element={AuthComponent(<AddCourse />)} />
              <Route path="/event/edit/:eventId" element={AuthComponent(<EditEvent />)} />
              <Route
                path="/event/registerusers/:eventId"
                element={AuthComponent(<EventRegisteredUsers />)}
              />
              <Route path="/addcourse/add" element={AuthComponent(<AddCourse />)} />
              <Route path="/upload-video" element={AuthComponent(<UploadVideo />)} />
              <Route path="/spotlight-rows" element={AuthComponent(<SpotlightRows />)} />
              <Route path="/highlight-reels" element={AuthComponent(<HighlightReel />)} />
              <Route path="/customize/:tab" element={AuthComponent(<VideoLibrary />)} />
              <Route path="/library-rows" element={AuthComponent(<LibraryRows />)} />
              <Route path="/monetization/discounts" element={AuthComponent(<Discounts />)} />
              <Route path="/monetization/packages" element={AuthComponent(<Packages />)} />
              <Route path="/monetization/vouchers" element={AuthComponent(<Vouchers />)} />
              <Route path="/monetization/bundles" element={AuthComponent(<Bundles />)} />
              <Route path="/courses/course" element={AuthComponent(<Courses />)} />
              <Route path="/events/event" element={AuthComponent(<Events />)} />
              <Route path="/member/memberList" element={AuthComponent(<MemberList />)} />
              <Route
                path="/member/memberList-details/:id"
                element={AuthComponent(<MemberListDetails />)}
              />
              <Route
                path="/monetization/subscriptions"
                element={AuthComponent(<Subscriptions />)}
              />
              <Route
                path="/monetization/subscribers/:id"
                element={AuthComponent(<SubscriptionsSubscribers />)}
              />
              <Route path="/addLiveStream" element={AuthComponent(<AddLiveStream />)} />
              <Route path="/*" element={AuthComponent(<NotFound />)} />
              <Route path="videos/analytics/:id" element={AuthComponent(<VideoInfoAnalytics />)} />
              <Route
                path="videos/create-poll/:id"
                element={AuthComponent(<InteractivityCreatePoll />)}
              />
              <Route path="videos/call-to-action/:id" element={AuthComponent(<CallToAction />)} />
              <Route path="videos/live-event/:id" element={AuthComponent(<LiveEvent />)} />
              <Route path="videos/upload-slides/:id" element={AuthComponent(<UploadSlides />)} />
              <Route path="videos/manage-slides/:id" element={AuthComponent(<ManageSlides />)} />
              <Route path="videos/replace/:id" element={AuthComponent(<PrimaryVideoReplace />)} />
              <Route
                path="videos/moderator/:id"
                element={AuthComponent(<InteractivityModerator />)}
              />
              <Route path="analytics/video" element={AuthComponent(<AnalyticsVideo />)} />
              <Route path="/analytics/members" element={AuthComponent(<AnalyticsMember />)} />
              <Route path="analytic/salesOrder" element={AuthComponent(<SalesOrders />)} />
              <Route path="/access" element={AuthComponent(<Registration />)} />
              <Route path="/api-config" element={AuthComponent(<APIConfig />)} />
              <Route path="/live-streaming" element={AuthComponent(<LiveStreaming />)} />
              <Route path="/saml" element={AuthComponent(<Saml />)} />
              <Route path="/ecommerce" element={AuthComponent(<Ecommerce />)} />
              <Route path="/videoLog" element={AuthComponent(<VideoLog />)} />
              <Route path="/analyticsLog" element={AuthComponent(<AnalyticsLog />)} />
              <Route path="/channelLog" element={AuthComponent(<ChannelLog />)} />
              <Route path="/log" element={AuthComponent(<Logs />)} />
              <Route path="/integrations" element={AuthComponent(<Integrations />)} />
              <Route path="/iMISIntegration" element={AuthComponent(<ImisIntegration />)} />
              <Route
                path="/MemberEvolutionIntegration"
                element={AuthComponent(<MemberEvolutionIntegration />)}
              />
              <Route path="/WicketIntegration" element={AuthComponent(<WicketIntegration />)} />
              <Route
                path="/Dynamics365Integration"
                element={AuthComponent(<Dynamics365Integration />)}
              />
              <Route
                path="/MicrosoftAzureIntegration"
                element={AuthComponent(<MicrosoftAzureIntegration />)}
              />
              <Route path="/NoviAMSIntegration" element={AuthComponent(<NoviAMSIntegration />)} />
              <Route path="/OAuth2Integration" element={AuthComponent(<OAuth2Integration />)} />
              <Route
                path="/MemberClicksIntegration"
                element={AuthComponent(<MemberClicksIntegration />)}
              />
              <Route path="/NaylorIntegration" element={AuthComponent(<NaylorIntegration />)} />
              <Route path="/SpargoIntegration" element={AuthComponent(<SpargoIntegration />)} />
              <Route path="/MembesIntegration" element={AuthComponent(<MembesIntegration />)} />
              <Route path="/google" element={AuthComponent(<GoogleIntegration />)} />
              <Route path="/ZoomIntegration" element={AuthComponent(<ZoomIntegration />)} />
              <Route path="/paymentProvider" element={AuthComponent(<PaymentProvider />)} />
              <Route path="/PaypalFlowProvider" element={AuthComponent(<PayPalPayFlow />)} />
              <Route path="/EzidebitProvider" element={AuthComponent(<EzidebitProvider />)} />
              <Route path="/StripeProvider" element={AuthComponent(<StripeProvider />)} />
              <Route path="/PayPalIntegration" element={AuthComponent(<PayPalProvider />)} />
              <Route path="/QuickBooksProvider" element={AuthComponent(<QuickBooksProvider />)} />
              <Route path="/BPointProvider" element={AuthComponent(<BPointProvider />)} />
              <Route path="/LivePay" element={AuthComponent(<LivePayProvider />)} />
              <Route path="/settings/education" element={AuthComponent(<SettingPoll />)} />
              <Route path="/courseTab" element={AuthComponent(<CourseTab />)} />
              <Route path="/courseTab/:id" element={AuthComponent(<CourseTab />)} />
              <Route path="/analytic/course" element={AuthComponent(<AnalyticsCourse />)} />
              <Route path="/analytic/quiz" element={AuthComponent(<AnalyticsQuiz />)} />
              <Route path="/accessList" element={AuthComponent(<AccessList />)} />
              <Route path="/quiz/singleQuiz/:id" element={AuthComponent(<SingleQuiz />)} />
              <Route path="/analytic/polls" element={AuthComponent(<AnalyticPolls />)} />
              <Route path="/analytic/singlePoll/:id" element={AuthComponent(<SinglePoll />)} />
              <Route path="/hls" element={AuthComponent(<HlsComponent />)} />
              <Route path="/unauthorizedAccess" element={AuthComponent(<UnauthorizedAccess />)} />
              <Route path="/chapterlist/:id" element={AuthComponent(<ChapterList />)} />
              <Route path="/checkpoint/:id" element={AuthComponent(<CheckpointsList />)} />
              <Route
                path="/singleVideoAnalytics/:videoId"
                element={AuthComponent(<SingleVideoAnalytics />)}
              />
              <Route
                path="/analytic/singlePollQuestion/:id"
                element={AuthComponent(<SinglePollQuestion />)}
              />
              <Route
                path="/analytic/question/:id"
                element={AuthComponent(<SingleQuizQuestion />)}
              />
              <Route
                path="/analytic/singleCourse/:id"
                element={AuthComponent(<SingleAnalyticsCourse />)}
              />
              <Route
                path="/WebCourseWorksIntegration"
                element={AuthComponent(<WebCourseIntegration />)}
              />
              <Route
                path="/PersonifyIntegration"
                element={AuthComponent(<PersonifyIntegration />)}
              />
              <Route
                path="/registrationAceessList"
                element={AuthComponent(<RegistrationAceessList />)}
              />
              <Route path="video-categories" element={AuthComponent(<VideoCategories />)} />
              <Route path="/Poll-Popout/:id" element={AuthComponent(<PollPopout />)} />
              <Route path="/layout/:tab" element={AuthComponent(<Layout />)} />
            </Route>
            <Route path="/unAuthorized" element={<UnAuthorized />} />
          </Routes>
        </div>
        <Footer />
        <div
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
          className="p-2 flex-items justify-center shadow-card rounded-lg h-8 w-8 btn-primary animate-bounce backTotop">
          <img src="../../assets/img/up-arrow.svg" />
        </div>
      </div>
    </div>
  );
};

export default MainLayoutRoutes;
