import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
import AttributeMapping from '../attributemapping/index';

const OAuth2Integration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { oauth2Integration, isIntegrationUpdated, message, error, providerId, mappingType } =
    useSelector((state: RootState) => {
      return {
        oauth2Integration: state.setting.oauth2Integration,
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        message: state.setting.message,
        error: state.setting.error,
        providerId: '16',
        mappingType: '2'
      };
    });

  const [showClientSecret, setShowClientSecret] = useState(false);

  useEffect(() => {
    if (oauth2Integration) {
      reset(oauth2Integration);
    }
  }, [oauth2Integration]);

  useEffect(() => {
    settingAction.getOAuth2IntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    if (oauth2Integration == undefined || oauth2Integration.id == undefined) {
      settingAction.addOAuth2IntegrationRequest(data);
    } else {
      const payloadData = {
        name: 'OAuth2',
        updateData: [
          { op: 'replace', path: 'tenantId', value: data.tenantId },
          { op: 'replace', path: 'authUrl', value: data.authUrl },
          { op: 'replace', path: 'tokenUrl', value: data.tokenUrl },
          { op: 'replace', path: 'clientId', value: data.clientId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'userInfoApi', value: data.userInfoApi },
          { op: 'replace', path: 'scope', value: data.scope },
          { op: 'replace', path: 'logoutUrl', value: data.logoutUrl }
        ]
      };
      settingAction.updateIntegrationRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> OAuth2 Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5 w-5/6">
        <div className="dashboard-card p-5 mt-2">
          <h4 className="text-lg">OAuth2 SSO Details</h4>

          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Tenant ID</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'tenantId'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>

          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">OAuth Authorization URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'authUrl'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">OAuth Token URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'tokenUrl'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Id</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'clientId'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Secret</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showClientSecret ? 'text' : 'password'}
                fieldName={'clientSecret'}
                register={register}
                errors={''}
                isRequired={true}
              />
              <i
                className={`fas ${
                  showClientSecret ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowClientSecret(!showClientSecret)}></i>
            </div>
          </div>

          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Scope</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'scope'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <h4 className="text-lg">API Details</h4>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">User Info URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'userInfoApi'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Logout URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'logoutUrl'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="dashboard-card p-5 mt-2">
            <div className="flex items-center">
              <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="dashboard-card p-5 w-5/6 mt-5">
        <AttributeMapping providerId={providerId} mappingType={mappingType} />
      </div>
    </div>
  );
};

export default OAuth2Integration;
