import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import moment from 'moment';
import { RootState } from 'src/store/reducers/state';
import './styles.scss';
import Loader from 'src/components/loader';
import { useParams } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { getVideoStatus, getVideoSource } from 'src/components/utilities/utils';
interface ScreenHeaderProps {
  isLiveLoading: boolean;
  setEndBroadcastFlag: (value: boolean) => void;
  setEndingTime: (value: number) => void;
  setShowHlsBroadcast: (value: boolean) => void;
  showHlsBroadcast: boolean;
  setEndHlsBroadcastFlag: (value: boolean) => void;
  endHlsBroadcastFlag: boolean;
  cancelClick: boolean;
  setCancelClick: (value: boolean) => void;
  endNowClick: boolean;
  setEndNowClick: (value: boolean) => void;
  setEndStreamingBroadcastFlag: (value: boolean) => void;
  endStreamingBroadcastFlag: boolean;
  endNowStreamingClick: boolean;
  setEndNowStreamingClick: (value: boolean) => void;
  cancelStreamingClick: boolean;
  setCancelStreamingClick: (value: boolean) => void;
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({
  isLiveLoading,
  setEndBroadcastFlag,
  setEndingTime,
  setShowHlsBroadcast,
  showHlsBroadcast,
  setEndHlsBroadcastFlag,
  endHlsBroadcastFlag,
  cancelClick,
  setCancelClick,
  endNowClick,
  setEndNowClick,
  setEndStreamingBroadcastFlag,
  endStreamingBroadcastFlag,
  endNowStreamingClick,
  setEndNowStreamingClick,
  cancelStreamingClick,
  setCancelStreamingClick
}) => {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);
  const streamingBtnRef = useRef<HTMLButtonElement>(null);
  const videoAction = useVideosActions(dispatch);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [joinClick, setJoinClick] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [streamingIntervalId, setStreamingIntervalId] = useState<any>(null);
  const [startTime, setStartTime] = useState<boolean>(false);
  const [endBroadcastTime, setEndBroadcastTime] = useState<number | string>();
  const [livemeetingRunTime, setLivemeetingRunTime] = useState<string>();
  const [disableBroadcastButton, setDisableBroadcastButton] = useState<boolean>(false);
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const localData = localStorage.getItem('userData');
  const userData = localData && JSON.parse(localData);
  let defaultDateFormat = localStorage.getItem('defaultDateFormat');
  if (!defaultDateFormat) {
    defaultDateFormat = 'DD/MM/YYYY';
  }
  const {
    singleVideo,
    isLoading,
    mediaChannelStatus,
    videoStatus,
    liveStudio,
    isLiveMeetingStarted,
    liveEventUrl,
    liveStartTime,
    liveViewers,
    liveViewersCount
  } = useSelector((state: RootState) => {
    return {
      singleVideo: state.videos.singleVideo,
      isVideoEdited: state.videos.isVideoEdited,
      isLoading: state.videos.isSingleVideoLoading,
      mediaChannelStatus: state.videos.mediaChannelStatus,
      videoStatus: state.videos.videoStatus,
      liveStudio: state.videos.liveStudio,
      isLiveMeetingStarted: state.videos.isLiveMeetingStarted,
      liveEventUrl: state.videos.liveEventUrl,
      liveStartTime: state.videos.liveStartTime,
      liveViewers: state.videos.liveViewers,
      liveViewersCount: state.videos.liveViewersCount
    };
  });

  const displayTime = moment(
    `${singleVideo.displayDate} ${singleVideo.displayTime}`,
    { defaultDateFormat } + ' hh:mm:ss'
  );

  useEffect(() => {
    const interval = setInterval(() => {
      displayRemainingTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const displayRemainingTime = () => {
    const currectTime = moment();
    const ms = moment(displayTime, { defaultDateFormat } + ' HH:mm:ss').diff(
      moment(currectTime, { defaultDateFormat } + ' HH:mm:ss')
    );
    const d = moment.duration(ms);
    Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  };

  const onClickEndBroadcast = () => {
    if (!disableBroadcastButton) {
      setEndStreamingBroadcastFlag(true);
      setEndBroadcastFlag(true);
      setDisableBroadcastButton(true);
      let time = 60;
      const interval = setInterval(() => {
        if (time >= 0) {
          setEndingTime(time);
          setEndBroadcastTime(time);
          time = time - 1;
          if (time === 0) {
            handleStopLiveClick();
            setEndStreamingBroadcastFlag(false);
          }
        }
      }, 1000);
      setStreamingIntervalId(interval);
      return () => clearInterval(interval);
    }
  };

  const onClickEndHlsBroadcast = () => {
    let time = 60;
    setEndHlsBroadcastFlag(true);
    const interval = setInterval(() => {
      if (time >= 0) {
        setEndingTime(time);
        setEndBroadcastTime(time);
        time = time - 1;
        if (time === 0) {
          endBroadcastHls();
          setEndHlsBroadcastFlag(false);
          sessionStorage.removeItem('broadcastStarted');
        }
        if (time === 5) {
          handleStopLiveClick();
        }
      }
    }, 1000);
    setIntervalId(interval);
    return () => clearInterval(interval);
  };

  const onCancelEndHlsBroadcast = () => {
    clearInterval(intervalId);
    setEndHlsBroadcastFlag(false);
    setCancelClick(false);
    setEndBroadcastTime('');
  };

  useEffect(() => {
    if (endNowClick) {
      setCancelClick(false);
      endBroadcastHls();
      setEndHlsBroadcastFlag(false);
      setEndNowClick(false);
      sessionStorage.removeItem('broadcastStarted');
    }
  }, [endNowClick]);

  useEffect(() => {
    if (endNowStreamingClick) {
      setCancelStreamingClick(false);
      setEndStreamingBroadcastFlag(false);
      setEndNowStreamingClick(false);
      handleStopLiveClick();
    }
  }, [endNowStreamingClick]);

  useEffect(() => {
    if (cancelClick) {
      onCancelEndHlsBroadcast();
    }
  }, [cancelClick]);
  useEffect(() => {
    if (cancelStreamingClick) {
      clearInterval(streamingIntervalId);
      setEndStreamingBroadcastFlag(false);
      setCancelStreamingClick(false);
      setEndBroadcastTime('');
      setDisableBroadcastButton(false);
    }
  }, [cancelStreamingClick]);
  const handlePrepareLiveClick = () => {
    id && videoAction.generateAuthKeyRequest(id);
    videoAction.startMediaChannelRequest({ VideoId: id, isLiveLoading });
  };

  const handleGoLiveClick = () => {
    videoAction.startMediaStreamRequest({ VideoId: id, isLiveLoading });
    id && videoAction.getSingleVideosRequest(id);
    setStartTime(true);
  };

  const handleGoLiveMeetingClick = () => {
    id && videoAction.startLiveMeetingRequest({ videoId: id });
    setStartTime(true);
    const timer = setTimeout(() => {
      id && videoAction.getSingleVideosRequest(id);
    }, 3000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (startTime) {
      const interval = setInterval(() => {
        stopWatch();
      }, 10);
      return () => clearInterval(interval);
    }
  }, [startTime]);

  useEffect(() => {
    if (isLiveMeetingStarted) {
      id && videoAction.getVideoStatusRequest(id);
      videoAction.resetVideoLibraryRequest();
    }
  }, [isLiveMeetingStarted]);

  const handleStopLiveClick = () => {
    videoAction.stopMediaStreamRequest({ VideoId: id, isLiveLoading });
  };

  useEffect(() => {
    if (
      getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' ||
      (!singleVideo?.useLowLatencyLiveMeeting &&
        getVideoSource(singleVideo?.videoSourceId) === 'Webcam')
    ) {
      if (mediaChannelStatus === 'RUNNING') {
        const interval = setInterval(() => {
          videoAction.mediaChannelStatusRequest({ videoId: id });
          id && videoAction.getVideoStatusRequest(id);
        }, 10000);
        return () => clearInterval(interval);
      } else {
        const interval = setInterval(() => {
          videoAction.mediaChannelStatusRequest({ videoId: id });
          id && videoAction.getVideoStatusRequest(id);
        }, 2000);
        return () => clearInterval(interval);
      }
    }
  }, [mediaChannelStatus, videoStatus, singleVideo]);

  useEffect(() => {
    if (getVideoSource(singleVideo.videoSourceId) === 'Webcam') {
      const interval = setInterval(() => {
        id && videoAction.getVideoStatusRequest(id);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [singleVideo, videoStatus]);

  useEffect(() => {
    id && videoAction.getSingleVideosRequest(id);
    id && videoAction.getVideoStatusRequest(id);
  }, []);

  const handleEndLiveMeetClick = () => {
    id && videoAction.endLiveMeetingRequest({ videoId: id });
    videoAction.resetEnterLiveStudioRequest();
    endLiveMeeting();
  };

  const handleEnterLiveStudioClick = () => {
    id && videoAction.enterLiveStudioRequest({ videoId: id });
  };

  const handleWebcamClick = () => {
    videoAction.startMediaChannelRequest({ VideoId: id, isLiveLoading });
  };

  useEffect(() => {
    if (
      getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
      singleVideo?.useLowLatencyLiveMeeting
    ) {
      if (
        getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
        getVideoStatus(videoStatus) === 'GreenRoom'
      ) {
        handleEnterLiveStudioClick();
      } else if (
        getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
        getVideoStatus(videoStatus) === 'Stopped'
      ) {
        handleEndLiveMeetClick();
      }
    }
  }, [videoStatus, singleVideo]);

  useEffect(() => {
    if (
      getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
      !singleVideo?.useLowLatencyLiveMeeting
    ) {
      if (
        getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
        getVideoStatus(videoStatus) === 'GreenRoom'
      ) {
        handleEnterLiveStudioClick();
      }
    }
  }, [singleVideo]);

  const handleEnterHlsBroadcast = () => {
    handleEnterLiveStudioClick();
    sessionStorage.removeItem('broadcastStarted');
  };

  const enterBroadcastHls = () => {
    setShowHlsBroadcast(true);
  };

  const endBroadcastHls = () => {
    let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
    setJoinClick(false);
    if (liveFrameObj) {
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(JSON.stringify({ action: 'endBroadcastClick' }), '*');
      handleStopLiveClick();
    }
  };

  useEffect(() => {
    window.addEventListener('message', function (e) {
      const jsonParse = JSON.parse(e.data);
      if (jsonParse.action == 'joinClick') {
        setJoinClick(true);
      }
    });
  }, []);

  let hour = 0;
  let minute = 0;
  let second = 0;
  let count = 0;
  function stopWatch() {
    count++;
    if (count == 100) {
      second++;
      count = 0;
    }
    if (second == 60) {
      minute++;
      second = 0;
    }
    if (minute == 60) {
      hour++;
      minute = 0;
      second = 0;
    }
    let hourString: any = hour;
    let minuteString: any = minute;
    let secondString: any = second;
    let countString: any = count;
    if (hour < 10) {
      hourString = '0' + hourString;
    }
    if (minute < 10) {
      minuteString = '0' + minuteString;
    }
    if (second < 10) {
      secondString = '0' + secondString;
    }

    if (count < 10) {
      countString = '0' + countString;
    }

    const timing = `${hourString}:${minuteString}:${secondString}`;
    setLivemeetingRunTime(timing);
  }

  const endLiveMeeting = () => {
    let liveFrameObj: any = document.querySelector('.roomContent iframe');
    if (liveFrameObj) {
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(JSON.stringify({ action: 'endlivemeeting' }), '*');
    }
  };

  useEffect(() => {
    if (liveStartTime) {
      setTimeRemaining('00:00:00');
      const intervalId = setInterval(() => {
        const currentTime = new Date();
        const apiTime = new Date(liveStartTime);
        if (apiTime) {
          const difference = currentTime.getTime() - apiTime.getTime();
          if (difference > 0) {
            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);

            setTimeRemaining(
              `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
                .toString()
                .padStart(2, '0')}`
            );
          }
        } else {
          setTimeRemaining('00:00:00');
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [liveStartTime]);

  useEffect(() => {
    if (endHlsBroadcastFlag) {
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [endHlsBroadcastFlag]);

  useEffect(() => {
    if (endStreamingBroadcastFlag) {
      if (streamingBtnRef.current) {
        streamingBtnRef.current.click();
      }
    }
  }, [endStreamingBroadcastFlag]);

  const startBroadcastHls = () => {
    setLoading(true);
    let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
    if (liveFrameObj) {
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(
        JSON.stringify({ action: 'startBroadcastClick', liveEventUrl: liveEventUrl }),
        '*'
      );
    }
    setStartTime(true);
  };
  useEffect(() => {
    const handleMessage = (event: any) => {
      const { type, data } = event.data;
      if (type === 'BROADCAST_STATUS') {
        videoAction.startMediaStreamRequest({ VideoId: id });
        id && videoAction.updateVideoStatusRequest({ videoId: id, status: 1 });
        setLoading(false);
        sessionStorage.removeItem('showdropdown');
        sessionStorage.setItem('broadcastStarted', 'true');
      }
    };

    // Add event listener for messages from the child iframe
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('message', handleMessage);
  }, []);
  return (
    <div className="box-header">
      <div className=" header ibox-content bg-white border">
        <div className="lg:flex justify-between px-5 items-center py-4">
          <div className="flex">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <h3 className="mb-1 d-inline-block text-base text-black font-semibold">
                  {singleVideo?.title}
                </h3>
                <div className="flex px-5 items-center">
                  <span
                    onClick={() => window.open(`/videos/${id}`, '_blank')}
                    className="cursor-pointer">
                    <i className="fa fa-pencil text-xs px-1"></i>Edit
                  </span>
                  <span className="d-none d-sm-inline-block text-xs text-black">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{singleVideo?.displayDate} &nbsp;&nbsp;
                  </span>
                  <span className="text-black  text-xs"> {displayTime.format('hh:mm:ss A')} </span>
                </div>
              </>
            )}
          </div>
          <button
            ref={btnRef}
            type="button"
            className="btn-primary w-auto ml-0 mr-3 px-5 hidden"
            data-bs-toggle="modal"
            data-bs-target="#broadcastModal">
            Edit
          </button>
          <button
            ref={streamingBtnRef}
            type="button"
            className="btn-primary w-auto ml-0 mr-3 px-5 hidden"
            data-bs-toggle="modal"
            data-bs-target="#streamingBroadcastModal">
            Edit
          </button>
          <div className="">
            {mediaChannelStatus === 'IDLE' &&
              getVideoStatus(videoStatus) === 'Standby' &&
              getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' && (
                <div className="btn-group option-menu-div-live-stream pull-right">
                  <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']} // preferred positions by priority
                    content={
                      <div>
                        {' '}
                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            zIndex: '1036',
                            marginRight: '50px',
                            marginTop: '5px',
                            border: '1px solid black'
                          }}>
                          <li>
                            <button
                              className="text-sm py-2 px-4 block w-full whitespace-nowrap bg-gray-100 standby-live-stream text-left"
                              type="button"
                              onClick={handlePrepareLiveClick}>
                              <h3 className="text-blue-600 font-bold">
                                Use RTMP Broadcast Software
                              </h3>
                              <i className="font-weight-400">
                                Use a 3rd party RTMP Live Streaming client like OBS,
                                <br />
                                Zoom Meeting with RTMP & more
                              </i>
                              <li>
                                <hr className="m-2" />
                              </li>
                            </button>
                          </li>
                        </ul>
                      </div>
                    }>
                    {!singleVideo?.useLowLatencyLiveMeeting ? (
                      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                        <button
                          type="button"
                          className=" dropdown-toggle  m-0 option-menu-live-stream"
                          data-mdb-toggle="dropdown"
                          aria-expanded="false">
                          Prepare To Go Live
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Popover>
                </div>
              )}
            {getVideoStatus(videoStatus) === 'Standby' &&
              getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              !liveStudio.status &&
              singleVideo.useLowLatencyLiveMeeting && (
                <div className="btn-group option-menu-div-live-stream pull-right">
                  {singleVideo?.useLowLatencyLiveMeeting ? (
                    <div onClick={handleEnterLiveStudioClick}>
                      <button
                        type="button"
                        className=" dropdown-toggle  m-0 option-menu-live-stream">
                        Enter Green Room
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}

            {mediaChannelStatus === 'IDLE' &&
              getVideoStatus(videoStatus) === 'Standby' &&
              getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              !singleVideo.useLowLatencyLiveMeeting &&
              !showHlsBroadcast && (
                <div className="btn-group option-menu-div-live-stream pull-right">
                  <Popover
                    isOpen={isPopoverOpen}
                    positions={['bottom']} // preferred positions by priority
                    content={
                      <div>
                        {' '}
                        <ul
                          className="dropdown-menu dropdown-menu-end"
                          style={{
                            zIndex: '1036',
                            marginRight: '50px',
                            marginTop: '5px',
                            border: '1px solid black'
                          }}>
                          <li>
                            <button
                              className="text-sm py-2 px-4 block w-full whitespace-nowrap bg-gray-100 standby-live-stream text-left"
                              type="button"
                              onClick={handleWebcamClick}>
                              <h3 className="text-blue-600 font-bold">Use Webcam</h3>
                              <i className="font-weight-400">
                                Use the webcastcloud live studio with your webcam to stream live
                              </i>
                              <li>
                                <hr className="m-2" />
                              </li>
                            </button>
                          </li>
                        </ul>
                      </div>
                    }>
                    {!singleVideo?.useLowLatencyLiveMeeting && userData?.role != 'Studio Guest' ? (
                      <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                        <button
                          type="button"
                          className=" dropdown-toggle  m-0 option-menu-live-stream"
                          data-mdb-toggle="dropdown"
                          aria-expanded="false">
                          Prepare To Go Live
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Popover>
                </div>
              )}

            {!singleVideo?.useLowLatencyLiveMeeting &&
              mediaChannelStatus === 'STARTING' &&
              getVideoStatus(videoStatus) === 'Standby' && (
                <button className="btn m-0 prepare-live-stream pull-right">
                  Preparing To Go Live
                </button>
              )}
            {!singleVideo?.useLowLatencyLiveMeeting &&
              getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' &&
              mediaChannelStatus === 'RUNNING' &&
              getVideoStatus(videoStatus) === 'Standby' && (
                <a className="btn start-live-stream pull-right" onClick={handleGoLiveClick}>
                  Go Live <span id="count" className="d-none"></span>
                </a>
              )}
            {!singleVideo?.useLowLatencyLiveMeeting &&
              singleVideo?.videoSourceId === 1 &&
              mediaChannelStatus === 'RUNNING' &&
              getVideoStatus(videoStatus) === 'Standby' && (
                <a
                  className="btn start-live-stream pull-right green-background"
                  onClick={handleEnterHlsBroadcast}>
                  Enter Green Room <span id="count" className="d-none"></span>
                </a>
              )}

            {singleVideo?.useLowLatencyLiveMeeting &&
              (getVideoStatus(videoStatus) === 'GreenRoom' ||
                getVideoStatus(videoStatus) === 'Standby') &&
              liveStudio.status &&
              userData?.role != 'Studio Guest' && (
                <a className="btn start-live-stream pull-right" onClick={handleGoLiveMeetingClick}>
                  Go Live <span id="count" className="d-none"></span>
                </a>
              )}
            {!singleVideo?.useLowLatencyLiveMeeting &&
              getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' &&
              mediaChannelStatus === 'RUNNING' &&
              getVideoStatus(videoStatus) == 'Live' && (
                <div className="flex items-center gap-3">
                  <h3
                    id="liveCountViewHeader"
                    className="live-count pull-right d-none"
                    data-mdb-toggle="tooltip"
                    title="Live viewers count updated every 30 seconds.">
                    &nbsp;&nbsp; Live <i className="fa fa-users"></i>{' '}
                    <span id="liveUserCountHeaderTxt">{liveViewersCount}</span> &nbsp;&nbsp;
                  </h3>
                  <span className="font-extrabold mr-4">{timeRemaining}</span>
                  <a
                    className="btn start-live-stream"
                    onClick={() => {
                      onClickEndBroadcast();
                    }}>
                    End Broadcast {endBroadcastTime} <span id="endcount"></span>
                  </a>
                </div>
              )}
            {!singleVideo?.useLowLatencyLiveMeeting &&
              getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              mediaChannelStatus === 'RUNNING' &&
              getVideoStatus(videoStatus) == 'GreenRoom' &&
              joinClick &&
              userData?.role != 'Studio Guest' && (
                <div className="flex items-center gap-3">
                  {sessionStorage.getItem('broadcastStarted') !== 'true' && (
                    <a
                      className="btn start-live-stream"
                      onClick={() => {
                        sessionStorage.setItem('showdropdown', 'true');
                      }}>
                      Go Live
                    </a>
                  )}
                </div>
              )}

            {sessionStorage.getItem('showdropdown') === 'true' && (
              <div className="absolute right-0 mt-2 w-96 bg-white shadow-lg rounded-md z-10">
                <p className="p-2 text-gray-700 font-bold text-center text-base">
                  Ready to go live?
                </p>
                <div className="flex justify-center mb-2">
                  {loading ? (
                    <Loader />
                  ) : (
                    <button
                      className="btn start-live-stream"
                      onClick={() => {
                        startBroadcastHls();
                      }}>
                      Go Live
                    </button>
                  )}

                  <button
                    onClick={() => sessionStorage.removeItem('showdropdown')}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 ml-2">
                    Cancel
                  </button>
                </div>
              </div>
            )}

            {!singleVideo?.useLowLatencyLiveMeeting &&
              getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              mediaChannelStatus === 'RUNNING' &&
              getVideoStatus(videoStatus) == 'Live' &&
              userData?.role != 'Studio Guest' && (
                <div className="flex items-center gap-3">
                  <h3
                    id="liveCountViewHeader"
                    className="live-count pull-right d-none"
                    data-mdb-toggle="tooltip"
                    title="Live viewers count updated every 30 seconds.">
                    &nbsp;&nbsp; Live <i className="fa fa-users"></i>{' '}
                    <span id="liveUserCountHeaderTxt">{liveViewersCount}</span> &nbsp;&nbsp;
                  </h3>
                  <span className="font-extrabold mr-4">{timeRemaining}</span>
                  {sessionStorage.getItem('broadcastStarted') === 'true' && (
                    <a
                      className="btn start-live-stream"
                      onClick={() => {
                        onClickEndHlsBroadcast();
                      }}>
                      End Broadcast {endBroadcastTime} <span id="endcount"></span>
                    </a>
                  )}
                </div>
              )}

            {singleVideo?.useLowLatencyLiveMeeting &&
              getVideoStatus(videoStatus) === 'Live' &&
              userData?.role != 'Studio Guest' && (
                <>
                  <div className="flex items-center">
                    <span className="font-extrabold mr-4">{timeRemaining}</span>
                    <a
                      className="btn start-live-stream pull-right"
                      onClick={handleEndLiveMeetClick}>
                      End Broadcast <span id="count" className="d-none"></span>
                    </a>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScreenHeader;
