import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
interface IProps {
  registrationId: string;
}
const DeleteModal: React.FC<IProps> = ({ registrationId }) => {
  const dispatch = useDispatch();
  const videoLibraryActions = useVideoLibraryActions(dispatch);

  const handleDeleteClick = () => {
    videoLibraryActions.deleteRegistrationAccessListRequest(registrationId);
  };
  return (
    <div>
      <div className="modal fade" id="deleteModal" aria-labelledby="deleteModal" aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <i className="fa fa-trash-o  modal-icon"></i>
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="deleteModal">
                Delete Email
              </h5>

              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">
                    Are you sure you want to delete this live video?
                  </label>
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    onClick={() => handleDeleteClick()}
                    type="button"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
