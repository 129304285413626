import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { CategoryModel } from 'src/models/categoryModel';
import Loader from 'src/components/loader';
interface IProps {
  contentTypeAddFlag: string;
}
const AddNewAudienceProfile: React.FC<IProps> = ({ contentTypeAddFlag }) => {
  const dispatch = useDispatch();
  const categoryActions = useCategoryActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();
  const { singleAudienceProfile, isAudienceProfilesSaved, isContentTypeSavedLoading } = useSelector(
    (state: RootState) => {
      return {
        singleAudienceProfile: state.category.singleAudienceProfile,
        isAudienceProfilesSaved: state.category.isAudienceProfilesSaved,
        isContentTypeSavedLoading: state.category.isContentTypeSavedLoading
      };
    }
  );
  const onSubmit = (data: any) => {
    if (contentTypeAddFlag === 'Add') {
      categoryActions.addAudienceProfilesRequest(data);
    } else {
      const payloadData = {
        id: data.id,
        updateData: [
          { op: 'replace', path: 'profileName', value: data.profileName },
          { op: 'replace', path: 'description', value: data.description }
        ]
      };
      categoryActions.updateAudienceProfilesRequest(payloadData);
    }
  };

  useEffect(() => {
    if (isAudienceProfilesSaved) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isAudienceProfilesSaved]);

  useEffect(() => {
    if (singleAudienceProfile) {
      reset(singleAudienceProfile);
    }
  }, [singleAudienceProfile]);
  const handleCloseClick = () => {
    reset({
      profileName: '',
      description: ''
    });
  };
  return (
    <div>
      <div
        className="modal fade"
        id="AddNewAudienceProfile"
        aria-labelledby="AddNewTagLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddNewTagLabel">
                {contentTypeAddFlag === 'Add' ? 'Add' : 'Update'} Audience Profile
              </h5>
              {isContentTypeSavedLoading && <Loader />}
              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Profile Name'}
                    type={'text'}
                    fieldName={'profileName'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Description'}
                    type={'text'}
                    fieldName={'description'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  />
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAudienceProfile;
