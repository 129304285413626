import { AppearanceActions } from "./actions";
import { RootState } from "../reducers/state";
import { PlayPageSettingModel, AppearanceStateModel, ChatStyleModel, ColorModel, FontModel, PlayPageNavigationModel, PlayPageVideoModel, SearchSettingModel, SingleNavigation, UserProfileModel, VideoCardModel, AdvanceSettingModal, FilterSettingModal } from "src/models/appearanceModel";
import { Actions } from "src/models/appearanceModel";
import * as Constants from './constants'
import { SiteIdentityModel } from "src/models/appearanceModel";
const initialState: RootState.AppearanceModelState = {
    siteIdentity: {} as SiteIdentityModel,
    colors: {} as ColorModel,
    fonts: {} as FontModel,
    userProfile: {} as UserProfileModel,
    videoCard: {} as VideoCardModel,
    playPageVideo: {} as PlayPageVideoModel,
    playPageNavigation: {} as PlayPageNavigationModel,
    chatStyle: {} as ChatStyleModel,
    singleNavigations: {} as SingleNavigation,
    searchSetting: {} as SearchSettingModel,
    playPageSetting: {} as PlayPageSettingModel,
    advanceSetting: {} as AdvanceSettingModal,
    filterSetting: {} as FilterSettingModal,
    navigations: [],
    message: '',
    isAppearanceUpdated: false,
    isNavigationAdded: false,
    isNavigationUpdated: false,
    isNavigationDeleted: false,
    isChatStyleUpdated: false,
    success: false,
    error: false,
    loading: false,
};


function appearanceReducer(state: AppearanceStateModel = initialState, action: Actions): AppearanceStateModel {
    switch (action.type) {
        case Constants.getSiteIdentityRequestActionType: return getSiteIdentityAction(state);
        case Constants.getSiteIdentityRequestSuccessActionType: return getSiteIdentitySuccessAction(state, action);
        case Constants.getSiteIdentityRequestFailureActionType: return getSiteIdentityFailureAction(state);
        case Constants.updateAppearanceRequestActionType: return updateAppearanceAction(state);
        case Constants.updateAppearanceRequestSuccessActionType: return updateAppearanceSuccessAction(state);
        case Constants.updateAppearanceRequestFailureActionType: return updateAppearanceFailureAction(state);
        case Constants.getColorsRequestActionType: return getColorsAction(state);
        case Constants.getColorsRequestSuccessActionType: return getColorsSuccessAction(state, action);
        case Constants.getColorsRequestFailureActionType: return getColorsFailureAction(state);
        case Constants.getFontsRequestActionType: return getFontsAction(state);
        case Constants.getFontsRequestSuccessActionType: return getFontsSuccessAction(state, action);
        case Constants.getFontsRequestFailureActionType: return getFontsFailureAction(state);
        case Constants.getUserProfileRequestActionType: return getUserProfileAction(state);
        case Constants.getUserProfileRequestSuccessActionType: return getUserProfileSuccessAction(state, action);
        case Constants.getUserProfileRequestFailureActionType: return getUserProfileFailureAction(state);
        case Constants.getVideoCardRequestActionType: return getVideoCardAction(state);
        case Constants.getVideoCardRequestSuccessActionType: return getVideoCardSuccessAction(state, action);
        case Constants.getVideoCardRequestFailureActionType: return getVideoCardFailureAction(state);
        case Constants.resetAppearanceStateRequestActionType: return resetAppearanceStateAction(state);

        case Constants.getPlayPageVideoRequestActionType: return getPlayPageVideoAction(state);
        case Constants.getPlayPageVideoRequestSuccessActionType: return getPlayPageVideoSuccessAction(state, action);
        case Constants.getPlayPageVideoRequestFailureActionType: return getPlayPageVideoFailureAction(state);

        case Constants.getPlayPageNavigationRequestActionType: return getPlayPageNavigationAction(state);
        case Constants.getPlayPageNavigationRequestSuccessActionType: return getPlayPageNavigationSuccessAction(state, action);
        case Constants.getPlayPageNavigationRequestFailureActionType: return getPlayPageNavigationFailureAction(state);

        case Constants.getChatStyleRequestActionType: return getChatStyleAction(state);
        case Constants.getChatStyleRequestSuccessActionType: return getChatStyleSuccessAction(state, action);
        case Constants.getChatStyleRequestFailureActionType: return getChatStyleFailureAction(state);

        case Constants.updateChatStyleRequestActionType: return updateChatStyleAction(state);
        case Constants.updateChatStyleRequestSuccessActionType: return updateChatStyleSuccessAction(state);
        case Constants.updateChatStyleRequestFailureActionType: return updateChatStyleFailureAction(state);

        case Constants.getNavigationRequestActionType: return getNavigationAction(state);
        case Constants.getNavigationRequestSuccessActionType: return getNavigationSuccessAction(state, action);
        case Constants.getNavigationRequestFailureActionType: return getNavigationFailureAction(state);

        case Constants.saveNavigationRequestActionType: return saveNavigationAction(state);
        case Constants.saveNavigationRequestSuccessActionType: return saveNavigationSuccessAction(state);
        case Constants.saveNavigationRequestFailureActionType: return saveNavigationFailureAction(state);

        case Constants.getSingleNavigationRequestActionType: return getSingleNavigationAction(state);
        case Constants.getSingleNavigationRequestSuccessActionType: return getSingleNavigationSuccessAction(state, action);
        case Constants.getSingleNavigationRequestFailureActionType: return getSingleNavigationFailureAction(state);

        case Constants.updateNavigationRequestActionType: return updateNavigationAction(state);
        case Constants.updateNavigationRequestSuccessActionType: return updateNavigationSuccessAction(state);
        case Constants.updateNavigationRequestFailureActionType: return updateNavigationFailureAction(state);

        case Constants.deleteNavigationRequestActionType: return deleteNavigationAction(state);
        case Constants.deleteNavigationRequestSuccessActionType: return deleteNavigationSuccessAction(state, action);
        case Constants.deleteNavigationRequestFailureActionType: return deleteNavigationFailureAction(state);

        case Constants.getSearchSettingRequestActionType: return getSearchSettingAction(state);
        case Constants.getSearchSettingRequestSuccessActionType: return getSearchSettingSuccessAction(state, action);
        case Constants.getSearchSettingRequestFailureActionType: return getSearchSettingFailureAction(state);

        case Constants.getPlayPageSettingRequestActionType: return getPlayPageSettingAction(state);
        case Constants.getPlayPageSettingRequestSuccessActionType: return getPlayPageSettingSuccessAction(state, action);
        case Constants.getPlayPageSettingRequestFailureActionType: return getPlayPageSettingFailureAction(state);

        case Constants.getAdvanceSettingRequestActionType: return getAdvanceSettingAction(state);
        case Constants.getAdvanceSettingRequestSuccessActionType: return getAdvanceSettingSuccessAction(state, action);
        case Constants.getAdvanceSettingRequestFailureActionType: return getAdvanceSettingFailureAction(state);

        case Constants.getFilterSettingRequestActionType: return getFilterSettingAction(state);
        case Constants.getFilterSettingRequestSuccessActionType: return getFilterSettingSuccessAction(state, action);
        case Constants.getFilterSettingRequestFailureActionType: return getFilterSettingFailureAction(state);

        default: return state;
    }

    function getSiteIdentityAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSiteIdentitySuccessAction(state: AppearanceStateModel, action: AppearanceActions.getSiteIdentityRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                siteIdentity: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSiteIdentityFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateAppearanceAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateAppearanceSuccessAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isAppearanceUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateAppearanceFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isAppearanceUpdated: false,
            loading: false
        }
    }
    function getColorsAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getColorsSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getColorsRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                colors: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getColorsFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getFontsAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getFontsSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getFontsRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                fonts: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getFontsFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getUserProfileAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserProfileSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getUserProfileRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                userProfile: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getUserProfileFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getVideoCardAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoCardSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getVideoCardRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                videoCard: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoCardFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function resetAppearanceStateAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isAppearanceUpdated: false,
            isNavigationAdded: false,
            isNavigationDeleted: false,
            isChatStyleUpdated: false
        }
    }

    function getPlayPageVideoAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }

    function getPlayPageVideoSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getPlayPageVideoRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                playPageVideo: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPlayPageVideoFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function getPlayPageNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }

    function getPlayPageNavigationSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getPlayPageNavigationRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                playPageNavigation: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPlayPageNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function getChatStyleAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getChatStyleSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getChatStyleRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                chatStyle: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getChatStyleFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateChatStyleAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateChatStyleSuccessAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isChatStyleUpdated: true,
            loading: false
        }
    }
    function updateChatStyleFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isChatStyleUpdated: false,
            loading: false
        }
    }
    function getNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getNavigationSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getNavigationRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                navigations: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }


    function saveNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationAdded: false,
            loading: true,
        };
    }
    function saveNavigationSuccessAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationAdded: true,
            message: 'Success',
            loading: false
        };
    }
    function saveNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationAdded: false,
            loading: false
        }
    }

    function getSingleNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleNavigationSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getSingleNavigationRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                singleNavigations: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function updateNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationUpdated: false,
            loading: true,
        };
    }
    function updateNavigationSuccessAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationUpdated: false,
            loading: false
        }
    }

    function deleteNavigationAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteNavigationSuccessAction(state: AppearanceStateModel, action: AppearanceActions.deleteNavigationRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                isNavigationDeleted: true,
                message: action.payload,
                loading: false
            };
        }
        return state;
    }
    function deleteNavigationFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            isNavigationDeleted: false,
            loading: false
        }
    }
    function getSearchSettingAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSearchSettingSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getSearchSettingRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                searchSetting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSearchSettingFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getPlayPageSettingAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPlayPageSettingSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getPlayPageSettingRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                playPageSetting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPlayPageSettingFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAdvanceSettingAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAdvanceSettingSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getAdvanceSettingRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                advanceSetting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getAdvanceSettingFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getFilterSettingAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getFilterSettingSuccessAction(state: AppearanceStateModel, action: AppearanceActions.getFilterSettingRequestSuccessAction): AppearanceStateModel {
        if (action.payload) {
            return {
                ...state,
                filterSetting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getFilterSettingFailureAction(state: AppearanceStateModel): AppearanceStateModel {
        return {
            ...state,
            loading: false
        }
    }
}

export default appearanceReducer
