import React, { useEffect, useState } from 'react';
import '../styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Modal from '../components/Modal';
import { useUserActions } from 'src/store/users/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { errorMsg, getItem, successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { UserInfoModel, UsersModel } from 'src/models/usersModel';
import { useForm } from 'react-hook-form';

type locationState = {
  userId: string;
};
const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const location = useLocation();
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const { userId } = (location.state as locationState) || {};
  const { userDetails, roles } = useSelector((state: RootState) => {
    return {
      userDetails: state.users.userDetails,
      roles: state.users.roles
    };
  });
  const { forgetRestPassword, success, passwordChangeObj } = useSelector((state: RootState) => {
    return {
      forgetRestPassword: state.users.userDetails,
      success: state.users.isPasswordChanged,
      passwordChangeObj: state.users.passwordChangedObj
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<UsersModel>();
  const [passState, setPassState] = useState('');
  const [passCheck, setPassCheck] = useState<string>('null');
  const handlePassChange = (e: any) => {
    setPassState(e.target.value);
  };

  const [userInfoDetails, setUserInfoDetails] = useState<UserInfoModel | null>(null);
  const [value, setValue] = useState(userDetails?.active);

  useEffect(() => {
    if (id) {
      userAction.getUserDetailsRequest(id);
    }
  }, [userDetails?.userId]);

  useEffect(() => {
    setValue(!userDetails?.active);
  }, [userDetails?.active]);

  useEffect(() => {
    setUserInfoDetails(JSON.parse(getItem('userData')));
  }, []);

  useEffect(() => {
    if (Object.keys(passwordChangeObj).length > 0) {
      if (success) {
        successMsg('Password changed successfully');
        userAction.reset();
        navigate(`/user-details/${id}`);
      } else {
        errorMsg('Error while chaning the password.');
        userAction.reset();
      }
    }
  }, [passwordChangeObj, success]);

  const handleConfirmPassChange = (e: any) => {
    e.target.value === passState ? setPassCheck('true') : setPassCheck('false');
  };

  const onSubmit = (data: UsersModel) => {
    if (passCheck === 'true') {
      const userData = { ...data };
      userAction.changePasswordRequest({
        ...userData,
        userId: id ?? '',
        passwordHash: passState
      });
    }
  };

  return (
    <>
      <ToastMessage />
      <div className="member-details mt-4">
        <div className="lg:mr-20 mt-2">
          {userDetails && (
            <div className="dashboard-card">
              <div className="mt-5">
                <h2 className="font-semibold border-b">Change Password </h2>
                <div className="w-1/3">
                  <ul>
                    <li className="my-3">
                      <label className="font-semibold">Email: </label>
                      {userDetails.email}
                    </li>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div>
                        <div className="mb-4">
                          <label className="mb-1 block">Password</label>
                          <input
                            type="password"
                            className="input-style"
                            id="passwordHash"
                            {...register('passwordHash', {
                              required: true,
                              maxLength: 80
                            })}
                            onChange={handlePassChange}
                          />
                          {errors.passwordHash?.type === 'required' && <p>Required Field</p>}
                          {errors.passwordHash?.type === 'maxLength' && <p>Max. length reached</p>}
                        </div>
                        <div className="mb-4">
                          <label className="mb-1 block">Confirm Password</label>
                          <input
                            type="password"
                            className="input-style"
                            id="confirmPasswordHash"
                            onChange={handleConfirmPassChange}
                          />
                          {passCheck !== 'null' && passCheck !== 'true' && (
                            <p>Password does not match</p>
                          )}
                        </div>
                      </div>

                      <div className="flex items-center justify-end text-center mb-4 mt-6">
                        <button type="submit" className="btn-primary w-auto px-5 ml-0 mr-3">
                          Save
                        </button>
                        <button type="submit" className="btn-primary w-auto mx-0 suspend-btn">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Edit Modal */}
        <Modal userDetails={userDetails} modalFlag="edit" />
      </div>
    </>
  );
};

export default ChangePassword;
