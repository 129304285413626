import React, { useState, useEffect, useRef } from 'react';
import '../../styles.scss';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useChannelActions } from 'src/store/channel/actions';
import Editor from 'src/components/Editor';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
interface IProps {
  id: string;
}
const ChannelStylesTab: React.FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const ChannelInputFile = useRef<HTMLInputElement>(null);
  const HeaderbackgroundInput = useRef<HTMLInputElement>(null);
  const bodyBackgroundInputFile = useRef<HTMLInputElement>(null);
  const [channelTitleFontSize, setChannelTitleFontSize] = useState(0);
  const [channelImageWidth, setChannelImageWidth] = useState(0);
  const [editorState, setEditorState] = useState<string>('');
  const [channelImageUrl, setChannelImageUrl] = useState<string>();
  const [headerBackgroundImageUrl, setHeaderBackgroundImageUrl] = useState<string>();
  const [bodyBackgroundImageUrl, setBodyBackgroundImageUrl] = useState<string>();
  const [playPageColumnHtmlState, setPlayPageColumnHtmlState] = useState<string>('');
  const [removeChannelLogo, setRemoveChannelLogo] = useState<boolean>(false);
  const [removeChannelImage, setRemoveChannelImage] = useState<boolean>(false);
  const [channelImageName, setChannelImageName] = useState<string>('');
  const [headerBackgroundImageName, setHeaderBackgroundImageName] = useState<string>('');
  const [removeHeaderBackgroundImage, setRemoveHeaderBackgroundImage] = useState<boolean>(false);
  const [channelBodyBackgroundImageName, setChannelBodyBackgroundImageName] = useState<string>('');
  const [removeBodyBackgroundImageName, setRemoveBodyBackgroundImageName] =
    useState<boolean>(false);

  const {
    channelLayout,
    isChannelLayoutLoading,
    isChannelLayoutUpdatedLoading,
    isChannelLayoutUpdated,
    mediaUrl
  } = useSelector((state: RootState) => {
    return {
      channelLayout: state.channel.channelLayout,
      isChannelLayoutLoading: state.channel.isChannelLayoutLoading,
      isChannelLayoutUpdatedLoading: state.channel.isChannelLayoutUpdatedLoading,
      isChannelLayoutUpdated: state.channel.isChannelLayoutUpdated,
      mediaUrl: state.videos.mediaUrl
    };
  });

  useEffect(() => {
    if (channelLayout && Object.keys(channelLayout).length) {
      videoAction.getMediaUrlRequest(channelLayout.headerHTML);
    }
  }, [channelLayout]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);
  const handleFormats = (e: any) => {
    console.log(e.target.value);
  };
  const handleRange = (e: any, formName: string) => {
    if (formName === 'channelImageWidth') {
      setChannelImageWidth(e.target.value);
    } else {
      setChannelTitleFontSize(e.target.value);
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    control
  } = useForm();

  useEffect(() => {
    if (id) {
      channelAction.getChannelLayoutRequest(id);
    }
  }, [id]);

  useEffect(() => {
    if (channelLayout) {
      reset(channelLayout);
      if (channelLayout.headerHTML) {
        setEditorState(channelLayout.headerHTML);
      }
      if (channelLayout.playPageColumnHtml) {
        setPlayPageColumnHtmlState(channelLayout.playPageColumnHtml);
      }
      if (channelLayout.channelImageWidth) {
        setChannelImageWidth(channelLayout.channelImageWidth);
      }
      if (channelLayout.channelTitleFontSize) {
        setChannelTitleFontSize(channelLayout.channelTitleFontSize);
      }
      setHeaderBackgroundImageUrl(channelLayout.headerBackgroundImageUrl);
      setChannelImageUrl(channelLayout.channelImageUrl);
      setBodyBackgroundImageUrl(channelLayout.channelBodyBackgroundImageURL);
    }
  }, [channelLayout]);
  const onSubmit = (data: any) => {
    const payloadData = {
      channelId: data.id,
      updateData: [
        { op: 'replace', path: 'displayChannelName', value: data.displayChannelName },
        { op: 'replace', path: 'channelTitleFontColor', value: data.channelTitleFontColor },
        { op: 'replace', path: 'channelTitleFontSize', value: channelTitleFontSize },
        { op: 'replace', path: 'channelTitleAlignment', value: data.channelTitleAlignment },
        { op: 'replace', path: 'headerHTML', value: ImageStringConverter(editorState) },
        { op: 'replace', path: 'channelLogoAlignment', value: data.channelLogoAlignment },
        { op: 'replace', path: 'channelImageWidth', value: channelImageWidth },
        { op: 'replace', path: 'channelImageLink', value: data.channelImageLink },
        {
          op: 'replace',
          path: 'channelImageBackgroundColor',
          value: data.channelImageBackgroundColor
        },
        {
          op: 'replace',
          path: 'channelBodyBackgroundColor',
          value: data.channelBodyBackgroundColor
        },
        { op: 'replace', path: 'bodyBackgroundImageRepeat', value: data.bodyBackgroundImageRepeat },
        { op: 'replace', path: 'watchButtonColor', value: data.watchButtonColor },
        { op: 'replace', path: 'addtoCartButtonColor', value: data.addtoCartButtonColor },
        { op: 'replace', path: 'paidButtonColor', value: data.paidButtonColor },
        { op: 'replace', path: 'mainTextColor', value: data.mainTextColor },
        { op: 'replace', path: 'secondaryTextColor', value: data.secondaryTextColor },
        { op: 'replace', path: 'videoCardBackgroundColor', value: data.videoCardBackgroundColor },
        {
          op: 'replace',
          path: 'channelNavigationFontColor',
          value: data.channelNavigationFontColor
        },
        { op: 'replace', path: 'enableSearchFilter', value: data.enableSearchFilter },
        { op: 'replace', path: 'displayCategories', value: data.displayCategories },
        { op: 'replace', path: 'displayTags', value: data.displayTags },
        { op: 'replace', path: 'displaySubChannels', value: data.displaySubChannels },
        { op: 'replace', path: 'subChannelAlias', value: data.subChannelAlias },
        { op: 'replace', path: 'channelDisplay', value: data.channelDisplay },
        { op: 'replace', path: 'defaultSortOrder', value: data.defaultSortOrder },
        { op: 'replace', path: 'channelCardtype', value: data.channelCardtype },
        { op: 'replace', path: 'animateVideoCard', value: data.animateVideoCard },
        { op: 'replace', path: 'playPageColumnHtml', value: playPageColumnHtmlState },
        { op: 'replace', path: 'channelImageUrl', value: channelImageUrl },
        { op: 'replace', path: 'headerBackgroundImageUrl', value: headerBackgroundImageUrl },
        { op: 'replace', path: 'channelBodyBackgroundImageURL', value: bodyBackgroundImageUrl },
        { op: 'replace', path: 'displaySpeakers', value: data.displaySpeakers }
      ]
    };
    channelAction.updateChannelLayoutRequest(payloadData);
  };
  const handleSelectChannelImageClick = () => {
    if (ChannelInputFile.current) {
      ChannelInputFile.current.click();
    }
  };
  const handleChannelImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      setChannelImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/logo?channelId=${id}&`,
        setChannelImageUrl,
        'channelImagesUrl'
      );
    }
  };
  const handleSelectHeaderBackgroundImageClick = () => {
    if (HeaderbackgroundInput.current) {
      HeaderbackgroundInput.current.click();
    }
  };
  const handleHeaderBackgroundImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      setHeaderBackgroundImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/headerbackground?channelId=${id}&`,
        setHeaderBackgroundImageUrl,
        'channelImagesUrl'
      );
    }
  };
  const handleSelectBodyBackgroundImageClick = () => {
    if (bodyBackgroundInputFile.current) {
      bodyBackgroundInputFile.current.click();
    }
  };
  const handleBodyBackgroundImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      setChannelBodyBackgroundImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/bodybackground?channelId=${id}&`,
        setBodyBackgroundImageUrl,
        'channelImagesUrl'
      );
    }
  };
  const handleCheckClick = (value: string) => {
    if (value === 'channelTitleFontColor') {
      setValue('channelTitleFontColor', '#00000000');
    } else if (value === 'channelImageBackgroundColor') {
      setValue('channelImageBackgroundColor', '#00000000');
    } else if (value === 'channelBodyBackgroundColor') {
      setValue('channelBodyBackgroundColor', '#00000000');
    } else if (value === 'watchButtonColor') {
      setValue('watchButtonColor', '#00000000');
    } else if (value === 'addtoCartButtonColor') {
      setValue('addtoCartButtonColor', '#00000000');
    } else if (value === 'paidButtonColor') {
      setValue('paidButtonColor', '#00000000');
    } else if (value === 'mainTextColor') {
      setValue('mainTextColor', '#00000000');
    } else if (value === 'secondaryTextColor') {
      setValue('secondaryTextColor', '#00000000');
    } else if (value === 'videoCardBackgroundColor') {
      setValue('videoCardBackgroundColor', '#00000000');
    } else if (value === 'channelNavigationFontColor') {
      setValue('channelNavigationFontColor', '#00000000');
    }
  };

  useEffect(() => {
    if (isChannelLayoutUpdated) {
      setRemoveChannelImage(false);
      setRemoveHeaderBackgroundImage(false);
      setChannelImageName('');
      setHeaderBackgroundImageName('');
      setChannelBodyBackgroundImageName('');
      setRemoveBodyBackgroundImageName(false);
      channelAction.getChannelLayoutRequest(id);
    }
  }, [isChannelLayoutUpdated]);
  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  return (
    <>
      <div className="tab-pane fade " id="tabs-channelStyles" role="tabpanel">
        <form onSubmit={handleSubmit(onSubmit)}>
          <button type="submit" className="btn-primary w-auto mx-4 ml-auto">
            Save Styles
          </button>
          {isChannelLayoutUpdatedLoading && <Loader />}
          <div className="accordion md:p-4 p-3" id="videoAccordion">
            <div className="accordion-item bg-white shadow border-0 mb-3">
              <h2 className="accordion-header mb-0" id="branding">
                <button
                  className="accordion-button
                collapsed
                accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne">
                  Channel Heading
                </button>
              </h2>
              <div
                id="collapseOne"
                data-bs-parent="#videoAccordion"
                className="accordion-collapse border-0 collapse">
                <div className="accordion-body py-4 px-5">
                  <div className="lg:flex mb-8 items-center">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Channel Name
                      </label>
                      <p>
                        Display the name of the channel as the page header. If turned on, then this
                        will display instead of the Channel Image
                      </p>
                    </div>

                    <div className="lg:w-1/2 pb-0">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displayChannelName"
                              {...register('displayChannelName')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Title Font Color</h6>
                        <p>
                          Site logo that will appear on the main navigatin across your videos site.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('channelTitleFontColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="channelTitleFontColor"
                            {...register('channelTitleFontColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Title Font Size</h6>
                        <p></p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="relative w-32">
                        <input
                          type="range"
                          value={channelTitleFontSize}
                          id="channelTitleFontSize"
                          {...register('channelTitleFontSize', {
                            onChange: (e: any) => {
                              handleRange(e, 'channelTitleFontSize');
                            }
                          })}
                        />
                        <span
                          style={{ left: `${channelTitleFontSize}%` }}
                          className="range-slider text-center absolute text-white block">
                          {channelTitleFontSize}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Title Alignment</h6>
                        <p></p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <select
                          id="channelTitleAlignment"
                          {...register('channelTitleAlignment')}
                          className="border border-gray-300 input-style">
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                          <option value="3">Center</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Header HTML</h6>
                        <p>
                          Create a welcome message, advertising banners or information for your
                          viewers on the channel page.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-8">
                    <Editor editorState={editorState} setEditorState={setEditorState} />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 my-3">
              <h2 className="accordion-header mb-0" id="headingThree">
                <button
                  className="accordion-button
                          collapsed
                          accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Channel Logo
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#videoAccordion">
                <div className="accordion-body py-4 px-5">
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Image</h6>
                        <p>
                          Upload a banner image to be displayed on the channel. Recommended Size:
                          650 x 350px.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div>
                          {channelLayout &&
                            channelLayout.channelImageUrl?.length !== 0 &&
                            channelLayout.channelImageUrl !== null && (
                              <>
                                <img
                                  src={`${channelLayout && channelLayout.channelImageUrl}`}
                                  className={`ChannelImageHeight ${
                                    removeChannelImage ? 'hidden' : ''
                                  }`}
                                />
                              </>
                            )}
                          {channelImageUrl && (
                            <span
                              onClick={() => {
                                setChannelImageUrl(''),
                                  setRemoveChannelImage(true),
                                  setChannelImageName('');
                              }}
                              className="cursor-pointer site-clr">
                              Remove
                            </span>
                          )}
                        </div>

                        <div className="">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 ">
                            <div className="relative">
                              <label
                                onClick={handleSelectChannelImageClick}
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                                Upload
                              </label>
                              <span className="ml-2">
                                {channelImageName ? channelImageName : 'No file chosen'}
                              </span>
                              <input
                                ref={ChannelInputFile}
                                type="file"
                                onChange={(e) => handleChannelImageUpload(e.target.files)}
                                className="hidden"
                                onClick={onInputClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <h6 className="font-bold mb-1">Channel Logo Alignment</h6>
                        <p></p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <select
                          id="channelLogoAlignment"
                          {...register('channelLogoAlignment')}
                          className="border border-gray-300 input-style">
                          <option value="1">Left</option>
                          <option value="2">Right</option>
                          <option value="3">Center</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Image Width</h6>
                        <p>Adjust the width of the channel image.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="relative w-32">
                        <input
                          type="range"
                          min={50}
                          max={650}
                          value={channelImageWidth}
                          id="channelImageWidth"
                          {...register('channelImageWidth', {
                            onChange: (e: any) => {
                              handleRange(e, 'channelImageWidth');
                            }
                          })}
                        />
                        <span
                          style={{
                            left: `calc(${(channelImageWidth / 650) * 100}% - 10px)`,
                            transform: 'translateX(-50%)'
                          }}
                          className="range-slider text-center absolute text-white block">
                          {channelImageWidth}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <h6 className="font-bold mb-1">Channel Image Link</h6>
                        <p>Hyperlink for the channel image.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <FormInputField
                          label={''}
                          type={'text'}
                          fieldName={'channelImageLink'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 my-3">
              <h2 className="accordion-header mb-0" id="headingThree">
                <button
                  className="accordion-button
                          collapsed
                          accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Heading Background
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#videoAccordion">
                <div className="accordion-body py-4 px-5">
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Header Background Color</h6>
                        <p>
                          Set the background color of your channel page, that will appear behind the
                          channel image.
                        </p>
                      </div>
                    </div>

                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('channelImageBackgroundColor')}
                            id="headerBackgroundColorCheckbox"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="channelImageBackgroundColor"
                            {...register('channelImageBackgroundColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Header Background Image Upload</h6>
                        <p>
                          Upload an image to be displayed as the background of the channel header.
                          This can also be used as a full width banner instead of the Channel Image.
                          Recommended Size: 1660 x 400px.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div>
                          {channelLayout &&
                            channelLayout.headerBackgroundImageUrl?.length !== 0 &&
                            channelLayout.headerBackgroundImageUrl !== null && (
                              <>
                                <img
                                  src={`${channelLayout && channelLayout.headerBackgroundImageUrl}`}
                                  className={`ChannelImageHeight ${
                                    removeHeaderBackgroundImage ? 'hidden' : ''
                                  }`}
                                />
                              </>
                            )}
                          {headerBackgroundImageUrl && (
                            <span
                              onClick={() => {
                                setHeaderBackgroundImageUrl(''),
                                  setRemoveHeaderBackgroundImage(true),
                                  setHeaderBackgroundImageName('');
                              }}
                              className="cursor-pointer site-clr">
                              Remove
                            </span>
                          )}
                        </div>

                        <div className="flex items-center">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 ">
                            <div className="relative">
                              <label
                                onClick={handleSelectHeaderBackgroundImageClick}
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                                Upload
                              </label>
                              <span className="ml-2">
                                {headerBackgroundImageName
                                  ? headerBackgroundImageName
                                  : 'No file chosen'}
                              </span>
                              <input
                                ref={HeaderbackgroundInput}
                                type="file"
                                onChange={(e) => handleHeaderBackgroundImageUpload(e.target.files)}
                                className="hidden"
                                onClick={onInputClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 mb-3">
              <h2 className="accordion-header mb-0" id="branding">
                <button
                  className="accordion-button
                          collapsed
                          accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                  Body Background
                </button>
              </h2>
              <div
                id="collapseFour"
                data-bs-parent="#videoAccordion"
                className="accordion-collapse border-0 collapse">
                <div className="accordion-body py-4 px-5">
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Background Color</h6>
                        <p></p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('channelBodyBackgroundColor')}
                            id="channelBackgroundColorCheckbox"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="channelBodyBackgroundColor"
                            {...register('channelBodyBackgroundColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Body Background Image</h6>
                        <p>
                          Upload an image to be displayed as the background on the entire channel
                          body. Recommended Size: 1920x1080px or same aspect ratio. Image will
                          repeat from top-left.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div>
                          {channelLayout &&
                            channelLayout.channelBodyBackgroundImageURL?.length !== 0 &&
                            channelLayout.channelBodyBackgroundImageURL !== null && (
                              <>
                                <img
                                  src={`${
                                    channelLayout && channelLayout.channelBodyBackgroundImageURL
                                  }`}
                                  className={`ChannelImageHeight ${
                                    removeBodyBackgroundImageName ? 'hidden' : ''
                                  }`}
                                />
                              </>
                            )}
                          {bodyBackgroundImageUrl && (
                            <span
                              onClick={() => {
                                setBodyBackgroundImageUrl(''),
                                  setRemoveBodyBackgroundImageName(true),
                                  setChannelBodyBackgroundImageName('');
                              }}
                              className="cursor-pointer site-clr">
                              Remove
                            </span>
                          )}
                        </div>

                        <div className="flex items-center">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 ">
                            <div className="relative">
                              <label
                                onClick={handleSelectBodyBackgroundImageClick}
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                                Upload
                              </label>
                              <span className="ml-2">
                                {channelBodyBackgroundImageName
                                  ? channelBodyBackgroundImageName
                                  : 'No file chosen'}
                              </span>
                              <input
                                ref={bodyBackgroundInputFile}
                                type="file"
                                onChange={(e) => handleBodyBackgroundImageUpload(e.target.files)}
                                className="hidden"
                                onClick={onInputClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="lg:w-1/2 pr-5">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Body Background Image Repeat
                      </label>
                      <p></p>
                    </div>

                    <div className="lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="bodyBackgroundImageRepeat"
                              {...register('bodyBackgroundImageRepeat')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 mb-3">
              <h2 className="accordion-header mb-0" id="branding">
                <button
                  className="accordion-button
                collapsed
                accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive">
                  Video Card And Font Colors
                </button>
              </h2>
              <div
                id="collapseFive"
                data-bs-parent="#videoAccordion"
                className="accordion-collapse border-0 collapse">
                <div className="accordion-body py-4 px-5">
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Watch Button Color</h6>
                        <p>
                          Select the Watch button color for your Video Cards on this channelpage. If
                          blank, color is inherited from homepage video channel settings.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('watchButtonColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="watchButtonColor"
                            {...register('watchButtonColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Buy Video Button Color</h6>
                        <p>
                          Select the Buy Video button color on Video Cards on this channel page. If
                          blank, color is inherited from homepage video channel settings.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('addtoCartButtonColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="addtoCartButtonColor"
                            {...register('addtoCartButtonColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">View Cart Button Color</h6>
                        <p>
                          Select the View Cart button color on Video Cards on this channel page. If
                          blank, color is inherited from homepage video channel settings.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('paidButtonColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="paidButtonColor"
                            {...register('paidButtonColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Main Text Color</h6>
                        <p>
                          Select the font color of Video Titles on video cards in this channel.
                          Leave blank to inherit.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('mainTextColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input type="color" id="mainTextColor" {...register('mainTextColor')} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Secondary Text Color</h6>
                        <p>
                          Select the font color for video description data (date, time, etc) on
                          video cards in this channel. Leave blank to inherit.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('secondaryTextColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="secondaryTextColor"
                            {...register('secondaryTextColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Video Card Background Color</h6>
                        <p>Select the background color of the video cards on your channel page.</p>
                      </div>
                    </div>

                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('videoCardBackgroundColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="videoCardBackgroundColor"
                            {...register('videoCardBackgroundColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Channel Navigation Font Color</h6>
                        <p>
                          Select the font color of the channel page navigation (ie. Logoff control).
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="flex items-center mb-1">
                          <input
                            onClick={() => handleCheckClick('channelNavigationFontColor')}
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="default-checkbox"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                            Transparent
                          </label>
                        </div>

                        <div className="md:w-2/3">
                          <input
                            type="color"
                            id="channelNavigationFontColor"
                            {...register('channelNavigationFontColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 mb-3">
              <h2 className="accordion-header mb-0" id="branding">
                <button
                  className="accordion-button
                collapsed
                accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix">
                  Search Filter Settings
                </button>
              </h2>
              <div
                id="collapseSix"
                data-bs-parent="#videoAccordion"
                className="accordion-collapse border-0 collapse">
                <div className="accordion-body py-4 px-5">
                  <div className="md:flex items-center mb-8">
                    <div className="xl:w-4/12 lg:w-1/2">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Enable Search Filter
                      </label>
                      <p>Will display the Search filter on the channel page.</p>
                    </div>

                    <div className="xl:w-8/12 lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="enableSearchFilter"
                              {...register('enableSearchFilter')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="xl:w-4/12 lg:w-1/2">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Categories
                      </label>
                      <p>Will display Categories in the Search filter on the channel page.</p>
                    </div>

                    <div className="xl:w-8/12 lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displayCategories"
                              {...register('displayCategories')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="xl:w-4/12 lg:w-1/2">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Tags
                      </label>
                      <p>Will display Tags in the Search filter on the channel page.</p>
                    </div>

                    <div className="xl:w-8/12 lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displayTags"
                              {...register('displayTags')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="xl:w-4/12 lg:w-1/2">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Sub-Channels
                      </label>
                      <p>Will display child channels in the Search filter on the channel page.</p>
                    </div>

                    <div className="xl:w-8/12 lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displaySubChannels"
                              {...register('displaySubChannels')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="xl:w-4/12 lg:w-1/2">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Speakers
                      </label>
                      <p>Will display speakers in the Search filter on the channel page.</p>
                    </div>

                    <div className="xl:w-8/12 lg:w-1/2">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displaySpeakers"
                              {...register('displaySpeakers')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-8">
                    <div className="xl:w-4/12 lg:w-1/2 pb-0 pr-5">
                      <div>
                        <h6 className="font-bold mb-1">Sub-Channel Alias</h6>
                        <p>Set the label that will appear for the Sub-channels menu.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0 ml-10">
                      <div>
                        <FormInputField
                          label={''}
                          type={'text'}
                          fieldName={'subChannelAlias'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 my-3">
              <h2 className="accordion-header mb-0" id="headingSix">
                <button
                  className="accordion-button
                          collapsed
                          accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven">
                  Display Settings
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#videoAccordion">
                <div className="accordion-body p-5">
                  <div>
                    <div className="md:flex items-center mb-8">
                      <div className="md:w-1/2 pr-5">
                        <label
                          className="form-check-label inline-block font-semibold switch-label mr-5"
                          htmlFor="Credit">
                          Channel Display View
                        </label>
                        <p>
                          Changes how Videos card are displayed on the channel page. Row view will
                          display the videos within each child channel on a new row. List View will
                          display All Videos in sort order.
                        </p>
                      </div>
                      <div className="md:w-1/2">
                        <select
                          {...register('channelDisplay')}
                          id="channelDisplay"
                          className="border border-gray-300 input-style">
                          <option value={1}>List View</option>
                          <option value={2}>Row View</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex items-center mb-8">
                      <div className="md:w-1/2 pr-5">
                        <label
                          className="form-check-label inline-block font-semibold switch-label mr-5"
                          htmlFor="Credit">
                          Default Sort Order
                        </label>
                        <p>Changes the sort order of the video cards on the channel.</p>
                      </div>
                      <div className="md:w-1/2">
                        <select
                          {...register('defaultSortOrder')}
                          id="defaultSortOrder"
                          className="border border-gray-300 input-style">
                          <option value={1}>Date:Most Recent</option>
                          <option value={2}>Date:Least Recent</option>
                          <option value={3}>A-Z</option>
                          <option value={4}>Z-A</option>
                          <option value={5}>Price:Lowest First</option>
                          <option value={6}>Price:Highest First</option>
                          <option value={7}>Video Sequence</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex items-center mb-8">
                      <div className="md:w-1/2 pr-5">
                        <label
                          className="form-check-label inline-block font-semibold switch-label mr-5"
                          htmlFor="Credit">
                          Channel Card Type
                        </label>
                        <p>
                          Set the video card style that will be used on your channel page.
                          Minamilist is smaller. Default displays more information.
                        </p>
                      </div>
                      <div className="md:w-1/2">
                        <select
                          {...register('channelCardtype')}
                          id="channelCardtype"
                          className="border border-gray-300 input-style">
                          <option value={0}>Default</option>
                          <option value={1}>Minimalist</option>
                        </select>
                      </div>
                    </div>
                    <div className="md:flex items-center mb-8">
                      <div className="md:w-1/2 pr-5">
                        <label
                          className="form-check-label inline-block font-semibold switch-label mr-5"
                          htmlFor="Credit">
                          Animate Video Card
                        </label>
                        <p>
                          Video card hover effect on the selected channel page, when the user
                          positions the computer cursor over the video card.
                        </p>
                      </div>

                      <div className="md:w-1/2">
                        <div className="flex items-center">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="animateVideoCard"
                                {...register('animateVideoCard')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-8">
                      <div>
                        <label
                          className="form-check-label inline-block font-semibold switch-label mr-5"
                          htmlFor="Credit">
                          Play Page Column HTML
                        </label>
                        <p>
                          HTML that will appear in the Ad Space column on all Video Play Pages
                          within this channel. Ideal for advertising sponsors, upcoming events, etc.
                          Default column size (200x350px)
                        </p>
                      </div>
                      <div className="mt-2">
                        <Editor
                          editorState={playPageColumnHtmlState}
                          setEditorState={setPlayPageColumnHtmlState}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChannelStylesTab;
