import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import Loader from 'src/components/loader';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationModel, CustomChecksModel } from 'src/models/eventModel';
import { CSVLink } from 'react-csv';
import ImportOption from '../components/ImportOption';
import DeleteRegisteredUserModal from '../components/DeleteRegisteredUserModal';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import { useVideosActions } from 'src/store/videos/actions';
const EventRegisteredUsers: React.FC = () => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [downloadAllUsersData, setDownloadAllUsersData] = useState();
  const [demoImportFileData, setDemoImportFileData] = useState<Array<string>>();
  const { eventId } = useParams<string>();
  const eventActions = useEventActions(dispatch);
  const [eventRegisteredUsers, setEventRegisteredUsers] = useState<any>([]);
  const [memberId, setMemberId] = useState<string>('');
  const {
    registration,
    isEventSaved,
    isEventUpdated,
    isEventDeleted,
    loader,
    registrationsDownload,
    isEventRegistrationDeleted,
    isRegistrationImported,
    videoAnalyticsTable,
    isVideoAnalyticsDataLoading,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      registration: state.event.registration,
      isEventSaved: state.event.isEventSaved,
      isEventUpdated: state.event.isEventUpdated,
      isEventDeleted: state.event.isEventDeleted,
      loader: state.event.loading,
      registrationsDownload: state.event.registrationsDownload,
      isEventRegistrationDeleted: state.event.isEventRegistrationDeleted,
      isRegistrationImported: state.event.isRegistrationImported,
      videoAnalyticsTable: state.videos.videoAnalyticsTable,
      isVideoAnalyticsDataLoading: state.videos.isVideoAnalyticsDataLoading,
      paginationMeta: state.videos.paginationMeta
    };
  });
  const { reset } = useForm<RegistrationModel, CustomChecksModel>();

  useEffect(() => {
    if (eventId) {
      eventActions.getEventRegistrationsRequest(eventId);
    }
  }, [eventId, isEventSaved, isEventUpdated, isEventDeleted]);

  useEffect(() => {
    if (registration && registration.membervalues) {
      const tempEventRegister: any = [];
      registration.membervalues.forEach((membervalue) => {
        const user: any = {};
        membervalue.customTexts.forEach((customtext) => {
          user[customtext.description] = customtext.value;
        });
        membervalue.customDates.forEach((customdate) => {
          user[customdate.description] = customdate.value;
        });
        membervalue.customDropdown.forEach((customdropdown) => {
          user[customdropdown.description] = customdropdown.selectedValue;
        });
        membervalue.customDates.forEach((customedate) => {
          user[customedate.description] = customedate.value;
        });
        user['memberid'] = membervalue.memberid;
        tempEventRegister.push(user);
      });
      setEventRegisteredUsers(tempEventRegister);
    }
  }, [registration.membervalues]);
  useEffect(() => {
    if (isEventSaved === true) {
      reset({
        id: '',
        name: '',
        title: '',
        eventType: '',
        displayDate: '',
        displayTime: '',
        timezoneAbbv: '',
        displayDuration: '',
        displayDurationInTable: ''
      });
    }
  }, [isEventSaved]);
  useEffect(() => {
    if (registration.customFields) {
      const demoImportFileCSVData = registration.customFields
        ? registration.customFields
          .map((customField) => {
            return !customField.ishiddenfield ? customField.description : null;
          })
          .filter((description) => description !== null)
        : [];
      if (demoImportFileCSVData) {
        // Find the index of "Last Login Date" in the array
        const indexToRemove = demoImportFileCSVData.indexOf('Last Login Date');

        // Remove the element at that index
        if (indexToRemove !== -1) {
          demoImportFileCSVData.splice(indexToRemove, 1);
        }
        setDemoImportFileData(demoImportFileCSVData as any);
      }
    }
  }, [registration, eventRegisteredUsers]);
  const handleDeleteMember = (memberID: any) => {
    setMemberId(memberID);
  };

  useEffect(() => {
    if (registration && registration.selectedVideoId) {
      const payloadData = { id: registration.selectedVideoId, pageNumber: 1, pageSize: 20 };
      videoAction.getVideoAnalyticsTableRequest(payloadData);
    }
  }, [registration]);
  useEffect(() => {
    eventId && eventActions.getRegistrationsDownloadRequest(eventId);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    const payloadData = { id: registration.selectedVideoId, pageNumber: pageNumber, pageSize: 20 };
    videoAction.getVideoAnalyticsTableRequest(payloadData);
  };

  const exportDemoCsv = () => {
    if (registrationsDownload && registrationsDownload.length && demoImportFileData) {
      const demoImportFile = demoImportFileData && demoImportFileData.map((item) => item);
      const downloadAllUserData = [demoImportFile];
      let CsvString = '';
      downloadAllUserData.forEach(function (RowItem, RowIndex) {
        RowItem.forEach(function (ColItem, ColIndex) {
          CsvString += ColItem + ',';
        });
        CsvString += '\r\n';
      });
      CsvString = 'data:application/csv,' + encodeURIComponent(CsvString);
      const x = document.createElement('A');
      x.setAttribute('href', CsvString);
      x.setAttribute('download', `RegistrationDemo.csv`);
      document.body.appendChild(x);
      x.click();
    }
  };

  const exportToCsv = () => {
    if (registrationsDownload && registrationsDownload.length) {
      const downloadAllUserData = registrationsDownload.map((item) => {
        return item.split(',');
      });
      let CsvString = '';
      downloadAllUserData.forEach(function (RowItem, RowIndex) {
        RowItem.forEach(function (ColItem, ColIndex) {
          CsvString += ColItem + ',';
        });
        CsvString += '\r\n';
      });
      CsvString = 'data:application/csv,' + encodeURIComponent(CsvString);
      const x = document.createElement('A');
      x.setAttribute('href', CsvString);
      x.setAttribute('download', `${registration && registration.name}_EventRegisteredUser.csv`);
      document.body.appendChild(x);
      x.click();
    }
  };

  useEffect(() => {
    if (isEventRegistrationDeleted || isRegistrationImported) {
      eventId && eventActions.getEventRegistrationsRequest(eventId);
      successMsg('Success');
      eventActions.resetEventStateRequest();
    }
  }, [isEventRegistrationDeleted, isRegistrationImported]);
  const getDarkBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 31, 31, 1)'
      : perc < 50
        ? 'rgba(255, 165, 31, 1)'
        : perc < 75
          ? 'rgba(31, 143, 255, 1)'
          : 'rgba(31, 255, 31, 1)';
  };
  const getLightBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 198, 184, 1)'
      : perc < 50
        ? 'rgba(255, 216, 158, 1)'
        : perc < 75
          ? 'rgba(191, 219, 254, 1)'
          : 'rgba(213, 255, 199, 1)';
  };
  return (
    <>
      <ToastMessage />
      <div className="video-categories">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Registerd Users</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/events/event">Event</Link>
            </li>
            <li>
              <strong>Registered Users</strong>
            </li>
          </ol>
        </div>
        <Link to={`/event/edit/${eventId}`}>
          <i className="fas fa-arrow-left mt-2 mb-2"></i>
          <span className="ml-2">Go Back</span>
        </Link>
        <div className="mt-4 members-list">
          <div>
            <div className="flex flex-col justify-center h-full">
              <div className="flex">
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  {/* {downloadAllUsersData && ( */}
                  <span className="export" onClick={exportToCsv}>
                    <button
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Download All Users
                    </button>
                  </span>
                  {/* )} */}
                </div>
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  <span className="export" onClick={exportDemoCsv}>
                    <button
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Demo Import File
                    </button>
                  </span>
                </div>
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#ImportOption"
                    type="button"
                    className="px-6 btn-primary w-max mr-2 ml-0 mt-0">
                    Import Registrations
                  </button>
                </div>
              </div>
              <div className="p-1">
                <h2 className="font-semibold ">Event Detail</h2>
              </div>
              <div className="p-1">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Time</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Duration (hh:mm)</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      <>
                        {registration ? (
                          <>
                            {registration && (
                              <>
                                <tr>
                                  <td className="p-2">
                                    <div className="text-left">{registration.name}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{registration.title}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{registration.displayDate}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      {registration.displayTime + (registration.timezoneAbbv ? registration.timezoneAbbv : "")}
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{registration.displayDuration}</div>
                                  </td>
                                </tr>
                              </>
                            )}
                          </>
                        ) : (
                          <tr>
                            <td className="text-center py-2" colSpan={5}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="p-1 mt-3">
                <h2 className="font-semibold ">Registered Users</h2>
              </div>
              <div className="p-1">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        {registration &&
                          registration.customFields &&
                          registration.customFields.map((customField) => {
                            return (
                              <>
                                <th className="p-2">
                                  <div className="font-semibold text-left">
                                    {customField.description}
                                  </div>
                                </th>
                              </>
                            );
                          })}
                        {registration && registration.customFields && (
                          <>
                            <th className="p-2">
                              <div className="font-semibold text-left">DELETE</div>
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loader ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {eventRegisteredUsers.length ? (
                            <>
                              {eventRegisteredUsers &&
                                eventRegisteredUsers.map(
                                  (eventRegister: any, eventRegisterIndex: any) => {
                                    return (
                                      <>
                                        <tr key={'eventRow' + eventRegisterIndex}>
                                          <>
                                            {registration.customFields.length > 0 &&
                                              registration.customFields.map(
                                                (customFieldData, customFieldIndex) => {
                                                  return (
                                                    <td
                                                      key={'eventColumn' + customFieldIndex}
                                                      className="p-2">
                                                      <div className="text-left">
                                                        {eventRegister[customFieldData.description]}
                                                      </div>
                                                    </td>
                                                  );
                                                }
                                              )}
                                            <td>
                                              <div
                                                className="text-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#DeleteRegisteredUserModal">
                                                <span
                                                  className="items-center cursor-pointer"
                                                  onClick={() =>
                                                    handleDeleteMember(eventRegister.memberid)
                                                  }>
                                                  <i className="fas fa-trash w-6 text-center"></i>
                                                </span>
                                              </div>
                                            </td>
                                          </>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {registration && registration.selectedVideoId !== null && (
                <div>
                  <div className="p-1 mt-3">
                    <h2 className="font-semibold ">Analytics</h2>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full my-6">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">User Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Live Views</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">OnDemand Views</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Time Watched</div>
                          </th>

                          <th className="p-2">
                            <div className="font-semibold text-left">First Watch</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Watch</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credits Achieved</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credits Sent</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Percentage Watched</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">
                              <p>OnDemand Milestones</p>
                              <div className="flex gap-2">
                                <div className="w-10 mx-auto text-center">25%</div>
                                <div className="w-10 mx-auto text-center">50%</div>
                                <div className="w-10 mx-auto text-center">75%</div>
                                <div className="w-10 mx-auto text-center">90%</div>
                                <div className="w-10 mx-auto text-center">100%</div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isVideoAnalyticsDataLoading && (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        )}
                        {videoAnalyticsTable &&
                          videoAnalyticsTable.map((item, index) => {
                            return (
                              <tr key={index} className="border-b">
                                <td className="p-2">
                                  <div className="text-left">{item.userName}</div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">{item.liveViews}</div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">{item.onDemandViews}</div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">{item.totalWatchedDuration}</div>
                                </td>

                                <td className="p-2">
                                  <div className="text-left">
                                    {item.firstWatch ? defaultDateFormatter(item.firstWatch) : ''}
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">
                                    {item.lastWatch ? defaultDateFormatter(item.lastWatch) : ''}
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">
                                    <div className="text-left">{item.creditPointsAchieved}</div>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">
                                    <div className="text-left">
                                      {item.creditsSent ? (
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td className="p-2">
                                  <div className="text-left">
                                    {item.percentageWatched}%
                                    <div
                                      className="w-full bg-blue-200 h-1 rounded-md mt-2"
                                      style={{
                                        backgroundColor: `${getLightBgColorStr(
                                          item.percentageWatched
                                        )}`
                                      }}>
                                      <div
                                        className="bg-blue-600 h-1 rounded-md"
                                        style={{
                                          width: `${item.percentageWatched}%`,
                                          backgroundColor: `${getDarkBgColorStr(
                                            item.percentageWatched
                                          )}`
                                        }}></div>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-2">
                                  <div className="flex gap-2">
                                    {item.milestone25 ? (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      </div>
                                    ) : (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                      </div>
                                    )}
                                    {item.milestone50 ? (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      </div>
                                    ) : (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                      </div>
                                    )}
                                    {item.milestone75 ? (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      </div>
                                    ) : (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                      </div>
                                    )}
                                    {item.milestone90 ? (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      </div>
                                    ) : (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                      </div>
                                    )}
                                    {item.milestone100 ? (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                      </div>
                                    ) : (
                                      <div className="w-10 mx-auto text-center">
                                        <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ImportOption demoImportFileData={demoImportFileData} />
        <DeleteRegisteredUserModal memberid={memberId} />
      </div>
    </>
  );
};

export default EventRegisteredUsers;
