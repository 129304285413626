import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { RootState } from 'src/store/reducers/state';
import SubscriptionModal from '../components/SubscriptionModal';

const Subscriptions: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalFlag, setModalFlag] = useState('');
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState('');

  const monetizationAction = useMonetizationActions(dispatch);

  const {
    loading,
    subscriptions,
    isSubscriptionDeleted,
    isSubscriptionAdded,
    isSubscriptionUpdated
  } = useSelector((state: RootState) => {
    return {
      loading: state.monetization.loading,
      subscriptions: state.monetization.subscriptionList,
      isSubscriptionDeleted: state.monetization.isSubscriptionDeleted,
      isSubscriptionAdded: state.monetization.isSubscriptionAdded,
      isSubscriptionUpdated: state.monetization.isSubscriptionUpdated
    };
  });

  useEffect(() => {
    monetizationAction.getSubscriptions();
  }, [isSubscriptionDeleted, isSubscriptionAdded, isSubscriptionUpdated]);

  const currency = [
    { value: 'AUD', label: 'AUD' },
    { value: 'USD', label: 'USD' }
  ];

  const interval = [
    { value: '1', label: 'Day' },
    { value: '2', label: 'Week' },
    { value: '3', label: 'Month' },
    { value: '4', label: 'Year' }
  ];

  const routeToSubscribers = (subscriptionId: string) => {
    navigate(`/monetization/subscribers/${subscriptionId}`);
  };

  const handleDeleteSubscription = (subscriptionId: string) => {
    monetizationAction.deleteSubscriptionRequest(subscriptionId);
  };
  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Subscriptions</h2>
                <div className="md:flex items-center text-center">
                  <button
                    onClick={() => {
                      setModalFlag('add');
                    }}
                    type="button"
                    className="btn-primary w-36 mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#editSubscriptionModel">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className={`overflow-x-auto-sm`}>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-60">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Active</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Channel</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Interval</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Cost</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Currency</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {subscriptions.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {subscriptions.map((subscription, index) => {
                              return (
                                <tr key={index}>
                                  <td className="p-2">
                                    <div className="form-switch ">
                                      <input
                                        className="form-check-input switch-btn appearance-none w-9 
                                                                                    -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                                                                    focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                        type="checkbox"
                                        role="switch"
                                        id="isActive"
                                        readOnly
                                        checked={subscription.active}
                                      />
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="font-medium ">{`${subscription.name}`}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{subscription.channelName}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      {interval
                                        .filter((i) => i.value == subscription.interval.toString())
                                        .map((filteredInterval) => filteredInterval.label)}
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{subscription.amount}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      {' '}
                                      {currency
                                        .filter(
                                          (c) =>
                                            c.value.toLowerCase() ==
                                            subscription.currency.toLowerCase()
                                        )
                                        .map((filteredInterval) =>
                                          filteredInterval.label.toUpperCase()
                                        )}
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      <div className="dropdown relative">
                                        <button
                                          className="dropdown-toggle"
                                          type="button"
                                          id="action-dropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false">
                                          <img
                                            src="../../assets/img/dots-vertical.svg"
                                            className="w-5 h-5"
                                          />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-ul"
                                          aria-labelledby="action-dropdown">
                                          <li>
                                            <button
                                              type="button"
                                              className="action-menu flex items-center"
                                              data-bs-toggle="modal"
                                              data-bs-target="#editSubscriptionModel"
                                              onClick={() => {
                                                setModalFlag('edit');
                                                setCurrentSubscriptionId(subscription.id);
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                              </svg>
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li>
                                            <span className="action-menu flex items-center text-red-500 cursor-pointer">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                              </svg>
                                              <span
                                                onClick={() =>
                                                  handleDeleteSubscription(subscription.id)
                                                }>
                                                Delete
                                              </span>
                                            </span>
                                          </li>
                                          {subscription.subscribersCount > 0 && (
                                            <>
                                              <li>
                                                <button
                                                  type="button"
                                                  className="action-menu flex items-center"
                                                  onClick={() => {
                                                    routeToSubscribers(subscription.id);
                                                  }}>
                                                  <span>
                                                    {subscription.subscribersCount} Subscribers
                                                  </span>
                                                </button>
                                              </li>
                                            </>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SubscriptionModal modalFlag={modalFlag} currentSubscriptionId={currentSubscriptionId} />
      </div>
    </>
  );
};

export default Subscriptions;
