import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { QuickBooksModel, EcommerceSettingModel, CurrencyModel, UpdateDataModel, PaymentProviderModel, UpdateIntegrationDataModel, EnableIntegrationModel, IntegrationModel, UpdateEcommerceModel, UpdateEducationPointModal, TemplateModel, EducationPointsModel, EducationPointsDetailModel, CePointModel, LivePayModel, BPointModel, PaypalModel, StripeModel, EzidebitModel, UpdateLiveStreamModel, UpdateApiV2Model, ApiV2Model, LiveStreamModel, SamlModel, EcommerceModel, VideoLogsModel, ZoomIntegrationModel, ImisIntegrationModel, MemberEvolutionIntegrationModel, WicketIntegrationModel, Dynamics365IntegrationModel, MicrosoftAzureIntegrationModel, NoviAMSIntegrationModel,  OAuth2IntegrationModel, MemberClicksIntegrationModel, PaypalPayFlowModel, GoogleIntegrationModel, WebCourseWorksIntegrationModel, SpargoIntegrationModel, MembesIntegrationModel, NaylorIntegrationModel, PersonifyIntegrationModel, ChannelLogsModel, AnalyticsLogsModel, AttributeMappingModel, AttributeMappingGetRequestModel, CECreditDBColumnMapping, AttributeMappingType, UpdateAttributeMappingModal } from 'src/models/settingModal';
import { PagingDataModel } from 'src/models/paginationModel';

export namespace SettingActions {

    export type getApiV2RequestAction = Action<typeof Constants.getApiV2RequestActionType, null>
    export type getApiV2RequestSuccessAction = Action<typeof Constants.getApiV2SuccessActionType, PagingDataModel<ApiV2Model>>;
    export type getApiV2RequestFailureAction = Action<typeof Constants.getApiV2FailureActionType, null>;

    export function getApiV2Request(): getApiV2RequestAction {
        return ActionCreator(Constants.getApiV2RequestActionType, null);
    }
    export function getApiV2RequestSuccess(P: PagingDataModel<ApiV2Model>): getApiV2RequestSuccessAction {
        return ActionCreator(Constants.getApiV2SuccessActionType, P);
    }
    export function getApiV2RequestFailure(): getApiV2RequestFailureAction {
        return ActionCreator(Constants.getApiV2FailureActionType, null);
    }

    export type addApiV2RequestAction = Action<typeof Constants.addApiV2RequestActionType, ApiV2Model>
    export type addApiV2RequestSuccessAction = Action<typeof Constants.addApiV2SuccessActionType, null>;
    export type addApiV2RequestFailureAction = Action<typeof Constants.addApiV2FailureActionType, null>;

    export function addApiV2Request(P: ApiV2Model): addApiV2RequestAction {
        return ActionCreator(Constants.addApiV2RequestActionType, P);
    }
    export function addApiV2RequestSuccess(): addApiV2RequestSuccessAction {
        return ActionCreator(Constants.addApiV2SuccessActionType, null);
    }
    export function addApiV2RequestFailure(): addApiV2RequestFailureAction {
        return ActionCreator(Constants.addApiV2FailureActionType, null);
    }

    export type deleteApiV2RequestAction = Action<typeof Constants.deleteApiV2RequestActionType, string>
    export type deleteApiV2RequestSuccessAction = Action<typeof Constants.deleteApiV2SuccessActionType, null>;
    export type deleteApiV2RequestFailureAction = Action<typeof Constants.deleteApiV2FailureActionType, null>;

    export function deleteApiV2Request(P: string): deleteApiV2RequestAction {
        return ActionCreator(Constants.deleteApiV2RequestActionType, P);
    }
    export function deleteApiV2RequestSuccess(): deleteApiV2RequestSuccessAction {
        return ActionCreator(Constants.deleteApiV2SuccessActionType, null);
    }
    export function deleteApiV2RequestFailure(): deleteApiV2RequestFailureAction {
        return ActionCreator(Constants.deleteApiV2FailureActionType, null);
    }

    export type getLiveStreamUrlRequestAction = Action<typeof Constants.getLiveStreamUrlRequestActionType, null>
    export type getLiveStreamUrlRequestSuccessAction = Action<typeof Constants.getLiveStreamUrlRequestSuccessActionType, LiveStreamModel[]>;
    export type getLiveStreamUrlRequestFailureAction = Action<typeof Constants.getLiveStreamUrlRequestFailureActionType, null>;

    export function getLiveStreamUrlRequest(): getLiveStreamUrlRequestAction {
        return ActionCreator(Constants.getLiveStreamUrlRequestActionType, null);
    }
    export function getLiveStreamUrlRequestSuccess(P: LiveStreamModel[]): getLiveStreamUrlRequestSuccessAction {
        return ActionCreator(Constants.getLiveStreamUrlRequestSuccessActionType, P);
    }
    export function getLiveStreamUrlRequestFailure(): getLiveStreamUrlRequestFailureAction {
        return ActionCreator(Constants.getLiveStreamUrlRequestFailureActionType, null);
    }

    export type getSamlRequestAction = Action<typeof Constants.getSamlRequestActionType, null>
    export type getSamlRequestSuccessAction = Action<typeof Constants.getSamlRequestSuccessActionType, SamlModel>;
    export type getSamlRequestFailureAction = Action<typeof Constants.getSamlRequestFailureActionType, null>;

    export function getSamlRequest(): getSamlRequestAction {
        return ActionCreator(Constants.getSamlRequestActionType, null);
    }
    export function getSamlRequestSuccess(P: SamlModel): getSamlRequestSuccessAction {
        return ActionCreator(Constants.getSamlRequestSuccessActionType, P);
    }
    export function getSamlRequestFailure(): getSamlRequestFailureAction {
        return ActionCreator(Constants.getSamlRequestFailureActionType, null);
    }

    export type saveSamlRequestAction = Action<typeof Constants.saveSamlRequestActionType, UpdateDataModel[]>
    export type saveSamlRequestSuccessAction = Action<typeof Constants.saveSamlRequestSuccessActionType, null>;
    export type saveSamlRequestFailureAction = Action<typeof Constants.saveSamlRequestFailureActionType, null>;

    export function saveSamlRequest(P: UpdateDataModel[]): saveSamlRequestAction {
        return ActionCreator(Constants.saveSamlRequestActionType, P);
    }
    export function saveSamlRequestSuccess(): saveSamlRequestSuccessAction {
        return ActionCreator(Constants.saveSamlRequestSuccessActionType, null);
    }
    export function saveSamlRequestFailure(): saveSamlRequestFailureAction {
        return ActionCreator(Constants.saveSamlRequestFailureActionType, null);
    }

    export type updateCloudRecordingRequestAction = Action<typeof Constants.updateCloudRecordingRequestActionType, UpdateLiveStreamModel>
    export type updateCloudRecordingRequestSuccessAction = Action<typeof Constants.updateCloudRecordingRequestSuccessActionType, null>;
    export type updateCloudRecordingRequestFailureAction = Action<typeof Constants.updateCloudRecordingRequestFailureActionType, null>;

    export function updateCloudRecordingRequest(P: UpdateLiveStreamModel): updateCloudRecordingRequestAction {
        return ActionCreator(Constants.updateCloudRecordingRequestActionType, P);
    }
    export function updateCloudRecordingRequestSuccess(): updateCloudRecordingRequestSuccessAction {
        return ActionCreator(Constants.updateCloudRecordingRequestSuccessActionType, null);
    }
    export function updateCloudRecordingRequestFailure(): updateCloudRecordingRequestFailureAction {
        return ActionCreator(Constants.updateCloudRecordingRequestFailureActionType, null);
    }

    export type updateAutoPublishOnDemandRequestAction = Action<typeof Constants.updateAutoPublishOnDemandRequestActionType, UpdateLiveStreamModel>
    export type updateAutoPublishOnDemandRequestSuccessAction = Action<typeof Constants.updateAutoPublishOnDemandRequestSuccessActionType, null>;
    export type updateAutoPublishOnDemandRequestFailureAction = Action<typeof Constants.updateAutoPublishOnDemandRequestFailureActionType, null>;

    export function updateAutoPublishOnDemandRequest(P: UpdateLiveStreamModel): updateAutoPublishOnDemandRequestAction {
        return ActionCreator(Constants.updateAutoPublishOnDemandRequestActionType, P);
    }
    export function updateAutoPublishOnDemandRequestSuccess(): updateAutoPublishOnDemandRequestSuccessAction {
        return ActionCreator(Constants.updateAutoPublishOnDemandRequestSuccessActionType, null);
    }
    export function updateAutoPublishOnDemandRequestFailure(): updateAutoPublishOnDemandRequestFailureAction {
        return ActionCreator(Constants.updateAutoPublishOnDemandRequestFailureActionType, null);
    }

    export type getEcommerceRequestAction = Action<typeof Constants.getEcommerceRequestActionType, null>
    export type getEcommerceRequestSuccessAction = Action<typeof Constants.getEcommerceRequestSuccessActionType, EcommerceModel>;
    export type getEcommerceRequestFailureAction = Action<typeof Constants.getEcommerceRequestFailureActionType, null>;

    export function getEcommerceRequest(): getEcommerceRequestAction {
        return ActionCreator(Constants.getEcommerceRequestActionType, null);
    }
    export function getEcommerceRequestSuccess(P: EcommerceModel): getEcommerceRequestSuccessAction {
        return ActionCreator(Constants.getEcommerceRequestSuccessActionType, P);
    }
    export function getEcommerceRequestFailure(): getEcommerceRequestFailureAction {
        return ActionCreator(Constants.getEcommerceRequestFailureActionType, null);
    }

    export type saveEcommerceRequestAction = Action<typeof Constants.saveEcommerceRequestActionType, UpdateEcommerceModel>
    export type saveEcommerceRequestSuccessAction = Action<typeof Constants.saveEcommerceRequestSuccessActionType, null>;
    export type saveEcommerceRequestFailureAction = Action<typeof Constants.saveEcommerceRequestFailureActionType, null>;

    export function saveEcommerceRequest(P: UpdateEcommerceModel): saveEcommerceRequestAction {
        return ActionCreator(Constants.saveEcommerceRequestActionType, P);
    }
    export function saveEcommerceRequestSuccess(): saveEcommerceRequestSuccessAction {
        return ActionCreator(Constants.saveEcommerceRequestSuccessActionType, null);
    }
    export function saveEcommerceRequestFailure(): saveEcommerceRequestFailureAction {
        return ActionCreator(Constants.saveEcommerceRequestFailureActionType, null);
    }

    export type getVideoLogsRequestAction = Action<typeof Constants.getVideoLogsRequestActionType, null>
    export type getVideoLogsRequestSuccessAction = Action<typeof Constants.getVideoLogsRequestSuccessActionType, VideoLogsModel[]>;
    export type getVideoLogsRequestFailureAction = Action<typeof Constants.getVideoLogsRequestFailureActionType, null>;

    export function getVideoLogsRequest(): getVideoLogsRequestAction {
        return ActionCreator(Constants.getVideoLogsRequestActionType, null);
    }
    export function getVideoLogsRequestSuccess(P: VideoLogsModel[]): getVideoLogsRequestSuccessAction {
        return ActionCreator(Constants.getVideoLogsRequestSuccessActionType, P);
    }
    export function getVideoLogsRequestFailure(): getVideoLogsRequestFailureAction {
        return ActionCreator(Constants.getVideoLogsRequestFailureActionType, null);
    }

    export type getChannelLogsRequestAction = Action<typeof Constants.getChannelLogsRequestActionType, null>
    export type getChannelLogsRequestSuccessAction = Action<typeof Constants.getChannelLogsRequestSuccessActionType, ChannelLogsModel[]>;
    export type getChannelLogsRequestFailureAction = Action<typeof Constants.getChannelLogsRequestFailureActionType, null>;

    export function getChannelLogsRequest(): getChannelLogsRequestAction {
        return ActionCreator(Constants.getChannelLogsRequestActionType, null);
    }
    export function getChannelLogsRequestSuccess(P: ChannelLogsModel[]): getChannelLogsRequestSuccessAction {
        return ActionCreator(Constants.getChannelLogsRequestSuccessActionType, P);
    }
    export function getChannelLogsRequestFailure(): getChannelLogsRequestFailureAction {
        return ActionCreator(Constants.getChannelLogsRequestFailureActionType, null);
    }

    export type getAnalyticsLogsRequestAction = Action<typeof Constants.getAnalyticsLogsRequestActionType, null>
    export type getAnalyticsLogsRequestSuccessAction = Action<typeof Constants.getAnalyticsLogsRequestSuccessActionType, AnalyticsLogsModel[]>;
    export type getAnalyticsLogsRequestFailureAction = Action<typeof Constants.getAnalyticsLogsRequestFailureActionType, null>;

    export function getAnalyticsLogsRequest(): getAnalyticsLogsRequestAction {
        return ActionCreator(Constants.getAnalyticsLogsRequestActionType, null);
    }
    export function getAnalyticsLogsRequestSuccess(P: AnalyticsLogsModel[]): getAnalyticsLogsRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsLogsRequestSuccessActionType, P);
    }
    export function getAnalyticsLogsRequestFailure(): getAnalyticsLogsRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsLogsRequestFailureActionType, null);
    }

    export type runUpdateRequestAction = Action<typeof Constants.runUpdateRequestActionType, null>
    export type runUpdateRequestSuccessAction = Action<typeof Constants.runUpdateRequestSuccessActionType, null>;
    export type runUpdateRequestFailureAction = Action<typeof Constants.runUpdateRequestFailureActionType, null>;

    export function runUpdateRequest(): runUpdateRequestAction {
        return ActionCreator(Constants.runUpdateRequestActionType, null);
    }
    export function runUpdateRequestSuccess(): runUpdateRequestSuccessAction {
        return ActionCreator(Constants.runUpdateRequestSuccessActionType, null);
    }
    export function runUpdateRequestFailure(): runUpdateRequestFailureAction {
        return ActionCreator(Constants.runUpdateRequestFailureActionType, null);
    }

    export type enableIntegrationProviderRequestAction = Action<typeof Constants.enableIntegrationProviderRequestActionType, EnableIntegrationModel>
    export type enableIntegrationProviderRequestSuccessAction = Action<typeof Constants.enableIntegrationProviderRequestSuccessActionType, null>;
    export type enableIntegrationProviderRequestFailureAction = Action<typeof Constants.enableIntegrationProviderRequestFailureActionType, null>;

    export function enableIntegrationProviderRequest(P: EnableIntegrationModel): enableIntegrationProviderRequestAction {
        return ActionCreator(Constants.enableIntegrationProviderRequestActionType, P);
    }
    export function enableIntegrationProviderRequestSuccess(): enableIntegrationProviderRequestSuccessAction {
        return ActionCreator(Constants.enableIntegrationProviderRequestSuccessActionType, null);
    }
    export function enableIntegrationProviderRequestFailure(): enableIntegrationProviderRequestFailureAction {
        return ActionCreator(Constants.enableIntegrationProviderRequestFailureActionType, null);
    }

    export type getImisIntegrationRequestAction = Action<typeof Constants.getImisIntegrationRequestActionType, null>
    export type getImisIntegrationRequestSuccessAction = Action<typeof Constants.getImisIntegrationRequestSuccessActionType, ImisIntegrationModel>;
    export type getImisIntegrationRequestFailureAction = Action<typeof Constants.getImisIntegrationRequestFailureActionType, null>;

    export function getImisIntegrationRequest(): getImisIntegrationRequestAction {
        return ActionCreator(Constants.getImisIntegrationRequestActionType, null);
    }
    export function getImisIntegrationRequestSuccess(P: ImisIntegrationModel): getImisIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getImisIntegrationRequestSuccessActionType, P);
    }
    export function getImisIntegrationRequestFailure(): getImisIntegrationRequestFailureAction {
        return ActionCreator(Constants.getImisIntegrationRequestFailureActionType, null);
    }
    export type addImisIntegrationRequestAction = Action<typeof Constants.addImisIntegrationRequestActionType, ImisIntegrationModel>
    export type addImisIntegrationRequestSuccessAction = Action<typeof Constants.addImisIntegrationRequestSuccessActionType, null>;
    export type addImisIntegrationRequestFailureAction = Action<typeof Constants.addImisIntegrationRequestFailureActionType, null>;

    export function addImisIntegrationRequest(P: ImisIntegrationModel): addImisIntegrationRequestAction {
        return ActionCreator(Constants.addImisIntegrationRequestActionType, P);
    }
    export function addImisIntegrationRequestSuccess(): addImisIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addImisIntegrationRequestSuccessActionType, null);
    }
    export function addImisIntegrationRequestFailure(): addImisIntegrationRequestFailureAction {
        return ActionCreator(Constants.addImisIntegrationRequestFailureActionType, null);
    }

    export type getMemberEvolutionIntegrationRequestAction = Action<typeof Constants.getMemberEvolutionIntegrationRequestActionType, null>
    export type getMemberEvolutionIntegrationRequestSuccessAction = Action<typeof Constants.getMemberEvolutionIntegrationRequestSuccessActionType, MemberEvolutionIntegrationModel>;
    export type getMemberEvolutionIntegrationRequestFailureAction = Action<typeof Constants.getMemberEvolutionIntegrationRequestFailureActionType, null>;

    export function getMemberEvolutionIntegrationRequest(): getMemberEvolutionIntegrationRequestAction {
        return ActionCreator(Constants.getMemberEvolutionIntegrationRequestActionType, null);
    }
    export function getMemberEvolutionIntegrationRequestSuccess(P: MemberEvolutionIntegrationModel): getMemberEvolutionIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getMemberEvolutionIntegrationRequestSuccessActionType, P);
    }
    export function getMemberEvolutionIntegrationRequestFailure(): getMemberEvolutionIntegrationRequestFailureAction {
        return ActionCreator(Constants.getMemberEvolutionIntegrationRequestFailureActionType, null);
    }
    export type addMemberEvolutionIntegrationRequestAction = Action<typeof Constants.addMemberEvolutionIntegrationRequestActionType, MemberEvolutionIntegrationModel>
    export type addMemberEvolutionIntegrationRequestSuccessAction = Action<typeof Constants.addMemberEvolutionIntegrationRequestSuccessActionType, null>;
    export type addMemberEvolutionIntegrationRequestFailureAction = Action<typeof Constants.addMemberEvolutionIntegrationRequestFailureActionType, null>;

    export function addMemberEvolutionIntegrationRequest(P: MemberEvolutionIntegrationModel): addMemberEvolutionIntegrationRequestAction {
        return ActionCreator(Constants.addMemberEvolutionIntegrationRequestActionType, P);
    }
    export function addMemberEvolutionIntegrationRequestSuccess(): addMemberEvolutionIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addMemberEvolutionIntegrationRequestSuccessActionType, null);
    }
    export function addMemberEvolutionIntegrationRequestFailure(): addMemberEvolutionIntegrationRequestFailureAction {
        return ActionCreator(Constants.addMemberEvolutionIntegrationRequestFailureActionType, null);
    }

    export type getWicketIntegrationRequestAction = Action<typeof Constants.getWicketIntegrationRequestActionType, null>
    export type getWicketIntegrationRequestSuccessAction = Action<typeof Constants.getWicketIntegrationRequestSuccessActionType, WicketIntegrationModel>;
    export type getWicketIntegrationRequestFailureAction = Action<typeof Constants.getWicketIntegrationRequestFailureActionType, null>;

    export function getWicketIntegrationRequest(): getWicketIntegrationRequestAction {
        return ActionCreator(Constants.getWicketIntegrationRequestActionType, null);
    }
    export function getWicketIntegrationRequestSuccess(P: WicketIntegrationModel): getWicketIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getWicketIntegrationRequestSuccessActionType, P);
    }
    export function getWicketIntegrationRequestFailure(): getWicketIntegrationRequestFailureAction {
        return ActionCreator(Constants.getWicketIntegrationRequestFailureActionType, null);
    }
    export type addWicketIntegrationRequestAction = Action<typeof Constants.addWicketIntegrationRequestActionType, WicketIntegrationModel>
    export type addWicketIntegrationRequestSuccessAction = Action<typeof Constants.addWicketIntegrationRequestSuccessActionType, null>;
    export type addWicketIntegrationRequestFailureAction = Action<typeof Constants.addWicketIntegrationRequestFailureActionType, null>;

    export function addWicketIntegrationRequest(P: WicketIntegrationModel): addWicketIntegrationRequestAction {
        return ActionCreator(Constants.addWicketIntegrationRequestActionType, P);
    }
    export function addWicketIntegrationRequestSuccess(): addWicketIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addWicketIntegrationRequestSuccessActionType, null);
    }
    export function addWicketIntegrationRequestFailure(): addWicketIntegrationRequestFailureAction {
        return ActionCreator(Constants.addWicketIntegrationRequestFailureActionType, null);
    }

    export type getDynamics365IntegrationRequestAction = Action<typeof Constants.getDynamics365IntegrationRequestActionType, null>
    export type getDynamics365IntegrationRequestSuccessAction = Action<typeof Constants.getDynamics365IntegrationRequestSuccessActionType, Dynamics365IntegrationModel>;
    export type getDynamics365IntegrationRequestFailureAction = Action<typeof Constants.getDynamics365IntegrationRequestFailureActionType, null>;

    export function getDynamics365IntegrationRequest(): getDynamics365IntegrationRequestAction {
        return ActionCreator(Constants.getDynamics365IntegrationRequestActionType, null);
    }
    export function getDynamics365IntegrationRequestSuccess(P: Dynamics365IntegrationModel): getDynamics365IntegrationRequestSuccessAction {
        return ActionCreator(Constants.getDynamics365IntegrationRequestSuccessActionType, P);
    }
    export function getDynamics365IntegrationRequestFailure(): getDynamics365IntegrationRequestFailureAction {
        return ActionCreator(Constants.getDynamics365IntegrationRequestFailureActionType, null);
    }
    export type addDynamics365IntegrationRequestAction = Action<typeof Constants.addDynamics365IntegrationRequestActionType, Dynamics365IntegrationModel>
    export type addDynamics365IntegrationRequestSuccessAction = Action<typeof Constants.addDynamics365IntegrationRequestSuccessActionType, null>;
    export type addDynamics365IntegrationRequestFailureAction = Action<typeof Constants.addDynamics365IntegrationRequestFailureActionType, null>;

    export function addDynamics365IntegrationRequest(P: Dynamics365IntegrationModel): addDynamics365IntegrationRequestAction {
        return ActionCreator(Constants.addDynamics365IntegrationRequestActionType, P);
    }
    export function addDynamics365IntegrationRequestSuccess(): addDynamics365IntegrationRequestSuccessAction {
        return ActionCreator(Constants.addDynamics365IntegrationRequestSuccessActionType, null);
    }
    export function addDynamics365IntegrationRequestFailure(): addDynamics365IntegrationRequestFailureAction {
        return ActionCreator(Constants.addDynamics365IntegrationRequestFailureActionType, null);
    }

    //
    export type getMicrosoftAzureIntegrationRequestAction = Action<typeof Constants.getMicrosoftAzureIntegrationRequestActionType, null>
    export type getMicrosoftAzureIntegrationRequestSuccessAction = Action<typeof Constants.getMicrosoftAzureIntegrationRequestSuccessActionType, MicrosoftAzureIntegrationModel>;
    export type getMicrosoftAzureIntegrationRequestFailureAction = Action<typeof Constants.getMicrosoftAzureIntegrationRequestFailureActionType, null>;

    export function getMicrosoftAzureIntegrationRequest(): getMicrosoftAzureIntegrationRequestAction {
        return ActionCreator(Constants.getMicrosoftAzureIntegrationRequestActionType, null);
    }
    export function getMicrosoftAzureIntegrationRequestSuccess(P: MicrosoftAzureIntegrationModel): getMicrosoftAzureIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getMicrosoftAzureIntegrationRequestSuccessActionType, P);
    }
    export function getMicrosoftAzureIntegrationRequestFailure(): getMicrosoftAzureIntegrationRequestFailureAction {
        return ActionCreator(Constants.getMicrosoftAzureIntegrationRequestFailureActionType, null);
    }
    export type addMicrosoftAzureIntegrationRequestAction = Action<typeof Constants.addMicrosoftAzureIntegrationRequestActionType, MicrosoftAzureIntegrationModel>
    export type addMicrosoftAzureIntegrationRequestSuccessAction = Action<typeof Constants.addMicrosoftAzureIntegrationRequestSuccessActionType, null>;
    export type addMicrosoftAzureIntegrationRequestFailureAction = Action<typeof Constants.addMicrosoftAzureIntegrationRequestFailureActionType, null>;

    export function addMicrosoftAzureIntegrationRequest(P: MicrosoftAzureIntegrationModel): addMicrosoftAzureIntegrationRequestAction {
        return ActionCreator(Constants.addMicrosoftAzureIntegrationRequestActionType, P);
    }
    export function addMicrosoftAzureIntegrationRequestSuccess(): addMicrosoftAzureIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addMicrosoftAzureIntegrationRequestSuccessActionType, null);
    }
    export function addMicrosoftAzureIntegrationRequestFailure(): addMicrosoftAzureIntegrationRequestFailureAction {
        return ActionCreator(Constants.addMicrosoftAzureIntegrationRequestFailureActionType, null);
    }

    export type getNoviAMSIntegrationRequestAction = Action<typeof Constants.getNoviAMSIntegrationRequestActionType, null>
    export type getNoviAMSIntegrationRequestSuccessAction = Action<typeof Constants.getNoviAMSIntegrationRequestSuccessActionType, NoviAMSIntegrationModel>;
    export type getNoviAMSIntegrationRequestFailureAction = Action<typeof Constants.getNoviAMSIntegrationRequestFailureActionType, null>;

    export function getNoviAMSIntegrationRequest(): getNoviAMSIntegrationRequestAction {
        return ActionCreator(Constants.getNoviAMSIntegrationRequestActionType, null);
    }
    export function getNoviAMSIntegrationRequestSuccess(P: NoviAMSIntegrationModel): getNoviAMSIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getNoviAMSIntegrationRequestSuccessActionType, P);
    }
    export function getNoviAMSIntegrationRequestFailure(): getNoviAMSIntegrationRequestFailureAction {
        return ActionCreator(Constants.getNoviAMSIntegrationRequestFailureActionType, null);
    }
    export type addNoviAMSIntegrationRequestAction = Action<typeof Constants.addNoviAMSIntegrationRequestActionType, NoviAMSIntegrationModel>
    export type addNoviAMSIntegrationRequestSuccessAction = Action<typeof Constants.addNoviAMSIntegrationRequestSuccessActionType, null>;
    export type addNoviAMSIntegrationRequestFailureAction = Action<typeof Constants.addNoviAMSIntegrationRequestFailureActionType, null>;

    export function addNoviAMSIntegrationRequest(P: NoviAMSIntegrationModel): addNoviAMSIntegrationRequestAction {
        return ActionCreator(Constants.addNoviAMSIntegrationRequestActionType, P);
    }
    export function addNoviAMSIntegrationRequestSuccess(): addNoviAMSIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addNoviAMSIntegrationRequestSuccessActionType, null);
    }
    export function addNoviAMSIntegrationRequestFailure(): addNoviAMSIntegrationRequestFailureAction {
        return ActionCreator(Constants.addNoviAMSIntegrationRequestFailureActionType, null);
    }

    export type getOAuth2IntegrationRequestAction = Action<typeof Constants.getOAuth2IntegrationRequestActionType, null>
    export type getOAuth2IntegrationRequestSuccessAction = Action<typeof Constants.getOAuth2IntegrationRequestSuccessActionType, OAuth2IntegrationModel>;
    export type getOAuth2IntegrationRequestFailureAction = Action<typeof Constants.getOAuth2IntegrationRequestFailureActionType, null>;

    export function getOAuth2IntegrationRequest(): getOAuth2IntegrationRequestAction {
        return ActionCreator(Constants.getOAuth2IntegrationRequestActionType, null);
    }
    export function getOAuth2IntegrationRequestSuccess(P: OAuth2IntegrationModel): getOAuth2IntegrationRequestSuccessAction {
        return ActionCreator(Constants.getOAuth2IntegrationRequestSuccessActionType, P);
    }
    export function getOAuth2IntegrationRequestFailure(): getOAuth2IntegrationRequestFailureAction {
        return ActionCreator(Constants.getOAuth2IntegrationRequestFailureActionType, null);
    }
    export type addOAuth2IntegrationRequestAction = Action<typeof Constants.addOAuth2IntegrationRequestActionType, OAuth2IntegrationModel>
    export type addOAuth2IntegrationRequestSuccessAction = Action<typeof Constants.addOAuth2IntegrationRequestSuccessActionType, null>;
    export type addOAuth2IntegrationRequestFailureAction = Action<typeof Constants.addOAuth2IntegrationRequestFailureActionType, null>;

    export function addOAuth2IntegrationRequest(P: OAuth2IntegrationModel): addOAuth2IntegrationRequestAction {
        return ActionCreator(Constants.addOAuth2IntegrationRequestActionType, P);
    }
    export function addOAuth2IntegrationRequestSuccess(): addOAuth2IntegrationRequestSuccessAction {
        return ActionCreator(Constants.addOAuth2IntegrationRequestSuccessActionType, null);
    }
    export function addOAuth2IntegrationRequestFailure(): addOAuth2IntegrationRequestFailureAction {
        return ActionCreator(Constants.addOAuth2IntegrationRequestFailureActionType, null);
    }

    export type getMemberClicksIntegrationRequestAction = Action<typeof Constants.getMemberClicksIntegrationRequestActionType, null>
    export type getMemberClicksIntegrationRequestSuccessAction = Action<typeof Constants.getMemberClicksIntegrationRequestSuccessActionType, MemberClicksIntegrationModel>;
    export type getMemberClicksIntegrationRequestFailureAction = Action<typeof Constants.getMemberClicksIntegrationRequestFailureActionType, null>;

    export function getMemberClicksIntegrationRequest(): getMemberClicksIntegrationRequestAction {
        return ActionCreator(Constants.getMemberClicksIntegrationRequestActionType, null);
    }
    export function getMemberClicksIntegrationRequestSuccess(P: MemberClicksIntegrationModel): getMemberClicksIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getMemberClicksIntegrationRequestSuccessActionType, P);
    }
    export function getMemberClicksIntegrationRequestFailure(): getMemberClicksIntegrationRequestFailureAction {
        return ActionCreator(Constants.getMemberClicksIntegrationRequestFailureActionType, null);
    }
    export type addMemberClicksIntegrationRequestAction = Action<typeof Constants.addMemberClicksIntegrationRequestActionType, MemberClicksIntegrationModel>
    export type addMemberClicksIntegrationRequestSuccessAction = Action<typeof Constants.addMemberClicksIntegrationRequestSuccessActionType, null>;
    export type addMemberClicksIntegrationRequestFailureAction = Action<typeof Constants.addMemberClicksIntegrationRequestFailureActionType, null>;

    export function addMemberClicksIntegrationRequest(P: MemberClicksIntegrationModel): addMemberClicksIntegrationRequestAction {
        return ActionCreator(Constants.addMemberClicksIntegrationRequestActionType, P);
    }
    export function addMemberClicksIntegrationRequestSuccess(): addMemberClicksIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addMemberClicksIntegrationRequestSuccessActionType, null);
    }
    export function addMemberClicksIntegrationRequestFailure(): addMemberClicksIntegrationRequestFailureAction {
        return ActionCreator(Constants.addMemberClicksIntegrationRequestFailureActionType, null);
    }

    export type getPersonifyIntegrationRequestAction = Action<typeof Constants.getPersonifyIntegrationRequestActionType, null>
    export type getPersonifyIntegrationRequestSuccessAction = Action<typeof Constants.getPersonifyIntegrationRequestSuccessActionType, PersonifyIntegrationModel>;
    export type getPersonifyIntegrationRequestFailureAction = Action<typeof Constants.getPersonifyIntegrationRequestFailureActionType, null>;

    export function getPersonifyIntegrationRequest(): getPersonifyIntegrationRequestAction {
        return ActionCreator(Constants.getPersonifyIntegrationRequestActionType, null);
    }
    export function getPersonifyIntegrationRequestSuccess(P: PersonifyIntegrationModel): getPersonifyIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getPersonifyIntegrationRequestSuccessActionType, P);
    }
    export function getPersonifyIntegrationRequestFailure(): getPersonifyIntegrationRequestFailureAction {
        return ActionCreator(Constants.getPersonifyIntegrationRequestFailureActionType, null);
    }

    export type addPersonifyIntegrationRequestAction = Action<typeof Constants.addPersonifyIntegrationRequestActionType, PersonifyIntegrationModel>
    export type addPersonifyIntegrationRequestSuccessAction = Action<typeof Constants.addPersonifyIntegrationRequestSuccessActionType, null>;
    export type addPersonifyIntegrationRequestFailureAction = Action<typeof Constants.addPersonifyIntegrationRequestFailureActionType, null>;
    export function addPersonifyIntegrationRequest(P: PersonifyIntegrationModel): addPersonifyIntegrationRequestAction {
        return ActionCreator(Constants.addPersonifyIntegrationRequestActionType, P);
    }
    export function addPersonifyIntegrationRequestSuccess(): addPersonifyIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addPersonifyIntegrationRequestSuccessActionType, null);
    }
    export function addPersonifyIntegrationRequestFailure(): addPersonifyIntegrationRequestFailureAction {
        return ActionCreator(Constants.addPersonifyIntegrationRequestFailureActionType, null);
    }

    export type savePersonifyIntegrationRequestAction = Action<typeof Constants.savePersonifyIntegrationRequestActionType, PersonifyIntegrationModel>
    export type savePersonifyIntegrationRequestSuccessAction = Action<typeof Constants.savePersonifyIntegrationRequestSuccessActionType, null>;
    export type savePersonifyIntegrationRequestFailureAction = Action<typeof Constants.savePersonifyIntegrationRequestFailureActionType, null>;

    export function savePersonifyIntegrationRequest(P: PersonifyIntegrationModel): savePersonifyIntegrationRequestAction {
        return ActionCreator(Constants.savePersonifyIntegrationRequestActionType, P);
    }
    export function savePersonifyIntegrationRequestSuccess(): savePersonifyIntegrationRequestSuccessAction {
        return ActionCreator(Constants.savePersonifyIntegrationRequestSuccessActionType, null);
    }
    export function savePersonifyIntegrationRequestFailure(): savePersonifyIntegrationRequestFailureAction {
        return ActionCreator(Constants.savePersonifyIntegrationRequestFailureActionType, null);
    }

    export type getNaylorIntegrationRequestAction = Action<typeof Constants.getNaylorIntegrationRequestActionType, null>
    export type getNaylorIntegrationRequestSuccessAction = Action<typeof Constants.getNaylorIntegrationRequestSuccessActionType, NaylorIntegrationModel>;
    export type getNaylorIntegrationRequestFailureAction = Action<typeof Constants.getNaylorIntegrationRequestFailureActionType, null>;

    export function getNaylorIntegrationRequest(): getNaylorIntegrationRequestAction {
        return ActionCreator(Constants.getNaylorIntegrationRequestActionType, null);
    }
    export function getNaylorIntegrationRequestSuccess(P: NaylorIntegrationModel): getNaylorIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getNaylorIntegrationRequestSuccessActionType, P);
    }
    export function getNaylorIntegrationRequestFailure(): getNaylorIntegrationRequestFailureAction {
        return ActionCreator(Constants.getNaylorIntegrationRequestFailureActionType, null);
    }


    export type getSpargoIntegrationRequestAction = Action<typeof Constants.getSpargoIntegrationRequestActionType, null>
    export type getSpargoIntegrationRequestSuccessAction = Action<typeof Constants.getSpargoIntegrationRequestSuccessActionType, SpargoIntegrationModel>;
    export type getSpargoIntegrationRequestFailureAction = Action<typeof Constants.getSpargoIntegrationRequestFailureActionType, null>;

    export function getSpargoIntegrationRequest(): getSpargoIntegrationRequestAction {
        return ActionCreator(Constants.getSpargoIntegrationRequestActionType, null);
    }
    export function getSpargoIntegrationRequestSuccess(P: SpargoIntegrationModel): getSpargoIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getSpargoIntegrationRequestSuccessActionType, P);
    }
    export function getSpargoIntegrationRequestFailure(): getSpargoIntegrationRequestFailureAction {
        return ActionCreator(Constants.getSpargoIntegrationRequestFailureActionType, null);
    }

    export type getSingleApiV2RequestAction = Action<typeof Constants.getSingleApiV2RequestActionType, string>
    export type getSingleApiV2RequestSuccessAction = Action<typeof Constants.getSingleApiV2RequestSuccessActionType, ApiV2Model>;
    export type getSingleApiV2RequestFailureAction = Action<typeof Constants.getSingleApiV2RequestFailureActionType, null>;

    export function getSingleApiV2Request(P: string): getSingleApiV2RequestAction {
        return ActionCreator(Constants.getSingleApiV2RequestActionType, P);
    }
    export function getSingleApiV2RequestSuccess(P: ApiV2Model): getSingleApiV2RequestSuccessAction {
        return ActionCreator(Constants.getSingleApiV2RequestSuccessActionType, P);
    }
    export function getSingleApiV2RequestFailure(): getSingleApiV2RequestFailureAction {
        return ActionCreator(Constants.getSingleApiV2RequestFailureActionType, null);
    }

    export type updateApiV2RequestAction = Action<typeof Constants.updateApiV2RequestActionType, UpdateApiV2Model>
    export type updateApiV2RequestSuccessAction = Action<typeof Constants.updateApiV2RequestSuccessActionType, null>;
    export type updateApiV2RequestFailureAction = Action<typeof Constants.updateApiV2RequestFailureActionType, null>;

    export function updateApiV2Request(P: UpdateApiV2Model): updateApiV2RequestAction {
        return ActionCreator(Constants.updateApiV2RequestActionType, P);
    }
    export function updateApiV2RequestSuccess(): updateApiV2RequestSuccessAction {
        return ActionCreator(Constants.updateApiV2RequestSuccessActionType, null);
    }
    export function updateApiV2RequestFailure(): updateApiV2RequestFailureAction {
        return ActionCreator(Constants.updateApiV2RequestFailureActionType, null);
    }
    export type getMembesIntegrationRequestAction = Action<typeof Constants.getMembesIntegrationRequestActionType, null>
    export type getMembesIntegrationRequestSuccessAction = Action<typeof Constants.getMembesIntegrationRequestSuccessActionType, MembesIntegrationModel>;
    export type getMembesIntegrationRequestFailureAction = Action<typeof Constants.getMembesIntegrationRequestFailureActionType, null>;

    export function getMembesIntegrationRequest(): getMembesIntegrationRequestAction {
        return ActionCreator(Constants.getMembesIntegrationRequestActionType, null);
    }
    export function getMembesIntegrationRequestSuccess(P: MembesIntegrationModel): getMembesIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getMembesIntegrationRequestSuccessActionType, P);
    }
    export function getMembesIntegrationRequestFailure(): getMembesIntegrationRequestFailureAction {
        return ActionCreator(Constants.getMembesIntegrationRequestFailureActionType, null);
    }

    export type addMembesIntegrationRequestAction = Action<typeof Constants.addMembesIntegrationRequestActionType, MembesIntegrationModel>
    export type addMembesIntegrationRequestSuccessAction = Action<typeof Constants.addMembesIntegrationRequestSuccessActionType, null>;
    export type addMembesIntegrationRequestFailureAction = Action<typeof Constants.addMembesIntegrationRequestFailureActionType, null>;

    export function addMembesIntegrationRequest(P: MembesIntegrationModel): addMembesIntegrationRequestAction {
        return ActionCreator(Constants.addMembesIntegrationRequestActionType, P);
    }
    export function addMembesIntegrationRequestSuccess(): addMembesIntegrationRequestSuccessAction {
        return ActionCreator(Constants.addMembesIntegrationRequestSuccessActionType, null);
    }
    export function addMembesIntegrationRequestFailure(): addMembesIntegrationRequestFailureAction {
        return ActionCreator(Constants.addMembesIntegrationRequestFailureActionType, null);
    }

    export type getGoogleIntegrationRequestAction = Action<typeof Constants.getGoogleIntegrationRequestActionType, null>
    export type getGoogleIntegrationRequestSuccessAction = Action<typeof Constants.getGoogleIntegrationRequestSuccessActionType, GoogleIntegrationModel>;
    export type getGoogleIntegrationRequestFailureAction = Action<typeof Constants.getGoogleIntegrationRequestFailureActionType, null>;

    export function getGoogleIntegrationRequest(): getGoogleIntegrationRequestAction {
        return ActionCreator(Constants.getGoogleIntegrationRequestActionType, null);
    }
    export function getGoogleIntegrationRequestSuccess(P: GoogleIntegrationModel): getGoogleIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getGoogleIntegrationRequestSuccessActionType, P);
    }
    export function getGoogleIntegrationRequestFailure(): getGoogleIntegrationRequestFailureAction {
        return ActionCreator(Constants.getGoogleIntegrationRequestFailureActionType, null);
    }

    export type getWebCourseIntegrationRequestAction = Action<typeof Constants.getWebCourseIntegrationRequestActionType, null>
    export type getWebCourseIntegrationRequestSuccessAction = Action<typeof Constants.getWebCourseIntegrationRequestSuccessActionType, WebCourseWorksIntegrationModel>;
    export type getWebCourseIntegrationRequestFailureAction = Action<typeof Constants.getWebCourseIntegrationRequestFailureActionType, null>;

    export function getWebCourseIntegrationRequest(): getWebCourseIntegrationRequestAction {
        return ActionCreator(Constants.getWebCourseIntegrationRequestActionType, null);
    }
    export function getWebCourseIntegrationRequestSuccess(P: WebCourseWorksIntegrationModel): getWebCourseIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getWebCourseIntegrationRequestSuccessActionType, P);
    }
    export function getWebCourseIntegrationRequestFailure(): getWebCourseIntegrationRequestFailureAction {
        return ActionCreator(Constants.getWebCourseIntegrationRequestFailureActionType, null);
    }

    export type getZoomIntegrationRequestAction = Action<typeof Constants.getZoomIntegrationRequestActionType, null>
    export type getZoomIntegrationRequestSuccessAction = Action<typeof Constants.getZoomIntegrationRequestSuccessActionType, ZoomIntegrationModel>;
    export type getZoomIntegrationRequestFailureAction = Action<typeof Constants.getZoomIntegrationRequestFailureActionType, null>;

    export function getZoomIntegrationRequest(): getZoomIntegrationRequestAction {
        return ActionCreator(Constants.getZoomIntegrationRequestActionType, null);
    }
    export function getZoomIntegrationRequestSuccess(P: ZoomIntegrationModel): getZoomIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getZoomIntegrationRequestSuccessActionType, P);
    }
    export function getZoomIntegrationRequestFailure(): getZoomIntegrationRequestFailureAction {
        return ActionCreator(Constants.getZoomIntegrationRequestFailureActionType, null);
    }

    export type enablePaymentProviderRequestAction = Action<typeof Constants.enablePaymentProviderRequestActionType, EnableIntegrationModel>
    export type enablePaymentProviderRequestSuccessAction = Action<typeof Constants.enablePaymentProviderRequestSuccessActionType, null>;
    export type enablePaymentProviderRequestFailureAction = Action<typeof Constants.enablePaymentProviderRequestFailureActionType, null>;

    export function enablePaymentProviderRequest(P: EnableIntegrationModel): enablePaymentProviderRequestAction {
        return ActionCreator(Constants.enablePaymentProviderRequestActionType, P);
    }
    export function enablePaymentProviderRequestSuccess(): enablePaymentProviderRequestSuccessAction {
        return ActionCreator(Constants.enablePaymentProviderRequestSuccessActionType, null);
    }
    export function enablePaymentProviderRequestFailure(): enablePaymentProviderRequestFailureAction {
        return ActionCreator(Constants.enablePaymentProviderRequestFailureActionType, null);
    }

    export type getPaypalPayFlowProviderRequestAction = Action<typeof Constants.getPaypalPayFlowProviderRequestActionType, null>
    export type getPaypalPayFlowProviderRequestSuccessAction = Action<typeof Constants.getPaypalPayFlowProviderRequestSuccessActionType, PaypalPayFlowModel>;
    export type getPaypalPayFlowProviderRequestFailureAction = Action<typeof Constants.getPaypalPayFlowProviderRequestFailureActionType, null>;

    export function getPaypalPayFlowProviderRequest(): getPaypalPayFlowProviderRequestAction {
        return ActionCreator(Constants.getPaypalPayFlowProviderRequestActionType, null);
    }
    export function getPaypalPayFlowProviderRequestSuccess(P: PaypalPayFlowModel): getPaypalPayFlowProviderRequestSuccessAction {
        return ActionCreator(Constants.getPaypalPayFlowProviderRequestSuccessActionType, P);
    }
    export function getPaypalPayFlowProviderRequestFailure(): getPaypalPayFlowProviderRequestFailureAction {
        return ActionCreator(Constants.getPaypalPayFlowProviderRequestFailureActionType, null);
    }
    export type addPaypalPayFlowProviderRequestAction = Action<typeof Constants.addPaypalPayFlowProviderRequestActionType, PaypalModel>
    export type addPaypalPayFlowProviderRequestSuccessAction = Action<typeof Constants.addPaypalPayFlowProviderRequestSuccessActionType, null>;
    export type addPaypalPayFlowProviderRequestFailureAction = Action<typeof Constants.addPaypalPayFlowProviderRequestFailureActionType, null>;

    export function addPaypalPayFlowProviderRequest(P: PaypalModel): addPaypalPayFlowProviderRequestAction {
        return ActionCreator(Constants.addPaypalPayFlowProviderRequestActionType, P);
    }
    export function addPaypalPayFlowProviderRequestSuccess(): addPaypalPayFlowProviderRequestSuccessAction {
        return ActionCreator(Constants.addPaypalPayFlowProviderRequestSuccessActionType, null);
    }
    export function addPaypalPayFlowProviderRequestFailure(): addPaypalPayFlowProviderRequestFailureAction {
        return ActionCreator(Constants.addPaypalPayFlowProviderRequestFailureActionType, null);
    }
    
    export type getEziDebitProviderRequestAction = Action<typeof Constants.getEziDebitProviderRequestActionType, null>
    export type getEziDebitProviderRequestSuccessAction = Action<typeof Constants.getEziDebitProviderRequestSuccessActionType, EzidebitModel>;
    export type getEziDebitProviderRequestFailureAction = Action<typeof Constants.getEziDebitProviderRequestFailureActionType, null>;

    export function getEziDebitProviderRequest(): getEziDebitProviderRequestAction {
        return ActionCreator(Constants.getEziDebitProviderRequestActionType, null);
    }
    export function getEziDebitProviderRequestSuccess(P: EzidebitModel): getEziDebitProviderRequestSuccessAction {
        return ActionCreator(Constants.getEziDebitProviderRequestSuccessActionType, P);
    }
    export function getEziDebitProviderRequestFailure(): getEziDebitProviderRequestFailureAction {
        return ActionCreator(Constants.getEziDebitProviderRequestFailureActionType, null);
    }

    export type getStripeProviderRequestAction = Action<typeof Constants.getStripeProviderRequestActionType, null>
    export type getStripeProviderRequestSuccessAction = Action<typeof Constants.getStripeProviderRequestSuccessActionType, StripeModel>;
    export type getStripeProviderRequestFailureAction = Action<typeof Constants.getStripeProviderRequestFailureActionType, null>;

    export function getStripeProviderRequest(): getStripeProviderRequestAction {
        return ActionCreator(Constants.getStripeProviderRequestActionType, null);
    }
    export function getStripeProviderRequestSuccess(P: StripeModel): getStripeProviderRequestSuccessAction {
        return ActionCreator(Constants.getStripeProviderRequestSuccessActionType, P);
    }
    export function getStripeProviderRequestFailure(): getStripeProviderRequestFailureAction {
        return ActionCreator(Constants.getStripeProviderRequestFailureActionType, null);
    }

    export type addStripeProviderRequestAction = Action<typeof Constants.addStripeProviderRequestActionType, StripeModel>
    export type addStripeProviderRequestSuccessAction = Action<typeof Constants.addStripeProviderRequestSuccessActionType, null>;
    export type addStripeProviderRequestFailureAction = Action<typeof Constants.addStripeProviderRequestFailureActionType, null>;

    export function addStripeProviderRequest(P: StripeModel): addStripeProviderRequestAction {
        return ActionCreator(Constants.addStripeProviderRequestActionType, P);
    }
    export function addStripeProviderRequestSuccess(): addStripeProviderRequestSuccessAction {
        return ActionCreator(Constants.addStripeProviderRequestSuccessActionType, null);
    }
    export function addStripeProviderRequestFailure(): addStripeProviderRequestFailureAction {
        return ActionCreator(Constants.addStripeProviderRequestFailureActionType, null);
    }

    export type getPayPalProviderRequestAction = Action<typeof Constants.getPayPalProviderRequestActionType, null>
    export type getPayPalProviderRequestSuccessAction = Action<typeof Constants.getPayPalProviderRequestSuccessActionType, PaypalModel>;
    export type getPayPalProviderRequestFailureAction = Action<typeof Constants.getPayPalProviderRequestFailureActionType, null>;

    export function getPayPalProviderRequest(): getPayPalProviderRequestAction {
        return ActionCreator(Constants.getPayPalProviderRequestActionType, null);
    }
    export function getPayPalProviderRequestSuccess(P: PaypalModel): getPayPalProviderRequestSuccessAction {
        return ActionCreator(Constants.getPayPalProviderRequestSuccessActionType, P);
    }
    export function getPayPalProviderRequestFailure(): getPayPalProviderRequestFailureAction {
        return ActionCreator(Constants.getPayPalProviderRequestFailureActionType, null);
    }
    export type addPayPalProviderRequestAction = Action<typeof Constants.addPayPalProviderRequestActionType, PaypalModel>
    export type addPayPalProviderRequestSuccessAction = Action<typeof Constants.addPayPalProviderRequestSuccessActionType, null>;
    export type addPayPalProviderRequestFailureAction = Action<typeof Constants.addPayPalProviderRequestFailureActionType, null>;

    export function addPayPalProviderRequest(P: PaypalModel): addPayPalProviderRequestAction {
        return ActionCreator(Constants.addPayPalProviderRequestActionType, P);
    }
    export function addPayPalProviderRequestSuccess(): addPayPalProviderRequestSuccessAction {
        return ActionCreator(Constants.addPayPalProviderRequestSuccessActionType, null);
    }
    export function addPayPalProviderRequestFailure(): addPayPalProviderRequestFailureAction {
        return ActionCreator(Constants.addPayPalProviderRequestFailureActionType, null);
    }

    export type getBPointProviderRequestAction = Action<typeof Constants.getBPointProviderRequestActionType, null>
    export type getBPointProviderRequestSuccessAction = Action<typeof Constants.getBPointProviderRequestSuccessActionType, BPointModel>;
    export type getBPointProviderRequestFailureAction = Action<typeof Constants.getBPointProviderRequestFailureActionType, null>;

    export function getBPointProviderRequest(): getBPointProviderRequestAction {
        return ActionCreator(Constants.getBPointProviderRequestActionType, null);
    }
    export function getBPointProviderRequestSuccess(P: BPointModel): getBPointProviderRequestSuccessAction {
        return ActionCreator(Constants.getBPointProviderRequestSuccessActionType, P);
    }
    export function getBPointProviderRequestFailure(): getBPointProviderRequestFailureAction {
        return ActionCreator(Constants.getBPointProviderRequestFailureActionType, null);
    }

    export type getLivePayProviderRequestAction = Action<typeof Constants.getLivePayProviderRequestActionType, null>
    export type getLivePayProviderRequestSuccessAction = Action<typeof Constants.getLivePayProviderRequestSuccessActionType, LivePayModel>;
    export type getLivePayProviderRequestFailureAction = Action<typeof Constants.getLivePayProviderRequestFailureActionType, null>;

    export function getLivePayProviderRequest(): getLivePayProviderRequestAction {
        return ActionCreator(Constants.getLivePayProviderRequestActionType, null);
    }
    export function getLivePayProviderRequestSuccess(P: LivePayModel): getLivePayProviderRequestSuccessAction {
        return ActionCreator(Constants.getLivePayProviderRequestSuccessActionType, P);
    }
    export function getLivePayProviderRequestFailure(): getLivePayProviderRequestFailureAction {
        return ActionCreator(Constants.getLivePayProviderRequestFailureActionType, null);
    }

    export type getCePointsRequestAction = Action<typeof Constants.getCePointsRequestActionType, null>
    export type getCePointsRequestSuccessAction = Action<typeof Constants.getCePointsRequestSuccessActionType, EducationPointsDetailModel>;
    export type getCePointsRequestFailureAction = Action<typeof Constants.getCePointsRequestFailureActionType, null>;

    export function getCePointsRequest(): getCePointsRequestAction {
        return ActionCreator(Constants.getCePointsRequestActionType, null);
    }
    export function getCePointsRequestSuccess(P: EducationPointsDetailModel): getCePointsRequestSuccessAction {
        return ActionCreator(Constants.getCePointsRequestSuccessActionType, P);
    }
    export function getCePointsRequestFailure(): getCePointsRequestFailureAction {
        return ActionCreator(Constants.getCePointsRequestFailureActionType, null);
    }

    export type saveCePointsRequestAction = Action<typeof Constants.saveCePointsRequestActionType, CePointModel>
    export type saveCePointsRequestSuccessAction = Action<typeof Constants.saveCePointsRequestSuccessActionType, null>;
    export type saveCePointsRequestFailureAction = Action<typeof Constants.saveCePointsRequestFailureActionType, null>;

    export function saveCePointsRequest(P: CePointModel): saveCePointsRequestAction {
        return ActionCreator(Constants.saveCePointsRequestActionType, P);
    }
    export function saveCePointsRequestSuccess(): saveCePointsRequestSuccessAction {
        return ActionCreator(Constants.saveCePointsRequestSuccessActionType, null);
    }
    export function saveCePointsRequestFailure(): saveCePointsRequestFailureAction {
        return ActionCreator(Constants.saveCePointsRequestFailureActionType, null);
    }

    export type getEducationPointsRequestAction = Action<typeof Constants.getEducationPointsRequestActionType, null>
    export type getEducationPointsRequestSuccessAction = Action<typeof Constants.getEducationPointsRequestSuccessActionType, EducationPointsModel[]>;
    export type getEducationPointsRequestFailureAction = Action<typeof Constants.getEducationPointsRequestFailureActionType, null>;

    export function getEducationPointsRequest(): getEducationPointsRequestAction {
        return ActionCreator(Constants.getEducationPointsRequestActionType, null);
    }
    export function getEducationPointsRequestSuccess(P: EducationPointsModel[]): getEducationPointsRequestSuccessAction {
        return ActionCreator(Constants.getEducationPointsRequestSuccessActionType, P);
    }
    export function getEducationPointsRequestFailure(): getEducationPointsRequestFailureAction {
        return ActionCreator(Constants.getEducationPointsRequestFailureActionType, null);
    }

    export type saveEducationPointsRequestAction = Action<typeof Constants.saveEducationPointsRequestActionType, EducationPointsModel>
    export type saveEducationPointsRequestSuccessAction = Action<typeof Constants.saveEducationPointsRequestSuccessActionType, null>;
    export type saveEducationPointsRequestFailureAction = Action<typeof Constants.saveEducationPointsRequestFailureActionType, null>;

    export function saveEducationPointsRequest(P: EducationPointsModel): saveEducationPointsRequestAction {
        return ActionCreator(Constants.saveEducationPointsRequestActionType, P);
    }
    export function saveEducationPointsRequestSuccess(): saveEducationPointsRequestSuccessAction {
        return ActionCreator(Constants.saveEducationPointsRequestSuccessActionType, null);
    }
    export function saveEducationPointsRequestFailure(): saveEducationPointsRequestFailureAction {
        return ActionCreator(Constants.saveEducationPointsRequestFailureActionType, null);
    }

    export type updateEducationPointsRequestAction = Action<typeof Constants.updateEducationPointsRequestActionType, UpdateEducationPointModal>
    export type updateEducationPointsRequestSuccessAction = Action<typeof Constants.updateEducationPointsRequestSuccessActionType, null>;
    export type updateEducationPointsRequestFailureAction = Action<typeof Constants.updateEducationPointsRequestFailureActionType, null>;

    export function updateEducationPointsRequest(P: UpdateEducationPointModal): updateEducationPointsRequestAction {
        return ActionCreator(Constants.updateEducationPointsRequestActionType, P);
    }
    export function updateEducationPointsRequestSuccess(): updateEducationPointsRequestSuccessAction {
        return ActionCreator(Constants.updateEducationPointsRequestSuccessActionType, null);
    }
    export function updateEducationPointsRequestFailure(): updateEducationPointsRequestFailureAction {
        return ActionCreator(Constants.updateEducationPointsRequestFailureActionType, null);
    }

    export type deleteEducationPointsRequestAction = Action<typeof Constants.deleteEducationPointsRequestActionType, string>
    export type deleteEducationPointsRequestSuccessAction = Action<typeof Constants.deleteEducationPointsRequestSuccessActionType, null>;
    export type deleteEducationPointsRequestFailureAction = Action<typeof Constants.deleteEducationPointsRequestFailureActionType, null>;

    export function deleteEducationPointsRequest(P: string): deleteEducationPointsRequestAction {
        return ActionCreator(Constants.deleteEducationPointsRequestActionType, P);
    }
    export function deleteEducationPointsRequestSuccess(): deleteEducationPointsRequestSuccessAction {
        return ActionCreator(Constants.deleteEducationPointsRequestSuccessActionType, null);
    }
    export function deleteEducationPointsRequestFailure(): deleteEducationPointsRequestFailureAction {
        return ActionCreator(Constants.deleteEducationPointsRequestFailureActionType, null);
    }

    export type getSingleEducationPointsRequestAction = Action<typeof Constants.getSingleEducationPointsRequestActionType, string>
    export type getSingleEducationPointsRequestSuccessAction = Action<typeof Constants.getSingleEducationPointsRequestSuccessActionType, EducationPointsModel>;
    export type getSingleEducationPointsRequestFailureAction = Action<typeof Constants.getSingleEducationPointsRequestFailureActionType, null>;

    export function getSingleEducationPointsRequest(P: string): getSingleEducationPointsRequestAction {
        return ActionCreator(Constants.getSingleEducationPointsRequestActionType, P);
    }
    export function getSingleEducationPointsRequestSuccess(P: EducationPointsModel): getSingleEducationPointsRequestSuccessAction {
        return ActionCreator(Constants.getSingleEducationPointsRequestSuccessActionType, P);
    }
    export function getSingleEducationPointsRequestFailure(): getSingleEducationPointsRequestFailureAction {
        return ActionCreator(Constants.getSingleEducationPointsRequestFailureActionType, null);
    }

    export type getTemplatesRequestAction = Action<typeof Constants.getTemplatesRequestActionType, null>
    export type getTemplatesRequestSuccessAction = Action<typeof Constants.getTemplatesRequestSuccessActionType, TemplateModel[]>;
    export type getTemplatesRequestFailureAction = Action<typeof Constants.getTemplatesRequestFailureActionType, null>;

    export function getTemplatesRequest(): getTemplatesRequestAction {
        return ActionCreator(Constants.getTemplatesRequestActionType, null);
    }
    export function getTemplatesRequestSuccess(P: TemplateModel[]): getTemplatesRequestSuccessAction {
        return ActionCreator(Constants.getTemplatesRequestSuccessActionType, P);
    }
    export function getTemplatesRequestFailure(): getTemplatesRequestFailureAction {
        return ActionCreator(Constants.getTemplatesRequestFailureActionType, null);
    }

    export type getIntegrationRequestAction = Action<typeof Constants.getIntegrationRequestActionType, null>
    export type getIntegrationRequestSuccessAction = Action<typeof Constants.getIntegrationRequestSuccessActionType, IntegrationModel[]>;
    export type getIntegrationRequestFailureAction = Action<typeof Constants.getIntegrationRequestFailureActionType, null>;

    export function getIntegrationRequest(): getIntegrationRequestAction {
        return ActionCreator(Constants.getIntegrationRequestActionType, null);
    }
    export function getIntegrationRequestSuccess(P: IntegrationModel[]): getIntegrationRequestSuccessAction {
        return ActionCreator(Constants.getIntegrationRequestSuccessActionType, P);
    }
    export function getIntegrationRequestFailure(): getIntegrationRequestFailureAction {
        return ActionCreator(Constants.getIntegrationRequestFailureActionType, null);
    }

    export type updateIntegrationRequestAction = Action<typeof Constants.updateIntegrationRequestActionType, UpdateIntegrationDataModel>
    export type updateIntegrationRequestSuccessAction = Action<typeof Constants.updateIntegrationRequestSuccessActionType, null>;
    export type updateIntegrationRequestFailureAction = Action<typeof Constants.updateIntegrationRequestFailureActionType, null>;

    export function updateIntegrationRequest(P: UpdateIntegrationDataModel): updateIntegrationRequestAction {
        return ActionCreator(Constants.updateIntegrationRequestActionType, P);
    }
    export function updateIntegrationRequestSuccess(): updateIntegrationRequestSuccessAction {
        return ActionCreator(Constants.updateIntegrationRequestSuccessActionType, null);
    }
    export function updateIntegrationRequestFailure(): updateIntegrationRequestFailureAction {
        return ActionCreator(Constants.updateIntegrationRequestFailureActionType, null);
    }

    export type getPaymentProviderRequestAction = Action<typeof Constants.getPaymentProviderRequestActionType, null>
    export type getPaymentProviderRequestSuccessAction = Action<typeof Constants.getPaymentProviderRequestSuccessActionType, PaymentProviderModel[]>;
    export type getPaymentProviderRequestFailureAction = Action<typeof Constants.getPaymentProviderRequestFailureActionType, null>;

    export function getPaymentProviderRequest(): getPaymentProviderRequestAction {
        return ActionCreator(Constants.getPaymentProviderRequestActionType, null);
    }
    export function getPaymentProviderRequestSuccess(P: PaymentProviderModel[]): getPaymentProviderRequestSuccessAction {
        return ActionCreator(Constants.getPaymentProviderRequestSuccessActionType, P);
    }
    export function getPaymentProviderRequestFailure(): getPaymentProviderRequestFailureAction {
        return ActionCreator(Constants.getPaymentProviderRequestFailureActionType, null);
    }

    export type updatePaymentRequestAction = Action<typeof Constants.updatePaymentRequestActionType, UpdateIntegrationDataModel>
    export type updatePaymentRequestSuccessAction = Action<typeof Constants.updatePaymentRequestSuccessActionType, null>;
    export type updatePaymentRequestFailureAction = Action<typeof Constants.updatePaymentRequestFailureActionType, null>;

    export function updatePaymentRequest(P: UpdateIntegrationDataModel): updatePaymentRequestAction {
        return ActionCreator(Constants.updatePaymentRequestActionType, P);
    }
    export function updatePaymentRequestSuccess(): updatePaymentRequestSuccessAction {
        return ActionCreator(Constants.updatePaymentRequestSuccessActionType, null);
    }
    export function updatePaymentRequestFailure(): updatePaymentRequestFailureAction {
        return ActionCreator(Constants.updatePaymentRequestFailureActionType, null);
    }

    export type addTemplateRequestAction = Action<typeof Constants.addTemplateRequestActionType, TemplateModel>
    export type addTemplateRequestSuccessAction = Action<typeof Constants.addTemplateRequestSuccessActionType, null>;
    export type addTemplateRequestFailureAction = Action<typeof Constants.addTemplateRequestFailureActionType, null>;

    export function addTemplateRequest(P: TemplateModel): addTemplateRequestAction {
        return ActionCreator(Constants.addTemplateRequestActionType, P);
    }
    export function addTemplateRequestSuccess(): addTemplateRequestSuccessAction {
        return ActionCreator(Constants.addTemplateRequestSuccessActionType, null);
    }
    export function addTemplateRequestFailure(): addTemplateRequestFailureAction {
        return ActionCreator(Constants.addTemplateRequestFailureActionType, null);
    }

    export type deleteTemplateRequestAction = Action<typeof Constants.deleteTemplateRequestActionType, string>
    export type deleteTemplateRequestSuccessAction = Action<typeof Constants.deleteTemplateRequestSuccessActionType, null>;
    export type deleteTemplateRequestFailureAction = Action<typeof Constants.deleteTemplateRequestFailureActionType, null>;

    export function deleteTemplateRequest(P: string): deleteTemplateRequestAction {
        return ActionCreator(Constants.deleteTemplateRequestActionType, P);
    }
    export function deleteTemplateRequestSuccess(): deleteTemplateRequestSuccessAction {
        return ActionCreator(Constants.deleteTemplateRequestSuccessActionType, null);
    }
    export function deleteTemplateRequestFailure(): deleteTemplateRequestFailureAction {
        return ActionCreator(Constants.deleteTemplateRequestFailureActionType, null);
    }

    export type updateTemplateRequestAction = Action<typeof Constants.updateTemplateRequestActionType, TemplateModel>
    export type updateTemplateRequestSuccessAction = Action<typeof Constants.updateTemplateRequestSuccessActionType, null>;
    export type updateTemplateRequestFailureAction = Action<typeof Constants.updateTemplateRequestFailureActionType, null>;

    export function updateTemplateRequest(P: TemplateModel): updateTemplateRequestAction {
        return ActionCreator(Constants.updateTemplateRequestActionType, P);
    }
    export function updateTemplateRequestSuccess(): updateTemplateRequestSuccessAction {
        return ActionCreator(Constants.updateTemplateRequestSuccessActionType, null);
    }
    export function updateTemplateRequestFailure(): updateTemplateRequestFailureAction {
        return ActionCreator(Constants.updateTemplateRequestFailureActionType, null);
    }

    export type getCurrencyRequestAction = Action<typeof Constants.getCurrencyRequestActionType, null>
    export type getCurrencyRequestSuccessAction = Action<typeof Constants.getCurrencyRequestSuccessActionType, CurrencyModel[]>;
    export type getCurrencyRequestFailureAction = Action<typeof Constants.getCurrencyRequestFailureActionType, null>;

    export function getCurrencyRequest(): getCurrencyRequestAction {
        return ActionCreator(Constants.getCurrencyRequestActionType, null);
    }
    export function getCurrencyRequestSuccess(P: CurrencyModel[]): getCurrencyRequestSuccessAction {
        return ActionCreator(Constants.getCurrencyRequestSuccessActionType, P);
    }
    export function getCurrencyRequestFailure(): getCurrencyRequestFailureAction {
        return ActionCreator(Constants.getCurrencyRequestFailureActionType, null);
    }

    export type updateCurrencyRequestAction = Action<typeof Constants.updateCurrencyRequestActionType, UpdateDataModel[]>
    export type updateCurrencyRequestSuccessAction = Action<typeof Constants.updateCurrencyRequestSuccessActionType, null>;
    export type updateCurrencyRequestFailureAction = Action<typeof Constants.updateCurrencyRequestFailureActionType, null>;

    export function updateCurrencyRequest(P: UpdateDataModel[]): updateCurrencyRequestAction {
        return ActionCreator(Constants.updateCurrencyRequestActionType, P);
    }
    export function updateCurrencyRequestSuccess(): updateCurrencyRequestSuccessAction {
        return ActionCreator(Constants.updateCurrencyRequestSuccessActionType, null);
    }
    export function updateCurrencyRequestFailure(): updateCurrencyRequestFailureAction {
        return ActionCreator(Constants.updateCurrencyRequestFailureActionType, null);
    }

    export type getSelectedCurrencyRequestAction = Action<typeof Constants.getSelectedCurrencyRequestActionType, null>
    export type getSelectedCurrencyRequestSuccessAction = Action<typeof Constants.getSelectedCurrencyRequestSuccessActionType, string>;
    export type getSelectedCurrencyRequestFailureAction = Action<typeof Constants.getSelectedCurrencyRequestFailureActionType, null>;

    export function getSelectedCurrencyRequest(): getSelectedCurrencyRequestAction {
        return ActionCreator(Constants.getSelectedCurrencyRequestActionType, null);
    }
    export function getSelectedCurrencyRequestSuccess(P: string): getSelectedCurrencyRequestSuccessAction {
        return ActionCreator(Constants.getSelectedCurrencyRequestSuccessActionType, P);
    }
    export function getSelectedCurrencyRequestFailure(): getSelectedCurrencyRequestFailureAction {
        return ActionCreator(Constants.getSelectedCurrencyRequestFailureActionType, null);
    }

    export type getEcommerceSettingRequestAction = Action<typeof Constants.getEcommerceSettingRequestActionType, null>
    export type getEcommerceSettingRequestSuccessAction = Action<typeof Constants.getEcommerceSettingRequestSuccessActionType, EcommerceSettingModel>;
    export type getEcommerceSettingRequestFailureAction = Action<typeof Constants.getEcommerceSettingRequestFailureActionType, null>;

    export function getEcommerceSettingRequest(): getEcommerceSettingRequestAction {
        return ActionCreator(Constants.getEcommerceSettingRequestActionType, null);
    }
    export function getEcommerceSettingRequestSuccess(P: EcommerceSettingModel): getEcommerceSettingRequestSuccessAction {
        return ActionCreator(Constants.getEcommerceSettingRequestSuccessActionType, P);
    }
    export function getEcommerceSettingRequestFailure(): getEcommerceSettingRequestFailureAction {
        return ActionCreator(Constants.getEcommerceSettingRequestFailureActionType, null);
    }

    export type updateEcommerceSettingRequestAction = Action<typeof Constants.updateEcommerceSettingRequestActionType, UpdateDataModel[]>
    export type updateEcommerceSettingRequestSuccessAction = Action<typeof Constants.updateEcommerceSettingRequestSuccessActionType, null>;
    export type updateEcommerceSettingRequestFailureAction = Action<typeof Constants.updateEcommerceSettingRequestFailureActionType, null>;

    export function updateEcommerceSettingRequest(P: UpdateDataModel[]): updateEcommerceSettingRequestAction {
        return ActionCreator(Constants.updateEcommerceSettingRequestActionType, P);
    }
    export function updateEcommerceSettingRequestSuccess(): updateEcommerceSettingRequestSuccessAction {
        return ActionCreator(Constants.updateEcommerceSettingRequestSuccessActionType, null);
    }
    export function updateEcommerceSettingRequestFailure(): updateEcommerceSettingRequestFailureAction {
        return ActionCreator(Constants.updateEcommerceSettingRequestFailureActionType, null);
    }

    export type getQuickBooksProviderRequestAction = Action<typeof Constants.getQuickBooksProviderRequestActionType, null>
    export type getQuickBooksProviderRequestSuccessAction = Action<typeof Constants.getQuickBooksProviderRequestSuccessActionType, QuickBooksModel>;
    export type getQuickBooksProviderRequestFailureAction = Action<typeof Constants.getQuickBooksProviderRequestFailureActionType, null>;

    export function getQuickBooksProviderRequest(): getQuickBooksProviderRequestAction {
        return ActionCreator(Constants.getQuickBooksProviderRequestActionType, null);
    }
    export function getQuickBooksProviderRequestSuccess(P: QuickBooksModel): getQuickBooksProviderRequestSuccessAction {
        return ActionCreator(Constants.getQuickBooksProviderRequestSuccessActionType, P);
    }
    export function getQuickBooksProviderRequestFailure(): getQuickBooksProviderRequestFailureAction {
        return ActionCreator(Constants.getQuickBooksProviderRequestFailureActionType, null);
    }

    export type addQuickBooksProviderRequestAction = Action<typeof Constants.addQuickBooksProviderRequestActionType, QuickBooksModel>
    export type addQuickBooksProviderRequestSuccessAction = Action<typeof Constants.addQuickBooksProviderRequestSuccessActionType, null>;
    export type addQuickBooksProviderRequestFailureAction = Action<typeof Constants.addQuickBooksProviderRequestFailureActionType, null>;

    export function addQuickBooksProviderRequest(P: QuickBooksModel): addQuickBooksProviderRequestAction {
        return ActionCreator(Constants.addQuickBooksProviderRequestActionType, P);
    }
    export function addQuickBooksProviderRequestSuccess(): addQuickBooksProviderRequestSuccessAction {
        return ActionCreator(Constants.addQuickBooksProviderRequestSuccessActionType, null);
    }
    export function addQuickBooksProviderRequestFailure(): addQuickBooksProviderRequestFailureAction {
        return ActionCreator(Constants.addQuickBooksProviderRequestFailureActionType, null);
    }

    export type getConnectQuickBooksProviderRequestAction = Action<typeof Constants.getConnectQuickBooksProviderRequestActionType, string>
    export type getConnectQuickBooksProviderRequestSuccessAction = Action<typeof Constants.getConnectQuickBooksProviderRequestSuccessActionType, string>;
    export type getConnectQuickBooksProviderRequestFailureAction = Action<typeof Constants.getConnectQuickBooksProviderRequestFailureActionType, null>;

    export function getConnectQuickBooksProviderRequest(P: string): getConnectQuickBooksProviderRequestAction {
        return ActionCreator(Constants.getConnectQuickBooksProviderRequestActionType, P);
    }
    export function getConnectQuickBooksProviderRequestSuccess(P: string): getConnectQuickBooksProviderRequestSuccessAction {
        return ActionCreator(Constants.getConnectQuickBooksProviderRequestSuccessActionType, P);
    }
    export function getConnectQuickBooksProviderRequestFailure(): getConnectQuickBooksProviderRequestFailureAction {
        return ActionCreator(Constants.getConnectQuickBooksProviderRequestFailureActionType, null);
    }

    export type getAttributeMappingRequestAction = Action<typeof Constants.getAttributeMappingRequestActionType, AttributeMappingGetRequestModel>
    export type getAttributeMappingRequestSuccessAction = Action<typeof Constants.getAttributeMappingRequestSuccessActionType, AttributeMappingModel[]>;
    export type getAttributeMappingRequestFailureAction = Action<typeof Constants.getAttributeMappingRequestFailureActionType, null>;

    export function getAttributeMappingRequest(P: AttributeMappingGetRequestModel): getAttributeMappingRequestAction {
        return ActionCreator(Constants.getAttributeMappingRequestActionType, P);
    }
    export function getAttributeMappingRequestSuccess(P: AttributeMappingModel[]): getAttributeMappingRequestSuccessAction {
        return ActionCreator(Constants.getAttributeMappingRequestSuccessActionType, P);
    }
    export function getAttributeMappingRequestFailure(): getAttributeMappingRequestFailureAction {
        return ActionCreator(Constants.getAttributeMappingRequestFailureActionType, null);
    }

    export type addAttributeMappingRequestAction = Action<typeof Constants.addAttributeMappingRequestActionType, AttributeMappingModel>
    export type addAttributeMappingRequestSuccessAction = Action<typeof Constants.addAttributeMappingRequestSuccessActionType, null>;
    export type addAttributeMappingRequestFailureAction = Action<typeof Constants.addAttributeMappingRequestFailureActionType, null>;

    export function addAttributeMappingRequest(P: AttributeMappingModel): addAttributeMappingRequestAction {
        return ActionCreator(Constants.addAttributeMappingRequestActionType, P);
    }
    export function addAttributeMappingRequestSuccess(): addAttributeMappingRequestSuccessAction {
        return ActionCreator(Constants.addAttributeMappingRequestSuccessActionType, null);
    }
    export function addAttributeMappingRequestFailure(): addAttributeMappingRequestFailureAction {
        return ActionCreator(Constants.addAttributeMappingRequestFailureActionType, null);
    }

    export type resetSettingStateRequestAction = Action<typeof Constants.resetSettingStateRequestActionType, null>

    export function resetSettingStateRequest(): resetSettingStateRequestAction {
        return ActionCreator(Constants.resetSettingStateRequestActionType, null);
    }
    export type updateAttributeMappingRequestAction = Action<typeof Constants.updateAttributeMappingRequestActionType, UpdateAttributeMappingModal>
    export type updateAttributeMappingRequestSuccessAction = Action<typeof Constants.updateAttributeMappingRequestSuccessActionType, null>;
    export type updateAttributeMappingRequestFailureAction = Action<typeof Constants.updateAttributeMappingRequestFailureActionType, null>;

    export function updateAttributeMappingRequest(P: UpdateAttributeMappingModal): updateAttributeMappingRequestAction {
        return ActionCreator(Constants.updateAttributeMappingRequestActionType, P);
    }
    export function updateAttributeMappingRequestSuccess(): updateAttributeMappingRequestSuccessAction {
        return ActionCreator(Constants.updateAttributeMappingRequestSuccessActionType, null);
    }
    export function updateAttributeMappingRequestFailure(): updateAttributeMappingRequestFailureAction {
        return ActionCreator(Constants.updateAttributeMappingRequestFailureActionType, null);
    }

    export type deleteAttributeMappingRequestAction = Action<typeof Constants.deleteAttributeMappingRequestActionType, string>
    export type deleteAttributeMappingRequestSuccessAction = Action<typeof Constants.deleteAttributeMappingRequestSuccessActionType, null>;
    export type deleteAttributeMappingRequestFailureAction = Action<typeof Constants.deleteAttributeMappingRequestFailureActionType, null>;

    export function deleteAttributeMappingRequest(P: string): deleteAttributeMappingRequestAction {
        return ActionCreator(Constants.deleteAttributeMappingRequestActionType, P);
    }
    export function deleteAttributeMappingRequestSuccess(): deleteAttributeMappingRequestSuccessAction {
        return ActionCreator(Constants.deleteAttributeMappingRequestSuccessActionType, null);
    }
    export function deleteAttributeMappingRequestFailure(): deleteAttributeMappingRequestFailureAction {
        return ActionCreator(Constants.deleteAttributeMappingRequestFailureActionType, null);
    }

    export type getSingleAttributeMappingRequestAction = Action<typeof Constants.getSingleAttributeMappingRequestActionType, string>
    export type getSingleAttributeMappingRequestSuccessAction = Action<typeof Constants.getSingleAttributeMappingRequestSuccessActionType, AttributeMappingModel>;
    export type getSingleAttributeMappingRequestFailureAction = Action<typeof Constants.getSingleAttributeMappingRequestFailureActionType, null>;

    export function getSingleAttributeMappingRequest(P: string): getSingleAttributeMappingRequestAction {
        return ActionCreator(Constants.getSingleAttributeMappingRequestActionType, P);
    }
    export function getSingleAttributeMappingRequestSuccess(P: AttributeMappingModel): getSingleAttributeMappingRequestSuccessAction {
        return ActionCreator(Constants.getSingleAttributeMappingRequestSuccessActionType, P);
    }
    export function getSingleAttributeMappingRequestFailure(): getSingleAttributeMappingRequestFailureAction {
        return ActionCreator(Constants.getSingleAttributeMappingRequestFailureActionType, null);
    }

    export type getAttributeMappingColumnListRequestAction = Action<typeof Constants.getAttributeMappingColumnListRequestActionType, string>
    export type getAttributeMappingColumnListRequestSuccessAction = Action<typeof Constants.getAttributeMappingColumnListRequestSuccessActionType, CECreditDBColumnMapping[]>;
    export type getAttributeMappingColumnListRequestFailureAction = Action<typeof Constants.getAttributeMappingColumnListRequestFailureActionType, null>;

    export function getAttributeMappingColumnListRequest(P: string): getAttributeMappingColumnListRequestAction {
        return ActionCreator(Constants.getAttributeMappingColumnListRequestActionType, P);
    }
    export function getAttributeMappingColumnListRequestSuccess(P: CECreditDBColumnMapping[]): getAttributeMappingColumnListRequestSuccessAction {
        return ActionCreator(Constants.getAttributeMappingColumnListRequestSuccessActionType, P);
    }
    export function getAttributeMappingColumnListRequestFailure(): getAttributeMappingColumnListRequestFailureAction {
        return ActionCreator(Constants.getAttributeMappingColumnListRequestFailureActionType, null);
    }

    export type getAttributeMappingTypeListRequestAction = Action<typeof Constants.getAttributeMappingTypeListRequestActionType, null>
    export type getAttributeMappingTypeListRequestSuccessAction = Action<typeof Constants.getAttributeMappingTypeListRequestSuccessActionType, AttributeMappingType[]>;
    export type getAttributeMappingTypeListRequestFailureAction = Action<typeof Constants.getAttributeMappingTypeListRequestFailureActionType, null>;

    export function getAttributeMappingTypeListRequest(): getAttributeMappingTypeListRequestAction {
        return ActionCreator(Constants.getAttributeMappingTypeListRequestActionType, null);
    }
    export function getAttributeMappingTypeListRequestSuccess(P: AttributeMappingType[]): getAttributeMappingTypeListRequestSuccessAction {
        return ActionCreator(Constants.getAttributeMappingTypeListRequestSuccessActionType, P);
    }
    export function getAttributeMappingTypeListRequestFailure(): getAttributeMappingTypeListRequestFailureAction {
        return ActionCreator(Constants.getAttributeMappingTypeListRequestFailureActionType, null);
    }


    export type addBPointProviderRequestAction = Action<typeof Constants.addBPointProviderRequestActionType, PaypalModel>
    export type addBPointProviderRequestSuccessAction = Action<typeof Constants.addBPointProviderRequestSuccessActionType, null>;
    export type addBPointProviderRequestFailureAction = Action<typeof Constants.addBPointProviderRequestFailureActionType, null>;

    export function addBPointProviderRequest(P: PaypalModel): addBPointProviderRequestAction {
        return ActionCreator(Constants.addBPointProviderRequestActionType, P);
    }
    export function addBPointProviderRequestSuccess(): addBPointProviderRequestSuccessAction {
        return ActionCreator(Constants.addBPointProviderRequestSuccessActionType, null);
    }
    export function addBPointProviderRequestFailure(): addBPointProviderRequestFailureAction {
        return ActionCreator(Constants.addBPointProviderRequestFailureActionType, null);
    }

    export type addLivePayProviderRequestAction = Action<typeof Constants.addLivePayProviderRequestActionType, PaypalModel>
    export type addLivePayProviderRequestSuccessAction = Action<typeof Constants.addLivePayProviderRequestSuccessActionType, null>;
    export type addLivePayProviderRequestFailureAction = Action<typeof Constants.addLivePayProviderRequestFailureActionType, null>;

    export function addLivePayProviderRequest(P: PaypalModel): addLivePayProviderRequestAction {
        return ActionCreator(Constants.addLivePayProviderRequestActionType, P);
    }
    export function addLivePayProviderRequestSuccess(): addLivePayProviderRequestSuccessAction {
        return ActionCreator(Constants.addLivePayProviderRequestSuccessActionType, null);
    }
    export function addLivePayProviderRequestFailure(): addLivePayProviderRequestFailureAction {
        return ActionCreator(Constants.addLivePayProviderRequestFailureActionType, null);
    }

    export type addEzidebitProviderRequestAction = Action<typeof Constants.addEzidebitProviderRequestActionType, PaypalModel>
    export type addEzidebitProviderRequestSuccessAction = Action<typeof Constants.addEzidebitProviderRequestSuccessActionType, null>;
    export type addEzidebitProviderRequestFailureAction = Action<typeof Constants.addEzidebitProviderRequestFailureActionType, null>;

    export function addEzidebitProviderRequest(P: PaypalModel): addEzidebitProviderRequestAction {
        return ActionCreator(Constants.addEzidebitProviderRequestActionType, P);
    }
    export function addEzidebitProviderRequestSuccess(): addEzidebitProviderRequestSuccessAction {
        return ActionCreator(Constants.addEzidebitProviderRequestSuccessActionType, null);
    }
    export function addEzidebitProviderRequestFailure(): addEzidebitProviderRequestFailureAction {
        return ActionCreator(Constants.addEzidebitProviderRequestFailureActionType, null);
    }
}

export type SettingActions = Omit<typeof SettingActions, 'Type'>;
export const useSettingActions = (dispatch: Dispatch) => {
    const { ...actions } = SettingActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as SettingActions;
};