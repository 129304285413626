import { UsersActions } from './actions';
import { RootState } from '../reducers/state';
import { UploadMemberListData, MemberModel, StripeSubscriptionModel, CheckUserNameAvailableModel, SingleMemberListModel, UsersModel, MemberCountDataModel, UserMfaSetup, MfaQr } from 'src/models/usersModel';
import { PaginationModel } from 'src/models/paginationModel';
import { UsersStateModel } from 'src/models/usersModel';
import { Actions } from 'src/models/usersModel';
import * as Constants from './constants'
const initialState: RootState.UsersState = {
    data: [],
    userDetails: {} as UsersModel,
    searchedData: {} as UploadMemberListData,
    members: [],
    memberDetail: {} as MemberModel,
    memberListPaginationMeta: {} as PaginationModel,
    paginationMeta: {} as PaginationModel,
    loginHistoryPaginationMeta: {} as PaginationModel,
    invoicePaginationMeta: {} as PaginationModel,
    activityPaginationMeta: {} as PaginationModel,
    stripeSubscription: {} as StripeSubscriptionModel,
    memberCountData: {} as MemberCountDataModel,
    memberList: [],
    memberListByMemberId: [],
    memberEvent: [],
    roles: [],
    status: [],
    loginHistory: [],
    singleMemberList: {} as SingleMemberListModel,
    userActivityTimeLine: [],
    memberVideoanalytics: [],
    memberSubscription: [],
    userProfile: '',
    memberInvoice: [],
    totalMembers: [],
    subscriptionProducts: [],
    message: '',
    success: false,
    isUserAdded: false,
    isUserUpdated: false,
    isUserDeleted: false,
    isUserListDeleted: false,
    isMemberAdded: false,
    isMemberUpdated: false,
    isMemberDeleted: false,
    isMemberListDeleted: false,
    isProfileUploaded: false,
    isCreditPrefrencesUpdated: false,
    isMemberListSaved: false,
    isMemberListUpdated: false,
    isMemberListTypeSaved: false,
    isMemberProfileUpdated: false,
    isMemberListloading: false,
    isUserNameAvailable: {} as CheckUserNameAvailableModel,
    error: false,
    loading: false,
    memberlistLoading: false,
    userlistLoading: false,
    isEmailDomainUploaded: false,
    isEmailDomainAlreadyExist: false,
    isEmailAlreadyExist: false,
    isUserNameAlreadyExist: false,
    isEmailUploaded: false,
    isUserNameUploaded: false,
    isMemberListDetailsDeleted: false,
    isPasswordChanged: false,
    passwordChangedObj: {},
    isMfaUpdated: false,
    isQrGenerated: false,
    isCodeVerified: false,
    qrdata: {} as MfaQr
};


function userReducer(state: UsersStateModel = initialState, action: Actions): UsersStateModel {
    switch (action.type) {
        case Constants.getUserRequestActionType: return getUserAction(state);
        case Constants.getUserRequestSuccessActionType: return getUserSuccessAction(state, action);
        case Constants.getUserRequestFailureActionType: return getUserFailureAction(state, action);

        case Constants.getUserRoleRequestActionType: return getUserRolesAction(state);
        case Constants.getUserRoleRequestSuccessActionType: return getUserRolesSuccessAction(state, action);
        case Constants.getUserRoleRequestFailureActionType: return getUserRolesFailureAction(state);

        case Constants.getUserDetailsRequestActionType: return getUserDetailsAction(state);
        case Constants.getUserDetailsRequestSuccessActionType: return getUserDetailsSuccessAction(state, action);
        case Constants.getUserDetailsRequestFailureActionType: return getUserDetailsFailureAction(state, action);

        case Constants.addUserRequestActionType: return addUserAction(state);
        case Constants.addUserRequestSuccessActionType: return addUserSuccessAction(state, action);
        case Constants.addUserRequestFailureActionType: return addUserFailureAction(state, action);

        case Constants.getMembersRequestActionType: return getMemberAction(state);
        case Constants.getMembersRequestSuccessActionType: return getMemberSuccessAction(state, action);
        case Constants.getMembersRequestFailureActionType: return getMemberFailureAction(state, action);

        case Constants.addMembersRequestActionType: return addMemberAction(state);
        case Constants.addMembersRequestSuccessActionType: return addMemberSuccessAction(state, action);
        case Constants.addMembersRequestFailureActionType: return addMemberFailureAction(state, action);

        case Constants.getMembersDetailRequestActionType: return getMemberDetailsAction(state);
        case Constants.getMembersDetailRequestSuccessActionType: return getMemberDetailsSuccessAction(state, action);
        case Constants.getMembersDetailRequestFailureActionType: return getMemberDetailsFailureAction(state, action);

        case Constants.UpdateUserRequestActionType: return UpdateUserAction(state);
        case Constants.UpdateUserRequestSuccessActionType: return UpdateUserSuccessAction(state, action);
        case Constants.UpdateUserRequestFailureActionType: return UpdateUserFailureAction(state, action);

        case Constants.userMfaSetupRequestActionType: return UserMfaSetupAction(state);
        case Constants.userMfaSetupRequestSuccessActionType: return UserMfaSetupSuccessAction(state, action);
        case Constants.userMfaSetupRequestFailureActionType: return UserMfaSetupFailureAction(state, action);

        case Constants.generateMFAQrRequestActionType: return GenerateMFAQrAction(state);
        case Constants.generateMFAQrRequestSuccessActionType: return GenerateMFAQrSuccessAction(state, action);
        case Constants.generateMFAQrRequestFailureActionType: return GenerateMFAQrFailureAction(state, action);

        case Constants.verifyMfaCodeRequestActionType: return VerifyMfaCodeAction(state);
        case Constants.verifyMfaCodeRequestSuccessActionType: return VerifyMfaCodeSuccessAction(state);
        case Constants.verifyMfaCodeRequestFailureActionType: return VerifyMfaCodeFailureAction(state);

        case Constants.resetMFAStateRequestActionType: return resetMFAStateAction(state);

        case Constants.editMemberRequestActionType: return editMemberAction(state);
        case Constants.editMemberRequestSuccessActionType: return editMemberSuccessAction(state, action);
        case Constants.editMemberRequestFailureActionType: return editMemberFailureAction(state, action);

        case Constants.deleteMembersRequestActionType: return deleteMemberAction(state);
        case Constants.deleteMembersRequestSuccessActionType: return deleteMemberSuccessAction(state, action);
        case Constants.deleteMembersRequestFailureActionType: return deleteMemberFailureAction(state, action);

        case Constants.uploadProfileRequestActionType: return uploadProfileAction(state);
        case Constants.uploadProfileRequestSuccessActionType: return uploadProfileSuccessAction(state, action);
        case Constants.uploadProfileRequestFailureActionType: return uploadProfileFailureAction(state, action);

        case Constants.updateCreditPreferencesRequestActionType: return updateCreditPreferenceAction(state);
        case Constants.updateCreditPreferencesRequestSuccessActionType: return updateCreditPreferenceSuccessAction(state, action);
        case Constants.updateCreditPreferencesRequestFailureActionType: return updateCreditPreferenceFailureAction(state, action);

        case Constants.getStripeSubscriptionRequestActionType: return getStripeSubscriptionAction(state);
        case Constants.getStripeSubscriptionRequestSuccessActionType: return getStripeSubscriptionSuccessAction(state, action);
        case Constants.getStripeSubscriptionRequestFailureActionType: return getStripeSubscriptionFailureAction(state, action);

        case Constants.checkUserNameAvailableRequestActionType: return checkUserNameAvailableAction(state);
        case Constants.checkUserNameAvailableRequestSuccessActionType: return checkUserNameAvailableSuccessAction(state, action);
        case Constants.checkUserNameAvailableRequestFailureActionType: return checkUserNameAvailableFailureAction(state, action);

        case Constants.getMemberVideoAnalyticsRequestActionType: return getMemberVideoAnalyticsAction(state);
        case Constants.getMemberVideoAnalyticsRequestSuccessActionType: return getMemberVideoAnalyticsSuccessAction(state, action);
        case Constants.getMemberVideoAnalyticsRequestFailureActionType: return getMemberVideoAnalyticsFailureAction(state, action);

        case Constants.getMemberSubscriptionRequestActionType: return getMemberSubscriptionAction(state);
        case Constants.getMemberSubscriptionRequestSuccessActionType: return getMemberSubscriptionSuccessAction(state, action);
        case Constants.getMemberSubscriptionRequestFailureActionType: return getMemberSubscriptionFailureAction(state, action);

        case Constants.getUserProfileRequestActionType: return getUserProfileAction(state);
        case Constants.getUserProfileRequestSuccessActionType: return getUserProfileSuccessAction(state, action);
        case Constants.getUserProfileRequestFailureActionType: return getUserProfileFailureAction(state, action);

        case Constants.getMemberInvoicesRequestActionType: return getMemberInvoiceAction(state);
        case Constants.getMemberInvoicesRequestSuccessActionType: return getMemberInvoiceSuccessAction(state, action);
        case Constants.getMemberInvoicesRequestFailureActionType: return getMemberInvoiceFailureAction(state, action);

        case Constants.getTotalMembersRequestActionType: return getTotalMembersAction(state);
        case Constants.getTotalMembersRequestSuccessActionType: return getTotalMembersSuccessAction(state, action);
        case Constants.getTotalMembersRequestFailureActionType: return getTotalMembersFailureAction(state, action);

        case Constants.getSubscriptionProductsRequestActionType: return getSubscriptionProductsAction(state);
        case Constants.getSubscriptionProductsRequestSuccessActionType: return getSubscriptionProductsSuccessAction(state, action);
        case Constants.getSubscriptionProductsRequestFailureActionType: return getSubscriptionProductsFailureAction(state);

        case Constants.deleteMemberListRequestActionType: return deleteMemberListAction(state);
        case Constants.deleteMemberListRequestSuccessActionType: return deleteMemberListSuccessAction(state, action);
        case Constants.deleteMemberListRequestFailureActionType: return deleteMemberListFailureAction(state);

        case Constants.getMemberListRequestActionType: return getMemberListAction(state);
        case Constants.getMemberListRequestSuccessActionType: return getMemberListSuccessAction(state, action);
        case Constants.getMemberListRequestFailureActionType: return getMemberListFailureAction(state);

        case Constants.getUserActivityTimeLineRequestActionType: return getUserActivityTimeLineAction(state);
        case Constants.getUserActivityTimeLineRequestSuccessActionType: return getUserActivityTimeLineSuccessAction(state, action);
        case Constants.getUserActivityTimeLineRequestFailureActionType: return getUserActivityTimeLineFailureAction(state);

        case Constants.saveMemberListRequestActionType: return saveMemberListAction(state);
        case Constants.saveMemberListRequestSuccessActionType: return saveMemberListSuccessAction(state);
        case Constants.saveMemberListRequestFailureActionType: return saveMemberListFailureAction(state);

        case Constants.getSingleMemberListRequestActionType: return getSingleMemberListAction(state);
        case Constants.getSingleMemberListRequestSuccessActionType: return getSingleMemberListSuccessAction(state, action);
        case Constants.getSingleMemberListRequestFailureActionType: return getSingleMemberListFailureAction(state);

        case Constants.updateMemberListRequestActionType: return updateMemberListAction(state);
        case Constants.updateMemberListRequestSuccessActionType: return updateMemberListSuccessAction(state);
        case Constants.updateMemberListRequestFailureActionType: return updateMemberListFailureAction(state);

        case Constants.saveMemberListTypeRequestActionType: return saveMemberListTypeAction(state);
        case Constants.saveMemberListTypeRequestSuccessActionType: return saveMemberListTypeSuccessAction(state);
        case Constants.saveMemberListTypeRequestFailureActionType: return saveMemberListTypeFailureAction(state);

        case Constants.searchMemberRequestActionType: return searchMemberAction(state);
        case Constants.searchMemberRequestSuccessActionType: return searchMemberSuccessAction(state, action);
        case Constants.searchMemberRequestFailureActionType: return searchMemberFailureAction(state);

        case Constants.getRolesRequestActionType: return getRolesAction(state);
        case Constants.getRolesRequestSuccessActionType: return getRolesSuccessAction(state, action);
        case Constants.getRolesRequestFailureActionType: return getRolesFailureAction(state);

        case Constants.getStatusRequestActionType: return getStatusAction(state);
        case Constants.getStatusRequestSuccessActionType: return getStatusSuccessAction(state, action);
        case Constants.getStatusRequestFailureActionType: return getStatusFailureAction(state);

        case Constants.getLoginHistoryRequestActionType: return getLoginHistoryAction(state);
        case Constants.getLoginHistoryRequestSuccessActionType: return getLoginHistorySuccessAction(state, action);
        case Constants.getLoginHistoryRequestFailureActionType: return getLoginHistoryFailureAction(state);

        case Constants.getMemberListByMemberIdRequestActionType: return getMemberListByMemberIdAction(state);
        case Constants.getMemberListByMemberIdRequestSuccessActionType: return getMemberListByMemberIdSuccessAction(state, action);
        case Constants.getMemberListByMemberIdRequestFailureActionType: return getMemberListByMemberIdFailureAction(state);

        case Constants.getMemberEventRegistrationRequestActionType: return getMemberEventRegistrationAction(state);
        case Constants.getMemberEventRegistrationRequestSuccessActionType: return getMemberEventRegistrationSuccessAction(state, action);
        case Constants.getMemberEventRegistrationRequestFailureActionType: return getMemberEventRegistrationFailureAction(state);

        case Constants.getMemberCountDataRequestActionType: return getMemberCountDataAction(state);
        case Constants.getMemberCountDataRequestSuccessActionType: return getMemberCountDataSuccessAction(state, action);
        case Constants.getMemberCountDataRequestFailureActionType: return getMemberCountDataFailureAction(state);

        case Constants.updateMemberProfileRequestActionType: return updateMemberProfileAction(state);
        case Constants.updateMemberProfileRequestSuccessActionType: return updateMemberProfileSuccessAction(state);
        case Constants.updateMemberProfileRequestFailureActionType: return updateMemberProfileFailureAction(state);

        case Constants.uploadEmailDomainRequestActionType: return uploadEmailDomainAction(state);
        case Constants.uploadEmailDomainRequestSuccessActionType: return uploadEmailDomainSuccessAction(state);
        case Constants.uploadEmailDomainRequestDataExistActionType: return uploadEmailDomainRequestDataExistActionType(state);
        case Constants.uploadEmailDomainRequestFailureActionType: return uploadEmailDomainFailureAction(state);

        case Constants.uploadEmailRequestActionType: return uploadEmailAction(state);
        case Constants.uploadEmailRequestSuccessActionType: return uploadEmailSuccessAction(state);
        case Constants.uploadEmailRequestDataExistActionType: return uploadEmailRequestDataExistActionType(state);
        case Constants.uploadEmailRequestFailureActionType: return uploadEmailFailureAction(state);

        case Constants.uploadUserNameRequestActionType: return uploadUsernameAction(state);
        case Constants.uploadUserNameRequestSuccessActionType: return uploadUserNameSuccessAction(state);
        case Constants.uploadUserNameRequestDataExistActionType: return uploadUserNameRequestDataExistActionType(state);
        case Constants.uploadUserNameRequestFailureActionType: return uploadUserNameFailureAction(state);

        case Constants.searchMemberListDetailsRequestActionType: return searchMemberListDetailsAction(state);
        case Constants.searchMemberListDetailsRequestSuccessActionType: return searchMemberListDetailsSuccessAction(state, action);
        case Constants.searchMemberListDetailsRequestFailureActionType: return searchMemberListDetailsFailureAction(state);

        case Constants.deleteMemberListDetailsRequestActionType: return deleteMemberListDetailsAction(state);
        case Constants.deleteMemberListDetailsRequestSuccessActionType: return deleteMemberListDetailsSuccessAction(state);
        case Constants.deleteMemberListDetailsRequestFailureActionType: return deleteMemberListDetailsFailureAction(state);

        case Constants.changePasswordRequestActionType: return changePasswordAction(state);
        case Constants.changePasswordRequestSuccessActionType: return changePasswordSuccessAction(state, action);
        case Constants.changePasswordRequestFailureActionType: return changePasswordFailureAction(state);


        case Constants.resetMemberStateActionType: return resetMemberAction(state);
        case Constants.resetUserStateActionType: return resetUserAction(state);
        case Constants.resetMemberDataStateActionType: return resetMemberDataStateAction(state);
        case Constants.resetUserDataStateActionType: return resetUserDataStateAction(state);

        case Constants.reset: return reset(state);
        default: return state;
    }

    function getUserAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
            userlistLoading: true,
        };
    }
    function getUserSuccessAction(state: UsersStateModel, action: UsersActions.getUserRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                success: true,
                loading: false,
                data: action.payload,
                userlistLoading: false,
            };
        }
        return state;
    }
    function getUserFailureAction(state: UsersStateModel, action: UsersActions.getUserRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function getUserDetailsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserDetailsSuccessAction(state: UsersStateModel, action: UsersActions.getUserDetailsRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                userDetails: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getUserDetailsFailureAction(state: UsersStateModel, action: UsersActions.getUserDetailsRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function addUserAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addUserSuccessAction(state: UsersStateModel, action: UsersActions.addUserRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isUserAdded: true,
            message: 'Success',
            loading: false
        };
    }
    function addUserFailureAction(state: UsersStateModel, action: UsersActions.addUserRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isUserAdded: false,
            error: true,
            loading: false
        }
    }
    function getMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            memberlistLoading: true,
        };
    }
    function getMemberSuccessAction(state: UsersStateModel, action: UsersActions.getMembersRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                members: action.payload.data,
                memberListPaginationMeta: action.payload.paginationMeta,
                memberlistLoading: false
            };
        }
        return state;
    }
    function getMemberFailureAction(state: UsersStateModel, action: UsersActions.getMembersRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            memberlistLoading: false
        }
    }

    function getUserRolesAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserRolesSuccessAction(state: UsersStateModel, action: UsersActions.getUserRoleRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getUserRolesFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }

    function addMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addMemberSuccessAction(state: UsersStateModel, action: UsersActions.addMembersRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isMemberAdded: true,
            message: 'Success',
            loading: false
        };
    }
    function addMemberFailureAction(state: UsersStateModel, action: UsersActions.addMembersRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isMemberAdded: false,
            error: true,
            loading: false
        }
    }
    function getMemberDetailsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberDetailsSuccessAction(state: UsersStateModel, action: UsersActions.getMemberDetailRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                memberDetail: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberDetailsFailureAction(state: UsersStateModel, action: UsersActions.getMemberDetailRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function UpdateUserAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function UpdateUserSuccessAction(state: UsersStateModel, action: UsersActions.UpdateUserRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isUserUpdated: true,
            message: 'Success',
            loading: false,
            data: state.data.map(user => user.userId === action.payload?.userId ? action.payload : user)
        };
    }
    function UpdateUserFailureAction(state: UsersStateModel, action: UsersActions.UpdateUserRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isUserUpdated: false,
            loading: false,
            error: true
        }
    }
    function UserMfaSetupAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function UserMfaSetupSuccessAction(state: UsersStateModel, action: UsersActions.UserMfaSetupRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isMfaUpdated: true,
            message: 'Success',
            loading: false,
        };
    }
    function UserMfaSetupFailureAction(state: UsersStateModel, action: UsersActions.UserMfaSetupRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isMfaUpdated: false,
            loading: false,
            message: 'Fail',
            error: true,
        }
    }
    function GenerateMFAQrAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function GenerateMFAQrSuccessAction(state: UsersStateModel, action: UsersActions.GenerateMFAQrRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isQrGenerated: true,
            message: 'Success',
            loading: false,
            qrdata: action.payload,
        };
    }
    function GenerateMFAQrFailureAction(state: UsersStateModel, action: UsersActions.GenerateMFAQrRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isQrGenerated: false,
            loading: false,
            error: true,
            message: 'Fail',
        }
    }
    function VerifyMfaCodeAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
            message: ""
        };
    }
    function VerifyMfaCodeSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isCodeVerified: true,
            message: 'Success',
            loading: false,
        };
    }
    function VerifyMfaCodeFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isCodeVerified: false,
            loading: false,
            error: true,
            message: 'Fail',
        }
    }
    function resetMFAStateAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isCodeVerified: false,
            isMfaUpdated: false,
            message: '',
            loading: false,
        }
    }
    function editMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function editMemberSuccessAction(state: UsersStateModel, action: UsersActions.editMemberRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            isMemberUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function editMemberFailureAction(state: UsersStateModel, action: UsersActions.editMemberRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isMemberUpdated: false,
            loading: false,
            error: true
        }
    }
    function deleteMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteMemberSuccessAction(state: UsersStateModel, action: UsersActions.deleteMemberRequestSuccessAction): UsersStateModel {

        return {
            ...state,
            isMemberDeleted: true,
            message: 'Success',
            loading: false
        };
    }


    function deleteMemberFailureAction(state: UsersStateModel, action: UsersActions.deleteMemberRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isMemberDeleted: false,
            loading: false,
            error: true
        }
    }
    function uploadProfileAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function uploadProfileSuccessAction(state: UsersStateModel, action: UsersActions.uploadProfileRequestSuccessAction): UsersStateModel {

        return {
            ...state,
            isProfileUploaded: true,
            loading: false
        };
    }


    function uploadProfileFailureAction(state: UsersStateModel, action: UsersActions.uploadProfileRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isProfileUploaded: false,
            loading: false
        }
    }
    function updateCreditPreferenceAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateCreditPreferenceSuccessAction(state: UsersStateModel, action: UsersActions.updateCreditPreferenceRequestSuccessAction): UsersStateModel {

        return {
            ...state,
            isCreditPrefrencesUpdated: true,
            loading: false
        };
    }


    function updateCreditPreferenceFailureAction(state: UsersStateModel, action: UsersActions.updateCreditPreferenceRequestFailureAction): UsersStateModel {
        return {
            ...state,
            isCreditPrefrencesUpdated: false,
            loading: false
        }
    }
    function getStripeSubscriptionAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getStripeSubscriptionSuccessAction(state: UsersStateModel, action: UsersActions.getStripeSubscriptionRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                stripeSubscription: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getStripeSubscriptionFailureAction(state: UsersStateModel, action: UsersActions.getStripeSubscriptionRequestFailureAction): UsersStateModel {
        return {
            ...state,
            loading: false,
            success: false
        }
    }
    function checkUserNameAvailableAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function checkUserNameAvailableSuccessAction(state: UsersStateModel, action: UsersActions.checkUserNameAvailableRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                isUserNameAvailable: action.payload,
                loading: false
            };
        }
        return state;
    }
    function checkUserNameAvailableFailureAction(state: UsersStateModel, action: UsersActions.checkUserNameAvailableRequestFailureAction): UsersStateModel {
        return {
            ...state,
            loading: false,
            success: false
        }
    }
    function getMemberVideoAnalyticsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberVideoAnalyticsSuccessAction(state: UsersStateModel, action: UsersActions.getMemberVideoAnalyticsRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                memberVideoanalytics: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getMemberVideoAnalyticsFailureAction(state: UsersStateModel, action: UsersActions.getMemberVideoAnalyticsRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getMemberSubscriptionAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberSubscriptionSuccessAction(state: UsersStateModel, action: UsersActions.getMemberSubscriptionRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                memberSubscription: action.payload,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getMemberSubscriptionFailureAction(state: UsersStateModel, action: UsersActions.getMemberSubscriptionRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getUserProfileAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserProfileSuccessAction(state: UsersStateModel, action: UsersActions.getUserProfileRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                userProfile: action.payload,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getUserProfileFailureAction(state: UsersStateModel, action: UsersActions.getUserProfileRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getMemberInvoiceAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberInvoiceSuccessAction(state: UsersStateModel, action: UsersActions.getMemberInvoicesRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                memberInvoice: action.payload.data,
                invoicePaginationMeta: action.payload.paginationMeta,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getMemberInvoiceFailureAction(state: UsersStateModel, action: UsersActions.getMemberInvoicesRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getTotalMembersAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTotalMembersSuccessAction(state: UsersStateModel, action: UsersActions.getTotalMembersRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                totalMembers: action.payload,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getTotalMembersFailureAction(state: UsersStateModel, action: UsersActions.getTotalMembersRequestFailureAction): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getSubscriptionProductsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSubscriptionProductsSuccessAction(state: UsersStateModel, action: UsersActions.getSubscriptionProductsRequestSuccessAction): UsersStateModel {

        if (action.payload) {
            return {
                ...state,
                subscriptionProducts: action.payload,
                success: true,
                loading: false
            };
        }
        return state;
    }
    function getSubscriptionProductsFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function deleteMemberListAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteMemberListSuccessAction(state: UsersStateModel, action: UsersActions.deleteMemberListRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                isMemberListDeleted: true,
                message: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function deleteMemberListFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            error: true
        }
    }
    function getMemberListAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberListloading: true,
        };
    }
    function getMemberListSuccessAction(state: UsersStateModel, action: UsersActions.getMemberListRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                isMemberListloading: false,
                memberList: action.payload.data,
                paginationMeta: action.payload.paginationMeta
            };
        }
        return state
    }
    function getMemberListFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberListloading: false
        }
    }
    function getUserActivityTimeLineAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserActivityTimeLineSuccessAction(state: UsersStateModel, action: UsersActions.getUserActivityTimeLineRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                userActivityTimeLine: action.payload.data,
                activityPaginationMeta: action.payload.paginationMeta,
            };
        }
        return state
    }
    function getUserActivityTimeLineFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveMemberListAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveMemberListSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            isMemberListSaved: true,
            message: 'Success'
        };

    }
    function saveMemberListFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            isMemberListSaved: false,
            error: true
        }
    }
    function updateMemberListAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateMemberListSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            isMemberListUpdated: true,
            message: 'Success',
        };
    }
    function updateMemberListFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            isMemberListUpdated: false,
            error: true
        }
    }
    function getSingleMemberListAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleMemberListSuccessAction(state: UsersStateModel, action: UsersActions.getSingleMemberListRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                singleMemberList: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleMemberListFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function saveMemberListTypeAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveMemberListTypeSuccessAction(state: UsersStateModel): UsersStateModel {

        return {
            ...state,
            isMemberListTypeSaved: true,
            loading: false
        };
    }
    function saveMemberListTypeFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
            isMemberListTypeSaved: false,
        }
    }
    function searchMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function searchMemberSuccessAction(state: UsersStateModel, action: UsersActions.searchMemberRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                members: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                loading: false
            };
        }
        return state;
    }
    function searchMemberFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getRolesAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRolesSuccessAction(state: UsersStateModel, action: UsersActions.getRolesRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                roles: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getRolesFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getStatusAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getStatusSuccessAction(state: UsersStateModel, action: UsersActions.getStatusRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                status: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getStatusFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function resetMemberAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberAdded: false,
            isMemberDeleted: false,
            isMemberListDeleted: false,
            isMemberUpdated: false,
            isMemberListSaved: false,
            isMemberListUpdated: false,
            isMemberProfileUpdated: false,
            isEmailDomainUploaded: false,
            isEmailDomainAlreadyExist: false,
            isEmailAlreadyExist: false,
            isUserNameAlreadyExist: false,
            isEmailUploaded: false,
            isUserNameUploaded: false,
            isMemberListDetailsDeleted: false,
            loading: false,
            error: false,
        };
    }
    function resetMemberDataStateAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            memberDetail: initialState.memberDetail
        };
    }
    function resetUserAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isUserAdded: false,
            isUserDeleted: false,
            isUserListDeleted: false,
            isUserUpdated: false,
            loading: false,
            error: false,
        };
    }
    function resetUserDataStateAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            userDetails: initialState.userDetails
        };
    }
    function getLoginHistoryAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLoginHistorySuccessAction(state: UsersStateModel, action: UsersActions.getLoginHistoryRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                loginHistory: action.payload.data,
                loginHistoryPaginationMeta: action.payload.paginationMeta,
                loading: false
            };
        }
        return state;
    }
    function getLoginHistoryFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getMemberListByMemberIdAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberListByMemberIdSuccessAction(state: UsersStateModel, action: UsersActions.getMemberListByMemberIdRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                memberListByMemberId: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberListByMemberIdFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getMemberEventRegistrationAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberEventRegistrationSuccessAction(state: UsersStateModel, action: UsersActions.getMemberEventRegistrationRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                memberEvent: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberEventRegistrationFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getMemberCountDataAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberCountDataSuccessAction(state: UsersStateModel, action: UsersActions.getMemberCountDataRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                memberCountData: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberCountDataFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function updateMemberProfileAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateMemberProfileSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberProfileUpdated: true,
            loading: false
        };
    }
    function updateMemberProfileFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberProfileUpdated: false,
            loading: false
        }
    }
    function uploadEmailDomainAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function uploadEmailDomainSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailDomainUploaded: true,
            loading: false
        };
    }
    function uploadEmailDomainRequestDataExistActionType(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailDomainAlreadyExist: true,
            isEmailDomainUploaded: true,
            loading: false,
        }
    }
    function uploadEmailDomainFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailDomainUploaded: false,
            isEmailDomainAlreadyExist: false,
            loading: false,
        }
    }
    function uploadEmailAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function uploadEmailSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailUploaded: true,
            loading: false
        };
    }
    function uploadEmailRequestDataExistActionType(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailAlreadyExist: true,
            isEmailUploaded: true,
            loading: false,
        }
    }
    function uploadEmailFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isEmailAlreadyExist: false,
            isEmailUploaded: false,
            loading: false,
        }
    }
    function uploadUsernameAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function uploadUserNameSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isUserNameUploaded: true,
            loading: false
        };
    }
    function uploadUserNameRequestDataExistActionType(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isUserNameAlreadyExist: true,
            isUserNameUploaded: true,
            loading: false,
        }
    }
    function uploadUserNameFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isUserNameUploaded: false,
            isUserNameAlreadyExist: false,
            loading: false,
        }
    }
    function searchMemberListDetailsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function searchMemberListDetailsSuccessAction(state: UsersStateModel, action: UsersActions.searchMemberListDetailsRequestSuccessAction): UsersStateModel {
        if (action.payload) {
            return {
                ...state,
                searchedData: action.payload.data as any,
                paginationMeta: action.payload.paginationMeta,
                loading: false
            };
        }
        return state;
    }
    function searchMemberListDetailsFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function deleteMemberListDetailsAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteMemberListDetailsSuccessAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberListDetailsDeleted: true,
            loading: false
        };
    }
    function deleteMemberListDetailsFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            isMemberListDetailsDeleted: false,
            loading: false,
        }
    }
    function changePasswordAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function changePasswordSuccessAction(state: UsersStateModel, action: UsersActions.changePasswordRequestSuccessAction): UsersStateModel {
        return {
            ...state,
            passwordChangedObj: action.payload,
            isPasswordChanged: true,
            loading: false
        };
    }
    function changePasswordFailureAction(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            passwordChangedObj: { error: 'error' },
            isPasswordChanged: false,
            loading: false,
        }
    }
    function reset(state: UsersStateModel): UsersStateModel {
        return {
            ...state,
            passwordChangedObj: {},
            isPasswordChanged: false,
        }
    }
}

export default userReducer
