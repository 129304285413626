import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { RootState } from 'src/store/reducers/state';
import { useNavigate } from 'react-router-dom';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Modal from '../components/Modal';
import ToastMessage from 'src/components/ToastContainer';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/components/loader';
import { MemberCSVModel } from 'src/models/usersModel';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState('');
  const userAction = useUserActions(dispatch);
  const [finalMemberList, setFinalMemberList] = useState<any>();
  const navigate = useNavigate();
  const [downloadData, setDownloadData] = useState<MemberCSVModel[]>();
  const { users, userlistLoading, userDetails } = useSelector((state: RootState) => {
    return {
      users: state.users.data,
      userDetails: state.users.userDetails,
      userlistLoading: state.users.userlistLoading
    };
  });

  useEffect(() => {
    userAction.getUserRequest();
  }, [userDetails?.userId]);

  const handleUserDetailClick = (userId: string) => {
    navigate(`/user-details/${userId}`, { state: { userId: userId } });
  };
  const handleEditClick = (id: string) => {
    setModalFlag('edit');
    userAction.getUserDetailsRequest(id);
  };
  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold "></h2>
                <div className="md:flex items-center text-center">
                  <div className="form-group md:flex items-center text-center">
                    <label className="mr-1 font-semibold">Search:</label>
                    <input
                      // onChange={(e) => handleSearch(e)}
                      className="input-style w-48 mr-3 mb-1"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <button
                    onClick={() => setModalFlag('add')}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#editMemberModal">
                    Add New User
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto-sm">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        {/* <th className="p-2">
                          <div className="font-semibold text-left">UserName</div>
                        </th> */}
                        <th className="p-2">
                          <div className="font-semibold text-left">Email</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Created</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Last Login</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Role</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">MFA Setup</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {userlistLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {users.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {users &&
                              users.map((user, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <td className="p-2">
                                      <div className="flex items-center">
                                        <div className="font-medium ">{`${user.userName} `}</div>
                                      </div>
                                    </td> */}
                                    <td className="p-2">
                                      <div className="text-left">{user.email}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{user.name}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {user.created == null ? "" : defaultDateFormatter(user.created)}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {user.lastLogin == null ? "" : defaultDateFormatter(user.lastLogin)}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="text-left">{user.role}</div>
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{user.isMfaSetupEnabled ? "Enabled" : "Not Setup"}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="dropdown relative">
                                          <button
                                            className="dropdown-toggle"
                                            type="button"
                                            id="action-dropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img
                                              src="../../assets/img/dots-vertical.svg"
                                              className="w-5 h-5"
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu dropdown-menu-ul"
                                            aria-labelledby="action-dropdown">
                                            <li>
                                              <button className="action-menu flex items-center">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="mr-2 w-4 h-4">
                                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                  <polyline points="14 2 14 8 20 8"></polyline>
                                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                                  <polyline points="10 9 9 9 8 9"></polyline>
                                                </svg>
                                                <span
                                                  onClick={() =>
                                                    handleUserDetailClick(user.userId)
                                                  }>
                                                  Details
                                                </span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => handleEditClick(user.userId)}
                                                className="action-menu flex items-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editMemberModal">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Edit</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        ) : (
                          <tr>
                            <td className="text-center py-2" colSpan={6}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                  {/* {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal userDetails={userDetails} modalFlag={modalFlag} />
      </div>
    </>
  );
};

export default Users;
