import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import FormTextareaField from 'src/components/common/formTextareaField';
const UserProfile = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { userProfile } = useSelector((state: RootState) => {
    return {
      userProfile: state.appearance.userProfile
    };
  });

  useEffect(() => {
    if (userProfile) {
      reset(userProfile);
    }
  }, [userProfile]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'userProfile', value: data.userProfile },
      { op: 'replace', path: 'userProfileMyProfile', value: data.userProfileMyProfile },
      { op: 'replace', path: 'userProfileActivity', value: data.userProfileActivity },
      { op: 'replace', path: 'userProfileCourses', value: data.userProfileCourses },
      { op: 'replace', path: 'userProfilePurchases', value: data.userProfilePurchases },
      { op: 'replace', path: 'userProfileSubscription', value: data.userProfileSubscription },
      { op: 'replace', path: 'userProfileExternalUrl', value: data.userProfileExternalUrl },
      { op: 'replace', path: 'userProfilecreditscore', value: data.userProfilecreditscore },
      { op: 'replace', path: 'askMemberInterests', value: data.askMemberInterests },
      { op: 'replace', path: 'memberInterestsHeader', value: data.memberInterestsHeader },
      { op: 'replace', path: 'memberInterestsDescription', value: data.memberInterestsDescription }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  return (
    <div className="tab-pane fade" id="tabs-videoUserProfile" role="tabpanel">
      <form onSubmit={handleSubmit(onSubmit)} className="p-5">
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              User Profile
            </label>
            <p>Display profile menu to the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfile"
                    {...register('userProfile')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              My Profile
            </label>
            <p>Display the profile of the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfileMyProfile"
                    {...register('userProfileMyProfile')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Activity
            </label>
            <p>Display the watched videos list of the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfileActivity"
                    {...register('userProfileActivity')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Courses
            </label>
            <p>Display the Course Results of the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfileCourses"
                    {...register('userProfileCourses')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Purchases
            </label>
            <p>Display the purchased videos of the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfilePurchases"
                    {...register('userProfilePurchases')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Subscriptions
            </label>
            <p>Display the subscriptions of the user.</p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfileSubscription"
                    {...register('userProfileSubscription')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Profile External Url
            </label>
            <p>
              Set this value when you want to redirect to the external page where you manage
              information of the users. Keep this blank if you want to use default profile
              information for users.
            </p>
          </div>
          <div className="md:w-8/12">
            <div className="md:mx-10 mx-5">
              <input
                type="text"
                className="input-style"
                placeholder="External Profile URL"
                id="userProfileExternalUrl"
                {...register('userProfileExternalUrl')}
              />
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Credit Preferences
            </label>
            <p>
              If set to true, a user will be displayed the option as to whether they certify for
              gaining continuing education points. If true, they will receive the credit type
              allocated to the course or video.
            </p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="userProfilecreditscore"
                    {...register('userProfilecreditscore')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Ask Member Interests
            </label>
            {/* <p>Display the subscriptions of the user.</p> */}
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="askMemberInterests"
                    {...register('askMemberInterests')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Member Interest header
            </label>
           
          </div>
          <div className="md:w-8/12">
            <div className="md:mx-10 mx-5">
              <input
                type="text"
                className="input-style"
                placeholder="External Profile URL"
                id="memberInterestsHeader"
                {...register('memberInterestsHeader')}
              />
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Member Interest Description
            </label>
          </div>
          <div className="md:w-8/12">
            <div className="md:mx-10 mx-5">
            <FormTextareaField
                          label={''}
                          fieldName={'memberInterestsDescription'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                        />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
