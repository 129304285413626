import { DiscountPolicyModel, MonetizationStateModel, PackageDetailsModel, DiscountPolicyDetailsModel, PackageModel, VoucherModel, BundleModel, BundleDetailsModel, SubscriptionModel } from "src/models/monetizationModel";
import { RootState } from "../reducers/state";
import { MonetizationActions } from "./actions";
import * as Constants from './constants'

const initialState: RootState.MonetizationModelState = {
    discountPolicies: [],
    discountPolicyInfo: {} as DiscountPolicyModel,
    loading: false,
    loadingDetails: false,
    loadingCode: false,
    loadingDiscountMemberList: false,
    discountPolicyDetails: {} as DiscountPolicyDetailsModel,
    isDiscountPolicySaved: false,
    isDiscountPolicyUpdated: false,
    isDiscountPolicyAdded: false,
    isDiscountPolicyDeleted: false,
    isDiscountMemberListAdded: false,
    isDiscountMemberListDeleted: false,
    isDiscountCodeDeleted: false,
    isDiscountCodeAdded: false,
    message: '',

    packageDetails: {} as PackageDetailsModel,
    loadingPackage: false,
    loadingPackageDetails: false,
    isPackageDeleted: false,
    isPackageAdded: false,
    isPackageUpdated: false,
    isPackageMemberListAdded: false,
    isPackageMemberListDeleted: false,
    loadingPackageMemberList: false,
    packagesList: [],
    packageInfo: {} as PackageModel,

    voucherList: [],
    voucherInfo: {} as VoucherModel,
    loadingVoucher: false,
    loadingVoucherDetails: false,
    isVoucherAdded: false,
    isVoucherUpdated: false,
    isVoucherDeleted: false,
    voucherUsageList: [],
    isVoucherUsageLoaded: false,
    isVoucherStatusUpdated: false,

    bundlesList: [],
    bundleInfo: {} as BundleModel,
    bundleDetails: {} as BundleDetailsModel,
    loadingBundle: false,
    loadingBundleDetails: false,
    isBundleDeleted: false,
    isBundleAdded: false,
    isBundleUpdated: false,
    isBundleMemberListAdded: false,
    isBundleMemberListDeleted: false,
    loadingBundleMemberList: false,

    subscriptionList: [],
    subscriptionInfo: {} as SubscriptionModel,
    subscriptionDetails: {} as SubscriptionModel,
    loadingSubscription: false,
    loadingSubscriptionDetails: false,
    isSubscriptionAdded: false,
    isSubscriptionDeleted: false,
    isSubscriptionUpdated: false,
    subscriptionSubscribersList: [],
    loadingSubscriptionSubscribers: false,


};
function monetizationReducer(state: MonetizationStateModel = initialState, action: any): MonetizationStateModel {
    switch (action.type) {
        case Constants.getDiscountPoliciesActionType: return getDiscountPolicies(state);
        case Constants.getDiscountPoliciesSuccessActionType: return getDiscountPoliciesSuccess(state, action);
        case Constants.getDiscountsPoliciesFailureActionType: return getDiscountPoliciesFailure(state, action);
        case Constants.getDiscountDetailRequestActionType: return getDiscountDetails(state);
        case Constants.getDiscountDetailRequestSuccessActionType: return getDiscountDetailsSuccess(state, action);
        case Constants.getDiscountDetailRequestFailureActionType: return getDiscountDetailsFailure(state, action);
        case Constants.addDiscountPolicyRequestActionType: return addDiscount(state);
        case Constants.addDiscountPolicyRequestSuccessActionType: return addDiscountSuccess(state, action);
        case Constants.addDiscountPolicyRequestFailureActionType: return addDiscountFailure(state, action);
        case Constants.editDiscountPolicyRequestActionType: return editDiscount(state);
        case Constants.editDiscountPolicyRequestSuccessActionType: return editDiscountSuccess(state, action);
        case Constants.editDiscountPolicyRequestFailureActionType: return editDiscountFailure(state, action);
        case Constants.deleteDiscountPolicyRequestActionType: return deleteDiscount(state);
        case Constants.deleteDiscountPolicyRequestSuccessActionType: return deleteDiscountSuccess(state, action);
        case Constants.deleteDiscountPolicyRequestFailureActionType: return deleteDiscountFailure(state, action);
        case Constants.addDiscountCodeRequestActionType: return addDiscountCode(state);
        case Constants.addDiscountCodeRequestSuccessActionType: return addDiscountCodeSuccess(state, action);
        case Constants.addDiscountCodeRequestFailureActionType: return addDiscountCodeFailure(state, action);
        case Constants.deleteDiscountCodeRequestActionType: return deleteDiscountCode(state);
        case Constants.deleteDiscountCodeRequestSuccessActionType: return deleteDiscountCodeSuccess(state, action);
        case Constants.deleteDiscountCodeRequestFailureActionType: return deleteDiscountCodeFailure(state, action);
        case Constants.addDiscountMemberListRequestActionType: return addDiscountMemberList(state);
        case Constants.addDiscountMemberListRequestSuccessActionType: return addDiscountMemberListSuccess(state, action);
        case Constants.addDiscountMemberListRequestFailureActionType: return addDiscountMemberListFailure(state, action);
        case Constants.deleteDiscountMemberListRequestActionType: return deleteDiscountMemberList(state);
        case Constants.deleteDiscountMemberListRequestSuccessActionType: return deleteDiscountMemberListSuccess(state, action);
        case Constants.deleteDiscountMemberListRequestFailureActionType: return deleteDiscountMemberListFailure(state, action);

        case Constants.getPackagesActionType: return getPackages(state);
        case Constants.getPackagesSuccessActionType: return getPackagesSuccess(state, action);
        case Constants.getPackagesFailureActionType: return getPackagesFailure(state, action);
        case Constants.getPackageDetailsRequestActionType: return getPackageDetails(state);
        case Constants.getPackageDetailsRequestSuccessActionType: return getPackageDetailsSuccess(state, action);
        case Constants.getPackageDetailsRequestFailureActionType: return getPackageDetailsFailure(state, action);
        case Constants.addPackageRequestActionType: return addPackage(state);
        case Constants.addPackageRequestSuccessActionType: return addPackageSuccess(state, action);
        case Constants.addPackageRequestFailureActionType: return addPackageFailure(state, action);
        case Constants.editPackageRequestActionType: return editPackage(state);
        case Constants.editPackageRequestSuccessActionType: return editPackageSuccess(state, action);
        case Constants.editPackageRequestFailureActionType: return editPackageFailure(state, action);
        case Constants.deletePackageRequestActionType: return deletePackage(state);
        case Constants.deletePackageRequestSuccessActionType: return deletePackageSuccess(state, action);
        case Constants.deletePackageRequestFailureActionType: return deletePackageFailure(state, action);
        case Constants.addPackageMemberListRequestActionType: return addPackageMemberList(state);
        case Constants.addPackageMemberListRequestSuccessActionType: return addPackageMemberListSuccess(state, action);
        case Constants.addPackageMemberListRequestFailureActionType: return addPackageMemberListFailure(state, action);
        case Constants.deletePackageMemberListRequestActionType: return deletePackageMemberList(state);
        case Constants.deletePackageMemberListRequestSuccessActionType: return deletePackageMemberListSuccess(state, action);
        case Constants.deletePackageMemberListRequestFailureActionType: return deletePackageMemberListFailure(state, action);

        case Constants.getVouchersActionType: return getVouchers(state);
        case Constants.getVouchersSuccessActionType: return getVouchersSuccess(state, action);
        case Constants.getVouchersFailureActionType: return getVouchersFailure(state, action);
        case Constants.getVoucherDetailsRequestActionType: return getVoucherDetails(state);
        case Constants.getVoucherDetailsRequestSuccessActionType: return getVoucherDetailsSuccess(state, action);
        case Constants.getVoucherDetailsRequestFailureActionType: return getVoucherDetailsFailure(state, action);
        case Constants.addVoucherRequestActionType: return addVoucher(state);
        case Constants.addVoucherRequestSuccessActionType: return addVoucherSuccess(state, action);
        case Constants.addVoucherRequestFailureActionType: return addVoucherFailure(state, action);
        case Constants.editVoucherRequestActionType: return editVoucher(state);
        case Constants.editVoucherRequestSuccessActionType: return editVoucherSuccess(state, action);
        case Constants.editVoucherRequestFailureActionType: return editVoucherFailure(state, action);
        case Constants.deleteVoucherRequestActionType: return deleteVoucher(state);
        case Constants.deleteVoucherRequestSuccessActionType: return deleteVoucherSuccess(state, action);
        case Constants.deleteVoucherRequestFailureActionType: return deleteVoucherFailure(state, action);
        case Constants.updateVoucherStatusRequestActionType: return editVoucherStatus(state);
        case Constants.updateVoucherStatusRequestSuccessActionType: return editVoucherStatusSuccess(state, action);
        case Constants.updateVoucherStatusRequestFailureActionType: return editVoucherStatusFailure(state, action);
        case Constants.getVoucherUsageRequestActionType: return getVoucherUsage(state);
        case Constants.getVoucherUsageRequestSuccessActionType: return getVoucherUsageSuccess(state, action);
        case Constants.getVoucherUsageRequestFailureActionType: return getVoucherUsageFailure(state, action);

        case Constants.getBundlesActionType: return getBundles(state);
        case Constants.getBundlesSuccessActionType: return getBundlesSuccess(state, action);
        case Constants.getBundlesFailureActionType: return getBundlesFailure(state, action);
        case Constants.getBundleDetailsRequestActionType: return getBundleDetails(state);
        case Constants.getBundleDetailsRequestSuccessActionType: return getBundleDetailsSuccess(state, action);
        case Constants.getBundleDetailsRequestFailureActionType: return getBundleDetailsFailure(state, action);
        case Constants.addBundleRequestActionType: return addBundle(state);
        case Constants.addBundleRequestSuccessActionType: return addBundleSuccess(state, action);
        case Constants.addBundleRequestFailureActionType: return addBundleFailure(state, action);
        case Constants.editBundleRequestActionType: return editBundle(state);
        case Constants.editBundleRequestSuccessActionType: return editBundleSuccess(state, action);
        case Constants.editBundleRequestFailureActionType: return editBundleFailure(state, action);
        case Constants.deleteBundleRequestActionType: return deleteBundle(state);
        case Constants.deleteBundleRequestSuccessActionType: return deleteBundleSuccess(state, action);
        case Constants.deleteBundleRequestFailureActionType: return deleteBundleFailure(state, action);
        case Constants.addBundleMemberListRequestActionType: return addBundleMemberList(state);
        case Constants.addBundleMemberListRequestSuccessActionType: return addBundleMemberListSuccess(state, action);
        case Constants.addBundleMemberListRequestFailureActionType: return addBundleMemberListFailure(state, action);
        case Constants.deleteBundleMemberListRequestActionType: return deleteBundleMemberList(state);
        case Constants.deleteBundleMemberListRequestSuccessActionType: return deleteBundleMemberListSuccess(state, action);
        case Constants.deleteBundleMemberListRequestFailureActionType: return deleteBundleMemberListFailure(state, action);

        case Constants.getSubscriptionsActionType: return getSubscriptions(state);
        case Constants.getSubscriptionsSuccessActionType: return getSubscriptionsSuccess(state, action);
        case Constants.getSubscriptionsFailureActionType: return getSubscriptionsFailure(state, action);
        case Constants.getSubscriptionDetailsRequestActionType: return getSubscriptionDetails(state);
        case Constants.getSubscriptionDetailsRequestSuccessActionType: return getSubscriptionDetailsSuccess(state, action);
        case Constants.getSubscriptionDetailsRequestFailureActionType: return getSubscriptionDetailsFailure(state, action);
        case Constants.addSubscriptionRequestActionType: return addSubscription(state);
        case Constants.addSubscriptionRequestSuccessActionType: return addSubscriptionSuccess(state, action);
        case Constants.addSubscriptionRequestFailureActionType: return addSubscriptionFailure(state, action);
        case Constants.editSubscriptionRequestActionType: return editSubscription(state);
        case Constants.editSubscriptionRequestSuccessActionType: return editSubscriptionSuccess(state, action);
        case Constants.editSubscriptionRequestFailureActionType: return editSubscriptionFailure(state, action);
        case Constants.deleteSubscriptionRequestActionType: return deleteSubscription(state);
        case Constants.deleteSubscriptionRequestSuccessActionType: return deleteSubscriptionSuccess(state, action);
        case Constants.deleteSubscriptionRequestFailureActionType: return deleteSubscriptionFailure(state, action);
        case Constants.getSubscriptionSubscribersRequestActionType: return getSubscriptionSubscribers(state);
        case Constants.getSubscriptionSubscribersRequestSuccessActionType: return getSubscriptionSubscribersSuccess(state, action);
        case Constants.getSubscriptionSubscribersRequestFailureActionType: return getSubscriptionSubscribersFailure(state, action);
        case Constants.resetPackagePolicyDataStateActionType: return resetPackagePolicyData(state);
        case Constants.resetDiscountPolicyDataStateActionType: return resetDiscountPolicyData(state);
        default: return state;
    }
}

function getDiscountPolicies(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loading: true,
    };
}
function getDiscountPoliciesSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        discountPolicies: action.payload.result.sort((a: DiscountPolicyModel, b: DiscountPolicyModel) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }),
        loading: false,
    };
}
function getDiscountPoliciesFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        loading: false,
    };
}

function getDiscountDetails(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingDetails: true,
    };
}
function getDiscountDetailsSuccess(state: MonetizationStateModel, action: MonetizationActions.getDiscountDetailsSuccessAction): MonetizationStateModel {

    return {
        ...state,
        discountPolicyDetails: action.payload.result,
        discountPolicyInfo: {
            id: action.payload.result.id,
            name: action.payload.result.name,
            channelId: action.payload.result.channelId,
            channelName: action.payload.result.channelName,
            message: action.payload.result.message,
            channelPercentage: action.payload.result.channelPercentage,
            percentage: action.payload.result.percentage,
        },
        loadingDetails: false,
    }
}
function getDiscountDetailsFailure(state: MonetizationStateModel, action: MonetizationActions.getDiscountDetailsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingDetails: false,
    }
}

function addDiscount(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyAdded: false,
        loadingDetails: true,
    };
}
function addDiscountSuccess(state: MonetizationStateModel, action: MonetizationActions.addDiscountPolicyRequestAction): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyAdded: true,
        loadingDetails: false
    };
}
function addDiscountFailure(state: MonetizationStateModel, action: MonetizationActions.addDiscountPolicyRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyAdded: false,
        loadingDetails: false
    }
}

function editDiscount(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingDetails: true,
        isDiscountPolicyUpdated: false,
    };
}
function editDiscountSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyUpdated: true,
        loadingDetails: false,
    };
}
function editDiscountFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyUpdated: false,
        loadingDetails: false,
    };
}

function deleteDiscount(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyDeleted: false,
        loading: true,
    };
}
function deleteDiscountSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyDeleted: true,
        loading: false,
    };
}
function deleteDiscountFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyDeleted: false,
        loading: false,
    };
}

function addDiscountCode(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeAdded: false,
        loadingCode: true,
    };
}
function addDiscountCodeSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeAdded: true,
        loadingCode: false,
    };
}
function addDiscountCodeFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeAdded: false,
        loadingCode: false,
    };
}

function deleteDiscountCode(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeDeleted: false,
        loadingCode: true,
    };
}
function deleteDiscountCodeSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeDeleted: true,
        loadingCode: false,
    };
}
function deleteDiscountCodeFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountCodeDeleted: false,
        loadingCode: false,
    };
}

function addDiscountMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListAdded: false,
        loadingDiscountMemberList: true,
    };
}
function addDiscountMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListAdded: true,
        loadingDiscountMemberList: false,
    };
}
function addDiscountMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListAdded: false,
        loadingDiscountMemberList: false,
    };
}

function deleteDiscountMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListDeleted: false,
        loadingDiscountMemberList: true,
    };
}
function deleteDiscountMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListDeleted: true,
        loadingDiscountMemberList: false,
    };
}
function deleteDiscountMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isDiscountMemberListDeleted: false,
        loadingDiscountMemberList: false,
    };
}

//Packages
function getPackages(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingPackage: true,
    };
}
function getPackagesSuccess(state: MonetizationStateModel, action: MonetizationActions.getPackageSuccessAction): MonetizationStateModel {
    return {
        ...state,
        packagesList: action.payload.result.sort((a: PackageDetailsModel, b: PackageDetailsModel) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }),
        loadingPackage: false,
    };
}
function getPackagesFailure(state: MonetizationStateModel, action: MonetizationActions.getPackageFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingPackage: false,
    };
}

function getPackageDetails(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingPackageDetails: true,
    };
}
function getPackageDetailsSuccess(state: MonetizationStateModel, action: MonetizationActions.getPackageDetailsSuccessAction): MonetizationStateModel {

    return {
        ...state,
        packageDetails: action.payload.result,
        packageInfo: {
            id: action.payload.result.id,
            name: action.payload.result.name,
            channelId: action.payload.result.channelId,
            channelName: action.payload.result.channelName
        },
        loadingPackageDetails: false,
    }
}
function getPackageDetailsFailure(state: MonetizationStateModel, action: MonetizationActions.getPackageDetailsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingPackageDetails: false,
    }
}

function addPackage(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isPackageAdded: false,
        loadingPackageDetails: true,
    };
}
function addPackageSuccess(state: MonetizationStateModel, action: MonetizationActions.addPackageRequestAction): MonetizationStateModel {
    return {
        ...state,
        isPackageAdded: true,
        loadingPackageDetails: false
    };
}
function addPackageFailure(state: MonetizationStateModel, action: MonetizationActions.addPackageRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isPackageAdded: false,
        loadingPackageDetails: false
    }
}

function editPackage(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingPackageDetails: true,
        isPackageUpdated: false,
    };
}
function editPackageSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageUpdated: true,
        loadingPackageDetails: false,
    };
}
function editPackageFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageUpdated: false,
        loadingPackageDetails: false,
    };
}

function deletePackage(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isPackageDeleted: false,
        loadingPackage: true,
    };
}
function deletePackageSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageDeleted: true,
        loadingPackage: false,
    };
}
function deletePackageFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageDeleted: false,
        loadingPackage: false,
    };
}

function addPackageMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListAdded: false,
        loadingPackageMemberList: true,
    };
}
function addPackageMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListAdded: true,
        loadingPackageMemberList: false,
    };
}
function addPackageMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListAdded: false,
        loadingPackageMemberList: false,
    };
}

function deletePackageMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListDeleted: false,
        loadingPackageMemberList: true,
    };
}
function deletePackageMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListDeleted: true,
        loadingPackageMemberList: false,
    };
}
function deletePackageMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isPackageMemberListDeleted: false,
        loadingPackageMemberList: false,
    };
}

//Vouchers
function getVouchers(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingVoucher: true,
    };
}
function getVouchersSuccess(state: MonetizationStateModel, action: MonetizationActions.getVoucherSuccessAction): MonetizationStateModel {
    return {
        ...state,
        voucherList: action.payload.result.sort((a: VoucherModel, b: VoucherModel) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }),
        loadingVoucher: false,
    };
}
function getVouchersFailure(state: MonetizationStateModel, action: MonetizationActions.getVoucherFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingVoucher: false,
    };
}

function getVoucherDetails(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingVoucherDetails: true,
    };
}
function getVoucherDetailsSuccess(state: MonetizationStateModel, action: MonetizationActions.getVoucherDetailsSuccessAction): MonetizationStateModel {
    return {
        ...state,
        voucherInfo: action.payload.result,
        loadingVoucherDetails: false,
    }
}
function getVoucherDetailsFailure(state: MonetizationStateModel, action: MonetizationActions.getVoucherDetailsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingVoucherDetails: false,
    }
}

function addVoucher(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isVoucherAdded: false,
        loadingVoucherDetails: true,
    };
}
function addVoucherSuccess(state: MonetizationStateModel, action: MonetizationActions.addVoucherRequestAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherAdded: true,
        loadingVoucherDetails: false
    };
}
function addVoucherFailure(state: MonetizationStateModel, action: MonetizationActions.addVoucherRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherAdded: false,
        loadingVoucherDetails: false
    }
}

function editVoucher(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingVoucherDetails: true,
        isVoucherUpdated: false,
    };
}
function editVoucherSuccess(state: MonetizationStateModel, action: MonetizationActions.editVoucherRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        loadingVoucherDetails: false,
        isVoucherUpdated: true,
    };
}
function editVoucherFailure(state: MonetizationStateModel, action: MonetizationActions.editVoucherRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingVoucherDetails: false,
        isVoucherUpdated: false,
    };
}

function deleteVoucher(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isVoucherDeleted: false,
        loadingVoucher: true,
    };
}
function deleteVoucherSuccess(state: MonetizationStateModel, action: MonetizationActions.deleteVoucherRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherDeleted: true,
        loadingVoucher: false,
    };
}
function deleteVoucherFailure(state: MonetizationStateModel, action: MonetizationActions.deleteVoucherRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherDeleted: false,
        loadingVoucher: false,
    };
}

function editVoucherStatus(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isVoucherStatusUpdated: false
    };
}
function editVoucherStatusSuccess(state: MonetizationStateModel, action: MonetizationActions.editVoucherStatusRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherStatusUpdated: true
    };
}
function editVoucherStatusFailure(state: MonetizationStateModel, action: MonetizationActions.editVoucherStatusRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherStatusUpdated: false
    };
}

function getVoucherUsage(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isVoucherUsageLoaded: true,
    };
}
function getVoucherUsageSuccess(state: MonetizationStateModel, action: MonetizationActions.getVoucherUsageRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        voucherUsageList: action.payload.result,
        isVoucherUsageLoaded: false
    };
}
function getVoucherUsageFailure(state: MonetizationStateModel, action: MonetizationActions.getVoucherUsageRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isVoucherUsageLoaded: false
    };
}

//Bundles
function getBundles(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingBundle: true,
    };
}
function getBundlesSuccess(state: MonetizationStateModel, action: MonetizationActions.getBundlesSuccessAction): MonetizationStateModel {
    return {
        ...state,
        bundlesList: action.payload.result.sort((a: BundleModel, b: BundleModel) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }),
        loadingBundle: false,
    };
}
function getBundlesFailure(state: MonetizationStateModel, action: MonetizationActions.getBundlesFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingBundle: false,
    };
}

function getBundleDetails(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingBundleDetails: true,
    };
}
function getBundleDetailsSuccess(state: MonetizationStateModel, action: MonetizationActions.getBundleDetailsSuccessAction): MonetizationStateModel {

    return {
        ...state,
        bundleInfo: action.payload.result.bundles,
        bundleDetails:
        {
            id: action.payload.result.bundles.id,
            name: action.payload.result.bundles.name,
            quantity: action.payload.result.bundles.quantity,
            memberDiscount: action.payload.result.bundles.memberDiscount,
            discount: action.payload.result.bundles.discount,
            memberlists: action.payload.result.memberLists
        },
        loadingBundleDetails: false,
    }
}
function getBundleDetailsFailure(state: MonetizationStateModel, action: MonetizationActions.getBundleDetailsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingBundleDetails: false,
    }
}

function addBundle(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isBundleAdded: false,
        loadingBundleDetails: true,
    };
}
function addBundleSuccess(state: MonetizationStateModel, action: MonetizationActions.addBundleRequestAction): MonetizationStateModel {
    return {
        ...state,
        isBundleAdded: true,
        loadingBundleDetails: false
    };
}
function addBundleFailure(state: MonetizationStateModel, action: MonetizationActions.addBundleRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isBundleAdded: false,
        loadingBundleDetails: false
    }
}

function editBundle(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingBundleDetails: true,
        isBundleUpdated: false,
    };
}
function editBundleSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleUpdated: true,
        loadingBundleDetails: false,
    };
}
function editBundleFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleUpdated: false,
        loadingBundleDetails: false,
    };
}

function deleteBundle(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isBundleDeleted: false,
        loadingBundle: true,
    };
}
function deleteBundleSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleDeleted: true,
        loadingBundle: false,
    };
}
function deleteBundleFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleDeleted: false,
        loadingBundle: false,
    };
}

function addBundleMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListAdded: false,
        loadingBundleMemberList: true,
    };
}
function addBundleMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListAdded: true,
        loadingBundleMemberList: false,
    };
}
function addBundleMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListAdded: false,
        loadingBundleMemberList: false,
    };
}

function deleteBundleMemberList(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListDeleted: false,
        loadingBundleMemberList: true,
    };
}
function deleteBundleMemberListSuccess(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListDeleted: true,
        loadingBundleMemberList: false,
    };
}
function deleteBundleMemberListFailure(state: MonetizationStateModel, action: any): MonetizationStateModel {
    return {
        ...state,
        isBundleMemberListDeleted: false,
        loadingBundleMemberList: false,
    };
}
//Subscription
function getSubscriptions(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingSubscription: true,
    };
}
function getSubscriptionsSuccess(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionsSuccessAction): MonetizationStateModel {
    return {
        ...state,
        subscriptionList: action.payload.result.sort((a: SubscriptionModel, b: SubscriptionModel) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        }),
        loadingSubscription: false,
    };
}
function getSubscriptionsFailure(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingSubscription: false,
    };
}

function getSubscriptionDetails(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionDetails: true,
    };
}
function getSubscriptionDetailsSuccess(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionDetailsSuccessAction): MonetizationStateModel {
    return {
        ...state,
        subscriptionInfo: action.payload.result,
        loadingSubscriptionDetails: false,
    }
}
function getSubscriptionDetailsFailure(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionDetailsFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionDetails: false,
    }
}

function addSubscription(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionAdded: false,
        loadingSubscriptionDetails: true,
    };
}
function addSubscriptionSuccess(state: MonetizationStateModel, action: MonetizationActions.addSubscriptionRequestAction): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionAdded: true,
        loadingSubscriptionDetails: false
    };
}
function addSubscriptionFailure(state: MonetizationStateModel, action: MonetizationActions.addSubscriptionRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionAdded: false,
        loadingSubscriptionDetails: false
    }
}

function editSubscription(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionDetails: true,
        isSubscriptionUpdated: false,
    };
}
function editSubscriptionSuccess(state: MonetizationStateModel, action: MonetizationActions.editSubscriptionRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionDetails: false,
        isSubscriptionUpdated: true,
    };
}
function editSubscriptionFailure(state: MonetizationStateModel, action: MonetizationActions.editSubscriptionRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionDetails: false,
        isSubscriptionUpdated: false,
    };
}

function deleteSubscription(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionDeleted: false,
        loadingSubscription: true,
    };
}
function deleteSubscriptionSuccess(state: MonetizationStateModel, action: MonetizationActions.deleteSubscriptionRequestSuccessAction): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionDeleted: true,
        loadingSubscription: false,
    };
}
function deleteSubscriptionFailure(state: MonetizationStateModel, action: MonetizationActions.deleteSubscriptionRequestFailureAction): MonetizationStateModel {
    return {
        ...state,
        isSubscriptionDeleted: false,
        loadingSubscription: false,
    };
}

function getSubscriptionSubscribers(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionSubscribers: true,
    };
}
function getSubscriptionSubscribersSuccess(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionSubscribersSuccessAction): MonetizationStateModel {
    return {
        ...state,
        subscriptionSubscribersList: action.payload.result,
        loadingSubscriptionSubscribers: false,
    }
}
function getSubscriptionSubscribersFailure(state: MonetizationStateModel, action: MonetizationActions.getSubscriptionSubscribersFailureAction): MonetizationStateModel {
    return {
        ...state,
        loadingSubscriptionSubscribers: false,
    }
}
function resetPackagePolicyData(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isPackageAdded: false,
        isPackageUpdated: false,
        isPackageDeleted: false,
        isPackageMemberListAdded: false,
        isPackageMemberListDeleted: false
    };
}
function resetDiscountPolicyData(state: MonetizationStateModel): MonetizationStateModel {
    return {
        ...state,
        isDiscountPolicyAdded: false,
        isDiscountPolicyUpdated: false,
        isDiscountPolicyDeleted: false
    };
}
export default monetizationReducer
