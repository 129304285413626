import { handleActions } from "redux-actions";
import { CategoryActions } from "./actions";
import { RootState } from "../reducers/state";
import { CategoryList, CategoryStateModel, CategoryModel, TagModel, ContentTypeModel, ModuleModel, ModulesList, AudienceProfiles, CollectionModel, CollectionVideosModel } from "src/models/categoryModel";
import { Actions } from "src/models/categoryModel";
import * as Constants from './constants'
import { PaginationModel } from 'src/models/paginationModel';

const initialState: RootState.CategoryModelState = {
    category: [],
    allCategory: [],
    singleCategory: {} as CategoryModel,
    singleTag: {} as TagModel,
    contentType: [],
    collectionId: '',
    singleContentType: {} as ContentTypeModel,
    paginationMeta: {} as PaginationModel,
    contentTypePaginationMeta: {} as PaginationModel,
    tagsPaginationMeta: {} as PaginationModel,
    singleModule: {} as ModuleModel,
    modulePaginationMeta: {} as PaginationModel,
    audienceProfilePaginationMeta: {} as PaginationModel,
    audienceProfile: [],
    singleAudienceProfile: {} as AudienceProfiles,
    tags: [],
    module: [],
    categoriesList: {} as CategoryList,
    modulesList: {} as ModulesList,
    message: '',
    isCategoryDeleted: false,
    isCategorySaved: false,
    isVideoTagSaved: false,
    isVideoTagUpdated: false,
    isCategoryUpdated: false,
    isSpotLightRowUpdated: false,
    isCateoryListLoading: false,
    isContentTypeLoading: false,
    isCategorySavedLoading: false,
    isTagListLoading: false,
    isVideoTagSavedLoading: false,
    isContentTypeSaved: false,
    isAudienceProfilesSaved: false,
    isAudienceProfilesDeleted: false,
    isContentTypeUpdated: false,
    isContentTypeDeleted: false,
    isContentTypeSavedLoading: false,
    isVideoTagDeleted: false,
    isModuleSaved: false,
    isModuleSavedLoading: false,
    isModuleDeleted: false,
    success: false,
    error: false,
    loading: false,
    collectionList: [],
    collection: {} as CollectionModel,
    collectionVideos: {} as CollectionVideosModel,
    isCollectionSaved: false,
    isCollectionVideosDeleted: false,
    isCollectionVideosSaved: false,
    isCollectionUpdated: false,
    isCollectionVideosLoading: false,
    isCollectionDeleted: false,
    isCollectionVideosUpdated: false
};


function CategoryReducer(state: CategoryStateModel = initialState, action: Actions): CategoryStateModel {
    switch (action.type) {
        case Constants.getCategoryRequestActionType: return getCategoryAction(state);
        case Constants.getCategorySuccessActionType: return getCategorySuccessAction(state, action);
        case Constants.getCategoryFailureActionType: return getCategoryFailureAction(state, action);

        case Constants.getAllCategoryRequestActionType: return getAllCategoryAction(state);
        case Constants.getAllCategorySuccessActionType: return getAllCategorySuccessAction(state, action);
        case Constants.getAllCategoryFailureActionType: return getAllCategoryFailureAction(state, action);

        case Constants.deleteCategoryRequestActionType: return deleteCategoryAction(state);
        case Constants.deleteCategorySuccessActionType: return deleteCategorySuccessAction(state, action);
        case Constants.deleteCategoryFailureActionType: return deleteCategoryFailureAction(state);

        case Constants.saveCategoryRequestActionType: return saveCategoryAction(state);
        case Constants.saveCategorySuccessActionType: return saveCategorySuccessAction(state);
        case Constants.saveCategoryFailureActionType: return saveCategoryFailureAction(state);

        case Constants.updateCategoryRequestActionType: return updateCategoryAction(state);
        case Constants.updateCategorySuccessActionType: return updateCategorySuccessAction(state);
        case Constants.updateCategoryFailureActionType: return updateCategoryFailureAction(state);

        case Constants.updateSpotLightRowFlagRequestActionType: return updateSpotLightRowFlagAction(state);
        case Constants.updateSpotLightRowFlagRequestSuccessActionType: return updateSpotLightRowFlagSuccessAction(state);
        case Constants.updateSpotLightRowFlagRequestFailureActionType: return updateSpotLightRowFlagFailureAction(state);

        case Constants.getSingleCategoryRequestActionType: return getSingleCategoryAction(state);
        case Constants.getSingleCategorySuccessActionType: return getSingleCategorySuccessAction(state, action);
        case Constants.getSingleCategoryFailureActionType: return getSingleCategoryFailureAction(state);

        case Constants.getVideoTagRequestActionType: return getVideoTagAction(state);
        case Constants.getVideoTagSuccessActionType: return getVideoTagSuccessAction(state, action);
        case Constants.getVideoTagFailureActionType: return getVideoTagFailureAction(state);

        case Constants.saveVideoTagRequestActionType: return saveVideoTagAction(state);
        case Constants.saveVideoTagRequestSuccessActionType: return saveVideoTagSuccessAction(state, action);
        case Constants.saveVideoTagRequestFailureActionType: return saveVideoTagFailureAction(state);

        case Constants.updateVideoTagRequestActionType: return updateVideoTagAction(state);
        case Constants.updateVideoTagRequestSuccessActionType: return updateVideoTagSuccessAction(state);
        case Constants.updateVideoTagRequestFailureActionType: return updateVideoTagFailureAction(state);

        case Constants.deleteVideoTagRequestActionType: return deleteVideoTagAction(state);
        case Constants.deleteVideoTagRequestSuccessActionType: return deleteVideoTagSuccessAction(state, action);
        case Constants.deleteVideoTagRequestFailureActionType: return deleteVideoTagFailureAction(state);

        case Constants.getSingleVideoTagRequestActionType: return getSingleVideoTagAction(state);
        case Constants.getSingleVideoTagSuccessActionType: return getSingleVideoTagSuccessAction(state, action);
        case Constants.getSingleVideoTagFailureActionType: return getSingleVideoTagFailureAction(state);

        case Constants.getContentTypeRequestActionType: return getContentTypeAction(state);
        case Constants.getContentTypeRequestSuccessActionType: return getContentTypeSuccessAction(state, action);
        case Constants.getContentTypeRequestFailureActionType: return getContentTypeFailureAction(state);

        case Constants.addContentTypeRequestActionType: return addContentTypeAction(state);
        case Constants.addContentTypeRequestSuccessActionType: return addContentTypeSuccessAction(state, action);
        case Constants.addContentTypeRequestFailureActionType: return addContentTypeFailureAction(state);

        case Constants.updateContentTypeRequestActionType: return updateContentTypeAction(state);
        case Constants.updateContentTypeRequestSuccessActionType: return updateContentTypeSuccessAction(state);
        case Constants.updateContentTypeRequestFailureActionType: return updateContentTypeFailureAction(state);

        case Constants.deleteContentTypeRequestActionType: return deleteContentTypeAction(state);
        case Constants.deleteContentTypeRequestSuccessActionType: return deleteContentTypeSuccessAction(state, action);
        case Constants.deleteContentTypeRequestFailureActionType: return deleteContentTypeFailureAction(state);

        case Constants.getSingleContentTypeRequestActionType: return getSingleContentTypeAction(state);
        case Constants.getSingleContentTypeRequestSuccessActionType: return getSingleContentTypeSuccessAction(state, action);
        case Constants.getSingleContentTypeRequestFailureActionType: return getSingleContentTypeFailureAction(state);

        case Constants.getModuleRequestActionType: return getModuleAction(state);
        case Constants.getModuleRequestSuccessActionType: return getModuleSuccessAction(state, action);
        case Constants.getModuleRequestFailureActionType: return getModuleFailureAction(state);

        case Constants.addModuleRequestActionType: return addModuleAction(state);
        case Constants.addModuleRequestSuccessActionType: return addModuleSuccessAction(state, action);
        case Constants.addModuleRequestFailureActionType: return addModuleFailureAction(state);

        case Constants.updateModuleRequestActionType: return updateModuleAction(state);
        case Constants.updateModuleRequestSuccessActionType: return updateModuleSuccessAction(state);
        case Constants.updateModuleRequestFailureActionType: return updateModuleFailureAction(state);

        case Constants.deleteModuleRequestActionType: return deleteModuleAction(state);
        case Constants.deleteModuleRequestSuccessActionType: return deleteModuleSuccessAction(state, action);
        case Constants.deleteModuleRequestFailureActionType: return deleteModuleFailureAction(state);

        case Constants.getSingleModuleRequestActionType: return getSingleModuleAction(state);
        case Constants.getSingleModuleRequestSuccessActionType: return getSingleModuleSuccessAction(state, action);
        case Constants.getSingleModuleRequestFailureActionType: return getSingleModuleFailureAction(state);

        case Constants.getCategoriesListRequestActionType: return getCategoriesListAction(state);
        case Constants.getCategoriesListRequestSuccessActionType: return getCategoriesListSuccessAction(state, action);
        case Constants.getCategoriesListRequestFailureActionType: return getCategoriesListFailureAction(state);

        case Constants.getModulesListRequestActionType: return getModulesListAction(state);
        case Constants.getModulesListRequestSuccessActionType: return getModulesListSuccessAction(state, action);
        case Constants.getModulesListRequestFailureActionType: return getModulesListFailureAction(state);

        case Constants.getAudienceProfilesRequestActionType: return getAudienceProfilesAction(state);
        case Constants.getAudienceProfilesRequestSuccessActionType: return getAudienceProfilesSuccessAction(state, action);
        case Constants.getAudienceProfilesRequestFailureActionType: return getAudienceProfilesFailureAction(state);

        case Constants.addAudienceProfilesRequestActionType: return addAudienceProfilesAction(state);
        case Constants.addAudienceProfilesRequestSuccessActionType: return addAudienceProfilesSuccessAction(state);
        case Constants.addAudienceProfilesRequestFailureActionType: return addAudienceProfilesFailureAction(state);

        case Constants.updateAudienceProfilesRequestActionType: return updateAudienceProfilesAction(state);
        case Constants.updateAudienceProfilesRequestSuccessActionType: return updateAudienceProfilesSuccessAction(state);
        case Constants.updateAudienceProfilesRequestFailureActionType: return updateAudienceProfilesFailureAction(state);

        case Constants.deleteAudienceProfilesRequestActionType: return deleteAudienceProfilesAction(state);
        case Constants.deleteAudienceProfilesRequestSuccessActionType: return deleteAudienceProfilesSuccessAction(state);
        case Constants.deleteAudienceProfilesRequestFailureActionType: return deleteAudienceProfilesFailureAction(state);

        case Constants.getSingleAudienceProfilesRequestActionType: return getSingleAudienceProfilesAction(state);
        case Constants.getSingleAudienceProfilesRequestSuccessActionType: return getSingleAudienceProfilesSuccessAction(state, action);
        case Constants.getSingleAudienceProfilesRequestFailureActionType: return getSingleAudienceProfilesFailureAction(state);

        case Constants.getCollectionsListRequestActionType: return getCollectionsListAction(state);
        case Constants.getCollectionsListRequestSuccessActionType: return getCollectionsListSuccessAction(state, action);
        case Constants.getCollectionsListRequestFailureActionType: return getCollectionsListFailureAction(state);

        case Constants.addCollectionRequestActionType: return addCollectionAction(state);
        case Constants.addCollectionRequestSuccessActionType: return addCollectionSuccessAction(state);
        case Constants.addCollectionRequestFailureActionType: return addCollectionFailureAction(state);

        case Constants.getCollectionRequestActionType: return getCollectionAction(state);
        case Constants.getCollectionRequestSuccessActionType: return getCollectionSuccessAction(state, action);
        case Constants.getCollectionRequestFailureActionType: return getCollectionFailureAction(state);

        case Constants.getCollectionVideosRequestActionType: return getCollectionVideosAction(state);
        case Constants.getCollectionVideosRequestSuccessActionType: return getCollectionVideosSuccessAction(state, action);
        case Constants.getCollectionVideosRequestFailureActionType: return getCollectionVideosFailureAction(state);

        case Constants.addCollectionVideosRequestActionType: return addCollectionVideosAction(state);
        case Constants.addCollectionVideosRequestSuccessActionType: return addCollectionVideosSuccessAction(state, action);
        case Constants.addCollectionVideosRequestFailureActionType: return addCollectionVideosFailureAction(state);

        case Constants.removeCollectionVideosRequestActionType: return removeCollectionVideosAction(state);
        case Constants.removeCollectionVideosRequestSuccessActionType: return removeCollectionVideosSuccessAction(state, action);
        case Constants.removeCollectionVideosRequestFailureActionType: return removeCollectionVideosFailureAction(state);

        case Constants.removeCollectionRequestActionType: return removeCollectionAction(state);
        case Constants.removeCollectionRequestSuccessActionType: return removeCollectionSuccessAction(state, action);
        case Constants.removeCollectionRequestFailureActionType: return removeCollectionFailureAction(state);

        case Constants.updateCollectionRequestActionType: return updateCollectionAction(state);
        case Constants.updateCollectionRequestSuccessActionType: return updateCollectionSuccessAction(state);
        case Constants.updateCollectionRequestFailureActionType: return updateCollectionFailureAction(state);

        case Constants.updateCollectionVideoOrderRequestActionType: return updateCollectionVideoOrderAction(state);
        case Constants.updateCollectionVideoOrderRequestSuccessActionType: return updateCollectionVideoOrderSuccessAction(state);
        case Constants.updateCollectionVideoOrderRequestFailureActionType: return updateCollectionVideoOrderFailureAction(state);

        case Constants.resetCategoryStateActionType: return resetCategoryStateAction(state);

        default: return state;
    }

    function getCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCateoryListLoading: true,
        };
    }
    function getCategorySuccessAction(state: CategoryStateModel, action: CategoryActions.getCategoryRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                category: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isCateoryListLoading: false,
            };
        }
        return state;
    }
    function getCategoryFailureAction(state: CategoryStateModel, action: CategoryActions.getCategoryRequestFailureAction): CategoryStateModel {
        return {
            ...state,
            isCateoryListLoading: false,
        }
    }
    function getAllCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAllCategorySuccessAction(state: CategoryStateModel, action: CategoryActions.getAllCategoryRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                allCategory: action.payload,
            };
        }
        return state;
    }
    function getAllCategoryFailureAction(state: CategoryStateModel, action: CategoryActions.getAllCategoryRequestFailureAction): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function deleteCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteCategorySuccessAction(state: CategoryStateModel, action: CategoryActions.deleteCategoryRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isCategoryDeleted: true,
                message: action.payload,
            };
        }
        return state;
    }
    function deleteCategoryFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategoryDeleted: false,
            error: true
        }
    }
    function saveCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategorySavedLoading: true,
        };
    }
    function saveCategorySuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategorySaved: true,
            message: 'Success',
            isCategorySavedLoading: false
        };
    }
    function saveCategoryFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategorySaved: false,
            isCategorySavedLoading: false,
            error: true
        }
    }
    function updateCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategorySavedLoading: true,
        };
    }
    function updateCategorySuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategoryUpdated: true,
            message: 'Success',
            isCategorySavedLoading: false,
        };
    }
    function updateCategoryFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategoryUpdated: false,
            isCategorySavedLoading: false,
            error: true
        }
    }
    function updateSpotLightRowFlagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateSpotLightRowFlagSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isSpotLightRowUpdated: true,
            message: 'Success'
        };
    }
    function updateSpotLightRowFlagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isSpotLightRowUpdated: false,
            error: true
        }
    }
    function getSingleCategoryAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleCategorySuccessAction(state: CategoryStateModel, action: CategoryActions.getSingleCategoryRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                singleCategory: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleCategoryFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function resetCategoryStateAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCategoryDeleted: false,
            isCategorySaved: false,
            isCategoryUpdated: false,
            isVideoTagSaved: false,
            isContentTypeSaved: false,
            isContentTypeUpdated: false,
            isContentTypeDeleted: false,
            isVideoTagUpdated: false,
            isVideoTagDeleted: false,
            isSpotLightRowUpdated: false,
            isModuleSaved: false,
            isModuleDeleted: false,
            isAudienceProfilesSaved: false,
            isAudienceProfilesDeleted: false,
            isCollectionDeleted: false,
            isCollectionSaved: false,
            isCollectionVideosDeleted: false,
            isCollectionVideosSaved: false,
            message: '',
            error: false
        };
    }
    function getVideoTagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isTagListLoading: true,
        };
    }
    function getVideoTagSuccessAction(state: CategoryStateModel, action: CategoryActions.getVideoTagRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                tags: action.payload.data,
                tagsPaginationMeta: action.payload.paginationMeta,
                isTagListLoading: false,
            };
        }
        return state;
    }
    function getVideoTagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isTagListLoading: false,
        }
    }
    function saveVideoTagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagSavedLoading: true,
        };
    }
    function saveVideoTagSuccessAction(state: CategoryStateModel, action: CategoryActions.saveVideoTagRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isVideoTagSaved: true,
                message: action.payload,
                isVideoTagSavedLoading: false
            };
        }
        return state
    }
    function saveVideoTagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagSaved: false,
            isVideoTagSavedLoading: false,
            error: true
        }
    }
    function updateVideoTagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagSavedLoading: true,
        };
    }
    function updateVideoTagSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagUpdated: true,
            message: 'Success',
            isVideoTagSavedLoading: false,
        };
    }
    function updateVideoTagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagUpdated: false,
            isVideoTagSavedLoading: false,
            error: true
        }
    }
    function deleteVideoTagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteVideoTagSuccessAction(state: CategoryStateModel, action: CategoryActions.deleteVideoTagRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isVideoTagDeleted: true,
                message: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function deleteVideoTagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isVideoTagDeleted: false,
            loading: false,
            error: true
        }
    }
    function getSingleVideoTagAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleVideoTagSuccessAction(state: CategoryStateModel, action: CategoryActions.getSingleVideoTagRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                singleTag: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleVideoTagFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getContentTypeAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeLoading: true,
        };
    }
    function getContentTypeSuccessAction(state: CategoryStateModel, action: CategoryActions.getContentTypeRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                contentType: action.payload.data,
                contentTypePaginationMeta: action.payload.paginationMeta,
                isContentTypeLoading: false,
            };
        }
        return state;
    }
    function getContentTypeFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeLoading: false,
        }
    }
    function addContentTypeAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeSavedLoading: true,
        };
    }
    function addContentTypeSuccessAction(state: CategoryStateModel, action: CategoryActions.addContentTypeRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isContentTypeSaved: true,
                message: action.payload,
                isContentTypeSavedLoading: false
            };
        }
        return state
    }
    function addContentTypeFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeSaved: false,
            isContentTypeSavedLoading: false,
            error: true
        }
    }
    function updateContentTypeAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeSavedLoading: true,
        };
    }
    function updateContentTypeSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeUpdated: true,
            message: 'Success',
            isContentTypeSavedLoading: false
        };
    }
    function updateContentTypeFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeUpdated: false,
            isContentTypeSavedLoading: false,
            error: true
        }
    }
    function deleteContentTypeAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteContentTypeSuccessAction(state: CategoryStateModel, action: CategoryActions.deleteContentTypeRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isContentTypeDeleted: true,
                message: action.payload,
                loading: false
            };
        }
        return state;
    }
    function deleteContentTypeFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isContentTypeDeleted: false,
            loading: false,
            error: true
        }
    }
    function getSingleContentTypeAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleContentTypeSuccessAction(state: CategoryStateModel, action: CategoryActions.getSingleContentTypeRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                singleContentType: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleContentTypeFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getModuleAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getModuleSuccessAction(state: CategoryStateModel, action: CategoryActions.getModuleRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                module: action.payload.data,
                modulePaginationMeta: action.payload.paginationMeta,
                loading: false,
            };
        }
        return state;
    }
    function getModuleFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function addModuleAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleSavedLoading: true,
        };
    }
    function addModuleSuccessAction(state: CategoryStateModel, action: CategoryActions.addModuleRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isModuleSaved: true,
                message: action.payload,
                isModuleSavedLoading: false
            };
        }
        return state;
    }
    function addModuleFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleSaved: false,
            isModuleSavedLoading: false,
            error: true
        }
    }
    function updateModuleAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleSavedLoading: true,
        };
    }
    function updateModuleSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleSaved: true,
            message: 'Success',
            isModuleSavedLoading: false
        };
    }
    function updateModuleFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleSaved: false,
            isModuleSavedLoading: false,
            error: true
        }
    }
    function deleteModuleAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteModuleSuccessAction(state: CategoryStateModel, action: CategoryActions.deleteModuleRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isModuleDeleted: true,
                message: action.payload,
                loading: false
            };
        }
        return state;
    }
    function deleteModuleFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isModuleDeleted: false,
            loading: false,
            error: true
        }
    }
    function getSingleModuleAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleModuleSuccessAction(state: CategoryStateModel, action: CategoryActions.getSingleModuleRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                singleModule: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleModuleFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getCategoriesListAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCategoriesListSuccessAction(state: CategoryStateModel, action: CategoryActions.getCategoriesListRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                categoriesList: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getCategoriesListFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getModulesListAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getModulesListSuccessAction(state: CategoryStateModel, action: CategoryActions.getModulesListRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                modulesList: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getModulesListFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getAudienceProfilesAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAudienceProfilesSuccessAction(state: CategoryStateModel, action: CategoryActions.getAudienceProfilesRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                audienceProfile: action.payload.data,
                audienceProfilePaginationMeta: action.payload.paginationMeta,
                loading: false,
            };
        }
        return state;
    }
    function getAudienceProfilesFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function addAudienceProfilesAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addAudienceProfilesSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function addAudienceProfilesFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesSaved: false,
            loading: false,
            error: true
        }
    }
    function updateAudienceProfilesAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateAudienceProfilesSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesSaved: true,
            message: 'Success',
            loading: false,
        };
    }
    function updateAudienceProfilesFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesSaved: false,
            loading: false,
            error: true
        }
    }
    function deleteAudienceProfilesAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleAudienceProfilesAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleAudienceProfilesSuccessAction(state: CategoryStateModel, action: CategoryActions.getSingleAudienceProfilesRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                singleAudienceProfile: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleAudienceProfilesFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getCollectionsListAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCollectionsListSuccessAction(state: CategoryStateModel, action: CategoryActions.getCollectionsListRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                collectionList: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getCollectionsListFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getCollectionAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCollectionSuccessAction(state: CategoryStateModel, action: CategoryActions.getCollectionRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                collection: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getCollectionFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getCollectionVideosAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosLoading: true,
        };
    }
    function getCollectionVideosSuccessAction(state: CategoryStateModel, action: CategoryActions.getCollectionVideosRequestSuccessAction): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                collectionVideos: action.payload,
                isCollectionVideosLoading: false,
            };
        }
        return state;
    }
    function getCollectionVideosFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosLoading: false,
        }
    }
    function addCollectionAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addCollectionSuccessAction(state: CategoryStateModel): CategoryStateModel {
        if (action.payload) {
            return {
                ...state,
                isCollectionSaved: true,
                collectionId: action.payload,
                message: 'Success',
                loading: false,
            };
        }
        return state;
    }
    function addCollectionFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionSaved: false,
            loading: false,
            error: true
        }
    }
    function addCollectionVideosAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addCollectionVideosSuccessAction(state: CategoryStateModel, action: CategoryActions.addCollectionVideosRequestSuccessAction): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosSaved: true,
            message: 'Success',
            loading: false,
        };
    }

    function addCollectionVideosFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosSaved: false,
            loading: false,
            error: true
        }
    }
    function removeCollectionVideosAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function removeCollectionVideosSuccessAction(state: CategoryStateModel, action: CategoryActions.removeCollectionVideosRequestSuccessAction): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosDeleted: true,
            message: 'Success',
            loading: false,
            collectionVideos: {
                ...state.collectionVideos,
                videos: state.collectionVideos.videos.filter(x => x.id != action.payload)
            }
        };
    }
    function removeCollectionVideosFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosDeleted: false,
            loading: false,
            error: true,
        }
    }
    function removeCollectionAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function removeCollectionSuccessAction(state: CategoryStateModel, action: CategoryActions.removeCollectionRequestSuccessAction): CategoryStateModel {
        return {
            ...state,
            isCollectionDeleted: true,
            message: 'Success',
            loading: false,
            collectionList: state.collectionList.filter(x => x.id != action.payload)
        };
    }
    function removeCollectionFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionDeleted: false,
            loading: false,
            error: true
        }
    }

    function updateCollectionAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateCollectionSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionUpdated: true,
            message: 'Success',
            loading: false,
        };
    }
    function updateCollectionFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionUpdated: false,
            loading: false,
            error: true
        }
    }
    function deleteAudienceProfilesSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesDeleted: true,
            message: 'Success',
            loading: false,
        };
    }
    function deleteAudienceProfilesFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isAudienceProfilesDeleted: false,
            loading: false,
            error: true
        }
    }
    function updateCollectionVideoOrderAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosUpdated: false,
        };
    }
    function updateCollectionVideoOrderSuccessAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosUpdated: true,
        };
    }
    function updateCollectionVideoOrderFailureAction(state: CategoryStateModel): CategoryStateModel {
        return {
            ...state,
            isCollectionVideosUpdated: false,
        }
    }
}

export default CategoryReducer
