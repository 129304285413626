import React, { useEffect } from 'react';
import './styles.scss';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
const Screen5: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const videoActions = useVideosActions(dispatch);
  const handleRestartMeeting = () => {
    id && videoActions.updateVideoStatusRequest({ videoId: id, status: 0 });
  };

  const { isVideoStatusUpdated } = useSelector((state: RootState) => {
    return {
      isVideoStatusUpdated: state.videos.isVideoStatusUpdated
    };
  });

  useEffect(() => {
    if (isVideoStatusUpdated) {
      id && videoActions.getVideoStatusRequest(id);
    }
  }, [isVideoStatusUpdated]);
  return (
    <div>
      <div className="live-event-main">
        <div id="rightMenuContentMainDiv" className="">
          <div id="dvStep1" className="ibox-content ">
            <div
              id="subData1"
              className="flex justify-center items-center flex-col text-sm font-normal">
              <h1 className="font-semibold text-center text-3xl text-black">
                The Live Meeting has Ended
              </h1>
              <a className="btn start-live-stream pull-right mt-5" onClick={handleRestartMeeting}>
                Restart meeting <span id="count" className="d-none"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Screen5;
