import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { VoucherModel, PatchJsonModel, PatchJsonData } from 'src/models/monetizationModel';
import { useUserActions } from 'src/store/users/actions';
import Loader from 'src/components/loader';
import { getFormattedDate } from 'src/components/utilities/utils';

interface IProps {
  modalFlag: string;
  currentVoucherId: string;
}

const VoucherModal: React.FC<IProps> = ({ modalFlag, currentVoucherId }) => {
  const dispatch = useDispatch();
  const monetizationAction = useMonetizationActions(dispatch);
  const userActions = useUserActions(dispatch);

  //Setting values to state variables
  const [heading, setHeading] = useState('Add');
  const [operation, setOperation] = useState('Add');
  const [result, setresult] = useState('');
  const btnRef = useRef<HTMLButtonElement>(null);
  //Getting values from RootState
  const {
    memberLists,
    voucherInfo,
    originalVoucherInfo,
    isVoucherAdded,
    loadingVoucherDetails,
    isVoucherUsageLoaded,
    voucherUsageList,
    isVoucherUpdated
  } = useSelector((state: RootState) => {
    return {
      memberLists: state.users.memberList,
      voucherInfo: state.monetization.voucherInfo,
      originalVoucherInfo: state.monetization.voucherInfo,
      isVoucherAdded: state.monetization.isVoucherAdded,
      loadingVoucherDetails: state.monetization.loadingVoucherDetails,
      isVoucherUsageLoaded: state.monetization.isVoucherUsageLoaded,
      voucherUsageList: state.monetization.voucherUsageList,
      isVoucherUpdated: state.monetization.isVoucherUpdated,
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<VoucherModel>();

  //on click of Add/Update Button
  const onSubmit = (data: VoucherModel) => {
    setresult('');
    if (modalFlag === 'add') {
      monetizationAction.addVoucherRequest(data);
    }
    else if (modalFlag === 'edit') {
      const patchJsonModel: PatchJsonModel = {
        id: data.id,
        patchJsonData: convertModelToPatch(data)
      }
      if (patchJsonModel.patchJsonData.length > 0)
        monetizationAction.editVoucherRequest(patchJsonModel);
      else
        setresult("No changes made yet in the Voucher Details");
    }
  };

  const convertModelToPatch = (data: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = originalVoucherInfo;
      if (value[field] == data[field])
        continue;

      const patchObjdal: PatchJsonData = {
        from: value[field],
        value: data[field],
        path: '/' + field,
        op: 'replace'
      };
      patchData.push(patchObjdal);
    }
    return patchData;
  };

  //On Cancel click
  const handleCancelClick = () => {
    /*setresult('');
    monetizationAction.resetVoucherPolicyDataStateRequest();
    reset({
      name: '',
      code: '',
      amount: 0,
      isActive: false,
      memberListId: 0
    });*/
  };

  //Get the Details about the voucher id and voucher usage
  useEffect(() => {
    setresult('');
    if (currentVoucherId.length > 0) {
      monetizationAction.getVoucherDetails(currentVoucherId);
      monetizationAction.getVoucherUsage(currentVoucherId);
    }
  }, [currentVoucherId]);

  useEffect(() => {
    if (modalFlag === 'edit') {
      reset(voucherInfo);
      setHeading('Edit');
      setOperation('Update');
      setresult('');
    } else {
      setHeading('Add');
      setOperation('Add');
      setresult('');
      reset({
        name: '',
        amount: 0,
        isActive: false,
        code: '',
        memberListId: 0
      });
    }
  }, [voucherInfo, modalFlag]);

  useEffect(() => {
    if (!memberLists || memberLists.length == 0) {
      userActions.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
    }
  }, []);

  useEffect(() => {
    if (isVoucherAdded === true) {
      reset({
        name: '',
        code: '',
        amount: 0,
        isActive: false,
        memberListId: 0
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isVoucherAdded]);

  useEffect(() => {
    if (isVoucherAdded === true)
      setresult("Voucher Added");
    else if (isVoucherUpdated === true)
      setresult("Voucher Updated");
  }, [isVoucherUpdated, isVoucherAdded]);

  return (
    <div className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editVoucherModel"
      aria-labelledby="editVoucherModelLabel"
      aria-hidden="true"
      >
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full" id="editVoucherModelLabel">
              {`${heading} Voucher Information`}
            </h5>
            <button
              onClick={handleCancelClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          {loadingVoucherDetails ? (
            <Loader />
          ) : (
            <>
              {' '}
              <div className="modal-body relative py-4 px-10">
                <form>
                  <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Name </label>
                      <input
                        type="text"
                        className="input-style"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 150
                        })}
                      />
                      {errors.name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.name?.type === 'maxLength' && <p className="text-red-700">Max. length reached</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Code </label>
                      <input
                        type="text"
                        className="input-style"
                        id="code"
                        {...register('code', {
                          required: true,
                          maxLength: 50
                        })}
                      />
                      {errors.code?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.code?.type === 'maxLength' && <p className="text-red-700">Max. length reached</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Amount </label>
                      <input
                        type="number"
                        className="input-style"
                        id="amount"
                        {...register('amount', {
                          required: true,
                          maxLength: 80,
                          pattern: /^[0-9.]/
                        })}
                      />
                      {errors.amount?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.amount?.type === 'maxLength' && <p className="text-red-700">Max. length reached</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Member List</label>
                      <select {...register("memberListId")}>
                        {memberLists && memberLists.map((values: any, index: any) => (
                          <option value={values.id} key={values.name}> {values.name} </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 form-switch gap-1 mb-4">
                    <div className="{`form-group`}">
                      <label className="block mb-1"> Active</label>
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          role="switch"
                          id="isActive"
                          {...register('isActive')}
                        />                        
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                    <button
                      type="button"
                      className="px-2 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)}>
                      {operation}
                    </button>
                    <button
                      onClick={handleCancelClick}
                      type="button"
                      ref={btnRef}
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                  <div className="flex flex-shrink-0 flex-wrap items-center justify-center p-0.5 rounded-b-md">
                    <div className="font-medium text-red-700 items-center">{result}</div>
                  </div>
                </form>
              </div>
            </>
          )}
          {modalFlag == 'edit' &&
            <>
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <div className="p-1.5 w-full inline-block align-middle">
                    <div className="overflow-hidden border-2 border-gray-300 shadow-slate-300 justify-between">
                      <table className="table-auto w-full" >
                        <thead className="text-xs font-semibold uppercase bg-gra-150">
                          <tr className="bg-slate-200">
                            <th className="p-1 ">
                              <div className="font-semibold text-left">Ref No.</div>
                            </th>
                            <th className="p-1">
                              <div className="font-semibold text-left">Name</div>
                            </th>
                            <th className="p-1">
                              <div className="font-semibold text-left">Email</div>
                            </th>
                            <th className="p-1 nowrap">
                              <div className="font-semibold text-left">Amount Used</div>
                            </th>
                            <th className="p-1 nowrap">
                              <div className="font-semibold text-left">Used Date</div>
                            </th>
                          </tr>
                        </thead>
                        {isVoucherUsageLoaded ? (
                          <Loader />
                        ) : (
                          <>
                            {' '}
                            {voucherUsageList && voucherUsageList.length ? (
                              <tbody className="text-sm divide-y divide-gray-100">
                                {voucherUsageList.map((usage, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="font-medium ">{`${usage.refNo}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="font-medium ">{`${usage.name}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="font-medium ">{`${usage.email}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="font-medium ">{`${usage.amount}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="font-medium ">{`${getFormattedDate(usage.usedDate)}`}</div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>}
        </div>
      </div>
    </div>
  );
};
export default VoucherModal;