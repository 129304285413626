import { SettingActions } from "./actions";
import { RootState } from "../reducers/state";
import { EcommerceSettingModel, SettingStateModel, ApiV2Model, SamlModel, EcommerceModel, NaylorIntegrationModel, ImisIntegrationModel, MemberEvolutionIntegrationModel, WicketIntegrationModel, Dynamics365IntegrationModel, MicrosoftAzureIntegrationModel, PersonifyIntegrationModel, SpargoIntegrationModel, MembesIntegrationModel, GoogleIntegrationModel, WebCourseWorksIntegrationModel, ZoomIntegrationModel, PaypalPayFlowModel, EzidebitModel, StripeModel, PaypalModel, BPointModel, LivePayModel, CePointModel, EducationPointsModel, EducationPointsDetailModel, NoviAMSIntegrationModel, OAuth2IntegrationModel, MemberClicksIntegrationModel, QuickBooksModel, AttributeMappingModel, AttributeMappingGetRequestModel } from "src/models/settingModal";
import { Actions } from "src/models/settingModal";
import * as Constants from './constants'
import { PaginationModel } from "src/models/paginationModel";
const initialState: RootState.SettingModelState = {
    apiV2: [],
    saml: {} as SamlModel,
    paginationMeta: {} as PaginationModel,
    ecommerce: {} as EcommerceModel,
    quickBooks: {} as QuickBooksModel,
    integration: [],
    paymentProvider: [],
    ecommerceSetting: {} as EcommerceSettingModel,
    imisIntegration: {} as ImisIntegrationModel,
    memberEvolutionIntegration: {} as MemberEvolutionIntegrationModel,
    wicketIntegration: {} as WicketIntegrationModel,
    dynamics365Integration: {} as Dynamics365IntegrationModel,
    microsoftAzureIntegration: {} as MicrosoftAzureIntegrationModel,
    noviamsIntegration: {} as NoviAMSIntegrationModel,
    oauth2Integration: {} as OAuth2IntegrationModel,
    memberclicksIntegration: {} as MemberClicksIntegrationModel,
    personifyIntegration: {} as PersonifyIntegrationModel,
    naylorIntegration: {} as NaylorIntegrationModel,
    spargoIntegration: {} as SpargoIntegrationModel,
    attributeMapping: [],
    attribureMappingGetRequestModel: {} as AttributeMappingGetRequestModel,

    singleApiV2: {} as ApiV2Model,
    membesIntegration: {} as MembesIntegrationModel,
    googleIntegration: {} as GoogleIntegrationModel,
    webCourseIntegration: {} as WebCourseWorksIntegrationModel,
    paypalPayFlow: {} as PaypalPayFlowModel,
    ezidebit: {} as EzidebitModel,
    stripe: {} as StripeModel,
    paypal: {} as PaypalModel,
    bPoint: {} as BPointModel,
    livePay: {} as LivePayModel,
    currency: [],
    selectedCurrency: {} as string,
    cePoints: {} as CePointModel,
    templates: [],
    educationPoints: [],
    singleEducationPoints: {} as EducationPointsModel,
    getCeEducationPoints: {} as EducationPointsDetailModel,
    zoomIntegration: {} as ZoomIntegrationModel,
    liveStreamUrl: [],

    videoLogs: [],
    channelLogs: [],
    analyticsLogs: [],

    message: '',
    isApiV2Deleted: false,
    isGetApiV2Loading: false,
    isApiV2Saved: false,
    isSamlSaved: false,
    isLiveStreamLoading: false,
    isCloudRecordingUpdated: false,
    isAutoPublishOnDemandUpdated: false,
    isEcommerceSaved: false,
    isEcommerceSettingUpdated: false,
    isRunUpdated: false,
    isIntegrationProviderUpdated: false,
    isPersonifyIntegrationSaved: false,
    isApiV2Updated: false,
    isZoomIntegrationLoading: false,
    isPaymentProviderUpdated: false,
    isEducationPointsSaved: false,
    isEducationPointsUpdate: false,
    isEducationPointsDeleted: false,
    isCePointSaved: false,
    isEducationPointsLoading: false,
    isIntegrationLoading: false,
    isIntegrationUpdated: false,
    isPaymentProviderLoading: false,
    isPaymentUpdated: false,
    isTemplateAdded: false,
    isTemplateDeleted: false,
    isTemplateUpdated: false,
    isSamlLoading: false,
    isCurrencyUpdated: false,
    error: false,
    loading: false,
    success: false,
    singleAttributeMapping: {} as AttributeMappingModel,
    AttributeMappingColumnList: [],
    AttributeMappingTypeList: [],
    AttributeMappingGetRequestModel: {} as AttributeMappingGetRequestModel,
    isAttributeMappingSaved: false,
    isAttributeMappingUpdate: false,
    isAttributeMappingDeleted: false,
    isAttributeMappingLoading: false,
    isAttributeMappingColumnListLoading: false,
    isAttributeMappingTypeListLoading: false,
    QBAuthURL: ''
};


function settingReducer(state: SettingStateModel = initialState, action: Actions): SettingStateModel {
    switch (action.type) {
        case Constants.getApiV2RequestActionType: return getApiV2Action(state);
        case Constants.getApiV2SuccessActionType: return getApiV2SuccessAction(state, action);
        case Constants.getApiV2FailureActionType: return getApiV2FailureAction(state);
        case Constants.addApiV2RequestActionType: return addApiV2Action(state);
        case Constants.addApiV2SuccessActionType: return addApiV2SuccessAction(state);
        case Constants.addApiV2FailureActionType: return addApiV2FailureAction(state);
        case Constants.deleteApiV2RequestActionType: return deleteApiV2Action(state);
        case Constants.deleteApiV2SuccessActionType: return deleteApiV2SuccessAction(state);
        case Constants.deleteApiV2FailureActionType: return deleteApiV2FailureAction(state);
        case Constants.resetSettingStateRequestActionType: return resetSettingAction(state);
        case Constants.getLiveStreamUrlRequestActionType: return getLiveStreamUrlAction(state);
        case Constants.getLiveStreamUrlRequestSuccessActionType: return getLiveStreamUrlSuccessAction(state, action);
        case Constants.getLiveStreamUrlRequestFailureActionType: return getLiveStreamUrlFailureAction(state);
        case Constants.getSamlRequestActionType: return getSamlAction(state);
        case Constants.getSamlRequestSuccessActionType: return getSamlSuccessAction(state, action);
        case Constants.getSamlRequestFailureActionType: return getSamlFailureAction(state);
        case Constants.saveSamlRequestActionType: return saveSamlAction(state);
        case Constants.saveSamlRequestSuccessActionType: return saveSamlSuccessAction(state);
        case Constants.saveSamlRequestFailureActionType: return saveSamlFailureAction(state);
        case Constants.updateCloudRecordingRequestActionType: return updateCloudRecordingAction(state);
        case Constants.updateCloudRecordingRequestSuccessActionType: return updateCloudRecordingSuccessAction(state);
        case Constants.updateCloudRecordingRequestFailureActionType: return updateCloudRecordingFailureAction(state);
        case Constants.updateAutoPublishOnDemandRequestActionType: return updateAutoPublishOnDemandAction(state);
        case Constants.updateAutoPublishOnDemandRequestSuccessActionType: return updateAutoPublishOnDemandSuccessAction(state);
        case Constants.updateAutoPublishOnDemandRequestFailureActionType: return updateAutoPublishOnDemandFailureAction(state);
        case Constants.getEcommerceRequestActionType: return getEcommerceAction(state);
        case Constants.getEcommerceRequestSuccessActionType: return getEcommerceSuccessAction(state, action);
        case Constants.getEcommerceRequestFailureActionType: return getEcommerceFailureAction(state);
        case Constants.saveEcommerceRequestActionType: return saveEcommerceAction(state);
        case Constants.saveEcommerceRequestSuccessActionType: return saveEcommerceSuccessAction(state);
        case Constants.saveEcommerceRequestFailureActionType: return saveEcommerceFailureAction(state);
        case Constants.getVideoLogsRequestActionType: return getVideoLogsAction(state);
        case Constants.getVideoLogsRequestSuccessActionType: return getVideoLogsSuccessAction(state, action);
        case Constants.getVideoLogsRequestFailureActionType: return getVideoLogsFailureAction(state);
        case Constants.getChannelLogsRequestActionType: return getChannelLogsAction(state);
        case Constants.getChannelLogsRequestSuccessActionType: return getChannelLogsSuccessAction(state, action);
        case Constants.getChannelLogsRequestFailureActionType: return getChannelLogsFailureAction(state);
        case Constants.getAnalyticsLogsRequestActionType: return getAnalyticsLogsAction(state);
        case Constants.getAnalyticsLogsRequestSuccessActionType: return getAnalyticsLogsSuccessAction(state, action);
        case Constants.getAnalyticsLogsRequestFailureActionType: return getAnalyticsLogsFailureAction(state);
        case Constants.runUpdateRequestActionType: return runUpdateAction(state);
        case Constants.runUpdateRequestSuccessActionType: return runUpdateSuccessAction(state);
        case Constants.runUpdateRequestFailureActionType: return runUpdateFailureAction(state);
        case Constants.enableIntegrationProviderRequestActionType: return enableIntegrationProviderAction(state);
        case Constants.enableIntegrationProviderRequestSuccessActionType: return enableIntegrationProviderSuccessAction(state);
        case Constants.enableIntegrationProviderRequestFailureActionType: return enableIntegrationProviderFailureAction(state);
        case Constants.getImisIntegrationRequestActionType: return getImisIntegrationAction(state);
        case Constants.getImisIntegrationRequestSuccessActionType: return getImisIntegrationSuccessAction(state, action);
        case Constants.getImisIntegrationRequestFailureActionType: return getImisIntegrationFailureAction(state);
        case Constants.addImisIntegrationRequestActionType: return addImisIntegrationAction(state);
        case Constants.addImisIntegrationRequestSuccessActionType: return addImisIntegrationSuccessAction(state);
        case Constants.addImisIntegrationRequestFailureActionType: return addImisIntegrationFailureAction(state);
        case Constants.getMemberEvolutionIntegrationRequestActionType: return getMemberEvolutionIntegrationAction(state);
        case Constants.getMemberEvolutionIntegrationRequestSuccessActionType: return getMemberEvolutionIntegrationSuccessAction(state, action);
        case Constants.getMemberEvolutionIntegrationRequestFailureActionType: return getMemberEvolutionIntegrationFailureAction(state);
        case Constants.addMemberEvolutionIntegrationRequestActionType: return addMemberEvolutionIntegrationAction(state);
        case Constants.addMemberEvolutionIntegrationRequestSuccessActionType: return addMemberEvolutionIntegrationSuccessAction(state);
        case Constants.addMemberEvolutionIntegrationRequestFailureActionType: return addMemberEvolutionIntegrationFailureAction(state);

        case Constants.getWicketIntegrationRequestActionType: return getWicketIntegrationAction(state);
        case Constants.getWicketIntegrationRequestSuccessActionType: return getWicketIntegrationSuccessAction(state, action);
        case Constants.getWicketIntegrationRequestFailureActionType: return getWicketIntegrationFailureAction(state);
        case Constants.addWicketIntegrationRequestActionType: return addWicketIntegrationAction(state);
        case Constants.addWicketIntegrationRequestSuccessActionType: return addWicketIntegrationSuccessAction(state);
        case Constants.addWicketIntegrationRequestFailureActionType: return addWicketIntegrationFailureAction(state);

        case Constants.getDynamics365IntegrationRequestActionType: return getDynamics365IntegrationAction(state);
        case Constants.getDynamics365IntegrationRequestSuccessActionType: return getDynamics365IntegrationSuccessAction(state, action);
        case Constants.getDynamics365IntegrationRequestFailureActionType: return getDynamics365IntegrationFailureAction(state);
        case Constants.addDynamics365IntegrationRequestActionType: return addDynamics365IntegrationAction(state);
        case Constants.addDynamics365IntegrationRequestSuccessActionType: return addDynamics365IntegrationSuccessAction(state);
        case Constants.addDynamics365IntegrationRequestFailureActionType: return addDynamics365IntegrationFailureAction(state);


        case Constants.getMicrosoftAzureIntegrationRequestActionType: return getMicrosoftAzureIntegrationAction(state);
        case Constants.getMicrosoftAzureIntegrationRequestSuccessActionType: return getMicrosoftAzureIntegrationSuccessAction(state, action);
        case Constants.getMicrosoftAzureIntegrationRequestFailureActionType: return getMicrosoftAzureIntegrationFailureAction(state);
        case Constants.addMicrosoftAzureIntegrationRequestActionType: return addMicrosoftAzureIntegrationAction(state);
        case Constants.addMicrosoftAzureIntegrationRequestSuccessActionType: return addMicrosoftAzureIntegrationSuccessAction(state);
        case Constants.addMicrosoftAzureIntegrationRequestFailureActionType: return addMicrosoftAzureIntegrationFailureAction(state);

        case Constants.getNoviAMSIntegrationRequestActionType: return getNoviAMSIntegrationAction(state);
        case Constants.getNoviAMSIntegrationRequestSuccessActionType: return getNoviAMSIntegrationSuccessAction(state, action);
        case Constants.getNoviAMSIntegrationRequestFailureActionType: return getNoviAMSIntegrationFailureAction(state);
        case Constants.addNoviAMSIntegrationRequestActionType: return addNoviAMSIntegrationAction(state);
        case Constants.addNoviAMSIntegrationRequestSuccessActionType: return addNoviAMSIntegrationSuccessAction(state);
        case Constants.addNoviAMSIntegrationRequestFailureActionType: return addNoviAMSIntegrationFailureAction(state);

        case Constants.getOAuth2IntegrationRequestActionType: return getOAuth2IntegrationAction(state);
        case Constants.getOAuth2IntegrationRequestSuccessActionType: return getOAuth2IntegrationSuccessAction(state, action);
        case Constants.getOAuth2IntegrationRequestFailureActionType: return getOAuth2IntegrationFailureAction(state);
        case Constants.addOAuth2IntegrationRequestActionType: return addOAuth2IntegrationAction(state);
        case Constants.addOAuth2IntegrationRequestSuccessActionType: return addOAuth2IntegrationSuccessAction(state);
        case Constants.addOAuth2IntegrationRequestFailureActionType: return addOAuth2IntegrationFailureAction(state);

        case Constants.getMemberClicksIntegrationRequestActionType: return getMemberClicksIntegrationAction(state);
        case Constants.getMemberClicksIntegrationRequestSuccessActionType: return getMemberClicksIntegrationSuccessAction(state, action);
        case Constants.getMemberClicksIntegrationRequestFailureActionType: return getMemberClicksIntegrationFailureAction(state);
        case Constants.addMemberClicksIntegrationRequestActionType: return addMemberClicksIntegrationAction(state);
        case Constants.addMemberClicksIntegrationRequestSuccessActionType: return addMemberClicksIntegrationSuccessAction(state);
        case Constants.addMemberClicksIntegrationRequestFailureActionType: return addMemberClicksIntegrationFailureAction(state);

        case Constants.getPersonifyIntegrationRequestActionType: return getPersonifyIntegrationAction(state);
        case Constants.getPersonifyIntegrationRequestSuccessActionType: return getPersonifyIntegrationSuccessAction(state, action);
        case Constants.getPersonifyIntegrationRequestFailureActionType: return getPersonifyIntegrationFailureAction(state);
        case Constants.addPersonifyIntegrationRequestActionType: return addPersonifyIntegrationAction(state);
        case Constants.addPersonifyIntegrationRequestSuccessActionType: return addPersonifyIntegrationSuccessAction(state);
        case Constants.addPersonifyIntegrationRequestFailureActionType: return addPersonifyIntegrationFailureAction(state);

        case Constants.savePersonifyIntegrationRequestActionType: return savePersonifyIntegrationAction(state);
        case Constants.savePersonifyIntegrationRequestSuccessActionType: return savePersonifyIntegrationSuccessAction(state);
        case Constants.savePersonifyIntegrationRequestFailureActionType: return savePersonifyIntegrationFailureAction(state);
        case Constants.getNaylorIntegrationRequestActionType: return getNaylorIntegrationAction(state);
        case Constants.getNaylorIntegrationRequestSuccessActionType: return getNaylorIntegrationSuccessAction(state, action);
        case Constants.getNaylorIntegrationRequestFailureActionType: return getNaylorIntegrationFailureAction(state);
        case Constants.getSpargoIntegrationRequestActionType: return getSpargoIntegrationAction(state);
        case Constants.getSpargoIntegrationRequestSuccessActionType: return getSpargoIntegrationSuccessAction(state, action);
        case Constants.getSpargoIntegrationRequestFailureActionType: return getSpargoIntegrationFailureAction(state);
        case Constants.getSingleApiV2RequestActionType: return getSingleApiV2Action(state);
        case Constants.getSingleApiV2RequestSuccessActionType: return getSingleApiV2SuccessAction(state, action);
        case Constants.getSingleApiV2RequestFailureActionType: return getSingleApiV2FailureAction(state);
        case Constants.updateApiV2RequestActionType: return updateApiV2Action(state);
        case Constants.updateApiV2RequestSuccessActionType: return updateApiV2SuccessAction(state);
        case Constants.updateApiV2RequestFailureActionType: return updateApiV2FailureAction(state);

        case Constants.getMembesIntegrationRequestActionType: return getMembesIntegrationAction(state);
        case Constants.getMembesIntegrationRequestSuccessActionType: return getMembesIntegrationSuccessAction(state, action);
        case Constants.getMembesIntegrationRequestFailureActionType: return getMembesIntegrationFailureAction(state);
        case Constants.addMembesIntegrationRequestActionType: return addMembesIntegrationAction(state);
        case Constants.addMembesIntegrationRequestSuccessActionType: return addMembesIntegrationSuccessAction(state);
        case Constants.addMembesIntegrationRequestFailureActionType: return addMembesIntegrationFailureAction(state);

        case Constants.getGoogleIntegrationRequestActionType: return getGoogleIntegrationAction(state);
        case Constants.getGoogleIntegrationRequestSuccessActionType: return getGoogleIntegrationSuccessAction(state, action);
        case Constants.getGoogleIntegrationRequestFailureActionType: return getGoogleIntegrationFailureAction(state);
        case Constants.getWebCourseIntegrationRequestActionType: return getWebCourseIntegrationAction(state);
        case Constants.getWebCourseIntegrationRequestSuccessActionType: return getWebCourseIntegrationSuccessAction(state, action);
        case Constants.getWebCourseIntegrationRequestFailureActionType: return getWebCourseIntegrationFailureAction(state);
        case Constants.getZoomIntegrationRequestActionType: return getZoomIntegrationAction(state);
        case Constants.getZoomIntegrationRequestSuccessActionType: return getZoomIntegrationSuccessAction(state, action);
        case Constants.getZoomIntegrationRequestFailureActionType: return getZoomIntegrationFailureAction(state);
        case Constants.enablePaymentProviderRequestActionType: return enablePaymentProviderAction(state);
        case Constants.enablePaymentProviderRequestSuccessActionType: return enablePaymentProviderSuccessAction(state);
        case Constants.enablePaymentProviderRequestFailureActionType: return enablePaymentProviderFailureAction(state);

        case Constants.getPaypalPayFlowProviderRequestActionType: return getPaypalPayFlowProviderAction(state);
        case Constants.getPaypalPayFlowProviderRequestSuccessActionType: return getPaypalPayFlowProviderSuccessAction(state, action);
        case Constants.getPaypalPayFlowProviderRequestFailureActionType: return getPaypalPayFlowProviderFailureAction(state);
        case Constants.addPaypalPayFlowProviderRequestActionType: return addPaypalPayFlowProviderAction(state);
        case Constants.addPaypalPayFlowProviderRequestSuccessActionType: return addPaypalPayFlowProviderSuccessAction(state);
        case Constants.addPaypalPayFlowProviderRequestFailureActionType: return addPaypalPayFlowProviderFailureAction(state);

        case Constants.getEziDebitProviderRequestActionType: return getEziDebitProviderAction(state);
        case Constants.getEziDebitProviderRequestSuccessActionType: return getEziDebitProviderSuccessAction(state, action);
        case Constants.getEziDebitProviderRequestFailureActionType: return getEziDebitProviderFailureAction(state);
        case Constants.addEzidebitProviderRequestActionType: return addEziDebitProviderAction(state);
        case Constants.addEzidebitProviderRequestSuccessActionType: return addEziDebitProviderSuccessAction(state);
        case Constants.addEzidebitProviderRequestFailureActionType: return addEziDebitProviderFailureAction(state);

        case Constants.getStripeProviderRequestActionType: return getStripeProviderAction(state);
        case Constants.getStripeProviderRequestSuccessActionType: return getStripeProviderSuccessAction(state, action);
        case Constants.getStripeProviderRequestFailureActionType: return getStripeProviderFailureAction(state);
        case Constants.addStripeProviderRequestActionType: return addStripeProviderAction(state);
        case Constants.addStripeProviderRequestSuccessActionType: return addStripeProviderSuccessAction(state);
        case Constants.addStripeProviderRequestFailureActionType: return addStripeProviderFailureAction(state);

        case Constants.getPayPalProviderRequestActionType: return getPayPalProviderAction(state);
        case Constants.getPayPalProviderRequestSuccessActionType: return getPayPalProviderSuccessAction(state, action);
        case Constants.getPayPalProviderRequestFailureActionType: return getPayPalProviderFailureAction(state);
        case Constants.addPayPalProviderRequestActionType: return addPayPalProviderAction(state);
        case Constants.addPayPalProviderRequestSuccessActionType: return addPayPalProviderSuccessAction(state);
        case Constants.addPayPalProviderRequestFailureActionType: return addPayPalProviderFailureAction(state);

        case Constants.getBPointProviderRequestActionType: return getBPointProviderAction(state);
        case Constants.getBPointProviderRequestSuccessActionType: return getBPointProviderSuccessAction(state, action);
        case Constants.getBPointProviderRequestFailureActionType: return getBPointProviderFailureAction(state);
        case Constants.addBPointProviderRequestActionType: return addBPointProviderAction(state);
        case Constants.addBPointProviderRequestSuccessActionType: return addBPointProviderSuccessAction(state);
        case Constants.addBPointProviderRequestFailureActionType: return addBPointProviderFailureAction(state);

        case Constants.getLivePayProviderRequestActionType: return getLivePayProviderAction(state);
        case Constants.getLivePayProviderRequestSuccessActionType: return getLivePayProviderSuccessAction(state, action);
        case Constants.getLivePayProviderRequestFailureActionType: return getLivePayProviderFailureAction(state);
        case Constants.addLivePayProviderRequestActionType: return addLivePayProviderAction(state);
        case Constants.addLivePayProviderRequestSuccessActionType: return addLivePayProviderSuccessAction(state);
        case Constants.addLivePayProviderRequestFailureActionType: return addLivePayProviderFailureAction(state);

        case Constants.getCePointsRequestActionType: return getCePointsAction(state);
        case Constants.getCePointsRequestSuccessActionType: return getCePointsSuccessAction(state, action);
        case Constants.getCePointsRequestFailureActionType: return getCePointsFailureAction(state);
        case Constants.saveCePointsRequestActionType: return saveCePointsAction(state);
        case Constants.saveCePointsRequestSuccessActionType: return saveCePointsSuccessAction(state);
        case Constants.saveCePointsRequestFailureActionType: return saveCePointsFailureAction(state);
        case Constants.getEducationPointsRequestActionType: return getEducationPointsAction(state);
        case Constants.getEducationPointsRequestSuccessActionType: return getEducationPointsSuccessAction(state, action);
        case Constants.getEducationPointsRequestFailureActionType: return getEducationPointsFailureAction(state);
        case Constants.saveEducationPointsRequestActionType: return saveEducationPointsAction(state);
        case Constants.saveEducationPointsRequestSuccessActionType: return saveEducationPointsSuccessAction(state);
        case Constants.saveEducationPointsRequestFailureActionType: return saveEducationPointsFailureAction(state);
        case Constants.updateEducationPointsRequestActionType: return updateEducationPointsAction(state);
        case Constants.updateEducationPointsRequestSuccessActionType: return updateEducationPointsSuccessAction(state);
        case Constants.updateEducationPointsRequestFailureActionType: return updateEducationPointsFailureAction(state);
        case Constants.deleteEducationPointsRequestActionType: return deleteEducationPointsAction(state);
        case Constants.deleteEducationPointsRequestSuccessActionType: return deleteEducationPointsSuccessAction(state);
        case Constants.deleteEducationPointsRequestFailureActionType: return deleteEducationPointsFailureAction(state);
        case Constants.getSingleEducationPointsRequestActionType: return getSingleEducationPointsAction(state);
        case Constants.getSingleEducationPointsRequestSuccessActionType: return getSingleEducationPointsSuccessAction(state, action);
        case Constants.getSingleEducationPointsRequestFailureActionType: return getSingleEducationPointsFailureAction(state);
        case Constants.getTemplatesRequestActionType: return getTemplatesRequestAction(state);
        case Constants.getTemplatesRequestSuccessActionType: return getTemplatesRequestSuccessAction(state, action);
        case Constants.getTemplatesRequestFailureActionType: return getTemplatesRequestFailureAction(state);
        case Constants.getIntegrationRequestActionType: return getIntegrationRequestAction(state);
        case Constants.getIntegrationRequestSuccessActionType: return getIntegrationRequestSuccessAction(state, action);
        case Constants.getIntegrationRequestFailureActionType: return getIntegrationRequestFailureAction(state);
        case Constants.updateIntegrationRequestActionType: return updateIntegrationAction(state);
        case Constants.updateIntegrationRequestSuccessActionType: return updateIntegrationSuccessAction(state);
        case Constants.updateIntegrationRequestFailureActionType: return updateIntegrationFailureAction(state);
        case Constants.getPaymentProviderRequestActionType: return getPaymentProviderRequestAction(state);
        case Constants.getPaymentProviderRequestSuccessActionType: return getPaymentProviderRequestSuccessAction(state, action);
        case Constants.getPaymentProviderRequestFailureActionType: return getPaymentProviderRequestFailureAction(state);
        case Constants.updatePaymentRequestActionType: return updatePaymentAction(state);
        case Constants.updatePaymentRequestSuccessActionType: return updatePaymentSuccessAction(state);
        case Constants.updatePaymentRequestFailureActionType: return updatePaymentFailureAction(state);
        case Constants.addTemplateRequestActionType: return addTemplateAction(state);
        case Constants.addTemplateRequestSuccessActionType: return addTemplateSuccessAction(state);
        case Constants.addTemplateRequestFailureActionType: return addTemplateFailureAction(state);
        case Constants.deleteTemplateRequestActionType: return deleteTemplateAction(state);
        case Constants.deleteTemplateRequestSuccessActionType: return deleteTemplateSuccessAction(state);
        case Constants.deleteTemplateRequestFailureActionType: return deleteTemplateFailureAction(state);
        case Constants.updateTemplateRequestActionType: return updateTemplateAction(state);
        case Constants.updateTemplateRequestSuccessActionType: return updateTemplateSuccessAction(state);
        case Constants.updateTemplateRequestFailureActionType: return updateTemplateFailureAction(state);
        case Constants.getCurrencyRequestActionType: return getCurrencyAction(state);
        case Constants.getCurrencyRequestSuccessActionType: return getCurrencySuccessAction(state, action);
        case Constants.getCurrencyRequestFailureActionType: return getCurrencyFailureAction(state);
        case Constants.updateCurrencyRequestActionType: return updateCurrencyAction(state);
        case Constants.updateCurrencyRequestSuccessActionType: return updateCurrencySuccessAction(state);
        case Constants.updateCurrencyRequestFailureActionType: return updateCurrencyFailureAction(state);
        case Constants.getSelectedCurrencyRequestActionType: return getSelectedCurrencyAction(state);
        case Constants.getSelectedCurrencyRequestSuccessActionType: return getSelectedCurrencySuccessAction(state, action);
        case Constants.getSelectedCurrencyRequestFailureActionType: return getSelectedCurrencyFailureAction(state);
        case Constants.getEcommerceSettingRequestActionType: return getEcommerceSettingAction(state);
        case Constants.getEcommerceSettingRequestSuccessActionType: return getEcommerceSettingSuccessAction(state, action);
        case Constants.getEcommerceSettingRequestFailureActionType: return getEcommerceSettingFailureAction(state);
        case Constants.updateEcommerceSettingRequestActionType: return updateEcommerceSettingAction(state);
        case Constants.updateEcommerceSettingRequestSuccessActionType: return updateEcommerceSettingSuccessAction(state);
        case Constants.updateEcommerceSettingRequestFailureActionType: return updateEcommerceSettingFailureAction(state);

        case Constants.getQuickBooksProviderRequestActionType: return getQuickBooksProviderProviderAction(state);
        case Constants.getQuickBooksProviderRequestSuccessActionType: return getQuickBooksProviderSuccessAction(state, action);
        case Constants.getQuickBooksProviderRequestFailureActionType: return getQuickBooksProviderFailureAction(state);
        case Constants.addQuickBooksProviderRequestActionType: return addQuickBooksProviderAction(state);
        case Constants.addQuickBooksProviderRequestSuccessActionType: return addQuickBooksProviderSuccessAction(state);
        case Constants.addQuickBooksProviderRequestFailureActionType: return addQuickBooksProviderFailureAction(state);
        case Constants.getConnectQuickBooksProviderRequestActionType: return getConnectQuickBooksProviderProviderAction(state);
        case Constants.getConnectQuickBooksProviderRequestSuccessActionType: return getConnectQuickBooksProviderSuccessAction(state, action);
        case Constants.getConnectQuickBooksProviderRequestFailureActionType: return getConnectQuickBooksProviderFailureAction(state);

        case Constants.getAttributeMappingRequestActionType: return getAttributeMappingAction(state);
        case Constants.getAttributeMappingRequestSuccessActionType: return getAttributeMappingSuccessAction(state, action);
        case Constants.getAttributeMappingRequestFailureActionType: return getAttributeMappingFailureAction(state);
        case Constants.addAttributeMappingRequestActionType: return addAttributeMappingAction(state);
        case Constants.addAttributeMappingRequestSuccessActionType: return addAttributeMappingSuccessAction(state);
        case Constants.addAttributeMappingRequestFailureActionType: return addAttributeMappingFailureAction(state);
        case Constants.updateAttributeMappingRequestActionType: return updateAttributeMappingAction(state);
        case Constants.updateAttributeMappingRequestSuccessActionType: return updateAttributeMappingSuccessAction(state);
        case Constants.updateAttributeMappingRequestFailureActionType: return updateAttributeMappingFailureAction(state);
        case Constants.deleteAttributeMappingRequestActionType: return deleteAttributeMappingAction(state);
        case Constants.deleteAttributeMappingRequestSuccessActionType: return deleteAttributeMappingSuccessAction(state);
        case Constants.deleteAttributeMappingRequestFailureActionType: return deleteAttributeMappingFailureAction(state);
        case Constants.getSingleAttributeMappingRequestActionType: return getSingleAttributeMappingAction(state);
        case Constants.getSingleAttributeMappingRequestSuccessActionType: return getSingleAttributeMappingSuccessAction(state, action);
        case Constants.getSingleAttributeMappingRequestFailureActionType: return getSingleAttributeMappingFailureAction(state);
        case Constants.getAttributeMappingColumnListRequestActionType: return getAttributeMappingColumnListAction(state);
        case Constants.getAttributeMappingColumnListRequestSuccessActionType: return getAttributeMappingColumnListSuccessAction(state, action);
        case Constants.getAttributeMappingColumnListRequestFailureActionType: return getAttributeMappingColumnListFailureAction(state);
        case Constants.getAttributeMappingTypeListRequestActionType: return getAttributeMappingTypeListAction(state);
        case Constants.getAttributeMappingTypeListRequestSuccessActionType: return getAttributeMappingTypeListSuccessAction(state, action);
        case Constants.getAttributeMappingTypeListRequestFailureActionType: return getAttributeMappingTypeListFailureAction(state);

        default: return state;
    }

    function getApiV2Action(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isGetApiV2Loading: true,
        };
    }
    function getApiV2SuccessAction(state: SettingStateModel, action: SettingActions.getApiV2RequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                apiV2: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isGetApiV2Loading: false
            };
        }
        return state;
    }
    function getApiV2FailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isGetApiV2Loading: false
        }
    }

    function addApiV2Action(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addApiV2SuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Saved: true,
            message: 'Success',
            loading: false
        };
    }
    function addApiV2FailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Saved: false,
            loading: false,
            error: true
        }
    }
    function deleteApiV2Action(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteApiV2SuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Deleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteApiV2FailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Deleted: false,
            loading: false,
            error: true
        }
    }
    function resetSettingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Saved: false,
            isApiV2Deleted: false,
            isSamlSaved: false,
            isCloudRecordingUpdated: false,
            isAutoPublishOnDemandUpdated: false,
            isEcommerceSaved: false,
            isRunUpdated: false,
            isIntegrationProviderUpdated: false,
            isPersonifyIntegrationSaved: false,
            isApiV2Updated: false,
            isPaymentProviderUpdated: false,
            isCePointSaved: false,
            isEducationPointsSaved: false,
            isEducationPointsUpdate: false,
            isEducationPointsDeleted: false,
            isIntegrationUpdated: false,
            isPaymentUpdated: false,
            isTemplateAdded: false,
            isTemplateDeleted: false,
            isTemplateUpdated: false,
            isCurrencyUpdated: false,
            error: false,
            isEcommerceSettingUpdated: false,
            isAttributeMappingSaved: false,
            isAttributeMappingUpdate: false,
            isAttributeMappingDeleted: false
        }
    }

    function getLiveStreamUrlAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isLiveStreamLoading: true,
        };
    }
    function getLiveStreamUrlSuccessAction(state: SettingStateModel, action: SettingActions.getLiveStreamUrlRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                liveStreamUrl: action.payload,
                isLiveStreamLoading: false
            };
        }
        return state;
    }
    function getLiveStreamUrlFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isLiveStreamLoading: false
        }
    }
    function getSamlAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isSamlLoading: true,
        };
    }
    function getSamlSuccessAction(state: SettingStateModel, action: SettingActions.getSamlRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                saml: action.payload,
                isSamlLoading: false
            };
        }
        return state;
    }
    function getSamlFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isSamlLoading: false
        }
    }
    function saveSamlAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveSamlSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isSamlSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function saveSamlFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isSamlSaved: false,
            loading: false,
            error: true
        }
    }
    function updateCloudRecordingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateCloudRecordingSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCloudRecordingUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateCloudRecordingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCloudRecordingUpdated: false,
            loading: false,
            error: true
        }
    }
    function updateAutoPublishOnDemandAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateAutoPublishOnDemandSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAutoPublishOnDemandUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateAutoPublishOnDemandFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAutoPublishOnDemandUpdated: false,
            loading: false,
            error: true
        }
    }
    function getEcommerceAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEcommerceSuccessAction(state: SettingStateModel, action: SettingActions.getEcommerceRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                ecommerce: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getEcommerceFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveEcommerceAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveEcommerceSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEcommerceSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function saveEcommerceFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEcommerceSaved: false,
            loading: false,
            error: true
        }
    }
    function getVideoLogsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoLogsSuccessAction(state: SettingStateModel, action: SettingActions.getVideoLogsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                videoLogs: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoLogsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getChannelLogsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getChannelLogsSuccessAction(state: SettingStateModel, action: SettingActions.getChannelLogsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                channelLogs: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getChannelLogsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAnalyticsLogsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAnalyticsLogsSuccessAction(state: SettingStateModel, action: SettingActions.getAnalyticsLogsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsLogs: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getAnalyticsLogsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function runUpdateAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function runUpdateSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isRunUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function runUpdateFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isRunUpdated: false,
            loading: false,
            error: true
        }
    }
    function enableIntegrationProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function enableIntegrationProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationProviderUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function enableIntegrationProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationProviderUpdated: false,
            loading: false,
            error: true
        }
    }
    function getImisIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getImisIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getImisIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                imisIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getImisIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addImisIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addImisIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addImisIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function getMemberEvolutionIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberEvolutionIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getMemberEvolutionIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                memberEvolutionIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberEvolutionIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addMemberEvolutionIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addMemberEvolutionIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addMemberEvolutionIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }

    function getWicketIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getWicketIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getWicketIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                wicketIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getWicketIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addWicketIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addWicketIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addWicketIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }

    function getDynamics365IntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getDynamics365IntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getDynamics365IntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                dynamics365Integration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getDynamics365IntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addDynamics365IntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addDynamics365IntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addDynamics365IntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function getPersonifyIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }

    function getMicrosoftAzureIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMicrosoftAzureIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getMicrosoftAzureIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                microsoftAzureIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMicrosoftAzureIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addMicrosoftAzureIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addMicrosoftAzureIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addMicrosoftAzureIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }

    function getNoviAMSIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getNoviAMSIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getNoviAMSIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                noviamsIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getNoviAMSIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addNoviAMSIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addNoviAMSIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addNoviAMSIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }

    function getOAuth2IntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getOAuth2IntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getOAuth2IntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                oauth2Integration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getOAuth2IntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addOAuth2IntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addOAuth2IntegrationSuccessAction(state: SettingStateModel): SettingStateModel {

        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addOAuth2IntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }

    function getMemberClicksIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMemberClicksIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getMemberClicksIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                memberclicksIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMemberClicksIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addMemberClicksIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addMemberClicksIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        //console.log('addMemberClicksIntegrationSuccessAction:', state);
        return {
            ...state,
            isApiV2Saved: true,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addMemberClicksIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function getPersonifyIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getPersonifyIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                personifyIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPersonifyIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addPersonifyIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addPersonifyIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addPersonifyIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function savePersonifyIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function savePersonifyIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPersonifyIntegrationSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function savePersonifyIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPersonifyIntegrationSaved: false,
            loading: false,
            error: true
        }
    }
    function getNaylorIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getNaylorIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getNaylorIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                naylorIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getNaylorIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getSpargoIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSpargoIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getSpargoIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                spargoIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSpargoIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getSingleApiV2Action(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleApiV2SuccessAction(state: SettingStateModel, action: SettingActions.getSingleApiV2RequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                singleApiV2: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleApiV2FailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateApiV2Action(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateApiV2SuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Updated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateApiV2FailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isApiV2Updated: false,
            loading: false,
            error: true
        }
    }
    function getMembesIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMembesIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getMembesIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                membesIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMembesIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addMembesIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addMembesIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addMembesIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function getGoogleIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getGoogleIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getGoogleIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                googleIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getGoogleIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getWebCourseIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getWebCourseIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getWebCourseIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                webCourseIntegration: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getWebCourseIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getZoomIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isZoomIntegrationLoading: true,
        };
    }
    function getZoomIntegrationSuccessAction(state: SettingStateModel, action: SettingActions.getZoomIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                zoomIntegration: action.payload,
                isZoomIntegrationLoading: false
            };
        }
        return state;
    }
    function getZoomIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isZoomIntegrationLoading: false
        }
    }
    function enablePaymentProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function enablePaymentProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentProviderUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function enablePaymentProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentProviderUpdated: false,
            loading: false,
            error: true
        }
    }
    function getPaypalPayFlowProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPaypalPayFlowProviderSuccessAction(state: SettingStateModel, action: SettingActions.getPaypalPayFlowProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                paypalPayFlow: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPaypalPayFlowProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addPaypalPayFlowProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addPaypalPayFlowProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addPaypalPayFlowProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function getEziDebitProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEziDebitProviderSuccessAction(state: SettingStateModel, action: SettingActions.getEziDebitProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                ezidebit: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getEziDebitProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function addEziDebitProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addEziDebitProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addEziDebitProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function getStripeProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getStripeProviderSuccessAction(state: SettingStateModel, action: SettingActions.getStripeProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                stripe: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getStripeProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addStripeProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addStripeProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addStripeProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }

    function getPayPalProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPayPalProviderSuccessAction(state: SettingStateModel, action: SettingActions.getPayPalProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                paypal: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getPayPalProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addPayPalProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addPayPalProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addPayPalProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }

    function getBPointProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getBPointProviderSuccessAction(state: SettingStateModel, action: SettingActions.getBPointProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                bPoint: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getBPointProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addBPointProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addBPointProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addBPointProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function getLivePayProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLivePayProviderSuccessAction(state: SettingStateModel, action: SettingActions.getLivePayProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                livePay: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLivePayProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addLivePayProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addLivePayProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addLivePayProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function getCePointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCePointsSuccessAction(state: SettingStateModel, action: SettingActions.getCePointsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                getCeEducationPoints: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getCePointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveCePointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveCePointsSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCePointSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function saveCePointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCePointSaved: false,
            loading: false,
            error: true
        }
    }
    function getEducationPointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsLoading: true,
        };
    }
    function getEducationPointsSuccessAction(state: SettingStateModel, action: SettingActions.getEducationPointsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                educationPoints: action.payload,
                isEducationPointsLoading: false
            };
        }
        return state;
    }
    function getEducationPointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsLoading: false
        }
    }
    function saveEducationPointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveEducationPointsSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function saveEducationPointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsSaved: false,
            loading: false,
            error: true
        }
    }
    function updateEducationPointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateEducationPointsSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsUpdate: true,
            message: 'Success',
            loading: false
        };
    }
    function updateEducationPointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsUpdate: false,
            loading: false,
            error: true
        }
    }
    function deleteEducationPointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteEducationPointsSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsDeleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteEducationPointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEducationPointsDeleted: false,
            loading: false,
            error: true
        }
    }
    function getSingleEducationPointsAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleEducationPointsSuccessAction(state: SettingStateModel, action: SettingActions.getSingleEducationPointsRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                singleEducationPoints: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleEducationPointsFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getTemplatesRequestAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTemplatesRequestSuccessAction(state: SettingStateModel, action: SettingActions.getTemplatesRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                templates: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getTemplatesRequestFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getIntegrationRequestAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationLoading: true,
        };
    }
    function getIntegrationRequestSuccessAction(state: SettingStateModel, action: SettingActions.getIntegrationRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                integration: action.payload,
                isIntegrationLoading: false
            };
        }
        return state;
    }
    function getIntegrationRequestFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationLoading: false
        }
    }
    function updateIntegrationAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateIntegrationSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateIntegrationFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isIntegrationUpdated: false,
            loading: false,
            error: true
        }
    }
    function getPaymentProviderRequestAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentProviderLoading: true,
        };
    }
    function getPaymentProviderRequestSuccessAction(state: SettingStateModel, action: SettingActions.getPaymentProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                paymentProvider: action.payload,
                isPaymentProviderLoading: false
            };
        }
        return state;
    }
    function getPaymentProviderRequestFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentProviderLoading: false
        }
    }
    function updatePaymentAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updatePaymentSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updatePaymentFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function addTemplateAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addTemplateSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateAdded: true,
            message: 'Success',
            loading: false
        };
    }
    function addTemplateFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateAdded: false,
            loading: false,
            error: true
        }
    }
    function deleteTemplateAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteTemplateSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateDeleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteTemplateFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateDeleted: false,
            loading: false,
            error: true
        }
    }
    function updateTemplateAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateTemplateSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateTemplateFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isTemplateUpdated: false,
            loading: false,
            error: true
        }
    }
    function getCurrencyAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCurrencySuccessAction(state: SettingStateModel, action: SettingActions.getCurrencyRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                currency: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getCurrencyFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateCurrencyAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateCurrencySuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCurrencyUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateCurrencyFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isCurrencyUpdated: false,
            loading: false,
            error: true
        }
    }
    function getSelectedCurrencyAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSelectedCurrencySuccessAction(state: SettingStateModel, action: SettingActions.getSelectedCurrencyRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                selectedCurrency: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSelectedCurrencyFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getEcommerceSettingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEcommerceSettingSuccessAction(state: SettingStateModel, action: SettingActions.getEcommerceSettingRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                ecommerceSetting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getEcommerceSettingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateEcommerceSettingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateEcommerceSettingSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEcommerceSettingUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateEcommerceSettingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isEcommerceSettingUpdated: false,
            loading: false,
            error: true
        }
    }
    function getQuickBooksProviderProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getQuickBooksProviderSuccessAction(state: SettingStateModel, action: SettingActions.getQuickBooksProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                quickBooks: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getQuickBooksProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addQuickBooksProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addQuickBooksProviderSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function addQuickBooksProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isPaymentUpdated: false,
            loading: false,
            error: true
        }
    }
    function getConnectQuickBooksProviderProviderAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getConnectQuickBooksProviderSuccessAction(state: SettingStateModel, action: SettingActions.getConnectQuickBooksProviderRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                QBAuthURL: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getConnectQuickBooksProviderFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAttributeMappingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingLoading: true,
        };
    }
    function getAttributeMappingSuccessAction(state: SettingStateModel, action: SettingActions.getAttributeMappingRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                attributeMapping: action.payload,
                isAttributeMappingLoading: false
            };
        }
        return state;
    }
    function getAttributeMappingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingLoading: false
        }
    }
    function addAttributeMappingAction(state: SettingStateModel): SettingStateModel {
        //  console.log('state', state);
        return {
            ...state,
            loading: true,
        };
    }
    function addAttributeMappingSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function addAttributeMappingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingSaved: false,
            loading: false,
            error: true
        }
    }
    function updateAttributeMappingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateAttributeMappingSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingUpdate: true,
            message: 'Success',
            loading: false
        };
    }
    function updateAttributeMappingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingUpdate: false,
            loading: false,
            error: true
        }
    }
    function deleteAttributeMappingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteAttributeMappingSuccessAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingDeleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteAttributeMappingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingDeleted: false,
            loading: false,
            error: true
        }
    }
    function getSingleAttributeMappingAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleAttributeMappingSuccessAction(state: SettingStateModel, action: SettingActions.getSingleAttributeMappingRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                singleAttributeMapping: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleAttributeMappingFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAttributeMappingColumnListAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingColumnListLoading: true,
        };
    }
    function getAttributeMappingColumnListSuccessAction(state: SettingStateModel, action: SettingActions.getAttributeMappingColumnListRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                AttributeMappingColumnList: action.payload,
                isAttributeMappingColumnListLoading: false
            };
        }
        return state;
    }
    function getAttributeMappingColumnListFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingColumnListLoading: false
        }
    }
    function getAttributeMappingTypeListAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingTypeListLoading: true,
        };
    }
    function getAttributeMappingTypeListSuccessAction(state: SettingStateModel, action: SettingActions.getAttributeMappingTypeListRequestSuccessAction): SettingStateModel {
        if (action.payload) {
            return {
                ...state,
                AttributeMappingTypeList: action.payload,
                isAttributeMappingTypeListLoading: false
            };
        }
        return state;
    }
    function getAttributeMappingTypeListFailureAction(state: SettingStateModel): SettingStateModel {
        return {
            ...state,
            isAttributeMappingTypeListLoading: false
        }
    }
}

export default settingReducer
