import { all } from "redux-saga/effects";
import { usersSaga } from "../users/usersSaga";
import { dashboardSaga } from "../dashboard/dashboardSaga";
import { videosSaga } from "../videos/videosSaga";
import { loginSaga } from "../login/loginSaga";
import { monetizationSaga } from "../monetization/monetizationSaga";
import { channelSaga } from "../channel/channelSaga";
import { courseSaga } from "../course/courseSaga";
import { eventSaga } from "../event/eventSaga";
import { videoLibrarySaga } from "../videoLibrary/videoLibrarySaga";
import { analyticsSaga } from "../analytics/analyticsSaga";
import { CategorySaga } from "../category/categorySaga";
import { settingSaga } from "../settings/settingSaga";
import { appearanceSaga } from "../appearance/appearanceSaga";
export default function* RootSaga() {
    yield all([
        usersSaga(),
        dashboardSaga(),
        videosSaga(),
        loginSaga(),
        monetizationSaga(),
        channelSaga(),
        courseSaga(),
        eventSaga(),
        videoLibrarySaga(),
        analyticsSaga(),
        CategorySaga(),
        settingSaga(),
        appearanceSaga()
    ])
}