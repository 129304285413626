import { all, put, takeEvery } from 'redux-saga/effects';
import { LoginActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import * as Constants from './constants'
import { setItem } from 'src/components/utilities/utils';
import { URLS } from 'src/components/utilities/endpoints';
import { UserInfoModel } from 'src/models/usersModel';


function* asyncLogin(data: LoginActions.sendLoginRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postLoginData(URLS.LOGIN.url, data.payload);
        if (response.data.StatusCode === 200) {
            setItem('token', response.data.Result.AuthToken);
            const userData: UserInfoModel = {
                firstName: response.data.Result.FirstName,
                lastName: response.data.Result.LastName,
                email: response.data.Result.Email,
                role: response.data.Result.Roles,
                profilePicUrl: response.data.Result.profilePicUrl,
                domainName: response.data.Result.DomainName,
                domainId: response.data.Result.DomainId,
                UserId: response.data.Result.UserId,
                isMfaSetupEnabled: response.data.Result.isMfaSetupEnabled
            }
            const date = new Date();

            const updateDate = new Date(date.getTime() + response.data.Result.ExpiresIn * 1000)
            document.cookie = 'RefreshCookie  = ' + updateDate.toString() + "; expires = " + updateDate.toString();
            localStorage.setItem('userData', JSON.stringify(userData))


            const expiryRefreshTokenDate = new Date(date.getTime());
            expiryRefreshTokenDate.setDate(expiryRefreshTokenDate.getDate() + response.data.Result.RefreshTokenExpiresInDays);
            document.cookie = 'RefreshTokenExpiryCookie = ' + expiryRefreshTokenDate.toString() + "; expires = " + expiryRefreshTokenDate.toString();

            setItem('refreshToken', response.data.Result.RefreshToken)
            yield put(LoginActions.sendLoginRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.sendLoginRequestFailure(error));
    }
}

function* asyncSSOLogin(data: LoginActions.sendSSOLoginRequestAction) {
    try {
       
        const response: AxiosResponse = yield ApiService.postLoginData(URLS.SSOLOGIN.url, data.payload);
        if (response.data.StatusCode === 200) {
            if (response.data.Result == null && response.data.Message !== '') {
                const error = { customerror: true, error_description: response.data.Message };
                yield put(LoginActions.sendSSOLoginRequestFailure(error));
                return;
            }
            setItem('token', response.data.Result.AuthToken);
            const userData: UserInfoModel = {
                firstName: response.data.Result.FirstName,
                lastName: response.data.Result.LastName,
                email: response.data.Result.Email,
                role: response.data.Result.Roles,
                profilePicUrl: response.data.Result.profilePicUrl,
                domainName: response.data.Result.DomainName,
                domainId: response.data.Result.DomainId,
                UserId: response.data.Result.UserId,
                isMfaSetupEnabled: response.data.Result.isMfaSetupEnabled
            }
            const date = new Date();

            const updateDate = new Date(date.getTime() + response.data.Result.ExpiresIn * 1000)
            document.cookie = 'RefreshCookie  = ' + updateDate.toString() + "; expires = " + updateDate.toString();
            localStorage.setItem('userData', JSON.stringify(userData))


            const expiryRefreshTokenDate = new Date(date.getTime());
            expiryRefreshTokenDate.setDate(expiryRefreshTokenDate.getDate() + response.data.Result.RefreshTokenExpiresInDays);
            document.cookie = 'RefreshTokenExpiryCookie = ' + expiryRefreshTokenDate.toString() + "; expires = " + expiryRefreshTokenDate.toString();

            setItem('refreshToken', response.data.Result.RefreshToken)
            yield put(LoginActions.sendSSOLoginRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.sendSSOLoginRequestFailure(error));
    }
}

function* asyncDomainList(data: LoginActions.sendLoginRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHANNELS.url);
        if (response.status === 200) {
            yield put(LoginActions.getDomainListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.getDomainListRequestFailure());
    }
}

function* asyncForgetRestPasswordDetails(data: LoginActions.forgetRestPasswordRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postResetPasswordData(`${URLS.RESET_PASSWORD.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(LoginActions.forgetRestPasswordRequestSuccess(response));
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.forgetRestPasswordRequestFailure());
    }
}

function* asyncUpdateUserPassword(data: LoginActions.resetPasswordRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchResetPasswordData(`${URLS.UPDATE_USERS.url}/forgetPassword/resetPassword/${data.payload?.userId}`, data.payload);
        if (response.status === 200) {
            yield put(LoginActions.resetPasswordRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.resetPasswordRequestFailure());
    }
}

function* asyncDefaultDateFormat() {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ACCOUNT_INFO.url}`);
        if (response.status === 200) {
            yield put(LoginActions.getDefaultdateFormatRequestSuccess(response.data.result));
            localStorage.setItem('defaultDateFormat', response.data.result.dateFormat);
        }
    } catch (error) {
        console.log(error);
        yield put(LoginActions.getDefaultdateFormatRequestFailure());
    }
}

export function* loginSaga() {
    yield all([
        takeEvery(Constants.SendLoginRequestActionType, asyncLogin),
        takeEvery(Constants.SendSSOLoginRequestActionType, asyncSSOLogin),
        takeEvery(Constants.GetDomainListRequestActionType, asyncDomainList),
        takeEvery(Constants.forgetRestPasswordRequestActionType, asyncForgetRestPasswordDetails),
        takeEvery(Constants.resetPasswordRequestActionType, asyncUpdateUserPassword),
        takeEvery(Constants.getDefaultdateFormatRequestActionType, asyncDefaultDateFormat),
    ]);
}
