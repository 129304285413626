import React, { useEffect, useState } from 'react';
import '../styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Modal from '../components/Modal';
import { useUserActions } from 'src/store/users/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLoginActions } from 'src/store/login/actions';
import { errorMsg, getItem, successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { UserInfoModel, UsersModel } from 'src/models/usersModel';

type locationState = {
  userId: string;
};
const UserDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAction = useUserActions(dispatch);
  const location = useLocation();
  const { id } = useParams<string>();
  const loginAction = useLoginActions(dispatch);
  const { userId } = (location.state as locationState) || {};
  const {
    userDetails, roles
  } = useSelector((state: RootState) => {
    return {
      userDetails: state.users.userDetails,
      roles: state.users.roles
    };
  });
  const {
    forgetRestPassword, success, resetPasswordObj
  } = useSelector((state: RootState) => {
    return {
      forgetRestPassword: state.users.userDetails,
      success: state.login.isResetSuccess,
      resetPasswordObj: state.login.resetPasswordObj
    };
  });

  const [userInfoDetails, setUserInfoDetails] = useState<UserInfoModel | null>(null);
  const [value, setValue] = useState(userDetails?.active);

  const handleEditClick = (id: string) => {
    userAction.getUserDetailsRequest(id);
  };

  useEffect(() => {
    if (id) {
      userAction.getUserDetailsRequest(id);
    }
  }, [userDetails?.userId]);

  useEffect(() => {
    setValue(!userDetails?.active)
  }, [userDetails?.active]);

  useEffect(() => {
    setUserInfoDetails(JSON.parse(getItem('userData')));
  }, []);

  useEffect(() => {
    if (Object.keys(resetPasswordObj).length > 0) {
      if (success) {
        successMsg('Link sent successfully.');
        loginAction.reset();
      }
      else {
        errorMsg('Link not sent.');
        loginAction.reset();
      }
    }
  }, [resetPasswordObj, success]);

  const handleForgetPasswordLink = (data: string) => {
    loginAction.forgetRestPasswordRequest(data);
  };

  const handleResetPassword = (data: string) => {
    navigate(`/change-password/${data}`);
  };

  const changeActiveStatus = (e: any) => {
    const userData = userDetails ?? {} as UsersModel
    const role = roles.filter((x) => x.name == userData.role)
    userAction.UpdateUserRequest({ ...userData, active: e, role: role[0].id });
  }

  return (<>
    <ToastMessage />
    <div className="member-details mt-4">
      <div className="lg:mr-20 mt-2">
        {userDetails && (
          <div className="dashboard-card">
            <div className="mt-5">
              <h2 className="font-semibold border-b">Details</h2>
              <div className='flex items-start justify-between gap-1'>
                <ul>
                  <li className="my-3">
                    <label className="font-semibold">Name: </label>
                    {userDetails.name}
                  </li>
                  <li className="my-3">
                    <label className="font-semibold">Username: </label>
                    {userDetails.userName}
                  </li>
                  <li className="my-3">
                    <label className="font-semibold">Email: </label>
                    {userDetails.email}
                  </li>

                  <li className="my-3">
                    <label className="font-semibold">Role: </label>
                    {userDetails.role}
                  </li>
                  <li className="my-3">
                    <label className="font-semibold">Phone: </label>
                    {userDetails.phoneNumber}
                  </li>
                  <li className="my-3">
                    <label className="font-semibold">MFA Setup: </label>
                    {userDetails.isMfaSetupEnabled ? "Enabled" : "Not Setup"}
                  </li>
                  <li className="my-3">
                    <label className="font-semibold">Active: </label>
                    <input type={'checkbox'} checked={userDetails.active ? true : false} readOnly />
                  </li>
                </ul>
                <div>
                  {userInfoDetails && (userInfoDetails.role == "Admin" || userInfoDetails.role == "Super Admin") &&
                    (<button className="btn w-auto py-2 px-3 mt-2 ml-0 mr-3 border rounded-lg reset-password-btn" onClick={() => handleResetPassword(userDetails.userId)}>
                      Reset Password
                    </button>)}
                  <button className="btn w-auto py-2 px-3 mt-2 ml-0 mr-3 border rounded-lg reset-password-btn" onClick={() => handleForgetPasswordLink(userDetails.email)}>
                    Send Password Reset
                  </button>
                </div>
              </div>
              <div className="flex justify-center pb-6">
                <button
                  type="button"
                  onClick={() => handleEditClick(userDetails.userId)}
                  className="btn-primary w-auto px-5 ml-0 mr-3"
                  data-bs-toggle="modal"
                  data-bs-target="#editMemberModal">
                  Edit
                </button>
                <button
                  type="submit"
                  className="btn-primary w-auto mx-0 suspend-btn"
                  onClick={() => changeActiveStatus(value)}
                >
                  {userDetails.active ? "Deactive" : "Activate"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Edit Modal */}
      <Modal userDetails={userDetails} modalFlag="edit" />
    </div>
  </>
  );
};

export default UserDetails;
