import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import AddNewCategory from './AddNewCategory/AddNewCategoryModal';
import DeleteCategoryModal from './DeleteNewCategory/DeleteNewCategoryModal';
import { RootState } from 'src/store/reducers/state';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import VideoTags from './Tags';
import ContentTypes from './ContentTypes';
import AudienceProfiles from './AudienceProfiles';
import CategorySideMenu from './CategorySideMenu';
import Loader from 'src/components/loader';
import { AddCategoryModel, CategoryData } from 'src/models/categoryModel';
import ViewAllVideos from './ViewAllVideos';
import Pagination from 'src/components/common/pagination';
import { errorMsg } from 'src/components/utilities/utils';
import Module from './Modules';
import { successMsg } from 'src/components/utilities/utils';
import { DebounceInput } from 'react-debounce-input';
const VideoCategories = () => {
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);
  const [categoryId, setCategoryId] = useState<CategoryData>({ id: '', name: '' });
  const [selectedTagId, setSelectedTagId] = useState<CategoryData>({ id: '', name: '' });
  const [selectedModuleId, setSelectedModuleId] = useState<CategoryData>({ id: '', name: '' });
  const [selectedAudienceProfileId, setSelectedAudienceProfileId] = useState<CategoryData>({
    id: '',
    name: ''
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedContentTypeId, setSelectedContentTypeId] = useState<CategoryData>({
    id: '',
    name: ''
  });
  const [tabFlag, setTabFlag] = useState<number>();
  const [categoryAddFlag, setCategoryAddFlag] = useState<AddCategoryModel>({
    saveFlag: 'Add',
    categoryId: ''
  });
  const categoryActions = useCategoryActions(dispatch);
  const {
    category,
    isCateoryListLoading,
    isCategorySaved,
    message,
    isCategoryDeleted,
    isCategoryUpdated,
    paginationMeta,
    isContentTypeSaved,
    isContentTypeUpdated,
    isContentTypeDeleted,
    isVideoTagUpdated,
    isVideoTagSaved,
    isVideoTagDeleted,
    videos,
    isSpotLightRowUpdated,
    error,
    isModuleDeleted,
    isModuleSaved,
    isAudienceProfilesDeleted,
    isAudienceProfilesSaved
  } = useSelector((state: RootState) => {
    return {
      category: state.category.category,
      isCateoryListLoading: state.category.isCateoryListLoading,
      isCategorySaved: state.category.isCategorySaved,
      message: state.category.message,
      isCategoryDeleted: state.category.isCategoryDeleted,
      isCategoryUpdated: state.category.isCategoryUpdated,
      paginationMeta: state.category.paginationMeta,
      isContentTypeSaved: state.category.isContentTypeSaved,
      isContentTypeUpdated: state.category.isContentTypeUpdated,
      isContentTypeDeleted: state.category.isContentTypeDeleted,
      isVideoTagUpdated: state.category.isVideoTagUpdated,
      isVideoTagSaved: state.category.isVideoTagSaved,
      isVideoTagDeleted: state.category.isVideoTagDeleted,
      videos: state.videos.videos,
      isSpotLightRowUpdated: state.category.isSpotLightRowUpdated,
      error: state.category.error,
      isModuleDeleted: state.category.isModuleDeleted,
      isModuleSaved: state.category.isModuleSaved,
      isAudienceProfilesDeleted: state.category.isAudienceProfilesDeleted,
      isAudienceProfilesSaved: state.category.isAudienceProfilesSaved
    };
  });

  useEffect(() => {
    if (isCategorySaved || isCategoryDeleted || isCategoryUpdated) {
      categoryActions.getCategoryRequest({ pageNumber: 1 });
    }
  }, [isCategorySaved, isCategoryDeleted, isCategoryUpdated]);

  useEffect(() => {
    if (
      isCategorySaved ||
      isCategoryDeleted ||
      isCategoryUpdated ||
      isContentTypeSaved ||
      isContentTypeUpdated ||
      isContentTypeDeleted ||
      isVideoTagUpdated ||
      isVideoTagSaved ||
      isVideoTagDeleted ||
      isSpotLightRowUpdated ||
      isModuleSaved ||
      isModuleDeleted ||
      isAudienceProfilesDeleted ||
      isAudienceProfilesSaved
    ) {
      successMsg(message);
      categoryActions.resetCategoryStateRequest();
    }
  }, [
    isCategorySaved,
    isCategoryDeleted,
    isCategoryUpdated,
    isContentTypeSaved,
    isContentTypeUpdated,
    isContentTypeDeleted,
    isVideoTagUpdated,
    isVideoTagSaved,
    isVideoTagDeleted,
    isSpotLightRowUpdated,
    isModuleSaved,
    isModuleDeleted,
    isAudienceProfilesDeleted,
    isAudienceProfilesSaved
  ]);

  const handleEditClick = (id: string) => {
    categoryActions.getSingleCategoryRequest(id);
    setCategoryAddFlag({ saveFlag: 'edit', categoryId: id });
  };
  const handleUpdateSpotlightFlag = (SpotlightRowFlag: boolean, categoryid: string) => {
    categoryActions.updateSpotLightRowFlagRequest({ SpotlightRowFlag, categoryid });
  };
  const handlePageChange = (pageNumber: number) => {
    const data = { querySearch: searchValue, pageNumber };
    categoryActions.getCategoryRequest(data);
  };
  useEffect(() => {
    if (
      (isCategoryDeleted || isVideoTagDeleted || isContentTypeDeleted || isModuleDeleted) &&
      message !== 'Record has been deleted'
    ) {
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isCategoryDeleted, isVideoTagDeleted, isContentTypeDeleted, isModuleDeleted]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      categoryActions.resetCategoryStateRequest();
    }
  }, [error]);

  const handleSearchCategory = (e: string) => {
    setSearchValue(e);
    categoryActions.getCategoryRequest({ querySearch: e, pageNumber: 1 });
  };

  return (
    <>
      <div className="video-categories">
        <div className="lg:flex">
          <CategorySideMenu />
          <div className="tab-content w-full shadow border" id="tabs-tabContentVertical">
            {
              <>
                <div
                  className="tab-pane fade show active"
                  id="tabs-videoCategories"
                  role="tabpanel">
                  <div className="py-5 px-7">
                    <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                      <h2 className="font-semibold "></h2>
                      <div className="md:flex items-center text-center">
                        <div className="form-group md:flex items-center text-center">
                          <label className="mr-1 font-semibold">Search:</label>
                          <DebounceInput
                            placeholder="Search"
                            minLength={1}
                            className="input-style w-48 mr-3 mb-1"
                            debounceTimeout={500}
                            onChange={(event) => handleSearchCategory(event.target.value)}
                          />
                        </div>
                        <button
                          onClick={() => {
                            setCategoryAddFlag({ saveFlag: 'Add' }),
                              categoryActions.getAllCategoryRequest({ pageNumber: 1 });
                          }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#AddNewCategory"
                          className="btn-primary w-auto mt-0 mr-0 ml-auto  borderonly-btn">
                          Add
                        </button>
                        <button
                          ref={btnRef}
                          className="hidden"
                          data-bs-toggle="modal"
                          data-bs-target="#categoryList">
                          demo
                        </button>
                      </div>
                    </div>
                    <div className="overflow-x-auto-sm">
                      <table className="table-auto w-full border categories-table-wrapper">
                        <thead className="text-xs font-semibold uppercase bg-gray-100">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left">Category Name</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Parent Category</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Category Description</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Highlight Category</div>
                            </th>
                            <th className="p-2">
                              <div className="text-left invisible">Action</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {isCateoryListLoading ? (
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {category && category.length ? (
                                <>
                                  {category &&
                                    category.map((category) => {
                                      return (
                                        <tr key={category.id}>
                                          <td className="p-2">{category.category}</td>
                                          <td className="p-2">{category.parentCategoryName}</td>
                                          <td className="p-2 multiline-wrap-text multiline-text">
                                            {category.description}
                                          </td>
                                          <td className="p-2">
                                            <div className="form-check">
                                              <input
                                                onChange={(e) =>
                                                  handleUpdateSpotlightFlag(
                                                    e.target.checked,
                                                    category.id
                                                  )
                                                }
                                                defaultChecked={category.displayAsSpotlightCategory}
                                                className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                                                type="checkbox"
                                              />
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="dropdown relative">
                                              <a
                                                className="
                                        dropdown-toggle
                                        transition
                                        duration-150
                                        ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                                href="#"
                                                type="button"
                                                id="ActionButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i className="fas fa-ellipsis-v"></i>
                                              </a>
                                              <ul
                                                className="dropdown-menu action-dropdown"
                                                aria-labelledby="ActionButton">
                                                <li
                                                  onClick={() => handleEditClick(category.id)}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#AddNewCategory">
                                                  <span className="dropdown-item flex-items cursor-pointer">
                                                    <i className="fas fa-edit w-6"></i>
                                                    <span>Edit</span>
                                                  </span>
                                                </li>
                                                <li>
                                                  <span
                                                    onClick={() => {
                                                      setCategoryId({
                                                        id: category.id,
                                                        name: category.category
                                                      }),
                                                        setTabFlag(0);
                                                    }}
                                                    className="dropdown-item flex-items cursor-pointer text-red-500"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#DeleteCategoryModal">
                                                    <i className="fas fa-trash w-6"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              ) : (
                                <tr>
                                  <td>No Data Found</td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                        <Pagination
                          currentPage={paginationMeta.pageNumber}
                          totalCount={paginationMeta.totalCount}
                          pageSize={paginationMeta.pageSize}
                          onPageChange={(page: any) => handlePageChange(page)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <VideoTags setSelectedTagId={setSelectedTagId} setTabFlag={setTabFlag} />
                <ContentTypes
                  setSelectedContentTypeId={setSelectedContentTypeId}
                  setTabFlag={setTabFlag}
                />
                <Module setSelectedModuleId={setSelectedModuleId} setTabFlag={setTabFlag} />
                <AudienceProfiles
                  setSelectedAudienceProfileId={setSelectedAudienceProfileId}
                  setTabFlag={setTabFlag}
                />
              </>
            }
          </div>
        </div>

        {/* Modals  */}
        <AddNewCategory categoryAddFlag={categoryAddFlag} />
        <DeleteCategoryModal categoryId={categoryId.id} />
      </div>
      <ViewAllVideos
        categoryId={categoryId}
        selectedTagId={selectedTagId}
        selectedContentTypeId={selectedContentTypeId}
        selectedModuleId={selectedModuleId}
        tabFlag={tabFlag}
      />
    </>
  );
};

export default VideoCategories;
