import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useParams } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
interface IProps {
  setOpenPoll: (value: boolean) => void;
  openPoll: boolean;
  setOpenLiveEvent: (value: boolean) => void;
  openLiveEvent: boolean;
  setOpenLiveViewer: (value: boolean) => void;
  openLiveViewer: boolean;
  setOpenchat: (value: boolean) => void;
  openchat: boolean;
  setOpenQuestion: (value: boolean) => void;
  openQuestion: boolean;
}

const RightSidenav: React.FC<IProps> = ({
  setOpenPoll,
  openPoll,
  setOpenLiveEvent,
  openLiveEvent,
  setOpenLiveViewer,
  openLiveViewer,
  setOpenchat,
  openchat,
  setOpenQuestion,
  openQuestion
}) => {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const handleSidebarClick = (value: string) => {
    if (value === 'liveEvent') {
      setOpenLiveEvent(!openLiveEvent);
      setOpenPoll(false);
      setOpenLiveViewer(false);
      setOpenchat(false);
      setOpenQuestion(false);
    } else if (value === 'polls') {
      setOpenPoll(!openPoll);
      setOpenLiveEvent(false);
      setOpenLiveViewer(false);
      setOpenchat(false);
      setOpenQuestion(false);
    } else if (value === 'liveViewer') {
      setOpenLiveViewer(!openLiveViewer);
      setOpenPoll(false);
      setOpenLiveEvent(false);
      setOpenchat(false);
      setOpenQuestion(false);
    } else if (value === 'chat') {
      setOpenchat(!openchat);
      setOpenPoll(false);
      setOpenLiveViewer(false);
      setOpenLiveEvent(false);
      setOpenQuestion(false);
    } else if (value === 'question') {
      setOpenQuestion(!openQuestion);
      setOpenLiveEvent(false);
      setOpenPoll(false);
      setOpenLiveViewer(false);
      setOpenchat(false);
      if (id) {
        videoAction.getQuestionRequest({ videoId: id, questionType: 0 });
      }
    }
  };

  return (
    <div className="right-side-nav-wrapper">
      <ul className="nav-wrapper px-2">
        <li className="my-3 item-wrapper">
          <span
            onClick={() => handleSidebarClick('liveEvent')}
            className="p-0 d-flex justify-content-center">
            <i className="fa fa-info-circle text-black item-icon" id="Live-Stream-URLs"></i>
          </span>
        </li>
        <li className="my-3 item-wrapper">
          <span
            onClick={() => handleSidebarClick('liveViewer')}
            className="p-0 d-flex justify-content-center">
            <i className="fa-solid fa-chart-line text-black item-icon" id="Live-Viewers"></i>
          </span>
        </li>
        <li className="my-3 item-wrapper">
          <span
            onClick={() => handleSidebarClick('chat')}
            className="p-0 d-flex justify-content-center">
            <i className="fa-regular fa-comments text-black item-icon" id="Chat"></i>
          </span>
        </li>
        <li className="my-3 item-wrapper">
          <span
            onClick={() => handleSidebarClick('polls')}
            className="p-0 d-flex justify-content-center">
            <i className="fa-solid fa-chart-column text-black item-icon" id="Polls"></i>
          </span>
        </li>
        <li className="my-3 item-wrapper">
          <span
            onClick={() => handleSidebarClick('question')}
            className="p-0 d-flex justify-content-center">
            <i className="fa-solid fa-comment text-black item-icon" id="Viewers-Questions"></i>
          </span>
        </li>
        <li className="my-3 item-wrapper">
          <a
            rel="noreferrer"
            className="p-0 d-flex justify-content-center"
            href="https://support.webcastcloud.com/hc/en-us/sections/4406435678351-Live-Streaming"
            target="_blank">
            <i className="fa-regular fa-circle-question text-black item-icon" id="Help"></i>
          </a>
        </li>
      </ul>
      <ReactTooltip anchorId="Live-Stream-URLs" place="top" content="Live Stream URLs" />
      <ReactTooltip anchorId="Live-Viewers" place="top" content="Live Viewers" />
      <ReactTooltip anchorId="Chat" place="top" content="Chat" />
      <ReactTooltip anchorId="Polls" place="top" content="Polls" />
      <ReactTooltip anchorId="Viewers-Questions" place="top" content="Questions" />
      <ReactTooltip anchorId="Help" place="top" content="Help" />
    </div>
  );
};
export default RightSidenav;
