import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
const Fonts = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const { fonts } = useSelector((state: RootState) => {
    return {
      fonts: state.appearance.fonts
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    if (fonts) {
      reset(fonts);
    }
  }, [fonts]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'fontLoaderCode', value: data.fontLoaderCode },
      { op: 'replace', path: 'fontFamilyHeading', value: data.fontFamilyHeading },
      { op: 'replace', path: 'fontFamilyBody', value: data.fontFamilyBody }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  return (
    <div className="tab-pane fade" id="tabs-fonts" role="tabpanel">
      <form onSubmit={handleSubmit(onSubmit)} className="p-5">
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        <div className="accordion-body py-4 px-5">
          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0">
              <div>
                <h6 className="font-bold mb-1">Custom Font Loader Code</h6>
                <p>
                  To use custom fonts such as Google font or Adobe Typekey, copy the code from the
                  font library provider.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <textarea
                  {...register('fontLoaderCode')}
                  className="input-style"
                  rows={4}
                  cols={50}></textarea>
              </div>
            </div>
          </div>
          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0">
              <div>
                <h6 className="font-bold mb-1">Heading Font Family CSS</h6>
                <p>Add the css rules to specify the heading font families from the font library.</p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'fontFamilyHeading'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
            </div>
          </div>
          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0">
              <div>
                <h6 className="font-bold mb-1">Body Font Family CSS</h6>
                <p>Add the rules to specify the body font families from the font library.</p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'fontFamilyBody'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Fonts;
