import React, { useEffect, useState } from 'react';
import { useVideosActions, VideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { DownloadQuestionModel } from 'src/models/videosModel';
interface IProps {
  openQuestion: boolean;
}
const ViewerQuestionModal: React.FC<IProps> = ({ openQuestion }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const [tab, setTab] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const [downloadData, setDownloadData] = useState<Array<DownloadQuestionModel>>();
  const { questions } = useSelector((state: RootState) => {
    return {
      questions: state.videos.questions
    };
  });

  useEffect(() => {
    if (id) {
      if (selectedValue) {
        const timer = setTimeout(() => {
          id && videoAction.getQuestionRequest({ videoId: id, questionType: tab });
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        videoAction.getQuestionRequest({ videoId: id, questionType: tab });
      }
    }
  }, [id, selectedValue]);

  useEffect(() => {
    if ((tab === 1 || tab === 2) && openQuestion) {
      const timer = setInterval(() => {
        id && videoAction.getQuestionRequest({ videoId: id, questionType: tab });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [tab, openQuestion]);

  useEffect(() => {
    if (questions && questions.length) {
      const csvData =
        questions &&
        questions.map((questions) => {
          return {
            videoId: questions.videoId,
            usernameInput: questions.usernameInput,
            userEmailInput: questions.userEmailInput,
            message: questions.message,
            questiontype: questions.questiontype
          };
        });
      if (csvData) {
        setDownloadData(csvData);
      }
    }
  }, [questions]);

  const handleArchiveClick = (questionId: string, selectedValue: number) => {
    setSelectedValue(selectedValue);
    const payloadData = {
      questionId: questionId,
      updateData: selectedValue
    };
    videoAction.updateQuestionRequest(payloadData);
  };
  const handleRefreshClick = () => {
    id && videoAction.getQuestionRequest({ videoId: id, questionType: tab });
  };
  return (
    <>
      <div className={`w-2/6 ${openQuestion ? '' : 'hidden'} relative`}>
        <div className="modal-content h-full poll-popup-max overflow-scroll live-studio-border">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md mt-4">
            <i className="fa fa-2x fas fa-comment live-studio-heading"></i>
            <h5
              className="text-xl font-semibold leading-normal text-center w-full live-studio-heading"
              id="PollModalLabel">
              Questions
            </h5>
            <i className="fas fa-external-link-alt live-studio-heading"></i>
          </div>
          <div className="flex w-full justify-end mb-2 pr-3 ">
            <i
              onClick={() => handleRefreshClick()}
              className="fa fa-refresh pull-left cursor-pointer"></i>
          </div>

          <ul
            id="tabs-tab"
            role="tablist"
            className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <li
              className="me-2 cursor-pointer"
              onClick={() => {
                setTab(0), id && videoAction.getQuestionRequest({ videoId: id, questionType: 0 });
              }}>
              <span
                className={`${
                  tab === 0 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                New
              </span>
            </li>
            <li
              className="me-2 cursor-pointer"
              onClick={() => {
                setTab(1), id && videoAction.getQuestionRequest({ videoId: id, questionType: 1 });
              }}>
              <span
                className={`${
                  tab === 1 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                Approved
              </span>
            </li>
            <li
              className="me-2 cursor-pointer"
              onClick={() => {
                setTab(2), id && videoAction.getQuestionRequest({ videoId: id, questionType: 2 });
              }}>
              <span
                className={`${
                  tab === 2 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                Archived
              </span>
            </li>
          </ul>

          <div>
            <div>
              <div className="flex justify-center mt-4">
                <div className="modal-body relative py-4 px-10">
                  {questions && questions.length ? (
                    <>
                      {questions &&
                        questions.map((item) => {
                          return (
                            <div key={item.id}>
                              <div className="flex justify-between">
                                <strong>{item.usernameInput}</strong>
                                <div className="text-left">
                                  <div className="dropdown relative">
                                    <button
                                      className="dropdown-toggle"
                                      type="button"
                                      id="action-dropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <img
                                        src="../../assets/img/dots-vertical.svg"
                                        className="w-5 h-5"
                                      />
                                    </button>
                                    <ul
                                      className="dropdown-menu dropdown-menu-ul"
                                      aria-labelledby="action-dropdown">
                                      <li onClick={() => handleArchiveClick(item.id, 1)}>
                                        <button className="action-menu flex items-center">
                                          <span>Approved</span>
                                        </button>
                                      </li>
                                      <li onClick={() => handleArchiveClick(item.id, 2)}>
                                        <button className="action-menu flex items-center">
                                          <span>Archived</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="text-blue-600">{item.userEmailInput}</div>
                              <p>{item.message}</p>

                              <small className="">{item.createdUTC}</small>

                              <hr className="mt-3" />
                            </div>
                          );
                        })}
                      <div className="flex justify-end mt-2">
                        {downloadData && (
                          <CSVLink
                            className="export mt-1"
                            data={downloadData}
                            filename={'my-file.csv'}>
                            <button
                              type="button"
                              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                              Download CSV
                            </button>
                          </CSVLink>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="p-4 text-center">
                      <p>No Questions</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewerQuestionModal;
