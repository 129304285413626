import React, { useEffect, useState } from 'react';
import Loader from 'src/components/loader';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { convertToSingleLevel } from 'src/components/utilities/utils';
import { useVideosActions } from 'src/store/videos/actions';
import VideoSearch from '../components/VideoSearch';
import { successMsg } from 'src/components/utilities/utils';
type locationState = {
  channelName: string;
  subChannelData: [];
};
const ChannelList = ({
  handleChannelDeleteClick,
  channelData,
  handleFilterClick,
  setFilterData,
  showFilter
}: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const channelAction = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const [channelNames, setChannelNames] = useState<string>();
  const { channelName, subChannelData } = (location.state as locationState) || {};
  const [clipboard, setClipboard] = useState<boolean>(false);
  const handleEditChannelClick = (id: string) => {
    if (id) {
      channelAction.setChannelIdRequest(id);
      channelAction.getSingleChannelRequest(id);
      channelAction.resetAddNewChannelFlagRequest();
    }
  };

  const { channels, isChannelLoading, channelId } = useSelector((state: RootState) => {
    return {
      channels: state.channel.channels,
      isChannelLoading: state.channel.isChannelLoading,
      channelId: state.channel.channelId
    };
  });

  const handleChannelDoubleClick = (currentChannel: any) => {
    navigate(`/channels/${currentChannel.id}`, {
      state: { channelName: currentChannel.title, subChannelData: currentChannel.children }
    });
    channelAction.setChannelIdRequest(currentChannel.id);
    if (currentChannel.subChannels !== null) {
      channelAction.getSubChannelRequest(currentChannel.subChannels);
    } else {
      channelAction.getSubChannelRequest([]);
    }
  };
  const handlePreviewClick = () => {
    const URL = '/channels';
    window.open(URL, '_blank');
  };

  const handleGoBackClick = () => {
    navigate('/channels');
    if (channels) {
      channelAction.getSubChannelRequest(channels.filter((channel) => channel.parentid === null));
    }
  };
  useEffect(() => {
    if (subChannelData) {
      channelAction.getSubChannelRequest(subChannelData);
    }
  }, [id]);

  useEffect(() => {
    if (channels && id) {
      const singleLevelChannel = convertToSingleLevel(channels);
      const channelName = singleLevelChannel && singleLevelChannel.filter((item) => item.id === id);
      channelName && channelName.length && setChannelNames(channelName[0].title);
    }
  }, [channels, id]);

  const handleCopyChannelLink = (channelId: string) => {
    const origin = window.location.origin;
    const finalUrl = origin + `/channels/${channelId}`;
    navigator.clipboard.writeText(finalUrl);
    setClipboard(true);
  };

  useEffect(() => {
    if (clipboard) {
      successMsg('Copied To Clipboard');
      setClipboard(false);
    }
  }, [clipboard]);

  return (
    <>
      <div className="flex justify-between">
        {id && (
          <span
            id="badge-dismiss-dark"
            className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800 mb-5">
            {channelNames}
            <button
              onClick={handleGoBackClick}
              type="button"
              className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-300 dark:hover:text-gray-900"
              data-dismiss-target="#badge-dismiss-dark"
              aria-label="Remove">
              <i className="fa fa-close"></i>
              <span className="sr-only">Remove badge</span>
            </button>
          </span>
        )}
        {id && (
          <>
            <div className="whitespace-nowrap">
              <button
                type="button"
                className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
                onClick={handleFilterClick}>
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </>
        )}
      </div>
      <VideoSearch setFilterData={setFilterData} showFilter={showFilter} />
      {channelData && channelData.length > 0 ? (
        <>
          <h6 className="font-bold mb-4">Channels</h6>

          <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
            {channelData?.map((channel: any, index: any) => {
              return (
                <div
                  key={index}
                  className="box shadow rounded-md video-card relative overflow-hidden =s">
                  <div className="" onDoubleClick={() => handleChannelDoubleClick(channel)}>
                    <div className="bg-gray-100 h-44 flex-items justify-center">
                      <img src={channel.channelimageurl} className="w-full h-full object-cover" />
                    </div>
                    <div className="px-4 py-2">
                      <div className="flex justify-between">
                        <h6 className="font-semibold box-text-main mr-3 hover:text-gray-900 cursor-pointer">
                          {channel.title}
                        </h6>
                        <span className="whitespace-nowrap hidden">50.34 MB</span>
                      </div>
                    </div>
                  </div>

                  {/* Dropdown */}
                  <div className="absolute top-3 right-3">
                    <div className="flex justify-center">
                      <div>
                        <div className="dropdown relative">
                          <a
                            className="
                          dropdown-toggle
                          transition
                          duration-150
                          ease-in-out"
                            href="#"
                            type="button"
                            id="ActionButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                          <ul
                            className="dropdown-menu action-dropdown"
                            aria-labelledby="ActionButton">
                            <li onClick={() => handleCopyChannelLink(channel.id)}>
                              <span className="dropdown-item flex-items cursor-pointer">
                                <i className="fa fa-link w-6"></i>
                                <span>Copy</span>
                              </span>
                            </li>

                            <li
                              onClick={() => handleEditChannelClick(channel.id)}
                              data-bs-toggle="modal"
                              data-bs-target="#SettingsModal">
                              <span className="dropdown-item flex-items cursor-pointer">
                                <i className="fas fa-edit w-6"></i>
                                <span>Quick Edit</span>
                              </span>
                            </li>
                            <li onClick={() => handleChannelDeleteClick(channel.id)}>
                              <span
                                className="dropdown-item flex-items cursor-pointer text-red-500"
                                data-bs-toggle="modal"
                                data-bs-target="#DeleteChannelModal">
                                <i className="fas fa-trash w-6"></i>
                                <span>Delete</span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <h6 className="font-bold mb-4">Channels</h6>
          <h1>No Channels Found</h1>
        </>
      )}
    </>
  );
};

export default ChannelList;
