import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useCourseActions } from 'src/store/course/actions';
import { CourseModel, CourseModelRequest, PatchJsonData, PatchJsonModel } from 'src/models/courseModel';
import Editor from 'src/components/Editor';
import { useChannelActions } from 'src/store/channel/actions';
import UploadCourseModal from '../components/UploadCourseModal';
import AddCourseModule from '../components/AddModuleModal';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import Loader from 'src/components/loader';
import makeAnimated from 'react-select/animated';
import { uploadImageToS3 } from 'src/components/utilities/utils';

const AddCourse = () => {
  //Input Parameter
  const { id } = useParams<string>();
  //Dispatches
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Actions
  const courseAction = useCourseActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  //State variables
  const [editorState, setEditorState] = useState<string>('');
  const [finalCourseModules, setFinalCourseModules] = useState<any>();
  const [finalCourseCreditTypes, setFinalCourseCreditTypes] = useState<any>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [fileUpload, setFileUpload] = useState<any>();
  const InputFile = useRef<any>();
  const animatedComponents = makeAnimated();
  const [selectedModuleOptions, setSelectedModuleOptions] = useState<any>();
  const [selectedCreditTypeOptions, setSelectedCreditTypeOptions] = useState<any>();
  const [courseThumbnailUrl, setCourseThumbnailUrl] = useState();
  //const btnRef = useRef<HTMLButtonElement>(null);

  //Getting values from RootState
  const {
    channels,
    singleCourse,
    originalCourseInfo,
    isCourseAdded,
    isCourseUpdated,
    loadingDetails,
    finalChannels,
    courseModules,
    courseCreditTypes,
    isCourseModuleAdded,
    message,
    newCourseId,
  } = useSelector((state: RootState) => {
    return {
      channels: state.channel.channels,
      singleCourse: state.course.singleCourse,
      originalCourseInfo: state.course.singleCourse,
      isCourseAdded: state.course.isCourseSaved,
      isCourseUpdated: state.course.isCourseUpdated,
      loadingDetails: false,
      finalChannels: state.channel.flattenedChannels,
      courseModules: state.course.courseModule,
      courseCreditTypes: state.course.courseCreditType,
      isCourseModuleAdded: state.course.isCourseModuleAdded,
      message: state.course.message,
      newCourseId: state.course.savedCourseId,
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<CourseModel>();

  const type = [
    { value: 0, label: 'Select' },
    { value: 1, label: 'Channel' },
  ]

  const status = [
    { value: 0, label: 'Select' },
    { value: 1, label: 'Draft' },
    { value: 2, label: 'Online' },
    { value: 3, label: 'Offline' },
  ]

  const completionType = [
    { value: 1, label: 'Quiz Completion' },
    { value: 2, label: 'Video Completion' },
  ]

  const contentTypes = [
    { value: '0', label: 'Select' },
    { value: '329d63e7-3329-4ea0-8489-5a679fc565cc', label: 'Conference' },
    { value: 'cba0701c-cca3-44ce-8c1f-d60cb47705e1', label: 'Video OnDemand' },
  ]

  const priceSource = [
    { value: 1, label: 'inherit From Channel' },
  ]

  const handleBackClick = () => {
    navigate('/courses/course');
  };
  const onFileUpload = (file: any) => {
    setFileUpload(file);
  };

  const handleSelectModules = (data: any) => {
    setSelectedModuleOptions(data);
  };

  const handleSelectCreditTypes = (data: any) => {
    setSelectedCreditTypeOptions(data);
  };

  const onSubmit = (data: CourseModel) => {
    const selectedCompletionType = completionType.filter(i => i.value == data.courseCompletionType).map(filteredType => filteredType.value)[0];
    const selectedModules = selectedModuleOptions.map((filteredType: { value: any; }) => filteredType.value);

    if (id === undefined) {
      const finalData: CourseModelRequest = {
        title: data.title,
        type: data.type,
        channelId: data.channelId,
        status: data.status,
        isPrivate: true,
        isHiddenCourse: true,
        thumbnailUrl: "",
        thumbnailUrlS3: "",
        description: editorState,
        points: data.points,
        inheritPrice: true,
        price: data.price ? data.price : 0,
        courseCompletionType: selectedCompletionType,
        courseCompletionPercentage: data.courseCompletionPercentage,
        displayCourseProgress: data.displayCourseProgress,
        showCourseCompletion: data.showCourseCompletion,
        externalLink: data.externalLink,
        courseCompletionMessage: data.courseCompletionMessage,
        modules: selectedModules,
        creditTypes: ["CPD"],
        mustCompleteVideos: data.mustCompleteVideos
      };
      console.log(finalData);
      courseAction.saveCourseRequest(finalData);
    }
    else if (id) {
      console.log('edit');
      if (fileUpload) {
        uploadImageToS3(
          fileUpload[0],
          `v2/courses/upload/thumbnail/${id}?`,
          setCourseThumbnailUrl
        );
        setFileUpload(null);
      }

      const patchJsonModel: PatchJsonModel = {
        id: data.id,
        patchJsonData: convertModelToPatch(data, selectedModules)
      }
      if (patchJsonModel.patchJsonData.length > 0)
        courseAction.updateCourseRequest(patchJsonModel);
    }
    // if (btnRef.current) {
    //   btnRef.current.click();
    // }
  };

  const convertModelToPatch = (data: any, selectedModules: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = originalCourseInfo;
      if (value[field] == data[field])
        continue;
      if (field === 'creditTypes')
        continue;
      if (field === 'modules') {
        const patchObjModule: PatchJsonData = {
          from: '',
          value: selectedModules,
          path: '/' + field,
          op: 'replace'
        };
        patchData.push(patchObjModule);
      }
      else {
        const patchObj: PatchJsonData = {
          from: value[field],
          value: data[field],
          path: '/' + field,
          op: 'replace'
        };
        patchData.push(patchObj);
      }
    }
    return patchData;
  };

  useEffect(() => {
    if (isCourseAdded) {
      if (fileUpload) {
        uploadImageToS3(
          fileUpload[0],
          `v2/courses/upload/thumbnail/${newCourseId}?`,
          setCourseThumbnailUrl
        );
        setFileUpload(null);
      }
    }
  }, [isCourseAdded]);

  useEffect(() => {
    if (isCourseAdded || isCourseUpdated) {
      // if (CloseButtonRef.current) {
      //   CloseButtonRef.current.click();
      // }
      successMsg(message);
      //handleCloseClick();
    }
  }, [isCourseAdded, isCourseUpdated]);

  useEffect(() => {
    if (!courseModules || courseModules.length == 0) {
      courseAction.getCourseModuleRequest();
    }
    if (!courseCreditTypes || courseCreditTypes.length == 0) {
      courseAction.getCourseCreditTypeRequest();
    }
    if (!channels || channels.length == 0) {
      channelAction.getChannelRequest();
    }
  }, []);

  useEffect(() => {
    if (typeof id != 'undefined') {
      courseAction.getSingleCourseRequest(id);
    }
  }, [isCourseAdded, id]);

  useEffect(() => {
    if (!courseModules || courseModules.length == 0) {
      courseAction.getCourseModuleRequest();
    }
  }, [isCourseModuleAdded])

  useEffect(() => {
    channelAction.getFlattenedChannel();
  }, [channels]);

  useEffect(() => {
    if (courseModules) {
      const finalCourseModules = courseModules.map((module) => {
        return { value: module.id, label: module.module };
      });
      setFinalCourseModules(finalCourseModules);
    }
  }, [courseModules]);

  useEffect(() => {
    if (isCourseAdded === true) {
      reset({
        title: '',
        type: 0,
        channelId: '',
        channelName: '',
        status: 0,
        points: 0,
        price: 0,
        courseCompletionType: 0,
        courseCompletionPercentage: 0,
        displayCourseProgress: false,
        showCourseCompletion: false,
        courseCompletionMessage: '',
        externalLink: '',
        mustCompleteVideos: false
      });
      setSelectedCreditTypeOptions([]);
      setSelectedModuleOptions([]);
      setEditorState('');
      // if (btnRef.current) {
      //   btnRef.current.click();
      // }
    }
  }, [isCourseAdded]);

  useEffect(() => {
    if (id?.length == 0) {
      reset({
        title: '',
        type: 0,
        channelId: '',
        channelName: '',
        status: 0,
        points: 0,
        price: 0,
        courseCompletionType: 0,
        courseCompletionPercentage: 0,
        displayCourseProgress: false,
        showCourseCompletion: false,
        courseCompletionMessage: '',
        externalLink: '',
        mustCompleteVideos: false
      });
      setEditorState('');
      setSelectedCreditTypeOptions([]);
      setSelectedModuleOptions([]);
    }
  }, []);

  useEffect(() => {
    if (singleCourse) {
      reset(singleCourse);
      setEditorState(singleCourse.description);
    }
  }, [singleCourse]);

  // const handleVideoThumbnailUpload = async () => {
  //   if (selectedFile) {
  //     uploadImageToS3(selectedFile[0], `v2/video/upload/thumbnail?videoId=${currentCourseId}`);
  //   }
  // };

  // const handleSelectNewClick = () => {
  //   InputFile.current.click();
  // };

  useEffect(() => {
    if (!singleCourse || !singleCourse.modules) {
      setSelectedModuleOptions([]);
      return;
    }
    const selectedModules = courseModules.filter(m => singleCourse.modules?.includes(m.id as never))
      .map((item) => ({
        value: item.id,
        label: item.module,
      }));
    setSelectedModuleOptions(selectedModules);

    if (!singleCourse || !singleCourse.creditTypes) {
      setSelectedCreditTypeOptions([]);
      return;
    }
    const selectedCreditTypes = courseCreditTypes.filter(m => singleCourse.creditTypes?.includes(m.id as never))
      .map((item) => ({
        value: item.id,
        label: item.type,
      }));
    setSelectedCreditTypeOptions(selectedCreditTypes);
  }, [singleCourse]);

  return (
    <>
      <ToastMessage />
      <div className="shadow p-5 border">
        <div className="mb-8">
          <h2 className="font-semibold my-1">Courses</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/courses/course">Courses</Link>
            </li>
            <li className="active">
              {id ? (<strong>Edit</strong>) : (<strong>Add</strong>)}
            </li>
          </ol>
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="xl:flex">
            {
              loadingDetails ? (
                <Loader />
              ) : (
                <>
                  {' '}
                  <form>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"><strong>Title</strong></label>
                        <input
                          type="text"
                          className="input-style"
                          placeholder="Enter Title"
                          id="title"
                          {...register('title', {
                            required: true,
                            maxLength: 100
                          })}
                        />
                        {errors.title?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.title?.type === 'maxLength' && <p className="text-red-700"> Exceeded the limit</p>}
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"><strong>Type</strong></label>
                        <select {...register("type")} style={{ width: "400px" }}>
                          {type && type.map((values: any, index: any) => (
                            <option value={values.value} key={values.name}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Channel</strong> </label>
                        <select {...register("channelId")}>
                          {finalChannels && finalChannels.map((values: any, index: any) => (
                            <option value={values.value} key={values.label + index}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Status</strong> </label>
                        <select {...register("status")} style={{ width: "400px" }}>
                          {status && status.map((values: any, index: any) => (
                            <option value={values.value} key={values.name}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Content Types</strong> </label>
                        <select {...register("contentTypes")} style={{ width: "400px" }}>
                          {contentTypes && contentTypes.map((values: any, index: any) => (
                            <option value={values.value} key={values.name}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Modules</strong> </label>
                        <div className=" flex items-center">
                          {courseModules && (
                            <Select
                              options={finalCourseModules}
                              components={animatedComponents}
                              value={selectedModuleOptions}
                              className="w-full mr-2"
                              id="modules"
                              isMulti
                              onChange={handleSelectModules}
                              isSearchable={true}
                              isLoading={courseModules.length ? false : true}
                            />
                          )}
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#addModulesModal"
                            className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                            <i className="fas fa-plus pr-1"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group mb-4`} style={{ marginLeft: "100px" }}>
                        <label className="block mb-1 font-semibold"> <strong>Thumbnail</strong> </label>
                        <img src={`${singleCourse.thumbnailUrl}`} alt='No Image' />
                        <div className="flex flex-wrap items-center py-4 rounded-b-md">
                          <div className="video-upload-btn mr-2">
                            <input
                              ref={InputFile}
                              type="file"
                              className="hidden"
                              onChange={(e) => setSelectedFile(e.target.files)}
                            />
                            {/* <label
                              data-bs-toggle="modal"
                              data-bs-target="#selectThumbnailModal"
                              className="btn-primary cursor-pointer mx-0 mt-0">
                              Select New
                            </label> */}
                          </div>
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#uploadCourseModal"
                            type="button"
                            className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                          // className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 mr-2"
                          >
                            Browse
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 form-switch">
                        <div className={`form-group`} style={{ width: "400px" }}>
                          <label className="block mb-1"> <strong>Display Course Progress</strong> </label>
                          <input
                            type="checkbox"
                            className="form-check-input switch-btn"
                            id="displayCourseProgress"
                            {...register('displayCourseProgress')}
                          />
                        </div>
                        <div className={`form-group`}>
                          <label className="block mb-1"> <strong>Show Course Completion</strong> </label>
                          <input
                            type="checkbox"
                            className="form-check-input switch-btn"
                            id="showCourseCompletion"
                            role="switch"
                            {...register('showCourseCompletion')}
                          />
                        </div>
                        <div className={`form-group`} style={{ width: "400px" }}>
                          <label className="block mb-1"> <strong>Must complete each Video before Progressing</strong> </label>
                          <input
                            type="checkbox"
                            className="form-check-input switch-btn"
                            id="mustCompleteVideos"
                            {...register('mustCompleteVideos')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Points</strong>  </label>
                        <input
                          type="number"
                          className="input-style"
                          placeholder="Enter Points"
                          id="points"
                          {...register('points', {
                            required: true,
                            pattern: /^[0-9.]/,
                            min: 0
                          })}
                        />
                        {errors.price?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.price?.type === 'pattern' && <p className="text-red-700"> Only numbers allowed</p>}
                        {errors.price?.type === 'min' && <p className="text-red-700"> Invalid number entered</p>}
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"><strong>Credit Types</strong></label>
                        {courseCreditTypes && (
                          <Select
                            options={finalCourseCreditTypes}
                            components={animatedComponents}
                            value={selectedCreditTypeOptions}
                            className="w-full mr-2"
                            id="creditTypes"
                            isMulti
                            onChange={handleSelectCreditTypes}
                            isSearchable={true}
                            isLoading={courseCreditTypes.length ? false : true}
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Price</strong> </label>
                        <input
                          type="number"
                          className="input-style"
                          readOnly
                          id="price"
                          {...register('price', {
                            pattern: /^[0-9.]/
                          })}
                        />
                        {errors.price?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.price?.type === 'pattern' && <p className="text-red-700"> Only numbers allowed</p>}
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong></strong> &nbsp;</label>
                        <select style={{ width: "400px" }} disabled>
                          {priceSource && priceSource.map((values: any, index: any) => (
                            <option value={values.value} key={values.name}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Completion Type</strong> </label>
                        <select {...register("courseCompletionType")} style={{ width: "400px" }}>
                          {completionType && completionType.map((values: any, index: any) => (
                            <option value={values.value} key={values.name}> {values.label} </option>
                          ))}
                        </select>
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Pass Rate</strong> </label>
                        <input
                          type="number"
                          style={{ width: "400px" }}
                          placeholder="Enter Pass Rate"
                          className="input-style"
                          id="courseCompletionPercentage"
                          {...register('courseCompletionPercentage', {
                            required: true,
                            pattern: /^[0-9.]/,
                            min: 0,
                            max: 100,
                          })}
                        />
                        {errors.courseCompletionPercentage?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.courseCompletionPercentage?.type === 'pattern' && <p className="text-red-700"> Only numbers allowed</p>}
                        {errors.courseCompletionPercentage?.type === 'min' && <p className="text-red-700"> Invalid Number</p>}
                        {errors.courseCompletionPercentage?.type === 'max' && <p className="text-red-700"> Numbers greater than 100 not allowed</p>}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>External Link</strong> </label>
                        <input
                          type="text"
                          className="input-style"
                          placeholder="Enter External Link"
                          id="externalLink"
                          {...register('externalLink', {
                            required: true,
                            maxLength: 500
                          })}
                        />
                        {errors.externalLink?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.externalLink?.type === 'maxLength' && <p className="text-red-700"> Exceeded the limit</p>}
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1"> <strong>Course Completion Message</strong> </label>
                        <input
                          type="text"
                          style={{ width: "400px" }}
                          className="input-style"
                          placeholder="Enter Course Completion Message"
                          id="courseCompletionMessage"
                          {...register('courseCompletionMessage', {
                            required: true,
                            maxLength: 250
                          })}
                        />
                        {errors.courseCompletionMessage?.type === 'required' && <p className="text-red-700"> Required Field</p>}
                        {errors.courseCompletionMessage?.type === 'maxLength' && <p className="text-red-700"> Exceeded the limit</p>}
                      </div>
                    </div>
                    <div className="p-5 pb-0">
                      <div className={`form-group mb-4`}>
                        <label className="block text-sm font-medium mb-1"><strong>Description</strong></label>
                        <Editor editorState={editorState} setEditorState={setEditorState} />
                      </div>
                    </div>
                    <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                      <button
                        type="submit"
                        className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                        onClick={handleSubmit(onSubmit)} >
                        Save
                      </button>
                      <button
                      onClick={handleBackClick}
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      {/* //ref={btnRef}> */}
                      Back
                    </button>
                    </div>
                  </form>
                </>
              )
            }
          </div>
          <UploadCourseModal onFileUpload={onFileUpload} />
          {/* <SelectNewThumbModal /> */}
        </div>
        <AddCourseModule />
      </div>
    </>
  );
};
export default AddCourse;