import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
ChartJS.register(...registerables);
const BarChart = ({ chartData, options, height }: any) => {
  return <Bar height={height} data={chartData} options={options} />;
};

export default BarChart;
