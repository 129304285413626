export const saveEventRequestActionType = 'SAVE_EVENT_REQUEST'
export const saveEventRequestSuccessActionType = 'SAVE_EVENT_REQUEST_SUCCESS'
export const saveEventRequestFailureActionType = 'SAVE_EVENT_REQUEST_FAILURE'

export const updateEventRequestActionType = 'UPDATE_EVENT_REQUEST'
export const updateEventRequestSuccessActionType = 'UPDATE_EVENT_REQUEST_SUCCESS'
export const updateEventRequestFailureActionType = 'UPDATE_EVENT_REQUEST_FAILURE'

export const getEventRequestActionType = 'GET_EVENT_REQUEST'
export const getEventRequestSuccessActionType = 'GET_EVENT_REQUEST_SUCCESS'
export const getEventRequestFailureActionType = 'GET_EVENT_REQUEST_FAILURE'

export const getSingleEventRequestActionType = 'GET_SINGLE_EVENT_REQUEST'
export const getSingleEventRequestSuccessActionType = 'GET_SINGLE_EVENT_REQUEST_SUCCESS'
export const getSingleEventRequestFailureActionType = 'GET_SINGLE_EVENT_REQUEST_FAILURE'

export const getEmailSettingRequestActionType = 'GET_EMAIL_SETTING_REQUEST'
export const getEmailSettingRequestSuccessActionType = 'GET_EMAIL_SETTING_REQUEST_SUCCESS'
export const getEmailSettingRequestFailureActionType = 'GET_EMAIL_SETTING_REQUEST_FAILURE'

export const updateEmailSettingRequestActionType = 'UPDATE_EMAIL_SETTING_REQUEST'
export const updateEmailSettingRequestSuccessActionType = 'UPDATE_EMAIL_SETTING_REQUEST_SUCCESS'
export const updateEmailSettingRequestFailureActionType = 'UPDATE_EMAIL_SETTING_REQUEST_FAILURE'

export const getEventLayoutRequestActionType = 'GET_EVENT_LAYOUT_REQUEST'
export const getEventLayoutRequestSuccessActionType = 'GET_EVENT_LAYOUT_REQUEST_SUCCESS'
export const getEventLayoutRequestFailureActionType = 'GET_EVENT_LAYOUT_REQUEST_FAILURE'

export const updateEventLayoutRequestActionType = 'UPDATE_EVENT_LAYOUT_REQUEST'
export const updateEventLayoutRequestSuccessActionType = 'UPDATE_EVENT_LAYOUT_REQUEST_SUCCESS'
export const updateEventLayoutRequestFailureActionType = 'UPDATE_EVENT_LAYOUT_REQUEST_FAILURE'

export const getEventRegistrationsRequestActionType = 'GET_EVENT_REGISTRATIONS_REQUEST'
export const getEventRegistrationsRequestSuccessActionType = 'GET_EVENT_REGISTRATIONS_REQUEST_SUCCESS'
export const getEventRegistrationsRequestFailureActionType = 'GET_EVENT_REGISTRATIONS_REQUEST_FAILURE'

export const deleteEventRegistrationsRequestActionType = 'DELETE_EVENT_REGISTRATIONS_REQUEST'
export const deleteEventRegistrationsRequestSuccessActionType = 'DELETE_EVENT_REGISTRATIONS_REQUEST_SUCCESS'
export const deleteEventRegistrationsRequestFailureActionType = 'DELETE_EVENT_REGISTRATIONS_REQUEST_FAILURE'

export const getRegistrationsDownloadRequestActionType = 'GET_REGISTRATIONS_DOWNLOAD_REQUEST'
export const getRegistrationsDownloadRequestSuccessActionType = 'GET_REGISTRATIONS_DOWNLOAD_REQUEST_SUCCESS'
export const getRegistrationsDownloadRequestFailureActionType = 'GET_REGISTRATIONS_DOWNLOAD_REQUEST_FAILURE'

export const getRegistrationFieldRequestActionType = 'GET_REGISTRATION_FIELD_REQUEST'
export const getRegistrationFieldRequestSuccessActionType = 'GET_REGISTRATION_FIELD_REQUEST_SUCCESS'
export const getRegistrationFieldRequestFailureActionType = 'GET_REGISTRATION_FIELD_REQUEST_FAILURE'

export const saveRegistrationFieldRequestActionType = 'SAVE_REGISTRATION_FIELD_REQUEST'
export const saveRegistrationFieldRequestSuccessActionType = 'SAVE_REGISTRATION_FIELD_REQUEST_SUCCESS'
export const saveRegistrationFieldRequestFailureActionType = 'SAVE_REGISTRATION_FIELD_REQUEST_FAILURE'

export const deleteRegistrationFieldRequestActionType = 'DELETE_REGISTRATION_FIELD_REQUEST'
export const deleteRegistrationFieldRequestSuccessActionType = 'DELETE_REGISTRATION_FIELD_REQUEST_SUCCESS'
export const deleteRegistrationFieldRequestFailureActionType = 'DELETE_REGISTRATION_FIELD_REQUEST_FAILURE'

export const updateIsRequiredRequestActionType = 'UPDATE_IS_REQUIRED_FIELD_REQUEST'
export const updateIsRequiredRequestSuccessActionType = 'UPDATE_IS_REQUIRED_FIELD_REQUEST_SUCCESS'
export const updateIsRequiredRequestFailureActionType = 'UPDATE_IS_REQUIRED_FIELD_REQUEST_FAILURE'

export const updateIsEnableRequestActionType = 'UPDATE_IS_ENABLE_FIELD_REQUEST'
export const updateIsEnableRequestSuccessActionType = 'UPDATE_IS_ENABLE_FIELD_REQUEST_SUCCESS'
export const updateIsEnableRequestFailureActionType = 'UPDATE_IS_ENABLE_FIELD_REQUEST_FAILURE'

export const updatePasscodeIsRequiredRequestActionType = 'UPDATE_PASSCODE_IS_REQUIRED_FIELD_REQUEST'
export const updatePasscodeIsRequiredRequestSuccessActionType = 'UPDATE_PASSCODE_IS_REQUIRED_FIELD_REQUEST_SUCCESS'
export const updatePasscodeIsRequiredRequestFailureActionType = 'UPDATE_PASSCODE_IS_REQUIRED_FIELD_REQUEST_FAILURE'

export const importRegistrationRequestActionType = 'IMPORT_REGISTRATION_REQUEST'
export const importRegistrationRequestSuccessActionType = 'IMPORT_REGISTRATION_REQUEST_SUCCESS'
export const importRegistrationRequestFailureActionType = 'IMPORT_REGISTRATION_REQUEST_FAILURE'

export const getEventVideoRequestActionType = 'GET_EVENT_VIDEO_REQUEST'
export const getEventVideoRequestSuccessActionType = 'GET_EVENT_VIDEO_REQUEST_SUCCESS'
export const getEventVideoRequestFailureActionType = 'GET_EVENT_VIDEO_REQUEST_FAILURE'

export const getEventReminderRequestActionType = 'GET_EVENT_REMINDER_REQUEST'
export const getEventReminderRequestSuccessActionType = 'GET_EVENT_REMINDER_REQUEST_SUCCESS'
export const getEventReminderRequestFailureActionType = 'GET_EVENT_REMINDER_REQUEST_FAILURE'

export const updateEventReminderRequestActionType = 'UPDATE_EVENT_REMINDER_REQUEST'
export const updateEventReminderRequestSuccessActionType = 'UPDATE_EVENT_REMINDER_REQUEST_SUCCESS'
export const updateEventReminderRequestFailureActionType = 'UPDATE_EVENT_REMINDER_REQUEST_FAILURE'

export const resetEventStateRequestActionType = 'RESET_EVENT_STATE_REQUEST'