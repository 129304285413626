import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { useParams } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
const UploadSlides = () => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [uploadThumbnail, setUploadThumbnail] = useState<string>('');
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const { id } = useParams<string>();
  const handleVideoThumbnailUpload = async (file: any) => {
    uploadImageToS3(
      file[0],
      `v2/video/upload/slide?videoId=${id}&slideId=1&`,
      setUploadThumbnail,
      'uploadSlide',
      setIsUploadToS3Loading,
      setIsUploadToS3Success,
      handleSaveDataToApi
    );
    setSelectedFile(file[0].name);
  };

  const handleSaveDataToApi = () => {
    id && videoAction.saveSlidesRequest({ id, uploadThumbnail });
  };
  return (
    <div>
      <div className="mb-8">
        <h2 className="font-semibold my-1">Video</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Video Library</Link>
          </li>
          <li className="active">
            <strong>Presentations</strong>
          </li>
          <li>
            <Link to="/">Manage</Link>
          </li>
          <li className="active">
            <strong>Upload Slides</strong>
          </li>
        </ol>
      </div>
      <div className="shadow bg-white my-5 p-4">
        <div className="mt-4">
          <form>
            <div className={`form-group mb-3`}>
              <div className="input-style p-0 speaker-browse-file flex items-center">
                <input id="uploadFile" disabled className="pl-2" placeholder={`${selectedFile}`} />
                <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                  <span>Select File</span>
                  {/* <input id="uploadBtn" type="file" className="upload" /> */}
                  <input
                    id="uploadBtn"
                    type="file"
                    className="upload"
                    onChange={(e) => handleVideoThumbnailUpload(e.target.files)}
                  />
                </div>
              </div>
            </div>

            <div>
              <button type="button" className="btn-primary w-auto mr-auto ml-0 discard-btn">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadSlides;
