import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import FormInputField from 'src/components/common/formInputField';
import { RootState } from 'src/store/reducers/state';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg } from 'src/components/utilities/utils';
import { successMsg } from 'src/components/utilities/utils';
const Brand = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const [logoWidth, setLogoWidth] = useState(0);
  const LogoInputFile = useRef<HTMLInputElement>(null);
  const FaviconInputFile = useRef<HTMLInputElement>(null);
  const [faviconImageName, setFaviconImageName] = useState<string>('');
  const [logoImageName, setLogoImageName] = useState<string>('');
  const [faviconImageUrl, setFaviconImageUrl] = useState<string>();
  const [logoImageUrl, setLogoImageUrl] = useState<string>();
  const [removeFaviconImage, setRemoveFaviconImage] = useState<boolean>(false);
  const [removeLogoImage, setRemoveLogoImage] = useState<boolean>(false);
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const { siteIdentity, isAppearanceUpdated } = useSelector((state: RootState) => {
    return {
      siteIdentity: state.appearance.siteIdentity,
      isAppearanceUpdated: state.appearance.isAppearanceUpdated
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();

  const handleRange = (e: any, formName: string) => {
    if (formName === 'logoWidth') {
      setLogoWidth(e.target.value);
    }
  };
  useEffect(() => {
    appearanceAction.getSiteIdentityRequest();
  }, []);

  useEffect(() => {
    if (siteIdentity) {
      reset(siteIdentity);
      setFaviconImageUrl(siteIdentity.faviconIconUrl);
      setLogoImageUrl(siteIdentity.logoUrl);
      setLogoWidth(siteIdentity.logoWidth);
    }
  }, [siteIdentity]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'siteTitle', value: data.siteTitle },
      { op: 'replace', path: 'logoLink', value: data.logoLink },
      { op: 'replace', path: 'logoWidth', value: logoWidth }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleSelectLogoClick = () => {
    if (LogoInputFile.current) {
      LogoInputFile.current.click();
    }
  };
  const handleSelectFaviconClick = () => {
    if (FaviconInputFile.current) {
      FaviconInputFile.current.click();
    }
  };
  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  const handleLogoImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setLogoImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/logo?`,
        setLogoImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadLogoImageToApi
      );
    }
  };

  const handleFaviconIconImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setFaviconImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/favicon?`,
        setFaviconImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadFaviconIconToApi
      );
    }
  };

  const uploadFaviconIconToApi = (faviconImage: string) => {
    const payloadData = [{ op: 'replace', path: 'faviconIconUrl', value: faviconImage }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const uploadLogoImageToApi = (logoImage: string) => {
    const payloadData = [{ op: 'replace', path: 'logoUrl', value: logoImage }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleRemoveLogoImage = () => {
    const payloadData = [{ op: 'replace', path: 'logoUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleRemoveFaviconImage = () => {
    const payloadData = [{ op: 'replace', path: 'faviconIconUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isAppearanceUpdated) {
      appearanceAction.getSiteIdentityRequest();
      appearanceAction.resetAppearanceStateRequest();
      setLogoImageName('');
      setRemoveLogoImage(false);
      setRemoveFaviconImage(false);
      setFaviconImageName('');
    }
  }, [isAppearanceUpdated]);
  return (
    <div className="tab-pane fade show active" id="tabs-videoLibrary" role="tabpanel">
      <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        {isUploadToS3Loading && <Loader />}
        <div className="accordion-body py-4 px-5">
          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0 pr-5">
              <div>
                <h6 className="font-bold mb-1">Set Title</h6>
                <p>
                  The title is the name of your site. That will appear in the site title & tagline.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'siteTitle'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
            </div>
          </div>

          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0 pr-5">
              <div>
                <h6 className="font-bold mb-1">Logo</h6>
                <p>Site logo that will appear on the main navigatin across your videos site.</p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <div className="video-upload-btn flex-items mt-1">
                  <div className="xl:w-8/12 lg:w-1/2">
                    <div>
                      {siteIdentity &&
                        siteIdentity.logoUrl?.length !== 0 &&
                        siteIdentity.logoUrl !== null && (
                          <>
                            <img
                              src={`${siteIdentity && siteIdentity.logoUrl}`}
                              className={` ${removeLogoImage ? 'hidden' : ''}`}
                            />
                          </>
                        )}
                      {logoImageUrl && (
                        <span
                          onClick={() => {
                            setLogoImageUrl(''),
                              setRemoveLogoImage(true),
                              handleRemoveLogoImage(),
                              setLogoImageName('');
                          }}
                          className="cursor-pointer site-clr">
                          Remove
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <div>
                        <div className="relative">
                          <label
                            onClick={handleSelectLogoClick}
                            className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                            Upload
                          </label>
                          <span className="ml-2">
                            {logoImageName ? logoImageName : 'No file chosen'}
                          </span>
                          <input
                            ref={LogoInputFile}
                            type="file"
                            className="hidden"
                            onChange={(e) => handleLogoImageUpload(e.target.files)}
                            onClick={onInputClick}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0 pr-5">
              <div>
                <h6 className="font-bold mb-1">Logo Width</h6>
                <p>Used to scale the site logo on the main navigation header.</p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div className="relative w-32">
                <input
                  type="range"
                  min={50}
                  max={650}
                  value={logoWidth}
                  id="logoWidth"
                  {...register('logoWidth', {
                    onChange: (e: any) => {
                      handleRange(e, 'logoWidth');
                    }
                  })}
                />
                <span>{logoWidth}</span>
                {/* <span
            style={{ left: `${logoWidth}%` }}
            className="range-slider text-center absolute text-white block">
            {logoWidth}
          </span> */}
              </div>
            </div>
          </div>

          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0 pr-5">
              <div>
                <h6 className="font-bold mb-1">Logo Link</h6>
                <p>
                  Set the hyperlink for the main header log. If no link is set then the default
                  homepage will be used.
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div>
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'logoLink'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
            </div>
          </div>

          <div className="lg:flex mb-8">
            <div className="lg:w-1/2 pb-0 pr-5">
              <div>
                <h6 className="font-bold mb-1">Favicon Icon</h6>
                <p>
                  Icon that is displayed next to the URL of the site in a browsers address bar.
                  (Recommended size 16X16px)
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 pb-0">
              <div className="video-upload-btn flex-items mt-1">
                <div className="xl:w-8/12 lg:w-1/2">
                  <div>
                    {siteIdentity &&
                      siteIdentity.faviconIconUrl?.length !== 0 &&
                      siteIdentity.faviconIconUrl !== null && (
                        <>
                          <img
                            src={`${siteIdentity && siteIdentity.faviconIconUrl}`}
                            className={`ChannelImageHeight ${removeFaviconImage ? 'hidden' : ''}`}
                          />
                        </>
                      )}
                    {faviconImageUrl && (
                      <span
                        onClick={() => {
                          setFaviconImageUrl(''),
                            handleRemoveFaviconImage(),
                            setRemoveFaviconImage(true),
                            setFaviconImageName('');
                        }}
                        className="cursor-pointer site-clr">
                        Remove
                      </span>
                    )}
                  </div>
                  <div className="flex items-center">
                    <div>
                      <div className="relative">
                        <label
                          onClick={handleSelectFaviconClick}
                          className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                          Upload
                        </label>
                        <span className="ml-2">
                          {faviconImageName ? faviconImageName : 'No file chosen'}
                        </span>
                        <input
                          ref={FaviconInputFile}
                          type="file"
                          className="hidden"
                          onChange={(e) => handleFaviconIconImageUpload(e.target.files)}
                          onClick={onInputClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Brand;
