import React from 'react';

const HlsComponent = () => {
  return (
    <div className="flex flex-col gap-2 live-event-main-h  flex-1">
      <div className="feature-div h-screen relative">
        <iframe
          src={`${process.env.REACT_APP_LIVE_MEET}/#/broadcast/test/test/host`}
          allow="camera;microphone;fullscreen; display-capture;"
          className="h-full w-full d-block"></iframe>
      </div>
    </div>
  );
};

export default HlsComponent;
