import React, { useRef, useEffect } from 'react';
import { useUserActions } from 'src/store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteDataModel } from 'src/models/usersModel';
import { RootState } from 'src/store/reducers/state';
interface IProps {
  deleteData: DeleteDataModel | undefined;
}
const DeleteMemberListModal: React.FC<IProps> = ({ deleteData }) => {
  const dispatch = useDispatch();
  const userActions = useUserActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const handleDeleteClick = () => {
    if (deleteData) {
      userActions.deleteMemberListRequest(deleteData.id);
    }
  };
  const { isMemberListDeleted } = useSelector((state: RootState) => {
    return {
      isMemberListDeleted: state.users.isMemberListDeleted
    };
  });
  useEffect(() => {
    if (isMemberListDeleted) {
      if (ref.current) {
        ref.current.click();
      }
    }
  }, [isMemberListDeleted]);
  return (
    <div>
      <div
        className="modal fade"
        id="DeleteMemberListModal"
        aria-labelledby="DeleteMemberListModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="DeleteMemberListModalLabel">
                Delete {deleteData && deleteData.name}
              </h5>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <div className={`form-group mb-3 text-center`}>
                  <p>
                    <strong>Are you sure you want to delete this?</strong>
                  </p>
                </div>
              </form>
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                onClick={handleDeleteClick}
                type="button"
                className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 borderonly-btn">
                Delete
              </button>
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteMemberListModal;
