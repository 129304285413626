import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg, successMsg } from 'src/components/utilities/utils';
import Editor from 'src/components/Editor';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
import FormTextareaField from 'src/components/common/formTextareaField';
const PlayPage = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const videoAction = useVideosActions(dispatch);
  const PlayerBackgroundInputFile = useRef<HTMLInputElement>(null);
  const LogoOverlayInputFile = useRef<HTMLInputElement>(null);
  const [removeLogoOverlayImage, setRemoveLogoOverlayImage] = useState<boolean>(false);
  const [removeBackgroundImage, setRemoveBackgroundImage] = useState<boolean>(false);
  const [backgroundImageName, setBackgroundImageName] = useState<string>('');
  const [logoOverlayImageUrl, setLogoOverlayImageUrl] = useState<string>();
  const [logoOverlayImageName, setLogoOverlayImageName] = useState<string>();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>();
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const [editorState, setEditorState] = useState<string>('');
  const { playPageVideo, playPageNavigation, playPageSetting, isAppearanceUpdated, mediaUrl } =
    useSelector((state: RootState) => {
      return {
        playPageVideo: state.appearance.playPageVideo,
        playPageNavigation: state.appearance.playPageNavigation,
        playPageSetting: state.appearance.playPageSetting,
        isAppearanceUpdated: state.appearance.isAppearanceUpdated,
        mediaUrl: state.videos.mediaUrl
      };
    });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm();

  useEffect(() => {
    if (playPageSetting && Object.keys(playPageSetting).length) {
      videoAction.getMediaUrlRequest(playPageSetting.playPageColumnHtml);
    }
  }, [playPageSetting]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl ? mediaUrl : '');
    }
  }, [mediaUrl]);
  useEffect(() => {
    if (playPageVideo && playPageNavigation && playPageSetting) {
      const playPageData = { ...playPageVideo, ...playPageNavigation, ...playPageSetting };
      reset(playPageData);
      setLogoOverlayImageUrl(playPageVideo.playerLogoOverlayUrl);
      setBackgroundImageUrl(playPageVideo.playerBackgroundImageUrl);
      setEditorState(playPageSetting.playPageColumnHtml ? playPageSetting.playPageColumnHtml : '');
    }
  }, [playPageVideo, playPageNavigation, playPageSetting]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'videoPlayerType', value: data.videoPlayerType },
      { op: 'replace', path: 'playerBackgroundColor', value: data.playerBackgroundColor },
      { op: 'replace', path: 'playerButtonColor', value: data.playerButtonColor },
      { op: 'replace', path: 'playerMediaControlBar', value: data.playerMediaControlBar },
      { op: 'replace', path: 'playerSettingsColor', value: data.playerSettingsColor },
      { op: 'replace', path: 'playerCustomcss', value: data.playerCustomcss },
      { op: 'replace', path: 'playerTheaterMode', value: data.playerTheaterMode },
      { op: 'replace', path: 'playerTheaterModeOn', value: data.playerTheaterModeOn },
      { op: 'replace', path: 'playPageRecommendations', value: data.playPageRecommendations },
      { op: 'replace', path: 'recommendationsLabel', value: data.recommendationsLabel },
      {
        op: 'replace',
        path: 'playPageColumnHtml',
        value: ImageStringConverter(editorState)
      },
      {
        op: 'replace',
        path: 'presenterDescriptionDisabled',
        value: data.presenterDescriptionDisabled
      },
      { op: 'replace', path: 'playlistButtonEnabled', value: data.playlistButtonEnabled },
      { op: 'replace', path: 'addtoPlaylistEnabled', value: data.addtoPlaylistEnabled },
      { op: 'replace', path: 'facebookShare', value: data.facebookShare },
      { op: 'replace', path: 'linkedInShare', value: data.linkedInShare },
      { op: 'replace', path: 'twitterShare', value: data.twitterShare },
      { op: 'replace', path: 'chaptersEnabled', value: data.chaptersEnabled },
      { op: 'replace', path: 'mainVcNavigation', value: data.mainVcNavigation },
      { op: 'replace', path: 'breadcrumbs', value: data.breadcrumbs },
      { op: 'replace', path: 'displayBackLink', value: data.displayBackLink }
    ];

    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const handleSelectBodyBackgroundClick = () => {
    if (PlayerBackgroundInputFile.current) {
      PlayerBackgroundInputFile.current.click();
    }
  };
  const handleSelectLogoOverlayClick = () => {
    if (LogoOverlayInputFile.current) {
      LogoOverlayInputFile.current.click();
    }
  };
  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  const handleBackgroundImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setBackgroundImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/playerbackground?`,
        setBackgroundImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadBackgroundImageToApi
      );
    }
  };

  const handleLogoOverlayImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setLogoOverlayImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/playerlogooverlay?`,
        setLogoOverlayImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadLogoOverlayImageToApi
      );
    }
  };

  const uploadLogoOverlayImageToApi = (logoOverlay: string) => {
    const payloadData = [{ op: 'replace', path: 'playerLogoOverlayUrl', value: logoOverlay }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  useEffect(() => {
    if (isAppearanceUpdated) {
      appearanceAction.getPlayPageVideoRequest();
      appearanceAction.getPlayPageSettingRequest();
      appearanceAction.resetAppearanceStateRequest();
      setBackgroundImageName('');
      setRemoveBackgroundImage(false);
      setLogoOverlayImageName('');
      setRemoveLogoOverlayImage(false);
    }
  }, [isAppearanceUpdated]);

  const uploadBackgroundImageToApi = (backgroundImage: string) => {
    const payloadData = [
      { op: 'replace', path: 'playerBackgroundImageUrl', value: backgroundImage }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const handleRemoveBackgroundImage = () => {
    const payloadData = [{ op: 'replace', path: 'playerBackgroundImageUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const handleRemoveLogoOverlayImage = () => {
    const payloadData = [{ op: 'replace', path: 'playerLogoOverlayUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);
  return (
    <div className="tab-pane fade" id="tabs-playPage" role="tabpanel">
      <div className="md:p-5 p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="accordion md:p-4" id="videoAccordion">
          <button type="submit" className="btn-primary w-auto mx-0 ml-auto mb-2">
            Save
          </button>
          {isUploadToS3Loading && <Loader />}
          <div className="accordion-item bg-white shadow border-0 mb-3">
            <h2 className="accordion-header mb-0" id="branding">
              <button
                className="accordion-button
              collapsed
              accordion-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne">
                Video Player
              </button>
            </h2>
            <div
              id="collapseOne"
              data-bs-parent="#videoAccordion"
              className="accordion-collapse border-0 collapse">
              <div className="accordion-body p-5">
                <div>
                  <div className="md:flex items-center mb-5">
                    <div className="md:w-1/3">
                      <label className="font-semibold mb-1">Player Mode</label>
                      <div className="description">
                        <p>
                          Select the default video player to be used site wide for your video
                          library. You may also update colors & style for each player template, as
                          these players can be selected on individual video. Although the selected
                          player here will be the default used on your videos.
                        </p>
                        <p></p>
                      </div>
                    </div>
                    <div className="md:w-2/3">
                      <select className="input-style" {...register('videoPlayerType')}>
                        <option value={1}>Default</option>
                        <option value={3}>Video Mode</option>
                        <option value={2}>Stage Mode</option>
                      </select>
                    </div>
                  </div>

                  {watch('videoPlayerType') != 1 && (
                    <>
                      <div className="mb-5">
                        <h2>Video Player Settings</h2>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Background Image</label>
                        </div>
                        <div className="md:w-2/3">
                          {playPageVideo &&
                            playPageVideo.playerBackgroundImageUrl?.length !== 0 &&
                            playPageVideo.playerBackgroundImageUrl !== null && (
                              <>
                                <img
                                  src={`${playPageVideo && playPageVideo.playerBackgroundImageUrl}`}
                                  className={` ${
                                    removeBackgroundImage ? 'hidden' : ''
                                  } w-60 h-full object-contain`}
                                />
                              </>
                            )}
                          {backgroundImageUrl && (
                            <span
                              className="cursor-pointer site-clr"
                              onClick={() => {
                                setBackgroundImageUrl('');
                                setBackgroundImageName('');
                                setRemoveBackgroundImage(true);
                                handleRemoveBackgroundImage();
                              }}>
                              Remove
                            </span>
                          )}
                          <div className="video-upload-btn flex-items">
                            <div>
                              <label
                                onClick={handleSelectBodyBackgroundClick}
                                className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                                Choose File
                              </label>
                              <span>
                                {backgroundImageName ? backgroundImageName : 'No file chosen'}
                              </span>
                            </div>
                            <input
                              ref={PlayerBackgroundInputFile}
                              type="file"
                              className="hidden"
                              onChange={(e) => handleBackgroundImageUpload(e.target.files)}
                              onClick={onInputClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Background Color</label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            placeholder="#000000"
                            type="color"
                            id="playerBackgroundColor"
                            {...register('playerBackgroundColor')}
                          />
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Button Color</label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            placeholder="#ffffff"
                            type="color"
                            id="playerButtonColor"
                            {...register('playerButtonColor')}
                          />
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Media Control Bar</label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            placeholder="#1a92ff"
                            type="color"
                            id="playerMediaControlBar"
                            {...register('playerMediaControlBar')}
                          />
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Video Player Setting Color</label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            placeholder="#ffffff"
                            type="color"
                            id="playerSettingsColor"
                            {...register('playerSettingsColor')}
                          />
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">
                            Logo Overlay
                            <br />
                            <small>Recommended Size: 150x120px</small>
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          {playPageVideo &&
                            playPageVideo.playerLogoOverlayUrl?.length !== 0 &&
                            playPageVideo.playerLogoOverlayUrl !== null && (
                              <>
                                <img
                                  src={`${playPageVideo && playPageVideo.playerLogoOverlayUrl}`}
                                  className={` ${
                                    removeLogoOverlayImage ? 'hidden' : ''
                                  }  w-60 h-full object-contain`}
                                />
                              </>
                            )}
                          {logoOverlayImageUrl && (
                            <span
                              className="cursor-pointer site-clr"
                              onClick={() => {
                                setLogoOverlayImageUrl('');
                                setRemoveLogoOverlayImage(true);
                                setLogoOverlayImageName('');
                                handleRemoveLogoOverlayImage();
                              }}>
                              Remove
                            </span>
                          )}
                          <div className="video-upload-btn flex-items">
                            <div>
                              <label
                                onClick={handleSelectLogoOverlayClick}
                                className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                                Choose File
                              </label>
                              <span>
                                {logoOverlayImageName ? logoOverlayImageName : 'No file chosen'}
                              </span>
                            </div>
                            <input
                              ref={LogoOverlayInputFile}
                              type="file"
                              className="hidden"
                              onChange={(e) => handleLogoOverlayImageUpload(e.target.files)}
                              onClick={onInputClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Custom Css</label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="input-style mr-5"
                            placeholder=""
                            type="text"
                            id="playerCustomcss"
                            {...register('playerCustomcss')}
                          />
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Theater Mode</label>
                        </div>
                        <div className="md:w-2/3">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="playerTheaterMode"
                                {...register('playerTheaterMode')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="md:flex items-center mb-5">
                        <div className="md:w-1/3">
                          <label className="font-semibold">Turn On Theatre Mode</label>
                        </div>
                        <div className="md:w-2/3">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="playerTheaterModeOn"
                                {...register('playerTheaterModeOn')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-white shadow border-0 my-3">
            <h2 className="accordion-header mb-0" id="headingTwo">
              <button
                className="accordion-button
              collapsed
              accordion-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo">
                Play Page Settings
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse border-0 collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#videoAccordion">
              <div className="accordion-body p-5">
                <div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Play Page Recommendations
                      </label>
                      <p>Display profile menu to the user.</p>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="playPageRecommendations"
                              {...register('playPageRecommendations')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Recommendations Label Text
                      </label>
                      <p>
                        Display custom recommendations heading. If blank, the heading on the page
                        will be: <i>More Like This.</i>
                      </p>
                    </div>
                    <div className="md:w-8/12">
                      <div className="md:mx-10 mx-5">
                        <input
                          type="text"
                          className="input-style"
                          id="recommendationsLabel"
                          {...register('recommendationsLabel')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Play Page CSS
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="md:mx-10 mx-5">
                        {/* <input
                          type="text"
                          className="input-style"
                          // id="playPageColumnHtml"
                          // {...register('playPageColumnHtml')}
                        /> */}
                        <FormTextareaField
                          label={''}
                          fieldName={'playPageCss'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-8 col-8 flex justify-between">
                    <label
                      className="form-check-label inline-block font-semibold switch-label mr-5"
                      htmlFor="Credit">
                      Play Page Ad Column
                    </label>
                    <div className="md:w-8/12">
                      <div className="md:mx-10 mx-5">
                        <Editor editorState={editorState} setEditorState={setEditorState} />
                      </div>
                    </div>
                  </div>

                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Presenter Description
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="presenterDescriptionDisabled"
                              {...register('presenterDescriptionDisabled')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Playlist Button
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="playlistButtonEnabled"
                              {...register('playlistButtonEnabled')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Add To Playlist
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="addtoPlaylistEnabled"
                              {...register('addtoPlaylistEnabled')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Facebook Share
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="facebookShare"
                              {...register('facebookShare')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        LinkedIn Share
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="linkedInShare"
                              {...register('linkedInShare')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Twitter Share
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="twitterShare"
                              {...register('twitterShare')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Display Chapters
                      </label>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="chaptersEnabled"
                              {...register('chaptersEnabled')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item bg-white shadow border-0 my-3">
            <h2 className="accordion-header mb-0" id="headingThree">
              <button
                className="accordion-button
              collapsed
              accordion-btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree">
                Navigation
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse border-0 collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#videoAccordion">
              <div className="accordion-body p-5">
                <div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Main Video Library Header
                      </label>
                      <p>
                        Will display the main video library header and navigation on all play pages.
                      </p>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="mainVcNavigation"
                              {...register('mainVcNavigation')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Breadcrumb Menu
                      </label>
                      <p>Will use breadcrumb navigation on your play page.</p>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="breadcrumbs"
                              {...register('breadcrumbs')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex items-center mb-8">
                    <div className="md:w-4/12">
                      <label
                        className="form-check-label inline-block font-semibold switch-label mr-5"
                        htmlFor="Credit">
                        Backlink Menu
                      </label>
                      <p>Will display Go Back on each play pages, instead of the breadcrumb menu</p>
                    </div>
                    <div className="md:w-8/12">
                      <div className="flex items-center">
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="displayBackLink"
                              {...register('displayBackLink')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PlayPage;
