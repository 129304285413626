import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const MultiTypeChart = ({ chartData, options, height = 30 }: any): JSX.Element => {
  return <Chart type="bar" data={chartData} />;
};

export default MultiTypeChart;
