import React, { useState, useRef, useEffect } from 'react';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { useCourseActions } from 'src/store/course/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
interface IProps {
  courseId: string | undefined;
}
const UploadThumbnail: React.FC<IProps> = ({ courseId }) => {
  const dispatch = useDispatch();
  const courseAction = useCourseActions(dispatch);
  const [uploadThumbnail, setUploadThumbnail] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<any>('');
  const ref = useRef<HTMLButtonElement>(null);
  const [isThumbnailUploadLoading, setIsThumbnailUploadLoading] = useState<boolean>();
  const [isThumbnailUploadSuccess, setIsThumbnailUploadSuccess] = useState<string>('');
  const { isCourseUpdated } = useSelector((state: RootState) => {
    return {
      isCourseUpdated: state.course.isCourseUpdated
    };
  });

  const handleVideoThumbnailUpload = async (file: any) => {
    setIsThumbnailUploadSuccess('');
    uploadImageToS3(
      file[0],
      `v2/courses/upload/thumbnail/${courseId}?`,
      setUploadThumbnail,
      '',
      setIsThumbnailUploadLoading,
      setIsThumbnailUploadSuccess
    );
    setSelectedFile(file[0].name);
  };
  useEffect(() => {
    if (isCourseUpdated) {
      if (ref.current) {
        ref.current.click();
        setSelectedFile('');
      }
    }
  }, [isCourseUpdated]);

  const handleSaveClick = () => {
    if (uploadThumbnail && courseId) {
      const payloadData = {
        id: courseId,
        patchJsonData: [{ op: 'replace', path: 'thumbnailUrl', value: uploadThumbnail }]
      };
      courseAction.updateCourseRequest(payloadData);
      setUploadThumbnail('');
    }
  };
  useEffect(() => {
    if (isThumbnailUploadSuccess === 'fail') {
      errorMsg('File has not been uploaded successfully');
    } else if (isThumbnailUploadSuccess === 'success') {
      successMsg('File uploaded successfully');
    }
  }, [isThumbnailUploadSuccess]);

  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  return (
    <div>
      <div
        className="modal fade"
        id="uploadCourseThumbnail"
        aria-labelledby="uploadVideoLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="uploadVideoLabel">
                Upload Thumbnail
              </h5>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <label className="font-semibold">Select Thumbnail:</label>
                <div className={`form-group my-5`}>
                  <div className="input-style p-0 speaker-browse-file flex items-center">
                    <input id="uploadFile" className="pl-2" placeholder={`${selectedFile}`} />
                    <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                      <span>Select File</span>
                      <input
                        id="uploadBtn"
                        type="file"
                        className="upload"
                        onChange={(e) => handleVideoThumbnailUpload(e.target.files)}
                        onClick={onInputClick}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                disabled={uploadThumbnail === '' ? true : false}
                onClick={handleSaveClick}
                type="button"
                className={`px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 ${
                  uploadThumbnail === '' ? 'opacity-25' : ''
                }`}>
                Save Changes
              </button>
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button>
              {isThumbnailUploadLoading && (
                <span className="ml-2">
                  <Loader />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadThumbnail;
