import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { QuestionArrayModel, ScrollQuestionModel } from 'src/models/videosModel';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch } from 'react-redux';
const UpdateQuizModal = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const [scrollQuestion, setScrollQuestion] = useState<ScrollQuestionModel[]>([]);
  const [questionArray, setQuestionArray] = useState<Array<QuestionArrayModel>>([
    { type: 'qt1', qId: 1, option: [], description: false }
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: any) => {
    settingAction.updateTemplateRequest(data);
  };
  return (
    <div>
      <div
        className="modal fade"
        id="UpdateQuizModal"
        aria-labelledby="UpdateQuizModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="UpdateQuizModalLabel">
                Quiz
              </h5>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>

            <form className="px-6" onSubmit={handleSubmit(onSubmit)}>
              <div className={`form-group mb-3 w-full ml-5`}>
                <label className="block text-sm font-medium mb-1">Display At End</label>
                <div className="form-check form-switch pl-0 mr-3 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="displayResults"
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                </div>
              </div>
              <div className="modal-body relative py-4 border-b">
                <div className="lg:grid lg:grid-cols-2 gap-6 mt-3 px-6">
                  <div className="border rounded-md">
                    <div className="px-4 py-3">
                      <div className={`form-group mb-3 w-full`}>
                        <FormInputField
                          label={'Quiz Name'}
                          type={'text'}
                          fieldName={'quizname'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                        />
                      </div>

                      <div className="p-6 rounded-md shadow border bg-white max-w-sm flex flex-col">
                        <>
                          <ul>
                            {scrollQuestion &&
                              scrollQuestion.map((item: ScrollQuestionModel) => {
                                return (
                                  <li key={item.id} className="quiz-question-list">
                                    <a href={`#Question${item.id}`} data-abc="true" id="quetext1">
                                      {item.name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="border rounded-md">
                    <div className="flex items-center justify-between border-b px-5 py-4">
                      <p className="font-semibold mb-0">Questions</p>
                      <button
                        type="button"
                        id="actions-dropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                        Add Question
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-ul w-32"
                        aria-labelledby="actions-dropdown">
                        <li className="cursor-pointer">
                          <span className="action-menu">Add Question</span>
                        </li>
                        <li className="cursor-pointer">
                          <span className="action-menu">Add Open Text Question</span>
                        </li>
                      </ul>
                    </div>

                    {questionArray &&
                      questionArray?.map((item, index) => {
                        return (
                          <div
                            id={`Question${index + 1}`}
                            key={index}
                            className={`p-4 questions-side-card `}>
                            <div className="flex-items">
                              <label className="font-semibold">Question {item.qId}</label>

                              <div className="dropdown relative">
                                <button
                                  className="dropdown-toggle border-0 input-style mx-auto flex-items"
                                  type="button"
                                  id="action-dropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <i className="fas fa-chevron-down"></i>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-ul w-32"
                                  aria-labelledby="action-dropdown">
                                  <li className="cursor-pointer">
                                    <span className="action-menu">Add description</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className={`form-group mb-3 w-full relative`}>
                              <i
                                id={`${item.qId}`}
                                className="fas fa-times absolute right-0 top-4 cursor-pointer"></i>
                              <input
                                type="text"
                                className="input-style pl-0"
                                placeholder="What would you like to ask ?"
                                {...register(`question_${item.qId}`)}
                              />
                            </div>
                            {item.description && (
                              <div className={`form-group mb-3 w-full`}>
                                <input
                                  type="text"
                                  className="input-style pl-0"
                                  placeholder="Description (for participants)"
                                  {...register(`description_${item.qId}`)}
                                />
                              </div>
                            )}

                            {item.option &&
                              item.option.map((option, index) => {
                                return (
                                  <div key={index} className={`form-group mb-3 w-full`}>
                                    <div className="flex items-center mr-3">
                                      <div className="form-check">
                                        <input
                                          id={`is_Answer_${option.option}_${index}`}
                                          name={`is_Answer_${option.option}`}
                                          className="form-check-input radio-btn"
                                          type="radio"
                                          checked={option.isAnswer}
                                        />
                                      </div>
                                      <input
                                        type="text"
                                        className="input-style pl-0 ml-3"
                                        placeholder="Add Option"
                                        id={`${option.option}_${index}`}
                                        {...register(`${option.option}_${index}`)}
                                      />
                                    </div>
                                  </div>
                                );
                              })}

                            {item.type === 'qt1' ? (
                              <>
                                {' '}
                                <button
                                  id={`addOption_${item.qId}`}
                                  type="button"
                                  className="btn-primary w-auto mx-0 ml-auto">
                                  <i className="fas fa-plus"></i>
                                </button>
                              </>
                            ) : (
                              <>
                                <div className="lg:flex my-5 justify-between">
                                  <div className="lg:w-1/2 pb-0">
                                    <h6 className="font-bold">Allow Resource Upload</h6>
                                  </div>
                                  <div className="lg:w-1/2 pb-0">
                                    <div className="form-check form-switch pl-0 flex items-center justify-end">
                                      <div className="relative">
                                        <input
                                          className="form-check-input switch-btn"
                                          type="checkbox"
                                          role="switch"
                                          id="allowResourceUpload"
                                          defaultChecked={item && item.allowResourceUpload}
                                          {...register(`allowResourceUpload_${item.qId}`)}
                                        />
                                        <i className="fas fa-check checkbox-tick hidden"></i>
                                        <i className="fas fa-times checkbox-cross"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:flex my-5 justify-between">
                                  <div className="lg:w-1/2 pb-0">
                                    <h6 className="font-bold">Text Answer</h6>
                                  </div>
                                  <div className="lg:w-1/2 pb-0">
                                    <div className="form-check form-switch pl-0 flex items-center justify-end">
                                      <div className="relative">
                                        <input
                                          className="form-check-input switch-btn"
                                          type="checkbox"
                                          role="switch"
                                        />
                                        <i className="fas fa-check checkbox-tick hidden"></i>
                                        <i className="fas fa-times checkbox-cross"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.isTextAnswer && (
                                  <div className={`form-group mb-3 w-full relative`}>
                                    <input
                                      type="text"
                                      className="input-style pl-0"
                                      placeholder="Enter Answer"
                                      {...register(`text_answer_${item.qId}`)}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            <div className="flex items-center mr-3">
                              <label className="font-semibold">Points</label>
                              <input
                                type="text"
                                className="input-style pl-0 w-1/2 ml-2"
                                placeholder="1"
                                {...register(`points_${item.qId}`)}
                              />
                            </div>
                          </div>
                        );
                      })}

                    {/* end */}
                  </div>
                </div>
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  type="submit"
                  className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                  Save Changes
                </button>
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateQuizModal;
