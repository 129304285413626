import React, { useEffect, useState } from 'react';
import BarChart from 'src/components/charts/barchart/BarChart';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Loader from 'src/components/loader';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { AnswerQuestionViewModel } from 'src/models/analyticsModel';
import AnswerQuestion from './components/AnswerQuestion';
import Pagination from 'src/components/common/pagination';
import moment from 'moment';
const AnalyticsCourse: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const [downloadData, setDownloadData] = useState<Array<any>>();
  const [modalData, setModalData] = useState<AnswerQuestionViewModel>({
    courseId: '',
    memberId: ''
  });
  const [activityByDate, setActivityByDate] = useState('This Month');
  const [submission, setSubmission] = useState('This Month');
  const [activityChartType, setActivityChartType] = useState('Monthly');
  const [submissionChartType, setSubmissionChartType] = useState('Monthly');
  const [granularity, setGranularity] = useState(2);
  const {
    courseTotal,
    courseResult,
    viewActivity,
    viewSubmission,
    isCourseResultLoading,
    recentCourseActivity,
    isRecentActivityLoading,
    recentCourseActivityPaginationMeta,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      courseTotal: state.analytics.courseTotal,
      courseResult: state.analytics.courseResult,
      viewActivity: state.analytics.viewActivity,
      viewSubmission: state.analytics.viewSubmission,
      isCourseResultLoading: state.analytics.isCourseResultLoading,
      recentCourseActivity: state.analytics.recentCourseActivity,
      isRecentActivityLoading: state.analytics.isRecentActivityLoading,
      recentCourseActivityPaginationMeta: state.analytics.recentCourseActivityPaginationMeta,
      paginationMeta: state.analytics.paginationMeta
    };
  });
  const [barChartData, setBarChartData] = useState({
    labels: viewActivity && viewActivity.map((data) => data.month),
    datasets: [
      {
        label: 'Page Views',
        data: viewActivity && viewActivity.map((data) => data.pageViews),
        backgroundColor: ['#2A71d0']
      }
    ]
  });
  const [stackedChartData, setStackedChartData] = useState({
    labels: viewSubmission && viewSubmission.map((data) => data.submittedDate),
    datasets: [
      {
        label: 'Missing',
        data: viewSubmission && viewSubmission.map((data) => data.started),
        backgroundColor: ['#2A71d0']
      }
    ]
  });

  useEffect(() => {
    setBarChartData({
      labels: viewActivity && viewActivity.map((data) => data.month),
      datasets: [
        {
          label: 'Page Views',
          data: viewActivity && viewActivity.map((data) => data.pageViews),
          backgroundColor: ['#2A71d0']
        },
        {
          label: 'Participation',
          data: viewActivity && viewActivity.map((data) => data.participation),
          backgroundColor: ['#1A92FF']
        }
      ]
    });
  }, [viewActivity]);

  useEffect(() => {
    setStackedChartData({
      labels:
        viewSubmission &&
        viewSubmission.map((data) => moment(data.submittedDate).format('YYYY-MM-DD')),
      datasets: [
        {
          label: 'Failed',
          data: viewSubmission && viewSubmission.map((data) => data.failed),
          backgroundColor: ['#FF0000']
        },
        {
          label: 'Started',
          data: viewSubmission && viewSubmission.map((data) => data.started),
          backgroundColor: ['#00CFE8']
        },
        {
          label: 'Completed',
          data: viewSubmission && viewSubmission.map((data) => data.completed),
          backgroundColor: ['#28C76F']
        }
      ]
    });
  }, [viewSubmission]);

  useEffect(() => {
    const videoFromDate = moment().startOf('month').format('YYYY-MM-DD');
    const videoToDate = moment().endOf('month').format('YYYY-MM-DD');
    analyticsAction.getCourseTotalRequest();
    analyticsAction.getCourseResultsRequest({ pageNumber: 1, pageSize: 25 });
    // analyticsAction.getViewActivityRequest();
    analyticsAction.getRecentCourseActivityRequest({ pageNumber: 1, pageSize: 25 });
    analyticsAction.getViewSubmissionRequest({
      granularityFilter: granularity,
      fromDate: videoFromDate,
      toDate: videoToDate,
      pageNumber: 1,
      pageSize: 20
    });
  }, []);

  useEffect(() => {
    if (recentCourseActivity.length) {
      const csvData =
        recentCourseActivity &&
        recentCourseActivity.map((recentCourseActivity) => {
          return {
            MemberName: recentCourseActivity.username,
            Email: recentCourseActivity.email,
            course: recentCourseActivity.course,
            video: recentCourseActivity.video,
            channel: recentCourseActivity.channel,
            quizName: recentCourseActivity.quizname,
            submittedDate: recentCourseActivity.submittedDate,
            passRate: recentCourseActivity.passrate,
            score: recentCourseActivity.score,
            pointsAchieved: recentCourseActivity.pointsachieved
          };
        });
      if (csvData) {
        setDownloadData(csvData);
      }
    }
  }, [recentCourseActivity]);

  const handleModalView = (courseId: string, memberId: string) => {
    setModalData({ courseId, memberId });
  };
  const handleActivityByDateFilterClick = (data: string) => {
    setActivityByDate(data);
  };

  const handleSubmissionFilterClick = (data: string) => {
    setSubmission(data);
    if (data === 'This Week') {
      const videoFromDate = moment().startOf('week').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('week').format('YYYY-MM-DD');
      analyticsAction.getViewSubmissionRequest({
        granularityFilter: granularity,
        fromDate: videoFromDate,
        toDate: videoToDate,
        pageNumber: 1,
        pageSize: 20
      });
    } else if (data === 'Last Week') {
      const videoFromDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      const videoToDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      analyticsAction.getViewSubmissionRequest({
        granularityFilter: granularity,
        fromDate: videoFromDate,
        toDate: videoToDate,
        pageNumber: 1,
        pageSize: 20
      });
    } else if (data === 'This Month') {
      const videoFromDate = moment().startOf('month').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('month').format('YYYY-MM-DD');
      analyticsAction.getViewSubmissionRequest({
        granularityFilter: granularity,
        fromDate: videoFromDate,
        toDate: videoToDate,
        pageNumber: 1,
        pageSize: 20
      });
    } else if (data === 'Last Month') {
      const videoFromDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const videoToDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      analyticsAction.getViewSubmissionRequest({
        granularityFilter: granularity,
        fromDate: videoFromDate,
        toDate: videoToDate,
        pageNumber: 1,
        pageSize: 20
      });
    } else if (data === 'This Year') {
      const videoFromDate = moment().startOf('year').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('year').format('YYYY-MM-DD');
      analyticsAction.getViewSubmissionRequest({
        granularityFilter: granularity,
        fromDate: videoFromDate,
        toDate: videoToDate,
        pageNumber: 1,
        pageSize: 20
      });
    }
  };
  const handleActivityChartType = (data: string) => {
    setActivityChartType(data);
  };
  const handleSubmissionChartType = (data: string) => {
    setSubmissionChartType(data);
  };

  const handlePageChange = (pageNumber: number) => {
    analyticsAction.getRecentCourseActivityRequest({ pageNumber, pageSize: 25 });
  };

  const handleCourseResultPageChange = (pageNumber: number) => {
    analyticsAction.getCourseResultsRequest({ pageNumber, pageSize: 25 });
  };
  return (
    <>
      <div className="dashboard">
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {courseTotal && courseTotal.totalCourses}
                  </span>
                  <span className="mb-2">Total Course</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {courseTotal && courseTotal.totalInProgress}
                  </span>
                  <span className="mb-2">Total In Progress</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                      <i className="fa fa-line-chart"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {courseTotal && courseTotal.totalCompleted}
                  </span>
                  <span className="mb-2">Total Completed</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                      <i className="fa fa-check-square-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {courseTotal && courseTotal.averageResult}
                  </span>
                  <span className="mb-2">Average Result</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                      <i className="fa fa-list-alt" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex">
          <h2 className="font-semibold ">View Activity by Date</h2>
        </div> */}
        <div className="mt-4 members-list">
          {/* <div className={`dashboard-card my-5 `}>
            <div className="flex items-center justify-end">
              <div className="dropdown relative mr-3">
                <button
                  className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="flex">{activityChartType}</span>
                  <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                  aria-labelledby="action-dropdown">
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityChartType('Monthly')}>
                      <span>Monthly</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityChartType('Daily')}>
                      <span>Daily</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="dropdown relative mr-3">
                <button
                  className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="flex">{activityByDate}</span>
                  <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                  aria-labelledby="action-dropdown">
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityByDateFilterClick('This Week')}>
                      <span>This Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityByDateFilterClick('Last Week')}>
                      <span>Last Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityByDateFilterClick('This Month')}>
                      <span>This Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityByDateFilterClick('Last Month')}>
                      <span>Last Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleActivityByDateFilterClick('This Year')}>
                      <span>This Year</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <BarChart chartData={barChartData} />
          </div> */}
          <h2 className="font-semibold ">View Submissions</h2>
          <div className={`dashboard-card my-5 `}>
            <div className="flex items-center justify-end">
              <div className="dropdown relative mr-3">
                <button
                  className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="flex">{submissionChartType}</span>
                  <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                  aria-labelledby="action-dropdown">
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => {
                        handleSubmissionChartType('Monthly');
                        setGranularity(2);
                      }}>
                      <span>Monthly</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => {
                        handleSubmissionChartType('Daily');
                        setGranularity(1);
                      }}>
                      <span>Daily</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="dropdown relative mr-3">
                <button
                  className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="flex">{submission}</span>
                  <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                  aria-labelledby="action-dropdown">
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleSubmissionFilterClick('This Week')}>
                      <span>This Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleSubmissionFilterClick('Last Week')}>
                      <span>Last Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleSubmissionFilterClick('This Month')}>
                      <span>This Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleSubmissionFilterClick('Last Month')}>
                      <span>Last Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handleSubmissionFilterClick('This Year')}>
                      <span>This Year</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <BarChart
              chartData={stackedChartData}
              height={90}
              options={{
                responsive: true,
                interaction: {
                  mode: 'index'
                },
                barPercentage: 0.1,
                scales: {
                  x: {
                    stacked: true
                  },
                  y: {
                    stacked: true
                  }
                }
              }}
            />
          </div>

          <div className="mt-5">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Recent Course Activity</h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <input className="input-style w-48 mr-3 mb-1" type="text" placeholder="Search" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Email</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Course</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Video</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Channel</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Submitted Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Pass Rate</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Score</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">CE Points Achieved</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Results</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">View Course</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isRecentActivityLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {recentCourseActivity &&
                              recentCourseActivity.map((item) => {
                                return (
                                  <tr key={item.courseId}>
                                    <td className="p-2">
                                      <div className="text-left">{item.email}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.username}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.course}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.video}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.channel}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.quizname}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.submittedDate ? item.submittedDate : ""}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.passrate}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.score}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <button
                                          onClick={() =>
                                            handleModalView(item.courseId, item.memberId)
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#SingleCourseView"
                                          className="cursor-pointer text-blue-800 font-extrabold hover:underline hover:text-blue-400">
                                          {item.pointsachieved}
                                        </button>
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left cursor-pointer">
                                        {downloadData && (
                                          <CSVLink
                                            className="export"
                                            data={downloadData}
                                            filename={'recent-course-activity.csv'}>
                                            <i className="course-download fa fa-download h4"></i>
                                          </CSVLink>
                                        )}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <Link
                                        to={`/courseTab/${item.courseId}`}
                                        className="text-left cursor-pointer">
                                        <i className="course-download fa fa-link h4"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!recentCourseActivityPaginationMeta &&
                      !!Object.keys(recentCourseActivityPaginationMeta).length && (
                        <Pagination
                          currentPage={recentCourseActivityPaginationMeta.pageNumber}
                          totalCount={recentCourseActivityPaginationMeta.totalCount}
                          pageSize={recentCourseActivityPaginationMeta.pageSize}
                          onPageChange={(page: any) => handlePageChange(page)}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Course Results</h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <input className="input-style w-48 mr-3 mb-1" type="text" placeholder="Search" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Course Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">In Progress</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Completed</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Average Pass Rate</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Quizzes</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Videos</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Actions</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isCourseResultLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {courseResult && courseResult.length ? (
                              <>
                                {courseResult &&
                                  courseResult.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <Link to={`/analytic/singleCourse/${item.id}`}>
                                          <td className="p-2">
                                            <div className="text-left">{item.name}</div>
                                          </td>
                                        </Link>

                                        <td className="p-2">
                                          <div className="text-left">{item.totalInProgress}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalCompleted}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.passRate}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalQuizzes}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalVideos}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.lastActivityDate ? item.lastActivityDate : ""}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            <div className="dropdown relative">
                                              <button
                                                className="dropdown-toggle"
                                                type="button"
                                                id="action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img
                                                  src="../../assets/img/dots-vertical.svg"
                                                  className="w-5 h-5"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu dropdown-menu-ul"
                                                aria-labelledby="action-dropdown">
                                                <Link to={`/analytic/singleCourse/${item.id}`}>
                                                  <button className="action-menu flex items-center">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      className="mr-2 w-4 h-4">
                                                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                      <polyline points="14 2 14 8 20 8"></polyline>
                                                      <line x1="16" y1="13" x2="8" y2="13"></line>
                                                      <line x1="16" y1="17" x2="8" y2="17"></line>
                                                      <polyline points="10 9 9 9 8 9"></polyline>
                                                    </svg>
                                                    <span>View Course Result</span>
                                                  </button>
                                                </Link>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </>
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handleCourseResultPageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnswerQuestion courseId={modalData.courseId} memberId={modalData.memberId} />
    </>
  );
};

export default AnalyticsCourse;
