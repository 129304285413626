import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useParams } from 'react-router-dom';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { EventRegistrationImagesViewModel, PatchEmailSettingsData } from 'src/models/eventModel';
import { useForm } from 'react-hook-form';
import Editor from 'src/components/Editor';
import DemoEmailTemplateModal from './DemoEmailTemplateModal';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
const EmailConfirmation = ({ eventConfirmationClicked }: any) => {
  const dispatch = useDispatch();
  const eventActions = useEventActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const { eventId } = useParams<string>();
  const [editorState, setEditorState] = useState<string>('');
  const { register, handleSubmit, reset } = useForm<EventRegistrationImagesViewModel>();
  const { eventRegistrationImagesView, isEventSaved, isEventUpdated, mediaUrl } = useSelector(
    (state: RootState) => {
      return {
        eventRegistrationImagesView: state.event.singleEvent.eventRegistrationField
          ? state.event.singleEvent.eventRegistrationField.eventRegistrationImagesViewModel
          : null,
        isEventSaved: state.event.isEventSaved,
        isEventUpdated: state.event.isEventUpdated,
        mediaUrl: state.videos.mediaUrl
      };
    }
  );

  useEffect(() => {
    if (
      eventRegistrationImagesView &&
      Object.keys(eventRegistrationImagesView).length &&
      eventConfirmationClicked
    ) {
      videoActions.getMediaUrlRequest(eventRegistrationImagesView.emailBody);
    }
  }, [eventRegistrationImagesView, eventConfirmationClicked]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);

  useEffect(() => {
    if (isEventSaved === true) {
      reset({
        emailOnRegistration: true,
        emailSubject: '',
        emailBody: '',
        sendICSAttachment: true
      });
    }
  }, [isEventSaved]);
  useEffect(() => {
    if (eventRegistrationImagesView) {
      setEditorState(eventRegistrationImagesView.emailBody);
      reset(eventRegistrationImagesView);
    }
    if (eventId?.length == 0) {
      reset({
        emailOnRegistration: true,
        emailSubject: '',
        emailBody: '',
        sendICSAttachment: true
      });
    }
  }, [eventRegistrationImagesView]);

  const onSubmit = (data: any) => {
    const patchJsonModel: PatchEmailSettingsData = {
      id: data.id,
      eventId: data.eventId,
      emailOnRegistration: data.emailOnRegistration,
      emailSubject: data.emailSubject,
      sendICSAttachment: data.sendICSAttachment,
      emailBody: ImageStringConverter(editorState)
    };
    if (patchJsonModel) eventActions.updateEmailSettingRequest(patchJsonModel);
  };
  return (
    <>
      <div className="tab-pane fade " id="tabs-emailConfirmation" role="tabpanel">
        <div className="shadow p-5 border">
          <div className="flex justify-center h-full">
            <div className="w-full">
              {
                // loadingDetails ? (
                //   <Loader />
                // ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch pl-0">
                      <div className={`form-group flex`}>
                        <label className="block mb-1">Registration Confirmation Email</label>
                        <input
                          type="checkbox"
                          className="form-check-input switch-btn ml-2"
                          {...register('emailOnRegistration')}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch pl-0">
                      <div className={`form-group flex`}>
                        <label className="block mb-1">Send ICS Attachment</label>
                        <input
                          type="checkbox"
                          className="form-check-input switch-btn ml-2"
                          {...register('sendICSAttachment')}
                        />
                      </div>
                    </div>
                    <div className="flex ">
                      <span className="text-lg">Compose Email Template</span>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#DemoEmailTemplateModal"
                        className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn">
                        Launch Demo Email Template
                      </button>
                    </div>
                    <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className={`form-group`}>
                        <label className="block mb-1">Subject</label>
                        <input type="text" className="input-style" {...register('emailSubject')} />
                      </div>
                    </div>

                    <Editor editorState={editorState} setEditorState={setEditorState} />
                    <div className="mt-2 modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                      <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                        Save
                      </button>
                    </div>
                  </form>
                </>
                //)
              }
            </div>
            <DemoEmailTemplateModal />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
