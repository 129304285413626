import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCategoryActions } from 'src/store/category/actions';
const CategorySideMenu = () => {
  const dispatch = useDispatch();
  const categoryActions = useCategoryActions(dispatch);
  const [showVideoInfo, setShowVideoInfo] = useState(true);

  const handleTagClick = () => {
    categoryActions.getVideoTagRequest({ pageNumber: 1 });
  };

  const handleContentTypeClick = () => {
    categoryActions.getContentTypeRequest({ pageNumber: 1 });
  };

  const handleModulesClick = () => {
    categoryActions.getModuleRequest({ pageNumber: 1 });
  };
  const handleProfilesClick = () => {
    categoryActions.getAudienceProfilesRequest({ pageNumber: 1 });
  };
  return (
    <div
      className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
        showVideoInfo ? 'hide-lists' : 'show-lists'
      }`}>
      <div className="mobile-dropdown" onClick={() => setShowVideoInfo(!showVideoInfo)}>
        <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
          <p>Categories</p>
          <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
        </div>
      </div>
      <ul
        className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mobile-layout-ul"
        id="tabs-tabVertical"
        role="tablist">
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-videoCategories"
            className="nav-link my-0 tabs-link pl-3 flex items-center active"
            data-bs-toggle="pill"
            data-bs-target="#tabs-videoCategories"
            role="tab"
            aria-controls="tabs-videoCategories"
            aria-selected="false">
            Categories
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation" onClick={() => handleTagClick()}>
          <a
            href="#tabs-videoTags"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-videoTags"
            role="tab"
            aria-controls="tabs-videoTags"
            aria-selected="false">
            Tags
          </a>
        </li>
        <li
          className="nav-item flex-grow"
          role="presentation"
          onClick={() => handleContentTypeClick()}>
          <a
            href="#tabs-contentTypes"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-contentTypes"
            role="tab"
            aria-controls="tabs-contentTypes"
            aria-selected="false">
            Content Types
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation" onClick={() => handleModulesClick()}>
          <a
            href="#tabs-module"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-module"
            role="tab"
            aria-controls="tabs-module"
            aria-selected="false">
            Modules
          </a>
        </li>
        <li
          className="nav-item flex-grow"
          role="presentation"
          onClick={() => handleProfilesClick()}>
          <a
            href="#tabs-audienceProfiles"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-audienceProfiles"
            role="tab"
            aria-controls="tabs-audienceProfiles"
            aria-selected="false">
            Audience Profiles
          </a>
        </li>
      </ul>
    </div>
  );
};

export default CategorySideMenu;
