export const getCourseRequestActionType = 'GET_COURSE_REQUEST'
export const getCourseRequestSuccessActionType = 'GET_COURSE_REQUEST_SUCCESS'
export const getCourseRequestFailureActionType = 'GET_COURSE_REQUEST_FAILURE'

export const saveCourseRequestActionType = 'SAVE_COURSE_REQUEST'
export const saveCourseRequestSuccessActionType = 'SAVE_COURSE_REQUEST_SUCCESS'
export const saveCourseRequestFailureActionType = 'SAVE_COURSE_REQUEST_FAILURE'

export const getSingleCourseRequestActionType = 'GET_SINGLE_COURSE_REQUEST'
export const getSingleCourseRequestSuccessActionType = 'GET_SINGLE_COURSE_REQUEST_SUCCESS'
export const getSingleCourseRequestFailureActionType = 'GET_SINGLE_COURSE_REQUEST_FAILURE'

export const deleteCourseRequestActionType = 'DELETE_COURSE_REQUEST'
export const deleteCourseRequestSuccessActionType = 'DELETE_COURSE_REQUEST_SUCCESS'
export const deleteCourseRequestFailureActionType = 'DELETE_COURSE_REQUEST_FAILURE'

export const updateCourseRequestActionType = 'UPDATE_COURSE_REQUEST'
export const updateCourseRequestSuccessActionType = 'UPDATE_COURSE_REQUEST_SUCCESS'
export const updateCourseRequestFailureActionType = 'UPDATE_COURSE_REQUEST_FAILURE'

export const getCourseModuleRequestActionType = 'GET_COURSE_MODULE_REQUEST'
export const getCourseModuleRequestSuccessActionType = 'GET_COURSE_MODULE_REQUEST_SUCCESS'
export const getCourseModuleRequestFailureActionType = 'GET_COURSE_MODULE_REQUEST_FAILURE'

export const addCourseModuleRequestActionType = 'ADD_COURSE_MODULE_REQUEST'
export const addCourseModuleRequestSuccessActionType = 'ADD_COURSE_MODULE_REQUEST_SUCCESS'
export const addCourseModuleRequestFailureActionType = 'ADD_COURSE_MODULE_REQUEST_FAILURE'

export const getCourseCreditTypeRequestActionType = 'GET_COURSE_CREDIT_TYPE_REQUEST'
export const getCourseCreditTypeRequestSuccessActionType = 'GET_COURSE_CREDIT_TYPE_REQUEST_SUCCESS'
export const getCourseCreditTypeRequestFailureActionType = 'GET_COURSE_CREDIT_TYPE_REQUEST_FAILURE'

export const getCourseResultRequestActionType = 'GET_COURSE_RESULT_REQUEST'
export const getCourseResultRequestSuccessActionType = 'GET_COURSE_RESULT_REQUEST_SUCCESS'
export const getCourseResultRequestFailureActionType = 'GET_COURSE_RESULT_REQUEST_FAILURE'

export const getCourseVideosRequestActionType = 'GET_COURSE_VIDEOS_REQUEST'
export const getCourseVideosRequestSuccessActionType = 'GET_COURSE_VIDEOS_REQUEST_SUCCESS'
export const getCourseVideosRequestFailureActionType = 'GET_COURSE_VIDEOS_REQUEST_FAILURE'

export const addCourseVideoSequenceRequestActionType = 'ADD_COURSE_VIDEOS_SEQUENCE_REQUEST'
export const addCourseVideoSequenceRequestSuccessActionType = 'ADD_COURSE_VIDEOS_SEQUENCE_REQUEST_SUCCESS'
export const addCourseVideoSequenceRequestFailureActionType = 'ADD_COURSE_VIDEOS_SEQUENCE_REQUEST_FAILURE'

export const updateCourseOverviewRequestActionType = 'UPDATE_COURSE_OVERVIEW_REQUEST'
export const updateCourseOverviewRequestSuccessActionType = 'UPDATE_COURSE_OVERVIEW_REQUEST_SUCCESS'
export const updateCourseOverviewRequestFailureActionType = 'UPDATE_COURSE_OVERVIEW_REQUEST_FAILURE'

export const updateCourseEducationRequestActionType = 'UPDATE_COURSE_EDUCATION_REQUEST'
export const updateCourseEducationRequestSuccessActionType = 'UPDATE_COURSE_EDUCATION_REQUEST_SUCCESS'
export const updateCourseEducationRequestFailureActionType = 'UPDATE_COURSE_EDUCATION_REQUEST_FAILURE'

export const updateCourseProgressRequestActionType = 'UPDATE_COURSE_PROGRESS_REQUEST'
export const updateCourseProgressRequestSuccessActionType = 'UPDATE_COURSE_PROGRESS_REQUEST_SUCCESS'
export const updateCourseProgressRequestFailureActionType = 'UPDATE_COURSE_PROGRESS_REQUEST_FAILURE'

export const getChaptersRequestActionType = 'GET_CHAPTERS_REQUEST'
export const getChaptersRequestSuccessActionType = 'GET_CHAPTERS_REQUEST_SUCCESS'
export const getChaptersRequestFailureActionType = 'GET_CHAPTERS_REQUEST_FAILURE'

export const addChapterRequestActionType = 'ADD_CHAPTER_REQUEST'
export const addChapterRequestSuccessActionType = 'ADD_CHAPTER_REQUEST_SUCCESS'
export const addChapterRequestFailureActionType = 'ADD_CHAPTER_REQUEST_FAILURE'

export const updateChapterRequestActionType = 'UPDATE_CHAPTER_REQUEST'
export const updateChapterRequestSuccessActionType = 'UPDATE_CHAPTER_REQUEST_SUCCESS'
export const updateChapterRequestFailureActionType = 'UPDATE_CHAPTER_REQUEST_FAILURE'

export const deleteChapterRequestActionType = 'DELETE_CHAPTER_REQUEST'
export const deleteChapterRequestSuccessActionType = 'DELETE_CHAPTER_REQUEST_SUCCESS'
export const deleteChapterRequestFailureActionType = 'DELETE_CHAPTER_REQUEST_FAILURE'

export const getSingleChapterRequestActionType = 'GET_SINGLE_CHAPTER_REQUEST'
export const getSingleChapterRequestSuccessActionType = 'GET_SINGLE_CHAPTER_REQUEST_SUCCESS'
export const getSingleChapterRequestFailureActionType = 'GET_SINGLE_CHAPTER_REQUEST_FAILURE'

export const getCheckpointsRequestActionType = 'GET_CHECKPOINTS_REQUEST'
export const getCheckpointsRequestSuccessActionType = 'GET_CHECKPOINTS_REQUEST_SUCCESS'
export const getCheckpointsRequestFailureActionType = 'GET_CHECKPOINTS_REQUEST_FAILURE'

export const addCheckpointRequestActionType = 'ADD_CHECKPOINT_REQUEST'
export const addCheckpointRequestSuccessActionType = 'ADD_CHECKPOINT_REQUEST_SUCCESS'
export const addCheckpointRequestFailureActionType = 'ADD_CHECKPOINT_REQUEST_FAILURE'

export const updateCheckpointRequestActionType = 'UPDATE_CHECKPOINT_REQUEST'
export const updateCheckpointRequestSuccessActionType = 'UPDATE_CHECKPOINT_REQUEST_SUCCESS'
export const updateCheckpointRequestFailureActionType = 'UPDATE_CHECKPOINT_REQUEST_FAILURE'

export const deleteCheckpointRequestActionType = 'DELETE_CHECKPOINT_REQUEST'
export const deleteCheckpointRequestSuccessActionType = 'DELETE_CHECKPOINT_REQUEST_SUCCESS'
export const deleteCheckpointRequestFailureActionType = 'DELETE_CHECKPOINT_REQUEST_FAILURE'

export const getSingleCheckpointRequestActionType = 'GET_SINGLE_CHECKPOINT_REQUEST'
export const getSingleCheckpointRequestSuccessActionType = 'GET_SINGLE_CHECKPOINT_REQUEST_SUCCESS'
export const getSingleCheckpointRequestFailureActionType = 'GET_SINGLE_CHECKPOINT_REQUEST_FAILURE'

export const getCourseSequencesRequestActionType = 'GET_COURSE_SEQUENCES_REQUEST'
export const getCourseSequencesRequestSuccessActionType = 'GET_COURSE_SEQUENCES_REQUEST_SUCCESS'
export const getCourseSequencesRequestFailureActionType = 'GET_COURSE_SEQUENCES_REQUEST_FAILURE'

export const saveCourseSequencesRequestActionType = 'SAVE_COURSE_SEQUENCES_REQUEST'
export const saveCourseSequencesRequestSuccessActionType = 'SAVE_COURSE_SEQUENCES_REQUEST_SUCCESS'
export const saveCourseSequencesRequestFailureActionType = 'SAVE_COURSE_SEQUENCES_REQUEST_FAILURE'


export const resetCourseStateActionType = 'RESET_COURSE_STATE'
export const resetCourseDataStateActionType = 'RESET_COURSE_DATA_STATE'