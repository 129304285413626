import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { RootState } from 'src/store/reducers/state';
import DiscountModal from '../components/DiscountModal';
import ToastMessage from 'src/components/ToastContainer';
import { useLocation } from 'react-router-dom';
import { successMsg } from 'src/components/utilities/utils';
type locationState = {
  from: string;
  policiesId: string;
};
const Discounts: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const ref = useRef<HTMLButtonElement>(null);
  const [modalFlag, setModalFlag] = useState('');
  const [currentDiscountId, setCurrentDiscountId] = useState('');
  const { from, policiesId } = (location.state as locationState) || {};
  const monetizationAction = useMonetizationActions(dispatch);

  const {
    discountDetail,
    loading,
    discountPolicies,
    isDiscountPolicyDeleted,
    isDiscountPolicyAdded,
    isDiscountPolicyUpdated
  } = useSelector((state: RootState) => {
    return {
      discountDetail: state.monetization.discountPolicyDetails,
      loading: state.monetization.loading,
      discountPolicies: state.monetization.discountPolicies,
      isDiscountPolicyDeleted: state.monetization.isDiscountPolicyDeleted,
      isDiscountPolicyAdded: state.monetization.isDiscountPolicyAdded,
      isDiscountPolicyUpdated: state.monetization.isDiscountPolicyUpdated
    };
  });

  useEffect(() => {
    monetizationAction.getDiscountPolicies();
  }, []);

  useEffect(() => {
    if (isDiscountPolicyDeleted || isDiscountPolicyAdded || isDiscountPolicyUpdated) {
      successMsg('Success');
      monetizationAction.getDiscountPolicies();
      monetizationAction.resetDiscountPolicyDataStateRequest();
    }
  }, [isDiscountPolicyDeleted, isDiscountPolicyAdded, isDiscountPolicyUpdated]);

  const handleDeleteDiscount = (discountId: string) => {
    monetizationAction.deleteDiscountRequest(discountId);
  };

  useEffect(() => {
    if (policiesId && from === 'memberlist') {
      setModalFlag('edit');
      setCurrentDiscountId(policiesId);
      if (ref.current) {
        ref.current.click();
      }
    }
  }, [policiesId, from]);
  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <button
            ref={ref}
            type="button"
            className="action-menu hidden"
            data-bs-toggle="modal"
            data-bs-target="#editDiscountPolicyModel"></button>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Discounts</h2>
                <div className="md:flex items-center text-center">
                  <button
                    onClick={() => {
                      console.log('add');
                      setModalFlag('add');
                    }}
                    type="button"
                    className="btn-primary w-36 mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#editDiscountPolicyModel">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className={`overflow-x-auto-sm`}>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Discount Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Linked Channel</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Video Discount (%)</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Channel Discount (%)</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {discountPolicies.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {discountPolicies.map((discount, index) => {
                              return (
                                <tr key={index}>
                                  <td className="p-2">
                                    <div className="flex items-center">
                                      <div className="font-medium ">{`${discount.name}`}</div>
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{discount.channelName}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{discount.percentage}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{discount.channelPercentage}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      <div className="dropdown relative">
                                        <button
                                          className="dropdown-toggle"
                                          type="button"
                                          id="action-dropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false">
                                          <img
                                            src="../../assets/img/dots-vertical.svg"
                                            className="w-5 h-5"
                                          />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-ul"
                                          aria-labelledby="action-dropdown">
                                          <li>
                                            <button
                                              type="button"
                                              className="action-menu flex items-center"
                                              data-bs-toggle="modal"
                                              data-bs-target="#editDiscountPolicyModel"
                                              onClick={() => {
                                                console.log(discount.id);
                                                setModalFlag('edit');
                                                setCurrentDiscountId(discount.id);
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                              </svg>
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li>
                                            <span className="action-menu flex items-center text-red-500 cursor-pointer">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                              </svg>
                                              <span
                                                onClick={() => handleDeleteDiscount(discount.id)}>
                                                Delete
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DiscountModal modalFlag={modalFlag} currentDiscountId={currentDiscountId} />
      </div>
    </>
  );
};

export default Discounts;
