import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg, successMsg } from 'src/components/utilities/utils';
const VideoCard = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const BodyBackgroundInputFile = useRef<HTMLInputElement>(null);
  const BodyBackgroundResoureInputFile = useRef<HTMLInputElement>(null);
  const BodyBackgroundLiveMeetInputFile = useRef<HTMLInputElement>(null);
  const [liveStreamImageName, setLiveStreamImageName] = useState<string>('');
  const [liveMeetBackgroundImageName, setLiveMeetBackgroundImageName] = useState<string>('');
  const [liveStreamImageUrl, setLiveStreamImageUrl] = useState<string>();
  const [liveMeetBackgroundImageUrl, setLiveMeetBackgroundImageUrl] = useState<string>();
  const [removeLiveStreamImage, setRemoveLiveStreamImage] = useState<boolean>(false);
  const [removeLiveMeetBackgroundImage, setRemoveLiveMeetBackgroundImage] = useState<boolean>(false);
  const [resourceImageName, setResourceImageName] = useState<string>('');
  const [resourceImageUrl, setResourceImageUrl] = useState<string>();
  const [removeResourceImage, setRemoveResourceImage] = useState<boolean>(false);
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const { videoCard, isAppearanceUpdated } = useSelector((state: RootState) => {
    return {
      videoCard: state.appearance.videoCard,
      isAppearanceUpdated: state.appearance.isAppearanceUpdated
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    appearanceAction.getVideoCardRequest();
  }, []);

  useEffect(() => {
    if (videoCard) {
      const resetData = {
        ...videoCard,
        primaryHexColor: '#' + videoCard.primaryHexColor,
        buyVideoHexColor: '#' + videoCard.buyVideoHexColor,
        viewCartHexColor: '#' + videoCard.viewCartHexColor,
        backgroundSecondaryHex: '#' + videoCard.backgroundSecondaryHex,
        backgroundTertiaryHex: '#' + videoCard.backgroundTertiaryHex,
        textColorHex: '#' + videoCard.textColorHex,
        textSecondaryColorHex: '#' + videoCard.textSecondaryColorHex
      };
      setLiveStreamImageUrl(videoCard.livestreamThumbnailUrl);
      setLiveMeetBackgroundImageUrl(videoCard.liveMeetBackgroundImageUrl);
      setResourceImageUrl(videoCard.resourceThumbnailUrl);
      reset(resetData);
    }
  }, [videoCard]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'primaryHexColor', value: data.primaryHexColor.replace('#', '') },
      { op: 'replace', path: 'buyVideoHexColor', value: data.buyVideoHexColor.replace('#', '') },
      { op: 'replace', path: 'viewCartHexColor', value: data.viewCartHexColor.replace('#', '') },
      {
        op: 'replace',
        path: 'backgroundSecondaryHex',
        value: data.backgroundSecondaryHex.replace('#', '')
      },
      {
        op: 'replace',
        path: 'backgroundTertiaryHex',
        value: data.backgroundTertiaryHex.replace('#', '')
      },
      { op: 'replace', path: 'textColorHex', value: data.textColorHex.replace('#', '') },
      {
        op: 'replace',
        path: 'textSecondaryColorHex',
        value: data.textSecondaryColorHex.replace('#', '')
      },
      { op: 'replace', path: 'cardCurve', value: data.cardCurveBool ? "1rem" : "0px" },
      { op: 'replace', path: 'roundButton', value: data.roundButtonBool ? "10rem" : "3px" },
      { op: 'replace', path: 'categoryCardCurve', value: data.categoryCardCurveBool ? "1rem" : "3px" }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const handleSelectLiveStreamImageClick = () => {
    if (BodyBackgroundInputFile.current) {
      BodyBackgroundInputFile.current.click();
    }
  };
  
  const handleSelectLiveMeetBackgroundImageClick = () => {
    if (BodyBackgroundLiveMeetInputFile.current) {
      BodyBackgroundLiveMeetInputFile.current.click();
    }
  };

  const handleSelectResourceImageClick = () => {
    if (BodyBackgroundResoureInputFile.current) {
      BodyBackgroundResoureInputFile.current.click();
    }
  };

  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  const onInputResourceClick = (event: any) => {
    event.target.value = '';
  };

  const handleLiveStreamImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setLiveStreamImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/livestreamthumbnail?`,
        setLiveStreamImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadLiveStreamImageToApi
      );
    }
  };

  const handleLiveMeetBackgroundImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setLiveMeetBackgroundImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/livemeetbackgroundimage?`,
        setLiveMeetBackgroundImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadLiveMeetBackgroundImageToApi
      );
    }
  };

  const handleResourceImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setResourceImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/resourcethumbnail?`,
        setResourceImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadResourceImageToApi
      );
    }
  };

  const uploadLiveStreamImageToApi = (liveStreamImage: string) => {
    const payloadData = [{ op: 'replace', path: 'livestreamThumbnailUrl', value: liveStreamImage }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleRemoveLiveStreamImage = () => {
    const payloadData = [{ op: 'replace', path: 'livestreamThumbnailUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const uploadLiveMeetBackgroundImageToApi = (liveMeetBackgroundImage: string) => {
    const payloadData = [{ op: 'replace', path: 'liveMeetBackgroundImageUrl', value: liveMeetBackgroundImage }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleRemoveLiveMeetBackgroundImage = () => {
    const payloadData = [{ op: 'replace', path: 'liveMeetBackgroundImageUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const uploadResourceImageToApi = (resourceImage: string) => {
    const payloadData = [{ op: 'replace', path: 'resourceThumbnailUrl', value: resourceImage }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleRemoveResourceImage = () => {
    const payloadData = [{ op: 'replace', path: 'resourceThumbnailUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  useEffect(() => {
    if (isAppearanceUpdated) {
      appearanceAction.getVideoCardRequest();
      appearanceAction.resetAppearanceStateRequest();
      setLiveMeetBackgroundImageName('');
      setLiveStreamImageName('');
      setRemoveLiveStreamImage(false);
      setRemoveLiveMeetBackgroundImage(false);
      setResourceImageName('');
      setRemoveResourceImage(false);
    }
  }, [isAppearanceUpdated]);

  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);
  return (
    <div className="tab-pane fade" id="tabs-videoCards" role="tabpanel">
      <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        {isUploadToS3Loading && <Loader />}
        <div className="accordion-body p-5">
          <div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Watch Button Color
                </label>
                <p>Select the Watch button color for your Video Cards for the library homepage.</p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="primaryHexColor"
                    {...register('primaryHexColor')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Buy Video Button Color
                </label>
                <p>
                  Select the buy Video button color for your Video Cards for the library homepage.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="buyVideoHexColor"
                    {...register('buyVideoHexColor')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  View Cart Button Color
                </label>
                <p>
                  Select the View Cart button color for your Video Cards for the library homepage.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="viewCartHexColor"
                    {...register('viewCartHexColor')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Video Card Background Color
                </label>
                <p>Select the background color for the video cards on your library homepage.</p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="backgroundSecondaryHex"
                    {...register('backgroundSecondaryHex')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Video Card Info Background Color
                </label>
                <p>Select the background color for the video card Info Panel.</p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="backgroundTertiaryHex"
                    {...register('backgroundTertiaryHex')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Main Text Color
                </label>
                <p>The main text color used for most of the Headings and Body text.</p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="textColorHex"
                    {...register('textColorHex')}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Secondary Text Color
                </label>
                <p>
                  Font color used for secondary text (eg. speaker, date, time) for the video cards
                  and the action buttons for the video card.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="md:mx-10 mx-5">
                  <input
                    placeholder="#000000"
                    type="color"
                    id="textSecondaryColorHex"
                    {...register('textSecondaryColorHex')}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Video Card Rounded Corners
                </label>
                <p>Use round corners on the video card.</p>
              </div>

              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="cardCurve"
                        {...register('cardCurveBool')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Rounded Buttons
                </label>
                <p>Use rounded buttons instead of square buttons across the video library.</p>
              </div>

              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="roundButton"
                        {...register('roundButtonBool')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Category Cards Rounded Corners
                </label>
                <p>
                  This will use rounded corners on the category and channel cards used on the
                  library spotlight rows.
                </p>
              </div>

              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 mx-5 md:mx-10">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="categoryCardCurve"
                        {...register('categoryCardCurveBool')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label"
                  htmlFor="Credit">
                  Live Stream Thumbnail Image
                </label>
                <p>
                  This will use rounded corners on the category and channel cards used on the
                  library spotlight rows.
                </p>
              </div>

              <div className="md:w-8/12">
                <div className="mb-4 mx-5 md:mx-10">
                  {videoCard &&
                    videoCard.livestreamThumbnailUrl?.length !== 0 &&
                    videoCard.livestreamThumbnailUrl !== null && (
                      <>
                        <img
                          src={`${videoCard && videoCard.livestreamThumbnailUrl}`}
                          alt="logo-image"
                          className={` ${removeLiveStreamImage ? 'hidden' : ''
                            }w-60 h-full object-contain mb-1`}
                        />
                      </>
                    )}
                  {liveStreamImageUrl && (
                    <span
                      onClick={() => {
                        setLiveStreamImageUrl(''),
                          setRemoveLiveStreamImage(true),
                          handleRemoveLiveStreamImage(),
                          setLiveStreamImageName('');
                      }}
                      className="cursor-pointer site-clr">
                      Remove
                    </span>
                  )}

                  <div className="video-upload-btn flex-items">
                    <div>
                      <label
                        onClick={handleSelectLiveStreamImageClick}
                        className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                        Choose File
                      </label>
                      <span>{liveStreamImageName ? liveStreamImageName : 'No file chosen'}</span>
                    </div>

                    <input
                      ref={BodyBackgroundInputFile}
                      type="file"
                      className="hidden"
                      onChange={(e) => handleLiveStreamImageUpload(e.target.files)}
                      onClick={onInputClick}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label"
                  htmlFor="Credit">
                  Live Studio Background Thumbnail Image
                </label>
              </div>

              <div className="md:w-8/12">
                <div className="mb-4 mx-5 md:mx-10">
                  {videoCard &&
                    videoCard.liveMeetBackgroundImageUrl?.length !== 0 &&
                    videoCard.liveMeetBackgroundImageUrl !== null && (
                      <>
                        <img
                          src={`${videoCard && videoCard.liveMeetBackgroundImageUrl}`}
                          alt="logo-image"
                          className={` ${removeLiveMeetBackgroundImage ? 'hidden' : ''
                            }w-60 h-full object-contain mb-1`}
                        />
                      </>
                    )}
                  {liveMeetBackgroundImageUrl && (
                    <span
                      onClick={() => {
                        setLiveMeetBackgroundImageUrl(''),
                          setRemoveLiveMeetBackgroundImage(true),
                          handleRemoveLiveMeetBackgroundImage(),
                          setLiveMeetBackgroundImageName('');
                      }}
                      className="cursor-pointer site-clr">
                      Remove
                    </span>
                  )}

                  <div className="video-upload-btn flex-items">
                    <div>
                      <label
                        onClick={handleSelectLiveMeetBackgroundImageClick}
                        className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                        Choose File
                      </label>
                      <span>{liveMeetBackgroundImageName ? liveMeetBackgroundImageName : 'No file chosen'}</span>
                    </div>

                    <input
                      ref={BodyBackgroundLiveMeetInputFile}
                      type="file"
                      className="hidden"
                      onChange={(e) => handleLiveMeetBackgroundImageUpload(e.target.files)}
                      onClick={onInputClick}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label"
                  htmlFor="Credit">
                  Resource Thumbnail Image
                </label>
                {/* <p>
                  This will use rounded corners on the category and channel cards used on the
                  library spotlight rows.
                </p> */}
              </div>

              <div className="md:w-8/12">
                <div className="mb-4 mx-5 md:mx-10">
                  {videoCard &&
                    videoCard.resourceThumbnailUrl?.length !== 0 &&
                    videoCard.resourceThumbnailUrl !== null && (
                      <>
                        <img
                          src={`${videoCard && videoCard.resourceThumbnailUrl}`}
                          alt="logo-image"
                          className={` ${removeResourceImage ? 'hidden' : ''
                            }w-60 h-full object-contain mb-1`}
                        />
                      </>
                    )}
                  {resourceImageUrl && (
                    <span
                      onClick={() => {
                        setResourceImageUrl(''),
                          setRemoveResourceImage(true),
                          handleRemoveResourceImage(),
                          setResourceImageName('');
                      }}
                      className="cursor-pointer site-clr">
                      Remove
                    </span>
                  )}

                  <div className="video-upload-btn flex-items">
                    <div>
                      <label
                        onClick={handleSelectResourceImageClick}
                        className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                        Choose File
                      </label>
                      <span>{resourceImageName ? resourceImageName : 'No file chosen'}</span>
                    </div>

                    <input
                      ref={BodyBackgroundResoureInputFile}
                      type="file"
                      className="hidden"
                      onChange={(e) => handleResourceImageUpload(e.target.files)}
                      onClick={onInputResourceClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VideoCard;
