import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Editor from 'src/components/Editor';
import TagsInput from 'react-tagsinput';
import EducationPointsTemplateModel from './components/EducationPointsTemplateModel';
import DeleteEducationPointModal from './components/DeleteEducationPointModal';
import { RootState } from 'src/store/reducers/state';
import Templates from './components/Templates';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingActions } from 'src/store/settings/actions';
import ToastMessage from 'src/components/ToastContainer';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
const SettingPoll = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const videoAction = useVideosActions(dispatch);
  const [editorState, setEditorState] = useState<string>('');
  const [creditTypes, setCreditTypes] = useState<string[]>([]);
  const [addFlag, setAddFlag] = useState<boolean>(false);
  const [educationPointId, setEducationPointId] = useState<string>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  useEffect(() => {
    settingAction.getEducationPointsRequest();
  }, []);

  const {
    educationPoints,
    getCeEducationPoints,
    message,
    isCePointSaved,
    isEducationPointsSaved,
    isEducationPointsUpdate,
    isEducationPointsLoading,
    isEducationPointsDeleted,
    error,
    mediaUrl
  } = useSelector((state: RootState) => {
    return {
      educationPoints: state.setting.educationPoints,
      getCeEducationPoints: state.setting.getCeEducationPoints,
      message: state.setting.message,
      isCePointSaved: state.setting.isCePointSaved,
      isEducationPointsSaved: state.setting.isEducationPointsSaved,
      isEducationPointsUpdate: state.setting.isEducationPointsUpdate,
      isEducationPointsLoading: state.setting.isEducationPointsLoading,
      isEducationPointsDeleted: state.setting.isEducationPointsDeleted,
      error: state.setting.error,
      mediaUrl: state.videos.mediaUrl
    };
  });
  useEffect(() => {
    if (getCeEducationPoints && Object.keys(getCeEducationPoints).length) {
      videoAction.getMediaUrlRequest(getCeEducationPoints.creditPreferenceCustomMessage);
    }
  }, [getCeEducationPoints]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);
  const [videoLayout, setVideoLayout] = useState(true);

  const handleChange = (creditType: string[]) => {
    setCreditTypes(creditType);
  };
  const onSubmit = (data: any) => {
    const cedata = data;
    data = {
      ...cedata,
      CreditTypeTags: creditTypes.join(','),
      CreditPreferenceCustomMessage: ImageStringConverter(editorState)
    };
    settingAction.saveCePointsRequest(data);
  };

  useEffect(() => {
    settingAction.getCePointsRequest();
  }, []);

  useEffect(() => {
    if (
      isCePointSaved ||
      isEducationPointsSaved ||
      isEducationPointsUpdate ||
      isEducationPointsDeleted
    ) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isCePointSaved, isEducationPointsSaved, isEducationPointsUpdate, isEducationPointsDeleted]);

  const getAssesmentType = (data: number) => {
    if (data === 1) {
      return 'Quiz';
    } else if (data === 2) {
      return 'Duration Watched';
    }
  };

  const handleEditClick = (id: string) => {
    setAddFlag(false);
    settingAction.getSingleEducationPointsRequest(id);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  useEffect(() => {
    if (getCeEducationPoints) {
      setValue('PointLabel', getCeEducationPoints?.pointLabel);
      setValue('NewMemberCreditPreference', getCeEducationPoints?.newMemberCreditPreference);
      setCreditTypes(
        getCeEducationPoints?.creditTypeList?.map((creditType) => creditType.type) || []
      );
      setEditorState(getCeEducationPoints.creditPreferenceCustomMessage);
    }
  }, [getCeEducationPoints]);

  const inputStyles = {
    width: 'auto'
  };

  return (
    <>
      <ToastMessage />
      <div className="highlight-reel">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Education Points</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Settings</Link>
            </li>
            <li>
              <strong>Polls</strong>
            </li>
          </ol>
        </div>

        <div className="lg:flex">
          <div
            className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
              videoLayout ? 'hide-lists' : 'show-lists'
            }`}>
            <div className="mobile-dropdown" onClick={() => setVideoLayout(!videoLayout)}>
              <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
                <p>Video Channel Layout</p>
                <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
              </div>
            </div>
            <ul
              className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mt-4 mobile-layout-ul"
              id="tabs-tabVertical"
              role="tablist">
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabs-eventinfo"
                  className="nav-link my-0 tabs-link pl-3 flex items-center active"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-eventinfo"
                  role="tab"
                  aria-controls="tabs-eventinfo"
                  aria-selected="false">
                  Continuing Education Credits
                </a>
              </li>
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabs-regFields"
                  className="
                  nav-link my-0 tabs-link pl-3 flex items-center"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-regFields"
                  role="tab"
                  aria-controls="tabs-regFields"
                  aria-selected="false">
                  Polls & Quizzes
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content lg:w-4/5 shadow border" id="tabs-tabContentVertical">
            <div className="tab-pane fade show active" id="tabs-eventinfo" role="tabpanel">
              <div className="md:p-5 p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <button type="submit" className="btn-primary w-auto mx-4 ml-auto mb-3">
                    Save
                  </button>
                  {getCeEducationPoints && (
                    <>
                      <div className="p-5">
                        <div className="md:flex items-center mb-8">
                          <div className="xl:w-4/12 lg:w-1/2">
                            <label
                              className="form-check-label inline-block font-semibold switch-label mr-5"
                              htmlFor="Credit">
                              Points Label
                            </label>
                            <h1>
                              The name of your organizations Continuing Education Points. This label
                              will be used on your video cards (i.e. CPD, CEU, CAE).
                            </h1>
                          </div>
                          <div className="xl:w-8/12 lg:w-1/2">
                            <div className="flex items-center">
                              <div className="w-full form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                                <div className="relative w-full">
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="PointLabel"
                                    {...register('PointLabel')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex items-center mb-8">
                          <div className="xl:w-4/12 lg:w-1/2">
                            <label
                              className="form-check-label inline-block font-semibold switch-label mr-5"
                              htmlFor="Credit">
                              Credit Types
                            </label>
                            <h1>
                              Credit Types can be set for a course to determine the credit that is
                              available. A course have one or more credit types. If a member can
                              gain CE Credits, then the credit type will be applied to their
                              activity upon course completion. If they can not claim CE Points, then
                              Participation points will be granted.
                            </h1>
                          </div>
                          <div className="xl:w-8/12 lg:w-1/2">
                            <div className="flex items-center">
                              <div className="w-full form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                                <div className="relative w-full">
                                  <TagsInput
                                    value={creditTypes}
                                    onChange={(e) => handleChange(e)}
                                    className="input-style py-0 w-auto"
                                    inputProps={{
                                      placeholder: 'Add Credit Type',
                                      style: inputStyles
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex items-center mb-8">
                          <div className="xl:w-4/12 lg:w-1/2">
                            <label
                              className="form-check-label inline-block font-semibold switch-label mr-5"
                              htmlFor="Credit">
                              Credit Preferences
                            </label>
                            <h1>Display Credit Preference option to new members.</h1>
                          </div>
                          <div className="xl:w-8/12 lg:w-1/2">
                            <div className="flex items-center">
                              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                                <div className="relative">
                                  <input
                                    {...register('NewMemberCreditPreference')}
                                    className="form-check-input switch-btn"
                                    type="checkbox"
                                    role="switch"
                                    id="NewMemberCreditPreference"
                                  />
                                  <i className="fas fa-check checkbox-tick hidden"></i>
                                  <i className="fas fa-times checkbox-cross"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="md:flex  mb-8">
                          <div className="xl:w-4/12 lg:w-1/2">
                            <label
                              className="form-check-label inline-block font-semibold switch-label mr-5"
                              htmlFor="Credit">
                              Credit Preferences Check Message
                            </label>
                            <h1>
                              This message displayed to the user as credit preferences description
                              on the profile page.
                            </h1>
                          </div>
                          <div className="xl:w-8/12 lg:w-1/2">
                            <div className="flex items-center">
                              <div className="w-full form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                                <div className="relative w-full">
                                  <Editor
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    {...register('CreditPreferenceCustomMessage')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
              <div className="py-5 px-7">
                <div className="flex">
                  <h1 className="font-bold">CE Activity Templates</h1>
                  <button
                    onClick={() => setAddFlag(true)}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#AddCETemplate"
                    className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn">
                    Add
                  </button>
                </div>

                <div className="">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-100">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Assessment Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Credit Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Pass Rate(%)</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Allow Retake Quiz</div>
                        </th>
                        <th className="p-2">
                          <div className="text-left invisible">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isEducationPointsLoading && (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      )}
                      <>
                        {educationPoints &&
                          educationPoints.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="p-2">{item.title}</td>
                                <td className="p-2">{getAssesmentType(item.assessmentType)}</td>
                                <td className="p-2">
                                  {item.creditTypes.map((credittype) => credittype.type)}
                                </td>
                                <td className="p-2">{item.passRate}</td>
                                <td className="p-2">{item.allowreTakeQuiz ? 'True' : 'False'}</td>
                                <td className="p-2">
                                  <div className="dropdown relative">
                                    <a
                                      className="
                                          dropdown-toggle
                                          transition
                                          duration-150
                                          ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                      href="#"
                                      type="button"
                                      id="ActionButton"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <ul
                                      className="dropdown-menu action-dropdown"
                                      aria-labelledby="ActionButton">
                                      <li
                                        onClick={() => handleEditClick(item.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#AddCETemplate">
                                        <span className="dropdown-item flex-items cursor-pointer">
                                          <i className="fas fa-edit w-6"></i>
                                          <span>Edit</span>
                                        </span>
                                      </li>
                                      <li onClick={() => setEducationPointId(item.id)}>
                                        <span
                                          className="dropdown-item flex-items cursor-pointer text-red-500"
                                          data-bs-toggle="modal"
                                          data-bs-target="#DeleteEducationModal">
                                          <i className="fas fa-trash w-6"></i>
                                          <span>Delete</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Templates />
          </div>
        </div>
      </div>
      <EducationPointsTemplateModel addFlag={addFlag} />
      <DeleteEducationPointModal educationPointId={educationPointId} />
    </>
  );
};

export default SettingPoll;
