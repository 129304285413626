import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUserActions } from 'src/store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
const UploadEmailModal = () => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const { id } = useParams<string>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const { isEmailUploaded } = useSelector((state: RootState) => {
    return {
      isEmailUploaded: state.users.isEmailUploaded
    };
  });

  const onSubmit = (data: any) => {
    if (id) {
      userAction.uploadEmailRequest({ id, type: 0, values: encodeURIComponent(data.name) });
    }
  };
  useEffect(() => {
    if (isEmailUploaded) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isEmailUploaded]);

  const handleCloseClick = () => {
    reset({
      name: ''
    });
  };
  return (
    <div>
      <div
        className="modal fade"
        id="UploadEmail"
        aria-labelledby="UploadEmailLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <i className="fa fa-at modal-icon"></i>
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="UploadEmailLabel">
                Upload Emails
              </h5>

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">
                    Add one or more emails, separate by a semicolon.
                    <div className="mt-2">
                      <b>{`E.g: john@google.com; emma@yahoo.com; sam@webcastcloud.com`}</b>
                    </div>
                  </label>

                  <textarea
                    {...register('name', { required: true })}
                    className="input-style mt-4"
                    placeholder="Enter Emails"
                    rows={4}
                    cols={50}></textarea>
                  {errors.name?.type === 'required' && (
                    <span className="text-red-500 text-xs flex item-center error">
                      Required Field
                    </span>
                  )}
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Upload
                  </button>
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadEmailModal;
