import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
const PDFViewerModal = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { videoMedia } = useSelector((state: RootState) => {
    return {
      videoMedia: state.videos.videoMedia
    };
  });

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="pdfViewer"
      aria-labelledby="pdfViewerModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-7xl">
        <div
          style={{ height: '800px' }}
          className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="pdfViewerModalLabel">
              {`Preview`}
            </h5>
            <button
              ref={btnRef}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10 pdf-iframe">
            <iframe
              src={videoMedia && videoMedia?.resourceUrl}
              width="100%"
              height="90%"
              frameBorder="0"></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFViewerModal;
