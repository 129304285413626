import React, { useEffect, useState } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useNavigate, useParams } from 'react-router-dom';
import IconPicker from 'src/components/common/iconPicker/IconPicker';
import AssignTemplateModal from './AssignTemplateModal';
import { Link } from 'react-router-dom';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
interface IProps {
  videoId: string;
  isSavedLoading: boolean;
}
const InterActivity: React.FC<IProps> = ({ videoId, isSavedLoading }) => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [selectIcon, setSelectIcon] = useState('');
  const [chatOpenOnLoadFlag, setChatOpenOnLoadFlag] = useState<boolean>();
  const [slidesOpenOnLoadFlag, setSlidesOpenOnLoadFlag] = useState<boolean>();
  const [pollTypeData, setPollTypeData] = useState<Array<number>>();
  const onIconChange = (icon: string) => {
    setSelectIcon(icon);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
    setValue
  } = useForm();

  const {
    interactivity,
    pollsTemplate,
    singleVideo,
    message,
    isPollSaved,
    isGetInteractivityLoading,
    error,
    polls
  } = useSelector((state: RootState) => {
    return {
      interactivity: state.videos.interactivity,
      pollsTemplate: state.videos.pollsTemplate,
      singleVideo: state.videos.singleVideo,
      isGetInteractivityLoading: state.videos.isGetInteractivityLoading,
      message: state.videos.message,
      isPollSaved: state.videos.isPollSaved,
      error: state.videos.error,
      polls: state.videos.polls
    };
  });

  useEffect(() => {
    if (isPollSaved) {
      successMsg(message);
      videoLibraryAction.resetVideoLibraryRequest();
      if (isPollSaved) {
        document.getElementById('assignTempModalClose')?.click();
      }
    }
  }, [isPollSaved]);

  const onSubmit = (data: any) => {
    const payloadData = {
      id: data.id,
      updateData: [
        { op: 'replace', path: 'callToAction', value: data.callToAction },
        { op: 'replace', path: 'chatOpenOnLoad', value: chatOpenOnLoadFlag },
        { op: 'replace', path: 'customLink', value: data.customLink },
        { op: 'replace', path: 'customLinkEnabled', value: data.customLinkEnabled },
        { op: 'replace', path: 'customLinkIcon', value: selectIcon },
        { op: 'replace', path: 'displayUserConnection', value: data.displayUserConnection },
        { op: 'replace', path: 'externalPollEnabled', value: data.externalPollEnabled },
        { op: 'replace', path: 'externalPollURL', value: data.externalPollURL },
        { op: 'replace', path: 'questionsEnabled', value: data.questionsEnabled },
        { op: 'replace', path: 'slidesOpenOnLoad', value: slidesOpenOnLoadFlag },
        { op: 'replace', path: 'chatEnabled', value: data.chatEnabled },
        { op: 'replace', path: 'isPollEnabled', value: data.isPollEnabled }
      ]
    };
    videoLibraryAction.updateInterActivityRequest(payloadData);
  };
  useEffect(() => {
    if (interactivity) {
      reset(interactivity);
      setSelectIcon(interactivity.customLinkIcon);
      setChatOpenOnLoadFlag(interactivity.chatOpenOnLoad);
      setSlidesOpenOnLoadFlag(interactivity.slidesOpenOnLoad);
    }
  }, [interactivity]);

  const handleAddCallToActionClick = () => {
    navigate(`/videos/call-to-action/${videoId}`);
  };
  const callToActionFlag = watch('callToAction');
  const externalPollFlag = watch('externalPollEnabled');

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      videoLibraryAction.resetVideoLibraryRequest();
    }
  }, [error]);

  useEffect(() => {
    if (polls) {
      const pollStatus = polls.map((item) => item.pollType);
      setPollTypeData(pollStatus);
    }
  }, [polls]);

  const handleViewQuestionClick = () => {
    videoLibraryAction.getQuestionRequest({ videoId, questionType: 0 });
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="tabs-interactivityView"
        role="tabpanel"
        aria-labelledby="tabs-recent-view">
        <form onSubmit={handleSubmit(onSubmit)}>
          {(isSavedLoading || isGetInteractivityLoading) && <Loader />}
          <div className="flex border-b items-center">
            <div className="font-bold text-lg ml-5">{singleVideo?.title}</div>
            <div className="flex items-center justify-end ml-auto  py-4">
              <button type="button" className="btn-primary w-auto mt-0 mr-3 ml-0 discard-btn">
                Cancel
              </button>
              <button type="submit" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Save
              </button>

              <Link
                to={`/videos/moderator/${id}`}
                className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Moderator
              </Link>
            </div>
          </div>

          <div className="p-5 border-b">
            <div className="md:flex items-center mb-8">
              <div className="lg:w-2/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Questions
                </label>
              </div>
              <div className="lg:w-1/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="questionsEnabled"
                        {...register('questionsEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={handleViewQuestionClick}
                type="button"
                className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap"
                data-bs-toggle="modal"
                data-bs-target="#QuestionsModal">
                View Q & A
              </button>
            </div>

            <div className="hr-line-dashed"></div>

            <div className="lg:grid grid-cols-4 gap-8">
              <div className="flex items-center">
                <label
                  className="form-check-label inline-block font-semibold switch-label lg:w-8/12 mr-5"
                  htmlFor="Credit">
                  Chat
                </label>
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="chatEnabled"
                        {...register('chatEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <label
                  className="form-check-label inline-block font-semibold switch-label lg:w-8/12 mr-5"
                  htmlFor="Credit">
                  Polls
                </label>
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="isPollEnabled"
                        {...register('isPollEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <label
                  className="form-check-label inline-block font-semibold switch-label lg:w-8/12 mr-5"
                  htmlFor="Credit">
                  Open On Load
                </label>
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onChange={(e) => {
                          setChatOpenOnLoadFlag(e.target.checked);
                          setSlidesOpenOnLoadFlag(false);
                        }}
                        checked={chatOpenOnLoadFlag}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <label
                  className="form-check-label inline-block font-semibold switch-label lg:w-8/12 mr-5"
                  htmlFor="Credit">
                  Display User Connection
                </label>
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="displayUserConnection"
                        {...register('displayUserConnection')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="hr-line-dashed"></div>

            <div className="lg:flex items-center my-8">
              <div className="lg:w-2/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Custom Link
                </label>
              </div>
              <div className="lg:w-1/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="customLinkEnabled"
                        {...register('customLinkEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-4/12 flex items-center p-3">
                <IconPicker value={selectIcon} onChange={onIconChange} />
              </div>
              <div className="lg:w-4/12 ml-2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Open On Load
                </label>
              </div>

              <div className="lg:w-1/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onChange={(e) => {
                          setSlidesOpenOnLoadFlag(e.target.checked), setChatOpenOnLoadFlag(false);
                        }}
                        checked={slidesOpenOnLoadFlag}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:flex items-center my-8">
              <div className="lg:w-2/12"></div>
              <div className="lg:w-10/12">
                <input
                  type="text"
                  className="input-style"
                  placeholder="https://"
                  {...register('customLink')}
                />
              </div>
            </div>

            <div className="hr-line-dashed"></div>

            <div className="lg:flex items-center my-8">
              <div className="lg:w-2/12">
                <label className="font-semibold">Polls</label>
              </div>
              <div className="lg:w-10/12 ml-14 ml">
                <div className="flex items-center w-full">
                  <div className="rounded-circle mr-10">
                    <div
                      className={`${
                        pollTypeData && pollTypeData.includes(0) ? 'circle-green' : 'circle'
                      }`}></div>
                    <span>Quiz</span>
                  </div>
                  <div className="rounded-circle mr-10">
                    <div
                      className={`${
                        pollTypeData && pollTypeData.includes(1) ? 'circle-green' : 'circle'
                      }`}></div>
                    <span>Multiple Choice</span>
                  </div>
                  <div className="rounded-circle mr-10">
                    <div
                      className={`${
                        pollTypeData && pollTypeData.includes(3) ? 'circle-green' : 'circle'
                      }`}></div>
                    <span>Star Rating</span>
                  </div>
                  <div className="rounded-circle">
                    <div
                      className={`${
                        pollTypeData && pollTypeData.includes(2) ? 'circle-green' : 'circle'
                      }`}></div>
                    <span>Open Text</span>
                  </div>
                </div>
              </div>
              <div className="lg:w-5/12">
                <div className="flex items-center">
                  <Link
                    to={`/videos/create-poll/${videoId}`}
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap">
                    Configure Poll
                  </Link>
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#AssignTempModal"
                    className="btn-primary w-auto mt-0 mx-0 borderonly-btn whitespace-nowrap">
                    Assign Template
                  </button>
                </div>
              </div>
            </div>

            <div className="hr-line-dashed"></div>

            <div className="lg:flex items-center my-8">
              <div className="lg:w-2/12">
                <label className="font-semibold">Call To Action</label>
              </div>
              <div className="lg:w-1/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="callToAction"
                        {...register('callToAction')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`lg:w-9/12 ${callToActionFlag ? '' : 'hidden'} md:ml-5`}>
                <span
                  onClick={handleAddCallToActionClick}
                  className="btn-primary mt-0 mr-3 ml-0 borderonly-btn cursor-pointer whitespace-nowrap"
                  style={{ width: 'fit-content' }}>
                  Add Call To Action
                </span>
              </div>
            </div>

            <div className="hr-line-dashed"></div>

            <div className="lg:flex items-center my-8">
              <div className="lg:w-2/12">
                <label className="font-semibold">External Poll</label>
              </div>
              <div className="lg:w-1/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="externalPollEnabled"
                        {...register('externalPollEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`lg:w-9/12 ${externalPollFlag ? '' : 'hidden'} md:ml-5`}>
                <input
                  type="text"
                  className="input-style"
                  placeholder="https://"
                  id="externalPollURL"
                  {...register('externalPollURL')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <AssignTemplateModal
        pollsTemplate={pollsTemplate}
        singleVideo={singleVideo}
        videoId={videoId}
      />
    </>
  );
};

export default InterActivity;
