import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useCourseActions } from 'src/store/course/actions';
import { CourseModuleModel } from 'src/models/courseModel';

const AddModulesModal: React.FC = () => {
  const dispatch = useDispatch();
  const courseAction = useCourseActions(dispatch);
  const btnRef = useRef<HTMLButtonElement>(null);

  const {
    isCourseModuleAdded,
  } = useSelector((state: RootState) => {
    return {
      isCourseModuleAdded: state.course.isCourseModuleAdded,
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<CourseModuleModel>();

  //on click of Add/Update Button
  const onSubmit = (data: CourseModuleModel) => {
    courseAction.addCourseModuleRequest(data.module);
  };

  useEffect(() => {
    if (isCourseModuleAdded === true) {
      reset({
        module: ''
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isCourseModuleAdded]);

  const handleCloseClick = () => {
    reset({
      module: ''
    });
  };

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="addModulesModal"
      aria-labelledby="addModulesModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full" id="addModulesModalLabel">
              Add Course Module
            </h5>
            <button
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">

            <form>
              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> Module </label>
                  <input
                    type="text"
                    className="input-style"
                    id="module"
                    {...register('module', {
                      required: true,
                      maxLength: 500
                    })}
                  />
                  {errors.module?.type === 'required' && <p className="text-red-700">Required Field</p>}
                  {errors.module?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                </div>
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                <button                  
                  className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                  onClick={handleSubmit(onSubmit)} >
                  Save
                </button>
                <button
                  ref={btnRef}
                  onClick={handleCloseClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddModulesModal;