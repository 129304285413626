import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { uploadPartUrl } from 'src/components/utilities/utils';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { toast } from 'react-toastify';
import { UpdateResourceUploadModel } from 'src/models/videosModel';
import axios from 'axios';
interface IProps {
  videoId: string;
}

const MediaInfo: React.FC<IProps> = ({ videoId }) => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const [source, SetSource] = useState(2);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const rootUrl = process.env.REACT_APP_BASE_API_URL;
  const [selectedFile, setSelectedFile] = useState<any>('');
  const [videoName, setVideoName] = useState<string>('');
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const secondaryUploadMediaBtn = useRef<HTMLInputElement>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [resourceName, setResourceName] = useState<string>('');
  const [resourceType, setResourceType] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadedFileUrl, setUploadedFileUrl] = useState<File>();
  const { videoMedia, singleVideo, productAccess, liveMedia, isCancelUpload, resourceId } =
    useSelector((state: RootState) => {
      return {
        videoMedia: state.videos.videoMedia,
        singleVideo: state.videos.singleVideo,
        productAccess: state.videos.isProductAccess,
        liveMedia: state.videos.liveMedia,
        isCancelUpload: state.videos.isCancelUpload,
        resourceId: state.videos.resourceId
      };
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue
  } = useForm();

  const onSubmit = (data: any) => {
    videoActions.saveVideoMediaRequest({ ...data, videoId });
  };
  const handleResourceUpload = async (files: any) => {
    setUploadedFile(files && files[0]);
    setResourceName(files[0].name);
    setResourceType(files[0].type);
    setSelectedFile(files[0].name);
  };

  useEffect(() => {
    if (uploadedFile) {
      fetchSignedURL();
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (videoMedia && videoMedia.isSecondary) {
      setValue('secondaryLiveSlidesType', videoMedia.secondaryLiveSlidesType);
      setValue('secondaryLiveMedia', videoMedia.secondaryLiveMedia);
      setValue('isSecondary', videoMedia.isSecondary);
    }
  }, [videoMedia]);

  useEffect(() => {
    videoActions.getProductAccessRequest();
    videoActions.getLiveMediaRequest();
  }, []);

  useEffect(() => {
    if (singleVideo.videoContentType == 1) {
      videoActions.getResourceDetailRequest(videoId);
    }
  }, [singleVideo]);

  const handleChangeVideoSource = (sourceOption: any) => {
    videoActions.updateLiveStreamSourceRequest({ videoId, option: sourceOption });
    if (videoId) {
      videoActions.getVideoMediaRequest(videoId);
    }
  };
  const isSecondary = watch('isSecondary');
  const secondaryLiveSlidesType = watch('secondaryLiveSlidesType');

  const handleMultiplePartUpload = async (selectedFile: any) => {
    setUploadPercentage(1);
    setVideoName(selectedFile[0].name);
    uploadPartUrl(
      rootUrl,
      singleVideo.id,
      singleVideo.title,
      selectedFile[0].name,
      singleVideo.channelId,
      selectedFile[0],
      false,
      setUploadPercentage,
      videoActions.uploadVideoRequestSuccess,
      isCancelUpload,
      true
    );
  };

  const handleSelectNewClick = (inputName: any) => {
    if (inputName.current) {
      inputName.current.click();
    }
  };

  const handleSecondaryMediaChange = (e: any) => {
    const selectedItem = liveMedia && liveMedia.find((item) => item.id === e);
    if (selectedItem) {
      if (selectedItem.mediaLiveChannelId === singleVideo?.urls[0]?.mediaLiveChannelId) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }
  };

  const fetchSignedURL = () => {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    const apiPath = '/v2/video/resource/replace';
    const fileName = resourceName.slice(0, -4);
    const fileType = resourceType;
    const bearerToken = localStorage.getItem('token');
    const payload: UpdateResourceUploadModel = {
      filename: fileName,
      filetype: fileType,
      resourceId: resourceId,
      title: singleVideo.title,
      channelid: singleVideo.channelId,
      thumbnailURL: ''
    };

    const apiUrl = `${rootUrl}${apiPath}?presentationId=${videoId}`;

    axios
      .patch(apiUrl, JSON.stringify(payload), {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(async (response) => {
        const preSignedUrl = response.data.result.signedUrl;
        await uploadFileWithProgress(preSignedUrl);
      })
      .catch((error) => {
        console.error('Error during fetch:', error);
      });
  };
  const uploadFileWithProgress = (url: any) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', resourceType);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
          toast('success');
          setTimeout(() => {
            setUploadProgress(0);
            setResourceName('');
            setResourceType('');
            setSelectedFile('');
          }, 3000);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(uploadedFile);
    });
  };
  return (
    <div
      className="tab-pane fade"
      id="tabs-mediainfoView"
      role="tabpanel"
      aria-labelledby="tabs-mediainfo-view">
      <div className="p-5">
        <div className="font-bold text-lg mb-2">{singleVideo?.title}</div>
        <div>
          {singleVideo && singleVideo.videoContentType !== 1 && (
            <>
              <label className="font-black text-lg">Live</label>
              <div className="flex items-center">
                <div className="flex my-5">
                  <Link
                    to={`/videos/live-event/${videoId}`}
                    className="btn-primary w-48 mt-0 mx-0 borderonly-btn">
                    Live Studio
                  </Link>
                </div>
                <div>
                  <select
                    className="input-style ml-2"
                    onChange={(e) => handleChangeVideoSource(e.target.value)}>
                    {productAccess && productAccess.liveMeeting ? (
                      <>
                        <option
                          selected={videoMedia.liveStreamSource === 'Webcam' ? true : false}
                          value={1}>
                          Webcam
                        </option>
                        <option
                          selected={
                            videoMedia.liveStreamSource === 'Streaming Software' ? true : false
                          }
                          value={2}>
                          Streaming Software
                        </option>
                        <option
                          selected={
                            videoMedia.liveStreamSource === 'Low Latency Meeting' ? true : false
                          }
                          value={3}>
                          Low Latency Meeting
                        </option>
                      </>
                    ) : (
                      <>
                        <option
                          selected={videoMedia.liveStreamSource === 'Webcam' ? true : false}
                          value={1}>
                          Webcam
                        </option>
                        <option
                          selected={
                            videoMedia.liveStreamSource === 'Streaming Software' ? true : false
                          }
                          value={2}>
                          Streaming Software
                        </option>
                      </>
                    )}
                  </select>
                </div>
              </div>
            </>
          )}
          {singleVideo && singleVideo.videoContentType === 1 && (
            <>
              <div className="modal-body relative py-4">
                <form>
                  <label className="font-semibold">Replace Media:</label>
                  <div className={`form-group my-5`}>
                    <div className="input-style p-0 speaker-browse-file flex items-center">
                      <input id="uploadFile" className="pl-2" placeholder={`${selectedFile}`} />
                      <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                        <span>Select File</span>
                        <input
                          id="uploadBtn"
                          type="file"
                          className="upload"
                          onChange={(e) => handleResourceUpload(e.target.files)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex items-center  whitespace-nowrap">
                {uploadProgress !== 0 && resourceName !== '' && (
                  <div
                    className={`w-full ${
                      uploadProgress === 100 ? 'bg-green-100' : 'bg-purple-100'
                    } h-3 rounded-full mt-4`}>
                    <div
                      className={` ${
                        uploadProgress === 100 ? 'bg-green-400' : 'bg-purple-600'
                      } text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                      style={{ width: `${Math.floor(uploadProgress)}%` }}>
                      {Math.floor(uploadProgress)}%
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {singleVideo && singleVideo.videoContentType !== 1 && (
            <div className="my-5">
              <label className="font-black text-lg">
                {`${singleVideo && singleVideo.videoContentType !== 1 ? 'Primary' : ''}`} Video
              </label>
              <div className="flex items-center mt-3">
                {videoMedia && videoMedia.primaryUrl && videoMedia.primaryUrl.mP4URL && (
                  <button
                    onClick={() => saveAs(videoMedia.primaryUrl.mP4URL, videoId)}
                    type="button"
                    className="btn-primary w-auto mt-0 borderonly-btn border-gray-200 mx-0 mr-3">
                    <i className="fas fa-download mr-1"></i> Download
                  </button>
                )}

                <Link
                  to={`/videos/replace/${videoId}`}
                  className="btn-primary w-auto mt-0 borderonly-btn border-gray-200 mx-0">
                  {`${
                    videoMedia && videoMedia.primaryUrl && videoMedia.primaryUrl.mP4URL
                      ? 'Replace'
                      : 'Upload'
                  }`}
                </Link>
              </div>
            </div>
          )}
          {singleVideo && singleVideo.videoContentType !== 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="md:flex items-center my-8">
                <div className="md:w-3/12">
                  <label
                    className="form-check-label inline-block switch-label mr-5 font-black text-lg"
                    htmlFor="Credit">
                    Secondary Media
                  </label>
                </div>
                <div className="md:w-10/12 mr-20">
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          role="switch"
                          id="isSecondary"
                          {...register('isSecondary')}
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isSecondary && (
                <div className="md:flex items-center my-8">
                  <div className="md:w-3/12">
                    <label
                      className="form-check-label inline-block switch-label mr-5 font-black text-lg"
                      htmlFor="Credit">
                      Secondary Media Type
                    </label>
                  </div>
                  <div className="md:w-10/12 mr-20">
                    <div className="flex items-center">
                      <div className="md:w-10/12 mr-20">
                        <select
                          className="input-style ml-2"
                          id="secondaryLiveSlidesType"
                          {...register('secondaryLiveSlidesType')}>
                          <option value="">Select</option>
                          <option value={0}>Slides</option>
                          <option value={1}>Video</option>
                          <option value={2}>Both</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isSecondary && (secondaryLiveSlidesType == 1 || secondaryLiveSlidesType == 2) && (
                <div className="md:flex items-center my-8">
                  <div className="md:w-3/12">
                    <label
                      className="form-check-label inline-block switch-label mr-5 font-black text-lg"
                      htmlFor="Credit">
                      Live Video Media
                    </label>
                  </div>
                  <div className="md:w-10/12 mr-20">
                    <div className="flex items-center">
                      <div className="md:w-10/12 mr-20">
                        <select
                          className="input-style ml-2"
                          id="secondaryLiveMedia"
                          {...register('secondaryLiveMedia', {
                            onChange: (e: any) => {
                              handleSecondaryMediaChange(e.target.value);
                            }
                          })}>
                          <option value="">Select</option>
                          {liveMedia &&
                            liveMedia.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.mediaName}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className=" my-8">
                {showWarning && (
                  <p className="font-semibold text-red-600">
                    You have selected the same Live Media Channel for both Primary and Secondary
                    stream. This means that the same media will be displayed twice in the live
                    stream. Are you sure you want to continue?
                  </p>
                )}

                <div className="md:w-3/12">
                  <button
                    type="submit"
                    className="btn-primary w-auto mt-0 borderonly-btn border-gray-200 mx-0 ml-6">
                    Save
                  </button>
                </div>
              </div>
            </form>
          )}
          {(watch('secondaryLiveSlidesType') == 1 || watch('secondaryLiveSlidesType') == 2) && (
            <div className="mb-5">
              <label className="font-semibold">Video</label>
              {uploadPercentage !== 0 && videoName !== '' && (
                <div
                  className={`w-full ${
                    uploadPercentage === 100 ? 'bg-green-100' : 'bg-purple-100'
                  } h-3 rounded-full mt-4`}>
                  <div
                    className={` ${
                      uploadPercentage === 100 ? 'bg-green-400' : 'bg-purple-600'
                    } text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                    style={{ width: `${Math.floor(uploadPercentage)}%` }}>
                    {Math.floor(uploadPercentage)}%
                  </div>
                </div>
              )}
              <div className="mt-3">
                <div className="video-upload-btn flex-items">
                  <label
                    onClick={() => handleSelectNewClick(secondaryUploadMediaBtn)}
                    className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                    Upload Secondary Video
                  </label>

                  <input
                    ref={secondaryUploadMediaBtn}
                    id="uploadBtns"
                    type="file"
                    className="hidden"
                    onChange={(e) => handleMultiplePartUpload(e.target.files)}
                  />
                </div>
              </div>
            </div>
          )}
          {(watch('secondaryLiveSlidesType') == 0 || watch('secondaryLiveSlidesType') == 2) && (
            <>
              <label className="font-semibold">Slides</label>
              <div className="flex my-5">
                <Link
                  to={`/videos/upload-slides/${videoId}`}
                  className="btn-primary w-48 mt-0 mx-0 borderonly-btn">
                  Upload Slides
                </Link>

                <Link
                  to={`/videos/manage-slides/${videoId}`}
                  className="btn-primary w-48 mt-0 mx-0 discard-btn">
                  Manage Slides
                </Link>
              </div>
            </>
          )}

          <div className="mb-5">
            <label className="font-black text-lg">Media</label>
            {singleVideo &&
              singleVideo?.useLowLatencyLiveMeeting &&
              videoMedia.liveStreamSource == 'Low Latency Meeting' && (
                <div className="md:flex items-center my-8">
                  <div className="md:w-2/12">
                    <label className="font-semibold">Live Meeting ID</label>
                  </div>
                  <div className="md:w-10/12">
                    <div>
                      <p>{videoMedia?.liveMeetingId}</p>
                    </div>
                  </div>
                </div>
              )}
            {singleVideo && singleVideo.videoContentType !== 1 && (
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">HLS Media URL</label>
                </div>
                <div className="md:w-10/12">
                  <div>
                    <p>{videoMedia && videoMedia?.primaryUrl?.mediaUrl}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          {singleVideo && singleVideo.videoContentType === 1 ? (
            <div className="mb-5">
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">Resource URL</label>
                </div>
                <div className="md:w-10/12">
                  <div>
                    <p>{videoMedia && videoMedia?.resourceUrl}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-5">
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">MP4 Media URL</label>
                </div>
                <div className="md:w-10/12">
                  <div>
                    <p>{videoMedia && videoMedia?.primaryUrl?.mP4URL}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {singleVideo && singleVideo.videoContentType !== 1 && (
            <div className="mb-5">
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">Secondary Media HLS URL</label>
                </div>
                <div className="md:w-10/12">
                  <p>{videoMedia && videoMedia?.secondaryUrl?.mediaUrl}</p>
                </div>
              </div>
            </div>
          )}
          {singleVideo && singleVideo.videoContentType !== 1 && (
            <div className="mb-5">
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">Secondary Media MP4 URL</label>
                </div>
                <div className="md:w-10/12">
                  <p>{videoMedia && videoMedia?.secondaryUrl?.mP4URL}</p>
                </div>
              </div>
            </div>
          )}
          {videoMedia && videoMedia?.primaryUrl?.mP3Url && (
            <div className="mb-5">
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label className="font-semibold">MP3 URL</label>
                </div>
                <div className="md:w-10/12">
                  <p>{videoMedia && videoMedia?.primaryUrl?.mP3Url}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaInfo;
