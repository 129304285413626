import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useSettingActions } from 'src/store/settings/actions';
const AddTemplate = ({ pollType }: any) => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { isTemplateAdded } = useSelector((state: RootState) => {
    return {
      isTemplateAdded: state.setting.isTemplateAdded
    };
  });
  useEffect(() => {
    if (isTemplateAdded || isTemplateAdded) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isTemplateAdded]);

  const onSubmit = (data: any) => {
    settingAction.addTemplateRequest(data);
  };

  const handleCloseClick = () => {
    reset({
      name: ''
    });
  };

  return (
    <div>
      <div
        className="modal fade"
        id="AddTemplate"
        aria-labelledby="AddTemplateLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddTemplateLabel">
                Add{' '}
                {pollType === 0
                  ? 'Quiz Template'
                  : pollType === 1
                  ? 'MultipleChoice Template'
                  : pollType === 2
                  ? 'StarRating Template'
                  : 'OpenText Template'}
              </h5>

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Template Name'}
                    type={'text'}
                    fieldName={'name'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTemplate;
