import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { SelectModel } from 'src/models/videosModel';
import {
  PackageModel,
  PackageDetailsModel,
  PatchJsonModel,
  PatchJsonData,
  MemberListModel
} from 'src/models/monetizationModel';
import { useUserActions } from 'src/store/users/actions';
import Loader from 'src/components/loader';
import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';
import { customFilterOption } from 'src/components/utilities/utils';
import { useChannelActions } from 'src/store/channel/actions';

interface IProps {
  modalFlag: string;
  currentPackageId: string;
}

const PackageModal: React.FC<IProps> = ({ modalFlag, currentPackageId }) => {
  const dispatch = useDispatch();
  const monetizationAction = useMonetizationActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const userActions = useUserActions(dispatch);

  //get Member Lists and Channel Names
  const [finalMemberLists, setFinalMemberLists] = useState<any>();

  //Setting values to state variables
  const [heading, setHeading] = useState('Add');
  const [operation, setOperation] = useState('Add');
  const [attachedMembers, setattachedMembers] = useState<any>([]);
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const animatedComponents = makeAnimated();
  const btnRef = useRef<HTMLButtonElement>(null);

  //Getting values from RootState
  const {
    channels,
    memberLists,
    packageInfo,
    originalPackageInfo,
    isPackageAdded,
    loadingDetails,
    packageDetails,
    loadingPackageMemberList,
    isPackageMemberListAdded,
    isPackageMemberListDeleted,
    channelList,
    isPackageUpdated
  } = useSelector((state: RootState) => {
    return {
      channels: state.channel.channels,
      memberLists: state.users.memberList,
      packageInfo: state.monetization.packageInfo,
      originalPackageInfo: state.monetization.packageInfo,
      isPackageAdded: state.monetization.isPackageAdded,
      loadingDetails: state.monetization.loadingPackageDetails,
      packageDetails: state.monetization.packageDetails,
      loadingPackageMemberList: state.monetization.loadingPackageMemberList,
      isPackageMemberListAdded: state.monetization.isPackageMemberListAdded,
      isPackageMemberListDeleted: state.monetization.isPackageMemberListDeleted,
      channelList: state.channel.channelList,
      isPackageUpdated: state.monetization.isPackageUpdated
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<PackageModel>();

  //on click of Add/Update Button
  const onSubmit = (data: any) => {
    const payloadData = {
      ...data,
      channelId: data.channelId.value == '' ? null : data.channelId.value
    };
    if (modalFlag === 'add') {
      setattachedMembers([]);
      monetizationAction.addPackageRequest(payloadData);
    } else if (modalFlag === 'edit') {
      const patchJsonModel: PatchJsonModel = {
        id: payloadData.id,
        patchJsonData: convertModelToPatch(payloadData)
      };
      if (patchJsonModel.patchJsonData.length > 0)
        monetizationAction.editPackageRequest(patchJsonModel);
    }
  };

  const convertModelToPatch = (data: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = originalPackageInfo;
      if (value[field] == data[field]) continue;

      const patchObjdal: PatchJsonData = {
        from: value[field],
        value: data[field],
        path: '/' + field,
        op: 'replace'
      };
      patchData.push(patchObjdal);
    }
    return patchData;
  };

  const onAttachMember = (option: any, actionMetadata: ActionMeta<any>) => {
    if (actionMetadata.action == 'remove-value') {
      const deleteMemberlistModel: MemberListModel = {
        id: actionMetadata.removedValue.value,
        entityId: currentPackageId,
        name: actionMetadata.removedValue.label
      };
      monetizationAction.deletePackageMemberListRequest(deleteMemberlistModel);
    } else {
      const addMemberlistModel: MemberListModel = {
        id: actionMetadata.option.value,
        entityId: currentPackageId,
        name: actionMetadata.option.label
      };
      monetizationAction.addPackageMemberListRequest(addMemberlistModel);
    }
    setattachedMembers(option);
  };

  //On Cancel click
  const handleCancelClick = () => {
    /*
    monetizationAction.resetPackagePolicyDataStateRequest();
    reset({
      name: '',
      channelName: '',

    });*/
  };

  //Get the Details about the discount id for different discounts in Parent page
  useEffect(() => {
    if (currentPackageId.length > 0) monetizationAction.getPackageDetails(currentPackageId);
  }, [currentPackageId, isPackageMemberListAdded, isPackageMemberListDeleted]);

  useEffect(() => {
    if (!packageDetails || !packageDetails.memberlists) {
      setattachedMembers([]);
      return;
    }
    const selectedMembers = packageDetails.memberlists.map((item) => ({
      value: item.id,
      label: item.name
    }));
    setattachedMembers(selectedMembers);
  }, [packageDetails]);

  //Get the Channel and Memberlists details
  useEffect(() => {
    if (!channels || channels.length == 0) {
      channelAction.getChannelRequest();
    }
    if (!memberLists || memberLists.length == 0) {
      userActions.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
    }
  }, []);

  useEffect(() => {
    if (modalFlag === 'edit') {
      const selectedChannel =
        channelList && channelList.filter((item: any) => item.id === packageInfo.channelId);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].name : ''
        };
      }
      const finalData = { ...packageInfo, channelId: dataRef };
      reset(finalData);
      setHeading('Edit');
      setOperation('Update');
    } else {
      setHeading('Add');
      setOperation('Add');
      reset({
        name: '',
        channelName: ''
      });
    }
  }, [packageInfo, modalFlag]);

  //Whenever channels value changed, below code will be called
  useEffect(() => {
    channelAction.getFlattenedChannel();
  }, [channels]);

  //Whenever memberlist value changed, below code will be called
  useEffect(() => {
    if (memberLists && memberLists.length) {
      const tempArray = [...memberLists];
      const options = tempArray.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setFinalMemberLists(options);
    }
  }, [memberLists]);

  useEffect(() => {
    if (isPackageAdded || isPackageUpdated) {
      reset({
        name: '',
        channelName: ''
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isPackageAdded, isPackageUpdated]);

  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);

  useEffect(() => {
    if (channelList) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels([
        {
          value: '',
          label: 'Select Channel'
        },
        ...options
      ]);
    }
  }, [channelList]);
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editPackageModel"
      aria-labelledby="editPackageModelLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editPackageModelLabel">
              {`${heading} Package Information`}
            </h5>
            <button
              onClick={handleCancelClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            {loadingDetails ? (
              <Loader />
            ) : (
              <>
                {' '}
                <form>
                  <div className="grid mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Name </label>
                      <input
                        type="text"
                        className="input-style"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 500
                        })}
                      />
                      {errors.name?.type === 'required' && (
                        <p className="text-red-700">Required Field</p>
                      )}
                      {errors.name?.type === 'maxLength' && (
                        <p className="text-red-700">Max. length (500 letters) reached</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Applies To </label>
                      {finalChannels && Object.keys(finalChannels).length && (
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error }
                          }) => (
                            <Select
                              filterOption={customFilterOption}
                              options={finalChannels}
                              value={value}
                              id="channelId"
                              onChange={(val) => onChange(val)}
                            />
                          )}
                          name="channelId"
                          control={control}
                        />
                      )}
                    </div>
                  </div>

                  {modalFlag == 'edit' && (
                    <>
                      <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <label className="block mb-1">Member Lists</label>
                          {loadingPackageMemberList ? (
                            <Loader />
                          ) : (
                            <>
                              {' '}
                              {finalMemberLists && (
                                <Select
                                  filterOption={customFilterOption}
                                  value={attachedMembers}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  onChange={onAttachMember}
                                  isMulti
                                  options={finalMemberLists}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 px-0 rounded-b-md mr-0">
                    <button
                      onClick={handleCancelClick}
                      type="button"
                      ref={btnRef}
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 mr-2"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                    <button
                      style={{ marginRight: '0px' }}
                      type="button"
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0 "
                      onClick={handleSubmit(onSubmit)}>
                      {operation}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PackageModal;
