import React, { useEffect } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
const UserProfile = () => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { customField, standardField } = useSelector((state: RootState) => {
    return {
      customField: state.videoLibrary.customField,
      standardField: state.videoLibrary.standardField
    };
  });
  useEffect(() => {
    if (standardField) {
      reset(standardField);
    }
  }, [standardField]);
  useEffect(() => {
    videoLibraryAction.getCustomFieldsRequest();
  }, []);
  const onSubmit = (data: any) => {
    videoLibraryAction.saveStandardFieldsRequest(data);
  };
  return (
    <div className="tab-pane fade" id="tabs-registerFields" role="tabpanel">
      <div className="md:p-5 p-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h2 className="border-b mb-3">Standard Fields</h2>
            <button type="submit" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
              Add
            </button>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="md:w-4/12">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Title Enabled
              </label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="titleEnabled"
                      {...register('titleEnabled')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="md:w-4/12">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Phone Enabled
              </label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="phoneEnabled"
                      {...register('phoneEnabled')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="md:w-4/12">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Organisation Enable
              </label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="organisationEnabled"
                      {...register('organisationEnabled')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-8/12">
            <FormInputField
              label={'Passcode'}
              type={'text'}
              fieldName={'passcode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </form>
        <div className="mt-5">
          <h2 className="border-b mb-3">Custom Fields</h2>
          <div className="flex items-center justify-between mt-2 py-4">
            <div className="flex items-center">
              <div className="mr-3">
                <FormInputField
                  label={''}
                  type={'text'}
                  fieldName={'name'}
                  register={register}
                  placeholder={'Name'}
                  errors={''}
                  isRequired={false}
                />
              </div>
              <div className={`form-group flex items-center mr-3`}>
                <label className="block mb-1 mr-2"> Type </label>
                <select className="input-style w-40">
                  <option>Textbox</option>
                  <option>Checkbox</option>
                </select>
              </div>
              <div className="form-check flex items-center justify-start">
                <input
                  className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                  type="checkbox"
                />
                <label className="ml-1">Required</label>
              </div>
            </div>

            <div>
              <button type="button" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Add
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-100">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Name</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Type</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Required</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Delete</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {customField &&
                  customField.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="p-2">{item.name}</td>
                        <td className="p-2">{item.type}</td>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Questions"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <span
                            className="cursor-pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#DeleteOrganisationModal">
                            <i className="fas fa-trash text-red-500"></i>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
