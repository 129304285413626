import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { useParams, Link } from 'react-router-dom';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import Loader from 'src/components/loader';

const SingleVideoAnalytics = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const { videoId } = useParams<string>();
  const { singleVideoAnalytics, paginationMeta, isSingleVideoAnalyticsLoading } = useSelector(
    (state: RootState) => {
      return {
        singleVideoAnalytics: state.analytics.singleVideoAnalytics,
        paginationMeta: state.analytics.paginationMeta,
        isSingleVideoAnalyticsLoading: state.analytics.isSingleVideoAnalyticsLoading
      };
    }
  );

  useEffect(() => {
    videoId &&
      analyticsAction.getSingleVideoAnalyticsRequest({
        videoIds: [videoId],
        allRecord: true,
        pageNumber: 1,
        pageSize: 50
      });
  }, []);

  const handlePageChange = (pageNumber: number) => {
    videoId &&
      analyticsAction.getSingleVideoAnalyticsRequest({
        videoIds: [videoId],
        allRecord: true,
        pageNumber,
        pageSize: 50
      });
  };

  return (
    <div>
      <>
        <div className="dashboard">
          <Link to={`/analytics/video`}>
            <i className="fas fa-arrow-left mt-2 mb-2"></i>
            <span className="ml-2">Go Back</span>
          </Link>
          <div>
            <div className="mt-2">
              <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Video Sales</h2>
              </div>

              <div className="flex flex-col justify-center h-full">
                <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                  <div className="p-3">
                    <div className="overflow-x-auto">
                      <table className="table-auto w-full border">
                        <thead className="text-xs font-semibold uppercase bg-gray-50">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left">Product Id</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Product Name</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Total Sales</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Total Order</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">First Order</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Last Order</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Product Price</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Average Price</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {isSingleVideoAnalyticsLoading ? (
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {singleVideoAnalytics && singleVideoAnalytics.length ? (
                                singleVideoAnalytics.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2">
                                        <div className="text-left">{item.productID}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{item.productName}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{item.totalSales}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{item.totalOrder}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {item.firstOrder ? defaultDateFormatter(item.firstOrder) : ''}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {item.lastOrder ? defaultDateFormatter(item.lastOrder) : ''}
                                        </div>
                                      </td>

                                      <td className="p-2">
                                        <div className="text-left">{item.productPrice}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{item.averagePrice}</div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-center py-2" colSpan={5}>
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      {!!paginationMeta &&
                        !!Object.keys(paginationMeta).length &&
                        paginationMeta.totalCount > 0 && (
                          <Pagination
                            currentPage={paginationMeta.pageNumber}
                            totalCount={paginationMeta.totalCount}
                            pageSize={paginationMeta.pageSize}
                            onPageChange={(page: any) => handlePageChange(page)}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SingleVideoAnalytics;
