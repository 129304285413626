import React, { useEffect, useState } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { Link, useParams } from 'react-router-dom';
import Editor from 'src/components/Editor';
import { useCourseActions } from 'src/store/course/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SelectModel } from 'src/models/videosModel';
import { RootState } from 'src/store/reducers/state';
import { useChannelActions } from 'src/store/channel/actions';
import { useCategoryActions } from 'src/store/category/actions';
import UploadThumbnail from '../components/UploadThumbnail';
import { useNavigate } from 'react-router-dom';
import Content from './components/Content';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
import VideoContentTypeModal from 'src/components/admin/videos/components/VideoContentTypeModal';
import { customFilterOption } from 'src/components/utilities/utils';
import { useUserActions } from 'src/store/users/actions';
import AddSpeaker from '../../videos/addSpeaker';
const CourseTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const courseActions = useCourseActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const userAction = useUserActions(dispatch);
  const categoryActions = useCategoryActions(dispatch);
  const [editorState, setEditorState] = useState<string>('');
  const [price, setPrice] = useState<number>();
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const [finalContent, setFinalContent] = useState<SelectModel[]>();
  const [step, setStep] = useState<number>(1);
  const [showError, setShowError] = useState<boolean>(false);
  const [publishStatus, setPublishStatus] = useState<number>(1);
  const [speakerName, setSelectedSpeakerName] = useState<string>();
  const [courseId, setCourseId] = useState<string>('');
  const [newChannelId, setNewChannelId] = useState<string>('');
  const [isVideoContentTypeModalOpen, setIsVideoContentTypeModalOpen] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  const [finalSpeaker, setFinalSpeaker] = useState<SelectModel[]>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
    getValues
  } = useForm();
  const channelId = watch('channelId');
  const handleNextClick = () => {
    if (channelId || watch('type') == 2) {
      if (step === 4 && watch('type') == 1 && !id) {
        setStep((x) => x + 2);
      } else {
        setStep((x) => x + 1);
      }
    } else {
      if (watch('type') != 2) {
        setShowError(true);
      }
    }
  };

  const handlePreviousClick = () => {
    if (!id && step === 6) {
      setStep((x) => x - 2);
    } else {
      setStep((x) => x - 1);
    }
  };
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    setRole(finalData.role);
  }, []);
  const {
    mediaUrl,
    channelList,
    contentType,
    singleCourse,
    courseCreditType,
    courseModule,
    singleChannel,
    isCourseSaved,
    isCourseUpdated,
    courseVideos,
    message,
    categoriesList,
    speakers,
    roles,
    memberId
  } = useSelector((state: RootState) => {
    return {
      mediaUrl: state.videos.mediaUrl,
      channelList: state.channel.channelList,
      contentType: state.category.contentType,
      singleCourse: state.course.singleCourse,
      courseCreditType: state.course.courseCreditType,
      courseModule: state.course.courseModule,
      singleChannel: state.channel.singleChannel,
      isCourseSaved: state.course.isCourseSaved,
      isCourseUpdated: state.course.isCourseUpdated,
      courseVideos: state.course.courseVideos,
      message: state.course.message,
      categoriesList: state.category.categoriesList,
      speakers: state.videos.speakers,
      roles: state.users.roles,
      memberId: state.videos.memberId
    };
  });

  useEffect(() => {
    if (memberId) {
      setValue('speakers', [...getValues('speakers'), { value: memberId, label: speakerName }]);
      const speakerId = roles && roles.filter((role) => role.name.toUpperCase() === 'SPEAKER');
      if (speakerId && speakerId.length) {
        videoActions.getSpeakersRequest(speakerId[0]?.id);
      }
    }
  }, [memberId, getValues]);

  useEffect(() => {
    if (singleCourse && Object.keys(singleCourse).length) {
      videoActions.getMediaUrlRequest(singleCourse.description);
    }
  }, [singleCourse]);

  useEffect(() => {
    categoryActions.getCategoriesListRequest();
    userAction.getRolesRequest();
  }, []);

  useEffect(() => {
    if (speakers) {
      const finalspeaker = speakers.map((speaker) => {
        return { value: speaker.id, label: `${speaker.firstName} ${speaker.lastName}` };
      });
      setFinalSpeaker(finalspeaker);
    }
  }, [speakers]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);
  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      const finalChannels = [{ value: '', label: 'Select...' }, ...options];
      setFinalChannels(finalChannels);
    }
  }, [channelList]);

  useEffect(() => {
    if (contentType) {
      const finalData = contentType.map((contentType) => {
        return { value: contentType.id, label: contentType.contentType };
      });
      const finalContentType = [{ value: '', label: 'Select...' }, ...finalData];
      setFinalContent(finalContentType);
    }
  }, [contentType]);

  useEffect(() => {
    if (roles && roles.length) {
      const speakerId = roles.filter((role) => role.name.toUpperCase() === 'SPEAKER');
      if (speakerId && speakerId.length) {
        videoActions.getSpeakersRequest(speakerId[0]?.id);
      }
    }
  }, [roles]);
 
  const inheritPrice = watch('inheritPrice');
  
   useEffect(() => {
      if (inheritPrice) {
        setValue(
          'price',
          singleChannel && (singleChannel.channelCost < 0 || singleChannel.channelCost == null)
            ? 0
            : singleChannel.channelCost
        );
      } else {
        setValue('price', singleCourse && singleCourse.price);
      }
    }, [inheritPrice]);
    useEffect(() => {
      const initialValue = singleCourse.inheritPrice;
      setValue('inheritPrice', initialValue);
    }, [setValue]);

    useEffect(() => {
        if (
          singleCourse &&
          Object.keys(singleCourse).length &&
          singleChannel &&
          Object.keys(singleChannel)
        ) {
         
          singleCourse.inheritPrice &&
            setValue(
              'price',
              singleChannel.channelCost < 0 || singleChannel.channelCost == null
                ? 0
                : singleChannel.channelCost
            );
        }
      }, [singleCourse, singleChannel]);
  const onSubmit = (data: any) => {
    const categories = data.categories && data.categories.map((item: any) => item.value);
    const speakers = data.speakers && data.speakers.map((item: any) => item.value);
    if (data.type == 2 && !id) {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer  ${localStorage.getItem('token')}`);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        name: data.title
      });

      const requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_BASE_API_URL}/v2/channel`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          const channelId = result.result.id;
          setNewChannelId(channelId);
          // Make the second API call to save the course
          const courseHeaders = new Headers();
          courseHeaders.append('Authorization', `Bearer  ${localStorage.getItem('token')}`);
          courseHeaders.append('Content-Type', 'application/json');

          const courseRaw = JSON.stringify({
            channelId: channelId,
            title: data.title,
            type: data.type,
            description: ImageStringConverter(editorState),
            contentTypes: data.contentTypes.value ? data.contentTypes.value : '',
            categories: categories ? categories : [],
            speakers: speakers ? speakers : []
          });

          const courseRequestOptions: any = {
            method: 'POST',
            headers: courseHeaders,
            body: courseRaw,
            redirect: 'follow'
          };

          fetch(`${process.env.REACT_APP_BASE_API_URL}/v2/courses`, courseRequestOptions)
            .then((response) => response.json())
            .then((result) => {
              setCourseId(result.result);
              navigate(`/courseTab/${result.result}`);
            })
            .catch((error) => console.log('error', error));
        })
        .catch((error) => console.log('error', error));
    } else {
      const creditTypes = data.creditTypes && data.creditTypes.map((item: any) => item.value);
      const modules = data.modules && data.modules.map((item: any) => item.value);
      if (id) {
        if (step === 1) {
          const payloadData = {
            id: id,
            patchJsonData: [
              { op: 'replace', path: 'title', value: data.title },
              { op: 'replace', path: 'type', value: data.type },
              { op: 'replace', path: 'channelId', value: data.channelId.value },
              { op: 'replace', path: 'contentTypes', value: data.contentTypes.value },
              { op: 'replace', path: 'categories', value: categories },
              { op: 'replace', path: 'speakers', value: speakers },
              { op: 'replace', path: 'description', value: ImageStringConverter(editorState) }
            ]
          };
          courseActions.updateCourseRequest(payloadData);
        } else if (step === 2) {
          const payloadData = {
            id: id,
            patchJsonData: [
              { op: 'replace', path: 'inheritPrice', value: inheritPrice },
              ...(inheritPrice ? [] : [{ op: 'replace', path: 'price', value: data.price }]),
            ]
          };
          
          courseActions.updateCourseRequest(payloadData);
        } else if (step === 3) {
          const payloadData = {
            id: id,
            patchJsonData: [
              { op: 'replace', path: 'points', value: data.points },
              { op: 'replace', path: 'creditTypes', value: creditTypes },
              { op: 'replace', path: 'modules', value: modules },
              { op: 'replace', path: 'courseCompletionType', value: data.courseCompletionType },
              {
                op: 'replace',
                path: 'courseCompletionPercentage',
                value: data.courseCompletionPercentage
              }
            ]
          };
          courseActions.updateCourseRequest(payloadData);
        } else if (step === 4) {
          const payloadData = {
            id: id,
            patchJsonData: [
              { op: 'replace', path: 'displayCourseProgress', value: data.displayCourseProgress },
              { op: 'replace', path: 'showCourseCompletion', value: data.showCourseCompletion },
              { op: 'replace', path: 'mustCompleteVideos', value: data.mustCompleteVideos },
              { op: 'replace', path: 'externalLink', value: data.externalLink },
              {
                op: 'replace',
                path: 'courseCompletionMessage',
                value: data.courseCompletionMessage
              }
            ]
          };
          courseActions.updateCourseRequest(payloadData);
        } else if (step === 6) {
          const payloadData = {
            id: id,
            patchJsonData: [
              { op: 'replace', path: 'status', value: publishStatus },
              { op: 'replace', path: 'title', value: data.title },
              { op: 'replace', path: 'type', value: data.type },
              { op: 'replace', path: 'channelId', value: data.channelId.value },
              { op: 'replace', path: 'contentTypes', value: data.contentTypes.value },
              { op: 'replace', path: 'categories', value: categories },
              { op: 'replace', path: 'speakers', value: speakers },
              { op: 'replace', path: 'description', value: ImageStringConverter(editorState) },
              { op: 'replace', path: 'points', value: data.points },
              { op: 'replace', path: 'creditTypes', value: creditTypes },
              { op: 'replace', path: 'modules', value: modules },
              { op: 'replace', path: 'courseCompletionType', value: data.courseCompletionType },
              {
                op: 'replace',
                path: 'courseCompletionPercentage',
                value: data.courseCompletionPercentage
              },
              { op: 'replace', path: 'displayCourseProgress', value: data.displayCourseProgress },
              { op: 'replace', path: 'showCourseCompletion', value: data.showCourseCompletion },
              { op: 'replace', path: 'mustCompleteVideos', value: data.mustCompleteVideos },
              { op: 'replace', path: 'externalLink', value: data.externalLink },
              {
                op: 'replace',
                path: 'courseCompletionMessage',
                value: data.courseCompletionMessage
              }
            ]
          };
          courseActions.updateCourseRequest(payloadData);
        }
      } else {
        const payloadData = {
          ...data,
          channelId: data.channelId ? data.channelId.value : '',
          description: ImageStringConverter(editorState),
          creditTypes: creditTypes ? creditTypes : [],
          modules: modules ? modules : [],
          points: data.points ? data.points : 0,
          contentTypes: data.contentTypes.value ? data.contentTypes.value : '',
          status: publishStatus,
          courseCompletionPercentage: data.courseCompletionPercentage
            ? data.courseCompletionPercentage
            : 0,
          courseCompletionType: parseInt(data.courseCompletionType)
        };
        courseActions.saveCourseRequest(payloadData);
      }
    }
  };

  useEffect(() => {
    channelActions.getChannelListRequest();
    categoryActions.getContentTypeRequest({ pageNumber: 1 });
    courseActions.getCourseCreditTypeRequest();
    courseActions.getCourseModuleRequest();
  }, []);

  useEffect(() => {
    if (id) {
      courseActions.getSingleCourseRequest(id);
    }
  }, []);

  useEffect(() => {
    if (singleCourse) {
      let creditTypesData;
      if (singleCourse.creditTypes) {
        creditTypesData = singleCourse.creditTypes.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      let modulesData;
      if (singleCourse.modules) {
        modulesData = singleCourse.modules.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      let categoriesData;
      if (singleCourse.categories) {
        categoriesData = singleCourse.categories.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      let speakerData;
      if (singleCourse.speakers) {
        speakerData = singleCourse.speakers.map((item: any) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      const data = {
        ...singleCourse,
        channelId: {
          value: singleCourse.channelId,
          label: singleCourse.channelName
        },
        creditTypes: creditTypesData,
        modules: modulesData,
        categories: categoriesData,
        speakers: speakerData,
        contentTypes: {
          value: singleCourse.contentTypes && singleCourse.contentTypes[0]?.id,
          label: singleCourse.contentTypes && singleCourse.contentTypes[0]?.name
        }
      };
      setPublishStatus(singleCourse.status);
      reset(data);
      setEditorState(singleCourse.description);
    }
  }, [singleCourse]);

  useEffect(() => {
    if (channelId && channelId.value) {
      setShowError(false);
      channelActions.getSingleChannelRequest(channelId.value);
    }
  }, [channelId]);

  useEffect(() => {
      if(inheritPrice){
        if (singleChannel) {
          setPrice(singleChannel.channelCost);
        }
      }
      else {
          setPrice(singleCourse.price);
      }
  }, [singleChannel]);

  useEffect(() => {
    if (!id) {
      reset({});
      setEditorState('');
      setValue('channelId', '');
      setValue('contentTypes', '');
      setValue('creditTypes', '');
      setValue('modules', '');
      setValue('categories', '');
      setValue('speakers', '');
      setPublishStatus(1);
    }
  }, [id]);

  useEffect(() => {
    if ((isCourseSaved && !id) || (isCourseUpdated && step === 6)) {
      navigate('/courses/course');
    }
  }, [isCourseSaved, isCourseUpdated]);

  useEffect(() => {
    if (id) {
      courseActions.getCourseVideosRequest(id);
    }
  }, [id]);

  useEffect(() => {
    if (isCourseUpdated) {
      courseActions.getSingleCourseRequest(id);
      courseActions.resetCourseStateRequest();
      successMsg('Success');
    }
  }, [isCourseUpdated]);

  useEffect(() => {
    if (message === 'Channel is already exists in other course.') {
      successMsg('Channel already exists in other course. Please select other channel');
      courseActions.resetCourseStateRequest();
    }
  }, [message]);
  return (
    <div>
      <ToastMessage />
      <>
        <div className="mb-8">
          <h2 className="font-semibold my-1">Add Course</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Course</Link>
            </li>
            <li className="active">
              <strong>Add Course</strong>
            </li>
          </ol>
        </div>
        {id ? (
          <h1 className="font-bold text-lg mb-5">{singleCourse && singleCourse.title}</h1>
        ) : (
          <h1 className="font-bold text-lg mb-5">{watch('title')}</h1>
        )}
        <div className="flex justify-between">
          <div className="flex justify-center items-center flex-col">
            <div
              onClick={() => setStep(1)}
              className="w-6 h-6 bg-green-400 rounded-full cursor-pointer"></div>
            <h1 className={`${step === 1 ? 'font-bold' : ''}`}>Course Information</h1>
          </div>
          <div className="flex justify-center items-center flex-col">
            <div
              onClick={() => setStep(2)}
              className={`w-6 h-6 ${
                step >= 2 ? 'bg-green-400' : 'bg-red-400'
              } rounded-full cursor-pointer`}></div>
            <h1 className={`${step === 2 ? 'font-bold' : ''}`}>Pricing</h1>
          </div>
          <div className="flex justify-center items-center flex-col">
            <div
              onClick={() => setStep(3)}
              className={`w-6 h-6 ${
                step >= 3 ? 'bg-green-400' : 'bg-red-400'
              } rounded-full cursor-pointer`}></div>
            <h1 className={`${step === 3 ? 'font-bold' : ''}`}>Education </h1>
          </div>
          <div className="flex justify-center items-center flex-col">
            <div
              onClick={() => setStep(4)}
              className={`w-6 h-6 ${
                step >= 4 ? 'bg-green-400' : 'bg-red-400'
              } rounded-full cursor-pointer`}></div>
            <h1 className={`${step === 4 ? 'font-bold' : ''}`}>Progress</h1>
          </div>
          {(watch('type') == 2 || id) && (
            <div className="flex justify-center items-center flex-col">
              <div
                onClick={() => setStep(5)}
                className={`w-6 h-6 ${
                  step >= 5 ? 'bg-green-400' : 'bg-red-400'
                } rounded-full  cursor-pointer`}></div>
              <h1 className={`${step === 5 ? 'font-bold' : ''}`}>Content</h1>
            </div>
          )}

          <div className="flex justify-center items-center flex-col">
            <div
              onClick={() => setStep(6)}
              className={`w-6 h-6 ${
                step >= 6 ? 'bg-green-400' : 'bg-red-400'
              } rounded-full cursor-pointer`}></div>
            <h1 className={`${step === 6 ? 'font-bold' : ''}`}>Publish</h1>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div id="course-Information" className={`mt-6 ${step === 1 ? '' : 'hidden'}`}>
            <div className="add-video min-h-96 w-full shadow">
              <div className="video-upload p-4 ">
                {id && (
                  <div className="flex justify-end">
                    <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Update
                    </button>
                  </div>
                )}

                <div className="flex">
                  <div className="md:w-1/2 w-full">
                    <div className="form-group mb-4">
                      <FormInputField
                        label={'Title'}
                        type={'text'}
                        fieldName={'title'}
                        register={register}
                        errors={errors}
                        isRequired={true}
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label className="block text-sm font-medium mb-1"> Course Type </label>
                      <select
                        className="input-style"
                        {...register('type', {
                          onChange: (e) => {
                            const selectedValue = parseInt(e.target.value, 10);
                            if (selectedValue === 2) {
                              setShowError(false);
                            } else {
                              setShowError(true);
                            }
                          }
                        })}>
                        <option value={0}>Select</option>
                        <option value={1}>Channel</option>
                        {/* {role === 'Super Admin' &&  */}
                        <option value={2}>New Course</option>
                        {/* } */}
                      </select>
                    </div>
                    {(watch('type') == 0 || watch('type') == 1) && (
                      <div className="form-group mb-4">
                        <label className="block text-sm font-medium mb-1">Channel </label>
                        {finalChannels && (
                          <Controller
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error }
                            }) => (
                              <Select
                                filterOption={customFilterOption}
                                options={finalChannels}
                                value={value}
                                id="channelId"
                                onChange={(val) => onChange(val)}
                                isLoading={channelList.length ? false : true}
                              />
                            )}
                            name="channelId"
                            control={control}
                          />
                        )}
                      </div>
                    )}

                    <div className="form-group mb-4">
                      <label className="block text-sm font-medium mb-1">Content Type </label>

                      {contentType && (
                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <Select
                              filterOption={customFilterOption}
                              options={finalContent}
                              value={value}
                              id="contentTypes"
                              onChange={(val) => onChange(val)}
                              isLoading={contentType.length ? false : true}
                            />
                          )}
                          name="contentTypes"
                          control={control}
                        />
                      )}
                    </div>
                    {watch('type') == 2 && (
                      <>
                        <div className="form-group mb-4">
                          <label className="block text-sm font-medium mb-1">Categories</label>
                          {categoriesList && (
                            <Controller
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  options={
                                    categoriesList &&
                                    categoriesList.categories?.map((category) => {
                                      return {
                                        value: category.id,
                                        label: category.category
                                      };
                                    })
                                  }
                                  value={value}
                                  filterOption={customFilterOption}
                                  id="categories"
                                  onChange={(val) => onChange(val)}
                                  isMulti
                                  isLoading={Object.keys(categoriesList).length ? false : true}
                                />
                              )}
                              name="categories"
                              control={control}
                            />
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <label className="block text-sm font-medium mb-1">Speaker </label>
                          {speakers && (
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState: { error }
                              }) => (
                                <Select
                                  options={finalSpeaker}
                                  value={value}
                                  className="w-full mr-2"
                                  filterOption={customFilterOption}
                                  id="speakers"
                                  isMulti
                                  onChange={(val) => onChange(val)}
                                  isLoading={speakers.length ? false : true}
                                />
                              )}
                              name="speakers"
                              control={control}
                            />
                          )}
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#AddSpeakerModal"
                            className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-2">
                            <i className="fas fa-plus pr-1"></i> Add
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  {id && (
                    <div className={`form-group  thumbnail-main ml-7`}>
                      <label className="block text-sm font-medium mb-1">Thumbnail </label>
                      <div className="relative thumb-wrap">
                        <img
                          src={singleCourse && singleCourse.thumbnailUrl}
                          style={{ aspectRatio: '16 / 9' }}
                        />
                        <div className="flex flex-wrap justify-center items-center pb-4 rounded-b-md absolute inset-0 btns-main">
                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#uploadCourseThumbnail"
                            type="button"
                            className="mx-0 px-6 btn-primary w-28 mt-0 mr-2">
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className={`form-group mb-4 ${id ? '' : 'md:w-1/2'} w-full`}>
                  <label className="block text-sm font-medium mb-1"> Description</label>
                  <Editor editorState={editorState} setEditorState={setEditorState} />
                </div>
                {showError && (
                  <span className="text-red-500 text-xs flex item-center error">
                    Please Select Channel
                  </span>
                )}
                <div className={`flex items-center mt-6 justify-end ${id ? '' : ' '}`}>
                  {watch('type') == 2 && !id ? (
                    <>
                      {' '}
                      <button type="submit" className="mx-0 px-6 btn-primary w-auto mt-0">
                        Save
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={handleNextClick}
                      type="button"
                      className="mx-0 px-6 btn-primary  w-auto mt-0">
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div id="pricing" className={`mt-6 ${step === 2 ? '' : 'hidden'}`}>
            <div className="add-video min-h-96 w-full shadow">
              <div className="video-upload p-4">
              <div className="flex justify-end">
                    <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Update
                    </button>
                  </div>
                {/* {watch('type') === 2 && (
                  <div className="flex justify-end">
                    <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Update
                    </button>
                  </div>
                )} */}
                <div className="video-upload lg:w-3/5">
                  {watch('type') === 1 ? (
                    <>
                      <div className="form-group mb-4">
                        <label className="block text-sm font-medium mb-1">Price</label>
                        <input
                          className={`input-style ${ inheritPrice ? 'bg-gray-300':  ''}`}
                          disabled={ inheritPrice}
                          type="text"
                          id="price"
                          {...register('price')}
                        />
                      </div>
                      <div className="form-group mb-4">
                        <label className="block text-sm font-medium mb-1">
                          Inherit from Channel
                        </label>
                        <div className="form-check form-switch pl-0 mr-3 relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                            id="inheritPrice"
                            {...register('inheritPrice')}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <div className="lg:grid lg:grid-cols-3 gap-4">
                        <div className="form-group mb-4 w-full">
                          <label className="block text-sm font-medium mb-1">
                            Access Type <span className="text-red-500">*</span>
                          </label>
                          <select
                            className="border input-style"
                            id="videoType"
                            {...register('videoType')}>
                            <option value="">Select</option>
                            <option value="Free">Free</option>
                            <option value="Paid">Paid</option>
                            <option value="Locked">Locked</option>
                          </select>
                        </div>
                        {watch('videoType') === 'Paid' && (
                          <div className="flex items-center">
                            <div className={`form-group mb-4 w-full`}>
                              <label className="block text-sm font-medium mb-1">Price</label>
                              <input
                                className="input-style"
                                type="text"
                                id="price"
                                {...register('price')}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="flex items-center mt-10 justify-between">
                  <button
                    onClick={handlePreviousClick}
                    type="button"
                    className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                    Previous
                  </button>
                  <button
                    onClick={handleNextClick}
                    type="button"
                    className="mx-0 px-6 btn-primary w-auto mt-0">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="education" className={`mt-6 ${step === 3 ? '' : 'hidden'}`}>
            <div className="add-video min-h-96 w-full shadow">
              <div className="video-upload p-4">
                {id && (
                  <div className="flex justify-end">
                    <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Update
                    </button>
                  </div>
                )}
                <div className="video-upload  lg:w-3/5">
                  <div className="form-group mb-4">
                    <FormInputField
                      label={'CE Credits'}
                      type={'text'}
                      fieldName={'points'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium mb-1">Credit Type </label>
                    {courseCreditType && (
                      <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Select
                            options={courseCreditType.map((item) => {
                              return {
                                value: item.id,
                                label: item.type
                              };
                            })}
                            filterOption={customFilterOption}
                            value={value}
                            id="creditTypes"
                            isMulti
                            onChange={(val) => onChange(val)}
                          />
                        )}
                        name="creditTypes"
                        control={control}
                      />
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium mb-1">Module </label>
                    {courseModule && (
                      <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Select
                            options={courseModule.map((item) => {
                              return {
                                value: item.id,
                                label: item.module
                              };
                            })}
                            filterOption={customFilterOption}
                            value={value}
                            id="modules"
                            isMulti
                            onChange={(val) => onChange(val)}
                          />
                        )}
                        name="modules"
                        control={control}
                      />
                    )}
                  </div>

                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium mb-1">
                      Completion Requirements
                    </label>
                    <select className="input-style" {...register('courseCompletionType')}>
                      <option value={0}>Select</option>
                      <option value={1}>Quiz Completion</option>
                      <option value={2}>Video Completion</option>
                    </select>
                  </div>
                  <div className="form-group mb-4">
                    <FormInputField
                      label={'Pass Rate'}
                      type={'text'}
                      fieldName={'courseCompletionPercentage'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="flex items-center mt-6 justify-between">
                  <button
                    onClick={handlePreviousClick}
                    type="button"
                    className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                    Previous
                  </button>
                  <button
                    onClick={handleNextClick}
                    type="button"
                    className="mx-0 px-6 btn-primary w-auto mt-0">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="progress" className={`mt-6 ${step === 4 ? '' : 'hidden'}`}>
            <div className="add-video min-h-96 w-full shadow">
              <div className="video-upload p-4 ">
                {id && (
                  <div className="flex justify-end">
                    <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Update
                    </button>
                  </div>
                )}
                <div className="video-upload lg:w-3/5">
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium mb-1">
                      Display Course Progress
                    </label>
                    <div className="form-check form-switch pl-0 mr-3 relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="displayCourseProgress"
                        {...register('displayCourseProgress')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                    </div>
                  </div>
                  <div className="form-group mb-4 mt-10">
                    <label className="block text-sm font-medium mb-1">Show Course Completion</label>
                    <div className="form-check form-switch pl-0 mr-3 relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="showCourseCompletion"
                        {...register('showCourseCompletion')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                    </div>
                  </div>
                  <div className="form-group mb-4 mt-10">
                    <label className="block text-sm font-medium mb-1">
                      Must complete each Lesson before Progressing
                    </label>
                    <div className="form-check form-switch pl-0 mr-3 relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="mustCompleteVideos"
                        {...register('mustCompleteVideos')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                    </div>
                  </div>
                  <div className="form-group mb-4 mt-10">
                    <FormInputField
                      label={'External Link'}
                      type={'text'}
                      fieldName={'externalLink'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <div className="form-group mb-4">
                    {/* <FormInputField
                    label={'Course Completion Message'}
                    type={'text'}
                    fieldName={'courseCompletionMessage'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  /> */}
                    <label className="block mb-1"> Course Completion Message </label>
                    <textarea
                      className="input-style"
                      {...register('courseCompletionMessage')}
                      rows={3}></textarea>
                  </div>
                </div>
                <div className="flex items-center mt-6 justify-between">
                  <button
                    onClick={handlePreviousClick}
                    type="button"
                    className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                    Previous
                  </button>
                  <button
                    onClick={handleNextClick}
                    type="button"
                    className="mx-0 px-6 btn-primary w-auto mt-0">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          {(watch('type') == 2 || id) && (
            <div id="content" className={`mt-6 ${step === 5 ? '' : 'hidden'}`}>
              <div className="add-video min-h-96 w-full shadow">
                <div className="video-upload p-4">
                  <Content newChannelId={newChannelId} type={watch('type')} />
                  <div className="flex items-center mt-6 justify-between">
                    <button
                      onClick={handlePreviousClick}
                      type="button"
                      className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                      Previous
                    </button>
                    <button
                      onClick={handleNextClick}
                      type="button"
                      className="mx-0 px-6 btn-primary w-auto mt-0">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div id="publish" className={`mt-6 ${step === 6 ? '' : 'hidden'}`}>
            <div className="add-video min-h-96 w-full shadow">
              <div className="video-upload p-4 ">
                <div className="video-upload lg:w-3/5">
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium mb-1">Publish Status</label>
                    <div>
                      <div className="flex items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="channelType"
                            value={publishStatus}
                            onChange={() => setPublishStatus(1)}
                            checked={publishStatus === 1 ? true : false}
                          />
                          <label className="w-16" htmlFor="paid">
                            Draft
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="channelType"
                            value={publishStatus}
                            onChange={() => setPublishStatus(2)}
                            checked={publishStatus === 2 ? true : false}
                          />
                          <label className="w-16" htmlFor="free">
                            Online
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="channelType"
                            value={publishStatus}
                            onChange={() => setPublishStatus(3)}
                            checked={publishStatus === 3 ? true : false}
                          />
                          <label className="w-16" htmlFor="locked">
                            Offline
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center mt-6 justify-between">
                  <button
                    onClick={handlePreviousClick}
                    type="button"
                    className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                    Previous
                  </button>
                  <button type="submit" className="mx-0 px-6 btn-primary  w-auto mt-0">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
      <UploadThumbnail courseId={id} />
      <AddSpeaker setSelectedSpeakerName={setSelectedSpeakerName} />
    </div>
  );
};

export default CourseTab;
