import React, { useRef } from 'react';
import VideoMonitor from '../components/VideoMonitor';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import VideoMonitorForMp4 from '../VideoPlayerForMp4';
const ViewRecordingModal = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { archiveUrl } = useSelector((state: RootState) => {
    return {
      archiveUrl: state.videos.archiveUrl
    };
  });
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="ViewRecordingModal"
      aria-labelledby="ViewRecordingModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <button
              ref={btnRef}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div className="modal-body relative py-4 px-10">
            <VideoMonitorForMp4 src={archiveUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewRecordingModal;
