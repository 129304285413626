import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SingleVideoModel } from 'src/models/videosModel';
import { RootState } from 'src/store/reducers/state';

interface IProps {
  singleVideo: SingleVideoModel;
  videoId: string;
  setClipboard: (value: boolean) => void;
}
const ShareVideoModal: React.FC<IProps> = ({ singleVideo, videoId, setClipboard }: IProps) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [isRedirectedToLogin, setIsRedirectedToLogin] = useState<boolean>();
  const [playPageCopyToClipBoard, setPlayPageCopyToClipBoard] = useState<string>('');
  const {
    register,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  useEffect(() => {
    if (singleVideo) {
      reset(singleVideo);
      setIsRedirectedToLogin(singleVideo.redirectToLoginUrlEnabled);
      setPlayPageCopyToClipBoard(singleVideo.playUrl);
      singleVideo.videoPlayerUrl &&
        setValue(
          'iframe',
          `<iframe width="640" height="360" src="${singleVideo.videoPlayerUrl}" frameborder="0" allowfullscreen></iframe>`
        );
      setValue('auth', singleVideo.authKey);
    }
  }, [singleVideo]);

  const handleGenerateClick = () => {
    videoAction.generateAuthKeyRequest(videoId);
  };

  const handleRedirectClick = (e: boolean) => {
    const payloadData = { videoId: videoId, redirectToLogin: e };
    videoAction.updateRedirectToLoginRequest(payloadData);
    if (singleVideo) {
      const playUrlSplitted = singleVideo.playUrl.includes('&redirecttologinurl=true')
        ? singleVideo.playUrl.split('&redirecttologinurl=true')[0]
        : singleVideo.playUrl;
      if (e) {
        setIsRedirectedToLogin(true);
        setValue('playUrl', `${playUrlSplitted}&redirecttologinurl=true`);
        setPlayPageCopyToClipBoard(`${playUrlSplitted}&redirecttologinurl=true`);
      } else {
        setIsRedirectedToLogin(false);
        setValue('playUrl', playUrlSplitted);
        setPlayPageCopyToClipBoard(playUrlSplitted);
      }
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="ShareVideoModal"
        aria-labelledby="ShareVideoModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="ShareVideoModalLabel">
                Share
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <div className="flex items-center">
                  <div className={`form-group mb-3 w-full`}>
                    <FormInputField
                      label={'Play Page'}
                      type={'text'}
                      fieldName={'playUrl'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <i
                    className="color fa fa-copy ml-5 text-lg cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(playPageCopyToClipBoard), setClipboard(true);
                    }}></i>
                </div>

                <div className="flex items-center">
                  <div className={`form-group mb-3 w-full`}>
                    <FormInputField
                      label={'Video Player'}
                      type={'text'}
                      fieldName={'videoPlayerUrl'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <i
                    className="color fa fa-copy ml-5 text-lg cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(singleVideo && singleVideo.videoPlayerUrl),
                        setClipboard(true);
                    }}></i>
                </div>
                <div className="flex items-center">
                  <div className={`form-group mb-3 w-full`}>
                    <label className="mb-1 block text-sm">iFrame</label>
                    <textarea className="input-style" {...register('iframe')} rows={4}></textarea>
                  </div>
                  <i
                    className="color fa fa-copy ml-5 text-lg cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<iframe width="640" height="360" src="${
                          singleVideo && singleVideo.videoPlayerUrl
                        }" frameborder="0" allowfullscreen></iframe>`
                      ),
                        setClipboard(true);
                    }}></i>
                </div>
                <div className="flex items-center">
                  <div className={`form-group mb-3 w-full`}>
                    <input type="text" disabled className="input-style" {...register('auth')} />
                    {(singleVideo.authKey === null || '') && (
                      <div className="mt-3" onClick={handleGenerateClick}>
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                          Generate New Auth Key
                        </button>
                      </div>
                    )}
                  </div>
                  <i className="color fa fa-copy ml-5 text-lg invisible"></i>
                </div>

                <div className="flex mt-5 items-center">
                  <div className="form-check form-switch pl-0 relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      checked={isRedirectedToLogin}
                      onChange={(e) => handleRedirectClick(e.target.checked)}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                  <label
                    className="form-check-label inline-block font-semibold ml-1 text-md"
                    htmlFor="redirect">
                    Redirect to Login Page
                  </label>
                </div>
              </form>
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareVideoModal;
