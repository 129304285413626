import React, { useState, useEffect } from 'react';
import BarChart from 'src/components/charts/barchart/BarChart';
import { saveAs } from 'file-saver';
import { getColor } from 'src/components/utilities/utils';
const ResultComponent = ({ resultData }: any) => {
  return (
    <div id="QuestionResultDiv">
      {resultData && resultData.pollType === 4 ? (
        <>
          {resultData &&
            resultData?.quizzes?.questions?.map((item: any) => {
              if (item?.isStarRatingQuestion) {
                return (
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <div>
                        <h5 className="font-bold">
                          {item.position}. {item.questionText}
                        </h5>
                      </div>
                      <div className="badge-pollAttended flex justify-center">
                        {resultData?.quizzes?.pollAttended}
                      </div>
                    </div>
                    {item.description && (
                      <div className="flex mt-2 font-bold ">
                        <div className="">
                          <label className="">Description:</label>
                        </div>
                        <div className="ml-7">
                          <label>{item.description}</label>
                        </div>
                      </div>
                    )}

                    <p>Max Value : {item?.choices && item?.choices[0]?.answerText}</p>
                    <BarChart
                      options={{
                        indexAxis: 'y',
                        responsive: true
                      }}
                      chartData={{
                        labels:
                          resultData?.ratingForChartResultLabels &&
                          resultData?.ratingForChartResultLabels[`${item.position}`]?.map(
                            (data: any) => data
                          ),
                        datasets: [
                          {
                            label: '',
                            data:
                              resultData?.ratingForChartResultValues &&
                              resultData?.ratingForChartResultValues[`${item.position}`]?.map(
                                (data: any) => data
                              ),
                            backgroundColor: ['#4285F4']
                          }
                        ]
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="mt-4" key={item.id}>
                    <div className="mt-1">
                      <div>
                        <div>
                          <h5 className="font-bold ">
                            {item.position}. {item.questionText}
                          </h5>
                        </div>
                        <div className="flex mt-2 font-bold ">
                          <div className="">
                            <label className="">Description:</label>
                          </div>
                          <div className="ml-7">
                            <label>{item.description}</label>
                          </div>
                        </div>
                        {item.choices &&
                          item.choices.map((choice: any) => {
                            return (
                              <div key={choice.id}>
                                <div
                                  className={`px-2 py-3 ${
                                    choice.isAnswer ? 'pollResult-background' : ''
                                  }`}>
                                  <span className="">
                                    {choice.answerText} &nbsp;&nbsp;({choice.votedPercentage}
                                    %,&nbsp;
                                    {choice.votedCount} Votes)
                                  </span>
                                  <div className={`w-full h-3 rounded-full mt-4 bg-gray-100`}>
                                    <div
                                      className={` bg-green-400 text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                                      style={{ width: `${choice.votedPercentage}%` }}></div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <hr className="mt-4" />
                  </div>
                );
              }
            })}
        </>
      ) : resultData && resultData.pollType === 2 ? (
        <>
          {resultData &&
            resultData?.quizzes?.questions?.map((item: any) => {
              return (
                <div className="mt-4" key={item.id}>
                  <div className="mt-1">
                    <div className="flex justify-between">
                      <div>
                        <h5 className="font-bold">
                          {item.position}. {item.questionText}
                        </h5>
                      </div>
                      <div className="badge-pollAttended flex justify-center">
                        {resultData?.quizzes?.pollAttended}
                      </div>
                    </div>
                    {item.description && (
                      <div className="flex mt-2 font-bold ">
                        <div className="">
                          <label className="">Description:</label>
                        </div>
                        <div className="ml-1">
                          <label>{item.description}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              );
            })}
          {
            <div className="border">
              <ul className="">
                {resultData &&
                  resultData?.pollResults?.map((item: any) => {
                    return (
                      <div key={item.id} className="border-b-2 p-5">
                        <p>{item?.username} :</p>
                        <li className="">{item?.resultDetails[0]?.submittedAnswerText}</li>
                      </div>
                    );
                  })}
              </ul>
            </div>
          }
        </>
      ) : (
        <>
          {resultData &&
            resultData?.quizzes?.questions?.map((item: any) => {
              return (
                <div className="mt-4" key={item.id}>
                  <div className="mt-1">
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <h5 className="font-bold">
                            {item.position}. {item.questionText}
                          </h5>
                        </div>
                        <div className="badge-pollAttended flex justify-center">
                          {resultData?.quizzes?.pollAttended}
                        </div>
                      </div>
                      {resultData.pollType === 0 && !item.quizOpenTextQuestion && (
                        <div className="flex mt-2 font-bold ">
                          <div>
                            <label>Description:</label>
                          </div>
                          <div className="ml-1">
                            <label>{item.description}</label>
                          </div>
                        </div>
                      )}

                      {item.choices &&
                        item.choices.map((choice: any, index: number) => {
                          return (
                            <div key={choice.id}>
                              <div
                                className={`px-2 py-3 ${
                                  choice.isAnswer ? 'pollResult-background' : ''
                                }`}>
                                <span className="">
                                  {choice.answerText} &nbsp;&nbsp;({choice.votedPercentage}%,&nbsp;
                                  {choice.votedCount} Votes)
                                </span>
                                <div className={`w-full h-3 rounded-full mt-4 bg-gray-100`}>
                                  <div
                                    className={` text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                                    style={{
                                      width: `${choice.votedPercentage}%`,
                                      backgroundColor: `${getColor(index)}`
                                    }}></div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {resultData.pollType === 2 ? (
                      <>
                        {' '}
                        <div className="flex mt-2 font-bold ">
                          <div className="">
                            <label className="">Description:</label>
                          </div>
                          <div className="ml-7">
                            <label>{item.description}</label>
                          </div>
                        </div>
                      </>
                    ) : resultData.pollType === 1 ? (
                      <>
                        <div className="form-check flex items-center justify-start">
                          <input
                            disabled
                            checked={item.hasMultipleAnswers}
                            className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                            type="checkbox"
                          />
                          <label className="ml-1">Allow to select Multiple Answer</label>
                        </div>
                      </>
                    ) : resultData.pollType === 0 && item.quizOpenTextQuestion ? (
                      <>
                        <div className=" mt-2 font-bold ">
                          <div>
                            <label className="">Answer: {item.textAnswer}</label>
                          </div>
                          <div className="mt-2">
                            <label>Description: {item.description}</label>
                          </div>
                        </div>
                        <div className="border  mt-2">
                          <ul className="">
                            {resultData &&
                              resultData?.pollResults?.map((item: any) => {
                                return (
                                  <div key={item.id} className="border-b-2 p-5">
                                    <div className="flex justify-between">
                                      <div>
                                        <p>{item.username} :</p>
                                        <li className="">
                                          {item?.resultDetails[0]?.submittedAnswerText}
                                        </li>
                                      </div>
                                      <div className="cursor-pointer">
                                        {item?.resultDetails[0]?.questionFileUrl && (
                                          <i
                                            onClick={() =>
                                              saveAs(item?.resultDetails[0]?.questionFileUrl)
                                            }
                                            className="color fa fa-download h6 "></i>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="flex mt-2 font-bold ">
                          <div className="">
                            <label className="">Points</label>
                          </div>
                          <div className="ml-7">
                            <label>{item.points}</label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {' '}
                        <div className="flex mt-2 font-bold ">
                          <div className="">
                            <label className="">Points</label>
                          </div>
                          <div className="ml-7">
                            <label>{item.points}</label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <hr />
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default ResultComponent;
