import { all, put, takeEvery } from 'redux-saga/effects';
import { VideosActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetVideoAnalytics() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_VIDEO_ANALYTICS.url);
        if (response.status === 200) {
            yield put(VideosActions.getVideoAnalyticsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsRequestFailure());
    }
}
function* asyncGetVideos(data: VideosActions.getVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEOS.url}?pageNumber=${data.payload?.pageNumber}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideosRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideosRequestFailure());
    }
}

function* asyncStartMediaStreamRequestActionType(data: VideosActions.startMediaStreamRequestAction) {
    try {
        const payloadData = { videoId: data.payload.VideoId }
        const response: AxiosResponse = yield ApiService.postData(URLS.START_MEDIA_STREAM.url, payloadData);

        if (response.status === 200) {
            yield put(VideosActions.startMediaStreamRequestSuccess({ data: response }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.startMediaStreamRequestFailure());
    }
}

function* asyncStartMediaChannelRequestActionType(data: VideosActions.startMediaChannelRequestAction) {
    try {
        const payloadData = { videoId: data.payload.VideoId }
        const response: AxiosResponse = yield ApiService.postData(URLS.START_MEDIA_CHANNEL.url, payloadData);

        if (response.status === 200) {
            yield put(VideosActions.startMediaChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.startMediaChannelRequestFailure());
    }
}

function* asyncStopMediaStreamRequestActionType(data: VideosActions.stopMediaStreamRequestAction) {
    try {
        const payloadData = { videoId: data.payload.VideoId }
        const response: AxiosResponse = yield ApiService.postData(URLS.STOP_MEDIA_STREAM.url, payloadData);

        if (response.status === 200) {
            yield put(VideosActions.stopMediaStreamRequestSuccess({ data: response }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.stopMediaStreamRequestFailure());
    }
}

function* asyncMediaChannelStatusRequestActionType(data: VideosActions.mediaChannelStatusRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.MEDIA_CHANNEL_STATUS.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.mediaChannelStatusRequestSuccess({ primaryChannelStatus: response.data.result.primaryChannelStatus, inUse: response.data.result.inUse }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.mediaChannelStatusRequestFailure());
    }
}

function* asyncGetSingleVideo(data: VideosActions.getVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_VIDEO.url}/${data.payload}`);
        if (response.status === 200) {
            const speakerIds = response.data.result.speakers.map((item: any) => {
                return {
                    value: item.memberId,
                    label: `${item.firstName} ${item.lastName}`
                };
            });
            const finalData = { speakerIds: speakerIds, ...response.data.result }
            yield put(VideosActions.getSingleVideosRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSingleVideosRequestFailure());
    }
}
function* asyncAddVideo(data: VideosActions.addVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_VIDEO.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.addVideoRequestSuccess(response.data.result.videoId));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.addVideoRequestFailure());
    }
}
function* asyncEditVideo(data: VideosActions.editVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.EDIT_VIDEO.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.editVideoRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.editVideoRequestFailure());
    }
}
function* asyncDeleteVideo(data: VideosActions.deleteVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_VIDEO.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteVideoRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteVideoRequestFailure());
    }
}
function* asyncAddLiveStream(data: VideosActions.addLiveStreamRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_LIVESTREAM.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.addLiveStreamRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.addLiveStreamRequestFailure());
    }
}
function* asyncAddCompleteUpload(data: VideosActions.addCompleteUploadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data);
        if (response.status === 200) {
            yield put(VideosActions.addCompleteUploadRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.addCompleteUploadRequestFailure());
    }
}
function* asyncCancelUpload(data: VideosActions.cancelUploadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/video/cancelupload`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.cancelUploadRequestSuccess());
            sessionStorage.setItem('cancel', 'true')
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.cancelUploadRequestFailure());
    }
}
function* asyncTestUpload(data: VideosActions.testUploadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/video/testupload`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.testUploadRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.testUploadRequestFailure());
    }
}
function* asyncUploadPartUrl(data: VideosActions.getuploadPartUrlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/video/uploadparturl?UploadId=${data.payload.uploadId}&FileName=${data.payload.fileName}&PartNumber=${data.payload.PartNumber}`);
        if (response.status === 200) {
            yield put(VideosActions.getuploadPartUrlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getuploadPartUrlRequestFailure());
    }
}

function* asyncUploadResourceFile(data: any) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.UPLOAD_RESOURCE_FILE.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.addResourceUploadRequestSuccess(response.data.result));
        }
    }
    catch (error) {
        console.log(error);
        yield put(VideosActions.addResourceUploadRequestFailure());
    }
}

function* asyncGetResourceDetail(data: any) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_RESOURCE_DETAIL.url}/${data.payload}`);
        if (response.status === 200) {  
            yield put(VideosActions.getResourceDetailRequestSuccess(response.data.result));
        }
    }
    catch (error) {
        console.log(error);
        yield put(VideosActions.getResourceDetailRequestFailure());
    }
}

function* asyncAddSpeaker(data: VideosActions.addSpeakerRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_VIDEO_SPEAKERS.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.addSpeakerRequestSuccess(response.data.result.memberId));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.addSpeakerRequestFailure());
    }
}

function* asyncSearchVideo(data: VideosActions.searchVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SEARCH_VIDEO.url, data.payload);
        if (response.status === 200) {
            if (response.data.result === null) {
                yield put(VideosActions.searchVideoRequestSuccess({ data: [], paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
            } else {
                yield put(VideosActions.searchVideoRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
            }
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.searchVideoRequestFailure());
    }
}
function* asyncGetVideoSlides(data: VideosActions.getVideoSlidesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_SLIDES.url}?VideoId=${data.payload?.id}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoSlidesRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoSlidesRequestFailure());
    }
}
function* asyncSaveVideoSlides() {
    try {
        const response: AxiosResponse = yield ApiService.getData(``);
        if (response.status === 200) {
            yield put(VideosActions.saveVideoSlidesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveVideoSlidesRequestFailure());
    }
}
function* asyncUpdateVideoSlides(data: VideosActions.updateVideoSlidesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.UPDATE_VIDEO_SLIDES.url}?videoId=${data.payload?.videoId}&id=${data.payload?.id}&isNext=${data.payload?.isNext}&dateTime=${data.payload?.dateTime}`);
        if (response.status === 200) {
            yield put(VideosActions.updateVideoSlidesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateVideoSlidesRequestFailure());
    }
}
function* asyncShareVideo(data: VideosActions.shareVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SHARE_VIDEO.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.shareVideoRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.shareVideoRequestFailure());
    }
}
function* asyncGetSpeakers(data: VideosActions.getSpeakersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_SPEAKERS.url}?PageSize=9999&RoleId=${data.payload}&SortFieldName=firstName`);
        if (response.status === 200) {
            yield put(VideosActions.getSpeakersRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSpeakersRequestFailure());
    }
}
function* asyncGetInterActivity(data: VideosActions.getInterActivityRequestFailureAction) {

    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_INTERACTIVTIY.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getInterActivityRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getInterActivityRequestFailure());
    }
}
function* asyncUpdateInterActivity(data: VideosActions.updateInterActivityRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_INTERACTIVTIY.url}?id=${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updateInterActivityRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateInterActivityRequestFailure());
    }
}
function* asyncGetAccessibility(data: VideosActions.getAccessibilityRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ACCESSIBILITY.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getAccessibilityRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getAccessibilityRequestFailure());
    }
}
function* asyncUpdateAccessibility(data: VideosActions.updateAccessibilityRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_ACCESSIBILITY.url}?id=${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updateAccessibilityRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateAccessibilityRequestFailure());
    }
}
function* asyncGetPlayPage(data: VideosActions.getPlayPageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_PLAY_PAGE.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getPlayPageRequestRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getPlayPageRequestRequestFailure());
    }
}
function* asyncUpdatePlayPage(data: VideosActions.updatePlayPageRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_PLAY_PAGE.url}?videoId=${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updatePlayPageRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updatePlayPageRequestFailure());
    }
}
function* asyncGetMemberListAccess(data: VideosActions.getMemberListAccessRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERLIST_ACCESS.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getMemberListAccessRequestRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getMemberListAccessRequestRequestFailure());
    }
}
function* asyncSaveMemberListAccess(data: VideosActions.saveMemberListAccessRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveMemberListAccessRequestRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveMemberListAccessRequestRequestFailure());
    }
}
function* asyncDeleteMemberListAccess(data: VideosActions.deleteMemberListAccessRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MEMBERLIST_ACCESS.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteMemberListAccessRequestRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteMemberListAccessRequestRequestFailure());
    }
}
function* asyncGetResources(data: VideosActions.getResourcesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_RESOURCES.url}?videoId=${data.payload?.id}&pageNumber=${data.payload?.pageNumber}`);
        if (response.status === 200) {
            yield put(VideosActions.getResourcesRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getResourcesRequestFailure());
    }
}
function* asyncAddResources(data: VideosActions.addResourcesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_RESOURCES.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.addResourcesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.addResourcesRequestFailure());
    }
}
function* asyncEditResources(data: VideosActions.editResourcesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.EDIT_RESOURCES.url}?id=${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.editResourcesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.editResourcesRequestFailure());
    }
}
function* asyncGetSingleResources(data: VideosActions.getSingleResourcesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_RESOURCES.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getSingleResourcesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSingleResourcesRequestFailure());
    }
}
function* asyncDeleteResources(data: VideosActions.deleteResourcesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.EDIT_RESOURCES.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteResourcesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteResourcesRequestFailure());
    }
}
function* asyncGetMemberListPrice(data: VideosActions.getMemberListPriceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERLIST_PRICE.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getMemberListPriceRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getMemberListPriceRequestFailure());
    }
}
function* asyncSaveMemberListPrice(data: VideosActions.saveMemberListPriceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_MEMBERLIST_PRICE.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveMemberListPriceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveMemberListPriceRequestFailure());
    }
}
function* asyncDeleteMemberListPrice(data: VideosActions.deleteMemberListPriceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MEMBERLIST_PRICE.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteMemberListPriceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteMemberListPriceRequestFailure());
    }
}
function* asyncGetModerator(data: VideosActions.getModeratorRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MODERATORS.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getModeratorRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getModeratorRequestFailure());
    }
}
function* asyncSaveModerator(data: VideosActions.saveModeratorsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MODERATORS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveModeratorsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveModeratorsRequestFailure());
    }
}
function* asyncDeleteModerator(data: VideosActions.deleteModeratorsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MODERATORS.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteModeratorsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteModeratorsRequestFailure());
    }
}
function* asyncGetCallToAction(data: VideosActions.getCallToActionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CALL_TO_ACTION.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getCallToActionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getCallToActionRequestFailure());
    }
}
function* asyncSaveCallToAction(data: VideosActions.saveCallToActionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_CALL_TO_ACTION.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveCallToActionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveCallToActionRequestFailure());
    }
}
function* asyncDeleteCallToAction(data: VideosActions.deleteCallToActionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CALL_TO_ACTION.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteCallToActionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteCallToActionRequestFailure());
    }
}
function* asyncGetSingleCallToAction(data: VideosActions.getSingleCallToActionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CALL_TO_ACTION.url}?id=${data.payload}`);
        if (response.status === 200) {
            console.log(response)
            yield put(VideosActions.getSingleCallToActionRequestSuccess(response.data.result[0]));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSingleCallToActionRequestFailure());
    }
}
function* asyncUpdateCallToAction(data: VideosActions.updateCallToActionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CALL_TO_ACTION.url}?videoCTAId=${data.payload?.videoCTAId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updateCallToActionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateCallToActionRequestFailure());
    }
}
function* asyncGetVideoViewingSession(data: VideosActions.getVideoViewingSessionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_VIDEO_SESSION.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.getVideoViewingSessionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoViewingSessionRequestFailure());
    }
}
function* asyncSaveSlides(data: VideosActions.saveSlidesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_SLIDE.url}?videoId=${data.payload?.id}`, [
            {
                "imageURL": data.payload?.uploadThumbnail,
                "imageURLs3": data.payload?.uploadThumbnail,
            }
        ]);
        if (response.status === 200) {
            yield put(VideosActions.saveSlidesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveSlidesRequestFailure());
    }
}
function* asyncGetSlides(data: VideosActions.getVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEOS.url}?pageNumber=${data.payload?.pageNumber}`);
        if (response.status === 200) {
            yield put(VideosActions.getSlidesRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSlidesRequestFailure());
    }
}
function* asyncUpdateSlides(data: VideosActions.saveSlidesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_RESOURCES.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.updateSlidesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateSlidesRequestFailure());
    }
}
function* asyncSavePoll(data: VideosActions.savePollRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_POLLS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.savePollRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.savePollRequestFailure());
    }
}
function* asyncGetPolls(data: VideosActions.getPollRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_POLLS.url}?VideoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getPollRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getPollRequestFailure());
    }
}
function* asyncUpdatePoll(data: VideosActions.updatePollRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_POLLS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.updatePollRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updatePollRequestFailure());
    }
}
function* asyncGetllPollsTemplate(data: VideosActions.getPollsTemplatesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_POLLS_TEMPLATES.url}`);
        if (response.status === 200) {
            yield put(VideosActions.getPollsTemplatesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getPollsTemplatesRequestFailure());
    }
}
function* asyncSaveQuestion(data: VideosActions.saveQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_RESOURCES.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveQuestionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveQuestionRequestFailure());
    }
}
function* asyncUpdateQuestion(data: VideosActions.updateQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.UPDATE_QUESTION.url}/${data.payload?.questionId}?questionType=${data.payload?.updateData}`);
        if (response.status === 200) {
            yield put(VideosActions.updateQuestionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateQuestionRequestFailure());
    }
}
function* asyncGetQuestions(data: VideosActions.getQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUESTIONS.url}/${data.payload?.videoId}?questionType=${data.payload?.questionType}`);
        if (response.status === 200) {
            yield put(VideosActions.getQuestionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getQuestionRequestFailure());
    }
}
function* asyncGetVideoMedia(data: VideosActions.getVideoMediaRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_MEDIA.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoMediaRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoMediaRequestFailure());
    }
}
function* asyncSaveVideoMedia(data: VideosActions.saveVideoMediaRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_VIDEO_MEDIA.url}/${data.payload?.videoId}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveVideoMediaRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveVideoMediaRequestFailure());
    }
}
function* asyncUpdateVideoMedia(data: VideosActions.updateVideoMediaRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.SAVE_VIDEO_MEDIA.url}/${data.payload?.videoId}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.updateVideoMediaRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateVideoMediaRequestFailure());
    }
}
function* asyncGetPollsResult(data: VideosActions.getPollsResultRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_POLLS_RESULT.url}?pollid=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getPollsResultRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getPollsResultRequestFailure());
    }
}

function* asyncSaveQuiz(data: VideosActions.saveQuizRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveQuestionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveQuestionRequestFailure());
    }
}

function* asyncGetQuiz(data: VideosActions.getQuizRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`);
        if (response.status === 200) {
            yield put(VideosActions.getQuizRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getQuizRequestFailure());
    }
}

function* asyncSaveMultiChoice(data: VideosActions.saveMultiChoiceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveMultiChoiceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveMultiChoiceRequestFailure());
    }
}

function* asyncGetMultiChoice(data: VideosActions.getMultiChoiceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`);
        if (response.status === 200) {
            yield put(VideosActions.getMultiChoiceRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getMultiChoiceRequestFailure());
    }
}

function* asyncSaveStarRating(data: VideosActions.saveStarRatingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveStarRatingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveStarRatingRequestFailure());
    }
}

function* asyncGetStarRating(data: VideosActions.getStarRatingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getStarRatingRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getStarRatingRequestFailure());
    }
}
function* asyncSaveOpenText(data: VideosActions.saveOpenTextRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveOpenTextRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveOpenTextRequestFailure());
    }
}

function* asyncGetOpenText(data: VideosActions.getOpenTextRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SAVE_MEMBERLIST_ACCESS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getOpenTextRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getOpenTextRequestFailure());
    }
}
function* asyncGetVideoAnalyticsData(data: VideosActions.getVideoAnalyticsDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBER_VIDEO_ANALYTICS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoAnalyticsDataRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsDataRequestFailure());
    }
}

function* asyncGetVideoAnalyticsChartData(data: VideosActions.getVideoAnalyticsChartDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_EDIT_VIDEO_ANALYTICS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoAnalyticsChartDataRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsChartDataRequestFailure());
    }
}
function* asyncSavePollTemplate(data: VideosActions.savePollTemplateRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_POLLS_TEMPLATES.url}`, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.savePollTemplateRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.savePollTemplateRequestFailure());
    }
}
function* asyncGenerateAuthKey(data: VideosActions.generateAuthKeyRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_AUTH_KEY.url}?videoId=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.generateAuthKeyRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.generateAuthKeyRequestFailure());
    }
}
function* asyncUpdateRedirectToLogin(data: VideosActions.updateRedirectToLoginRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.UPDATE_REDIRECT_TO_LOGIN.url}?videoId=${data.payload?.videoId}&redirectToLogin=${data.payload?.redirectToLogin}`);
        if (response.status === 200) {
            yield put(VideosActions.updateRedirectToLoginRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateRedirectToLoginRequestFailure());
    }
}

function* asyncUpdateVideoAnalytics(data: VideosActions.updateVideoAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.UPDATE_VIDEO_ANALYTICS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.updateVideoAnalyticsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateVideoAnalyticsRequestFailure());
    }
}
function* asyncGetVideoAnalyticsLiveViews(data: VideosActions.getVideoAnalyticsLiveViewsRequestAction) {
    try {
        const hardcodedData = [{
            id: '1',
            userName: 'rsingh',
            emailAddress: 'rsingh@webcastcloud.com',
            ipAddress: '11111.22222.11111',
            connected: '00:00:29'
        },
        {
            id: '2',
            userName: 'rahul',
            emailAddress: 'rsingh@webcastcloud.com',
            ipAddress: '11111.33333.11111',
            connected: '00:00:29'
        }]
        // const response: AxiosResponse = yield ApiService.postData(``);
        // if (response.status === 200) {
        //     yield put(VideosActions.getVideoAnalyticsLiveViewsRequestSuccess(response.data.result));
        // }
        yield put(VideosActions.getVideoAnalyticsLiveViewsRequestSuccess(hardcodedData));
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsLiveViewsRequestFailure());
    }
}
function* asyncGetVideoAnalyticsLiveViewsChartData(data: VideosActions.getVideoAnalyticsLiveViewsChartDataRequestAction) {
    try {
        const hardcodedData = {
            time: [],
            users: []
        }
        // const response: AxiosResponse = yield ApiService.postData(``);
        // if (response.status === 200) {
        //     yield put(VideosActions.getVideoAnalyticsLiveViewsChartDataRequestSuccess(response.data.result));
        // }
        yield put(VideosActions.getVideoAnalyticsLiveViewsChartDataRequestSuccess(hardcodedData));
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsLiveViewsChartDataRequestFailure());
    }
}
function* asyncUpdateVideoThumbnail(data: VideosActions.updateVideoThumbnailRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDataWithContentType(`${URLS.UPDATE_VIDEO_THUMBNAIL.url}/${data.payload?.videoId}`, data.payload?.thumbnail);
        if (response.status === 200) {
            yield put(VideosActions.updateVideoThumbnailRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateVideoThumbnailRequestFailure());
    }
}
function* asyncGetLiveEventUrl(data: VideosActions.getLiveEventUrlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_LIVE_MEDIA_URL.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getLiveEventUrlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getLiveEventUrlRequestFailure());
    }
}
function* asyncGetLiveMedia() {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_LIVE_MEDIA.url);
        if (response.status === 200) {
            yield put(VideosActions.getLiveMediaRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getLiveMediaRequestFailure());
    }
}

function* asyncGetProductAccess() {
    try {

        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ACCOUNT_PRODUCTS.url);
        if (response.status === 200) {
            yield put(VideosActions.getProductAccessRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getProductAccessRequestFailure());
    }
}
function* asyncDeletePoll(data: VideosActions.deletePollRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_POLL.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deletePollRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deletePollRequestFailure());
    }
}

function* asyncUpdatePollStatus(data: VideosActions.updatePollStatusRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_POLL_STATUS.url}/${data.payload?.pollId}/active`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updatePollStatusRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updatePollStatusRequestFailure());
    }
}

function* asyncGetDiscountCode(data: VideosActions.getDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_DISCOUNT_CODE.url}?VideoId=${data.payload?.id}&PageNumber=${data.payload?.pageNumber}&PageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(VideosActions.getDiscountCodeRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getDiscountCodeRequestFailure());
    }
}

function* asyncSaveDiscountCode(data: VideosActions.saveDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_DISCOUNT_CODE.url, data.payload);
        if (response.status === 200) {
            yield put(VideosActions.saveDiscountCodeRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.saveDiscountCodeRequestFailure());
    }
}

function* asyncUpdateDiscountCode(data: VideosActions.updateDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_DISCOUNT_CODE.url}?VideoDCId=${data.payload?.discountCodeId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(VideosActions.updateDiscountCodeRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateDiscountCodeRequestFailure());
    }
}

function* asyncDeleteDiscountCode(data: VideosActions.deleteDiscountCodesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_DISCOUNT_CODE.url}?id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.deleteDiscountCodesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.deleteDiscountCodesRequestFailure());
    }
}

function* asyncGetSingleDiscountCode(data: VideosActions.getSingleDiscountCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_DISCOUNT_CODE.url}?Id=${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getSingleDiscountCodeRequestSuccess(response.data.result[0]));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getSingleDiscountCodeRequestFailure());
    }
}

function* asyncUpdateVideoStatus(data: VideosActions.updateVideoStatusRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.UPDATE_VIDEO_STATUS.url}?id=${data.payload?.videoId}&VideoStatus=${data.payload?.status}`);
        if (response.status === 200) {
            yield put(VideosActions.updateVideoStatusRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateVideoStatusRequestFailure());
    }
}

function* asyncGetVideoStatus(data: VideosActions.getVideoStatusRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.UPDATE_VIDEO_STATUS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoStatusRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoStatusRequestFailure());
    }
}

function* asyncGetVideoModule(data: VideosActions.getVideoModulesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_MODULE.url}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoModulesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoModulesRequestFailure());
    }
}

function* asyncStartLiveMeeting(data: VideosActions.startLiveMeetingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.START_LIVE_MEETING.url}?videoId=${data.payload?.videoId}`);
        if (response.status === 200) {
            yield put(VideosActions.startLiveMeetingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.startLiveMeetingRequestFailure());
    }
}

function* asyncEndLiveMeeting(data: VideosActions.endLiveMeetingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.STOP_LIVE_MEETING.url}?videoId=${data.payload?.videoId}`);
        if (response.status === 200) {
            yield put(VideosActions.endLiveMeetingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.endLiveMeetingRequestFailure());
    }
}

function* asyncEnterLiveStudio(data: VideosActions.enterLiveStudioRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ENTER_LIVE_STUDIO.url}?videoId=${data.payload?.videoId}`);
        if (response.status === 200) {
            yield put(VideosActions.enterLiveStudioRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.enterLiveStudioRequestFailure());
    }
}

function* asyncGetVideoAnalyticsTable(data: VideosActions.getVideoAnalyticsTableRequestAction) {
    try {
        if (data.payload?.RoleIds?.length) {
            const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_ANALYTICS_TABLE.url}/${data.payload?.id}?pageNumber=${data.payload?.pageNumber}&pageSize=20&UserNameOrEmail=${data.payload?.UserNameOrEmail ? data.payload?.UserNameOrEmail : ""}&MemberTypesId=${data.payload?.MemberTypesId ? data.payload?.MemberTypesId : 0}&RoleIds=${data.payload?.RoleIds}`);
            if (response.status === 200) {
                yield put(VideosActions.getVideoAnalyticsTableRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
            }
        } else {
            const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_ANALYTICS_TABLE.url}/${data.payload?.id}?pageNumber=${data.payload?.pageNumber}&pageSize=20&UserNameOrEmail=${data.payload?.UserNameOrEmail ? data.payload?.UserNameOrEmail : ""}&MemberTypesId=${data.payload?.MemberTypesId ? data.payload?.MemberTypesId : 0}`);
            if (response.status === 200) {
                yield put(VideosActions.getVideoAnalyticsTableRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
            }
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsTableRequestFailure());
    }
}


function* asyncGetVideoAnalyticsTableDownload(data: VideosActions.getVideoAnalyticsTableDownloadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_VIDEO_ANALYTICS_TABLE.url}/${data.payload?.id}?pageNumber=${data.payload?.pageNumber}&pageSize=100000&UserNameOrEmail=${data.payload?.UserNameOrEmail ? data.payload?.UserNameOrEmail : ""}&MemberTypesId=${data.payload?.MemberTypesId ? data.payload?.MemberTypesId : 0}`);
        if (response.status === 200) {
            yield put(VideosActions.getVideoAnalyticsTableDownloadRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getVideoAnalyticsTableDownloadRequestFailure());
    }
}

function* asyncRemoveMediaUrl(data: VideosActions.removeMediaUrlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.REMOVE_MEDIA_URL.url}`, { htmlValue: data.payload });
        if (response.status === 200) {
            yield put(VideosActions.removeMediaUrlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.removeMediaUrlRequestFailure());
    }
}

function* asyncGetMediaUrl(data: VideosActions.getMediaUrlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_MEDIA_URL.url}`, { htmlValue: data.payload });
        if (response.status === 200) {
            yield put(VideosActions.getMediaUrlRequestSuccess(response.data.result.url));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getMediaUrlRequestFailure());
    }
}

function* asyncGetLiveViewers(data: VideosActions.getLiveViewersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_LIVE_VIEWERS.url}/${data.payload}/live?pageNumber=1&pageSize=50`);
        if (response.status === 200) {
            yield put(VideosActions.getLiveViewersRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getLiveViewersRequestFailure());
    }
}

function* asyncGetLiveViewersCount(data: VideosActions.getLiveViewersCountRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_LIVE_VIEWERS.url}/${data.payload}/livecount`);
        if (response.status === 200) {
            yield put(VideosActions.getLiveViewersCountRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getLiveViewersCountRequestFailure());
    }
}

function* asyncUpdateLiveStreamSource(data: VideosActions.updateLiveStreamSourceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.UPDATE_LIVE_STREAM_SOURCE.url}/${data.payload?.videoId}?liveStreamSource=${data.payload?.option}`);
        if (response.status === 200) {
            yield put(VideosActions.updateLiveStreamSourceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.updateLiveStreamSourceRequestFailure());
    }
}

function* asyncGetArchiveUrl(data: VideosActions.getArchiveUrlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ARCHIVE_URL.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(VideosActions.getArchiveUrlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(VideosActions.getArchiveUrlRequestFailure());
    }
}


export function* videosSaga() {
    yield all([
        takeEvery(Constants.getVideoAnalyticsRequestActionType, asyncGetVideoAnalytics),
        takeEvery(Constants.getVideosRequestActionType, asyncGetVideos),
        takeEvery(Constants.startMediaChannelRequestActionType, asyncStartMediaChannelRequestActionType),
        takeEvery(Constants.startMediaStreamRequestActionType, asyncStartMediaStreamRequestActionType),
        takeEvery(Constants.stopMediaStreamRequestActionType, asyncStopMediaStreamRequestActionType),
        takeEvery(Constants.mediaChannelStatusRequestActionType, asyncMediaChannelStatusRequestActionType),
        takeEvery(Constants.addVideoRequestActionType, asyncAddVideo),
        takeEvery(Constants.getSingleVideoRequestActionType, asyncGetSingleVideo),
        takeEvery(Constants.editVideoRequestActionType, asyncEditVideo),
        takeEvery(Constants.deleteVideoRequestActionType, asyncDeleteVideo),
        takeEvery(Constants.addLiveStreamRequestActionType, asyncAddLiveStream),
        takeEvery(Constants.addCompleteUploadRequestActionType, asyncAddCompleteUpload),
        takeEvery(Constants.cancelUploadRequestActionType, asyncCancelUpload),
        takeEvery(Constants.testUploadRequestActionType, asyncTestUpload),
        takeEvery(Constants.getuploadPartUrlRequestActionType, asyncUploadPartUrl),
        takeEvery(Constants.addSpeakerRequestActionType, asyncAddSpeaker),
        takeEvery(Constants.searchVideoRequestActionType, asyncSearchVideo),
        takeEvery(Constants.getVideoSlidesRequestActionType, asyncGetVideoSlides),
        takeEvery(Constants.saveVideoSlidesRequestActionType, asyncSaveVideoSlides),
        takeEvery(Constants.updateVideoSlidesRequestActionType, asyncUpdateVideoSlides),
        takeEvery(Constants.shareVideoRequestActionType, asyncShareVideo),
        takeEvery(Constants.getSpeakersRequestActionType, asyncGetSpeakers),
        takeEvery(Constants.getInterActivityRequestActionType, asyncGetInterActivity),
        takeEvery(Constants.updateInterActivityRequestActionType, asyncUpdateInterActivity),
        takeEvery(Constants.getAccessibilityRequestActionType, asyncGetAccessibility),
        takeEvery(Constants.updateAccessibilityRequestActionType, asyncUpdateAccessibility),
        takeEvery(Constants.getPlayPageRequestActionType, asyncGetPlayPage),
        takeEvery(Constants.updatePlayPageRequestActionType, asyncUpdatePlayPage),
        takeEvery(Constants.getMemberListAccessRequestActionType, asyncGetMemberListAccess),
        takeEvery(Constants.saveMemberListAccessRequestActionType, asyncSaveMemberListAccess),
        takeEvery(Constants.deleteMemberListAccessRequestActionType, asyncDeleteMemberListAccess),
        takeEvery(Constants.getResourcesRequestActionType, asyncGetResources),
        takeEvery(Constants.addResourcesRequestActionType, asyncAddResources),
        takeEvery(Constants.editResourcesRequestActionType, asyncEditResources),
        takeEvery(Constants.getSingleResourcesRequestActionType, asyncGetSingleResources),
        takeEvery(Constants.deleteResourcesRequestActionType, asyncDeleteResources),
        takeEvery(Constants.getMemberListPriceRequestActionType, asyncGetMemberListPrice),
        takeEvery(Constants.saveMemberListPriceRequestActionType, asyncSaveMemberListPrice),
        takeEvery(Constants.deleteMemberListPriceRequestActionType, asyncDeleteMemberListPrice),
        takeEvery(Constants.getModeratorRequestActionType, asyncGetModerator),
        takeEvery(Constants.saveModeratorsRequestActionType, asyncSaveModerator),
        takeEvery(Constants.deleteModeratorsRequestActionType, asyncDeleteModerator),
        takeEvery(Constants.getCallToActionRequestActionType, asyncGetCallToAction),
        takeEvery(Constants.saveCallToActionRequestActionType, asyncSaveCallToAction),
        takeEvery(Constants.deleteCallToActionRequestActionType, asyncDeleteCallToAction),
        takeEvery(Constants.getSingleCallToActionRequestActionType, asyncGetSingleCallToAction),
        takeEvery(Constants.updateCallToActionRequestActionType, asyncUpdateCallToAction),
        takeEvery(Constants.getVideoViewingSessionRequestActionType, asyncGetVideoViewingSession),
        takeEvery(Constants.saveSlidesRequestActionType, asyncSaveSlides),
        takeEvery(Constants.getSlidesRequestActionType, asyncGetSlides),
        takeEvery(Constants.updateSlidesRequestActionType, asyncUpdateSlides),
        takeEvery(Constants.savePollRequestActionType, asyncSavePoll),
        takeEvery(Constants.getPollRequestActionType, asyncGetPolls),
        takeEvery(Constants.updatePollRequestActionType, asyncUpdatePoll),
        takeEvery(Constants.getPollsTemplatesRequestActionType, asyncGetllPollsTemplate),
        takeEvery(Constants.saveQuestionRequestActionType, asyncSaveQuestion),
        takeEvery(Constants.updateQuestionRequestActionType, asyncUpdateQuestion),
        takeEvery(Constants.getQuestionRequestActionType, asyncGetQuestions),
        takeEvery(Constants.getVideoMediaRequestActionType, asyncGetVideoMedia),
        takeEvery(Constants.saveVideoMediaRequestActionType, asyncSaveVideoMedia),
        takeEvery(Constants.updateVideoMediaRequestActionType, asyncUpdateVideoMedia),
        takeEvery(Constants.getPollsResultRequestActionType, asyncGetPollsResult),
        takeEvery(Constants.saveQuizRequestActionType, asyncSaveQuiz),
        takeEvery(Constants.getQuizRequestActionType, asyncGetQuiz),
        takeEvery(Constants.saveMultiChoiceRequestActionType, asyncSaveMultiChoice),
        takeEvery(Constants.getMultiChoiceRequestActionType, asyncGetMultiChoice),
        takeEvery(Constants.saveStarRatingRequestActionType, asyncSaveStarRating),
        takeEvery(Constants.getStarRatingRequestActionType, asyncGetStarRating),
        takeEvery(Constants.saveOpenTextRequestActionType, asyncSaveOpenText),
        takeEvery(Constants.getOpenTextRequestActionType, asyncGetOpenText),
        takeEvery(Constants.getVideoAnalyticsDataRequestActionType, asyncGetVideoAnalyticsData),
        takeEvery(Constants.getVideoAnalyticsChartDataRequestActionType, asyncGetVideoAnalyticsChartData),
        takeEvery(Constants.savePollTemplateRequestActionType, asyncSavePollTemplate),
        takeEvery(Constants.generateAuthKeyRequestActionType, asyncGenerateAuthKey),
        takeEvery(Constants.updateRedirectToLoginRequestActionType, asyncUpdateRedirectToLogin),
        takeEvery(Constants.updateVideoAnalyticsRequestActionType, asyncUpdateVideoAnalytics),
        takeEvery(Constants.getVideoAnalyticsLiveViewsRequestActionType, asyncGetVideoAnalyticsLiveViews),
        takeEvery(Constants.getVideoAnalyticsLiveViewsChartDataRequestActionType, asyncGetVideoAnalyticsLiveViewsChartData),
        takeEvery(Constants.updateVideoThumbnailRequestActionType, asyncUpdateVideoThumbnail),
        takeEvery(Constants.getLiveEventUrlRequestActionType, asyncGetLiveEventUrl),
        takeEvery(Constants.getLiveMediaRequestActionType, asyncGetLiveMedia),
        takeEvery(Constants.getProductAccessRequestActionType, asyncGetProductAccess),
        takeEvery(Constants.deletePollRequestActionType, asyncDeletePoll),
        takeEvery(Constants.updatePollStatusRequestActionType, asyncUpdatePollStatus),
        takeEvery(Constants.getDiscountCodeRequestActionType, asyncGetDiscountCode),
        takeEvery(Constants.saveDiscountCodeRequestActionType, asyncSaveDiscountCode),
        takeEvery(Constants.updateDiscountCodeRequestActionType, asyncUpdateDiscountCode),
        takeEvery(Constants.deleteDiscountCodesRequestActionType, asyncDeleteDiscountCode),
        takeEvery(Constants.getSingleDiscountCodeRequestActionType, asyncGetSingleDiscountCode),
        takeEvery(Constants.updateVideoStatusRequestActionType, asyncUpdateVideoStatus),
        takeEvery(Constants.getVideoStatusRequestActionType, asyncGetVideoStatus),
        takeEvery(Constants.getVideoModulesRequestActionType, asyncGetVideoModule),
        takeEvery(Constants.startLiveMeetingRequestActionType, asyncStartLiveMeeting),
        takeEvery(Constants.endLiveMeetingRequestActionType, asyncEndLiveMeeting),
        takeEvery(Constants.enterLiveStudioRequestActionType, asyncEnterLiveStudio),
        takeEvery(Constants.getVideoAnalyticsTableRequestActionType, asyncGetVideoAnalyticsTable),
        takeEvery(Constants.getVideoAnalyticsTableDownloadRequestActionType, asyncGetVideoAnalyticsTableDownload),
        takeEvery(Constants.removeMediaUrlRequestActionType, asyncRemoveMediaUrl),
        takeEvery(Constants.getMediaUrlRequestActionType, asyncGetMediaUrl),
        takeEvery(Constants.getLiveViewersRequestActionType, asyncGetLiveViewers),
        takeEvery(Constants.getLiveViewersCountRequestActionType, asyncGetLiveViewersCount),
        takeEvery(Constants.updateLiveStreamSourceRequestActionType, asyncUpdateLiveStreamSource),
        takeEvery(Constants.addResourceUploadRequestActionType, asyncUploadResourceFile),
        takeEvery(Constants.getResourceDetailRequestActionType, asyncGetResourceDetail),
        takeEvery(Constants.getArchiveUrlRequestActionType, asyncGetArchiveUrl),
    ]);
}
