import React, { useState, useEffect } from 'react';
import { MemberInvoiceModel } from 'src/models/usersModel';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import Pagination from 'src/components/common/pagination';
import InvoiceDetailModal from './InvoiceDetailModal';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { CSVLink } from 'react-csv';
import { RootState } from 'src/store/reducers/state';
interface IProps {
  memberId: string;
}
const MemberInvoices: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const [invoiceData, setInvoiceData] = useState<MemberInvoiceModel>();
  const [downloadData, setDownloadData] = useState<any>();
  const { invoicePaginationMeta, memberInvoice } = useSelector((state: RootState) => {
    return {
      invoicePaginationMeta: state.users.invoicePaginationMeta,
      memberInvoice: state.users.memberInvoice
    };
  });

  useEffect(() => {
    if (memberInvoice && memberInvoice.length) {
      const final: any = [];
      memberInvoice &&
        memberInvoice.map((item) => {
          item?.items?.map((data) => {
            const finalData = {
              Shopping: item.refnumber,
              Status: item?.status[0]?.description,
              PurchaseOrderDate: item.orderdate ? defaultDateFormatter(item.orderdate) : '',
              CustomerId: item.customerid,
              ChannelName: item.channelname,
              Subtotal: item.ordertotal,
              Tax: item.tax,
              TotalAfterDiscount: item.orderincdiscounttotal,
              DiscountCode: item.discountcode,
              ShoppingCartCreatedDate: item.shoppingCartCreatedDate
                ? defaultDateFormatter(item.shoppingCartCreatedDate)
                : '',
              PurchaseOrderTotal: item.ordertotal,
              PurchaseOrderFacilitatorToatal: item.facilitatortotal,
              customerTitle: item.customertitle,
              customerFirstName: item.customerfirstname,
              customerlastname: item.customerlastname,
              Company: item.company,
              Created: item.createddate ? defaultDateFormatter(item.createddate) : '',
              Updated: item.updateddate ? defaultDateFormatter(item.updateddate) : '',
              LastLogin: item.lastlogindate ? defaultDateFormatter(item.lastlogindate) : '',
              ProductDescription: data.description,
              Amount: data.costex
            };
            final.push(finalData);
          });
        });
      if (final) {
        setDownloadData(final);
      }
    }
  }, [memberInvoice]);

  const handlePageChange = (pageNumber: number) => {
    const data = { id: memberId, pageNumber, pageSize: 5 };
    userAction.getMemberInvoicesRequest(data);
  };
  return (
    <>
      <div>
        <div className="flex flex-col justify-center h-full">
          <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
            <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Invoices</h2>
              <div className="flex items-center">
                <div className="dropdown relative">
                  {downloadData && (
                    <CSVLink className="export" data={downloadData} filename={'Invoice.csv'}>
                      <button
                        className="dropdown-toggle border input-style w-32 mr-3 flex-items export-dropdown"
                        type="button">
                        <span className="flex">
                          <img src="../../assets/img/export.svg" className="w-4 h-4 mr-2" />
                          Export{' '}
                        </span>
                      </button>
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
            <div className="p-3">
              <div className="overflow-x-auto">
                <table className="table-auto w-full border">
                  <thead className="text-xs font-semibold bg-gray-50">
                    <tr className="uppercase">
                      <th className="p-2">
                        <div className="font-semibold text-left">Reference #</div>
                      </th>
                      <th className="p-2">
                        <div className="font-semibold text-left">Total Paid</div>
                      </th>
                      <th className="p-2">
                        <div className="font-semibold text-left">Issued Date</div>
                      </th>
                      <th className="p-2">
                        <div className="font-semibold text-left">Actions</div>
                      </th>
                    </tr>
                  </thead>
                  {memberInvoice && memberInvoice.length ? (
                    <tbody className="text-sm divide-y divide-gray-100">
                      {memberInvoice &&
                        memberInvoice.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="p-2">
                                <div className="text-left">{item.refnumber}</div>
                              </td>

                              <td className="p-2">
                                <div className="text-left">${item.ordertotal}</div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">{item.orderdate ? defaultDateFormatter(item.orderdate) : ""}</div>
                              </td>
                              <td className="p-2">
                                <div
                                  onClick={() => setInvoiceData(item)}
                                  className="text-left flex items-center py-2 cursor-pointer"
                                  data-bs-toggle="modal"
                                  data-bs-target="#invoiceDetailModal">
                                  <span className="ml-4">
                                    <img
                                      src="../../assets/img/preview-invoice.svg"
                                      className="w-4 h-4"
                                    />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td className="p-2">
                          <div className="text-center">No data Found</div>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                {!!invoicePaginationMeta &&
                  !!Object.keys(invoicePaginationMeta).length &&
                  memberInvoice && (
                    <Pagination
                      currentPage={invoicePaginationMeta.pageNumber}
                      totalCount={invoicePaginationMeta.totalCount}
                      pageSize={invoicePaginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <InvoiceDetailModal invoiceData={invoiceData} />
    </>
  );
};

export default MemberInvoices;
