import React, { useEffect } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
const RegistrationOptions = () => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();
  const { registrationOption } = useSelector((state: RootState) => {
    return {
      registrationOption: state.videoLibrary.registrationOption
    };
  });
  useEffect(() => {
    videoLibraryAction.getRegistrationOptionsRequest();
  }, []);
  const onSubmit = (data: any) => {
    videoLibraryAction.addRegistrationOptionsRequest(data);
  };
  return (
    <div className="tab-pane fade" id="tabs-registerOptions" role="tabpanel">
      <div className="md:p-5 p-3">
        <form onSubmit={handleSubmit(onSubmit)} className="flex items-center justify-between py-4">
          <div className="w-1/2 flex items-center">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'name'}
              register={register}
              placeholder={'Name'}
              errors={''}
              isRequired={false}
            />

            <div className="flex items-center ml-2">
              <label
                className="form-check-label inline-block font-semibold mr-2 whitespace-nowrap"
                htmlFor="Credit">
                Default Value
              </label>
              <div className="form-check form-switch pl-0 relative">
                <input
                  className="form-check-input switch-btn"
                  type="checkbox"
                  role="switch"
                  id="defaultValue"
                  {...register('defaultValue')}
                />
                <i className="fas fa-check checkbox-tick hidden"></i>
                <i className="fas fa-times checkbox-cross"></i>
              </div>
            </div>
          </div>

          <div>
            <button type="submit" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
              Add
            </button>
          </div>
        </form>

        <div className="overflow-x-auto">
          <table className="table-auto w-full border">
            <thead className="text-xs font-semibold uppercase bg-gray-100">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Default Value</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Enabled</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Delete</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {registrationOption &&
                registrationOption.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="p-2">{item.name}</td>
                      <td className="p-2">
                        <div className="form-check form-switch pl-0 relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </td>
                      <td className="p-2">
                        <div className="form-check form-switch pl-0 relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </td>
                      <td className="p-2">
                        <i className="fa fa-trash cursor-pointer"></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RegistrationOptions;
