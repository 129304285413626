import { handleActions } from "redux-actions";
import { DashboardActions } from "./actions";
import { RootState } from "../reducers/state";
import { DashboardModel, TotalDashboardModel } from "src/models/dashboardModel";
import { DashboardStateModel } from "src/models/dashboardModel";
import { Actions } from "src/models/dashboardModel";
import * as Constants from './constants'
import { MostPopularVideoModel } from "src/models/dashboardModel";
const initialState: RootState.DashboardState = {
    data: [],
    salesOrder: [],
    previousYearSalesOrder: [],
    topPayingCustomer: [],
    developerMeetup: [],
    userTimeline: [],
    salesRatio: [],
    mostPopularVideo: {} as MostPopularVideoModel,
    salesOrderStats: [],
    memberActivity: [],
    whatsNew: [],
    sales: [],
    totalDashboardData: {} as TotalDashboardModel,
    topSellingProduct: [],
    isThemeCustomized: false,
    success: false,
    error: false,
    isSalesOrderDataLoading: false,
    isSalesOrderStatsLoading: false,
    isSalesLoading: false,
    isMemberActivityLoading: false,
    isPopularVideoLoading: false,
    loading: false,
};


function dashboardReducer(state: DashboardStateModel = initialState, action: Actions): DashboardStateModel {
    switch (action.type) {
        case Constants.getDashboardRequestActionType: return getDashboardAction(state);
        case Constants.getDashboardSuccessActionType: return getDashboardSuccessAction(state, action);
        case Constants.getDashboardFailureActionType: return getDashboardFailureAction(state, action);
        case Constants.getSalesOrderDataRequestActionType: return getSalesOrderDataAction(state);
        case Constants.getSalesOrderDataSuccessActionType: return getSalesOrderDataSuccessAction(state, action);
        case Constants.getSalesOrderDataFailureActionType: return getSalesOrderDataFailureAction(state, action);
        case Constants.getTopPayingCustomerRequestActionType: return getTopPayingCustomerAction(state);
        case Constants.getTopPayingCustomerRequestSuccessActionType: return getTopPayingCustomerSuccessAction(state, action);
        case Constants.getTopPayingCustomerRequestFailureActionType: return getTopPayingCustomerFailureAction(state, action);
        case Constants.getDeveloperMeetupRequestActionType: return getDeveloperMeetupAction(state);
        case Constants.getDeveloperMeetupRequestSuccessActionType: return getDeveloperMeetupSuccessAction(state, action);
        case Constants.getDeveloperMeetupRequestFailureActionType: return getDeveloperMeetupFailureAction(state, action);
        case Constants.getUserTimeLineRequestActionType: return getUserTimelineAction(state);
        case Constants.getUserTimeLineRequestSuccessActionType: return getUserTimelineSuccessAction(state, action);
        case Constants.getUserTimeLineRequestFailureActionType: return getUserTimelineFailureAction(state, action);
        case Constants.getSalesRatioRequestActionType: return getSalesRatioAction(state);
        case Constants.getSalesRatioRequestSuccessActionType: return getSalesRatioSuccessAction(state, action);
        case Constants.getSalesRatioRequestFailureActionType: return getSalesRatioFailureAction(state, action);
        case Constants.getMostPopularVideoRequestActionType: return getMostPopularVideoAction(state);
        case Constants.getMostPopularVideoRequestSuccessActionType: return getMostPopularVideoSuccessAction(state, action);
        case Constants.getMostPopularVideoRequestFailureActionType: return getMostPopularVideoFailureAction(state, action);
        case Constants.getMemberActivityRequestActionType: return getMemberActivityAction(state);
        case Constants.getMemberActivityRequestSuccessActionType: return getMemberActivitySuccessAction(state, action);
        case Constants.getMemberActivityRequestFailureActionType: return getMemberActivityFailureAction(state, action);
        case Constants.getSalesRequestActionType: return getSalesAction(state);
        case Constants.getSalesRequestSuccessActionType: return getSalesSuccessAction(state, action);
        case Constants.getSalesRequestFailureActionType: return getSalesFailureAction(state, action);
        case Constants.getWhatsNewRequestActionType: return getWhatsNewAction(state);
        case Constants.getWhatsNewRequestSuccessActionType: return getWhatsNewSuccessAction(state, action);
        case Constants.getWhatsNewRequestFailureActionType: return getWhatsNewFailureAction(state, action);
        case Constants.getSearchedMostPopularVideoRequestActionType: return getSearchedMostPopularVideoAction(state);
        case Constants.getSearchedMostPopularVideoRequestSuccessActionType: return getSearchedMostPopularVideoSuccessAction(state, action);
        case Constants.getSearchedMostPopularVideoRequestFailureActionType: return getSearchedMostPopularVideoFailureAction(state, action);
        case Constants.getSearchMemberActivityRequestActionType: return getSearchMemberActivityAction(state);
        case Constants.getSearchMemberActivityRequestSuccessActionType: return getSearchMemberActivitySuccessAction(state, action);
        case Constants.getSearchMemberActivityRequestFailureActionType: return getSearchMemberActivityFailureAction(state, action);
        case Constants.getTotalDashboardDataRequestActionType: return getTotalDashboardDataAction(state);
        case Constants.getTotalDashboardDataRequestSuccessActionType: return getTotalDashboardDataSuccessAction(state, action);
        case Constants.getTotalDashboardDataRequestFailureActionType: return getTotalDashboardDataFailureAction(state);
        case Constants.saveCustomizeThemeRequestActionType: return saveCustomizeThemeAction(state);
        case Constants.saveCustomizeThemeRequestSuccessActionType: return saveCustomizeThemeSuccessAction(state);
        case Constants.saveCustomizeThemeRequestFailureActionType: return saveCustomizeThemeFailureAction(state);
        case Constants.getSalesOrderStatsDataRequestActionType: return getSalesOrderStatsAction(state);
        case Constants.getSalesOrderStatsDataRequestSuccessActionType: return getSalesOrderStatsSuccessAction(state, action);
        case Constants.getSalesOrderStatsDataRequestFailureActionType: return getSalesOrderStatsFailureAction(state);
        case Constants.getPreviousYearSalesOrderDataRequestActionType: return getPreviousYearSalesOrderDataAction(state);
        case Constants.getPreviousYearSalesOrderDataRequestSuccessActionType: return getPreviousYearSalesOrderDataSuccessAction(state, action);
        case Constants.getPreviousYearSalesOrderDataRequestFailureActionType: return getPreviousYearSalesOrderDataFailureAction(state);
        case Constants.getTopSellingProductsRequestActionType: return getTopSellingProductsAction(state);
        case Constants.getTopSellingProductsRequestSuccessActionType: return getTopSellingProductsSuccessAction(state, action);
        case Constants.getTopSellingProductsRequestFailureActionType: return getTopSellingProductsFailureAction(state);
        default: return state;
    }

    function getDashboardAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getDashboardSuccessAction(state: DashboardStateModel, action: DashboardActions.getDashboardRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                data: action.payload
            };
        }
        return state;
    }
    function getDashboardFailureAction(state: DashboardStateModel, action: DashboardActions.getDashboardRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getSalesOrderDataAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isSalesOrderDataLoading: true,
        };
    }
    function getSalesOrderDataSuccessAction(state: DashboardStateModel, action: DashboardActions.getSalesOrderRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                salesOrder: action.payload,
                isSalesOrderDataLoading: false,
            };
        }
        return state;
    }
    function getSalesOrderDataFailureAction(state: DashboardStateModel, action: DashboardActions.getSalesOrderRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false,
            isSalesOrderDataLoading: false,
        }
    }
    function getTopPayingCustomerAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTopPayingCustomerSuccessAction(state: DashboardStateModel, action: DashboardActions.getTopPayingCustomerRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                topPayingCustomer: action.payload
            };
        }
        return state;
    }
    function getTopPayingCustomerFailureAction(state: DashboardStateModel, action: DashboardActions.getTopPayingCustomerRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getDeveloperMeetupAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getDeveloperMeetupSuccessAction(state: DashboardStateModel, action: DashboardActions.getDeveloperMeetupRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                developerMeetup: action.payload
            };
        }
        return state;
    }
    function getDeveloperMeetupFailureAction(state: DashboardStateModel, action: DashboardActions.getDeveloperMeetupRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getUserTimelineAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUserTimelineSuccessAction(state: DashboardStateModel, action: DashboardActions.getUserTimeLineRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                userTimeline: action.payload
            };
        }
        return state;
    }
    function getUserTimelineFailureAction(state: DashboardStateModel, action: DashboardActions.getUserTimeLineRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getSalesRatioAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSalesRatioSuccessAction(state: DashboardStateModel, action: DashboardActions.getSalesRatioRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                salesRatio: action.payload
            };
        }
        return state;
    }
    function getSalesRatioFailureAction(state: DashboardStateModel, action: DashboardActions.getSalesRatioRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getMostPopularVideoAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isPopularVideoLoading: true,
        };
    }
    function getMostPopularVideoSuccessAction(state: DashboardStateModel, action: DashboardActions.getMostPopularVideoRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                mostPopularVideo: action.payload,
                isPopularVideoLoading: false
            };
        }
        return state;
    }
    function getMostPopularVideoFailureAction(state: DashboardStateModel, action: DashboardActions.getMostPopularVideoRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false,
            isPopularVideoLoading: false
        }
    }
    function getMemberActivityAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isMemberActivityLoading: true,
        };
    }
    function getMemberActivitySuccessAction(state: DashboardStateModel, action: DashboardActions.getMemberActivityRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                memberActivity: action.payload,
                isMemberActivityLoading: false
            };
        }
        return state;
    }
    function getMemberActivityFailureAction(state: DashboardStateModel, action: DashboardActions.getMemberActivityRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false,
            isMemberActivityLoading: false
        }
    }
    function getSalesAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isSalesLoading: true,
        };
    }
    function getSalesSuccessAction(state: DashboardStateModel, action: DashboardActions.getSalesRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                sales: action.payload,
                isSalesLoading: false,
            };
        }
        return state;
    }
    function getSalesFailureAction(state: DashboardStateModel, action: DashboardActions.getSalesRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false,
            isSalesLoading: false,
        }
    }
    function getWhatsNewAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getWhatsNewSuccessAction(state: DashboardStateModel, action: DashboardActions.getWhatsNewRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                whatsNew: action.payload
            };
        }
        return state;
    }
    function getWhatsNewFailureAction(state: DashboardStateModel, action: DashboardActions.getWhatsNewRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getSearchedMostPopularVideoAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSearchedMostPopularVideoSuccessAction(state: DashboardStateModel, action: DashboardActions.getSearchedMostPopularVideoRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                mostPopularVideo: action.payload
            };
        }
        return state;
    }
    function getSearchedMostPopularVideoFailureAction(state: DashboardStateModel, action: DashboardActions.getSearchedMostPopularVideoRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getSearchMemberActivityAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSearchMemberActivitySuccessAction(state: DashboardStateModel, action: DashboardActions.getSearchMemberActivityRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                memberActivity: action.payload
            };
        }
        return state;
    }
    function getSearchMemberActivityFailureAction(state: DashboardStateModel, action: DashboardActions.getSearchMemberActivityRequestFailureAction): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getTotalDashboardDataAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTotalDashboardDataSuccessAction(state: DashboardStateModel, action: DashboardActions.getTotalDashboardDataRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                totalDashboardData: action.payload
            };
        }
        return state;
    }
    function getTotalDashboardDataFailureAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function saveCustomizeThemeAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveCustomizeThemeSuccessAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isThemeCustomized: action.payload
        };
    }
    function saveCustomizeThemeFailureAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isThemeCustomized: false
        }
    }
    function getSalesOrderStatsAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            isSalesOrderStatsLoading: true,
        };
    }
    function getSalesOrderStatsSuccessAction(state: DashboardStateModel, action: DashboardActions.getSalesOrderStatsDataRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                salesOrderStats: action.payload,
                isSalesOrderStatsLoading: false,
            };
        }
        return state;
    }
    function getSalesOrderStatsFailureAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            success: false,
            isSalesOrderStatsLoading: false,
        }
    }
    function getPreviousYearSalesOrderDataAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPreviousYearSalesOrderDataSuccessAction(state: DashboardStateModel, action: DashboardActions.getPreviousYearSalesOrderDataRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                previousYearSalesOrder: action.payload
            };
        }
        return state;
    }
    function getPreviousYearSalesOrderDataFailureAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getTopSellingProductsAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTopSellingProductsSuccessAction(state: DashboardStateModel, action: DashboardActions.getTopSellingProductsRequestSuccessAction): DashboardStateModel {
        if (action.payload) {
            return {
                ...state,
                topSellingProduct: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getTopSellingProductsFailureAction(state: DashboardStateModel): DashboardStateModel {
        return {
            ...state,
            loading: false
        }
    }
}

export default dashboardReducer
