import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { convertHourMinuteSecondToSecond, SubtractSeconds } from 'src/components/utilities/utils';
import { RootState } from 'src/store/reducers/state';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
const AddNewAction = ({ videoId, editFlag }: any) => {
  const [selectedFile, setSelectedFile] = useState();
  const ref = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [actionTimeError, setActionTimeError] = useState<boolean>(false);
  const [duration, setDuration] = useState<any>('');
  const [actionTime, setActionTime] = useState<any>('00:00:00');
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();

  const { singleCallToAction, singleVideo, isCallToActionSaved } = useSelector(
    (state: RootState) => {
      return {
        singleCallToAction: state.videos.singleCallToAction,
        singleVideo: state.videos.singleVideo,
        isCallToActionSaved: state.videos.isCallToActionSaved
      };
    }
  );
  useEffect(() => {
    videoLibraryAction.getSingleVideosRequest(videoId);
  }, []);
  const onSubmit = (data: any) => {
    setActionTimeError(false);
    const finalData = {
      ...data,
      videoId: videoId,
      actionTime: actionTime ? convertHourMinuteSecondToSecond(actionTime) : 0
    };
    if (actionTime <= singleVideo.duration) {
      if (editFlag === 'add') {
        videoLibraryAction.saveCallToActionRequest(finalData);
      } else {
        const payloadData = {
          videoCTAId: data.id,
          updateData: [
            {
              op: 'replace',
              path: 'actionTime',
              value: convertHourMinuteSecondToSecond(actionTime)
            },
            { op: 'replace', path: 'actionType', value: data.actionType },
            { op: 'replace', path: 'buttonText', value: data.buttonText },
            { op: 'replace', path: 'displayText', value: data.displayText },
            { op: 'replace', path: 'linkText', value: data.linkText }
          ]
        };

        videoLibraryAction.updateCallToActionRequest(payloadData);
      }
    } else {
      setActionTimeError(true);
    }
  };

  useEffect(() => {
    if (isCallToActionSaved) {
      if (ref.current) {
        ref.current.click();
      }
      handleResetFields();
    }
  }, [isCallToActionSaved]);

  const handleResetFields = () => {
    setActionTimeError(false);
    reset({
      actionTime: '',
      displayText: '',
      linkText: '',
      buttonText: ''
    });
    setActionTime('00:00:00');
  };
  useEffect(() => {
    if (singleCallToAction) {
      reset(singleCallToAction);
      setActionTime(singleCallToAction.actionTime);
    }
  }, [singleCallToAction]);

  useEffect(() => {
    if (singleVideo) {
      setDuration(singleVideo.duration);
    }
  }, [singleVideo]);
  const handleDisplayAtTheEndClick = () => {
    if (singleVideo) {
      setActionTime(SubtractSeconds(singleVideo.duration));
    }
  };

  const onChange = (e: any) => {
    setActionTime(moment(e).format('HH:mm:ss'));
  };

  return (
    <div>
      <div
        className="modal fade"
        id="AddlivestreamModal"
        aria-labelledby="AddlivestreamModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddlivestreamModalLabel">
                Add Call-To-Action
              </h5>
              <button
                ref={ref}
                onClick={handleResetFields}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body relative py-4 px-10">
                <div className={`form-group mb-3`}>
                  <select {...register('actionType')} className="input-style">
                    <option value="Text">Text</option>
                  </select>
                </div>

                <div className={`form-group mb-3`}>
                  <div className="flex justify-center align-items-center">
                    <div>
                      <label className="block text-sm font-medium mb-1">Action Time</label>
                      <TimePicker
                        onChange={(e) => onChange(e)}
                        value={actionTime ? moment(actionTime, 'HH:mm:ss') : undefined}
                      />
                    </div>
                    <div className="ml-2">
                      <label className="block text-sm font-bold mb-1">Duration</label>
                      <input
                        disabled
                        type="text"
                        value={duration}
                        className="input-style duration quiz-disable"
                      />
                    </div>

                    <div className="mt-3 ml-2">
                      <button
                        onClick={handleDisplayAtTheEndClick}
                        type="button"
                        className="btn-primary w-auto mt-3 mr-auto ml-0 borderonly-btn whitespace-nowrap">
                        Display at end
                      </button>
                    </div>
                  </div>
                </div>

                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Display Text'}
                    type={'text'}
                    fieldName={'displayText'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Link URL'}
                    type={'text'}
                    fieldName={'linkText'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Continue Text'}
                    type={'text'}
                    fieldName={'buttonText'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  />
                </div>
              </div>
              {actionTimeError && (
                <div className="ml-10 mb-3">
                  <span className="text-red-500 text-xs flex item-center error">
                    Action Time can not be set longer than video duration
                  </span>
                </div>
              )}
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                <button
                  type="submit"
                  className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                  Add
                </button>
                <button
                  onClick={handleResetFields}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAction;
