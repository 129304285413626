import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
interface IProps {
  setEventReminderClicked: any;
  setEventConfirmationClicked: any;
  setEditEventClicked: any;
}
const EventSideMenu: React.FC<IProps> = ({
  setEventReminderClicked,
  setEventConfirmationClicked,
  setEditEventClicked
}) => {
  const [showVideoInfo, setShowVideoInfo] = useState(true);
  const { eventId } = useParams<string>();
  const handleEventConfirmationClick = () => {
    setEventConfirmationClicked(true);
    setEventReminderClicked(false);
    setEditEventClicked(false);
  };

  const handleEventReminderClick = () => {
    setEventReminderClicked(true);
    setEventConfirmationClicked(false);
    setEditEventClicked(false);
  };

  const handleEditEventClick = () => {
    setEventReminderClicked(false);
    setEventConfirmationClicked(false);
    setEditEventClicked(true);
  };
  return (
    <div
      className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
        showVideoInfo ? 'hide-lists' : 'show-lists'
      }`}>
      <div className="mobile-dropdown" onClick={() => setShowVideoInfo(!showVideoInfo)}>
        <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
          <p>Edit Event</p>
          <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
        </div>
      </div>
      <ul
        className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mobile-layout-ul"
        id="tabs-tabVertical"
        role="tablist">
        <li
          className="nav-item flex-grow"
          role="presentation"
          onClick={() => handleEditEventClick()}>
          <a
            href="#tabs-eventInfo"
            className="nav-link my-0 tabs-link pl-3 flex items-center active"
            data-bs-toggle="pill"
            data-bs-target="#tabs-eventInfo"
            role="tab"
            aria-controls="tabs-eventInfo"
            aria-selected="false">
            Event info
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-registrationFields"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-registrationFields"
            role="tab"
            aria-controls="tabs-registrationFields"
            aria-selected="false">
            Registration Fields
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-imagesStyles"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-imagesStyles"
            role="tab"
            aria-controls="tabs-imagesStyles"
            aria-selected="false">
            Images & Style
          </a>
        </li>
        <li
          className="nav-item flex-grow"
          role="presentation"
          onClick={() => handleEventConfirmationClick()}>
          <a
            href="#tabs-emailConfirmation"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-emailConfirmation"
            role="tab"
            aria-controls="tabs-emailConfirmation"
            aria-selected="false">
            Email Confirmation
          </a>
        </li>
        <li
          className="nav-item flex-grow"
          role="presentation"
          onClick={() => handleEventReminderClick()}>
          <a
            href="#tabs-eventRemainder"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-eventRemainder"
            role="tab"
            aria-controls="#tabs-eventRemainder"
            aria-selected="false">
            Event Reminders
          </a>
        </li>
        <li className="nav-item flex-grow">
          <Link
            to={`/event/registerusers/${eventId}`}
            className="nav-link my-0 tabs-link pl-3 flex items-center cursor-pointer">
            Registered Users
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default EventSideMenu;
