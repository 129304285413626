import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useCourseActions } from 'src/store/course/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import Pagination from 'src/components/common/pagination';
import AddCheckpointModel from './AddCheckpointModel';
import { useParams } from 'react-router-dom';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
const CheckpointsList = () => {
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);
  const courseAction = useCourseActions(dispatch);
  const { id } = useParams<string>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [checkpointAddFlag, setCheckpointAddFlag] = useState<string>('Add');
  const {
    isCheckpointAdded,
    paginationMeta,
    checkpoints,
    isCheckpointsLoading,
    isCheckpointDeleted
  } = useSelector((state: RootState) => {
    return {
      isCheckpointAdded: state.course.isCheckpointAdded,
      paginationMeta: state.course.paginationMeta,
      checkpoints: state.course.checkpoints,
      isCheckpointsLoading: state.course.isCheckpointsLoading,
      isCheckpointDeleted: state.course.isCheckpointDeleted
    };
  });

  useEffect(() => {
    courseAction.getCheckpointsRequest({ pageNumber: 1, id });
  }, []);

  useEffect(() => {
    if (isCheckpointAdded || isCheckpointDeleted) {
      courseAction.getCheckpointsRequest({ pageNumber: 1, id });
    }
  }, [isCheckpointAdded, isCheckpointDeleted]);

  const handleEditClick = (id: string) => {
    courseAction.getSingleCheckpointRequest(id);
    setCheckpointAddFlag('Edit');
  };

  const handlePageChange = (pageNumber: number) => {
    const data = { querySearch: searchValue, pageNumber };
    courseAction.getCheckpointsRequest(data);
  };
  const handleDeleteClick = (id: string) => {
    courseAction.deleteCheckpointRequest(id);
  };
  useEffect(() => {
    if (isCheckpointAdded || isCheckpointDeleted) {
      successMsg('Success');
      courseAction.resetCourseStateRequest();
    }
  }, [isCheckpointAdded, isCheckpointDeleted]);
  return (
    <>
      <ToastMessage />
      <div className="video-categries">
        <div className="lg:flex">
          <div className="tab-content w-full shadow border" id="tabs-tabContentVertical">
            {
              <>
                <div
                  className="tab-pane fade show active"
                  id="tabs-videoCategories"
                  role="tabpanel">
                  <div className="py-5 px-7">
                    <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                      <h2 className="font-semibold ">Checkpoints</h2>
                      <div className="md:flex items-center text-center">
                        <button
                          onClick={() => {
                            setCheckpointAddFlag('Add');
                          }}
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#AddCheckpointModel"
                          className="btn-primary w-auto mt-0 mr-0 ml-auto  borderonly-btn">
                          Add
                        </button>
                        <button
                          ref={btnRef}
                          className="hidden"
                          data-bs-toggle="modal"
                          data-bs-target="#categoryList">
                          demo
                        </button>
                      </div>
                    </div>
                    <Link to={`/courseTab/${id}`}>
                      <i className="fas fa-arrow-left mt-2 mb-2"></i>
                      <span className="ml-2">Go Back</span>
                    </Link>
                    <div className="overflow-x-auto-sm">
                      <table className="table-auto w-full border categories-table-wrapper">
                        <thead className="text-xs font-semibold uppercase bg-gray-100">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left">Title</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left">Description</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold text-left"></div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {isCheckpointsLoading ? (
                            <tr>
                              <td>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {checkpoints && checkpoints.length ? (
                                <>
                                  {checkpoints &&
                                    checkpoints.map((checkpoints) => {
                                      return (
                                        <tr key={checkpoints.id}>
                                          <td className="p-2">{checkpoints.title}</td>
                                          <td className="p-2 multiline-wrap-text multiline-text">
                                            {checkpoints.description}
                                          </td>
                                          <td className="p-2">
                                            <div className="dropdown relative">
                                              <a
                                                className="
                                        dropdown-toggle
                                        transition
                                        duration-150
                                        ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                                href="#"
                                                type="button"
                                                id="ActionButton"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i className="fas fa-ellipsis-v"></i>
                                              </a>
                                              <ul
                                                className="dropdown-menu action-dropdown"
                                                aria-labelledby="ActionButton">
                                                <li
                                                  onClick={() => handleEditClick(checkpoints.id)}
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#AddCheckpointModel">
                                                  <span className="dropdown-item flex-items cursor-pointer">
                                                    <i className="fas fa-edit w-6"></i>
                                                    <span>Edit</span>
                                                  </span>
                                                </li>
                                                <li
                                                  onClick={() => handleDeleteClick(checkpoints.id)}>
                                                  <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                                    <i className="fas fa-trash w-6"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              ) : (
                                <tr>
                                  <td>No Data Found</td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                        <Pagination
                          currentPage={paginationMeta.pageNumber}
                          totalCount={paginationMeta.totalCount}
                          pageSize={paginationMeta.pageSize}
                          onPageChange={(page: any) => handlePageChange(page)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <AddCheckpointModel checkpointAddFlag={checkpointAddFlag} />
    </>
  );
};

export default CheckpointsList;
