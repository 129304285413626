import { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles.scss';

const LibraryRows = () => {
  const [videoLayout, setVideoLayout] = useState(true);

  return (
    <div>
      <div className="custom-theme">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Library Rows</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Video Library</Link>
            </li>
            <li className="active">
              <strong>Library Rows</strong>
            </li>
          </ol>
        </div>

        <button type="button" className="btn-primary w-auto mx-0 ml-auto mb-3">
          Save Layout
        </button>
        <div className={`${videoLayout ? 'hide-lists' : 'show-lists'}`}>
          <div className="mobile-dropdown" onClick={() => setVideoLayout(!videoLayout)}>
            <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
              <p>Video Channel Layout</p>
              <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
            </div>
          </div>
          <ul
            className="nav nav-tabs horizontal-tabs-top flex md:flex-row overflow-auto list-none border-b-0 p-2 mb-4 bg-white shadow-card rounded-lg mobile-layout-ul"
            id="tabs-tab"
            role="tablist">
            <li className="nav-item" role="presentation">
              <a
                href="#tab-accounts"
                className="
                  nav-link
                  tabs-link
                  active"
                id="tab-accounts-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-accounts"
                role="tab"
                aria-controls="tab-accounts"
                aria-selected="true">
                <span className="flex items-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="h-4 w-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_1076_92631"
                      maskUnits="userSpaceOnUse"
                      x="1"
                      y="0"
                      width="14"
                      height="16">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.3999 5.47488L8.39992 0.815773C8.13325 0.616097 7.79992 0.616097 7.59992 0.815773L1.59992 5.47488C1.39992 5.608 1.33325 5.80767 1.33325 6.00735V13.3288C1.33325 14.4603 2.19992 15.3256 3.33325 15.3256H12.6666C13.7999 15.3256 14.6666 14.4603 14.6666 13.3288V6.00735C14.6666 5.80767 14.5999 5.608 14.3999 5.47488ZM9.33325 8.6697V13.9944H6.66658V8.6697H9.33325ZM12.6666 13.9944C13.0666 13.9944 13.3333 13.7282 13.3333 13.3288V6.34014L7.99992 2.21351L2.66659 6.34014V13.3288C2.66659 13.7282 2.93325 13.9944 3.33325 13.9944H5.33325V8.00411C5.33325 7.60476 5.59992 7.33853 5.99992 7.33853H9.99992C10.3999 7.33853 10.6666 7.60476 10.6666 8.00411V13.9944H12.6666Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1076_92631)">
                      <rect width="16" height="15.9922" fill="currentcolor" />
                    </g>
                  </svg>
                  <span>Highlight Reel</span>
                  <i className="fas fa-chevron-down ml-3"></i>
                </span>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-security"
                className="nav-link tabs-link"
                id="tabs-security-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-security"
                role="tab"
                aria-controls="tabs-security"
                aria-selected="false">
                <span className="flex items-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="h-4 w-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_1076_92624"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.86675 0.400098L14.2001 3.06547C14.9334 3.39864 15.3334 4.06499 15.3334 4.79796V11.1282C15.3334 11.9278 14.8667 12.5942 14.2001 12.9274L8.86675 15.5927C8.60008 15.726 8.33341 15.7926 8.00008 15.7926C7.73341 15.7926 7.40008 15.726 7.13341 15.5927L1.80008 12.9274C1.06675 12.5942 0.666748 11.8612 0.666748 11.1282V4.79796C0.666748 4.06499 1.06675 3.39864 1.73341 3.06547L7.06675 0.400098C7.66675 0.133561 8.33341 0.133561 8.86675 0.400098ZM8.00008 1.46625C7.93341 1.46625 7.80008 1.46625 7.73341 1.53288L2.80008 3.99835L8.00008 6.59709L13.2001 3.99835L8.26675 1.53288C8.20008 1.46625 8.13341 1.46625 8.00008 1.46625ZM2.33341 11.7946C2.13341 11.6613 2.00008 11.3948 2.00008 11.1949V5.0645L7.33342 7.72988V14.26L2.33341 11.7946ZM8.66675 14.26L13.6001 11.7946C13.8667 11.6613 14.0001 11.4614 14.0001 11.1949V5.0645L8.66675 7.72988V14.26Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1076_92624)">
                      <rect width="16" height="15.9922" fill="currentcolor" />
                    </g>
                  </svg>
                  <span>Add Space</span>
                  <i className="fas fa-chevron-down ml-3"></i>
                </span>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tab-analytics"
                className="nav-link tabs-link"
                id="tab-analytics-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-analytics"
                role="tab"
                aria-controls="tab-analytics"
                aria-selected="false">
                <span className="flex items-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="h-4 w-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_1076_92619"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.73341 8.58785L1.06675 5.25234C0.800081 5.11892 0.666748 4.91879 0.666748 4.65195C0.666748 4.38511 0.800081 4.18498 1.00008 4.05156L7.66675 0.716048C7.86675 0.649338 8.06675 0.649338 8.26675 0.716048L14.9334 4.05156C15.2001 4.18498 15.3334 4.38511 15.3334 4.65195C15.3334 4.91879 15.2001 5.11892 14.9334 5.25234L8.26675 8.58785C8.20008 8.65456 8.13341 8.65456 8.00008 8.65456C7.86675 8.65456 7.80008 8.65456 7.73341 8.58785ZM13.2001 4.65195L8.00008 2.05025L2.80008 4.65195L8.00008 7.25364L13.2001 4.65195ZM15.2667 11.0561C15.4001 11.3897 15.2667 11.7899 15.0001 11.9234L8.33341 15.2589C8.20008 15.3256 8.13341 15.3256 8.00008 15.3256C7.86675 15.3256 7.80008 15.3256 7.73341 15.2589L1.06675 11.9234C0.733415 11.7899 0.600081 11.3897 0.800081 11.0561C0.933415 10.7226 1.33341 10.5891 1.66675 10.7893L8.06675 13.9914L14.4667 10.7893C14.6667 10.5891 15.0667 10.7226 15.2667 11.0561ZM15.0001 8.58785C15.2667 8.45443 15.4001 8.05416 15.2667 7.72061C15.0667 7.38706 14.6667 7.25364 14.4667 7.45377L8.06675 10.6559L1.66675 7.45377C1.33341 7.25364 0.933415 7.38706 0.800081 7.72061C0.600081 8.05416 0.733415 8.45443 1.06675 8.58785L7.73341 11.9234C7.80008 11.9901 7.86675 11.9901 8.00008 11.9901C8.13341 11.9901 8.20008 11.9901 8.33341 11.9234L15.0001 8.58785Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1076_92619)">
                      <rect width="16" height="15.9922" fill="currentcolor" />
                    </g>
                  </svg>
                  <span>Recommendations</span>
                  <i className="fas fa-chevron-down ml-3"></i>
                </span>
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                href="#tabs-notification"
                className="nav-link tabs-link"
                id="tabs-notification-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabs-notification"
                role="tab"
                aria-controls="tabs-notification"
                aria-selected="false">
                <span className="flex items-center">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    className="h-4 w-4 mr-2"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="mask0_1076_92614"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.1332 4.46417L8.4665 11.1276C8.33317 11.2609 8.19984 11.3275 7.99984 11.3275H5.33317C4.93317 11.3275 4.6665 11.061 4.6665 10.6612V7.99579C4.6665 7.79589 4.73317 7.66262 4.8665 7.52935L11.5332 0.865919C11.7998 0.599381 12.1998 0.599381 12.4665 0.865919L15.1332 3.53129C15.3998 3.79783 15.3998 4.19764 15.1332 4.46417ZM13.9998 9.79492V13.3265C13.9998 14.4593 13.1332 15.3256 11.9998 15.3256H2.6665C1.53317 15.3256 0.666504 14.4593 0.666504 13.3265V3.99773C0.666504 2.86495 1.53317 1.9987 2.6665 1.9987H6.19984C6.59984 1.9987 6.8665 2.26524 6.8665 2.66505C6.8665 3.06485 6.59984 3.33139 6.19984 3.33139H2.6665C2.2665 3.33139 1.99984 3.59793 1.99984 3.99773V13.3265C1.99984 13.7263 2.2665 13.9929 2.6665 13.9929H11.9998C12.3998 13.9929 12.6665 13.7263 12.6665 13.3265V9.79492C12.6665 9.39511 12.9332 9.12858 13.3332 9.12858C13.7332 9.12858 13.9998 9.39511 13.9998 9.79492ZM11.9998 2.26524L13.7332 3.99773L7.73317 9.99482H5.99984V8.26233L11.9998 2.26524Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1076_92614)">
                      <rect width="16" height="15.9922" fill="currentcolor" />
                    </g>
                  </svg>
                  <span>Channels</span>
                  <i className="fas fa-chevron-down ml-3"></i>
                </span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="tabs-tabContent">
            <div
              className="tab-pane fade show active"
              id="tab-accounts"
              role="tabpanel"
              aria-labelledby="tab-accounts-tab">
              <div className="bg-white shadow-card rounded-lg">
                <div className="accordion md:p-4" id="videoAccordion">
                  <div className="accordion-item bg-white shadow border-0 mb-3">
                    <h2 className="accordion-header mb-0" id="branding">
                      <button
                        className="accordion-button
                        collapsed
                        accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne">
                        Branding
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      data-bs-parent="#videoAccordion"
                      className="accordion-collapse border-0 collapse">
                      <div className="accordion-body py-4 px-5">
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0 pr-5">
                            <div>
                              <h6 className="font-bold mb-1">Set Title</h6>
                              <p>
                                The title is the name of your site. That will appear in the site
                                title & tagline.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Title</label>
                              <input
                                type="text"
                                className="input-style w-full"
                                placeholder="MEA TV | Events & meetings Astraliya"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0 pr-5">
                            <div>
                              <h6 className="font-bold mb-1">Logo</h6>
                              <p>
                                Site logo that will appear on the main navigatin across your videos
                                site.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <button
                                type="button"
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                                data-bs-dismiss="modal">
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0 pr-5">
                            <div>
                              <h6 className="font-bold mb-1">Logo Width</h6>
                              <p>Used to scale the site logo on the main navigation header.</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="w-full bg-gray-200 h-1 mb-6">
                              <div className="bg-primary h-1 w-1/2"></div>
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0 pr-5">
                            <div>
                              <h6 className="font-bold mb-1">Logo Link</h6>
                              <p>
                                Set the hyperlink for the main header log. If no link is set then
                                the default homepage will be used.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Link</label>
                              <input
                                type="text"
                                className="input-style w-full"
                                placeholder="Default"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0 pr-5">
                            <div>
                              <h6 className="font-bold mb-1">Favicon Icon</h6>
                              <p>
                                Icon that is displayed next to the URL of the site in a browsers
                                address bar. (Recommended size 16X16px)
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <button
                                type="button"
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                                data-bs-dismiss="modal">
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingTwo">
                      <button
                        className="accordion-button
                        collapsed
                        accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo">
                        Backgrounds & Colors
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse border-0 collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Login Button Background Color</h6>
                              <p>
                                Set the background color of the login button across the video
                                library.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Header Background Color</h6>
                              <p>
                                Set the background color of the navigation header across the video
                                library.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Sticky Header Background Color</h6>
                              <p>Sticky background color of the navigation header when scrolling</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Header Border Bottom Color</h6>
                              <p>Set the color of the header border bottom.</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="grid">
                              <div className="mb-2 flex items-center">
                                <input type="checkbox" className="rounded-sm shadow" checked />
                                <span className="font-semibold ml-2">Transparent</span>
                              </div>
                              <input type="text" className="input-style w-56" placeholder="Type" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Menu/Navigation Font Color</h6>
                              <p>Set the color for navigation font.</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Body Background Color</h6>
                              <p>Set the background color for the body of the video library.</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Body Background Image</h6>
                              <p>Set the background image for the body of the video library.</p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <button
                                type="button"
                                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                                data-bs-dismiss="modal">
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Custom Body HTML/CSS</h6>
                              <p>
                                Use the field to insert custom css into the video home html or css
                                into the video home.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font Size</label>
                              <input type="text" className="input-style w-36" placeholder="24" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingThree">
                      <button
                        className="accordion-button
                          collapsed
                          accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree">
                        Fonts
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingThree"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Custom Font Loader Code</h6>
                              <p>
                                To use custom fonts such as Google font or Adobe Typekey, copy the
                                code from the font library provider.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Font code</label>
                              <input type="text" className="input-style w-56" placeholder="Code" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Heading Font Family CSS</h6>
                              <p>
                                Add the css rules to specify the heading font families from the font
                                library.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Heading Font Family</label>
                              <input type="text" className="input-style w-56" placeholder="Font" />
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Highlight Reel</h6>
                              <p>
                                Add the rules to specify the body font families from the font
                                library.
                              </p>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Body Font Family</label>
                              <input type="text" className="input-style w-56" placeholder="Font" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingFour">
                      <button
                        className="accordion-button
                          collapsed
                          accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour">
                        Video Cards
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">Test</div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingFive">
                      <button
                        className="accordion-button
                          collapsed
                          accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive">
                        General Layout & Headings
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">
                        <div className="mb-5">
                          <h6 className="font-semibold mb-2">Home Page Ad Banner</h6>
                          <p>
                            Display a custom on your home page horizontol ad space,that will appear
                            below your highlight reel and above your search filter.Ideal for a
                            welcome message and/or advertising space.
                          </p>
                          <textarea rows={3} className="input-style mt-3" placeholder="Editor" />
                        </div>
                        <div className="mb-5">
                          <h6 className="font-semibold mb-2">Login HTML Message</h6>
                          <p>Login message to appear on your video library local login page.</p>
                          <textarea rows={3} className="input-style mt-3" placeholder="Editor" />
                        </div>
                        <div className="mb-5">
                          <h6 className="font-semibold mb-2">Registration HTML Message</h6>
                          <p>
                            Register message to appear on your video library local registration
                            page.
                          </p>
                          <textarea rows={3} className="input-style mt-3" placeholder="Editor" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingSix">
                      <button
                        className="accordion-button
                          collapsed
                          accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix">
                        Advanced Settings
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Pay Per View</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">View Cart with Authentication</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Display Date</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Display Time</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Display Timezone</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold mb-1">Default Sort Order</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Sort Order</label>
                              <select className="input-style w-1/2">
                                <option>Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold">Display Page Size</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <label className="block mb-1">Page Size</label>
                              <select className="input-style w-1/2">
                                <option>Select</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold">Channel Menu Expanded</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lg:flex mb-5">
                          <div className="lg:w-1/2 pb-0">
                            <div>
                              <h6 className="font-bold">Enable Categories Page</h6>
                            </div>
                          </div>
                          <div className="lg:w-1/2 pb-0">
                            <div className="form-check form-switch pl-0 flex items-center">
                              <div className="relative">
                                <input
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="Credit"
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                                <i className="fas fa-times checkbox-cross"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-white shadow border-0 my-3">
                    <h2 className="accordion-header mb-0" id="headingFive">
                      <button
                        className="accordion-button
                          collapsed
                          accordion-btn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven">
                        Search Filter Settings
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#videoAccordion">
                      <div className="accordion-body py-4 px-5">
                        <div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Hide Search filter Menu</h6>
                                <p>
                                  Will hide the search fileter menu on the video library home page.
                                </p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <div className="form-check form-switch pl-0 flex items-center">
                                  <div className="relative">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="Credit"
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Channel Alias</h6>
                                <p>Will display te Channel name using the following label</p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <label className="block mb-1">Channel Label</label>
                                <input
                                  type="text"
                                  className="input-style w-full"
                                  placeholder="Label"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Sub Channel</h6>
                                <p>Will display te Sub-Channel menu with the following label.</p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div className="flex items-end">
                                <div className="form-check form-switch pl-0 flex items-center">
                                  <div className="relative mr-8">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="Credit"
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <label className="block mb-1">Channel Label</label>
                                  <input
                                    type="text"
                                    className="input-style w-full"
                                    placeholder="Label"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Categories</h6>
                                <p>
                                  Will display categories on your filter menu and use the label
                                  across your title. The default name will be Tags if left blank.
                                </p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div className="flex items-end">
                                <div className="form-check form-switch pl-0 flex items-center">
                                  <div className="relative mr-8">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="Credit"
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <label className="block mb-1">Channel Label</label>
                                  <input
                                    type="text"
                                    className="input-style w-full"
                                    placeholder="Label"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Tags</h6>
                                <p>
                                  Will display categories on your filter menu and across your site
                                  using the following label.The default name will be Tags if left
                                  blank.
                                </p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div className="flex items-end">
                                <div className="form-check form-switch pl-0 flex items-center">
                                  <div className="relative mr-8">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="Credit"
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <label className="block mb-1">Channel Label</label>
                                  <input
                                    type="text"
                                    className="input-style w-full"
                                    placeholder="Label"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lg:flex mb-5">
                            <div className="lg:w-1/2 pb-0">
                              <div>
                                <h6 className="font-bold mb-1">Speaker</h6>
                                <p>
                                  Will display categories on your filter menu and across your site
                                  using the following label.The default name will be Speaker if left
                                  blank.
                                </p>
                              </div>
                            </div>
                            <div className="lg:w-1/2 pb-0">
                              <div className="flex items-end">
                                <div className="form-check form-switch pl-0 flex items-center">
                                  <div className="relative mr-8">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="Credit"
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <label className="block mb-1">Channel Label</label>
                                  <input
                                    type="text"
                                    className="input-style w-full"
                                    placeholder="Label"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="tabs-security"
              role="tabpanel"
              aria-labelledby="tabs-security-tab">
              tab 2
            </div>
            <div
              className="tab-pane fade"
              id="tab-analytics"
              role="tabpanel"
              aria-labelledby="tabs-security-tab">
              Tab 3 content
            </div>
            <div
              className="tab-pane fade"
              id="tabs-security"
              role="tabpanel"
              aria-labelledby="tabs-security-tab">
              Tab 4 content
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryRows;
