import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import MultipleChoiceModal from './MultipleChoiceModal';
import OpenTextModal from './OpenTextModal';
import QuizViewResultModel from './QuizViewResultModel';
import MultipleChoiceResultModal from './MultipleViewResultModel';
import QuizModal from './QuizModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { useParams } from 'react-router-dom';
import StarRatingModal from './StarRatingModal';
import { CSVLink } from 'react-csv';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
const PollPopout = () => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const quizRef = useRef<HTMLButtonElement>(null);
  const multipleChoiceRef = useRef<HTMLButtonElement>(null);
  const starRatingRef = useRef<HTMLButtonElement>(null);
  const openTextRef = useRef<HTMLButtonElement>(null);
  const [pollType, setPollType] = useState<number>();
  const [modalEditFlag, setModalEditFlag] = useState<boolean>(false);
  const { id } = useParams<string>();
  const { polls, singleVideo, isPollSaved, isPollDeleted, isPollStatusUpdated, message } =
    useSelector((state: RootState) => {
      return {
        polls: state.videos.polls,
        singleVideo: state.videos.singleVideo,
        isPollSaved: state.videos.isPollSaved,
        isPollDeleted: state.videos.isPollDeleted,
        isPollStatusUpdated: state.videos.isPollStatusUpdated,
        message: state.videos.message
      };
    });
  const handlePollResultClick = (pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
  };
  const handlePollType = (pollType: number) => {
    if (pollType === 0) {
      return 'fa fa-trophy';
    } else if (pollType === 1) {
      return 'fa fa-th-list';
    } else if (pollType === 3) {
      return 'fa fa-star';
    } else if (pollType === 2) {
      return 'fa fa-question';
    }
  };
  const handleEditClick = (pollType: number, pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
    if (pollType === 0) {
      if (quizRef.current) {
        quizRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 1) {
      if (multipleChoiceRef.current) {
        multipleChoiceRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 3) {
      if (starRatingRef.current) {
        starRatingRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 2) {
      if (openTextRef.current) {
        openTextRef.current.click();
        setModalEditFlag(true);
      }
    }
  };
  const handleViewQuizResultClick = (pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
  };
  const handlePollTypeName = (pollType: number) => {
    if (pollType === 0) {
      return 'Quiz';
    } else if (pollType === 1) {
      return 'Multiple Choice';
    } else if (pollType === 3) {
      return 'Star Rating';
    } else if (pollType === 2) {
      return 'Open Text';
    }
  };
  useEffect(() => {
    if (id) {
      videoAction.getPollRequest(id);
    }
  }, [id]);

  useEffect(() => {
    if (isPollSaved || isPollDeleted || isPollStatusUpdated) {
      id && videoAction.getPollRequest(id);
      successMsg(message);
      videoAction.resetVideoLibraryRequest();
    }
  }, [isPollSaved, isPollDeleted, isPollStatusUpdated]);

  const handlePollStatus = (pollId: string, status: any) => {
    const payloadData = {
      pollId: pollId,
      updateData: [{ op: 'replace', path: 'activePollStatus', value: status ? 1 : null }]
    };
    videoAction.updatePollStatusRequest(payloadData);
  };

  return (
    <>
      <div>
        <ToastMessage />
        <div className="modal-content live-studio-border">
          <div className="flex justify-end">
            <div className="dropdown relative">
              <button
                onClick={() => setModalEditFlag(false)}
                className="dropdown-toggle border input-style mx-auto flex-items btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn"
                type="button"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="flex">New </span>
                <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-ul w-32 create-poll-menu"
                aria-labelledby="action-dropdown">
                <li className="cursor-pointer hidden" onClick={() => setPollType(0)}>
                  <span
                    ref={quizRef}
                    className="action-menu"
                    data-bs-toggle="modal"
                    data-bs-target="#QuizModal">
                    Quiz
                  </span>
                </li>
                <li className="cursor-pointer" onClick={() => setPollType(1)}>
                  <span
                    ref={multipleChoiceRef}
                    className="action-menu"
                    data-bs-toggle="modal"
                    data-bs-target="#MultipleChoiceModal">
                    Multiple Choice
                  </span>
                </li>
                <li className="cursor-pointer" onClick={() => setPollType(3)}>
                  <span
                    ref={starRatingRef}
                    className="action-menu"
                    data-bs-toggle="modal"
                    data-bs-target="#StarRatingModal">
                    Star Rating
                  </span>
                </li>
                <li className="cursor-pointer" onClick={() => setPollType(2)}>
                  <span
                    ref={openTextRef}
                    className="action-menu"
                    data-bs-toggle="modal"
                    data-bs-target="#OpenTextModal">
                    Open Text
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-3">
            {polls &&
              polls.map((poll) => {
                return (
                  <div
                    key={poll.pollId}
                    className="sm:w-full flex p-4 border rounded-md mb-2 justify-between cursor-pointer">
                    <div
                      onClick={() => handlePollResultClick(poll.pollId)}
                      className="site-clr flex items-center">
                      <p className="gd-info mr-4">
                        <i className={`${handlePollType(poll.pollType)}`}></i>
                      </p>
                      <div className="text-left">
                        <span className="font-semibold">{handlePollTypeName(poll.pollType)}</span>
                        <p>{poll.pollTitle}</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="form-check form-switch pl-0 mr-3 relative">
                        <input
                          onChange={(e) => handlePollStatus(poll.pollId, e.target.checked)}
                          className="form-check-input switch-btn"
                          type="checkbox"
                          role="switch"
                          checked={poll.activePollStatus === null ? false : true}
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                      </div>
                      <span>
                        <div className="text-left mt-2">
                          <div className="dropdown relative">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              id="action-dropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <img src="../../assets/img/dots-vertical.svg" className="w-5 h-5" />
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-ul"
                              aria-labelledby="action-dropdown">
                              <li onClick={() => handleEditClick(poll.pollType, poll.pollId)}>
                                <button type="button" className="action-menu flex items-center">
                                  <i className="fas fa-edit w-6 text-left"></i>
                                  <span>Edit</span>
                                </button>
                              </li>
                              <li>
                                <span className="action-menu flex items-center cursor-pointer text-red-500">
                                  <i className="fas fa-trash w-6"></i>
                                  <span>Delete</span>
                                </span>
                              </li>
                              {poll.pollType === 0 ? (
                                <li onClick={() => handleViewQuizResultClick(poll.pollId)}>
                                  <button
                                    type="button"
                                    className="action-menu flex items-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#QuizResultModal">
                                    <i className="fas fa-play w-6 text-left"></i>
                                    <span>View Result</span>
                                  </button>
                                </li>
                              ) : poll.pollType === 1 ? (
                                <li onClick={() => handleViewQuizResultClick(poll.pollId)}>
                                  <button
                                    type="button"
                                    className="action-menu flex items-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#MultipleChoiceResultModal">
                                    <i className="fas fa-play w-6 text-left"></i>
                                    <span>View Result</span>
                                  </button>
                                </li>
                              ) : (
                                <></>
                              )}
                            </ul>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="modal-body relative py-4 px-10"></div>
        </div>
      </div>
      {id && (
        <>
          <QuizModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <MultipleChoiceModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <StarRatingModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <OpenTextModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <QuizViewResultModel />
          <MultipleChoiceResultModal />
        </>
      )}
    </>
  );
};

export default PollPopout;
