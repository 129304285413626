import React from 'react';
import './styles.scss';

const Screen1 = () => {
  return (
    <div>
      <div className="ibox-content ">
        <div id="rightMenuContentMainDiv" className="">
          <div id="dvStep1" className="ibox-content ">
            <div
              id="subData1"
              className="flex justify-center items-center flex-col text-sm font-normal">
              <h4 className="text-sm font-normal">Your live stream is starting soon.</h4>
              <p className="text-sm font-normal max-w-lg text-center">
                {' '}
                Click <b className="text-black">Prepare to Go Live</b> when you are ready to start
                your broadcast. You are a few steps away from live streaming to your online viewers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Screen1;
