import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { useParams, useNavigate } from 'react-router-dom';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import * as XLSX from 'xlsx';
import Pagination from 'src/components/common/pagination';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from 'src/components/loader';
const SingleQuiz: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { questionByQuiz, paginationMeta, singleQuizTotal, isQuestionByQuizIdLoading } =
    useSelector((state: RootState) => {
      return {
        questionByQuiz: state.analytics.questionByQuiz,
        paginationMeta: state.analytics.paginationMeta,
        singleQuizTotal: state.analytics.singleQuizTotal,
        isQuestionByQuizIdLoading: state.analytics.isQuestionByQuizIdLoading
      };
    });

  useEffect(() => {
    id && analyticsAction.getSingleQuizTotalRequest(id);
    const data = { id, pageNumber: 1, pageSize: 20 };
    id && analyticsAction.getQuestionByQuizIdRequest(data);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    const data = { id, pageNumber, pageSize: 20 };
    id && analyticsAction.getQuestionByQuizIdRequest(data);
  };

  const handleQuestionClick = (questionId: string) => {
    navigate(`/analytic/question/${questionId}`);
  };
  const handleDownloadQuizResultClick = () => {
    if (singleQuizTotal) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_API_URL}/v2/video/polls/${singleQuizTotal.pollId}/download/quiz?videoId=${singleQuizTotal.videoId}`
        )
        .then((response: any) => {
          downloadExcel(response.data.result);
        })
        .catch((error) => {
          console.error('Error downloading file:', error);
        });
    }
  };
  const downloadExcel = (result: any) => {
    if (result) {
      const decodedData = atob(result.filestream);

      const uint8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const fileName = result.filename;
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      <div className="dashboard">
        <div>
          <div className="mt-2">
            <div className="flex justify-between">
              <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Quiz Results</h2>
              </div>
              <button
                onClick={() => handleDownloadQuizResultClick()}
                type="button"
                className=" btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Download Quiz Result
              </button>
            </div>
            <Link to={`/analytic/quiz`}>
              <i className="fas fa-arrow-left mt-2 mb-2"></i>
              <span className="ml-2">Go Back</span>
            </Link>
            <div className="py-4">
              <h3 className="font-medium">
                Quiz Name: {singleQuizTotal && singleQuizTotal.quizName}
              </h3>
              <h3 className="font-medium">
                Video: {singleQuizTotal && singleQuizTotal.videoTitle}
              </h3>
              <h3 className="font-medium">
                Quiz Time: {singleQuizTotal && singleQuizTotal.quizTimeInFormat}{' '}
              </h3>
            </div>
            <div className="flex flex-wrap mt-5 dashboard-tiles">
              <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
                <div className="dashboard-card flex-items justify-center">
                  <div className="flex-items w-full p-4">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">
                        {singleQuizTotal && singleQuizTotal.totalCompleted}
                      </span>
                      <span className="mb-2">Total Completed</span>
                    </div>
                    <div>
                      <div className="rounded-full">
                        <div
                          className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                          <i className="fa fa-question-circle"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
                <div className="dashboard-card flex-items justify-center">
                  <div className="flex-items w-full p-4">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">
                        {singleQuizTotal && singleQuizTotal.totalFails}
                      </span>
                      <span className="mb-2">Total Failed</span>
                    </div>
                    <div>
                      <div className="rounded-full">
                        <div
                          className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                          <i className="fa fa-check-square-o"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
                <div className="dashboard-card flex-items justify-center">
                  <div className="flex-items w-full p-4">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">
                        {singleQuizTotal && singleQuizTotal.totalCorrectAnswers}
                      </span>
                      <span className="mb-2">Correct Answers</span>
                    </div>
                    <div>
                      <div className="rounded-full">
                        <div
                          className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                          <i className="fa fa-list-alt" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
                <div className="dashboard-card flex-items justify-center">
                  <div className="flex-items w-full p-4">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">
                        {singleQuizTotal && singleQuizTotal.totalIncorrectAnswers}
                      </span>
                      <span className="mb-2">Incorrect Answers</span>
                    </div>
                    <div>
                      <div className="rounded-full">
                        <div
                          className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                          <i className="fa fa-warning"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Quiz Questions</h2>
            </div>
            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Question Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Name</div>
                          </th>

                          <th className="p-2">
                            <div className="font-semibold text-left">Created Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created BY</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">InCorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Highest InCorrect Answer</div>
                          </th>

                          <th className="p-2">
                            <div className="font-semibold text-left">Average Score</div>
                          </th>

                          <th className="p-2">
                            <div className="font-semibold text-left"> Number of Attempts </div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Question Type</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Intended Weight </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isQuestionByQuizIdLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {questionByQuiz && questionByQuiz.length ? (
                              questionByQuiz.map((item, index) => {
                                return (
                                  <tr
                                    className="cursor-pointer"
                                    onClick={() => handleQuestionClick(item.questionID)}
                                    key={index}>
                                    <td className="p-2 w-52">
                                      <div className="text-left">{item.questionName}</div>
                                    </td>
                                    <td className="p-2 w-52">
                                      <div className="text-left">{item.quizName}</div>
                                    </td>

                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.createdDate
                                          ? defaultDateFormatter(item.createdDate)
                                          : ''}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.createdBy}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastActivityDate
                                          ? defaultDateFormatter(item.lastActivityDate)
                                          : ''}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.correctAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.incorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.highestIncorrectAnswers}
                                      </div>
                                    </td>

                                    <td className="p-2">
                                      <div className="text-left">{item.averageScore}%</div>
                                    </td>

                                    <td className="p-2">
                                      <div className="text-left">{item.numberOfAttempts}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.questionType}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.intendedWeight}</div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="text-center py-2" colSpan={5}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleQuiz;
