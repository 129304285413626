import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import AddNewEvent from '../components/AddNewEvent';
import { RootState } from 'src/store/reducers/state';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from 'src/components/common/pagination';
import { useEventActions } from 'src/store/event/actions';
import { AddEventModal } from 'src/models/eventModel';
import { DebounceInput } from 'react-debounce-input';
import { minutesToHourMinutes } from 'src/components/utilities/utils';
import moment from 'moment';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
const Event: React.FC = () => {
  interface EventFilterProp {
    title?: string;
    name?: string;
    pageNumber: number | string;
    pageSize?: number | string;
    sortFieldName?: string;
    isAscending?: boolean;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const [rightClickEventId, setRightClickEventId] = useState('');
  const eventAction = useEventActions(dispatch);
  const [filter, setFilter] = useState<EventFilterProp>({
    pageNumber: 1,
    pageSize: 25,
    sortFieldName: 'eventdate',
    isAscending: false
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const { events, isEventSaved, isEventDeleted, paginationMeta, loader } = useSelector(
    (state: RootState) => {
      return {
        events: state.event.events,
        isEventSaved: state.event.isEventSaved,
        isEventDeleted: state.event.isEventDeleted,
        paginationMeta: state.event.paginationMeta,
        loader: state.event.loading
      };
    }
  );
  const handleAction = (id: string, type: string, eventUrl: string) => {
    const url = eventUrl;
    if (type == 'edit') window.open(`/event/edit/${id}`, '_blank');
    else if (type == 'registeredusers') navigate(`/event/registerusers/${id}`);
    else if (type == 'delete') return true;
    else if (type == 'launch') window.open(url, '_blank');
    else return false;
  };
  const handlePageChange = (pageNumber: number) => {
    setFilter({
      ...filter,
      pageNumber,
      pageSize: 25,
      name: searchValue
    });
  };
  const copyTextToClipboard = (eventUrl: string) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(`${eventUrl}`);
      successMsg('Url copied to clipboard!');
    } else {
      document.execCommand('copy', true, `${eventUrl}`);
    }
  };

  useEffect(() => {
    eventAction.getEventRequest(filter);
  }, [isEventDeleted, filter, isEventSaved]);

  const handleSearchVideo = (e: string) => {
    setSearchValue(e);
    eventAction.getEventRequest({
      pageNumber: 1,
      pageSize: 25,
      name: e
    });
  };

  const handleDuration = (value: string, duration: number) => {
    if (value.includes('.')) {
      return moment.duration(duration, 'minutes').days();
    } else {
      return minutesToHourMinutes(duration);
    }
  };
  const openInNewTabClick = () => {
    handleResetRightClick();
  };

  const handleRightClick = (e: any, eventId: string) => {
    e.preventDefault();
    setRightClickEventId(eventId);
  };
  useEffect(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, [rightClickEventId]);

  const handleResetRightClick = () => {
    setRightClickEventId('');
  };
  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Events</h2>
                <div className="lg:flex items-center text-center">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#AddNewEvent"
                    className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn">
                    Add
                  </button>
                </div>
              </div>
              <div className="md:flex text-center justify-end mt-2">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <DebounceInput
                    minLength={2}
                    className="input-style w-48 mr-3 mb-1"
                    debounceTimeout={500}
                    onChange={(event) => handleSearchVideo(event.target.value)}
                  />
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto-sm">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Event Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Event Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Time</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Timezone</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Duration(hh:mm / days)</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Registrations</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Event Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loader ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {events && events?.length ? (
                            <>
                              {events &&
                                events.map((event, index) => {
                                  return (
                                    <tr
                                      onContextMenu={(e) => handleRightClick(e, event.id)}
                                      key={index}>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="text-left">{event.name}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.title}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.displayDate}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.displayTime}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.timezoneAbbv}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {handleDuration(event.displayDuration, event.duration)}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.noOfRegistrations}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{event.displayEventType}</div>
                                      </td>
                                      {rightClickEventId === event.id ? (
                                        <td className="text-left">
                                          <div>
                                            <div className="flex justify-center">
                                              <div>
                                                <div className="dropdown relative">
                                                  <a
                                                    ref={ref}
                                                    className="
                                                 dropdown-toggle
                                                 transition
                                                 duration-150
                                                 ease-in-out"
                                                    href="#"
                                                    type="button"
                                                    id="ActionButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                  </a>
                                                  <ul
                                                    className="dropdown-menu action-dropdown"
                                                    aria-labelledby="ActionButton">
                                                    <li
                                                      onClick={() => {
                                                        openInNewTabClick();
                                                        handleAction(
                                                          event.id,
                                                          'edit',
                                                          event.eventUrl
                                                        );
                                                      }}>
                                                      <span className="dropdown-item flex-items cursor-pointer">
                                                        <i className="fas fa-edit w-6"></i>
                                                        <span>Open in new tab</span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      ) : (
                                        <td className="p-2">
                                          <div className="text-left">
                                            <div className="dropdown relative">
                                              <button
                                                className="dropdown-toggle"
                                                type="button"
                                                id="action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img
                                                  src="../../assets/img/dots-vertical.svg"
                                                  className="w-5 h-5"
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu dropdown-menu-ul"
                                                aria-labelledby="action-dropdown">
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      handleAction(
                                                        event.id,
                                                        'edit',
                                                        event.eventUrl
                                                      );
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fas fa-edit w-6 text-left"></i>
                                                    <span>Edit</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      handleAction(
                                                        event.id,
                                                        'launch',
                                                        event.eventUrl
                                                      );
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fa fa-play w-6 text-left"></i>
                                                    <span>Launch</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      handleAction(
                                                        event.id,
                                                        'registeredusers',
                                                        event.eventUrl
                                                      );
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fa fa-users w-6 text-left"></i>
                                                    <span>Registered Users</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      copyTextToClipboard(event.eventUrl);
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fa fa-link w-6 text-left"></i>
                                                    <span>Copy Link</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <span className="action-menu flex items-center cursor-pointer text-red-500">
                                                    <i className="fas fa-trash w-6"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta)?.length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modals  */}
        <AddNewEvent eventName="" />
      </div>
    </>
  );
};

export default Event;
