import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddNavigationMenu from 'src/components/admin/videos/components/AddNavigationMenu';
import { AddNavigationModel, DeleteNavigationData } from 'src/models/appearanceModel';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
import DeleteNavigationModal from './DeleteNavigation';
import Loader from 'src/components/loader';

const Navigation = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const [navigationAddFlag, setNavigationAddFlag] = useState<AddNavigationModel>({
    saveFlag: 'Add',
    navigationId: ''
  });
  const [navigationId, setNavigationId] = useState<DeleteNavigationData>({ id: '', name: '' });

  const { navigations, loading } = useSelector((state: RootState) => {
    return {
      navigations: state.appearance.navigations,
      loading: state.appearance.loading
    };
  });

  const handleChannels = () => {
    setNavigationAddFlag({ saveFlag: "Add" })
  }

  const handleEdit = (id: string) => {
    setNavigationAddFlag({ saveFlag: 'edit', navigationId: id })
    appearanceAction.getSingleNavigationRequest(id)
  }

  return (
    <>
      <div className="tab-pane fade" id="tabs-navigation" role="tabpanel">
        <div className="p-3">
          <div className="md:flex items-center justify-between mb-5">
            <h6 className="font-semibold mb-0">Navigation Menu</h6>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#AddnavigationModal"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn"
              onClick={handleChannels}
            >
              Add Navigation Menu
            </button>
          </div>
          <div className="overflow-x-auto">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-50">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Page</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">URL</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {loading ? (<Loader />) : (
                  <>
                    {navigations.length ?
                      <>
                        {!!navigations && !!navigations.length && navigations.map((menu, idx) => (
                          <tr key={idx}>
                            <td className="p-2">{menu.linkName}</td>
                            <td className="p-2">{menu.linkURL}</td>
                            <td className="p-2">
                              <div className="flex items-center">
                                <button
                                  onClick={() => handleEdit(menu.id)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#AddnavigationModal"
                                >
                                  <img src="../../assets/img/icons/edit.svg" className="w-4 h-4 mr-2" />
                                </button>
                                <button
                                  onClick={() => setNavigationId({ id: menu.id, name: menu.linkName })}
                                  data-bs-toggle="modal"
                                  data-bs-target="#DeleteNavigationModal"
                                >
                                  <img src="../../assets/img/icons/trash.svg" className="w-4 h-4 mr-2" />
                                </button>
                                <img src="../../assets/img/icons/tabler_drag-drop.svg" className="w-4 h-4" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </> : (
                        <tr>
                          <td>No Data Found</td>
                        </tr>)}
                  </>
                )}


              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Add Navigation menu  */}
      <AddNavigationMenu navigationAddFlag={navigationAddFlag} />
      <DeleteNavigationModal navigationId={navigationId.id} />
    </>
  );
};

export default Navigation;
