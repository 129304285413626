import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import FormInputField from 'src/components/common/formInputField';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { useUserActions } from 'src/store/users/actions';
import Loader from 'src/components/loader';
const AddSpeaker = ({ setSelectedSpeakerName }: any) => {
  const ref = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const videoActions = useVideosActions(dispatch);
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [uploadProfileLoading, setUploadProfileLoading] = useState<boolean>(false);
  const [uploadProfileSuccess, setUploadProfileSuccess] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control
  } = useForm();

  const { isSaved, memberId, memberDetail, isMemberProfileUpdated } = useSelector(
    (state: RootState) => {
      return {
        isSaved: state.videos.isSaved,
        memberId: state.videos.memberId,
        memberDetail: state.users.memberDetail,
        isMemberProfileUpdated: state.users.isMemberProfileUpdated
      };
    }
  );

  const onSubmit = (data: any) => {
    const finalData = { ...data, roles: ['speaker'] };
    videoActions.addSpeakerRequest(finalData);
    videoActions.resetVideoLibraryRequest();
  };
  const speakerName = watch('firstName');
  useEffect(() => {
    if (speakerName) {
      setSelectedSpeakerName(speakerName);
    }
  }, [speakerName]);

  const handleCloseClick = () => {
    reset({
      title: '',
      suffix: '',
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      jobTitle: '',
      speakerEmailNotRequired: false
    });
    setSelectedFile('');
    setProfileUrl('');
    userAction.resetMemberDataStateRequest();
    setShowProfile(false);
  };

  useEffect(() => {
    if (uploadProfileSuccess == 'success') {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
        setUploadProfileSuccess('');
      }
    }
  }, [uploadProfileSuccess]);

  const handleMemberProfileUpload = async (file: any) => {
    uploadImageToS3(
      file[0],
      `v2/member/uploadprofile?memberId=${memberId}&`,
      setProfileUrl,
      'member',
      setUploadProfileLoading,
      setUploadProfileSuccess
    );
    setSelectedFile(file[0].name);
  };
  const handleSaveClick = () => {
    if (memberId) {
      const updateData = { memberId, profileUrl };
      userAction.updateMemberProfileRequest(updateData);
    }
  };
  useEffect(() => {
    if (profileUrl) {
      handleSaveClick();
    }
  }, [profileUrl]);
  useEffect(() => {
    if (isMemberProfileUpdated) {
      userAction.getMemberDetailRequest(memberId);
      userAction.resetMemberRequest();
    }
  }, [isMemberProfileUpdated]);

  useEffect(() => {
    if (isSaved) {
      setShowProfile(true);
    }
  }, [isSaved]);
  return (
    <div
      className="modal fade"
      id="AddSpeakerModal"
      aria-labelledby="AddSpeakerModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="AddSpeakerModalLabel">
              Add Speaker
            </h5>
            <button
              ref={ref}
              type="button"
              onClick={handleCloseClick}
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <div className="w-2/4 mx-auto">
              {!showProfile && (
                <div className="relative w-20 h-20 mx-auto mb-5">
                  {memberDetail ? (
                    <img
                      src={
                        memberDetail.profileURL
                          ? memberDetail.profileURL
                          : '../../assets/img/user-png.png'
                      }
                      className="w-full rounded-md"
                      style={{
                        height: '100%',
                        width: '100%'
                      }}
                    />
                  ) : (
                    <img src="../../assets/img/user-png.png" className="w-full rounded-md" />
                  )}

                  {/* <div className="absolute w-10 h-10 flex-items speaker-dlt cursor-pointer">
                  <span className="bg-white rounded-md p-2 shadow">
                    <img src={`${profileUrl ? profileUrl : '../../assets/img/icons/trash.png'}`} />
                  </span>
                </div> */}
                </div>
              )}

              {showProfile && (
                <div className={`form-group mb-4`}>
                  <div className="input-style p-0 speaker-browse-file flex items-center m-4">
                    <input
                      id="uploadFile"
                      disabled
                      value={selectedFile}
                      className="pl-2"
                      placeholder="No file chosen"
                    />
                    <div className="fileUpload btn btn-primary mt-0 px-1">
                      <span>Browse</span>
                      <input
                        id="uploadBtn"
                        type="file"
                        className="upload"
                        onChange={(e) => handleMemberProfileUpload(e.target.files)}
                      />
                    </div>
                  </div>
                  {uploadProfileLoading && <Loader />}
                </div>
              )}
            </div>
            {!showProfile && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Title'}
                      type={'text'}
                      fieldName={'title'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Suffix'}
                      type={'text'}
                      fieldName={'suffix'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  <div className={`form-group`}>
                    <FormInputField
                      label={'First Name'}
                      type={'text'}
                      fieldName={'firstName'}
                      register={register}
                      errors={errors}
                      isRequired={true}
                    />
                  </div>
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Last Name'}
                      type={'text'}
                      fieldName={'lastName'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-3">
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Email'}
                      type={'text'}
                      fieldName={'email'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <div className="flex mt-5 items-center">
                    <div className="form-check form-switch pl-0 relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        {...register('speakerEmailNotRequired')}
                        id="speakerEmailNotRequired"
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold ml-1 text-md"
                      htmlFor="Email">
                      No Email for Speaker
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-3">
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Company'}
                      type={'text'}
                      fieldName={'company'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                  <div className={`form-group`}>
                    <FormInputField
                      label={'Job Title'}
                      type={'text'}
                      fieldName={'jobTitle'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                </div>
                <div className={`form-group mb-4`}>
                  <label className="block mb-1"> Biography </label>
                  <textarea className="input-style" {...register('biography')} rows={3}></textarea>
                </div>

                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                  <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                    Save
                  </button>
                  <button
                    onClick={handleCloseClick}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpeaker;
