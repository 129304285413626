import React, { useEffect, useState } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm, Controller } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from 'src/components/ToastContainer';
import { Link } from 'react-router-dom';
import { successMsg } from 'src/components/utilities/utils';
import { RootState } from 'src/store/reducers/state';
import { errorMsg } from 'src/components/utilities/utils';
import FormSelector from 'src/components/common/formSelector';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
const Saml = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const [iPCertificate, setIPCertificate] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [isCertificateUploadLoading, setIsCertificateUploadLoading] = useState<boolean>();
  const [isCertificateUploadSuccess, setIsCertificateUploadSuccess] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const { isSamlSaved, saml, message, error, isSamlLoading } = useSelector((state: RootState) => {
    return {
      isSamlSaved: state.setting.isSamlSaved,
      saml: state.setting.saml,
      message: state.setting.message,
      error: state.setting.error,
      isSamlLoading: state.setting.isSamlLoading
    };
  });

  const handleMemberProfileUpload = async (file: any) => {
    setIsCertificateUploadSuccess('');
    uploadImageToS3(
      file[0],
      `v2/settings/integrations/SAML/IdPCertificate?`,
      setIPCertificate,
      'setting',
      setIsCertificateUploadLoading,
      setIsCertificateUploadSuccess
    );
    setSelectedFile(file[0].name);
  };

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'serviceProviderHostname', value: data.serviceProviderHostname },
      { op: 'replace', path: 'identityProviderHostname', value: data.identityProviderHostname },
      { op: 'replace', path: 'ssoServiceUrl', value: data.ssoServiceUrl },
      { op: 'replace', path: 'loginAnchorText', value: data.loginAnchorText },
      { op: 'replace', path: 'debugMode', value: data.debugMode },
      { op: 'replace', path: 'debugEmailRecipients', value: data.debugEmailRecipients },
      { op: 'replace', path: 'firstNameMapping', value: data.firstNameMapping },
      { op: 'replace', path: 'lastNameMapping', value: data.lastNameMapping },
      { op: 'replace', path: 'emailMapping', value: data.emailMapping },
      { op: 'replace', path: 'agreePage', value: data.agreePage },
      { op: 'replace', path: 'addMemberToMemberList', value: data.addMemberToMemberList },
      { op: 'replace', path: 'memberTypeMapping', value: data.memberTypeMapping },
      { op: 'replace', path: 'subscribertypemapping', value: data.subscriperTypeMapping },
      { op: 'replace', path: 'memberListNames', value: data.memberListNames },
      { op: 'replace', path: 'idpcertificate', value: iPCertificate }
    ];

    settingAction.saveSamlRequest(payloadData);
  };

  useEffect(() => {
    if (saml) {
      reset(saml);
      setSelectedFile(saml.identityProviderCertificateFile);
      setIPCertificate(saml.identityProviderCertificateFile);
    }
  }, [saml]);

  useEffect(() => {
    if (isSamlSaved) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isSamlSaved]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  useEffect(() => {
    settingAction.getSamlRequest();
  }, []);

  useEffect(() => {
    if (isCertificateUploadSuccess === 'fail') {
      errorMsg('File has not been uploaded successfully');
    }
  }, [isCertificateUploadSuccess]);

  useEffect(() => {
    if (isCertificateUploadSuccess === 'success') {
      successMsg('File uploaded successfully');
    }
  }, [isCertificateUploadSuccess]);
  return (
    <div>
      <ToastMessage />
      <div className="mb-8">
        <h2 className="font-semibold my-1">SAML</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/saml">Settings</Link>
          </li>
          <li className="active">
            <strong>SAML</strong>
          </li>
        </ol>
      </div>
      <div>
        <div className="add-video min-h-96 w-full shadow">
          {(isSamlLoading || isCertificateUploadLoading) && <Loader />}
          <div className="video-upload p-4 ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Service Provider Hostname'}
                  type={'text'}
                  fieldName={'serviceProviderHostname'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
                {/* <FormSelector
                  label={'Service Provider Hostname'}
                  fieldName={'serviceProviderHostname'}
                  register={register}
                  errors={errors}
                  isRequired={true}
                  data={[
                    {
                      id: 'https://wwww.play.webcastclouddev.com',
                      name: 'play.webcastclouddev.com'
                    },
                    {
                      id: 'https://wwww.app.webcastclouddev.com',
                      name: 'app.webcastclouddev.com'
                    },
                    {
                      id: 'https://wwww.appdev.webcastclouddev.com',
                      name: 'appdev.webcastclouddev.com'
                    },
                    {
                      id: 'https://wwww.amit.webcastclouddev.com',
                      name: 'amit.webcastclouddev.com'
                    }
                  ]}
                  options={'name'}
                /> */}
              </div>

              <div className="form-group mb-4">
                <FormInputField
                  label={'Identity Provider Hostname'}
                  type={'text'}
                  fieldName={'identityProviderHostname'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <label className="block text-sm font-medium mb-1">
                  Identity Provider Certifcate File
                </label>
                <div className="input-style p-0 speaker-browse-file flex items-center">
                  <input
                    id="uploadFile"
                    className="pl-2 w-full outline-none"
                    placeholder={`${selectedFile ? selectedFile : ''}`}
                  />
                  <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                    <span>Select File</span>
                    <input
                      id="uploadBtn"
                      type="file"
                      className="upload"
                      onChange={(e) => handleMemberProfileUpload(e.target.files)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'SSO Service URL'}
                  type={'text'}
                  fieldName={'ssoServiceUrl'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Login Button Text'}
                  type={'text'}
                  fieldName={'loginAnchorText'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="agreePage"
                    {...register('agreePage')}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexCheckDefault">
                    Agree Page
                  </label>
                </div>
              </div>
              <h4 className="text-lg">Member Attribute Mappings</h4>
              <div className="form-group mb-4 mt-1">
                <FormInputField
                  label={'First Name Mapping'}
                  type={'text'}
                  fieldName={'firstNameMapping'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Last Name Mapping'}
                  type={'text'}
                  fieldName={'lastNameMapping'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Email Name Mapping'}
                  type={'text'}
                  fieldName={'emailMapping'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>

              <div className="form-group mb-4">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="addMemberToMemberList"
                    {...register('addMemberToMemberList')}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexCheckDefault">
                    AddMemberTo Member List
                  </label>
                </div>
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Member Type Mapping'}
                  type={'text'}
                  fieldName={'memberTypeMapping'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Subscriber Type Mapping'}
                  type={'text'}
                  fieldName={'subscriperTypeMapping'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Member List Names'}
                  type={'text'}
                  fieldName={'memberListNames'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <h4 className="text-lg">Debugging</h4>
              <div className="form-group mb-4 mt-1">
                <div className="form-check mb-2">
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id="debugMode"
                    {...register('debugMode')}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor="flexCheckDefault">
                    Debug Mode
                  </label>
                </div>
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Debug Email Recipients'}
                  type={'text'}
                  fieldName={'debugEmailRecipients'}
                  register={register}
                  errors={errors}
                  isRequired={false}
                />
              </div>
              <div className="flex items-center mt-6">
                <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                  Save
                </button>
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto mt-0"
                  data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saml;
