import moment from "moment";
import { ChannelModel } from "src/models/videosModel";
import { toast } from "react-toastify";
import axios from "axios";
export function addZeroes(num: number): string {
    return num.toFixed(Math.max(((num + '').split(".")[1] || "").length, 2));
}

export const toastOptions: any = {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
}

export const allMonths = (): string[] => {
    return moment.months()
};

export const getYears = (): number[] => {
    const minOffset = 0;
    const maxOffset = 60;
    const thisYear = new Date().getFullYear();
    const options = [];

    for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        options.push(year);
    }
    return options;
};
export const numberWithCommas = (x: number) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const wordify = (num: any): string => {
    const single = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const double = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const formatTenth = (digit: any, prev: any) => {
        return 0 == digit ? "" : " " + (1 == digit ? double[prev] : tens[digit])
    };
    const formatOther = (digit: any, next: any, denom: any) => {
        return (0 != digit && 1 != next ? " " + single[digit] : "") + (0 != next || digit > 0 ? " " + denom : "")
    };
    let res = "";
    let index = 0;
    let digit = 0;
    let next = 0;
    const words = [];
    if (num += "", isNaN(parseInt(num))) {
        res = "";
    }
    else if (parseInt(num) > 0 && num.length <= 10) {
        for (index = num.length - 1; index >= 0; index--) switch (digit = num[index] - 0, next = index > 0 ? num[index - 1] - 0 : 0, num.length - index - 1) {
            case 0:
                words.push(formatOther(digit, next, ""));
                break;
            case 1:
                words.push(formatTenth(digit, num[index + 1]));
                break;
            case 2:
                words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] && 0 != num[index + 2] ? " and" : "") : "");
                break;
            case 3:
                words.push(formatOther(digit, next, "Thousand"));
                break;
            case 4:
                words.push(formatTenth(digit, num[index + 1]));
                break;
            case 5:
                words.push(formatOther(digit, next, "Lakh"));
                break;
            case 6:
                words.push(formatTenth(digit, num[index + 1]));
                break;
            case 7:
                words.push(formatOther(digit, next, "Crore"));
                break;
            case 8:
                words.push(formatTenth(digit, num[index + 1]));
                break;
            case 9:
                words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] || 0 != num[index + 2] ? " and" : " Crore") : "")
        }
        res = words.reverse().join("")
    } else res = "";
    return res
};

export const setItem = (itemname: string, item: any): any => {
    localStorage.setItem(itemname, item);
}

export const getItem = (itemname: any): any => {
    return localStorage.getItem(itemname)
}

export const removeItem = (itemname: string): any => {
    localStorage.removeItem(itemname);
}

export const successMsg = (message: string) => {
    toast.success(message, toastOptions)
}
export const errorMsg = (message: string) => {
    toast.error(message, toastOptions)
}
export const warningMsg = (message: string) => {
    toast.warning(message, toastOptions)
}
export const infoMsg = (message: string) => {
    toast.info(message, toastOptions)
}

export const getFormattedDate = (date: string | number): string => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString('en-GB')
}

export const getInputDateFormat = (date: string): string => {
    const formatted_date = moment(getFormattedDate(date), 'DD/MM/YYYY').format('YYYY-MM-DD');
    return formatted_date
}

export const getDay = (date: string | number): string => {
    const mydate = date;
    const weekDayName = moment(mydate).format('dddd');
    return weekDayName;
}

export const changeDateFormat = (date: string) => {
    const newDate = new Date(date);
    const formattedDate: any = newDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        })
        .replace(/ /g, '-');
    return formattedDate;
};
export const changeDateFormatter = (date: Date) => {
    const newDate = new Date(date);
    const formattedDate: any = newDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        .replace(/ /g, '-');
    return formattedDate;
}
export const changeDateFormatter_US = (date: Date) => {
    const newDate = new Date(date);
    const formattedDate: any = newDate
        .toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        })
        .replace(/ /g, '-');
    return formattedDate;
}
export const defaultDateFormatter = (date: any) => {
    const defaultDateFormat = localStorage.getItem('defaultDateFormat');
    let finalDate = "";
    if (defaultDateFormat == "dd/MM/yyyy")
        finalDate = changeDateFormatter(date);
    else if (defaultDateFormat == "MM/dd/yyyy")
        finalDate = changeDateFormatter_US(date);
    else if (defaultDateFormat == "dddd, dd MMMM yyyy")
        finalDate = moment(date).format("dddd, DD MMMM yyyy");
    return finalDate;
}
export const changeDateFormatterForVideoList = (date: Date) => {
    const newDate = new Date(date);
    const formattedDate: any = newDate
        .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        .replace(/ /g, '-');

    const formattedTime: any = newDate
        .toLocaleTimeString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

    return `${formattedDate} ${formattedTime}`;
}
export const changeDateFormatterForVideoList_US = (date: Date) => {
    const newDate = new Date(date);
    const formattedDate: any = newDate
        .toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        .replace(/ /g, '-');

    const formattedTime: any = newDate
        .toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

    return `${formattedDate} ${formattedTime}`;
}
export const defaultDateFormatterForVideoList = (date: any) => {
    const defaultDateFormat = localStorage.getItem('defaultDateFormat');
    let finalDate = "";
    if (defaultDateFormat == "dd/MM/yyyy")
        finalDate = changeDateFormatterForVideoList(date);
    else if (defaultDateFormat == "MM/dd/yyyy")
        finalDate = changeDateFormatterForVideoList_US(date);
    else if (defaultDateFormat == "dddd, dd MMMM yyyy")
        finalDate = moment(date).format("dddd, DD MMMM yyyy");
    return finalDate;
}


export const logOut = (): void => {
    localStorage.clear()
    window.open('/login', '_self')
}
export const deleteCookies = (): void => {
    const allCookies = document.cookie.split(';');

    for (let i = 0; i < allCookies.length; i++)
        document.cookie = allCookies[i] + "=;expires="
            + new Date(0).toUTCString();

}

export const ImageStringConverter = (originalString: any) => {
    // Return the converted string wrapped in <div class='w-100 ck ck-content'>
    return `<div class='w-100 ck ck-content'>${originalString}</div>`;
};
export const getrandomcolor = (length: number) => {
    const letters = '0123456789ABCDEF';
    const colors = [];
    for (let i = 0; i < length; i++) {
        let color = '#';
        for (let j = 0; j < 6; j++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        colors.push(color);
    }
    return colors;
};
export const getColor = (index: number) => {
    const colors = ['#008000', '#FF0000', '#0000FF', '#FFFF00', '#800080', '#ADD8E6', '#FFA500', '#006400', '#1A75BC'];
    if (index >= colors.length) {
        return '#1A75BC'
    } else {
        return colors[index];
    }
};
export const SubtractSeconds = (time: string): string => {
    if (time > '00:00:10') {
        return moment(time, 'HH:mm:ss').subtract(10, 'seconds').format('HH:mm:ss')
    } else if (time < '00:00:10') {
        return time
    } else {
        return '00:00:00'
    }
};

export const DateToHourMinutes = (time: string): string => {
    return moment(time).format('hh:mm A');
};
export const secondsToMinutes = (time: string): string => {
    return moment(time).format('mm');
}
export const getDayName = (time: string): string => {
    return moment(time).format('ddd');
};
export function miliSecondsToHourMinute(miliseconds: any) {
    const duration = moment.duration(miliseconds);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    const addLeadingZero = (num: any) => (num < 10 ? `0${num}` : num);

    const formattedHours = addLeadingZero(hours);
    const formattedMinutes = addLeadingZero(minutes);

    // Combine hours and minutes in "hh:mm" format
    return `${formattedHours}:${formattedMinutes}`;
}

export const getDateAndTime = (originalDateString: string) => {
    if (originalDateString) {
        const date = new Date(originalDateString);
        const addLeadingZero = (num: any) => (num < 10 ? `0${num}` : num);

        const day = addLeadingZero(date.getDate());
        const month = addLeadingZero(date.getMonth() + 1);
        const year = date.getFullYear();

        const hours = addLeadingZero(date.getHours());
        const minutes = addLeadingZero(date.getMinutes());

        // Format the date and time strings
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hours}:${minutes}`;
        return { formattedDate, formattedTime };
    }
};

export const getDate = (time: string): string => {
    return moment(time).format('DD');
};
export const convertSecondToHourMinuteSecond = (seconds: number) => {
    //  return new Date(seconds * 1000).toISOString().substring(11, 19) // commented as it's not handle when pass more than 86400 seconds(24 hours)
    return [(seconds / 60 / 60) | 0, (seconds / 60 % 60) | 0, (seconds % 60) | 0].join(':').replace(/\b(\d)\b/g, '0$1');
}

export const convertHourMinuteSecondToSecond = (string: any) => {
    const [hours, minutes, seconds] = string.split(':');
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
}

export const currentDate = (): string => {
    return moment().format("DD-MM-YYYY");
}
export const previousYearDate = (): string => {
    return moment().subtract(1, 'years').format("DD-MM-YYYY");
}
export const convertHourMinuteToSecond = (hours: number, minutes: number) => {
    const seconds = hours * 3600 + minutes * 60;
    return seconds * 1000
}
export const secondsToTime = (seconds: number): number => {
    return new Date(seconds * 1000).getSeconds();
}
export const minutesToHourMinutes = (numberOfMinutes: number) => {
    const hours = Math.floor(numberOfMinutes / 60);
    const minutes = numberOfMinutes % 60;
    return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;

}
export const formatDate = (data: string): string => {
    return moment(data).format('DD/MM/YYYY hh:mm:ss A');
};
export const padToTwoDigits = (num: number) => {
    return num.toString().padStart(2, '0');
}

export const formatTimeHHMMSS = (time: number): string => {
    return Math.floor(moment.duration(time * 1000).asHours()) + moment.utc(time * 1000).format(":mm:ss");
};

export const uploadImageToS3WithReturnData = async (file: any, url: any, setGetUploadUrl?: any) => {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    const axiosInstance = axios.create();
    try {
        const startUpload = await axiosInstance.get(
            `${rootUrl}/${url}&fileName=${file.name}&fileType=${file.type}`,

            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        const preSignedUrl = startUpload.data.result.uploadUrl;
        setGetUploadUrl(startUpload.data.result.imageUrl)
        const config: any = {
            method: 'put',
            url: preSignedUrl,
            headers: {
                'Content-Type': `${file.type}`
            },
            data: file
        };
        await axiosInstance(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    } catch (error) {
        console.log(error);
    }
}
export const uploadPartUrl = async (rootUrl: any, videoId: string, title: string, videoName: string, channelId: string, uploadedFile: any, captions: boolean, setUploadPercentage: any, uploadVideoRequest: any, isCancelUpload: boolean, secondary: boolean) => {

    try {
        const startUpload = await axios.post(
            `${rootUrl}/v2/video/startupload`,
            {
                videoId,
                fileName: videoName,
                fileType: uploadedFile.type,
                fileNameIsUnique: false,
                title,
                channelId,
                secondary
            },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        if (startUpload.data.result.uploadId) {
            setUploadPercentage(4)
        }
        uploadVideoRequest({ uploadId: startUpload.data.result.uploadId, fileName: startUpload.data.result.fileName })
        const uploadId = startUpload.data.result.uploadId;
        const uploadFileName = startUpload.data.result.fileName;
        const chunkSize = 10 * 1024 * 1024; //10mib
        const chunkCount = Math.floor(uploadedFile.size / chunkSize) + 1;
        const multiUploadArray = [];
        const uploadFilePercent = 85 / chunkCount
        for (let uploadCount = 1; uploadCount < chunkCount + 1; uploadCount++) {
            const start = (uploadCount - 1) * chunkSize;
            const end = uploadCount * chunkSize;
            const fileBlob =
                uploadCount < chunkCount ? uploadedFile.slice(start, end) : uploadedFile.slice(start);

            const getSignedUrlRes = await axios.get(
                `${rootUrl}/v2/video/uploadparturl?UploadId=${uploadId}&FileName=${uploadFileName}&PartNumber=${uploadCount}&Secondary=${secondary}`,
                { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
            );

            const preSignedUrl = getSignedUrlRes.data.result;
            const uploadChunk = await fetch(preSignedUrl, {
                method: 'PUT',
                body: fileBlob
            });

            const EtagHeader = uploadChunk.headers.get('ETag');
            if (EtagHeader) {
                setUploadPercentage((x: number) => x + uploadFilePercent)
            }
            const uploadPartDetails = {
                Etag: EtagHeader,
                PartNumber: uploadCount
            };
            multiUploadArray.push(uploadPartDetails);
        }
        const completeUpload = await axios.post(
            `${rootUrl}/v2/video/completeupload`,
            {
                videoId,
                fileName: uploadFileName,
                uploadId: uploadId,
                uploadedParts: multiUploadArray,
                secondary,
                captions
            },
            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        if (completeUpload.data.statusCode === 200) {
            toast('Video Uploaded Successfully');
            setUploadPercentage(100)
        }
    } catch (error) {
        const cancelFlag = sessionStorage.getItem('cancel')
        console.log(error)
        if (cancelFlag !== 'true') {
            toast('Please try again');
        }
        else {
            sessionStorage.removeItem('cancel')
        }
    }
}

export const uploadImageToS3 = async (file: any, url: any, setGetUploadUrl?: any, from?: string, setLoading?: any, setUploadSuccess?: any, uploadImageToApi?: any) => {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    const axiosInstance = axios.create();
    try {
        const lastIndex = file.name.lastIndexOf('.');
        const fileExtension = file.name.substring(lastIndex);
        const fileType = fileExtension === ".vtt" ? "text/vtt" : file.type;
        setLoading && setLoading(true)
        const startUpload = await axiosInstance.get(
            `${rootUrl}/${url}fileName=${file.name}&fileType=${fileType}`,

            { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );
        const preSignedUrl = startUpload.data.result.signedUrl;
        if (from === 'resource') {
            setGetUploadUrl(startUpload.data.result.resourceUrl)
        } else if (from === 'member') {
            setGetUploadUrl(startUpload.data.result.profileUrl)
        } else if (from === 'onDemandCaption') {
            setGetUploadUrl(startUpload.data.result.onDemandCaptionImageUrl)
        } else if (from === 'chapterUrl') {
            setGetUploadUrl(startUpload.data.result.chapterUrl)
        } else if (from === 'channelImagesUrl') {
            setGetUploadUrl(startUpload.data.result.channelImagesUrl)
        } else if (from === 'setting') {
            setGetUploadUrl(startUpload.data.result.resourceUrl)
        } else if (from === 'uploadSlide') {
            setGetUploadUrl(startUpload.data.result.cdnUrl)
        } else if (from === 'customize') {
            setGetUploadUrl(startUpload.data.result.resourceUrl)
        }
        else {
            setGetUploadUrl(startUpload.data.result.cdnUrl)
        }
        const config: any = {
            method: 'put',
            url: preSignedUrl,
            headers: {
                'Content-Type': `${fileType}`
            },
            data: file
        };
        await axiosInstance(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setLoading && setLoading(false)
                setUploadSuccess && setUploadSuccess('success')
                if (from === 'channelImagesUrl' || from === 'uploadSlide') {
                    uploadImageToApi && uploadImageToApi(startUpload.data.result.channelImagesUrl)
                } else if (from === 'customize') {
                    uploadImageToApi && uploadImageToApi(startUpload.data.result.resourceUrl)
                }
            })
            .catch(function (error) {
                console.log(error);
                setUploadSuccess && setUploadSuccess('fail')
                setLoading && setLoading(false)
            });
    } catch (error) {
        console.log(error);
        setUploadSuccess && setUploadSuccess('fail')
        setLoading && setLoading(false)
    }
}

export const postImageToS3 = async (file: any, url: any, setIsFileUploaded: any, setGetUploadUrl?: any) => {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    try {
        const configs: any = {
            method: 'post',
            url: `${rootUrl}/${url}&fileName=${file.name}&fileType=${file.type}`,
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        };

        const startUpload = await axios(configs);
        const preSignedUrl = startUpload.data.result;
        setGetUploadUrl(preSignedUrl)
        const config: any = {
            method: 'put',
            url: preSignedUrl,
            headers: {
                'Content-Type': `${file.type}`
            },
            data: file
        };
        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setIsFileUploaded(true)
            })
            .catch(function (error) {
                console.log(error);
                setIsFileUploaded(false)
            });
    } catch (error) {
        console.log(error);
        setIsFileUploaded(false)
    }
}

export const customFilterOption = (option: any, rawInput: any) => {
    // Convert both the label and input value to lowercase for case-insensitive matching
    const inputValue = rawInput?.toLowerCase();
    const label = option?.label?.toLowerCase();

    // Check if the label contains the input value
    return label?.includes(inputValue);
};


export const getVideoStatus = (status: number) => {
    if (status === 0) {
        return 'Standby'
    } else if (status === 1) {
        return 'Live'
    } else if (status === 2) {
        return 'OnDemand'
    } else if (status === 3) {
        return 'Stopped'
    } else if (status === 4) {
        return 'Processing'
    } else if (status === 5) {
        return 'Offline'
    } else if (status === 6) {
        return 'GreenRoom'
    }
}
export const getVideoSource = (status: number) => {
    if (status === 1) {
        return 'Webcam'
    } else if (status === 2) {
        return 'StreamingSoftware'
    }
}
export const compareDate = (currentDate: any, oldDate: any) => {
    if (currentDate.getTime() < oldDate.getTime())
        return true
    else if (currentDate.getTime() > oldDate.getTime())
        return false;
}

export const getCookieData: any = (cookieName: string) => {
    return document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split("=")[1];
};

export const nameOnlyPattern = /^[A-Za-z]/
export const emailOnlyPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const phoneNumberOnlyPattern = /^([+]\d{2}[ ])?\d{10}$/
export const numberOnlyPattern = /^[0-9]+$/
export function msToTime(duration: any) {

    let seconds: any = Math.floor((duration / 1000) % 60);
    let minutes: any = Math.floor((duration / (1000 * 60)) % 60);
    let hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}
export const convertToSingleLevel = (data: any) => {
    const stack = data.map((item: ChannelModel) => ({ ...item }));
    const result = [];

    while (stack.length) {
        const item = stack.pop();

        const { children, ...restItem } = item;

        stack.push(
            ...item.children.map((child: ChannelModel) => ({
                ...child
            }))
        );

        result.push(restItem);
    }
    const finalData = result.map((item) => {
        return {
            id: item.id,
            title: item.title,
            order: item.order,
            level: item.level,
            parentid: item.parentid
        };
    });
    return finalData;
};
export function rename(obj: any) {
    for (const prop in obj) {
        if (Array.isArray(obj[prop])) {
            obj[prop] = obj[prop].map(rename);
        }
        if (prop === 'name') {
            obj.title = !obj.name ? '' : obj[prop]
            delete obj[prop]
        }
        if (prop === 'subChannels') {
            obj.title = obj[prop]
            obj.children = !obj.subChannels ? [] : obj[prop];
            delete obj[prop];
        }
    }

    return obj;
}
export function getTransactionStatus(Status: string) {
    if (Status === 'Purchased') {
        return 'bg-green-100 text-green-500 font-semibold'
    } else if (Status === 'Open') {
        return 'bg-gray-100 text-gray-800 font-semibold'
    } else if (Status === 'Refunded') {
        return 'bg-red-100 text-red-500 font-semibold'
    } else if (Status === 'Refund') {
        return 'standbyBackground standbyTextColor font-semibold'
    }
}
export enum videoStatusEnum {
    Standby,
    Live,
    OnDemand,
    Stopped,
    Processing,
    Offline,
    GreenRoom
}
export enum PollTypeEnum {
    Quiz,
    MultipleChoice,
    OpenText,
    StarRating,
    Survey
}
export enum CollectionVisibilityEnum {
    Public,
    Offline
}
export enum CollectionAccessibilityEnum {
    Free,
    Paid,
    Locked
}
export function getPollTypeName(pollStatus: number) {
    if (pollStatus === PollTypeEnum.Quiz) {
        return 'Quiz'
    } else if (pollStatus === PollTypeEnum.MultipleChoice) {
        return 'Multiple Choice'
    } else if (pollStatus === PollTypeEnum.StarRating) {
        return 'Star Rating'
    } else if (pollStatus === PollTypeEnum.OpenText) {
        return 'OpenText'
    } else if (pollStatus === PollTypeEnum.Survey) {
        return 'Survey'
    }
}
export function getBadge(videoStatus: number) {
    if (videoStatus === videoStatusEnum.Standby) {
        return 'Standby'
    } else if (videoStatus === videoStatusEnum.Live) {
        return 'Live'
    } else if (videoStatus === videoStatusEnum.OnDemand) {
        return 'OnDemand'
    } else if (videoStatus === videoStatusEnum.Stopped) {
        return 'Stopped'
    } else if (videoStatus === videoStatusEnum.Processing) {
        return 'Processing'
    } else if (videoStatus === videoStatusEnum.Offline) {
        return 'Offline'
    } else if (videoStatus === videoStatusEnum.GreenRoom) {
        return 'GreenRoom'
    }
}
export function getBadgeBackgroundColor(videoStatus: number) {
    if (videoStatus === videoStatusEnum.Standby) {
        return 'standbyBackground standbyTextColor font-semibold'
    } else if (videoStatus === videoStatusEnum.Live) {
        return 'bg-red-100 text-red-500 font-semibold'
    } else if (videoStatus === videoStatusEnum.OnDemand) {
        return 'bg-green-100 text-green-500 font-semibold'
    } else if (videoStatus === videoStatusEnum.Stopped) {
        return 'bg-yellow-100 text-yellow-400 font-semibold'
    } else if (videoStatus === videoStatusEnum.Processing) {
        return 'bg-gray-100 text-gray-800 font-semibold'
    } else if (videoStatus === videoStatusEnum.Offline) {
        return 'bg-gray-100 text-gray-800 font-semibold'
    } else if (videoStatus === videoStatusEnum.GreenRoom) {
        return 'bg-gray-100 text-gray-800 font-semibold'
    }
}
