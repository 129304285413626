import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginActions } from 'src/store/login/actions';
import { useNavigate } from 'react-router-dom';
import { getItem } from 'src/components/utilities/utils';
import { deleteCookies } from 'src/components/utilities/utils';
import { UserInfoModel } from 'src/models/usersModel';
import { useUserActions } from 'src/store/users/actions';
import { RootState } from 'src/store/reducers/state';
interface IProps {
  handleMobileMenu: () => void;
}

const Header: React.FC<IProps> = ({ handleMobileMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<UserInfoModel | null>(null);
  const loginAction = useLoginActions(dispatch);
  const userAction = useUserActions(dispatch);
  const { defaultDateFormat } = useSelector((state: RootState) => {
    return {
      defaultDateFormat: state.login.defaultDateFormat
    };
  });

  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
  }, []);
  const logoutClick = () => {
    loginAction.sendLogoutRequest();
    deleteCookies();
    navigate('/login');
    userAction.resetMFAStateRequest();
  };

  useEffect(() => {
    loginAction.getDefaultdateFormatRequest();
  }, []);

  return (
    <>
      <div className="inset-0 fixed block w-full customshadow"></div>
      <header
        className={`shadow px-4 py-3 rounded ${
          defaultDateFormat.accountOverdue ? 'flex justify-between' : ''
        }`}>
        {defaultDateFormat.accountOverdue && (
          <div className="bg-red-100 text-red-500 p-4 rounded-md shadow-md">
            Action Required: Your account is overdue. Immediate action is needed to avoid platform
            restrictions. Contact Webcastcloud Accounts to resolve.
          </div>
        )}

        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="../../assets/img/bars.svg"
              className="menu-icon w-9 hidden cursor-pointer menu-bars"
              onClick={handleMobileMenu}
            />
          </div>
          <div className="flex-items">
            {/* <img src="../../assets/img/mode-change.svg" className="menu-icon w-9 cursor-pointer" />
            <img src="../../assets/img/search.svg" className="menu-icon w-9 cursor-pointer" />
            <div className="relative">
              <span className="bg-red-500 notification">
                <small>1</small>
              </span>
              <img src="../../assets/img/bell.svg" className="menu-icon w-9" />
            </div> */}
            {userDetails && (
              <div className="dropdown relative profile-dropdown">
                <div
                  className="flex-items ml-3 cursor-pointer current-usr"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <div className="grid leading-tight">
                    <span className="font-semibold leading-none">
                      {userDetails.firstName + ' ' + userDetails.lastName}
                    </span>
                    <span className="text-right">
                      <small>{userDetails && userDetails.role}</small>
                    </span>
                  </div>
                  <div className="relative">
                    <img
                      src={`${
                        userDetails.profilePicUrl
                          ? userDetails.profilePicUrl
                          : '../../assets/img/user-png.png'
                      }`}
                      className="rounded-full w-10 h-10 object-cover ml-2"
                    />
                    <span className="h-3 w-3 rounded-full bg-green-500 absolute border-2 border-solid border-white active-usr"></span>
                  </div>
                </div>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-full"
                  aria-labelledby="action-dropdown">
                  <li className="cursor-pointer">
                    <span className="action-menu text-center px-3" onClick={() => logoutClick()}>
                      <span className="flex items-center justify-center">
                        <img src="../../assets/img/logout.svg" className="w-4 h-4 mr-2" />
                        Logout
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
