import React, { useEffect, useState, useRef } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import FormInputField from 'src/components/common/formInputField';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const ResourceModal = ({ videoId, selectedClickFlag, resourceId }: any) => {
  const [selectedFile, setSelectedFile] = useState<string>();
  const [option, setOption] = useState('U');
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [isSavedClicked, setIsSavedClicked] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isResourceUploadLoading, setIsResourceUploadLoading] = useState<boolean>();
  const [isResourceUploadSuccess, setIsResourceUploadSuccess] = useState<string>('');
  const btnRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();
  const {
    resources,
    singleResource,
    isResourceAdded,
    attachmentId,
    isResourceUpdated,
    isSavedLoading
  } = useSelector((state: RootState) => {
    return {
      resources: state.videos.resources,
      singleResource: state.videos.singleResource,
      isResourceAdded: state.videos.isResourceAdded,
      attachmentId: state.videos.attachmentId,
      isResourceUpdated: state.videos.isResourceUpdated,
      isSavedLoading: state.videos.isSavedLoading
    };
  });
  useEffect(() => {
    if (singleResource && selectedClickFlag?.flag === 'edit') {
      reset(singleResource);
      setOption(singleResource.uploadType);
      if (singleResource.uploadType === 'F') {
        setSelectedFile(singleResource.linkUrl.split('resources/')[1]);
        setBackgroundImageUrl(singleResource.linkUrl);
      }
    }
  }, [singleResource]);

  useEffect(() => {
    if (selectedClickFlag?.flag === 'edit') {
      videoLibraryAction.getSingleResourcesRequest(selectedClickFlag.resourceId);
    } else if (selectedClickFlag?.flag === 'add') {
      videoLibraryAction.resetAttachmentIdRequest();
    }
  }, [selectedClickFlag]);
  const onSubmit = (data: any) => {
    setIsSavedClicked(true);
    const finalData = {
      videoId: videoId,
      ...data,
      attachmentIconId: data.attachmentIconId && parseInt(data.attachmentIconId)
    };
    if (selectedClickFlag?.flag === 'edit') {
      const payloadData = {
        id: data.id,
        updateData: [
          { op: 'replace', path: 'attachmentName', value: data.attachmentName },
          { op: 'replace', path: 'description', value: data.description },
          {
            op: 'replace',
            path: 'linkUrl',
            value: option === 'U' ? data.linkUrl : backgroundImageUrl
          },
          { op: 'replace', path: 'uploadType', value: option.toString() },
          {
            op: 'replace',
            path: 'attachmentIconId',
            value: data.attachmentIconId && parseInt(data.attachmentIconId)
          }
        ]
      };
      videoLibraryAction.editResourcesRequest(payloadData);
    } else {
      videoLibraryAction.addResourcesRequest(finalData);
    }
  };

  const handleResetFields = () => {
    reset({
      attachmentName: '',
      linkUrl: '',
      uploadType: '',
      description: '',
      attachmentIconId: 1
    });
    setOption('U');
    setSelectedFile('');
    setIsSavedClicked(false);
    setIsResourceUploadSuccess('');
  };

  useEffect(() => {
    if (isResourceAdded) {
      handleResetFields();
    }
  }, [isResourceAdded]);

  useEffect(() => {
    if (isResourceUpdated) {
      if (btnRef.current) {
        btnRef.current.click();
      }
      handleResetFields();
      setLinkUrl('');
      setOption('U');
      setSelectedFile('');
      setBackgroundImageUrl('');
      setIsSavedClicked(false);
    }
  }, [isResourceUpdated]);

  const uploadUserProfile = async (file: any) => {
    uploadImageToS3(
      file[0],
      `v2/video/upload/resource/${attachmentId}?`,
      setBackgroundImageUrl,
      'resource',
      setIsResourceUploadLoading,
      setIsResourceUploadSuccess
    );
    setSelectedFile(file[0].name);
  };

  const updateUploadResource = async (file: any) => {
    if (singleResource) {
      uploadImageToS3(
        file[0],
        `v2/video/upload/resource/${singleResource.id}?`,
        setBackgroundImageUrl,
        'resource',
        setIsResourceUploadLoading,
        setIsResourceUploadSuccess
      );
    }
    setSelectedFile(file[0].name);
  };

  const handleUpdateResourceClick = () => {
    const payloadData = {
      id: attachmentId,
      updateData: [
        { op: 'replace', path: 'linkUrl', value: option === 'U' ? linkUrl : backgroundImageUrl },
        { op: 'replace', path: 'uploadType', value: option.toString() }
      ]
    };
    videoLibraryAction.editResourcesRequest(payloadData);
  };

  useEffect(() => {
    if (isResourceUpdated) {
      videoLibraryAction.resetAttachmentIdRequest();
    }
  }, [isResourceUpdated]);

  useEffect(() => {
    if (isResourceUploadSuccess === 'fail') {
      errorMsg('File has not been uploaded successfully');
    }
  }, [isResourceUploadSuccess]);

  useEffect(() => {
    if (isResourceUploadSuccess === 'success') {
      successMsg('File uploaded successfully');
    }
  }, [isResourceUploadSuccess]);

  return (
    <div className="modal fade" id="AddNewResource" aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
            <h5 className="text-xl font-semibold leading-normal text-center w-full">
              {selectedClickFlag?.flag === 'edit' ? 'Edit' : 'Add'} Resource
            </h5>
            <button
              ref={btnRef}
              onClick={handleResetFields}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            {attachmentId !== '' && selectedClickFlag?.flag !== 'edit' ? (
              <>
                <div className="form-group mb-3">
                  <div className="flex items-center">
                    <div className="flex items-center mr-3">
                      <div className="form-check mr-20">
                        <input
                          className="form-check-input radio-btn"
                          type="radio"
                          name="uploadType"
                          id="uploadType"
                          onChange={() => setOption('U')}
                          checked={option === 'U' ? true : false}
                        />
                        <label className="w-16" htmlFor="uploadType">
                          Link Url
                        </label>
                      </div>
                    </div>

                    <div className="flex items-center mr-3">
                      <div className="form-check">
                        <input
                          className="form-check-input radio-btn"
                          type="radio"
                          name="uploadType"
                          id="uploadType"
                          onChange={() => setOption('F')}
                          checked={option === 'F' ? true : false}
                        />
                        <label className="w-16" htmlFor="uploadType">
                          Upload File
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {option === 'U' && (
                  <div className={`form-group mb-3`}>
                    <input
                      type="text"
                      className="input-style"
                      onChange={(e) => setLinkUrl(e.target.value)}
                    />
                  </div>
                )}
                {option === 'F' && (
                  <div className={`form-group mb-3`}>
                    <div className="input-style p-0 speaker-browse-file flex items-center">
                      <input
                        id="uploadFile"
                        disabled
                        value={selectedFile}
                        className="pl-2"
                        placeholder="No file chosen"
                      />
                      <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                        <span>Select File</span>
                        <input
                          id="attachmentIcon"
                          type="file"
                          className="upload"
                          onChange={(e) => uploadUserProfile(e.target.files)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                  <button
                    disabled={
                      (option === 'U' && linkUrl === '') ||
                      (option === 'F' && backgroundImageUrl === '')
                        ? true
                        : false
                    }
                    onClick={handleUpdateResourceClick}
                    type="button"
                    className={`px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 ${
                      (option === 'U' && linkUrl === '') ||
                      (option === 'F' && isResourceUploadSuccess === 'fail')
                        ? 'opacity-25'
                        : ''
                    }`}>
                    Save Changes
                  </button>
                  <button
                    onClick={handleResetFields}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                  {isResourceUploadLoading && (
                    <span className="ml-2">
                      <Loader />
                    </span>
                  )}
                </div>
              </>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Resource Name'}
                    type={'text'}
                    fieldName={'attachmentName'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                {selectedClickFlag?.flag === 'edit' && (
                  <>
                    <div className="form-group mb-3">
                      <div className="flex items-center">
                        <div className="flex items-center mr-3">
                          <div className="form-check mr-20">
                            <input
                              className="form-check-input radio-btn"
                              type="radio"
                              name="uploadType"
                              id="uploadType"
                              onChange={() => setOption('U')}
                              checked={option === 'U' ? true : false}
                            />
                            <label className="w-16" htmlFor="uploadType">
                              Link Url
                            </label>
                          </div>
                        </div>

                        <div className="flex items-center mr-3">
                          <div className="form-check">
                            <input
                              className="form-check-input radio-btn"
                              type="radio"
                              name="uploadType"
                              id="uploadType"
                              onChange={() => setOption('F')}
                              checked={option === 'F' ? true : false}
                            />
                            <label className="w-16" htmlFor="uploadType">
                              Upload File
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {option === 'U' && (
                      <div className={`form-group mb-3`}>
                        <FormInputField
                          label={'Link URL'}
                          type={'text'}
                          fieldName={'linkUrl'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                        />
                      </div>
                    )}
                    {option === 'F' && (
                      <div className={`form-group mb-3`}>
                        <div className="input-style p-0 speaker-browse-file flex items-center">
                          <input
                            id="uploadFile"
                            disabled
                            value={selectedFile}
                            className="pl-2"
                            placeholder="No file chosen"
                          />
                          <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                            <span>Select File</span>
                            <input
                              id="attachmentIcon"
                              type="file"
                              className="upload"
                              onChange={(e) => updateUploadResource(e.target.files)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className={`form-group mb-3`}>
                  <label className="mb-1 block text-sm">Resource Type</label>
                  <select className="input-style" {...register('attachmentIconId')}>
                    <option value={1}>PDF</option>
                    <option value={2}>Word</option>
                    <option value={3}>Excel</option>
                    <option value={4}>Image</option>
                    <option value={5}>Preview</option>
                    <option value={6}>Email</option>
                    <option value={7}>Website</option>
                    <option value={8}>Facebook</option>
                    <option value={9}>Twitter</option>
                    <option value={10}>Linkedin</option>
                    <option value={11}>Download</option>
                  </select>
                </div>
                <div className={`form-group mb-3`}>
                  <label className="mb-1 block text-sm">Description</label>
                  <textarea className="input-style" {...register('description')}></textarea>
                </div>
                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0"
                    disabled={isSavedClicked ? true : false}>
                    Save Changes
                  </button>
                  <button
                    onClick={handleResetFields}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                  {isSavedLoading && (
                    <span className="ml-2">
                      <Loader />
                    </span>
                  )}
                  {isResourceUploadLoading && (
                    <span className="ml-2">
                      <Loader />
                    </span>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceModal;
