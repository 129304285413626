import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { errorMsg } from 'src/components/utilities/utils';
const PaymentProvider = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);

  const { paymentProvider, isPaymentProviderLoading, isPaymentProviderUpdated, message, error } =
    useSelector((state: RootState) => {
      return {
        paymentProvider: state.setting.paymentProvider,
        isPaymentProviderLoading: state.setting.isPaymentProviderLoading,
        isPaymentProviderUpdated: state.setting.isPaymentProviderUpdated,
        message: state.setting.message,
        error: state.setting.error
      };
    });

  useEffect(() => {
    settingAction.getPaymentProviderRequest();
  }, []);
  const handleUpdateIntegration = (data: boolean, id: string) => {
    const payloadData = { active: data, id };
    settingAction.enablePaymentProviderRequest(payloadData);
  };
  useEffect(() => {
    if (isPaymentProviderUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
      settingAction.getPaymentProviderRequest();
    }
  }, [isPaymentProviderUpdated]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  const getActiveFlag = (integrations: any) => {
    const flag =
      paymentProvider &&
      paymentProvider.filter((item) => {
        if (item.providerName === integrations) {
          return item;
        }
      });
    return flag[0]?.active;
  };

  const getPaymentId = (integrations: any) => {
    const flag =
      paymentProvider &&
      paymentProvider.filter((item) => {
        if (item.providerName === integrations) {
          return item;
        }
      });
    return flag[0]?.id;
  };
  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payment Providers</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Settings</Link>
          </li>
          <li>
            <strong>Payment Providers</strong>
          </li>
        </ol>
      </div>
      <div>
        <h3 className="text-base font-bold">Payment Providers</h3>
        <h1 className="">
          Select your preferred payment gateway provider and setup your connection details to
          support payment processing on your video site.
        </h1>
      </div>
      <div className="mt-5">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1">
          <>
            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/bPoint.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('bPoint')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('bPoint'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/BPointProvider`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/PayPal.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('PayPal')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('PayPal'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/PayPalIntegration`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/Stripe.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('Stripe')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('Stripe'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/StripeProvider`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/LivePay.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('LivePay')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('LivePay'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/LivePay`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/PaypalFlow.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('PaypalFlow')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('PaypalFlow'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/PaypalFlowProvider`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/ezidebit.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('ezidebit')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('ezidebit'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/EzidebitProvider`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>

            <div className="dashboard-card paymentProvider">
              <div className="p-4 flex items-center">
                <div className=" w-full">
                  <img src={`../../assets/img/QuickBooks.png`} height="50" width="120" />
                </div>

                <div className="flex">
                  {isPaymentProviderLoading ? (
                    <Loader />
                  ) : (
                    <div className="flex items-center mr-3">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            defaultChecked={getActiveFlag('QuickBooks')}
                            onChange={(e) =>
                              handleUpdateIntegration(e.target.checked, getPaymentId('QuickBooks'))
                            }
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <Link to={`/QuickBooksProvider`}>
                    <h2 className="font-bold cursor-pointer">
                      <i className="fa fa-edit text-purple-500"></i>
                    </h2>
                  </Link>
                </div>
              </div>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default PaymentProvider;
