import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
const InteractivityModerator = () => {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const [tab, setTab] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const videoLibraryAction = useVideosActions(dispatch);
  const { questions, singleVideo } = useSelector((state: RootState) => {
    return {
      questions: state.videos.questions,
      singleVideo: state.videos.singleVideo
    };
  });
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    const win: any = window;
    if (finalData) {
      win.ChatUser = {
        id: finalData.UserId,
        userId: finalData.UserId,
        name: `${finalData.firstName} ${finalData.lastName} `,
        profileUrl: '',
        chatId: id,
        isModerator: false,
        displayUserConnection: false,
        domainId: `${finalData.domainId}`,
        chatEnabled: true,
        pollEnabled: false
      };
    }
    let chatPopOutObject: any = null;
    window.addEventListener('message', function (e) {
      let customChatStyleString = '';
      let chatObj: any = document.querySelector('.playerchatContent iframe');
      chatObj = chatObj.contentWindow;
      if (chatPopOutObject != null) {
        chatObj = chatPopOutObject;
      }
      const jsonParse = JSON.parse(e.data);
      if (jsonParse.action == 'getuserdata') {
        chatObj.postMessage(JSON.stringify({ action: 'userdata', ChatUser: win.ChatUser }), '*');
      } else if (jsonParse.action == 'getchatstyle') {
        chatObj.postMessage(
          JSON.stringify({ action: 'chatstyleresponse', ChatUser: win.ChatUser }),
          '*'
        );

        const dataString =
          '{"messageListPage":[{"key":"--msg-l-color","value":"","id":"chatMessageLeftColor"},{"key":"--chat-box-bg","value":"","id":"chatBodyColor"}],"customStyleString":""}';
        const data = JSON.parse(dataString);
        customChatStyleString = data.customStyleString;
        ((styleText) => {
          setTimeout(() => {
            chatObj.postMessage(JSON.stringify({ action: 'setcustomcss', body: styleText }), '*');
          }, 100);
        })(data.customStyleString);
        const styleArray = data.messageListPage;
        for (const i of styleArray) {
          ((key, color) => {
            setTimeout(() => {
              chatObj.postMessage(
                JSON.stringify({ action: 'setcolor', text: key, value: color }),
                '*'
              );
            }, 100);
          })(i.key, i.value);
        }
      } else if (jsonParse.action == 'pop-out-chat') {
        $(`[src="${process.env.REACT_APP_WCC_CHAT}?v=1.01"]`).attr('id', 'newChatframe');
        const urlStr: any = $('#newChatframe').attr('src');

        const url: any = new URL(urlStr);
        url.searchParams.delete('displaypopout');
        url.searchParams.append('displaypopout', false);
        chatPopOutObject = window.open(url.href, '_blank', 'width=350px,height=500px');
        let getSubActiveRightPanelId: any = null;
        const playerActiveRightPanel = $('.vjs-right-panel.active');
        if (
          playerActiveRightPanel != undefined &&
          playerActiveRightPanel != null &&
          playerActiveRightPanel['length']
        ) {
          getSubActiveRightPanelId =
            playerActiveRightPanel[0].getAttribute('data-right-panel-button');
          if (getSubActiveRightPanelId != undefined && getSubActiveRightPanelId != null) {
            $('#right-panel-button-' + getSubActiveRightPanelId).click();
          }
        }

        const funCheckWindow = () => {
          if (chatPopOutObject != null && !chatPopOutObject.closed) {
            this.setTimeout(funCheckWindow, 500);
          } else if (chatPopOutObject != null && chatPopOutObject.closed) {
            $(`#newChatframe`).attr('src', `${process.env.REACT_APP_WCC_CHAT}?v=1.01`);

            //Load chat again if not opened any other side panels.
            const playerActiveRightPanel2 = $('.vjs-right-panel.active');
            if (playerActiveRightPanel2['length'] == 0) {
              $('#right-panel-button-' + getSubActiveRightPanelId).click();
            }

            chatPopOutObject = null;
          }
        };

        $('#newChatframe').attr('src', '');
        funCheckWindow();
      }
    });

    id && videoLibraryAction.getSingleVideosRequest(id);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      const timer = setTimeout(() => {
        id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: tab });
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: tab });
    }
  }, [selectedValue]);
  const handleRefreshClick = () => {
    id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: tab });
  };

  const handleArchiveClick = (questionId: string, selectedValue: number) => {
    setSelectedValue(selectedValue);
    const payloadData = {
      questionId: questionId,
      updateData: selectedValue
    };
    videoLibraryAction.updateQuestionRequest(payloadData);
  };
  return (
    <div>
      <div className="flex flex-wrap items-center my-6">
        <h2 className="font-semibold mr-3">{singleVideo && singleVideo.title}</h2>
      </div>
      <div className="dashboard-card my-5 py-4">
        <Link to={`/videos/${id}`}>
          <i className="fa fa-arrow-left mr-1"></i> Back to Video
        </Link>
        <div className="grid grid-cols-2 gap-6 mt-3 moderator-interactivity">
          <div className="border rounded-md overflow-y-scroll">
            <div className="flex items-center justify-between border-b px-5 py-4">
              <h2 className="font-semibold mb-4">List</h2>
              <div>
                <div className="md:flex items-center">
                  <button
                    onClick={() => handleRefreshClick()}
                    type="button"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Refresh
                  </button>
                </div>
              </div>
            </div>
            <ul
              id="tabs-tab"
              role="tablist"
              className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(0),
                    id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: 0 });
                }}>
                <span
                  className={`${
                    tab === 0 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  New
                </span>
              </li>
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(1),
                    id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: 1 });
                }}>
                <span
                  className={`${
                    tab === 1 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  Approved
                </span>
              </li>
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(2),
                    id && videoLibraryAction.getQuestionRequest({ videoId: id, questionType: 2 });
                }}>
                <span
                  className={`${
                    tab === 2 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  Archived
                </span>
              </li>
            </ul>

            <div className="modal-body relative py-4 px-10">
              {questions && questions.length ? (
                <>
                  {questions &&
                    questions.map((item) => {
                      return (
                        <div key={item.id}>
                          <div className="flex justify-between">
                            <strong>{item.usernameInput}</strong>
                            <div className="text-left">
                              <div className="dropdown relative">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  id="action-dropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <img
                                    src="../../assets/img/dots-vertical.svg"
                                    className="w-5 h-5"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-ul"
                                  aria-labelledby="action-dropdown">
                                  <li onClick={() => handleArchiveClick(item.id, 1)}>
                                    <button className="action-menu flex items-center">
                                      <span>Approved</span>
                                    </button>
                                  </li>
                                  <li onClick={() => handleArchiveClick(item.id, 2)}>
                                    <button className="action-menu flex items-center">
                                      <span>Archived</span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="text-blue-600">{item.userEmailInput}</div>
                          <p>{item.message}</p>
                          <small className="">{item.createdUTC}</small>
                          <hr className="mt-3" />
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="p-4 text-center">
                  <p>No Questions</p>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="feature-div playerchatContent h-full ">
              <iframe
                id="chatFrame"
                src={`${process.env.REACT_APP_WCC_CHAT}?v=1.01`}
                width="100%"
                height="100%"
                allow="autoplay"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractivityModerator;
