import { handleActions } from "redux-actions";
import { CourseActions } from "./actions";
import { RootState } from "../reducers/state";
import { CourseSequencesModel, CourseStateModel, CourseModel, CourseModuleModel, ChapterModel, CheckpointsModel } from "src/models/courseModel";
import { Actions } from "src/models/courseModel";
import * as Constants from './constants';
import { act } from "react-dom/test-utils";
import { PaginationModel } from "src/models/paginationModel";

const initialState: RootState.courseModelState = {
    course: [],
    singleCourse: {} as CourseModel,
    courseModule: [],
    courseCreditType: [],
    courseResults: [],
    chapters: [],
    singleChapter: {} as ChapterModel,
    singleCheckpoint: {} as CheckpointsModel,
    checkpoints: [],
    isCourseSaved: false,
    isCourseDeleted: false,
    isCourseUpdated: false,
    isCourseModuleAdded: false,
    isChapterAdded: false,
    isChapterDeleted: false,
    isCheckpointAdded: false,
    isCheckpointDeleted: false,
    success: false,
    error: false,
    loading: false,
    loadingCourseResult: false,
    message: '',
    savedCourseId: '',
    loadingCourseVideo: false,
    courseVideos: [],
    courseSequences: {} as CourseSequencesModel,
    paginationMeta: {} as PaginationModel,
    isCourseSequencesSaved: false,
    isCourseVideoSequenceUpdated: false,
    isCourseOverviewUpdated: false,
    isCourseEducationUpdated: false,
    isCourseProgressUpdated: false,
    isChapterLoading: false,
    isCheckpointsLoading: false
};

function courseReducer(state: CourseStateModel = initialState, action: Actions): CourseStateModel {
    switch (action.type) {
        case Constants.getCourseRequestActionType: return getCourseAction(state);
        case Constants.getCourseRequestSuccessActionType: return getCourseSuccessAction(state, action);
        case Constants.getCourseRequestFailureActionType: return getCourseFailureAction(state);

        case Constants.saveCourseRequestActionType: return saveCourseAction(state);
        case Constants.saveCourseRequestSuccessActionType: return saveCourseSuccessAction(state, action);
        case Constants.saveCourseRequestFailureActionType: return saveCourseFailureAction(state, action);

        case Constants.getSingleCourseRequestActionType: return getSingleCourseAction(state);
        case Constants.getSingleCourseRequestSuccessActionType: return getSingleCourseSuccessAction(state, action);
        case Constants.getSingleCourseRequestFailureActionType: return getSingleCourseFailureAction(state);

        case Constants.deleteCourseRequestActionType: return deleteCourseAction(state);
        case Constants.deleteCourseRequestSuccessActionType: return deleteCourseSuccessAction(state);
        case Constants.deleteCourseRequestFailureActionType: return deleteCourseFailureAction(state);

        case Constants.updateCourseRequestActionType: return updateCourseAction(state);
        case Constants.updateCourseRequestSuccessActionType: return updateCourseSuccessAction(state);
        case Constants.updateCourseRequestFailureActionType: return updateCourseFailureAction(state);

        case Constants.getCourseModuleRequestActionType: return getCourseModuleAction(state);
        case Constants.getCourseModuleRequestSuccessActionType: return getCourseModuleSuccessAction(state, action);
        case Constants.getCourseModuleRequestFailureActionType: return getCourseModuleFailureAction(state);

        case Constants.addCourseModuleRequestActionType: return addCourseModuleAction(state);
        case Constants.addCourseModuleRequestSuccessActionType: return addCourseModuleSuccessAction(state);
        case Constants.addCourseModuleRequestFailureActionType: return addCourseModuleFailureAction(state);

        case Constants.getCourseCreditTypeRequestActionType: return getCourseCreditTypeAction(state);
        case Constants.getCourseCreditTypeRequestSuccessActionType: return getCourseCreditTypeSuccessAction(state, action);
        case Constants.getCourseCreditTypeRequestFailureActionType: return getCourseCreditTypeFailureAction(state);

        case Constants.getCourseResultRequestActionType: return getCourseResultAction(state);
        case Constants.getCourseResultRequestSuccessActionType: return getCourseResultSuccessAction(state, action);
        case Constants.getCourseResultRequestFailureActionType: return getCourseResultFailureAction(state);

        case Constants.getCourseVideosRequestActionType: return getCourseVideoAction(state);
        case Constants.getCourseVideosRequestSuccessActionType: return getCourseVideoSuccessAction(state, action);
        case Constants.getCourseVideosRequestFailureActionType: return getCourseVideoFailureAction(state);

        case Constants.addCourseVideoSequenceRequestActionType: return updateCourseVideoSequenceAction(state);
        case Constants.addCourseVideoSequenceRequestSuccessActionType: return updateCourseVideoSequenceSuccessAction(state);
        case Constants.addCourseVideoSequenceRequestFailureActionType: return updateCourseVideoSequenceFailureAction(state);

        case Constants.updateCourseOverviewRequestActionType: return updateCourseOverviewAction(state);
        case Constants.updateCourseOverviewRequestSuccessActionType: return updateCourseOverviewSuccessAction(state);
        case Constants.updateCourseOverviewRequestFailureActionType: return updateCourseOverviewFailureAction(state);

        case Constants.updateCourseEducationRequestActionType: return updateCourseEducationAction(state);
        case Constants.updateCourseEducationRequestSuccessActionType: return updateCourseEducationSuccessAction(state);
        case Constants.updateCourseEducationRequestFailureActionType: return updateCourseEducationFailureAction(state);

        case Constants.updateCourseProgressRequestActionType: return updateCourseProgressAction(state);
        case Constants.updateCourseProgressRequestSuccessActionType: return updateCourseProgressSuccessAction(state);
        case Constants.updateCourseProgressRequestFailureActionType: return updateCourseProgressFailureAction(state);

        case Constants.getChaptersRequestActionType: return getChaptersAction(state);
        case Constants.getChaptersRequestSuccessActionType: return getChaptersSuccessAction(state, action);
        case Constants.getChaptersRequestFailureActionType: return getChaptersFailureAction(state);

        case Constants.addChapterRequestActionType: return addChapterAction(state);
        case Constants.addChapterRequestSuccessActionType: return addChapterSuccessAction(state);
        case Constants.addChapterRequestFailureActionType: return addChapterFailureAction(state);

        case Constants.updateChapterRequestActionType: return updateChapterAction(state);
        case Constants.updateChapterRequestSuccessActionType: return updateChapterSuccessAction(state);
        case Constants.updateChapterRequestFailureActionType: return updateChapterFailureAction(state);

        case Constants.deleteChapterRequestActionType: return deleteChapterAction(state);
        case Constants.deleteChapterRequestSuccessActionType: return deleteChapterSuccessAction(state);
        case Constants.deleteChapterRequestFailureActionType: return deleteChapterFailureAction(state);

        case Constants.getSingleChapterRequestActionType: return getSingleChapterAction(state);
        case Constants.getSingleChapterRequestSuccessActionType: return getSingleChapterSuccessAction(state, action);
        case Constants.getSingleChapterRequestFailureActionType: return getSingleChapterFailureAction(state);

        case Constants.getCheckpointsRequestActionType: return getCheckpointsAction(state);
        case Constants.getCheckpointsRequestSuccessActionType: return getCheckpointsSuccessAction(state, action);
        case Constants.getCheckpointsRequestFailureActionType: return getCheckpointsFailureAction(state);

        case Constants.addCheckpointRequestActionType: return addCheckpointAction(state);
        case Constants.addCheckpointRequestSuccessActionType: return addCheckpointSuccessAction(state);
        case Constants.addCheckpointRequestFailureActionType: return addCheckpointFailureAction(state);

        case Constants.updateCheckpointRequestActionType: return updateCheckpointAction(state);
        case Constants.updateCheckpointRequestSuccessActionType: return updateCheckpointSuccessAction(state);
        case Constants.updateCheckpointRequestFailureActionType: return updateCheckpointFailureAction(state);

        case Constants.deleteCheckpointRequestActionType: return deleteCheckpointAction(state);
        case Constants.deleteCheckpointRequestSuccessActionType: return deleteCheckpointSuccessAction(state);
        case Constants.deleteCheckpointRequestFailureActionType: return deleteCheckpointFailureAction(state);

        case Constants.getSingleCheckpointRequestActionType: return getSingleCheckpointAction(state);
        case Constants.getSingleCheckpointRequestSuccessActionType: return getSingleCheckpointSuccessAction(state, action);
        case Constants.getSingleCheckpointRequestFailureActionType: return getSingleCheckpointFailureAction(state);

        case Constants.getCourseSequencesRequestActionType: return getCourseSequencesAction(state);
        case Constants.getCourseSequencesRequestSuccessActionType: return getCourseSequencesSuccessAction(state, action);
        case Constants.getCourseSequencesRequestFailureActionType: return getCourseSequencesFailureAction(state);

        case Constants.saveCourseSequencesRequestActionType: return saveCourseSequencesAction(state);
        case Constants.saveCourseSequencesRequestSuccessActionType: return saveCourseSequencesSuccessAction(state);
        case Constants.saveCourseSequencesRequestFailureActionType: return saveCourseSequencesFailureAction(state);

        case Constants.resetCourseStateActionType: return resetCourseStateAction(state);

        default: return state;
    }

    function getCourseAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCourseSuccessAction(state: CourseStateModel, action: CourseActions.getCourseRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                course: action.payload.data,
                loading: false,
                paginationMeta: action.payload.paginationMeta
            };
        }
        return state;
    }
    function getCourseFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loading: false,
            //message: action.payload.message,
        }
    }
    function saveCourseAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCourseSaved: false,
        };
    }
    function saveCourseSuccessAction(state: CourseStateModel, action: any): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                isCourseSaved: true,
                message: action.payload.message,
                savedCourseId: action.payload.result
            };
        }
        return state;
    }
    function saveCourseFailureAction(state: CourseStateModel, action: any): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                isCourseSaved: false,
                message: action.payload,
            }
        }
        return state;
    }
    function getSingleCourseAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleCourseSuccessAction(state: CourseStateModel, action: CourseActions.getSingleCourseRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                singleCourse: action.payload.result,
                loading: false,
            };
        }
        return state;
    }
    function getSingleCourseFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function deleteCourseAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteCourseSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseDeleted: true,
            loading: false,
        };
    }
    function deleteCourseFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseDeleted: false,
            loading: false
        }
    }
    function updateCourseAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCourseUpdated: false,
        };
    }
    function updateCourseSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseUpdated: true,
            message: "Course updated successfully",
        };
    }
    function updateCourseFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseUpdated: false
        }
    }
    function getCourseModuleAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCourseModuleSuccessAction(state: CourseStateModel, action: CourseActions.getCourseModuleRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                courseModule: action.payload.result,
                loading: false,
            };
        }
        return state;
    }
    function getCourseModuleFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function addCourseModuleAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCourseModuleAdded: false,
        };
    }
    function addCourseModuleSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseModuleAdded: true,
            loading: false,
        };
    }
    function addCourseModuleFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseModuleAdded: false,
            loading: false,
        }
    }
    function getCourseCreditTypeAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCourseCreditTypeSuccessAction(state: CourseStateModel, action: CourseActions.getCourseCreditTypeRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                courseCreditType: action.payload.result,
            };
        }
        return state;
    }
    function getCourseCreditTypeFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getCourseResultAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loadingCourseResult: true,
        };
    }
    function getCourseResultSuccessAction(state: CourseStateModel, action: CourseActions.getCourseResultRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                courseResults: action.payload.result,
                loadingCourseResult: false,
            };
        }
        return state;
    }
    function getCourseResultFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loadingCourseResult: false,
        }
    }

    function getCourseVideoAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loadingCourseVideo: true,
        };
    }
    function getCourseVideoSuccessAction(state: CourseStateModel, action: CourseActions.getCourseVideosRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                courseVideos: action.payload.result,
                loadingCourseVideo: false,
                message: action.payload.message,
            };
        }
        return state;
    }
    function getCourseVideoFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loadingCourseVideo: false,
        }
    }
    function updateCourseVideoSequenceAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseVideoSequenceUpdated: false,
        };
    }
    function updateCourseVideoSequenceSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseVideoSequenceUpdated: true,
        };
    }
    function updateCourseVideoSequenceFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseVideoSequenceUpdated: false,
        }
    }
    function updateCourseOverviewAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state
        };
    }
    function updateCourseOverviewSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseOverviewUpdated: true,
        };
    }
    function updateCourseOverviewFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseOverviewUpdated: false,
        }
    }
    function updateCourseEducationAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state
        };
    }
    function updateCourseEducationSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseEducationUpdated: true,
        };
    }
    function updateCourseEducationFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseEducationUpdated: false,
        }
    }
    function updateCourseProgressAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state
        };
    }
    function updateCourseProgressSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseProgressUpdated: true,
        };
    }
    function updateCourseProgressFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseProgressUpdated: false,
        }
    }
    function getChaptersAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterLoading: true,
        };
    }
    function getChaptersSuccessAction(state: CourseStateModel, action: CourseActions.getChaptersRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                chapters: action.payload.data,
                isChapterLoading: false,
                paginationMeta: action.payload.paginationMeta
            };
        }
        return state;
    }
    function getChaptersFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            isChapterLoading: false,
        }
    }
    function addChapterAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isChapterAdded: false,
        };
    }
    function addChapterSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterAdded: true,
            loading: false,
        };
    }
    function addChapterFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterAdded: false,
            loading: false,
        }
    }
    function updateChapterAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isChapterAdded: false,
        };
    }
    function updateChapterSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterAdded: true,
            loading: false,
        };
    }
    function updateChapterFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterAdded: false,
            loading: false,
        }
    }
    function deleteChapterAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isChapterDeleted: false,
        };
    }
    function deleteChapterSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterDeleted: true,
            loading: false,
        };
    }
    function deleteChapterFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isChapterDeleted: false,
            loading: false,
        }
    }
    function getSingleChapterAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleChapterSuccessAction(state: CourseStateModel, action: CourseActions.getSingleChapterRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                singleChapter: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleChapterFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function getCheckpointsAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointsLoading: true,
        };
    }
    function getCheckpointsSuccessAction(state: CourseStateModel, action: CourseActions.getCheckpointsRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                checkpoints: action.payload.data,
                isCheckpointsLoading: false,
                paginationMeta: action.payload.paginationMeta
            };
        }
        return state;
    }
    function getCheckpointsFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointsLoading: false,
        }
    }
    function addCheckpointAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCheckpointAdded: false,
        };
    }
    function addCheckpointSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointAdded: true,
            loading: false,
        };
    }
    function addCheckpointFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointAdded: false,
            loading: false,
        }
    }
    function updateCheckpointAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCheckpointAdded: false,
        };
    }
    function updateCheckpointSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointAdded: true,
            loading: false,
        };
    }
    function updateCheckpointFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointAdded: false,
            loading: false,
        }
    }
    function deleteCheckpointAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCheckpointDeleted: false,
        };
    }
    function deleteCheckpointSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointDeleted: true,
            loading: false,
        };
    }
    function deleteCheckpointFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCheckpointDeleted: false,
            loading: false,
        }
    }
    function getSingleCheckpointAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleCheckpointSuccessAction(state: CourseStateModel, action: CourseActions.getSingleCheckpointRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                singleCheckpoint: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getSingleCheckpointFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function getCourseSequencesAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCourseSequencesSuccessAction(state: CourseStateModel, action: CourseActions.getCourseSequencesRequestSuccessAction): CourseStateModel {
        if (action.payload) {
            return {
                ...state,
                courseSequences: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getCourseSequencesFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: false,
        }
    }
    function saveCourseSequencesAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            loading: true,
            isCourseSequencesSaved: false,
        };
    }
    function saveCourseSequencesSuccessAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseSequencesSaved: true,
            loading: false,
        };
    }
    function saveCourseSequencesFailureAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseSequencesSaved: false,
            loading: false,
        }
    }
    function resetCourseStateAction(state: CourseStateModel): CourseStateModel {
        return {
            ...state,
            isCourseSaved: false,
            isCourseUpdated: false,
            isCourseOverviewUpdated: false,
            isCourseEducationUpdated: false,
            isCourseProgressUpdated: false,
            isCourseVideoSequenceUpdated: false,
            isCourseDeleted: false,
            message: '',
            isChapterDeleted: false,
            isChapterAdded: false,
            isCheckpointDeleted: false,
            isCheckpointAdded: false
        }
    }
}

export default courseReducer
