export const getDiscountPoliciesActionType = 'GET_DISCOUNTS'
export const getDiscountPoliciesSuccessActionType = 'GET_DISCOUNTS_SUCCESS'
export const getDiscountsPoliciesFailureActionType = 'GET_DISCOUNTS_FAILURE'

export const getDiscountDetailRequestActionType = 'GET_DISCOUNT_DETAIL_REQUEST'
export const getDiscountDetailRequestSuccessActionType = 'GET_DISCOUNT_DETAIL_REQUEST_SUCCESS'
export const getDiscountDetailRequestFailureActionType = 'GET_DISCOUNT_DETAIL_REQUEST_FAILURE'

export const addDiscountPolicyRequestActionType = 'ADD_DISCOUNT_REQUEST'
export const addDiscountPolicyRequestSuccessActionType = 'ADD_DISCOUNT_REQUEST_SUCCESS'
export const addDiscountPolicyRequestFailureActionType = 'ADD_DISCOUNT_REQUEST_FAILURE'

export const editDiscountPolicyRequestActionType = 'EDIT_DISCOUNT_REQUEST'
export const editDiscountPolicyRequestSuccessActionType = 'EDIT_DISCOUNT_REQUEST_SUCCESS'
export const editDiscountPolicyRequestFailureActionType = 'EDIT_DISCOUNT_REQUEST_FAILURE'

export const deleteDiscountPolicyRequestActionType = 'DELETE_DISCOUNT_REQUEST'
export const deleteDiscountPolicyRequestSuccessActionType = 'DELETE_DISCOUNT_REQUEST_SUCCESS'
export const deleteDiscountPolicyRequestFailureActionType = 'DELETE_DISCOUNT_REQUEST_FAILURE'

export const addDiscountCodeRequestActionType = 'ADD_DISCOUNT_CODE_REQUEST'
export const addDiscountCodeRequestSuccessActionType = 'ADD_DISCOUNT_CODE_REQUEST_SUCCESS'
export const addDiscountCodeRequestFailureActionType = 'ADD_DISCOUNT_CODE_REQUEST_FAILURE'

export const deleteDiscountCodeRequestActionType = 'DELETE_DISCOUNT_CODE_REQUEST'
export const deleteDiscountCodeRequestSuccessActionType = 'DELETE_DISCOUNT_CODE_REQUEST_SUCCESS'
export const deleteDiscountCodeRequestFailureActionType = 'DELETE_DISCOUNT_CODE_REQUEST_FAILURE'

export const addDiscountMemberListRequestActionType = 'ADD_DISCOUNT_MEMBERLIST_REQUEST'
export const addDiscountMemberListRequestSuccessActionType = 'ADD_DISCOUNT_MEMBERLIST_REQUEST_SUCCESS'
export const addDiscountMemberListRequestFailureActionType = 'ADD_DISCOUNT_MEMBERLIST_REQUEST_FAILURE'

export const deleteDiscountMemberListRequestActionType = 'DELETE_DISCOUNT_MEMBERLIST_REQUEST'
export const deleteDiscountMemberListRequestSuccessActionType = 'DELETE_DISCOUNT_MEMBERLIST_REQUEST_SUCCESS'
export const deleteDiscountMemberListRequestFailureActionType = 'DELETE_DISCOUNT_MEMBERLIST_REQUEST_FAILURE'

export const resetDiscountPolicyStateActionType = 'RESET_DISCOUNTS_STATE'
export const resetDiscountPolicyDataStateActionType = 'RESET_DISCOUNTS_DATA_STATE'

//Packages
export const getPackagesActionType = 'GET_PACKAGES'
export const getPackagesSuccessActionType = 'GET_PACKAGES_SUCCESS'
export const getPackagesFailureActionType = 'GET_PACKAGES_FAILURE'

export const getPackageDetailsRequestActionType = 'GET_PACKAGE_DETAIL_REQUEST'
export const getPackageDetailsRequestSuccessActionType = 'GET_PACKAGE_DETAIL_REQUEST_SUCCESS'
export const getPackageDetailsRequestFailureActionType = 'GET_PACKAGE_DETAIL_REQUEST_FAILURE'

export const addPackageRequestActionType = 'ADD_PACKAGE_REQUEST'
export const addPackageRequestSuccessActionType = 'ADD_PACKAGE_REQUEST_SUCCESS'
export const addPackageRequestFailureActionType = 'ADD_PACKAGE_REQUEST_FAILURE'

export const editPackageRequestActionType = 'EDIT_PACKAGE_REQUEST'
export const editPackageRequestSuccessActionType = 'EDIT_PACKAGE_REQUEST_SUCCESS'
export const editPackageRequestFailureActionType = 'EDIT_PACKAGE_REQUEST_FAILURE'

export const deletePackageRequestActionType = 'DELETE_PACKAGE_REQUEST'
export const deletePackageRequestSuccessActionType = 'DELETE_PACKAGE_REQUEST_SUCCESS'
export const deletePackageRequestFailureActionType = 'DELETE_PACKAGE_REQUEST_FAILURE'

export const addPackageMemberListRequestActionType = 'ADD_PACKAGE_MEMBERLIST_REQUEST'
export const addPackageMemberListRequestSuccessActionType = 'ADD_PACKAGE_MEMBERLIST_REQUEST_SUCCESS'
export const addPackageMemberListRequestFailureActionType = 'ADD_PACKAGE_MEMBERLIST_REQUEST_FAILURE'

export const deletePackageMemberListRequestActionType = 'DELETE_PACKAGE_MEMBERLIST_REQUEST'
export const deletePackageMemberListRequestSuccessActionType = 'DELETE_PACKAGE_MEMBERLIST_REQUEST_SUCCESS'
export const deletePackageMemberListRequestFailureActionType = 'DELETE_PACKAGE_MEMBERLIST_REQUEST_FAILURE'

export const resetPackagePolicyStateActionType = 'RESET_PACKAGE_STATE'
export const resetPackagePolicyDataStateActionType = 'RESET_PACKAGE_DATA_STATE'

//Vouchers
export const getVouchersActionType = 'GET_VOUCHERS'
export const getVouchersSuccessActionType = 'GET_VOUCHERS_SUCCESS'
export const getVouchersFailureActionType = 'GET_VOUCHERS_FAILURE'

export const getVoucherDetailsRequestActionType = 'GET_VOUCHER_DETAIL_REQUEST'
export const getVoucherDetailsRequestSuccessActionType = 'GET_VOUCHER_DETAIL_REQUEST_SUCCESS'
export const getVoucherDetailsRequestFailureActionType = 'GET_VOUCHER_DETAIL_REQUEST_FAILURE'

export const addVoucherRequestActionType = 'ADD_VOUCHER_REQUEST'
export const addVoucherRequestSuccessActionType = 'ADD_VOUCHER_REQUEST_SUCCESS'
export const addVoucherRequestFailureActionType = 'ADD_VOUCHER_REQUEST_FAILURE'

export const editVoucherRequestActionType = 'EDIT_VOUCHER_REQUEST'
export const editVoucherRequestSuccessActionType = 'EDIT_VOUCHER_REQUEST_SUCCESS'
export const editVoucherRequestFailureActionType = 'EDIT_VOUCHER_REQUEST_FAILURE'

export const deleteVoucherRequestActionType = 'DELETE_VOUCHER_REQUEST'
export const deleteVoucherRequestSuccessActionType = 'DELETE_VOUCHER_REQUEST_SUCCESS'
export const deleteVoucherRequestFailureActionType = 'DELETE_VOUCHER_REQUEST_FAILURE'

export const updateVoucherStatusRequestActionType = 'UPDATE_VOUCHER_STATUS_REQUEST'
export const updateVoucherStatusRequestSuccessActionType = 'UPDATE_VOUCHER_STATUS_REQUEST_SUCCESS'
export const updateVoucherStatusRequestFailureActionType = 'UPDATE_VOUCHER_STATUS_REQUEST_FAILURE'

export const getVoucherUsageRequestActionType = 'GET_VOUCHER_USAGE_REQUEST'
export const getVoucherUsageRequestSuccessActionType = 'GET_VOUCHER_USAGE_REQUEST_SUCCESS'
export const getVoucherUsageRequestFailureActionType = 'GET_VOUCHER_USAGE_REQUEST_FAILURE'

export const resetVoucherPolicyStateActionType = 'RESET_VOUCHER_STATE'
export const resetVoucherPolicyDataStateActionType = 'RESET_VOUCHER_DATA_STATE'

//Bundles
export const getBundlesActionType = 'GET_BUNDLES'
export const getBundlesSuccessActionType = 'GET_BUNDLES_SUCCESS'
export const getBundlesFailureActionType = 'GET_BUNDLES_FAILURE'

export const getBundleDetailsRequestActionType = 'GET_BUNDLE_DETAIL_REQUEST'
export const getBundleDetailsRequestSuccessActionType = 'GET_BUNDLE_DETAIL_REQUEST_SUCCESS'
export const getBundleDetailsRequestFailureActionType = 'GET_BUNDLE_DETAIL_REQUEST_FAILURE'

export const addBundleRequestActionType = 'ADD_BUNDLE_REQUEST'
export const addBundleRequestSuccessActionType = 'ADD_BUNDLE_REQUEST_SUCCESS'
export const addBundleRequestFailureActionType = 'ADD_BUNDLE_REQUEST_FAILURE'

export const editBundleRequestActionType = 'EDITBUNDLE_REQUEST'
export const editBundleRequestSuccessActionType = 'EDIT_BUNDLE_REQUEST_SUCCESS'
export const editBundleRequestFailureActionType = 'EDIT_BUNDLE_REQUEST_FAILURE'

export const deleteBundleRequestActionType = 'DELETE_BUNDLE_REQUEST'
export const deleteBundleRequestSuccessActionType = 'DELETE_BUNDLE_REQUEST_SUCCESS'
export const deleteBundleRequestFailureActionType = 'DELETE_BUNDLE_REQUEST_FAILURE'

export const addBundleMemberListRequestActionType = 'ADD_BUNDLE_MEMBERLIST_REQUEST'
export const addBundleMemberListRequestSuccessActionType = 'ADD_BUNDLE_MEMBERLIST_REQUEST_SUCCESS'
export const addBundleMemberListRequestFailureActionType = 'ADD_BUNDLE_MEMBERLIST_REQUEST_FAILURE'

export const deleteBundleMemberListRequestActionType = 'DELETE_BUNDLE_MEMBERLIST_REQUEST'
export const deleteBundleMemberListRequestSuccessActionType = 'DELETE_BUNDLE_MEMBERLIST_REQUEST_SUCCESS'
export const deleteBundleMemberListRequestFailureActionType = 'DELETE_BUNDLE_MEMBERLIST_REQUEST_FAILURE'

export const resetBundlePolicyStateActionType = 'RESET_BUNDLE_STATE'
export const resetBundlePolicyDataStateActionType = 'RESET_BUNDLE_DATA_STATE'
 

//Subsciptions
export const getSubscriptionsActionType = 'GET_SUBSSCRIPTIONS'
export const getSubscriptionsSuccessActionType = 'GET_SUBSSCRIPTIONS_SUCCESS'
export const getSubscriptionsFailureActionType = 'GET_SUBSSCRIPTIONS_FAILURE'

export const getSubscriptionDetailsRequestActionType = 'GET_SUBSSCRIPTION_DETAIL_REQUEST'
export const getSubscriptionDetailsRequestSuccessActionType = 'GET_SUBSSCRIPTION_DETAIL_REQUEST_SUCCESS'
export const getSubscriptionDetailsRequestFailureActionType = 'GET_SUBSSCRIPTION_DETAIL_REQUEST_FAILURE'

export const addSubscriptionRequestActionType = 'ADD_SUBSSCRIPTION_REQUEST'
export const addSubscriptionRequestSuccessActionType = 'ADD_SUBSSCRIPTION_REQUEST_SUCCESS'
export const addSubscriptionRequestFailureActionType = 'ADD_SUBSSCRIPTION_REQUEST_FAILURE'

export const editSubscriptionRequestActionType = 'EDIT_SUBSSCRIPTION_REQUEST'
export const editSubscriptionRequestSuccessActionType = 'EDIT_SUBSSCRIPTION_REQUEST_SUCCESS'
export const editSubscriptionRequestFailureActionType = 'EDIT_SUBSSCRIPTION_REQUEST_FAILURE'

export const deleteSubscriptionRequestActionType = 'DELETE_SUBSSCRIPTION_REQUEST'
export const deleteSubscriptionRequestSuccessActionType = 'DELETE_SUBSSCRIPTION_REQUEST_SUCCESS'
export const deleteSubscriptionRequestFailureActionType = 'DELETE_SUBSSCRIPTION_REQUEST_FAILURE'

export const getSubscriptionSubscribersRequestActionType = 'GET_SUBSSCRIPTION_DSUBSCRIBERS_REQUEST'
export const getSubscriptionSubscribersRequestSuccessActionType = 'GET_SUBSSCRIPTION_SUBSCRIBERS_REQUEST_SUCCESS'
export const getSubscriptionSubscribersRequestFailureActionType = 'GET_SUBSSCRIPTION_SUBSCRIBERS_REQUEST_FAILURE'

export const resetSubscriptionStateActionType = 'RESET_SUBSSCRIPTION_STATE'
export const resetSubscriptionDataStateActionType = 'RESET_SUBSSCRIPTION_DATA_STATE' 