export const getEzidebitRequestActionType = 'GET_EZIDEBIT_REQUEST'
export const getEzidebitSuccessActionType = 'GET_EZIDEBIT_REQUEST_SUCCESS'
export const getEzidebitFailureActionType = 'GET_EZIDEBIT_REQUEST_FAILURE'
export const saveEzidebitRequestActionType = 'SAVE_EZIDEBIT_REQUEST'
export const saveEzidebitSuccessActionType = 'SAVE_EZIDEBIT_REQUEST_SUCCESS'
export const saveEzidebitFailureActionType = 'SAVE_EZIDEBIT_REQUEST_FAILURE'
export const updateEzidebitRequestActionType = 'UPDATE_EZIDEBIT_REQUEST'
export const updateEzidebitSuccessActionType = 'UPDATE_EZIDEBIT_REQUEST_SUCCESS'
export const updateEzidebitFailureActionType = 'UPDATE_EZIDEBIT_REQUEST_FAILURE'
export const getSingleEzidebitRequestActionType = 'GET_SINGLE_EZIDEBIT_REQUEST'
export const getSingleEzidebitSuccessActionType = 'GET_SINGLE_EZIDEBIT_REQUEST_SUCCESS'
export const getSingleEzidebitFailureActionType = 'GET_SINGLE_EZIDEBIT_REQUEST_FAILURE'
export const deleteEzidebitRequestActionType = 'DELETE_EZIDEBIT_REQUEST'
export const deleteEzidebitSuccessActionType = 'DELETE_EZIDEBIT_REQUEST_SUCCESS'
export const deleteEzidebitFailureActionType = 'DELETE_EZIDEBIT_REQUEST_FAILURE'