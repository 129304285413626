import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { successMsg } from 'src/components/utilities/utils';
import { useParams, Link } from 'react-router-dom';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  setSelectedOption: (value: number) => void;
  excelData: Array<string> | undefined;
  demoImportFileData: Array<string> | undefined;
  fileData: any;
  setFileData: any;
}
const SelectField: React.FC<IProps> = ({
  setSelectedOption,
  excelData,
  demoImportFileData,
  fileData,
  setFileData
}) => {
  const { eventId } = useParams<string>();
  const dispatch = useDispatch();
  const eventAction = useEventActions(dispatch);
  const [mapFieldData, setMapFieldData] = useState<any>();
  const [finalExcelData, setFinalExcelData] = useState<any>();
  const [finalJsonOptionData, setFinalJsonOptionData] = useState<any>();
  const [excelValidationError, setExcelValidationError] = useState<any>();
  const [newData, setNewData] = useState<any>(excelData);
  const [errorValidation, setErrorValidation] = useState<boolean>(false);
  const [numberFieldError, setNumberFieldError] = useState<boolean>(false);
  const { register, watch, setValue } = useForm();
  useEffect(() => {
    sessionStorage.setItem('initialExcel', JSON.stringify(excelData));
  }, []);

  useEffect(() => {
    if (excelData && excelData.length) {
      const excelOptionData = excelData[0].split(',');
      const lastIndex = excelOptionData.length - 1;
      excelOptionData[lastIndex] = excelOptionData[lastIndex].replace(/\r/g, '');
      // Find the index of "Last Login Date" in the array
      const indexToRemove = excelOptionData.indexOf('Last Login Date');

      // Remove the element at that index
      if (indexToRemove !== -1) {
        excelOptionData.splice(indexToRemove, 1);
      }

      const finalExcelOptions =
        demoImportFileData &&
        demoImportFileData.map((item, index) => {
          const updatedExcelOptionData = [...excelOptionData];
          if (item !== 'Email' && item !== 'First Name' && item !== 'Last Name') {
            updatedExcelOptionData.push('Do not import this field');
          }
          return {
            field: item,
            excelOptionData: updatedExcelOptionData
          };
        });
      setMapFieldData(finalExcelOptions);
    }
    const excelArray = excelData?.map((item) => {
      return item.split(',');
    });
    setFinalExcelData(excelArray);
  }, [excelData]);

  const convertToCsv = (dataArray: any) => {
    const csvContent = dataArray.join('\n');
    console.log('csv after mapping', csvContent);
    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });
    return blob;
  };

  const handleOptionChange = (value: string, optionId: number) => {
    setValue(`item_${optionId}`, value);
    setErrorValidation(false);
    setNumberFieldError(false);
    const JsonData =
      demoImportFileData &&
      demoImportFileData.map((item, index) => {
        return { key: item, value: watch(`item_${index}`) };
      });

    // const valuesArray = JsonData && JsonData.map((entry) => entry.value);
    // const finalMappedData = valuesArray && valuesArray.toString() + '\r';
    // if (excelData && finalMappedData) {
    //   excelData[0] = finalMappedData;
    //   const newArray = excelData.filter(
    //     (value) => value.trim() !== '' && value.trim() !== ','.repeat(value.trim().length)
    //   );
    //   console.log('excel data after mapping', newArray);
    //   const csvData = convertToCsv(newArray);
    //   setFileData(csvData);
    // }
    JsonData &&
      JsonData.map((item) => {
        if (item.key === 'Email') {
          if (item.key === 'Email' && item.value !== 'Email') {
            setErrorValidation(true);
          }
        }
      });
    setFinalJsonOptionData(JsonData);
  };

  // Define the mapping function
  function mapFields(e1: any, e2: any) {
    // Parse mappings from e1
    const mapping: any = {};
    e1.forEach((item: any) => {
      mapping[item.key] = item.value;
    });

    // Separate header
    const header = e2.shift().split(',');

    // Create the new header based on keys and filter out fields to remove
    const newHeader = e1
      .filter((item: any) => item.value !== 'Do not import this field')
      .map((item: any) => item.key);

    // Rearrange fields based on mappings and filter out fields to remove
    const result = [
      newHeader.join(','), // Add the new header as the first row
      ...e2.map((row: any) => {
        // Split each row by comma
        const fields = row.split(',');

        // Create a new row based on the new header order
        const newRow = newHeader.map((key: string) => {
          const value = mapping[key];
          if (value) {
            const index = header.findIndex((h: string) => h.trim() === value);
            return fields[index] || '';
          }
          return '';
        });

        // Assemble rearranged fields back into a string
        return newRow.join(',');
      })
    ];

    setNewData(result);
    console.log('result', result);
    return result;
  }

  useEffect(() => {
    if (demoImportFileData && demoImportFileData.length) {
      const JsonData =
        demoImportFileData &&
        demoImportFileData.map((item, index) => {
          return { key: item, value: watch(`item_${index}`) };
        });
      setFinalJsonOptionData(JsonData);
    }
  }, [demoImportFileData, mapFieldData, watch]);

  // Utility function to validate email
  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Utility function to validate if a string is a number
  const isValidNumber = (number: any) => {
    return !isNaN(number) && number.trim() !== '';
  };
  // Function to parse and validate the CSV data
  const validateCSVData = (data: any) => {
    const headers = data[0].split(',');
    const emailIndex = headers.indexOf('Email');
    const contactNumberIndex = headers.indexOf('Contact Number');
    const memberNumberIndex = headers.indexOf('Member Number');
    const invalidEntries = [];
    for (let i = 1; i < data.length; i++) {
      const row = data[i].split(',');
      const email = row[emailIndex];
      const contactNumber = contactNumberIndex && row[contactNumberIndex];
      const memberNumber = memberNumberIndex && row[memberNumberIndex];

      if (email && !isValidEmail(email)) {
        invalidEntries.push({ row: i, field: 'Email', value: email });
      }
      if (contactNumber && !isValidNumber(contactNumber)) {
        invalidEntries.push({ row: i, field: 'Contact Number', value: contactNumber });
      }
      if (memberNumber && !isValidNumber(memberNumber)) {
        invalidEntries.push({ row: i, field: 'Member Number', value: memberNumber });
      }
    }

    return invalidEntries;
  };

  const handleSaveExcelData = () => {
    setErrorValidation(false);
    setNumberFieldError(false);
    const JsonData =
      demoImportFileData &&
      demoImportFileData.map((item, index) => {
        return { key: item, value: watch(`item_${index}`) };
      });
    console.log(JsonData, newData);
    const initailExcelData = sessionStorage.getItem('initialExcel');
    const parseInitialExcelData = initailExcelData && JSON.parse(initailExcelData);
    const finalMappingData = mapFields(JsonData, parseInitialExcelData);
    const newArray = finalMappingData.filter(
      (value) => value.trim() !== '' && value.trim() !== ','.repeat(value.trim().length)
    );
    console.log('excel data after mapping', newArray);

    const cleanData = newArray.map((item) => item.replace(/\r/g, ''));
    const validateEmail = validateCSVData(cleanData);
    console.log(validateEmail);
    if (validateEmail && validateEmail.length) {
      validateEmail &&
        validateEmail.map((item) => {
          if (item.field === 'Member Number' || item.field === 'Contact Number') {
            setNumberFieldError(true);
          }
          if (item.field === 'Email') {
            setErrorValidation(true);
          }
        });
    } else {
      const csvData = convertToCsv(cleanData);
      setFileData(csvData);
      const formData = new FormData();
      formData.append('membercsv', csvData);
      const payloadData = { id: eventId, csvdata: formData };
      eventAction.importRegistrationRequest(payloadData);
    }
  };

  useEffect(() => {
    excelValidationError &&
      (excelValidationError.email ||
        excelValidationError.firstName ||
        excelValidationError.lastName ||
        excelValidationError.organization ||
        excelValidationError.jobTitle) &&
      successMsg('Email, FirstName, LastName, Organization And JobTitle Column can not be empty');
  }, [excelValidationError]);
  return (
    <div className="px-4 py-12">
      <div className="overflow-x-auto">
        {errorValidation && (
          <p className="flex justify-center text-red-500">
            Email should be mapped to Email field only
          </p>
        )}
        {numberFieldError && (
          <p className="flex justify-center text-red-500">
            Number Fields should be mapped to Number field only
          </p>
        )}
        <table className="table-auto w-full border">
          <thead className="text-xs font-semibold uppercase bg-gray-100">
            <tr>
              <th className="p-2">
                <div className="font-semibold text-left">Column To Import</div>
              </th>
              <th className="p-2">
                <div className="font-semibold text-left">Map Into Field</div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm divide-y divide-gray-100">
            {mapFieldData &&
              mapFieldData.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className="p-2">{item.field}</td>
                    <td className="p-2">
                      <select
                        {...register(`item_${index}`)}
                        onChange={(e) => handleOptionChange(e.target.value, index)}
                        className="input-style">
                        {item.excelOptionData.map((item: any, secondIndex: number) => {
                          return (
                            <option key={secondIndex} selected={index === secondIndex} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className="mt-3 flex justify-end">
          {!errorValidation && !numberFieldError && (
            <button
              onClick={() => handleSaveExcelData()}
              type="button"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
              Continue
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectField;
