
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { TopSellingProductModel, TopSellingProductRequestModel, MemberActivityFilterModel, WhatsNewModel, MemberActivityModel, TotalDashboardModel, SalesOrderModel, MostPopularVideoFilterModel, ThemeCustomizerModel, SalesOrderStatsModel, SalesOrderRequestModel, SalesModel, TopPayingCustomerModel, DeveloperMeetupModel, UserTimeLineModel, SalesRatioModel, MostPopularVideoModel } from 'src/models/dashboardModel';
export namespace DashboardActions {

    export type getDashboardRequestAction = Action<typeof Constants.getDashboardRequestActionType, null>
    export type getDashboardRequestSuccessAction = Action<typeof Constants.getDashboardSuccessActionType, null>;
    export type getDashboardRequestFailureAction = Action<typeof Constants.getDashboardFailureActionType, null>;

    export function getDashboardRequest(): getDashboardRequestAction {
        return ActionCreator(Constants.getDashboardRequestActionType, null);
    }
    export function getDashboardRequestSuccess(): getDashboardRequestSuccessAction {
        return ActionCreator(Constants.getDashboardSuccessActionType, null);
    }
    export function getDashboardRequestFailure(): getDashboardRequestFailureAction {
        return ActionCreator(Constants.getDashboardFailureActionType, null);
    }

    export type getSalesOrderRequestAction = Action<typeof Constants.getSalesOrderDataRequestActionType, SalesOrderRequestModel>
    export type getSalesOrderRequestSuccessAction = Action<typeof Constants.getSalesOrderDataSuccessActionType, any>;
    export type getSalesOrderRequestFailureAction = Action<typeof Constants.getSalesOrderDataFailureActionType, null>;

    export function getSalesOrderRequest(P: SalesOrderRequestModel): getSalesOrderRequestAction {
        return ActionCreator(Constants.getSalesOrderDataRequestActionType, P);
    }
    export function getSalesOrderRequestSuccess(P: any): getSalesOrderRequestSuccessAction {
        return ActionCreator(Constants.getSalesOrderDataSuccessActionType, P);
    }
    export function getSalesOrderRequestFailure(): getSalesOrderRequestFailureAction {
        return ActionCreator(Constants.getSalesOrderDataFailureActionType, null);
    }

    export type getTopPayingCustomerRequestAction = Action<typeof Constants.getTopPayingCustomerRequestActionType, null>
    export type getTopPayingCustomerRequestSuccessAction = Action<typeof Constants.getTopPayingCustomerRequestSuccessActionType, TopPayingCustomerModel[]>;
    export type getTopPayingCustomerRequestFailureAction = Action<typeof Constants.getTopPayingCustomerRequestFailureActionType, null>;

    export function getTopPayingCustomerRequest(): getTopPayingCustomerRequestAction {
        return ActionCreator(Constants.getTopPayingCustomerRequestActionType, null);
    }
    export function getTopPayingCustomerRequestSuccess(P: TopPayingCustomerModel[]): getTopPayingCustomerRequestSuccessAction {
        return ActionCreator(Constants.getTopPayingCustomerRequestSuccessActionType, P);
    }
    export function getTopPayingCustomerRequestFailure(): getTopPayingCustomerRequestFailureAction {
        return ActionCreator(Constants.getTopPayingCustomerRequestFailureActionType, null);
    }

    export type getDeveloperMeetupRequestAction = Action<typeof Constants.getDeveloperMeetupRequestActionType, null>
    export type getDeveloperMeetupRequestSuccessAction = Action<typeof Constants.getDeveloperMeetupRequestSuccessActionType, DeveloperMeetupModel[]>;
    export type getDeveloperMeetupRequestFailureAction = Action<typeof Constants.getDeveloperMeetupRequestFailureActionType, null>;

    export function getDeveloperMeetupRequest(): getDeveloperMeetupRequestAction {
        return ActionCreator(Constants.getDeveloperMeetupRequestActionType, null);
    }
    export function getDeveloperMeetupRequestSuccess(P: DeveloperMeetupModel[]): getDeveloperMeetupRequestSuccessAction {
        return ActionCreator(Constants.getDeveloperMeetupRequestSuccessActionType, P);
    }
    export function getDeveloperMeetupRequestFailure(): getDeveloperMeetupRequestFailureAction {
        return ActionCreator(Constants.getDeveloperMeetupRequestFailureActionType, null);
    }

    export type getUserTimeLineRequestAction = Action<typeof Constants.getUserTimeLineRequestActionType, null>
    export type getUserTimeLineRequestSuccessAction = Action<typeof Constants.getUserTimeLineRequestSuccessActionType, UserTimeLineModel[]>;
    export type getUserTimeLineRequestFailureAction = Action<typeof Constants.getUserTimeLineRequestFailureActionType, null>;

    export function getUserTimeLineRequest(): getUserTimeLineRequestAction {
        return ActionCreator(Constants.getUserTimeLineRequestActionType, null);
    }
    export function getUserTimeLineRequestSuccess(P: UserTimeLineModel[]): getUserTimeLineRequestSuccessAction {
        return ActionCreator(Constants.getUserTimeLineRequestSuccessActionType, P);
    }
    export function getUserTimeLineRequestFailure(): getUserTimeLineRequestFailureAction {
        return ActionCreator(Constants.getUserTimeLineRequestFailureActionType, null);
    }

    export type getSalesRatioRequestAction = Action<typeof Constants.getSalesRatioRequestActionType, null>
    export type getSalesRatioRequestSuccessAction = Action<typeof Constants.getSalesRatioRequestSuccessActionType, SalesRatioModel[]>;
    export type getSalesRatioRequestFailureAction = Action<typeof Constants.getSalesRatioRequestFailureActionType, null>;

    export function getSalesRatioRequest(): getSalesRatioRequestAction {
        return ActionCreator(Constants.getSalesRatioRequestActionType, null);
    }
    export function getSalesRatioRequestSuccess(P: SalesRatioModel[]): getSalesRatioRequestSuccessAction {
        return ActionCreator(Constants.getSalesRatioRequestSuccessActionType, P);
    }
    export function getSalesRatioRequestFailure(): getSalesRatioRequestFailureAction {
        return ActionCreator(Constants.getSalesRatioRequestFailureActionType, null);
    }

    export type getMostPopularVideoRequestAction = Action<typeof Constants.getMostPopularVideoRequestActionType, MostPopularVideoFilterModel>
    export type getMostPopularVideoRequestSuccessAction = Action<typeof Constants.getMostPopularVideoRequestSuccessActionType, MostPopularVideoModel>;
    export type getMostPopularVideoRequestFailureAction = Action<typeof Constants.getMostPopularVideoRequestFailureActionType, null>;

    export function getMostPopularVideoRequest(P: MostPopularVideoFilterModel): getMostPopularVideoRequestAction {
        return ActionCreator(Constants.getMostPopularVideoRequestActionType, P);
    }
    export function getMostPopularVideoRequestSuccess(P: MostPopularVideoModel): getMostPopularVideoRequestSuccessAction {
        return ActionCreator(Constants.getMostPopularVideoRequestSuccessActionType, P);
    }
    export function getMostPopularVideoRequestFailure(): getMostPopularVideoRequestFailureAction {
        return ActionCreator(Constants.getMostPopularVideoRequestFailureActionType, null);
    }

    export type getMemberActivityRequestAction = Action<typeof Constants.getMemberActivityRequestActionType, MemberActivityFilterModel>
    export type getMemberActivityRequestSuccessAction = Action<typeof Constants.getMemberActivityRequestSuccessActionType, MemberActivityModel[]>;
    export type getMemberActivityRequestFailureAction = Action<typeof Constants.getMemberActivityRequestFailureActionType, null>;

    export function getMemberActivityRequest(P: MemberActivityFilterModel): getMemberActivityRequestAction {
        return ActionCreator(Constants.getMemberActivityRequestActionType, P);
    }
    export function getMemberActivityRequestSuccess(P: MemberActivityModel[]): getMemberActivityRequestSuccessAction {
        return ActionCreator(Constants.getMemberActivityRequestSuccessActionType, P);
    }
    export function getMemberActivityRequestFailure(): getMemberActivityRequestFailureAction {
        return ActionCreator(Constants.getMemberActivityRequestFailureActionType, null);
    }

    export type getWhatsNewRequestAction = Action<typeof Constants.getWhatsNewRequestActionType, null>
    export type getWhatsNewRequestSuccessAction = Action<typeof Constants.getWhatsNewRequestSuccessActionType, WhatsNewModel[]>;
    export type getWhatsNewRequestFailureAction = Action<typeof Constants.getWhatsNewRequestFailureActionType, null>;

    export function getWhatsNewRequest(): getWhatsNewRequestAction {
        return ActionCreator(Constants.getWhatsNewRequestActionType, null);
    }
    export function getWhatsNewRequestSuccess(P: WhatsNewModel[]): getWhatsNewRequestSuccessAction {
        return ActionCreator(Constants.getWhatsNewRequestSuccessActionType, P);
    }
    export function getWhatsNewRequestFailure(): getWhatsNewRequestFailureAction {
        return ActionCreator(Constants.getWhatsNewRequestFailureActionType, null);
    }

    export type getSalesRequestAction = Action<typeof Constants.getSalesRequestActionType, null>
    export type getSalesRequestSuccessAction = Action<typeof Constants.getSalesRequestSuccessActionType, SalesModel[]>;
    export type getSalesRequestFailureAction = Action<typeof Constants.getSalesRequestFailureActionType, null>;

    export function getSalesRequest(): getSalesRequestAction {
        return ActionCreator(Constants.getSalesRequestActionType, null);
    }
    export function getSalesRequestSuccess(P: SalesModel[]): getSalesRequestSuccessAction {
        return ActionCreator(Constants.getSalesRequestSuccessActionType, P);
    }
    export function getSalesRequestFailure(): getSalesRequestFailureAction {
        return ActionCreator(Constants.getSalesRequestFailureActionType, null);
    }

    export type getSearchedMostPopularVideoRequestAction = Action<typeof Constants.getSearchedMostPopularVideoRequestActionType, any>
    export type getSearchedMostPopularVideoRequestSuccessAction = Action<typeof Constants.getSearchedMostPopularVideoRequestSuccessActionType, MostPopularVideoModel>;
    export type getSearchedMostPopularVideoRequestFailureAction = Action<typeof Constants.getSearchedMostPopularVideoRequestFailureActionType, null>;

    export function getSearchedMostPopularVideoRequest(P: any): getSearchedMostPopularVideoRequestAction {
        return ActionCreator(Constants.getSearchedMostPopularVideoRequestActionType, P);
    }
    export function getSearchedMostPopularVideoRequestSuccess(P: MostPopularVideoModel): getSearchedMostPopularVideoRequestSuccessAction {
        return ActionCreator(Constants.getSearchedMostPopularVideoRequestSuccessActionType, P);
    }
    export function getSearchedMostPopularVideoRequestFailure(): getSearchedMostPopularVideoRequestFailureAction {
        return ActionCreator(Constants.getSearchedMostPopularVideoRequestFailureActionType, null);
    }

    export type getSearchMemberActivityRequestAction = Action<typeof Constants.getSearchMemberActivityRequestActionType, any>
    export type getSearchMemberActivityRequestSuccessAction = Action<typeof Constants.getSearchMemberActivityRequestSuccessActionType, MemberActivityModel[]>;
    export type getSearchMemberActivityRequestFailureAction = Action<typeof Constants.getSearchMemberActivityRequestFailureActionType, null>;

    export function getSearchMemberActivityRequest(P: any): getSearchMemberActivityRequestAction {
        return ActionCreator(Constants.getSearchMemberActivityRequestActionType, null);
    }
    export function getSearchMemberActivityRequestSuccess(P: MemberActivityModel[]): getSearchMemberActivityRequestSuccessAction {
        return ActionCreator(Constants.getSearchMemberActivityRequestSuccessActionType, P);
    }
    export function getSearchMemberActivityRequestFailure(): getSearchMemberActivityRequestFailureAction {
        return ActionCreator(Constants.getSearchMemberActivityRequestFailureActionType, null);
    }

    export type getTotalDashboardDataRequestAction = Action<typeof Constants.getTotalDashboardDataRequestActionType, null>
    export type getTotalDashboardDataRequestSuccessAction = Action<typeof Constants.getTotalDashboardDataRequestSuccessActionType, TotalDashboardModel>;
    export type getTotalDashboardDataRequestFailureAction = Action<typeof Constants.getTotalDashboardDataRequestFailureActionType, null>;

    export function getTotalDashboardDataRequest(): getTotalDashboardDataRequestAction {
        return ActionCreator(Constants.getTotalDashboardDataRequestActionType, null);
    }
    export function getTotalDashboardDataRequestSuccess(P: TotalDashboardModel): getTotalDashboardDataRequestSuccessAction {
        return ActionCreator(Constants.getTotalDashboardDataRequestSuccessActionType, P);
    }
    export function getTotalDashboardDataRequestFailure(): getTotalDashboardDataRequestFailureAction {
        return ActionCreator(Constants.getTotalDashboardDataRequestFailureActionType, null);
    }

    export type saveCustomizeThemeRequestAction = Action<typeof Constants.saveCustomizeThemeRequestActionType, ThemeCustomizerModel>
    export type saveCustomizeThemeRequestSuccessAction = Action<typeof Constants.saveCustomizeThemeRequestSuccessActionType, null>;
    export type saveCustomizeThemeRequestFailureAction = Action<typeof Constants.saveCustomizeThemeRequestFailureActionType, null>;

    export function saveCustomizeThemeRequest(P: ThemeCustomizerModel): saveCustomizeThemeRequestAction {
        return ActionCreator(Constants.saveCustomizeThemeRequestActionType, P);
    }
    export function saveCustomizeThemeRequestSuccess(): saveCustomizeThemeRequestSuccessAction {
        return ActionCreator(Constants.saveCustomizeThemeRequestSuccessActionType, null);
    }
    export function saveCustomizeThemeRequestFailure(): saveCustomizeThemeRequestFailureAction {
        return ActionCreator(Constants.saveCustomizeThemeRequestFailureActionType, null);
    }

    export type getSalesOrderStatsDataRequestAction = Action<typeof Constants.getSalesOrderStatsDataRequestActionType, string>
    export type getSalesOrderStatsDataRequestSuccessAction = Action<typeof Constants.getSalesOrderStatsDataRequestSuccessActionType, SalesOrderStatsModel[]>;
    export type getSalesOrderStatsDataRequestFailureAction = Action<typeof Constants.getSalesOrderStatsDataRequestFailureActionType, null>;

    export function getSalesOrderStatsRequest(P: string): getSalesOrderStatsDataRequestAction {
        return ActionCreator(Constants.getSalesOrderStatsDataRequestActionType, P);
    }
    export function getSalesOrderStatsRequestSuccess(P: SalesOrderStatsModel[]): getSalesOrderStatsDataRequestSuccessAction {
        return ActionCreator(Constants.getSalesOrderStatsDataRequestSuccessActionType, P);
    }
    export function getSalesOrderStatsRequestFailure(): getSalesOrderStatsDataRequestFailureAction {
        return ActionCreator(Constants.getSalesOrderStatsDataRequestFailureActionType, null);
    }

    export type getPreviousYearSalesOrderDataRequestAction = Action<typeof Constants.getPreviousYearSalesOrderDataRequestActionType, SalesOrderRequestModel>
    export type getPreviousYearSalesOrderDataRequestSuccessAction = Action<typeof Constants.getPreviousYearSalesOrderDataRequestSuccessActionType, SalesOrderModel[]>;
    export type getPreviousYearSalesOrderDataRequestFailureAction = Action<typeof Constants.getPreviousYearSalesOrderDataRequestFailureActionType, null>;

    export function getPreviousYearSalesOrderRequest(P: SalesOrderRequestModel): getPreviousYearSalesOrderDataRequestAction {
        return ActionCreator(Constants.getPreviousYearSalesOrderDataRequestActionType, P);
    }
    export function getPreviousYearSalesOrderRequestSuccess(P: SalesOrderModel[]): getPreviousYearSalesOrderDataRequestSuccessAction {
        return ActionCreator(Constants.getPreviousYearSalesOrderDataRequestSuccessActionType, P);
    }
    export function getPreviousYearSalesOrderRequestFailure(): getPreviousYearSalesOrderDataRequestFailureAction {
        return ActionCreator(Constants.getPreviousYearSalesOrderDataRequestFailureActionType, null);
    }

    export type getTopSellingProductsRequestAction = Action<typeof Constants.getTopSellingProductsRequestActionType, TopSellingProductRequestModel>
    export type getTopSellingProductsRequestSuccessAction = Action<typeof Constants.getTopSellingProductsRequestSuccessActionType, TopSellingProductModel[]>;
    export type getTopSellingProductsRequestFailureAction = Action<typeof Constants.getTopSellingProductsRequestFailureActionType, null>;

    export function getTopSellingProductsRequest(P: TopSellingProductRequestModel): getTopSellingProductsRequestAction {
        return ActionCreator(Constants.getTopSellingProductsRequestActionType, P);
    }
    export function getTopSellingProductsRequestSuccess(P: TopSellingProductModel[]): getTopSellingProductsRequestSuccessAction {
        return ActionCreator(Constants.getTopSellingProductsRequestSuccessActionType, P);
    }
    export function getTopSellingProductsRequestFailure(): getTopSellingProductsRequestFailureAction {
        return ActionCreator(Constants.getTopSellingProductsRequestFailureActionType, null);
    }
}

export type DashboardActions = Omit<typeof DashboardActions, 'Type'>;
export const useDashboardActions = (dispatch: Dispatch) => {
    const { ...actions } = DashboardActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as DashboardActions;
};