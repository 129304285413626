import React, { useEffect } from 'react';
import { useUserActions } from 'src/store/users/actions';
import { RootState } from 'src/store/reducers/state';
import { defaultDateFormatter, formatTimeHHMMSS } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import { useDispatch, useSelector } from 'react-redux';
interface IProps {
  memberId: string;
}
const UserActivityTimeline: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);

  const { userActivityTimeLine, activityPaginationMeta } = useSelector((state: RootState) => {
    return {
      userActivityTimeLine: state.users.userActivityTimeLine,
      activityPaginationMeta: state.users.activityPaginationMeta
    };
  });
  const handlePageChange = (pageNumber: number) => {
    const data = { pageNumber, memberId, pageSize: 10 };
    userAction.getUserActivityTimeLineRequest(data);
  };

  return (
    <div className="w-full mx-auto bg-white shadow-card rounded-lg relative my-6">
      <div className="px-5 py-3">
        <h2 className="font-semibold mb-4">User Activity Timeline</h2>
      </div>
      <div className="px-4 pb-2">
        <ul className="activity-info text-xs overflow-hidden">
          {userActivityTimeLine && userActivityTimeLine.length ? (
            <>
              {userActivityTimeLine &&
                userActivityTimeLine.map((item, index) => {
                  return (
                    <li key={index} className="grid">
                      <div className="flex justify-between">
                        <div>
                          <p className="mb-1 font-semibold">{item.activity} </p>
                          <p>{item.activityType !== 'Watch' && item.activityType}</p>
                          <p>
                            {item.activityType === 'Watch' &&
                              `Duration: ${formatTimeHHMMSS(item.timeWatched)}`}
                          </p>
                        </div>
                        <span>
                          {item.activityDate ? defaultDateFormatter(item.activityDate) : ''}
                        </span>
                      </div>
                    </li>
                  );
                })}
            </>
          ) : (
            <>
              <div className="text-center">No data Found</div>
            </>
          )}
        </ul>
      </div>
      {!!activityPaginationMeta &&
        !!Object.keys(activityPaginationMeta).length &&
        userActivityTimeLine && (
          <Pagination
            currentPage={activityPaginationMeta.pageNumber}
            totalCount={activityPaginationMeta.totalCount}
            pageSize={activityPaginationMeta.pageSize}
            onPageChange={(page: any) => handlePageChange(page)}
          />
        )}
    </div>
  );
};

export default UserActivityTimeline;
