import React, { useEffect, useState } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { successMsg } from 'src/components/utilities/utils';
import UploadChapterModal from './UploadChapterModal';
import UploadCaptionsModal from './UploadCaptionsModal';
import Loader from 'src/components/loader';
import { errorMsg } from 'src/components/utilities/utils';
import { saveAs } from 'file-saver';
interface IProps {
  videoId: string;
  isSavedLoading: boolean;
}

const Accessibility: React.FC<IProps> = ({ videoId, isSavedLoading }) => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [ondemandCaptionUrl, setOndemandCaptionUrl] = useState<string>('');
  const [uploadChapterUrl, setUploadChapterUrl] = useState<string>('');
  const [uploadChapterError, setUploadChapterError] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch
  } = useForm();
  const { accessibility, isGetAccessibilityLoading, isAccessibilityUpdated, singleVideo } =
    useSelector((state: RootState) => {
      return {
        accessibility: state.videos.accessibility,
        isGetAccessibilityLoading: state.videos.isGetAccessibilityLoading,
        isAccessibilityUpdated: state.videos.isAccessibilityUpdated,
        singleVideo: state.videos.singleVideo
      };
    });

  const onSubmit = (data: any) => {
    if (!uploadChapterError) {
      const payloadData = {
        id: data.id,
        updateData: [
          { op: 'replace', path: 'captionUrl', value: data.captionUrl ? data.captionUrl : '' },
          { op: 'replace', path: 'isCaptionEnabled', value: data.isCaptionEnabled },
          { op: 'replace', path: 'onDemandCaptionURL', value: ondemandCaptionUrl },
          { op: 'replace', path: 'webvttChaptersUrl', value: uploadChapterUrl },
          { op: 'replace', path: 'onDemandCaptionEnable', value: data.onDemandCaptionEnable },
          { op: 'replace', path: 'webVTTChaptersEnabled', value: data.webVTTChaptersEnabled }
        ]
      };
      videoLibraryAction.updateAccessibilityRequest(payloadData);
    } else {
      errorMsg('No Captions file exists.');
    }
  };
  useEffect(() => {
    if (accessibility) {
      const finalData = {
        ...accessibility,
        webVTTChaptersEnabled: accessibility.chaptersEnabled,
        isCaptionEnabled: accessibility.liveCaption,
        captionUrl: accessibility.liveCaptionUrl
      };
      reset(finalData);
      setOndemandCaptionUrl(accessibility.onDemandCaptionURL);
      setUploadChapterUrl(accessibility.chaptersUrl);
    }
  }, [accessibility]);

  useEffect(() => {
    if (isAccessibilityUpdated) {
      videoLibraryAction.getAccessibilityRequest(videoId);
    }
  }, [isAccessibilityUpdated]);

  const isCaptionEnabled = watch('isCaptionEnabled');
  const chapterUrl = watch('onDemandCaptionEnable');
  const webVTTChaptersEnabled = watch('webVTTChaptersEnabled');

  useEffect(() => {
    if (
      (uploadChapterUrl === undefined || uploadChapterUrl === null) &&
      watch('webVTTChaptersEnabled')
    ) {
      setUploadChapterError(true);
    } else {
      setUploadChapterError(false);
    }
  }, [uploadChapterUrl, webVTTChaptersEnabled, accessibility]);

  return (
    <>
      <div
        className="tab-pane fade"
        id="tabs-accessibilityView"
        role="tabpanel"
        aria-labelledby="tabs-accessibility-view">
        <form onSubmit={handleSubmit(onSubmit)}>
          {(isSavedLoading || isGetAccessibilityLoading) && <Loader />}
          <div className="p-5">
            <div className="flex justify-between mb-5">
              <div className="font-bold text-lg">{singleVideo?.title}</div>
              <div className="flex items-center justify-end">
                <button type="button" className="btn-primary w-auto mt-0 mr-3 ml-0 discard-btn">
                  Cancel
                </button>
                <button type="submit" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                  Save
                </button>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-2/12">
                {' '}
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Live Captions
                </label>
              </div>
              <div className="lg:w-10/12 sm:w-auto">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="isCaptionEnabled"
                        {...register('isCaptionEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            {isCaptionEnabled && (
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label
                    className="form-check-label inline-block font-semibold switch-label mr-5"
                    htmlFor="Credit">
                    Live Caption URL
                  </label>
                </div>
                <div className="lg:w-10/12 sm:w-auto">
                  <div>
                    <input
                      className="input-style mr-5"
                      placeholder=""
                      type="text"
                      id="captionUrl"
                      {...register('captionUrl')}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="hr-line-dashed"></div>
            <div className="md:flex items-center my-8">
              <div className="md:w-2/12">
                {' '}
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Ondemand Captions
                </label>
              </div>
              <div className="lg:w-10/12 sm:w-auto">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="onDemandCaptionEnable"
                        {...register('onDemandCaptionEnable')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            {chapterUrl && (
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label
                    className="form-check-label inline-block font-semibold switch-label mr-5"
                    htmlFor="Credit">
                    Ondemand Caption File
                  </label>
                </div>
                <div className="lg:w-10/12 sm:w-auto flex">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#UploadCaptionsModal"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Upload Captions
                  </button>
                  {accessibility && accessibility.onDemandCaptionURL && (
                    <button
                      onClick={() => saveAs(accessibility.onDemandCaptionURL)}
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Download Captions
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="hr-line-dashed"></div>
            <div className="md:flex items-center my-8">
              <div className="md:w-2/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Video Chapters
                </label>
              </div>
              <div className="lg:w-10/12 sm:w-auto">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="webVTTChaptersEnabled"
                        {...register('webVTTChaptersEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr-line-dashed"></div>
            {webVTTChaptersEnabled && (
              <div className="md:flex items-center my-8">
                <div className="md:w-2/12">
                  <label
                    className="form-check-label inline-block font-semibold switch-label mr-5"
                    htmlFor="Credit">
                    Chapters File
                  </label>
                </div>
                <div className="lg:w-10/12 sm:w-auto flex">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#UploadChapterModal"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Upload Chapters
                  </button>
                  {accessibility && accessibility.chaptersUrl && (
                    <button
                      onClick={() => saveAs(accessibility.chaptersUrl)}
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Download Chapters
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <UploadCaptionsModal
        videoId={videoId}
        setOndemandCaptionUrl={setOndemandCaptionUrl}
        ondemandCaptionUrl={ondemandCaptionUrl}
      />
      <UploadChapterModal
        videoId={videoId}
        setUploadChapterUrl={setUploadChapterUrl}
        uploadChapterUrl={uploadChapterUrl}
      />
    </>
  );
};

export default Accessibility;
