import { createAction } from "redux-actions"
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { UpdateAudienceProfilesModel, AudienceProfiles, CategoryList, UpdateModuleModel, ModuleModel, CategoryModel, UpdateCategoryModel, TagModel, UpdateSpotlightRowModel, ContentTypeModel, UpdateContentTypeModel, UpdateTagModel, CollectionModel, CollectionVideosModel, CollectionVideoModel, UpdateCollectionModel } from "src/models/categoryModel";
import { Action, ActionCreator } from 'src/models/actions';
import { PagingDataModel } from 'src/models/paginationModel';
import { SearchModel } from "src/models/commonModels";
export namespace CategoryActions {

    export type getCategoryRequestAction = Action<typeof Constants.getCategoryRequestActionType, SearchModel>
    export type getCategoryRequestSuccessAction = Action<typeof Constants.getCategorySuccessActionType, PagingDataModel<CategoryModel>>;
    export type getCategoryRequestFailureAction = Action<typeof Constants.getCategoryFailureActionType, null>;

    export function getCategoryRequest(P: SearchModel): getCategoryRequestAction {
        return ActionCreator(Constants.getCategoryRequestActionType, P);
    }
    export function getCategoryRequestSuccess(P: PagingDataModel<CategoryModel>): getCategoryRequestSuccessAction {
        return ActionCreator(Constants.getCategorySuccessActionType, P);
    }
    export function getCategoryRequestFailure(): getCategoryRequestFailureAction {
        return ActionCreator(Constants.getCategoryFailureActionType, null);
    }
    export type getAllCategoryRequestAction = Action<typeof Constants.getAllCategoryRequestActionType, SearchModel>
    export type getAllCategoryRequestSuccessAction = Action<typeof Constants.getAllCategorySuccessActionType, CategoryModel[]>;
    export type getAllCategoryRequestFailureAction = Action<typeof Constants.getAllCategoryFailureActionType, null>;

    export function getAllCategoryRequest(P: SearchModel): getAllCategoryRequestAction {
        return ActionCreator(Constants.getAllCategoryRequestActionType, P);
    }
    export function getAllCategoryRequestSuccess(P: CategoryModel[]): getAllCategoryRequestSuccessAction {
        return ActionCreator(Constants.getAllCategorySuccessActionType, P);
    }
    export function getAllCategoryRequestFailure(): getAllCategoryRequestFailureAction {
        return ActionCreator(Constants.getAllCategoryFailureActionType, null);
    }

    export type deleteCategoryRequestAction = Action<typeof Constants.deleteCategoryRequestActionType, string>
    export type deleteCategoryRequestSuccessAction = Action<typeof Constants.deleteCategorySuccessActionType, string>;
    export type deleteCategoryRequestFailureAction = Action<typeof Constants.deleteCategoryFailureActionType, null>;

    export function deleteCategoryRequest(P: string): deleteCategoryRequestAction {
        return ActionCreator(Constants.deleteCategoryRequestActionType, P);
    }
    export function deleteCategoryRequestSuccess(P: string): deleteCategoryRequestSuccessAction {
        return ActionCreator(Constants.deleteCategorySuccessActionType, P);
    }
    export function deleteCategoryRequestFailure(): deleteCategoryRequestFailureAction {
        return ActionCreator(Constants.deleteCategoryFailureActionType, null);
    }

    export type saveCategoryRequestAction = Action<typeof Constants.saveCategoryRequestActionType, CategoryModel>
    export type saveCategoryRequestSuccessAction = Action<typeof Constants.saveCategorySuccessActionType, null>;
    export type saveCategoryRequestFailureAction = Action<typeof Constants.saveCategoryFailureActionType, null>;

    export function saveCategoryRequest(P: CategoryModel): saveCategoryRequestAction {
        return ActionCreator(Constants.saveCategoryRequestActionType, P);
    }
    export function saveCategoryRequestSuccess(): saveCategoryRequestSuccessAction {
        return ActionCreator(Constants.saveCategorySuccessActionType, null);
    }
    export function saveCategoryRequestFailure(): saveCategoryRequestFailureAction {
        return ActionCreator(Constants.saveCategoryFailureActionType, null);
    }

    export type updateCategoryRequestAction = Action<typeof Constants.updateCategoryRequestActionType, UpdateCategoryModel>
    export type updateCategoryRequestSuccessAction = Action<typeof Constants.updateCategorySuccessActionType, null>;
    export type updateCategoryRequestFailureAction = Action<typeof Constants.updateCategoryFailureActionType, null>;

    export function updateCategoryRequest(P: UpdateCategoryModel): updateCategoryRequestAction {
        return ActionCreator(Constants.updateCategoryRequestActionType, P);
    }
    export function updateCategoryRequestSuccess(): updateCategoryRequestSuccessAction {
        return ActionCreator(Constants.updateCategorySuccessActionType, null);
    }
    export function updateCategoryRequestFailure(): updateCategoryRequestFailureAction {
        return ActionCreator(Constants.updateCategoryFailureActionType, null);
    }

    export type updateSpotLightRowFlagRequestAction = Action<typeof Constants.updateSpotLightRowFlagRequestActionType, UpdateSpotlightRowModel>
    export type updateSpotLightRowFlagRequestSuccessAction = Action<typeof Constants.updateSpotLightRowFlagRequestSuccessActionType, null>;
    export type updateSpotLightRowFlagRequestFailureAction = Action<typeof Constants.updateSpotLightRowFlagRequestFailureActionType, null>;

    export function updateSpotLightRowFlagRequest(P: UpdateSpotlightRowModel): updateSpotLightRowFlagRequestAction {
        return ActionCreator(Constants.updateSpotLightRowFlagRequestActionType, P);
    }
    export function updateSpotLightRowFlagRequestSuccess(): updateSpotLightRowFlagRequestSuccessAction {
        return ActionCreator(Constants.updateSpotLightRowFlagRequestSuccessActionType, null);
    }
    export function updateSpotLightRowFlagRequestFailure(): updateSpotLightRowFlagRequestFailureAction {
        return ActionCreator(Constants.updateSpotLightRowFlagRequestFailureActionType, null);
    }

    export type getSingleCategoryRequestAction = Action<typeof Constants.getSingleCategoryRequestActionType, string>
    export type getSingleCategoryRequestSuccessAction = Action<typeof Constants.getSingleCategorySuccessActionType, CategoryModel>;
    export type getSingleCategoryRequestFailureAction = Action<typeof Constants.getSingleCategoryFailureActionType, null>;

    export function getSingleCategoryRequest(P: string): getSingleCategoryRequestAction {
        return ActionCreator(Constants.getSingleCategoryRequestActionType, P);
    }
    export function getSingleCategoryRequestSuccess(P: CategoryModel): getSingleCategoryRequestSuccessAction {
        return ActionCreator(Constants.getSingleCategorySuccessActionType, P);
    }
    export function getSingleCategoryRequestFailure(): getSingleCategoryRequestFailureAction {
        return ActionCreator(Constants.getSingleCategoryFailureActionType, null);
    }

    export type getVideoTagRequestAction = Action<typeof Constants.getVideoTagRequestActionType, SearchModel>
    export type getVideoTagRequestSuccessAction = Action<typeof Constants.getVideoTagSuccessActionType, PagingDataModel<TagModel>>;
    export type getVideoTagRequestFailureAction = Action<typeof Constants.getVideoTagFailureActionType, null>;

    export function getVideoTagRequest(P: SearchModel): getVideoTagRequestAction {
        return ActionCreator(Constants.getVideoTagRequestActionType, P);
    }
    export function getVideoTagRequestSuccess(P: PagingDataModel<TagModel>): getVideoTagRequestSuccessAction {
        return ActionCreator(Constants.getVideoTagSuccessActionType, P);
    }
    export function getVideoTagRequestFailure(): getVideoTagRequestFailureAction {
        return ActionCreator(Constants.getVideoTagFailureActionType, null);
    }

    export type saveVideoTagRequestAction = Action<typeof Constants.saveVideoTagRequestActionType, TagModel>
    export type saveVideoTagRequestSuccessAction = Action<typeof Constants.saveVideoTagRequestSuccessActionType, string>;
    export type saveVideoTagRequestFailureAction = Action<typeof Constants.saveVideoTagRequestFailureActionType, null>;

    export function saveVideoTagRequest(P: TagModel): saveVideoTagRequestAction {
        return ActionCreator(Constants.saveVideoTagRequestActionType, P);
    }
    export function saveVideoTagRequestSuccess(P: string): saveVideoTagRequestSuccessAction {
        return ActionCreator(Constants.saveVideoTagRequestSuccessActionType, P);
    }
    export function saveVideoTagRequestFailure(): saveVideoTagRequestFailureAction {
        return ActionCreator(Constants.saveVideoTagRequestFailureActionType, null);
    }

    export type updateVideoTagRequestAction = Action<typeof Constants.updateVideoTagRequestActionType, UpdateTagModel>
    export type updateVideoTagRequestSuccessAction = Action<typeof Constants.updateVideoTagRequestSuccessActionType, null>;
    export type updateVideoTagRequestFailureAction = Action<typeof Constants.updateVideoTagRequestFailureActionType, null>;

    export function updateVideoTagRequest(P: UpdateTagModel): updateVideoTagRequestAction {
        return ActionCreator(Constants.updateVideoTagRequestActionType, P);
    }
    export function updateVideoTagRequestSuccess(): updateVideoTagRequestSuccessAction {
        return ActionCreator(Constants.updateVideoTagRequestSuccessActionType, null);
    }
    export function updateVideoTagRequestFailure(): updateVideoTagRequestFailureAction {
        return ActionCreator(Constants.updateVideoTagRequestFailureActionType, null);
    }

    export type deleteVideoTagRequestAction = Action<typeof Constants.deleteVideoTagRequestActionType, string>
    export type deleteVideoTagRequestSuccessAction = Action<typeof Constants.deleteVideoTagRequestSuccessActionType, string>;
    export type deleteVideoTagRequestFailureAction = Action<typeof Constants.deleteVideoTagRequestFailureActionType, null>;

    export function deleteVideoTagRequest(P: string): deleteVideoTagRequestAction {
        return ActionCreator(Constants.deleteVideoTagRequestActionType, P);
    }
    export function deleteVideoTagRequestSuccess(P: string): deleteVideoTagRequestSuccessAction {
        return ActionCreator(Constants.deleteVideoTagRequestSuccessActionType, P);
    }
    export function deleteVideoTagRequestFailure(): deleteVideoTagRequestFailureAction {
        return ActionCreator(Constants.deleteVideoTagRequestFailureActionType, null);
    }
    export type getSingleVideoTagRequestAction = Action<typeof Constants.getSingleVideoTagRequestActionType, string>
    export type getSingleVideoTagRequestSuccessAction = Action<typeof Constants.getSingleVideoTagSuccessActionType, TagModel>;
    export type getSingleVideoTagRequestFailureAction = Action<typeof Constants.getSingleVideoTagFailureActionType, null>;

    export function getSingleVideoTagRequest(P: string): getSingleVideoTagRequestAction {
        return ActionCreator(Constants.getSingleVideoTagRequestActionType, P);
    }
    export function getSingleVideoTagRequestSuccess(P: TagModel): getSingleVideoTagRequestSuccessAction {
        return ActionCreator(Constants.getSingleVideoTagSuccessActionType, P);
    }
    export function getSingleVideoTagRequestFailure(): getSingleVideoTagRequestFailureAction {
        return ActionCreator(Constants.getSingleVideoTagFailureActionType, null);
    }

    export type getContentTypeRequestAction = Action<typeof Constants.getContentTypeRequestActionType, SearchModel>
    export type getContentTypeRequestSuccessAction = Action<typeof Constants.getContentTypeRequestSuccessActionType, PagingDataModel<ContentTypeModel>>;
    export type getContentTypeRequestFailureAction = Action<typeof Constants.getContentTypeRequestFailureActionType, null>;

    export function getContentTypeRequest(P: SearchModel): getContentTypeRequestAction {
        return ActionCreator(Constants.getContentTypeRequestActionType, P);
    }
    export function getContentTypeRequestSuccess(P: PagingDataModel<ContentTypeModel>): getContentTypeRequestSuccessAction {
        return ActionCreator(Constants.getContentTypeRequestSuccessActionType, P);
    }
    export function getContentTypeRequestFailure(): getContentTypeRequestFailureAction {
        return ActionCreator(Constants.getContentTypeRequestFailureActionType, null);
    }

    export type addContentTypeRequestAction = Action<typeof Constants.addContentTypeRequestActionType, ContentTypeModel>
    export type addContentTypeRequestSuccessAction = Action<typeof Constants.addContentTypeRequestSuccessActionType, string>;
    export type addContentTypeRequestFailureAction = Action<typeof Constants.addContentTypeRequestFailureActionType, null>;

    export function addContentTypeRequest(P: ContentTypeModel): addContentTypeRequestAction {
        return ActionCreator(Constants.addContentTypeRequestActionType, P);
    }
    export function addContentTypeRequestSuccess(P: string): addContentTypeRequestSuccessAction {
        return ActionCreator(Constants.addContentTypeRequestSuccessActionType, P);
    }
    export function addContentTypeRequestFailure(): addContentTypeRequestFailureAction {
        return ActionCreator(Constants.addContentTypeRequestFailureActionType, null);
    }

    export type updateContentTypeRequestAction = Action<typeof Constants.updateContentTypeRequestActionType, UpdateContentTypeModel>
    export type updateContentTypeRequestSuccessAction = Action<typeof Constants.updateContentTypeRequestSuccessActionType, null>;
    export type updateContentTypeRequestFailureAction = Action<typeof Constants.updateContentTypeRequestFailureActionType, null>;

    export function updateContentTypeRequest(P: UpdateContentTypeModel): updateContentTypeRequestAction {
        return ActionCreator(Constants.updateContentTypeRequestActionType, P);
    }
    export function updateContentTypeRequestSuccess(): updateContentTypeRequestSuccessAction {
        return ActionCreator(Constants.updateContentTypeRequestSuccessActionType, null);
    }
    export function updateContentTypeRequestFailure(): updateContentTypeRequestFailureAction {
        return ActionCreator(Constants.updateContentTypeRequestFailureActionType, null);
    }

    export type deleteContentTypeRequestAction = Action<typeof Constants.deleteContentTypeRequestActionType, string>
    export type deleteContentTypeRequestSuccessAction = Action<typeof Constants.deleteContentTypeRequestSuccessActionType, string>;
    export type deleteContentTypeRequestFailureAction = Action<typeof Constants.deleteContentTypeRequestFailureActionType, null>;

    export function deleteContentTypeRequest(P: string): deleteContentTypeRequestAction {
        return ActionCreator(Constants.deleteContentTypeRequestActionType, P);
    }
    export function deleteContentTypeRequestSuccess(P: string): deleteContentTypeRequestSuccessAction {
        return ActionCreator(Constants.deleteContentTypeRequestSuccessActionType, P);
    }
    export function deleteContentTypeRequestFailure(): deleteContentTypeRequestFailureAction {
        return ActionCreator(Constants.deleteContentTypeRequestFailureActionType, null);
    }

    export type getSingleContentTypeRequestAction = Action<typeof Constants.getSingleContentTypeRequestActionType, string>
    export type getSingleContentTypeRequestSuccessAction = Action<typeof Constants.getSingleContentTypeRequestSuccessActionType, ContentTypeModel>;
    export type getSingleContentTypeRequestFailureAction = Action<typeof Constants.getSingleContentTypeRequestFailureActionType, null>;

    export function getSingleContentTypeRequest(P: string): getSingleContentTypeRequestAction {
        return ActionCreator(Constants.getSingleContentTypeRequestActionType, P);
    }
    export function getSingleContentTypeRequestSuccess(P: ContentTypeModel): getSingleContentTypeRequestSuccessAction {
        return ActionCreator(Constants.getSingleContentTypeRequestSuccessActionType, P);
    }
    export function getSingleContentTypeRequestFailure(): getSingleContentTypeRequestFailureAction {
        return ActionCreator(Constants.getSingleContentTypeRequestFailureActionType, null);
    }

    export type resetCategoryStateRequestAction = Action<typeof Constants.resetCategoryStateActionType, null>

    export function resetCategoryStateRequest(): resetCategoryStateRequestAction {
        return ActionCreator(Constants.resetCategoryStateActionType, null);
    }

    export type getModuleRequestAction = Action<typeof Constants.getModuleRequestActionType, SearchModel>
    export type getModuleRequestSuccessAction = Action<typeof Constants.getModuleRequestSuccessActionType, PagingDataModel<ModuleModel>>;
    export type getModuleRequestFailureAction = Action<typeof Constants.getModuleRequestFailureActionType, null>;

    export function getModuleRequest(P: SearchModel): getModuleRequestAction {
        return ActionCreator(Constants.getModuleRequestActionType, P);
    }
    export function getModuleRequestSuccess(P: PagingDataModel<ModuleModel>): getModuleRequestSuccessAction {
        return ActionCreator(Constants.getModuleRequestSuccessActionType, P);
    }
    export function getModuleRequestFailure(): getModuleRequestFailureAction {
        return ActionCreator(Constants.getModuleRequestFailureActionType, null);
    }

    export type addModuleRequestAction = Action<typeof Constants.addModuleRequestActionType, ModuleModel>
    export type addModuleRequestSuccessAction = Action<typeof Constants.addModuleRequestSuccessActionType, string>;
    export type addModuleRequestFailureAction = Action<typeof Constants.addModuleRequestFailureActionType, null>;

    export function addModuleRequest(P: ModuleModel): addModuleRequestAction {
        return ActionCreator(Constants.addModuleRequestActionType, P);
    }
    export function addModuleRequestSuccess(P: string): addModuleRequestSuccessAction {
        return ActionCreator(Constants.addModuleRequestSuccessActionType, P);
    }
    export function addModuleRequestFailure(): addModuleRequestFailureAction {
        return ActionCreator(Constants.addModuleRequestFailureActionType, null);
    }

    export type updateModuleRequestAction = Action<typeof Constants.updateModuleRequestActionType, UpdateModuleModel>
    export type updateModuleRequestSuccessAction = Action<typeof Constants.updateModuleRequestSuccessActionType, null>;
    export type updateModuleRequestFailureAction = Action<typeof Constants.updateModuleRequestFailureActionType, null>;

    export function updateModuleRequest(P: UpdateModuleModel): updateModuleRequestAction {
        return ActionCreator(Constants.updateModuleRequestActionType, P);
    }
    export function updateModuleRequestSuccess(): updateModuleRequestSuccessAction {
        return ActionCreator(Constants.updateModuleRequestSuccessActionType, null);
    }
    export function updateModuleRequestFailure(): updateModuleRequestFailureAction {
        return ActionCreator(Constants.updateModuleRequestFailureActionType, null);
    }

    export type deleteModuleRequestAction = Action<typeof Constants.deleteModuleRequestActionType, string>
    export type deleteModuleRequestSuccessAction = Action<typeof Constants.deleteModuleRequestSuccessActionType, string>;
    export type deleteModuleRequestFailureAction = Action<typeof Constants.deleteModuleRequestFailureActionType, null>;

    export function deleteModuleRequest(P: string): deleteModuleRequestAction {
        return ActionCreator(Constants.deleteModuleRequestActionType, P);
    }
    export function deleteModuleRequestSuccess(P: string): deleteModuleRequestSuccessAction {
        return ActionCreator(Constants.deleteModuleRequestSuccessActionType, P);
    }
    export function deleteModuleRequestFailure(): deleteModuleRequestFailureAction {
        return ActionCreator(Constants.deleteModuleRequestFailureActionType, null);
    }

    export type getSingleModuleRequestAction = Action<typeof Constants.getSingleModuleRequestActionType, string>
    export type getSingleModuleRequestSuccessAction = Action<typeof Constants.getSingleModuleRequestSuccessActionType, ModuleModel>;
    export type getSingleModuleRequestFailureAction = Action<typeof Constants.getSingleModuleRequestFailureActionType, null>;

    export function getSingleModuleRequest(P: string): getSingleModuleRequestAction {
        return ActionCreator(Constants.getSingleModuleRequestActionType, P);
    }
    export function getSingleModuleRequestSuccess(P: ModuleModel): getSingleModuleRequestSuccessAction {
        return ActionCreator(Constants.getSingleModuleRequestSuccessActionType, P);
    }
    export function getSingleModuleRequestFailure(): getSingleModuleRequestFailureAction {
        return ActionCreator(Constants.getSingleModuleRequestFailureActionType, null);
    }

    export type getCategoriesListRequestAction = Action<typeof Constants.getCategoriesListRequestActionType, null>
    export type getCategoriesListRequestSuccessAction = Action<typeof Constants.getCategoriesListRequestSuccessActionType, CategoryList>;
    export type getCategoriesListRequestFailureAction = Action<typeof Constants.getCategoriesListRequestFailureActionType, null>;

    export function getCategoriesListRequest(): getCategoriesListRequestAction {
        return ActionCreator(Constants.getCategoriesListRequestActionType, null);
    }
    export function getCategoriesListRequestSuccess(P: CategoryList): getCategoriesListRequestSuccessAction {
        return ActionCreator(Constants.getCategoriesListRequestSuccessActionType, P);
    }
    export function getCategoriesListRequestFailure(): getCategoriesListRequestFailureAction {
        return ActionCreator(Constants.getCategoriesListRequestFailureActionType, null);
    }

    export type getModulesListRequestAction = Action<typeof Constants.getModulesListRequestActionType, null>
    export type getModulesListRequestSuccessAction = Action<typeof Constants.getModulesListRequestSuccessActionType, CategoryList>;
    export type getModulesListRequestFailureAction = Action<typeof Constants.getModulesListRequestFailureActionType, null>;

    export function getModulesListRequest(): getModulesListRequestAction {
        return ActionCreator(Constants.getModulesListRequestActionType, null);
    }
    export function getModulesListRequestSuccess(P: CategoryList): getModulesListRequestSuccessAction {
        return ActionCreator(Constants.getModulesListRequestSuccessActionType, P);
    }
    export function getModulesListRequestFailure(): getModulesListRequestFailureAction {
        return ActionCreator(Constants.getModulesListRequestFailureActionType, null);
    }

    export type getAudienceProfilesRequestAction = Action<typeof Constants.getAudienceProfilesRequestActionType, SearchModel>
    export type getAudienceProfilesRequestSuccessAction = Action<typeof Constants.getAudienceProfilesRequestSuccessActionType, PagingDataModel<AudienceProfiles>>;
    export type getAudienceProfilesRequestFailureAction = Action<typeof Constants.getAudienceProfilesRequestFailureActionType, null>;

    export function getAudienceProfilesRequest(P: SearchModel): getAudienceProfilesRequestAction {
        return ActionCreator(Constants.getAudienceProfilesRequestActionType, P);
    }
    export function getAudienceProfilesRequestSuccess(P: PagingDataModel<AudienceProfiles>): getAudienceProfilesRequestSuccessAction {
        return ActionCreator(Constants.getAudienceProfilesRequestSuccessActionType, P);
    }
    export function getAudienceProfilesRequestFailure(): getAudienceProfilesRequestFailureAction {
        return ActionCreator(Constants.getAudienceProfilesRequestFailureActionType, null);
    }

    export type addAudienceProfilesRequestAction = Action<typeof Constants.addAudienceProfilesRequestActionType, AudienceProfiles>
    export type addAudienceProfilesRequestSuccessAction = Action<typeof Constants.addAudienceProfilesRequestSuccessActionType, null>;
    export type addAudienceProfilesRequestFailureAction = Action<typeof Constants.addAudienceProfilesRequestFailureActionType, null>;

    export function addAudienceProfilesRequest(P: AudienceProfiles): addAudienceProfilesRequestAction {
        return ActionCreator(Constants.addAudienceProfilesRequestActionType, P);
    }
    export function addAudienceProfilesRequestSuccess(): addAudienceProfilesRequestSuccessAction {
        return ActionCreator(Constants.addAudienceProfilesRequestSuccessActionType, null);
    }
    export function addAudienceProfilesRequestFailure(): addAudienceProfilesRequestFailureAction {
        return ActionCreator(Constants.addAudienceProfilesRequestFailureActionType, null);
    }

    export type updateAudienceProfilesRequestAction = Action<typeof Constants.updateAudienceProfilesRequestActionType, UpdateAudienceProfilesModel>
    export type updateAudienceProfilesRequestSuccessAction = Action<typeof Constants.updateAudienceProfilesRequestSuccessActionType, null>;
    export type updateAudienceProfilesRequestFailureAction = Action<typeof Constants.updateAudienceProfilesRequestFailureActionType, null>;

    export function updateAudienceProfilesRequest(P: UpdateAudienceProfilesModel): updateAudienceProfilesRequestAction {
        return ActionCreator(Constants.updateAudienceProfilesRequestActionType, P);
    }
    export function updateAudienceProfilesRequestSuccess(): updateAudienceProfilesRequestSuccessAction {
        return ActionCreator(Constants.updateAudienceProfilesRequestSuccessActionType, null);
    }
    export function updateAudienceProfilesRequestFailure(): updateAudienceProfilesRequestFailureAction {
        return ActionCreator(Constants.updateAudienceProfilesRequestFailureActionType, null);
    }

    export type deleteAudienceProfilesRequestAction = Action<typeof Constants.deleteAudienceProfilesRequestActionType, string>
    export type deleteAudienceProfilesRequestSuccessAction = Action<typeof Constants.deleteAudienceProfilesRequestSuccessActionType, null>;
    export type deleteAudienceProfilesRequestFailureAction = Action<typeof Constants.deleteAudienceProfilesRequestFailureActionType, null>;

    export function deleteAudienceProfilesRequest(P: string): deleteAudienceProfilesRequestAction {
        return ActionCreator(Constants.deleteAudienceProfilesRequestActionType, P);
    }
    export function deleteAudienceProfilesRequestSuccess(): deleteAudienceProfilesRequestSuccessAction {
        return ActionCreator(Constants.deleteAudienceProfilesRequestSuccessActionType, null);
    }
    export function deleteAudienceProfilesRequestFailure(): deleteAudienceProfilesRequestFailureAction {
        return ActionCreator(Constants.deleteAudienceProfilesRequestFailureActionType, null);
    }

    export type getSingleAudienceProfilesRequestAction = Action<typeof Constants.getSingleAudienceProfilesRequestActionType, string>
    export type getSingleAudienceProfilesRequestSuccessAction = Action<typeof Constants.getSingleAudienceProfilesRequestSuccessActionType, AudienceProfiles>;
    export type getSingleAudienceProfilesRequestFailureAction = Action<typeof Constants.getSingleAudienceProfilesRequestFailureActionType, null>;

    export function getSingleAudienceProfilesRequest(P: string): getSingleAudienceProfilesRequestAction {
        return ActionCreator(Constants.getSingleAudienceProfilesRequestActionType, P);
    }
    export function getSingleAudienceProfilesRequestSuccess(P: AudienceProfiles): getSingleAudienceProfilesRequestSuccessAction {
        return ActionCreator(Constants.getSingleAudienceProfilesRequestSuccessActionType, P);
    }
    export function getSingleAudienceProfilesRequestFailure(): getSingleAudienceProfilesRequestFailureAction {
        return ActionCreator(Constants.getSingleAudienceProfilesRequestFailureActionType, null);
    }

    export type getCollectionsListRequestAction = Action<typeof Constants.getCollectionsListRequestActionType, null>
    export type getCollectionsListRequestSuccessAction = Action<typeof Constants.getCollectionsListRequestSuccessActionType, CollectionModel[]>;
    export type getCollectionsListRequestFailureAction = Action<typeof Constants.getCollectionsListRequestFailureActionType, null>;

    export function getCollectionsListRequest(): getCollectionsListRequestAction {
        return ActionCreator(Constants.getCollectionsListRequestActionType, null);
    }
    export function getCollectionsListRequestSuccess(P: CollectionModel[]): getCollectionsListRequestSuccessAction {
        return ActionCreator(Constants.getCollectionsListRequestSuccessActionType, P);
    }
    export function getCollectionsListRequestFailure(): getCollectionsListRequestFailureAction {
        return ActionCreator(Constants.getCollectionsListRequestFailureActionType, null);
    }

    export type addCollectionRequestAction = Action<typeof Constants.addCollectionRequestActionType, CollectionModel>
    export type addCollectionRequestSuccessAction = Action<typeof Constants.addCollectionRequestSuccessActionType, string>;
    export type addCollectionRequestFailureAction = Action<typeof Constants.addCollectionRequestFailureActionType, null>;

    export function addCollectionRequest(P: CollectionModel): addCollectionRequestAction {
        return ActionCreator(Constants.addCollectionRequestActionType, P);
    }
    export function addCollectionRequestSuccess(P: string): addCollectionRequestSuccessAction {
        return ActionCreator(Constants.addCollectionRequestSuccessActionType, P);
    }
    export function addCollectionRequestFailure(): addCollectionRequestFailureAction {
        return ActionCreator(Constants.addCollectionRequestFailureActionType, null);
    }

    export type getCollectionVideosRequestAction = Action<typeof Constants.getCollectionVideosRequestActionType, string>
    export type getCollectionVideosRequestSuccessAction = Action<typeof Constants.getCollectionVideosRequestSuccessActionType, CollectionVideosModel>;
    export type getCollectionVideosRequestFailureAction = Action<typeof Constants.getCollectionVideosRequestFailureActionType, null>;

    export function getCollectionVideosRequest(P: string): getCollectionVideosRequestAction {
        return ActionCreator(Constants.getCollectionVideosRequestActionType, P);
    }
    export function getCollectionVideosRequestSuccess(P: CollectionVideosModel): getCollectionVideosRequestSuccessAction {
        return ActionCreator(Constants.getCollectionVideosRequestSuccessActionType, P);
    }
    export function getCollectionVideosRequestFailure(): getCollectionVideosRequestFailureAction {
        return ActionCreator(Constants.getCollectionVideosRequestFailureActionType, null);
    }

    export type addCollectionVideosRequestAction = Action<typeof Constants.addCollectionVideosRequestActionType, CollectionVideoModel>
    export type addCollectionVideosRequestSuccessAction = Action<typeof Constants.addCollectionVideosRequestSuccessActionType, string>;
    export type addCollectionVideosRequestFailureAction = Action<typeof Constants.addCollectionVideosRequestFailureActionType, null>;

    export function addCollectionVideosRequest(P: CollectionVideoModel): addCollectionVideosRequestAction {
        return ActionCreator(Constants.addCollectionVideosRequestActionType, P);
    }
    export function addCollectionVideosRequestSuccess(P: string): addCollectionVideosRequestSuccessAction {
        return ActionCreator(Constants.addCollectionVideosRequestSuccessActionType, P);
    }
    export function addCollectionVideosRequestFailure(): addCollectionVideosRequestFailureAction {
        return ActionCreator(Constants.addCollectionVideosRequestFailureActionType, null);
    }

    export type removeCollectionVideosRequestAction = Action<typeof Constants.removeCollectionVideosRequestActionType, CollectionVideoModel>
    export type removeCollectionVideosRequestSuccessAction = Action<typeof Constants.removeCollectionVideosRequestSuccessActionType, string>;
    export type removeCollectionVideosRequestFailureAction = Action<typeof Constants.removeCollectionVideosRequestFailureActionType, null>;

    export function removeCollectionVideosRequest(P: CollectionVideoModel): removeCollectionVideosRequestAction {
        return ActionCreator(Constants.removeCollectionVideosRequestActionType, P);
    }
    export function removeCollectionVideosRequestSuccess(P: string): removeCollectionVideosRequestSuccessAction {
        return ActionCreator(Constants.removeCollectionVideosRequestSuccessActionType, P);
    }
    export function removeCollectionVideosRequestFailure(): removeCollectionVideosRequestFailureAction {
        return ActionCreator(Constants.removeCollectionVideosRequestFailureActionType, null);
    }

    export type removeCollectionRequestAction = Action<typeof Constants.removeCollectionRequestActionType, CollectionVideoModel>
    export type removeCollectionRequestSuccessAction = Action<typeof Constants.removeCollectionRequestSuccessActionType, string>;
    export type removeCollectionRequestFailureAction = Action<typeof Constants.removeCollectionRequestFailureActionType, null>;

    export function removeCollectionRequest(P: CollectionVideoModel): removeCollectionRequestAction {
        return ActionCreator(Constants.removeCollectionRequestActionType, P);
    }
    export function removeCollectionRequestSuccess(P: string): removeCollectionRequestSuccessAction {
        return ActionCreator(Constants.removeCollectionRequestSuccessActionType, P);
    }
    export function removeCollectionRequestFailure(): removeCollectionRequestFailureAction {
        return ActionCreator(Constants.removeCollectionRequestFailureActionType, null);
    }

    export type updateCollectionRequestAction = Action<typeof Constants.updateCollectionRequestActionType, UpdateCollectionModel>
    export type updateCollectionRequestSuccessAction = Action<typeof Constants.updateCollectionRequestSuccessActionType, null>;
    export type updateCollectionRequestFailureAction = Action<typeof Constants.updateCollectionRequestFailureActionType, null>;

    export function updateCollectionRequest(P: UpdateCollectionModel): updateCollectionRequestAction {
        return ActionCreator(Constants.updateCollectionRequestActionType, P);
    }
    export function updateCollectionRequestSuccess(): updateCollectionRequestSuccessAction {
        return ActionCreator(Constants.updateCollectionRequestSuccessActionType, null);
    }
    export function updateCollectionRequestFailure(): updateCollectionRequestFailureAction {
        return ActionCreator(Constants.updateCollectionRequestFailureActionType, null);
    }

    export type getCollectionRequestAction = Action<typeof Constants.getCollectionRequestActionType, string>
    export type getCollectionRequestSuccessAction = Action<typeof Constants.getCollectionRequestSuccessActionType, CollectionModel>;
    export type getCollectionRequestFailureAction = Action<typeof Constants.getCollectionRequestFailureActionType, null>;

    export function getCollectionRequest(P: string): getCollectionRequestAction {
        return ActionCreator(Constants.getCollectionRequestActionType, P);
    }
    export function getCollectionRequestSuccess(P: CollectionModel): getCollectionRequestSuccessAction {
        return ActionCreator(Constants.getCollectionRequestSuccessActionType, P);
    }
    export function getCollectionRequestFailure(): getCollectionRequestFailureAction {
        return ActionCreator(Constants.getCollectionRequestFailureActionType, null);
    }

    export type updateCollectionVideoOrderRequestAction = Action<typeof Constants.updateCollectionVideoOrderRequestActionType, any>
    export type updateCollectionVideoOrderRequestSuccessAction = Action<typeof Constants.updateCollectionVideoOrderRequestSuccessActionType, null>;
    export type updateCollectionVideoOrderRequestFailureAction = Action<typeof Constants.updateCollectionVideoOrderRequestFailureActionType, null>;

    export function updateCollectionVideoOrderRequest(P: any): updateCollectionVideoOrderRequestAction {
        return ActionCreator(Constants.updateCollectionVideoOrderRequestActionType, P);
    }
    export function updateCollectionVideoOrderRequestSuccess(): updateCollectionVideoOrderRequestSuccessAction {
        return ActionCreator(Constants.updateCollectionVideoOrderRequestSuccessActionType, null);
    }
    export function updateCollectionVideoOrderRequestFailure(): updateCollectionVideoOrderRequestFailureAction {
        return ActionCreator(Constants.updateCollectionVideoOrderRequestFailureActionType, null);
    }
}

export type CategoryActions = Omit<typeof CategoryActions, 'Type'>;
export const useCategoryActions = (dispatch: Dispatch) => {
    const { ...actions } = CategoryActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as CategoryActions;
};