import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import VideoSidebar from '../videoSidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import ToastMessage from 'src/components/ToastContainer';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FileDrop } from 'react-file-drop';
import { uploadPartUrl } from 'src/components/utilities/utils';

type locationState = {
  channelId: string;
  title: string;
  captions: boolean;
  from: string;
};
const UploadVideo = () => {
  const rootUrl = process.env.REACT_APP_BASE_API_URL;
  const navigate = useNavigate();
  const fileInputRef = useRef<any>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [videoName, setVideoName] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const { channelId, title, captions, from } = (location.state as locationState) || {};

  const { videoId, uploadId, fileNames, isCancelUpload } = useSelector((state: RootState) => {
    return {
      videoId: state.videos.videoId,
      uploadId: state.videos.uploadId,
      fileNames: state.videos.fileNames,
      isCancelUpload: state.videos.isCancelUpload
    };
  });

  const handleMultiplePartUpload = async () => {
    setUploadPercentage(1);
    uploadPartUrl(
      rootUrl,
      videoId,
      title,
      videoName,
      channelId,
      uploadedFile,
      captions,
      setUploadPercentage,
      videoLibraryAction.uploadVideoRequestSuccess,
      isCancelUpload,
      false
    );
  };

  const handleRemoveClick = () => {
    fileInputRef.current.value = '';
    setVideoName('');
    if (uploadId !== '') {
      videoLibraryAction.cancelUploadRequest({ videoId, uploadId, fileName: fileNames });
      setUploadPercentage(0);
    }
  };
  useEffect(() => {
    if (isCancelUpload) {
      toast('Video Upload Canceled Successfully');
      videoLibraryAction.resetVideoLibraryRequest();
    }
  }, [isCancelUpload]);
  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  const onFileInputChange = (event: any) => {
    setUploadedFile(event.target.files[0]);
    const { files } = event.target;
    setVideoName(event.target.files[0].name);
  };

  const handleDropClick = (files: any) => {
    setUploadedFile(files && files[0]);
    setVideoName(files[0].name);
  };

  const handleUploadAgainClick = () => {
    fileInputRef.current.value = '';
    setVideoName('');
    setUploadPercentage(0);
    navigate('/add-video/0', { state: { channelIds: channelId } });
  };
  useEffect(() => {
    if (!videoId) {
      navigate('/add-video/0');
    }
  }, [videoId]);

  useEffect(() => {
    if (from == 'course' && uploadPercentage === 100) {
      const returnUrl = sessionStorage.getItem('returnUrl');
      navigate(`${returnUrl}`);
      sessionStorage.removeItem('returnUrl');
    }
  }, [from, uploadPercentage]);
  return (
    <div className="md:flex mt-6">
      <ToastMessage />
      <div className="add-video min-h-96 lg:w-4/5 ">
        <form>
          <input onChange={onFileInputChange} ref={fileInputRef} type="file" className="hidden" />
          <div className="browse-file-dropzone">
            <FileDrop
              onTargetClick={onTargetClick}
              onDrop={(files, event) => handleDropClick(files)}>
              Drop files here or Click to Upload
            </FileDrop>
          </div>
          <div className="video-progress mt-7">
            <div className="flex items-center">
              {videoName !== '' && (
                <div className={`form-group mb-2 w-1/2 mr-2`}>
                  <input
                    type="text"
                    className="input-style"
                    placeholder={`${videoName && videoName}`}
                  />
                </div>
              )}

              {videoName !== '' && (
                <button
                  type="button"
                  onClick={handleRemoveClick}
                  className="px-6 btn-primary w-28 ml-2 mt-0">
                  Remove
                </button>
              )}
            </div>
            {uploadPercentage !== 0 && videoName !== '' && (
              <div
                className={`w-full ${
                  uploadPercentage === 100 ? 'bg-green-100' : 'bg-purple-100'
                } h-3 rounded-full mt-4`}>
                <div
                  className={` ${
                    uploadPercentage === 100 ? 'bg-green-400' : 'bg-purple-600'
                  } text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                  style={{ width: `${Math.floor(uploadPercentage)}%` }}>
                  {Math.floor(uploadPercentage)}%
                </div>
              </div>
            )}
          </div>

          <div className="completed-progress mt-8">
            {/* <div className="flex items-center">
              <div className={`form-group mb-2 w-1/2 mr-2`}>
                <input type="text" className="input-style" placeholder="Video1.MP4" />
              </div>
              <button type="button" className="px-6 btn-primary bg-green w-28 ml-2 mt-0">
                Complete
              </button>
            </div>
            <div className="w-full bg-blue-100 h-3 rounded-full mt-4">
              <div className="bg-blue-500 text-white text-xs h-3 font-medium text-center leading-none rounded-full w-full">
                100%
              </div>
            </div> */}
            <div className="flex items-center mt-6 whitespace-nowrap">
              {uploadPercentage !== 100 && (
                <button
                  disabled={videoName === '' ? true : false}
                  type="button"
                  onClick={handleMultiplePartUpload}
                  className={`px-6 btn-primary w-44 mr-2 ml-0 mt-0 ${
                    videoName === '' ? 'opacity-25' : ''
                  }`}>
                  Upload
                </button>
              )}

              {uploadPercentage === 100 && (
                <>
                  <button
                    type="button"
                    onClick={handleUploadAgainClick}
                    className="mx-0 px-6 btn-primary discard-btn w-36 mt-0">
                    Upload New
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(`/videos/${videoId}`)}
                    className="mx-2 px-6 btn-primary  w-40 mt-0"
                    data-bs-dismiss="modal">
                    Go to Video
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadVideo;
