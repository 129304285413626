import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useChannelActions } from 'src/store/channel/actions';
const AddNewCourseModal = ({ newChannelId, singleCourse }: any) => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const ref = useRef<HTMLButtonElement>(null);
  const handleVideoUploadClick = (status: number) => {
    channelActions.getChannelListRequest();
    sessionStorage.setItem('returnUrl', window.location.pathname + window.location.search);
    navigate(`/add-video/${status}`, {
      state: { channelIds: newChannelId ? newChannelId : singleCourse.channelId, from: 'course' }
    });
    handleCloseClick();
  };
  const handleCloseClick = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleResourceUploadClick = () => {
    channelActions.getChannelListRequest();
    sessionStorage.setItem('returnUrl', window.location.pathname + window.location.search);
    navigate('/add-resource', {
      state: { channelIds: newChannelId ? newChannelId : singleCourse.channelId, from: 'course' }
    });
    handleCloseClick();
  };
  return (
    <div className="addNewCourse">
      <div
        className={` videoViews modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
        id="addNewCourseModal"
        aria-labelledby="memberListModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="memberListModalLabel">
                Add New
              </h5>
              <button
                ref={ref}
                onClick={handleCloseClick}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="wrapper flex  mt-8 mb-8 ">
                <div className="box-wrapper" onClick={() => handleVideoUploadClick(0)}>
                  <i className="fa-solid fa-video fa-3x"></i>
                  <h6>Video</h6>
                </div>
                <div className="box-wrapper" onClick={handleResourceUploadClick}>
                  <i className="fa-solid fa-file fa-3x"></i>
                  <div className="flex gap-2">
                    <h6>Resource</h6>
                  </div>
                </div>
              </div>
              <div className="wrapper flex  mt-8 mb-8 ">
                <div
                  className="box-wrapper"
                  onClick={() => {
                    navigate(`/chapterlist/${id}`), handleCloseClick();
                  }}>
                  <i className="fa-solid fa-file-text fa-3x"></i>
                  <h6>Chapter</h6>
                </div>
                <div
                  className="box-wrapper"
                  onClick={() => {
                    navigate(`/checkpoint/${id}`), handleCloseClick();
                  }}>
                  <i className="fa-solid fa-check fa-3x"></i>
                  <div className="flex gap-2">
                    <h6>Checkpoint</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCourseModal;
