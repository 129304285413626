import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingActions } from 'src/store/settings/actions';

const UpdateMultipleChoiceModal = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const [options, SetOptions] = useState<Array<number>>([]);
  const [showDescription, setShowDescription] = useState(false);
  const [actionTimeError, setActionTimeError] = useState<boolean>(false);
  const [allowToSelectMultiple, setAllowToSelectMultiple] = useState<boolean>(false);
  const [answerId, setAnswerID] = useState<number>();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data: any) => {
    settingAction.updateTemplateRequest(data);
  };
  return (
    <div>
      <div
        className="modal fade"
        id="UpdateMultipleChoiceModal"
        aria-labelledby="UpdateMultipleChoiceModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="UpdateMultipleChoiceModalLabel">
                Multiple Choice
                <span>
                  {actionTimeError && (
                    <p className="text-red-500 text-xs error">
                      Poll Display Time can not be greater than Video Duration
                    </p>
                  )}
                </span>
              </h5>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form className="px-6" onSubmit={handleSubmit(onSubmit)}>
              <div className={`form-group mb-3 w-full`}>
                <label className="block text-sm font-medium mb-1">Display At End</label>
                <div className="form-check form-switch pl-0 mr-3 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="displayResults"
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                </div>
              </div>
              <div className="modal-body relative py-4 border-b">
                <div className="mt-3 px-0">
                  <div className="border rounded-md">
                    <div className="flex items-center justify-between border-b px-5 py-4">
                      <p className="font-semibold mb-0">Questions</p>
                    </div>

                    <div className="p-4 questions-side-card">
                      <div className="flex-items">
                        <label className="font-semibold">Question 1</label>

                        <div className="dropdown relative">
                          <button
                            className="dropdown-toggle border-0 input-style mx-auto flex-items"
                            type="button"
                            id="action-dropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i className="fas fa-chevron-down"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-ul w-32"
                            aria-labelledby="action-dropdown">
                            <li className="cursor-pointer" onClick={() => setShowDescription(true)}>
                              <span className="action-menu">Add description</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`form-group mb-3 w-full relative`}>
                        <input
                          {...register('pollTitle')}
                          type="text"
                          className="input-style pl-0"
                          placeholder="What would you like to ask ?"
                        />
                      </div>
                      <div
                        className={`form-group mb-3 w-full relative ${
                          showDescription ? '' : 'hidden'
                        }`}>
                        <input
                          type="text"
                          {...register('description')}
                          className="input-style pl-0"
                          placeholder="Description"
                        />
                      </div>
                      {options &&
                        options.map((item, index) => {
                          return (
                            <div key={index} className={`form-group mb-3 w-full`}>
                              <div className="flex items-center mr-3">
                                <div className="form-check">
                                  <input
                                    name="isAnswer"
                                    className="form-check-input radio-btn"
                                    defaultChecked={index === answerId ? true : false}
                                    type="radio"
                                  />
                                </div>
                                <input
                                  type="text"
                                  className="input-style pl-0 ml-3"
                                  placeholder="Add Option"
                                  {...register(`option_${index}`)}
                                />
                              </div>
                            </div>
                          );
                        })}

                      <button type="button" className="btn-primary w-auto mx-0 ml-auto">
                        <i className="fas fa-plus"></i>
                      </button>
                      <div className="form-check flex items-center justify-start">
                        <input
                          onChange={(e) => setAllowToSelectMultiple(e.target.checked)}
                          checked={allowToSelectMultiple}
                          className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                          type="checkbox"
                        />
                        <label className="ml-1">Allow to select Multiple Answer</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  type="submit"
                  className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                  Save Changes
                </button>
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateMultipleChoiceModal;
