import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { EventRegistrationImagesViewModel } from 'src/models/eventModel';
import Loader from 'src/components/loader';
import Editor from 'src/components/Editor';
const DemoEmailTemplateModal: React.FC = () => {
    const { eventId } = useParams<string>();
    const ref = useRef<HTMLButtonElement>(null);
    const { reset } = useForm<EventRegistrationImagesViewModel>();
    const { eventRegistrationImagesView, isEventSaved } =
        useSelector((state: RootState) => {
        return {
            eventRegistrationImagesView: state.event.singleEvent.eventRegistrationField ? state.event.singleEvent.eventRegistrationField.eventRegistrationImagesViewModel : null,
            isEventSaved: state.event.isEventSaved,
            isEventUpdated: state.event.isEventUpdated
        };
        });
    
    useEffect(() => {
      if (isEventSaved === true) {
        reset({
            emailOnRegistration: true,
            emailSubject: '',
            emailBody: '',
            sendICSAttachment: true
        });
      }
    }, [isEventSaved]);
    useEffect(() => {
      if (eventRegistrationImagesView)
        reset(eventRegistrationImagesView);
      if (eventId?.length == 0) {
        reset({
            emailOnRegistration: true,
            emailSubject: '',
            emailBody: '',
            sendICSAttachment: true
        });
      }
    }, [eventRegistrationImagesView]);
  
return (
    <div>
      <div
        className="modal fade"
        id="DemoEmailTemplateModal"
        aria-labelledby="DemoEmailTemplateModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="DemoEmailTemplateModalLabel">
                Email Template Demo
              </h5>
              {isEventSaved && <Loader />}
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Note: </strong>
                    <span className="block sm:inline">Configure column name in between % sign.</span>
                </div>
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                    <label className="block text-sm font-medium mb-1">Subject</label>
                        <p>{eventRegistrationImagesView?.emailSubject}</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                    <label className="block text-sm font-medium mb-1">Body</label>
                        <div dangerouslySetInnerHTML={{__html: eventRegistrationImagesView?.emailBody as any}} />
                    </div>
                </div>
                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoEmailTemplateModal;