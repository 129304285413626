import React, { useState, useRef, useEffect } from 'react';
import '../styles.scss';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ChannelSideMenu from './components/ChannelSideMenu';
import ChannelStylesTab from './components/ChannelStylesTab';
import ChannelMenu from './components/ChannelMenu';
import { useChannelActions } from 'src/store/channel/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { successMsg } from 'src/components/utilities/utils';
import ChannelHightlightReelsTab from './components/ChannelHightlightReelsTab';
import ChannelSpotLightRow from './components/ChannelSpotLightRow';
import ChannelInfoTab from './components/ChannelInfoTab';
import ToastMessage from 'src/components/ToastContainer';
import { errorMsg } from 'src/components/utilities/utils';
const ChannelLayout = () => {
  const { id } = useParams<string>();

  const { message, isChannelLayoutUpdated, isChannelUpdated, error } = useSelector(
    (state: RootState) => {
      return {
        channels: state.channel.channels,
        message: state.channel.message,
        channelLayout: state.channel.channelLayout,
        isChannelLayoutLoading: state.channel.isChannelLayoutLoading,
        isChannelLayoutUpdatedLoading: state.channel.isChannelLayoutUpdatedLoading,
        isChannelLayoutUpdated: state.channel.isChannelLayoutUpdated,
        isChannelUpdated: state.channel.isChannelUpdated,
        error: state.channel.error
      };
    }
  );

  const dispatch = useDispatch();
  const channelAction = useChannelActions(dispatch);

  useEffect(() => {
    if (isChannelLayoutUpdated || isChannelUpdated) {
      successMsg(message);
      channelAction.resetChannelStateRequest();
    }
  }, [isChannelLayoutUpdated, isChannelUpdated]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      channelAction.resetChannelStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="highlight-reel">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Channels</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Channels</Link>
            </li>
            <li>
              <strong>Edit</strong>
            </li>
          </ol>
        </div>
        <Link to={`/channels`}>
          <i className="fas fa-arrow-left mt-2 mb-2"></i>
          <span className="ml-2">Go Back</span>
        </Link>
        <div className="lg:flex">
          <ChannelSideMenu />
          <div className="tab-content lg:w-4/5 shadow border" id="tabs-tabContentVertical">
            {id && (
              <>
                <ChannelStylesTab id={id} />
                <ChannelMenu />
                <ChannelSpotLightRow id={id} />
                <ChannelHightlightReelsTab />
                <ChannelInfoTab id={id} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelLayout;
