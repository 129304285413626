import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUserActions } from 'src/store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';

const UploadUserNameModal = () => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const { id } = useParams<string>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const { isUserNameUploaded } = useSelector((state: RootState) => {
    return {
      isUserNameUploaded: state.users.isUserNameUploaded
    };
  });

  const onSubmit = (data: any) => {
    if (id) {
      userAction.uploadUserNameRequest({ id, type: 2, values: encodeURIComponent(data.name) });
    }
  };

  useEffect(() => {
    if (isUserNameUploaded) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isUserNameUploaded]);

  const handleCloseClick = () => {
    reset({
      name: ''
    });
  };
  return (
    <div>
      <div
        className="modal fade"
        id="UploadMembers"
        aria-labelledby="UploadMembersLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <i className="fa fa-users modal-icon"></i>
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="UploadMembersLabel">
                Upload Usernames
              </h5>

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">
                    Add one or more usernames, separate by a semicolon.
                    <div className="mt-2">
                      <b>{`E.g: user1; emma@yahoo.com; sam@webcastcloud.com`}</b>
                    </div>
                  </label>

                  <textarea
                    {...register('name', { required: true })}
                    className="input-style mt-4"
                    placeholder="Enter Usernames"
                    rows={4}
                    cols={50}></textarea>
                  {errors.name?.type === 'required' && (
                    <span className="text-red-500 text-xs flex item-center error">
                      Required Field
                    </span>
                  )}
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Upload
                  </button>
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadUserNameModal;
