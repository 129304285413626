import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
const AssignTemplateModal = ({ pollsTemplate, singleVideo, videoId }: any) => {
  const dispatch = useDispatch();
  const [timeFormatError, setTimeFormatError] = useState(false);
  const { loading } = useSelector((state: RootState) => {
    return {
      loading: state.videos.loading
    };
  });
  const videoActions = useVideosActions(dispatch);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    if (singleVideo) {
      const durationData = singleVideo.duration;
      setValue('pollDurationTimerString', durationData);
    }
  }, [singleVideo]);

  const onSubmit = (data: any) => {
    const existTime = data.pollDisplayTime;
    if (existTime <= singleVideo.duration) {
      const finalPayload = { ...data, videoId: videoId };
      videoActions.savePollTemplateRequest(finalPayload);
      videoActions.resetVideoLibraryRequest();
      setTimeFormatError(false);
    } else {
      setTimeFormatError(true);
    }
  };

  const handleCloseClick = () => {
    reset();
    setTimeFormatError(false);
  };

  return (
    <div
      className="modal fade"
      id="AssignTempModal"
      aria-labelledby="AssignTempModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="AssignTempModalLabel">
              Create Poll From Template
            </h5>
            <button
              id="assignTempModalClose"
              type="button"
              className="dismiss-btn"
              onClick={handleCloseClick}
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">Template Name</label>
                  <select {...register('templateId', { required: true })} className="w-full">
                    <option value={''}>Select Template</option>
                    {pollsTemplate &&
                      pollsTemplate.map((polls: any) => {
                        return (
                          <option key={polls.id} value={polls.id}>
                            {polls.templateName}
                          </option>
                        );
                      })}
                  </select>
                  {errors.templateId?.type === 'required' && (
                    <span className="text-red-500 text-xs flex item-center error">
                      please select template
                    </span>
                  )}
                </div>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">Video Duration</label>
                  <input
                    disabled
                    type="text"
                    value={singleVideo.duration || ''}
                    className="input-style"
                    id="pollDurationTimerString"
                    {...register('pollDurationTimerString')}
                  />
                </div>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">
                    Poll Display Time (hh:mm:ss)
                  </label>
                  <input
                    type="text"
                    className="input-style"
                    id="pollDisplayTime"
                    placeholder="hh:mm:ss"
                    {...register('pollDisplayTime', {
                      required: true,
                      pattern: /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/
                    })}
                  />
                  {(errors.pollDisplayTime?.type === 'pattern' || timeFormatError) && (
                    <span className="text-red-500 text-xs flex item-center error">
                      Not a Valid Time
                    </span>
                  )}
                </div>

                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseClick}
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTemplateModal;
