import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Pagination from 'src/components/common/pagination';
import { useNavigate, Link } from 'react-router-dom';
import Loader from 'src/components/loader';
import { defaultDateFormatter } from 'src/components/utilities/utils';
const AnalyticsQuiz: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const navigate = useNavigate();
  const {
    quizTotal,
    quizAnalytics,
    quizQuestions,
    paginationMeta,
    quizAnalyticsPaginationMeta,
    isQuizAnalyticLoading,
    isQuizQuestionLoading
  } = useSelector((state: RootState) => {
    return {
      quizTotal: state.analytics.quizTotal,
      quizAnalytics: state.analytics.quizAnalytics,
      quizQuestions: state.analytics.quizQuestions,
      paginationMeta: state.analytics.paginationMeta,
      quizAnalyticsPaginationMeta: state.analytics.quizAnalyticsPaginationMeta,
      isQuizAnalyticLoading: state.analytics.isQuizAnalyticLoading,
      isQuizQuestionLoading: state.analytics.isQuizQuestionLoading
    };
  });

  useEffect(() => {
    const data = { pageNumber: 1, pageSize: 20 };
    const quizAnalyticsData = { pageNumber: 1, pageSize: 20 };
    analyticsAction.getQuizTotalRequest();
    analyticsAction.getQuizAnalyticsRequest(quizAnalyticsData);
    analyticsAction.getQuizQuestionsRequest(data);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    const data = { pageNumber, pageSize: 20 };
    analyticsAction.getQuizQuestionsRequest(data);
  };

  const handleQuizAnalyticsPageChange = (pageNumber: number) => {
    const data = { pageNumber, pageSize: 20 };
    analyticsAction.getQuizAnalyticsRequest(data);
  };

  const handleQuestionClick = (questionId: string) => {
    navigate(`/analytic/question/${questionId}`);
  };

  const handleQuizClick = (quizId: string) => {
    navigate(`/quiz/singleQuiz/${quizId}`);
  };
  return (
    <>
      <div className="dashboard">
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {quizTotal && quizTotal.totalCount}
                  </span>
                  <span className="mb-2">Total Quiz</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                      <i className="fa fa-question-circle"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {quizTotal && quizTotal.totalInProgress}
                  </span>
                  <span className="mb-2">Total In Progress</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                      <i className="fa fa-check-square-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {quizTotal && quizTotal.totalCompleted}
                  </span>
                  <span className="mb-2">Total Completed</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                      <i className="fa fa-list-alt" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {quizTotal && quizTotal.totalFailed}
                  </span>
                  <span className="mb-2">Total Failed</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                      <i className="fa fa-warning"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-2">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Quizzes</h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <input className="input-style w-48 mr-3 mb-1" type="text" placeholder="Search" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Video Title</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Modified Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Participants</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Attempts</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Scores</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Questions</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Actions</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isQuizAnalyticLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {quizAnalytics &&
                              quizAnalytics.map((item, index) => {
                                return (
                                  <tr className="cursor-pointer" key={index}>
                                    <td className="p-2">
                                      <div className="text-left">{item.name}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.videoTitle}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.creationDate ? defaultDateFormatter(item.creationDate) : ""}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastModifiedDate ? defaultDateFormatter(item.lastModifiedDate) : ""}
                                      </div>
                                    </td>

                                    <td className="p-2">
                                      <div className="text-left">{item.totalParticipants}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalAttempts}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.scores}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalQuestions}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalCorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalIncorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="dropdown relative">
                                          <button
                                            className="dropdown-toggle"
                                            type="button"
                                            id="action-dropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img
                                              src="../../assets/img/dots-vertical.svg"
                                              className="w-5 h-5"
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu dropdown-menu-ul"
                                            aria-labelledby="action-dropdown">
                                            <li onClick={() => handleQuizClick(item.id)}>
                                              <button className="action-menu flex items-center">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="mr-2 w-4 h-4">
                                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                  <polyline points="14 2 14 8 20 8"></polyline>
                                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                                  <polyline points="10 9 9 9 8 9"></polyline>
                                                </svg>
                                                <span>Quiz Results</span>
                                              </button>
                                            </li>
                                            <li>
                                              <Link to={`/videos/create-poll/${item.videoID}`}>
                                                <button
                                                  type="button"
                                                  className="action-menu flex items-center">
                                                  <i className="fas fa-edit w-6 text-left"></i>
                                                  <span>Edit Quiz</span>
                                                </button>
                                              </Link>
                                            </li>
                                            <Link to={`/videos/${item.videoID}`}>
                                              <button
                                                type="button"
                                                className="action-menu flex items-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editMemberModal">
                                                <i className="fa fa-play w-6 text-left"></i>
                                                <span>Go to Video</span>
                                              </button>
                                            </Link>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!quizAnalyticsPaginationMeta &&
                      !!Object.keys(quizAnalyticsPaginationMeta).length && (
                        <Pagination
                          currentPage={quizAnalyticsPaginationMeta.pageNumber}
                          totalCount={quizAnalyticsPaginationMeta.totalCount}
                          pageSize={quizAnalyticsPaginationMeta.pageSize}
                          onPageChange={(page: any) => handleQuizAnalyticsPageChange(page)}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Quiz Questions</h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <input className="input-style w-48 mr-3 mb-1" type="text" placeholder="Search" />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border cursor-pointer">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Question Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Status</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created By</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Hightest Incorect Answer</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isQuizQuestionLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {quizQuestions &&
                              quizQuestions.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => handleQuestionClick(item.questionID)}>
                                    <td className="p-2">
                                      <div className="text-left">{item.questionName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.quizName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.quizStatus}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.createdDate ? defaultDateFormatter(item.createdDate) : ""}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.createdBy}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastActivityDate ? defaultDateFormatter(item.lastActivityDate) : ""}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.correctAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.incorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.highestIncorrectAnswers}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsQuiz;
