import React, { useState, useRef, useEffect } from 'react';
import SelectField from './SelectField';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
interface IProps {
  demoImportFileData: Array<string> | undefined;
}
const ImportOption: React.FC<IProps> = ({ demoImportFileData }) => {
  const ref = useRef<HTMLButtonElement>(null);

  const InputFile = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState(1);
  const [continueFlag, setContinueFlag] = useState(false);
  const [excelData, setExcelData] = useState<Array<string>>();
  const [fileData, setFileData] = useState<any>();
  const [showTypeError, setShowTypeError] = useState<boolean>(false);
  const { isRegistrationImported } = useSelector((state: RootState) => {
    return {
      isRegistrationImported: state.event.isRegistrationImported
    };
  });
  const onInputClick = (event: any) => {
    event.target.value = '';
  };

  const handleFileUpload = async (selectedFile: any) => {
    const files = selectedFile;
    if (files[0].type === 'text/csv') {
      setShowTypeError(false);
    } else {
      setShowTypeError(true);
    }
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      setFileData(file);
      reader.onload = function (event: any) {
        const csvdata = event.target.result;
        const rowData = csvdata.split('\n');
        setExcelData(rowData);
        // const files = file;
        // const formData = new FormData();
        // formData.append('membercsv', files);
        // const payloadData = { id: eventId, csvdata: formData };
        // eventAction.importRegistrationRequest(payloadData);
      };
    }
  };

  const handleUploadFileClick = () => {
    if (InputFile.current) {
      InputFile.current.click();
    }
  };

  useEffect(() => {
    if (excelData && excelData.length && !showTypeError) {
      setSelectedOption(3);
    }
  }, [excelData]);

  const convertToCsv = (dataArray: any) => {
    const csvContent = dataArray.map((row: any) => row.join(',')).join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });
    return blob;
  };

  const handlePasteExcel = (e: any) => {
    const excelData = constructJSONFromPastedInput(e.target.value);
    const data = convertToCsv(excelData);
    setFileData(data);
    const arrayToStringData = excelData.map((item) => item.toString());
    setExcelData(arrayToStringData);
  };

  const constructJSONFromPastedInput = (pastedInput: any) => {
    const finalCopyExcelData = [];
    const rawRows = pastedInput.split('\n').filter((item: string) => item !== '');
    const headersArray = rawRows[0].split('\t');
    finalCopyExcelData.push(headersArray);
    rawRows.forEach((rawRow: string, idx: number) => {
      if (idx > 0) {
        const values = rawRow.split('\t');
        finalCopyExcelData.push(values);
      }
    });
    return finalCopyExcelData;
  };

  useEffect(() => {
    if (isRegistrationImported) {
      ref.current?.click();
    }
  }, [isRegistrationImported]);

  return (
    <div>
      <div
        className="modal fade"
        id="ImportOption"
        aria-labelledby="ImportOptionLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddNewTagLabel">
                {continueFlag && selectedOption === 1
                  ? 'Select Fields To Map'
                  : 'Map Registration Data'}
              </h5>
              <button
                onClick={() => {
                  setSelectedOption(1), setContinueFlag(false);
                }}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            {showTypeError && (
              <p className="flex justify-center text-red-500">Please upload correct csv file</p>
            )}
            {!continueFlag && (
              <div>
                <div className="grid grid-cols-2 gap-4 px-4 py-12 cursor-pointer">
                  <div className="border-2 px-4 py-6 option-border">
                    <div className="flex justify-between">
                      <i className="fa fa-upload"></i>
                      <input
                        type="radio"
                        name="option"
                        checked={selectedOption === 1}
                        onChange={() => setSelectedOption(1)}
                      />
                    </div>
                    <div className="mt-3 font-bold">Upload a File</div>
                    <h3>Import contacts from a CSV</h3>
                  </div>

                  <div className="border-2 px-4 py-6 option-border">
                    <div className="flex justify-between">
                      <i className="fa fa-copy"></i>
                      <input
                        type="radio"
                        name="option"
                        checked={selectedOption === 2}
                        onChange={() => setSelectedOption(2)}
                      />
                    </div>
                    <div className="mt-3 font-bold">Copy and Paste</div>
                    <h3>Directly paste in new contacts from a spreadsheet or similar list</h3>
                  </div>
                </div>
                <div className="px-4 mb-2">
                  <button
                    onClick={() => setContinueFlag(true)}
                    type="button"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Continue
                  </button>
                </div>
              </div>
            )}
            {continueFlag && selectedOption === 3 && !showTypeError && (
              <SelectField
                setSelectedOption={setSelectedOption}
                excelData={excelData}
                demoImportFileData={demoImportFileData}
                fileData={fileData}
                setFileData={setFileData}
              />
            )}
            {continueFlag && selectedOption === 1 && (
              <div className="px-10 py-10 flex justify-center">
                <button
                  onClick={handleUploadFileClick}
                  type="button"
                  className="px-6 btn-primary w-max mr-2 ml-0 mt-0">
                  Import Registrations from CSV
                </button>
              </div>
            )}
            {continueFlag && selectedOption === 2 && (
              <div className="px-10 py-10 flex justify-center">
                <textarea
                  rows={6}
                  placeholder="Paste your excel form data here..."
                  onChange={handlePasteExcel}
                />
              </div>
            )}
            <input
              ref={InputFile}
              type="file"
              className="hidden"
              onChange={(e) => handleFileUpload(e.target.files)}
              onClick={onInputClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportOption;
