import React, { useEffect, useState, useRef } from 'react';
import AddTemplate from './AddTemplate';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import UpdateQuizModal from './UpdateQuizModal';
import UpdateStarRatingModal from './UpdateStarRatingModal';
import UpdateMultipleChoiceModal from './UpdateMultipleModal';
import UpdateOpenTextModal from './UpdateOpenTextModal';
import { RootState } from 'src/store/reducers/state';
const Templates = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const [pollType, setPollType] = useState<number>();
  const quizRef = useRef<HTMLButtonElement>(null);
  const starRatingRef = useRef<HTMLButtonElement>(null);
  const multipleChoiceRef = useRef<HTMLButtonElement>(null);
  const openTextRef = useRef<HTMLButtonElement>(null);
  const { templates } = useSelector((state: RootState) => {
    return {
      templates: state.setting.templates
    };
  });

  useEffect(() => {
    settingAction.getTemplatesRequest();
  }, []);

  const handleEditClick = (type: string) => {
    if (type === 'Quiz') {
      if (quizRef.current) {
        quizRef.current.click();
      }
    } else if (type === 'starRating') {
      if (starRatingRef.current) {
        starRatingRef.current.click();
      }
    } else if (type === 'multipleChoice') {
      if (multipleChoiceRef.current) {
        multipleChoiceRef.current.click();
      }
    } else if (type === 'openText') {
      if (openTextRef.current) {
        openTextRef.current.click();
      }
    }
  };
  return (
    <>
      <div className="tab-pane fade" id="tabs-regFields" role="tabpanel">
        <div className="md:p-5 p-3">
          <div className="py-5 px-7">
            <div className="text-right">
              <div className="dropdown relative">
                <button
                  className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Create Poll Template
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-auto mt-0 mr-0 ml-auto mb-4 "
                  aria-labelledby="action-dropdown">
                  <li
                    onClick={() => setPollType(0)}
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#AddTemplate">
                    <span className="action-menu">Quiz</span>
                  </li>
                  <li
                    onClick={() => setPollType(1)}
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#AddTemplate">
                    <span className="action-menu">Multiple Choice</span>
                  </li>
                  <li
                    onClick={() => setPollType(2)}
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#AddTemplate">
                    <span className="action-menu">Star Rating</span>
                  </li>
                  <li
                    onClick={() => setPollType(3)}
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#AddTemplate">
                    <span className="action-menu">Open Text</span>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <table className="table-auto w-full border">
                <thead className="text-xs font-semibold uppercase bg-gray-100">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Template Name</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left"> Poll Type</div>
                    </th>
                    <th className="p-2">
                      <div className="text-left invisible">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {templates &&
                    templates.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="p-2">{item.name}</td>
                          <td className="p-2"> {item.pollType}</td>
                          <td className="p-2">
                            <div className="dropdown relative">
                              <a
                                className="
                        dropdown-toggle
                        transition
                        duration-150
                        ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                href="#"
                                type="button"
                                id="ActionButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <ul
                                className="dropdown-menu action-dropdown"
                                aria-labelledby="ActionButton">
                                <li onClick={() => handleEditClick(item.pollType)}>
                                  <span className="dropdown-item flex-items cursor-pointer">
                                    <i className="fas fa-edit w-6"></i>
                                    <span>Edit</span>
                                  </span>
                                </li>
                                <li>
                                  <span
                                    className="dropdown-item flex-items cursor-pointer text-red-500"
                                    data-bs-toggle="modal"
                                    data-bs-target="#DeleteTemplateModal">
                                    <i className="fas fa-trash w-6"></i>
                                    <span>Delete</span>
                                  </span>
                                </li>
                              </ul>
                              <button
                                ref={quizRef}
                                type="button"
                                className="hidden"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateQuizModal"></button>
                              <button
                                ref={starRatingRef}
                                type="button"
                                className="hidden"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateStarRatingModal"></button>
                              <button
                                ref={multipleChoiceRef}
                                type="button"
                                className="hidden"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateMultipleChoiceModal"></button>
                              <button
                                ref={openTextRef}
                                type="button"
                                className="hidden"
                                data-bs-toggle="modal"
                                data-bs-target="#UpdateOpenTextModal"></button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <AddTemplate pollType={pollType} />
      </div>
      <UpdateQuizModal />
      <UpdateStarRatingModal />
      <UpdateMultipleChoiceModal />
      <UpdateOpenTextModal />
    </>
  );
};

export default Templates;
