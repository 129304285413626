import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
const EndStreamingBroadcastModal = ({
  endingTime,
  endStreamingBroadcastFlag,
  setCancelStreamingClick,
  setEndNowStreamingClick
}: any) => {
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const { mediaChannelStatus } = useSelector((state: RootState) => {
    return {
      mediaChannelStatus: state.videos.mediaChannelStatus
    };
  });
  useEffect(() => {
    if (!endStreamingBroadcastFlag && mediaChannelStatus === 'IDLE') {
      if (closeBtnRef.current) {
        closeBtnRef.current.click();
      }
    }
  }, [endStreamingBroadcastFlag, mediaChannelStatus]);

  const handleCancelClick = () => {
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
    }
  };
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="streamingBroadcastModal"
      aria-labelledby="streamingBroadcastModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <button
              ref={closeBtnRef}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <h2 className="font-bold text-xl text-center text-red-600">Live Broadcast is Ending</h2>
            {mediaChannelStatus === 'STOPPING' ? (
              <h1 className="font-bold text-5xl text-center mt-5 text-red-600">Stopping</h1>
            ) : (
              <h1 className="font-bold text-5xl text-center mt-5 text-red-600">{endingTime}</h1>
            )}

            <p className="mt-5">
              <Loader />
            </p>

            <p className="font-semibold mt-5">
              Do not close your browser. Please wait for broadcast to end after 60 seconds.{' '}
            </p>
            <div className="flex justify-between mt-3">
              <button
                type="button"
                onClick={() => {
                  setCancelStreamingClick(true), handleCancelClick();
                }}
                className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Cancel
              </button>
              <button
                onClick={() => setEndNowStreamingClick(true)}
                type="button"
                className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                End Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndStreamingBroadcastModal;
