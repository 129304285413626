import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
import { defaultDateFormatter, successMsg } from 'src/components/utilities/utils';
import { useCourseActions } from 'src/store/course/actions';
import ToastMessage from 'src/components/ToastContainer';
import { CourseVideo, CourseVideoSequenceModel } from 'src/models/courseModel';
import Pagination from 'src/components/common/pagination';
import DeleteCourseModal from '../components/DeleteCourseModal';
import { DebounceInput } from 'react-debounce-input';
const Courses: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAction = useUserActions(dispatch);
  const courseAction = useCourseActions(dispatch);
  const [courseItems, setCourseItems] = useState();
  const [expandedCourseId, setExpandedCourseId] = useState('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [courseId, setCourseId] = useState<string>('');
  const [courseVideosOrdered, setCourseVideosOrdered] = useState<any>([]);
  const {
    courses,
    isCourseSaved,
    isCourseUpdated,
    isCourseDeleted,
    error,
    success,
    loader,
    courseModules,
    courseVideos,
    loadingCourseVideo,
    message,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      courses: state.course.course,
      isCourseSaved: state.course.isCourseSaved,
      isCourseUpdated: state.course.isCourseUpdated,
      isCourseDeleted: state.course.isCourseDeleted,
      error: state.course.error,
      success: state.course.success,
      loader: state.course.loading,
      courseModules: state.course.courseModule,
      courseVideos: state.course.courseVideos,
      loadingCourseVideo: state.course.loadingCourseVideo,
      message: state.course.message,
      paginationMeta: state.course.paginationMeta
    };
  });

  const status = [
    { value: '1', label: 'Draft' },
    { value: '2', label: 'Online' },
    { value: '3', label: 'Offline' }
  ];

  const completionType = [
    { value: '1', label: 'Quiz Completion' },
    { value: '2', label: 'Video Completion' }
  ];

  const handleAddCourse = (id: string, action: string) => {
    if (action === 'edit') window.open(`/courseTab/${id}`, '_blank');
    else if (action === 'add') navigate(`/courseTab`);
  };

  const handleCoureResultNavigate = (id: string) => {
    navigate(`/analytic/singleCourse/${id}`);
  };

  const handleDeleteCourse = (courseId: string) => {
    setCourseId(courseId);
  };

  const copyTextToClipboard = (courseUrl: string) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(`${courseUrl}`);
      successMsg('Url copied to clipboard!');
    } else {
      document.execCommand('copy', true, `${courseUrl}`);
    }
  };

  const orderCourseVideos = (courseName: string, position: number) => {
    const tempCourseVideos = [...courseVideos];
    const removedCourseVideo: CourseVideo = tempCourseVideos.filter(
      (v) => v.title == courseName
    )[0];
    tempCourseVideos.splice(position, 1);
    tempCourseVideos.splice(position, 0, removedCourseVideo);

    const updateCourseVideos: CourseVideoSequenceModel = {
      id: expandedCourseId,
      videoIds: tempCourseVideos.map((a) => a.id)
    };
    courseAction.saveCourseVideoSequenceRequest(updateCourseVideos);
  };

  useEffect(() => {
    courseAction.getCourseRequest({ pageNumber: 1, pageSize: 20, querySearch: '' });
  }, [isCourseSaved, isCourseUpdated, isCourseDeleted]);

  useEffect(() => {
    if (!courseModules || courseModules.length == 0) {
      courseAction.getCourseModuleRequest();
    }
  }, []);

  const onRowClick = (courseId: string) => {
    courseAction.getCourseVideosRequest(courseId);
    setExpandedCourseId(courseId);
  };

  useEffect(() => {
    if (isCourseSaved || isCourseUpdated) {
      courseAction.resetCourseStateRequest();
      successMsg(message);
    }
  }, [isCourseSaved, isCourseUpdated]);

  const handlePageChange = (pageNumber: number) => {
    courseAction.getCourseRequest({ pageNumber, pageSize: 20, querySearch: searchValue });
  };
  const handleEditVideoClick = (videoId: string) => {
    window.open(`/videos/${videoId}`, '_blank');
  };
  const handleSearchCourse = (e: string) => {
    setSearchValue(e);
    courseAction.getCourseRequest({
      querySearch: e,
      pageNumber: 1,
      pageSize: 10
    });
  };

  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Courses</h2>
                <div className="md:flex items-center text-center">
                  <div className="form-group md:flex items-center text-center">
                    <label className="mr-1 font-semibold">Search:</label>
                    <DebounceInput
                      placeholder="Search"
                      minLength={1}
                      className="input-style w-48 mr-3 mb-1"
                      debounceTimeout={500}
                      onChange={(event) => handleSearchCourse(event.target.value)}
                    />
                  </div>
                  <button
                    onClick={() => {
                      handleAddCourse('', 'add');
                    }}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className={`overflow-x-auto-sm`}>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Channel Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Status</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-center">Modules</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Credits</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-center">Credit Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Thumbnails</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Price</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Pass Rate Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Pass Rate</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Created Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loader ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {courses.length ? (
                            <>
                              {courses &&
                                courses.map((course, index) => {
                                  return (
                                    <>
                                      <tr
                                        onClick={() => {
                                          onRowClick(course.id);
                                        }}
                                        key={index}
                                        style={{ cursor: 'pointer' }}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${course.title}`}</div>
                                          </div>
                                        </td>
                                        <td
                                          className="p-2 accordion-button collapsed"
                                          data-bs-toggle="collapse"
                                          data-bs-target={'#collapse' + course.id}
                                          aria-expanded="false"
                                          aria-controls={'collapse' + course.id}>
                                          <div className="text-left flex flex-col">
                                            <span>{`${course.channelName}`}</span>
                                            {course.parentChannelName && (
                                              <span
                                                className="text-left p-2 italic video-view-badge"
                                                style={{ width: 'fit-content' }}>
                                                Parent: {course.parentChannelName}
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {status
                                              .filter((i) => i.value == course.status?.toString())
                                              .map((filteredStatus) => filteredStatus.label)}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left flex flex-col">
                                            {course.modules &&
                                              course?.modules?.map((item: any) => {
                                                return (
                                                  <span
                                                    className="video-view-badge w-auto whitespace-nowrap"
                                                    key={item.id}>
                                                    {item.name}
                                                  </span>
                                                );
                                              })}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{course.points}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left flex flex-col">
                                            {course.creditTypes &&
                                              course?.creditTypes?.map((item: any) => {
                                                const isLong = item.name.length > 8;
                                                return (
                                                  <span
                                                    className={`video-view-badge w-auto whitespace-nowrap ${
                                                      isLong ? 'truncate' : ''
                                                    }`}
                                                    key={item.id}
                                                    title={isLong ? item.name : ''} // Show full text on hover
                                                  >
                                                    {isLong
                                                      ? `${item.name.slice(0, 8)}...`
                                                      : item.name}
                                                  </span>
                                                );
                                              })}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="font-medium">
                                            <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                                              <img
                                                // className="rounded-full"
                                                src={course.thumbnailUrl}
                                                style={{ height: '40px' }}
                                                width="50"
                                                height="40"
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{course.price}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {completionType
                                              .filter(
                                                (i) =>
                                                  i.value == course.courseCompletionType.toString()
                                              )
                                              .map(
                                                (filteredCompletionType) =>
                                                  filteredCompletionType.label
                                              )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {course.courseCompletionPercentage}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left whitespace-nowrap">
                                            {course.createdDate
                                              ? defaultDateFormatter(course.createdDate)
                                              : ''}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            <div className="dropdown relative">
                                              <button
                                                className="dropdown-toggle"
                                                onClick={(e: any) => {
                                                  e.stopPropagation();
                                                }}
                                                type="button"
                                                id="action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img
                                                  src="../../assets/img/dots-vertical.svg"
                                                  className="w-5 h-5"
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                  }}
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu dropdown-menu-ul"
                                                aria-labelledby="action-dropdown">
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      handleAddCourse(course.id, 'edit');
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fas fa-edit w-6 text-left"></i>
                                                    <span>Edit</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <span
                                                    className="action-menu flex items-center cursor-pointer text-red-500"
                                                    onClick={() => handleDeleteCourse(course.id)}
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#DeleteCourseModal">
                                                    <i className="fas fa-trash w-6 text-left"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                                <li>
                                                  <span
                                                    className="action-menu flex items-center"
                                                    onClick={() =>
                                                      handleCoureResultNavigate(course.id)
                                                    }>
                                                    <i className="fas fa-sticky-note w-6 text-left"></i>
                                                    <span>Results</span>
                                                  </span>
                                                </li>
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      copyTextToClipboard(course.courseUrl);
                                                    }}
                                                    className="action-menu flex items-center">
                                                    <i className="fa fa-link w-6 text-left"></i>
                                                    <span>Copy Link</span>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr
                                        id={'collapse' + course.id}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <td colSpan={10}>
                                          <div>
                                            {loadingCourseVideo ? (
                                              <Loader />
                                            ) : (
                                              <>
                                                {' '}
                                                {courseVideos &&
                                                courseVideos.length &&
                                                course.id == expandedCourseId ? (
                                                  <>
                                                    {courseVideos &&
                                                      courseVideos.map((item) => {
                                                        return (
                                                          <div
                                                            key={item.id}
                                                            className="dashboard-card flex-items justify-center mt-2">
                                                            <div className=" w-full p-4 flex-col">
                                                              <div className="flex justify-between">
                                                                <div>
                                                                  <span>
                                                                    <i className="fa fa-camera"></i>
                                                                  </span>
                                                                  <span className="text-sm font-semibold ml-2">
                                                                    {item.title}
                                                                  </span>
                                                                </div>
                                                                <span
                                                                  className="cursor-pointer"
                                                                  onClick={() =>
                                                                    handleEditVideoClick(item.id)
                                                                  }>
                                                                  <i className="fas fa-edit"></i>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                  </>
                                                ) : (
                                                  <div> No Videos available</div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteCourseModal courseId={courseId} />
    </>
  );
};

export default Courses;
