import React from 'react';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

interface IProps {
  label: string;
  fieldName: string;
  register: any;
  errors: any;
  isRequired: boolean;
  maxLength?: number;
  rows?: number;
  cols?: number;
}

const FormTextareaField: React.FC<IProps> = ({
  label,
  fieldName,
  register,
  errors,
  isRequired,
  maxLength,
  rows,
  cols
}) => {
  return (
    <>
      <label className="block text-sm font-medium mb-1">
        {' '}
        {label} {isRequired ? <span className="text-red-500">*</span> : ''}
      </label>
      <textarea
        rows={rows}
        className="input-style"
        id={fieldName}
        {...register(fieldName, {
          required: isRequired,
          maxLength: maxLength
        })}
      />
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
      {errors[fieldName]?.type === 'maxLength' && (
        <FormFieldError message={AppConstants.maxLengthReached} />
      )}
    </>
  );
};

export default FormTextareaField;
