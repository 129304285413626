import { all, put, takeEvery } from 'redux-saga/effects';
import { DashboardActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { PieChartData } from 'src/components/charts/piechart/data';
import { URLS } from 'src/components/utilities/endpoints';
import moment from 'moment';
function* asyncGetDashboardData() {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(DashboardActions.getDashboardRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getDashboardRequestFailure());
    }
}
function* asyncGetSalesOrderData(data: DashboardActions.getSalesOrderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SALES_ORDER.url}?year=${data.payload?.year}`);
        if (response.status === 200) {
            yield put(DashboardActions.getSalesOrderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getDashboardRequestFailure());
    }
}
function* asyncGetTopPayingCustomerData() {
    const data = [
        {
            id: 1,
            name: 'Alex',
            email: 'alex@gmail.com',
            spent: 564,
            createdDate: '07/27/2022',
            country: 'US'
        },
        {
            id: 2,
            name: 'Peter',
            email: 'peter@gmail.com',
            spent: 764,
            createdDate: '07/27/2022',
            country: 'US'
        },
        {
            id: 3,
            name: 'John',
            email: 'john@gmail.com',
            spent: 898,
            createdDate: '07/27/2022',
            country: 'US'
        },
        {
            id: 4,
            name: 'Rock',
            email: 'rock@gmail.com',
            spent: 322,
            createdDate: '07/27/2022',
            country: 'US'
        }
    ]
    try {
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        yield put(DashboardActions.getTopPayingCustomerRequestSuccess(data));
        // }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getTopPayingCustomerRequestFailure());
    }
}
function* asyncGetDeveloperMeetup() {
    const data = {
        sortFieldName: "VideoDateTime",
        pageNumber: 1,
        pageSize: 1,
        videoStatus: [
            0, 1
        ],
        createdDateFrom: moment().format('YYYY-MM-DD')

    }
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_DEVELOPER_MEETUP.url, data);
        if (response.status === 200) {
            yield put(DashboardActions.getDeveloperMeetupRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getDeveloperMeetupRequestFailure());
    }
}
function* asyncGetUserTimeline() {
    const data = [
        {
            id: 1,
            title: '12 Invoices have been paid',
            description: 'Invoices have been paid to the company.',
        },
        {
            id: 2,
            title: 'Client Meeting',
            description: 'Project meeting with John @10:15am.',
        },
        {
            id: 3,
            title: 'Create a new project for client',
            description: 'Add files to new design folder',
        },
        {
            id: 4,
            title: 'Create a new project for client',
            description: 'Add files to new design folder',
        },
    ]
    try {
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        yield put(DashboardActions.getUserTimeLineRequestSuccess(data));
        // }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getUserTimeLineRequestFailure());
    }
}
function* asyncGetSalesRatio() {
    try {
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        yield put(DashboardActions.getSalesRatioRequestSuccess(PieChartData));
        // }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getSalesRatioRequestFailure());
    }
}
function* asyncGetMostPopularVideo(data: DashboardActions.getMostPopularVideoRequestAction) {
    try {
        if (data.payload) {
            const { fromDate, toDate } = data.payload
            const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MOST_POPULAR_VIDEO.url}?PageSize=10&PageNumber=1&startDate=${fromDate ? fromDate : ''}`);
            if (response.status === 200) {
                yield put(DashboardActions.getMostPopularVideoRequestSuccess(response.data.result));
            }
        }

    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getMostPopularVideoRequestFailure());
    }
}
function* asyncGetMemberActivity(data: DashboardActions.getMemberActivityRequestAction) {
    try {
        if (data.payload?.memberValue === 0) {
            const response: AxiosResponse = yield ApiService.postData(URLS.GET_MEMBER_LOGINS.url, {
                fromdate: data.payload.watchFromDate ? data.payload.watchFromDate : '',
                todate: data.payload.watchToDate ? data.payload.watchToDate : '',
                pageNumber: 1,
                pageSize: 30,
                granularityFilter: data.payload.granularityFilter
            });
            if (response.status === 200) {
                yield put(DashboardActions.getMemberActivityRequestSuccess(response.data.result));
            }
        } else if (data.payload?.memberValue === 1) {
            const response: AxiosResponse = yield ApiService.postData(URLS.GET_MEMBER_VIEWS.url, {
                watchFromDate: data.payload.watchFromDate ? data.payload.watchFromDate : '',
                watchToDate: data.payload.watchToDate ? data.payload.watchToDate : '',
                pageNumber: 1,
                pageSize: 30,
                granularityFilter: data.payload.granularityFilter
            });
            if (response.status === 200) {
                yield put(DashboardActions.getMemberActivityRequestSuccess(response.data.result));
            }
        }

    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getMemberActivityRequestFailure());
    }
}
function* asyncGetSales() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SALES.url);
        if (response.status === 200) {
            const receivedData = response.data.result.chart
            const stringToArrayData = receivedData.map((item: string) => {
                return (
                    item.split(",")
                )
            })
            const stringValueToArray = stringToArrayData.map((item: Array<string>) => {
                const intData = item.map((value: string) => {
                    return parseInt(value)
                })
                return intData

            })
            const finalData = stringValueToArray.map((item: Array<number>) => {
                return (
                    {
                        data: item,
                    }
                )
            })

            yield put(DashboardActions.getSalesRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getSalesRequestFailure());
    }
}
function* asyncGetWhatsNew() {
    const data = [
        {
            id: 1,
            title: 'New features and improvements are dropping every month!',
            date: "June 22,2022",
            heading1: "Stay on-top of what 's new by checking out our latest",
            heading2: "Start using the Recommendations to enhance your video library and member experience. Turn on these key features today:",

        },

    ]
    try {
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        yield put(DashboardActions.getWhatsNewRequestSuccess(data));
        // }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getWhatsNewRequestFailure());
    }
}
function* asyncGetSearchedMostPopularVideo(data: DashboardActions.getSearchedMostPopularVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(DashboardActions.getSearchedMostPopularVideoRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getSearchedMostPopularVideoRequestFailure());
    }
}
function* asyncGetSearchMemberActivity(data: DashboardActions.getSearchMemberActivityRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(DashboardActions.getSearchMemberActivityRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getSearchMemberActivityRequestFailure());
    }
}
function* asyncGetTotalDashboardData(data: DashboardActions.getTotalDashboardDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_AGGREGATE.url);
        if (response.status === 200) {
            const finalData =
            {
                totalChannels: response.data.result.totalChannels,
                totalVideos: response.data.result.totalVideos,
                totalMembers: response.data.result.totalMembers,
                currentStorage: response.data.result.totalStorage,
            }

            yield put(DashboardActions.getTotalDashboardDataRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getTotalDashboardDataRequestFailure());
    }
}
function* asyncSaveCustomizeTheme(data: DashboardActions.saveCustomizeThemeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(DashboardActions.saveCustomizeThemeRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.saveCustomizeThemeRequestFailure());
    }
}
function* asyncGetSalesOrderStatsData(data: DashboardActions.getSalesOrderStatsDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SALES_ORDER_STATS.url}?year=${data.payload}`);
        if (response.status === 200) {
            const finalData = [
                {
                    name: '',
                    total: 0
                },
                {
                    name: 'Videos',
                    total: response.data.result.totalVideoSales
                },
                {
                    name: 'Subscriptions',
                    total: response.data.result.totalSubscriptions
                },
                {
                    name: 'Channels',
                    total: response.data.result.totalChannelSales
                },
            ]
            yield put(DashboardActions.getSalesOrderStatsRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getSalesOrderStatsRequestFailure());
    }
}
function* asyncGetPreviousYearSalesOrderData(data: DashboardActions.getPreviousYearSalesOrderDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SALES_ORDER.url}?year=${data.payload?.year}`);
        if (response.status === 200) {
            yield put(DashboardActions.getPreviousYearSalesOrderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getPreviousYearSalesOrderRequestFailure());
    }
}
function* asyncGetTopSellingProducts(data: DashboardActions.getTopSellingProductsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_TOP_SELLING_PRODUCT.url, data.payload);
        if (response.status === 200) {
            yield put(DashboardActions.getTopSellingProductsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(DashboardActions.getTopSellingProductsRequestFailure());
    }
}
export function* dashboardSaga() {
    yield all([
        takeEvery(Constants.getDashboardRequestActionType, asyncGetDashboardData),
        takeEvery(Constants.getSalesOrderDataRequestActionType, asyncGetSalesOrderData),
        takeEvery(Constants.getTopPayingCustomerRequestActionType, asyncGetTopPayingCustomerData),
        takeEvery(Constants.getDeveloperMeetupRequestActionType, asyncGetDeveloperMeetup),
        takeEvery(Constants.getUserTimeLineRequestActionType, asyncGetUserTimeline),
        takeEvery(Constants.getSalesRatioRequestActionType, asyncGetSalesRatio),
        takeEvery(Constants.getMostPopularVideoRequestActionType, asyncGetMostPopularVideo),
        takeEvery(Constants.getMemberActivityRequestActionType, asyncGetMemberActivity),
        takeEvery(Constants.getWhatsNewRequestActionType, asyncGetWhatsNew),
        takeEvery(Constants.getSalesRequestActionType, asyncGetSales),
        takeEvery(Constants.getSearchedMostPopularVideoRequestActionType, asyncGetSearchedMostPopularVideo),
        takeEvery(Constants.getSearchMemberActivityRequestActionType, asyncGetSearchMemberActivity),
        takeEvery(Constants.getTotalDashboardDataRequestActionType, asyncGetTotalDashboardData),
        takeEvery(Constants.saveCustomizeThemeRequestActionType, asyncSaveCustomizeTheme),
        takeEvery(Constants.getSalesOrderStatsDataRequestActionType, asyncGetSalesOrderStatsData),
        takeEvery(Constants.getPreviousYearSalesOrderDataRequestActionType, asyncGetPreviousYearSalesOrderData),
        takeEvery(Constants.getTopSellingProductsRequestActionType, asyncGetTopSellingProducts),
    ]);
}
