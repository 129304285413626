import React from 'react';
import { Link } from 'react-router-dom';

const UnAuthorized: React.FC = () => {
  return (
    <div className="flex justify-center items-center not-found">
      <div className="text-center">
        <div className="py-3">
          <h6 className="text-2xl font-bold text-black dark:text-[#ccc] mb-1">Not Authorized</h6>
          <p className="text-gray-500 dark:text-[#ccc] mb-3">
          You are not authorized to view the requested page. You can click your back button. We apologize for the inconvenience.
          </p>
          <Link to={`/`} className="btn-primary w-28 text-white hover:text-white mt-0">
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnAuthorized;
