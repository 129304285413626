import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useCategoryActions } from 'src/store/category/actions';
import { useCourseActions } from 'src/store/course/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { AddCategoryModel, CategoryData } from 'src/models/categoryModel';
import Pagination from 'src/components/common/pagination';
import { errorMsg } from 'src/components/utilities/utils';
import { successMsg } from 'src/components/utilities/utils';
import { DebounceInput } from 'react-debounce-input';
import { useParams } from 'react-router-dom';
import AddChapterModel from './AddChapterModel';
import ToastMessage from 'src/components/ToastContainer';
const ChapterList = () => {
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);
  const courseAction = useCourseActions(dispatch);
  const { id } = useParams<string>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [chapterAddFlag, setChapterAddFlag] = useState<string>('Add');
  const { isChapterAdded, paginationMeta, chapters, isChapterLoading, isChapterDeleted } =
    useSelector((state: RootState) => {
      return {
        isChapterAdded: state.course.isChapterAdded,
        paginationMeta: state.course.paginationMeta,
        chapters: state.course.chapters,
        isChapterLoading: state.course.isChapterLoading,
        isChapterDeleted: state.course.isChapterDeleted
      };
    });

  useEffect(() => {
    courseAction.getChaptersRequest({ pageNumber: 1, id });
  }, []);

  useEffect(() => {
    if (isChapterAdded || isChapterDeleted) {
      courseAction.getChaptersRequest({ pageNumber: 1, id });
    }
  }, [isChapterAdded, isChapterDeleted]);

  useEffect(() => {
    if (isChapterAdded || isChapterDeleted) {
      successMsg('Success');
      courseAction.resetCourseStateRequest();
    }
  }, [isChapterAdded, isChapterDeleted]);

  const handleEditClick = (id: string) => {
    courseAction.getSingleChapterRequest(id);
    setChapterAddFlag('edit');
  };

  const handlePageChange = (pageNumber: number) => {
    const data = { querySearch: searchValue, pageNumber };
    courseAction.getChaptersRequest(data);
  };

  const handleDeleteClick = (id: string) => {
    courseAction.deleteChapterRequest(id);
  };
  return (
    <>
      <ToastMessage />
      <div className="video-categories">
        <div className="lg:flex">
          <div className="tab-content w-full shadow border" id="tabs-tabContentVertical">
            <div className="tab-pane fade show active" id="tabs-videoCategories" role="tabpanel">
              <div className="py-5 px-7">
                <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                  <h2 className="font-semibold ">Chapters</h2>
                  <div className="md:flex items-center text-center">
                    <button
                      onClick={() => {
                        setChapterAddFlag('Add');
                      }}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#AddChapterModel"
                      className="btn-primary w-auto mt-0 mr-0 ml-auto  borderonly-btn">
                      Add
                    </button>
                    <button
                      ref={btnRef}
                      className="hidden"
                      data-bs-toggle="modal"
                      data-bs-target="#categoryList">
                      demo
                    </button>
                  </div>
                </div>
                <Link to={`/courseTab/${id}`}>
                  <i className="fas fa-arrow-left mt-2 mb-2"></i>
                  <span className="ml-2">Go Back</span>
                </Link>
                <div className="overflow-x-auto-sm">
                  <table className="table-auto w-full border categories-table-wrapper">
                    <thead className="text-xs font-semibold uppercase bg-gray-100">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Description</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isChapterLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {chapters && chapters.length ? (
                            <>
                              {chapters &&
                                chapters.map((chapters) => {
                                  return (
                                    <tr key={chapters.id}>
                                      <td className="p-2">{chapters.title}</td>
                                      <td className="p-2 multiline-wrap-text multiline-text">
                                        {chapters.description}
                                      </td>
                                      <td className="p-2">
                                        <div className="dropdown relative">
                                          <a
                                            className="
                                        dropdown-toggle
                                        transition
                                        duration-150
                                        ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                            href="#"
                                            type="button"
                                            id="ActionButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </a>
                                          <ul
                                            className="dropdown-menu action-dropdown"
                                            aria-labelledby="ActionButton">
                                            <li
                                              onClick={() => handleEditClick(chapters.id)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#AddChapterModel">
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-edit w-6"></i>
                                                <span>Edit</span>
                                              </span>
                                            </li>
                                            <li onClick={() => handleDeleteClick(chapters.id)}>
                                              <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                                <i className="fas fa-trash w-6"></i>
                                                <span>Delete</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddChapterModel chapterAddFlag={chapterAddFlag} />
    </>
  );
};

export default ChapterList;
