
export const getSpotlightRowRequestActionType = 'GET_SPOTLIGHT_ROW_REQUEST'
export const getSpotlightRowRequestSuccessActionType = 'GET_SPOTLIGHT_ROW_REQUEST_SUCCESS'
export const getSpotlightRowRequestFailureActionType = 'GET_SPOTLIGHT_ROW_REQUEST_FAILURE'
export const saveHighlightReelRequestActionType = 'SAVE_HIGHLIGHT_REEL_REQUEST'
export const saveHighlightReelRequestSuccessActionType = 'SAVE_HIGHLIGHT_REEL_REQUEST_SUCCESS'
export const saveHighlightReelRequestFailureActionType = 'SAVE_HIGHLIGHT_REEL_REQUEST_FAILURE'
export const saveCustomizeThemeHeaderRequestActionType = 'SAVE_CUSTOMIZE_THEME_HEADER_REQUEST'
export const saveCustomizeThemeHeaderRequestSuccessActionType = 'SAVE_CUSTOMIZE_THEME_HEADER_REQUEST_SUCCESS'
export const saveCustomizeThemeHeaderRequestFailureActionType = 'SAVE_CUSTOMIZE_THEME_HEADER_REQUEST_FAILURE'
export const saveSpotlightRowsRecommendationRequestActionType = 'SAVE_SPOTLIGHTROWS_RECOMMENDATION_REQUEST'
export const saveSpotlightRowsRecommendationRequestSuccessActionType = 'SAVE_SPOTLIGHTROWS_RECOMMENDATION_REQUEST_SUCCESS'
export const saveSpotlightRowsRecommendationRequestFailureActionType = 'SAVE_SPOTLIGHTROWS_RECOMMENDATION_REQUEST_FAILURE'
export const getTotalRecordsRequestActionType = 'GET_TOTAL_RECORDS_REQUEST'
export const getTotalRecordsRequestSuccessActionType = 'GET_TOTAL_RECORDS_REQUEST_SUCCESS'
export const getTotalRecordsRequestFailureActionType = 'GET_TOTAL_RECORDS_REQUEST_FAILURE'
export const getAuthenticationRequestActionType = 'GET_AUTHENTICATION_REQUEST'
export const getAuthenticationRequestSuccessActionType = 'GET_AUTHENTICATION_REQUEST_SUCCESS'
export const getAuthenticationRequestFailureActionType = 'GET_AUTHENTICATION_REQUEST_FAILURE'
export const saveAuthenticationRequestActionType = 'SAVE_AUTHENTICATION_REQUEST'
export const saveAuthenticationRequestSuccessActionType = 'SAVE_AUTHENTICATION_REQUEST_SUCCESS'
export const saveAuthenticationRequestFailureActionType = 'SAVE_AUTHENTICATION_REQUEST_FAILURE'
export const resetVideoLibraryStateRequestActionType = 'RESET_VIDEO_LIBRARY_STATE_REQUEST'
export const getRegistrationOptionsRequestActionType = 'GET_REGISTRATION_OPTIONS_REQUEST'
export const getRegistrationOptionsRequestSuccessActionType = 'GET_REGISTRATION_OPTIONS_REQUEST_SUCCESS'
export const getRegistrationOptionsRequestFailureActionType = 'GET_REGISTRATION_OPTIONS_REQUEST_FAILURE'
export const addRegistrationOptionsRequestActionType = 'ADD_REGISTRATION_OPTIONS_REQUEST'
export const addRegistrationOptionsRequestSuccessActionType = 'ADD_REGISTRATION_OPTIONS_REQUEST_SUCCESS'
export const addRegistrationOptionsRequestFailureActionType = 'ADD_REGISTRATION_OPTIONS_REQUEST_FAILURE'
export const deleteRegistrationOptionRequestActionType = 'DELETE_REGISTRATION_OPTION_REQUEST'
export const deleteRegistrationOptionRequestSuccessActionType = 'DELETE_REGISTRATION_OPTION_REQUEST_SUCCESS'
export const deleteRegistrationOptionRequestFailureActionType = 'DELETE_REGISTRATION_OPTION_REQUEST_FAILURE'
export const getStandardFieldsRequestActionType = 'GET_STANDARD_FIELDS_REQUEST'
export const getStandardFieldsRequestSuccessActionType = 'GET_STANDARD_FIELDS_REQUEST_SUCCESS'
export const getStandardFieldsRequestFailureActionType = 'GET_STANDARD_FIELDS_REQUEST_FAILURE'
export const saveStandardFieldsRequestActionType = 'SAVE_STANDARD_FIELDS_REQUEST'
export const saveStandardFieldsRequestSuccessActionType = 'SAVE_STANDARD_FIELDS_REQUEST_SUCCESS'
export const saveStandardFieldsRequestFailureActionType = 'SAVE_STANDARD_FIELDS_REQUEST_FAILURE'
export const getCustomFieldsRequestActionType = 'GET_CUSTOM_FIELDS_REQUEST'
export const getCustomFieldsRequestSuccessActionType = 'GET_CUSTOM_FIELDS_REQUEST_SUCCESS'
export const getCustomFieldsRequestFailureActionType = 'GET_CUSTOM_FIELDS_REQUEST_FAILURE'
export const addCustomFieldsRequestActionType = 'ADD_CUSTOM_FIELDS_REQUEST'
export const addCustomFieldsRequestSuccessActionType = 'ADD_CUSTOM_FIELDS_REQUEST_SUCCESS'
export const addCustomFieldsRequestFailureActionType = 'ADD_CUSTOM_FIELDS_REQUEST_FAILURE'
export const deleteCustomFieldsRequestActionType = 'DELETE_CUSTOM_FIELDS_REQUEST'
export const deleteCustomFieldsRequestSuccessActionType = 'DELETE_CUSTOM_FIELDS_REQUEST_SUCCESS'
export const deleteCustomFieldsRequestFailureActionType = 'DELETE_CUSTOM_FIELDS_REQUEST_FAILURE'
export const updateVideoLibraryLayoutRequestActionType = 'UPDATE_VIDEO_LIBRARY_LAYOUT_REQUEST'
export const updateVideoLibraryLayoutRequestSuccessActionType = 'UPDATE_VIDEO_LIBRARY_LAYOUT_REQUEST_SUCCESS'
export const updateVideoLibraryLayoutRequestFailureActionType = 'UPDATE_VIDEO_LIBRARY_LAYOUT_REQUEST_FAILURE'
export const getRegistrationAccessListRequestActionType = 'GET_REGISTRATION_ACCESS_LIST_REQUEST'
export const getRegistrationAccessListRequestSuccessActionType = 'GET_REGISTRATION_ACCESS_LIST_REQUEST_SUCCESS'
export const getRegistrationAccessListRequestFailureActionType = 'GET_REGISTRATION_ACCESS_LIST_REQUEST_FAILURE'
export const addRegistrationAccessListRequestActionType = 'ADD_REGISTRATION_ACCESS_LIST_REQUEST'
export const addRegistrationAccessListRequestSuccessActionType = 'ADD_REGISTRATION_ACCESS_LIST_REQUEST_SUCCESS'
export const addRegistrationAccessListRequestFailureActionType = 'ADD_REGISTRATION_ACCESS_LIST_REQUEST_FAILURE'
export const deleteRegistrationAccessListRequestActionType = 'DELETE_REGISTRATION_ACCESS_LIST_REQUEST'
export const deleteRegistrationAccessListRequestSuccessActionType = 'DELETE_REGISTRATION_ACCESS_LIST_REQUEST_SUCCESS'
export const deleteRegistrationAccessListRequestFailureActionType = 'DELETE_REGISTRATION_ACCESS_LIST_REQUEST_FAILURE'
export const getLayoutStyleRequestActionType = 'GET_LAYOUT_STYLE_REQUEST'
export const getLayoutStyleRequestSuccessActionType = 'GET_LAYOUT_STYLE_REQUEST_SUCCESS'
export const getLayoutStyleRequestFailureActionType = 'GET_LAYOUT_STYLE_REQUEST_FAILURE'
export const updateLayoutStyleRequestActionType = 'UPDATE_LAYOUT_STYLE_REQUEST'
export const updateLayoutStyleRequestSuccessActionType = 'UPDATE_LAYOUT_STYLE_REQUEST_SUCCESS'
export const updateLayoutStyleRequestFailureActionType = 'UPDATE_LAYOUT_STYLE_REQUEST_FAILURE'
export const getRecommendationRequestActionType = 'GET_RECOMMENDATION_REQUEST'
export const getRecommendationRequestSuccessActionType = 'GET_RECOMMENDATION_REQUEST_SUCCESS'
export const getRecommendationRequestFailureActionType = 'GET_RECOMMENDATION_REQUEST_FAILURE'
export const updateRecommendationRequestActionType = 'UPDATE_RECOMMENDATION_REQUEST'
export const updateRecommendationRequestSuccessActionType = 'UPDATE_RECOMMENDATION_REQUEST_SUCCESS'
export const updateRecommendationRequestFailureActionType = 'UPDATE_RECOMMENDATION_REQUEST_FAILURE'
export const getHightlightReelDataRequestActionType = 'GET_HIGHLIGHT_REEL_REQUEST'
export const getHightlightReelDataRequestSuccessActionType = 'GET_HIGHLIGHT_REEL_REQUEST_SUCCESS'
export const getHightlightReelDataRequestFailureActionType = 'GET_HIGHLIGHT_REEL_REQUEST_FAILURE'
export const getLayoutContentRequestActionType = 'GET_LAYOUT_CONTENT_REQUEST'
export const getLayoutContentRequestSuccessActionType = 'GET_LAYOUT_CONTENT_REQUEST_SUCCESS'
export const getLayoutContentRequestFailureActionType = 'GET_LAYOUT_CONTENT_REQUEST_FAILURE'
export const getAdBannerRequestActionType = 'GET_ADBANNER_REQUEST'
export const getAdBannerRequestSuccessActionType = 'GET_ADBANNER_REQUEST_SUCCESS'
export const getAdBannerRequestFailureActionType = 'GET_ADBANNER_REQUEST_FAILURE'
export const updateAdBannerRequestActionType = 'UPDATE_ADBANNER_REQUEST'
export const updateAdBannerRequestSuccessActionType = 'UPDATE_ADBANNER_REQUEST_SUCCESS'
export const updateAdBannerRequestFailureActionType = 'UPDATE_ADBANNER_REQUEST_FAILURE'
export const getSpotlightChannelRequestActionType = 'GET_SPOTLIGHT_CHANNEL_REQUEST'
export const getSpotlightChannelRequestSuccessActionType = 'GET_SPOTLIGHT_CHANNEL_REQUEST_SUCCESS'
export const getSpotlightChannelRequestFailureActionType = 'GET_SPOTLIGHT_CHANNEL_REQUEST_FAILURE'
export const deleteSpotlightChannelRequestActionType = 'DELETE_SPOTLIGHT_CHANNEL_REQUEST'
export const deleteSpotlightChannelRequestSuccessActionType = 'DELETE_SPOTLIGHT_CHANNEL_REQUEST_SUCCESS'
export const deleteSpotlightChannelRequestFailureActionType = 'DELETE_SPOTLIGHT_CHANNEL_REQUEST_FAILURE'
export const updateSpotlightSettingOrderRequestActionType = 'UPDATE_SPOTLIGHT_SETTING_ORDER_REQUEST'
export const updateSpotlightSettingOrderRequestSuccessActionType = 'UPDATE_SPOTLIGHT_SETTING_ORDER_REQUEST_SUCCESS'
export const updateSpotlightSettingOrderRequestFailureActionType = 'UPDATE_SPOTLIGHT_SETTING_ORDER_REQUEST_FAILURE'
export const updateLayoutContentRequestActionType = 'UPDATE_LAYOUT_CONTENT_REQUEST'
export const updateLayoutContentRequestSuccessActionType = 'UPDATE_LAYOUT_CONTENT_REQUEST_SUCCESS'
export const updateLayoutContentRequestFailureActionType = 'UPDATE_LAYOUT_CONTENT_REQUEST_FAILURE'
export const addSpotlightChannelRequestActionType = 'ADD_SPOTLIGHT_CHANNEL_REQUEST'
export const addSpotlightChannelRequestSuccessActionType = 'ADD_SPOTLIGHT_CHANNEL_REQUEST_SUCCESS'
export const addSpotlightChannelRequestFailureActionType = 'ADD_SPOTLIGHT_CHANNEL_REQUEST_FAILURE'