import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/common/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { RootState } from 'src/store/reducers/state';
import { useNavigate } from 'react-router-dom';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/components/loader';
import MemberListModal from './components/memberListModal';
import { DeleteDataModel } from 'src/models/usersModel';
import DeleteMemberListModal from './components/deleteMemberListModal';
import { errorMsg } from 'src/components/utilities/utils';
import { DebounceInput } from 'react-debounce-input';
const MemberList: React.FC = () => {
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState('');
  const userAction = useUserActions(dispatch);
  const [deleteData, setDeleteData] = useState<DeleteDataModel>();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const {
    memberList,
    isMemberListloading,
    message,
    isMemberListSaved,
    isMemberListDeleted,
    isMemberListUpdated,
    error,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      memberList: state.users.memberList,
      isMemberListloading: state.users.isMemberListloading,
      message: state.users.message,
      isMemberListSaved: state.users.isMemberListSaved,
      isMemberListDeleted: state.users.isMemberListDeleted,
      isMemberListUpdated: state.users.isMemberListUpdated,
      error: state.users.error,
      paginationMeta: state.users.paginationMeta
    };
  });

  useEffect(() => {
    userAction.getMemberListRequest({ pageNumber: 1, pageSize: 20 });
  }, []);

  const handleMemberListDetailClick = (memberListId: string) => {
    navigate(`/member/memberList-details/${memberListId}`);
  };
  const handleEditClick = (memberListId: string) => {
    setModalFlag('edit');
    userAction.getSingleMemberListRequest(memberListId);
  };

  useEffect(() => {
    if (isMemberListSaved || isMemberListDeleted || isMemberListUpdated) {
      userAction.getMemberListRequest({ pageNumber: 1, pageSize: 20 });
      successMsg(message);
      userAction.resetMemberRequest();
    }
  }, [isMemberListSaved, isMemberListDeleted, isMemberListUpdated]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      userAction.resetMemberRequest();
    }
  }, [error]);
  const handlePageChange = (pageNumber: number) => {
    userAction.getMemberListRequest({ pageNumber, pageSize: 20 });
  };

  const handleSearchVideo = (e: string) => {
    setSearchValue(e);
    userAction.getMemberListRequest({
      querySearch: e,
      pageNumber: 1,
      pageSize: 10
    });
  };

  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold "></h2>
                <div className="md:flex items-center text-center">
                  <div className="form-group md:flex items-center text-center">
                    <label className="mr-1 font-semibold">Search:</label>
                    <DebounceInput
                      placeholder="Search"
                      minLength={1}
                      className="input-style w-48 mr-3 mb-1"
                      debounceTimeout={500}
                      onChange={(event) => handleSearchVideo(event.target.value)}
                    />
                  </div>
                  <button
                    onClick={() => setModalFlag('add')}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#memberListModal">
                    Add Member List
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto-sm">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Member List Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Source</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Domains</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Emails</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">User Names</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {isMemberListloading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {memberList && memberList.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {memberList &&
                              memberList.map((memberList: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td className="p-2">
                                      <div className="text-left">{memberList.name}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{memberList.listTypeName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{memberList.sourceName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {memberList.memberEmailDomainsCount}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="text-left">
                                          {memberList.memberEmailsCount}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="text-left">
                                          {memberList.memberUsernamesCount}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="dropdown relative">
                                          <button
                                            className="dropdown-toggle"
                                            type="button"
                                            id="action-dropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img
                                              src="../../assets/img/dots-vertical.svg"
                                              className="w-5 h-5"
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu dropdown-menu-ul"
                                            aria-labelledby="action-dropdown">
                                            <li
                                              onClick={() =>
                                                handleMemberListDetailClick(memberList.id)
                                              }>
                                              <button className="action-menu flex items-center">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  className="mr-2 w-4 h-4">
                                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                  <polyline points="14 2 14 8 20 8"></polyline>
                                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                                  <polyline points="10 9 9 9 8 9"></polyline>
                                                </svg>
                                                <span>Details</span>
                                              </button>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => handleEditClick(memberList.id)}
                                                className="action-menu flex items-center"
                                                data-bs-toggle="modal"
                                                data-bs-target="#memberListModal">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Edit</span>
                                              </button>
                                            </li>
                                            <li>
                                              <span
                                                data-bs-toggle="modal"
                                                data-bs-target="#DeleteMemberListModal"
                                                className="action-menu flex items-center cursor-pointer text-red-500"
                                                onClick={() =>
                                                  setDeleteData({
                                                    id: memberList.id,
                                                    name: memberList.name
                                                  })
                                                }>
                                                <i className="fas fa-trash w-6"></i>
                                                <span>Delete</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        ) : (
                          <tr>
                            <td className="text-center py-2" colSpan={6}>
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MemberListModal modalFlag={modalFlag} />
      <DeleteMemberListModal deleteData={deleteData} />
    </>
  );
};

export default MemberList;
