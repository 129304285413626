import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AttributeMappingTemplateModel from  './components/AttributeMappingTemplateModel' ;
import DeleteAttributeMappingModal from './components/DeleteAttributeMappingModal';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useSettingActions } from 'src/store/settings/actions';
import Loader from 'src/components/loader';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
interface IProps {
    providerId: string,
    mappingType: string
  }
const AttributeMapping : React.FC<IProps> = ({ providerId, mappingType }) => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const [addFlag, setAddFlag] = useState<boolean>(false);
  const [attributeMappingId, setAttributeMappingId] = useState<string>();
  const {
    formState: { errors },
  } = useForm();

 

  useEffect(() => {
    if (providerId) {
    settingAction.getAttributeMappingRequest({providerId: providerId, mappingType: mappingType});
    }
  }, []);

  const {
    attributeMapping,
    attributeMappingColumnList,
    attributeMappingTypeList,
    message,
    isAttributeMappingSaved,
    isAttributeMappingUpdate,
    isAttributeMappingLoading,
    isAttributeMappingDeleted,
    error,
  } = useSelector((state: RootState) => {
    return {
      attributeMapping: state.setting.attributeMapping,
      attributeMappingColumnList: state.setting.AttributeMappingColumnList,
      attributeMappingTypeList: state.setting.AttributeMappingTypeList,
      message: state.setting.message,
      isAttributeMappingSaved: state.setting.isAttributeMappingSaved,
      isAttributeMappingUpdate: state.setting.isAttributeMappingUpdate,
      isAttributeMappingLoading: state.setting.isAttributeMappingLoading,
      isAttributeMappingDeleted: state.setting.isAttributeMappingDeleted,
      error: state.setting.error,
    };
  });

  useEffect(() => {
    if (Object.keys(attributeMappingColumnList).length == 0  && providerId) {
      settingAction.getAttributeMappingColumnListRequest(providerId);
      //console.log('attributeMappingColumnList -123', attributeMappingColumnList);
    }
  }, [attributeMappingColumnList]);

  useEffect(() => {
    
    // console.log('attributeMappingTypeList Length',  Object.keys(attributeMappingTypeList).length);
    if (Object.keys(attributeMappingTypeList).length == 0) {
      settingAction.getAttributeMappingTypeListRequest();
      //console.log('attributeMappingTypeList-456', attributeMappingTypeList);
    }
  }, [attributeMappingTypeList]);
  
  useEffect(() => {
    if (
      isAttributeMappingSaved ||
      isAttributeMappingUpdate ||
      isAttributeMappingDeleted
    ) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isAttributeMappingSaved, isAttributeMappingUpdate, isAttributeMappingDeleted]);



  const handleEditClick = (id: string) => {
    setAddFlag(false);
    settingAction.getSingleAttributeMappingRequest(id);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <>
     <div className="py-5 px-7">
                <div className="flex">
                { mappingType == '1'? ( <h3 className="font-bold text-lg">CE Credit Mapping</h3>) : (<h3 className="font-bold text-lg">Member Response Mapping</h3>) }
                <button
                    onClick={() => setAddFlag(true)}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#AddCETemplate"
                    className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn">
                    Add
                </button>
                </div>

                <div className="">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-100">
                      <tr>
                      <th className="p-2 hidden">
                          <div className="font-semibold text-left">Provider Id</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Property Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Property Value</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Dynamic Column</div>
                        </th>
                        {/* <th className="p-2">
                          <div className="font-semibold text-left">Mapping Type</div>
                        </th> */}
                        <th className="p-2">
                          <div className="text-left invisible">Action</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isAttributeMappingLoading && (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      )}
                      <>
                        {attributeMapping &&
                          attributeMapping.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td className="p-2 hidden">{item.providerId}</td>
                                <td className="p-2">{item.propertyName}</td>
                                <td className="p-2">{item.propertyDisplayValue}</td>
                                <td className="p-2">{item.dynamicColumn ? 'True' : 'False'}</td>
                                {/* <td className="p-2">{item.mappingTypeDisplayValue}</td> */}
                                <td className="p-2">
                                  <div className="dropdown relative">
                                    <a
                                      className="
                                          dropdown-toggle
                                          transition
                                          duration-150
                                          ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                      href="#"
                                      type="button"
                                      id="ActionButton"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <ul
                                      className="dropdown-menu action-dropdown"
                                      aria-labelledby="ActionButton">
                                      <li
                                        onClick={() => handleEditClick(item.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#AddCETemplate">
                                        <span className="dropdown-item flex-items cursor-pointer">
                                          <i className="fas fa-edit w-6"></i>
                                          <span>Edit</span>
                                        </span>
                                      </li>
                                      <li onClick={() => setAttributeMappingId(item.id)}>
                                        <span
                                          className="dropdown-item flex-items cursor-pointer text-red-500"
                                          data-bs-toggle="modal"
                                          data-bs-target="#DeleteAttributeMappingModal">
                                          <i className="fas fa-trash w-6"></i>
                                          <span>Delete</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    </tbody>
                  </table>
                </div>
     </div>
      <AttributeMappingTemplateModel addFlag={addFlag} providerId={providerId}  mappingType={mappingType}/>
      <DeleteAttributeMappingModal attributeMappingId={attributeMappingId} />
    </>
  );
};

export default AttributeMapping;
