import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const SpargoIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { spargoIntegration, message, isIntegrationUpdated, error } = useSelector(
    (state: RootState) => {
      return {
        spargoIntegration: state.setting.spargoIntegration,
        message: state.setting.message,
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        error: state.setting.error
      };
    }
  );

  const [showVendorPassword, setShowVendorPassword] = useState(false);
  const [showAuthPassword, setShowAuthPassword] = useState(false);

  useEffect(() => {
    if (spargoIntegration) {
      reset(spargoIntegration);
    }
  }, [spargoIntegration]);

  useEffect(() => {
    settingAction.getSpargoIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    const payloadData = {
      name: 'Spargo',
      updateData: [
        { op: 'replace', path: 'vendorUserName', value: data.vendorUserName },
        { op: 'replace', path: 'vendorPassword', value: data.vendorPassword },
        { op: 'replace', path: 'apiEndPoint', value: data.apiEndPoint },
        { op: 'replace', path: 'headerShowCode', value: data.headerShowCode },
        { op: 'replace', path: 'enableRegistrationIdCheck', value: data.enableRegistrationIdCheck },
        {
          op: 'replace',
          path: 'enableMultipleRegistrationIdCheck',
          value: data.enableMultipleRegistrationIdCheck
        },
        { op: 'replace', path: 'authApiEndPoint', value: data.authApiEndPoint },
        { op: 'replace', path: 'authUsername', value: data.authUsername },
        { op: 'replace', path: 'authPassword', value: data.authPassword },
        { op: 'replace', path: 'authShowCode', value: data.authShowCode }
      ]
    };
    settingAction.updateIntegrationRequest(payloadData);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> Spargo Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">Spargo Provider Detail</h4>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Vendor User Name</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'vendorUserName'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Vendor Password</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showVendorPassword ? 'text' : 'password'}
              fieldName={'vendorPassword'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showVendorPassword ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowVendorPassword(!showVendorPassword)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Api EndPoint</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'apiEndPoint'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Header ShowCode</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'headerShowCode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enable RegistrationId Check</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="enableRegistrationIdCheck"
              {...register('enableRegistrationIdCheck')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enable Multiple RegistrationId Check</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="enableMultipleRegistrationIdCheck"
              {...register('enableMultipleRegistrationIdCheck')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Auth Api End Point</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'authApiEndPoint'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Auth Username</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'authUsername'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Auth Password</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showAuthPassword ? 'text' : 'password'}
              fieldName={'authPassword'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showAuthPassword ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowAuthPassword(!showAuthPassword)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Auth Show Code</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'authShowCode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>

        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default SpargoIntegration;
