import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { MemberModel } from '../../../../models/usersModel';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
import Select from 'react-select';

interface IProps {
  memberDetails: MemberModel | undefined;
  modalFlag: string;
  setModalFlag?: (value: string) => void;
}
const Modal: React.FC<IProps> = ({ memberDetails, modalFlag, setModalFlag }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const userAction = useUserActions(dispatch);
  const [heading, setHeading] = useState('Save');
  const { isMemberAdded, isMemberUpdated, memberRoles } = useSelector((state: RootState) => {
    return {
      isMemberAdded: state.users.isMemberAdded,
      isMemberUpdated: state.users.isMemberUpdated,
      memberRoles: state.users.roles
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<MemberModel>();
  useEffect(() => {
    if (modalFlag === 'edit') {
      reset(memberDetails);
      setHeading('Edit');
      setSelectedOption(
        memberDetails?.roles?.map((item: any) => {
          return { label: item?.name, value: item?.name };
        })
      );
    } else {
      reset({
        title: '',
        suffix: '',
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        customerId: '',
        jobTitle: '',
        company: '',
        companyId: '',
        biography: '',
        roles: []
      });
      setHeading('Save');
      setSelectedOption(null);
    }
  }, [memberDetails?.roles, modalFlag, setModalFlag]);

  const onSubmit = (data: MemberModel) => {
    const roleValue =
      selectedOption && selectedOption.length ? selectedOption.map((val: any) => val.value) : [];
    if (modalFlag === 'add') {
      userAction.addMembersRequest({ ...data, roles: roleValue });
    } else {
      userAction.editMemberRequest({ ...data, roles: roleValue });
    }
  };

  useEffect(() => {
    if (isMemberAdded || isMemberUpdated) {
      if (ref.current) {
        ref.current.click();
      }
      userAction.resetMemberRequest();
      reset({
        title: '',
        suffix: '',
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        customerId: '',
        jobTitle: '',
        company: '',
        companyId: '',
        biography: '',
        roles: []
      });
    }
  }, [isMemberAdded, isMemberUpdated]);
  const handleCancleClick = () => {
    // reset({
    //   firstName: '',
    //   lastName: '',
    //   userName: '',
    //   email: '',
    //   customerId: '',
    //   jobTitle: '',
    //   company: '',
    //   companyId: ''
    // });
    // setSelectedOption(null)
    setModalFlag?.('');
  };

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editMemberModal"
      aria-labelledby="editMemberModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editMemberModalLabel">
              {`${heading} Member Information`}
            </h5>
            <button
              ref={ref}
              onClick={handleCancleClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> Title </label>
                  <input
                    type="text"
                    className="input-style"
                    id="title"
                    {...register('title', {
                      maxLength: 80
                    })}
                  />
                </div>
                <div className={`form-group`}>
                  <label className="block mb-1"> Suffix </label>
                  <input
                    type="text"
                    className="input-style"
                    id="suffix"
                    {...register('suffix', {
                      maxLength: 80
                    })}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> First Name </label>
                  <input
                    type="text"
                    className="input-style"
                    id="firstName"
                    {...register('firstName', {
                      required: true,
                      maxLength: 80,
                      pattern: /^[A-Za-z,.'-]/
                    })}
                  />
                  {errors.firstName?.type === 'required' && <p>Required Field</p>}
                  {errors.firstName?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.firstName?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>
                <div className={`form-group`}>
                  <label className="block mb-1"> Last Name </label>
                  <input
                    type="text"
                    className="input-style"
                    id="lastName"
                    {...register('lastName', {
                      maxLength: 80,
                      pattern: /^[A-Za-z,.'-]/
                    })}
                  />
                  {errors.lastName?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.lastName?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>
              </div>

              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> Email </label>
                  <input
                    type="text"
                    className="input-style"
                    id="emailAddress"
                    {...register('email', {
                      required: true,
                      maxLength: 80,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    })}
                  />
                  {errors.email?.type === 'required' && <p>Required Field</p>}
                  {errors.email?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.email?.type === 'pattern' && <p>Only email are allowed</p>}
                </div>
                <div className={`form-group mb-4`}>
                  <label className="block mb-1"> Username </label>
                  <input
                    type="text"
                    className="input-style"
                    id="userName"
                    {...register('userName', {
                      maxLength: 150
                      // pattern: /^[A-Za-z]/
                    })}
                  />
                  {errors.userName?.type === 'maxLength' && <p>Max. length reached</p>}
                  {/* {errors.userName?.type === 'pattern' && <p>Only alphabets are allowed</p>} */}
                </div>
              </div>
              <div className={`form-group mb-4`}>
                <label className="block mb-1"> Roles </label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  closeMenuOnScroll={false}
                  options={memberRoles.map((value) => ({
                    value: value.name,
                    label: value.name
                  }))}
                  value={selectedOption}
                  onChange={(ev) => {
                    setSelectedOption(ev);
                  }}
                />
              </div>

              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> Customer ID </label>
                  <input
                    type="text"
                    className="input-style"
                    id="customerId"
                    {...register('customerId', {
                      maxLength: 80
                    })}
                  />
                  {errors.customerId?.type === 'maxLength' && <p>Max. length reached</p>}
                </div>
                <div className={`form-group`}>
                  <label className="block mb-1"> Job Title </label>
                  <input
                    type="text"
                    className="input-style"
                    id="jobTitle"
                    {...register('jobTitle', {
                      maxLength: 80,
                      pattern: /^[A-Za-z]/
                    })}
                  />
                  {errors.jobTitle?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.jobTitle?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>
              </div>

              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> Company </label>
                  <input
                    type="text"
                    className="input-style"
                    id="company"
                    {...register('company', {
                      maxLength: 80,
                      pattern: /^[A-Za-z]/
                    })}
                  />
                  {errors.company?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.company?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>

                <div className={`form-group`}>
                  <label className="mb-1 whitespace-nowrap">Company ID</label>
                  <input
                    type="text"
                    className="input-style"
                    id="companyId"
                    {...register('companyId', {
                      maxLength: 80
                    })}
                  />
                  {errors.companyId?.type === 'maxLength' && <p>Max. length reached</p>}
                </div>
              </div>

              <div className={`form-group mb-4`}>
                <label className="block mb-1"> Biography </label>
                <textarea className="input-style" {...register('biography')} rows={3}></textarea>
              </div>

              <div className="flex items-center">
                <div className="form-check form-switch pl-0 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="cePreference"
                    {...register('cePreference')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
                <label
                  className="form-check-label inline-block font-semibold ml-2"
                  htmlFor="Credit">
                  Credit Preference Check
                </label>
              </div>
              <div className="flex items-center mt-2">
                <div className="form-check form-switch pl-0 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="active"
                    {...register('active')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
                <label
                  className="form-check-label inline-block font-semibold ml-2"
                  htmlFor="Credit">
                  Status
                </label>
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                  Submit
                </button>
                <button
                  onClick={handleCancleClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
