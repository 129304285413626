import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const MemberEvolutionIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { memberEvolutionIntegration, isIntegrationUpdated, message, error } = useSelector(
    (state: RootState) => {
      return {
        memberEvolutionIntegration: state.setting.memberEvolutionIntegration,
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        message: state.setting.message,
        error: state.setting.error
      };
    }
  );

  const [showClientSecret, setShowClientSecret] = useState(false);

  useEffect(() => {
    if (memberEvolutionIntegration) {
      reset(memberEvolutionIntegration);
    }
  }, [memberEvolutionIntegration]);

  useEffect(() => {
    settingAction.getMemberEvolutionIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    if (memberEvolutionIntegration == undefined || memberEvolutionIntegration.id == undefined) {
      settingAction.addMemberEvolutionIntegrationRequest(data);
    } else {
      const payloadData = {
        name: 'MemberEvolution',
        updateData: [
          { op: 'replace', path: 'baseUrl', value: data.baseUrl },
          { op: 'replace', path: 'clientId', value: data.clientId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'memberContactMethod', value: data.memberContactMethod }
        ]
      };
      settingAction.updateIntegrationRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> Member Evolution Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5 w-5/6">
        {/* <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Provider Name</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'providerName'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div> */}
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Base Url</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'baseUrl'}
              register={register}
              errors={''}
              isRequired={true}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Client Id</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'clientId'}
              register={register}
              errors={''}
              isRequired={true}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Client Secret</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showClientSecret ? 'text' : 'password'}
              fieldName={'clientSecret'}
              register={register}
              errors={''}
              isRequired={true}
            />
            <i
              className={`fas ${
                showClientSecret ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowClientSecret(!showClientSecret)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Member Contact Method</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'memberContactMethod'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>

        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default MemberEvolutionIntegration;
