import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { defaultDateFormatter } from 'src/components/utilities/utils';

interface IProps {
  memberId: string;
}

const Registrations: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const { memberEvent } = useSelector((state: RootState) => {
    return {
      memberEvent: state.users.memberEvent
    };
  });

  return (
    <div className="my-6">
      <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
          <div className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold ">Event Registrations</h2>
          </div>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold   bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Event Name</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Event Title</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Registration Date</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Date</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Time</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">Duration(hh:mm)</div>
                    </th>
                  </tr>
                </thead>
                {memberEvent && memberEvent.length ? (
                  <tbody className="text-sm divide-y divide-gray-100">
                    {memberEvent &&
                      memberEvent.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-2">
                              <div className="text-left">{item.name}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.title}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.registrationDate
                                  ? defaultDateFormatter(item.registrationDate)
                                  : ''}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.displayDate ? defaultDateFormatter(item.displayDate) : ''}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.displayTime}</div>
                            </td>

                            <td className="p-2">
                              <div className="text-left">{item.displayDurationInTable}</div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="text-center">No data Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {/* {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                <Pagination
                  currentPage={paginationMeta.pageNumber}
                  totalCount={paginationMeta.totalCount}
                  pageSize={paginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registrations;
