import React, { useState, useEffect } from 'react';
import '../../styles.scss';
import { useForm } from 'react-hook-form';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
const ChannelMenu = () => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset
  } = useForm();
  const { singleChannel, isChannelSavedLoading } = useSelector((state: RootState) => {
    return {
      singleChannel: state.channel.singleChannel,
      isChannelSavedLoading: state.channel.isChannelSavedLoading
    };
  });
  const onSubmit = (data: any) => {
    const payloadData = {
      channelId: data.id,
      updateData: [
        { op: 'replace', path: 'displayVideoChannelHeader', value: data.displayVideoChannelHeader },
        { op: 'replace', path: 'displayLogoffControl', value: data.displayLogoffControl }
      ]
    };
    channelActions.updateChannelRequest(payloadData);
  };
  useEffect(() => {
    if (singleChannel) {
      reset(singleChannel);
    }
  }, [singleChannel]);
  return (
    <div className="tab-pane fade" id="tabs-channelMenu" role="tabpanel">
      <form onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-4 ml-auto mb-3">
          Save Styles
        </button>
        {isChannelSavedLoading && <Loader />}
        <div className="p-5">
          <div className="md:flex items-center mb-8">
            <div className="md:w-1/2 pr-5">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Video Channel Header
              </label>
              <p>
                Will use the main Video Channel Header, Login controls and Navigation on your
                channel page. This may be hidden to create break-out events or branding that you may
                differ from your main video library.Event Registration is generally used to access
                your channel if disabling your main header & login controls.
              </p>
            </div>
            <div className="md:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayVideoChannelHeader"
                      {...register('displayVideoChannelHeader')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="md:w-1/2 pr-5">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Logoff Control
              </label>
              <p>
                If your main Video Channel Header is hidden, the Logoff control can be added to your
                channel page. This will allow users to simple Logoff your site.
              </p>
            </div>
            <div className="md:w-1/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayLogoffControl"
                      {...register('displayLogoffControl')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChannelMenu;
