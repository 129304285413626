import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
const SearchSettings = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const { advanceSetting, filterSetting } = useSelector((state: RootState) => {
    return {
      advanceSetting: state.appearance.advanceSetting,
      filterSetting: state.appearance.filterSetting
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    if (advanceSetting && filterSetting) {
      const finalData = { ...advanceSetting, ...filterSetting };
      reset(finalData);
    }
  }, [advanceSetting, filterSetting]);

  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'menuBarDisabled', value: data.menuBarDisabled },
      { op: 'replace', path: 'channelAlias', value: data.channelAlias },
      { op: 'replace', path: 'subfolderEnabled', value: data.subfolderEnabled },
      { op: 'replace', path: 'subChannelAlias', value: data.subChannelAlias },
      { op: 'replace', path: 'categoriesEnabled', value: data.categoriesEnabled },
      { op: 'replace', path: 'categoriesAlias', value: data.categoriesAlias },
      { op: 'replace', path: 'collectionsAlias', value: data.collectionsAlias },
      { op: 'replace', path: 'tagsEnabled', value: data.tagsEnabled },
      { op: 'replace', path: 'tagsAlias', value: data.tagsAlias },
      { op: 'replace', path: 'displaySpeaker', value: data.displaySpeaker },
      { op: 'replace', path: 'speakerAlias', value: data.speakerAlias },
      { op: 'replace', path: 'enableCategoriesPage', value: data.enableCategoriesPage },
      { op: 'replace', path: 'displayTimezone', value: data.displayTimezone },
      { op: 'replace', path: 'defaultSortOrder', value: data.defaultSortOrder },
      { op: 'replace', path: 'defaultPageSize', value: data.defaultPageSize },
      { op: 'replace', path: 'channelMenuExpanded', value: data.channelMenuExpanded },
      { op: 'replace', path: 'presentationDateThirdLine', value: data.presentationDateThirdLine }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  return (
    <div className="tab-pane fade" id="tabs-searchSetting" role="tabpanel">
      <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        <div className="accordion-body p-5">
          <div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Search Filter Menu
                </label>
                <p>Will hide the Search Filter menu on the video library homepage.</p>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="menuBarDisabled"
                        {...register('menuBarDisabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Channel Alias
                </label>
                <p>Will display the Channel name using the following label.</p>
              </div>
              <div className="md:w-8/12">
                <div className="md:ml-10">
                  <input
                    type="text"
                    placeholder="Channel"
                    className="input-style"
                    id="channelAlias"
                    {...register('channelAlias')}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Sub Channel
                </label>
                <p>Will display the sub-channel filter menu with the following label.</p>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="subfolderEnabled"
                        {...register('subfolderEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>

                  <FormInputField
                    type={'text'}
                    fieldName={'subChannelAlias'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                    placeholder={'Sub Channel'}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Categories
                </label>
                <p>
                  Will display Categories on your filter menu and use the label across your site.
                  The default name will be Categories if left blank.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="categoriesEnabled"
                        {...register('categoriesEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>

                  <FormInputField
                    type={'text'}
                    fieldName={'categoriesAlias'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                    placeholder={'Category'}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Tags
                </label>
                <p>
                  Will display Tags on your filter menu and across your site using the following
                  label. The default name will be Tags if left blank.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="tagsEnabled"
                        {...register('tagsEnabled')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>

                  <FormInputField
                    type={'text'}
                    fieldName={'tagsAlias'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                    placeholder={'Tags'}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Speakers
                </label>
                <p>
                  Will display Speakers on your filter menu and across your site using the following
                  label. The default name will be Speakers if left blank.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="displaySpeaker"
                        {...register('displaySpeaker')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>

                  <FormInputField
                    type={'text'}
                    fieldName={'speakerAlias'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                    placeholder={'Speakers'}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Collections
                </label>
                <p>
                  Set a label for Collections that will be used on your library. If left blank, the
                  default name is Collections.
                </p>
              </div>
              <div className="md:w-8/12">
                <div className="md:ml-10">
                  <input
                    type="text"
                    placeholder="Collections"
                    className="input-style"
                    id="collectionsAlias"
                    {...register('collectionsAlias')}
                  />
                </div>
              </div>
            </div>
            {/* <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Categories Page
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="searchFilterSetting.hideSearchmenu"
                        {...register('searchFilterSetting.hideSearchmenu')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Categories Page
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="enableCategoriesPage"
                        {...register('enableCategoriesPage')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Display Date
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="presentationDateThirdLine"
                        {...register('presentationDateThirdLine')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Display Time
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="displayVideoTime"
                        {...register('displayVideoTime')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Display Timezone
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="displayTimezone"
                        {...register('displayTimezone')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Default Sort Order
                </label>
              </div>
              <div className="md:w-8/12">
                <select
                  {...register('defaultSortOrder', {
                    required: false
                  })}
                  id="defaultSortOrder"
                  className="border border-gray-300 input-style">
                  <option value="">Select</option>
                  <option value={0}>Date: Most Recent</option>
                  <option value={1}>Date: Least Recent</option>
                  <option value={2}>A-Z</option>
                  <option value={3}>Z-A</option>
                  <option value={4}>Price: Lowest First</option>
                  <option value={5}>Price: Highest First</option>
                  <option value={6}>Video Sequence</option>
                </select>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Display Page Size
                </label>
              </div>
              <div className="md:w-8/12">
                <select
                  {...register('defaultPageSize', {
                    required: false
                  })}
                  id="defaultPageSize"
                  className="border border-gray-300 input-style">
                  <option value="">Select</option>
                  <option value={12}>12</option>
                  <option value={24}>24</option>
                  <option value={36}>36</option>
                  <option value={48}>48</option>
                  <option value={60}>60</option>
                  <option value={72}>72</option>
                  <option value={84}>84</option>
                  <option value={96}>96</option>
                  <option value={108}>108</option>
                </select>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="md:w-4/12">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Channel Menu Expanded
                </label>
              </div>
              <div className="md:w-8/12">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="channelMenuExpanded"
                        {...register('channelMenuExpanded')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchSettings;
