import { createAction } from "redux-actions"
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { NavigationMenuModel, TotalRecordsModel } from "src/models/videosModel";
import { AddSpotlightChannelModel, GetHighlightReelDataModel, UpdateLayoutContentModel, SpotlightChannelModel, AdBannerModel, LayoutContentModel, UpdateLayoutStyleModel, HighlightReelDataModel, RecommendationModel, LayoutStyleModel, RegistrationAccessListModel, UpdateVideoLibraryModel, CustomFieldModel, StandardFieldModel, AuthenticationModel, RegistrationOptionModel } from "src/models/videoLibraryModel";
import { PagingDataModel } from 'src/models/paginationModel';
import { Action, ActionCreator } from 'src/models/actions';
export namespace VideoLibraryActions {

    export type getSpotLightRowRequestAction = Action<typeof Constants.getSpotlightRowRequestActionType, any>
    export type getSpotLightRowRequestSuccessAction = Action<typeof Constants.getSpotlightRowRequestSuccessActionType, any>;
    export type getSpotLightRowRequestFailureAction = Action<typeof Constants.getSpotlightRowRequestFailureActionType, null>;

    export function getSpotLightRowRequest(P: any): getSpotLightRowRequestAction {
        return ActionCreator(Constants.getSpotlightRowRequestActionType, P);
    }
    export function getSpotLightRowRequestSuccess(P: any): getSpotLightRowRequestSuccessAction {
        return ActionCreator(Constants.getSpotlightRowRequestSuccessActionType, P);
    }
    export function getSpotLightRowRequestFailure(): getSpotLightRowRequestFailureAction {
        return ActionCreator(Constants.getSpotlightRowRequestFailureActionType, null);
    }

    export type saveHighlightReelRequestAction = Action<typeof Constants.saveHighlightReelRequestActionType, any>
    export type saveHighlightReelRequestSuccessAction = Action<typeof Constants.saveHighlightReelRequestSuccessActionType, string>;
    export type saveHighlightReelRequestFailureAction = Action<typeof Constants.saveHighlightReelRequestFailureActionType, null>;

    export function saveHighlightReelRequest(P: any): saveHighlightReelRequestAction {
        return ActionCreator(Constants.saveHighlightReelRequestActionType, P);
    }
    export function saveHighlightReelRequestSuccess(P: string): saveHighlightReelRequestSuccessAction {
        return ActionCreator(Constants.saveHighlightReelRequestSuccessActionType, P);
    }
    export function saveHighlightReelRequestFailure(): saveHighlightReelRequestFailureAction {
        return ActionCreator(Constants.saveHighlightReelRequestFailureActionType, null);
    }

    export type saveCustomizeThemeHeaderRequestAction = Action<typeof Constants.saveCustomizeThemeHeaderRequestActionType, any>
    export type saveCustomizeThemeHeaderRequestSuccessAction = Action<typeof Constants.saveCustomizeThemeHeaderRequestSuccessActionType, string>;
    export type saveCustomizeThemeHeaderRequestFailureAction = Action<typeof Constants.saveCustomizeThemeHeaderRequestFailureActionType, null>;

    export function saveCustomizeThemeHeaderRequest(P: any): saveCustomizeThemeHeaderRequestAction {
        return ActionCreator(Constants.saveCustomizeThemeHeaderRequestActionType, P);
    }
    export function saveCustomizeThemeHeaderRequestSuccess(P: string): saveCustomizeThemeHeaderRequestSuccessAction {
        return ActionCreator(Constants.saveCustomizeThemeHeaderRequestSuccessActionType, P);
    }
    export function saveCustomizeThemeHeaderRequestFailure(): saveCustomizeThemeHeaderRequestFailureAction {
        return ActionCreator(Constants.saveCustomizeThemeHeaderRequestFailureActionType, null);
    }

    export type saveSpotlightRowsRecommendationRequestAction = Action<typeof Constants.saveSpotlightRowsRecommendationRequestActionType, any>
    export type saveSpotlightRowsRecommendationRequestSuccessAction = Action<typeof Constants.saveSpotlightRowsRecommendationRequestSuccessActionType, null>;
    export type saveSpotlightRowsRecommendationRequestFailureAction = Action<typeof Constants.saveSpotlightRowsRecommendationRequestFailureActionType, null>;

    export function saveSpotlightRowsRecommendationRequest(P: any): saveSpotlightRowsRecommendationRequestAction {
        return ActionCreator(Constants.saveSpotlightRowsRecommendationRequestActionType, P);
    }
    export function saveSpotlightRowsRecommendationRequestSuccess(): saveSpotlightRowsRecommendationRequestSuccessAction {
        return ActionCreator(Constants.saveSpotlightRowsRecommendationRequestSuccessActionType, null);
    }
    export function saveSpotlightRowsRecommendationRequestFailure(): saveSpotlightRowsRecommendationRequestFailureAction {
        return ActionCreator(Constants.saveSpotlightRowsRecommendationRequestFailureActionType, null);
    }

    export type getTotalRecordsRequestAction = Action<typeof Constants.getTotalRecordsRequestActionType, null>
    export type getTotalRecordsRequestSuccessAction = Action<typeof Constants.getTotalRecordsRequestSuccessActionType, TotalRecordsModel[]>;
    export type getTotalRecordsRequestFailureAction = Action<typeof Constants.getTotalRecordsRequestFailureActionType, null>;

    export function getTotalRecordsRequest(): getTotalRecordsRequestAction {
        return ActionCreator(Constants.getTotalRecordsRequestActionType, null);
    }
    export function getTotalRecordsRequestSuccess(P: TotalRecordsModel[]): getTotalRecordsRequestSuccessAction {
        return ActionCreator(Constants.getTotalRecordsRequestSuccessActionType, P);
    }
    export function getTotalRecordsRequestFailure(): getTotalRecordsRequestFailureAction {
        return ActionCreator(Constants.getTotalRecordsRequestFailureActionType, null);
    }

    export type getAuthenticationRequestAction = Action<typeof Constants.getAuthenticationRequestActionType, null>
    export type getAuthenticationRequestSuccessAction = Action<typeof Constants.getAuthenticationRequestSuccessActionType, AuthenticationModel>;
    export type getAuthenticationRequestFailureAction = Action<typeof Constants.getAuthenticationRequestFailureActionType, null>;

    export function getAuthenticationRequestRequest(): getAuthenticationRequestAction {
        return ActionCreator(Constants.getAuthenticationRequestActionType, null);
    }
    export function getAuthenticationRequestSuccess(P: AuthenticationModel): getAuthenticationRequestSuccessAction {
        return ActionCreator(Constants.getAuthenticationRequestSuccessActionType, P);
    }
    export function getAuthenticationRequestFailure(): getAuthenticationRequestFailureAction {
        return ActionCreator(Constants.getAuthenticationRequestFailureActionType, null);
    }

    export type saveAuthenticationRequestAction = Action<typeof Constants.saveAuthenticationRequestActionType, AuthenticationModel>
    export type saveAuthenticationRequestSuccessAction = Action<typeof Constants.saveAuthenticationRequestSuccessActionType, null>;
    export type saveAuthenticationRequestFailureAction = Action<typeof Constants.saveAuthenticationRequestFailureActionType, null>;

    export function saveAuthenticationRequest(P: AuthenticationModel): saveAuthenticationRequestAction {
        return ActionCreator(Constants.saveAuthenticationRequestActionType, P);
    }
    export function saveAuthenticationRequestSuccess(): saveAuthenticationRequestSuccessAction {
        return ActionCreator(Constants.saveAuthenticationRequestSuccessActionType, null);
    }
    export function saveAuthenticationRequestFailure(): saveAuthenticationRequestFailureAction {
        return ActionCreator(Constants.saveAuthenticationRequestFailureActionType, null);
    }

    export type resetVideoLibraryStateRequestAction = Action<typeof Constants.resetVideoLibraryStateRequestActionType, null>
    export function resetVideoLibraryStateRequest(): resetVideoLibraryStateRequestAction {
        return ActionCreator(Constants.resetVideoLibraryStateRequestActionType, null);
    }

    export type getRegistrationOptionsRequestAction = Action<typeof Constants.getRegistrationOptionsRequestActionType, null>
    export type getRegistrationOptionsRequestSuccessAction = Action<typeof Constants.getRegistrationOptionsRequestSuccessActionType, RegistrationOptionModel[]>;
    export type getRegistrationOptionsRequestFailureAction = Action<typeof Constants.getRegistrationOptionsRequestFailureActionType, null>;

    export function getRegistrationOptionsRequest(): getRegistrationOptionsRequestAction {
        return ActionCreator(Constants.getRegistrationOptionsRequestActionType, null);
    }
    export function getRegistrationOptionsRequestSuccess(P: RegistrationOptionModel[]): getRegistrationOptionsRequestSuccessAction {
        return ActionCreator(Constants.getRegistrationOptionsRequestSuccessActionType, P);
    }
    export function getRegistrationOptionsRequestFailure(): getRegistrationOptionsRequestFailureAction {
        return ActionCreator(Constants.getRegistrationOptionsRequestFailureActionType, null);
    }

    export type addRegistrationOptionsRequestAction = Action<typeof Constants.addRegistrationOptionsRequestActionType, RegistrationOptionModel>
    export type addRegistrationOptionsRequestSuccessAction = Action<typeof Constants.addRegistrationOptionsRequestSuccessActionType, null>;
    export type addRegistrationOptionsRequestFailureAction = Action<typeof Constants.addRegistrationOptionsRequestFailureActionType, null>;

    export function addRegistrationOptionsRequest(P: RegistrationOptionModel): addRegistrationOptionsRequestAction {
        return ActionCreator(Constants.addRegistrationOptionsRequestActionType, P);
    }
    export function addRegistrationOptionsRequestSuccess(): addRegistrationOptionsRequestSuccessAction {
        return ActionCreator(Constants.addRegistrationOptionsRequestSuccessActionType, null);
    }
    export function addRegistrationOptionsRequestFailure(): addRegistrationOptionsRequestFailureAction {
        return ActionCreator(Constants.addRegistrationOptionsRequestFailureActionType, null);
    }

    export type deleteRegistrationOptionRequestAction = Action<typeof Constants.deleteRegistrationOptionRequestActionType, string>
    export type deleteRegistrationOptionRequestSuccessAction = Action<typeof Constants.deleteRegistrationOptionRequestSuccessActionType, null>;
    export type deleteRegistrationOptionRequestFailureAction = Action<typeof Constants.deleteRegistrationOptionRequestFailureActionType, null>;

    export function deleteRegistrationOptionRequest(P: string): deleteRegistrationOptionRequestAction {
        return ActionCreator(Constants.deleteRegistrationOptionRequestActionType, P);
    }
    export function deleteRegistrationOptionRequestSuccess(): deleteRegistrationOptionRequestSuccessAction {
        return ActionCreator(Constants.deleteRegistrationOptionRequestSuccessActionType, null);
    }
    export function deleteRegistrationOptionRequestFailure(): deleteRegistrationOptionRequestFailureAction {
        return ActionCreator(Constants.deleteRegistrationOptionRequestFailureActionType, null);
    }

    export type getStandardFieldsRequestAction = Action<typeof Constants.getStandardFieldsRequestActionType, null>
    export type getStandardFieldsRequestSuccessAction = Action<typeof Constants.getStandardFieldsRequestSuccessActionType, StandardFieldModel>;
    export type getStandardFieldsRequestFailureAction = Action<typeof Constants.getStandardFieldsRequestFailureActionType, null>;

    export function getStandardFieldsRequest(): getStandardFieldsRequestAction {
        return ActionCreator(Constants.getStandardFieldsRequestActionType, null);
    }
    export function getStandardFieldsRequestSuccess(P: StandardFieldModel): getStandardFieldsRequestSuccessAction {
        return ActionCreator(Constants.getStandardFieldsRequestSuccessActionType, P);
    }
    export function getStandardFieldsRequestFailure(): getStandardFieldsRequestFailureAction {
        return ActionCreator(Constants.getStandardFieldsRequestFailureActionType, null);
    }

    export type saveStandardFieldsRequestAction = Action<typeof Constants.saveStandardFieldsRequestActionType, StandardFieldModel>
    export type saveStandardFieldsRequestSuccessAction = Action<typeof Constants.saveStandardFieldsRequestSuccessActionType, null>;
    export type saveStandardFieldsRequestFailureAction = Action<typeof Constants.saveStandardFieldsRequestFailureActionType, null>;

    export function saveStandardFieldsRequest(P: StandardFieldModel): saveStandardFieldsRequestAction {
        return ActionCreator(Constants.saveStandardFieldsRequestActionType, P);
    }
    export function saveStandardFieldsRequestSuccess(): saveStandardFieldsRequestSuccessAction {
        return ActionCreator(Constants.saveStandardFieldsRequestSuccessActionType, null);
    }
    export function saveStandardFieldsRequestFailure(): saveStandardFieldsRequestFailureAction {
        return ActionCreator(Constants.saveStandardFieldsRequestFailureActionType, null);
    }

    export type getCustomFieldsRequestAction = Action<typeof Constants.getCustomFieldsRequestActionType, null>
    export type getCustomFieldsRequestSuccessAction = Action<typeof Constants.getCustomFieldsRequestSuccessActionType, CustomFieldModel[]>;
    export type getCustomFieldsRequestFailureAction = Action<typeof Constants.getCustomFieldsRequestFailureActionType, null>;

    export function getCustomFieldsRequest(): getCustomFieldsRequestAction {
        return ActionCreator(Constants.getCustomFieldsRequestActionType, null);
    }
    export function getCustomFieldsRequestSuccess(P: CustomFieldModel[]): getCustomFieldsRequestSuccessAction {
        return ActionCreator(Constants.getCustomFieldsRequestSuccessActionType, P);
    }
    export function getCustomFieldsRequestFailure(): getCustomFieldsRequestFailureAction {
        return ActionCreator(Constants.getCustomFieldsRequestFailureActionType, null);
    }

    export type addCustomFieldsRequestAction = Action<typeof Constants.addCustomFieldsRequestActionType, CustomFieldModel>
    export type addCustomFieldsRequestSuccessAction = Action<typeof Constants.addCustomFieldsRequestSuccessActionType, null>;
    export type addCustomFieldsRequestFailureAction = Action<typeof Constants.addCustomFieldsRequestFailureActionType, null>;

    export function addCustomFieldsRequest(P: CustomFieldModel): addCustomFieldsRequestAction {
        return ActionCreator(Constants.addCustomFieldsRequestActionType, P);
    }
    export function addCustomFieldsRequestSuccess(): addCustomFieldsRequestSuccessAction {
        return ActionCreator(Constants.addCustomFieldsRequestSuccessActionType, null);
    }
    export function addCustomFieldsRequestFailure(): addCustomFieldsRequestFailureAction {
        return ActionCreator(Constants.addCustomFieldsRequestFailureActionType, null);
    }

    export type deleteCustomFieldsRequestAction = Action<typeof Constants.deleteCustomFieldsRequestActionType, string>
    export type deleteCustomFieldsRequestSuccessAction = Action<typeof Constants.deleteCustomFieldsRequestSuccessActionType, null>;
    export type deleteCustomFieldsRequestFailureAction = Action<typeof Constants.deleteCustomFieldsRequestFailureActionType, null>;

    export function deleteCustomFieldsRequest(P: string): deleteCustomFieldsRequestAction {
        return ActionCreator(Constants.deleteCustomFieldsRequestActionType, P);
    }
    export function deleteCustomFieldsRequestSuccess(): deleteCustomFieldsRequestSuccessAction {
        return ActionCreator(Constants.deleteCustomFieldsRequestSuccessActionType, null);
    }
    export function deleteCustomFieldsRequestFailure(): deleteCustomFieldsRequestFailureAction {
        return ActionCreator(Constants.deleteCustomFieldsRequestFailureActionType, null);
    }


    export type updateVideoLibraryLayoutRequestAction = Action<typeof Constants.updateVideoLibraryLayoutRequestActionType, UpdateVideoLibraryModel>
    export type updateVideoLibraryLayoutRequestSuccessAction = Action<typeof Constants.updateVideoLibraryLayoutRequestSuccessActionType, null>;
    export type updateVideoLibraryLayoutRequestFailureAction = Action<typeof Constants.updateVideoLibraryLayoutRequestFailureActionType, null>;

    export function updateVideoLibraryLayoutRequest(P: UpdateVideoLibraryModel): updateVideoLibraryLayoutRequestAction {
        return ActionCreator(Constants.updateVideoLibraryLayoutRequestActionType, P);
    }
    export function updateVideoLibraryLayoutRequestSuccess(): updateVideoLibraryLayoutRequestSuccessAction {
        return ActionCreator(Constants.updateVideoLibraryLayoutRequestSuccessActionType, null);
    }
    export function updateVideoLibraryLayoutRequestFailure(): updateVideoLibraryLayoutRequestFailureAction {
        return ActionCreator(Constants.updateVideoLibraryLayoutRequestFailureActionType, null);
    }

    export type getRegistrationAccessListRequestAction = Action<typeof Constants.getRegistrationAccessListRequestActionType, null>
    export type getRegistrationAccessListRequestSuccessAction = Action<typeof Constants.getRegistrationAccessListRequestSuccessActionType, RegistrationAccessListModel[]>;
    export type getRegistrationAccessListRequestFailureAction = Action<typeof Constants.getRegistrationAccessListRequestFailureActionType, null>;

    export function getRegistrationAccessListRequest(): getRegistrationAccessListRequestAction {
        return ActionCreator(Constants.getRegistrationAccessListRequestActionType, null);
    }
    export function getRegistrationAccessListRequestSuccess(P: RegistrationAccessListModel[]): getRegistrationAccessListRequestSuccessAction {
        return ActionCreator(Constants.getRegistrationAccessListRequestSuccessActionType, P);
    }
    export function getRegistrationAccessListRequestFailure(): getRegistrationAccessListRequestFailureAction {
        return ActionCreator(Constants.getRegistrationAccessListRequestFailureActionType, null);
    }

    export type addRegistrationAccessListRequestAction = Action<typeof Constants.addRegistrationAccessListRequestActionType, RegistrationAccessListModel>
    export type addRegistrationAccessListRequestSuccessAction = Action<typeof Constants.addRegistrationAccessListRequestSuccessActionType, null>;
    export type addRegistrationAccessListRequestFailureAction = Action<typeof Constants.addRegistrationAccessListRequestFailureActionType, null>;

    export function addRegistrationAccessListRequest(P: RegistrationAccessListModel): addRegistrationAccessListRequestAction {
        return ActionCreator(Constants.addRegistrationAccessListRequestActionType, P);
    }
    export function addRegistrationAccessListRequestSuccess(): addRegistrationAccessListRequestSuccessAction {
        return ActionCreator(Constants.addRegistrationAccessListRequestSuccessActionType, null);
    }
    export function addRegistrationAccessListRequestFailure(): addRegistrationAccessListRequestFailureAction {
        return ActionCreator(Constants.addRegistrationAccessListRequestFailureActionType, null);
    }

    export type deleteRegistrationAccessListRequestAction = Action<typeof Constants.deleteRegistrationAccessListRequestActionType, string>
    export type deleteRegistrationAccessListRequestSuccessAction = Action<typeof Constants.deleteRegistrationAccessListRequestSuccessActionType, null>;
    export type deleteRegistrationAccessListRequestFailureAction = Action<typeof Constants.deleteRegistrationAccessListRequestFailureActionType, null>;

    export function deleteRegistrationAccessListRequest(P: string): deleteRegistrationAccessListRequestAction {
        return ActionCreator(Constants.deleteRegistrationAccessListRequestActionType, P);
    }
    export function deleteRegistrationAccessListRequestSuccess(): deleteRegistrationAccessListRequestSuccessAction {
        return ActionCreator(Constants.deleteRegistrationAccessListRequestSuccessActionType, null);
    }
    export function deleteRegistrationAccessListRequestFailure(): deleteRegistrationAccessListRequestFailureAction {
        return ActionCreator(Constants.deleteRegistrationAccessListRequestFailureActionType, null);
    }

    export type getLayoutStyleRequestAction = Action<typeof Constants.getLayoutStyleRequestActionType, null>
    export type getLayoutStyleRequestSuccessAction = Action<typeof Constants.getLayoutStyleRequestSuccessActionType, LayoutStyleModel>;
    export type getLayoutStyleRequestFailureAction = Action<typeof Constants.getLayoutStyleRequestFailureActionType, null>;

    export function getLayoutStyleRequest(): getLayoutStyleRequestAction {
        return ActionCreator(Constants.getLayoutStyleRequestActionType, null);
    }
    export function getLayoutStyleRequestSuccess(P: LayoutStyleModel): getLayoutStyleRequestSuccessAction {
        return ActionCreator(Constants.getLayoutStyleRequestSuccessActionType, P);
    }
    export function getLayoutStyleRequestFailure(): getLayoutStyleRequestFailureAction {
        return ActionCreator(Constants.getLayoutStyleRequestFailureActionType, null);
    }

    export type updateLayoutStyleRequestAction = Action<typeof Constants.updateLayoutStyleRequestActionType, UpdateLayoutStyleModel[]>
    export type updateLayoutStyleRequestSuccessAction = Action<typeof Constants.updateLayoutStyleRequestSuccessActionType, null>;
    export type updateLayoutStyleRequestFailureAction = Action<typeof Constants.updateLayoutStyleRequestFailureActionType, null>;

    export function updateLayoutStyleRequest(P: UpdateLayoutStyleModel[]): updateLayoutStyleRequestAction {
        return ActionCreator(Constants.updateLayoutStyleRequestActionType, P);
    }
    export function updateLayoutStyleRequestSuccess(): updateLayoutStyleRequestSuccessAction {
        return ActionCreator(Constants.updateLayoutStyleRequestSuccessActionType, null);
    }
    export function updateLayoutStyleRequestFailure(): updateLayoutStyleRequestFailureAction {
        return ActionCreator(Constants.updateLayoutStyleRequestFailureActionType, null);
    }

    export type getRecommendationRequestAction = Action<typeof Constants.getRecommendationRequestActionType, null>
    export type getRecommendationRequestSuccessAction = Action<typeof Constants.getRecommendationRequestSuccessActionType, RecommendationModel>;
    export type getRecommendationRequestFailureAction = Action<typeof Constants.getRecommendationRequestFailureActionType, null>;

    export function getRecommendationRequest(): getRecommendationRequestAction {
        return ActionCreator(Constants.getRecommendationRequestActionType, null);
    }
    export function getRecommendationRequestSuccess(P: RecommendationModel): getRecommendationRequestSuccessAction {
        return ActionCreator(Constants.getRecommendationRequestSuccessActionType, P);
    }
    export function getRecommendationRequestFailure(): getRecommendationRequestFailureAction {
        return ActionCreator(Constants.getRecommendationRequestFailureActionType, null);
    }

    export type updateRecommendationRequestAction = Action<typeof Constants.updateRecommendationRequestActionType, RecommendationModel>
    export type updateRecommendationRequestSuccessAction = Action<typeof Constants.updateRecommendationRequestSuccessActionType, null>;
    export type updateRecommendationRequestFailureAction = Action<typeof Constants.updateRecommendationRequestFailureActionType, null>;

    export function updateRecommendationRequest(P: RecommendationModel): updateRecommendationRequestAction {
        return ActionCreator(Constants.updateRecommendationRequestActionType, P);
    }
    export function updateRecommendationRequestSuccess(): updateRecommendationRequestSuccessAction {
        return ActionCreator(Constants.updateRecommendationRequestSuccessActionType, null);
    }
    export function updateRecommendationRequestFailure(): updateRecommendationRequestFailureAction {
        return ActionCreator(Constants.updateRecommendationRequestFailureActionType, null);
    }

    export type getHightlightReelDataRequestAction = Action<typeof Constants.getHightlightReelDataRequestActionType, GetHighlightReelDataModel>
    export type getHightlightReelDataRequestSuccessAction = Action<typeof Constants.getHightlightReelDataRequestSuccessActionType, HighlightReelDataModel>;
    export type getHightlightReelDataRequestFailureAction = Action<typeof Constants.getHightlightReelDataRequestFailureActionType, null>;

    export function getHightlightReelDataRequest(P: GetHighlightReelDataModel): getHightlightReelDataRequestAction {
        return ActionCreator(Constants.getHightlightReelDataRequestActionType, P);
    }
    export function getHightlightReelDataRequestSuccess(P: HighlightReelDataModel): getHightlightReelDataRequestSuccessAction {
        return ActionCreator(Constants.getHightlightReelDataRequestSuccessActionType, P);
    }
    export function getHightlightReelDataRequestFailure(): getHightlightReelDataRequestFailureAction {
        return ActionCreator(Constants.getHightlightReelDataRequestFailureActionType, null);
    }

    export type getLayoutContentRequestAction = Action<typeof Constants.getLayoutContentRequestActionType, null>
    export type getLayoutContentRequestSuccessAction = Action<typeof Constants.getLayoutContentRequestSuccessActionType, LayoutContentModel>;
    export type getLayoutContentRequestFailureAction = Action<typeof Constants.getLayoutContentRequestFailureActionType, null>;

    export function getLayoutContentRequest(): getLayoutContentRequestAction {
        return ActionCreator(Constants.getLayoutContentRequestActionType, null);
    }
    export function getLayoutContentRequestSuccess(P: LayoutContentModel): getLayoutContentRequestSuccessAction {
        return ActionCreator(Constants.getLayoutContentRequestSuccessActionType, P);
    }
    export function getLayoutContentRequestFailure(): getLayoutContentRequestFailureAction {
        return ActionCreator(Constants.getLayoutContentRequestFailureActionType, null);
    }

    export type getAdBannerRequestAction = Action<typeof Constants.getAdBannerRequestActionType, null>
    export type getAdBannerRequestSuccessAction = Action<typeof Constants.getAdBannerRequestSuccessActionType, AdBannerModel>;
    export type getAdBannerRequestFailureAction = Action<typeof Constants.getAdBannerRequestFailureActionType, null>;

    export function getAdBannerRequest(): getAdBannerRequestAction {
        return ActionCreator(Constants.getAdBannerRequestActionType, null);
    }
    export function getAdBannerRequestSuccess(P: AdBannerModel): getAdBannerRequestSuccessAction {
        return ActionCreator(Constants.getAdBannerRequestSuccessActionType, P);
    }
    export function getAdBannerRequestFailure(): getAdBannerRequestFailureAction {
        return ActionCreator(Constants.getAdBannerRequestFailureActionType, null);
    }

    export type updateAdBannerRequestAction = Action<typeof Constants.updateAdBannerRequestActionType, UpdateLayoutStyleModel[]>
    export type updateAdBannerRequestSuccessAction = Action<typeof Constants.updateAdBannerRequestSuccessActionType, null>;
    export type updateAdBannerRequestFailureAction = Action<typeof Constants.updateAdBannerRequestFailureActionType, null>;

    export function updateAdBannerRequest(P: UpdateLayoutStyleModel[]): updateAdBannerRequestAction {
        return ActionCreator(Constants.updateAdBannerRequestActionType, P);
    }
    export function updateAdBannerRequestSuccess(): updateAdBannerRequestSuccessAction {
        return ActionCreator(Constants.updateAdBannerRequestSuccessActionType, null);
    }
    export function updateAdBannerRequestFailure(): updateAdBannerRequestFailureAction {
        return ActionCreator(Constants.updateAdBannerRequestFailureActionType, null);
    }

    export type getSpotlightChannelRequestAction = Action<typeof Constants.getSpotlightChannelRequestActionType, null>
    export type getSpotlightChannelRequestSuccessAction = Action<typeof Constants.getSpotlightChannelRequestSuccessActionType, SpotlightChannelModel[]>;
    export type getSpotlightChannelRequestFailureAction = Action<typeof Constants.getSpotlightChannelRequestFailureActionType, null>;

    export function getSpotlightChannelRequest(): getSpotlightChannelRequestAction {
        return ActionCreator(Constants.getSpotlightChannelRequestActionType, null);
    }
    export function getSpotlightChannelRequestSuccess(P: SpotlightChannelModel[]): getSpotlightChannelRequestSuccessAction {
        return ActionCreator(Constants.getSpotlightChannelRequestSuccessActionType, P);
    }
    export function getSpotlightChannelRequestFailure(): getSpotlightChannelRequestFailureAction {
        return ActionCreator(Constants.getSpotlightChannelRequestFailureActionType, null);
    }

    export type deleteSpotlightChannelRequestAction = Action<typeof Constants.deleteSpotlightChannelRequestActionType, string>
    export type deleteSpotlightChannelRequestSuccessAction = Action<typeof Constants.deleteSpotlightChannelRequestSuccessActionType, null>;
    export type deleteSpotlightChannelRequestFailureAction = Action<typeof Constants.deleteSpotlightChannelRequestFailureActionType, null>;

    export function deleteSpotlightChannelRequest(P: string): deleteSpotlightChannelRequestAction {
        return ActionCreator(Constants.deleteSpotlightChannelRequestActionType, P);
    }
    export function deleteSpotlightChannelRequestSuccess(): deleteSpotlightChannelRequestSuccessAction {
        return ActionCreator(Constants.deleteSpotlightChannelRequestSuccessActionType, null);
    }
    export function deleteSpotlightChannelRequestFailure(): deleteSpotlightChannelRequestFailureAction {
        return ActionCreator(Constants.deleteSpotlightChannelRequestFailureActionType, null);
    }

    export type updateSpotlightSettingOrderRequestAction = Action<typeof Constants.updateSpotlightSettingOrderRequestActionType, Array<string>>
    export type updateSpotlightSettingOrderRequestSuccessAction = Action<typeof Constants.updateSpotlightSettingOrderRequestSuccessActionType, null>;
    export type updateSpotlightSettingOrderRequestFailureAction = Action<typeof Constants.updateSpotlightSettingOrderRequestFailureActionType, null>;

    export function updateSpotlightSettingOrderRequest(P: Array<string>): updateSpotlightSettingOrderRequestAction {
        return ActionCreator(Constants.updateSpotlightSettingOrderRequestActionType, P);
    }
    export function updateSpotlightSettingOrderRequestSuccess(): updateSpotlightSettingOrderRequestSuccessAction {
        return ActionCreator(Constants.updateSpotlightSettingOrderRequestSuccessActionType, null);
    }
    export function updateSpotlightSettingOrderRequestFailure(): updateSpotlightSettingOrderRequestFailureAction {
        return ActionCreator(Constants.updateSpotlightSettingOrderRequestFailureActionType, null);
    }

    export type updateLayoutContentRequestAction = Action<typeof Constants.updateLayoutContentRequestActionType, UpdateLayoutContentModel>
    export type updateLayoutContentRequestSuccessAction = Action<typeof Constants.updateLayoutContentRequestSuccessActionType, null>;
    export type updateLayoutContentRequestFailureAction = Action<typeof Constants.updateLayoutContentRequestFailureActionType, null>;

    export function updateLayoutContentRequest(P: UpdateLayoutContentModel): updateLayoutContentRequestAction {
        return ActionCreator(Constants.updateLayoutContentRequestActionType, P);
    }
    export function updateLayoutContentRequestSuccess(): updateLayoutContentRequestSuccessAction {
        return ActionCreator(Constants.updateLayoutContentRequestSuccessActionType, null);
    }
    export function updateLayoutContentRequestFailure(): updateLayoutContentRequestFailureAction {
        return ActionCreator(Constants.updateLayoutContentRequestFailureActionType, null);
    }

    export type addSpotlightChannelRequestAction = Action<typeof Constants.addSpotlightChannelRequestActionType, AddSpotlightChannelModel>
    export type addSpotlightChannelRequestSuccessAction = Action<typeof Constants.addSpotlightChannelRequestSuccessActionType, null>;
    export type addSpotlightChannelRequestFailureAction = Action<typeof Constants.addSpotlightChannelRequestFailureActionType, null>;

    export function addSpotlightChannelRequest(P: AddSpotlightChannelModel): addSpotlightChannelRequestAction {
        return ActionCreator(Constants.addSpotlightChannelRequestActionType, P);
    }
    export function addSpotlightChannelRequestSuccess(): addSpotlightChannelRequestSuccessAction {
        return ActionCreator(Constants.addSpotlightChannelRequestSuccessActionType, null);
    }
    export function addSpotlightChannelRequestFailure(): addSpotlightChannelRequestFailureAction {
        return ActionCreator(Constants.addSpotlightChannelRequestFailureActionType, null);
    }
}

export type VideoLibraryActions = Omit<typeof VideoLibraryActions, 'Type'>;
export const useVideoLibraryActions = (dispatch: Dispatch) => {
    const { ...actions } = VideoLibraryActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as VideoLibraryActions;
};