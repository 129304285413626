export const ActivityData: any = [
  {
    id: 1,
    month: 'Jan',
    pageViews: 100,
    participation: 100,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 2,
    month: 'Feb',
    pageViews: 200,
    participation: 200,
    missing: 200,
    late: 200,
    onTime: 200,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 3,
    month: 'Mar',
    pageViews: 300,
    participation: 100,
    missing: 200,
    late: 200,
    onTime: 200,
    range: 100,
    percentile: 100,
    median: 200
  },
  {
    id: 4,
    month: 'Apr',
    pageViews: 100,
    participation: 100,
    missing: 200,
    late: 200,
    onTime: 200,
    range: 300,
    percentile: 200,
    median: 200
  },
  {
    id: 5,
    month: 'May',
    pageViews: 100,
    participation: 100,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 6,
    month: 'Jun',
    pageViews: 100,
    participation: 100,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 7,
    month: 'July',
    pageViews: 100,
    participation: 200,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 8,
    month: 'Aug',
    pageViews: 100,
    participation: 100,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  },
  {
    id: 9,
    month: 'Sep',
    pageViews: 100,
    participation: 100,
    missing: 100,
    late: 100,
    onTime: 100,
    range: 200,
    percentile: 200,
    median: 200
  }
];
