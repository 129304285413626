import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { useParams, Link } from 'react-router-dom';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import Loader from 'src/components/loader';
const SinglePoll: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const { id } = useParams<string>();
  const { singlePoll, paginationMeta, isSinglePollLoading } = useSelector((state: RootState) => {
    return {
      singlePoll: state.analytics.singlePoll,
      paginationMeta: state.analytics.paginationMeta,
      isSinglePollLoading: state.analytics.isSinglePollLoading
    };
  });

  useEffect(() => {
    id && analyticsAction.getSinglePollRequest({ id, pageNumber: 1, pageSize: 20 });
  }, []);

  const handlePageChange = (pageNumber: number) => {
    id && analyticsAction.getSinglePollRequest({ id, pageNumber, pageSize: 20 });
  };
  return (
    <>
      <div className="dashboard">
        <Link to={`/analytic/polls`}>
          <i className="fas fa-arrow-left mt-2 mb-2"></i>
          <span className="ml-2">Go Back</span>
        </Link>
        <div>
          <div className="mt-2">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Poll Answers</h2>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Member Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Email</div>
                          </th>

                          <th className="p-2">
                            <div className="font-semibold text-left">UserName</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Attempts</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Results</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isSinglePollLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {singlePoll && singlePoll.length ? (
                              singlePoll.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="p-2">
                                      <div className="text-left">{item.memberName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.memberEmail}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.username}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.attempts}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastActivityDate ? defaultDateFormatter(item.lastActivityDate) : ''}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.results}</div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="text-center py-2" colSpan={5}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePoll;
