
import { RootState } from "../reducers/state";
import { ChannelStateModel, ChannelModel, ChannelLayoutModel, ChannelStylesModel, ChannelHightlightReelModel } from "src/models/channelModel";
import { Actions } from "src/models/channelModel";
import * as Constants from './constants'
import { ChannelActions } from "./actions";
const initialState: RootState.channelModelState = {
    channels: [],
    singleChannel: {} as ChannelModel,
    channelList: [],
    channelLayout: {} as ChannelLayoutModel,
    channelStyles: {} as ChannelStylesModel,
    channelHightlightReel: {} as ChannelHightlightReelModel,
    highlightReelVideo: [],
    subChannels: [],
    channelId: '',
    message: '',
    success: false,
    isChannelAdded: false,
    isChannelUpdated: false,
    isChannelDeleted: false,
    isChannelLayoutUpdated: false,
    isChannelLayoutLoading: false,
    addNewChannelFlag: false,
    isChannelLoading: false,
    isChannelSavedLoading: false,
    isChannelLayoutUpdatedLoading: false,
    isSingleChannelLoading: false,
    isChannelListLoading: false,
    error: false,
    loading: false,
    flattenedChannels: [],
    isChannelSortedLoading: false,
    isChannelSorted: false,
    isHighlightReelVideoAdded: false,
    isHighlightReelVideoDeleted: false
};


function ChannelReducer(state: ChannelStateModel = initialState, action: Actions): ChannelStateModel {
    switch (action.type) {
        case Constants.saveChannelRequestActionType: return saveChannelAction(state);
        case Constants.saveChannelSuccessActionType: return saveChannelSuccessAction(state);
        case Constants.saveChannelFailureActionType: return saveChannelFailureAction(state);

        case Constants.getSingleChannelRequestActionType: return getSingleChannelAction(state);
        case Constants.getSingleChannelSuccessActionType: return getSingleChannelSuccessAction(state, action);
        case Constants.getSingleChannelFailureActionType: return getSingleChannelFailureAction(state);

        case Constants.updateChannelRequestActionType: return updateChannelAction(state);
        case Constants.updateChannelSuccessActionType: return updateChannelSuccessAction(state);
        case Constants.updateChannelFailureActionType: return updateChannelFailureAction(state);

        case Constants.deleteChannelRequestActionType: return deleteChannelAction(state);
        case Constants.deleteChannelSuccessActionType: return deleteChannelSuccessAction(state);
        case Constants.deleteChannelFailureActionType: return deleteChannelFailureAction(state);

        case Constants.getChannelLayoutRequestActionType: return getChannelLayoutAction(state);
        case Constants.getChannelLayoutSuccessActionType: return getChannelLayoutSuccessAction(state, action);
        case Constants.getChannelLayoutFailureActionType: return getChannelLayoutFailureAction(state);

        case Constants.updateChannelLayoutRequestActionType: return updateChannelLayoutAction(state);
        case Constants.updateChannelLayoutSuccessActionType: return updateChannelLayoutSuccessAction(state);
        case Constants.updateChannelLayoutFailureActionType: return updateChannelLayoutFailureAction(state);

        case Constants.getChannelRequestActionType: return getChannelAction(state);
        case Constants.getChannelSuccessActionType: return getChannelSuccessAction(state, action);
        case Constants.getChannelFailureActionType: return getChannelFailureAction(state);

        case Constants.getSubChannelRequestActionType: return getSubChannelSuccessAction(state, action);
        case Constants.resetSubChannelRequestActionType: return resetSubChannelSuccessAction(state);

        case Constants.setChannelIDRequestActionType: return setChannelIdAction(state, action);
        case Constants.resetChannelStateRequestActionType: return resetChannelStateAction(state);
        case Constants.addNewChannelFlagRequestActionType: return addNewChannelFlagAction(state);
        case Constants.resetAddNewChannelFlagRequestActionType: return resetAddNewChannelFlagAction(state);

        case Constants.getFlattenedChannel: return flattenChannel(state);

        case Constants.saveChannelStylesRequestActionType: return saveChannelStylesAction(state);
        case Constants.saveChannelStylesRequestSuccessActionType: return saveChannelStylesSuccessAction(state);
        case Constants.saveChannelStylesRequestFailureActionType: return saveChannelStylesFailureAction(state);

        case Constants.getChannelStylesRequestActionType: return getChannelStylesAction(state);
        case Constants.getChannelStylesRequestSuccessActionType: return getChannelStylesSuccessAction(state, action);
        case Constants.getChannelStylesRequestFailureActionType: return getChannelStylesFailureAction(state);

        case Constants.saveChannelHightlightReelRequestActionType: return saveChannelHightlightReelAction(state);
        case Constants.saveChannelHightlightReelSuccessActionType: return saveChannelHightlightReelSuccessAction(state);
        case Constants.saveChannelHightlightReelFailureActionType: return saveChannelHightlightReelFailureAction(state);

        case Constants.getChannelHightlightReelRequestActionType: return getChannelHightlightReelAction(state);
        case Constants.getChannelHightlightReelSuccessActionType: return getChannelHightlightReelSuccessAction(state, action);
        case Constants.getChannelHightlightReelFailureActionType: return getChannelHightlightReelFailureAction(state);

        case Constants.getChannelListRequestActionType: return getChannelListAction(state);
        case Constants.getChannelListSuccessActionType: return getChannelListSuccessAction(state, action);
        case Constants.getChannelListFailureActionType: return getChannelListFailureAction(state);

        case Constants.sortedChannelRequestActionType: return sortedChannelAction(state);
        case Constants.sortedChannelSuccessActionType: return sortedChannelSuccessAction(state);
        case Constants.sortedChannelFailureActionType: return sortedChannelFailureAction(state);

        case Constants.addHighlightReelVideoRequestActionType: return addHighlightReelVideoAction(state);
        case Constants.addHighlightReelVideoRequestSuccessActionType: return addHighlightReelVideoSuccessAction(state);
        case Constants.addHighlightReelVideoRequestFailureActionType: return addHighlightReelVideoFailureAction(state);

        case Constants.deleteHighlightReelVideoRequestActionType: return deleteHighlightReelVideoAction(state);
        case Constants.deleteHighlightReelVideoRequestSuccessActionType: return deleteHighlightReelVideoSuccessAction(state);
        case Constants.deleteHighlightReelVideoRequestFailureActionType: return deleteHighlightReelVideoFailureAction(state);

        case Constants.getHighlightReelVideoRequestActionType: return getHighlightReelVideoAction(state);
        case Constants.getHighlightReelVideoRequestSuccessActionType: return getHighlightReelVideoSuccessAction(state, action);
        case Constants.getHighlightReelVideoRequestFailureActionType: return getHighlightReelVideoFailureAction(state);

        default: return state;
    }

    function saveChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
            isChannelSavedLoading: true,
        };
    }
    function saveChannelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: true,
            message: 'Channel Added Successfully',
            isChannelSavedLoading: false,
            loading: false
        };
    }
    function saveChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: false,
            isChannelSavedLoading: false,
            loading: false,
            error: true
        }
    }
    function getSingleChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isSingleChannelLoading: true,
        };
    }
    function getSingleChannelSuccessAction(state: ChannelStateModel, action: ChannelActions.getSingleChannelRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                singleChannel: action.payload,
                isSingleChannelLoading: false
            };
        }
        return state;
    }
    function getSingleChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            isSingleChannelLoading: false,
        }
    }
    function updateChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelSavedLoading: true,
            loading: true,
        };
    }
    function updateChannelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelUpdated: true,
            message: 'Channel Updated Successfully',
            isChannelSavedLoading: false,
            loading: false
        };
    }
    function updateChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelUpdated: false,
            isChannelSavedLoading: false,
            loading: false,
            error: true
        }
    }
    function deleteChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteChannelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelDeleted: true,
            message: 'Channel Deleted Successfully',
            loading: false
        };
    }
    function deleteChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelDeleted: false,
            loading: false,
            error: true
        }
    }
    function getChannelLayoutAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelLayoutLoading: true,
        };
    }
    function getChannelLayoutSuccessAction(state: ChannelStateModel, action: ChannelActions.getChannelLayoutRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channelLayout: action.payload,
                isChannelLayoutLoading: false
            };
        }
        return state;
    }
    function getChannelLayoutFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            isChannelLayoutLoading: false,
        }
    }
    function updateChannelLayoutAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelLayoutUpdatedLoading: true,
        };
    }
    function updateChannelLayoutSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelLayoutUpdated: true,
            isChannelLayoutUpdatedLoading: false,
            message: 'Channel Edited Successfully',
        };
    }
    function updateChannelLayoutFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelLayoutUpdated: false,
            isChannelLayoutUpdatedLoading: false,
            error: true
        }
    }
    function getChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelLoading: true,
        };
    }
    function getChannelSuccessAction(state: ChannelStateModel, action: ChannelActions.getChannelRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channels: action.payload,
                isChannelLoading: false
            };
        }
        return state;
    }
    function getChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            isChannelLoading: false,
        }
    }
    function getSubChannelSuccessAction(state: ChannelStateModel, action: ChannelActions.getSubChannelRequestAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                subChannels: action.payload,
                loading: false
            };
        }
        return state;
    }
    function resetSubChannelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            subChannels: initialState.subChannels,
            loading: false
        };
    }
    function setChannelIdAction(state: ChannelStateModel, action: ChannelActions.setChannelIdRequestAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channelId: action.payload,
                loading: false
            };
        }
        return state;
    }
    function resetChannelStateAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: false,
            isChannelDeleted: false,
            isChannelUpdated: false,
            isChannelLayoutUpdated: false,
            error: false
        };
    }
    function addNewChannelFlagAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            addNewChannelFlag: true,

        };
    }
    function resetAddNewChannelFlagAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            addNewChannelFlag: false,

        };
    }

    function flattenChannel(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            flattenedChannels: processChannel(state.channels)

        };
    }

    function processChannel(channels: any): any {
        return expandChannel(channels);

    }

    function expandChannel(channels: any): any {
        let flattenedChannels: any = [];
        let channel: any = null;
        for (channel of channels) {
            flattenedChannels.push({
                value: channel.id,
                label: channel.title

            });
            if (channel.subChannels)
                flattenedChannels = [...flattenedChannels, ...expandChannel(channel.subChannels)];
        }
        return flattenedChannels;
    }
    function saveChannelStylesAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
            isChannelSavedLoading: true,
        };
    }
    function saveChannelStylesSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: true,
            message: 'Saved Successfully',
            isChannelSavedLoading: false,
            loading: false
        };
    }
    function saveChannelStylesFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: false,
            isChannelSavedLoading: false,
            loading: false,
            error: true
        }
    }
    function getChannelStylesAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getChannelStylesSuccessAction(state: ChannelStateModel, action: ChannelActions.getChannelStylesRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channelStyles: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getChannelStylesFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function saveChannelHightlightReelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
            isChannelSavedLoading: true,
        };
    }
    function saveChannelHightlightReelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: true,
            message: 'Saved Successfully',
            isChannelSavedLoading: false,
            loading: false
        };
    }
    function saveChannelHightlightReelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelAdded: false,
            isChannelSavedLoading: false,
            loading: false,
            error: true
        }
    }
    function getChannelHightlightReelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getChannelHightlightReelSuccessAction(state: ChannelStateModel, action: ChannelActions.getChannelHightlightReelRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channelHightlightReel: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getChannelHightlightReelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }
    function getChannelListAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelListLoading: true,
        };
    }
    function getChannelListSuccessAction(state: ChannelStateModel, action: ChannelActions.getChannelListRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                channelList: action.payload,
                isChannelListLoading: false
            };
        }
        return state;
    }
    function getChannelListFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            success: false,
            isChannelListLoading: false,
        }
    }

    function sortedChannelAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelSortedLoading: true,
        };
    }
    function sortedChannelSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelSorted: true,
            message: 'Channel Sorted Successfully',
            isChannelSortedLoading: false,
        };
    }
    function sortedChannelFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isChannelSorted: false,
            isChannelSortedLoading: false
        }
    }
    function addHighlightReelVideoAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true
        };
    }
    function addHighlightReelVideoSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isHighlightReelVideoAdded: true,
            message: 'Success',
            loading: false
        };
    }
    function addHighlightReelVideoFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isHighlightReelVideoAdded: false,
            loading: false
        }
    }
    function deleteHighlightReelVideoAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true
        };
    }
    function deleteHighlightReelVideoSuccessAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isHighlightReelVideoDeleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteHighlightReelVideoFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            isHighlightReelVideoDeleted: false,
            loading: false
        }
    }
    function getHighlightReelVideoAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getHighlightReelVideoSuccessAction(state: ChannelStateModel, action: ChannelActions.getHighlightReelVideoRequestSuccessAction): ChannelStateModel {
        if (action.payload) {
            return {
                ...state,
                highlightReelVideo: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getHighlightReelVideoFailureAction(state: ChannelStateModel): ChannelStateModel {
        return {
            ...state,
            loading: false,
        }
    }
}

export default ChannelReducer
