export const getUserRequestActionType = 'GET_USER_REQUEST'
export const getUserRequestSuccessActionType = 'GET_USER_REQUEST_SUCCESS'
export const getUserRequestFailureActionType = 'GET_USER_REQUEST_FAILURE'
export const getUserRoleRequestActionType = 'GET_USER_ROLE_REQUEST'
export const getUserRoleRequestSuccessActionType = 'GET_USER_ROLE_REQUEST_SUCCESS'
export const getUserRoleRequestFailureActionType = 'GET_USER_ROLE_REQUEST_FAILURE'
export const getUserDetailsRequestActionType = 'GET_USER_DETAILS_REQUEST'
export const getUserDetailsRequestSuccessActionType = 'GET_USER_DETAILS_REQUEST_SUCCESS'
export const getUserDetailsRequestFailureActionType = 'GET_USER_DETAILS_REQUEST_FAILURE'
export const addUserRequestActionType = 'ADD_USER_REQUEST'
export const addUserRequestSuccessActionType = 'ADD_USER_REQUEST_SUCCESS'
export const addUserRequestFailureActionType = 'ADD_USER_REQUEST_FAILURE'
export const getMembersRequestActionType = 'GET_MEMBERS_REQUEST'
export const getMembersRequestSuccessActionType = 'GET_MEMBERS_REQUEST_SUCCESS'
export const getMembersRequestFailureActionType = 'GET_MEMBERS_REQUEST_FAILURE'
export const addMembersRequestActionType = 'ADD_MEMBERS_REQUEST'
export const addMembersRequestSuccessActionType = 'ADD_MEMBERS_REQUEST_SUCCESS'
export const addMembersRequestFailureActionType = 'ADD_MEMBERS_REQUEST_FAILURE'
export const getMembersDetailRequestActionType = 'GET_MEMBERS_DETAIL_REQUEST'
export const getMembersDetailRequestSuccessActionType = 'GET_MEMBERS_DETAIL_REQUEST_SUCCESS'
export const getMembersDetailRequestFailureActionType = 'GET_MEMBERS_DETAIL_REQUEST_FAILURE'
export const editMemberRequestActionType = 'EDIT_MEMBER_REQUEST'
export const editMemberRequestSuccessActionType = 'EDIT_MEMBER_REQUEST_SUCCESS'
export const editMemberRequestFailureActionType = 'EDIT_MEMBER_REQUEST_FAILURE'
export const UpdateUserRequestActionType = 'UPDATE_USER_REQUEST'
export const UpdateUserRequestSuccessActionType = 'UPDATE_USER_REQUEST_SUCCESS'
export const UpdateUserRequestFailureActionType = 'UPDATE_USER_REQUEST_FAILURE'
export const deleteMembersRequestActionType = 'DELETE_MEMBERS_REQUEST'
export const deleteMembersRequestSuccessActionType = 'DELETE_MEMBERS_REQUEST_SUCCESS'
export const deleteMembersRequestFailureActionType = 'DELETE_MEMBERS_REQUEST_FAILURE'
export const uploadProfileRequestActionType = 'UPLOAD_PROFILE_REQUEST'
export const uploadProfileRequestSuccessActionType = 'UPLOAD_PROFILE_REQUEST_SUCCESS'
export const uploadProfileRequestFailureActionType = 'UPLOAD_PROFILE_REQUEST_FAILURE'
export const updateCreditPreferencesRequestActionType = 'UPDATE_CREDIT_REQUEST'
export const updateCreditPreferencesRequestSuccessActionType = 'UPDATE_CREDIT_REQUEST_SUCCESS'
export const updateCreditPreferencesRequestFailureActionType = 'UPDATE_CREDIT_REQUEST_FAILURE'
export const getStripeSubscriptionRequestActionType = 'GET_STRIPE_SUBSCRIPTION_REQUEST'
export const getStripeSubscriptionRequestSuccessActionType = 'GET_STRIPE_SUBSCRIPTION_REQUEST_SUCCESS'
export const getStripeSubscriptionRequestFailureActionType = 'GET_STRIPE_SUBSCRIPTION_REQUEST_FAILURE'
export const cancelStripeSubscriptionRequestActionType = 'CANCEL_STRIPE_SUBSCRIPTION_REQUEST'
export const cancelStripeSubscriptionSuccessActionType = 'CANCEL_STRIPE_SUBSCRIPTION_SUCCESS'
export const cancelStripeSubscriptionFailureActionType = 'CANCEL_STRIPE_SUBSCRIPTION_FAILURE'
export const checkUserNameAvailableRequestActionType = 'CHECK_USERNAME_AVAILABE_REQUEST'
export const checkUserNameAvailableRequestSuccessActionType = 'CHECK_USERNAME_AVAILABE_REQUEST_SUCCESS'
export const checkUserNameAvailableRequestFailureActionType = 'CHECK_USERNAME_AVAILABE_REQUEST_FAILURE'
export const getMemberVideoAnalyticsRequestActionType = 'GET_MEMBER_VIDEO_ANALYTICS_REQUEST'
export const getMemberVideoAnalyticsRequestSuccessActionType = 'GET_MEMBER_VIDEO_ANALYTICS_REQUEST_SUCCESS'
export const getMemberVideoAnalyticsRequestFailureActionType = 'GET_MEMBER_VIDEO_ANALYTICS_REQUEST_FAILURE'
export const getMemberSubscriptionRequestActionType = 'GET_MEMBER_SUBSCRIPTION_REQUEST'
export const getMemberSubscriptionRequestSuccessActionType = 'GET_MEMBER_SUBSCRIPTION_REQUEST_SUCCESS'
export const getMemberSubscriptionRequestFailureActionType = 'GET_MEMBER_SUBSCRIPTION_REQUEST_FAILURE'
export const getUserProfileRequestActionType = 'GET_USER_PROFILE_REQUEST'
export const getUserProfileRequestSuccessActionType = 'GET_USER_PROFILE_REQUEST_SUCCESS'
export const getUserProfileRequestFailureActionType = 'GET_USER_PROFILE_REQUEST_FAILURE'
export const getMemberInvoicesRequestActionType = 'GET_MEMBER_INVOICES_REQUEST'
export const getMemberInvoicesRequestSuccessActionType = 'GET_MEMBER_INVOICES_REQUEST_SUCCESS'
export const getMemberInvoicesRequestFailureActionType = 'GET_MEMBER_INVOICES_REQUEST_FAILURE'
export const getTotalMembersRequestActionType = 'GET_TOTAL_MEMBERS_REQUEST'
export const getTotalMembersRequestSuccessActionType = 'GET_TOTAL_MEMBERS_REQUEST_SUCCESS'
export const getTotalMembersRequestFailureActionType = 'GET_TOTAL_MEMBERS_REQUEST_FAILURE'
export const getSubscriptionProductsRequestActionType = 'GET_SUBSCRIPTION_PRODUCTS_REQUEST'
export const getSubscriptionProductsRequestSuccessActionType = 'GET_SUBSCRIPTION_PRODUCTS_REQUEST_SUCCESS'
export const getSubscriptionProductsRequestFailureActionType = 'GET_SUBSCRIPTION_PRODUCTS_REQUEST_FAILURE'
export const deleteMemberListRequestActionType = 'DELETE_MEMBER_LIST_REQUEST'
export const deleteMemberListRequestSuccessActionType = 'DELETE_MEMBER_LIST_REQUEST_SUCCESS'
export const deleteMemberListRequestFailureActionType = 'DELETE_MEMBER_LIST_REQUEST_FAILURE'
export const getMemberListRequestActionType = 'GET_MEMBER_LIST_REQUEST'
export const getMemberListRequestSuccessActionType = 'GET_MEMBER_LIST_REQUEST_SUCCESS'
export const getMemberListRequestFailureActionType = 'GET_MEMBER_LIST_REQUEST_FAILURE'
export const getUserActivityTimeLineRequestActionType = 'GET_USER_ACTIVITY_TIMELINE_REQUEST'
export const getUserActivityTimeLineRequestSuccessActionType = 'GET_USER_ACTIVITY_TIMELINE_REQUEST_SUCCESS'
export const getUserActivityTimeLineRequestFailureActionType = 'GET_USER_ACTIVITY_TIMELINE_REQUEST_FAILURE'
export const saveMemberListRequestActionType = 'SAVE_MEMBER_LIST_REQUEST'
export const saveMemberListRequestSuccessActionType = 'SAVE_MEMBER_LIST_REQUEST_SUCCESS'
export const saveMemberListRequestFailureActionType = 'SAVE_MEMBER_LIST_REQUEST_FAILURE'
export const updateMemberListRequestActionType = 'UPDATE_MEMBER_LIST_REQUEST'
export const updateMemberListRequestSuccessActionType = 'UPDATE_MEMBER_LIST_REQUEST_SUCCESS'
export const updateMemberListRequestFailureActionType = 'UPDATE_MEMBER_LIST_REQUEST_FAILURE'
export const getSingleMemberListRequestActionType = 'GET_SINGLE_MEMBER_LIST_REQUEST'
export const getSingleMemberListRequestSuccessActionType = 'GET_SINGLE_MEMBER_LIST_REQUEST_SUCCESS'
export const getSingleMemberListRequestFailureActionType = 'GET_SINGLE_MEMBER_LIST_REQUEST_FAILURE'
export const saveMemberListTypeRequestActionType = 'SAVE_MEMBER_LIST_TYPE_REQUEST'
export const saveMemberListTypeRequestSuccessActionType = 'SAVE_MEMBER_LIST_TYPE_REQUEST_SUCCESS'
export const saveMemberListTypeRequestFailureActionType = 'SAVE_MEMBER_LIST_TYPE_REQUEST_FAILURE'
export const searchMemberRequestActionType = 'SEARCH_MEMBER_REQUEST'
export const searchMemberRequestSuccessActionType = 'SEARCH_MEMBER_REQUEST_SUCCESS'
export const searchMemberRequestFailureActionType = 'SEARCH_MEMBER_REQUEST_FAILURE'
export const getRolesRequestActionType = 'GET_ROLES_REQUEST'
export const getRolesRequestSuccessActionType = 'GET_ROLES_REQUEST_SUCCESS'
export const getRolesRequestFailureActionType = 'GET_ROLES_REQUEST_FAILURE'
export const getStatusRequestActionType = 'GET_STATUS_REQUEST'
export const getStatusRequestSuccessActionType = 'GET_STATUS_REQUEST_SUCCESS'
export const getStatusRequestFailureActionType = 'GET_STATUS_REQUEST_FAILURE'
export const resetMemberStateActionType = 'RESET_MEMBERS_STATE'
export const resetUserStateActionType = 'RESET_USER_STATE'
export const resetMemberDataStateActionType = 'RESET_MEMBER_DATA_STATE'
export const resetUserDataStateActionType = 'RESET_USER_DATA_STATE'
export const getLoginHistoryRequestActionType = 'GET_LOGIN_HISTORY_REQUEST'
export const getLoginHistoryRequestSuccessActionType = 'GET_LOGIN_HISTORY_REQUEST_SUCCESS'
export const getLoginHistoryRequestFailureActionType = 'GET_LOGIN_HISTORY_REQUEST_FAILURE'
export const getMemberListByMemberIdRequestActionType = 'GET_MEMBER_LIST_BY_MEMBERID_REQUEST'
export const getMemberListByMemberIdRequestSuccessActionType = 'GET_MEMBER_LIST_BY_MEMBERID_REQUEST_SUCCESS'
export const getMemberListByMemberIdRequestFailureActionType = 'GET_MEMBER_LIST_BY_MEMBERID_REQUEST_FAILURE'
export const getMemberEventRegistrationRequestActionType = 'GET_MEMBER_EVENT_REGISTRATION_REQUEST'
export const getMemberEventRegistrationRequestSuccessActionType = 'GET_MEMBER_EVENT_REGISTRATION_REQUEST_SUCCESS'
export const getMemberEventRegistrationRequestFailureActionType = 'GET_MEMBER_EVENT_REGISTRATION_REQUEST_FAILURE'
export const getMemberCountDataRequestActionType = 'GET_MEMBER_COUNT_DATA_REQUEST'
export const getMemberCountDataRequestSuccessActionType = 'GET_MEMBER_COUNT_DATA_REQUEST_SUCCESS'
export const getMemberCountDataRequestFailureActionType = 'GET_MEMBER_COUNT_DATA_REQUEST_FAILURE'
export const updateMemberProfileRequestActionType = 'UPDATE_MEMBER_PROFILE_REQUEST'
export const updateMemberProfileRequestSuccessActionType = 'UPDATE_MEMBER_PROFILE_REQUEST_SUCCESS'
export const updateMemberProfileRequestFailureActionType = 'UPDATE_MEMBER_PROFILE_REQUEST_FAILURE'
export const uploadEmailDomainRequestActionType = 'UPLOAD_EMAIL_DOMAIN_REQUEST'
export const uploadEmailDomainRequestSuccessActionType = 'UPLOAD_EMAIL_DOMAIN_REQUEST_SUCCESS'
export const uploadEmailDomainRequestDataExistActionType = 'UPLOAD_EMAIL_DOMAIN_REQUEST_ALREADY_EXIST'
export const uploadEmailDomainRequestFailureActionType = 'UPLOAD_EMAIL_DOMAIN_REQUEST_FAILURE'
export const uploadEmailRequestActionType = 'UPLOAD_EMAIL_REQUEST'
export const uploadEmailRequestSuccessActionType = 'UPLOAD_EMAIL_REQUEST_SUCCESS'
export const uploadEmailRequestDataExistActionType = 'UPLOAD_EMAIL_REQUEST_ALREADY_EXIST'
export const uploadEmailRequestFailureActionType = 'UPLOAD_EMAIL_REQUEST_FAILURE'
export const uploadUserNameRequestActionType = 'UPLOAD_USERNAME_REQUEST'
export const uploadUserNameRequestSuccessActionType = 'UPLOAD_USERNAME_REQUEST_SUCCESS'
export const uploadUserNameRequestDataExistActionType = 'UPLOAD_USERNAME_REQUEST_ALREADY_EXIST'
export const uploadUserNameRequestFailureActionType = 'UPLOAD_USERNAME_REQUEST_FAILURE'
export const searchMemberListDetailsRequestActionType = 'SEARCH_MEMBER_LIST_DETAILS_REQUEST'
export const searchMemberListDetailsRequestSuccessActionType = 'SEARCH_MEMBER_LIST_DETAILS_REQUEST_SUCCESS'
export const searchMemberListDetailsRequestFailureActionType = 'SEARCH_MEMBER_LIST_DETAILS_REQUEST_FAILURE'
export const deleteMemberListDetailsRequestActionType = 'DELETE_MEMBER_LIST_DETAILS_REQUEST'
export const deleteMemberListDetailsRequestSuccessActionType = 'DELETE_MEMBER_LIST_DETAILS_REQUEST_SUCCESS'
export const deleteMemberListDetailsRequestFailureActionType = 'DELETE_MEMBER_LIST_DETAILS_REQUEST_FAILURE'
export const changePasswordRequestActionType = 'CHANGE_PASSWORD_REQUEST'
export const changePasswordRequestSuccessActionType = 'CHANGE_PASSWORD_REQUEST_SUCCESS'
export const changePasswordRequestFailureActionType = 'CHANGE_PASSWORD_REQUEST_FAILURE'
export const generateMFAQrRequestActionType = 'GENERATE_MFA_QR_REQUEST'
export const generateMFAQrRequestSuccessActionType = 'GENERATE_MFA_QR_REQUEST_SUCCESS'
export const generateMFAQrRequestFailureActionType = 'GENERATE_MFA_QR_REQUEST_FAILURE'
export const verifyMfaCodeRequestActionType = 'VERIFY_MFA_CODE_REQUEST'
export const verifyMfaCodeRequestSuccessActionType = 'VERIFY_MFA_CODE_REQUEST_SUCCESS'
export const verifyMfaCodeRequestFailureActionType = 'VERIFY_MFA_CODE_REQUEST_FAILURE'
export const resetMFAStateRequestActionType = 'RESET_MFA_STATE_REQUEST'
export const userMfaSetupRequestActionType = 'USER_MFA_SETUP_REQUEST'
export const userMfaSetupRequestSuccessActionType = 'USER_MFA_SETUP_REQUEST_SUCCESS'
export const userMfaSetupRequestFailureActionType = 'USER_MFA_SETUP_REQUEST_FAILURE'
export const reset = 'RESET'

