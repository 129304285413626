import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { SingleVideoModel } from 'src/models/videosModel';
import { useDispatch, useSelector } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { SubtractSeconds } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { videoStatusEnum } from 'src/components/utilities/utils';
import { convertSecondToHourMinuteSecond } from 'src/components/utilities/utils';
interface IProps {
  videoId: string;
  singleVideo: SingleVideoModel;
  pollType: number | undefined;
  modalEditFlag: boolean;
  setModalEditFlag: (value: boolean) => void;
}
const OpenTextModal: React.FC<IProps> = ({
  videoId,
  singleVideo,
  pollType,
  modalEditFlag,
  setModalEditFlag
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const videoActions = useVideosActions(dispatch);
  const [showDescription, setShowDescription] = useState(false);
  const [actionTimeError, setActionTimeError] = useState<boolean>(false);
  const [displayResultData, setDisplayResultData] = useState(false);
  const [time, setTime] = useState<any>('00:00:00');
  const onChange = (e: any) => {
    setTime(moment(e).format('HH:mm:ss'));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();
  const { isPollSaved, pollsResult, isPollSavedLoading } = useSelector((state: RootState) => {
    return {
      isPollSaved: state.videos.isPollSaved,
      pollsResult: state.videos.pollsResult,
      isPollSavedLoading: state.videos.isPollSavedLoading
    };
  });
  const onSubmit = (data: any) => {
    if (
      time <= singleVideo.duration ||
      singleVideo.videoStatus === 0 ||
      singleVideo.videoStatus === 1 ||
      singleVideo.videoStatus === 3
    ) {
      setActionTimeError(false);
      const payloadData = {
        videoId,
        pollTitle: data.pollTitle,
        pollType: 2,
        pollDisplayTimeString: time,
        displayResults: displayResultData,
        displayResultsTimer: data.displayResultsTimer,
        quizzes: {
          name: data.pollTitle,
          questions: [
            {
              position: 1,
              questionText: data.pollTitle,
              description: data.description
            }
          ]
        }
      };
      if (modalEditFlag) {
        const payloadData = {
          videoId,
          activePollStatus: pollsResult?.activePollStatus,
          pollTitle: data.pollTitle,
          pollType: 2,
          pollDisplayTimeString: time,
          displayResults: displayResultData,
          displayResultsTimer: data.displayResultsTimer,
          quizzes: {
            id: pollsResult.quizzes.id,
            name: data.pollTitle,
            questions: [
              {
                id: pollsResult?.quizzes?.questions[0]?.id,
                position: 1,
                questionText: data.pollTitle,
                description: data.description
              }
            ]
          }
        };
        if (
          singleVideo.videoStatus === videoStatusEnum.Standby ||
          videoStatusEnum.Live ||
          videoStatusEnum.Stopped
        ) {
          const newPayload = { ...payloadData, pollDurationTimerString: time };
          videoActions.updatePollRequest({ ...newPayload, pollId: pollsResult.pollId });
        } else {
          videoActions.updatePollRequest({ ...payloadData, pollId: pollsResult.pollId });
        }
      } else {
        if (
          singleVideo.videoStatus === videoStatusEnum.Standby ||
          videoStatusEnum.Live ||
          videoStatusEnum.Stopped
        ) {
          const newPayload = { ...payloadData, pollDurationTimerString: time };
          videoActions.savePollRequest(newPayload);
        } else {
          videoActions.savePollRequest(payloadData);
        }
      }
    } else {
      setActionTimeError(true);
    }
  };
  useEffect(() => {
    if (singleVideo) {
      setValue('duration', singleVideo.duration);
    }
  }, [singleVideo]);
  const displayResult = watch('displayResults');

  useEffect(() => {
    setValue('pollType', pollType);
  }, [pollType]);

  const handleCloseClick = () => {
    setTime('00:01:00');
    setActionTimeError(false);
    setShowDescription(false);
    setDisplayResultData(false);
    reset({
      pollDisplayTimeString: '',
      displayResults: false,
      pollTitle: '',
      description: ''
    });
    setModalEditFlag(false);
  };
  useEffect(() => {
    if (isPollSaved) {
      if (ref.current) {
        ref.current.click();
      }
      handleCloseClick();
    }
  }, [isPollSaved]);

  useEffect(() => {
    if (pollsResult && modalEditFlag) {
      const resetData = {
        pollDisplayTimeString: pollsResult.pollDisplayTime,
        displayResultsTimer: pollsResult.displayResultsTimer,
        description: pollsResult?.quizzes?.questions[0]?.description,
        pollTitle: pollsResult?.quizzes?.questions[0]?.questionText
      };
      setShowDescription(pollsResult?.quizzes?.questions[0]?.description === '' ? false : true);
      setDisplayResultData(pollsResult.displayResults);
      setTime(pollsResult.pollDisplayTime ? pollsResult.pollDisplayTime : '00:00:00');
      reset(resetData);
    }
  }, [pollsResult]);
  const handleDisplayAtTheEndClick = () => {
    if (singleVideo) {
      setTime(SubtractSeconds(singleVideo.duration));
    }
  };
  useEffect(() => {
    if (!modalEditFlag) {
      handleCloseClick();
    }
  }, [modalEditFlag]);
  useEffect(() => {
    if (singleVideo && !modalEditFlag) {
      setTime('00:01:00');
    }
  }, [singleVideo]);
  useEffect(() => {
    if (pollsResult && modalEditFlag) {
      if (
        singleVideo.videoStatus === videoStatusEnum.Standby ||
        videoStatusEnum.Live ||
        videoStatusEnum.Stopped
      ) {
        const secondToTime = convertSecondToHourMinuteSecond(pollsResult.pollDurationTimer);
        setTime(secondToTime);
      }
    }
  }, [pollsResult]);
  return (
    <div>
      <div
        className="modal fade"
        id="OpenTextModal"
        aria-labelledby="OpenTextModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="OpenTextModalLabel">
                Open Text
                <span>
                  {actionTimeError && (
                    <p className="text-red-500 text-xs error">
                      Poll Display Time can not be greater than Video Duration
                    </p>
                  )}
                </span>
              </h5>
              <button
                ref={ref}
                onClick={handleCloseClick}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form className="px-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body relative py-4 border-b">
                <select disabled {...register('pollType')} className="input-style w-full mb-5">
                  <option value={0}>Quiz</option>
                  <option value={1}>Multiple Choice</option>
                  <option value={2}>Open Text</option>
                  <option value={3}>Star Rating</option>
                </select>
                <div className="flex items-center justify-center">
                  <div className={`form-group mb-3 mr-3 w-full`}>
                    <label className="block text-sm font-medium mb-1">Video Duration</label>
                    <input
                      disabled
                      type="text"
                      className="input-style quiz-disable"
                      id="duration"
                      {...register('duration')}
                    />
                  </div>

                  <div className={`form-group mb-3 w-full flex items-center`}>
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Poll Display Time (hh:mm:ss)
                      </label>
                      <TimePicker
                        className="w-full"
                        onChange={(e) => onChange(e)}
                        value={time ? moment(time, 'HH:mm:ss') : undefined}
                      />
                    </div>
                    {singleVideo &&
                      singleVideo.videoStatus !== 0 &&
                      singleVideo.videoStatus !== 1 &&
                      singleVideo.videoStatus !== 3 && (
                        <div className="ml-2 mt-3">
                          <button
                            onClick={handleDisplayAtTheEndClick}
                            type="button"
                            className="btn-primary w-auto mt-3 mr-auto ml-0 borderonly-btn whitespace-nowrap">
                            Display at end
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                {singleVideo &&
                  singleVideo.videoStatus !== 0 &&
                  singleVideo.videoStatus !== 1 &&
                  singleVideo.videoStatus !== 2 &&
                  singleVideo.videoStatus !== 3 &&
                  singleVideo.videoStatus !== 4 &&
                  singleVideo.videoStatus !== 5 && (
                    <div className="flex items-center justify-center">
                      <div className={`form-group mb-3 w-full mr-3`}>
                        <label className="block text-sm font-medium mb-1">
                          Display Result to User
                        </label>
                        <div className="form-check form-switch pl-0 mr-3 relative">
                          <input
                            onChange={(e) => setDisplayResultData(e.target.checked)}
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                            id="displayResults"
                            checked={displayResultData}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </div>

                      <div
                        className={`form-group mb-3 w-full ${displayResultData ? '' : 'hidden'}`}>
                        <label className="block text-sm font-medium mb-1">
                          Display Result Timer
                        </label>
                        <select className="input-style" {...register('displayResultsTimer')}>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                          <option value={60}>60</option>
                          <option value={90}>90</option>
                          <option value={120}>120</option>
                        </select>
                      </div>
                    </div>
                  )}
                <div className="mt-3 px-0">
                  <div className="border rounded-md">
                    <div className="flex items-center justify-between border-b px-5 py-4">
                      <p className="font-semibold mb-0">Question</p>
                    </div>

                    <div className="p-4 pt-2 questions-side-card">
                      <div className="flex-items">
                        <label className="font-semibold"></label>

                        <div className="dropdown relative">
                          <button
                            className="dropdown-toggle border-0 input-style mx-auto flex-items"
                            type="button"
                            id="action-dropdown"
                            data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i className="fas fa-chevron-down"></i>
                          </button>
                          <ul
                            className="dropdown-menu dropdown-menu-ul w-32"
                            aria-labelledby="action-dropdown">
                            <li className="cursor-pointer" onClick={() => setShowDescription(true)}>
                              <span className="action-menu">Add description</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={`form-group mb-3 w-full relative`}>
                        <input
                          type="text"
                          className="input-style pl-0"
                          placeholder="What would you like to ask ?"
                          {...register('pollTitle')}
                        />
                      </div>
                      <div
                        className={`form-group mb-3 w-full relative ${
                          showDescription ? '' : 'hidden'
                        }`}>
                        <input
                          type="text"
                          className="input-style pl-0"
                          placeholder="Description"
                          {...register('description')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  disabled={isPollSavedLoading}
                  type="submit"
                  className={`px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 ${
                    isPollSavedLoading ? 'opacity-25' : ''
                  }`}>
                  Save
                </button>
                <button
                  onClick={handleCloseClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
                <span className="ml-1"> {isPollSavedLoading && <Loader />}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenTextModal;
