import React, { useState } from 'react';
import TreeView from '../treeView';
import { useChannelActions } from 'src/store/channel/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
const VideoSidebar = ({ setDeleteChannelId, dragFlag, setDragFlag, setRecent, recent }: any) => {
  const [channelShow, setChannelShow] = useState(false);
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const channelAction = useChannelActions(dispatch);
  const handleChannelClick = () => {
    if (channelShow) {
      setChannelShow(false);
    } else {
      setChannelShow(true);
    }
  };
  const handleAddNewClick = () => {
    channelAction.addNewChannelFlagRequest();
    channelAction.getChannelListRequest();
  };

  const handleRecentClick = () => {
    setRecent(true);
    const data = { pageNumber: 1, channelId: id, pageSize: 10 };
    videoAction.searchVideoRequest(data);
  };
  return (
    <div className="border pl-0 lg:w-1/3 xl:w-1/5 md:w-full lg:mr-5 shadow leftSidebar cursor-default">
      <div className="p-4">
        <button
          onClick={handleAddNewClick}
          type="button"
          className="btn-primary mt-0 mb-6"
          data-bs-toggle="modal"
          data-bs-target="#SettingsModal">
          Add Channel
        </button>
        {/* <div className="flex items-center tree-view my-4" onClick={handleChannelClick}>
          {channelShow ? (
            <span className="mr-2">
              <i className="fas fa-chevron-down text-xs"></i>
            </span>
          ) : (
            <span className="mr-2">
              <i className="fas fa-chevron-right text-xs"></i>
            </span>
          )}

          <span className="mr-2">
            <i className="far fa-folder"></i>
          </span>
          <p>Channels</p>
        </div> */}
        <div className={`d-tree ml-2 ${dragFlag ? 'taller-channel-tree' : ''}`}>
          <TreeView
            setDeleteChannelId={setDeleteChannelId}
            setDragFlag={setDragFlag}
            dragFlag={dragFlag}
            setRecent={setRecent}
          />
        </div>
        {/* <div className="tree-view flex items-center my-4">
          <span className="mr-2">
            <i className="fas fa-chevron-right text-xs"></i>
          </span>
          <span className="mr-2">
            <i className="far fa-folder"></i>
          </span>
          <p>Webinars</p>
        </div> */}
        {/* <div className="tree-view flex items-center my-4">
          <span className="mr-2">
            <i className="fas fa-chevron-right text-xs"></i>
          </span>
          <span className="mr-2">
            <i className="far fa-folder"></i>
          </span>
          <p>Confrences</p>
        </div> */}
        {/* <div className="tree-view flex items-center my-4">
          <span className="mr-2">
            <i className="fas fa-chevron-right text-xs"></i>
          </span>
          <span className="mr-2">
            <i className="far fa-folder"></i>
          </span>
          <p>Seminars</p>
        </div> */}
        {/* <div className="tree-view flex items-center my-4">
          <span className="mr-2">
            <i className="fas fa-chevron-right text-xs"></i>
          </span>
          <span className="mr-2">
            <i className="far fa-folder"></i>
          </span>
          <p>Internal Employee</p>
        </div> */}
      </div>

      <div className={`videos-view ${dragFlag ? 'hidden' : 'block'}`}>
        <ul
          className="nav nav-tabs flex flex-col flex-wrap list-none pl-0"
          id="tabs-tabVertical"
          role="tablist">
          <li className="nav-item flex-grow" role="presentation">
            <a
              onClick={() => handleRecentClick()}
              className={`nav-link my-0 tabs-link pl-3 flex items-center py-3 ${
                recent ? 'active' : ''
              } cursor-pointer`}
              id="tabs-recent-view"
              role="tab"
              aria-controls="tabs-recentView"
              aria-selected="false">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg">
                <mask
                  id="mask0_719_79558"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="20"
                  height="20">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0002 0.833344C4.91683 0.833344 0.833496 4.91668 0.833496 10C0.833496 15.0833 4.91683 19.1667 10.0002 19.1667C15.0835 19.1667 19.1668 15.0833 19.1668 10C19.1668 4.91668 15.0835 0.833344 10.0002 0.833344ZM10.0002 17.5C5.8335 17.5 2.50016 14.1667 2.50016 10C2.50016 5.83334 5.8335 2.50001 10.0002 2.50001C14.1668 2.50001 17.5002 5.83334 17.5002 10C17.5002 14.1667 14.1668 17.5 10.0002 17.5ZM13.0835 13.0833C13.4168 12.75 13.4168 12.25 13.0835 11.9167L10.8335 9.66668V5.00001C10.8335 4.50001 10.5002 4.16668 10.0002 4.16668C9.50016 4.16668 9.16683 4.50001 9.16683 5.00001V10C9.16683 10.25 9.25016 10.4167 9.41683 10.5833L11.9168 13.0833C12.0835 13.25 12.2502 13.3333 12.5002 13.3333C12.7502 13.3333 12.9168 13.25 13.0835 13.0833Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_719_79558)">
                  <rect width="20" height="20" fill="currentcolor" />
                </g>
              </svg>
              Recent
            </a>
          </li>
          <li className="nav-item flex-grow" role="presentation">
            <a
              href="#tabs-deleteView"
              className="nav-link my-0 tabs-link pl-3 flex items-center py-3"
              id="tabs-delete-view"
              data-bs-toggle="pill"
              data-bs-target="#tabs-deleteView"
              role="tab"
              aria-controls="tabs-deleteView"
              aria-selected="true">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.4998 4.16668H14.1665V3.33334C14.1665 1.91668 13.0832 0.833344 11.6665 0.833344H8.33317C6.9165 0.833344 5.83317 1.91668 5.83317 3.33334V4.16668H2.49984C1.99984 4.16668 1.6665 4.50001 1.6665 5.00001C1.6665 5.50001 1.99984 5.83334 2.49984 5.83334H3.33317V16.6667C3.33317 18.0833 4.4165 19.1667 5.83317 19.1667H14.1665C15.5832 19.1667 16.6665 18.0833 16.6665 16.6667V5.83334H17.4998C17.9998 5.83334 18.3332 5.50001 18.3332 5.00001C18.3332 4.50001 17.9998 4.16668 17.4998 4.16668ZM7.49984 3.33334C7.49984 2.83334 7.83317 2.50001 8.33317 2.50001H11.6665C12.1665 2.50001 12.4998 2.83334 12.4998 3.33334V4.16668H7.49984V3.33334ZM14.1665 17.5C14.6665 17.5 14.9998 17.1667 14.9998 16.6667V5.83334H4.99984V16.6667C4.99984 17.1667 5.33317 17.5 5.83317 17.5H14.1665Z"
                />
                <mask
                  id="mask0_719_79562"
                  maskUnits="userSpaceOnUse"
                  x="1"
                  y="0"
                  width="18"
                  height="20">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4998 4.16668H14.1665V3.33334C14.1665 1.91668 13.0832 0.833344 11.6665 0.833344H8.33317C6.9165 0.833344 5.83317 1.91668 5.83317 3.33334V4.16668H2.49984C1.99984 4.16668 1.6665 4.50001 1.6665 5.00001C1.6665 5.50001 1.99984 5.83334 2.49984 5.83334H3.33317V16.6667C3.33317 18.0833 4.4165 19.1667 5.83317 19.1667H14.1665C15.5832 19.1667 16.6665 18.0833 16.6665 16.6667V5.83334H17.4998C17.9998 5.83334 18.3332 5.50001 18.3332 5.00001C18.3332 4.50001 17.9998 4.16668 17.4998 4.16668ZM7.49984 3.33334C7.49984 2.83334 7.83317 2.50001 8.33317 2.50001H11.6665C12.1665 2.50001 12.4998 2.83334 12.4998 3.33334V4.16668H7.49984V3.33334ZM14.1665 17.5C14.6665 17.5 14.9998 17.1667 14.9998 16.6667V5.83334H4.99984V16.6667C4.99984 17.1667 5.33317 17.5 5.83317 17.5H14.1665Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_719_79562)">
                  <rect width="20" height="20" fill="currentcolor" />
                </g>
              </svg>
              Deleted Files
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VideoSidebar;
