import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { RootState } from 'src/store/reducers/state';
import Modal from '../components/VoucherModal';

const Vouchers: React.FC = () => {
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState('');
  const [currentVoucherId, setcurrentVoucherId] = useState('');

  const monetizationAction = useMonetizationActions(dispatch);

  const {
    voucherList,
    loadingVoucher,
    isVoucherAdded,
    isVoucherUpdated,
    isVoucherDeleted,
    isVoucherStatusUpdated
  } = useSelector((state: RootState) => {
    return {
      voucherList: state.monetization.voucherList,
      loadingVoucher: state.monetization.loadingVoucher,
      isVoucherAdded: state.monetization.isVoucherAdded,
      isVoucherUpdated: state.monetization.isVoucherUpdated,
      isVoucherDeleted: state.monetization.isVoucherDeleted,
      isVoucherStatusUpdated: state.monetization.isVoucherStatusUpdated
    };
  });

  useEffect(() => {
    monetizationAction.getVouchers();
  }, [isVoucherAdded, isVoucherUpdated, isVoucherDeleted, isVoucherStatusUpdated]);

  const handleVoucherUsage = (voucherId: string, status: boolean) => {
    const editVoucherUsage: any = {
      voucherId: voucherId,
      voucherStatus: status
    };
    monetizationAction.editVoucherStatusRequest(editVoucherUsage);
  };
  const handleDeleteVoucher = (voucherId: string) => {
    monetizationAction.deleteVoucherRequest(voucherId);
  };
  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Vouchers</h2>
                <div className="md:flex items-center text-center">
                  <button
                    onClick={() => {
                      setModalFlag('add');
                    }}
                    type="button"
                    className="btn-primary w-36 mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#editVoucherModel">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className={`overflow-x-auto-sm`}>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Code</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Amount</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left"></div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {loadingVoucher ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {voucherList.length ? (
                          <tbody className="text-sm divide-y divide-gray-100" form-switch>
                            {voucherList.map((voucherRow, index) => {
                              return (
                                <tr key={index}>
                                  <td className="p-2">
                                    <div className="flex items-center">
                                      <div className="font-medium ">{`${voucherRow.name}`}</div>
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{voucherRow.code}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{voucherRow.amount}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="form-switch ">
                                      <input
                                        className="form-check-input switch-btn appearance-none w-9 
                                                                                    -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                                                                    focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                        type="checkbox"
                                        role="switch"
                                        id="isActive"
                                        checked={voucherRow.isActive}
                                        onChange={(e) => {
                                          handleVoucherUsage(voucherRow.id, e.target.checked);
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      <div className="dropdown relative">
                                        <button
                                          className="dropdown-toggle"
                                          type="button"
                                          id="action-dropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false">
                                          <img
                                            src="../../assets/img/dots-vertical.svg"
                                            className="w-5 h-5"
                                          />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-ul"
                                          aria-labelledby="action-dropdown">
                                          <li>
                                            <button
                                              type="button"
                                              className="action-menu flex items-center"
                                              data-bs-toggle="modal"
                                              data-bs-target="#editVoucherModel"
                                              onClick={() => {
                                                setModalFlag('edit');
                                                setcurrentVoucherId(voucherRow.id);
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                              </svg>
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li>
                                            <span className="action-menu flex items-center text-red-500 cursor-pointer">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                              </svg>
                                              <span
                                                onClick={() => handleDeleteVoucher(voucherRow.id)}>
                                                Delete
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal modalFlag={modalFlag} currentVoucherId={currentVoucherId} />
      </div>
    </>
  );
};

export default Vouchers;
