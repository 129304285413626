export const getAnalyticsVideoRequestActionType = 'GET_ANALYTICS_VIDEO_REQUEST'
export const getAnalyticsVideoSuccessActionType = 'GET_ANALYTICS_VIDEO_REQUEST_SUCCESS'
export const getAnalyticsVideoFailureActionType = 'GET_ANALYTICS_VIDEO_REQUEST_FAILURE'
export const getAnalyticsVideoChartRequestActionType = 'GET_ANALYTICS_VIDEO_CHART_REQUEST'
export const getAnalyticsVideoChartRequestSuccessActionType = 'GET_ANALYTICS_VIDEO_CHART_REQUEST_SUCCESS'
export const getAnalyticsVideoChartRequestFailureActionType = 'GET_ANALYTICS_VIDEO_CHART_REQUEST_FAILURE'
export const getAnalyticsMembersRequestActionType = 'GET_ANALYTICS_MEMBERS_REQUEST'
export const getAnalyticsMembersRequestSuccessActionType = 'GET_ANALYTICS_MEMBERS_REQUEST_SUCCESS'
export const getAnalyticsMembersRequestFailureActionType = 'GET_ANALYTICS_MEMBERS_REQUEST_FAILURE'
export const getSalesOrderRequestActionType = 'GET_SALES_ORDER_REQUEST'
export const getSalesOrderRequestSuccessActionType = 'GET_SALES_ORDER_REQUEST_SUCCESS'
export const getSalesOrderRequestFailureActionType = 'GET_SALES_ORDER_REQUEST_FAILURE'
export const downloadSalesOrderRequestActionType = 'DOWNLOAD_SALES_ORDER_REQUEST'
export const downloadSalesOrderRequestSuccessActionType = 'DOWNLOAD_SALES_ORDER_REQUEST_SUCCESS'
export const downloadSalesOrderRequestFailureActionType = 'DOWNLOAD_SALES_ORDER_REQUEST_FAILURE'
export const getCourseAnalyticsRequestActionType = 'GET_COURSE_ANALYTICS_REQUEST'
export const getCourseAnalyticsRequestSuccessActionType = 'GET_COURSE_ANALYTICS_REQUEST_SUCCESS'
export const getCourseAnalyticsRequestFailureActionType = 'GET_COURSE_ANALYTICS_REQUEST_FAILURE'
export const getAnalyticsMemberChartRequestActionType = 'GET_ANALYTICS_MEMBER_CHART_REQUEST'
export const getAnalyticsMemberChartRequestSuccessActionType = 'GET_ANALYTICS_MEMBER_CHART_REQUEST_SUCCESS'
export const getAnalyticsMemberChartRequestFailureActionType = 'GET_ANALYTICS_MEMBER_CHART_REQUEST_FAILURE'
export const getCourseTotalRequestActionType = 'GET_COURSE_TOTAL_REQUEST'
export const getCourseTotalRequestSuccessActionType = 'GET_COURSE_TOTAL_REQUEST_SUCCESS'
export const getCourseTotalRequestFailureActionType = 'GET_COURSE_TOTAL_REQUEST_FAILURE'
export const getCourseResultsRequestActionType = 'GET_COURSE_RESULTS_REQUEST'
export const getCourseResultsRequestSuccessActionType = 'GET_COURSE_RESULTS_REQUEST_SUCCESS'
export const getCourseResultsRequestFailureActionType = 'GET_COURSE_RESULTS_REQUEST_FAILURE'
export const getViewActivityRequestActionType = 'GET_VIEW_ACTIVITY_REQUEST'
export const getViewActivityRequestSuccessActionType = 'GET_VIEW_ACTIVITY_REQUEST_SUCCESS'
export const getViewActivityRequestFailureActionType = 'GET_VIEW_ACTIVITY_REQUEST_FAILURE'
export const getViewSubmissionRequestActionType = 'GET_VIEW_SUBMISSION_REQUEST'
export const getViewSubmissionRequestSuccessActionType = 'GET_VIEW_SUBMISSION_REQUEST_SUCCESS'
export const getViewSubmissionRequestFailureActionType = 'GET_VIEW_SUBMISSION_REQUEST_FAILURE'
export const getQuizTotalRequestActionType = 'GET_QUIZ_TOTAL_REQUEST'
export const getQuizTotalRequestSuccessActionType = 'GET_QUIZ_TOTAL_REQUEST_SUCCESS'
export const getQuizTotalRequestFailureActionType = 'GET_QUIZ_TOTAL_REQUEST_FAILURE'
export const getQuizAnalyticsRequestActionType = 'GET_QUIZ_ANALYTICS_REQUEST'
export const getQuizAnalyticsRequestSuccessActionType = 'GET_QUIZ_ANALYTICS_REQUEST_SUCCESS'
export const getQuizAnalyticsRequestFailureActionType = 'GET_QUIZ_ANALYTICS_REQUEST_FAILURE'
export const getQuizQuestionsRequestActionType = 'GET_QUIZ_QUESTIONS_REQUEST'
export const getQuizQuestionsRequestSuccessActionType = 'GET_QUIZ_QUESTIONS_REQUEST_SUCCESS'
export const getQuizQuestionsRequestFailureActionType = 'GET_QUIZ_QUESTIONS_REQUEST_FAILURE'
export const getSingleCourseResultRequestActionType = 'GET_SINGLE_COURSE_RESULT_REQUEST'
export const getSingleCourseResultRequestSuccessActionType = 'GET_SINGLE_COURSE_RESULT_REQUEST_SUCCESS'
export const getSingleCourseResultRequestFailureActionType = 'GET_SINGLE_COURSE_RESULT_REQUEST_FAILURE'
export const getRecentCourseActivityRequestActionType = 'GET_RECENT_COURSE_ACTIVITY_REQUEST'
export const getRecentCourseActivityRequestSuccessActionType = 'GET_RECENT_COURSE_ACTIVITY_REQUEST_SUCCESS'
export const getRecentCourseActivityRequestFailureActionType = 'GET_RECENT_COURSE_ACTIVITY_REQUEST_FAILURE'
export const getRecentGradeRequestActionType = 'GET_RECENT_GRADE_REQUEST'
export const getRecentGradeRequestSuccessActionType = 'GET_RECENT_GRADE_REQUEST_SUCCESS'
export const getRecentGradeRequestFailureActionType = 'GET_RECENT_GRADE_REQUEST_FAILURE'
export const getSingleQuizQuestionRequestActionType = 'GET_SINGLE_QUIZ_QUESTION_REQUEST'
export const getSingleQuizQuestionRequestSuccessActionType = 'GET_SINGLE_QUIZ_QUESTION_REQUEST_SUCCESS'
export const getSingleQuizQuestionRequestFailureActionType = 'GET_SINGLE_QUIZ_QUESTION_REQUEST_FAILURE'
export const getQuestionByQuizIdRequestActionType = 'GET_QUESTION_BY_QUIZ_ID_REQUEST'
export const getQuestionByQuizIdRequestSuccessActionType = 'GET_QUESTION_BY_QUIZ_ID_REQUEST_SUCCESS'
export const getQuestionByQuizIdRequestFailureActionType = 'GET_QUESTION_BY_QUIZ_ID_REQUEST_FAILURE'
export const getPollAnalyticsRequestActionType = 'GET_POLL_ANALYTICS_REQUEST'
export const getPollAnalyticsRequestSuccessActionType = 'GET_POLL_ANALYTICS_REQUEST_SUCCESS'
export const getPollAnalyticsRequestFailureActionType = 'GET_POLL_ANALYTICS_REQUEST_FAILURE'
export const getPollQuestionRequestActionType = 'GET_POLL_QUESTION_REQUEST'
export const getPollQuestionRequestSuccessActionType = 'GET_POLL_QUESTION_REQUEST_SUCCESS'
export const getPollQuestionRequestFailureActionType = 'GET_POLL_QUESTION_REQUEST_FAILURE'
export const getSinglePollRequestActionType = 'GET_SINGLE_POLL_REQUEST'
export const getSinglePollRequestSuccessActionType = 'GET_SINGLE_POLL_REQUEST_SUCCESS'
export const getSinglePollRequestFailureActionType = 'GET_SINGLE_POLL_REQUEST_FAILURE'
export const getSinglePollQuestionRequestActionType = 'GET_SINGLE_POLL_QUESTION_REQUEST'
export const getSinglePollQuestionRequestSuccessActionType = 'GET_SINGLE_POLL_QUESTION_REQUEST_SUCCESS'
export const getSinglePollQuestionRequestFailureActionType = 'GET_SINGLE_POLL_QUESTION_REQUEST_FAILURE'
export const getAnswerQuestionRequestActionType = 'GET_ANSWER_QUESTION_ACTIVITY_REQUEST'
export const getAnswerQuestionRequestSuccessActionType = 'GET_ANSWER_QUESTION_ACTIVITY_REQUEST_SUCCESS'
export const getAnswerQuestionRequestFailureActionType = 'GET_ANSWER_QUESTION_ACTIVITY_REQUEST_FAILURE'
export const getSalesOrderTotalRequestActionType = 'GET_SALES_ORDER_TOTAL_REQUEST'
export const getSalesOrderTotalRequestSuccessActionType = 'GET_SALES_ORDER_TOTAL_REQUEST_SUCCESS'
export const getSalesOrderTotalRequestFailureActionType = 'GET_SALES_ORDER_TOTAL_REQUEST_FAILURE'
export const getSingleQuizTotalRequestActionType = 'GET_SINGLE_QUIZ_TOTAL_REQUEST'
export const getSingleQuizTotalRequestSuccessActionType = 'GET_SINGLE_QUIZ_TOTAL_REQUEST_SUCCESS'
export const getSingleQuizTotalRequestFailureActionType = 'GET_SINGLE_QUIZ_TOTAL_REQUEST_FAILURE'
export const getAnalyticsMemberSearchRequestActionType = 'GET_ANALYTICS_MEMBER_SEARCH_REQUEST'
export const getAnalyticsMemberSearchRequestSuccessActionType = 'GET_ANALYTICS_MEMBER_SEARCH_REQUEST_SUCCESS'
export const getAnalyticsMemberSearchRequestFailureActionType = 'GET_ANALYTICS_MEMBER_SEARCH_REQUEST_FAILURE'
export const getQuizQuestionDataRequestActionType = 'GET_QUIZ_QUESTION_DATA_REQUEST'
export const getQuizQuestionDataRequestSuccessActionType = 'GET_QUIZ_QUESTION_DATA_REQUEST_SUCCESS'
export const getQuizQuestionDataRequestFailureActionType = 'GET_QUIZ_QUESTION_DATA_REQUEST_FAILURE'
export const getPollWidgetRequestActionType = 'GET_POLL_WIDGET_REQUEST'
export const getPollWidgetRequestSuccessActionType = 'GET_POLL_WIDGET_REQUEST_SUCCESS'
export const getPollWidgetRequestFailureActionType = 'GET_POLL_WIDGET_REQUEST_FAILURE'
export const getSingleVideoAnalyticsRequestActionType = 'GET_SINGLE_VIDEO_ANALYTICS_REQUEST'
export const getSingleVideoAnalyticsRequestSuccessActionType = 'GET_SINGLE_VIDEO_REQUEST_SUCCESS'
export const getSingleVideoAnalyticsRequestFailureActionType = 'GET_SINGLE_VIDEO_REQUEST_FAILURE'
export const getAnalyticsVideoDownloadRequestActionType = 'GET_ANALYTICS_VIDEO_DOWNLOAD_REQUEST'
export const getAnalyticsVideoDownloadSuccessActionType = 'GET_ANALYTICS_VIDEO_DOWNLOAD_REQUEST_SUCCESS'
export const getAnalyticsVideoDownloadFailureActionType = 'GET_ANALYTICS_VIDEO_DOWNLOAD_REQUEST_FAILURE'