import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const StackedBarChart = ({ chartData, options, height = 30 }: any): JSX.Element => {
  return (
    <Bar
      width={70}
      height={height}
      data={chartData}
      options={options}
      plugins={[ChartDataLabels]}
    />
  );
};

export default StackedBarChart;
