import { useMemo } from 'react';
import * as Constants from './constants';
import { bindActionCreators, Dispatch } from "redux";
import { Action, ActionCreator } from 'src/models/actions';
import { LoginFormModel, SSOLoginFormModel } from 'src/models/loginModel';
import { UsersModel } from 'src/models/usersModel';

export namespace LoginActions {

    // Log In
    export type sendLoginRequestAction = Action<typeof Constants.SendLoginRequestActionType, LoginFormModel>
    export type sendLoginRequestSuccessAction = Action<typeof Constants.SendLoginRequestSuccessActionType, any>;
    export type sendLoginRequestFailureAction = Action<typeof Constants.SendLoginRequestFailureActionType, any>;

    export function sendLoginRequest(P: LoginFormModel): sendLoginRequestAction {
        return ActionCreator(Constants.SendLoginRequestActionType, P);
    }
    export function sendLoginRequestSuccess(P: any): sendLoginRequestSuccessAction {
        return ActionCreator(Constants.SendLoginRequestSuccessActionType, P);
    }
    export function sendLoginRequestFailure(P: any): sendLoginRequestFailureAction {
        return ActionCreator(Constants.SendLoginRequestFailureActionType, P);
    }
  // Log In SSO
  export type sendSSOLoginRequestAction = Action<typeof Constants.SendSSOLoginRequestActionType, SSOLoginFormModel>
  export type sendSSOLoginRequestSuccessAction = Action<typeof Constants.SendSSOLoginRequestSuccessActionType, any>;
  export type sendSSOLoginRequestFailureAction = Action<typeof Constants.SendSSOLoginRequestFailureActionType, any>;

  export function sendSSOLoginRequest(P: SSOLoginFormModel): sendSSOLoginRequestAction {
      return ActionCreator(Constants.SendSSOLoginRequestActionType, P);
  }
  export function sendSSOLoginRequestSuccess(P: any): sendSSOLoginRequestSuccessAction {
      return ActionCreator(Constants.SendSSOLoginRequestSuccessActionType, P);
  }
  export function sendSSOLoginRequestFailure(P: any): sendSSOLoginRequestFailureAction {
      return ActionCreator(Constants.SendSSOLoginRequestFailureActionType, P);
  }
    // Domain List
    export type getDomainListRequestAction = Action<typeof Constants.GetDomainListRequestActionType, null>
    export type getDomainListRequestSuccessAction = Action<typeof Constants.GetDomainListRequestSuccessActionType, any>;
    export type getDomainListRequestFailureAction = Action<typeof Constants.GetDomainListRequestFailureActionType, null>;

    export function getDomainListRequest(): getDomainListRequestAction {
        return ActionCreator(Constants.GetDomainListRequestActionType, null);
    }
    export function getDomainListRequestSuccess(P: any): getDomainListRequestSuccessAction {
        return ActionCreator(Constants.GetDomainListRequestSuccessActionType, P);
    }
    export function getDomainListRequestFailure(): getDomainListRequestFailureAction {
        return ActionCreator(Constants.GetDomainListRequestFailureActionType, null);
    }

    // Setting callback link
    export type setCallbackLinkRequestAction = Action<typeof Constants.SetCallbackLinkActionType, null>

    export function setCallbackLinkRequest(): setCallbackLinkRequestAction {
        return ActionCreator(Constants.SetCallbackLinkActionType, null);
    }

    // remove callback link
    export type removeCallbackLinkRequestAction = Action<typeof Constants.RemoveCallbackLinkActionType, null>

    export function removeCallbackLinkRequest(): removeCallbackLinkRequestAction {
        return ActionCreator(Constants.RemoveCallbackLinkActionType, null);
    }

    // Log Out
    export type sendLogoutRequestAction = Action<typeof Constants.SendLogoutRequestActionType, null>

    export function sendLogoutRequest(): sendLogoutRequestAction {
        return ActionCreator(Constants.SendLogoutRequestActionType, null);
    }

    // Reset state
    export type resetLoginStateRequestAction = Action<typeof Constants.ResetLoginStateRequestActionType, null>

    export function resetLoginStateRequest(): resetLoginStateRequestAction {
        return ActionCreator(Constants.ResetLoginStateRequestActionType, null);
    }

    export type forgetRestPasswordRequestAction = Action<typeof Constants.forgetRestPasswordRequestActionType, string>
    export type forgetRestPasswordRequestSuccessAction = Action<typeof Constants.forgetRestPasswordRequestSuccessActionType, any>;
    export type forgetRestPasswordRequestFailureAction = Action<typeof Constants.forgetRestPasswordRequestFailureActionType, null>;

    export function forgetRestPasswordRequest(P: string): forgetRestPasswordRequestAction {
        return ActionCreator(Constants.forgetRestPasswordRequestActionType, P);
    }
    export function forgetRestPasswordRequestSuccess(P: any): forgetRestPasswordRequestSuccessAction {
        return ActionCreator(Constants.forgetRestPasswordRequestSuccessActionType, P);
    }
    export function forgetRestPasswordRequestFailure(): forgetRestPasswordRequestFailureAction {
        return ActionCreator(Constants.forgetRestPasswordRequestFailureActionType, null);
    }

    export type resetPasswordRequestAction = Action<typeof Constants.resetPasswordRequestActionType, UsersModel>
    export type resetPasswordRequestSuccessAction = Action<typeof Constants.resetPasswordRequestSuccessActionType, any>;
    export type resetPasswordRequestFailureAction = Action<typeof Constants.resetPasswordRequestFailureActionType, null>;

    export function resetPasswordRequest(P: UsersModel): resetPasswordRequestAction {
        return ActionCreator(Constants.resetPasswordRequestActionType, P);
    }
    export function resetPasswordRequestSuccess(P: any): resetPasswordRequestSuccessAction {
        return ActionCreator(Constants.resetPasswordRequestSuccessActionType, P);
    }
    export function resetPasswordRequestFailure(): resetPasswordRequestFailureAction {
        return ActionCreator(Constants.resetPasswordRequestFailureActionType, null);
    }

    export type getDefaultdateFormatRequestAction = Action<typeof Constants.getDefaultdateFormatRequestActionType, null>
    export type getDefaultdateFormatRequestSuccessAction = Action<typeof Constants.getDefaultdateFormatRequestSuccessActionType, string>;
    export type getDefaultdateFormatRequestFailureAction = Action<typeof Constants.getDefaultdateFormatRequestFailureActionType, null>;

    export function getDefaultdateFormatRequest(): getDefaultdateFormatRequestAction {
        return ActionCreator(Constants.getDefaultdateFormatRequestActionType, null);
    }
    export function getDefaultdateFormatRequestSuccess(P: string): getDefaultdateFormatRequestSuccessAction {
        return ActionCreator(Constants.getDefaultdateFormatRequestSuccessActionType, P);
    }
    export function getDefaultdateFormatRequestFailure(): getDefaultdateFormatRequestFailureAction {
        return ActionCreator(Constants.getDefaultdateFormatRequestFailureActionType, null);
    }
    export type reset = Action<typeof Constants.reset, null>;

    export function reset(): reset {
        return ActionCreator(Constants.reset, null);
    }
}

export type LoginActions = Omit<typeof LoginActions, 'Type'>;
export const useLoginActions = (dispatch: Dispatch) => {
    const { ...actions } = LoginActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as LoginActions;
};
