import { useNavigate, useSearchParams } from 'react-router-dom';
import MFAScannerModal from './MFAScannerModal';
import { useUserActions } from 'src/store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';

const MultiFactorAuthenticationSetup: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userActions = useUserActions(dispatch);
  const localData = localStorage.getItem('userData');
  const userData = localData && JSON.parse(localData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const loginType = Number(searchParams.get('sa'));
  const returnUrl = searchParams.get('returnUrl');

  useEffect(() => {
    userActions.getUserDetailsRequest(userData.UserId);
  }, []);

  const { userDetails } = useSelector((state: RootState) => {
    return {
      userDetails: state.users.userDetails
    };
  });

  const handleContinueClick = () => {
    if (userDetails?.isMfaSetupEnabled) {
      navigate('/account/CodeVerification?returnUrl=' + returnUrl);
    } else {
      if (returnUrl) {
        const pageUrl = returnUrl == 'null' ? '/' : returnUrl;
        navigate(`${pageUrl}`);
      } else {
        const pageUrl = '/';
        navigate(`${pageUrl}`);
      }
    }
  };

  const handleTurnOn = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <section
          className="bg-gray-100 h-screen"
          style={{
            backgroundImage: `url(../../assets/img/wcc-background-image.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}>
          <img
            className="w-64 absolute top-4 left-4"
            src="../../assets/img/wcc-logo.png"
            alt="logo"
          />
          <div className="container m-auto h-full flex flex-1 flex-col">
            <div className="mx-6 login-wrap mt-auto mb-6 overflow-auto">
              <div className="flex justify-center  h-full ">
                <div className="xl:w-3/5 lg:w-3/5 md:w-8/12 w-12/12 flex flex-col">
                  <div className="m-auto">
                    <div className="block bg-white shadow-lg rounded-xl m-5">
                      <div className="lg:flex-wrap g-0">
                        <div className="px-6 py-8 md:px-16 md:py-16">
                          <h1 className="font-black sm:text-2xl text-xl mb-5">
                            Keep your account secure!
                          </h1>
                          <div className="flex flex-col">
                            <h3 className="font-black mb-4">
                              Confirm your two-step authentication details.
                            </h3>
                            <div className="font-orange mb-4 flex items-start gap-2">
                              <i className="fa-solid fa-triangle-exclamation mt-1"></i>
                              <span>
                                We encourage you to enable multiple factor authentication on your
                                account, to keep your data secure.
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col mb-4">
                            <h3 className="font-black mb-4">Authenticator app</h3>
                            <div className="flex align-top gap-3 sm:flex-row flex-col items-center">
                              <p>
                                Download a free authenticator app such as Google Authenticator,{' '}
                                <a
                                  href="https://www.microsoft.com/en-au/security/mobile-authenticator-app"
                                  className="auth-link">
                                  Microsoft Authenticator
                                </a>
                                , or{' '}
                                <a href="https://authy.com/download/" className="auth-link">
                                  Authy
                                </a>
                                . Then setup your account using the code shown.
                                <br />
                                You will then require your Authenticator app for logging in.
                              </p>
                              {!userDetails?.isMfaSetupEnabled && (
                                <button
                                  className="btn bg-white border px-3 py-2 rounded-lg hover:bg-blue-800 hover:text-white h-10 w-max m-0 whitespace-pre"
                                  onClick={handleTurnOn}>
                                  Turn on
                                </button>
                              )}
                            </div>
                          </div>
                          <button
                            className="btn btn-primary m-0 h-14 font-bold tracking-widest"
                            onClick={handleContinueClick}
                            data-bs-target="#MFAScannerModal">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isModalOpen && <MFAScannerModal onClose={closeModal} userData={userData} />}
        </section>
      </div>
    </>
  );
};
export default MultiFactorAuthenticationSetup;
