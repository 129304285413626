import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useUserActions } from 'src/store/users/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import UploadEmailDomainModal from './UploadEmailDomainModal';
import UploadEmailModal from './UploadEmailModal';
import UploadUserNameModal from './UploadUserNames';
const UploadModal = () => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const { id } = useParams<string>();
  const {
    reset,
    formState: { errors }
  } = useForm();

  const { isEmailDomainUploaded } = useSelector((state: RootState) => {
    return {
      isEmailDomainUploaded: state.users.isEmailDomainUploaded
    };
  });

  useEffect(() => {
    if (isEmailDomainUploaded) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isEmailDomainUploaded]);

  const handleCloseClick = () => {
    reset({
      name: ''
    });
  };
  return (
    <>
      <div>
        <div
          className="modal fade"
          id="Upload"
          aria-labelledby="UploadLabel"
          aria-hidden="true">
          <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
            <div className="modal-content">
              <div className="relative modal-header flex items-center justify-between p-4 mb-6 rounded-t-md">
                <i className="fa fa-cloud modal-icon absolute top-4"></i>
                <h5
                  className="text-xl font-semibold leading-normal text-center w-full"
                  id="UploadLabel">
                  Upload
                </h5>

                <button
                  onClick={() => handleCloseClick()}
                  ref={ref}
                  type="button"
                  className="dismiss-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body relative pb-4 px-10">
                <form>
                  <div className={`form-group mb-3`}>
                    <label className="flex justify-center text-md font-medium">
                      Select the member data you would like to upload:
                    </label>
                    <div className="mt-6 flex items-center gap-3">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#UploadEmail"
                        className="font-bold btn-primary mt-0">
                        Emails
                      </button> <button
                        data-bs-toggle="modal"
                        data-bs-target="#UploadMembers"
                        type="button"
                        className="font-bold btn-primary mt-0">
                        Usernames
                      </button>
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#UploadEmailDomain"
                        className="font-bold btn-primary mt-0">
                        Domains
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UploadEmailDomainModal />
      <UploadEmailModal />
      <UploadUserNameModal />
    </>
  );
};

export default UploadModal;
