import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm, Controller } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { SelectModel } from 'src/models/videosModel';
import Select from 'react-select';
import Country from './country.json';
import { errorMsg } from 'src/components/utilities/utils';
import Editor from 'src/components/Editor';
import { useVideosActions } from 'src/store/videos/actions';
import { customFilterOption } from 'src/components/utilities/utils';
import { ImageStringConverter } from 'src/components/utilities/utils';
const Ecommerce: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const videoAction = useVideosActions(dispatch);
  const [finalCountry, setFinalCountry] = useState<SelectModel[]>();
  const [editorState, setEditorState] = useState<string>('');
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control
  } = useForm();

  const {
    ecommerce,
    message,
    isEcommerceSaved,
    error,
    currency,
    isCurrencyUpdated,
    selectedCurrency,
    ecommerceSetting,
    isEcommerceSettingUpdated,
    removeMediaUrl,
    mediaUrl
  } = useSelector((state: RootState) => {
    return {
      ecommerce: state.setting.ecommerce,
      message: state.setting.message,
      isEcommerceSaved: state.setting.isEcommerceSaved,
      error: state.setting.error,
      currency: state.setting.currency,
      isCurrencyUpdated: state.setting.isCurrencyUpdated,
      selectedCurrency: state.setting.selectedCurrency,
      ecommerceSetting: state.setting.ecommerceSetting,
      isEcommerceSettingUpdated: state.setting.isEcommerceSettingUpdated,
      removeMediaUrl: state.videos.removeMediaUrl,
      mediaUrl: state.videos.mediaUrl
    };
  });

  useEffect(() => {
    settingAction.getEcommerceRequest();
    settingAction.getCurrencyRequest();
    settingAction.getSelectedCurrencyRequest();
    settingAction.getEcommerceSettingRequest();
  }, []);

  useEffect(() => {
    if (ecommerce && ecommerceSetting) {
      const country = {
        value: ecommerce.country,
        label: ecommerce.country
      };
      setEditorState(ecommerceSetting.cartHtml ? ecommerceSetting.cartHtml : '');
      reset({ ...ecommerce, country, ...ecommerceSetting });
    }
  }, [ecommerce, ecommerceSetting]);

  useEffect(() => {
    if (isEcommerceSaved || isCurrencyUpdated || isEcommerceSettingUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isEcommerceSaved, isCurrencyUpdated, isEcommerceSettingUpdated]);

  useEffect(() => {
    if (Country && Country.length) {
      const options = Country.map((item) => ({
        value: item.name,
        label: item.name
      }));
      setFinalCountry(options);
    }
  }, [Country]);

  const onSubmit = (data: any) => {
    const payloadData = {
      id: data.id,
      updateData: [
        { op: 'replace', path: 'publicBusinessName', value: data.publicBusinessName },
        { op: 'replace', path: 'businessNumber', value: data.businessNumber },
        { op: 'replace', path: 'supportEmail', value: data.supportEmail },
        { op: 'replace', path: 'supportPhone', value: data.supportPhone },
        { op: 'replace', path: 'addressLine1', value: data.addressLine1 },
        { op: 'replace', path: 'addressLine2', value: data.addressLine2 },
        { op: 'replace', path: 'city', value: data.city },
        { op: 'replace', path: 'state', value: data.state },
        { op: 'replace', path: 'postCode', value: data.postCode },
        { op: 'replace', path: 'country', value: data.country.value },
        { op: 'replace', path: 'priceSuffix', value: data.priceSuffix },
        { op: 'replace', path: 'taxRate', value: data.taxRate },
        // { op: 'replace', path: 'currency', value: data.currency },
        { op: 'replace', path: 'sendEmailReceipt', value: data.sendEmailReceipt },
        {
          op: 'replace',
          path: 'allowPuchaseOrderOnInvoice',
          value: data.allowPuchaseOrderOnInvoice
        }
      ]
    };
    settingAction.saveEcommerceRequest(payloadData);
    const EcommerceSettingData = [
      { op: 'replace', path: 'payPerView', value: data.payPerView },
      { op: 'replace', path: 'viewCartWithAuthetication', value: data.viewCartWithAuthetication },
      { op: 'replace', path: 'cartHtml', value: ImageStringConverter(editorState) }
    ];
    settingAction.updateEcommerceSettingRequest(EcommerceSettingData);
  };

  // useEffect(() => {
  //   if (error) {
  //     errorMsg('Error');
  //     settingAction.resetSettingStateRequest();
  //   }
  // }, [error]);

  const handleCountryChange = (value: string) => {
    const payloadData = [{ op: 'replace', path: 'currency', value: value }];
    settingAction.updateCurrencyRequest(payloadData);
  };

  useEffect(() => {
    if (ecommerceSetting && Object.keys(ecommerceSetting).length) {
      videoAction.getMediaUrlRequest(ecommerceSetting.cartHtml);
    }
  }, [ecommerceSetting]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl ? mediaUrl : '');
    }
  }, [mediaUrl]);
  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">eCommerce</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/ecommerce">Settings</Link>
          </li>
          <li>
            <strong>eCommerce</strong>
          </li>
        </ol>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <div>
          <h4 className="text-lg mb-4">eCommerce Settings</h4>
          <div className="md:flex items-center mb-8">
            <div className="md:w-4/12">
              <label className="font-semibold">Pay Per View</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      {...register('payPerView')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="md:w-4/12">
              <label className="font-semibold">View Cart With Authentication</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      {...register('viewCartWithAuthetication')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex mb-8 mt-4">
            <div className="md:w-4/12">
              <label className="font-semibold">Cart HTML Message</label>
            </div>
            <div className="md:w-8/12">
              {/* <FormInputField
                label={''}
                type={'text'}
                fieldName={'test'}
                register={register}
                errors={''}
                isRequired={false}
              /> */}
              {/* <input type="text" className="input-style" {...register('cartHtml')} /> */}
              <Editor editorState={editorState} setEditorState={setEditorState} />
            </div>
          </div>
        </div>
        <h4 className="text-lg">Business Information</h4>
        <p>
          The following business information will appear on your receipt pages, member user profile
          video activity statements and other locations that require your account information to be
          displayed.
        </p>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Public Business Name</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'publicBusinessName'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Business Number</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="text"
              className="input-style"
              id="businessNumber"
              {...register('businessNumber', {
                pattern: /^\d*\.?\d*$/
              })}
            />
            {errors.businessNumber?.type === 'pattern' && (
              <span className="text-red-500 text-xs flex item-center error">
                Only Numbers are allowed
              </span>
            )}
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Email</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'email'}
              fieldName={'supportEmail'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Phone</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="text"
              className="input-style"
              id="supportPhone"
              {...register('supportPhone', {
                pattern: /^\d*\.?\d*$/
              })}
            />
            {errors.supportPhone?.type === 'pattern' && (
              <span className="text-red-500 text-xs flex item-center error">
                Only Numbers are allowed
              </span>
            )}
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Address Line 1</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'addressLine1'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Address Line 2</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'addressLine2'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">City</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'city'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">State</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'state'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Postcode</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'postCode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Country</label>
          </div>
          <div className="md:w-8/12">
            {finalCountry && Object.keys(finalCountry).length && (
              <Controller
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    filterOption={customFilterOption}
                    options={finalCountry}
                    value={value}
                    id="country"
                    onChange={(val) => onChange(val)}
                  />
                )}
                name="country"
                control={control}
              />
            )}
          </div>
        </div>

        <h4 className="text-lg">eCommerce</h4>
        <div className="md:flex items-center mb-8 mt-4">
          <div className="md:w-4/12">
            <label className="font-semibold">Price Suffix</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'priceSuffix'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Tax Rate (%)</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'taxRate'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Currency</label>
          </div>
          <div className="md:w-8/12">
            <select className="input-style" onChange={(e) => handleCountryChange(e.target.value)}>
              <option value="">Select</option>
              {currency &&
                currency.map((item, index) => {
                  return (
                    <option
                      key={index}
                      selected={item.value === selectedCurrency ? true : false}
                      value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label className="font-semibold">Send Email Receipt</label>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex-items mb-2">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    id="sendEmailReceipt"
                    {...register('sendEmailReceipt')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label className="font-semibold">Allow Puchase Order on Invoice</label>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex-items mb-2">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    id="allowPuchaseOrderOnInvoice"
                    {...register('allowPuchaseOrderOnInvoice')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <button type="button" className="mx-0 px-6 btn-primary discard-btn w-28 mt-0">
            Cancel
          </button>
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Ecommerce;
