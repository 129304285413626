import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'src/components/common/pagination';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { SingleVideoModel } from 'src/models/videosModel';
interface IProps {
  singleVideo?: SingleVideoModel;
}

const SelectNewThumbModal: React.FC<IProps> = ({ singleVideo }) => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [selectThumbnailFromSlide, setSelectThumbnailFromSlide] = useState('');
  const [slideImages, setSlideImages] = useState<Array<string>>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { isThumbnailUpdated } = useSelector((state: RootState) => {
    return {
      isThumbnailUpdated: state.videos.isThumbnailUpdated
    };
  });

  useEffect(() => {
    getData();
  }, [singleVideo]);

  const getData = (pageNumber = 1) => {
    const thumbnailArray = [];
    if (singleVideo) {
      for (let i = 0; i < singleVideo.thumbnail?.thumbnailCount; i++) {
        const sequenceLength = singleVideo.thumbnail?.sequenceLength;
        const name = `${singleVideo.thumbnail?.prefixUrl}.${
          new Array(sequenceLength).join('0').slice((sequenceLength - i.toString().length) * -1) + i
        }.${singleVideo.thumbnail?.fileType}`;

        thumbnailArray.push(name);
      }
    }
    setSlideImages(thumbnailArray);
    Paginator(thumbnailArray, pageNumber, 10);
  };

  useEffect(() => {
    if (isThumbnailUpdated) {
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isThumbnailUpdated]);
  const handlePageChange = (pageNumber: number) => {
    getData(pageNumber);
  };

  const Paginator = (items: any, pages: number, per_pages: number) => {
    const page = pages || 1;
    const per_page = per_pages || 10;
    const offset = (page - 1) * per_page;
    const paginatedItems = items.slice(offset).slice(0, per_page);
    setSlideImages(paginatedItems);
    setPageNumber(page);
  };

  const handleThumbnailClick = (thumbnailUrl: string) => {
    setSelectThumbnailFromSlide(thumbnailUrl);
  };

  const handleSaveClick = () => {
    if (singleVideo) {
      const updateData = { videoId: singleVideo?.id, thumbnail: selectThumbnailFromSlide };
      videoActions.updateVideoThumbnailRequest(updateData);
      setSelectThumbnailFromSlide('');
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="selectThumbnailModal"
        aria-labelledby="selectThumbnailLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="selectThumbnailLabel"></h5>
              <button
                ref={btnRef}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              {slideImages && slideImages.length ? (
                <div>
                  <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mb-5">
                    {slideImages &&
                      slideImages.length &&
                      slideImages.map((item, index) => {
                        return (
                          <div
                            onClick={() => handleThumbnailClick(item)}
                            className={`thumbnail p-1 rounded-sm border ${
                              selectThumbnailFromSlide === item
                                ? 'border-solid border-5 border-indigo-600'
                                : ''
                            } `}
                            key={index}>
                            <img
                              src={item}
                              className="w-full h-full object-contain"
                              loading="lazy"
                            />
                          </div>
                        );
                      })}
                  </div>
                  {singleVideo && singleVideo.thumbnail && (
                    <Pagination
                      currentPage={pageNumber}
                      totalCount={singleVideo && singleVideo.thumbnail?.thumbnailCount}
                      pageSize={10}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                    <button
                      disabled={selectThumbnailFromSlide === '' ? true : false}
                      onClick={handleSaveClick}
                      type="button"
                      className={`px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 ${
                        selectThumbnailFromSlide === '' ? 'opacity-25' : ''
                      }`}>
                      Save Changes
                    </button>
                    <button
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-center flex-col items-center">
                    <h1 className="text-lg font-bold">There are no thumbnail images available.</h1>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#uploadVideoModal"
                      type="button"
                      className="mx-0 px-6 btn-primary w-28 mt-2 mr-2">
                      Upload
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectNewThumbModal;
