import { all, put, takeEvery } from 'redux-saga/effects';
import { ChannelActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';
import { rename } from 'src/components/utilities/utils';

function* asyncSaveChannel(data: ChannelActions.saveChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_CHANNEL.url, data.payload);
        if (response.status === 200) {
            yield put(ChannelActions.saveChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.saveChannelRequestFailure());
    }
}
function* asyncGetSingleChannel(data: ChannelActions.saveChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_CHANNEL.url}/${data.payload}`);
        if (response.status === 200) {
            const finalData = { ...response.data.result, authorisationRequired: response.data.result.authenticationRequired }
            yield put(ChannelActions.getSingleChannelRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getSingleChannelRequestFailure());
    }
}
function* asyncUpdateChannel(data: ChannelActions.updateChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CHANNEL.url}/${data?.payload.channelId}`, data.payload.updateData);
        if (response.status === 200) {
            yield put(ChannelActions.updateChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.updateChannelRequestFailure());
    }
}
function* asyncDeleteChannel(data: ChannelActions.deleteChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CHANNEL.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(ChannelActions.deleteChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.deleteChannelRequestFailure());
    }
}
function* asyncGetChannelLayout(data: ChannelActions.getChannelLayoutRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHANNEL_LAYOUT.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(ChannelActions.getChannelLayoutRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getChannelLayoutRequestFailure());
    }
}
function* asyncUpdateChannelLayout(data: ChannelActions.updateChannelLayoutRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CHANNEL.url}/${data.payload?.channelId}`, data.payload.updateData);
        if (response.status === 200) {
            yield put(ChannelActions.updateChannelLayoutRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.updateChannelLayoutRequestFailure());
    }
}
function* asyncGetChannels(data: ChannelActions.getChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHANNELS.url);
        if (response.status === 200) {
            const finalData = response.data.result.map(rename)
            yield put(ChannelActions.getChannelRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getChannelRequestFailure());
    }
}
function* asyncSaveChannelStyles(data: ChannelActions.saveChannelStylesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_CHANNEL.url, data.payload);
        if (response.status === 200) {
            yield put(ChannelActions.saveChannelStylesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.saveChannelStylesRequestFailure());
    }
}
function* asyncGetChannelStyles() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHANNELS.url);
        if (response.status === 200) {
            yield put(ChannelActions.getChannelStylesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getChannelStylesRequestFailure());
    }
}
function* asyncSaveChannelHightlightReel(data: ChannelActions.saveChannelHightlightReelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_CHANNEL.url, data.payload);
        if (response.status === 200) {
            yield put(ChannelActions.saveChannelHightlightReelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.saveChannelHightlightReelRequestFailure());
    }
}
function* asyncGetChannelHightlightReels() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHANNELS.url);
        if (response.status === 200) {
            yield put(ChannelActions.getChannelHightlightReelRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getChannelHightlightReelRequestFailure());
    }
}
function* asyncGetChannelList() {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHANNELS_LIST.url}?PageSize=9999`);
        if (response.status === 200) {
            yield put(ChannelActions.getChannelListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getChannelListRequestFailure());
    }
}
function* asyncSortedChannel(data: ChannelActions.sortedChannelRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.SORT_CHANNEL.url}/${data.payload?.channelId}`, data.payload?.payloadData);
        if (response.status === 200) {
            yield put(ChannelActions.sortedChannelRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.sortedChannelRequestFailure());
    }
}
function* asyncAddHighlightReelVideo(data: ChannelActions.addHighlightReelVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('');
        if (response.status === 200) {
            yield put(ChannelActions.addHighlightReelVideoRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.addHighlightReelVideoRequestFailure());
    }
}

function* asyncdeleteHighlightReelVideo(data: ChannelActions.deleteHighlightReelVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData('');
        if (response.status === 200) {
            yield put(ChannelActions.deleteHighlightReelVideoRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.deleteHighlightReelVideoRequestFailure());
    }
}

function* asyncgetHighlightReelVideo(data: ChannelActions.getHighlightReelVideoRequestAction) {
    try {
        const hardcodedeData = [
            {
                id: '1',
                name: 'Quiz 1 - Trivia'
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(ChannelActions.getHighlightReelVideoRequestSuccess(response.data.result));
        // }
        yield put(ChannelActions.getHighlightReelVideoRequestSuccess(hardcodedeData));
    } catch (error) {
        console.log(error);
        yield put(ChannelActions.getHighlightReelVideoRequestFailure());
    }
}
export function* channelSaga() {
    yield all([
        takeEvery(Constants.saveChannelRequestActionType, asyncSaveChannel),
        takeEvery(Constants.getSingleChannelRequestActionType, asyncGetSingleChannel),
        takeEvery(Constants.updateChannelRequestActionType, asyncUpdateChannel),
        takeEvery(Constants.deleteChannelRequestActionType, asyncDeleteChannel),
        takeEvery(Constants.getChannelLayoutRequestActionType, asyncGetChannelLayout),
        takeEvery(Constants.updateChannelLayoutRequestActionType, asyncUpdateChannelLayout),
        takeEvery(Constants.getChannelRequestActionType, asyncGetChannels),
        takeEvery(Constants.saveChannelStylesRequestActionType, asyncSaveChannelStyles),
        takeEvery(Constants.getChannelStylesRequestActionType, asyncGetChannelStyles),
        takeEvery(Constants.saveChannelHightlightReelRequestActionType, asyncSaveChannelHightlightReel),
        takeEvery(Constants.getChannelHightlightReelRequestActionType, asyncGetChannelHightlightReels),
        takeEvery(Constants.getChannelListRequestActionType, asyncGetChannelList),
        takeEvery(Constants.sortedChannelRequestActionType, asyncSortedChannel),
        takeEvery(Constants.addHighlightReelVideoRequestActionType, asyncAddHighlightReelVideo),
        takeEvery(Constants.deleteHighlightReelVideoRequestActionType, asyncdeleteHighlightReelVideo),
        takeEvery(Constants.getHighlightReelVideoRequestActionType, asyncgetHighlightReelVideo),
    ]);
}
