import React, { useEffect, useState } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import LineChart from 'src/components/charts/linechart/LineChart';
const LiveInfoAnalytics = () => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const videoAction = useVideosActions(dispatch);
  const {
    videoAnalyticsData,
    isVideoAnalyticsDataLoading,
    analyticsLiveView,
    analyticsLiveViewChartData
  } = useSelector((state: RootState) => {
    return {
      videoAnalyticsData: state.videos.videoAnalyticsData,
      isVideoAnalyticsDataLoading: state.videos.isVideoAnalyticsDataLoading,
      analyticsLiveView: state.videos.analyticsLiveView,
      analyticsLiveViewChartData: state.videos.analyticsLiveViewChartData
    };
  });

  const [areaChartData, setAreaChartData] = useState({
    labels: analyticsLiveView && analyticsLiveView.map((data: any) => data.time),
    datasets: [
      {
        fill: true,
        lineTension: 0.4,
        label: 'No. of users',
        data: analyticsLiveView && analyticsLiveView.map((data: any) => data.value),
        borderColor: 'rgb(255,99,132)',
        backgroundColor: 'rgba(255, 255, 255, .4)'
      }
    ]
  });

  useEffect(() => {
    if (analyticsLiveViewChartData) {
      setAreaChartData({
        labels:
          analyticsLiveViewChartData && analyticsLiveViewChartData?.time?.map((data: any) => data),
        datasets: [
          {
            fill: true,
            lineTension: 0.4,
            label: 'No. of users',
            data:
              analyticsLiveViewChartData &&
              analyticsLiveViewChartData?.users?.map((data: any) => data),
            borderColor: 'rgb(255,99,132)',
            backgroundColor: 'rgba(255, 255, 255, .4)'
          }
        ]
      });
    }
  }, [analyticsLiveViewChartData]);
  useEffect(() => {
    if (id) {
      videoAction.getVideoAnalyticsDataRequest(id);
      videoAction.getVideoAnalyticsLiveViewsRequest();
      videoAction.getVideoAnalyticsLiveViewsChartDataRequest();
    }
  }, []);

  useEffect(() => {
    if (id) {
      videoAction.getSingleVideosRequest(id);
    }
  }, [id]);
  const options = {
    scales: {
      xAxis: {
        grid: {
          display: false
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const
      }
    }
  };

  return (
    <>
      {videoAnalyticsData && (
        <>
          <div>
            <LineChart chartData={areaChartData} options={options} />
          </div>

          <div className="overflow-x-auto">
            <table className="table-auto w-full my-6">
              <thead className="text-xs font-semibold uppercase bg-gray-50">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Username</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Email Address</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">IP Address</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Connected Since UTC</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {/* {analyticsLiveView &&
                  analyticsLiveView.map((item, index) => {
                    return (
                      <tr key={index} className="border-b">
                        <td className="p-2">
                          <div className="text-left">{item.userName}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.emailAddress}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.ipAddress}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.connected}</div>
                        </td>
                      </tr>
                    );
                  })} */}
                <tr className="border-b">
                  <td className="p-2">
                    <div className="text-left">No Data Found</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default LiveInfoAnalytics;
