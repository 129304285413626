import { all, put, takeEvery } from 'redux-saga/effects';
import { SettingActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetApiV2(data: SettingActions.getApiV2RequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_API_V2.url);
        if (response.status === 200) {
            yield put(SettingActions.getApiV2RequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getApiV2RequestFailure());
    }
}

function* asyncAddApiV2(data: SettingActions.addApiV2RequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_API_V2.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addApiV2RequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addApiV2RequestFailure());
    }
}

function* asyncDeleteApiV2(data: SettingActions.deleteApiV2RequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.ADD_API_V2.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.deleteApiV2RequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.deleteApiV2RequestFailure());
    }
}

function* asyncGetLiveStreamUrl() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_LIVESTREAM_URL.url);
        if (response.status === 200) {
            yield put(SettingActions.getLiveStreamUrlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getLiveStreamUrlRequestFailure());
    }
}

function* asyncGetSaml() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SAML.url);
        if (response.status === 200) {
            yield put(SettingActions.getSamlRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSamlRequestFailure());
    }
}

function* asyncSaveSaml(data: SettingActions.saveSamlRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_SAML.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.saveSamlRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.saveSamlRequestFailure());
    }
}
function* asyncUpdateCloudRecording(data: SettingActions.updateCloudRecordingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_LIVESTREAM_URL.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateCloudRecordingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateCloudRecordingRequestFailure());
    }
}
function* asyncUpdateAutoPublishOnDemand(data: SettingActions.updateAutoPublishOnDemandRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_LIVESTREAM_URL.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateAutoPublishOnDemandRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateAutoPublishOnDemandRequestFailure());
    }
}

function* asyncGetEcommerce(data: SettingActions.getEcommerceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ECOMMERCE.url);
        if (response.status === 200) {
            yield put(SettingActions.getEcommerceRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getEcommerceRequestFailure());
    }
}
function* asyncSaveEcommerce(data: SettingActions.saveEcommerceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.SAVE_ECOMMERCE.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.saveEcommerceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.saveEcommerceRequestFailure());
    }
}

function* asyncGetVideoLogs(data: SettingActions.getVideoLogsRequestAction) {
    try {
        const hardCodedData = [
            {
                id: '1',
                title: 'demo989',
                channelName: 'QA Testing 1',
                videoId: '	ad4704e8-b26e-49a5-9f58-942ca7d38833',
                createdBy: 'Lakhveer Singh',
                createdDate: '06/12/2022 10:52:03 AM',
                lastModifiedBy: 'Lakhveer Singh',
                lastModifiedDate: '	05/01/2023 09:56:57 AM'
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(SettingActions.getVideoLogsRequestSuccess(response.data.result));
        // }
        yield put(SettingActions.getVideoLogsRequestSuccess(hardCodedData));
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getVideoLogsRequestFailure());
    }
}

function* asyncGetChannelLogs(data: SettingActions.getChannelLogsRequestAction) {
    try {
        const hardCodedData = [
            {
                id: '1',
                name: 'Test channel1',
                parentChannelName: 'Test Channel_A',
                channelId: '463b5eec-5d67-4c5e-9725-f7e89feb42c7',
                createdBy: 'Joseph Fryer',
                createdDate: '06/12/2022 10:52:03 AM',
                lastModifiedBy: 'Lakhveer Singh',
                lastModifiedDate: '	05/01/2023 09:56:57 AM'
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(SettingActions.getChannelLogsRequestSuccess(response.data.result));
        // }
        yield put(SettingActions.getChannelLogsRequestSuccess(hardCodedData));
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getChannelLogsRequestFailure());
    }
}

function* asyncGetAnalyticsLogs(data: SettingActions.getAnalyticsLogsRequestAction) {
    try {
        const hardCodedData = [
            {
                id: '1',
                title: 'Live Stream July21',
                channelName: 'Live Channel',
                videoId: '2940d5d5-1e45-4940-be59-bbc566943403',
                addedDate: '13/10/2021 09:50:03 PM',
                lastRunDate: '20/07/2021 02:07:12 PM'
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(SettingActions.getAnalyticsLogsRequestSuccess(response.data.result));
        // }
        yield put(SettingActions.getAnalyticsLogsRequestSuccess(hardCodedData));
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getAnalyticsLogsRequestFailure());
    }
}

function* asyncRunUpdate(data: SettingActions.runUpdateRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(SettingActions.runUpdateRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.runUpdateRequestFailure());
    }
}

function* asyncEnableIntegrationProvider(data: SettingActions.enableIntegrationProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.putData(`${URLS.ENABLE_INTEGRATION.url}/${data.payload?.id}?active=${data.payload?.active}`);
        if (response.status === 200) {
            yield put(SettingActions.enableIntegrationProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.enableIntegrationProviderRequestFailure());
    }
}

function* asyncGetImisIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_IMIS.url);
        if (response.status === 200) {
            yield put(SettingActions.getImisIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getImisIntegrationRequestFailure());
    }
}
function* asyncAddImisIntegration(data: SettingActions.addImisIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_IMIS.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addImisIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addImisIntegrationRequestFailure());
    }
}
function* asyncGetMemberEvolutionIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_MEMBER_EVOLUTION.url);
        if (response.status === 200) {
            yield put(SettingActions.getMemberEvolutionIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getMemberEvolutionIntegrationRequestFailure());
    }
}
function* asyncAddMemberEvolutionIntegration(data: SettingActions.addMemberEvolutionIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MEMBER_EVOLUTION.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addMemberEvolutionIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addMemberEvolutionIntegrationRequestFailure());
    }
}

function* asyncGetWicketIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_WICKET.url);
        if (response.status === 200) {
            yield put(SettingActions.getWicketIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getWicketIntegrationRequestFailure());
    }
}
function* asyncAddWicketIntegration(data: SettingActions.addWicketIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_WICKET.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addWicketIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addWicketIntegrationRequestFailure());
    }
}

function* asyncGetDynamics365Integration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_DYNAMICS365.url);
        if (response.status === 200) {
            yield put(SettingActions.getDynamics365IntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getDynamics365IntegrationRequestFailure());
    }
}
function* asyncAddDynamics365Integration(data: SettingActions.addDynamics365IntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_DYNAMICS365.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addDynamics365IntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addDynamics365IntegrationRequestFailure());
    }
}
//
function* asyncGetMicrosoftAzureIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_MICROSOFTAZURE.url);
        if (response.status === 200) {
            yield put(SettingActions.getMicrosoftAzureIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getMicrosoftAzureIntegrationRequestFailure());
    }
}
function* asyncAddMicrosoftAzureIntegration(data: SettingActions.addMicrosoftAzureIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MICROSOFTAZURE.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addMicrosoftAzureIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addMicrosoftAzureIntegrationRequestFailure());
    }
}
function* asyncGetNoviAMSIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_NOVIAMS.url);
        if (response.status === 200) {
            yield put(SettingActions.getNoviAMSIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getNoviAMSIntegrationRequestFailure());
    }
}
function* asyncAddNoviAMSIntegration(data: SettingActions.addNoviAMSIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_NOVIAMS.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addNoviAMSIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addNoviAMSIntegrationRequestFailure());
    }
}

function* asyncGetOAuth2Integration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_OAUTH2.url);
        if (response.status === 200) {
            yield put(SettingActions.getOAuth2IntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getOAuth2IntegrationRequestFailure());
    }
}
function* asyncAddOAuth2Integration(data: SettingActions.addOAuth2IntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_OAUTH2.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addOAuth2IntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addOAuth2IntegrationRequestFailure());
    }
}

function* asyncGetMemberClicksIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_MEMBERCLICKS.url);
        if (response.status === 200) {
            yield put(SettingActions.getMemberClicksIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getMemberClicksIntegrationRequestFailure());
    }
}
function* asyncAddMemberClicksIntegration(data: SettingActions.addMemberClicksIntegrationRequestAction) {
    try {
       // console.log(URLS.ADD_MEMBERCLICKS.url +': '+ data.payload);
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MEMBERCLICKS.url, data.payload);
        if (response.status === 200) {
            //console.log("add member success called");
            yield put(SettingActions.addMemberClicksIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addMemberClicksIntegrationRequestFailure());
    }
}
function* asyncGetPersonifyIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PERSONIFY.url);
        if (response.status === 200) {
            yield put(SettingActions.getPersonifyIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getPersonifyIntegrationRequestFailure());
    }
}
function* asyncAddPersonifyIntegration(data: SettingActions.addPersonifyIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_PERSONIFY.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addPersonifyIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addPersonifyIntegrationRequestFailure());
    }
}
function* asyncSavePersonifyIntegration(data: SettingActions.savePersonifyIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('', data.payload);
        if (response.status === 200) {
            yield put(SettingActions.savePersonifyIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.savePersonifyIntegrationRequestFailure());
    }
}

function* asyncGetNaylorIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_NAYLOR.url);
        if (response.status === 200) {
            yield put(SettingActions.getNaylorIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getNaylorIntegrationRequestFailure());
    }
}

function* asyncGetSpargoIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SPARGO.url);
        if (response.status === 200) {
            yield put(SettingActions.getSpargoIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSpargoIntegrationRequestFailure());
    }
}

function* asyncGetSingleApiV2(data: SettingActions.getSingleApiV2RequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_API_V2.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.getSingleApiV2RequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSingleApiV2RequestFailure());
    }
}

function* asyncUpdateApiV2(data: SettingActions.updateApiV2RequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_API_V2.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateApiV2RequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateApiV2RequestFailure());
    }
}
function* asyncGetMembesIntegration(data: SettingActions.getMembesIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_MEMBES.url);
        if (response.status === 200) {
            yield put(SettingActions.getMembesIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getMembesIntegrationRequestFailure());
    }
}
function* asyncAddMembesIntegration(data: SettingActions.addMembesIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MEMBES.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addMembesIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addMembesIntegrationRequestFailure());
    }
}
function* asyncGetGoogleIntegration(data: SettingActions.getGoogleIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_GOOGLE.url);
        if (response.status === 200) {
            yield put(SettingActions.getGoogleIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getGoogleIntegrationRequestFailure());
    }
}

function* asyncGetWebCourseIntegration(data: SettingActions.getWebCourseIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_WEBCOURSE.url);
        if (response.status === 200) {
            yield put(SettingActions.getWebCourseIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getWebCourseIntegrationRequestFailure());
    }
}

function* asyncGetZoomIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ZOOM.url);
        if (response.status === 200) {
            yield put(SettingActions.getZoomIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getZoomIntegrationRequestFailure());
    }
}

function* asyncEnablePaymentProvider(data: SettingActions.enablePaymentProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.putData(`${URLS.ENABLE_PAYMENT_PROVIDER.url}/${data.payload?.id}?active=${data.payload?.active}`);
        if (response.status === 200) {
            yield put(SettingActions.enablePaymentProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.enablePaymentProviderRequestFailure());
    }
}

function* asyncGetPaypalPayflow(data: SettingActions.getPaypalPayFlowProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PAYPAL_PAYFLOW.url);
        if (response.status === 200) {
            yield put(SettingActions.getPaypalPayFlowProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getPaypalPayFlowProviderRequestFailure());
    }
}
function* asyncAddPaypalPayFlowProvider(data: SettingActions.addPaypalPayFlowProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_PAYPAL_PAYFLOW.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addPaypalPayFlowProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addPaypalPayFlowProviderRequestFailure());
    }
}
function* asyncGetEzidebitProvider(data: SettingActions.getEziDebitProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_EZIDEBIT.url);
        if (response.status === 200) {
            yield put(SettingActions.getEziDebitProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getEziDebitProviderRequestFailure());
    }
}
function* asyncAddEzidebitProvider(data: SettingActions.addEzidebitProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_EZIDEBIT.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addEzidebitProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addEzidebitProviderRequestFailure());
    }
}
function* asyncGetQuickBooksProvider(data: SettingActions.getQuickBooksProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_QUICKBOOKS.url);
        if (response.status === 200) {
            yield put(SettingActions.getQuickBooksProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getQuickBooksProviderRequestFailure());
    }
}

function* asyncGetStripeProvider() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_STRIPE.url);
        if (response.status === 200) {
            yield put(SettingActions.getStripeProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getStripeProviderRequestFailure());
    }
}
function* asyncAddStripeProvider(data: SettingActions.addStripeProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_STRIPE.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addStripeProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addStripeProviderRequestFailure());
    }
}
function* asyncGetPayPalProvider() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PAYPAL.url);
        if (response.status === 200) {
            yield put(SettingActions.getPayPalProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getPayPalProviderRequestFailure());
    }
}
function* asyncAddPayPalProvider(data: SettingActions.addPayPalProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_PAYPAL.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addPayPalProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addPayPalProviderRequestFailure());
    }
}
function* asyncGetBPointProvider() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_BPOINT.url);
        if (response.status === 200) {
            yield put(SettingActions.getBPointProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getBPointProviderRequestFailure());
    }
}
function* asyncAddBPointProvider(data: SettingActions.addBPointProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_BPOINT.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addBPointProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addBPointProviderRequestFailure());
    }
}
function* asyncGetLivePayProvider() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_LIVEPAY.url);
        if (response.status === 200) {
            yield put(SettingActions.getLivePayProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getLivePayProviderRequestFailure());
    }
}
function* asyncAddLivePayProvider(data: SettingActions.addLivePayProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_LIVEPAY.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addLivePayProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addLivePayProviderRequestFailure());
    }
}
function* asyncGetCePoints() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CE_POINTS.url)
        if (response.status === 200) {
            yield put(SettingActions.getCePointsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getCePointsRequestFailure());
    }
}

function* asyncSaveCePoint(data: SettingActions.saveCePointsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.SAVE_CE_POINTS.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.saveCePointsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.saveCePointsRequestFailure());
    }
}

function* asyncGetEducationPoints() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_EDUCATION_POINTS.url);
        if (response.status === 200) {
            yield put(SettingActions.getEducationPointsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getEducationPointsRequestFailure());
    }
}

function* asyncSaveEducationPoints(data: SettingActions.saveEducationPointsRequestAction) {
    try {
        if (data.payload) {
            const updatedPayload = { ...data.payload, creditTypes: [data.payload?.creditTypes] }
            const response: AxiosResponse = yield ApiService.postData(`${URLS.SAVE_EDUCATION_POINTS.url}`, updatedPayload);
            if (response.status === 200) {
                yield put(SettingActions.saveEducationPointsRequestSuccess());
            }
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.saveEducationPointsRequestFailure());
    }
}

function* asyncUpdateEducationPoints(data: SettingActions.updateEducationPointsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_EDUCATION_POINTS.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateEducationPointsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateEducationPointsRequestFailure());
    }
}

function* asyncDeleteEducationPoints(data: SettingActions.deleteEducationPointsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_EDUCATION_POINTS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.deleteEducationPointsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.deleteEducationPointsRequestFailure());
    }
}

function* asyncGetSingleEducationPoints(data: SettingActions.getEducationPointsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_EDUCATION_POINTS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.getSingleEducationPointsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSingleEducationPointsRequestFailure());
    }
}

function* asyncGetAttributeMapping(data: SettingActions.getAttributeMappingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ATTRIBUTEMAPPING.url}/${data.payload?.providerId}/${data.payload?.mappingType}`);
        if (response.status === 200) {
            yield put(SettingActions.getAttributeMappingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getAttributeMappingRequestFailure());
    }
}

function* asyncAddAttributeMapping(data: SettingActions.addAttributeMappingRequestAction) {
    try {
        //console.log('data.payload', data.payload);
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_ATTRIBUTEMAPPING.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addAttributeMappingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addAttributeMappingRequestFailure());
    }
}
function* asyncUpdateAttributeMapping(data: SettingActions.updateAttributeMappingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_ATTRIBUTEMAPPING.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateAttributeMappingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateAttributeMappingRequestFailure());
    }
}

function* asyncDeleteAttributeMapping(data: SettingActions.deleteAttributeMappingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_ATTRIBUTEMAPPING.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.deleteAttributeMappingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.deleteAttributeMappingRequestFailure());
    }
}

function* asyncGetSingleAttributeMapping(data: SettingActions.getAttributeMappingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_ATTRIBUTEMAPPING.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.getSingleAttributeMappingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSingleAttributeMappingRequestFailure());
    }
}

function* asyncGetAttributeMappingColumnList(data: SettingActions.getAttributeMappingColumnListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ATTRIBUTEMAPPING_COLUMNLIST.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.getAttributeMappingColumnListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getAttributeMappingColumnListRequestFailure());
    }
}
function* asyncAttributeMappingTypeList(data: SettingActions.getAttributeMappingColumnListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ATTRIBUTEMAPPING_TYPELIST.url}`);
        if (response.status === 200) {
            yield put(SettingActions.getAttributeMappingTypeListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getAttributeMappingTypeListRequestFailure());
    }
}
function* asyncGetTemplates() {
    try {
        const hardcodedData = [
            {
                id: '1',
                name: 'Test Template',
                pollType: 'Quiz',
            },
            {
                id: '2',
                name: 'Sample Template',
                pollType: 'starRating',
            },
            {
                id: '3',
                name: 'Sample Template1',
                pollType: 'multipleChoice',
            },
            {
                id: '4',
                name: 'Sample Template2',
                pollType: 'openText',
            }

        ]
        // const response: AxiosResponse = yield ApiService.getData('');
        // if (response.status === 200) {
        //     yield put(SettingActions.getTemplatesRequestSuccess(response.data.result));
        // }
        yield put(SettingActions.getTemplatesRequestSuccess(hardcodedData));
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getTemplatesRequestFailure());
    }
}

function* asyncGetIntegration() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_INTEGRATION.url);
        if (response.status === 200) {
            yield put(SettingActions.getIntegrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getIntegrationRequestFailure());
    }
}

function* asyncUpdateIntegration(data: SettingActions.updateIntegrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_INTEGRATIONS.url}/${data.payload?.name}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updateIntegrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateIntegrationRequestFailure());
    }
}

function* asyncGetPaymentProvider(data: SettingActions.getPaymentProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PAYMENT_PROVIDER.url);
        if (response.status === 200) {
            yield put(SettingActions.getPaymentProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getPaymentProviderRequestFailure());
    }
}

function* asyncUpdatePayment(data: SettingActions.updatePaymentRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_PAYMENT.url}/${data.payload?.name}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(SettingActions.updatePaymentRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updatePaymentRequestFailure());
    }
}
function* asyncAddTemplate(data: SettingActions.addTemplateRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``);
        if (response.status === 200) {
            yield put(SettingActions.addTemplateRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addTemplateRequestFailure());
    }
}
function* asyncDeleteTemplate(data: SettingActions.deleteTemplateRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(``);
        if (response.status === 200) {
            yield put(SettingActions.deleteTemplateRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.deleteTemplateRequestFailure());
    }
}
function* asyncUpdateTemplate(data: SettingActions.updateTemplateRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(``, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.updateTemplateRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateTemplateRequestFailure());
    }
}
function* asyncGetCurrency() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CURRENCY.url);
        if (response.status === 200) {
            yield put(SettingActions.getCurrencyRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getCurrencyRequestFailure());
    }
}
function* asyncUpdateCurrency(data: SettingActions.updateCurrencyRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_CURRENCY.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.updateCurrencyRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateCurrencyRequestFailure());
    }
}
function* asyncGetSelectedCurrency() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SELECTED_CURRENCY.url);
        if (response.status === 200) {
            yield put(SettingActions.getSelectedCurrencyRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getSelectedCurrencyRequestFailure());
    }
}
function* asyncGetEcommerceSetting() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ECOMMERCE_SETTING.url);
        if (response.status === 200) {
            yield put(SettingActions.getEcommerceSettingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getEcommerceSettingRequestFailure());
    }
}
function* asyncUpdateEcommerceSetting(data: SettingActions.updateEcommerceSettingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_ECOMMERCE_SETTING.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.updateEcommerceSettingRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.updateEcommerceSettingRequestFailure());
    }
}
function* asyncGetQuickBooks(data: SettingActions.getQuickBooksProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(SettingActions.getQuickBooksProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getQuickBooksProviderRequestFailure());
    }
}
function* asyncAddQuickBooksProvider(data: SettingActions.addQuickBooksProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_QUICKBOOKS.url, data.payload);
        if (response.status === 200) {
            yield put(SettingActions.addQuickBooksProviderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.addQuickBooksProviderRequestFailure());
    }
}
function* asyncGetConnectQuickBooks(data: SettingActions.getConnectQuickBooksProviderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getDataWithAPIkey(`${URLS.GET_CONNECT_QUICKBOOKS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(SettingActions.getConnectQuickBooksProviderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(SettingActions.getConnectQuickBooksProviderRequestFailure());
    }
}
export function* settingSaga() {
    yield all([
        takeEvery(Constants.getApiV2RequestActionType, asyncGetApiV2),
        takeEvery(Constants.addApiV2RequestActionType, asyncAddApiV2),
        takeEvery(Constants.deleteApiV2RequestActionType, asyncDeleteApiV2),
        takeEvery(Constants.getLiveStreamUrlRequestActionType, asyncGetLiveStreamUrl),
        takeEvery(Constants.getSamlRequestActionType, asyncGetSaml),
        takeEvery(Constants.saveSamlRequestActionType, asyncSaveSaml),
        takeEvery(Constants.updateCloudRecordingRequestActionType, asyncUpdateCloudRecording),
        takeEvery(Constants.updateAutoPublishOnDemandRequestActionType, asyncUpdateAutoPublishOnDemand),
        takeEvery(Constants.getEcommerceRequestActionType, asyncGetEcommerce),
        takeEvery(Constants.saveEcommerceRequestActionType, asyncSaveEcommerce),
        takeEvery(Constants.getVideoLogsRequestActionType, asyncGetVideoLogs),
        takeEvery(Constants.getChannelLogsRequestActionType, asyncGetChannelLogs),
        takeEvery(Constants.getAnalyticsLogsRequestActionType, asyncGetAnalyticsLogs),
        takeEvery(Constants.runUpdateRequestActionType, asyncRunUpdate),
        takeEvery(Constants.enableIntegrationProviderRequestActionType, asyncEnableIntegrationProvider),
        takeEvery(Constants.getImisIntegrationRequestActionType, asyncGetImisIntegration),
        takeEvery(Constants.addImisIntegrationRequestActionType, asyncAddImisIntegration),
        takeEvery(Constants.getMemberEvolutionIntegrationRequestActionType, asyncGetMemberEvolutionIntegration),
        takeEvery(Constants.addMemberEvolutionIntegrationRequestActionType, asyncAddMemberEvolutionIntegration),
        takeEvery(Constants.getWicketIntegrationRequestActionType, asyncGetWicketIntegration),
        takeEvery(Constants.addWicketIntegrationRequestActionType, asyncAddWicketIntegration),
        takeEvery(Constants.getDynamics365IntegrationRequestActionType, asyncGetDynamics365Integration),
        takeEvery(Constants.addDynamics365IntegrationRequestActionType, asyncAddDynamics365Integration),
        takeEvery(Constants.getMicrosoftAzureIntegrationRequestActionType, asyncGetMicrosoftAzureIntegration),
        takeEvery(Constants.addMicrosoftAzureIntegrationRequestActionType, asyncAddMicrosoftAzureIntegration),
        takeEvery(Constants.getNoviAMSIntegrationRequestActionType, asyncGetNoviAMSIntegration),
        takeEvery(Constants.addNoviAMSIntegrationRequestActionType, asyncAddNoviAMSIntegration),
        takeEvery(Constants.getOAuth2IntegrationRequestActionType, asyncGetOAuth2Integration),
        takeEvery(Constants.addOAuth2IntegrationRequestActionType, asyncAddOAuth2Integration),
        takeEvery(Constants.getMemberClicksIntegrationRequestActionType, asyncGetMemberClicksIntegration),
        takeEvery(Constants.addMemberClicksIntegrationRequestActionType, asyncAddMemberClicksIntegration),
        takeEvery(Constants.getPersonifyIntegrationRequestActionType, asyncGetPersonifyIntegration),
        takeEvery(Constants.addPersonifyIntegrationRequestActionType, asyncAddPersonifyIntegration),
        takeEvery(Constants.savePersonifyIntegrationRequestActionType, asyncSavePersonifyIntegration),
        takeEvery(Constants.getNaylorIntegrationRequestActionType, asyncGetNaylorIntegration),
        takeEvery(Constants.getSpargoIntegrationRequestActionType, asyncGetSpargoIntegration),
        takeEvery(Constants.getSingleApiV2RequestActionType, asyncGetSingleApiV2),
        takeEvery(Constants.updateApiV2RequestActionType, asyncUpdateApiV2),
        takeEvery(Constants.getMembesIntegrationRequestActionType, asyncGetMembesIntegration),
        takeEvery(Constants.addMembesIntegrationRequestActionType, asyncAddMembesIntegration),
        takeEvery(Constants.getGoogleIntegrationRequestActionType, asyncGetGoogleIntegration),
        takeEvery(Constants.getWebCourseIntegrationRequestActionType, asyncGetWebCourseIntegration),
        takeEvery(Constants.getZoomIntegrationRequestActionType, asyncGetZoomIntegration),
        takeEvery(Constants.enablePaymentProviderRequestActionType, asyncEnablePaymentProvider),
        takeEvery(Constants.getPaypalPayFlowProviderRequestActionType, asyncGetPaypalPayflow),
        takeEvery(Constants.addPaypalPayFlowProviderRequestActionType, asyncAddPaypalPayFlowProvider),
        takeEvery(Constants.getEziDebitProviderRequestActionType, asyncGetEzidebitProvider),
        takeEvery(Constants.addEzidebitProviderRequestActionType, asyncAddEzidebitProvider),
        takeEvery(Constants.getQuickBooksProviderRequestActionType, asyncGetQuickBooksProvider),
        takeEvery(Constants.getStripeProviderRequestActionType, asyncGetStripeProvider),
        takeEvery(Constants.addStripeProviderRequestActionType, asyncAddStripeProvider),
        takeEvery(Constants.getPayPalProviderRequestActionType, asyncGetPayPalProvider),
        takeEvery(Constants.addPayPalProviderRequestActionType, asyncAddPayPalProvider),
        takeEvery(Constants.getBPointProviderRequestActionType, asyncGetBPointProvider),
        takeEvery(Constants.addBPointProviderRequestActionType, asyncAddBPointProvider),
        takeEvery(Constants.getLivePayProviderRequestActionType, asyncGetLivePayProvider),
        takeEvery(Constants.addLivePayProviderRequestActionType, asyncAddLivePayProvider),
        takeEvery(Constants.getCePointsRequestActionType, asyncGetCePoints),
        takeEvery(Constants.saveCePointsRequestActionType, asyncSaveCePoint),
        takeEvery(Constants.getEducationPointsRequestActionType, asyncGetEducationPoints),
        takeEvery(Constants.saveEducationPointsRequestActionType, asyncSaveEducationPoints),
        takeEvery(Constants.updateEducationPointsRequestActionType, asyncUpdateEducationPoints),
        takeEvery(Constants.deleteEducationPointsRequestActionType, asyncDeleteEducationPoints),
        takeEvery(Constants.getSingleEducationPointsRequestActionType, asyncGetSingleEducationPoints),
        takeEvery(Constants.getAttributeMappingRequestActionType, asyncGetAttributeMapping),
        takeEvery(Constants.addAttributeMappingRequestActionType, asyncAddAttributeMapping),
        takeEvery(Constants.updateAttributeMappingRequestActionType, asyncUpdateAttributeMapping),
        takeEvery(Constants.deleteAttributeMappingRequestActionType, asyncDeleteAttributeMapping),
        takeEvery(Constants.getSingleAttributeMappingRequestActionType, asyncGetSingleAttributeMapping),
        takeEvery(Constants.getAttributeMappingColumnListRequestActionType, asyncGetAttributeMappingColumnList),
        takeEvery(Constants.getAttributeMappingTypeListRequestActionType, asyncAttributeMappingTypeList),
        takeEvery(Constants.getTemplatesRequestActionType, asyncGetTemplates),
        takeEvery(Constants.getIntegrationRequestActionType, asyncGetIntegration),
        takeEvery(Constants.updateIntegrationRequestActionType, asyncUpdateIntegration),
        takeEvery(Constants.getPaymentProviderRequestActionType, asyncGetPaymentProvider),
        takeEvery(Constants.updatePaymentRequestActionType, asyncUpdatePayment),
        takeEvery(Constants.addTemplateRequestActionType, asyncAddTemplate),
        takeEvery(Constants.deleteTemplateRequestActionType, asyncDeleteTemplate),
        takeEvery(Constants.updateTemplateRequestActionType, asyncUpdateTemplate),
        takeEvery(Constants.getCurrencyRequestActionType, asyncGetCurrency),
        takeEvery(Constants.updateCurrencyRequestActionType, asyncUpdateCurrency),
        takeEvery(Constants.getSelectedCurrencyRequestActionType, asyncGetSelectedCurrency),
        takeEvery(Constants.getEcommerceSettingRequestActionType, asyncGetEcommerceSetting),
        takeEvery(Constants.updateEcommerceSettingRequestActionType, asyncUpdateEcommerceSetting),
        takeEvery(Constants.getQuickBooksProviderRequestActionType, asyncGetQuickBooks),
        takeEvery(Constants.addQuickBooksProviderRequestActionType, asyncAddQuickBooksProvider),
        takeEvery(Constants.getConnectQuickBooksProviderRequestActionType, asyncGetConnectQuickBooks),
    ]);
}
