import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'src/components/common/pagination';
import { CategoryData } from 'src/models/categoryModel';
import Loader from 'src/components/loader';
import AddModule from '../AddModule';
import { DebounceInput } from 'react-debounce-input';
import DeleteModule from '../DeleteModule';
interface IProps {
  setSelectedModuleId: (value: CategoryData) => void;
  setTabFlag: (value: number) => void;
}
const Module: React.FC<IProps> = ({ setSelectedModuleId, setTabFlag }) => {
  const dispatch = useDispatch();
  const categoryActions = useCategoryActions(dispatch);
  const [searchValue, setSearchValue] = useState<string>('');
  const [moduleAddFlag, setModuleAddFlag] = useState<string>('Add');
  const [moduleId, setModuleId] = useState<string>('');
  const { module, isModuleSaved, modulePaginationMeta } = useSelector((state: RootState) => {
    return {
      module: state.category.module,
      isModuleSaved: state.category.isModuleSaved,
      modulePaginationMeta: state.category.modulePaginationMeta
    };
  });

  useEffect(() => {
    if (isModuleSaved) {
      categoryActions.getModuleRequest({ pageNumber: 1 });
    }
  }, [isModuleSaved]);

  const handleEditClick = (moduleId: string) => {
    categoryActions.getSingleModuleRequest(moduleId);
    setModuleAddFlag('Edit');
  };
  const handlePageChange = (pageNumber: number) => {
    const data = { querySearch: searchValue, pageNumber };
    categoryActions.getModuleRequest(data);
  };
  const handleDeleteClick = (data: CategoryData) => {
    setModuleId(data.id);
    setSelectedModuleId({ id: data.id, name: data.name });
    setTabFlag(3);
  };

  const handleSearchModules = (e: string) => {
    setSearchValue(e);
    categoryActions.getModuleRequest({ querySearch: e, pageNumber: 1 });
  };

  return (
    <>
      <div className="tab-pane fade " id="tabs-module" role="tabpanel">
        <div className="py-5 px-7">
          <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
            <h2 className="font-semibold "></h2>
            <div className="md:flex items-center text-center">
              <div className="form-group md:flex items-center text-center">
                <label className="mr-1 font-semibold">Search:</label>
                <DebounceInput
                  placeholder="Search"
                  minLength={1}
                  className="input-style w-48 mr-3 mb-1"
                  debounceTimeout={500}
                  onChange={(event) => handleSearchModules(event.target.value)}
                />
              </div>
              <button
                onClick={() => setModuleAddFlag('Add')}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#AddModule"
                className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                Add
              </button>
            </div>
          </div>
          <div className="overflow-x-auto-sm">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-100">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Module Name</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left invisible">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {module &&
                  module.map((module) => {
                    return (
                      <tr key={module.id}>
                        <td className="p-2">{module.module}</td>
                        <td className="p-2">
                          <div className="dropdown relative">
                            <a
                              className="
                              dropdown-toggle
                              transition
                              duration-150
                              ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                              href="#"
                              type="button"
                              id="ActionButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul
                              className="dropdown-menu action-dropdown"
                              aria-labelledby="ActionButton">
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#AddModule"
                                onClick={() => handleEditClick(module.id)}>
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-edit w-6"></i>
                                  <span>Edit</span>
                                </span>
                              </li>
                              <li
                                onClick={() =>
                                  handleDeleteClick({ id: module.id, name: module.module })
                                }>
                                <span
                                  className="dropdown-item flex-items cursor-pointer text-red-500"
                                  data-bs-toggle="modal"
                                  data-bs-target="#DeleteModule">
                                  <i className="fas fa-trash w-6"></i>
                                  <span>Delete</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!!modulePaginationMeta && !!Object.keys(modulePaginationMeta).length && (
              <Pagination
                currentPage={modulePaginationMeta.pageNumber}
                totalCount={modulePaginationMeta.totalCount}
                pageSize={modulePaginationMeta.pageSize}
                onPageChange={(page: any) => handlePageChange(page)}
              />
            )}
          </div>
        </div>
      </div>
      <AddModule moduleAddFlag={moduleAddFlag} />
      <DeleteModule moduleId={moduleId} />
    </>
  );
};

export default Module;
