import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useSettingActions } from 'src/store/settings/actions';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const AnalyticsLog = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();

  useEffect(() => {
    settingAction.getAnalyticsLogsRequest();
  }, []);

  const { analyticsLogs, isRunUpdated, message, error } = useSelector((state: RootState) => {
    return {
      analyticsLogs: state.setting.analyticsLogs,
      isRunUpdated: state.setting.isRunUpdated,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  useEffect(() => {
    if (isRunUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isRunUpdated]);

  const handleLogClick = (e: string) => {
    if (e === '1') {
      navigate('/videoLog');
    } else if (e === '2') {
      navigate('/channelLog');
    } else if (e === '3') {
      navigate('/analyticsLog');
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <>
      <div className="mb-5">
        <h2 className="font-semibold my-1">Logs</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">
            <strong>Logs</strong>
          </li>
        </ol>
      </div>
      <div className="dashboard-card p-3">
        <form>
          <div className="form-group mb-4 w-1/2">
            <select className="input-style" onChange={(e) => handleLogClick(e.target.value)}>
              <option value={0}>Select Log</option>
              <option value={1}>Video</option>
              <option value={2}>Channel</option>
              <option value={3}>Queued Analytics</option>
            </select>
          </div>
        </form>
        <div className={`form-group w-2/6 ml-auto mb-4`}>
          <FormInputField
            label={''}
            type={'text'}
            fieldName={'search'}
            register={register}
            errors={errors}
            isRequired={true}
            placeholder={'search'}
          />
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border">
            <thead className="text-xs font-semibold uppercase bg-gray-50">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Title</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Channel Name</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Video Id</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left"> Added Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Last Run Date</div>
                </th>

                <th className="p-2">
                  <div className="font-semibold text-left"></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {analyticsLogs &&
                analyticsLogs.length &&
                analyticsLogs.map((log) => {
                  return (
                    <tr key={log.id}>
                      <td className="p-2">{log.title}</td>
                      <td className="p-2">{log.channelName}</td>
                      <td className="p-2">{log.videoId}</td>
                      <td className="p-2">{log.addedDate}</td>
                      <td className="p-2">{log.lastRunDate}</td>

                      <td className="p-2">
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap px-2">
                          Run update
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AnalyticsLog;
