import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UsersModel } from 'src/models/usersModel';
import { useLoginActions } from 'src/store/login/actions';
import { errorMsg, successMsg } from '../utilities/utils';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from '../ToastContainer';

const ResetPassword: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<UsersModel>();
    const [userDetails, setUserDetails] = useSearchParams();
    const [passState, setPassState] = useState('');
    const [passCheck, setPassCheck] = useState<string>("null");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handlePassChange = (e: any) => {
        setPassState(e.target.value)
    }
    
    const {
        success, resetPasswordObj
    } = useSelector((state: RootState) => {
        return {
            success: state.login.isResetSuccess,
            resetPasswordObj: state.login.resetPasswordObj
        };
    });

    const handleConfirmPassChange = (e: any) => {
        e.target.value === passState ? setPassCheck("true") : setPassCheck("false")
    }

    useEffect(() => {
        if (Object.keys(resetPasswordObj).length > 0) {
            if (success) {
                if (resetPasswordObj?.statusCode == 504) {
                    errorMsg(resetPasswordObj?.message);
                    loginAction.reset();
                }
                else
                    navigate(`/account/ConfirmResetPassword`);
            }
            else {
                errorMsg("Error while reseting the Password.");
                loginAction.reset();
            }
        }
    }, [resetPasswordObj, success]);

    const loginAction = useLoginActions(dispatch);
    const onSubmit = (data: UsersModel) => {
        const userData = { ...data }
        loginAction.resetPasswordRequest({
            ...userData, userId: userDetails.get('userId')?.toString() ?? '', code: userDetails.get('code')?.toString() ?? '', passwordHash: passState
        });
    }

    return (
        <>
            <ToastMessage />
            <section className="bg-gray-100 h-screen">
                <div className="container px-6 h-full mx-auto">
                    <div className="flex justify-center items-center h-full">
                        <div className="xl:w-6/12 lg:w-6/12 md:w-8/12 w-12/12">
                            <div className="block bg-white shadow-lg">
                                <div className="lg:flex-wrap g-0">
                                    <div className=" px-4 md:px-0">
                                        <div className="md:px-8 px-5 py-16">
                                            <div className="flex justify-center">
                                                <img
                                                    className="w-64 mb-8"
                                                    src="../../assets/img/webcastcloud-logo.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <h3 className="text-2xl font-semibold mb-2 mb-5">Password Reset</h3>
                                            <div className="mb-5"><label>Please create your new password.</label></div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div>
                                                    <div className="mb-4">
                                                        <label className="mb-1 block">Password</label>
                                                        <input
                                                            type="password"
                                                            className="input-style"
                                                            id="passwordHash"
                                                            {...register('passwordHash', {
                                                                required: true,
                                                                maxLength: 80,
                                                            })}
                                                            onChange={handlePassChange}
                                                        />
                                                        {errors.passwordHash?.type === 'required' && <p>Required Field</p>}
                                                        {errors.passwordHash?.type === 'maxLength' && <p>Max. length reached</p>}
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="mb-1 block">Confirm Password</label>
                                                        <input
                                                            type="password"
                                                            className="input-style"
                                                            id="confirmPasswordHash"
                                                            onChange={handleConfirmPassChange}
                                                        />
                                                        {(passCheck !== "null" && passCheck !== "true") && <p>Password does not match</p>}
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-end text-center mb-4 mt-6">
                                                    <button
                                                     disabled={passCheck == "null" || passCheck == "false" ? true : false} 
                                                     className="btn-primary m-0 px-16" 
                                                     style={{ cursor: (passCheck == "null" || passCheck == "false") ? 'not-allowed' : 'pointer' }}
                                                     type="submit" >
                                                        Reset
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ResetPassword;