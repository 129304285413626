export const TimeZones = [
    {
        value: 0,
        label: 'Select Timezone'
    },
    {
        value: 90,
        label: '(UTC+00:00) Casablanca'
    },
    {
        value: 31,
        label: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London'
    },
    {
        value: 33,
        label: '(UTC+00:00) Monrovia, Reykjavik'
    },
    {
        value: 72,
        label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
    },
    {
        value: 16,
        label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
    },
    {
        value: 53,
        label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
    },
    {
        value: 17,
        label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
    },
    {
        value: 71,
        label: '(UTC+01:00) West Central Africa'
    },
    {
        value: 84,
        label: '(UTC+01:00) Windhoek'
    },
    {
        value: 80,
        label: '(UTC+02:00) Amman'
    },
    {
        value: 34,
        label: '(UTC+02:00) Athens, Bucharest'
    },
    {
        value: 81,
        label: '(UTC+02:00) Beirut'
    },
    {
        value: 27,
        label: '(UTC+02:00) Cairo'
    },
    {
        value: 24,
        label: '(UTC+02:00) Chisinau'
    },
    {
        value: 61,
        label: '(UTC+02:00) Harare, Pretoria'
    },
    {
        value: 30,
        label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
    },
    {
        value: 38,
        label: '(UTC+02:00) Jerusalem'
    },
    {
        value: 5,
        label: '(UTC+03:00) Baghdad'
    },
    {
        value: 3,
        label: '(UTC+03:00) Kuwait, Riyadh'
    },
    {
        value: 54,
        label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd'
    },
    {
        value: 22,
        label: '(UTC+03:00) Nairobi'
    },
    {
        value: 37,
        label: '(UTC+03:30) Tehran'
    },
    {
        value: 4,
        label: '(UTC+04:00) Abu Dhabi, Muscat'
    },
    {
        value: 77,
        label: '(UTC+04:00) Baku'
    },
    {
        value: 89,
        label: '(UTC+04:00) Port Louis'
    },
    {
        value: 87,
        label: '(UTC+04:00) Tbilisi'
    },
    {
        value: 12,
        label: '(UTC+04:00) Yerevan'
    },
    {
        value: 1,
        label: '(UTC+04:30) Kabul'
    },
    {
        value: 73,
        label: '(UTC+05:00) Ashgabat, Tashkent'
    },
    {
        value: 28,
        label: '(UTC+05:00) Ekaterinburg'
    },
    {
        value: 91,
        label: '(UTC+05:00) Islamabad, Karachi'
    },
    {
        value: 36,
        label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
    },
    {
        value: 62,
        label: '(UTC+05:30) Sri Jayawardenepura'
    },
    {
        value: 46,
        label: '(UTC+05:45) Kathmandu'
    },
    {
        value: 15,
        label: '(UTC+06:00) Astana'
    },
    {
        value: 45,
        label: '(UTC+06:00) Novosibirsk'
    },
    {
        value: 44,
        label: '(UTC+06:30) Yangon (Rangoon)'
    },
    {
        value: 59,
        label: '(UTC+07:00) Bangkok, Hanoi, Jakarta'
    },
    {
        value: 50,
        label: '(UTC+07:00) Krasnoyarsk'
    },
    {
        value: 20,
        label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
    },
    {
        value: 49,
        label: '(UTC+08:00) Irkutsk'
    },
    {
        value: 60,
        label: '(UTC+08:00) Kuala Lumpur, Singapore'
    },
    {
        value: 70,
        label: '(UTC+08:00) Perth'
    },
    {
        value: 63,
        label: '(UTC+08:00) Taipei'
    },
    {
        value: 93,
        label: '(UTC+08:00) Ulaanbaatar'
    },
    {
        value: 65,
        label: '(UTC+09:00) Osaka, Sapporo, Tokyo'
    },
    {
        value: 39,
        label: '(UTC+09:00) Seoul'
    },
    {
        value: 75,
        label: '(UTC+09:00) Yakutsk'
    },
    {
        value: 13,
        label: '(UTC+09:30) Adelaide'
    },
    {
        value: 7,
        label: '(UTC+09:30) Darwin'
    },
    {
        value: 23,
        label: '(UTC+10:00) Brisbane'
    },
    {
        value: 8,
        label: '(UTC+10:00) Canberra, Melbourne, Sydney'
    },
    {
        value: 74,
        label: '(UTC+10:00) Guam, Port Moresby'
    },
    {
        value: 64,
        label: '(UTC+10:00) Hobart'
    },
    {
        value: 69,
        label: '(UTC+10:00) Vladivostok'
    },
    {
        value: 18,
        label: '(UTC+11:00) Solomon Is., New Caledonia'
    },
    {
        value: 47,
        label: '(UTC+12:00) Auckland, Wellington'
    },
    {
        value: 29,
        label: '(UTC+12:00) Fiji'
    },
    {
        value: 88,
        label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
    },
    {
        value: 66,
        label: "(UTC+13:00) Nuku'alofa'"
    },
    {
        value: 58,
        label: '(UTC+13:00) Samoa'
    },
    {
        value: 9,
        label: '(UTC-01:00) Azores'
    },
    {
        value: 11,
        label: '(UTC-01:00) Cabo Verde Is.'
    },
    {
        value: 42,
        label: '(UTC-02:00) Mid-Atlantic - Old'
    },
    {
        value: 25,
        label: '(UTC-03:00) Brasilia'
    },
    {
        value: 55,
        label: '(UTC-03:00) Cayenne, Fortaleza'
    },
    {
        value: 86,
        label: '(UTC-03:00) City of Buenos Aires'
    },
    {
        value: 32,
        label: '(UTC-03:00) Greenland'
    },
    {
        value: 82,
        label: '(UTC-03:00) Montevideo'
    },
    {
        value: 48,
        label: '(UTC-03:30) Newfoundland'
    },
    {
        value: 92,
        label: '(UTC-04:00) Asuncion'
    },
    {
        value: 6,
        label: '(UTC-04:00) Atlantic Time (Canada)'
    },
    {
        value: 76,
        label: '(UTC-04:00) Caracas'
    },
    {
        value: 78,
        label: '(UTC-04:00) Cuiaba'
    },
    {
        value: 57,
        label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
    },
    {
        value: 51,
        label: '(UTC-04:00) Santiago'
    },
    {
        value: 56,
        label: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco'
    },
    {
        value: 26,
        label: '(UTC-05:00) Eastern Time (US & Canada)'
    },
    {
        value: 67,
        label: '(UTC-05:00) Indiana (East)'
    },
    {
        value: 14,
        label: '(UTC-06:00) Central America'
    },
    {
        value: 19,
        label: '(UTC-06:00) Central Time (US & Canada)'
    },
    {
        value: 79,
        label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
    },
    {
        value: 10,
        label: '(UTC-06:00) Saskatchewan'
    },
    {
        value: 68,
        label: '(UTC-07:00) Arizona'
    },
    {
        value: 83,
        label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'
    },
    {
        value: 43,
        label: '(UTC-07:00) Mountain Time (US & Canada)'
    },
    {
        value: 85,
        label: '(UTC-08:00) Baja California'
    },
    {
        value: 52,
        label: '(UTC-08:00) Pacific Time (US & Canada)'
    },
    {
        value: 2,
        label: '(UTC-09:00) Alaska'
    },
    {
        value: 35,
        label: '(UTC-10:00) Hawaii'
    },
    {
        value: 21,
        label: '(UTC-12:00) International Date Line West'
    },
    {
        value: 40,
        label: 'Mexico Standard Time'
    },
    {
        value: 94,
        label: 'Universal Coordinated Time'
    }

]