import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollQuestionModel } from 'src/models/videosModel';
import { RootState } from 'src/store/reducers/state';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
const QuizViewResultModel = () => {
  const [scrollQuestion, setScrollQuestion] = useState<ScrollQuestionModel[]>([]);
  const { register, reset } = useForm();
  const { pollsResult, singleVideo } = useSelector((state: RootState) => {
    return {
      pollsResult: state.videos.pollsResult,
      singleVideo: state.videos.singleVideo
    };
  });
  useEffect(() => {
    if (pollsResult) {
      const scrollQuestionReset: Array<ScrollQuestionModel> = [];
      pollsResult?.quizzes?.questions?.map((item: any, index: number) => {
        scrollQuestionReset.push({ name: item.questionText, id: index + 1 });
      });
      setScrollQuestion(scrollQuestionReset);
      const resetData = {
        totalpoints: pollsResult.totalPoints,
        displayDuration: pollsResult.displayDuration,
        passRate: pollsResult.passRate,
        quizname: pollsResult.pollTitle,
        videoDuration: singleVideo.duration
      };
      reset(resetData);
    }
  }, [pollsResult]);
  return (
    <div>
      <div
        className="modal fade"
        id="QuizResultModal"
        aria-labelledby="QuizResultModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="QuizResultModalLabel">
                Quiz
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form className="px-6">
              <div className="modal-body relative py-4 border-b">
                <select
                  disabled
                  className="input-style w-full mb-5 quiz-disable"
                  {...register('pollType')}>
                  <option value={0}>Quiz</option>
                </select>

                <div className="flex items-center justify-center">
                  <div className={`form-group mb-3 w-full mr-3`}>
                    <label className="block text-sm font-medium mb-1">Video Duration</label>
                    <input
                      disabled
                      type="text"
                      className="input-style quiz-disable"
                      id="videoDuration"
                      {...register('videoDuration')}
                    />
                  </div>

                  <div className={`form-group mb-3 w-full`}>
                    <label className="block text-sm font-medium mb-1">Total Points</label>
                    <input
                      disabled
                      type="text"
                      className="input-style quiz-disable"
                      id="totalpoints"
                      {...register('totalpoints')}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-center">
                  <div className={`form-group mb-3 w-full mr-3`}>
                    <label className="block text-sm font-medium mb-1">
                      Poll Display Time (hh:mm:ss)
                    </label>
                    <input
                      disabled
                      type="text"
                      className="input-style quiz-disable"
                      id="displayDuration"
                      {...register('displayDuration')}
                    />
                  </div>

                  <div className={`form-group mb-3 w-full`}>
                    <label className="block text-sm font-medium mb-1">PassRate</label>
                    <input
                      disabled
                      type="text"
                      className="input-style quiz-disable"
                      id="passRate"
                      {...register('passRate')}
                    />
                  </div>
                </div>

                <div className="lg:grid lg:grid-cols-2 gap-6 mt-3 px-6">
                  <div className="border rounded-md">
                    <div className="px-4 py-3">
                      <div className={`form-group mb-3 w-full`}>
                        <label className="block text-sm font-medium mb-1">Quiz Name</label>
                        <input
                          disabled
                          type="text"
                          className="input-style quiz-disable"
                          id="quizname"
                          {...register('quizname')}
                        />
                      </div>

                      <div className="p-6 rounded-md shadow border bg-white max-w-sm flex flex-col">
                        <>
                          <ul>
                            {scrollQuestion &&
                              scrollQuestion.map((item: ScrollQuestionModel) => {
                                return (
                                  <li key={item.id} className="quiz-question-list">
                                    <a href={`#Question${item.id}`} data-abc="true" id="quetext1">
                                      {item.name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="border rounded-md px-4 py-4">
                    <div id="QuestionResultDiv">
                      {pollsResult &&
                        pollsResult?.quizzes?.questions?.map((item: any, index: number) => {
                          return (
                            <div id={`Question${index + 1}`} className="mt-4" key={item.id}>
                              <div className="mt-1">
                                {item.quizOpenTextQuestion ? (
                                  <>
                                    <div className="flex justify-between">
                                      <div>
                                        <h5 className="font-bold">
                                          {item.position}. {item.questionText}
                                        </h5>
                                      </div>
                                      <div className="badge-pollAttended flex justify-center">
                                        {pollsResult?.quizzes?.pollAttended}
                                      </div>
                                    </div>
                                    <>
                                      <div className=" mt-2 font-bold ">
                                        <div>
                                          <label className="">Answer: {item.textAnswer}</label>
                                        </div>
                                        <div className="mt-2">
                                          <label>Description: {item.description}</label>
                                        </div>
                                      </div>
                                      <div className="border  mt-2">
                                        <ul className="">
                                          {pollsResult &&
                                            pollsResult?.pollResults?.map((item: any) => {
                                              return (
                                                <div key={item.id} className="border-b-2 p-5">
                                                  <div className="flex justify-between">
                                                    <div>
                                                      <p>{item.username} :</p>
                                                      <li>
                                                        {
                                                          item?.resultDetails[0]
                                                            ?.submittedAnswerText
                                                        }
                                                      </li>
                                                    </div>
                                                    <div className="cursor-pointer">
                                                      {item?.resultDetails[0]?.questionFileUrl && (
                                                        <i
                                                          onClick={() =>
                                                            saveAs(
                                                              item?.resultDetails[0]
                                                                ?.questionFileUrl
                                                            )
                                                          }
                                                          className="color fa fa-download h6 "></i>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <div className="flex justify-between">
                                        <div>
                                          <h5 className="font-bold">
                                            {item.position}. {item.questionText}
                                          </h5>
                                        </div>
                                        <div className="badge-pollAttended flex justify-center">
                                          {pollsResult?.quizzes?.pollAttended}
                                        </div>
                                      </div>
                                      {item.choices &&
                                        item.choices.map((choice: any) => {
                                          return (
                                            <div key={choice.id}>
                                              <div
                                                className={`px-2 py-3 ${
                                                  choice.isAnswer ? 'pollResult-background' : ''
                                                }`}>
                                                <span className="">
                                                  {choice.answerText} &nbsp;&nbsp;(
                                                  {choice.votedPercentage}%, {choice.votedCount}{' '}
                                                  Votes)
                                                </span>
                                                <div
                                                  className={`w-full h-3 rounded-full mt-4 bg-gray-100`}>
                                                  <div
                                                    className={` bg-green-400 text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                                                    style={{
                                                      width: `${choice.votedPercentage}%`
                                                    }}></div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </>
                                )}

                                <>
                                  {' '}
                                  <div className="flex mt-2 font-bold ">
                                    <div className="">
                                      <label className="">Points</label>
                                    </div>
                                    <div className="ml-7">
                                      <label>{item.points}</label>
                                    </div>
                                  </div>
                                </>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizViewResultModel;
