import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { successMsg, errorMsg, warningMsg, infoMsg } from 'src/components/utilities/utils';
import {
  CustomFieldsModel,
  DeleteRegistrationFieldModal,
  RegistrationFieldModel
} from 'src/models/eventModel';
import ToastMessage from 'src/components/ToastContainer';
import DeleteRegistrationModal from 'src/components/admin/events/components/DeleteRegistrationModal';
const RegistrationFields: React.FC = () => {
  const dispatch = useDispatch();
  const { eventId } = useParams<string>();
  const [eventType, setEventType] = useState<string | number>('1');
  const eventActions = useEventActions(dispatch);
  const [duplicateCheck, setDuplicateCheck] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<DeleteRegistrationFieldModal>();
  const {
    registration,
    isRegistrationFieldSaved,
    isRegistrationFieldDeleted,
    isRegistrationFieldUpdated,
    message
  } = useSelector((state: RootState) => {
    return {
      registration: state.event.registration,
      isRegistrationFieldSaved: state.event.isRegistrationFieldSaved,
      message: state.event.message,
      isRegistrationFieldDeleted: state.event.isRegistrationFieldDeleted,
      isRegistrationFieldUpdated: state.event.isRegistrationFieldUpdated
    };
  });
  const { reset } = useForm<CustomFieldsModel>();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegistrationFieldModel>();

  useEffect(() => {
    eventActions.getEventRegistrationsRequest(eventId);
  }, []);
  useEffect(() => {
    if (isRegistrationFieldSaved || isRegistrationFieldDeleted || isRegistrationFieldUpdated)
      eventActions.getEventRegistrationsRequest(eventId);
    if (isRegistrationFieldUpdated === true) {
      infoMsg(message);
      eventActions.resetEventStateRequest();
    } else if (isRegistrationFieldDeleted === true) errorMsg(message);
    else if (isRegistrationFieldSaved === true) successMsg(message);
  }, [eventId, isRegistrationFieldSaved, isRegistrationFieldDeleted, isRegistrationFieldUpdated]);

  useEffect(() => {
    if (isRegistrationFieldSaved === true) {
      reset({
        id: 0,
        eventid: '',
        description: '',
        required: true,
        type: 0,
        isenable: true,
        isdefault: true,
        alwaysrequired: true,
        passcoderequiredonlogin: true,
        passcodes: ''
      });
    }
  }, [isRegistrationFieldSaved]);
  const onSubmit = (data: any) => {
    registration.customFields.forEach((customFieldData: any) => {
      if (customFieldData.description == data.description) {
        errorMsg('Given name is already exists');
        setDuplicateCheck(true);
      }
    });
    {
      duplicateCheck;
      const insertData = {
        ...data,
        eventId
      };
      eventActions.saveRegistrationFieldRequest(insertData);
    }
  };
  const handleChangeisRequired = (
    isRequiredValue: boolean,
    registrationFieldID: number | string
  ) => {
    const patchisRequiredData = {
      registration_field_id: registrationFieldID,
      isrequired: isRequiredValue
    };
    eventActions.updateIsRequiredRequest(patchisRequiredData);
  };
  const handleChangeisEnable = (isEnableValue: boolean, registrationFieldId: number | string) => {
    const patchIsEnableData = {
      registration_field_id: registrationFieldId,
      isEnable: isEnableValue
    };
    eventActions.updateIsEnableRequest(patchIsEnableData);
  };
  const handleChangeisPasscodeRequired = (
    isPasscodeRequiredValue: boolean,
    registrationFieldId: number | string
  ) => {
    const patchIsPasscodeRequiredData = {
      registration_field_id: registrationFieldId,
      isPasscodeRequired: isPasscodeRequiredValue
    };
    eventActions.updateIsPasscodeRequiredRequest(patchIsPasscodeRequiredData);
  };
  const formatEventType = (eventType: number) => {
    if (eventType === 1) return 'Textbox';
    else if (eventType === 2) return 'Checkbox';
    else if (eventType === 3) return 'Passcode';
    else return 'None';
  };
  return (
    <>
      <ToastMessage />
      <div className="tab-pane fade " id="tabs-registrationFields" role="tabpanel">
        <div className="py-5 px-7">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1">Name</label>
                <input
                  type="text"
                  className="input-style"
                  {...register('description', {
                    required: true
                  })}
                />
                {errors.description?.type === 'required' && (
                  <p className="text-red-700"> Required Field</p>
                )}
              </div>
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1">Type</label>
                <select
                  className="input-style"
                  {...register('type', {
                    required: true
                  })}
                  onChange={(event) => {
                    setEventType(event.target.value);
                  }}>
                  <option value={1}>Textbox</option>
                  <option value={2}>Checkbox</option>
                  <option value={3}>Passcode</option>
                </select>
                {errors.type?.type === 'required' && (
                  <p className="text-red-700"> Required Field</p>
                )}
              </div>
              <div className={`form-group form-switch`}>
                <label className="block mb-1">Required</label>
                <input
                  type="checkbox"
                  className="form-check-input switch-btn"
                  id="alwaysrequired"
                  defaultChecked
                  {...register('alwaysrequired', {
                    required: false
                  })}
                />
              </div>
            </div>
            {eventType === '3' && (
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group form-switch`}>
                  <label className="block mb-1">Display passcode on Login</label>
                  <input
                    type="checkbox"
                    className="form-check-input switch-btn"
                    id="passcoderequiredonlogin"
                    {...register('passcoderequiredonlogin')}
                  />
                </div>
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Enter Passcode</label>
                  <input
                    type="text"
                    id="passcodes"
                    className="input-style"
                    {...register('passcodes')}
                  />
                </div>
              </div>
            )}
            <div className="text-right">
              <button
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#AddNewTag"
                className="btn-primary w-auto mt-0 mr-0 ml-auto mb-4 borderonly-btn">
                Add
              </button>
            </div>
          </form>
          <div>
            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-100">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Type</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-right">Required</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-right">Enable</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">
                              Display passcode on Login
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">Passcode</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">Delete</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        <>
                          {registration.customFields ? (
                            <>
                              {registration.customFields &&
                                registration.customFields.map((customField, index) => {
                                  return (
                                    <>
                                      {(customField.ishiddenfield === null ||
                                        !customField.ishiddenfield) && (
                                        <tr key={index}>
                                          <td className="p-2">
                                            <div className="text-left">
                                              {customField.description}
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-left">
                                              {formatEventType(customField.type)}
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-center">
                                              <div className="form-switch ">
                                                <input
                                                  className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                        focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                  type="checkbox"
                                                  role="switch"
                                                  checked={customField.required === true}
                                                  onChange={(e) =>
                                                    handleChangeisRequired(
                                                      e.target.checked,
                                                      customField.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-center">
                                              <div className="form-switch ">
                                                <input
                                                  className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                        focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                  type="checkbox"
                                                  role="switch"
                                                  checked={customField.isenable}
                                                  onChange={(e) =>
                                                    handleChangeisEnable(
                                                      e.target.checked,
                                                      customField.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            {customField.type === 5 && (
                                              <div className="text-center">
                                                <div className="form-switch ">
                                                  <input
                                                    className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                          focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={customField.passcoderequiredonlogin}
                                                    onChange={(e) =>
                                                      handleChangeisPasscodeRequired(
                                                        e.target.checked,
                                                        customField.id
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </td>
                                          <td className="p-2">
                                            <div className="text-left">{customField.passcodes}</div>
                                          </td>
                                          <td className="p-2">
                                            {!customField.isdefault && (
                                              <div className="text-center">
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#DeleteRegistrationModal"
                                                  className="action-menu flex items-center cursor-pointer"
                                                  onClick={() =>
                                                    setDeleteData({
                                                      regsitrationFieldId: customField.id,
                                                      eventName: customField.description
                                                    })
                                                  }>
                                                  <i className="fas fa-trash w-6"></i>
                                                </span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center py-2" colSpan={7}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                    <p className="font-bold mt-3">Hidden Fields</p>
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-100">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Type</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-right">Required</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-right">Enable</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">
                              Display passcode on Login
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">Passcode</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-center">Delete</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        <>
                          {registration.customFields ? (
                            <>
                              {registration.customFields &&
                                registration.customFields.map((customField, index) => {
                                  return (
                                    <>
                                      {customField.ishiddenfield && (
                                        <tr key={index}>
                                          <td className="p-2">
                                            <div className="text-left">
                                              {customField.description}
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-left">
                                              {formatEventType(customField.type)}
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-center">
                                              <div className="form-switch ">
                                                <input
                                                  className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                        focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                  type="checkbox"
                                                  role="switch"
                                                  checked={customField.required === true}
                                                  onChange={(e) =>
                                                    handleChangeisRequired(
                                                      e.target.checked,
                                                      customField.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            <div className="text-center">
                                              <div className="form-switch ">
                                                <input
                                                  className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                        focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                  type="checkbox"
                                                  role="switch"
                                                  checked={customField.isenable}
                                                  onChange={(e) =>
                                                    handleChangeisEnable(
                                                      e.target.checked,
                                                      customField.id
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </td>
                                          <td className="p-2">
                                            {customField.type === 5 && (
                                              <div className="text-center">
                                                <div className="form-switch ">
                                                  <input
                                                    className="form-check-input switch-btn appearance-none w-9 rounded-full float-none h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 
                                          focus:outline-none cursor-pointer shadow-sm filter-none opacity-75"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={customField.passcoderequiredonlogin}
                                                    onChange={(e) =>
                                                      handleChangeisPasscodeRequired(
                                                        e.target.checked,
                                                        customField.id
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </td>
                                          <td className="p-2">
                                            <div className="text-left">{customField.passcodes}</div>
                                          </td>
                                          <td className="p-2">
                                            {!customField.isdefault && (
                                              <div className="text-center">
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#DeleteRegistrationModal"
                                                  className="action-menu flex items-center cursor-pointer"
                                                  onClick={() =>
                                                    setDeleteData({
                                                      regsitrationFieldId: customField.id,
                                                      eventName: customField.description
                                                    })
                                                  }>
                                                  <i className="fas fa-trash w-6"></i>
                                                </span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center py-2" colSpan={7}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteRegistrationModal deleteData={deleteData} />
    </>
  );
};

export default RegistrationFields;
