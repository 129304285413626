import React, { useEffect, useState } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { CSVLink } from 'react-csv';
import Select from 'react-select';
import { useUserActions } from 'src/store/users/actions';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { SelectModel } from 'src/models/videosModel';
import { VideoAnalyticsFilterModel } from 'src/models/videosModel';
import AreaChart from 'src/components/charts/areachart/AreaChart';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import LiveInfoAnalytics from './LiveInfoAnalytics';
import { errorMsg } from 'src/components/utilities/utils';
import { customFilterOption } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
const VideoInfoAnalytics = () => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const { id } = useParams<string>();
  const [downloadData, setDownloadData] = useState<any>();
  const [finalMemberList, setFinalMemberList] = useState<SelectModel[]>();
  const videoAction = useVideosActions(dispatch);
  const [showFilter, setShowFilter] = useState(false);
  const [finalRoles, setFinalRoles] = useState<SelectModel[]>();
  const [showTabFlag, setShowTabFlag] = useState<string>('all');
  const [filterMenu, setFilterMenu] = useState<string>('userName');

  const {
    videoAnalyticsData,
    isVideoAnalyticsDataLoading,
    singleVideo,
    videoAnalyticsChartData,
    roles,
    memberList,
    message,
    isVideoAnalyticsUpdated,
    error,
    paginationMeta,
    videoAnalyticsTable,
    videoAnalyticsTableDownload,
    isVideoAnalyticsTableLoading
  } = useSelector((state: RootState) => {
    return {
      videoAnalyticsData: state.videos.videoAnalyticsData,
      isVideoAnalyticsDataLoading: state.videos.isVideoAnalyticsDataLoading,
      singleVideo: state.videos.singleVideo,
      videoAnalyticsChartData: state.videos.videoAnalyticsChartData,
      roles: state.users.roles,
      memberList: state.users.memberList,
      message: state.videos.message,
      isVideoAnalyticsUpdated: state.videos.isVideoAnalyticsUpdated,
      error: state.videos.error,
      paginationMeta: state.videos.paginationMeta,
      videoAnalyticsTable: state.videos.videoAnalyticsTable,
      videoAnalyticsTableDownload: state.videos.videoAnalyticsTableDownload,
      isVideoAnalyticsTableLoading: state.videos.isVideoAnalyticsTableLoading
    };
  });
  useEffect(() => {
    if (memberList && memberList.length) {
      const options = memberList.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setFinalMemberList([{ value: '', label: 'Select Member Type' }, ...options]);
    }
  }, [memberList]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm<VideoAnalyticsFilterModel>();
  const [areaChartData, setAreaChartData] = useState({
    labels: [''],
    datasets: [
      {
        fill: true,
        lineTension: 0.4,
        label: '',
        data: [1],
        borderColor: '',
        backgroundColor: ''
      }
    ]
  });

  const handlePageChange = (pageNumber: number) => {
    const payloadData = { id, pageNumber: pageNumber, pageSize: 20 };
    videoAction.getVideoAnalyticsTableRequest(payloadData);
  };
  useEffect(() => {
    if (videoAnalyticsChartData) {
      setAreaChartData({
        labels: videoAnalyticsChartData && videoAnalyticsChartData.map((data) => data.date),
        datasets: [
          {
            fill: true,
            lineTension: 0.4,
            label: 'On-Demand Views',
            data:
              videoAnalyticsChartData && videoAnalyticsChartData.map((data) => data.onDemandViews),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgb(53, 162, 235)'
          },
          {
            fill: true,
            lineTension: 0.4,
            label: 'Live Views',
            data: videoAnalyticsChartData && videoAnalyticsChartData.map((data) => data.liveViews),
            borderColor: 'rgb(123,51,206)',
            backgroundColor: 'rgb(123,51,206)'
          },
          {
            fill: true,
            lineTension: 0.4,
            label: 'Total Views',
            data: videoAnalyticsChartData && videoAnalyticsChartData.map((data) => data.totalViews),
            borderColor: 'rgb(255,99,132)',
            backgroundColor: 'rgb(255,99,132)'
          }
        ]
      });
    }
  }, [videoAnalyticsChartData]);
  useEffect(() => {
    if (id) {
      const payloadData = { id, pageNumber: 1, pageSize: 20 };
      videoAction.getVideoAnalyticsDataRequest(id);
      videoAction.getVideoAnalyticsChartDataRequest(id);
      videoAction.getVideoAnalyticsTableRequest(payloadData);
      videoAction.getVideoAnalyticsTableDownloadRequest(payloadData);
    }
  }, []);

  useEffect(() => {
    if (
      videoAnalyticsTableDownload &&
      videoAnalyticsTableDownload.length &&
      videoAnalyticsData &&
      singleVideo
    ) {
      const csvData =
      videoAnalyticsTableDownload &&
      videoAnalyticsTableDownload.map((analytics) => {
        return [
          analytics.firstName,
          analytics.lastName,
          analytics.email,
          analytics.userName,
          analytics.totalWatchedDuration,
          analytics.percentageWatched,
          analytics.liveViews,
          analytics.onDemandViews,
          (analytics.liveViews + analytics.onDemandViews),
          analytics.firstWatch,
          analytics.lastWatch
        ];
      });
    const finalDownloadData = [
      [`Title:`, singleVideo && singleVideo.title],
      [`Date:`, singleVideo.recordDate],
      [`Total Live Views:`, videoAnalyticsData.liveViews],
      [`Total Ondemand Views:`, videoAnalyticsData.onDemandViews],
      [`Total Unique Views:`, videoAnalyticsData.uniqueViews],
      [`Total Views:`, videoAnalyticsData.totalViews],
      [`First Watch:`, videoAnalyticsData.firstWatch],
      [`Last Watch:`, videoAnalyticsData.lastWatch],
      [`Total Time Watched:`, videoAnalyticsData.totalWatchedDuration],
      [`Average Time Watched:`, videoAnalyticsData.averageWatchedDuration],
      [`Last Update:`, videoAnalyticsData.lastModifiedDate],
      [``],
      [
        'First Name',
        'Last Name',
        'Email',
        'UserName',
        'Total Watched Duration',
        'Percentage Watched',
        'Live Views',
        'OnDemand Views',
        'Total Views',
        'First Watch',
        'Last Watch'
      ],
      ...csvData
    ];
      setDownloadData(finalDownloadData);
    }
  }, [videoAnalyticsTableDownload, videoAnalyticsData, singleVideo]);

  const handleUpdateClick = () => {
    if (id) {
      videoAction.updateVideoAnalyticsRequest(id);
    }
  };

  useEffect(() => {
    if (isVideoAnalyticsUpdated) {
      successMsg(message);
      videoAction.resetVideoLibraryRequest();
    }
  }, [isVideoAnalyticsUpdated]);

  useEffect(() => {
    if (id) {
      videoAction.getSingleVideosRequest(id);
    }
  }, [id]);

  const handleFilterClick = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };
  useEffect(() => {
    if (showFilter && !memberList.length && !roles.length) {
      userAction.getRolesRequest();
      userAction.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
    }
  }, [showFilter]);
  useEffect(() => {
    if (roles && roles.length) {
      const options = roles.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setFinalRoles(options);
    }
  }, [roles]);
  useEffect(() => {
    if (memberList && memberList.length) {
      const options = memberList.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setFinalMemberList([{ value: '', label: 'Select Member Type' }, ...options]);
    }
  }, [memberList]);

  const onSubmit = (data: any) => {
    const roleIds = data.RoleIds && data.RoleIds.map((item: any) => item.value);
    const payloadData = {
      ...data,
      id,
      pageNumber: 1,
      MemberTypesId: data.MemberTypesId?.value,
      RoleIds: roleIds
    };
    videoAction.getVideoAnalyticsTableRequest(payloadData);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      videoAction.resetVideoLibraryRequest();
    }
  }, [error]);

  const getDarkBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 31, 31, 1)'
      : perc < 50
      ? 'rgba(255, 165, 31, 1)'
      : perc < 75
      ? 'rgba(31, 143, 255, 1)'
      : 'rgba(31, 255, 31, 1)';
  };
  const getLightBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 198, 184, 1)'
      : perc < 50
      ? 'rgba(255, 216, 158, 1)'
      : perc < 75
      ? 'rgba(191, 219, 254, 1)'
      : 'rgba(213, 255, 199, 1)';
  };
  return (
    <>
      <div>
        <ToastMessage />
        {videoAnalyticsData && (
          <div className="dashboard-card my-5 py-4">
            <Link to={`/videos/${id}`}>
              <i className="fas fa-arrow-left"></i>
              <span className="ml-2">Go Back</span>
            </Link>
            <div className="lg:flex items-center justify-between">
              <h2 className="font-semibold mb-4">{singleVideo && singleVideo.title}</h2>
              <div>
                <div className="md:flex items-center">
                  <p className="mr-1">
                    Last Update:
                    {videoAnalyticsData.lastUpdated ? videoAnalyticsData.lastUpdated : ''}
                  </p>
                  <button
                    type="button"
                    onClick={handleUpdateClick}
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn mb-3 md:mb-0">
                    Run Update
                  </button>
                  {/* <button
                    type="submit"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Analytics
                  </button> */}
                  <div className="flex items-center">
                    <div className="dropdown relative">
                      <button
                        className="dropdown-toggle border input-style w-32 mr-3 flex-items export-dropdown"
                        type="button"
                        id="action-dropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span className="flex">Analytics </span>
                        <img src="../../assets/img/down-icon.svg" className="w-4 h-4" />
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                        aria-labelledby="action-dropdown">
                        <li onClick={() => setShowTabFlag('live')} className="text-center">
                          <span>Live Views</span>
                        </li>
                        <li onClick={() => setShowTabFlag('all')} className="text-center">
                          <span>All Views</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <p className="mt-2 text-right">
                  {videoAnalyticsData.queueToProcess
                    ? 'There are new views.  Run Update to retrieve latest analytics.'
                    : 'There are no new views after the last analytics run.'}
                </p>
              </div>
            </div>
            {showTabFlag === 'all' ? (
              <div>
                <div className="my-6">
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                    <div className="analytics-box navy-bg flex justify-between">
                      <span>Total Views</span> <span>{videoAnalyticsData.totalViews}</span>
                    </div>
                    <div className="analytics-box purple-bg flex justify-between">
                      <span>Live Viewers</span> <span>{videoAnalyticsData.liveViews}</span>
                    </div>
                    <div className="analytics-box light-green-bg flex justify-between">
                      <span>OnDemand</span> <span> {videoAnalyticsData.onDemandViews}</span>
                    </div>
                    <div className="analytics-box navy-bg flex justify-between">
                      <span>Unique Viewers</span> <span>{videoAnalyticsData.uniqueViews}</span>
                    </div>
                  </div>
                  <div>
                    {downloadData && (
                      <CSVLink
                        className="export"
                        data={downloadData}
                        filename={'VideoAnalytics.csv'}>
                        <button
                          type="submit"
                          className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                          Download
                        </button>
                      </CSVLink>
                    )}
                  </div>
                </div>

                <div>
                  <div>
                    {singleVideo && singleVideo.recordDate && (
                      <p>
                        <i>Date: {singleVideo.recordDate ? singleVideo.recordDate : ''}</i>
                      </p>
                    )}
                    {videoAnalyticsData.firstWatch && (
                      <p>
                        First Watch:{' '}
                        {videoAnalyticsData.firstWatch ? videoAnalyticsData.firstWatch : ''}
                      </p>
                    )}
                    {videoAnalyticsData.lastWatch && (
                      <p>
                        Last Watch:{' '}
                        {videoAnalyticsData.lastWatch ? videoAnalyticsData.lastWatch : ''}
                      </p>
                    )}
                    {videoAnalyticsData.totalWatchedDuration && (
                      <p>Total Time Watched: {videoAnalyticsData.totalWatchedDuration}</p>
                    )}
                    {videoAnalyticsData.averageWatchedDuration && (
                      <p>Average Watched: {videoAnalyticsData.averageWatchedDuration}</p>
                    )}
                  </div>
                </div>
                <div>
                  <AreaChart chartData={areaChartData} />
                </div>

                <div className={`mt-5`}>
                  <div>
                    <button
                      type="button"
                      className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
                      onClick={handleFilterClick}>
                      <i className="fa fa-filter" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div className={`mb-5  ${showFilter ? '' : 'hidden'}`}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="md:flex flex-wrap items-center mb-4">
                        <div className="lg:pr-4 xl:w-1/4 lg:w-1/2 w-full">
                          <div className={`form-group lg:mt-6 mt-3 w-full`}>
                            <input
                              className="input-style"
                              type="text"
                              placeholder="Username/email"
                              {...register('UserNameOrEmail')}
                            />
                          </div>
                        </div>
                        <div className="lg:pr-4 xl:w-1/4 lg:w-1/2 w-full">
                          <div className={`form-group lg:mt-6 mt-3 w-full`}>
                            {finalRoles && Object.keys(finalRoles).length && (
                              <Controller
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error }
                                }) => (
                                  <Select
                                    filterOption={customFilterOption}
                                    placeholder="Roles"
                                    options={finalRoles}
                                    isMulti
                                    value={value}
                                    id="RoleIds"
                                    onChange={(val) => onChange(val)}
                                  />
                                )}
                                name="RoleIds"
                                control={control}
                              />
                            )}
                          </div>
                        </div>

                        <div className="lg:pr-4 xl:w-1/4 lg:w-1/2 w-full">
                          <div className={`form-group lg:mt-6 mt-3 w-full`}>
                            {finalMemberList && Object.keys(finalMemberList).length && (
                              <Controller
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error }
                                }) => (
                                  <Select
                                    filterOption={customFilterOption}
                                    placeholder="Select Member Type"
                                    options={finalMemberList}
                                    value={value}
                                    id="MemberTypesId"
                                    onChange={(val) => onChange(val)}
                                  />
                                )}
                                name="MemberTypesId"
                                control={control}
                              />
                            )}
                          </div>
                        </div>

                        <div className="lg:pr-4 xl:pr-0 xl:w-1/4 lg:w-1/2 w-full">
                          <div className={`form-group w-full lg:mt-6 mt-3`}>
                            <button
                              type="submit"
                              className="btn-primary lg:w-full mt-0 w-56 ml-0 borderonly-btn">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <select className="input-style" onChange={(e) => setFilterMenu(e.target.value)}>
                      <option value="userName">UserName</option>
                      <option value="email">Email</option>
                      <option value="fullName">FullName</option>
                    </select>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="table-auto w-full my-6">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">
                            {filterMenu === 'userName'
                              ? 'User Name'
                              : filterMenu === 'email'
                              ? 'Email'
                              : filterMenu === 'fullName'
                              ? 'Full Name'
                              : 'User Name'}
                          </div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Live Views</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">OnDemand Views</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total Time Watched</div>
                        </th>

                        <th className="p-2">
                          <div className="font-semibold text-left">First Watch</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Last Watch</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Credits Achieved</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Credits Sent</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Percentage Watched</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-center">
                            <p>OnDemand Milestones</p>
                            <div className="flex gap-2">
                              <div className="w-10 mx-auto text-center">25%</div>
                              <div className="w-10 mx-auto text-center">50%</div>
                              <div className="w-10 mx-auto text-center">75%</div>
                              <div className="w-10 mx-auto text-center">90%</div>
                              <div className="w-10 mx-auto text-center">100%</div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isVideoAnalyticsTableLoading && (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      )}
                      {videoAnalyticsTable &&
                        videoAnalyticsTable.map((item, index) => {
                          return (
                            <tr key={index} className="border-b">
                              <td className="p-2">
                                <div className="text-left">
                                  {' '}
                                  {filterMenu === 'userName'
                                    ? `${item.userName}`
                                    : filterMenu === 'email'
                                    ? `${item.email}`
                                    : filterMenu === 'fullName'
                                    ? `${item.firstName} ${item.lastName}`
                                    : `${item.userName}`}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">{item.liveViews}</div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">{item.onDemandViews}</div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">{item.totalWatchedDuration}</div>
                              </td>

                              <td className="p-2">
                                <div className="text-left">
                                  {item.firstWatch ? defaultDateFormatter(item.firstWatch) : ''}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  {item.lastWatch ? defaultDateFormatter(item.lastWatch) : ''}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  <div className="text-left">{item.creditPointsAchieved}</div>
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  <div className="text-left">
                                    {item.creditsSent ? (
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td className="p-2">
                                <div className="text-left">
                                  {item.percentageWatched}%
                                  <div
                                    className="w-full bg-blue-200 h-1 rounded-md mt-2"
                                    style={{
                                      backgroundColor: `${getLightBgColorStr(
                                        item.percentageWatched
                                      )}`
                                    }}>
                                    <div
                                      className="bg-blue-600 h-1 rounded-md"
                                      style={{
                                        width: `${item.percentageWatched}%`,
                                        backgroundColor: `${getDarkBgColorStr(
                                          item.percentageWatched
                                        )}`
                                      }}></div>
                                  </div>
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="flex gap-2">
                                  {item.milestone25 ? (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    </div>
                                  ) : (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                    </div>
                                  )}
                                  {item.milestone50 ? (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    </div>
                                  ) : (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                    </div>
                                  )}
                                  {item.milestone75 ? (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    </div>
                                  ) : (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                    </div>
                                  )}
                                  {item.milestone90 ? (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    </div>
                                  ) : (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                    </div>
                                  )}
                                  {item.milestone100 ? (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                    </div>
                                  ) : (
                                    <div className="w-10 mx-auto text-center">
                                      <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            ) : (
              <LiveInfoAnalytics />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default VideoInfoAnalytics;
