import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import FormTextareaField from 'src/components/common/formTextareaField';
import { errorMsg, successMsg } from 'src/components/utilities/utils';

const ColorBackground = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const BodyBackgroundInputFile = useRef<HTMLInputElement>(null);
  const [bodyBackgroundImageName, setBodyBackgroundImageName] = useState<string>('');
  const [bodyBackgroundImageUrl, setBodyBackgroundImageUrl] = useState<string>();
  const [removeBodyBackgroundImage, setRemoveBodyBackgroundImage] = useState<boolean>(false);
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const { colors, isAppearanceUpdated } = useSelector((state: RootState) => {
    return {
      colors: state.appearance.colors,
      isAppearanceUpdated: state.appearance.isAppearanceUpdated
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    if (colors) {
      const resetData = {
        ...colors,
        authButtonBGHex: '#' + colors.authButtonBGHex,
        headerBackgroundHex: '#' + colors.headerBackgroundHex,
        stickyHeaderBackgroundHex: '#' + colors.stickyHeaderBackgroundHex,
        headerBottomBorderColor: '#' + colors.headerBottomBorderColor,
        menuFontHex: '#' + colors.menuFontHex,
        backgroundHex: '#' + colors.backgroundHex
      };
      setBodyBackgroundImageUrl(colors.bodyBackgroundImageUrl);
      reset(resetData);
    }
  }, [colors]);
  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'authButtonBGHex', value: data.authButtonBGHex.replace('#', '') },
      {
        op: 'replace',
        path: 'headerBackgroundHex',
        value: data.headerBackgroundHex.replace('#', '')
      },
      {
        op: 'replace',
        path: 'stickyHeaderBackgroundHex',
        value: data.stickyHeaderBackgroundHex.replace('#', '')
      },
      {
        op: 'replace',
        path: 'headerBottomBorderColor',
        value: data.headerBottomBorderColor.replace('#', '')
      },
      { op: 'replace', path: 'menuFontHex', value: data.menuFontHex.replace('#', '') },
      { op: 'replace', path: 'backgroundHex', value: data.backgroundHex.replace('#', '') },
      { op: 'replace', path: 'bodyHtml', value: data.bodyHtml }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  const handleRemoveBodyBackgroundImage = () => {
    const payloadData = [{ op: 'replace', path: 'bodyBackgroundImageUrl', value: '' }];
    appearanceAction.updateAppearanceRequest(payloadData);
  };
  const handleSelectBodyBackgroundClick = () => {
    if (BodyBackgroundInputFile.current) {
      BodyBackgroundInputFile.current.click();
    }
  };

  const handleBodyBackgroundImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      setBodyBackgroundImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/layout/upload/bodybackground?`,
        setBodyBackgroundImageUrl,
        'customize',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadBodyBackgroundImageToApi
      );
    }
  };

  const uploadBodyBackgroundImageToApi = (bodyBackgroundImage: string) => {
    const payloadData = [
      { op: 'replace', path: 'bodyBackgroundImageUrl', value: bodyBackgroundImage }
    ];
    appearanceAction.updateAppearanceRequest(payloadData);
  };

  useEffect(() => {
    if (isAppearanceUpdated) {
      appearanceAction.getColorsRequest();
      appearanceAction.resetAppearanceStateRequest();
      setBodyBackgroundImageName('');
      setRemoveBodyBackgroundImage(false);
    }
  }, [isAppearanceUpdated]);

  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);
  return (
    <div className="tab-pane fade" id="tabs-colorAndBackground" role="tabpanel">
      <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-0 ml-auto ">
          Save
        </button>
        {isUploadToS3Loading && <Loader />}
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Login Button Background Color</h6>
              <p>Set the background color of the login button across the video library.</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <input
                placeholder="#000000"
                type="color"
                id="authButtonBGHex"
                {...register('authButtonBGHex')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Header Background Color</h6>
              <p>Set the background color of the navigation header across the video library.</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <input
                placeholder="#000000"
                type="color"
                id="headerBackgroundHex"
                {...register('headerBackgroundHex')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Sticky Header Background Color</h6>
              <p>Sticky background color of the navigation header when scrolling</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <input
                placeholder="#000000"
                type="color"
                id="stickyHeaderBackgroundHex"
                {...register('stickyHeaderBackgroundHex')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Header Border Bottom Color</h6>
              <p>Set the color of the header border bottom.</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div className="grid">
              <div className="mb-2 flex items-center">
                <input className="rounded-sm shadow" type="checkbox" />
                <span className="font-semibold ml-2">Transparent</span>
              </div>

              <input
                placeholder="#000000"
                type="color"
                id="headerBottomBorderColor"
                {...register('headerBottomBorderColor')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Menu/Navigation Font Color</h6>
              <p>Set the color for navigation font.</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <input
                placeholder="#000000"
                type="color"
                id="menuFontHex"
                {...register('menuFontHex')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Body Background Color</h6>
              <p>Set the background color for the body of the video library.</p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <input
                placeholder="#000000"
                type="color"
                id="backgroundHex"
                {...register('backgroundHex')}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Body Background Image</h6>
              <p>Set the background image for the body of the video library.</p>
            </div>
          </div>
          {/* <div className="lg:w-1/2 pb-0">
            <div className="video-upload-btn flex-items">
              <label
                htmlFor="bodyBgImage"
                className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                Upload
              </label>
            </div>
          </div> */}
          <div className="lg:w-1/2 pb-0">
            <div>
              <div className="video-upload-btn flex-items mt-1">
                <div className="xl:w-8/12 lg:w-1/2">
                  <div>
                    {colors &&
                      colors.bodyBackgroundImageUrl?.length !== 0 &&
                      colors.bodyBackgroundImageUrl !== null && (
                        <>
                          <img
                            src={`${colors && colors.bodyBackgroundImageUrl}`}
                            className={` ${removeBodyBackgroundImage ? 'hidden' : ''}`}
                          />
                        </>
                      )}
                    {bodyBackgroundImageUrl && (
                      <span
                        onClick={() => {
                          setBodyBackgroundImageUrl(''),
                            setRemoveBodyBackgroundImage(true),
                            handleRemoveBodyBackgroundImage(),
                            setBodyBackgroundImageName('');
                        }}
                        className="cursor-pointer site-clr">
                        Remove
                      </span>
                    )}
                  </div>
                  <div className="flex items-center">
                    <div>
                      <div className="relative">
                        <label
                          onClick={handleSelectBodyBackgroundClick}
                          className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                          Upload
                        </label>
                        <span className="ml-2">
                          {bodyBackgroundImageName ? bodyBackgroundImageName : 'No file chosen'}
                        </span>
                        <input
                          ref={BodyBackgroundInputFile}
                          type="file"
                          className="hidden"
                          onChange={(e) => handleBodyBackgroundImageUpload(e.target.files)}
                          onClick={onInputClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Header Script</h6>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <FormTextareaField
                label={''}
                fieldName={'background.customBodyHtmlCss'}
                register={register}
                errors={errors}
                isRequired={false}
              />
            </div>
          </div>
        </div>
        <div className="lg:flex mb-8">
          <div className="lg:w-1/2 pb-0">
            <div>
              <h6 className="font-bold mb-1">Custom Body HTML/CSS</h6>
              <p>
                Use the field to insert custom css into the video home html or css into the video
                home.
              </p>
            </div>
          </div>
          <div className="lg:w-1/2 pb-0">
            <div>
              <FormTextareaField
                label={''}
                fieldName={'bodyHtml'}
                register={register}
                errors={errors}
                isRequired={false}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ColorBackground;
