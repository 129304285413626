import React, { useEffect, useRef, useState } from 'react';
import Loader from 'src/components/loader';
import { useDispatch, useSelector } from 'react-redux';
import { defaultDateFormatter } from '../../../utilities/utils';
import { RootState } from 'src/store/reducers/state';
import { useChannelActions } from 'src/store/channel/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import VideoSearch from './VideoSearch';
import { useVideosActions } from 'src/store/videos/actions';
import {
  getBadge,
  getBadgeBackgroundColor,
  defaultDateFormatterForVideoList
} from '../../../utilities/utils';
import { successMsg } from 'src/components/utilities/utils';
type locationState = {
  channelName: string;
  subChannelData: [];
};
const VideoListView = ({
  handleFilterClick,
  showFilter,
  setFilterData,
  channels,
  handleChannelDeleteClick,
  channelData,
  videos,
  isSearchVideoLoading,
  searchValue,
  setVideoId,
  recent
}: any) => {
  const location = useLocation();
  const { channelName, subChannelData } = (location.state as locationState) || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const channelActions = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const { isChannelLoading } = useSelector((state: RootState) => {
    return {
      isChannelLoading: state.channel.isChannelLoading
    };
  });
  const { id } = useParams<string>();
  const [rightClickVideoId, setRightClickVideoId] = useState('');
  const [clipboard, setClipboard] = useState<boolean>(false);
  const handleEditChannelClick = (id: string) => {
    if (id) {
      channelActions.getSingleChannelRequest(id);
      channelActions.resetAddNewChannelFlagRequest();
    }
  };
  const handleEditClick = (videoId: string) => {
    navigate(`/videos/${videoId}`);
  };
  const getChannelType = (
    id: string,
    notavailable: boolean,
    free: boolean,
    authorisationRequired: boolean
  ) => {
    if (notavailable == true) {
      return 'Locked';
    } else if (free == true) {
      return 'Free';
    } else if (authorisationRequired == true) {
      return 'Auth';
    } else {
      return 'Paid';
    }
  };
  const handleChannelDoubleClick = (currentChannel: any) => {
    navigate(`/channels/${currentChannel.id}`, {
      state: { channelName: currentChannel.title, subChannelData: currentChannel.children }
    });
    channelActions.setChannelIdRequest(currentChannel.id);
    if (currentChannel.subChannels !== null) {
      channelActions.getSubChannelRequest(currentChannel.subChannels);
    } else {
      channelActions.getSubChannelRequest([]);
    }
  };

  const handleChannelLaunch = (channelUrl: string) => {
    window.open(channelUrl, '_blank');
  };

  const handleGoBackClick = () => {
    navigate('/channels');
    if (channels) {
      channelActions.getSubChannelRequest(
        channels.filter((channel: any) => channel.parentid === null)
      );
    }
  };

  const handleAnalyticsClick = (videoId: string) => {
    window.open(`/videos/analytics/${videoId}`, '_blank');
  };

  const handleDeleteClick = (videoId: string) => {
    videoActions.deleteVideoRequest(videoId);
  };

  const handlePreviewClick = (videoId: string, previewUrl: string) => {
    window.open(`${previewUrl}${videoId}`, '_blank');
  };

  const openInNewTabClick = (videoId: string) => {
    handleResetRightClick();
    window.open(`/videos/${videoId}`, '_blank');
  };

  const handleRightClick = (e: any, videoId: string) => {
    e.preventDefault();
    setRightClickVideoId(videoId);
  };
  useEffect(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, [rightClickVideoId]);

  const handleResetRightClick = () => {
    setRightClickVideoId('');
  };

  const copyTextToClipboard = (channelUrl: string) => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(`${channelUrl}`);
      successMsg('Url copied to clipboard!');
    } else {
      document.execCommand('copy', true, `${channelUrl}`);
    }
  };

  useEffect(() => {
    if (clipboard) {
      successMsg('Copied To Clipboard');
      setClipboard(false);
    }
  }, [clipboard]);

  const handleAddNewClick = () => {
    channelActions.addNewChannelFlagRequest();
  };
  return (
    <div
      className="tab-pane fade active show"
      id="tabs-list"
      role="tabpanel"
      aria-labelledby="tabs-list-tab">
      <div className="flex justify-between">
        {id && (
          <span
            id="badge-dismiss-dark"
            className="inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-200 dark:text-gray-800 mb-5">
            {channelName}
            <button
              onClick={handleGoBackClick}
              type="button"
              className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-300 dark:hover:text-gray-900"
              data-dismiss-target="#badge-dismiss-dark"
              aria-label="Remove">
              <i className="fa fa-close"></i>
              <span className="sr-only">Remove badge</span>
            </button>
          </span>
        )}

        {id && (
          <>
            <div className="whitespace-nowrap">
              <button
                type="button"
                className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
                onClick={handleFilterClick}>
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </>
        )}
      </div>
      <VideoSearch setFilterData={setFilterData} showFilter={showFilter} />
      <div className="flex flex-col">
        <div className={`inline-block w-full overflow-x-auto-sm`}>
          {!channels.length && !isChannelLoading ? (
            <>
              <div className="">
                <h1 className="font-bold text-lg text-center mt-5">
                  {"You don't have any content yet. Get started by creating your first channel."}
                </h1>
                <button
                  onClick={handleAddNewClick}
                  type="button"
                  className="btn-primary mt-0 mb-6 w-40"
                  data-bs-toggle="modal"
                  data-bs-target="#SettingsModal">
                  Add New
                </button>
              </div>
            </>
          ) : (
            <table className="min-w-full border-spacing border-separate">
              <thead className="font-bold">
                <tr className="mb-5">
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    FILE NAME
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left"></th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    VIEWS
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    TYPE
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    STATUS
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    PRICE
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    DATE
                  </th>
                  <th scope="col" className="text-sm px-6 py-4 text-left">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              {channels.length ? (
                <tbody>
                  <>
                    {searchValue === '' && !recent && (
                      <>
                        {channelData !== null ? (
                          channelData?.map((channel: any, index: number) => {
                            return (
                              <tr key={index} className="shadow rounded-md cursor-pointer">
                                <td
                                  onDoubleClick={() => handleChannelDoubleClick(channel)}
                                  className="text-sm font-light px-4 py-3 whitespace-nowrap multiline-text">
                                  <div className="flex items-center">
                                    {/* <img
                                                    src="../../assets/img/list-view.png"
                                                    className="w-14 h-14 object-contain"
                                                  /> */}
                                    <span className="mr-2">
                                      <i className="far fa-folder"></i>
                                    </span>
                                    <div>{channel.title}</div>
                                  </div>
                                </td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap"></td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap"></td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap">
                                  {getChannelType(
                                    channel.id,
                                    channel.notavailable,
                                    channel.free,
                                    channel.authorisationrequired
                                  )}
                                </td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap">
                                  {}
                                </td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap">
                                  ${channel.channelCost}
                                </td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap"></td>
                                <td className="text-sm font-light px-4 py-3 whitespace-nowrap">
                                  <div>
                                    <div className="flex justify-center">
                                      <div>
                                        <div className="dropdown relative">
                                          <a
                                            className="
                                      dropdown-toggle
                                      transition
                                      duration-150
                                      ease-in-out"
                                            href="#"
                                            type="button"
                                            id="ActionButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </a>
                                          <ul
                                            className="dropdown-menu action-dropdown"
                                            aria-labelledby="ActionButton">
                                            <li
                                              onClick={() =>
                                                copyTextToClipboard(channel.channelUrl)
                                              }>
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fa fa-link w-6"></i>
                                                <span>Copy Url</span>
                                              </span>
                                            </li>
                                            <li>
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  handleChannelLaunch(channel.channelUrl);
                                                }}
                                                className="action-menu flex items-center">
                                                <i className="fa fa-play w-6 text-left"></i>
                                                <span>Launch</span>
                                              </button>
                                            </li>
                                            <li>
                                              <span
                                                className="dropdown-item flex-items cursor-pointer"
                                                onClick={() =>
                                                  navigate(`/channels/edit/${channel.id}`)
                                                }>
                                                <i className="fas fa-pencil-alt w-6"></i>
                                                <span>Edit</span>
                                              </span>
                                            </li>
                                            <li>
                                              <span
                                                className="dropdown-item flex-items cursor-pointer"
                                                onClick={() => handleEditChannelClick(channel.id)}>
                                                <i className="fas fa-edit w-6"></i>
                                                <span
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#SettingsModal">
                                                  Quick Edit
                                                </span>
                                              </span>
                                            </li>
                                            <li
                                              onClick={() => handleChannelDeleteClick(channel.id)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#DeleteChannelModal">
                                              <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                                <i className="fas fa-trash w-6"></i>
                                                <span>Delete</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>No Subchannels Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                  {!isSearchVideoLoading ? (
                    <>
                      {videos.length ? (
                        videos &&
                        videos.map((videos: any, index: any) => {
                          return (
                            <tr
                              onContextMenu={(e) => handleRightClick(e, videos.id)}
                              key={index}
                              className="shadow rounded-md">
                              <td
                                onDoubleClick={() => handleEditClick(videos.id)}
                                className="text-sm font-light px-4 py-3">
                                <div className="list-view-thumb">
                                  <img
                                    src={`${videos.thumbnailUrl}`}
                                    className="object-cover h-full w-full"
                                  />
                                </div>
                              </td>
                              <td className="text-sm font-light px-4 py-3">
                                <div className="flex items-center">
                                  <div className="ml-1">{videos.title}</div>
                                  {videos?.videoContentType == 1 ? (
                                    <span className="ml-2 bg-blue-500 text-white px-2 py-1 rounded">
                                      Resource
                                    </span>
                                  ) : (
                                    <span className="ml-2 bg-purple-500 text-white px-2 py-1 rounded">
                                      Video
                                    </span>
                                  )}
                                </div>
                                <div className="mt-1 flex items-center">
                                  {videos.categories &&
                                    videos?.categories?.map((item: any) => {
                                      return (
                                        <span
                                          key={item.id}
                                          className="mr-2 video-view-badge w-auto whitespace-nowrap">
                                          {item.name}
                                        </span>
                                      );
                                    })}
                                </div>
                              </td>
                              <td className="text-sm font-light px-4 py-3">{videos.totalViews}</td>
                              <td className="text-sm font-light px-4 py-3">
                                {videos?.videoPriceType}
                              </td>
                              <td className="text-sm font-light px-4 py-3">
                                <span
                                  className={`${getBadgeBackgroundColor(
                                    videos.videoStatus
                                  )} text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300`}>
                                  {getBadge(videos.videoStatus)}
                                </span>
                              </td>
                              <td className="text-sm font-light px-4 py-3">${videos.videoCost}</td>
                              <td className="text-sm font-light px-4 py-3">
                                {videos && videos.recordDate
                                  ? defaultDateFormatterForVideoList(videos.recordDate)
                                  : ''}
                              </td>
                              {rightClickVideoId === videos.id ? (
                                <td className="text-sm font-light px-4 py-3">
                                  <div>
                                    <div className="flex justify-center">
                                      <div>
                                        <div className="dropdown relative">
                                          <a
                                            ref={ref}
                                            className="
                                      dropdown-toggle
                                      transition
                                      duration-150
                                      ease-in-out"
                                            href="#"
                                            type="button"
                                            id="ActionButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </a>
                                          <ul
                                            className="dropdown-menu action-dropdown"
                                            aria-labelledby="ActionButton">
                                            <li onClick={() => openInNewTabClick(videos.id)}>
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-edit w-6"></i>
                                                <span>Open in new tab</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              ) : (
                                <td className="text-sm font-light px-4 py-3">
                                  <div>
                                    <div className="flex justify-center">
                                      <div>
                                        <div className="dropdown relative">
                                          <a
                                            onClick={handleResetRightClick}
                                            className="
                                        dropdown-toggle
                                        transition
                                        duration-150
                                        ease-in-out"
                                            href="#"
                                            type="button"
                                            id="ActionButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </a>
                                          <ul
                                            className="dropdown-menu action-dropdown"
                                            aria-labelledby="ActionButton">
                                            <li
                                              onClick={() =>
                                                handlePreviewClick(videos.id, videos.previewUrl)
                                              }>
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-eye w-6"></i>
                                                <span>Preview</span>
                                              </span>
                                            </li>
                                            <li
                                              onClick={() => setVideoId(videos.id)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#ShareVideoModal">
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-user-plus w-6"></i>
                                                <span>Share</span>
                                              </span>
                                            </li>
                                            <li onClick={() => handleAnalyticsClick(videos.id)}>
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-chart-bar w-6"></i>
                                                <span>Analytics</span>
                                              </span>
                                            </li>
                                            <li>
                                              <span
                                                className="dropdown-item flex-items cursor-pointer"
                                                onClick={() => handleEditClick(videos.id)}>
                                                <i className="fas fa-edit w-6"></i>
                                                <span>Edit</span>
                                              </span>
                                            </li>
                                            <li>
                                              <span
                                                className="dropdown-item flex-items cursor-pointer text-red-500"
                                                onClick={() => handleDeleteClick(videos.id)}>
                                                <i className="fas fa-trash w-6"></i>
                                                <span>Delete</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td className="px-6">No Video Data Found</td>
                        </tr>
                      )}
                    </>
                  ) : (
                    <tr>
                      <td>
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoListView;
