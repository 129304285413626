import { useEffect } from 'react';
import '../styles.scss';
import { useForm } from 'react-hook-form';
import HighlightReelSidebar from './components/HighlightReelSidebar';
import Content from './components/Content';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import Loader from 'src/components/loader';
const HighlightReel = () => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm();
  const { layoutStyle, isLayoutStyleUpdateLoading } = useSelector((state: RootState) => {
    return {
      layoutStyle: state.videoLibrary.layoutStyle,
      isLayoutStyleUpdateLoading: state.videoLibrary.isLayoutStyleUpdateLoading
    };
  });
  useEffect(() => {
    videoLibraryAction.getLayoutStyleRequest();
    videoLibraryAction.getLayoutContentRequest();
  }, []);
  useEffect(() => {
    if (layoutStyle) {
      reset(layoutStyle);
      !layoutStyle?.highlightReelBackgroundHex?.includes("#") && setValue("highlightReelBackgroundHex", "#" + layoutStyle.highlightReelBackgroundHex);
      !layoutStyle?.highlightReelH1FontColor?.includes("#") && setValue("highlightReelH1FontColor", "#" + layoutStyle.highlightReelH1FontColor);
    }
  }, [layoutStyle]);
  const onSubmit = (data: any) => {
    const payloadData = [
      { op: 'replace', path: 'highLightReelType', value: data.highLightReelType },
      { op: 'replace', path: 'highlightReelBackgroundHex', value: data.highlightReelBackgroundHex?.includes("#") ? data.highlightReelBackgroundHex?.replace('#', '') : data.highlightReelBackgroundHex },
      { op: 'replace', path: 'enableHighlightReelText', value: data.enableHighlightReelText },
      { op: 'replace', path: 'highlightReelH1Text', value: data.highlightReelH1Text },
      { op: 'replace', path: 'highlightReelH1FontSize', value: data.highlightReelH1FontSize },
      { op: 'replace', path: 'highlightReelH1FontColor', value: data.highlightReelH1FontColor },
      { op: 'replace', path: 'highlightReelH2Text', value: data.highlightReelH2Text },
      { op: 'replace', path: 'highlightReelH2FontSize', value: data.highlightReelH2FontSize },
      { op: 'replace', path: 'highlightReelH2FontColor', value: data.highlightReelH2FontColor },
      {
        op: 'replace',
        path: 'enableHighlightReelCategories',
        value: data.enableHighlightReelCategories
      },
      { op: 'replace', path: 'fontColor', value: data.fontColor },
      { op: 'replace', path: 'highlightReelSortOrder', value: data.highlightReelSortOrder },
      { op: 'replace', path: 'indicatorColor', value: data.indicatorColor },
      { op: 'replace', path: 'indicatorColorSelector', value: data.indicatorColorSelector }
    ];
    videoLibraryAction.updateLayoutStyleRequest(payloadData);
  };
  const handleCheckClick = (value: string) => {
    if (value === 'indicatorColorSelector') {
      setValue('indicatorColorSelector', '#FFFFFF');
    }
  };

  return (
    <div
      className="tab-pane fade show active"
      id="tab-accounts"
      role="tabpanel"
      aria-labelledby="tab-accounts-tab">
      <div className="highlight-reel">
        <div className="lg:flex">
          <HighlightReelSidebar />
          <div className="tab-content lg:w-4/5 shadow border" id="tabs-tabContentVertical">
            <div
              className="tab-pane fade show active"
              id="tabs-importantView"
              role="tabpanel"
              aria-labelledby="tabs-important-view">
              <div className="content-header p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {isLayoutStyleUpdateLoading && <Loader />}
                  <button type="submit" className="btn-primary w-auto mx-0 ml-auto mb-4 mt-0">
                    Save
                  </button>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Highlight Reel</h6>
                        <p>
                          Select the carousal style to be used as the main highlight reel on your
                          video library homepage.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <label className="block mb-1">Carousal Style</label>
                        <select
                          className="border border-gray-300 input-style w-56"
                          {...register('highLightReelType')}>
                          <option value={1}>Glide</option>
                          <option value={2}>Large</option>
                          <option value={3}>Small</option>
                          <option value={4}>3D</option>
                          <option value={5}>Hero</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Highlight Reel Background</h6>
                        <p>Set the main color to your main highlight reel background.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <label className="block mb-1">Background Color</label>
                        <div className="relative w-56">
                          <input
                            type="color"
                            id="highlightReelBackgroundHex"
                            {...register('highlightReelBackgroundHex')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Display Video Title</h6>
                        <p>
                          Display video title of your video as heading on your highlight reel cards.
                          Best to disable of your videos thumbnail images include a title and text
                          to avoid layering of text on the image.
                        </p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="form-check form-switch pl-0 flex items-center mt-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                            id="enableHighlightReelText"
                            {...register('enableHighlightReelText')}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">H1</h6>
                        <p>Set the heading title for the highlight reel</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="lg:flex items-center flex-wrap">
                        <div className="mr-4">
                          <label className="block mb-1">Display text</label>
                          <select
                            className="border border-gray-300 input-style md:w-36"
                            id="highlightReelH1Text"
                            {...register('highlightReelH1Text')}>
                            <option value={0}>Select</option>
                            <option value={1}>Video Title</option>
                            <option value={2}>Speaker Name</option>
                          </select>
                        </div>
                        <div className="mr-4">
                          <label className="block mb-1">Font size</label>
                          <input
                            type="text"
                            className="input-style w-36"
                            placeholder="24"
                            id="highlightReelH1FontSize"
                            {...register('highlightReelH1FontSize')}
                          />
                        </div>
                        <div>
                          <label className="block mb-1">Font color</label>
                          <input
                            type="color"
                            id="highlightReelH1FontColor"
                            {...register('highlightReelH1FontColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">H2</h6>
                        <p>Set the sub-heading title for the highlight reel</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="lg:flex items-center flex-wrap">
                        <div className="mr-4">
                          <label className="block mb-1">Display text</label>
                          <select
                            className="border border-gray-300 input-style md:w-36"
                            id="highlightReelH2Text"
                            {...register('highlightReelH2Text')}>
                            <option value={0}>Select</option>
                            <option value={1}>Video Title</option>
                            <option value={2}>Speaker Name</option>
                          </select>
                        </div>
                        <div className="mr-4">
                          <label className="block mb-1">Font size</label>
                          <input
                            type="text"
                            className="input-style md:w-36"
                            placeholder="24"
                            id="highlightReelH2FontSize"
                            {...register('highlightReelH2FontSize')}
                          />
                        </div>
                        <div>
                          <label className="block mb-1">Font color</label>
                          <input
                            placeholder="#000000"
                            type="color"
                            id="highlightReelH2FontColor"
                            {...register('highlightReelH2FontColor')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Display Video Categories</h6>
                        <p>Display the categories on your video on your highlight reel.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="form-check form-switch pl-0 flex items-center mt-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                            id="enableHighlightReelCategories"
                            {...register('enableHighlightReelCategories')}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Category Color</h6>
                        <p>Set the main color to your main highlight reel background.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <label className="block mb-1">Category font Color</label>
                        <input
                          placeholder="#000000"
                          type="color"
                          id="fontColor"
                          {...register('fontColor')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Highlight Reel Sort Order</h6>
                        <p>Set the display order of the videos within the highlight reel.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <label className="block mb-1">Set Order</label>
                        <select
                          className="border border-gray-300 input-style w-56"
                          id="highlightReelSortOrder"
                          {...register('highlightReelSortOrder')}>
                          <option value={'recorddate asc'}>Record Date Ascending</option>
                          <option value={'recorddate desc'}>Record Date Descending</option>
                          <option value={'title asc'}>Title Ascending</option>
                          <option value={'title desc'}>Title Descending</option>
                          <option value={'duration asc'}>Duration Ascending</option>
                          <option value={'duration desc'}>Duration Descending</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Indicator Menu</h6>
                        <p>Display the categories on your video on your highlight reel.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div>
                        <div className="form-check form-switch pl-0 flex items-center mt-2">
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="indicatorColor"
                              {...register('indicatorColor')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:flex mb-5">
                    <div className="lg:w-1/2 pb-0 lg:pr-8">
                      <div>
                        <h6 className="font-bold mb-1">Indicator Color Selector</h6>
                        <p>Set the main color to your main highlight reel background.</p>
                      </div>
                    </div>
                    <div className="lg:w-1/2 pb-0">
                      <div className="grid">
                        <div className="mb-2 flex items-center">
                          <input
                            type="checkbox"
                            className="rounded-sm shadow"
                            onClick={() => handleCheckClick('indicatorColorSelector')}
                          />
                          <span className="font-semibold ml-2">Transparent</span>
                        </div>
                        <input
                          placeholder="#000000"
                          type="color"
                          id="indicatorColorSelector"
                          {...register('indicatorColorSelector')}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <Content />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighlightReel;
