import React, { useEffect, useState } from 'react';
import '../styles.scss';
import { useVideosActions } from 'src/store/videos/actions';
import { useChannelActions } from 'src/store/channel/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { RootState } from 'src/store/reducers/state';
import Pagination from 'src/components/common/pagination';
import { useNavigate, useParams } from 'react-router-dom';
import VideoSidebar from '../videoSidebar';
import ChannelList from '../components/ChannelList';
import ChannelSetting from '../channelSetting';
import VideoList from '../components/VideoList';
import { successMsg } from 'src/components/utilities/utils';
import DeleteChannelModal from '../components/DeleteChannelModel';
import VideoListView from '../components/VideoListView';
import { useCategoryActions } from 'src/store/category/actions';
import { DebounceInput } from 'react-debounce-input';
import { SearchVideoModel } from 'src/models/videosModel';
import { useUserActions } from 'src/store/users/actions';
import ToastMessage from 'src/components/ToastContainer';
import { errorMsg } from 'src/components/utilities/utils';
import ShareVideoModal from '../components/ShareVideoModal';
import VideoContentTypeModal from '../components/VideoContentTypeModal';

const VideoViews: React.FC = () => {
  const [AddFlag, setAddFlag] = useState(false);
  const [collapseChannelMenu, setCollapseChannelMenu] = useState(false);
  const [deleteChannelId, setDeleteChannelId] = useState<string>();
  const [dragFlag, setDragFlag] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [clipboard, setClipboard] = useState<boolean>(false);
  const [recent, setRecent] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<SearchVideoModel | null>();
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const videoActions = useVideosActions(dispatch);
  const categoryActions = useCategoryActions(dispatch);
  const [videoId, setVideoId] = useState<string>('');
  const userActions = useUserActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const [isVideoContentTypeModalOpen, setIsVideoContentTypeModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    videos,
    paginationMeta,
    channels,
    message,
    isVideoDeleted,
    isSearchVideoLoading,
    subChannels,
    channelId,
    roles,
    error,
    categoriesList,
    singleVideo,
    isAuthKeySaved
  } = useSelector((state: RootState) => {
    return {
      videos: state.videos.videos,
      paginationMeta: state.videos.paginationMeta,
      channels: state.channel.channels,
      message: state.videos.message,
      isVideoDeleted: state.videos.isVideoDeleted,
      isSearchVideoLoading: state.videos.isSearchVideoLoading,
      subChannels: state.channel.subChannels,
      channelId: state.channel.channelId,
      roles: state.users.roles,
      error: state.videos.error,
      categoriesList: state.category.categoriesList,
      singleVideo: state.videos.singleVideo,
      isAuthKeySaved: state.videos.isAuthKeySaved
    };
  });
  useEffect(() => {
    if (isVideoDeleted) {
      successMsg(message);
      videoActions.resetVideoLibraryRequest();
    }
  }, [isVideoDeleted]);

  useEffect(() => {
    channelActions.getChannelRequest();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const onSubmit = (data: any) => {
    videoActions.addVideoRequest(data);
  };
  const handlePageChange = (pageNumber: number | string) => {
    const data = { ...filterData, pageNumber, channelId: id, pageSize: 10, criteria: searchValue };
    videoActions.searchVideoRequest(data);
  };
  const handleEditClick = (videoId: string) => {
    navigate(`/videos/${videoId}`);
  };
  const handleChannelDeleteClick = (channelId: string) => {
    setDeleteChannelId(channelId);
  };

  const openVideoContentTypeModal = () => {
    setIsVideoContentTypeModalOpen(true);
  };

  const handleDeleteVideoClick = (videoId: string) => {
    // videoActions.deleteVideoRequest(videoId);
  };

  useEffect(() => {
    if (id) {
      const data = { pageNumber: 1, channelId: id, pageSize: 10 };
      videoActions.searchVideoRequest(data);
    }
  }, [id]);

  const handleResourceUploadClick = () => {
    navigate('/add-resource', { state: { channelIds: id } });
  };

  const handleChannelMenuClick = () => {
    if (collapseChannelMenu) {
      setCollapseChannelMenu(false);
    } else {
      setCollapseChannelMenu(true);
    }
  };

  const handleSearchVideo = (e: string) => {
    const finalData = { criteria: e, pageNumber: 1, channelId: id, pageSize: 10 };
    setSearchValue(e);
    videoActions.searchVideoRequest(finalData);
  };

  const handleFilterClick = () => {
    if (id) {
      if (!roles.length) {
        userActions.getRolesRequest();
      }
      if (!Object.keys(categoriesList).length) {
        categoryActions.getCategoriesListRequest();
      }
    }
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  useEffect(() => {
    if (roles && roles.length) {
      const speakerId = roles.filter((role) => role.name.toUpperCase() === 'SPEAKER');
      if (speakerId && speakerId.length) {
        videoActions.getSpeakersRequest(speakerId[0]?.id);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      videoActions.resetVideoLibraryRequest();
    }
  }, [error]);

  useEffect(() => {
    if (videoId) {
      videoActions.getSingleVideosRequest(videoId);
    }
  }, [videoId]);
  useEffect(() => {
    if (isAuthKeySaved && videoId) {
      videoActions.getSingleVideosRequest(videoId);
      videoActions.resetAuthKeyRequest();
    }
  }, [isAuthKeySaved]);

  useEffect(() => {
    if (clipboard) {
      successMsg('Copied To Clipboard');
      setClipboard(false);
    }
  }, [clipboard]);

  return (
    <>
      <ToastMessage />
      <div className="mt-4">
        <div className="pl-0">
          <div
            className={`lg:flex w-full catalogueMain ${dragFlag ? 'dragDropMain' : ''} ${
              collapseChannelMenu ? 'cataloguetoggle' : ''
            }`}>
            <VideoSidebar
              setDeleteChannelId={setDeleteChannelId}
              setDragFlag={setDragFlag}
              dragFlag={dragFlag}
              setRecent={setRecent}
              recent={recent}
            />
            <div
              className={`${
                AddFlag ? 'enable-video-div' : 'disable-video-div'
              } xl:w-4/5 lg:w-2/3 md:w-full shadow left-side-card border rightsideCard`}>
              {/* <AddVideo /> */}
              <div className="video-cards">
                <div className="border-b">
                  <div className="xl:flex items-center justify-between ml-auto p-4 header-action-btns">
                    <div className="flex items-center">
                      <span
                        className="cursor-pointer text-lg site-clr mr-10"
                        onClick={handleChannelMenuClick}>
                        <i className="fas fa-bars"></i>
                      </span>
                      <div className="relative input-style py-0 px-2">
                        <DebounceInput
                          minLength={2}
                          className="border-0 pl-6"
                          debounceTimeout={500}
                          onChange={(event) => handleSearchVideo(event.target.value)}
                        />
                        <div className="absolute top-2 pt-1">
                          <i className="fa fa-search"></i>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center sm:mt-2">
                      <button
                        onClick={openVideoContentTypeModal}
                        className="btn-primary cursor-pointer w-28 mt-0 mr-3 ml-0"
                        data-toggle="modal"
                        data-target="#videoContentTypeModal">
                        Add New
                      </button>

                      <ul
                        className="nav nav-tabs flex md:flex-row list-none border rounded-md pl-0 mb-0 overflow-hidden"
                        id="tabs-tab"
                        role="tablist">
                        <li className="nav-item" role="presentation">
                          <a
                            href="#tabs-list"
                            className="nav-link video-tab-link border-r active"
                            id="tabs-list-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-list"
                            role="tab"
                            aria-controls="tabs-list"
                            aria-selected="true">
                            <img
                              src="../../assets/img/icons/list-view.svg"
                              className="cursor-pointer p-1 w-6 h-6"
                            />
                          </a>
                        </li>
                        <li className="nav-item" role="presentation">
                          <a
                            href="#tabs-card"
                            className="nav-link video-tab-link  "
                            id="tabs-card-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-card"
                            role="tab"
                            aria-controls="tabs-card"
                            aria-selected="false">
                            <img
                              src="../../assets/img/icons/card-view.svg"
                              className="cursor-pointer p-1 w-6 h-6"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {isVideoContentTypeModalOpen && (
                  <>
                    <VideoContentTypeModal
                      setIsVideoContentTypeModalOpen={setIsVideoContentTypeModalOpen}
                      isVideoContentTypeModalOpen={isVideoContentTypeModalOpen}
                      handleResourceUploadClick={handleResourceUploadClick}
                    />
                  </>
                )}
                <div className="p-4">
                  <div className="tab-content" id="tabs-tabContent">
                    <div
                      className="tab-pane fade"
                      id="tabs-card"
                      role="tabpanel"
                      aria-labelledby="tabs-card-tab">
                      <div>
                        {searchValue === '' && !recent && (
                          <ChannelList
                            channelData={subChannels}
                            handleChannelDeleteClick={handleChannelDeleteClick}
                            handleFilterClick={handleFilterClick}
                            setFilterData={setFilterData}
                            showFilter={showFilter}
                          />
                        )}

                        <VideoList
                          isSearchVideoLoading={isSearchVideoLoading}
                          videos={videos}
                          handleEditClick={handleEditClick}
                          handleDelteClick={handleDeleteVideoClick}
                          setVideoId={setVideoId}
                        />
                      </div>
                    </div>
                    <VideoListView
                      isSearchVideoLoading={isSearchVideoLoading}
                      videos={videos}
                      channels={channels}
                      channelData={subChannels}
                      handleChannelDeleteClick={handleChannelDeleteClick}
                      setFilterData={setFilterData}
                      showFilter={showFilter}
                      handleFilterClick={handleFilterClick}
                      searchValue={searchValue}
                      setVideoId={setVideoId}
                      recent={recent}
                    />
                  </div>
                </div>
              </div>
              {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                <Pagination
                  currentPage={paginationMeta.pageNumber}
                  totalCount={paginationMeta.totalCount}
                  pageSize={paginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>

        {/* Modal */}
        <ChannelSetting />

        <DeleteChannelModal deleteChannelId={deleteChannelId} />
        <ShareVideoModal singleVideo={singleVideo} videoId={videoId} setClipboard={setClipboard} />
      </div>
    </>
  );
};

export default VideoViews;
