import '../styles.scss';
import { useVideosActions } from 'src/store/videos/actions';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import Select from 'react-select';
import { useChannelActions } from 'src/store/channel/actions';
import { useForm, Controller } from 'react-hook-form';
import { customFilterOption } from 'src/components/utilities/utils';
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
  SortableHandle
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
interface defaultChannel {
  value: string;
  label: string;
}
interface ISortableHandleElement {
  children: React.ReactNode;
  className?: string;
}

interface ISortableItem extends SortableElementProps {
  children: React.ReactNode;
  className?: string;
}

interface ISortableContainer extends SortableContainerProps {
  children: React.ReactNode;
  className?: string;
}

const DndTrigger: React.ComponentClass<ISortableHandleElement, any> = SortableHandle(
  ({ children, className }: { children: React.ReactNode; className: string }) => (
    <div className={className || ''}>{children}</div>
  )
);

const DndItem: React.ComponentClass<ISortableItem, any> = SortableElement(
  ({ children, className }: { children: React.ReactNode; className: string }) => (
    <div className={className || ''}>{children}</div>
  )
);

const DndList: React.ComponentClass<ISortableContainer, any> = SortableContainer(
  ({ children, className }: { children: React.ReactNode; className: string }) => {
    return <div className={className || ''}>{children}</div>;
  }
);

const SpotlightRows = () => {
  const dispatch = useDispatch();
  const channelAction = useChannelActions(dispatch);
  const libraryActions = useVideoLibraryActions(dispatch);
  const [channelId, setChannelId] = useState<any>('');
  const [collectionId, setCollectionId] = useState<any>('');
  const [channelDefault, setChannelDefault] = useState<defaultChannel>();
  const [spotlightChannelData, setSpotlightChannelData] = useState<any>();
  const [moveFlag, setMoveFlag] = useState<boolean>(false);
  const [role, setRole] = useState<string>('');
  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);

  const {
    spotLightRowVideo,
    spotlightChannel,
    recommendations,
    isSpotlightChannelDeleted,
    isSpotlightChannelAdded,
    isSpotlightOrderUpdated,
    collectionList
  } = useSelector((state: RootState) => {
    return {
      spotLightRowVideo: state.videoLibrary.spotLightRowVideo,
      spotlightChannel: state.videoLibrary.spotlightChannel,
      recommendations: state.videoLibrary.recommendations,
      isSpotlightChannelDeleted: state.videoLibrary.isSpotlightChannelDeleted,
      isSpotlightChannelAdded: state.videoLibrary.isSpotlightChannelAdded,
      isSpotlightOrderUpdated: state.videoLibrary.isSpotlightOrderUpdated,
      collectionList: state.category.collectionList
    };
  });
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
    setSpotlightChannelData(arrayMoveImmutable(spotlightChannelData, oldIndex, newIndex));
    setMoveFlag(true);
  };
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    setRole(finalData.role);
  }, []);

  useEffect(() => {
    if (spotlightChannel) {
      setSpotlightChannelData(spotlightChannel);
    }
  }, [spotlightChannel]);
  const {
    formState: { errors },
    control
  } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();

  const handleValueChange = (onChange: any, val: defaultChannel) => {
    onChange(val);
    setChannelId(val.value);
  };

  const handleCollectionValueChange = (onChange: any, val: defaultChannel) => {
    onChange(val);
    setCollectionId(val.value);
  };

  const handleAddClick = () => {
    if (channelId) {
      const payloadData = { contentId: channelId, rowtype: 0 };
      libraryActions.addSpotlightChannelRequest(payloadData);
      setChannelId('');
    } else if (collectionId) {
      const payloadData = { contentId: collectionId, rowtype: 1 };
      libraryActions.addSpotlightChannelRequest(payloadData);
      setCollectionId('');
    }
  };
  useEffect(() => {
    if (moveFlag) {
      const channelIdArray = spotlightChannelData.map((item: any) => {
        return item.id;
      });
      libraryActions.updateSpotlightSettingOrderRequest(channelIdArray);
      setMoveFlag(false);
    }
  }, [moveFlag]);

  const handleDeleteClick = (channelId: string) => {
    libraryActions.deleteSpotlightChannelRequest(channelId);
  };

  useEffect(() => {
    if (isSpotlightChannelDeleted || isSpotlightChannelAdded) {
      libraryActions.getSpotlightChannelRequest();
      libraryActions.getRecommendationRequest();
      libraryActions.resetVideoLibraryStateRequest();
    }
  }, [isSpotlightChannelDeleted, isSpotlightChannelAdded]);

  const handleSaveRecommendations = (data: any, recommendationId: string) => {
    if (data.target.checked) {
      const channelIdArray = spotlightChannel.map((item: any) => {
        return item.id;
      });
      libraryActions.updateSpotlightSettingOrderRequest([recommendationId, ...channelIdArray]);
    } else {
      libraryActions.deleteSpotlightChannelRequest(recommendationId);
    }
  };

  useEffect(() => {
    if (isSpotlightOrderUpdated) {
      libraryActions.getSpotlightChannelRequest();
      libraryActions.resetVideoLibraryStateRequest();
    }
  }, [isSpotlightOrderUpdated]);
  return (
    <div>
      <div className="spotlight-rows">
        <div className="shadow p-5 border">
          <h6 className="font-semibold">Recommendations</h6>
          <div>
            <div className="mt-3">
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mb-1">
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '1')}
                        defaultChecked={recommendations?.spotlightSettings?.trendingNow}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Trending Now
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '2')}
                        defaultChecked={recommendations?.spotlightSettings?.mostPopular}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Most Popular
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '3')}
                        defaultChecked={recommendations?.spotlightSettings?.myList}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      My List
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '4')}
                        defaultChecked={recommendations?.spotlightSettings?.recentlyAdded}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Recently Added
                    </label>
                  </div>
                </div>
              </div>

              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '5')}
                        defaultChecked={recommendations?.spotlightSettings?.categories}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Categories
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '6')}
                        defaultChecked={recommendations?.spotlightSettings?.channels}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Channels
                    </label>
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '7')}
                        defaultChecked={recommendations?.spotlightSettings?.courses}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Courses
                    </label>
                  </div>
                </div>
                <div className="form-check form-switch pl-0 flex items-center mb-2">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        onClick={(e) => handleSaveRecommendations(e, '8')}
                        defaultChecked={recommendations?.spotlightSettings?.collection}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                    <label
                      className="form-check-label inline-block font-semibold switch-label ml-2"
                      htmlFor="Credit">
                      Collections
                    </label>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shadow p-5 mt-6 border" style={{ minHeight: '500px' }}>
          <div className="md:flex justify-between">
            <div>
              <h6 className="font-semibold">Rows</h6>
              <p className="pr-8">
                Add new rows into the main video channel page. Spotlight rows will appear as
                horizontol rows of <br />
                video cards & the order you specify on this page.
              </p>
            </div>
            <div className="flex items-center justify-center">
              <div>
                <label className="block mb-1">Select Collection</label>
                {recommendations && (
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        options={
                          recommendations &&
                          recommendations.collectionsHierList?.map((item) => {
                            return {
                              value: item.value,
                              label: item.text,
                              isDisabled: item.disabled
                            };
                          })
                        }
                        filterOption={customFilterOption}
                        id="collectionId"
                        value={value}
                        onChange={(val: any) => handleCollectionValueChange(onChange, val)}
                        className="w-80"
                        isDisabled={role === 'Super Admin' ? false : true}
                      />
                    )}
                    name="collectionId"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={channelDefault && channelDefault}
                  />
                )}
              </div>

              <div>
                <label className="block mb-1">Select Channel</label>
                {recommendations && (
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        options={
                          recommendations &&
                          recommendations.channelsHierList?.map((item) => {
                            return {
                              value: item.value,
                              label: item.text,
                              isDisabled: item.disabled
                            };
                          })
                        }
                        filterOption={customFilterOption}
                        id="channelId"
                        value={value}
                        onChange={(val: any) => handleValueChange(onChange, val)}
                        className="w-80 ml-1"
                      />
                    )}
                    name="channelId"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={channelDefault && channelDefault}
                  />
                )}
              </div>

              <button
                onClick={() => handleAddClick()}
                type="button"
                className="btn-primary w-auto borderonly-btn ml-2 mt-5">
                Add
              </button>
            </div>
          </div>
          <div className="channel-card my-4">
            {spotlightChannelData && spotlightChannelData.length ? (
              <>
                <DndList
                  lockAxis="y"
                  useWindowAsScrollContainer={true}
                  useDragHandle
                  onSortEnd={onSortEnd}
                  className="itemsContainer">
                  {spotlightChannelData &&
                    spotlightChannelData?.map((item: any, index: number) => (
                      <DndItem key={`item-${item.id}`} index={index} className="item">
                        <div className="border p-2 rounded-lg flex items-center mt-2">
                          <div className="w-full flex justify-between">
                            <DndTrigger className="itemTrigger">
                              <div className="flex">
                                <img
                                  src="../../assets/img/icons/tabler_drag-drop.svg"
                                  className="w-5 h-5 mr-1"
                                />
                                <span> {item.name}</span>
                              </div>
                            </DndTrigger>
                            <div
                              onClick={() => handleDeleteClick(item.id)}
                              className="cursor-pointer">
                              {!item.name.includes('Recommendations') && (
                                <i className="fa fa-trash"></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </DndItem>
                    ))}
                </DndList>
              </>
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotlightRows;
