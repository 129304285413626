import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
const ManageSlides = () => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const countRef = useRef<any>();
  const { id } = useParams<string>();
  const [slideCount, setSlideCount] = useState(0);
  const [timer, setTimer] = useState(0);
  const { videoSlide, isVideoSlideLoading } = useSelector((state: RootState) => {
    return {
      videoSlide: state.videos.videoSlide,
      isVideoSlideLoading: state.videos.isVideoSlideLoading
    };
  });

  useEffect(() => {
    videoActions.getVideoSlidesRequest({ id });
  }, []);

  const handleNextClick = () => {
    if (videoSlide && videoSlide.length !== 1) {
      slideCount < videoSlide.length - 1 ? setSlideCount((x) => x + 1) : setSlideCount(0);
      if (id) {
        const payloadData = {
          id: videoSlide[slideCount].id,
          videoId: id,
          isNext: true,
          dateTime: formatTime(timer)
        };
        videoActions.updateVideoSlidesRequest(payloadData);
      }
    }
  };
  const handlePreviousClick = () => {
    if (videoSlide && videoSlide.length !== 1) {
      slideCount !== 0 ? setSlideCount((x) => x - 1) : setSlideCount(videoSlide.length - 1);
      if (id) {
        const payloadData = {
          id: videoSlide[slideCount].id,
          videoId: id,
          isNext: true,
          dateTime: formatTime(timer)
        };
        videoActions.updateVideoSlidesRequest(payloadData);
      }
    }
  };
  const formatTime = (timer: number) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = Math.floor(timer / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  useEffect(() => {
    const interval = (countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000));
    return () => clearInterval(interval);
  }, []);
  return (
    <div>
      <div className="mb-8">
        <h2 className="font-semibold my-1">Video</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Video Library</Link>
          </li>
          <li className="active">
            <strong>Presentations</strong>
          </li>
          <li>
            <Link to="/">Manage</Link>
          </li>
          <li className="active">
            <strong>Manage Slides</strong>
          </li>
        </ol>
      </div>
      <div className="mb-5 lg:p-10 md:p-6 p-5">
        {isVideoSlideLoading ? (
          <Loader />
        ) : (
          <div className="mt-4">
            <p className="font-bold">Live Time:</p>
            <div className="text-center mb-4">
              <span ref={countRef} className="font-semibold text-lg">
                Duration: {formatTime(timer)}
              </span>
            </div>
            {videoSlide && videoSlide.length ? (
              <div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={handlePreviousClick}
                    type="button"
                    className="px-6 btn-primary w-auto ml-0 mr-3 mb-3">
                    Previous
                  </button>
                  <button
                    onClick={handleNextClick}
                    type="button"
                    className="px-6 btn-primary w-auto ml-auto mr-0 mb-3">
                    Next
                  </button>
                </div>
                <div>
                  <div className="thumbnail p-1 rounded-sm border">
                    <img
                      src={videoSlide && videoSlide[slideCount]?.imageURL}
                      className="w-full h-full object-contain"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="crasoel mt-3 flex flex-wrap">
                  {videoSlide &&
                    videoSlide.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          onClick={() => setSlideCount(index)}
                          className="ml-1 mt-1 relative channel-setting-image flex justify-center cursor-pointer">
                          <img className="w-32" src={item.imageURL} />
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <p>No Data Found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageSlides;
