import React, { useEffect, useState } from 'react';
import Tree from '../components/Tree';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useChannelActions } from 'src/store/channel/actions';
import { VideoSidebarModel } from 'src/models/sidebarModel';
import Loader from 'src/components/loader';
const TreeView = ({ setDeleteChannelId, setDragFlag, dragFlag, setRecent }: any) => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [searchChannel, setSearchChannel] = useState('');
  const { id } = useParams<string>();
  const { channels, isChannelLoading } = useSelector((state: RootState) => {
    return {
      channels: state.channel.channels,
      isChannelLoading: state.channel.isChannelLoading
    };
  });
  const [treeData, setTreeData] = useState<any>(channels);
  const channelAction = useChannelActions(dispatch);

  function filterBy(arr: any, query: any) {
    return query
      ? arr.reduce((acc: any, item: any) => {
          if (item.children?.length) {
            const filtered = filterBy(item.children, query);
            if (filtered.length) return [...acc, { ...item, children: filtered }];
          }

          const { children, ...itemWithoutChildren } = item;
          return item.title?.toLowerCase().includes(query.toLowerCase())
            ? [...acc, itemWithoutChildren]
            : acc;
        }, [])
      : arr;
  }
  useEffect(() => {
    if (channels) {
      if (searchChannel === '') {
        setTreeData(channels);
        if (channels && channels.length && !id) {
          channelAction.getSubChannelRequest(
            channels.filter((channel) => channel.parentid === null)
          );
        }
      }
    }
  }, [id, channels]);
  const onQueryChange = (e: any) => {
    setSearchChannel(e);
    setTreeData(filterBy(channels, e.target.value));
  };
  return (
    <>
      <input placeholder="Filter Channel" className="input-style" onChange={onQueryChange} />
      {isChannelLoading ? (
        <Loader />
      ) : (
        <Tree
          data={treeData}
          setDeleteChannelId={setDeleteChannelId}
          setDragFlag={setDragFlag}
          dragFlag={dragFlag}
          setRecent={setRecent}
        />
      )}
    </>
  );
};

export default TreeView;
