import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginFormModel, SSOLoginFormModel } from 'src/models/loginModel';
import { removeItem, errorMsg } from '../utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { useLoginActions } from 'src/store/login/actions';
import { RootState } from 'src/store/reducers/state';
import FormFieldError from '../common/formFieldError';
import * as AppConstants from '../utilities/constants';
import { useSearchParams, useParams } from 'react-router-dom';
import MicrosoftLogin from 'react-microsoft-login';
//import { jwtDecode } from 'jwt-decode';
const mslclientid: string = process.env.REACT_APP_MSL_CLIENTID ?? '';
type locationState = {
  from: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginAction = useLoginActions(dispatch);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const loginType = Number(searchParams.get('sa'));
  const returnUrl = searchParams.get('returnUrl');
  const { from } = (location.state as locationState) || {};
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormModel>();

  useEffect(() => {
    removeItem('token');
    removeItem('role');
  }, []);

  const handleLogin = (data: LoginFormModel) => {
    loginAction.sendLoginRequest(data);
  };

  const SSOauthHandler = (err: any, data: any) => {
    if (err) {
      console.error('Error on MSL: ', err);
      return;
    }
    console.log('Response on MSL: ', data);
    if (data.idTokenClaims == undefined || data.idTokenClaims == '') {
      console.error('Error on MSL response. idTokenClaims should not be null: ');
      return;
    }
    const idTokenClaims = data.idTokenClaims;
    // const decodedToken = jwtDecode(data.idToken);
    // console.log('Decoded token:', decodedToken);
    if (idTokenClaims.preferred_username == undefined || idTokenClaims.preferred_username == '') {
      console.error('Error on MSL response. idTokenClaims.preferred_username should not be null: ');
      return;
    }

    //console.log('preferred_username', idTokenClaims.preferred_username);
    // Handle the login response and validate the user against your database here
    const payloadData = { Email: idTokenClaims.preferred_username };
    //console.log('sso payloadData', payloadData);
    loginAction.sendSSOLoginRequest(payloadData);
  };

  const { loginError, isLoggedIn, isCallbackLink, domainList, defaultDateFormat } = useSelector(
    (state: RootState) => {
      return {
        loginError: state.login.error,
        loading: state.login.loading,
        isLoggedIn: state.login.isLoggedIn,
        domainList: state.login.domainList,
        isCallbackLink: state.login.isCallbackLink,
        defaultDateFormat: state.login.defaultDateFormat
      };
    }
  );

  useEffect(() => {
    if (isLoggedIn) {
      loginAction.getDefaultdateFormatRequest();
      const localData = localStorage.getItem('userData');
      const userData = localData && JSON.parse(localData);
      if (userData.isMfaSetupEnabled) {
        if (returnUrl && returnUrl.trim() !== '')
          navigate('/account/CodeVerification?returnUrl=' + returnUrl);
        else navigate('/account/CodeVerification');
      } else {
        if (returnUrl && returnUrl.trim() !== '') {
          navigate(
            '/account/MultiFactorAuthentication?returnUrl=' + returnUrl != 'null' ? returnUrl : '/'
          );
        } else {
          navigate('/account/MultiFactorAuthentication');
        }
      }

      // if (returnUrl) {
      //   console.log("insideif");

      //   const pageUrl = returnUrl;
      //   // navigate(`${pageUrl}`);
      // } else {
      //   console.log("inside else");

      //   const pageUrl = '/';
      //   navigate(`${pageUrl}`);
      // }
      loginAction.resetLoginStateRequest();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (Object.keys(loginError).length) {
      console.log('error', loginError);
      if (loginError.customerror != undefined && loginError.customerror) {
        errorMsg(loginError.error_description);
      } else {
        errorMsg('Something went wrong please try again');
      }
    }
  }, [loginError]);

  useEffect(() => {
    removeItem('token');
    removeItem('role');
    if (isCallbackLink) {
      loginAction.removeCallbackLinkRequest();
    }
  }, []);

  return (
    <div>
      <ToastMessage />

      <section
        className="bg-gray-100 h-screen"
        style={{
          backgroundImage: `url(../../assets/img/wcc-background-image.png)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}>
        <img
          className="w-64 absolute top-4 left-4"
          src="../../assets/img/wcc-logo.png"
          alt="logo"
        />
        <div className="container px-6 h-full mx-auto">
          <div className="flex justify-center items-center h-full">
            <div className="xl:w-3/5 lg:w-3/5 md:w-8/12 w-12/12">
              <div className="block bg-white shadow-lg rounded-xl">
                <div className="lg:flex-wrap g-0">
                  <div className=" px-4 md:px-0">
                    <div className="md:px-8 px-5 py-16">
                      <div className="mb-2">
                        <div className="flex justify-center">
                          <h1 className="font-black text-2xl">Welcome Back!</h1>
                        </div>

                        <div className="text-center mb-4">
                          <MicrosoftLogin
                            clientId={mslclientid}
                            authCallback={SSOauthHandler}
                            redirectUri={window.location.origin + '/login'}>
                            <button className="btn-primary mb-3 microsoft-btn" type="button">
                              <span className="mr-2">
                                <i className="fab fa-microsoft"></i>
                              </span>
                              <span>SIGN IN WITH MICROSOFT</span>
                            </button>
                          </MicrosoftLogin>
                        </div>

                        <div className="separator-line">
                          <span className="separator-text">OR</span>
                        </div>
                        {/* <h3 className="text-2xl font-semibold mb-2">Login</h3>
                        <p>Please use your email address to sign-in to your account.</p> */}
                      </div>
                      <form onSubmit={handleSubmit(handleLogin)}>
                        <div className="mb-4">
                          <label className="mb-1 block font-bold">Work Email</label>
                          <input
                            type="text"
                            className="input-style"
                            id="UserName"
                            {...register('UserName', {
                              required: true,
                              maxLength: 200,
                              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                            })}
                          />
                          {errors.UserName?.type === 'required' && (
                            <FormFieldError message={AppConstants.requiredField} />
                          )}
                          {errors.UserName?.type === 'pattern' && (
                            <FormFieldError message={AppConstants.notValidEmail} />
                          )}
                          {errors.UserName?.type === 'maxLength' && (
                            <FormFieldError message={AppConstants.maxLengthReached} />
                          )}
                        </div>
                        <div className="mb-4">
                          <label className="mb-1 block font-bold">Password</label>
                          <input
                            id="Password"
                            type="password"
                            className="input-style"
                            {...register('Password', { required: true })}
                          />
                          {errors.Password?.type === 'required' && (
                            <FormFieldError message={AppConstants.requiredField} />
                          )}

                          {loginType ? (
                            <div className="mt-3">
                              <label className="mb-1 block">Select Domain</label>
                              <select
                                className="input-style"
                                {...register('DomainId', { required: true })}>
                                {domainList && domainList.length ? (
                                  domainList.map((domain, idx) => (
                                    <option key={idx} value={domain.id}>
                                      {domain.name}
                                    </option>
                                  ))
                                ) : (
                                  <>
                                    <option value={'0C7C3EDC-4490-48D0-BB45-CD7295E3647C'}>
                                      Appdev
                                    </option>
                                    <option value={'15AC404A-F196-4E93-B3BC-D72486A6A7AD'}>
                                      Associationsdev
                                    </option>
                                    <option value={'04C98F10-5ABC-41DA-A689-7B65FCFAC48B'}>
                                      EngineersAustraliadev
                                    </option>
                                    <option value={'1D0F0114-B6C2-47B0-96D8-7728F96AA387'}>
                                      opteddev
                                    </option>
                                    <option value={'27466dfa-c6a7-48fa-89cc-6a34cc71c6c9'}>
                                      apptest3
                                    </option>
                                  </>
                                )}
                              </select>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="custom-form-check flex items-center justify-between my-4">
                            <div className="form-check flex items-center justify-start">
                              <input
                                className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                                type="checkbox"
                              />
                              <label className="ml-1">Remember me</label>
                            </div>
                            <Link
                              to="/account/ForgetPassword"
                              className="text-right text-purple-600">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div className=" mb-4 mt-6">
                          <button
                            className="btn-primary m-0 w-full px-16"
                            type="submit"
                            style={{ backgroundColor: '#6638ff', borderColor: '#6638ff' }}>
                            Login
                          </button>
                        </div>
                        {/* <div className="text-center">
                          <p className="mb-0 mr-2">
                            New to our platform?
                            <a href="#" className="ml-2 site-clr">
                              Create an account
                            </a>
                          </p>
                        </div> */}
                      </form>
                    </div>

                    {/* <div className="text-center p-1">
                      <p className="mb-0 mr-2">
                        New to our platform?
                        <a href="#" className="ml-2 site-clr">
                          Create an account
                        </a>
                      </p>
                    </div> */}
                  </div>
                  {/* <div
                    className="lg:w-6/12 bg-cover relative right-card-img"
                    style={{
                      backgroundImage: `url("../../assets/img/login-img.png")`
                    }}>
                    <div className="h-full w-full text-center">
                      <p className="absolute bottom-16 text-white w-full">
                        Get your videos working for you
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
