import { VideosActions } from './actions';
import { RootState } from '../reducers/state';
import { GetLiveStreamModel, EnterLiveMeetingModel, LiveMeetingModel, VideoStatus, DiscountCodeModel, AccessibilityModel, AddResourceUploadModel, MemberListAccess, PlayPageModel, CallToActionModel, VideoAnalyticsDataModel, QuizModel, ResourcesModel, SingleVideoModel, VideoMediaModel, PollModel, MultiChoiceModel, StarRatingModel, OpenTextModel, LiveEventUrlModel, ProductAccessLiveMeetingModel } from 'src/models/videosModel';
import { PaginationModel } from 'src/models/paginationModel';
import { VideosStateModel, InterActivityModel } from 'src/models/videosModel';
import { Actions } from 'src/models/videosModel';
import * as Constants from './constants'
const initialState: RootState.VideosState = {
    videos: [],
    attachmentId: '',
    discountCode: [],
    videoSlide: [],
    liveViewers: [],
    liveViewersCount: 0,
    singleDiscountCode: {} as DiscountCodeModel,
    singleVideo: {} as SingleVideoModel,
    videoAnalyticsData: {} as VideoAnalyticsDataModel,
    liveStartTime: '',
    videoAnalyticsChartData: [],
    removeMediaUrl: '',
    mediaUrl: '',
    archiveUrl: '',
    liveMeeting: {} as LiveMeetingModel,
    addResourceUpload: {} as AddResourceUploadModel,
    presentationId: '',
    liveStudio: {} as EnterLiveMeetingModel,
    interactivity: {} as InterActivityModel,
    accessibility: {} as AccessibilityModel,
    quiz: {} as QuizModel,
    multiChoice: {} as MultiChoiceModel,
    starRating: {} as StarRatingModel,
    openText: {} as OpenTextModel,
    playPage: {} as PlayPageModel,
    singleResource: {} as ResourcesModel,
    videoMedia: {} as VideoMediaModel,
    analyticsLiveView: [],
    liveEventUrl: {} as LiveEventUrlModel,
    videoStatus: 0,
    analyticsLiveViewChartData: [],
    pollsTemplate: [],
    pollsResult: {} as PollModel,
    authKey: '',
    questions: [],
    polls: [],
    slides: [],
    videoViewingSession: [],
    callToAction: [],
    singleCallToAction: {} as CallToActionModel,
    moderators: [],
    memberListPrice: [],
    resources: [],
    memberListAccess: [],
    videoAnalytics: [],
    speakers: [],
    liveMedia: [],
    videoModule: [],
    videoId: '',
    uploadId: '',
    uploadUrl: '',
    fileNames: '',
    liveStreamId: {} as GetLiveStreamModel,
    memberId: '',
    message: '',
    mediaChannelStatus: '',
    mediaChannelInUse: false,
    spotLightRowVideo: [],
    videoSlides: [],
    paginationMeta: {} as PaginationModel,
    videoAnalyticsTable: [],
    videoAnalyticsTableDownload: [],
    navigationMenu: [],
    totalRecords: [],
    isHighLightReelSaved: false,
    isAuthKeySaved: false,
    isRedirectToLoginSaved: false,
    isVideoLibraryLayoutSaved: false,
    isVideoAdded: false,
    isVideoDeleted: false,
    isVideoUploaded: false,
    isCompleteUploaded: false,
    isNavigationMenuAdded: false,
    isNavigationMenuEdited: false,
    isNavigationMenuDeleted: false,
    isVideoAddedLoading: false,
    isCancelUpload: false,
    isSpeakerAdded: false,
    isSearchVideoLoading: false,
    isVideoSlideSaved: false,
    isVideoAnalyticsTableLoading: false,
    isVideoSlideUpdated: false,
    isVideoEdited: false,
    error: false,
    loading: false,
    isAddLiveStreamLoading: false,
    isVideoEditedLoading: false,
    isSingleVideoLoading: false,
    isEditVideoError: false,
    isLiveStreamError: false,
    isSaved: false,
    isSavedLoading: false,
    isCallToActionSaved: false,
    isCallToActionLoading: false,
    isResourceAdded: false,
    isResourceUpdated: false,
    isResourceDeleted: false,
    isSlideSaved: false,
    isMemberListAccessSaved: false,
    isMemberListPriceSaved: false,
    isMemberListModeratorSaved: false,
    isPollSaved: false,
    isQuizSaved: false,
    isQuizLoading: false,
    isMultiChoiceSaved: false,
    isMultiChoiceLoading: false,
    isStarRatingSaved: false,
    isStarRatingLoading: false,
    isOpenTextSaved: true,
    isOpenTextLoading: false,
    isGetInteractivityLoading: false,
    isGetResourceLoading: false,
    isGetAccessibilityLoading: false,
    isGetPlayPageLoading: false,
    isGetMemberAccessLoading: false,
    isGetMemberAccessPriceLoading: false,
    isGetModeratorLoading: false,
    isVideoAnalyticsDataLoading: false,
    isVideoAnalyticsUpdated: false,
    isPlayPageUpdated: false,
    isAccessibilityUpdated: false,
    success: false,
    isPollResultLoading: false,
    isThumbnailUpdated: false,
    isPollSavedLoading: false,
    isPollDeleted: false,
    isPollStatusUpdated: false,
    isDiscountCodeAdded: false,
    isLiveMeetingStarted: false,
    isDiscountCodeLoading: false,
    isSuccess: false,
    isMediaStreamStarted: false,
    isVideoStatusUpdated: false,
    isVideoSlideLoading: false,
    isStartLiveMeetingLoading: false,
    isLiveStreamSourceUpdated: false,
    isResourceUploaded: false,
    isProductAccess: {} as ProductAccessLiveMeetingModel,
    resourceId: ''
};


function videosReducer(state: VideosStateModel = initialState, action: Actions): VideosStateModel {
    switch (action.type) {
        case Constants.getVideoAnalyticsRequestActionType: return getVideoAnalyticsAction(state);
        case Constants.getVideoAnalyticsRequestSuccessActionType: return getVideoAnalyticsSuccessAction(state, action);
        case Constants.getVideoAnalyticsRequestFailureActionType: return getVideoAnalyticsFailureAction(state);
        case Constants.getVideosRequestActionType: return getVideosAction(state);
        case Constants.getVideosRequestSuccessActionType: return getVideosSuccessAction(state, action);
        case Constants.getVideosRequestFailureActionType: return getVideosFailureAction(state);
        case Constants.addVideoRequestActionType: return addVideoAction(state);
        case Constants.addVideoRequestSuccessActionType: return addVideoSuccessAction(state, action);
        case Constants.addVideoRequestFailureActionType: return addVideoFailureAction(state);
        case Constants.getSingleVideoRequestActionType: return getSingleVideoAction(state);
        case Constants.getSingleVideoRequestSuccessActionType: return getSingleVideoSuccessAction(state, action);
        case Constants.getSingleVideoRequestFailureActionType: return getSingleVideoFailureAction(state);
        case Constants.deleteVideoRequestActionType: return deleteVideoAction(state);
        case Constants.deleteVideoRequestSuccessActionType: return deleteVideoSuccessAction(state);
        case Constants.deleteVideoRequestFailureActionType: return deleteVideoFailureAction(state);
        case Constants.uploadVideoRequestActionType: return uploadVideoAction(state);
        case Constants.uploadVideoRequestSuccessActionType: return uploadVideoSuccessAction(state, action);
        case Constants.uploadVideoRequestFailureActionType: return uploadVideoFailureAction(state);
        case Constants.addLiveStreamRequestActionType: return addLiveStreamAction(state);
        case Constants.addLiveStreamSuccessActionType: return addLiveStreamSuccessAction(state, action);
        case Constants.addLiveStreamFailureActionType: return addLiveStreamFailureAction(state);
        case Constants.getuploadPartUrlRequestActionType: return getUploadPartUrlAction(state);
        case Constants.getuploadPartUrlRequestSuccessActionType: return getUploadPartSuccessAction(state, action);
        case Constants.getuploadPartUrlRequestFailureActionType: return getUploadPartFailureAction(state);
        case Constants.cancelUploadRequestActionType: return cancelUploadVideoAction(state);
        case Constants.cancelUploadRequestSuccessActionType: return cancelUploadVideoSuccessAction(state);
        case Constants.cancelUploadRequestFailureActionType: return cancelUploadVideoFailureAction(state);
        case Constants.addSpeakerRequestActionType: return addSpeakerAction(state);
        case Constants.addSpeakerRequestSuccessActionType: return addSpeakerSuccessAction(state);
        case Constants.addSpeakerRequestFailureActionType: return addSpeakerFailureAction(state);
        case Constants.searchVideoRequestActionType: return searchVideoAction(state);
        case Constants.searchVideoRequestSuccessActionType: return searchVideoSuccessAction(state, action);
        case Constants.searchVideoRequestFailureActionType: return searchVideoFailureAction(state);
        case Constants.getVideoSlidesRequestActionType: return getVideoSlidesAction(state);
        case Constants.getVideoSlidesRequestSuccessActionType: return getVideoSlidesSuccessAction(state, action);
        case Constants.getVideoSlidesRequestFailureActionType: return getVideoSlidesFailureAction(state);
        case Constants.saveVideoSlidesRequestActionType: return saveVideoSlidesAction(state);
        case Constants.saveVideoSlidesRequestSuccessActionType: return saveVideoSlidesSuccessAction(state);
        case Constants.saveVideoSlidesRequestFailureActionType: return saveVideoSlidesFailureAction(state);
        case Constants.updateVideoSlidesRequestActionType: return updateVideoSlidesAction(state);
        case Constants.updateVideoSlidesRequestSuccessActionType: return updateVideoSlidesSuccessAction(state);
        case Constants.updateVideoSlidesRequestFailureActionType: return updateVideoSlidesFailureAction(state);
        case Constants.editVideoRequestActionType: return editVideoAction(state);
        case Constants.editVideoRequestSuccessActionType: return editVideoSuccessAction(state);
        case Constants.editVideoRequestFailureActionType: return editVideoFailureAction(state);
        case Constants.getSpeakersRequestActionType: return getSpeakersAction(state);
        case Constants.getSpeakersRequestSuccessActionType: return getSpeakersSuccessAction(state, action);
        case Constants.getSpeakersRequestFailureActionType: return getSpeakersFailureAction(state);
        case Constants.getInterActivityRequestActionType: return getInterActivityAction(state);
        case Constants.getInterActivityRequestSuccessActionType: return getInterActivitySuccessAction(state, action);
        case Constants.getInterActivityRequestFailureActionType: return getInterActivityFailureAction(state);
        case Constants.updateInterActivityRequestActionType: return updateInterActivityAction(state);
        case Constants.updateInterActivityRequestSuccessActionType: return updateInterActivitySuccessAction(state);
        case Constants.updateInterActivityRequestFailureActionType: return updateInterActivityFailureAction(state);
        case Constants.getAccessibilityRequestActionType: return getAccessibilityAction(state);
        case Constants.getAccessibilityRequestSuccessActionType: return getAccessibilitySuccessAction(state, action);
        case Constants.getAccessibilityRequestFailureActionType: return getAccessibilityFailureAction(state);
        case Constants.updateAccessibilityRequestActionType: return updateAccessibilityAction(state);
        case Constants.updateAccessibilityRequestSuccessActionType: return updateAccessibilitySuccessAction(state);
        case Constants.updateAccessibilityRequestFailureActionType: return updateAccessibilityFailureAction(state);
        case Constants.getPlayPageRequestActionType: return getPlayPageAction(state);
        case Constants.getPlayPageRequestSuccessActionType: return getPlayPageSuccessAction(state, action);
        case Constants.getPlayPageRequestFailureActionType: return getPlayPageFailureAction(state);
        case Constants.updatePlayPageRequestActionType: return updatePlayPageAction(state);
        case Constants.updatePlayPageRequestSuccessActionType: return updatePlayPageSuccessAction(state);
        case Constants.updatePlayPageRequestFailureActionType: return updatePlayPageFailureAction(state);
        case Constants.getMemberListAccessRequestActionType: return getMemberListAccessAction(state);
        case Constants.getMemberListAccessSuccessActionType: return getMemberListAccessSuccessAction(state, action);
        case Constants.getMemberListAccessRequestFailureActionType: return getMemberListAccessFailureAction(state);
        case Constants.saveMemberListAccessRequestActionType: return saveMemberListAccessAction(state);
        case Constants.saveMemberListAccessSuccessActionType: return saveMemberListAccessSuccessAction(state);
        case Constants.saveMemberListAccessRequestFailureActionType: return saveMemberListAccessFailureAction(state);
        case Constants.deleteMemberListAccessRequestActionType: return deleteMemberListAccessAction(state);
        case Constants.deleteMemberListAccessSuccessActionType: return deleteMemberListAccessSuccessAction(state);
        case Constants.deleteMemberListAccessRequestFailureActionType: return deleteMemberListAccessFailureAction(state);
        case Constants.getResourcesRequestActionType: return getResourcesAction(state);
        case Constants.getResourcesRequestSuccessActionType: return getResourcesSuccessAction(state, action);
        case Constants.getResourcesRequestFailureActionType: return getResourcesFailureAction(state);
        case Constants.addResourcesRequestActionType: return addResourcesAction(state);
        case Constants.addResourcesRequestSuccessActionType: return addResourcesSuccessAction(state);
        case Constants.addResourcesRequestFailureActionType: return addResourcesFailureAction(state);
        case Constants.editResourcesRequestActionType: return editResourcesAction(state);
        case Constants.editResourcesRequestSuccessActionType: return editResourcesSuccessAction(state);
        case Constants.editResourcesRequestFailureActionType: return editResourcesFailureAction(state);
        case Constants.getSingleResourcesRequestActionType: return getSingleResourcesAction(state);
        case Constants.getSingleResourcesRequestSuccessActionType: return getSingleResourcesSuccessAction(state, action);
        case Constants.getSingleResourcesRequestFailureActionType: return getSingleResourcesFailureAction(state);
        case Constants.deleteResourcesRequestActionType: return deleteResourcesAction(state);
        case Constants.deleteResourcesRequestSuccessActionType: return deleteResourcesSuccessAction(state);
        case Constants.deleteResourcesRequestFailureActionType: return deleteResourcesFailureAction(state);
        case Constants.getMemberListPriceRequestActionType: return getMemberListPriceAction(state);
        case Constants.getMemberListPriceSuccessActionType: return getMemberListPriceSuccessAction(state, action);
        case Constants.getMemberListPriceRequestFailureActionType: return getMemberListPriceFailureAction(state);
        case Constants.saveMemberListPriceRequestActionType: return saveMemberListPriceAction(state);
        case Constants.saveMemberListPriceRequestSuccessActionType: return saveMemberListPriceSuccessAction(state);
        case Constants.saveMemberListPriceRequestFailureActionType: return saveMemberListPriceFailureAction(state);
        case Constants.deleteMemberListPriceRequestActionType: return deleteMemberListPriceAction(state);
        case Constants.deleteMemberListPriceRequestSuccessActionType: return deleteMemberListPriceSuccessAction(state);
        case Constants.deleteMemberListPriceRequestFailureActionType: return deleteMemberListPriceFailureAction(state);
        case Constants.getModeratorRequestActionType: return getModeratorAction(state);
        case Constants.getModeratorRequestSuccessActionType: return getModeratorSuccessAction(state, action);
        case Constants.getModeratorRequestFailureActionType: return getModeratorFailureAction(state);
        case Constants.saveModeratorsRequestActionType: return saveModeratorsAction(state);
        case Constants.saveModeratorsRequestSuccessActionType: return saveModeratorsSuccessAction(state);
        case Constants.saveModeratorsRequestFailureActionType: return saveModeratorsFailureAction(state);
        case Constants.deleteModeratorsRequestActionType: return deleteModeratorsAction(state);
        case Constants.deleteModeratorsRequestSuccessActionType: return deleteModeratorsSuccessAction(state);
        case Constants.deleteModeratorsRequestFailureActionType: return deleteModeratorsFailureAction(state);
        case Constants.getCallToActionRequestActionType: return getCallToActionAction(state);
        case Constants.getCallToActionRequestSuccessActionType: return getCallToActionSuccessAction(state, action);
        case Constants.getCallToActionRequestFailureActionType: return getCallToActionFailureAction(state);
        case Constants.saveCallToActionRequestActionType: return saveCallToActionAction(state);
        case Constants.saveCallToActionRequestSuccessActionType: return saveCallToActionSuccessAction(state);
        case Constants.saveCallToActionRequestFailureActionType: return saveCallToActionFailureAction(state);
        case Constants.deleteCallToActionRequestActionType: return deleteCallToActionAction(state);
        case Constants.deleteCallToActionRequestSuccessActionType: return deleteCallToActionSuccessAction(state);
        case Constants.deleteCallToActionRequestFailureActionType: return deleteCallToActionFailureAction(state);
        case Constants.getSingleCallToActionRequestActionType: return getSingleCallToActionAction(state);
        case Constants.getSingleCallToActionRequestSuccessActionType: return getSingleCallToActionSuccessAction(state, action);
        case Constants.getSingleCallToActionRequestFailureActionType: return getSingleCallToActionFailureAction(state);
        case Constants.updateCallToActionRequestActionType: return updateCallToActionAction(state);
        case Constants.updateCallToActionRequestSuccessActionType: return updateCallToActionSuccessAction(state);
        case Constants.updateCallToActionRequestFailureActionType: return updateCallToActionFailureAction(state);
        case Constants.getVideoViewingSessionRequestActionType: return getVideoViewingSessionAction(state);
        case Constants.getVideoViewingSessionRequestSuccessActionType: return getVideoViewingSessionSuccessAction(state, action);
        case Constants.getVideoViewingSessionRequestFailureActionType: return getVideoViewingSessionFailureAction(state);
        case Constants.saveSlidesRequestActionType: return saveSlidesAction(state);
        case Constants.saveSlidesRequestSuccessActionType: return saveSlidesSuccessAction(state);
        case Constants.saveSlidesRequestFailureActionType: return saveSlidesFailureAction(state);
        case Constants.getSlidesRequestActionType: return getSlidesAction(state);
        case Constants.getSlidesRequestSuccessActionType: return getSlidesSuccessAction(state, action);
        case Constants.getSlidesRequestFailureActionType: return getSlidesFailureAction(state);
        case Constants.updateSlidesRequestActionType: return updateSlidesAction(state);
        case Constants.updateSlidesRequestSuccessActionType: return updateSlidesSuccessAction(state);
        case Constants.updateSlidesRequestFailureActionType: return updateSlidesFailureAction(state);
        case Constants.savePollRequestActionType: return savePollAction(state);
        case Constants.savePollRequestSuccessActionType: return savePollSuccessAction(state);
        case Constants.savePollRequestFailureActionType: return savePollFailureAction(state);
        case Constants.getPollRequestActionType: return getPollAction(state);
        case Constants.getPollRequestSuccessActionType: return getPollSuccessAction(state, action);
        case Constants.getPollRequestFailureActionType: return getPollFailureAction(state);
        case Constants.updatePollRequestActionType: return updatePollAction(state);
        case Constants.updatePollRequestSuccessActionType: return updatePollSuccessAction(state);
        case Constants.updatePollRequestFailureActionType: return updatePollFailureAction(state);
        case Constants.getPollsTemplatesRequestActionType: return getPollsTemplatesAction(state);
        case Constants.getPollsTemplatesSuccessActionType: return getPollsTemplatesSuccessAction(state, action);
        case Constants.getPollsTemplatesFailureActionType: return getPollsTemplatesFailureAction(state);
        case Constants.saveQuestionRequestActionType: return saveQuestionAction(state);
        case Constants.saveQuestionRequestSuccessActionType: return saveQuestionSuccessAction(state);
        case Constants.saveQuestionRequestFailureActionType: return saveQuestionFailureAction(state);
        case Constants.updateQuestionRequestActionType: return updateQuestionAction(state);
        case Constants.updateQuestionRequestSuccessActionType: return updateQuestionSuccessAction(state);
        case Constants.updateQuestionRequestFailureActionType: return updateQuestionFailureAction(state);
        case Constants.getQuestionRequestActionType: return getQuestionAction(state);
        case Constants.getQuestionRequestSuccessActionType: return getQuestionSuccessAction(state, action);
        case Constants.getQuestionRequestFailureActionType: return getQuestionFailureAction(state);
        case Constants.getVideoMediaRequestActionType: return getVideoMediaAction(state);
        case Constants.getVideoMediaRequestSuccessActionType: return getVideoMediaSuccessAction(state, action);
        case Constants.getVideoMediaRequestFailureActionType: return getVideoMediaFailureAction(state);
        case Constants.saveVideoMediaRequestActionType: return saveVideoMediaAction(state);
        case Constants.saveVideoMediaRequestSuccessActionType: return saveVideoMediaSuccessAction(state);
        case Constants.saveVideoMediaRequestFailureActionType: return saveVideoMediaFailureAction(state);
        case Constants.updateVideoMediaRequestActionType: return updateVideoMediaAction(state);
        case Constants.updateVideoMediaRequestSuccessActionType: return updateVideoMediaSuccessAction(state);
        case Constants.updateVideoMediaRequestFailureActionType: return updateVideoMediaFailureAction(state);
        case Constants.getPollsResultRequestActionType: return getPollsResultAction(state);
        case Constants.getPollsResultRequestSuccessActionType: return getPollsResultSuccessAction(state, action);
        case Constants.getPollsResultRequestFailureActionType: return getPollsResultFailureAction(state);
        case Constants.resetVideoLibraryStateRequestActionType: return resetVideoLibraryStateAction(state);
        case Constants.saveQuizRequestActionType: return saveQuizAction(state);
        case Constants.saveQuizRequestSuccessActionType: return saveQuizSuccessAction(state);
        case Constants.saveQuizRequestFailureActionType: return saveQuizFailureAction(state);
        case Constants.getQuizRequestActionType: return getQuizAction(state);
        case Constants.getQuizRequestSuccessActionType: return getQuizSuccessAction(state, action);
        case Constants.getQuizRequestFailureActionType: return getQuizFailureAction(state);
        case Constants.saveMultiChoiceRequestActionType: return saveMultiChoiceAction(state);
        case Constants.saveMultiChoiceRequestSuccessActionType: return saveMultiChoiceSuccessAction(state);
        case Constants.saveMultiChoiceRequestFailureActionType: return saveMultiChoiceFailureAction(state);
        case Constants.getMultiChoiceRequestActionType: return getMultiChoiceAction(state);
        case Constants.getMultiChoiceRequestSuccessActionType: return getMultiChoiceSuccessAction(state, action);
        case Constants.getMultiChoiceRequestFailureActionType: return getMultiChoiceFailureAction(state);
        case Constants.saveStarRatingRequestActionType: return saveStarRatingAction(state);
        case Constants.saveStarRatingRequestSuccessActionType: return saveStarRatingSuccessAction(state);
        case Constants.saveStarRatingRequestFailureActionType: return saveStarRatingFailureAction(state);
        case Constants.getStarRatingRequestActionType: return getStarRatingAction(state);
        case Constants.getStarRatingRequestSuccessActionType: return getStarRatingSuccessAction(state, action);
        case Constants.getStarRatingRequestFailureActionType: return getStarRatingFailureAction(state);
        case Constants.saveOpenTextRequestActionType: return saveOpenTextAction(state);
        case Constants.saveOpenTextRequestSuccessActionType: return saveOpenTextSuccessAction(state);
        case Constants.saveOpenTextRequestFailureActionType: return saveOpenTextFailureAction(state);
        case Constants.getOpenTextRequestActionType: return getOpenTextAction(state);
        case Constants.getOpenTextRequestSuccessActionType: return getOpenTextSuccessAction(state, action);
        case Constants.getOpenTextRequestFailureActionType: return getOpenTextFailureAction(state);
        case Constants.getVideoAnalyticsDataRequestActionType: return getVideoAnalyticsDataAction(state);
        case Constants.getVideoAnalyticsDataRequestSuccessActionType: return getVideoAnalyticsDataSuccessAction(state, action);
        case Constants.getVideoAnalyticsDataRequestFailureActionType: return getVideoAnalyticsDataFailureAction(state);
        case Constants.getVideoAnalyticsChartDataRequestActionType: return getVideoAnalyticsChartDataAction(state);
        case Constants.getVideoAnalyticsChartDataRequestSuccessActionType: return getVideoAnalyticsChartDataSuccessAction(state, action);
        case Constants.getVideoAnalyticsChartDataRequestFailureActionType: return getVideoAnalyticsChartFailureAction(state);
        case Constants.savePollTemplateRequestActionType: return savePollTemplateAction(state);
        case Constants.savePollTemplateRequestSuccessActionType: return savePollTemplateSuccessAction(state);
        case Constants.savePollTemplateRequestFailureActionType: return savePollTemplateRequestFailureActionType(state)
        case Constants.generateAuthKeyRequestActionType: return generateAuthKeyAction(state);
        case Constants.generateAuthKeyRequestSuccessActionType: return generateAuthKeySuccessAction(state, action);
        case Constants.generateAuthKeyRequestFailureActionType: return generateAuthKeyFailureAction(state);
        case Constants.updateRedirectToLoginRequestActionType: return updateRedirectToLoginAction(state);
        case Constants.updateRedirectToLoginRequestSuccessActionType: return updateRedirectToLoginSuccessAction(state);
        case Constants.updateRedirectToLoginRequestFailureActionType: return updateRedirectToLoginFailureAction(state);
        case Constants.resetRedirectToLoginRequestActionType: return resetRedirectToLoginAction(state);
        case Constants.updateVideoAnalyticsRequestActionType: return updateVideoAnalyticsAction(state);
        case Constants.updateVideoAnalyticsRequestSuccessActionType: return updateVideoAnalyticsSuccessAction(state);
        case Constants.updateVideoAnalyticsRequestFailureActionType: return updateVideoAnalyticsFailureAction(state);
        case Constants.getVideoAnalyticsLiveViewsRequestActionType: return getVideoAnalyticsLiveViewsAction(state);
        case Constants.getVideoAnalyticsLiveViewsRequestSuccessActionType: return getVideoAnalyticsLiveViewsSuccessAction(state, action);
        case Constants.getVideoAnalyticsLiveViewsRequestFailureActionType: return getVideoAnalyticsLiveViewsFailureAction(state);
        case Constants.getVideoAnalyticsLiveViewsChartDataRequestActionType: return getVideoAnalyticsLiveViewsChartDataAction(state);
        case Constants.getVideoAnalyticsLiveViewsChartDataRequestSuccessActionType: return getVideoAnalyticsLiveViewsChartDataSuccessAction(state, action);
        case Constants.getVideoAnalyticsLiveViewsChartDataRequestFailureActionType: return getVideoAnalyticsLiveViewsChartDataFailureAction(state);
        case Constants.updateVideoThumbnailRequestActionType: return updateVideoThumbnailAction(state);
        case Constants.updateVideoThumbnailRequestSuccessActionType: return updateVideoThumbnailSuccessAction(state);
        case Constants.updateVideoThumbnailRequestFailureActionType: return updateVideoThumbnailFailureAction(state);
        case Constants.resetAuthKeyRequestActionType: return resetAuthKeyAction(state);
        case Constants.resetAttachmentIdRequestActionType: return resetAttachmentIdAction(state);
        case Constants.getLiveEventUrlRequestActionType: return getLiveEventUrlAction(state);
        case Constants.getLiveEventUrlRequestSuccessActionType: return getLiveEventUrlSuccessAction(state, action);
        case Constants.getLiveEventUrlRequestFailureActionType: return getLiveEventUrlFailureAction(state);
        case Constants.getLiveMediaRequestActionType: return getLiveMediaAction(state);
        case Constants.getLiveMediaRequestSuccessActionType: return getLiveMediaSuccessAction(state, action);
        case Constants.getLiveMediaRequestFailureActionType: return getLiveMediaFailureAction(state);
        case Constants.deletePollRequestActionType: return deletePollAction(state);
        case Constants.deletePollRequestSuccessActionType: return deletePollSuccessAction(state);
        case Constants.deletePollRequestFailureActionType: return deletePollFailureAction(state);
        case Constants.updatePollStatusRequestActionType: return updatePollStatusAction(state);
        case Constants.updatePollStatusRequestSuccessActionType: return updatePollStatusSuccessAction(state);
        case Constants.updatePollStatusRequestFailureActionType: return updatePollStatusFailureAction(state);
        case Constants.getDiscountCodeRequestActionType: return getDiscountCodeAction(state);
        case Constants.getDiscountCodeRequestSuccessActionType: return getDiscountCodeSuccessAction(state, action);
        case Constants.getDiscountCodeRequestFailureActionType: return getDiscountCodeFailureAction(state);
        case Constants.saveDiscountCodeRequestActionType: return saveDiscountCodeAction(state);
        case Constants.saveDiscountCodeRequestSuccessActionType: return saveDiscountCodeSuccessAction(state);
        case Constants.saveDiscountCodeRequestFailureActionType: return saveDiscountCodeFailureAction(state);
        case Constants.updateDiscountCodeRequestActionType: return updateDiscountCodeAction(state);
        case Constants.updateDiscountCodeRequestSuccessActionType: return updateDiscountCodeSuccessAction(state);
        case Constants.updateDiscountCodeRequestFailureActionType: return updateDiscountCodeFailureAction(state);
        case Constants.deleteDiscountCodesRequestActionType: return deleteDiscountCodesAction(state);
        case Constants.deleteDiscountCodesRequestSuccessActionType: return deleteDiscountCodesSuccessAction(state);
        case Constants.deleteDiscountCodesRequestFailureActionType: return deleteDiscountCodesFailureAction(state);
        case Constants.getSingleDiscountCodeRequestActionType: return getSingleDiscountCodeAction(state);
        case Constants.getSingleDiscountCodeRequestSuccessActionType: return getSingleDiscountCodeSuccessAction(state, action);
        case Constants.getSingleDiscountCodeRequestFailureActionType: return getSingleDiscountCodeFailureAction(state);
        case Constants.startMediaChannelRequestActionType: return startMediaChannelRequestAction(state);
        case Constants.startMediaChannelRequestSuccessActionType: return startMediaChannelRequestSuccessAction(state);
        case Constants.startMediaChannelRequestFailureActionType: return startMediaChannelRequestFailureAction(state);
        case Constants.startMediaStreamRequestActionType: return startMediaStreamRequestAction(state);
        case Constants.startMediaStreamRequestSuccessActionType: return startMediaStreamRequestSuccessAction(state);
        case Constants.startMediaStreamRequestFailureActionType: return startMediaStreamRequestFailureAction(state);
        case Constants.stopMediaStreamRequestActionType: return stopMediaStreamRequestAction(state);
        case Constants.stopMediaStreamRequestSuccessActionType: return stopMediaStreamRequestSuccessAction(state, action);
        case Constants.stopMediaStreamRequestFailureActionType: return stopMediaStreamRequestFailureAction(state);
        case Constants.mediaChannelStatusRequestActionType: return mediaChannelStatusRequestAction(state);
        case Constants.mediaChannelStatusRequestSuccessActionType: return mediaChannelStatusRequestSuccessAction(state, action);
        case Constants.mediaChannelStatusRequestFailureActionType: return mediaChannelStatusRequestFailureAction(state);
        case Constants.updateVideoStatusRequestActionType: return updateVideoStatusAction(state);
        case Constants.updateVideoStatusRequestSuccessActionType: return updateVideoStatusSuccessAction(state);
        case Constants.updateVideoStatusRequestFailureActionType: return updateVideoStatusFailureAction(state);
        case Constants.getVideoStatusRequestActionType: return getVideoStatusAction(state);
        case Constants.getVideoStatusRequestSuccessActionType: return getVideoStatusSuccessAction(state, action);
        case Constants.getVideoStatusRequestFailureActionType: return getVideoStatusFailureAction(state);
        case Constants.getVideoModulesRequestActionType: return getVideoModulesAction(state);
        case Constants.getVideoModulesRequestSuccessActionType: return getVideoModulesSuccessAction(state, action);
        case Constants.getVideoModulesRequestFailureActionType: return getVideoModulesFailureAction(state);
        case Constants.startLiveMeetingRequestActionType: return startLiveMeetingAction(state);
        case Constants.startLiveMeetingRequestSuccessActionType: return startLiveMeetingSuccessAction(state, action);
        case Constants.startLiveMeetingRequestFailureActionType: return startLiveMeetingFailureAction(state);
        case Constants.endLiveMeetingRequestActionType: return endLiveMeetingAction(state);
        case Constants.endLiveMeetingRequestSuccessActionType: return endLiveMeetingSuccessAction(state, action);
        case Constants.endLiveMeetingRequestFailureActionType: return endLiveMeetFailureAction(state);
        case Constants.enterLiveStudioRequestActionType: return enterLiveStudioAction(state);
        case Constants.enterLiveStudioRequestSuccessActionType: return enterLiveStudioSuccessAction(state, action);
        case Constants.enterLiveStudioRequestFailureActionType: return enterLiveStudioFailureAction(state);
        case Constants.getProductAccessRequestActionType: return getProductAccessAction(state);
        case Constants.getProductAccessRequestSuccessActionType: return getProductAccessSuccessAction(state, action);
        case Constants.getProductAccessRequestFailureActionType: return getProductAccessFailureAction(state);
        case Constants.getVideoAnalyticsTableRequestActionType: return getVideoAnalyticsTableAction(state);
        case Constants.getVideoAnalyticsTableRequestSuccessActionType: return getVideoAnalyticsTableSuccessAction(state, action);
        case Constants.getVideoAnalyticsTableRequestFailureActionType: return getVideoAnalyticsTableFailureAction(state);
        case Constants.getVideoAnalyticsTableDownloadRequestActionType: return getVideoAnalyticsTableDownloadAction(state);
        case Constants.getVideoAnalyticsTableDownloadRequestSuccessActionType: return getVideoAnalyticsTableDownloadSuccessAction(state, action);
        case Constants.getVideoAnalyticsTableDownloadRequestFailureActionType: return getVideoAnalyticsTableDownloadFailureAction(state);
        case Constants.removeMediaUrlRequestActionType: return removeMediaUrlAction(state);
        case Constants.removeMediaUrlRequestSuccessActionType: return removeMediaUrlSuccessAction(state, action);
        case Constants.removeMediaUrlRequestFailureActionType: return removeMediaUrlFailureAction(state);
        case Constants.getMediaUrlRequestActionType: return getMediaUrlAction(state);
        case Constants.getMediaUrlRequestSuccessActionType: return getMediaUrlSuccessAction(state, action);
        case Constants.getMediaUrlRequestFailureActionType: return getMediaUrlFailureAction(state);
        case Constants.getLiveViewersRequestActionType: return getLiveViewersAction(state);
        case Constants.getLiveViewersRequestSuccessActionType: return getLiveViewersSuccessAction(state, action);
        case Constants.getLiveViewersRequestFailureActionType: return getLiveViewersFailureAction(state);
        case Constants.getLiveViewersCountRequestActionType: return getLiveViewersCountAction(state);
        case Constants.getLiveViewersCountRequestSuccessActionType: return getLiveViewersCountSuccessAction(state, action);
        case Constants.getLiveViewersCountRequestFailureActionType: return getLiveViewersCountFailureAction(state);
        case Constants.updateLiveStreamSourceRequestActionType: return updateLiveStreamSourceAction(state);
        case Constants.updateLiveStreamSourceRequestSuccessActionType: return updateLiveStreamSourceSuccessAction(state, action);
        case Constants.updateLiveStreamSourceRequestFailureActionType: return updateLiveStreamSourceFailureAction(state);
        case Constants.addResourceUploadRequestActionType: return addResourceUploadAction(state);
        case Constants.addResourceUploadRequestSuccessActionType: return addResourceUploadSuccessAction(state, action);
        case Constants.addResourceUploadRequestFailureActionType: return addResourceUploadFailureAction(state);
        case Constants.getResourceDetailRequestActionType: return getResourceDetailAction(state);
        case Constants.getResourceDetailRequestSuccessActionType: return getResourceDetailSuccessAction(state);
        case Constants.getResourceDetailRequestFailureActionType: return getResourceDetailFailureAction(state);
        case Constants.getArchiveUrlRequestActionType: return getArchiveUrlAction(state);
        case Constants.getArchiveUrlRequestSuccessActionType: return getArchiveUrlSuccessAction(state, action);
        case Constants.getArchiveUrlRequestFailureActionType: return getArchiveUrlFailureAction(state);
        case Constants.resetEnterLiveStudioRequestActionType: return resetEnterLiveStudioAction(state);
        case Constants.resetResourceUploadActionType: return resetResourceUploadAction(state);
        default: return state;
    }

    function savePollTemplateAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function savePollTemplateSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            isPollSaved: true,
            loading: false
        };
    }
    function savePollTemplateRequestFailureActionType(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSaved: false,
            loading: false,
            error: true
        }
    }

    function getVideoAnalyticsAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoAnalyticsSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoAnalytics: action.payload
            };
        }
        return state;
    }
    function getVideoAnalyticsFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getVideosAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideosSuccessAction(state: VideosStateModel, action: VideosActions.getVideosRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videos: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getVideosFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function addVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAddedLoading: true,
        };
    }
    function addVideoSuccessAction(state: VideosStateModel, action: VideosActions.addVideoRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                isVideoAdded: true,
                videoId: action.payload,
                isVideoAddedLoading: false
            }
        }
        return state;
    }
    function addVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAdded: false,
            error: true
        }
    }
    function getSingleVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSingleVideoLoading: true,
        };
    }
    function getSingleVideoSuccessAction(state: VideosStateModel, action: VideosActions.getSingleVideoRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                singleVideo: action.payload,
                isSingleVideoLoading: false
            };
        }
        return state;
    }
    function getSingleVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSingleVideoLoading: false
        }
    }
    function deleteVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteVideoSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isVideoDeleted: true,
            message: 'Success',
            loading: false
        };
    }
    function deleteVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoDeleted: false,
            loading: false,
            error: true
        }
    }
    function uploadVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function uploadVideoSuccessAction(state: VideosStateModel, action: VideosActions.uploadVideoRequestSuccessAction): VideosStateModel {

        if (action.payload) {
            return {
                ...state,
                isVideoUploaded: true,
                uploadId: action.payload.uploadId,
                fileNames: action.payload.fileName,
                loading: false
            }
        }
        return state;
    }
    function uploadVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoUploaded: false,
            loading: false
        }
    }
    function addLiveStreamAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isAddLiveStreamLoading: true,
        };
    }
    function addLiveStreamSuccessAction(state: VideosStateModel, action: VideosActions.addLiveStreamRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                isVideoUploaded: true,
                message: 'Success',
                liveStreamId: action.payload,
                isAddLiveStreamLoading: false
            };
        }
        return state;
    }
    function addLiveStreamFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoUploaded: false,
            isLiveStreamError: true,
            isAddLiveStreamLoading: false,
        }
    }
    function getUploadPartUrlAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getUploadPartSuccessAction(state: VideosStateModel, action: VideosActions.getuploadPartUrlRequestSuccessAction): VideosStateModel {

        if (action.payload) {
            return {
                ...state,
                uploadUrl: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getUploadPartFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function cancelUploadVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function cancelUploadVideoSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isCancelUpload: true
        };
    }
    function cancelUploadVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCancelUpload: false
        }
    }
    function addSpeakerAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function addSpeakerSuccessAction(state: VideosStateModel): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                isSaved: true,
                isSavedLoading: false,
                memberId: action.payload,
                message: 'Success'
            };
        }
        return state;
    }
    function addSpeakerFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function searchVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSearchVideoLoading: true,
        };
    }
    function searchVideoSuccessAction(state: VideosStateModel, action: VideosActions.searchVideoRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videos: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSearchVideoLoading: false
            };
        }
        return state;
    }
    function searchVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSearchVideoLoading: false
        }
    }
    function getVideoSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideLoading: true,
        };
    }
    function getVideoSlidesSuccessAction(state: VideosStateModel, action: VideosActions.getVideoSlidesRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoSlide: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isVideoSlideLoading: false
            };
        }
        return state;
    }
    function getVideoSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideLoading: false
        }
    }
    function saveVideoSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveVideoSlidesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideSaved: true,
            loading: false
        };
    }
    function saveVideoSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideSaved: false,
            loading: false,
            error: true
        }
    }
    function updateVideoSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateVideoSlidesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideUpdated: true,
            loading: false
        };
    }
    function updateVideoSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoSlideUpdated: false,
            loading: false,
            error: true
        }
    }
    function editVideoAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoEditedLoading: true,
        };
    }
    function editVideoSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isVideoEdited: true,
            message: 'Success',
            isVideoEditedLoading: false
        };
    }
    function editVideoFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoEdited: false,
            isEditVideoError: true,
            isVideoEditedLoading: false
        }
    }
    function getSpeakersAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSpeakersSuccessAction(state: VideosStateModel, action: VideosActions.getSpeakersRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                speakers: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSpeakersFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getInterActivityAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetInteractivityLoading: true,
        };
    }
    function getInterActivitySuccessAction(state: VideosStateModel, action: VideosActions.getInterActivityRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                interactivity: action.payload,
                isGetInteractivityLoading: false
            };
        }
        return state;
    }
    function getInterActivityFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetInteractivityLoading: false
        }
    }
    function updateInterActivityAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function updateInterActivitySuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function updateInterActivityFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getAccessibilityAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetAccessibilityLoading: true,
        };
    }
    function getAccessibilitySuccessAction(state: VideosStateModel, action: VideosActions.getAccessibilityRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                accessibility: action.payload,
                isGetAccessibilityLoading: false
            };
        }
        return state;
    }
    function getAccessibilityFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetAccessibilityLoading: false
        }
    }
    function updateAccessibilityAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function updateAccessibilitySuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isAccessibilityUpdated: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function updateAccessibilityFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isAccessibilityUpdated: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getPlayPageAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetPlayPageLoading: true,
        };
    }
    function getPlayPageSuccessAction(state: VideosStateModel, action: VideosActions.getPlayPageRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                playPage: action.payload,
                isGetPlayPageLoading: false
            };
        }
        return state;
    }
    function getPlayPageFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetPlayPageLoading: false
        }
    }
    function updatePlayPageAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function updatePlayPageSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isPlayPageUpdated: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function updatePlayPageFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPlayPageUpdated: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getMemberListAccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetMemberAccessLoading: true,
        };
    }
    function getMemberListAccessSuccessAction(state: VideosStateModel, action: VideosActions.getMemberListAccessRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                memberListAccess: action.payload,
                isGetMemberAccessLoading: false
            };
        }
        return state;
    }
    function getMemberListAccessFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetMemberAccessLoading: false
        }
    }
    function saveMemberListAccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function saveMemberListAccessSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isMemberListAccessSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function saveMemberListAccessFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListAccessSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function deleteMemberListAccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function deleteMemberListAccessSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            isMemberListAccessSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function deleteMemberListAccessFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListAccessSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getResourcesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetResourceLoading: true,
        };
    }
    function getResourcesSuccessAction(state: VideosStateModel, action: VideosActions.getResourcesRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                resources: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isGetResourceLoading: false
            };
        }
        return state;
    }
    function getResourcesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetResourceLoading: false
        }
    }
    function addResourcesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function addResourcesSuccessAction(state: VideosStateModel): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                isResourceAdded: true,
                attachmentId: action.payload,
                message: 'Success',
                isSavedLoading: false
            };
        }
        return state;
    }
    function addResourcesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceAdded: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getResourceDetailAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
        };
    }
    function getResourceDetailSuccessAction(state: VideosStateModel): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                resourceId : action.payload,
                message: 'Success',
            };
        }
        return state;
    }
    function getResourceDetailFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            error: true
        }
    }
    function editResourcesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function editResourcesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceUpdated: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function editResourcesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceUpdated: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getSingleResourcesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleResourcesSuccessAction(state: VideosStateModel, action: VideosActions.getSingleResourcesSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                singleResource: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleResourcesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function deleteResourcesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function deleteResourcesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceDeleted: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function deleteResourcesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceDeleted: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getMemberListPriceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetMemberAccessPriceLoading: true,
        };
    }
    function getMemberListPriceSuccessAction(state: VideosStateModel, action: VideosActions.getMemberListPriceSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                memberListPrice: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isGetMemberAccessPriceLoading: false
            };
        }
        return state;
    }
    function getMemberListPriceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetMemberAccessPriceLoading: false
        }
    }
    function saveMemberListPriceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function saveMemberListPriceSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListPriceSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function saveMemberListPriceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListPriceSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function deleteMemberListPriceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function deleteMemberListPriceSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListPriceSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function deleteMemberListPriceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListPriceSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getModeratorAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetModeratorLoading: true,
        };
    }
    function getModeratorSuccessAction(state: VideosStateModel, action: VideosActions.getModeratorSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                moderators: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isGetModeratorLoading: false
            };
        }
        return state;
    }
    function getModeratorFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isGetModeratorLoading: false
        }
    }
    function saveModeratorsAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function saveModeratorsSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListModeratorSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function saveModeratorsFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListModeratorSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function deleteModeratorsAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function deleteModeratorsSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListModeratorSaved: true,
            message: 'Success',
            isSavedLoading: false
        };
    }
    function deleteModeratorsFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMemberListModeratorSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getCallToActionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionLoading: true,
        };
    }
    function getCallToActionSuccessAction(state: VideosStateModel, action: VideosActions.getCallToActionSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                callToAction: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getCallToActionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionLoading: false
        }
    }
    function saveCallToActionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionLoading: true,
        };
    }
    function saveCallToActionSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: true,
            message: 'Success',
            isCallToActionLoading: false
        };
    }
    function saveCallToActionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: false,
            isCallToActionLoading: false,
            error: true
        }
    }
    function deleteCallToActionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionLoading: true,
        };
    }
    function deleteCallToActionSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: true,
            message: 'Success',
            isCallToActionLoading: false
        };
    }
    function deleteCallToActionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: false,
            isCallToActionLoading: false,
            error: true
        }
    }
    function getSingleCallToActionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleCallToActionSuccessAction(state: VideosStateModel, action: VideosActions.getSingleCallToActionSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                singleCallToAction: action.payload,
            };
        }
        return state;
    }
    function getSingleCallToActionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateCallToActionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionLoading: true,
        };
    }
    function updateCallToActionSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: true,
            message: 'Success',
            isCallToActionLoading: false
        };
    }
    function updateCallToActionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isCallToActionSaved: false,
            isCallToActionLoading: false,
            error: true
        }
    }
    function getVideoViewingSessionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoViewingSessionSuccessAction(state: VideosStateModel, action: VideosActions.getVideoViewingSessionSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoViewingSession: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getVideoViewingSessionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveSlidesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSlideSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function saveSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSlideSaved: false,
            loading: false,
            error: true
        }
    }
    function getSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSlidesSuccessAction(state: VideosStateModel, action: VideosActions.getSlidesSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                slides: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function updateSlidesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateSlidesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSlideSaved: true,
            message: 'Success',
            loading: false
        };
    }
    function updateSlidesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSlideSaved: false,
            loading: false,
            error: true
        }
    }
    function savePollAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSavedLoading: true,
        };
    }
    function savePollSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            isPollSaved: true,
            isPollSavedLoading: false
        };
    }
    function savePollFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSaved: false,
            isPollSavedLoading: false,
            error: true
        }
    }
    function getPollAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPollSuccessAction(state: VideosStateModel, action: VideosActions.getPollSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                polls: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getPollFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function updatePollAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSavedLoading: true,
        };
    }
    function updatePollSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSaved: true,
            message: 'Success',
            isPollSavedLoading: false
        };
    }
    function updatePollFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollSaved: false,
            isPollSavedLoading: false,
            error: true
        }
    }
    function getPollsTemplatesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPollsTemplatesSuccessAction(state: VideosStateModel, action: VideosActions.getPollsTemplatesSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                pollsTemplate: action.payload,
            };
        }
        return state;
    }
    function getPollsTemplatesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function saveQuestionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveQuestionSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            loading: false
        };
    }
    function saveQuestionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            error: true
        }
    }
    function updateQuestionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateQuestionSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            loading: false
        };
    }
    function updateQuestionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            error: true
        }
    }
    function getQuestionAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getQuestionSuccessAction(state: VideosStateModel, action: VideosActions.getQuestionSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                questions: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
            };
        }
        return state;
    }
    function getQuestionFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function getVideoMediaAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoMediaSuccessAction(state: VideosStateModel, action: VideosActions.getVideoMediaSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoMedia: action.payload,
            };
        }
        return state;
    }
    function getVideoMediaFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false
        }
    }
    function saveVideoMediaAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function saveVideoMediaSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            isSaved: true,
            isSavedLoading: false
        };
    }
    function saveVideoMediaFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function updateVideoMediaAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSavedLoading: true,
        };
    }
    function updateVideoMediaSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            message: 'Success',
            isSaved: true,
            isSavedLoading: false
        };
    }
    function updateVideoMediaFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSaved: false,
            isSavedLoading: false,
            error: true
        }
    }
    function getPollsResultAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollResultLoading: true,
        };
    }
    function getPollsResultSuccessAction(state: VideosStateModel, action: VideosActions.getPollsResultSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                pollsResult: action.payload,
                isPollResultLoading: false
            };
        }
        return state;
    }
    function getPollsResultFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollResultLoading: false
        }
    }
    function saveQuizAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isQuizLoading: true,
        };
    }
    function saveQuizSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isQuizSaved: true,
            isQuizLoading: false,
            message: 'Success'
        };
    }
    function saveQuizFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isQuizSaved: false,
            isQuizLoading: false,
            error: true
        }
    }
    function getQuizAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isQuizLoading: true,
        };
    }
    function getQuizSuccessAction(state: VideosStateModel, action: VideosActions.getQuizRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                quiz: action.payload,
                isQuizLoading: false,
            };
        }
        return state;
    }
    function getQuizFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isQuizLoading: false
        }
    }
    function saveMultiChoiceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMultiChoiceLoading: true,
        };
    }
    function saveMultiChoiceSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMultiChoiceSaved: true,
            isMultiChoiceLoading: false,
            message: 'Success'
        };
    }
    function saveMultiChoiceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMultiChoiceSaved: false,
            isMultiChoiceLoading: false,
            error: true
        }
    }
    function getMultiChoiceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMultiChoiceLoading: true,
        };
    }
    function getMultiChoiceSuccessAction(state: VideosStateModel, action: VideosActions.getMultiChoiceRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                multiChoice: action.payload,
                isMultiChoiceLoading: false,
            };
        }
        return state;
    }
    function getMultiChoiceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMultiChoiceLoading: false
        }
    }
    function saveStarRatingAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingLoading: true,
        };
    }
    function saveStarRatingSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingSaved: true,
            isStarRatingLoading: false,
            message: 'Success'
        };
    }
    function saveStarRatingFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingSaved: false,
            isStarRatingLoading: false,
            error: true
        }
    }
    function getStarRatingAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingLoading: true,
        };
    }
    function getStarRatingSuccessAction(state: VideosStateModel, action: VideosActions.getStarRatingRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                multiChoice: action.payload,
                isStarRatingLoading: false,
            };
        }
        return state;
    }
    function getStarRatingFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingLoading: false
        }
    }
    function saveOpenTextAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStarRatingLoading: true,
        };
    }
    function saveOpenTextSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isOpenTextSaved: true,
            isOpenTextLoading: false,
            message: 'Success'
        };
    }
    function saveOpenTextFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isOpenTextSaved: false,
            isOpenTextLoading: false,
            error: true
        }
    }
    function getOpenTextAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isOpenTextLoading: true,
        };
    }
    function getOpenTextSuccessAction(state: VideosStateModel, action: VideosActions.getOpenTextRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                openText: action.payload,
                isOpenTextLoading: false,
            };
        }
        return state;
    }
    function getOpenTextFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isOpenTextLoading: false
        }
    }
    function getVideoAnalyticsDataAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAnalyticsDataLoading: true,
        };
    }
    function getVideoAnalyticsDataSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsDataRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoAnalyticsData: action.payload,
                isVideoAnalyticsDataLoading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsDataFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            success: false,
            isVideoAnalyticsDataLoading: false
        }
    }
    function getVideoAnalyticsChartDataAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoAnalyticsChartDataSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsChartDataRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoAnalyticsChartData: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsChartFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function generateAuthKeyAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function generateAuthKeySuccessAction(state: VideosStateModel, action: VideosActions.generateAuthKeyRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                authKey: action.payload,
                isAuthKeySaved: true,
                loading: false
            };
        }
        return state;
    }
    function generateAuthKeyFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isAuthKeySaved: false,
            loading: false
        }
    }
    function resetRedirectToLoginAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isRedirectToLoginSaved: false,
        }
    }
    function resetAuthKeyAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isAuthKeySaved: false,
        }
    }
    function updateRedirectToLoginAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
        };
    }
    function updateRedirectToLoginSuccessAction(state: VideosStateModel): VideosStateModel {

        return {
            ...state,
            loading: false,
            isRedirectToLoginSaved: true
        };
    }
    function updateRedirectToLoginFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            isRedirectToLoginSaved: false
        }
    }
    function updateVideoAnalyticsAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true
        };
    }
    function updateVideoAnalyticsSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            isVideoAnalyticsUpdated: true,
            message: "Success"
        };
    }
    function updateVideoAnalyticsFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            isVideoAnalyticsUpdated: false,
            error: true
        }
    }
    function getVideoAnalyticsLiveViewsAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoAnalyticsLiveViewsSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsLiveViewsRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsLiveView: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsLiveViewsFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getVideoAnalyticsLiveViewsChartDataAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoAnalyticsLiveViewsChartDataSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsLiveViewsChartDataRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsLiveViewChartData: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsLiveViewsChartDataFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateVideoThumbnailAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateVideoThumbnailSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isThumbnailUpdated: true,
            message: 'Success',
            loading: false
        };
    }
    function updateVideoThumbnailFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isThumbnailUpdated: false,
            loading: false,
            error: true
        }
    }
    function getLiveMediaAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLiveMediaSuccessAction(state: VideosStateModel, action: VideosActions.getLiveMediaRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveMedia: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLiveMediaFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isThumbnailUpdated: false,
            loading: false
        }
    }
    function resetAttachmentIdAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            attachmentId: ''
        }
    }

    function startMediaChannelRequestAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function startMediaChannelRequestSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isSuccess: true,
            loading: false
        };
    }
    function startMediaChannelRequestFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false,
            isSuccess: false,

        }
    }

    function startMediaStreamRequestAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function startMediaStreamRequestSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMediaStreamStarted: true,
            loading: false
        };
    }
    function startMediaStreamRequestFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isMediaStreamStarted: false,
            loading: false
        }
    }

    function stopMediaStreamRequestAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function stopMediaStreamRequestSuccessAction(state: VideosStateModel, action: VideosActions.stopMediaStreamRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false
            };
        }
        return state;
    }
    function stopMediaStreamRequestFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function mediaChannelStatusRequestFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function mediaChannelStatusRequestAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function mediaChannelStatusRequestSuccessAction(state: VideosStateModel, action: VideosActions.mediaChannelStatusRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                mediaChannelStatus: action.payload.primaryChannelStatus,
                mediaChannelInUse: action.payload.inUse,
                loading: false
            };
        }
        return state;
    }

    function resetVideoLibraryStateAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAdded: false,
            isVideoDeleted: false,
            uploadId: '',
            fileNames: '',
            uploadUrl: '',
            isVideoEdited: false,
            isSlideSaved: false,
            isCancelUpload: false,
            isVideoUploaded: false,
            isEditVideoError: false,
            isMemberListAccessSaved: false,
            isMemberListModeratorSaved: false,
            isPollSaved: false,
            isLiveStreamError: false,
            isCallToActionSaved: false,
            isCallToActionLoading: false,
            isMemberListPriceSaved: false,
            isResourceAdded: false,
            isResourceUpdated: false,
            isResourceDeleted: false,
            isSaved: false,
            isSavedLoading: false,
            isVideoAnalyticsUpdated: false,
            isPlayPageUpdated: false,
            isThumbnailUpdated: false,
            isAccessibilityUpdated: false,
            isPollDeleted: false,
            isPollStatusUpdated: false,
            error: false,
            isDiscountCodeAdded: false,
            isLiveMeetingStarted: false,
            isLiveStreamSourceUpdated: false
        }
    }
    function getLiveEventUrlAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLiveEventUrlSuccessAction(state: VideosStateModel, action: VideosActions.getLiveEventUrlRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveEventUrl: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLiveEventUrlFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function deletePollAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deletePollSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollDeleted: true,
            loading: false,
            message: 'Success'
        };
    }
    function deletePollFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollDeleted: false,
            loading: false,
            error: true
        }
    }
    function updatePollStatusAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updatePollStatusSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollStatusUpdated: true,
            loading: false,
            message: 'Success'
        };
    }
    function updatePollStatusFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isPollStatusUpdated: false,
            loading: false,
            error: true
        }
    }
    function getDiscountCodeAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeLoading: true,
        };
    }
    function getDiscountCodeSuccessAction(state: VideosStateModel, action: VideosActions.getDiscountCodeRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                discountCode: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isDiscountCodeLoading: false
            };
        }
        return state;
    }
    function getDiscountCodeFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeLoading: false
        }
    }
    function saveDiscountCodeAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveDiscountCodeSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: true,
            loading: false,
            message: 'Success'
        };
    }
    function saveDiscountCodeFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: false,
            loading: false,
            error: true
        }
    }
    function updateDiscountCodeAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateDiscountCodeSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: true,
            loading: false,
            message: 'Success'
        };
    }
    function updateDiscountCodeFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: false,
            loading: false,
            error: true
        }
    }
    function deleteDiscountCodesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteDiscountCodesSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: true,
            loading: false,
            message: 'Success'
        };
    }
    function deleteDiscountCodesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isDiscountCodeAdded: false,
            loading: false,
            error: true
        }
    }
    function getSingleDiscountCodeAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleDiscountCodeSuccessAction(state: VideosStateModel, action: VideosActions.getSingleDiscountCodeRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                singleDiscountCode: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSingleDiscountCodeFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateVideoStatusAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateVideoStatusSuccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoStatusUpdated: true,
            loading: false,
            message: 'Success'
        };
    }
    function updateVideoStatusFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoStatusUpdated: false,
            loading: false,
            error: true
        }
    }
    function getVideoStatusAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoStatusSuccessAction(state: VideosStateModel, action: VideosActions.getVideoStatusRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoStatus: action.payload.videoStatusType,
                liveStartTime: action.payload.liveStartTime,
                loading: false
            };
        }
        return state;
    }
    function getVideoStatusFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getVideoModulesAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoModulesSuccessAction(state: VideosStateModel, action: VideosActions.getVideoModulesRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoModule: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoModulesFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function startLiveMeetingAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStartLiveMeetingLoading: true
        };
    }
    function startLiveMeetingSuccessAction(state: VideosStateModel, action: VideosActions.startLiveMeetingRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveMeeting: action.payload,
                isLiveMeetingStarted: true,
                isStartLiveMeetingLoading: false
            };
        }
        return state;
    }
    function startLiveMeetingFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isStartLiveMeetingLoading: false,
            isLiveMeetingStarted: false
        }
    }
    function endLiveMeetingAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function endLiveMeetingSuccessAction(state: VideosStateModel, action: VideosActions.endLiveMeetingRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveMeeting: action.payload,
                loading: false
            };
        }
        return state;
    }
    function endLiveMeetFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function enterLiveStudioAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function enterLiveStudioSuccessAction(state: VideosStateModel, action: VideosActions.enterLiveStudioRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveStudio: action.payload,
                loading: false
            };
        }
        return state;
    }
    function enterLiveStudioFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function resetEnterLiveStudioAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            liveStudio: initialState.liveStudio,
        };
    }
    function getProductAccessAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getProductAccessSuccessAction(state: VideosStateModel, action: VideosActions.getProductAccessRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                isProductAccess: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getProductAccessFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            // isProductAccess: false,
            loading: false
        }
    }
    function getVideoAnalyticsTableAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAnalyticsTableLoading: true,
        };
    }
    function getVideoAnalyticsTableSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsTableRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoAnalyticsTable: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isVideoAnalyticsTableLoading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsTableFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isVideoAnalyticsTableLoading: false
        }
    }
    function getVideoAnalyticsTableDownloadAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getVideoAnalyticsTableDownloadSuccessAction(state: VideosStateModel, action: VideosActions.getVideoAnalyticsTableDownloadRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                videoAnalyticsTableDownload: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getVideoAnalyticsTableDownloadFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function removeMediaUrlAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function removeMediaUrlSuccessAction(state: VideosStateModel, action: VideosActions.removeMediaUrlRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                removeMediaUrl: action.payload,
                loading: false
            };
        }
        return state;
    }
    function removeMediaUrlFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getMediaUrlAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getMediaUrlSuccessAction(state: VideosStateModel, action: VideosActions.getMediaUrlRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                mediaUrl: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getMediaUrlFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getLiveViewersAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLiveViewersSuccessAction(state: VideosStateModel, action: VideosActions.getLiveViewersRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveViewers: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLiveViewersFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getLiveViewersCountAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLiveViewersCountSuccessAction(state: VideosStateModel, action: VideosActions.getLiveViewersCountRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                liveViewersCount: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLiveViewersCountFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateLiveStreamSourceAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isLiveStreamSourceUpdated: false,
        };
    }
    function updateLiveStreamSourceSuccessAction(state: VideosStateModel, action: VideosActions.updateLiveStreamSourceRequestSuccessAction): VideosStateModel {
        return {
            ...state,
            isLiveStreamSourceUpdated: true,
            message: 'Success',
        };
    }
    function updateLiveStreamSourceFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isLiveStreamSourceUpdated: false
        }
    }
    function addResourceUploadAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceUploaded: false,
        };
    }
    function addResourceUploadSuccessAction(state: VideosStateModel, action: VideosActions.addResourceUploadRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                presentationId: action.payload.presentationId,
                isResourceUploaded: true,
                message: 'Success',
            };
        }
        return state;
    }
    function addResourceUploadFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceUploaded: false
        }
    }
    function getArchiveUrlAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getArchiveUrlSuccessAction(state: VideosStateModel, action: VideosActions.getArchiveUrlRequestSuccessAction): VideosStateModel {
        if (action.payload) {
            return {
                ...state,
                archiveUrl: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getArchiveUrlFailureAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function resetResourceUploadAction(state: VideosStateModel): VideosStateModel {
        return {
            ...state,
            isResourceUploaded: initialState.isResourceUploaded,
        };
    }
}

export default videosReducer
