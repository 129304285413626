export const getApiV2RequestActionType = 'GET_APIV2_REQUEST'
export const getApiV2SuccessActionType = 'GET_APIV2_REQUEST_SUCCESS'
export const getApiV2FailureActionType = 'GET_APIV2_REQUEST_FAILURE'
export const addApiV2RequestActionType = 'ADD_APIV2_REQUEST'
export const addApiV2SuccessActionType = 'ADD_APIV2_REQUEST_SUCCESS'
export const addApiV2FailureActionType = 'ADD_APIV2_REQUEST_FAILURE'
export const deleteApiV2RequestActionType = 'DELETE_APIV2_REQUEST'
export const deleteApiV2SuccessActionType = 'DELETE_APIV2_REQUEST_SUCCESS'
export const deleteApiV2FailureActionType = 'DELETE_APIV2_REQUEST_FAILURE'
export const resetSettingStateRequestActionType = 'RESET_SETTING_STATE_REQUEST'
export const getLiveStreamUrlRequestActionType = 'GET_LIVE_STREAM_REQUEST'
export const getLiveStreamUrlRequestSuccessActionType = 'GET_LIVE_STREAM_REQUEST_SUCCESS'
export const getLiveStreamUrlRequestFailureActionType = 'GET_LIVE_STREAM_REQUEST_FAILURE'
export const getSamlRequestActionType = 'GET_SAML_REQUEST'
export const getSamlRequestSuccessActionType = 'GET_SAML_REQUEST_SUCCESS'
export const getSamlRequestFailureActionType = 'GET_SAML_REQUEST_FAILURE'
export const saveSamlRequestActionType = 'SAVE_SAML_REQUEST'
export const saveSamlRequestSuccessActionType = 'SAVE_SAML_REQUEST_SUCCESS'
export const saveSamlRequestFailureActionType = 'SAVE_SAML_REQUEST_FAILURE'
export const updateCloudRecordingRequestActionType = 'UPDATE_CLOUD_RECORDING_REQUEST'
export const updateCloudRecordingRequestSuccessActionType = 'UPDATE_CLOUD_RECORDING_REQUEST_SUCCESS'
export const updateCloudRecordingRequestFailureActionType = 'UPDATE_CLOUD_RECORDING_REQUEST_FAILURE'
export const updateAutoPublishOnDemandRequestActionType = 'UPDATE_AUTO_PUBLISH_ON_DEMAND_REQUEST'
export const updateAutoPublishOnDemandRequestSuccessActionType = 'UPDATE_AUTO_PUBLISH_ON_DEMAND_REQUEST_SUCCESS'
export const updateAutoPublishOnDemandRequestFailureActionType = 'UPDATE_AUTO_PUBLISH_ON_DEMAND_REQUEST_FAILURE'
export const getEcommerceRequestActionType = 'GET_ECOMMERCE_REQUEST'
export const getEcommerceRequestSuccessActionType = 'GET_ECOMMERCE_REQUEST_SUCCESS'
export const getEcommerceRequestFailureActionType = 'GET_ECOMMERCE_REQUEST_FAILURE'
export const saveEcommerceRequestActionType = 'SAVE_ECOMMERCE_REQUEST'
export const saveEcommerceRequestSuccessActionType = 'SAVE_ECOMMERCE_REQUEST_SUCCESS'
export const saveEcommerceRequestFailureActionType = 'SAVE_ECOMMERCE_REQUEST_FAILURE'
export const getVideoLogsRequestActionType = 'GET_VIDEO_LOGS_REQUEST'
export const getVideoLogsRequestSuccessActionType = 'GET_VIDEO_LOGS_REQUEST_SUCCESS'
export const getVideoLogsRequestFailureActionType = 'GET_VIDEO_LOGS_REQUEST_FAILURE'
export const getChannelLogsRequestActionType = 'GET_CHANNEL_LOGS_REQUEST'
export const getChannelLogsRequestSuccessActionType = 'GET_CHANNEL_LOGS_REQUEST_SUCCESS'
export const getChannelLogsRequestFailureActionType = 'GET_CHANNEL_LOGS_REQUEST_FAILURE'
export const getAnalyticsLogsRequestActionType = 'GET_ANALYTICS_LOGS_REQUEST'
export const getAnalyticsLogsRequestSuccessActionType = 'GET_ANALYTICS_LOGS_REQUEST_SUCCESS'
export const getAnalyticsLogsRequestFailureActionType = 'GET_ANALYTICS_LOGS_REQUEST_FAILURE'
export const runUpdateRequestActionType = 'RUN_UPDATE_REQUEST'
export const runUpdateRequestSuccessActionType = 'RUN_UPDATE_REQUEST_SUCCESS'
export const runUpdateRequestFailureActionType = 'RUN_UPDATE_REQUEST_FAILURE'
export const enableIntegrationProviderRequestActionType = 'ENABLE_INTEGRATION_PROVIDER_REQUEST'
export const enableIntegrationProviderRequestSuccessActionType = 'ENABLE_INTEGRATION_PROVIDER_REQUEST_SUCCESS'
export const enableIntegrationProviderRequestFailureActionType = 'ENABLE_INTEGRATION_PROVIDER_REQUEST_FAILURE'
export const getImisIntegrationRequestActionType = 'GET_IMIS_INTEGRATION_REQUEST'
export const getImisIntegrationRequestSuccessActionType = 'GET_IMIS_INTEGRATION_REQUEST_SUCCESS'
export const getImisIntegrationRequestFailureActionType = 'GET_IMIS_INTEGRATION_REQUEST_FAILURE'
export const addImisIntegrationRequestActionType = 'ADD_IMIS_INTEGRATION_REQUEST'
export const addImisIntegrationRequestSuccessActionType = 'ADD_IMIS_INTEGRATION_REQUEST_SUCCESS'
export const addImisIntegrationRequestFailureActionType = 'ADD_IMIS_INTEGRATION_REQUEST_FAILURE'
export const getMemberEvolutionIntegrationRequestActionType = 'GET_MEMBER_EVOLUTION_INTEGRATION_REQUEST'
export const getMemberEvolutionIntegrationRequestSuccessActionType = 'GET_MEMBER_EVOLUTION_INTEGRATION_REQUEST_SUCCESS'
export const getMemberEvolutionIntegrationRequestFailureActionType = 'GET_MEMBER_EVOLUTION_INTEGRATION_REQUEST_FAILURE'
export const addMemberEvolutionIntegrationRequestActionType = 'ADD_MEMBER_EVOLUTION_INTEGRATION_REQUEST'
export const addMemberEvolutionIntegrationRequestSuccessActionType = 'ADD_MEMBER_EVOLUTION_INTEGRATION_REQUEST_SUCCESS'
export const addMemberEvolutionIntegrationRequestFailureActionType = 'ADD_MEMBER_EVOLUTION_INTEGRATION_REQUEST_FAILURE'

export const getWicketIntegrationRequestActionType = 'GET_WICKET_INTEGRATION_REQUEST'
export const getWicketIntegrationRequestSuccessActionType = 'GET_WICKET_INTEGRATION_REQUEST_SUCCESS'
export const getWicketIntegrationRequestFailureActionType = 'GET_WICKET_INTEGRATION_REQUEST_FAILURE'
export const addWicketIntegrationRequestActionType = 'ADD_WICKET_INTEGRATION_REQUEST'
export const addWicketIntegrationRequestSuccessActionType = 'ADD_WICKET_INTEGRATION_REQUEST_SUCCESS'
export const addWicketIntegrationRequestFailureActionType = 'ADD_WICKET_INTEGRATION_REQUEST_FAILURE'

export const getDynamics365IntegrationRequestActionType = 'GET_DYNAMICS365_INTEGRATION_REQUEST'
export const getDynamics365IntegrationRequestSuccessActionType = 'GET_DYNAMICS365_INTEGRATION_REQUEST_SUCCESS'
export const getDynamics365IntegrationRequestFailureActionType = 'GET_DYNAMICS365_INTEGRATION_REQUEST_FAILURE'
export const addDynamics365IntegrationRequestActionType = 'ADD_DYNAMICS365_INTEGRATION_REQUEST'
export const addDynamics365IntegrationRequestSuccessActionType = 'ADD_DYNAMICS365_INTEGRATION_REQUEST_SUCCESS'
export const addDynamics365IntegrationRequestFailureActionType = 'ADD_DYNAMICS365_INTEGRATION_REQUEST_FAILURE'

export const getMicrosoftAzureIntegrationRequestActionType = 'GET_MICROSOFT_AZURE_INTEGRATION_REQUEST'
export const getMicrosoftAzureIntegrationRequestSuccessActionType = 'GET_MICROSOFT_AZURE_INTEGRATION_REQUEST_SUCCESS'
export const getMicrosoftAzureIntegrationRequestFailureActionType = 'GET_MICROSOFT_AZURE_INTEGRATION_REQUEST_FAILURE'
export const addMicrosoftAzureIntegrationRequestActionType = 'ADD_MICROSOFT_AZURE_INTEGRATION_REQUEST'
export const addMicrosoftAzureIntegrationRequestSuccessActionType = 'ADD_MICROSOFT_AZURE_INTEGRATION_REQUEST_SUCCESS'
export const addMicrosoftAzureIntegrationRequestFailureActionType = 'ADD_MICROSOFT_AZURE_INTEGRATION_REQUEST_FAILURE'

export const getNoviAMSIntegrationRequestActionType = 'GET_NOVI_AMS_INTEGRATION_REQUEST'
export const getNoviAMSIntegrationRequestSuccessActionType = 'GET_NOVI_AMS_INTEGRATION_REQUEST_SUCCESS'
export const getNoviAMSIntegrationRequestFailureActionType = 'GET_NOVI_AMS_INTEGRATION_REQUEST_FAILURE'
export const addNoviAMSIntegrationRequestActionType = 'ADD_NOVI_AMS_INTEGRATION_REQUEST'
export const addNoviAMSIntegrationRequestSuccessActionType = 'ADD_NOVI_AMS_INTEGRATION_REQUEST_SUCCESS'
export const addNoviAMSIntegrationRequestFailureActionType = 'ADD_NOVI_AMS_INTEGRATION_REQUEST_FAILURE'

export const getOAuth2IntegrationRequestActionType = 'GET_OAUTH2_INTEGRATION_REQUEST'
export const getOAuth2IntegrationRequestSuccessActionType = 'GET_OAUTH2_INTEGRATION_REQUEST_SUCCESS'
export const getOAuth2IntegrationRequestFailureActionType = 'GET_OAUTH2_INTEGRATION_REQUEST_FAILURE'
export const addOAuth2IntegrationRequestActionType = 'ADD_OAUTH2_INTEGRATION_REQUEST'
export const addOAuth2IntegrationRequestSuccessActionType = 'ADD_OAUTH2_INTEGRATION_REQUEST_SUCCESS'
export const addOAuth2IntegrationRequestFailureActionType = 'ADD_OAUTH2_INTEGRATION_REQUEST_FAILURE'

export const getMemberClicksIntegrationRequestActionType = 'GET_MEMBER_CLICKS_INTEGRATION_REQUEST'
export const getMemberClicksIntegrationRequestSuccessActionType = 'GET_MEMBER_CLICKS_INTEGRATION_REQUEST_SUCCESS'
export const getMemberClicksIntegrationRequestFailureActionType = 'GET_MEMBER_CLICKS_INTEGRATION_REQUEST_FAILURE'
export const addMemberClicksIntegrationRequestActionType = 'ADD_MEMBER_CLICKS_INTEGRATION_REQUEST'
export const addMemberClicksIntegrationRequestSuccessActionType = 'ADD_MEMBER_CLICKS_INTEGRATION_REQUEST_SUCCESS'
export const addMemberClicksIntegrationRequestFailureActionType = 'ADD_MEMBER_CLICKS_INTEGRATION_REQUEST_FAILURE'


export const getPersonifyIntegrationRequestActionType = 'GET_PERSONIFY_INTEGRATION_REQUEST'
export const getPersonifyIntegrationRequestSuccessActionType = 'GET_PERSONIFY_INTEGRATION_REQUEST_SUCCESS'
export const getPersonifyIntegrationRequestFailureActionType = 'GET_PERSONIFY_INTEGRATION_REQUEST_FAILURE'
export const addPersonifyIntegrationRequestActionType = 'ADD_PERSONIFY_INTEGRATION_REQUEST'
export const addPersonifyIntegrationRequestSuccessActionType = 'ADD_PERSONIFY_INTEGRATION_REQUEST_SUCCESS'
export const addPersonifyIntegrationRequestFailureActionType = 'ADD_PERSONIFY_INTEGRATION_REQUEST_FAILURE'
export const savePersonifyIntegrationRequestActionType = 'SAVE_PERSONIFY_INTEGRATION_REQUEST'
export const savePersonifyIntegrationRequestSuccessActionType = 'SAVE_PERSONIFY_INTEGRATION_REQUEST_SUCCESS'
export const savePersonifyIntegrationRequestFailureActionType = 'SAVE_PERSONIFY_INTEGRATION_REQUEST_FAILURE'
export const getNaylorIntegrationRequestActionType = 'GET_NAYLOR_INTEGRATION_REQUEST'
export const getNaylorIntegrationRequestSuccessActionType = 'GET_NAYLOR_INTEGRATION_REQUEST_SUCCESS'
export const getNaylorIntegrationRequestFailureActionType = 'GET_NAYLOR_INTEGRATION_REQUEST_FAILURE'
export const getSpargoIntegrationRequestActionType = 'GET_SPARGO_INTEGRATION_REQUEST'
export const getSpargoIntegrationRequestSuccessActionType = 'GET_SPARGO_INTEGRATION_REQUEST_SUCCESS'
export const getSpargoIntegrationRequestFailureActionType = 'GET_SPARGO_INTEGRATION_REQUEST_FAILURE'
export const getSingleApiV2RequestActionType = 'GET_SINGLE_APIV2_REQUEST'
export const getSingleApiV2RequestSuccessActionType = 'GET_SINGLE_APIV2_REQUEST_SUCCESS'
export const getSingleApiV2RequestFailureActionType = 'GET_SINGLE_APIV2_REQUEST_FAILURE'
export const updateApiV2RequestActionType = 'UPDATE_APIV2_REQUEST'
export const updateApiV2RequestSuccessActionType = 'UPDATE_APIV2_REQUEST_SUCCESS'
export const updateApiV2RequestFailureActionType = 'UPDATE_APIV2_REQUEST_FAILURE'

export const getMembesIntegrationRequestActionType = 'GET_MEMBES_INTEGRATION_REQUEST'
export const getMembesIntegrationRequestSuccessActionType = 'GET_MEMBES_INTEGRATION_REQUEST_SUCCESS'
export const getMembesIntegrationRequestFailureActionType = 'GET_MEMBES_INTEGRATION_REQUEST_FAILURE'
export const addMembesIntegrationRequestActionType = 'ADD_MEMBES_INTEGRATION_REQUEST'
export const addMembesIntegrationRequestSuccessActionType = 'ADD_MEMBES_INTEGRATION_REQUEST_SUCCESS'
export const addMembesIntegrationRequestFailureActionType = 'ADD_MEMBES_INTEGRATION_REQUEST_FAILURE'

export const getGoogleIntegrationRequestActionType = 'GET_GOOGLE_INTEGRATION_REQUEST'
export const getGoogleIntegrationRequestSuccessActionType = 'GET_GOOGLE_INTEGRATION_REQUEST_SUCCESS'
export const getGoogleIntegrationRequestFailureActionType = 'GET_GOOGLE_INTEGRATION_REQUEST_FAILURE'
export const getWebCourseIntegrationRequestActionType = 'GET_WEBCOURSE_INTEGRATION_REQUEST'
export const getWebCourseIntegrationRequestSuccessActionType = 'GET_WEBCOURSE_INTEGRATION_REQUEST_SUCCESS'
export const getWebCourseIntegrationRequestFailureActionType = 'GET_WEBCOURSE_INTEGRATION_REQUEST_FAILURE'
export const getZoomIntegrationRequestActionType = 'GET_ZOOM_INTEGRATION_REQUEST'
export const getZoomIntegrationRequestSuccessActionType = 'GET_ZOOM_INTEGRATION_REQUEST_SUCCESS'
export const getZoomIntegrationRequestFailureActionType = 'GET_ZOOM_INTEGRATION_REQUEST_FAILURE'
export const enablePaymentProviderRequestActionType = 'ENABLE_PAYMENT_PROVIDER_REQUEST'
export const enablePaymentProviderRequestSuccessActionType = 'ENABLE_PAYMENT_PROVIDER_REQUEST_SUCCESS'
export const enablePaymentProviderRequestFailureActionType = 'ENABLE_PAYMENT_PROVIDER_REQUEST_FAILURE'
export const getPaypalPayFlowProviderRequestActionType = 'GET_PAYPAL_PAYFLOW_PROVIDER_REQUEST'
export const getPaypalPayFlowProviderRequestSuccessActionType = 'GET_PAYPAL_PAYFLOW_PROVIDER_REQUEST_SUCCESS'
export const getPaypalPayFlowProviderRequestFailureActionType = 'GET_PAYPAL_PAYFLOW_PROVIDER_REQUEST_FAILURE'
export const addPaypalPayFlowProviderRequestActionType = 'ADD_PAYPAL_PAYFLOW_PROVIDER_REQUEST'
export const addPaypalPayFlowProviderRequestSuccessActionType = 'ADD_PAYPAL_PAYFLOW_PROVIDER_REQUEST_SUCCESS'
export const addPaypalPayFlowProviderRequestFailureActionType = 'ADD_PAYPAL_PAYFLOW_PROVIDER_REQUEST_FAILURE'

export const getEziDebitProviderRequestActionType = 'GET_EZIDEBIT_PROVIDER_REQUEST'
export const getEziDebitProviderRequestSuccessActionType = 'GET_EZIDEBIT_PROVIDER_REQUEST_SUCCESS'
export const getEziDebitProviderRequestFailureActionType = 'GET_EZIDEBIT_PROVIDER_REQUEST_FAILURE'
export const addEzidebitProviderRequestActionType = 'ADD_EZIDEBIT_PROVIDER_REQUEST'
export const addEzidebitProviderRequestSuccessActionType = 'ADD_EZIDEBIT_PROVIDER_REQUEST_SUCCESS'
export const addEzidebitProviderRequestFailureActionType = 'ADD_EZIDEBIT_PROVIDER_REQUEST_FAILURE'
export const getStripeProviderRequestActionType = 'GET_STRIPE_PROVIDER_REQUEST'
export const getStripeProviderRequestSuccessActionType = 'GET_STRIPE_PROVIDER_REQUEST_SUCCESS'
export const getStripeProviderRequestFailureActionType = 'GET_STRIPE_PROVIDER_REQUEST_FAILURE'
export const addStripeProviderRequestActionType = 'ADD_STRIPE_PROVIDER_REQUEST'
export const addStripeProviderRequestSuccessActionType = 'ADD_STRIPE_PROVIDER_REQUEST_SUCCESS'
export const addStripeProviderRequestFailureActionType = 'ADD_STRIPE_PROVIDER_REQUEST_FAILURE'
export const getPayPalProviderRequestActionType = 'GET_PAYPAL_PROVIDER_REQUEST'
export const getPayPalProviderRequestSuccessActionType = 'GET_PAYPAL_PROVIDER_REQUEST_SUCCESS'
export const getPayPalProviderRequestFailureActionType = 'GET_PAYPAL_PROVIDER_REQUEST_FAILURE'
export const addPayPalProviderRequestActionType = 'ADD_PAYPAL_PROVIDER_REQUEST'
export const addPayPalProviderRequestSuccessActionType = 'ADD_PAYPAL_PROVIDER_REQUEST_SUCCESS'
export const addPayPalProviderRequestFailureActionType = 'ADD_PAYPAL_PROVIDER_REQUEST_FAILURE'
export const getBPointProviderRequestActionType = 'GET_BPOINT_PROVIDER_REQUEST'
export const getBPointProviderRequestSuccessActionType = 'GET_BPOINT_PROVIDER_REQUEST_SUCCESS'
export const getBPointProviderRequestFailureActionType = 'GET_BPOINT_PROVIDER_REQUEST_FAILURE'
export const addBPointProviderRequestActionType = 'ADD_BPOINT_PROVIDER_REQUEST'
export const addBPointProviderRequestSuccessActionType = 'ADD_BPOINT_PROVIDER_REQUEST_SUCCESS'
export const addBPointProviderRequestFailureActionType = 'ADD_BPOINT_PROVIDER_REQUEST_FAILURE'

export const getLivePayProviderRequestActionType = 'GET_LIVEPAY_PROVIDER_REQUEST'
export const getLivePayProviderRequestSuccessActionType = 'GET_LIVEPAY_PROVIDER_REQUEST_SUCCESS'
export const getLivePayProviderRequestFailureActionType = 'GET_LIVEPAY_PROVIDER_REQUEST_FAILURE'
export const addLivePayProviderRequestActionType = 'ADD_LIVEPAY_PROVIDER_REQUEST'
export const addLivePayProviderRequestSuccessActionType = 'ADD_LIVEPAY_PROVIDER_REQUEST_SUCCESS'
export const addLivePayProviderRequestFailureActionType = 'ADD_LIVEPAY_PROVIDER_REQUEST_FAILURE'

export const getCePointsRequestActionType = 'GET_CE_POINTS_REQUEST'
export const getCePointsRequestSuccessActionType = 'GET_CE_POINTS_REQUEST_SUCCESS'
export const getCePointsRequestFailureActionType = 'GET_CE_POINTS_REQUEST_FAILURE'
export const saveCePointsRequestActionType = 'SAVE_CE_POINTS_REQUEST'
export const saveCePointsRequestSuccessActionType = 'SAVE_CE_POINTS_REQUEST_SUCCESS'
export const saveCePointsRequestFailureActionType = 'SAVE_CE_POINTS_REQUEST_FAILURE'
export const getEducationPointsRequestActionType = 'GET_EDUCATION_POINTS_REQUEST'
export const getEducationPointsRequestSuccessActionType = 'GET_EDUCATION_POINTS_REQUEST_SUCCESS'
export const getEducationPointsRequestFailureActionType = 'GET_EDUCATION_POINTS_REQUEST_FAILURE'
export const saveEducationPointsRequestActionType = 'SAVE_EDUCATION_POINTS_REQUEST'
export const saveEducationPointsRequestSuccessActionType = 'SAVE_EDUCATION_POINTS_REQUEST_SUCCESS'
export const saveEducationPointsRequestFailureActionType = 'SAVE_EDUCATION_POINTS_REQUEST_FAILURE'
export const updateEducationPointsRequestActionType = 'UPDATE_EDUCATION_POINTS_REQUEST'
export const updateEducationPointsRequestSuccessActionType = 'UPDATE_EDUCATION_POINTS_REQUEST_SUCCESS'
export const updateEducationPointsRequestFailureActionType = 'UPDATE_EDUCATION_POINTS_REQUEST_FAILURE'
export const deleteEducationPointsRequestActionType = 'DELETE_EDUCATION_POINTS_REQUEST'
export const deleteEducationPointsRequestSuccessActionType = 'DELETE_EDUCATION_POINTS_REQUEST_SUCCESS'
export const deleteEducationPointsRequestFailureActionType = 'DELETE_EDUCATION_POINTS_REQUEST_FAILURE'
export const getSingleEducationPointsRequestActionType = 'GET_SINGLE_EDUCATION_POINTS_REQUEST'
export const getSingleEducationPointsRequestSuccessActionType = 'GET_SINGLE_EDUCATION_POINTS_REQUEST_SUCCESS'
export const getSingleEducationPointsRequestFailureActionType = 'GET_SINGLE_EDUCATION_POINTS_REQUEST_FAILURE'
export const getTemplatesRequestActionType = 'GET_TEMPLATES_REQUEST'
export const getTemplatesRequestSuccessActionType = 'GET_TEMPLATES_REQUEST_SUCCESS'
export const getTemplatesRequestFailureActionType = 'GET_TEMPLATES_REQUEST_FAILURE'
export const getIntegrationRequestActionType = 'GET_INTEGRATION_REQUEST'
export const getIntegrationRequestSuccessActionType = 'GET_INTEGRATION_REQUEST_SUCCESS'
export const getIntegrationRequestFailureActionType = 'GET_INTEGRATION_REQUEST_FAILURE'
export const updateIntegrationRequestActionType = 'UPDATE_INTEGRATION_REQUEST'
export const updateIntegrationRequestSuccessActionType = 'UPDATE_INTEGRATION_REQUEST_SUCCESS'
export const updateIntegrationRequestFailureActionType = 'UPDATE_INTEGRATION_REQUEST_FAILURE'
export const getPaymentProviderRequestActionType = 'GET_PAYMENT_PROVIDER_REQUEST'
export const getPaymentProviderRequestSuccessActionType = 'GET_PAYMENT_PROVIDER_REQUEST_SUCCESS'
export const getPaymentProviderRequestFailureActionType = 'GET_PAYMENT_PROVIDER_REQUEST_FAILURE'
export const updatePaymentRequestActionType = 'UPDATE_PAYMENT_REQUEST'
export const updatePaymentRequestSuccessActionType = 'UPDATE_PAYMENT_REQUEST_SUCCESS'
export const updatePaymentRequestFailureActionType = 'UPDATE_PAYMENT_REQUEST_FAILURE'
export const addTemplateRequestActionType = 'ADD_TEMPLATE_REQUEST'
export const addTemplateRequestSuccessActionType = 'ADD_TEMPLATE_REQUEST_SUCCESS'
export const addTemplateRequestFailureActionType = 'ADD_TEMPLATE_REQUEST_FAILURE'
export const deleteTemplateRequestActionType = 'DELETE_TEMPLATE_REQUEST'
export const deleteTemplateRequestSuccessActionType = 'DELETE_TEMPLATE_REQUEST_SUCCESS'
export const deleteTemplateRequestFailureActionType = 'DELETE_TEMPLATE_REQUEST_FAILURE'
export const updateTemplateRequestActionType = 'UPDATE_TEMPLATE_REQUEST'
export const updateTemplateRequestSuccessActionType = 'UPDATE_TEMPLATE_REQUEST_SUCCESS'
export const updateTemplateRequestFailureActionType = 'UPDATE_TEMPLATE_REQUEST_FAILURE'
export const getCurrencyRequestActionType = 'GET_CURRENCY_REQUEST'
export const getCurrencyRequestSuccessActionType = 'GET_CURRENCY_REQUEST_SUCCESS'
export const getCurrencyRequestFailureActionType = 'GET_CURRENCY_REQUEST_FAILURE'
export const updateCurrencyRequestActionType = 'UPDATE_CURRENCY_REQUEST'
export const updateCurrencyRequestSuccessActionType = 'UPDATE_CURRENCY_REQUEST_SUCCESS'
export const updateCurrencyRequestFailureActionType = 'UPDATE_CURRENCY_REQUEST_FAILURE'
export const getSelectedCurrencyRequestActionType = 'GET_SELECTED_CURRENCY_REQUEST'
export const getSelectedCurrencyRequestSuccessActionType = 'GET_SELECTED_CURRENCY_REQUEST_SUCCESS'
export const getSelectedCurrencyRequestFailureActionType = 'GET_SELECTED_CURRENCY_REQUEST_FAILURE'
export const getEcommerceSettingRequestActionType = 'GET_ECOMMERCE_SETTING_REQUEST'
export const getEcommerceSettingRequestSuccessActionType = 'GET_ECOMMERCE_SETTING_REQUEST_SUCCESS'
export const getEcommerceSettingRequestFailureActionType = 'GET_ECOMMERCE_SETTING_REQUEST_FAILURE'
export const updateEcommerceSettingRequestActionType = 'UPDATE_ECOMMERCE_SETTING_REQUEST'
export const updateEcommerceSettingRequestSuccessActionType = 'UPDATE_ECOMMERCE_SETTING_REQUEST_SUCCESS'
export const updateEcommerceSettingRequestFailureActionType = 'UPDATE_ECOMMERCE_SETTING_REQUEST_FAILURE'
// export const getQuickBooksRequestActionType = 'GET_QUICK_BOOKS_REQUEST'
// export const getQuickBooksRequestSuccessActionType = 'GET_QUICK_BOOKS_REQUEST_SUCCESS'
// export const getQuickBooksRequestFailureActionType = 'GET_QUICK_BOOKS_REQUEST_FAILURE'
export const getQuickBooksProviderRequestActionType = 'GET_QUICKBOOKS_PROVIDER_REQUEST'
export const getQuickBooksProviderRequestSuccessActionType = 'GET_QUICKBOOK_PROVIDER_REQUEST_SUCCESS'
export const getQuickBooksProviderRequestFailureActionType = 'GET_QUICKBOOK_PROVIDER_REQUEST_FAILURE'

export const getConnectQuickBooksProviderRequestActionType = 'GET_CONNECT_QUICKBOOKS_PROVIDER_REQUEST'
export const getConnectQuickBooksProviderRequestSuccessActionType = 'GET_CONNECTT_QUICKBOOK_PROVIDER_REQUEST_SUCCESS'
export const getConnectQuickBooksProviderRequestFailureActionType = 'GET_CONNECT_QUICKBOOK_PROVIDER_REQUEST_FAILURE'

export const addQuickBooksProviderRequestActionType = 'ADD_QUICKBOOKS_PROVIDER_REQUEST'
export const addQuickBooksProviderRequestSuccessActionType = 'ADD_QUICKBOOK_PROVIDER_REQUEST_SUCCESS'
export const addQuickBooksProviderRequestFailureActionType = 'ADD_QUICKBOOK_PROVIDER_REQUEST_FAILURE'
export const getAttributeMappingRequestActionType = 'GET_ATTRIBUTEMAPPING_REQUEST'
export const getAttributeMappingRequestSuccessActionType = 'GET_ATTRIBUTEMAPPING_REQUEST_SUCCESS'
export const getAttributeMappingRequestFailureActionType = 'GET_ATTRIBUTEMAPPING_REQUEST_FAILURE'
export const addAttributeMappingRequestActionType = 'ADD_ATTRIBUTEMAPPING_REQUEST'
export const addAttributeMappingRequestSuccessActionType = 'ADD_ATTRIBUTEMAPPING_REQUEST_SUCCESS'
export const addAttributeMappingRequestFailureActionType = 'ADD_ATTRIBUTEMAPPING_REQUEST_FAILURE'
export const updateAttributeMappingRequestActionType = 'UPDATE_ATTRIBUTEMAPPING_REQUEST'
export const updateAttributeMappingRequestSuccessActionType = 'UPDATE_ATTRIBUTEMAPPING_REQUEST_SUCCESS'
export const updateAttributeMappingRequestFailureActionType = 'UPDATE_ATTRIBUTEMAPPING_REQUEST_FAILURE'
export const deleteAttributeMappingRequestActionType = 'DELETE_ATTRIBUTEMAPPING_REQUEST'
export const deleteAttributeMappingRequestSuccessActionType = 'DELETE_ATTRIBUTEMAPPING_REQUEST_SUCCESS'
export const deleteAttributeMappingRequestFailureActionType = 'DELETE_ATTRIBUTEMAPPING_REQUEST_FAILURE'
export const getSingleAttributeMappingRequestActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_REQUEST'
export const getSingleAttributeMappingRequestSuccessActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_REQUEST_SUCCESS'
export const getSingleAttributeMappingRequestFailureActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_REQUEST_FAILURE'
export const getAttributeMappingColumnListRequestActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_COLUMNLIST_REQUEST'
export const getAttributeMappingColumnListRequestSuccessActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_COLUMNLIST_REQUEST_SUCCESS'
export const getAttributeMappingColumnListRequestFailureActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_COLUMNLIST_REQUEST_FAILURE'
export const getAttributeMappingTypeListRequestActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_TYPELIST_REQUEST'
export const getAttributeMappingTypeListRequestSuccessActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_TYPELIST_REQUEST_SUCCESS'
export const getAttributeMappingTypeListRequestFailureActionType = 'GET_SINGLE_ATTRIBUTEMAPPING_TYPELIST_REQUEST_FAILURE'
