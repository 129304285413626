import React, { useEffect, useState, memo } from 'react';
import Data from '../../../components/utilities/sidebarData.json';
import Menu from './components/Menu';
import { MenuModel } from 'src/models/sidebarModel';
import { useNavigate } from 'react-router-dom';
interface IProps {
  isPinned: boolean;
  handleSidebarPinned: React.MouseEventHandler<HTMLElement>;
  handleSidebarHover: () => void;
  isSidebarHovered: boolean;
  handleMobileMenu: () => void;
}

const Sidebar: React.FC<IProps> = ({
  isPinned,
  handleSidebarPinned,
  handleSidebarHover,
  isSidebarHovered,
  handleMobileMenu
}) => {
  const [menuId, setMenuId] = useState<number | string>();
  const [subMenuId, setSubMenuId] = useState<number | string>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const navigate = useNavigate();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const handleMenuClick = (id: number, subMenuLength: number, menuRoute: string) => {
    if (menuRoute !== '') {
      navigate(menuRoute);
    }
    if (subMenuLength === 0 && isMobile) {
      handleMobileMenu();
    }
    if (menuId === id) {
      setMenuId('');
    } else {
      setMenuId(id);
      setSubMenuId('');
    }
  };

  useEffect(() => {
    if (location.pathname.includes('videos/live-event')) {
      setMenuId('');
    }
  }, [location.pathname]);

  const handleSubMenuClick = (id: number) => {
    if (isMobile) {
      handleMobileMenu();
    }
    setSubMenuId(id);
  };

  return (
    <div>
      <aside
        id="sidebar"
        className={`sidebar`}
        onMouseEnter={
          !isPinned
            ? handleSidebarHover
            : () => {
                return null;
              }
        }
        onMouseLeave={
          !isPinned
            ? handleSidebarHover
            : () => {
                return null;
              }
        }>
        <div className="flex flex-col space-y-6">
          <nav>
            <div className="flex justify-between items-center mb-3">
              <img
                src="../../assets/img/half-logo.png"
                className="w-9 mx-auto half-icon-logo smalltext-logo"
              />
              <img src="../../assets/img/wcc-logo.png" className="ml-1 full-logo text-logo" />
              <div className="flex items-center">
                <img
                  src="../../assets/img/unpin-icon.svg"
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1 ml-4 cursor-pointer mr-4 unpin-icon"
                />

                <img
                  src="../../assets/img/pin-icon.svg"
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1 ml-4 cursor-pointer mr-4 hidden pin-icon"
                />
              </div>
            </div>

            <ul>
              {Data &&
                Data.map((menus: MenuModel, index: number) => {
                  return (
                    <Menu
                      key={index}
                      index={index}
                      handleMenuClick={handleMenuClick}
                      menuId={menuId}
                      menuData={menus}
                      handleSubMenuClick={handleSubMenuClick}
                      subMenuId={subMenuId}
                      isSidebarHovered={isSidebarHovered}
                      isMobile={isMobile}
                    />
                  );
                })}
              <li>
                <a href="https://support.webcastcloud.com" target="_blank" rel="noreferrer">
                  <span className={`side-menu cursor-pointer`}>
                    <img className="w-4" src={`../../../assets/img/sidebar-icons/life-buoy.png`} />
                    <span className="text-sm ml-2">Support</span>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default memo(Sidebar);
