import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const MembesIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isIntegrationUpdated, membesIntegration, message, error } = useSelector(
    (state: RootState) => {
      return {
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        membesIntegration: state.setting.membesIntegration,
        message: state.setting.message,
        error: state.setting.error
      };
    }
  );

  const [showClientSecret, setShowClientSecret] = useState(false);

  useEffect(() => {
    if (membesIntegration) {
      reset(membesIntegration);
    }
  }, [membesIntegration]);

  useEffect(() => {
    settingAction.getMembesIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    if (membesIntegration == undefined || membesIntegration.id == undefined) {
      settingAction.addMembesIntegrationRequest(data);
    } else {
      const payloadData = {
        name: 'Membes',
        updateData: [
          { op: 'replace', path: 'clientId', value: data.clientId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'orgId', value: data.orgId },
          { op: 'replace', path: 'apiV2AuthApiUrl', value: data.apiV2AuthApiUrl },
          { op: 'replace', path: 'apiV2BaseURL', value: data.apiV2BaseURL },
          { op: 'replace', path: 'apiV2ApiURl', value: data.apiV2ApiURl },
          { op: 'replace', path: 'profileApiUrl', value: data.profileApiUrl },
          { op: 'replace', path: 'useActiveFlagMember', value: data.useActiveFlagMember }
        ]
      };
      settingAction.updateIntegrationRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> Membes Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5 w-5/6">
        <div className="md:flex items-center mb-3 mt-3">
          <div className="md:w-4/12">
            <label className="font-semibold">Client Id</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'clientId'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-3 mt-3">
          <div className="md:w-4/12">
            <label className="font-semibold">Client Secret</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showClientSecret ? 'text' : 'password'}
              fieldName={'clientSecret'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showClientSecret ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowClientSecret(!showClientSecret)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">OrgId</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'orgId'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Auth Base URL</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'apiV2BaseURL'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Token Path</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'apiV2AuthApiUrl'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">API URL</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'apiV2ApiURl'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-3 mt-3">
          <div className="md:w-4/12">
            <label className="font-semibold">User Profile Api Url</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'profileApiUrl'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Use Active flag as Member</label>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex-items mb-2">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    id="useActiveFlagMember"
                    {...register('useActiveFlagMember')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center p-5 mt-2">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default MembesIntegration;
