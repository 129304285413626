import { all, put, takeEvery } from 'redux-saga/effects';
import { UsersActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import memberData from '../../components/utilities/member.json';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetUsersData(data: UsersActions.getUserRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_USERS.url}`);
        if (response.status === 200) {
            yield put(UsersActions.getUserRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getUserRequestFailure());
    }
}
function* asyncGetUserRolesData(data: UsersActions.getUserRoleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ROLES.url}`);
        if (response.status === 200) {
            yield put(UsersActions.getUserRoleRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getUserRoleRequestFailure());
    }
}
function* asyncGetUserDetails(data: UsersActions.getUserDetailsRequestAction) {
    try {

        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_USER_DETAILS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getUserDetailsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getUserDetailsRequestFailure());
    }
}
function* asyncAddUsers(data: UsersActions.addUserRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_USERS.url, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.addUserRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.addUserRequestFailure());
    }
}
function* asyncUpdateUser(data: UsersActions.UpdateUserRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_USERS.url}/${data.payload?.userId}`, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.UpdateUserRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.UpdateUserRequestFailure());
    }
}

function* asyncGetMembersData(data: UsersActions.getMembersRequestAction) {
    try {
        if (data.payload) {
            const { pageNumber, MemberListId, RoleId, Active, SortFieldName, PageSize } = data.payload
            const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS.url}?pageNumber=${pageNumber ? pageNumber : 1}&PageSize=${PageSize ? PageSize : 50}&MemberListId=${MemberListId ? MemberListId : 0}&RoleId=${RoleId ? RoleId : ''}&Active=${Active ? Active : ''}&SortFieldName=${SortFieldName ? SortFieldName : ''}`);
            if (response.status === 200) {
                yield put(UsersActions.getMembersRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
            }
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMembersRequestFailure());
    }
}

function* asyncAddMember(data: UsersActions.addMembersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MEMBERS.url, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.addMembersRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.addMembersRequestFailure());
    }
}

function* asyncGetMemberDetails(data: UsersActions.getMemberDetailRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_DETAILS.url}/${data.payload}`);
        if (response.status === 200) {
            const finalData = { ...response.data.result, email: response.data.result.emailAddress }
            yield put(UsersActions.getMemberDetailRequestSuccess(finalData));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberDetailRequestFailure());
    }
}
function* asyncEditMember(data: UsersActions.editMemberRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.EDIT_MEMBERS_DETAILS.url}/${data.payload?.id}`, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.editMemberRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.editMemberRequestFailure());
    }
}

function* asyncUploadProfile(data: UsersActions.addMembersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.uploadProfileRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.uploadProfileRequestFailure());
    }
}
function* asyncUpdateCreditPrefrences(data: UsersActions.editMemberRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.putData(``, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.updateCreditPreferenceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.updateCreditPreferenceRequestFailure());
    }
}
function* asyncGetStripeSubscription(data: UsersActions.getStripeSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(``);
        if (response.status === 200) {
            yield put(UsersActions.getStripeSubscriptionRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getStripeSubscriptionRequestFailure());
    }
}

function* asyncCancelStripeSubscription(data: UsersActions.cancelStripeSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.cancelStripeSubscriptionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.cancelStripeSubscriptionRequestFailure());
    }
}
function* asyncMemberVideoAnalytics(data: UsersActions.getMemberVideoAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_VIDEO_ANALYTICS.url}?memberId=${data.payload.memberId}&pageNo=${data.payload.pageNumber}&pageSize=5`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberVideoAnalyticsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberVideoAnalyticsRequestFailure());
    }
}
function* asyncMemberSubscription(data: UsersActions.getMemberSubscriptionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_SUBSCRIPTION.url}?memberId=${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberSubscriptionRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberSubscriptionRequestFailure());
    }
}

function* asyncCheckUserNameAvailable(data: UsersActions.checkUserNameAvailableRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.CHECK_USER_NAME_AVAILABLE.url}?userName=${data.payload}`, data.payload);

        if (response.status === 200) {
            yield put(UsersActions.checkUserNameAvailableRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.checkUserNameAvailableRequestFailure());
    }
}
function* asyncGetUserProfile(data: UsersActions.getUserProfileRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(``);
        if (response.status === 200) {
            yield put(UsersActions.getUserProfileRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getUserProfileRequestFailure());
    }
}
function* asyncGetMemberInvoice(data: UsersActions.getMemberInvoicesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_INVOICE.url}?MemberId=${data.payload?.id}&PageNumber=${data.payload?.pageNumber}&PageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberInvoicesRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberInvoicesRequestFailure());
    }
}
function* asyncGetTotalMembers(data: UsersActions.getTotalMembersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBER_WIDGETS_ANALYSIS.url}`);
        if (response.status === 200) {
            const widgetsData = [
                {
                    id: 1,
                    name: 'Total Members',
                    total: response.data.result.totalMembers,
                    icon: 'fas fa-user-group'
                },
                {
                    id: 2,
                    name: 'Engaged Members',
                    total: response.data.result.totalEngagedMembers,
                    icon: 'fas fa-user-check'
                },
                {
                    id: 3,
                    name: 'Total Subscribers',
                    total: response.data.result.totalSubscribers,
                    icon: 'fas fa-credit-card'
                },
                {
                    id: 4,
                    name: 'Product Members',
                    total: response.data.result.totalProductMembers,
                    icon: 'fa-solid fa-cart-shopping'
                },
            ]
            yield put(UsersActions.getTotalMembersRequestSuccess(widgetsData));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getTotalMembersRequestFailure());
    }
}

function* asyncGetSubscriptionProducts(data: UsersActions.getSubscriptionProductsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBER_SUBSCRIPTION.url}?memberid=${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getSubscriptionProductsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getSubscriptionProductsRequestFailure());
    }
}

function* asyncDeleteMemberList(data: UsersActions.deleteMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MEMBER_LIST.url}/${data.payload}`);
        if (response.status === 200) {
            let message
            if (response.data === 'Can not delete member list as it contains values.') {
                message = 'Can not delete member list as it contains values.'
            } else {
                message = 'success'
            }

            yield put(UsersActions.deleteMemberListRequestSuccess(message));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.deleteMemberListRequestFailure());
    }
}

function* asyncDeleteMember(data: UsersActions.deleteMemberRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MEMBER.url}?memberId=${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.deleteMemberRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.deleteMemberRequestFailure());
    }
}

function* asyncGetMemberList(data: UsersActions.getMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBER_LIST.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNo=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberListRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberListRequestFailure());
    }
}
function* asyncGetUserActivityTimeLine(data: UsersActions.getUserActivityTimeLineRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_MEMBER_ACTIVITY_TIMELINE.url}`, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.getUserActivityTimeLineRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getUserActivityTimeLineRequestFailure());
    }
}
function* asyncSaveMemberList(data: UsersActions.saveMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_MEMBER_LIST.url, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.saveMemberListRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.saveMemberListRequestFailure());
    }
}
function* asyncUpdateMemberList(data: UsersActions.updateMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDataWithContentType(`${URLS.UPDATE_MEMBER_LIST.url}/${data.payload?.id}`, data.payload?.memberListName);
        if (response.status === 200) {
            yield put(UsersActions.updateMemberListRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.updateMemberListRequestFailure());
    }
}
function* asyncGetSingleMemberList(data: UsersActions.getSingleMemberListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_MEMBER_LIST.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getSingleMemberListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getSingleMemberListRequestFailure());
    }
}
function* asyncSaveMemberListType(data: UsersActions.saveMemberListTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(``, data);
        if (response.status === 200) {
            yield put(UsersActions.saveMemberListTypeRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.saveMemberListTypeRequestFailure());
    }
}
function* asyncSearchMember(data: UsersActions.searchMemberRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SEARCH_MEMBER_LIST.url}?SearchText=${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.searchMemberRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.searchMemberRequestFailure());
    }
}
function* asyncGetRoles(data: UsersActions.getRolesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_ROLES.url}`);
        if (response.status === 200) {
            yield put(UsersActions.getRolesRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getRolesRequestFailure());
    }
}
function* asyncGetStatus(data: UsersActions.getStatusRequestAction) {
    try {
        const statusHardcodedData = [
            {
                id: 1,
                name: 'Active',
                value: 'true'
            },
            {
                id: 2,
                name: 'Inactive',
                value: 'false'
            }
        ]
        // const response: AxiosResponse = yield ApiService.getData(``);
        // if (response.status === 200) {
        yield put(UsersActions.getStatusRequestSuccess(statusHardcodedData));
        // }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getStatusRequestFailure());
    }
}
function* asyncGetLoginHistory(data: UsersActions.getLoginHistoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_LOGIN_HISTORY.url}/${data.payload?.id}?pageNumber=${data.payload?.pageNumber}&pageSize=20`);
        if (response.status === 200) {
            yield put(UsersActions.getLoginHistoryRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getLoginHistoryRequestFailure());
    }
}
function* asyncGetMemberListByMemberId(data: UsersActions.getMemberListByMemberIdRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS.url}/${data.payload}/memberlist`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberListByMemberIdRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberListByMemberIdRequestFailure());
    }
}
function* asyncGetMemberEventRegistration(data: UsersActions.getMemberEventRegistrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_EVENT_REGISTRATION.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberEventRegistrationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberEventRegistrationRequestFailure());
    }
}
function* asyncGetMemberCountData(data: UsersActions.getMemberCountDataRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MEMBERS_COUNT_DATA.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.getMemberCountDataRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.getMemberCountDataRequestFailure());
    }
}
function* asyncUpdateMemberProfile(data: UsersActions.updateMemberProfileRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDataWithContentType(`${URLS.UPDATE_MEMBER_PROFILE.url}/${data.payload?.memberId}`, data.payload?.profileUrl);
        if (response.status === 200) {
            yield put(UsersActions.updateMemberProfileRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.updateMemberProfileRequestFailure());
    }
}
function* asyncUploadEmailDomain(data: UsersActions.uploadEmailDomainRequestAction) {
    try {
        const requestBody = { Values: data.payload?.values };
        const response: AxiosResponse = yield ApiService.postData(`${URLS.UPLOAD_MEMBER_LIST_DETAILS.url}/${data.payload?.id}?type=${data.payload?.type}`, requestBody);
        if (response.data.statusCode === 304) {
            yield put(UsersActions.uploadEmailDomainRequestDataExistAction());
        }
        else if (response.status === 200) {
            yield put(UsersActions.uploadEmailDomainRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.uploadEmailDomainRequestFailure());
    }
}
function* asyncUploadEmail(data: UsersActions.uploadEmailRequestAction) {
    try {
        const requestBody = { Values: data.payload?.values };
        const response: AxiosResponse = yield ApiService.postData(`${URLS.UPLOAD_MEMBER_LIST_DETAILS.url}/${data.payload?.id}?type=${data.payload?.type}`, requestBody);
        if (response.data.statusCode === 304) {
            yield put(UsersActions.uploadEmailRequestDataExistAction());
        }
        else if (response.status === 200) {
            yield put(UsersActions.uploadEmailRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.uploadEmailRequestFailure());
    }
}
function* asyncUploadUserName(data: UsersActions.uploadUserNameRequestAction) {
    try {
        const requestBody = { Values: data.payload?.values };
        const response: AxiosResponse = yield ApiService.postData(`${URLS.UPLOAD_MEMBER_LIST_DETAILS.url}/${data.payload?.id}?type=${data.payload?.type}`, requestBody);
        if (response.data.statusCode === 304) {
            yield put(UsersActions.uploadUserNameRequestDataExistAction());
        }
        else if (response.status === 200) {
            yield put(UsersActions.uploadUserNameRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.uploadUserNameRequestFailure());
    }
}
function* asyncSearchMemberDetails(data: UsersActions.searchMemberListDetailsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.SEARCH_MEMBER_LIST_DETAILS.url}/${data.payload?.memberlistId}/${data.payload?.type}?filter=${data.payload?.filter ? data.payload?.filter : ''}&pageNo=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(UsersActions.searchMemberListDetailsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.searchMemberListDetailsRequestFailure());
    }
}
function* asyncDeleteMemberDetails(data: UsersActions.deleteMemberListDetailsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MEMBER_LIST_DETAILS.url}/${data.payload?.memberlistId}/${data.payload?.id}?type=${data.payload?.type}`);
        if (response.status === 200) {
            yield put(UsersActions.deleteMemberListDetailsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.deleteMemberListDetailsRequestFailure());
    }
}

function* asyncChangePassword(data: UsersActions.changePasswordRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_USERS.url}/changePassword/${data.payload?.userId}`, data.payload);
        if (response.status === 200) {
            yield put(UsersActions.changePasswordRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.changePasswordRequestFailure());
    }
}

function* asyncUserMfaSetup(data: UsersActions.UserMfaSetupRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchDatas(`${URLS.MFA_SETUP.url}?userId=${data.payload?.userId}&isMfaSetupEnabled=${data.payload?.isMfaSetupEnabled}&userRole=${data.payload?.userRole}`);
        if (response.status === 200) {
            yield put(UsersActions.UserMfaSetupRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.UserMfaSetupRequestFailure());
    }
}

function* asyncGenerateMFAQr(data: UsersActions.GenerateMFAQrRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GENERATE_MFA_QR.url}?userId=${data.payload}`);
        if (response.status === 200) {
            yield put(UsersActions.generateMFAQrRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.generateMFAQrRequestFailure());
    }
}

function* asyncVerifyCode(data: UsersActions.VerifyMfaCodeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.VERIFY_MFA_CODE.url}?userId=${data.payload?.userId}&otp=${data.payload?.otp}`);
        if (response.status === 200) {
            yield put(UsersActions.VerifyMfaCodeRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(UsersActions.VerifyMfaCodeRequestFailure());
    }
}

export function* usersSaga() {
    yield all([
        takeEvery(Constants.getUserRequestActionType, asyncGetUsersData),
        takeEvery(Constants.getUserRoleRequestActionType, asyncGetUserRolesData),
        takeEvery(Constants.getUserDetailsRequestActionType, asyncGetUserDetails),
        takeEvery(Constants.getMembersRequestActionType, asyncGetMembersData),
        takeEvery(Constants.addUserRequestActionType, asyncAddUsers),
        takeEvery(Constants.addMembersRequestActionType, asyncAddMember),
        takeEvery(Constants.getMembersDetailRequestActionType, asyncGetMemberDetails),
        takeEvery(Constants.editMemberRequestActionType, asyncEditMember),
        takeEvery(Constants.UpdateUserRequestActionType, asyncUpdateUser),
        takeEvery(Constants.uploadProfileRequestActionType, asyncUploadProfile),
        takeEvery(Constants.updateCreditPreferencesRequestActionType, asyncUpdateCreditPrefrences),
        takeEvery(Constants.getStripeSubscriptionRequestActionType, asyncGetStripeSubscription),
        takeEvery(Constants.cancelStripeSubscriptionRequestActionType, asyncCancelStripeSubscription),
        takeEvery(Constants.checkUserNameAvailableRequestActionType, asyncCheckUserNameAvailable),
        takeEvery(Constants.getMemberVideoAnalyticsRequestActionType, asyncMemberVideoAnalytics),
        takeEvery(Constants.getMemberSubscriptionRequestActionType, asyncMemberSubscription),
        takeEvery(Constants.getUserProfileRequestActionType, asyncGetUserProfile),
        takeEvery(Constants.getMemberInvoicesRequestActionType, asyncGetMemberInvoice),
        takeEvery(Constants.getTotalMembersRequestActionType, asyncGetTotalMembers),
        takeEvery(Constants.getSubscriptionProductsRequestActionType, asyncGetSubscriptionProducts),
        takeEvery(Constants.deleteMemberListRequestActionType, asyncDeleteMemberList),
        takeEvery(Constants.deleteMembersRequestActionType, asyncDeleteMember),
        takeEvery(Constants.getMemberListRequestActionType, asyncGetMemberList),
        takeEvery(Constants.getUserActivityTimeLineRequestActionType, asyncGetUserActivityTimeLine),
        takeEvery(Constants.saveMemberListRequestActionType, asyncSaveMemberList),
        takeEvery(Constants.updateMemberListRequestActionType, asyncUpdateMemberList),
        takeEvery(Constants.getSingleMemberListRequestActionType, asyncGetSingleMemberList),
        takeEvery(Constants.saveMemberListTypeRequestActionType, asyncSaveMemberListType),
        takeEvery(Constants.searchMemberRequestActionType, asyncSearchMember),
        takeEvery(Constants.getRolesRequestActionType, asyncGetRoles),
        takeEvery(Constants.getStatusRequestActionType, asyncGetStatus),
        takeEvery(Constants.getLoginHistoryRequestActionType, asyncGetLoginHistory),
        takeEvery(Constants.getMemberListByMemberIdRequestActionType, asyncGetMemberListByMemberId),
        takeEvery(Constants.getMemberEventRegistrationRequestActionType, asyncGetMemberEventRegistration),
        takeEvery(Constants.getMemberCountDataRequestActionType, asyncGetMemberCountData),
        takeEvery(Constants.updateMemberProfileRequestActionType, asyncUpdateMemberProfile),
        takeEvery(Constants.uploadEmailDomainRequestActionType, asyncUploadEmailDomain),
        takeEvery(Constants.uploadEmailRequestActionType, asyncUploadEmail),
        takeEvery(Constants.uploadUserNameRequestActionType, asyncUploadUserName),
        takeEvery(Constants.searchMemberListDetailsRequestActionType, asyncSearchMemberDetails),
        takeEvery(Constants.deleteMemberListDetailsRequestActionType, asyncDeleteMemberDetails),
        takeEvery(Constants.changePasswordRequestActionType, asyncChangePassword),
        takeEvery(Constants.userMfaSetupRequestActionType, asyncUserMfaSetup),
        takeEvery(Constants.generateMFAQrRequestActionType, asyncGenerateMFAQr),
        takeEvery(Constants.verifyMfaCodeRequestActionType, asyncVerifyCode),
    ]);
}
