import { createAction } from "redux-actions"
import { useMemo } from 'react';
import { EventStyleModel, UpdateEventReminderModal, GetEventReminderModal, GetEventVideoModal, EventVideoModal, EventModel, EmailSettingModal, EventLayoutModal, RegistrationFieldModel, RegistrationModel, PatchJsonModel, PatchEmailSettingsData } from "src/models/eventModel";
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { PagingDataModel } from 'src/models/paginationModel';
import { SearchModel } from "src/models/commonModels";
export namespace EventActions {

    export type saveEventRequestAction = Action<typeof Constants.saveEventRequestActionType, string>
    export type saveEventRequestSuccessAction = Action<typeof Constants.saveEventRequestSuccessActionType, any>;
    export type saveEventRequestFailureAction = Action<typeof Constants.saveEventRequestFailureActionType, null>;

    export function saveEventRequest(P: string): saveEventRequestAction {
        return ActionCreator(Constants.saveEventRequestActionType, P);
    }
    export function saveEventRequestSuccess(data: any): saveEventRequestSuccessAction {
        return ActionCreator(Constants.saveEventRequestSuccessActionType, data);
    }
    export function saveEventRequestFailure(): saveEventRequestFailureAction {
        return ActionCreator(Constants.saveEventRequestFailureActionType, null);
    }

    export type updateEventRequestAction = Action<typeof Constants.updateEventRequestActionType, PatchJsonModel>
    export type updateEventRequestSuccessAction = Action<typeof Constants.updateEventRequestSuccessActionType, any>;
    export type updateEventRequestFailureAction = Action<typeof Constants.updateEventRequestFailureActionType, null>;

    export function updateEventRequest(P: PatchJsonModel): updateEventRequestAction {
        return ActionCreator(Constants.updateEventRequestActionType, P);
    }
    export function updateEventRequestSuccess(data: any): updateEventRequestSuccessAction {
        return ActionCreator(Constants.updateEventRequestSuccessActionType, data);
    }
    export function updateEventRequestFailure(): updateEventRequestFailureAction {
        return ActionCreator(Constants.updateEventRequestFailureActionType, null);
    }

    export type getEventRequestAction = Action<typeof Constants.getEventRequestActionType, any>
    export type getEventRequestSuccessAction = Action<typeof Constants.getEventRequestSuccessActionType, PagingDataModel<EventModel>>;
    export type getEventRequestFailureAction = Action<typeof Constants.getEventRequestFailureActionType, null>;

    export function getEventRequest(P: any): getEventRequestAction {
        return ActionCreator(Constants.getEventRequestActionType, P);
    }
    export function getEventRequestSuccess(P: PagingDataModel<EventModel>): getEventRequestSuccessAction {
        return ActionCreator(Constants.getEventRequestSuccessActionType, P);
    }
    export function getEventRequestFailure(): getEventRequestFailureAction {
        return ActionCreator(Constants.getEventRequestFailureActionType, null);
    }

    export type getSingleEventRequestAction = Action<typeof Constants.getSingleEventRequestActionType, string>
    export type getSingleEventRequestSuccessAction = Action<typeof Constants.getSingleEventRequestSuccessActionType, any>;
    export type getSingleEventRequestFailureAction = Action<typeof Constants.getSingleEventRequestFailureActionType, null>;

    export function getSingleEventRequest(P: any): getSingleEventRequestAction {
        return ActionCreator(Constants.getSingleEventRequestActionType, P);
    }
    export function getSingleEventRequestSuccess(P: EventModel): getSingleEventRequestSuccessAction {
        return ActionCreator(Constants.getSingleEventRequestSuccessActionType, P);
    }
    export function getSingleEventRequestFailure(): getSingleEventRequestFailureAction {
        return ActionCreator(Constants.getSingleEventRequestFailureActionType, null);
    }

    export type getEmailSettingRequestAction = Action<typeof Constants.getEmailSettingRequestActionType, string>
    export type getEmailSettingRequestSuccessAction = Action<typeof Constants.getEmailSettingRequestSuccessActionType, EmailSettingModal>;
    export type getEmailSettingRequestFailureAction = Action<typeof Constants.getEmailSettingRequestFailureActionType, null>;

    export function getEmailSettingRequest(P: string): getEmailSettingRequestAction {
        return ActionCreator(Constants.getEmailSettingRequestActionType, P);
    }
    export function getEmailSettingRequestSuccess(P: EmailSettingModal): getEmailSettingRequestSuccessAction {
        return ActionCreator(Constants.getEmailSettingRequestSuccessActionType, P);
    }
    export function getEmailSettingRequestFailure(): getEmailSettingRequestFailureAction {
        return ActionCreator(Constants.getEmailSettingRequestFailureActionType, null);
    }

    export type updateEmailSettingRequestAction = Action<typeof Constants.updateEmailSettingRequestActionType, PatchEmailSettingsData>
    export type updateEmailSettingRequestSuccessAction = Action<typeof Constants.updateEmailSettingRequestSuccessActionType, any>;
    export type updateEmailSettingRequestFailureAction = Action<typeof Constants.updateEmailSettingRequestFailureActionType, null>;

    export function updateEmailSettingRequest(P: PatchEmailSettingsData): updateEmailSettingRequestAction {
        return ActionCreator(Constants.updateEmailSettingRequestActionType, P);
    }
    export function updateEmailSettingRequestSuccess(data: any): updateEmailSettingRequestSuccessAction {
        return ActionCreator(Constants.updateEmailSettingRequestSuccessActionType, data);
    }
    export function updateEmailSettingRequestFailure(): updateEmailSettingRequestFailureAction {
        return ActionCreator(Constants.updateEmailSettingRequestFailureActionType, null);
    }

    export type getEventLayoutRequestAction = Action<typeof Constants.getEventLayoutRequestActionType, string>
    export type getEventLayoutRequestSuccessAction = Action<typeof Constants.getEventLayoutRequestSuccessActionType, EventLayoutModal>;
    export type getEventLayoutRequestFailureAction = Action<typeof Constants.getEventLayoutRequestFailureActionType, null>;

    export function getEventLayoutRequest(P: string): getEventLayoutRequestAction {
        return ActionCreator(Constants.getEventLayoutRequestActionType, P);
    }
    export function getEventLayoutRequestSuccess(P: EventLayoutModal): getEventLayoutRequestSuccessAction {
        return ActionCreator(Constants.getEventLayoutRequestSuccessActionType, P);
    }
    export function getEventLayoutRequestFailure(): getEventLayoutRequestFailureAction {
        return ActionCreator(Constants.getEventLayoutRequestFailureActionType, null);
    }

    export type updateEventLayoutRequestAction = Action<typeof Constants.updateEventLayoutRequestActionType, EventStyleModel>
    export type updateEventLayoutRequestSuccessAction = Action<typeof Constants.updateEventLayoutRequestSuccessActionType, any>;
    export type updateEventLayoutRequestFailureAction = Action<typeof Constants.updateEventLayoutRequestFailureActionType, null>;

    export function updateEventLayoutRequest(P: EventStyleModel): updateEventLayoutRequestAction {
        return ActionCreator(Constants.updateEventLayoutRequestActionType, P);
    }
    export function updateEventLayoutRequestSuccess(data: any): updateEventLayoutRequestSuccessAction {
        return ActionCreator(Constants.updateEventLayoutRequestSuccessActionType, data);
    }
    export function updateEventLayoutRequestFailure(): updateEventLayoutRequestFailureAction {
        return ActionCreator(Constants.updateEventLayoutRequestFailureActionType, null);
    }

    export type getEventRegistrationsRequestAction = Action<typeof Constants.getEventRegistrationsRequestActionType, string>
    export type getEventRegistrationsRequestSuccessAction = Action<typeof Constants.getEventRegistrationsRequestSuccessActionType, any>;
    export type getEventRegistrationsRequestFailureAction = Action<typeof Constants.getEventRegistrationsRequestFailureActionType, null>;

    export function getEventRegistrationsRequest(P: any): getEventRegistrationsRequestAction {
        return ActionCreator(Constants.getEventRegistrationsRequestActionType, P);
    }
    export function getEventRegistrationsRequestSuccess(P: RegistrationModel): getEventRegistrationsRequestSuccessAction {
        return ActionCreator(Constants.getEventRegistrationsRequestSuccessActionType, P);
    }
    export function getEventRegistrationsRequestFailure(): getEventRegistrationsRequestFailureAction {
        return ActionCreator(Constants.getEventRegistrationsRequestFailureActionType, null);
    }

    export type deleteEventRegistrationsRequestAction = Action<typeof Constants.deleteEventRegistrationsRequestActionType, any>
    export type deleteEventRegistrationsRequestSuccessAction = Action<typeof Constants.deleteEventRegistrationsRequestSuccessActionType, null>;
    export type deleteEventRegistrationsRequestFailureAction = Action<typeof Constants.deleteEventRegistrationsRequestFailureActionType, null>;

    export function deleteEventRegistrationsRequest(P: any): deleteEventRegistrationsRequestAction {
        return ActionCreator(Constants.deleteEventRegistrationsRequestActionType, P);
    }
    export function deleteEventRegistrationsRequestSuccess(): deleteEventRegistrationsRequestSuccessAction {
        return ActionCreator(Constants.deleteEventRegistrationsRequestSuccessActionType, null);
    }
    export function deleteEventRegistrationsRequestFailure(): deleteEventRegistrationsRequestFailureAction {
        return ActionCreator(Constants.deleteEventRegistrationsRequestFailureActionType, null);
    }

    export type getRegistrationsDownloadRequestAction = Action<typeof Constants.getRegistrationsDownloadRequestActionType, string>
    export type getRegistrationsDownloadRequestSuccessAction = Action<typeof Constants.getRegistrationsDownloadRequestSuccessActionType, Array<string>>;
    export type getRegistrationsDownloadRequestFailureAction = Action<typeof Constants.getRegistrationsDownloadRequestFailureActionType, null>;

    export function getRegistrationsDownloadRequest(P: string): getRegistrationsDownloadRequestAction {
        return ActionCreator(Constants.getRegistrationsDownloadRequestActionType, P);
    }
    export function getRegistrationsDownloadRequestSuccess(P: Array<string>): getRegistrationsDownloadRequestSuccessAction {
        return ActionCreator(Constants.getRegistrationsDownloadRequestSuccessActionType, P);
    }
    export function getRegistrationsDownloadRequestFailure(): getRegistrationsDownloadRequestFailureAction {
        return ActionCreator(Constants.getRegistrationsDownloadRequestFailureActionType, null);
    }

    export type getRegistrationFieldRequestAction = Action<typeof Constants.getRegistrationFieldRequestActionType, null>
    export type getRegistrationFieldRequestSuccessAction = Action<typeof Constants.getRegistrationFieldRequestSuccessActionType, RegistrationFieldModel>;
    export type getRegistrationFieldRequestFailureAction = Action<typeof Constants.getRegistrationFieldRequestFailureActionType, null>;

    export function getRegistrationFieldRequest(): getRegistrationFieldRequestAction {
        return ActionCreator(Constants.getRegistrationFieldRequestActionType, null);
    }
    export function getRegistrationFieldRequestSuccess(P: RegistrationFieldModel): getRegistrationFieldRequestSuccessAction {
        return ActionCreator(Constants.getRegistrationFieldRequestSuccessActionType, P);
    }
    export function getRegistrationFieldRequestFailure(): getRegistrationFieldRequestFailureAction {
        return ActionCreator(Constants.getRegistrationFieldRequestFailureActionType, null);
    }

    export type saveRegistrationFieldRequestAction = Action<typeof Constants.saveRegistrationFieldRequestActionType, RegistrationFieldModel>
    export type saveRegistrationFieldRequestSuccessAction = Action<typeof Constants.saveRegistrationFieldRequestSuccessActionType, null>;
    export type saveRegistrationFieldRequestFailureAction = Action<typeof Constants.saveRegistrationFieldRequestFailureActionType, null>;

    export function saveRegistrationFieldRequest(P: RegistrationFieldModel): saveRegistrationFieldRequestAction {
        return ActionCreator(Constants.saveRegistrationFieldRequestActionType, P);
    }
    export function saveRegistrationFieldRequestSuccess(): saveRegistrationFieldRequestSuccessAction {
        return ActionCreator(Constants.saveRegistrationFieldRequestSuccessActionType, null);
    }
    export function saveRegistrationFieldRequestFailure(): saveRegistrationFieldRequestFailureAction {
        return ActionCreator(Constants.saveRegistrationFieldRequestFailureActionType, null);
    }

    export type deleteRegistrationFieldRequestAction = Action<typeof Constants.deleteRegistrationFieldRequestActionType, string | number>
    export type deleteRegistrationFieldRequestSuccessAction = Action<typeof Constants.deleteRegistrationFieldRequestSuccessActionType, null>;
    export type deleteRegistrationFieldRequestFailureAction = Action<typeof Constants.deleteRegistrationFieldRequestFailureActionType, null>;

    export function deleteRegistrationFieldRequest(P: string | number): deleteRegistrationFieldRequestAction {
        return ActionCreator(Constants.deleteRegistrationFieldRequestActionType, P);
    }
    export function deleteRegistrationFieldRequestSuccess(): deleteRegistrationFieldRequestSuccessAction {
        return ActionCreator(Constants.deleteRegistrationFieldRequestSuccessActionType, null);
    }
    export function deleteRegistrationFieldRequestFailure(): deleteRegistrationFieldRequestFailureAction {
        return ActionCreator(Constants.deleteRegistrationFieldRequestFailureActionType, null);
    }

    export type updateIsRequiredRequestAction = Action<typeof Constants.updateIsRequiredRequestActionType, any>
    export type updateIsRequiredRequestSuccessAction = Action<typeof Constants.updateIsRequiredRequestSuccessActionType, any>;
    export type updateIsRequiredRequestFailureAction = Action<typeof Constants.updateIsRequiredRequestFailureActionType, null>;

    export function updateIsRequiredRequest(P: any): updateIsRequiredRequestAction {
        return ActionCreator(Constants.updateIsRequiredRequestActionType, P);
    }
    export function updateIsRequiredRequestSuccess(P: any): updateIsRequiredRequestSuccessAction {
        return ActionCreator(Constants.updateIsRequiredRequestSuccessActionType, P);
    }
    export function updateIsRequiredRequestFailure(): updateIsRequiredRequestFailureAction {
        return ActionCreator(Constants.updateIsRequiredRequestFailureActionType, null);
    }

    export type updateIsEnableRequestAction = Action<typeof Constants.updateIsEnableRequestActionType, any>
    export type updateIsEnableRequestSuccessAction = Action<typeof Constants.updateIsEnableRequestSuccessActionType, any>;
    export type updateIsEnableRequestFailureAction = Action<typeof Constants.updateIsEnableRequestFailureActionType, null>;

    export function updateIsEnableRequest(P: any): updateIsEnableRequestAction {
        return ActionCreator(Constants.updateIsEnableRequestActionType, P);
    }
    export function updateIsEnableRequestSuccess(P: any): updateIsEnableRequestSuccessAction {
        return ActionCreator(Constants.updateIsEnableRequestSuccessActionType, P);
    }
    export function updateIsEnableRequestFailure(): updateIsEnableRequestFailureAction {
        return ActionCreator(Constants.updateIsEnableRequestFailureActionType, null);
    }

    export type updateIsPasscodeRequiredRequestAction = Action<typeof Constants.updatePasscodeIsRequiredRequestActionType, any>
    export type updateIsPasscodeRequiredeRequestSuccessAction = Action<typeof Constants.updatePasscodeIsRequiredRequestSuccessActionType, any>;
    export type updateIsPasscodeRequiredRequestFailureAction = Action<typeof Constants.updatePasscodeIsRequiredRequestFailureActionType, null>;

    export function updateIsPasscodeRequiredRequest(P: any): updateIsPasscodeRequiredRequestAction {
        return ActionCreator(Constants.updatePasscodeIsRequiredRequestActionType, P);
    }
    export function updateIsPasscodeRequiredRequestSuccess(P: any): updateIsPasscodeRequiredeRequestSuccessAction {
        return ActionCreator(Constants.updatePasscodeIsRequiredRequestSuccessActionType, P);
    }
    export function updateIsPasscodeRequiredRequestFailure(): updateIsPasscodeRequiredRequestFailureAction {
        return ActionCreator(Constants.updatePasscodeIsRequiredRequestFailureActionType, null);
    }

    export type importRegistrationRequestAction = Action<typeof Constants.importRegistrationRequestActionType, any>
    export type importRegistrationRequestSuccessAction = Action<typeof Constants.importRegistrationRequestSuccessActionType, null>;
    export type importRegistrationRequestFailureAction = Action<typeof Constants.importRegistrationRequestFailureActionType, null>;

    export function importRegistrationRequest(P: any): importRegistrationRequestAction {
        return ActionCreator(Constants.importRegistrationRequestActionType, P);
    }
    export function importRegistrationRequestSuccess(): importRegistrationRequestSuccessAction {
        return ActionCreator(Constants.importRegistrationRequestSuccessActionType, null);
    }
    export function importRegistrationRequestFailure(): importRegistrationRequestFailureAction {
        return ActionCreator(Constants.importRegistrationRequestFailureActionType, null);
    }

    export type getEventVideoRequestAction = Action<typeof Constants.getEventVideoRequestActionType, GetEventVideoModal>
    export type getEventVideoRequestSuccessAction = Action<typeof Constants.getEventVideoRequestSuccessActionType, EventVideoModal[]>;
    export type getEventVideoRequestFailureAction = Action<typeof Constants.getEventVideoRequestFailureActionType, null>;

    export function getEventVideoRequest(P: GetEventVideoModal): getEventVideoRequestAction {
        return ActionCreator(Constants.getEventVideoRequestActionType, P);
    }
    export function getEventVideoRequestSuccess(P: EventVideoModal[]): getEventVideoRequestSuccessAction {
        return ActionCreator(Constants.getEventVideoRequestSuccessActionType, P);
    }
    export function getEventVideoRequestFailure(): getEventVideoRequestFailureAction {
        return ActionCreator(Constants.getEventVideoRequestFailureActionType, null);
    }

    export type getEventReminderRequestAction = Action<typeof Constants.getEventReminderRequestActionType, string>
    export type getEventReminderRequestSuccessAction = Action<typeof Constants.getEventReminderRequestSuccessActionType, GetEventReminderModal[]>;
    export type getEventReminderRequestFailureAction = Action<typeof Constants.getEventReminderRequestFailureActionType, null>;

    export function getEventReminderRequest(P: string): getEventReminderRequestAction {
        return ActionCreator(Constants.getEventReminderRequestActionType, P);
    }
    export function getEventReminderRequestSuccess(P: GetEventReminderModal[]): getEventReminderRequestSuccessAction {
        return ActionCreator(Constants.getEventReminderRequestSuccessActionType, P);
    }
    export function getEventReminderRequestFailure(): getEventReminderRequestFailureAction {
        return ActionCreator(Constants.getEventReminderRequestFailureActionType, null);
    }

    export type updateEventReminderRequestAction = Action<typeof Constants.updateEventReminderRequestActionType, UpdateEventReminderModal>
    export type updateEventReminderRequestSuccessAction = Action<typeof Constants.updateEventReminderRequestSuccessActionType, null>;
    export type updateEventReminderRequestFailureAction = Action<typeof Constants.updateEventReminderRequestFailureActionType, null>;

    export function updateEventReminderRequest(P: UpdateEventReminderModal): updateEventReminderRequestAction {
        return ActionCreator(Constants.updateEventReminderRequestActionType, P);
    }
    export function updateEventReminderRequestSuccess(): updateEventReminderRequestSuccessAction {
        return ActionCreator(Constants.updateEventReminderRequestSuccessActionType, null);
    }
    export function updateEventReminderRequestFailure(): updateEventReminderRequestFailureAction {
        return ActionCreator(Constants.updateEventReminderRequestFailureActionType, null);
    }

    export type resetEventStateRequestAction = Action<typeof Constants.resetEventStateRequestActionType, null>

    export function resetEventStateRequest(): resetEventStateRequestAction {
        return ActionCreator(Constants.resetEventStateRequestActionType, null);
    }

}

export type EventActions = Omit<typeof EventActions, 'Type'>;
export const useEventActions = (dispatch: Dispatch) => {
    const { ...actions } = EventActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as EventActions;
};