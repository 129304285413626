import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import VideoMonitor from '../components/VideoMonitor';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useVideosActions } from 'src/store/videos/actions';
import { getVideoStatus, getVideoSource } from 'src/components/utilities/utils';
import { useParams } from 'react-router-dom';
import './styles.scss';
interface ScreenHeaderProps {
  endBroadcastFlag: boolean;
  endingTime: number;
  cancelStreamingClick: boolean;
}

const Screen3: React.FC<ScreenHeaderProps> = ({
  endBroadcastFlag,
  endingTime,
  cancelStreamingClick
}) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSecondaryOpen, setIsSecondaryOpen] = useState<boolean>(false);
  const [playerKey, setPlayerKey] = useState(0);
  const [showMessage, setShowMessage] = useState(true);
  const { id } = useParams<string>();

  const { singleVideo, mediaChannelStatus, liveEventUrl, videoStatus } = useSelector(
    (state: RootState) => {
      return {
        singleVideo: state.videos.singleVideo,
        isVideoEdited: state.videos.isVideoEdited,
        mediaChannelStatus: state.videos.mediaChannelStatus,
        liveEventUrl: state.videos.liveEventUrl,
        videoStatus: state.videos.videoStatus
      };
    }
  );

  const handleRefreshMonitor = () => {
    setPlayerKey((prevKey) => prevKey + 1);
  };
  return (
    <div>
      {mediaChannelStatus && mediaChannelStatus === 'STARTING' && (
        <div className="ibox-content ">
          <div id="rightMenuContentMainDiv" className="">
            <div
              id="subData2"
              className="flex justify-center items-center flex-col text-sm font-normal">
              <div>
                <h3 className="text-black text-base font-semibold text-center">
                  Preparing your Live Stream Channel
                </h3>
                <div className="flex justify-center items-center flex-col italic">
                  <div>This can take around 30-40 seconds. Please do not</div>
                  <div className="flex justify-center items-center flex-col">
                    refresh your page.
                  </div>
                </div>

                <section className="talign-center">
                  <div className="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}

      {mediaChannelStatus === 'RUNNING' && (
        <div>
          <h1 className="mt-5 font-bold text-lg">Primary Live Media</h1>
          <div
            id="subData3"
            className={`${getVideoStatus(videoStatus) !== 'Live' ? 'flex' : ''} mt-5`}>
            <div style={{ width: '100%' }}>
              <div id="rightMenuContentMainDiv" className="">
                <div id="dvStep1" className="ibox-content ">
                  {getVideoStatus(videoStatus) === 'Live' && (
                    <div className="row">
                      <h1 className="text-3xl font-bold text-black mt-1 mb-3">
                        You are <span className="text-red-600">Live</span> On-Air
                      </h1>
                      <h3 className="text-base font-semibold text-black mt-1 mb-3">
                        Monitor Your Stream
                      </h3>
                      <div className="text-sm font-normal text-black mb-3">
                        Your stream is Live. Your viewers are watching online.
                        <br />
                        <b>If you are a speaker</b> for this live stream and on the same device,{' '}
                        <b>we recommend you keep the monitor closed.</b>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="flex justify-between mb-2"
                        style={{
                          width: `${getVideoStatus(videoStatus) !== 'Live' ? '90%' : '60%'}`
                        }}>
                        <span
                          className="text-sm font-normal text-black"
                          id="streamOpenCloseMenu"
                          onClick={() => setIsOpen(!isOpen)}>
                          {!isOpen ? (
                            <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                              Open Monitor
                              <i className="fa fa-chevron-down px-1"></i>
                            </button>
                          ) : (
                            <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                              Close Monitor
                              <i className="fa fa-chevron-up px-1"></i>
                            </button>
                          )}
                        </span>
                        {showMessage && (
                          <h3 className="text-red-600 font-bold">
                            Media may be cached. Refresh the player once you start pushing RTMP from
                            your encoding software.
                          </h3>
                        )}
                        <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                          <span onClick={() => handleRefreshMonitor()}>
                            <i className="fa fa-refresh pull-left cursor-pointer"></i>
                          </span>
                        </button>
                      </div>

                      {isOpen && liveEventUrl && (
                        <div
                          style={{
                            width: `${getVideoStatus(videoStatus) !== 'Live' ? '90%' : '60%'}`
                          }}>
                          <VideoMonitor
                            src={liveEventUrl.liveMediaUrl}
                            playerKey={playerKey}
                            setShowMessage={setShowMessage}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getVideoStatus(videoStatus) !== 'Live' && (
              <div className="mr-2">
                {getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' && (
                  <>
                    <h2 className="font-bold">Check your Software Settings:</h2>
                    <div className="mt-2">
                      Copy the <b>Server URL</b> and <b>Stream Key</b> below into your broadcast
                      application.
                      <br />
                      When you are ready, select <b>Go Live</b> first from your broadcast software (
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406439082127-Live-Stream-to-webcastcloud-Using-Zoom"
                        rel="noreferrer"
                        target="_blank">
                        Zoom
                      </a>
                      ,
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406438785167-Create-a-Live-Stream-with-any-RTMP-source-"
                        rel="noreferrer"
                        target="_blank">
                        OBS Studio
                      </a>
                      ,
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406452711567-Live-Stream-to-webcastcloud-Using-StreamYard"
                        rel="noreferrer"
                        target="_blank">
                        StreamYard
                      </a>{' '}
                      etc).
                      <br />
                      Once your applications starts pushing your live stream to webcastcloud, you
                      can then select <b>Go Live</b> from here. Your viewers will then be watching
                      you live stream online.
                    </div>
                  </>
                )}

                <br />
                <h5 className="text-base mb-2 text-black-600">
                  Media Live settings - information only. When using Webcam in Live Studio, no
                  action is required
                </h5>
                <h3 className="text-base font-semibold text-black mb-2">Primary Live Media</h3>
                <div className="row form-group">
                  <div className="col-12">
                    <span className="text-xs font-normal">Server URL</span>
                    <div className="primary-media-wrapper">
                      <input
                        type="text"
                        className="primary-media-input"
                        id="txtPrimaryStreamURL"
                        value={liveEventUrl && liveEventUrl.rtmpIngestURL}
                        readOnly
                      />
                      <CopyToClipboard text={liveEventUrl && liveEventUrl.rtmpIngestURL}>
                        <button className="primary-media-btn">
                          <i className="fa fa-copy"></i>
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="col-12">
                    <span className="text-xs font-normal">Stream Key</span>
                    <div className="primary-media-wrapper">
                      <input
                        type="text"
                        className="primary-media-input"
                        id="txtPrimaryName"
                        value={liveEventUrl && liveEventUrl.streamKey}
                        readOnly
                      />
                      <CopyToClipboard text={liveEventUrl && liveEventUrl.streamKey}>
                        <button className="primary-media-btn">
                          <i className="fa fa-copy"></i>
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {liveEventUrl && liveEventUrl.secondaryLiveMediaUrl && (
            <>
              <h1 className="mt-5 font-bold text-lg">Secondary Live Media</h1>
              <div
                id="subData4"
                className={`${getVideoStatus(videoStatus) !== 'Live' ? 'flex' : ''} mt-5`}>
                <div style={{ width: '100%' }}>
                  <div id="rightMenuContentMainDiv" className="">
                    <div id="dvStep2" className="ibox-content ">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="flex justify-between mb-2"
                            style={{
                              width: `${getVideoStatus(videoStatus) !== 'Live' ? '90%' : '60%'}`
                            }}>
                            <span
                              className="text-sm font-normal text-black"
                              id="streamOpenCloseMenu"
                              onClick={() => setIsSecondaryOpen(!isSecondaryOpen)}>
                              {!isSecondaryOpen ? (
                                <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                                  Open Monitor
                                  <i className="fa fa-chevron-down px-1"></i>
                                </button>
                              ) : (
                                <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                                  Close Monitor
                                  <i className="fa fa-chevron-up px-1"></i>
                                </button>
                              )}
                            </span>
                            {showMessage && (
                              <h3 className="text-red-600 font-bold">
                                Media may be cached. Refresh the player once you start pushing RTMP
                                from your encoding software.
                              </h3>
                            )}

                            <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-500 focus:outline-none focus:ring focus:ring-indigo-300 focus:ring-opacity-80">
                              <span onClick={() => handleRefreshMonitor()}>
                                <i className="fa fa-refresh pull-left cursor-pointer"></i>
                              </span>
                            </button>
                          </div>

                          {isSecondaryOpen && liveEventUrl && (
                            <div
                              style={{
                                width: `${getVideoStatus(videoStatus) !== 'Live' ? '90%' : '60%'}`
                              }}>
                              <VideoMonitor
                                src={liveEventUrl.secondaryLiveMediaUrl}
                                playerKey={playerKey}
                                setShowMessage={setShowMessage}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {getVideoStatus(videoStatus) !== 'Live' && (
                  <div className="mr-2">
                    {getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' && (
                      <>
                        <h2 className="font-bold">Check your Software Settings:</h2>
                        <div className="mt-2">
                          Copy the <b>Server URL</b> and <b>Stream Key</b> below into your broadcast
                          application.
                          <br />
                          When you are ready, select <b>Go Live</b> first from your broadcast
                          software (
                          <a
                            href="https://support.webcastcloud.com/hc/en-us/articles/4406439082127-Live-Stream-to-webcastcloud-Using-Zoom"
                            rel="noreferrer"
                            target="_blank">
                            Zoom
                          </a>
                          ,
                          <a
                            href="https://support.webcastcloud.com/hc/en-us/articles/4406438785167-Create-a-Live-Stream-with-any-RTMP-source-"
                            rel="noreferrer"
                            target="_blank">
                            OBS Studio
                          </a>
                          ,
                          <a
                            href="https://support.webcastcloud.com/hc/en-us/articles/4406452711567-Live-Stream-to-webcastcloud-Using-StreamYard"
                            rel="noreferrer"
                            target="_blank">
                            StreamYard
                          </a>{' '}
                          etc).
                          <br />
                          Once your applications starts pushing your live stream to webcastcloud,
                          you can then select <b>Go Live</b> from here. Your viewers will then be
                          watching you live stream online.
                        </div>
                      </>
                    )}

                    <br />
                    <h5 className="text-base mb-2 text-black-600">
                      Media Live settings - information only. When using Webcam in Live Studio, no
                      action is required
                    </h5>
                    <h3 className="text-base font-semibold text-black mb-2">
                      Secondary Live Media
                    </h3>
                    <div className="row form-group">
                      <div className="col-12">
                        <span className="text-xs font-normal">Server URL</span>
                        <div className="primary-media-wrapper">
                          <input
                            type="text"
                            className="primary-media-input"
                            id="txtPrimaryStreamURL"
                            value={liveEventUrl && liveEventUrl.secondaryRTMPIngestURL}
                            readOnly
                          />
                          <CopyToClipboard
                            text={liveEventUrl && liveEventUrl.secondaryRTMPIngestURL}>
                            <button className="primary-media-btn">
                              <i className="fa fa-copy"></i>
                              <span>Copy</span>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="col-12">
                        <span className="text-xs font-normal">Stream Key</span>
                        <div className="primary-media-wrapper">
                          <input
                            type="text"
                            className="primary-media-input"
                            id="txtPrimaryName"
                            value={liveEventUrl && liveEventUrl.secondaryStreamKey}
                            readOnly
                          />
                          <CopyToClipboard text={liveEventUrl && liveEventUrl.secondaryStreamKey}>
                            <button className="primary-media-btn">
                              <i className="fa fa-copy"></i>
                              <span>Copy</span>
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default Screen3;
