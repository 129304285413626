import { useParams } from 'react-router-dom';
import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import Loader from 'src/components/loader';
import { useChannelActions } from 'src/store/channel/actions';
import { useVideosActions } from 'src/store/videos/actions';
import { SearchVideoModel } from 'src/models/videosModel';
import { useDispatch, useSelector } from 'react-redux';
import { useCategoryActions } from 'src/store/category/actions';
import { RootState } from 'src/store/reducers/state';
import { CollectionVideoModel } from 'src/models/categoryModel';
import { Link } from 'react-router-dom';
import {
  customFilterOption,
  defaultDateFormatter,
  errorMsg,
  getVideoStatus,
  successMsg,
  uploadImageToS3
} from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface Option {
  label: string;
  value: string;
}

const CollectionVideos: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control
  } = useForm();
  const { id } = useParams<string>();
  const BackgroundInputFile = useRef<HTMLInputElement>(null);
  const [finalChannels, setFinalChannels] = useState<any>();
  const [finalVideos, setFinalVideos] = useState<any>();
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [isClicked, setIsClicked] = useState(false);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const dispatch = useDispatch();
  const categoryActions = useCategoryActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const {
    channelList,
    collectionVideos,
    isCollectionVideosLoading,
    isCollectionVideosSaved,
    videosList,
    isSearchVideoLoading,
    isCollectionUpdated,
    isCollectionVideosUpdated,
    error
  } = useSelector((state: RootState) => {
    return {
      channelList: state.channel.channelList,
      collectionVideos: state.category.collectionVideos,
      isCollectionVideosLoading: state.category.isCollectionVideosLoading,
      isCollectionVideosSaved: state.category.isCollectionVideosSaved,
      videosList: state.videos.videos,
      isSearchVideoLoading: state.videos.isSearchVideoLoading,
      isCollectionUpdated: state.category.isCollectionUpdated,
      isCollectionVideosUpdated: state.category.isCollectionVideosUpdated,
      error: state.category.error
    };
  });

  useEffect(() => {
    channelActions.getChannelListRequest();
    if (id) {
      categoryActions.getCollectionVideosRequest(id);
    }
  }, []);

  useEffect(() => {
    if (collectionVideos && collectionVideos.thumbnailUrl) {
      setBackgroundImageUrl(collectionVideos.thumbnailUrl);
    }
  }, [collectionVideos]);

  useEffect(() => {
    if (isCollectionVideosSaved || isUploadToS3Success) {
      if (id) categoryActions.getCollectionVideosRequest(id);
    }
  }, [isCollectionVideosSaved, isUploadToS3Success]);

  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      const finalChannels = [{ value: '', label: 'Select...' }, ...options];
      setFinalChannels(finalChannels);
    }
  }, [channelList]);

  useEffect(() => {
    if (videosList && videosList.length) {
      const options = videosList.map((item: any) => ({
        value: item.id,
        label: item.title
      }));
      const finalVideos = [{ value: '', label: 'Select...' }, ...options];
      setFinalVideos(finalVideos);
    }
  }, [videosList]);

  const handleDoubleClick = (videoId: string) => {
    window.open(`/videos/${videoId}`, '_blank');
  };

  const handleRemoveVideoClick = (videoId: string) => {
    if (id) {
      const data: CollectionVideoModel = {
        presentationId: videoId,
        collectionId: id
      };
      categoryActions.removeCollectionVideosRequest(data);
    }
  };

  const handleAddCollectionVideos = () => {
    if (selectedVideoId && id) {
      const collectionVideoData: CollectionVideoModel = {
        collectionId: id,
        presentationId: selectedVideoId
      };
      categoryActions.addCollectionVideosRequest(collectionVideoData);
      categoryActions.resetCategoryStateRequest();
    }
  };

  const channelOptions: Option[] = channelList.map((channel) => ({
    label: channel.name,
    value: channel.id
  }));
  const videosOptions: Option[] = videosList.map((video: any) => ({
    label: video.title,
    value: video.id
  }));

  const handleChannelChange = (selectedOption: SingleValue<Option>) => {
    if (selectedOption) {
      const selectedChannelId = selectedOption.value;
      fetchVideoByChannelId(selectedChannelId);
    }
  };
  const handleVideoSelection = (selectedOption: SingleValue<Option>) => {
    if (selectedOption) {
      const selectedvideoIdOption = selectedOption.value;
      setSelectedVideoId(selectedvideoIdOption);
    }
  };

  const fetchVideoByChannelId = (selectedChannelId: string) => {
    const videoChannel: SearchVideoModel = {
      channelId: selectedChannelId
    };
    videoActions.searchVideoRequest(videoChannel);
  };

  const handleCancelClick = () => {
    reset(collectionVideos);
  };
  const handleClick = () => {
    setIsClicked((prevState) => !prevState);
  };

  useEffect(() => {
    reset(collectionVideos);
  }, [collectionVideos, reset]);

  const onSubmit = (data: any) => {
    if (id) {
      const payloadData = {
        collectionId: id,
        updateData: [
          { op: 'replace', path: 'collection', value: data.collection },
          { op: 'replace', path: 'description', value: data.description },
          { op: 'replace', path: 'accessibility', value: data.accessibility },
          { op: 'replace', path: 'visibility', value: data.visibility }
        ]
      };
      categoryActions.updateCollectionRequest(payloadData);
    }
  };

  useEffect(() => {
    if (isCollectionVideosSaved) {
      successMsg('Collection Content saved successfully.');
    } else if (isCollectionUpdated) {
      successMsg('Collections updated successfully.');
    } else if (error) {
      errorMsg('Error.');
    }
  }, [isCollectionVideosSaved, isCollectionUpdated]);

  const handleBackgroundImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      uploadImageToS3(
        selectedFile[0],
        `v2/categories/collections/upload/thumbnail/${id}?`,
        setBackgroundImageUrl,
        'setting',
        setIsUploadToS3Loading,
        setIsUploadToS3Success
      );
    }
  };

  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  const handleRemoveBackgroundImage = () => {
    const cId = collectionVideos && collectionVideos.collectionId;
    const payloadData = {
      collectionId: cId,
      updateData: [{ op: 'replace', path: 'thumbnailUrl', value: '' }]
    };
    categoryActions.updateCollectionRequest(payloadData);
    categoryActions.getCollectionRequest(cId);
  };

  const handleSelectBackgroundImageClick = () => {
    if (BackgroundInputFile.current) {
      BackgroundInputFile.current.click();
    }
  };

  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      if (id) {
        successMsg('File uploaded successfully');
        categoryActions.getCollectionRequest(id);
        setIsUploadToS3Success('');
      }
    }
  }, [isUploadToS3Success]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(collectionVideos.videos);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const reorderedVideoIds = items.map((video: any) => video.id);
    sendReorderedVideosToApi(reorderedVideoIds);
  };

  const sendReorderedVideosToApi = (videoIds: string[]) => {
    if (id) {
      const payloadData = {
        collectionId: id,
        videoIds: videoIds
      };
      categoryActions.updateCollectionVideoOrderRequest(payloadData);
    }
  };

  useEffect(() => {
    if (id) {
      categoryActions.getCollectionVideosRequest(id);
    }
  }, [isCollectionVideosUpdated]);
  return (
    <>
      <ToastMessage />
      <div className="collection-main overflow-auto">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col md:px-4 py-3  rounded ">
            <div className="flex flex-col md:flex-row md:items-center justify-between w-full border-b border-gray-300 pb-3 gap-3 lg:gap-0">
              <div className="flex items-center gap-3">
                <div className="h-7 w-7 flex items-center justify-center bg-gray-200 hover:bg-gray-300 rounded-full">
                  <Link to={`/collections`}>
                    <i className="fas fa-arrow-left mt-2 mb-2"></i>
                  </Link>
                </div>
                <h3 className="text-left text-lg font-semibold text-gray-600">
                  {collectionVideos.collection}
                </h3>
              </div>

              <div className="flex items-center gap-3">
                <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                  Save
                </button>
                <button
                  onClick={handleCancelClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </div>
            <div className="flex items-start lg:flex-row flex-col gap-4">
              {!isCollectionVideosLoading || !isUploadToS3Loading ? (
                <>
                  <div className="flex mt-4 gap-3 flex-col lg:w-2/4 w-full">
                    <div className={`flex flex-col gap-1 form-group w-full`}>
                      <label className="block text-sm font-medium">Name</label>
                      <input
                        type="text"
                        className="input-style"
                        {...register('collection', {
                          required: true
                        })}
                      />
                    </div>
                    <div className={`flex flex-col gap-1 form-group w-full`}>
                      <label className="block text-sm font-medium">Description</label>
                      <textarea
                        className="input-style"
                        {...register('description', {
                          required: false
                        })}
                        rows={7}></textarea>
                    </div>
                  </div>
                  <div className="flex gap-3 mt-4 flex-col lg:w-2/4 w-full">
                    <div className="flex gap-7 w-1/2">
                      <div className={`flex flex-col gap-1 form-group flex-1`}>
                        <label className="block text-sm font-medium">Visibility</label>
                        <select
                          className="border input-style"
                          id="visibility"
                          {...register('visibility')}>
                          <option value="">Select</option>
                          <option value="0">Online</option>
                          <option value="1">Offline</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group file-upload flex-1 w-full flex flex-col gap-1">
                      <label>Thumbnail Image</label>

                      <div className="relative channel-setting-image flex justify-center w-full rounded-md overflow-hidden max-w-xl group cursor-pointer">
                        {backgroundImageUrl ? (
                          <img
                            className="w-full object-contain video-image border border-gray-300 rounded-md overflow-hidden"
                            src={backgroundImageUrl}
                          />
                        ) : (
                          <div className="flex flex-wrap justify-center items-center border border-gray-300 w-full btns-main rounded-md overflow-hidden">
                            <button
                              type="button"
                              onClick={handleSelectBackgroundImageClick}
                              className="mx-0 px-6 btn-primary w-28 mt-0">
                              Upload
                            </button>
                          </div>
                        )}
                        <div
                          className={`hidden items-center justify-center gap-3 absolute top-0 bg-black/20 w-full h-full transition-all duration-300 ${
                            backgroundImageUrl != '' ? 'video-upload-btn' : 'hidden'
                          }`}>
                          <input
                            ref={BackgroundInputFile}
                            type="file"
                            onChange={(e) => handleBackgroundImageUpload(e.target.files)}
                            className="hidden"
                            onClick={onInputClick}
                          />
                          <button
                            onClick={() => {
                              setBackgroundImageUrl(''), handleRemoveBackgroundImage();
                            }}
                            className={`btn-primary cursor-pointer mx-0 mt-0 w-auto`}>
                            Remove
                          </button>
                          <button
                            type="button"
                            onClick={handleSelectBackgroundImageClick}
                            className="mx-0 px-6 btn-primary w-28 mt-0">
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
            <div className="flex items-center flex-wrap justify-between gap-3 mt-4">
              <h3 className="text-left text-lg font-semibold text-gray-600">Collection List</h3>
              <div className="flex items-center gap-3 justify-end">
                <button
                  type="button"
                  className="btn-primary m-0 w-auto"
                  data-bs-toggle="modal"
                  data-bs-target="#AddNewCollection"
                  onClick={handleClick}>
                  Add New Content
                </button>
              </div>
            </div>
            <div
              className={`form-group flex lg:flex-row flex-col lg:items-center gap-3 flex-wrap mt-4 ${
                isClicked ? 'd-flex' : 'hidden'
              }`}>
              <div className="flex flex-col items-start flex-1 w-full">
                <label className="block text-sm font-medium min-w-65">Channels</label>
                {channelList.length > 0 && (
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        filterOption={customFilterOption}
                        options={channelOptions}
                        value={channelOptions.find((option) => option.value === value)}
                        id="channelId"
                        className="w-full"
                        onChange={(selectedOption: SingleValue<Option>) => {
                          onChange(selectedOption ? selectedOption.value : null);
                          handleChannelChange(selectedOption);
                        }}
                        isLoading={channelList.length === 0}
                      />
                    )}
                    name="channelId"
                    control={control}
                  />
                )}
              </div>
              <div className="flex flex-col items-start flex-1 w-full">
                {isSearchVideoLoading ? (
                  <Loader />
                ) : (
                  <>
                    {videosList.length > 0 && (
                      <>
                        <label className="block text-sm font-medium  min-w-65">Content</label>
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error }
                          }) => (
                            <Select
                              filterOption={customFilterOption}
                              options={videosOptions}
                              value={videosOptions.find((option) => option.value === value)}
                              id="videoId"
                              className="w-full"
                              onChange={(selectedOption: SingleValue<Option>) => {
                                onChange(selectedOption ? selectedOption.value : null);
                                handleVideoSelection(selectedOption);
                              }}
                              isLoading={videosList.length === 0}
                            />
                          )}
                          name="videoId"
                          control={control}
                        />
                      </>
                    )}
                  </>
                )}
              </div>

              <button
                type="button"
                className="btn-primary text-2xl leading-none p-2 h-10 w-10 mt-auto mx-0 lg:mx-auto"
                data-bs-toggle="modal"
                data-bs-target="#AddNewCollection"
                onClick={() => handleAddCollectionVideos()}>
                +
              </button>
            </div>

            <div className="tab-content-box shadow mt-4 p-4">
              {isCollectionVideosLoading ? (
                <Loader />
              ) : (
                <>
                  <div id="tabs-list" className="w-full fade show active overflow-x-auto">
                    <div className="flex flex-col gap-3 tab-box">
                      <div className="bg-gray-100 flex">
                        <div className="p-3 text-left font-semibold text-gray-600 custom-blank-wrap">
                          THUMBNAIL
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-name">
                          NAME
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-col">
                          ACCESS
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-col">
                          STATUS
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-col">
                          PRICE
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-col">
                          DATE
                        </div>
                        <div className="p-3 text-left font-semibold text-gray-600 custom-col">
                          ACTIONS
                        </div>
                      </div>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="videos">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              className="flex flex-col gap-3 table-body">
                              {collectionVideos.videos && collectionVideos.videos.length === 0 ? (
                                <div className="empty-list-msg">
                                  {' '}
                                  No Videos added to the playlist.
                                </div>
                              ) : (
                                <>
                                  {collectionVideos.videos?.map((video: any, index: number) => (
                                    <Draggable key={video.id} draggableId={video.id} index={index}>
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="flex items-center shadow rounded-md cursor-pointer"
                                          onDoubleClick={() => handleDoubleClick(video.id)}>
                                          <div className="p-3 custom-blank-wrap">
                                            <div className="flex items-center">
                                              {video.thumbnailUrl ? (
                                                <img
                                                  src={video.thumbnailUrl}
                                                  className="w-44 h-24 object-cover mr-3 rounded"
                                                />
                                              ) : (
                                                <img
                                                  src={`../../assets/img/dummy-image.svg`}
                                                  className="w-44 h-24 object-contain mr-3 p-3 rounded bg-gray-200 border-0"
                                                />
                                              )}
                                            </div>
                                          </div>

                                          <div className="p-3 custom-name">
                                            <span className="font-medium text-gray-900">
                                              {video.title}
                                            </span>
                                          </div>
                                          <div className="p-3 custom-col">
                                            <span className="font-medium text-gray-900">
                                              {video.contentAccessTypeName}
                                            </span>
                                          </div>
                                          <div className="p-3 custom-col">
                                            <span
                                              className={`font-semibold text-xs p-2.5 rounded ${
                                                getVideoStatus(video.status) === 'Stopped'
                                                  ? 'bg-yellow-100 text-yellow-400'
                                                  : getVideoStatus(video.status) === 'Standby'
                                                  ? 'standbyColor'
                                                  : getVideoStatus(video.status) === 'OnDemand'
                                                  ? 'bg-green-100 text-green-500'
                                                  : getVideoStatus(video.status) === 'Offline' ||
                                                    getVideoStatus(video.status) === 'GreenRoom'
                                                  ? 'bg-gray-100 text-gray-800'
                                                  : getVideoStatus(video.status) === 'Live'
                                                  ? 'bg-red-100 text-red-500'
                                                  : ''
                                              }`}>
                                              {getVideoStatus(video.status)}
                                            </span>
                                          </div>
                                          <div className="p-3 custom-col">
                                            <span className="font-medium text-gray-900">
                                              ${video.cost}
                                            </span>
                                          </div>
                                          <div className="p-3 custom-col">
                                            <span className="font-medium text-gray-900">
                                              {video.displayDate
                                                ? defaultDateFormatter(video.displayDate)
                                                : ''}
                                            </span>
                                          </div>
                                          <div className="p-3 custom-col">
                                            <button
                                              type="button"
                                              className="btn btn-danger w-auto inline-flex mt-0"
                                              onClick={() => handleRemoveVideoClick(video.id)}>
                                              Remove
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default CollectionVideos;
