import React, { useRef, useEffect } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
const DeleteResourceModal = ({ resourceID }: any) => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const handleDeleteResourceClick = () => {
    videoActions.deleteResourcesRequest(resourceID);
  };
  const { isResourceDeleted } = useSelector((state: RootState) => {
    return {
      isResourceDeleted: state.videos.isResourceDeleted
    };
  });
  useEffect(() => {
    if (isResourceDeleted) {
      if (ref.current) {
        ref.current.click();
      }
    }
  }, [isResourceDeleted]);
  return (
    <div>
      <div
        className="modal fade"
        id="DeleteResourceModal"
        aria-labelledby="DeleteResourceModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="DeleteResourceModalLabel">
                Delete Attachment
              </h5>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <div className="p-4 text-center">
                <p>Are you sure you want to delete this attachment?</p>
              </div>
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0 mr-3"
                data-bs-dismiss="modal">
                Close
              </button>
              <button
                onClick={handleDeleteResourceClick}
                type="button"
                className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteResourceModal;
