import React, { useEffect } from 'react';
import { compareDate, getCookieData } from '../utilities/utils';
import { useNavigate } from 'react-router-dom';
import { deleteCookies } from '../utilities/utils';
const AuthComponent = (components: any) => {
  const navigate = useNavigate();
  const loginDuration = new Date(getCookieData('RefreshCookie'));
  const refreshDuration = new Date(getCookieData('RefreshTokenExpiryCookie'));
  const prevLocation = location.pathname;
  useEffect(() => {
    if (!compareDate(new Date(), loginDuration) && !compareDate(new Date(), refreshDuration)) {
      navigate(`/login?returnUrl=${prevLocation}`);
      localStorage.clear();
      deleteCookies();
    }
  }, [refreshDuration, loginDuration]);
  return components;
};

export default AuthComponent;
