import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import MainLayoutRoutes from './components/common/layout/MainLayoutRoutes';
import './asset/style.scss';
import './App.css';
import Login from './components/login';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from './components/utilities/utils';
import { RootState } from './store/reducers/state';
import ForgetPassword from './components/login/ForgetPassword';
import ConfirmForgetPassword from './components/login/ConfirmForgetPassword';
import ResetPassword from './components/login/ResetPassword';
import ConfirmResetPassword from './components/login/ConfirmResetPassword';
import MultiFactorAuthenticationSetup from './components/login/MultiFactorAuthenticationSetup';
import VerifyMfa from './components/login/VerifyMFA';

const App = () => {
  const navigate = useNavigate();
  const { isCallbackLink } = useSelector((state: RootState) => {
    return {
      isCallbackLink: state.login.isCallbackLink
    };
  });
  useEffect(() => {
    if (isCallbackLink) {
      removeItem('token');
      removeItem('role');
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [isCallbackLink]);

  useEffect(() => {
    const idleDurationSecs = 3600; // X number of seconds
    const redirectUrl = '/login'; // Redirect idle users to this URL
    let idleTimeout: any; // variable to hold the timeout
    let isApiCallInProgress = false; // variable to track ongoing API calls

    // Function to reset the idle timeout
    const resetIdleTimeout = function () {
      // Clears the existing timeout
      if (idleTimeout) clearTimeout(idleTimeout);

      // Set a new idle timeout to load the redirectUrl after idleDurationSecs
      idleTimeout = setTimeout(() => {
        // Only redirect if not on the live meeting page
        if (!window.location.pathname.includes('live-event')) {
          location.href = redirectUrl;
        }
      }, idleDurationSecs * 1000);
    };

    // Function to reset idle timeout when a network request starts or completes
    const handleNetworkRequest = function () {
      isApiCallInProgress = true;
      resetIdleTimeout(); // Reset the idle timeout when a network request starts
    };

    // Listen for fetch API calls
    const originalFetch = window.fetch;
    window.fetch = function (...args: Parameters<typeof originalFetch>) {
      handleNetworkRequest(); // Intercept fetch calls and handle them
      return originalFetch(...args);
    };

    // Init on page load
    resetIdleTimeout();

    // Reset the idle timeout on any of the events listed below
    ['click', 'touchstart', 'mousemove'].forEach((evt) =>
      document.addEventListener(evt, resetIdleTimeout, false)
    );

    // Cleanup function to remove event listeners and restore original fetch function
    return () => {
      ['click', 'touchstart', 'mousemove'].forEach((evt) =>
        document.removeEventListener(evt, resetIdleTimeout, false)
      );
      window.fetch = originalFetch;
    };
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/login?sa=:id" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/account/ConfirmForgetPassword" element={<ConfirmForgetPassword />} />
        <Route path="/account/ConfirmResetPassword" element={<ConfirmResetPassword />} />
        <Route path="/account/ResetPassword" element={<ResetPassword />} />
        <Route path="/account/MultiFactorAuthentication" element={<MultiFactorAuthenticationSetup />} />
        <Route path="/account/CodeVerification" element={<VerifyMfa />} />
        <Route path="*" element={<MainLayoutRoutes />} />
      </Routes>
    </div>
  );
};

export default App;
