import { combineReducers } from "redux"
import usersReducer from "../users/usersReducer"
import videosReducer from "../videos/videosReducer"
import dashboardReducer from "../dashboard/dashboardReducer"
import loginReducer from "../login/loginReducer"
import ChannelReducer from "../channel/channelReducer"
import courseReducer from "../course/courseReducer"
import CategoryReducer from "../category/categoryReducer"
import EventReducer from "../event/eventReducer"
import EzidebitReducer from "../ezidebit/ezidebitReducer"
import videoLibraryReducer from "../videoLibrary/videoLibraryReducer"
import monetizationReducer from "../monetization/monetizationReducer"
import analyticsReducer from "../analytics/analyticsReducer"
import settingReducer from "../settings/settingReducer"
import appearanceReducer from "../appearance/appearanceReducer"
const RootReducer = combineReducers({
  users: usersReducer,
  videos: videosReducer,
  dashboard: dashboardReducer,
  login: loginReducer,
  channel: ChannelReducer,
  course: courseReducer,
  category: CategoryReducer,
  event: EventReducer,
  ezidebit: EzidebitReducer,
  videoLibrary: videoLibraryReducer,
  monetization: monetizationReducer,
  analytics: analyticsReducer,
  setting: settingReducer,
  appearance: appearanceReducer
})

export default RootReducer