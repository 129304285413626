import React, { useEffect, useRef, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ViewRecordingModal from '../viewRecordingModal';
import { useDispatch } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
const AddRecording = () => {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const userData = localStorage.getItem('userData');
  const finalUserData = userData && JSON.parse(userData);
  const [isRecording, setIsRecording] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [flag, setFlag] = useState(false);

  const sendMessageToIframe = () => {
    let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    const win: any = window;
    if (finalData) {
      win.ChatUser = {
        id: finalData.UserId,
        userId: finalData.UserId,
        name: `${finalData.firstName} ${finalData.lastName} `,
        profileUrl: '',
        chatId: id,
        isModerator: false,
        displayUserConnection: false,
        domainId: `${finalData.domainId}`,
        chatEnabled: true,
        pollEnabled: false,

        token: localStorage.getItem('token')
      };
    }
    if (liveFrameObj) {
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(
        JSON.stringify({ action: 'recording', ChatUser: win.ChatUser }),
        '*'
      );
    }
  };

  const formatTime = (timeInSeconds: any) => {
    const hours = String(Math.floor(timeInSeconds / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeInSeconds % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    let timer: any;
    if (isRecording) {
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isRecording]);

  useEffect(() => {
    const timer = setTimeout(() => {
      sendMessageToIframe();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleStartRecording = () => {
    let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
    if (liveFrameObj) {
      setFlag(true);
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(JSON.stringify({ action: 'startRecordingSignal' }), '*');
      sessionStorage.setItem('isRecStopped', 'false');
      setIsRecording(!isRecording);
    }
  };
  const handleStopRecording = () => {
    let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
    if (liveFrameObj) {
      setFlag(false);
      liveFrameObj = liveFrameObj.contentWindow;
      liveFrameObj.postMessage(JSON.stringify({ action: 'stopRecordingSignal' }), '*');
      sessionStorage.setItem('isRecStopped', 'true');
    }
  };

  const handleViewRecording = () => {
    id && videoActions.getArchiveUrlRequest(id);
  };
  return (
    <>
      {sessionStorage.getItem('isRecStopped') === 'false' ||
      sessionStorage.getItem('isRecStopped') === null ? (
        <div className="box-wrap flex flex-col">
          <div className="box-header">
            <div className=" header ibox-content bg-white border">
              <div className="lg:flex justify-end px-5 items-center py-4">
                <div className="">
                  {!flag && (
                    <button
                      onClick={() => handleStartRecording()}
                      type="button"
                      className=" dropdown-toggle  m-0 option-menu-live-stream">
                      Start Recording
                    </button>
                  )}
                  {flag && (
                    <>
                      <span className="font-extrabold mr-4">{formatTime(elapsedTime)}</span>
                      <button
                        onClick={() => handleStopRecording()}
                        type="button"
                        className=" dropdown-toggle  m-0 start-live-stream">
                        Stop Recording
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex custom-height-wrapper">
            <div className="flex flex-col gap-2 live-event-main-h  flex-1">
              <div className="feature-div h-screen relative greenRoomContent">
                <iframe
                  id="greenroomFrame"
                  src={`${process.env.REACT_APP_LIVE_MEET}/#/WaitingRoom/${id}/${
                    finalUserData.firstName
                  } ${finalUserData.lastName}/${finalUserData.UserId}/host/${'broadcast'}`}
                  allow="camera;microphone;fullscreen; display-capture;"
                  className="h-full w-full d-block"></iframe>
                {/* {getVideoStatus(videoStatus) === 'GreenRoom' && (
            <div className="green-room-button"> Green Room</div>
          )} */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2 live-event-main flex-1">
          <div className="ibox-content ">
            <div id="rightMenuContentMainDiv" className="">
              <div id="dvStep1" className="ibox-content ">
                <div
                  id="subData1"
                  className="flex justify-center items-center flex-col text-sm font-normal">
                  <h4 className="text-lg font-bold">Recording Ended.</h4>
                  <p className="text-sm font-medium max-w-lg text-center">
                    Your recording has finished and will be available ondemand once it has finished
                    processing.
                  </p>
                  <div className="mt-5">
                    <button
                      onClick={() => sessionStorage.setItem('isRecStopped', 'false')}
                      type="button"
                      className=" dropdown-toggle  m-0 option-menu-live-stream">
                      Record Again
                    </button>
                    <Link
                      to={`/add-video/7`}
                      type="button"
                      className=" dropdown-toggle  m-0 option-menu-live-stream ml-2">
                      New Recording
                    </Link>
                    <button
                      onClick={() => handleViewRecording()}
                      type="button"
                      className=" dropdown-toggle  m-0 option-menu-live-stream ml-2"
                      data-bs-toggle="modal"
                      data-bs-target="#ViewRecordingModal">
                      View Recording
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewRecordingModal />
        </div>
      )}
    </>
  );
};

export default AddRecording;
