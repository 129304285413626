import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVideosActions } from 'src/store/videos/actions';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
interface IProps {
  openchat: boolean;
}
const ChatModal: React.FC<IProps> = ({ openchat }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const { id } = useParams<string>();
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    const win: any = window;
    if (finalData) {
      win.ChatUser = {
        id: finalData.UserId,
        userId: finalData.UserId,
        name: `${finalData.firstName} ${finalData.lastName} `,
        profileUrl: '',
        chatId: id,
        isModerator: false,
        displayUserConnection: false,
        domainId: `${finalData.domainId}`,
        chatEnabled: true,
        pollEnabled: false
      };
    }
    let chatPopOutObject: any = null;
    window.addEventListener('message', function (e) {
      let customChatStyleString = '';
      let chatObj: any = document.querySelector('.playerchatContent iframe');
      chatObj = chatObj.contentWindow;
      if (chatPopOutObject != null) {
        chatObj = chatPopOutObject;
      }
      const jsonParse = JSON.parse(e.data);
      if (jsonParse.action == 'getuserdata') {
        chatObj.postMessage(JSON.stringify({ action: 'userdata', ChatUser: win.ChatUser }), '*');
      } else if (jsonParse.action == 'getchatstyle') {
        chatObj.postMessage(
          JSON.stringify({ action: 'chatstyleresponse', ChatUser: win.ChatUser }),
          '*'
        );

        const dataString =
          '{"messageListPage":[{"key":"--msg-l-color","value":"","id":"chatMessageLeftColor"},{"key":"--chat-box-bg","value":"","id":"chatBodyColor"}],"customStyleString":""}';
        const data = JSON.parse(dataString);
        customChatStyleString = data.customStyleString;
        ((styleText) => {
          setTimeout(() => {
            chatObj.postMessage(JSON.stringify({ action: 'setcustomcss', body: styleText }), '*');
          }, 100);
        })(data.customStyleString);
        const styleArray = data.messageListPage;
        for (const i of styleArray) {
          ((key, color) => {
            setTimeout(() => {
              chatObj.postMessage(
                JSON.stringify({ action: 'setcolor', text: key, value: color }),
                '*'
              );
            }, 100);
          })(i.key, i.value);
        }
      } else if (jsonParse.action == 'pop-out-chat') {
        $(`[src="${process.env.REACT_APP_WCC_CHAT}?v=1.01"]`).attr('id', 'newChatframe');
        const urlStr: any = $('#newChatframe').attr('src');

        const url: any = new URL(urlStr);
        url.searchParams.delete('displaypopout');
        url.searchParams.append('displaypopout', false);
        chatPopOutObject = window.open(url.href, '_blank', 'width=350px,height=500px');
        let getSubActiveRightPanelId: any = null;
        const playerActiveRightPanel = $('.vjs-right-panel.active');
        if (
          playerActiveRightPanel != undefined &&
          playerActiveRightPanel != null &&
          playerActiveRightPanel['length']
        ) {
          getSubActiveRightPanelId =
            playerActiveRightPanel[0].getAttribute('data-right-panel-button');
          if (getSubActiveRightPanelId != undefined && getSubActiveRightPanelId != null) {
            $('#right-panel-button-' + getSubActiveRightPanelId).click();
          }
        }

        const funCheckWindow = () => {
          if (chatPopOutObject != null && !chatPopOutObject.closed) {
            this.setTimeout(funCheckWindow, 500);
          } else if (chatPopOutObject != null && chatPopOutObject.closed) {
            $(`#newChatframe`).attr('src', `${process.env.REACT_APP_WCC_CHAT}?v=1.01`);

            //Load chat again if not opened any other side panels.
            const playerActiveRightPanel2 = $('.vjs-right-panel.active');
            if (playerActiveRightPanel2['length'] == 0) {
              $('#right-panel-button-' + getSubActiveRightPanelId).click();
            }

            chatPopOutObject = null;
          }
        };

        $('#newChatframe').attr('src', '');
        funCheckWindow();
      }
    });
  }, []);
  return (
    <>
      <div className={`w-3/12  ${openchat ? '' : 'hidden'} relative`}>
        {/* <div className="modal-content h-full poll-popup-max live-studio-border">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md mt-4">
            <i className="fa fa-2x fas fa-comment live-studio-heading"></i>
            <h5
              className="text-xl font-semibold leading-normal text-center w-full live-studio-heading"
              id="PollModalLabel">
              Chat
            </h5>
            <i className="fas fa-external-link-alt live-studio-heading"></i>
          </div>
        </div> */}
        <div className="feature-div playerchatContent h-full ">
          <iframe
            id="chatFrame"
            src={`${process.env.REACT_APP_WCC_CHAT}?v=1.01`}
            width="100%"
            height="100%"
            allow="autoplay"></iframe>
        </div>
      </div>
    </>
  );
};

export default ChatModal;
