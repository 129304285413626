import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import Select from 'react-select';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import Loader from 'src/components/loader';
import { SelectModel } from 'src/models/videosModel';
import { useParams } from 'react-router-dom';
import { errorMsg } from 'src/components/utilities/utils';
import { customFilterOption } from 'src/components/utilities/utils';
import { useNavigate } from 'react-router-dom';
const ChannelSetting = () => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const CloseButtonRef = useRef<HTMLButtonElement>(null);
  const InputFile = useRef<HTMLInputElement>(null);
  const HeroInputFile = useRef<HTMLInputElement>(null);
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const [videoTypeValue, setVideoTypeValue] = useState<number>(1);
  const [heroImageUrl, setHeroImageUrl] = useState<string>('');
  const [channelThumbnail, setChannelThumbnail] = useState<string>('');
  const [removeHeroImage, setRemoveHeroImage] = useState<boolean>(false);
  const [heroImageName, setHeroImageName] = useState<string>('');
  const [removeChannelThumbnail, setRemoveChannelThumbnail] = useState<boolean>(false);
  const [channelThumbnailName, setChannelThumbanilName] = useState<string>('');
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch
  } = useForm();

  const handleSelectNewClick = () => {
    if (InputFile.current) {
      InputFile.current.click();
    }
  };

  const handleSelectHeroImageClick = () => {
    if (HeroInputFile.current) {
      HeroInputFile.current.click();
    }
  };

  const handleVideoThumbnailUpload = async (selectedFile: any) => {
    const cId = singleChannel && singleChannel.id;
    setIsUploadToS3Success('');
    if (selectedFile) {
      setChannelThumbanilName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/channelthumbnail?channelId=${cId}&`,
        setChannelThumbnail,
        'channelImagesUrl',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadChannelImageToApi
      );
    }
  };

  const handleHeroImageUpload = async (selectedFile: any) => {
    const cId = singleChannel && singleChannel.id;
    setIsUploadToS3Success('');
    if (selectedFile) {
      setHeroImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/channelhero?channelId=${cId}&`,
        setHeroImageUrl,
        'channelImagesUrl',
        setIsUploadToS3Loading,
        setIsUploadToS3Success,
        uploadHeroImageToApi
      );
    }
  };

  const uploadHeroImageToApi = (heroImageUrl: string) => {
    const cId = singleChannel && singleChannel.id;
    const payloadData = {
      channelId: cId,
      updateData: [{ op: 'replace', path: 'highlightImageUrl', value: heroImageUrl }]
    };
    channelActions.updateChannelRequest(payloadData);
  };

  const uploadChannelImageToApi = (channelImage: string) => {
    const cId = singleChannel && singleChannel.id;
    const payloadData = {
      channelId: cId,
      updateData: [{ op: 'replace', path: 'channelThumbnail', value: channelImage }]
    };
    channelActions.updateChannelRequest(payloadData);
  };

  const {
    channelId,
    singleChannel,
    isChannelAdded,
    message,
    addNewChannelFlag,
    isChannelDeleted,
    isChannelUpdated,
    isChannelSavedLoading,
    channelList,
    error
  } = useSelector((state: RootState) => {
    return {
      channelId: state.channel.channelId,
      singleChannel: state.channel.singleChannel,
      isChannelAdded: state.channel.isChannelAdded,
      message: state.channel.message,
      addNewChannelFlag: state.channel.addNewChannelFlag,
      isChannelDeleted: state.channel.isChannelDeleted,
      isChannelUpdated: state.channel.isChannelUpdated,
      isChannelSavedLoading: state.channel.isChannelSavedLoading,
      channelList: state.channel.channelList,
      error: state.channel.error
    };
  });
  useEffect(() => {
    if (channelList && channelList.length) {
      if (!addNewChannelFlag) {
        const filteredChannel = channelList.filter((channel) => channel.id !== singleChannel.id);
        const filteredOptions = filteredChannel.map((item) => ({
          value: item.id,
          label: item.displayText
        }));

        setFinalChannels([
          {
            value: '',
            label: 'Select Channel'
          },
          ...filteredOptions
        ]);
      } else {
        const options = channelList.map((item) => ({
          value: item.id,
          label: item.name
        }));
        setFinalChannels([
          {
            value: '',
            label: 'Select Channel'
          },
          ...options
        ]);
      }
    }
  }, [channelList, singleChannel]);

  const onSubmit = (data: any) => {
    if (!addNewChannelFlag) {
      const payloadData = {
        channelId: data.id,
        updateData: [
          { op: 'replace', path: 'name', value: data.name },
          { op: 'replace', path: 'animateVideoCardHome', value: data.animateVideoCardHome },
          { op: 'replace', path: 'authorisationRequired', value: data.authorisationRequired },
          { op: 'replace', path: 'channelCost', value: data.channelCost },
          { op: 'replace', path: 'priceType', value: videoTypeValue },
          {
            op: 'replace',
            path: 'displayAsSpotlightChannel',
            value: data.displayAsSpotlightChannel
          },
          { op: 'replace', path: 'heroDescription', value: data.heroDescription },
          { op: 'replace', path: 'homeCardType', value: data.homeCardType },
          { op: 'replace', path: 'privateChannel', value: data.privateChannel },
          { op: 'replace', path: 'publishtoLibrary', value: data.publishtoLibrary },
          { op: 'replace', path: 'useExternalSales', value: data.useExternalSales },
          { op: 'replace', path: 'externalSalesPageUrl', value: data.externalSalesPageUrl },
          { op: 'replace', path: 'videoCost', value: data.videoCost },
          { op: 'replace', path: 'parentId', value: data.parentId.value }
        ]
      };
      channelActions.updateChannelRequest(payloadData);
    } else {
      const finalData = { ...data, parentId: data.parentId.value, priceType: videoTypeValue };
      channelActions.saveChannelRequest(finalData);
    }
  };

  useEffect(() => {
    if (singleChannel) {
      reset(singleChannel);
      setVideoTypeValue(singleChannel.priceType);
      setHeroImageUrl(singleChannel.highlightImageUrl);
      setChannelThumbnail(singleChannel.channelThumbnail);
    }
  }, [singleChannel]);
  useEffect(() => {
    if (singleChannel) {
      const selectedChannel =
        channelList && channelList.filter((item) => item.id === singleChannel.parentId);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].displayText : ''
        };
      }
      setValue('parentId', dataRef);
    }
  }, [channelList, singleChannel]);

  useEffect(() => {
    if ((id && channelList && channelList.length) || addNewChannelFlag) {
      const selectedChannel = channelList && channelList.filter((item) => item.id === id);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].displayText : ''
        };
      }
      reset({
        id: '',
        animateVideoCardHome: false,
        authorisationRequired: false,
        channelCost: 0,
        priceType: 1,
        displayAsSpotlightChannel: false,
        heroDescription: '',
        homeCardType: 0,
        name: '',
        privateChannel: false,
        publishtoLibrary: false,
        useExternalSales: false,
        videoCost: 0,
        parentId: dataRef
      });
      setVideoTypeValue(1);
    }
  }, [channelList, id, addNewChannelFlag]);

  useEffect(() => {
    if (isChannelAdded || isChannelDeleted) {
      channelActions.resetChannelStateRequest();
      channelActions.getChannelRequest();
      if (CloseButtonRef.current) {
        CloseButtonRef.current.click();
      }
      successMsg(message);
      handleCloseClick();
      setRemoveHeroImage(false);
      setHeroImageName('');
      setRemoveChannelThumbnail(false);
      setChannelThumbanilName('');
    }
  }, [isChannelAdded, isChannelDeleted]);

  useEffect(() => {
    if (isChannelUpdated) {
      const cId = singleChannel && singleChannel.id;
      successMsg(message);
      channelActions.getSingleChannelRequest(cId);
      channelActions.resetChannelStateRequest();
      channelActions.getChannelRequest();
      navigate('/channels');
    }
  }, [isChannelUpdated]);
  const handleCloseClick = () => {
    reset({
      id: '',
      animateVideoCardHome: false,
      authorisationRequired: false,
      channelCost: 0,
      priceType: 1,
      displayAsSpotlightChannel: false,
      heroDescription: '',
      homeCardType: 0,
      name: '',
      privateChannel: false,
      publishtoLibrary: false,
      useExternalSales: false,
      videoCost: 0,
      parentId: ''
    });
    channelActions.resetAddNewChannelFlagRequest();
    setVideoTypeValue(1);
    setIsUploadToS3Success('');
  };

  useEffect(() => {
    if (!channelList.length) {
      channelActions.getChannelListRequest();
    }
  }, []);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      channelActions.resetChannelStateRequest();
    }
  }, [error]);

  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  const handleRemoveHeroImage = () => {
    const cId = singleChannel && singleChannel.id;
    const payloadData = {
      channelId: cId,
      updateData: [{ op: 'replace', path: 'highlightImageUrl', value: '' }]
    };
    channelActions.updateChannelRequest(payloadData);
  };

  const handleRemoveChannelImage = () => {
    const cId = singleChannel && singleChannel.id;
    const payloadData = {
      channelId: cId,
      updateData: [{ op: 'replace', path: 'channelThumbnail', value: '' }]
    };
    channelActions.updateChannelRequest(payloadData);
  };
  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  const useExternalSales = watch('useExternalSales');
  return (
    <>
      <div>
        <div className="channel-settings">
          <div
            className="modal fade"
            id="SettingsModal"
            aria-labelledby="SettingsModalTitle"
            aria-modal="true"
            role="dialog">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header flex-items px-5 pt-5">
                  <h5 className="font-bold">Channel Settings</h5>
                  <button
                    onClick={handleCloseClick}
                    ref={CloseButtonRef}
                    type="button"
                    className="btn-close ml-0"
                    data-bs-dismiss="modal"
                    aria-label="Close"></button>
                </div>
                <div className="modal-body bg-white relative p-5">
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className={`form-group mb-2`}>
                        <label className="block mb-1"> Name </label>
                        <input
                          type="text"
                          className="input-style"
                          id="name"
                          {...register('name', {
                            required: true,
                            maxLength: 150
                          })}
                        />
                        {errors.name?.type === 'required' && (
                          <span className="text-red-500 text-xs flex item-center error">
                            Required Field
                          </span>
                        )}
                        {errors.name?.type === 'maxLength' && (
                          <span className="text-red-500 text-xs flex item-center error">
                            Max. length reached
                          </span>
                        )}
                      </div>
                      <div className={`form-group mb-2`}>
                        <label className="block mb-1"> Parent Channel </label>
                        {finalChannels && Object.keys(finalChannels).length && (
                          <Controller
                            render={({
                              field: { onChange, onBlur, value, ref },
                              fieldState: { error }
                            }) => (
                              <Select
                                filterOption={customFilterOption}
                                options={finalChannels}
                                value={value}
                                id="parentId"
                                onChange={(val) => onChange(val)}
                              />
                            )}
                            name="parentId"
                            control={control}
                          />
                        )}
                      </div>

                      <div>
                        <div className="flex items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input radio-btn"
                              type="radio"
                              name="priceType"
                              value={videoTypeValue}
                              onChange={() => setVideoTypeValue(1)}
                              checked={videoTypeValue === 1 ? true : false}
                            />
                            <label className="w-16" htmlFor="paid">
                              Paid
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input radio-btn"
                              type="radio"
                              name="priceType"
                              value={videoTypeValue}
                              onChange={() => setVideoTypeValue(2)}
                              checked={videoTypeValue === 2 ? true : false}
                            />
                            <label className="w-16" htmlFor="free">
                              Free
                            </label>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input radio-btn"
                              type="radio"
                              name="priceType"
                              value={videoTypeValue}
                              onChange={() => setVideoTypeValue(3)}
                              checked={videoTypeValue === 3 ? true : false}
                            />
                            <label className="w-16" htmlFor="locked">
                              Locked
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2 border-b mt-2 py-2">
                        <div className={`form-group mb-2`}>
                          <label className="block mb-1"> Video Price($) </label>
                          <input
                            type="text"
                            className="input-style"
                            id="videoCost"
                            {...register('videoCost', {
                              required: true,
                              maxLength: 150,
                              pattern: /^\d*\.?\d*$/
                            })}
                          />
                          {errors.videoCost?.type === 'required' && (
                            <span className="text-red-500 text-xs flex item-center error">
                              Required Field
                            </span>
                          )}
                          {errors.videoCost?.type === 'maxLength' && (
                            <span className="text-red-500 text-xs flex item-center error">
                              Max. length reached
                            </span>
                          )}
                          {errors.videoCost?.type === 'pattern' && (
                            <span className="text-red-500 text-xs flex item-center error">
                              Only Numbers are allowed
                            </span>
                          )}
                        </div>
                        <div className={`form-group mb-2`}>
                          <label className="block mb-1"> Channel Price($) </label>
                          <input
                            type="text"
                            className="input-style"
                            id="channelCost"
                            {...register('channelCost', {
                              maxLength: 150,
                              pattern: /^\d*\.?\d*$/
                            })}
                          />

                          {errors.channelCost?.type === 'maxLength' && (
                            <span className="text-red-500 text-xs flex item-center error">
                              Max. length reached
                            </span>
                          )}
                          {errors.channelCost?.type === 'pattern' && (
                            <span className="text-red-500 text-xs flex item-center error">
                              Only Numbers are allowed
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="border-b pb-2 pt-3">
                        <h5 className="font-semiblod mb-2 text-gray-400">CHANNEL SECURITY</h5>
                        <div>
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <label
                              className="form-check-label inline-block font-semibold text-lg switch-label"
                              htmlFor="authorisationRequired">
                              Authentication Required
                            </label>
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="authorisationRequired"
                                {...register('authorisationRequired')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <label
                              className="form-check-label inline-block font-semibold text-lg switch-label"
                              htmlFor="Credit">
                              Private Channel
                            </label>
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="privateChannel"
                                {...register('privateChannel')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-3">
                        <div>
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <label
                              className="form-check-label inline-block font-semibold text-lg switch-label"
                              htmlFor="Credit">
                              Publish to Library
                            </label>
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="publishtoLibrary"
                                {...register('publishtoLibrary')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <label
                              className="form-check-label inline-block font-semibold text-lg switch-label"
                              htmlFor="Credit">
                              Use External Sales
                            </label>
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="useExternalSales"
                                {...register('useExternalSales')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                        {useExternalSales && (
                          <div className={`form-group mb-2`}>
                            <input
                              className="input-style"
                              id="externalSalesPageUrl"
                              {...register('externalSalesPageUrl')}
                            />
                          </div>
                        )}

                        <div>
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <label
                              className="form-check-label inline-block font-semibold text-lg switch-label"
                              htmlFor="displayAsSpotlightChannel">
                              Highlight Channel
                            </label>
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="displayAsSpotlightChannel"
                                {...register('displayAsSpotlightChannel')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`form-group mb-2`}>
                        <label className="block mb-1"> Home Card Type </label>
                        <select
                          defaultValue={0}
                          className="border border-gray-300 input-style"
                          id="homeCardType"
                          {...register('homeCardType', {
                            required: true
                          })}>
                          <option value={0}>Default</option>
                          <option value={1}>Minimalist</option>
                        </select>
                        {errors.homeCardType?.type === 'required' && (
                          <span className="text-red-500 text-xs flex item-center error">
                            Required Field
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                          <label
                            className="form-check-label inline-block font-semibold text-lg switch-label"
                            htmlFor="animateVideoCardHome">
                            Animate Video Card(Home)
                          </label>
                          <div className="relative">
                            <input
                              className="form-check-input switch-btn"
                              type="checkbox"
                              role="switch"
                              id="animateVideoCardHome"
                              {...register('animateVideoCardHome')}
                            />
                            <i className="fas fa-check checkbox-tick hidden"></i>
                            <i className="fas fa-times checkbox-cross"></i>
                          </div>
                        </div>
                      </div>
                      {!addNewChannelFlag && (
                        <>
                          <div className={`form-group mb-4 thumbnail-main`}>
                            <label>Channel Thumbnail :</label>
                            <div className="relative channel-setting-image flex justify-center">
                              {singleChannel && singleChannel.channelThumbnail ? (
                                <img
                                  className="w-3/5"
                                  src={`${singleChannel && singleChannel.channelThumbnail}`}
                                />
                              ) : (
                                <div className="w-3/5 h-36 border-2"></div>
                              )}

                              <div className="flex flex-wrap justify-center items-center pb-4 rounded-b-md absolute inset-0 btns-main">
                                <div className="flex flex-col items-center">
                                  <div className="flex mt-14">
                                    <div
                                      className={`video-upload-btn mr-2 ${
                                        channelThumbnail ? '' : 'hidden'
                                      }`}>
                                      <input
                                        ref={InputFile}
                                        type="file"
                                        onChange={(e) => handleVideoThumbnailUpload(e.target.files)}
                                        className="hidden"
                                        onClick={onInputClick}
                                      />
                                      <label
                                        onClick={() => {
                                          setChannelThumbnail(''),
                                            setRemoveChannelThumbnail(true),
                                            setChannelThumbanilName('');
                                          handleRemoveChannelImage();
                                        }}
                                        className={`btn-primary cursor-pointer mx-0 mt-0 `}>
                                        Remove
                                      </label>
                                    </div>

                                    <button
                                      type="button"
                                      onClick={handleSelectNewClick}
                                      className="mx-0 px-6 btn-primary w-28 mt-0">
                                      Upload
                                    </button>
                                  </div>
                                  <div className="mt-2">
                                    <p className="text-black text-xs font-semibold">
                                      Recommended Size: 220x180px
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={`form-group mb-4 thumbnail-main`}>
                            <label>Hero Image :</label>
                            <div className="relative channel-setting-image flex justify-center">
                              {singleChannel && singleChannel.highlightImageUrl ? (
                                <img src={`${singleChannel && singleChannel.highlightImageUrl}`} />
                              ) : (
                                <div className="w-3/5 h-36 border-2"></div>
                              )}

                              <div className="flex flex-wrap justify-center items-center pb-4 rounded-b-md absolute inset-0 btns-main">
                                <div className="flex flex-col items-center">
                                  <div className="flex mt-14">
                                    <div
                                      className={`video-upload-btn mr-2 ${
                                        heroImageUrl ? '' : 'hidden'
                                      }`}>
                                      <input
                                        ref={HeroInputFile}
                                        type="file"
                                        onChange={(e) => handleHeroImageUpload(e.target.files)}
                                        className="hidden"
                                        onClick={onInputClick}
                                      />
                                      <label
                                        onClick={() => {
                                          setHeroImageUrl(''),
                                            setRemoveHeroImage(true),
                                            setHeroImageName('');
                                          handleRemoveHeroImage();
                                        }}
                                        className={`btn-primary cursor-pointer mx-0 mt-0 `}>
                                        Remove
                                      </label>
                                    </div>
                                    <button
                                      type="button"
                                      onClick={handleSelectHeroImageClick}
                                      className="mx-0 px-6 btn-primary w-28 mt-0">
                                      Upload
                                    </button>
                                  </div>
                                  <div className="mt-2">
                                    <p className="text-black text-xs font-semibold">
                                      Recommended Size: 1400x400px
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {isUploadToS3Loading && <Loader />}
                      <div className={`form-group mb-2`}>
                        <label className="block mb-1">Hero Description </label>
                        <textarea
                          rows={3}
                          className="input-style"
                          placeholder="Write a comment..."
                          id="heroDescription"
                          {...register('heroDescription')}
                        />
                      </div>
                      {isChannelSavedLoading && <Loader />}
                      <div className="flex items-center justify-end ml-auto p-4">
                        <button type="submit" className="btn-primary w-36 mt-0 mr-3 ml-0">
                          Save
                        </button>
                        {!addNewChannelFlag && (
                          <>
                            <button
                              type="button"
                              className="btn-primary blue-border-btn w-36 mt-0 mr-3 ml-0">
                              Launch
                            </button>
                            <button
                              type="button"
                              className="btn-primary blue-border-btn w-36 mt-0 mr-3 ml-0">
                              Share
                            </button>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelSetting;
