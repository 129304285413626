import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SelectModel } from 'src/models/videosModel';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { customFilterOption } from 'src/components/utilities/utils';
import Select from 'react-select';
import { RootState } from 'src/store/reducers/state';

const VideoSearch = ({ setFilterData, showFilter }: any) => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const [finalCategories, setFinalCategories] = useState<SelectModel[]>();
  const [finalVideoTag, setFinalVideoTag] = useState<SelectModel[]>();
  const [finalSpeaker, setFinalSpeaker] = useState<SelectModel[]>();
  const [finalContentType, setFinalContentType] = useState<SelectModel[]>();

  const { speakers, categoriesList } = useSelector((state: RootState) => {
    return {
      speakers: state.videos.speakers,
      categoriesList: state.category.categoriesList
    };
  });
  const { handleSubmit, setValue, control } = useForm();

  useEffect(() => {
    if (categoriesList && Object.keys(categoriesList).length) {
      const options = categoriesList.categories.map((item) => ({
        value: item.id,
        label: item.category
      }));
      const finalCategoriesOptions = [{ value: '', label: 'Select...' }, ...options];
      setFinalCategories(finalCategoriesOptions);
    }
  }, [categoriesList]);
  useEffect(() => {
    if (categoriesList && Object.keys(categoriesList).length) {
      const finalVideoTags = categoriesList.tags.map((videoTag) => {
        return { value: videoTag.id, label: videoTag.tag };
      });
      const finalTagsOptions = [{ value: '', label: 'Select...' }, ...finalVideoTags];
      setFinalVideoTag(finalTagsOptions);
    }
  }, [categoriesList]);

  useEffect(() => {
    if (speakers) {
      const finalspeaker = speakers.map((speaker) => {
        return { value: speaker.id, label: speaker.userName };
      });
      const finalSpeakerOptions = [{ value: '', label: 'Select...' }, ...finalspeaker];
      setFinalSpeaker(finalSpeakerOptions);
    }
  }, [speakers]);

  useEffect(() => {
    if (categoriesList && Object.keys(categoriesList).length) {
      const finalContentType = categoriesList.contenttypes.map((contentType) => {
        return { value: contentType.id, label: contentType.contentType };
      });
      const finalOptions = [{ value: '', label: 'Select...' }, ...finalContentType];
      setFinalContentType(finalOptions);
    }
  }, [categoriesList]);
  const onSubmit = (data: any) => {
    const { category, speakerId, tag, contentType } = data;
    const payloadData = {
      channelId: id,
      category: category?.value ? category.value : '',
      speakerId: speakerId?.value ? speakerId.value : '',
      tag: tag?.value ? tag.value : '',
      contentType: contentType?.value ? contentType.value : ''
    };
    setFilterData(payloadData);
    videoActions.searchVideoRequest(payloadData);
  };
  const handleResetClick = () => {
    setFilterData(null);
    setValue('speakerId', '');
    setValue('category', '');
    setValue('tag', '');
    setValue('contentType', '');
    const payloadData = {
      channelId: id,
      pageNumber: 1
    };
    videoActions.searchVideoRequest(payloadData);
  };
  useEffect(() => {
    if (id) {
      setFilterData(null);
    }
  }, [id]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`${showFilter ? '' : 'hidden'} mb-3`}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 mt-5">
        <h5 className="font-semibold mb-4">Search Videos</h5>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 xl:grid-cols-4 gap-4 2xl:gap-5">
        <div className={`form-group`}>
          <label className="block text-sm font-medium mb-1"> Speakers </label>
          {speakers && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  filterOption={customFilterOption}
                  options={finalSpeaker}
                  value={value}
                  id="speakerId"
                  onChange={(val) => onChange(val)}
                  isLoading={speakers.length ? false : true}
                />
              )}
              name="speakerId"
              control={control}
            />
          )}
        </div>
        <div className={`form-group`}>
          <label className="block text-sm font-medium mb-1"> Category </label>
          {categoriesList && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  filterOption={customFilterOption}
                  options={finalCategories}
                  value={value}
                  id="category"
                  onChange={(val) => onChange(val)}
                  isLoading={Object.keys(categoriesList).length ? false : true}
                />
              )}
              name="category"
              control={control}
            />
          )}
        </div>
        <div className={`form-group`}>
          <label className="block text-sm font-medium mb-1"> Tags </label>
          {categoriesList && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  filterOption={customFilterOption}
                  options={finalVideoTag}
                  value={value}
                  id="tag"
                  onChange={(val) => onChange(val)}
                  isLoading={Object.keys(categoriesList).length ? false : true}
                />
              )}
              name="tag"
              control={control}
            />
          )}
        </div>
        <div className={`form-group`}>
          <label className="block text-sm font-medium mb-1">Content Type</label>
          {categoriesList && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <Select
                  filterOption={customFilterOption}
                  options={finalContentType}
                  value={value}
                  id="contentType"
                  onChange={(val) => onChange(val)}
                  isLoading={Object.keys(categoriesList).length ? false : true}
                />
              )}
              name="contentType"
              control={control}
            />
          )}
        </div>
        <div className={`flex items-center lg:mt-5`}>
          <button type="submit" className="btn-primary w-auto mt-0 mr-1 ml-0 borderonly-btn">
            Filter
          </button>
          <button
            onClick={handleResetClick}
            type="button"
            className="btn-primary w-auto mt-0 mr-3 ml-2 borderonly-btn">
            Reset
          </button>
        </div>
      </div>
    </form>
  );
};

export default VideoSearch;
