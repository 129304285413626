import React, { useState } from 'react';

const HighlightReelSidebar = () => {
  const [showVideoInfo, setShowVideoInfo] = useState(true);

  return (
    <div
      className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
        showVideoInfo ? 'hide-lists' : 'show-lists'
      }`}>
      <div className="mobile-dropdown" onClick={() => setShowVideoInfo(!showVideoInfo)}>
        <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
          <p>Video Channel Layout</p>
          <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
        </div>
      </div>
      <ul
        className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mobile-layout-ul"
        id="tabs-tabVertical"
        role="tablist">
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-importantView"
            className="nav-link my-0 tabs-link pl-3 flex items-center active"
            id="tabs-important-view"
            data-bs-toggle="pill"
            data-bs-target="#tabs-importantView"
            role="tab"
            aria-controls="tabs-importantView"
            aria-selected="false">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              className="w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg">
              <mask
                id="mask0_1009_170881"
                maskUnits="userSpaceOnUse"
                x="0"
                y="2"
                width="20"
                height="16">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.6666 2.50098H3.33325C1.91659 2.50098 0.833252 3.58431 0.833252 5.00098V15.001C0.833252 16.4176 1.91659 17.501 3.33325 17.501H16.6666C18.0833 17.501 19.1666 16.4176 19.1666 15.001V5.00098C19.1666 3.58431 18.0833 2.50098 16.6666 2.50098ZM3.33325 4.16764H16.6666C16.9999 4.16764 17.2499 4.33431 17.4166 4.66764L9.99992 9.83431L2.58325 4.66764C2.74992 4.33431 2.99992 4.16764 3.33325 4.16764ZM3.33325 15.8343H16.6666C17.1666 15.8343 17.4999 15.501 17.4999 15.001V6.58431L10.4999 11.501C10.3333 11.5843 10.1666 11.6676 9.99992 11.6676C9.83325 11.6676 9.66658 11.5843 9.49992 11.501L2.49992 6.58431V15.001C2.49992 15.501 2.83325 15.8343 3.33325 15.8343Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_1009_170881)">
                <rect y="0.000976562" width="20" height="20" fill="currentcolor" />
              </g>
            </svg>
            Style
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-recentView"
            className="
          nav-link
         my-0 tabs-link pl-3 flex items-center
          "
            id="tabs-recent-view"
            data-bs-toggle="pill"
            data-bs-target="#tabs-recentView"
            role="tab"
            aria-controls="tabs-recentView"
            aria-selected="false">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              className="w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg">
              <mask
                id="mask0_1009_170877"
                maskUnits="userSpaceOnUse"
                x="0"
                y="-1"
                width="20"
                height="18">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.471 5.34752C18.8032 5.43072 19.0524 5.59712 19.1354 5.92991C19.2185 6.17951 19.1354 6.5123 18.8863 6.6787L15.066 10.4226L15.9795 15.7473C16.0626 16.0801 15.8965 16.4129 15.6473 16.5793C15.4812 16.6625 15.3151 16.7457 15.149 16.7457H14.7338L9.99992 14.2498L5.26606 16.7457C4.93386 16.9121 4.60165 16.8289 4.3525 16.6625C4.10335 16.4961 3.93725 16.1633 4.0203 15.8305L4.93386 10.5058L1.11355 6.7619C0.864396 6.5955 0.781346 6.26271 0.864396 5.92991C1.0305 5.59712 1.27965 5.34752 1.61185 5.34752L6.84401 4.51554L9.25247 -0.226782C9.50162 -0.809172 10.4982 -0.809172 10.7474 -0.226782L13.1558 4.59873L18.471 5.34752ZM13.3219 10.3394C13.3219 10.0898 13.405 9.84024 13.5711 9.59065L16.5609 6.6787L12.4914 6.09631C12.1592 6.09631 11.9101 5.92991 11.827 5.68032L9.99992 2.01958L8.17281 5.76351C8.00671 5.92991 7.75756 6.17951 7.50841 6.17951L3.43895 6.7619L6.42876 9.59065C6.59486 9.84024 6.67791 10.0898 6.67791 10.3394L5.93046 14.4162L9.58467 12.5026C9.83382 12.3362 10.083 12.3362 10.3321 12.5026L13.9863 14.4162L13.3219 10.3394Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_1009_170877)">
                <rect y="-1.49707" width="20" height="20" fill="currentcolor" />
              </g>
            </svg>
            Content
          </a>
        </li>
      </ul>
    </div>
  );
};

export default HighlightReelSidebar;
