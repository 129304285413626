import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { uploadPartUrl } from 'src/components/utilities/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
import ToastMessage from 'src/components/ToastContainer';
const PrimaryVideoReplace = () => {
  const dispatch = useDispatch();
  const rootUrl = process.env.REACT_APP_BASE_API_URL;
  const videoLibraryAction = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [videoName, setVideoName] = useState<string>('');

  const { isCancelUpload, singleVideo } = useSelector((state: RootState) => {
    return {
      isCancelUpload: state.videos.isCancelUpload,
      singleVideo: state.videos.singleVideo
    };
  });
  const handleMultiplePartUpload = async () => {
    setUploadPercentage(1);
    uploadPartUrl(
      rootUrl,
      singleVideo.id,
      singleVideo.title,
      videoName,
      singleVideo.channelId,
      uploadedFile,
      false,
      setUploadPercentage,
      videoLibraryAction.uploadVideoRequestSuccess,
      isCancelUpload,
      false
    );
  };

  const onFileInputChange = (event: any) => {
    setUploadedFile(event.target.files[0]);
    const { files } = event.target;
    setVideoName(event.target.files[0].name);
  };

  useEffect(() => {
    id && videoLibraryAction.getSingleVideosRequest(id);
  }, [id]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-8">
        <h2 className="font-semibold my-1">Videos</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to={`/videos/${id}`}>Video</Link>
          </li>
          <li className="active">
            <strong>Edit</strong>
          </li>
        </ol>
      </div>

      <div className="shadow bg-white my-5 p-4">
        <div className="mt-4">
          <form>
            <div className={`form-group mb-3`}>
              <div className="input-style p-0 speaker-browse-file flex items-center">
                <input
                  id="uploadFile"
                  disabled
                  className="pl-2"
                  placeholder={`${videoName && videoName}`}
                />
                <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                  <span>Select File</span>
                  <input
                    id="uploadBtn"
                    type="file"
                    className="upload"
                    onChange={(e) => onFileInputChange(e)}
                  />
                </div>
              </div>
              {uploadPercentage !== 0 && videoName !== '' && (
                <div
                  className={`w-full ${
                    uploadPercentage === 100 ? 'bg-green-100' : 'bg-purple-100'
                  } h-3 rounded-full mt-4`}>
                  <div
                    className={` ${
                      uploadPercentage === 100 ? 'bg-green-400' : 'bg-purple-600'
                    } text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                    style={{ width: `${uploadPercentage}%` }}>
                    {uploadPercentage}%
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center mt-6 whitespace-nowrap">
              {uploadPercentage !== 100 && (
                <button
                  disabled={videoName === '' ? true : false}
                  type="button"
                  onClick={handleMultiplePartUpload}
                  className={`px-6 btn-primary w-44 mr-2 ml-0 mt-0 ${
                    videoName === '' ? 'opacity-25' : ''
                  }`}>
                  Upload
                </button>
              )}
              <Link to={`/videos/${id}`} className="mx-0 px-6 btn-primary discard-btn w-36 mt-0">
                Back
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PrimaryVideoReplace;
