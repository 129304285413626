import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { useParams } from 'react-router-dom';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import { Link } from 'react-router-dom';
import { getColor } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
const SingleQuizQuestion: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const { id } = useParams<string>();
  const {
    singleQuizQuestion,
    singleQuizQuestionPaginationMeta,
    isSingleQuizQuestionLoading,
    quizQuestionResult
  } = useSelector((state: RootState) => {
    return {
      singleQuizQuestion: state.analytics.singleQuizQuestion,
      singleQuizQuestionPaginationMeta: state.analytics.singleQuizQuestionPaginationMeta,
      isSingleQuizQuestionLoading: state.analytics.isSingleQuizQuestionLoading,
      quizQuestionResult: state.analytics.quizQuestionResult
    };
  });

  useEffect(() => {
    const data = { id, pageNumber: 1, pageSize: 20 };
    id && analyticsAction.getSingleQuizQuestionRequest(data);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    const data = { id, pageNumber, pageSize: 20 };
    id && analyticsAction.getSingleQuizQuestionRequest(data);
  };

  useEffect(() => {
    const data = { id };
    id && analyticsAction.getQuizQuestionDataRequest(data);
  }, []);

  return (
    <>
      <div className="dashboard">
        <div>
          <div className="mt-2">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Quiz Question</h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <input className="input-style w-48 mr-3 mb-1" type="text" placeholder="Search" />
                </div>
              </div>
            </div>
            <Link to={`/analytic/quiz`}>
              <i className="fas fa-arrow-left mt-2 mb-2"></i>
              <span className="ml-2">Go Back</span>
            </Link>
            <>
              <div className="flex justify-between">
                <div>
                  <h5 className="font-bold">1. {quizQuestionResult.questionText}</h5>
                </div>
                <div className="font-bold">Points {quizQuestionResult.points}</div>
                <div className="badge-pollAttended flex justify-center">2</div>
              </div>
              {quizQuestionResult &&
                quizQuestionResult.choices?.map((choice, index) => {
                  return (
                    <div key={choice.id} className="mt-5">
                      <div
                        className={`px-2 py-3 ${choice.isAnswer ? 'pollResult-background' : ''}`}>
                        <span className="">
                          {choice.answerText} &nbsp;&nbsp;({choice.votedPercentage}%,&nbsp;
                          {choice.votedCount} Votes)
                        </span>
                        <div className={`w-full h-3 rounded-full mt-4 bg-gray-100`}>
                          <div
                            className={` text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                            style={{
                              width: `${choice.votedPercentage}%`,
                              backgroundColor: `${getColor(index)}`
                            }}></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
            <div className="flex flex-col justify-center h-full mt-4">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Email</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Username</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Attempts</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Answer</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isSingleQuizQuestionLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {singleQuizQuestion && singleQuizQuestion.length ? (
                              singleQuizQuestion.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="p-2">
                                      <div className="text-left">{item.memberName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.memberEmail}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.username}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.attempts}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastActivityDate ? defaultDateFormatter(item.lastActivityDate) : ""}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.correctAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.incorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastAnswer ? 'Correct' : 'Incorrect'}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="text-center py-2" colSpan={5}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!singleQuizQuestionPaginationMeta &&
                      !!Object.keys(singleQuizQuestionPaginationMeta).length && (
                        <Pagination
                          currentPage={singleQuizQuestionPaginationMeta.pageNumber}
                          totalCount={singleQuizQuestionPaginationMeta.totalCount}
                          pageSize={singleQuizQuestionPaginationMeta.pageSize}
                          onPageChange={(page: any) => handlePageChange(page)}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleQuizQuestion;
