import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { useCategoryActions } from 'src/store/category/actions';
import { useChannelActions } from 'src/store/channel/actions';
import { useParams, useNavigate } from 'react-router-dom';
const SideMenu = () => {
  const siteIdentyRef = useRef<HTMLAnchorElement>(null);
  const colorRef = useRef<HTMLAnchorElement>(null);
  const fontRef = useRef<HTMLAnchorElement>(null);
  const videoCardRef = useRef<HTMLAnchorElement>(null);
  const searchSettingRef = useRef<HTMLAnchorElement>(null);
  const userProfileRef = useRef<HTMLAnchorElement>(null);
  const playPageRef = useRef<HTMLAnchorElement>(null);
  const chatStyleRef = useRef<HTMLAnchorElement>(null);
  const menuStyleRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const { tab } = useParams<string>();
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const categoryAction = useCategoryActions(dispatch);
  const handleColorClick = () => {
    appearanceAction.getColorsRequest();
    navigate('/customize/color');
  };
  const handleFontClick = () => {
    appearanceAction.getFontsRequest();
    navigate('/customize/font');
  };
  const handleUserProfile = () => {
    appearanceAction.getUserProfileRequest();
    navigate('/customize/userProfile');
  };
  const handleVideoCardClick = () => {
    appearanceAction.getVideoCardRequest();
    navigate('/customize/videoCard');
  };

  const handlePlayPage = () => {
    appearanceAction.getPlayPageVideoRequest();
    appearanceAction.getPlayPageNavigationRequest();
    appearanceAction.getPlayPageSettingRequest();
    navigate('/customize/playPage');
  };

  const handleChatStyle = () => {
    appearanceAction.getChatStyleRequest();
    navigate('/customize/chatStyle');
  };

  const handleNavigation = () => {
    appearanceAction.getNavigationRequest();
    channelActions.getChannelRequest();
    categoryAction.getCategoriesListRequest();
    navigate('/customize/menus');
  };

  const handleSearchSettingClick = () => {
    appearanceAction.getAdvanceSettingRequest();
    appearanceAction.getFilterSettingRequest();
    navigate('/customize/searchSetting');
  };

  useEffect(() => {
    if (tab === 'siteIdentity') {
      if (siteIdentyRef.current) {
        siteIdentyRef.current.click();
      }
    } else if (tab === 'color') {
      if (colorRef.current) {
        colorRef.current.click();
      }
    } else if (tab === 'font') {
      if (fontRef.current) {
        fontRef.current.click();
      }
    } else if (tab === 'videoCard') {
      if (videoCardRef.current) {
        videoCardRef.current.click();
      }
    } else if (tab === 'searchSetting') {
      if (searchSettingRef.current) {
        searchSettingRef.current.click();
      }
    } else if (tab === 'userProfile') {
      if (userProfileRef.current) {
        userProfileRef.current.click();
      }
    } else if (tab === 'playPage') {
      if (playPageRef.current) {
        playPageRef.current.click();
      }
    } else if (tab === 'chatStyle') {
      if (chatStyleRef.current) {
        chatStyleRef.current.click();
      }
    } else if (tab === 'menus') {
      if (menuStyleRef.current) {
        menuStyleRef.current.click();
      }
    }
  }, [tab]);

  const handleSiteIdentityClick = () => {
    navigate('/customize/siteIdentity');
  };
  return (
    <ul
      className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mt-4 mobile-layout-ul"
      id="tabs-tabVertical"
      role="tablist">
      <li onClick={handleSiteIdentityClick} className="nav-item flex-grow" role="presentation">
        <a
          ref={siteIdentyRef}
          href="#tabs-videoLibrary"
          className="nav-link my-0 tabs-link pl-3 flex items-center active"
          data-bs-toggle="pill"
          data-bs-target="#tabs-videoLibrary"
          role="tab"
          aria-controls="tabs-videoLibrary"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-address-card-o"></i>
          Site Identity
        </a>
      </li>

      <li onClick={handleColorClick} className="nav-item flex-grow" role="presentation">
        <a
          ref={colorRef}
          href="#tabs-colorAndBackground"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-colorAndBackground"
          role="tab"
          aria-controls="tabs-colorAndBackground"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-play-circle"></i>
          Colors & Branding
        </a>
      </li>
      <li onClick={handleFontClick} className="nav-item flex-grow" role="presentation">
        <a
          ref={fontRef}
          href="#tabs-fonts"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-fonts"
          role="tab"
          aria-controls="tabs-fonts"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-edit"></i>
          Fonts
        </a>
      </li>
      <li onClick={handleVideoCardClick} className="nav-item flex-grow" role="presentation">
        <a
          ref={videoCardRef}
          href="#tabs-videoCards"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-videoCards"
          role="tab"
          aria-controls="tabs-videoCards"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-play"></i>
          Video Cards
        </a>
      </li>
      <li onClick={handleSearchSettingClick} className="nav-item flex-grow" role="presentation">
        <a
          ref={searchSettingRef}
          href="#tabs-searchSetting"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-searchSetting"
          role="tab"
          aria-controls="tabs-searchSetting"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-search"></i>
          Search Settings
        </a>
      </li>
      <li onClick={handleUserProfile} className="nav-item flex-grow" role="presentation">
        <a
          ref={userProfileRef}
          href="#tabs-videoUserProfile"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-videoUserProfile"
          role="tab"
          aria-controls="tabs-videoUserProfile"
          aria-selected="false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0_1189_92742"
              maskUnits="userSpaceOnUse"
              x="2"
              y="1"
              width="16"
              height="18">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.83333 5.83317C5.83333 3.49984 7.66667 1.6665 10 1.6665C12.3333 1.6665 14.1667 3.49984 14.1667 5.83317C14.1667 8.1665 12.3333 9.99984 10 9.99984C7.66667 9.99984 5.83333 8.1665 5.83333 5.83317ZM17.5 15.8332V17.4998C17.5 17.9998 17.1667 18.3332 16.6667 18.3332C16.1667 18.3332 15.8333 17.9998 15.8333 17.4998V15.8332C15.8333 14.4165 14.75 13.3332 13.3333 13.3332H6.66667C5.25 13.3332 4.16667 14.4165 4.16667 15.8332V17.4998C4.16667 17.9998 3.83333 18.3332 3.33333 18.3332C2.83333 18.3332 2.5 17.9998 2.5 17.4998V15.8332C2.5 13.4998 4.33333 11.6665 6.66667 11.6665H13.3333C15.6667 11.6665 17.5 13.4998 17.5 15.8332ZM10 8.33317C8.58333 8.33317 7.5 7.24984 7.5 5.83317C7.5 4.4165 8.58333 3.33317 10 3.33317C11.4167 3.33317 12.5 4.4165 12.5 5.83317C12.5 7.24984 11.4167 8.33317 10 8.33317Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1189_92742)">
              <rect width="20" height="20" fill="currentcolor" />
            </g>
          </svg>
          User Profile
        </a>
      </li>
      <li onClick={handlePlayPage} className="nav-item flex-grow" role="presentation">
        <a
          ref={playPageRef}
          href="#tabs-playPage"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-playPage"
          role="tab"
          aria-controls="tabs-playPage"
          aria-selected="false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0_1189_92756"
              maskUnits="userSpaceOnUse"
              x="2"
              y="0"
              width="16"
              height="20">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4167 7.16683C17.375 7.12516 17.3542 7.0835 17.3333 7.04183C17.3125 7.00016 17.2917 6.9585 17.25 6.91683L11.4167 1.0835C11.3333 1.00016 11.25 0.916829 11.1667 0.916829C11.0833 0.833496 10.9167 0.833496 10.8333 0.833496H5C3.58333 0.833496 2.5 1.91683 2.5 3.3335V16.6668C2.5 18.0835 3.58333 19.1668 5 19.1668H15C16.4167 19.1668 17.5 18.0835 17.5 16.6668V7.50016C17.5 7.41683 17.5 7.25016 17.4167 7.16683ZM11.6667 3.66683L14.6667 6.66683H11.6667V3.66683ZM5 17.5002H15C15.5 17.5002 15.8333 17.1668 15.8333 16.6668V8.3335H10.8333C10.3333 8.3335 10 8.00016 10 7.50016V2.50016H5C4.5 2.50016 4.16667 2.8335 4.16667 3.3335V16.6668C4.16667 17.1668 4.5 17.5002 5 17.5002Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1189_92756)">
              <rect width="20" height="20" fill="currentcolor" />
            </g>
          </svg>
          Play Page
        </a>
      </li>
      <li className="nav-item flex-grow" role="presentation" onClick={handleChatStyle}>
        <a
          ref={chatStyleRef}
          href="#tabs-chatStyle"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-chatStyle"
          role="tab"
          aria-controls="tabs-chatStyle"
          aria-selected="false">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg">
            <mask
              id="mask0_1189_92770"
              maskUnits="userSpaceOnUse"
              x="1"
              y="1"
              width="18"
              height="18">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8479 1.6665C14.9232 1.83317 18.0837 5.08317 18.3332 9.08317V9.49984C18.3332 10.7498 18.0837 11.9998 17.5015 13.0832C16.1707 15.7498 13.4261 17.4165 10.432 17.4165C9.26762 17.4165 8.18641 17.1665 7.18836 16.7498L2.78034 18.2498C2.69717 18.3332 2.614 18.3332 2.53083 18.3332C2.28132 18.3332 2.11498 18.2498 1.86547 18.0832C1.69913 17.9165 1.61596 17.5832 1.69913 17.2498L3.1962 12.8332C2.78034 11.8332 2.53083 10.6665 2.53083 9.58317C2.53083 6.58317 4.19424 3.83317 6.93885 2.49984C8.02007 1.9165 9.18445 1.6665 10.432 1.6665H10.8479ZM16.0044 12.4165C16.4203 11.4998 16.6698 10.5832 16.6698 9.58317V9.1665C16.4203 5.99984 13.9251 3.49984 10.7647 3.33317H10.432C9.51713 3.33317 8.51909 3.58317 7.68739 3.99984C5.52496 5.08317 4.19424 7.1665 4.19424 9.58317C4.19424 10.4998 4.44375 11.4998 4.8596 12.3332C5.02594 12.5832 5.02594 12.7498 4.94277 12.9998L3.86156 16.1665L7.02202 15.0832H7.02203C7.27154 15.0832 7.52105 15.0832 7.68739 15.1665C8.51909 15.5832 9.51713 15.8332 10.432 15.8332C12.8439 15.8332 14.9232 14.5832 16.0044 12.4165Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_1189_92770)">
              <rect width="20" height="20" fill="currentcolor" />
            </g>
          </svg>
          Chat Style
        </a>
      </li>

      <li className="nav-item flex-grow" role="presentation" onClick={handleNavigation}>
        <a
          ref={menuStyleRef}
          href="#tabs-navigation"
          className="
        nav-link my-0 tabs-link pl-3 flex items-center"
          data-bs-toggle="pill"
          data-bs-target="#tabs-navigation"
          role="tab"
          aria-controls="tabs-navigation"
          aria-selected="false">
          <i className="w-4 h-4 mr-2 fa fa-bars"></i>
          Menus
        </a>
      </li>
    </ul>
  );
};

export default SideMenu;
