export const getVideoAnalyticsRequestActionType = 'GET_VIDEO_ANALYTICS_REQUEST'
export const getVideoAnalyticsRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_REQUEST_SUCCESS'
export const getVideoAnalyticsRequestFailureActionType = 'GET_VIDEO_ANALYTICS_REQUEST_FAILURE'
export const getVideosRequestActionType = 'GET_VIDEOS_REQUEST'
export const getVideosRequestSuccessActionType = 'GET_VIDEOS_REQUEST_SUCCESS'
export const getVideosRequestFailureActionType = 'GET_VIDEOS_REQUEST_FAILURE'
export const addVideoRequestActionType = 'ADD_VIDEO_REQUEST'
export const addVideoRequestSuccessActionType = 'ADD_VIDEO_REQUEST_SUCCESS'
export const addVideoRequestFailureActionType = 'ADD_VIDEO_REQUEST_FAILURE'
export const getSingleVideoRequestActionType = 'GET_SINGLE_VIDEO_REQUEST'
export const getSingleVideoRequestSuccessActionType = 'GET_SINGLE_VIDEO_REQUEST_SUCCESS'
export const getSingleVideoRequestFailureActionType = 'GET_SINGLE_VIDEO_REQUEST_FAILURE'
export const editVideoRequestActionType = 'EDIT_VIDEO_REQUEST'
export const editVideoRequestSuccessActionType = 'EDIT_VIDEO_REQUEST_SUCCESS'
export const editVideoRequestFailureActionType = 'EDIT_VIDEO_REQUEST_FAILURE'
export const deleteVideoRequestActionType = 'DELETE_VIDEO_REQUEST'
export const deleteVideoRequestSuccessActionType = 'DELETE_VIDEO_REQUEST_SUCCESS'
export const deleteVideoRequestFailureActionType = 'DELETE_VIDEO_REQUEST_FAILURE'
export const resetVideoLibraryStateRequestActionType = 'RESET_VIDEO_LIBRARY_STATE_REQUEST'
export const uploadVideoRequestActionType = 'UPLOAD_VIDEO_REQUEST'
export const uploadVideoRequestSuccessActionType = 'UPLOAD_VIDEO_REQUEST_SUCCESS'
export const uploadVideoRequestFailureActionType = 'UPLOAD_VIDEO_REQUEST_FAILURE'
export const addLiveStreamRequestActionType = 'ADD_LIVE_STREAM_REQUEST'
export const addLiveStreamSuccessActionType = 'ADD_LIVE_STREAM_REQUEST_SUCCESS'
export const addLiveStreamFailureActionType = 'ADD_LIVE_STREAM_REQUEST_FAILURE'
export const addCompleteUploadRequestActionType = 'ADD_COMPLETE_UPLOAD_REQUEST'
export const addCompleteUploadRequestSuccessActionType = 'ADD_COMPLETE_UPLOAD_REQUEST_SUCCESS'
export const addCompleteUploadRequestFailureActionType = 'ADD_COMPLETE_UPLOAD_REQUEST_FAILURE'
export const cancelUploadRequestActionType = 'CANCEL_UPLOAD_REQUEST'
export const cancelUploadRequestSuccessActionType = 'CANCEL_UPLOAD_REQUEST_SUCCESS'
export const cancelUploadRequestFailureActionType = 'CANCEL_UPLOAD_REQUEST_FAILURE'
export const testUploadRequestActionType = 'TEST_UPLOAD_REQUEST'
export const testUploadRequestSuccessActionType = 'TEST_UPLOAD_REQUEST_SUCCESS'
export const testUploadRequestFailureActionType = 'TEST_UPLOAD_REQUEST_FAILURE'
export const getuploadPartUrlRequestActionType = 'GET_UPLOAD_PART_URL_REQUEST'
export const getuploadPartUrlRequestSuccessActionType = 'GET_UPLOAD_PART_URL_REQUEST_SUCCESS'
export const getuploadPartUrlRequestFailureActionType = 'GET_UPLOAD_PART_URL_REQUEST_FAILURE'
export const addSpeakerRequestActionType = 'ADD_SPEAKER_REQUEST'
export const addSpeakerRequestSuccessActionType = 'ADD_SPEAKER_REQUEST_SUCCESS'
export const addSpeakerRequestFailureActionType = 'ADD_SPEAKER_REQUEST_FAILURE'
export const searchVideoRequestActionType = 'SEARCH_VIDEO_REQUEST'
export const searchVideoRequestSuccessActionType = 'SEARCH_VIDEO_REQUEST_SUCCESS'
export const searchVideoRequestFailureActionType = 'SEARCH_VIDEO_REQUEST_FAILURE'
export const getVideoSlidesRequestActionType = 'GET_VIDEO_SLIDES_REQUEST'
export const getVideoSlidesRequestSuccessActionType = 'GET_VIDEO_SLIDES_REQUEST_SUCCESS'
export const getVideoSlidesRequestFailureActionType = 'GET_VIDEO_SLIDES_REQUEST_FAILURE'
export const saveVideoSlidesRequestActionType = 'SAVE_VIDEO_SLIDES_REQUEST'
export const saveVideoSlidesRequestSuccessActionType = 'SAVE_VIDEO_SLIDES_REQUEST_SUCCESS'
export const saveVideoSlidesRequestFailureActionType = 'SAVE_VIDEO_SLIDES_REQUEST_FAILURE'
export const updateVideoSlidesRequestActionType = 'UPDATE_VIDEO_SLIDES_REQUEST'
export const updateVideoSlidesRequestSuccessActionType = 'UPDATE_VIDEO_SLIDES_REQUEST_SUCCESS'
export const updateVideoSlidesRequestFailureActionType = 'UPDATE_VIDEO_SLIDES_REQUEST_FAILURE'
export const shareVideoRequestActionType = 'SHARE_VIDEO_REQUEST'
export const shareVideoRequestSuccessActionType = 'SHARE_VIDEO_REQUEST_SUCCESS'
export const shareVideoRequestFailureActionType = 'SHARE_VIDEO_REQUEST_FAILURE'
export const getSpeakersRequestActionType = 'GET_SPEAKERS_REQUEST'
export const getSpeakersRequestSuccessActionType = 'GET_SPEAKERS_REQUEST_SUCCESS'
export const getSpeakersRequestFailureActionType = 'GET_SPEAKERS_REQUEST_FAILURE'
export const getInterActivityRequestActionType = 'GET_INTERACTIVITY_REQUEST'
export const getInterActivityRequestSuccessActionType = 'GET_INTERACTIVITY_REQUEST_SUCCESS'
export const getInterActivityRequestFailureActionType = 'GET_INTERACTIVITY_REQUEST_FAILURE'
export const updateInterActivityRequestActionType = 'UPDATE_INTERACTIVITY_REQUEST'
export const updateInterActivityRequestSuccessActionType = 'UPDATE_INTERACTIVITY_REQUEST_SUCCESS'
export const updateInterActivityRequestFailureActionType = 'UPDATE_INTERACTIVITY_REQUEST_FAILURE'
export const getAccessibilityRequestActionType = 'GET_ACCESSIBILITY_REQUEST'
export const getAccessibilityRequestSuccessActionType = 'GET_ACCESSIBILITY_REQUEST_SUCCESS'
export const getAccessibilityRequestFailureActionType = 'GET_ACCESSIBILITY_REQUEST_FAILURE'
export const updateAccessibilityRequestActionType = 'UPDATE_ACCESSIBILITY_REQUEST'
export const updateAccessibilityRequestSuccessActionType = 'UPDATE_ACCESSIBILITY_REQUEST_SUCCESS'
export const updateAccessibilityRequestFailureActionType = 'UPDATE_ACCESSIBILITY_REQUEST_FAILURE'
export const getPlayPageRequestActionType = 'GET_PLAY_PAGE_REQUEST'
export const getPlayPageRequestSuccessActionType = 'GET_PLAY_PAGE_REQUEST_SUCCESS'
export const getPlayPageRequestFailureActionType = 'GET_PLAY_PAGE_REQUEST_FAILURE'
export const updatePlayPageRequestActionType = 'UPDATE_PLAY_PAGE_REQUEST'
export const updatePlayPageRequestSuccessActionType = 'UPDATE_PLAY_PAGE_REQUEST_SUCCESS'
export const updatePlayPageRequestFailureActionType = 'UPDATE_PLAY_PAGE_REQUEST_FAILURE'
export const getMemberListAccessRequestActionType = 'GET_MEMBER_LIST_ACCESS_REQUEST'
export const getMemberListAccessSuccessActionType = 'GET_MEMBER_LIST_ACCESS_REQUEST_SUCCESS'
export const getMemberListAccessRequestFailureActionType = 'GET_MEMBER_LIST_ACCESS_REQUEST_FAILURE'
export const saveMemberListAccessRequestActionType = 'SAVE_MEMBER_LIST_ACCESS_REQUEST'
export const saveMemberListAccessSuccessActionType = 'SAVE_MEMBER_LIST_ACCESS_REQUEST_SUCCESS'
export const saveMemberListAccessRequestFailureActionType = 'SAVE_MEMBER_LIST_ACCESS_REQUEST_FAILURE'
export const deleteMemberListAccessRequestActionType = 'DELETE_MEMBER_LIST_ACCESS_REQUEST'
export const deleteMemberListAccessSuccessActionType = 'DELETE_MEMBER_LIST_ACCESS_REQUEST_SUCCESS'
export const deleteMemberListAccessRequestFailureActionType = 'DELETE_MEMBER_LIST_ACCESS_REQUEST_FAILURE'
export const getResourcesRequestActionType = 'GET_RESOURCES_REQUEST'
export const getResourcesRequestSuccessActionType = 'GET_RESOURCES_REQUEST_SUCCESS'
export const getResourcesRequestFailureActionType = 'GET_RESOURCES_REQUEST_FAILURE'
export const addResourcesRequestActionType = 'ADD_RESOURCES_REQUEST'
export const addResourcesRequestSuccessActionType = 'ADD_RESOURCES_REQUEST_SUCCESS'
export const addResourcesRequestFailureActionType = 'ADD_RESOURCES_REQUEST_FAILURE'
export const editResourcesRequestActionType = 'EDIT_RESOURCES_REQUEST'
export const editResourcesRequestSuccessActionType = 'EDIT_RESOURCES_REQUEST_SUCCESS'
export const editResourcesRequestFailureActionType = 'EDIT_RESOURCES_REQUEST_FAILURE'
export const getSingleResourcesRequestActionType = 'GET_SINGLE_RESOURCES_REQUEST'
export const getSingleResourcesRequestSuccessActionType = 'GET_SINGLE_RESOURCES_REQUEST_SUCCESS'
export const getSingleResourcesRequestFailureActionType = 'GET_SINGLE_RESOURCES_REQUEST_FAILURE'
export const deleteResourcesRequestActionType = 'DELETE_RESOURCES_REQUEST'
export const deleteResourcesRequestSuccessActionType = 'DELETE_RESOURCES_REQUEST_SUCCESS'
export const deleteResourcesRequestFailureActionType = 'DELETE_RESOURCES_REQUEST_FAILURE'
export const getMemberListPriceRequestActionType = 'GET_MEMBER_LIST_PRICE_REQUEST'
export const getMemberListPriceSuccessActionType = 'GET_MEMBER_LIST_PRICE_REQUEST_SUCCESS'
export const getMemberListPriceRequestFailureActionType = 'GET_MEMBER_LIST_PRICE_REQUEST_FAILURE'
export const saveMemberListPriceRequestActionType = 'SAVE_MEMBER_LIST_PRICE_REQUEST'
export const saveMemberListPriceRequestSuccessActionType = 'SAVE_MEMBER_LIST_PRICE_REQUEST_SUCCESS'
export const saveMemberListPriceRequestFailureActionType = 'SAVE_MEMBER_LIST_PRICE_REQUEST_FAILURE'
export const deleteMemberListPriceRequestActionType = 'DELETE_MEMBER_LIST_PRICE_REQUEST'
export const deleteMemberListPriceRequestSuccessActionType = 'DELETE_MEMBER_LIST_PRICE_REQUEST_SUCCESS'
export const deleteMemberListPriceRequestFailureActionType = 'DELETE_MEMBER_LIST_PRICE_REQUEST_FAILURE'
export const getModeratorRequestActionType = 'GET_MODERATOR_REQUEST'
export const getModeratorRequestSuccessActionType = 'GET_MODERATOR_REQUEST_SUCCESS'
export const getModeratorRequestFailureActionType = 'GET_MODERATOR_REQUEST_FAILURE'
export const saveModeratorsRequestActionType = 'SAVE_MODERATORS_REQUEST'
export const saveModeratorsRequestSuccessActionType = 'SAVE_MODERATORS_REQUEST_SUCCESS'
export const saveModeratorsRequestFailureActionType = 'SAVE_MODERATORS_REQUEST_FAILURE'
export const deleteModeratorsRequestActionType = 'DELETE_MODERATORS_REQUEST'
export const deleteModeratorsRequestSuccessActionType = 'DELETE_MODERATORS_REQUEST_SUCCESS'
export const deleteModeratorsRequestFailureActionType = 'DELETE_MODERATORS_REQUEST_FAILURE'
export const getCallToActionRequestActionType = 'GET_CALL_TO_ACTION_REQUEST'
export const getCallToActionRequestSuccessActionType = 'GET_CALL_TO_ACTION_REQUEST_SUCCESS'
export const getCallToActionRequestFailureActionType = 'GET_CALL_TO_ACTION_REQUEST_FAILURE'
export const saveCallToActionRequestActionType = 'SAVE_CALL_TO_ACTION_REQUEST'
export const saveCallToActionRequestSuccessActionType = 'SAVE_CALL_TO_ACTION_REQUEST_SUCCESS'
export const saveCallToActionRequestFailureActionType = 'SAVE_CALL_TO_ACTION_REQUEST_FAILURE'
export const deleteCallToActionRequestActionType = 'DELETE_CALL_TO_ACTION_REQUEST'
export const deleteCallToActionRequestSuccessActionType = 'DELETE_CALL_TO_ACTION_REQUEST_SUCCESS'
export const deleteCallToActionRequestFailureActionType = 'DELETE_CALL_TO_ACTION_REQUEST_FAILURE'
export const getSingleCallToActionRequestActionType = 'GET_SINGLE_CALL_TO_ACTION_REQUEST'
export const getSingleCallToActionRequestSuccessActionType = 'GET_SINGLE_CALL_TO_ACTION_REQUEST_SUCCESS'
export const getSingleCallToActionRequestFailureActionType = 'GET_SINGLE_CALL_TO_ACTION_REQUEST_FAILURE'
export const updateCallToActionRequestActionType = 'UPDATE_CALL_TO_ACTION_REQUEST'
export const updateCallToActionRequestSuccessActionType = 'UPDATE_CALL_TO_ACTION_REQUEST_SUCCESS'
export const updateCallToActionRequestFailureActionType = 'UPDATE_CALL_TO_ACTION_REQUEST_FAILURE'
export const getVideoViewingSessionRequestActionType = 'GET_VIDEO_VIEWING_SESSION_REQUEST'
export const getVideoViewingSessionRequestSuccessActionType = 'GET_VIDEO_VIEWING_SESSION_REQUEST_SUCCESS'
export const getVideoViewingSessionRequestFailureActionType = 'GET_VIDEO_VIEWING_SESSION_REQUEST_FAILURE'
export const createPollFromTemplateRequestActionType = 'CREATE_POLL_FROM_TEMPLATE_REQUEST'
export const createPollFromTemplateRequestSuccessActionType = 'CREATE_POLL_FROM_TEMPLATE_REQUEST_SUCCESS'
export const createPollFromTemplateRequestFailureActionType = 'CREATE_POLL_FROM_TEMPLATE_REQUEST_FAILURE'
export const saveSlidesRequestActionType = 'SAVE_SLIDES_REQUEST'
export const saveSlidesRequestSuccessActionType = 'SAVE_SLIDES_REQUEST_SUCCESS'
export const saveSlidesRequestFailureActionType = 'SAVE_SLIDES_REQUEST_FAILURE'
export const getSlidesRequestActionType = 'GET_SLIDES_REQUEST'
export const getSlidesRequestSuccessActionType = 'GET_SLIDES_REQUEST_SUCCESS'
export const getSlidesRequestFailureActionType = 'GET_SLIDES_REQUEST_FAILURE'
export const updateSlidesRequestActionType = 'UPDATE_SLIDES_REQUEST'
export const updateSlidesRequestSuccessActionType = 'UPDATE_SLIDES_REQUEST_SUCCESS'
export const updateSlidesRequestFailureActionType = 'UPDATE_SLIDES_REQUEST_FAILURE'
export const savePollRequestActionType = 'SAVE_POLL_REQUEST'
export const savePollRequestSuccessActionType = 'SAVE_POLL_REQUEST_SUCCESS'
export const savePollRequestFailureActionType = 'SAVE_POLL_REQUEST_FAILURE'
export const getPollRequestActionType = 'GET_POLL_REQUEST'
export const getPollRequestSuccessActionType = 'GET_POLL_REQUEST_SUCCESS'
export const getPollRequestFailureActionType = 'GET_POLL_REQUEST_FAILURE'
export const updatePollRequestActionType = 'UPDATE_POLL_REQUEST'
export const updatePollRequestSuccessActionType = 'UPDATE_POLL_REQUEST_SUCCESS'
export const updatePollRequestFailureActionType = 'UPDATE_POLL_REQUEST_FAILURE'
export const getPollsTemplatesRequestActionType = 'GET_POLLS_TEMPLATES_REQUEST'
export const getPollsTemplatesSuccessActionType = 'GET_POLLS_TEMPLATES_REQUEST_SUCCESS'
export const getPollsTemplatesFailureActionType = 'GET_POLLS_TEMPLATES_REQUEST_FAILURE'
export const saveQuestionRequestActionType = 'SAVE_QUESTION_REQUEST'
export const saveQuestionRequestSuccessActionType = 'SAVE_QUESTION_REQUEST_SUCCESS'
export const saveQuestionRequestFailureActionType = 'SAVE_QUESTION_REQUEST_FAILURE'
export const updateQuestionRequestActionType = 'UPDATE_QUESTION_REQUEST'
export const updateQuestionRequestSuccessActionType = 'UPDATE_QUESTION_REQUEST_SUCCESS'
export const updateQuestionRequestFailureActionType = 'UPDATE_QUESTION_REQUEST_FAILURE'
export const getQuestionRequestActionType = 'GET_QUESTION_REQUEST'
export const getQuestionRequestSuccessActionType = 'GET_QUESTION_REQUEST_SUCCESS'
export const getQuestionRequestFailureActionType = 'GET_QUESTION_REQUEST_FAILURE'
export const getVideoMediaRequestActionType = 'GET_VIDEO_MEDIA_REQUEST'
export const getVideoMediaRequestSuccessActionType = 'GET_VIDEO_MEDIA_REQUEST_SUCCESS'
export const getVideoMediaRequestFailureActionType = 'GET_VIDEO_MEDIA_REQUEST_FAILURE'
export const saveVideoMediaRequestActionType = 'SAVE_VIDEO_MEDIA_REQUEST'
export const saveVideoMediaRequestSuccessActionType = 'SAVE_VIDEO_MEDIA_REQUEST_SUCCESS'
export const saveVideoMediaRequestFailureActionType = 'SAVE_VIDEO_MEDIA_REQUEST_FAILURE'
export const updateVideoMediaRequestActionType = 'UPDATE_VIDEO_MEDIA_REQUEST'
export const updateVideoMediaRequestSuccessActionType = 'UPDATE_VIDEO_MEDIA_REQUEST_SUCCESS'
export const updateVideoMediaRequestFailureActionType = 'UPDATE_VIDEO_MEDIA_REQUEST_FAILURE'
export const getPollsResultRequestActionType = 'GET_POLLS_RESULT_REQUEST'
export const getPollsResultRequestSuccessActionType = 'GET_POLLS_REQUEST_SUCCESS'
export const getPollsResultRequestFailureActionType = 'GET_POLLS_REQUEST_FAILURE'
export const saveQuizRequestActionType = 'SAVE_QUIZ_REQUEST'
export const saveQuizRequestSuccessActionType = 'SAVE_QUIZ_REQUEST_SUCCESS'
export const saveQuizRequestFailureActionType = 'SAVE_QUIZ_REQUEST_FAILURE'
export const getQuizRequestActionType = 'GET_QUIZ_REQUEST'
export const getQuizRequestSuccessActionType = 'GET_QUIZ_REQUEST_SUCCESS'
export const getQuizRequestFailureActionType = 'GET_QUIZ_REQUEST_FAILURE'
export const saveMultiChoiceRequestActionType = 'SAVE_MULTICHOICE_REQUEST'
export const saveMultiChoiceRequestSuccessActionType = 'SAVE_MULTICHOICE_REQUEST_SUCCESS'
export const saveMultiChoiceRequestFailureActionType = 'SAVE_MULTICHOICE_REQUEST_FAILURE'
export const getMultiChoiceRequestActionType = 'GET_MULTICHOICE_REQUEST'
export const getMultiChoiceRequestSuccessActionType = 'GET_MULTICHOICE_REQUEST_SUCCESS'
export const getMultiChoiceRequestFailureActionType = 'GET_MULTICHOICE_REQUEST_FAILURE'
export const saveStarRatingRequestActionType = 'SAVE_STAR_RATING_REQUEST'
export const saveStarRatingRequestSuccessActionType = 'SAVE_STAR_RATING_REQUEST_SUCCESS'
export const saveStarRatingRequestFailureActionType = 'SAVE_STAR_RATING_REQUEST_FAILURE'
export const getStarRatingRequestActionType = 'GET_STAR_RATING_REQUEST'
export const getStarRatingRequestSuccessActionType = 'GET_STAR_RATING_REQUEST_SUCCESS'
export const getStarRatingRequestFailureActionType = 'GET_STAR_RATING_REQUEST_FAILURE'
export const saveOpenTextRequestActionType = 'SAVE_OPEN_TEXT_REQUEST'
export const saveOpenTextRequestSuccessActionType = 'SAVE_OPEN_TEXT_REQUEST_SUCCESS'
export const saveOpenTextRequestFailureActionType = 'SAVE_OPEN_TEXT_REQUEST_FAILURE'
export const getOpenTextRequestActionType = 'GET_OPEN_TEXT_REQUEST'
export const getOpenTextRequestSuccessActionType = 'GET_OPEN_TEXT_REQUEST_SUCCESS'
export const getOpenTextRequestFailureActionType = 'GET_OPEN_TEXT_REQUEST_FAILURE'
export const getVideoAnalyticsDataRequestActionType = 'GET_VIDEO_ANALYTICS_DATA_REQUEST'
export const getVideoAnalyticsDataRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_DATA_REQUEST_SUCCESS'
export const getVideoAnalyticsDataRequestFailureActionType = 'GET_VIDEO_ANALYTICS_DATA_REQUEST_FAILURE'
export const getVideoAnalyticsChartDataRequestActionType = 'GET_VIDEO_ANALYTICS_CHART_DATA_REQUEST'
export const getVideoAnalyticsChartDataRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_CHART_DATA_REQUEST_SUCCESS'
export const getVideoAnalyticsChartDataRequestFailureActionType = 'GET_VIDEO_ANALYTICS_CHART_DATA_REQUEST_FAILURE'
export const savePollTemplateRequestActionType = 'SAVE_POLL_TEMPLATE_REQUEST'
export const savePollTemplateRequestSuccessActionType = 'SAVE_POLL_TEMPLATE_REQUEST_SUCCESS'
export const savePollTemplateRequestFailureActionType = 'SAVE_POLL_TEMPLATE_REQUEST_FAILURE'
export const generateAuthKeyRequestActionType = 'GENERATE_AUTH_KEY_REQUEST'
export const generateAuthKeyRequestSuccessActionType = 'GENERATE_AUTH_KEY_REQUEST_SUCCESS'
export const generateAuthKeyRequestFailureActionType = 'GENERATE_AUTH_KEY_REQUEST_FAILURE'
export const updateRedirectToLoginRequestActionType = 'UPDATE_REDIRECT_TO_LOGIN_REQUEST'
export const updateRedirectToLoginRequestSuccessActionType = 'UPDATE_REDIRECT_TO_LOGIN_REQUEST_SUCCESS'
export const updateRedirectToLoginRequestFailureActionType = 'UPDATE_REDIRECT_TO_LOGIN_REQUEST_FAILURE'
export const resetRedirectToLoginRequestActionType = 'RESET_REDIRECT_TO_LOGIN_REQUEST'
export const resetAuthKeyRequestActionType = 'RESET_AUTH_KEY_REQUEST'
export const updateVideoAnalyticsRequestActionType = 'UPDATE_VIDEO_ANALYTCS_REQUEST'
export const updateVideoAnalyticsRequestSuccessActionType = 'UPDATE_VIDEO_ANALYTCS_REQUEST_SUCCESS'
export const updateVideoAnalyticsRequestFailureActionType = 'UPDATE_VIDEO_ANALYTCS_REQUEST_FAILURE'
export const getVideoAnalyticsLiveViewsRequestActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_REQUEST'
export const getVideoAnalyticsLiveViewsRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_REQUEST_SUCCESS'
export const getVideoAnalyticsLiveViewsRequestFailureActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_REQUEST_FAILURE'
export const getVideoAnalyticsLiveViewsChartDataRequestActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_CHART_DATA_REQUEST'
export const getVideoAnalyticsLiveViewsChartDataRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_CHART_DATA_REQUEST_SUCCESS'
export const getVideoAnalyticsLiveViewsChartDataRequestFailureActionType = 'GET_VIDEO_ANALYTICS_LIVE_VIEWS_CHART_DATA_REQUEST_FAILURE'
export const updateVideoThumbnailRequestActionType = 'UPDATE_VIDEO_THUMBNAIL_REQUEST'
export const updateVideoThumbnailRequestSuccessActionType = 'UPDATE_VIDEO_THUMBNAIL_REQUEST_SUCCESS'
export const updateVideoThumbnailRequestFailureActionType = 'UPDATE_VIDEO_THUMBNAIL_REQUEST_FAILURE'
export const resetAttachmentIdRequestActionType = 'RESET_ATTACHMENT_ID_REQUEST'
export const getLiveEventUrlRequestActionType = 'GET_LIVE_EVENT_URL_REQUEST'
export const getLiveEventUrlRequestSuccessActionType = 'GET_LIVE_EVENT_URL_REQUEST_SUCCESS'
export const getLiveEventUrlRequestFailureActionType = 'GET_LIVE_EVENT_URL_REQUEST_FAILURE'
export const getLiveMediaRequestActionType = 'GET_LIVE_MEDIA_REQUEST'
export const getLiveMediaRequestSuccessActionType = 'GET_LIVE_MEDIA_REQUEST_SUCCESS'
export const getLiveMediaRequestFailureActionType = 'GET_LIVE_MEDIA_REQUEST_FAILURE'
export const deletePollRequestActionType = 'DELETE_POLL_REQUEST'
export const deletePollRequestSuccessActionType = 'DELETE_POLL_REQUEST_SUCCESS'
export const deletePollRequestFailureActionType = 'DELETE_POLL_REQUEST_FAILURE'
export const updatePollStatusRequestActionType = 'UPDATE_POLL_STATUS_REQUEST'
export const updatePollStatusRequestSuccessActionType = 'UPDATE_POLL_STATUS_REQUEST_SUCCESS'
export const updatePollStatusRequestFailureActionType = 'UPDATE_POLL_STATUS_REQUEST_FAILURE'
export const getDiscountCodeRequestActionType = 'GET_DISCOUNT_CODE_REQUEST'
export const getDiscountCodeRequestSuccessActionType = 'GET_DISCOUNT_CODE_REQUEST_SUCCESS'
export const getDiscountCodeRequestFailureActionType = 'GET_DISCOUNT_CODE_REQUEST_FAILURE'
export const saveDiscountCodeRequestActionType = 'SAVE_DISCOUNT_CODE_REQUEST'
export const saveDiscountCodeRequestSuccessActionType = 'SAVE_DISCOUNT_CODE_REQUEST_SUCCESS'
export const saveDiscountCodeRequestFailureActionType = 'SAVE_DISCOUNT_CODE_REQUEST_FAILURE'
export const updateDiscountCodeRequestActionType = 'UPDATE_DISCOUNT_CODE_REQUEST'
export const updateDiscountCodeRequestSuccessActionType = 'UPDATE_DISCOUNT_CODE_REQUEST_SUCCESS'
export const updateDiscountCodeRequestFailureActionType = 'UPDATE_DISCOUNT_CODE_REQUEST_FAILURE'
export const deleteDiscountCodesRequestActionType = 'DELETE_DISCOUNT_CODES_REQUEST'
export const deleteDiscountCodesRequestSuccessActionType = 'DELETE_DISCOUNT_CODES_REQUEST_SUCCESS'
export const deleteDiscountCodesRequestFailureActionType = 'DELETE_DISCOUNT_CODES_REQUEST_FAILURE'
export const getSingleDiscountCodeRequestActionType = 'GET_SINGLE_DISCOUNT_CODE_REQUEST'
export const getSingleDiscountCodeRequestSuccessActionType = 'GET_SINGLE_DISCOUNT_CODE_REQUEST_SUCCESS'
export const getSingleDiscountCodeRequestFailureActionType = 'GET_SINGLE_DISCOUNT_CODE_REQUEST_FAILURE'
export const startMediaChannelRequestActionType = 'START_MEDIA_CHANNEL_REQUEST'
export const startMediaChannelRequestSuccessActionType = 'START_MEDIA_CHANNEL_REQUEST_SUCCESS'
export const startMediaChannelRequestFailureActionType = 'START_MEDIA_CHANNEL_REQUEST_FAILURE'
export const startMediaStreamRequestActionType = 'START_MEDIA_STREAM_REQUEST'
export const startMediaStreamRequestSuccessActionType = 'START_MEDIA_STREAM_REQUEST_SUCCESS'
export const startMediaStreamRequestFailureActionType = 'START_MEDIA_STREAM_REQUEST_FAILURE'
export const stopMediaStreamRequestActionType = 'STOP_MEDIA_STREAM_REQUEST'
export const stopMediaStreamRequestSuccessActionType = 'STOP_MEDIA_STREAM_REQUEST_SUCCESS'
export const stopMediaStreamRequestFailureActionType = 'STOP_MEDIA_STREAM_REQUEST_FAILURE'
export const mediaChannelStatusRequestActionType = 'MEDIA_CHANNEL_STATUS_REQUEST'
export const mediaChannelStatusRequestSuccessActionType = 'MEDIA_CHANNEL_STATUS_REQUEST_SUCCESS'
export const mediaChannelStatusRequestFailureActionType = 'MEDIA_CHANNEL_STATUS_REQUEST_FAILURE'
export const updateVideoStatusRequestActionType = 'UPDATE_VIDEO_STATUS_REQUEST'
export const updateVideoStatusRequestSuccessActionType = 'UPDATE_VIDEO_STATUS_REQUEST_SUCCESS'
export const updateVideoStatusRequestFailureActionType = 'UPDATE_VIDEO_STATUS_REQUEST_FAILURE'
export const getVideoStatusRequestActionType = 'GET_VIDEO_STATUS_REQUEST'
export const getVideoStatusRequestSuccessActionType = 'GET_VIDEO_STATUS_REQUEST_SUCCESS'
export const getVideoStatusRequestFailureActionType = 'GET_VIDEO_STATUS_REQUEST_FAILURE'
export const getVideoModulesRequestActionType = 'GET_VIDEO_MODULES_REQUEST'
export const getVideoModulesRequestSuccessActionType = 'GET_VIDEO_MODULES_REQUEST_SUCCESS'
export const getVideoModulesRequestFailureActionType = 'GET_VIDEO_MODULES_REQUEST_FAILURE'
export const startLiveMeetingRequestActionType = 'START_LIVEMEETING_REQUEST'
export const startLiveMeetingRequestSuccessActionType = 'START_LIVEMEETING_REQUEST_SUCCESS'
export const startLiveMeetingRequestFailureActionType = 'START_LIVEMEETING_REQUEST_FAILURE'
export const endLiveMeetingRequestActionType = 'END_LIVEMEETING_REQUEST'
export const endLiveMeetingRequestSuccessActionType = 'END_LIVEMEETING_REQUEST_SUCCESS'
export const endLiveMeetingRequestFailureActionType = 'END_LIVEMEETING_REQUEST_FAILURE'
export const enterLiveStudioRequestActionType = 'ENTER_LIVESTUDIO_REQUEST'
export const enterLiveStudioRequestSuccessActionType = 'ENTER_LIVESTUDIO_REQUEST_SUCCESS'
export const enterLiveStudioRequestFailureActionType = 'ENTER_LIVESTUDIO_REQUEST_FAILURE'
export const getProductAccessRequestActionType = 'GET_PRODUCT_ACCESS_REQUEST'
export const getProductAccessRequestSuccessActionType = 'GET_PRODUCT_ACCESS_REQUEST_SUCCESS'
export const getProductAccessRequestFailureActionType = 'GET_PRODUCT_ACCESS_REQUEST_FAILURE'
export const getVideoAnalyticsTableRequestActionType = 'GET_VIDEO_ANALYTICS_TABLE_REQUEST'
export const getVideoAnalyticsTableRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_TABLE_REQUEST_SUCCESS'
export const getVideoAnalyticsTableRequestFailureActionType = 'GET_VIDEO_ANALYTICS_TABLE_REQUEST_FAILURE'
export const getVideoAnalyticsTableDownloadRequestActionType = 'GET_VIDEO_ANALYTICS_TABLE_DOWNLOAD_REQUEST'
export const getVideoAnalyticsTableDownloadRequestSuccessActionType = 'GET_VIDEO_ANALYTICS_TABLE_DOWNLOAD_REQUEST_SUCCESS'
export const getVideoAnalyticsTableDownloadRequestFailureActionType = 'GET_VIDEO_ANALYTICS_TABLE_DOWNLOAD_REQUEST_FAILURE'
export const removeMediaUrlRequestActionType = 'REMOVE_MEDIA_URL_REQUEST'
export const removeMediaUrlRequestSuccessActionType = 'REMOVE_MEDIA_URL_REQUEST_SUCCESS'
export const removeMediaUrlRequestFailureActionType = 'REMOVE_MEDIA_URL_REQUEST_FAILURE'
export const getMediaUrlRequestActionType = 'GET_MEDIA_URL_REQUEST'
export const getMediaUrlRequestSuccessActionType = 'GET_MEDIA_URL_REQUEST_SUCCESS'
export const getMediaUrlRequestFailureActionType = 'GET_MEDIA_URL_REQUEST_FAILURE'
export const getLiveViewersRequestActionType = 'GET_LIVE_VIEWERS_REQUEST'
export const getLiveViewersRequestSuccessActionType = 'GET_LIVE_VIEWERS_REQUEST_SUCCESS'
export const getLiveViewersRequestFailureActionType = 'GET_LIVE_VIEWERS_REQUEST_FAILURE'
export const getLiveViewersCountRequestActionType = 'GET_LIVE_VIEWERS_COUNT_REQUEST'
export const getLiveViewersCountRequestSuccessActionType = 'GET_LIVE_VIEWERS_COUNT_REQUEST_SUCCESS'
export const getLiveViewersCountRequestFailureActionType = 'GET_LIVE_VIEWERS_COUNT_REQUEST_FAILURE'
export const updateLiveStreamSourceRequestActionType = 'UPDATE_LIVE_STREAM_SOURCE_REQUEST'
export const updateLiveStreamSourceRequestSuccessActionType = 'UPDATE_LIVE_STREAM_SOURCE_REQUEST_SUCCESS'
export const updateLiveStreamSourceRequestFailureActionType = 'UPDATE_LIVE_STREAM_SOURCE_REQUEST_FAILURE'
export const resetEnterLiveStudioRequestActionType = 'RESET_ENTER_LIVESTUDIO_REQUEST'
export const addResourceUploadRequestActionType = 'ADD_RESOURCE_UPLOAD_REQUEST'
export const addResourceUploadRequestSuccessActionType = 'ADD_RESOURCE_UPLOAD_REQUEST_SUCCESS'
export const addResourceUploadRequestFailureActionType = 'ADD_RESOURCE_UPLOAD_REQUEST_FAILURE'
export const getResourceDetailRequestActionType = 'GET_RESOURCE_REQUEST'
export const getResourceDetailRequestSuccessActionType = 'GET_RESOURCE_REQUEST_SUCCESS'
export const getResourceDetailRequestFailureActionType = 'GET_RESOURCE_REQUEST_FAILURE'
export const getArchiveUrlRequestActionType = 'GET_ARCHIVE_URL_REQUEST'
export const getArchiveUrlRequestSuccessActionType = 'GET_ARCHIVE_URL_REQUEST_SUCCESS'
export const getArchiveUrlRequestFailureActionType = 'GET_ARCHIVE_URL_REQUEST_FAILURE'
export const resetResourceUploadActionType = 'RESET_RESOURCE_UPLOAD_REQUEST'