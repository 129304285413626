export const saveChannelRequestActionType = 'SAVE_CHANNEL_REQUEST'
export const saveChannelSuccessActionType = 'SAVE_CHANNEL_REQUEST_SUCCESS'
export const saveChannelFailureActionType = 'SAVE_CHANNEL_REQUEST_FAILURE'
export const getSingleChannelRequestActionType = 'GET_SINGLE_CHANNEL_REQUEST'
export const getSingleChannelSuccessActionType = 'GET_SINGLE_CHANNEL_REQUEST_SUCCESS'
export const getSingleChannelFailureActionType = 'GET_SINGLE_CHANNEL_REQUEST_FAILURE'
export const updateChannelRequestActionType = 'UPDATE_CHANNEL_REQUEST'
export const updateChannelSuccessActionType = 'UPDATE_CHANNEL_REQUEST_SUCCESS'
export const updateChannelFailureActionType = 'UPDATE_CHANNEL_REQUEST_FAILURE'
export const deleteChannelRequestActionType = 'DELETE_CHANNEL_REQUEST'
export const deleteChannelSuccessActionType = 'DELETE_CHANNEL_REQUEST_SUCCESS'
export const deleteChannelFailureActionType = 'DELETE_CHANNEL_REQUEST_FAILURE'
export const getChannelLayoutRequestActionType = 'GET_CHANNEL_LAYOUT_REQUEST'
export const getChannelLayoutSuccessActionType = 'GET_CHANNEL_LAYOUT_REQUEST_SUCCESS'
export const getChannelLayoutFailureActionType = 'GET_CHANNEL_LAYOUT_REQUEST_FAILURE'
export const updateChannelLayoutRequestActionType = 'UPDATE_CHANNEL_LAYOUT_REQUEST'
export const updateChannelLayoutSuccessActionType = 'UPDATE_CHANNEL_LAYOUT_REQUEST_SUCCESS'
export const updateChannelLayoutFailureActionType = 'UPDATE_CHANNEL_LAYOUT_REQUEST_FAILURE'
export const getChannelRequestActionType = 'GET_CHANNEL_REQUEST'
export const getChannelSuccessActionType = 'GET_CHANNEL_REQUEST_SUCCESS'
export const getChannelFailureActionType = 'GET_CHANNEL_REQUEST_FAILURE'
export const getSubChannelRequestActionType = 'GET_SUB_CHANNEL_REQUEST'
export const resetSubChannelRequestActionType = 'RESET_SUB_CHANNEL_REQUEST'
export const setChannelIDRequestActionType = 'SET_CHANNEL_ID_REQUEST'
export const resetChannelStateRequestActionType = 'RESET_CHANNEL_STATE_REQUEST'
export const addNewChannelFlagRequestActionType = 'ADD_NEW_CHANNEL_FLAG_REQUEST'
export const resetAddNewChannelFlagRequestActionType = 'RESET_ADD_NEW_CHANNEL_FLAG_REQUEST'
export const getFlattenedChannel = 'GET_FLATTENED_CHANNEL'
export const saveChannelStylesRequestActionType = 'SAVE_CHANNEL_STYLES_REQUEST'
export const saveChannelStylesRequestSuccessActionType = 'SAVE_CHANNEL_STYLES_REQUEST_SUCCESS'
export const saveChannelStylesRequestFailureActionType = 'SAVE_CHANNEL_STYLES_REQUEST_FAILURE'
export const getChannelStylesRequestActionType = 'GET_CHANNEL_STYLES_REQUEST'
export const getChannelStylesRequestSuccessActionType = 'GET_CHANNEL_STYLES_REQUEST_SUCCESS'
export const getChannelStylesRequestFailureActionType = 'GET_CHANNEL_STYLES_REQUEST_FAILURE'
export const saveChannelHightlightReelRequestActionType = 'SAVE_CHANNEL_HIGHTLIGHT_REEL_REQUEST'
export const saveChannelHightlightReelSuccessActionType = 'SAVE_CHANNEL_HIGHTLIGHT_REEL_REQUEST_SUCCESS'
export const saveChannelHightlightReelFailureActionType = 'SAVE_CHANNEL_HIGHTLIGHT_REEL_REQUEST_FAILURE'
export const getChannelHightlightReelRequestActionType = 'GET_CHANNEL_HIGHTLIGHT_REEL_REQUEST'
export const getChannelHightlightReelSuccessActionType = 'GET_CHANNEL_HIGHTLIGHT_REEL_REQUEST_SUCCESS'
export const getChannelHightlightReelFailureActionType = 'GET_CHANNEL_HIGHTLIGHT_REEL_REQUEST_FAILURE'
export const getChannelListRequestActionType = 'GET_CHANNEL_LIST_REQUEST'
export const getChannelListSuccessActionType = 'GET_CHANNEL_LIST_REQUEST_SUCCESS'
export const getChannelListFailureActionType = 'GET_CHANNEL_LIST_REQUEST_FAILURE'

export const sortedChannelRequestActionType = 'SORTED_CHANNEL_REQUEST'
export const sortedChannelSuccessActionType = 'SORTED_CHANNEL_REQUEST_SUCCESS'
export const sortedChannelFailureActionType = 'SORTED_CHANNEL_REQUEST_FAILURE'
export const addHighlightReelVideoRequestActionType = 'ADD_HIGHLIGHT_REEL_VIDEO_REQUEST'
export const addHighlightReelVideoRequestSuccessActionType = 'ADD_HIGHLIGHT_REEL_VIDEO_REQUEST_SUCCESS'
export const addHighlightReelVideoRequestFailureActionType = 'ADD_HIGHLIGHT_REEL_VIDEO_REQUEST_FAILURE'
export const deleteHighlightReelVideoRequestActionType = 'DELETE_HIGHLIGHT_REEL_VIDEO_REQUEST'
export const deleteHighlightReelVideoRequestSuccessActionType = 'DELETE_HIGHLIGHT_REEL_VIDEO_REQUEST_SUCCESS'
export const deleteHighlightReelVideoRequestFailureActionType = 'DELETE_HIGHLIGHT_REEL_VIDEO_REQUEST_FAILURE'
export const getHighlightReelVideoRequestActionType = 'GET_HIGHLIGHT_REEL_VIDEO_REQUEST'
export const getHighlightReelVideoRequestSuccessActionType = 'GET_HIGHLIGHT_REEL_VIDEO_REQUEST_SUCCESS'
export const getHighlightReelVideoRequestFailureActionType = 'GET_HIGHLIGHT_REEL_VIDEO_REQUEST_FAILURE'