import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import Screen1 from './screen1';
import ScreenHeader from './screenHeader';
import { useParams, Link } from 'react-router-dom';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import Screen2 from './screen2';
import Screen3 from './screen3';
import Screen4 from './screen4';
import RightSidenav from './rightsidenav';
import Loader from 'src/components/loader';
import PollModal from '../components/PollModal';
import LiveEventModal from '../components/LiveEventModal';
import LiveViewerModal from '../components/LiveViewerModal';
import ChatModal from '../components/ChatModal';
import ViewerQuestionModal from '../components/ViewerQuestionModal';
import ToastMessage from 'src/components/ToastContainer';
import EndBroadcastModal from '../components/EndBroadcastModal';
import EndStreamingBroadcastModal from '../components/EndStreamingBroadcastModel';
import { getVideoStatus, getVideoSource } from 'src/components/utilities/utils';
import Screen5 from './screen5';
const LiveEvent: React.FC = () => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const [isLiveLoading, setIsLiveLoading] = useState<boolean>(true);
  const [endBroadcastFlag, setEndBroadcastFlag] = useState<boolean>(false);
  const [endingTime, setEndingTime] = useState<number>(0);
  const [endHlsBroadcastFlag, setEndHlsBroadcastFlag] = useState<boolean>(false);
  const [endStreamingBroadcastFlag, setEndStreamingBroadcastFlag] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<string>();
  const [openPoll, setOpenPoll] = useState<boolean>(false);
  const [cancelClick, setCancelClick] = useState<boolean>(false);
  const [cancelStreamingClick, setCancelStreamingClick] = useState<boolean>(false);
  const [endNowClick, setEndNowClick] = useState<boolean>(false);
  const [endNowStreamingClick, setEndNowStreamingClick] = useState<boolean>(false);
  const [openLiveEvent, setOpenLiveEvent] = useState<boolean>(false);
  const [openLiveViewer, setOpenLiveViewer] = useState<boolean>(false);
  const [openchat, setOpenchat] = useState<boolean>(false);
  const [openQuestion, setOpenQuestion] = useState<boolean>(false);
  const [showHlsBroadcast, setShowHlsBroadcast] = useState<boolean>(false);
  const userData = localStorage.getItem('userData');
  const finalUserData = userData && JSON.parse(userData);
  let defaultDateFormat = localStorage.getItem('defaultDateFormat');
  if(!defaultDateFormat){
    defaultDateFormat = 'DD/MM/YYYY';
  }
  const { id } = useParams<string>();
  const {
    singleVideo,
    isLoading,
    mediaChannelStatus,
    videoStatus,
    liveMeeting,
    isStartLiveMeetingLoading,
    liveStudio,
    mediaChannelInUse,
    liveEventUrl
  } = useSelector((state: RootState) => {
    return {
      singleVideo: state.videos.singleVideo,
      isVideoEdited: state.videos.isVideoEdited,
      isLoading: state.videos.isSingleVideoLoading,
      mediaChannelStatus: state.videos.mediaChannelStatus,
      videoStatus: state.videos.videoStatus,
      liveMeeting: state.videos.liveMeeting,
      isStartLiveMeetingLoading: state.videos.isStartLiveMeetingLoading,
      liveStudio: state.videos.liveStudio,
      mediaChannelInUse: state.videos.mediaChannelInUse,
      liveEventUrl: state.videos.liveEventUrl
    };
  });

  useEffect(() => {
    if (id) videoActions.getSingleVideosRequest(id);
    id && videoActions.getLiveEventUrlRequest(id);
  }, []);

  useEffect(() => {
    if (!displayRemainingTime()) {
      const interval = setInterval(() => {
        displayRemainingTime() && clearInterval(interval);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [singleVideo.displayDate, singleVideo.displayTime]);

  const displayRemainingTime = () => {
    const displayTime = moment(
      `${singleVideo.displayDate} ${singleVideo.displayTime}`,
      {defaultDateFormat}+' HH:mm'
    );
    const currentTime = moment();
    const diffTime = displayTime.diff(currentTime);
    let duration: any = moment.duration(diffTime);
    const interval = 1000;
    duration = moment.duration(duration - interval);
    if (duration.seconds() < 0) {
      setRemainingTime(`00 hrs 00 min 00 sec`);
      return true;
    }
    setRemainingTime(`${duration.hours()} hrs ${duration.minutes()} min ${duration.seconds()} sec`);
  };

  const getScreen = () => {
    if (
      mediaChannelStatus === 'IDLE' &&
      getVideoStatus(videoStatus) === 'Standby' &&
      finalUserData.role != 'Studio Guest'
    ) {
      return <Screen1 />;
    } else if (
      ((mediaChannelStatus === 'STARTING' && getVideoStatus(videoStatus) === 'Standby') ||
        (mediaChannelStatus === 'RUNNING' && getVideoStatus(videoStatus) === 'Standby')) &&
      getVideoSource(singleVideo.videoSourceId) !== 'StreamingSoftware'
    ) {
      return <Screen2 isLiveLoading={isLiveLoading} setIsLiveLoading={setIsLiveLoading} />;
    } else if (
      (mediaChannelStatus === 'RUNNING' || mediaChannelStatus === 'STARTING') &&
      getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware'
    ) {
      return (
        <Screen3
          endBroadcastFlag={endBroadcastFlag}
          endingTime={endingTime}
          cancelStreamingClick={cancelStreamingClick}
        />
      );
    } else if (getVideoStatus(videoStatus) == 'Stopped') {
      return <Screen4 />;
    } else if (
      getVideoStatus(videoStatus) == 'Processing' &&
      !singleVideo?.useLowLatencyLiveMeeting &&
      getVideoSource(singleVideo.videoSourceId) === 'Webcam'
    ) {
      return <Screen4 />;
    }
  };

  useEffect(() => {
    if (singleVideo && getVideoSource(singleVideo.videoSourceId) === 'Webcam') {
      const userData = localStorage.getItem('userData');
      const finalData = userData && JSON.parse(userData);
      const win: any = window;
      if (finalData) {
        win.ChatUser = {
          id: finalData.UserId,
          userId: finalData.UserId,
          name: `${finalData.firstName} ${finalData.lastName} `,
          profileUrl: '',
          chatId: id,
          isModerator: false,
          displayUserConnection: false,
          domainId: `${finalData.domainId}`,
          chatEnabled: true,
          pollEnabled: false,
          videoTitle: singleVideo.title,
          token: localStorage.getItem('token'),
          role: finalData.role
        };
      }
      window.addEventListener('message', function (e) {
        let liveFrameObj: any = document.querySelector('.greenRoomContent iframe');
        liveFrameObj = liveFrameObj?.contentWindow;
        let jsonParse;
        // Check if e.data is a string before parsing
        if (typeof e.data === 'string') {
          jsonParse = JSON.parse(e.data);
        } else {
          jsonParse = e.data; // If already an object, use it directly
        }
        if (jsonParse.action == 'getuserdata') {
          if (jsonParse.from === 'livemeet') {
            liveFrameObj.postMessage(
              JSON.stringify({ action: 'userdata', ChatUser: win.ChatUser, from: 'livemeet' }),
              '*'
            );
          }
        }
      });
    }
  }, [singleVideo]);

  const handleEnterHlsBroadcast = () => {
    handleEnterLiveStudioClick();
    sessionStorage.removeItem('broadcastStarted');
  };
  const handleEnterLiveStudioClick = () => {
    id && videoActions.enterLiveStudioRequest({ videoId: id });
  };

  useEffect(() => {
    if (getVideoStatus(videoStatus) === 'Live' && singleVideo.useLowLatencyLiveMeeting) {
      id && videoActions.enterLiveStudioRequest({ videoId: id });
    }
  }, [singleVideo]);

  return (
    <>
      <ToastMessage />
      <div className="box-wrap flex flex-col">
        <ScreenHeader
          isLiveLoading={isLiveLoading}
          setEndBroadcastFlag={setEndBroadcastFlag}
          setEndingTime={setEndingTime}
          setShowHlsBroadcast={setShowHlsBroadcast}
          showHlsBroadcast={showHlsBroadcast}
          setEndHlsBroadcastFlag={setEndHlsBroadcastFlag}
          endHlsBroadcastFlag={endHlsBroadcastFlag}
          cancelClick={cancelClick}
          setCancelClick={setCancelClick}
          endNowClick={endNowClick}
          setEndNowClick={setEndNowClick}
          setEndStreamingBroadcastFlag={setEndStreamingBroadcastFlag}
          endStreamingBroadcastFlag={endStreamingBroadcastFlag}
          endNowStreamingClick={endNowStreamingClick}
          setEndNowStreamingClick={setEndNowStreamingClick}
          cancelStreamingClick={cancelStreamingClick}
          setCancelStreamingClick={setCancelStreamingClick}
        />
        {
          // mediaChannelInUse ? (
          //   <div className="flex custom-height-wrapper">
          //     <div className="flex flex-col gap-2 live-event-main flex-1">
          //       <div className="flex items-center flex-col">
          //         <h1 className="text-3xl font-semibold text-red-600 mt-2 mb-3">
          //           The Live Stream channel is already {mediaChannelStatus} so may be in use for
          //           another live stream If you continue, you may interrupt another live stream.
          //         </h1>
          //         <h2 id="timeDiffDispl" className="text-2xl font-bold text-black text-re">
          //           Are you sure you want to continue?
          //         </h2>
          //         <div className="flex mt-3">
          //           <Link to={`/videos/${id}`} className="btn start-live-stream">
          //             Go Back
          //           </Link>
          //           <a
          //             className="btn start-live-stream pull-right green-background ml-2"
          //             onClick={handleEnterHlsBroadcast}>
          //             Yes Continue<span id="count" className="d-none"></span>
          //           </a>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // ) : (
          <div
            className={`flex ${
              liveEventUrl && liveEventUrl.secondaryLiveMediaUrl !== null
                ? ''
                : 'custom-height-wrapper'
            }`}>
            {(getVideoStatus(videoStatus) === 'Standby' ||
              getVideoStatus(videoStatus) === 'OnDemand') &&
              (!liveStudio.status ||
                liveStudio.status === undefined ||
                getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware') &&
              !showHlsBroadcast && (
                <div className="flex flex-col gap-2 live-event-main flex-1">
                  {getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' &&
                  mediaChannelStatus === 'RUNNING' ? (
                    <></>
                  ) : (
                    <>
                      {finalUserData?.role != 'Studio Guest' ? (
                    <div className="flex items-center flex-col">
                      <h1 className="text-3xl font-semibold text-black mt-2 mb-3">
                        Your Live Event Starts In
                      </h1>
                      <h2 id="timeDiffDispl" className="text-2xl font-bold text-black">
                        {isLoading ? <Loader /> : remainingTime}
                      </h2>
                    </div>
                      ) : (
                        <h1 className="font-bold text-xl">Waiting for Host to Start the Meeting</h1>
                      )}
                    </>
                  )}

                  {getScreen()}
                </div>
              )}
            {(getVideoStatus(videoStatus) === 'Stopped' ||
              (getVideoStatus(videoStatus) === 'Processing' &&
                getVideoSource(singleVideo.videoSourceId) === 'Webcam') ||
              (getVideoStatus(videoStatus) === 'Live' &&
                getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware')) &&
              !singleVideo?.useLowLatencyLiveMeeting && (
                <div className={`flex flex-col gap-2 live-event-main-h flex-1`}>{getScreen()}</div>
              )}
            {getVideoStatus(videoStatus) === 'Processing' && singleVideo.useLowLatencyLiveMeeting && (
              <div className="flex flex-col gap-2 live-event-main-h  flex-1">
                <Screen4 />
              </div>
            )}
            {getVideoStatus(videoStatus) === 'Stopped' &&
              singleVideo?.useLowLatencyLiveMeeting &&
              getVideoSource(singleVideo.videoSourceId) === 'Webcam' && (
                <div className="flex flex-col gap-2 live-event-main-h  flex-1">
                  <Screen5 />
                </div>
              )}
            {getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              singleVideo.useLowLatencyLiveMeeting &&
              // getVideoStatus(videoStatus) !== 'Live' &&
              getVideoStatus(videoStatus) !== 'Stopped' &&
              getVideoStatus(videoStatus) !== 'Processing' &&
              liveStudio.status && (
                <div className="flex flex-col gap-2 live-event-main-h  flex-1">
                  <div className="feature-div h-screen relative greenRoomContent">
                    <iframe
                      id="greenroomFrame"
                      src={`${liveStudio.url}/${
                        singleVideo?.useLowLatencyLiveMeeting ? 'livemeeting' : 'broadcast'
                      }`}
                      allow="camera;microphone;fullscreen; display-capture;"
                      className="h-full w-full d-block"></iframe>
                    {getVideoStatus(videoStatus) === 'Live' ? (
                      <div className="live-button"> Live</div>
                    ) : (
                      <div className="green-room-button"> Green Room</div>
                    )}
                  </div>
                </div>
              )}
            {getVideoSource(singleVideo.videoSourceId) === 'Webcam' &&
              getVideoStatus(videoStatus) !== 'Stopped' &&
              !singleVideo.useLowLatencyLiveMeeting &&
              mediaChannelStatus === 'RUNNING' &&
              (liveStudio.status || getVideoStatus(videoStatus) === 'Live') && (
                <div className="flex flex-col gap-2 live-event-main-h  flex-1">
                  <div className="feature-div h-screen relative greenRoomContent">
                    <iframe
                      id="greenroomFrame"
                      src={`${process.env.REACT_APP_LIVE_MEET}/#/WaitingRoom/${singleVideo.id}/${
                        finalUserData.firstName
                      } ${finalUserData.lastName}/${finalUserData.UserId}/host/${
                        singleVideo?.useLowLatencyLiveMeeting ? 'livemeeting' : 'broadcast'
                      }`}
                      allow="camera;microphone;fullscreen; display-capture;"
                      className="h-full w-full d-block"></iframe>
                    {getVideoStatus(videoStatus) === 'GreenRoom' && (
                      <div className="green-room-button"> Green Room</div>
                    )}
                  </div>
                </div>
              )}
            {/* {isStartLiveMeetingLoading ? (
              <Loader />
            ) : (
              <>
                {getVideoStatus(videoStatus) === 'Live' && singleVideo?.useLowLatencyLiveMeeting && (
                  <div className="flex flex-col gap-2 live-event-main-h  flex-1">
                    <div className="feature-div h-screen relative roomContent">
                      <iframe
                        id="liveFrame"
                        src={liveMeeting.url}
                        allow="camera;microphone;fullscreen; display-capture;"
                        className="h-full w-full d-block"></iframe>
                      <div className="live-button"> Live</div>
                    </div>
                  </div>
                )}
              </>
            )} */}

            <PollModal openPoll={openPoll} />
            <LiveEventModal openLiveEvent={openLiveEvent} />
            <LiveViewerModal openLiveViewer={openLiveViewer} />
            <ChatModal openchat={openchat} />
            <ViewerQuestionModal openQuestion={openQuestion} />
            <RightSidenav
              setOpenPoll={setOpenPoll}
              openPoll={openPoll}
              setOpenLiveEvent={setOpenLiveEvent}
              openLiveEvent={openLiveEvent}
              setOpenLiveViewer={setOpenLiveViewer}
              openLiveViewer={openLiveViewer}
              setOpenchat={setOpenchat}
              openchat={openchat}
              setOpenQuestion={setOpenQuestion}
              openQuestion={openQuestion}
            />
            <EndBroadcastModal
              endingTime={endingTime}
              setEndHlsBroadcastFlag={setEndHlsBroadcastFlag}
              endHlsBroadcastFlag={endHlsBroadcastFlag}
              setCancelClick={setCancelClick}
              setEndNowClick={setEndNowClick}
            />
            <EndStreamingBroadcastModal
              endingTime={endingTime}
              setEndStreamingBroadcastFlag={setEndStreamingBroadcastFlag}
              endStreamingBroadcastFlag={endStreamingBroadcastFlag}
              setCancelStreamingClick={setCancelStreamingClick}
              setEndNowStreamingClick={setEndNowStreamingClick}
            />
          </div>
          // )
        }
      </div>
    </>
  );
};
export default LiveEvent;
