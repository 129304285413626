import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { MemberModel, UsersModel } from '../../../../models/usersModel';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
interface IProps {
  userDetails: UsersModel | undefined;
  modalFlag: string;
}
const Modal: React.FC<IProps> = ({ userDetails, modalFlag }) => {
  const dispatch = useDispatch();
  const btnRef = useRef<HTMLButtonElement>(null);
  const userAction = useUserActions(dispatch);
  const [heading, setHeading] = useState('Save');

  const { isUserAdded, isUserUpdated, roles } = useSelector((state: RootState) => {
    return {
      isUserAdded: state.users.isUserAdded,
      isUserUpdated: state.users.isUserUpdated,
      roles: state.users.roles
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm<UsersModel>();

  const [value, setValue] = useState('');

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (modalFlag === 'edit') {
      reset(userDetails);
      setHeading('Edit');
    } else {
      reset({
        userId: '',
        userName: '',
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        created: '',
        lastLogin: '',
        role: '',
        phoneNumber: '',
        active: true,
        passwordHash: ''
      });
      setHeading('Save');
    }
  }, [userDetails, modalFlag]);
  const onSubmit = (data: UsersModel) => {
    // userAction.checkUserNameAvailableRequest(data.firstName);
    const userData = { ...data };
    delete userData.created;
    if (modalFlag === 'add') {
      delete userData.lastLogin;
      userAction.addUserRequest({ ...userData, role: value });
    } else {
      userAction.UpdateUserRequest({ ...userData, role: value });
    }
  };

  useEffect(() => {
    userAction.getUserRoleRequest();
    userAction.getStatusRequest();
  }, []);

  useEffect(() => {
    if (isUserAdded || isUserUpdated) {
      if (btnRef.current) {
        btnRef.current.click();
      }
      userAction.getUserRequest();
      userAction.resetUserRequest();
      reset({
        userId: '',
        userName: '',
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        created: '',
        lastLogin: '',
        role: '',
        phoneNumber: '',
        active: true,
        passwordHash: ''
      });
    }
  }, [isUserAdded, isUserUpdated]);

  const handleCancleClick = () => {
    userAction.resetUserDataStateRequest();
    reset({
      userId: '',
      userName: '',
      email: '',
      firstName: '',
      lastName: '',
      name: '',
      created: '',
      lastLogin: '',
      role: '',
      phoneNumber: '',
      active: false,
      passwordHash: ''
    });
  };

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editMemberModal"
      aria-labelledby="editMemberModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editMemberModalLabel">
              {`${heading} User Information`}
            </h5>
            <button
              ref={btnRef}
              onClick={handleCancleClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`form-group mb-4`}>
                <label className="block mb-1"> Email </label>
                <input
                  type="text"
                  className="input-style"
                  id="emailAddress"
                  {...register('email', {
                    required: true,
                    maxLength: 80,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                  })}
                />
                {errors.email?.type === 'required' && <p>Required Field</p>}
                {errors.email?.type === 'maxLength' && <p>Max. length reached</p>}
                {errors.email?.type === 'pattern' && <p>Only email are allowed</p>}
              </div>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1"> First Name </label>
                  <input
                    type="text"
                    className="input-style"
                    id="firstName"
                    {...register('firstName', {
                      required: true,
                      maxLength: 80,
                      pattern: /^[A-Za-z]/
                    })}
                  />
                  {errors.firstName?.type === 'required' && <p>Required Field</p>}
                  {errors.firstName?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.firstName?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>
                <div className={`form-group`}>
                  <label className="block mb-1"> Last Name </label>
                  <input
                    type="text"
                    className="input-style"
                    id="lastName"
                    {...register('lastName', {
                      required: true,
                      maxLength: 80,
                      pattern: /^[A-Za-z]/
                    })}
                  />
                  {errors.lastName?.type === 'required' && <p>Required Field</p>}
                  {errors.lastName?.type === 'maxLength' && <p>Max. length reached</p>}
                  {errors.lastName?.type === 'pattern' && <p>Only alphabets are allowed</p>}
                </div>
              </div>
              <div className={`form-group`}>
                <label className="block mb-1"> Phone Number </label>
                <input
                  type="text"
                  className="input-style"
                  id="phoneNumber"
                  {...register('phoneNumber', {
                    maxLength: 12
                    // pattern: /^[0-9]{12}$/
                  })}
                />
                {errors.phoneNumber?.type === 'maxLength' && <p>Max. length reached</p>}
                {/* {errors.phoneNumber?.type === 'pattern' && <p>Only Numeric values are allowed</p>} */}
              </div>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <div className={`form-group`}>
                    <label className="block mb-1"> Role </label>
                    <select className="input-style" onChange={handleChange}>
                      <option>Select Role</option>
                      {roles &&
                        roles.map((role) => {
                          return modalFlag === 'add' ? (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ) : (
                            <option
                              key={role.id}
                              value={role.id}
                              selected={role.name === userDetails?.role ? true : false}>
                              {role.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              {modalFlag === 'add' && (
                <div>
                  <div className={`form-group`}>
                    <label className="block mb-1"> Password </label>
                    <input
                      type="password"
                      className="input-style"
                      id="passwordHash"
                      {...register('passwordHash', {
                        required: true,
                        maxLength: 80
                      })}
                    />
                    {errors.passwordHash?.type === 'required' && <p>Required Field</p>}
                    {errors.passwordHash?.type === 'maxLength' && <p>Max. length reached</p>}
                  </div>
                  <div className={`form-group`}>
                    <label className="block mb-1"> Confirm Password </label>
                    <input
                      type="password"
                      className="input-style"
                      id="confirmPasswordHash"
                      {...register('confirmPasswordHash', {
                        required: true,
                        maxLength: 80,
                        validate: (value) => {
                          if (watch('passwordHash') !== value) {
                            return "Passwords don't match";
                          }
                        }
                      })}
                    />

                    {errors.confirmPasswordHash?.type === 'validate' && (
                      <p>Password does not match</p>
                    )}
                    {errors.confirmPasswordHash?.type === 'required' && <p>Required Field</p>}
                    {errors.confirmPasswordHash?.type === 'maxLength' && <p>Max. length reached</p>}
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="active"
                    {...register('active')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
                <label
                  className="form-check-label inline-block font-semibold ml-2"
                  htmlFor="Credit">
                  Status
                </label>
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                  Submit
                </button>
                <button
                  onClick={handleCancleClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
