import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg } from 'src/components/utilities/utils';
const Integrations = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const { integration, isIntegrationProviderUpdated, message, isIntegrationLoading, error } =
    useSelector((state: RootState) => {
      return {
        integration: state.setting.integration,
        isIntegrationProviderUpdated: state.setting.isIntegrationProviderUpdated,
        message: state.setting.message,
        isIntegrationLoading: state.setting.isIntegrationLoading,
        error: state.setting.error
      };
    });
  useEffect(() => {
    settingAction.getIntegrationRequest();
  }, []);

  const handleUpdateIntegration = (data: boolean, id: any) => {
    const payloadData = { active: data, id };
    settingAction.enableIntegrationProviderRequest(payloadData);
  };

  useEffect(() => {
    if (isIntegrationProviderUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationProviderUpdated]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  const getActiveFlag = (integrations: any) => {
    const flag =
      integration &&
      integration.filter((item) => {
        if (item.providerName === integrations) {
          return item;
        }
      });
    return flag[0]?.active;
  };

  const getIntegrationId = (integrations: any) => {
    const flag =
      integration &&
      integration.filter((item) => {
        if (item.providerName === integrations) {
          return item;
        }
      });
    return flag[0]?.id;
  };
  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integrations</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Settings</Link>
          </li>
          <li>
            <strong>Integrations</strong>
          </li>
        </ol>
      </div>
      <div>
        <h3 className="text-base font-bold">Choose Integration Provider</h3>
      </div>

      <div className="mt-5">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-5">
          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/iMISIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">iMIS</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/iMISIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('iMIS')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('iMIS'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/PersonifyIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Personify</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/PersonifyIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Personify')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Personify'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/NaylorIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Naylor</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/NaylorIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Naylor')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Naylor'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/SpargoIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Spargo</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/SpargoIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Spargo')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Spargo'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/MembesIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Membes</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/MembesIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Membes')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Membes'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/ZoomIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Zoom</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/ZoomIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Zoom')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Zoom'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/google.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Google</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/google`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Google')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Google'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/WebCourseWorksIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Web Courseworks</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/WebCourseWorksIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Web Courseworks')}
                          onChange={(e) =>
                            handleUpdateIntegration(
                              e.target.checked,
                              getIntegrationId('Web Courseworks')
                            )
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/MemberEvolutionIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Member Evolution</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/MemberEvolutionIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Member Evolution')}
                          onChange={(e) =>
                            handleUpdateIntegration(
                              e.target.checked,
                              getIntegrationId('Member Evolution')
                            )
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/WicketIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Wicket</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/WicketIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Wicket')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Wicket'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/Dynamics365Integration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Dynamics 365</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/Dynamics365Integration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Dynamics 365')}
                          onChange={(e) =>
                            handleUpdateIntegration(
                              e.target.checked,
                              getIntegrationId('Dynamics 365')
                            )
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/MicrosoftAzureIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Microsoft Azure</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/MicrosoftAzureIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Microsoft Azure')}
                          onChange={(e) =>
                            handleUpdateIntegration(
                              e.target.checked,
                              getIntegrationId('Microsoft Azure')
                            )
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/NoviAMSIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Novi AMS</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/NoviAMSIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('Novi AMS')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('Novi AMS'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/OAuth2Integration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">OAuth 2</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/OAuth2Integration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('OAuth2')}
                          onChange={(e) =>
                            handleUpdateIntegration(e.target.checked, getIntegrationId('OAuth2'))
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-card flex-items justify-center">
            <div className=" w-full p-4">
              <div className="flex justify-center w-full">
                <img src={`../../assets/img/MemberClicksIntegration.png`} />
              </div>

              <h3 className="text-center text-base font-bold">Member Clicks</h3>
              <div className="flex justify-between align-item-center mt-14">
                <Link to={`/MemberClicksIntegration`}>
                  <h2 className="font-bold cursor-pointer">
                    <i className="fa fa-edit text-purple-500"></i>
                  </h2>
                </Link>
                {isIntegrationLoading ? (
                  <Loader />
                ) : (
                  <div className="flex items-center">
                    <div className="form-check form-switch pl-0 flex-items mb-2">
                      <div className="relative">
                        <input
                          className="form-check-input switch-btn"
                          type="checkbox"
                          defaultChecked={getActiveFlag('MemberClicks')}
                          onChange={(e) =>
                            handleUpdateIntegration(
                              e.target.checked,
                              getIntegrationId('MemberClicks')
                            )
                          }
                        />
                        <i className="fas fa-check checkbox-tick hidden"></i>
                        <i className="fas fa-times checkbox-cross"></i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Integrations;
