import React from 'react';

const TotalMember = ({ totalMembers }: any) => {
  return (
    <div className="mt-5">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
        {totalMembers &&
          totalMembers.map((item: any, index: number) => {
            return (
              <div key={item.id} className="dashboard-card flex-items justify-center">
                <div className="flex-items w-full p-4">
                  <div className="flex flex-col">
                    <span className="text-lg font-semibold mb-2">{item.total}</span>
                    <span>{item.name}</span>
                  </div>
                  <div>
                    <div className={`bg-light-${index + 1} rounded-full`}>
                      <div className="w-12 h-12 flex items-center justify-center">
                        <img src={`../assets/img/icons/total-m-${item.id}.svg`}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TotalMember;
