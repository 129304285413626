import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useParams } from 'react-router-dom';
import { useCourseActions } from 'src/store/course/actions';
import AddNewCourseModal from './AddNewCourseModal';
import ChapterList from './ChaptersList';
import CourseSequence from './CourseSequence';
import CheckpointsList from './CheckpointsList';
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
  SortableHandle
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { useNavigate } from 'react-router-dom';
interface ISortableHandleElement {
  children: React.ReactNode;
  className?: string;
}

interface ISortableItem extends SortableElementProps {
  children: React.ReactNode;
  className?: string;
}

interface ISortableContainer extends SortableContainerProps {
  children: React.ReactNode;
  className?: string;
}

const DndTrigger: React.ComponentClass<ISortableHandleElement, any> = SortableHandle(
  ({ children, className }: { children: React.ReactNode; className: string }) => (
    <div className={className || ''}>{children}</div>
  )
);

const DndItem: React.ComponentClass<ISortableItem, any> = SortableElement(
  ({ children, className }: { children: React.ReactNode; className: string }) => (
    <div className={className || ''}>{children}</div>
  )
);

const DndList: React.ComponentClass<ISortableContainer, any> = SortableContainer(
  ({ children, className }: { children: React.ReactNode; className: string }) => {
    return <div className={className || ''}>{children}</div>;
  }
);

const Content = ({ newChannelId, type }: any) => {
  const dispatch = useDispatch();
  const courseActions = useCourseActions(dispatch);
  const { id } = useParams<string>();
  const [videoData, setVideoData] = useState<any>();
  const [moveFlag, setMoveFlag] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('content');
  const navigate = useNavigate();
  // State to handle collapse for each chapter
  const [collapsedChapters, setCollapsedChapters] = useState<any>({});

  // Toggle collapse
  const toggleChapter = (chapter: any) => {
    setCollapsedChapters((prevState: any) => ({
      ...prevState,
      [chapter]: !prevState[chapter]
    }));
  };
  const { courseVideos, isCourseVideoSequenceUpdated, singleCourse } = useSelector(
    (state: RootState) => {
      return {
        courseVideos: state.course.courseVideos,
        isCourseVideoSequenceUpdated: state.course.isCourseVideoSequenceUpdated,
        singleCourse: state.course.singleCourse
      };
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
    setVideoData(arrayMoveImmutable(videoData, oldIndex, newIndex));
    setMoveFlag(true);
  };
  useEffect(() => {
    setVideoData('');
  }, []);
  useEffect(() => {
    if (courseVideos) {
      setVideoData(courseVideos);
    }
  }, [courseVideos]);

  useEffect(() => {
    if (moveFlag) {
      if (id) {
        const videoIdArray = videoData.map((item: any) => {
          return item.id;
        });
        const payloadData = {
          id,
          videoIds: videoIdArray
        };
        courseActions.saveCourseVideoSequenceRequest(payloadData);
        setMoveFlag(false);
      }
    }
  }, [moveFlag]);

  useEffect(() => {
    if (isCourseVideoSequenceUpdated) {
      courseActions.getCourseVideosRequest(id);
      courseActions.resetCourseStateRequest();
    }
  }, [isCourseVideoSequenceUpdated]);

  useEffect(() => {
    if (id) {
      courseActions.getSingleCourseRequest(id);
    }
  }, [id]);

  const handleEditVideoClick = (videoId: string) => {
    window.open(`/videos/${videoId}`, '_blank');
  };
  return (
    <>
      <>
        {singleCourse.type == 2 || type == 2 ? (
          <>
            <>
              <div className="flex">
                <button
                  type="button"
                  className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mr-0"
                  data-bs-toggle="modal"
                  data-bs-target="#addNewCourseModal"
                  onClick={() => setTab('content')}>
                  Add New
                </button>
              </div>
              {tab == 'content' && (
                // <div className="flex flex-col md:flex-row h-screen bg-gray-100">
                //   {/* Left Section */}
                //   <div className="w-full md:w-1/3 bg-white p-6 shadow-md overflow-auto">
                //     {/* <h2 className="text-2xl font-bold mb-6">Chapters</h2> */}
                //     <div className="space-y-4">
                //       {/* Chapter 1 */}
                //       {videoData &&
                //         videoData.map((item: any) => (
                //           <div key={item.id} className="border border-gray-300 rounded-lg p-4">
                //             <button
                //               className="w-full text-left font-semibold text-gray-700 flex justify-between items-center"
                //               onClick={() => toggleChapter('chapter1')}>
                //               {item.title}
                //               <span>{collapsedChapters['chapter1'] ? '▲' : '▼'}</span>
                //             </button>
                //           </div>
                //         ))}
                //     </div>
                //   </div>
                // </div>
                <CourseSequence />
              )}
              {/* {tab == 'chapter' && <ChapterList />}
              {tab == 'checkpoint' && <CheckpointsList />} */}
            </>
          </>
        ) : (
          <>
            {videoData && videoData.length ? (
              <>
                <DndList
                  lockAxis="y"
                  useWindowAsScrollContainer={true}
                  useDragHandle
                  onSortEnd={onSortEnd}
                  distance={1}
                  className="itemsContainer">
                  {videoData &&
                    videoData.map((item: any, index: number) => (
                      <DndItem key={`item-${index}`} index={index} className="item">
                        <DndTrigger className="itemTrigger">
                          <div className="dashboard-card flex-items justify-center mt-2">
                            <div className=" w-full p-4 flex-col">
                              <div
                                className="flex justify-between"
                                onClick={() => handleEditVideoClick(item.id)}>
                                <div>
                                  <span>
                                    <i className="fa fa-square-o"></i>
                                  </span>
                                  <span className="text-sm font-semibold ml-2">{item.title}</span>
                                </div>
                                <span className="cursor-pointer">
                                  <i className="fas fa-edit"></i>
                                </span>
                              </div>
                              <div className="flex">
                                {item.videoContentType == 0 ? (
                                  <div className="flex ml-5 mt-4">
                                    <span>
                                      <i className="fa fa-camera"></i>
                                    </span>
                                    <span className="text-sm  ml-2">Video</span>
                                  </div>
                                ) : (
                                  <div className="flex ml-5 mt-4">
                                    <span>
                                      <i className="fa fa-file-text-o"></i>
                                    </span>
                                    <span className="text-sm  ml-2">Resource</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </DndTrigger>
                      </DndItem>
                    ))}
                </DndList>
              </>
            ) : (
              <div>No Video Found</div>
            )}
          </>
        )}
      </>

      <AddNewCourseModal newChannelId={newChannelId} singleCourse={singleCourse} />
    </>
  );
};

export default Content;
