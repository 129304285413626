import { Actions, LoginErrorModel, LoginStateModel } from 'src/models/loginModel';
import { RootState } from '../reducers/state';
import { LoginActions } from './actions';
import * as Constants from './constants';
import { removeItem } from 'src/components/utilities/utils';
const initialState: RootState.LoginModelState = {
    isLoggedIn: false,
    loading: false,
    success: false,
    domainList: [],
    error: {} as LoginErrorModel,
    isCallbackLink: false,
    isResetSuccess: false,
    resetPasswordObj: {},
    defaultDateFormat: {},
};

function loginReducer(state: LoginStateModel = initialState, action: Actions): LoginStateModel {
    switch (action.type) {
        case Constants.SendLoginRequestActionType: return sendLoginAction(state);
        case Constants.SendLoginRequestSuccessActionType: return sendLoginSuccessAction(state, action);
        case Constants.SendLoginRequestFailureActionType: return sendLoginFailureAction(state, action);

        case Constants.SendSSOLoginRequestActionType: return sendSSOLoginAction(state);
        case Constants.SendSSOLoginRequestSuccessActionType: return sendSSOLoginSuccessAction(state, action);
        case Constants.SendSSOLoginRequestFailureActionType: return sendSSOLoginFailureAction(state, action);

        case Constants.SendLogoutRequestActionType: return sendLogoutAction();
        case Constants.ResetLoginStateRequestActionType: return resetLoginStateAction(state);
        case Constants.SetCallbackLinkActionType: return setCallbackLinkAction(state);
        case Constants.RemoveCallbackLinkActionType: return removeCallbackLinkAction(state);

        case Constants.GetDomainListRequestActionType: return getDomainListRequestAction(state)
        case Constants.GetDomainListRequestSuccessActionType: return getDomainListRequestSuccessAction(state, action);
        case Constants.GetDomainListRequestFailureActionType: return getDomainListRequestFailureAction(state)

        case Constants.forgetRestPasswordRequestActionType: return forgetRestPasswordAction(state);
        case Constants.forgetRestPasswordRequestSuccessActionType: return forgetRestPasswordSuccessAction(state, action);
        case Constants.forgetRestPasswordRequestFailureActionType: return forgetRestPasswordFailureAction(state);

        case Constants.resetPasswordRequestActionType: return resetPasswordAction(state);
        case Constants.resetPasswordRequestSuccessActionType: return resetPasswordSuccessAction(state, action);
        case Constants.resetPasswordRequestFailureActionType: return resetPasswordFailureAction(state);

        case Constants.getDefaultdateFormatRequestActionType: return getDefaultdateFormatAction(state);
        case Constants.getDefaultdateFormatRequestSuccessActionType: return getDefaultdateFormatSuccessAction(state, action);
        case Constants.getDefaultdateFormatRequestFailureActionType: return getDefaultdateFormatFailureAction(state);

        case Constants.reset: return reset(state);
        default:
            return state;
    }
    // Domain List
    function getDomainListRequestAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            loading: true
        };
    }
    function getDomainListRequestSuccessAction(state: LoginStateModel, action: LoginActions.getDomainListRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                domainList: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getDomainListRequestFailureAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }

    // Log In
    function sendLoginAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isLoggedIn: false,
            loading: true,
            success: false
        };
    }

    function sendLoginSuccessAction(state: LoginStateModel, action: LoginActions.sendLoginRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                isLoggedIn: true,
                loading: false
            };
        }
        return state;
    }

    function sendLoginFailureAction(state: LoginStateModel, action: LoginActions.sendLoginRequestFailureAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoggedIn: false,
                success: false
            };
        }
        return state;
    }

    // SSO Log In
    function sendSSOLoginAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isLoggedIn: false,
            loading: true,
            success: false
        };
    }

    function sendSSOLoginSuccessAction(state: LoginStateModel, action: LoginActions.sendSSOLoginRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                isLoggedIn: true,
                loading: false
            };
        }
        return state;
    }

    function sendSSOLoginFailureAction(state: LoginStateModel, action: LoginActions.sendSSOLoginRequestFailureAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoggedIn: false,
                success: false
            };
        }
        return state;
    }

    // setting callback link
    function setCallbackLinkAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isCallbackLink: true
        };
    }

    // removing callback link
    function removeCallbackLinkAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isCallbackLink: false
        };
    }

    // Log Out
    function sendLogoutAction(): LoginStateModel {
        removeItem('token');
        removeItem('refreshToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('defaultDateFormat');
        return initialState;
    }


    function resetLoginStateAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isLoggedIn: false
        };
    }

    function forgetRestPasswordAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function forgetRestPasswordSuccessAction(state: LoginStateModel, action: LoginActions.forgetRestPasswordRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                resetPasswordObj: action.payload,
                isResetSuccess: true,
                loading: false
            };
        }
        return state;
    }
    function forgetRestPasswordFailureAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            resetPasswordObj: { error: 'error' },
            isResetSuccess: false,
            loading: false,
        }
    }

    function resetPasswordAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function resetPasswordSuccessAction(state: LoginStateModel, action: LoginActions.resetPasswordRequestSuccessAction): LoginStateModel {
        return {
            ...state,
            resetPasswordObj: action.payload,
            isResetSuccess: true,
            loading: false
        };
    }
    function resetPasswordFailureAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            resetPasswordObj: { error: 'error' },
            isResetSuccess: false,
            loading: false,
        }
    }

    function getDefaultdateFormatAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getDefaultdateFormatSuccessAction(state: LoginStateModel, action: LoginActions.getDefaultdateFormatRequestSuccessAction): LoginStateModel {
        if (action.payload) { 
            return {
                ...state,
                defaultDateFormat: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getDefaultdateFormatFailureAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function reset(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            resetPasswordObj: {},
            isResetSuccess: false,
        }
    }
}

export default loginReducer;
