import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Pagination from 'src/components/common/pagination';
import { useNavigate, Link } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useForm, Controller } from 'react-hook-form';
import { SelectModel } from 'src/models/videosModel';
import { useChannelActions } from 'src/store/channel/actions';
import { customFilterOption } from 'src/components/utilities/utils';
import {
  defaultDateFormatter,
  getBadgeBackgroundColor,
  getPollTypeName
} from 'src/components/utilities/utils';
import Select from 'react-select';
const AnalyticPolls: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState<any>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm();
  const {
    paginationMeta,
    pollAnalytics,
    pollQuestion,
    pollQuestionPaginationMeta,
    isPollAnalyticLoading,
    isPollQuestionLoading,
    channelList,
    pollWidget
  } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.analytics.paginationMeta,
      pollAnalytics: state.analytics.pollAnalytics,
      pollQuestion: state.analytics.pollQuestion,
      pollQuestionPaginationMeta: state.analytics.pollQuestionPaginationMeta,
      isPollAnalyticLoading: state.analytics.isPollAnalyticLoading,
      isPollQuestionLoading: state.analytics.isPollQuestionLoading,
      channelList: state.channel.channelList,
      pollWidget: state.analytics.pollWidget
    };
  });
  const handleFilterClick = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };
  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText,
        name: item.name
      }));
      setFinalChannels(options);
    }
  }, [channelList]);
  useEffect(() => {
    analyticsAction.getPollAnalyticsRequest({ pageNumber: 1, pageSize: 20, pollType: -1 });
    analyticsAction.getPollQuestionRequest({ pageNumber: 1, pageSize: 20 });
    analyticsAction.getPollWidgetRequest();
  }, []);

  const handlePageChange = (pageNumber: number) => {
    analyticsAction.getPollAnalyticsRequest({ ...filterData, pageNumber, pageSize: 20 });
  };

  const handlePollQuestionPageChange = (pageNumber: number) => {
    analyticsAction.getPollQuestionRequest({ pageNumber, pageSize: 20 });
  };

  const handleQuestionClick = (questionId: string) => {
    navigate(`/analytic/singlePollQuestion/${questionId}`);
  };

  const handleAnalyticsClick = (pollId: string) => {
    navigate(`/analytic/singlePoll/${pollId}`);
  };

  const handlePollClick = (videoId: string) => {
    navigate(`/videos/create-poll/${videoId}`);
  };

  useEffect(() => {
    if (!channelList.length && showFilter) {
      channelActions.getChannelListRequest();
    }
  }, [showFilter]);
  const onSubmit = (data: any) => {
    const filterPayloadData = {
      pollTitle: data.pollTitle,
      videoTitle: data.videoTitle,
      pollType: data.pollType,
      pageNumber: 1,
      pageSize: 20,
      channelName: data.channelIds && data.channelIds.name ? data.channelIds.name : ''
    };
    setFilterData(filterPayloadData);
    analyticsAction.getPollAnalyticsRequest(filterPayloadData);
  };

  const handleResetClick = () => {
    setFilterData(null);
    reset();
    setValue('channelIds', '');
    const payloadData = {
      pageNumber: 1,
      pageSize: 20,
      pollType: -1
    };
    analyticsAction.getPollAnalyticsRequest(payloadData);
  };

  return (
    <>
      <div className="dashboard">
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {pollWidget && pollWidget.totalPolls}
                  </span>
                  <span className="mb-2">Total Polls</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                      <i className="fa fa-poll"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {pollWidget && pollWidget.totalCompleted}
                  </span>
                  <span className="mb-2">Total Completed</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                      <i className="fa fa-list-alt" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="mt-2">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Polls</h2>
              <div>
                <button
                  type="button"
                  className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
                  onClick={handleFilterClick}>
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div className={`dashboard-card my-5 ${showFilter ? '' : 'hidden'}`}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-3">
                  <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                    <div className={`form-group`}>
                      <label className="block text-sm font-medium mb-1">Poll Title</label>
                      <input type="text" className="input-style" {...register('pollTitle')} />
                    </div>
                    <div className={`form-group`}>
                      <label className="block text-sm font-medium mb-1">Video Title</label>
                      <input type="text" className="input-style" {...register('videoTitle')} />
                    </div>
                    <div className={`form-group`}>
                      <label className="block text-sm font-medium mb-1">Poll Types</label>
                      <select className="input-style" {...register('pollType')}>
                        <option value="">Select</option>
                        <option value={0}>Quiz</option>
                        <option value={1}>Multiple Choice</option>
                        <option value={2}>Open Text</option>
                        <option value={3}>Star Rating</option>
                        <option value={4}>Survey</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                    <div className={`form-group`}>
                      <label className="block text-sm font-medium mb-1">Channel </label>
                      {channelList && (
                        <Controller
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Select
                              options={finalChannels}
                              filterOption={customFilterOption}
                              value={value}
                              id="channelIds"
                              onChange={(val) => onChange(val)}
                            />
                          )}
                          name="channelIds"
                          control={control}
                        />
                      )}
                    </div>
                    <div className="flex justify-center items-center mt-5">
                      <button
                        type="submit"
                        className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                        Submit
                      </button>

                      <button
                        onClick={handleResetClick}
                        type="button"
                        className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Poll Title</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Poll Type</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Video Title</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Questions</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Actions</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isPollAnalyticLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {' '}
                            {pollAnalytics &&
                              pollAnalytics.map((item, index) => {
                                return (
                                  <tr className="cursor-pointer" key={index}>
                                    <td className="p-2">
                                      <div className="text-left">{item.pollTitle}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left w-32">
                                        <span
                                          className={`${getBadgeBackgroundColor(
                                            item.pollType
                                          )} text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300`}>
                                          {getPollTypeName(item.pollType)}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.videoTitle}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalQuestions}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalCorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.totalIncorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        <div className="dropdown relative">
                                          <button
                                            className="dropdown-toggle"
                                            type="button"
                                            id="action-dropdown"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img
                                              src="../../assets/img/dots-vertical.svg"
                                              className="w-5 h-5"
                                            />
                                          </button>
                                          <ul
                                            className="dropdown-menu dropdown-menu-ul"
                                            aria-labelledby="action-dropdown">
                                            <li onClick={() => handlePollClick(item.videoID)}>
                                              <button
                                                type="button"
                                                className="action-menu flex items-center">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Edit Poll</span>
                                              </button>
                                            </li>
                                            <li onClick={() => handleAnalyticsClick(item.id)}>
                                              <button
                                                type="button"
                                                className="action-menu flex items-center">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Analytics</span>
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">Poll Questions</h2>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Question Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Quiz Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Status</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Created By</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Last Activity Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Correct Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. of Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Highest Incorrect Answers</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">No. Of Viewers</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isPollQuestionLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {pollQuestion &&
                              pollQuestion.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    onClick={() => handleQuestionClick(item.questionID)}
                                    className="cursor-pointer">
                                    <td className="p-2">
                                      <div className="text-left">{item.questionName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.quizName}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.quizStatus}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.createdDate
                                          ? defaultDateFormatter(item.createdDate)
                                          : ''}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.createdBy}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.lastActivityDate
                                          ? defaultDateFormatter(item.lastActivityDate)
                                          : ''}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.correctAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.incorrectAnswers}</div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">
                                        {item.highestIncorrectAnswers}
                                      </div>
                                    </td>
                                    <td className="p-2">
                                      <div className="text-left">{item.viewers}</div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                    {!!pollQuestionPaginationMeta &&
                      !!Object.keys(pollQuestionPaginationMeta).length && (
                        <Pagination
                          currentPage={pollQuestionPaginationMeta.pageNumber}
                          totalCount={pollQuestionPaginationMeta.totalCount}
                          pageSize={pollQuestionPaginationMeta.pageSize}
                          onPageChange={(page: any) => handlePollQuestionPageChange(page)}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticPolls;
