import { AnalyticsActions } from "./actions";
import { RootState } from "../reducers/state";
import { AnalyticsStateModel, CourseTotalModel, PollWidgetModel, QuizQuestionsResultsModel, QuizTotalModel, SalesOrderTotalModal, SingleQuizTotalModal } from "src/models/analyticsModel";
import { Actions } from "src/models/analyticsModel";
import * as Constants from './constants'
import { AnalyticsVideoChartModel } from "src/models/analyticsModel";
import { PaginationModel } from "src/models/paginationModel";
const initialState: RootState.AnalyticsModelState = {
    analyticsVideo: [],
    analyticsVideoDownload: [],
    analyticsMember: [],
    analyticsMemberSearch: [],
    analyticsCourse: [],
    salesOrder: [],
    salesOrderDownload: [],
    salesOrderTotal: {} as SalesOrderTotalModal,
    pollQuestion: [],
    courseMemberQuestion: [],
    quizQuestionResult: {} as QuizQuestionsResultsModel,
    analyticVideoPaginationMeta: {} as PaginationModel,
    paginationMeta: {} as PaginationModel,
    singleQuizQuestionPaginationMeta: {} as PaginationModel,
    pollQuestionPaginationMeta: {} as PaginationModel,
    quizAnalyticsPaginationMeta: {} as PaginationModel,
    analyticsVideoChart: {} as AnalyticsVideoChartModel,
    singleQuizTotal: {} as SingleQuizTotalModal,
    pollWidget: {} as PollWidgetModel,
    singleQuizQuestion: [],
    analyticsMemberChart: [],
    questionByQuiz: [],
    singlePollQuestion: [],
    courseTotal: {} as CourseTotalModel,
    quizTotal: {} as QuizTotalModel,
    singleVideoAnalytics: [],
    recentGrade: [],
    quizAnalytics: [],
    quizQuestions: [],
    courseResult: [],
    singleCourseResult: [],
    recentCourseActivity: [],
    viewActivity: [],
    viewSubmission: [],
    pollAnalytics: [],
    singlePoll: [],
    isAnalyticsVideoLoading: false,
    isAnalyticsMemberLoading: false,
    isSalesOrderLoading: false,
    isAnalyticsCourseLoading: false,
    isCourseResultLoading: false,
    isSingleCourseResultLoading: false,
    isRecentActivityLoading: false,
    isPollAnalyticLoading: false,
    isPollQuestionLoading: false,
    success: false,
    error: false,
    loading: false,
    isSinglePollLoading: false,
    isSinglePollQuestionLoading: false,
    isQuizAnalyticLoading: false,
    isQuizQuestionLoading: false,
    isQuestionByQuizIdLoading: false,
    isSingleQuizQuestionLoading: false,
    isSingleVideoAnalyticsLoading: false,
    recentCourseActivityPaginationMeta: {} as PaginationModel,
    isCourseMemberQuestionLoading: false
};


function analyticsReducer(state: AnalyticsStateModel = initialState, action: Actions): AnalyticsStateModel {
    switch (action.type) {
        case Constants.getAnalyticsVideoRequestActionType: return getAnalyticsVideoAction(state);
        case Constants.getAnalyticsVideoSuccessActionType: return getAnalyticsVideoSuccessAction(state, action);
        case Constants.getAnalyticsVideoFailureActionType: return getAnalyticsVideoFailureAction(state);
        case Constants.getAnalyticsVideoDownloadRequestActionType: return getAnalyticsVideoDownloadAction(state);
        case Constants.getAnalyticsVideoDownloadSuccessActionType: return getAnalyticsVideoDownloadSuccessAction(state, action);
        case Constants.getAnalyticsVideoDownloadFailureActionType: return getAnalyticsVideoDownloadFailureAction(state);
        case Constants.getAnalyticsVideoChartRequestActionType: return getAnalyticsVideoChartAction(state);
        case Constants.getAnalyticsVideoChartRequestSuccessActionType: return getAnalyticsVideoChartSuccessAction(state, action);
        case Constants.getAnalyticsVideoChartRequestFailureActionType: return getAnalyticsVideoChartFailureAction(state);
        case Constants.getAnalyticsMembersRequestActionType: return getAnalyticsMembersAction(state);
        case Constants.getAnalyticsMembersRequestSuccessActionType: return getAnalyticsMembersSuccessAction(state, action);
        case Constants.getAnalyticsMembersRequestFailureActionType: return getAnalyticsMembersFailureAction(state);
        case Constants.getSalesOrderRequestActionType: return getSalesOrderAction(state);
        case Constants.getSalesOrderRequestSuccessActionType: return getSalesOrderSuccessAction(state, action);
        case Constants.getSalesOrderRequestFailureActionType: return getSalesOrderFailureAction(state);
        case Constants.downloadSalesOrderRequestActionType: return downloadSalesOrderAction(state);
        case Constants.downloadSalesOrderRequestSuccessActionType: return downloadSalesOrderSuccessAction(state, action);
        case Constants.downloadSalesOrderRequestFailureActionType: return downloadSalesOrderFailureAction(state);
        case Constants.getCourseAnalyticsRequestActionType: return getCourseAnalyticsAction(state);
        case Constants.getCourseAnalyticsRequestSuccessActionType: return getCourseAnalyticsSuccessAction(state, action);
        case Constants.getCourseAnalyticsRequestFailureActionType: return getCourseAnalyticsFailureAction(state);
        case Constants.getAnalyticsMemberChartRequestActionType: return getAnalyticsMemberChartAction(state);
        case Constants.getAnalyticsMemberChartRequestSuccessActionType: return getAnalyticsMemberChartSuccessAction(state, action);
        case Constants.getAnalyticsMemberChartRequestFailureActionType: return getAnalyticsMemberChartFailureAction(state);
        case Constants.getCourseTotalRequestActionType: return getCourseTotalAction(state);
        case Constants.getCourseTotalRequestSuccessActionType: return getCourseTotalSuccessAction(state, action);
        case Constants.getCourseTotalRequestFailureActionType: return getCourseTotalFailureAction(state);
        case Constants.getCourseResultsRequestActionType: return getCourseResultAction(state);
        case Constants.getCourseResultsRequestSuccessActionType: return getCourseResultSuccessAction(state, action);
        case Constants.getCourseResultsRequestFailureActionType: return getCourseResultFailureAction(state);
        case Constants.getViewActivityRequestActionType: return getViewActivityAction(state);
        case Constants.getViewActivityRequestSuccessActionType: return getViewActivitySuccessAction(state, action);
        case Constants.getViewActivityRequestFailureActionType: return getViewActivityFailureAction(state);
        case Constants.getViewSubmissionRequestActionType: return getViewSubmissionAction(state);
        case Constants.getViewSubmissionRequestSuccessActionType: return getViewSubmissionSuccessAction(state, action);
        case Constants.getViewSubmissionRequestFailureActionType: return getViewSubmissionFailureAction(state);
        case Constants.getQuizTotalRequestActionType: return getQuizTotalAction(state);
        case Constants.getQuizTotalRequestSuccessActionType: return getQuizTotalSuccessAction(state, action);
        case Constants.getQuizTotalRequestFailureActionType: return getQuizTotalFailureAction(state);
        case Constants.getQuizAnalyticsRequestActionType: return getQuizAnalyticsAction(state);
        case Constants.getQuizAnalyticsRequestSuccessActionType: return getQuizAnalyticsSuccessAction(state, action);
        case Constants.getQuizAnalyticsRequestFailureActionType: return getQuizAnalyticsFailureAction(state);
        case Constants.getQuizQuestionsRequestActionType: return getQuizQuestionsAction(state);
        case Constants.getQuizQuestionsRequestSuccessActionType: return getQuizQuestionsSuccessAction(state, action);
        case Constants.getQuizQuestionsRequestFailureActionType: return getQuizQuestionsFailureAction(state);
        case Constants.getSingleCourseResultRequestActionType: return getSingleCourseResultAction(state);
        case Constants.getSingleCourseResultRequestSuccessActionType: return getSingleCourseResultSuccessAction(state, action);
        case Constants.getSingleCourseResultRequestFailureActionType: return getSingleCourseResultFailureAction(state);
        case Constants.getRecentCourseActivityRequestActionType: return getRecentCourseActivityAction(state);
        case Constants.getRecentCourseActivityRequestSuccessActionType: return getRecentCourseActivitySuccessAction(state, action);
        case Constants.getRecentCourseActivityRequestFailureActionType: return getRecentCourseActivityFailureAction(state);
        case Constants.getRecentGradeRequestActionType: return getRecentGradeAction(state);
        case Constants.getRecentGradeRequestSuccessActionType: return getRecentGradeSuccessAction(state, action);
        case Constants.getRecentGradeRequestFailureActionType: return getRecentGradeFailureAction(state);
        case Constants.getSingleQuizQuestionRequestActionType: return getSingleQuizQuestionAction(state);
        case Constants.getSingleQuizQuestionRequestSuccessActionType: return getSingleQuizQuestionSuccessAction(state, action);
        case Constants.getSingleQuizQuestionRequestFailureActionType: return getSingleQuizQuestionFailureAction(state);
        case Constants.getQuestionByQuizIdRequestActionType: return getQuestionByQuizIdAction(state);
        case Constants.getQuestionByQuizIdRequestSuccessActionType: return getQuestionByQuizIdSuccessAction(state, action);
        case Constants.getQuestionByQuizIdRequestFailureActionType: return getQuestionByQuizIdFailureAction(state);
        case Constants.getPollAnalyticsRequestActionType: return getPollAnalyticsAction(state);
        case Constants.getPollAnalyticsRequestSuccessActionType: return getPollAnalyticsSuccessAction(state, action);
        case Constants.getPollAnalyticsRequestFailureActionType: return getPollAnalyticsFailureAction(state);
        case Constants.getPollQuestionRequestActionType: return getPollQuestionAction(state);
        case Constants.getPollQuestionRequestSuccessActionType: return getPollQuestionSuccessAction(state, action);
        case Constants.getPollQuestionRequestFailureActionType: return getPollQuestionFailureAction(state);
        case Constants.getSinglePollRequestActionType: return getSinglePollAction(state);
        case Constants.getSinglePollRequestSuccessActionType: return getSinglePollSuccessAction(state, action);
        case Constants.getSinglePollRequestFailureActionType: return getSinglePollFailureAction(state);

        case Constants.getAnswerQuestionRequestActionType: return getAnswerQuestionAction(state);
        case Constants.getAnswerQuestionRequestSuccessActionType: return getAnswerQuestionSuccessAction(state, action);
        case Constants.getAnswerQuestionRequestFailureActionType: return getAnswerQuestionFailureAction(state);
        case Constants.getSalesOrderTotalRequestActionType: return getSalesOrderTotalAction(state);
        case Constants.getSalesOrderTotalRequestSuccessActionType: return getSalesOrderTotalSuccessAction(state, action);
        case Constants.getSalesOrderTotalRequestFailureActionType: return getSalesOrderTotalFailureAction(state);
        case Constants.getSingleQuizTotalRequestActionType: return getSingleQuizTotalAction(state);
        case Constants.getSingleQuizTotalRequestSuccessActionType: return getSingleQuizTotalSuccessAction(state, action);
        case Constants.getSingleQuizTotalRequestFailureActionType: return getSingleQuizTotalFailureAction(state);
        case Constants.getAnalyticsMemberSearchRequestActionType: return getAnalyticsMemberSearchAction(state);
        case Constants.getAnalyticsMemberSearchRequestSuccessActionType: return getAnalyticsMemberSearchSuccessAction(state, action);
        case Constants.getAnalyticsMemberSearchRequestFailureActionType: return getAnalyticsMemberSearchFailureAction(state);
        case Constants.getSinglePollQuestionRequestActionType: return getSinglePollQuestionAction(state);
        case Constants.getSinglePollQuestionRequestSuccessActionType: return getSinglePollQuestionSuccessAction(state, action);
        case Constants.getSinglePollQuestionRequestFailureActionType: return getSinglePollQuestionFailureAction(state);
        case Constants.getQuizQuestionDataRequestActionType: return getQuizQuestionDataAction(state);
        case Constants.getQuizQuestionDataRequestSuccessActionType: return getQuizQuestionDataSuccessAction(state, action);
        case Constants.getQuizQuestionDataRequestFailureActionType: return getQuizQuestionDataFailureAction(state);
        case Constants.getPollWidgetRequestActionType: return getPollWidgetAction(state);
        case Constants.getPollWidgetRequestSuccessActionType: return getPollWidgetSuccessAction(state, action);
        case Constants.getPollWidgetRequestFailureActionType: return getPollWidgetFailureAction(state);
        case Constants.getSingleVideoAnalyticsRequestActionType: return getSingleVideoAnalyticsAction(state);
        case Constants.getSingleVideoAnalyticsRequestSuccessActionType: return getSingleVideoAnalyticsSuccessAction(state, action);
        case Constants.getSingleVideoAnalyticsRequestFailureActionType: return getSingleVideoAnalyticsFailureAction(state);
        default: return state;
    }

    function getAnalyticsVideoAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsVideoLoading: true,
        };
    }
    function getAnalyticsVideoSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsVideoRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsVideo: action.payload.data,
                analyticVideoPaginationMeta: action.payload.paginationMeta,
                isAnalyticsVideoLoading: false
            };
        }
        return state;
    }
    function getAnalyticsVideoFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsVideoLoading: false
        }
    }
    function getAnalyticsVideoDownloadAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAnalyticsVideoDownloadSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsVideoDownloadRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsVideoDownload: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getAnalyticsVideoDownloadFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAnalyticsVideoChartAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAnalyticsVideoChartSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsVideoChartRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsVideoChart: action.payload,
            };
        }
        return state;
    }
    function getAnalyticsVideoChartFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function getAnalyticsMembersAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsMemberLoading: true,
        };
    }
    function getAnalyticsMembersSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsMembersRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsMember: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isAnalyticsMemberLoading: false
            };
        }
        return state;
    }
    function getAnalyticsMembersFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsMemberLoading: false
        }
    }

    function getSalesOrderAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSalesOrderLoading: true,
        };
    }
    function getSalesOrderSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSalesOrderRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                salesOrder: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSalesOrderLoading: false
            };
        }
        return state;
    }
    function getSalesOrderFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSalesOrderLoading: false
        }
    }
    function downloadSalesOrderAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSalesOrderLoading: true,
        };
    }
    function downloadSalesOrderSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.downloadSalesOrderRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                salesOrderDownload: action.payload.data,
                isSalesOrderLoading: false
            };
        }
        return state;
    }
    function downloadSalesOrderFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSalesOrderLoading: false
        }
    }
    function getCourseAnalyticsAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsCourseLoading: true,
        };
    }
    function getCourseAnalyticsSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getCourseAnalyticsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsCourse: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isAnalyticsCourseLoading: false
            };
        }
        return state;
    }
    function getCourseAnalyticsFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsCourseLoading: false
        }
    }
    function getAnalyticsMemberChartAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAnalyticsMemberChartSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsMemberChartRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsMemberChart: action.payload,
            };
        }
        return state;
    }
    function getAnalyticsMemberChartFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getCourseTotalAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCourseTotalSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getCourseTotalRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                courseTotal: action.payload,
            };
        }
        return state;
    }
    function getCourseTotalFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getCourseResultAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isCourseResultLoading: true,
        };
    }
    function getCourseResultSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getCourseResultsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                courseResult: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isCourseResultLoading: false
            };
        }
        return state;
    }
    function getCourseResultFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isCourseResultLoading: false
        }
    }
    function getViewActivityAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getViewActivitySuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getViewActivityRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                viewActivity: action.payload,
            };
        }
        return state;
    }
    function getViewActivityFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getViewSubmissionAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getViewSubmissionSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getViewSubmissionRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                viewSubmission: action.payload,
            };
        }
        return state;
    }
    function getViewSubmissionFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getQuizTotalAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getQuizTotalSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getQuizTotalRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                quizTotal: action.payload,
            };
        }
        return state;
    }
    function getQuizTotalFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getQuizAnalyticsAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuizAnalyticLoading: true,
        };
    }
    function getQuizAnalyticsSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getQuizAnalyticsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                quizAnalytics: action.payload.data,
                quizAnalyticsPaginationMeta: action.payload.paginationMeta,
                isQuizAnalyticLoading: false,
            };
        }
        return state;
    }
    function getQuizAnalyticsFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuizAnalyticLoading: false,
        }
    }
    function getQuizQuestionsAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuizQuestionLoading: true,
        };
    }
    function getQuizQuestionsSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getQuizQuestionsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                quizQuestions: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isQuizQuestionLoading: false,
            };
        }
        return state;
    }
    function getQuizQuestionsFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuizQuestionLoading: false,
        }
    }
    function getSingleCourseResultAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleCourseResultLoading: true,
        };
    }
    function getSingleCourseResultSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSingleCourseResultRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singleCourseResult: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSingleCourseResultLoading: false
            };
        }
        return state;
    }
    function getSingleCourseResultFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleCourseResultLoading: false
        }
    }
    function getRecentCourseActivityAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isRecentActivityLoading: true,
        };
    }
    function getRecentCourseActivitySuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getRecentCourseActivityRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                recentCourseActivity: action.payload.data,
                recentCourseActivityPaginationMeta: action.payload.paginationMeta,
                isRecentActivityLoading: false
            };
        }
        return state;
    }
    function getRecentCourseActivityFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isRecentActivityLoading: false
        }
    }
    function getRecentGradeAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRecentGradeSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getRecentGradeRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                recentGrade: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getRecentGradeFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getSingleQuizQuestionAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleQuizQuestionLoading: true,
        };
    }
    function getSingleQuizQuestionSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSingleQuizQuestionRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singleQuizQuestion: action.payload.data,
                singleQuizQuestionPaginationMeta: action.payload.paginationMeta,
                isSingleQuizQuestionLoading: false,
            };
        }
        return state;
    }
    function getSingleQuizQuestionFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleQuizQuestionLoading: false,
        }
    }
    function getQuestionByQuizIdAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuestionByQuizIdLoading: true,
        };
    }
    function getQuestionByQuizIdSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getQuestionByQuizIdRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                questionByQuiz: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isQuestionByQuizIdLoading: false,
            };
        }
        return state;
    }
    function getQuestionByQuizIdFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isQuestionByQuizIdLoading: false,
        }
    }
    function getPollAnalyticsAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isPollAnalyticLoading: true,
        };
    }
    function getPollAnalyticsSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getPollAnalyticsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                pollAnalytics: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isPollAnalyticLoading: false
            };
        }
        return state;
    }
    function getPollAnalyticsFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isPollAnalyticLoading: false
        }
    }
    function getPollQuestionAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isPollQuestionLoading: true,
        };
    }
    function getPollQuestionSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getPollQuestionRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                pollQuestion: action.payload.data,
                pollQuestionPaginationMeta: action.payload.paginationMeta,
                isPollQuestionLoading: false
            };
        }
        return state;
    }
    function getPollQuestionFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isPollQuestionLoading: false
        }
    }
    function getSinglePollAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSinglePollLoading: true,
        };
    }
    function getSinglePollSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSinglePollRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singlePoll: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSinglePollLoading: false,
            };
        }
        return state;
    }
    function getSinglePollFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSinglePollLoading: false,
        }
    }
    function getSinglePollQuestionAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSinglePollQuestionLoading: true,
        };
    }
    function getSinglePollQuestionSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSinglePollQuestionRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singlePollQuestion: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSinglePollQuestionLoading: false,
            };
        }
        return state;
    }
    function getSinglePollQuestionFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSinglePollQuestionLoading: false,
        }
    }

    function getAnswerQuestionAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isCourseMemberQuestionLoading: true,
        };
    }
    function getAnswerQuestionSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnswerQuestionRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                courseMemberQuestion: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isCourseMemberQuestionLoading: false
            };
        }
        return state;
    }
    function getAnswerQuestionFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isCourseMemberQuestionLoading: false
        }
    }
    function getSalesOrderTotalAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSalesOrderTotalSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSalesOrderTotalRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                salesOrderTotal: action.payload,
            };
        }
        return state;
    }
    function getSalesOrderTotalFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getSingleQuizTotalAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleQuizTotalSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSingleQuizTotalRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singleQuizTotal: action.payload,
            };
        }
        return state;
    }
    function getSingleQuizTotalFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAnalyticsMemberSearchAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsMemberLoading: true,
        };
    }
    function getAnalyticsMemberSearchSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getAnalyticsMemberSearchRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                analyticsMemberSearch: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isAnalyticsMemberLoading: false
            };
        }
        return state;
    }
    function getAnalyticsMemberSearchFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isAnalyticsMemberLoading: false
        }
    }
    function getQuizQuestionDataAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getQuizQuestionDataSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getQuizQuestionDataRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                quizQuestionResult: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getQuizQuestionDataFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getPollWidgetAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getPollWidgetSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getPollWidgetRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                pollWidget: action.payload,
                loading: false,
            };
        }
        return state;
    }
    function getPollWidgetFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getSingleVideoAnalyticsAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleVideoAnalyticsLoading: true,
        };
    }
    function getSingleVideoAnalyticsSuccessAction(state: AnalyticsStateModel, action: AnalyticsActions.getSingleVideoAnalyticsRequestSuccessAction): AnalyticsStateModel {
        if (action.payload) {
            return {
                ...state,
                singleVideoAnalytics: action.payload.data,
                paginationMeta: action.payload.paginationMeta,
                isSingleVideoAnalyticsLoading: false,
            };
        }
        return state;
    }
    function getSingleVideoAnalyticsFailureAction(state: AnalyticsStateModel): AnalyticsStateModel {
        return {
            ...state,
            isSingleVideoAnalyticsLoading: false
        }
    }
}

export default analyticsReducer
