import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg } from 'src/components/utilities/utils';
const LiveStreaming = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const [clipboard, setClipboard] = useState<boolean>(false);
  const {
    liveStreamUrl,
    isLiveStreamLoading,
    isCloudRecordingUpdated,
    message,
    isAutoPublishOnDemandUpdated,
    error
  } = useSelector((state: RootState) => {
    return {
      liveStreamUrl: state.setting.liveStreamUrl,
      isLiveStreamLoading: state.setting.isLiveStreamLoading,
      isCloudRecordingUpdated: state.setting.isCloudRecordingUpdated,
      message: state.setting.message,
      isAutoPublishOnDemandUpdated: state.setting.isAutoPublishOnDemandUpdated,
      error: state.setting.error
    };
  });

  useEffect(() => {
    settingAction.getLiveStreamUrlRequest();
  }, []);

  useEffect(() => {
    if (clipboard) {
      successMsg('Copied To Clipboard');
      setClipboard(false);
    }
  }, [clipboard]);

  useEffect(() => {
    if (isCloudRecordingUpdated || isAutoPublishOnDemandUpdated) {
      successMsg(message);
      settingAction.getLiveStreamUrlRequest();
      settingAction.resetSettingStateRequest();
    }
  }, [isCloudRecordingUpdated, isAutoPublishOnDemandUpdated]);
  const handleCloudRecordingEnableClick = (id: string, e: boolean) => {
    const payloadData = {
      id,
      updateData: [{ op: 'replace', path: 'cloudRecordingEnable', value: e }]
    };
    settingAction.updateCloudRecordingRequest(payloadData);
  };

  const handleAutoPublishedOnDemand = (id: string, e: boolean) => {
    const payloadData = {
      id,
      updateData: [{ op: 'replace', path: 'autoPublishOnDemand', value: e }]
    };
    settingAction.updateAutoPublishOnDemandRequest(payloadData);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <>
      <ToastMessage />
      <div className="mb-5">
        <h2 className="font-semibold my-1">Live Media Channels</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">
            <strong> Live Media Channels</strong>
          </li>
        </ol>
      </div>

      <div className="dashboard-card py-4 px-5">
        {isLiveStreamLoading && <Loader />}
        {liveStreamUrl &&
          liveStreamUrl.map((item) => {
            return (
              <div key={item.id} className="overflow-x-auto mb-5">
                <h1 className="font-bold">{item.liveMediaName}</h1>
                <p className="font-semibold mb-4">{item.urlRefName}</p>
                <table className="table-auto w-full border">
                  <tbody>
                    <tr className="border-b">
                      <td className="p-2">Live Stream Playback URL</td>
                      <td className="p-2">{item.liveMediaUrl}</td>
                      <td className="p-2">
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap">
                          <i
                            className="fas fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(item.liveMediaUrl), setClipboard(true);
                            }}></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2">Channel ID</td>
                      <td className="p-2">{item.liveMediaLiveChannelId}</td>
                      <td className="p-2">
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap">
                          <i
                            className="fas fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(item.liveMediaLiveChannelId),
                                setClipboard(true);
                            }}></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2">RTMP Ingest URL</td>
                      <td className="p-2">{item.rtmpIngestURL}</td>
                      <td className="p-2">
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap">
                          <i
                            className="fas fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(item.rtmpIngestURL), setClipboard(true);
                            }}></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2">Stream Key</td>
                      <td className="p-2">{item.streamKey}</td>
                      <td className="p-2">
                        <button
                          type="button"
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn whitespace-nowrap">
                          <i
                            className="fas fa-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(item.streamKey), setClipboard(true);
                            }}></i>
                        </button>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2">Cloud Recording</td>
                      <td className="p-2">
                        <div className="form-check">
                          <input
                            onChange={(e) =>
                              handleCloudRecordingEnableClick(item.id, e.target.checked)
                            }
                            className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                            type="checkbox"
                            defaultChecked={item.cloudRecordingEnable}
                          />
                        </div>
                      </td>
                      <td className="p-2"></td>
                    </tr>
                    <tr className="border-b">
                      <td className="p-2">Auto Publish OnDemand</td>
                      <td className="p-2">
                        <div className="form-check">
                          <input
                            disabled={item.cloudRecordingEnable === true ? false : true}
                            onChange={(e) => handleAutoPublishedOnDemand(item.id, e.target.checked)}
                            className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                            type="checkbox"
                            defaultChecked={item.autoPublishOnDemand}
                          />
                        </div>
                      </td>
                      <td className="p-2"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default LiveStreaming;
