import { all, put, takeEvery } from 'redux-saga/effects';
import { CategoryActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import { URLS } from 'src/components/utilities/endpoints';
import * as Constants from './constants'

function* asyncGetCategory(data: CategoryActions.getCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CATEGORIES.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNo=${data.payload?.pageNumber}&pageSize=20`);
        if (response.status === 200) {
            yield put(CategoryActions.getCategoryRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getCategoryRequestFailure());
    }
}
function* asyncGetAllCategory(data: CategoryActions.getAllCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CATEGORIES.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNo=${data.payload?.pageNumber}&pageSize=1000`);
        if (response.status === 200) {
            yield put(CategoryActions.getAllCategoryRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getAllCategoryRequestFailure());
    }
}
function* asyncGetSingleCategory(data: CategoryActions.getSingleCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_CATEGORIES.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getSingleCategoryRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getSingleCategoryRequestFailure());
    }
}
function* asyncDeleteCategory(data: CategoryActions.deleteCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CATEGORIES.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.deleteCategoryRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.deleteCategoryRequestFailure());
    }
}
function* asyncSaveCategory(data: CategoryActions.deleteCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_CATEGORIES.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.saveCategoryRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.saveCategoryRequestFailure());
    }
}
function* asyncUpdateCategory(data: CategoryActions.updateCategoryRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CATEGORIES.url}/${data.payload?.categoryId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateCategoryRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateCategoryRequestFailure());
    }
}
function* asyncUpdateSpotLightRowFlag(data: CategoryActions.updateSpotLightRowFlagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_SPOTLIGHTROW_FLAG.url}/${data.payload?.categoryid}/${data.payload?.SpotlightRowFlag}`, null);
        if (response.status === 200) {
            yield put(CategoryActions.updateSpotLightRowFlagRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateSpotLightRowFlagRequestFailure());
    }
}
function* asyncGetVideoTag(data: CategoryActions.getVideoTagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_TAGS.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNumber=${data.payload?.pageNumber}&pageSize=20`);
        if (response.status === 200) {
            yield put(CategoryActions.getVideoTagRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getVideoTagRequestFailure());
    }
}
function* asyncSaveVideoTag(data: CategoryActions.saveVideoTagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_TAGS.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.saveVideoTagRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.saveVideoTagRequestFailure());
    }
}
function* asyncUpdateVideoTag(data: CategoryActions.updateVideoTagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_TAGS.url}/${data.payload?.tagId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateVideoTagRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateVideoTagRequestFailure());
    }
}
function* asyncDeleteVideoTag(data: CategoryActions.deleteVideoTagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_TAGS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.deleteVideoTagRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.deleteVideoTagRequestFailure());
    }
}
function* asyncGetSingleVideoTag(data: CategoryActions.getSingleVideoTagRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_TAGS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getSingleVideoTagRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getSingleVideoTagRequestFailure());
    }
}
function* asyncGetContentType(data: CategoryActions.getContentTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CONTENT_TYPE.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNumber=${data.payload?.pageNumber}&pageSize=20`);
        if (response.status === 200) {
            yield put(CategoryActions.getContentTypeRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getContentTypeRequestFailure());
    }
}
function* asyncAddContentType(data: CategoryActions.addContentTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_CONTENT_TYPE.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.addContentTypeRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.addContentTypeRequestFailure());
    }
}
function* asyncUpdateContentType(data: CategoryActions.updateContentTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CONTENT_TYPE.url}/${data.payload?.contentTypeId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateContentTypeRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateContentTypeRequestFailure());
    }
}
function* asyncDeleteContentType(data: CategoryActions.deleteContentTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CONTENT_TYPE.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.deleteContentTypeRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.deleteContentTypeRequestFailure());
    }
}
function* asyncGetSingleContentType(data: CategoryActions.getSingleContentTypeRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_CONTENT_TYPE.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getSingleContentTypeRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getSingleContentTypeRequestFailure());
    }
}
function* asyncGetModule(data: CategoryActions.getModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_MODULE.url}?filter=${data.payload?.querySearch ? data.payload?.querySearch : ''}&pageNumber=${data.payload?.pageNumber}&pageSize=20`);
        if (response.status === 200) {
            yield put(CategoryActions.getModuleRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getModuleRequestFailure());
    }
}
function* asyncAddModule(data: CategoryActions.addModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_MODULE.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.addModuleRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.addModuleRequestFailure());
    }
}
function* asyncUpdateModule(data: CategoryActions.updateModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_MODULE.url}/${data.payload?.moduleId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateModuleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateModuleRequestFailure());
    }
}

function* asyncDeleteModule(data: CategoryActions.deleteModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_MODULE.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.deleteModuleRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.deleteModuleRequestFailure());
    }
}
function* asyncGetSingleModule(data: CategoryActions.getSingleModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GFT_SINGLE_MODULE.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getSingleModuleRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getSingleModuleRequestFailure());
    }
}
function* asyncGetCategoriesList(data: CategoryActions.getCategoriesListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CATEGORIES_LIST.url);
        if (response.status === 200) {
            yield put(CategoryActions.getCategoriesListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getCategoriesListRequestFailure());
    }
}
function* asyncGetModulesList(data: CategoryActions.getModulesListRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_MODULES_LIST.url);
        if (response.status === 200) {
            yield put(CategoryActions.getModulesListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getModulesListRequestFailure());
    }
}
function* asyncGetAudienceProfiles(data: CategoryActions.getAudienceProfilesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_AUDIENCE_PROFILES.url);
        if (response.status === 200) {
            yield put(CategoryActions.getAudienceProfilesRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getAudienceProfilesRequestFailure());
    }
}
function* asyncAddAudienceProfiles(data: CategoryActions.addAudienceProfilesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_AUDIENCE_PROFILES.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.addAudienceProfilesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.addAudienceProfilesRequestFailure());
    }
}
function* asyncUpdateAudienceProfiles(data: CategoryActions.updateAudienceProfilesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_AUDIENCE_PROFILES.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateAudienceProfilesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateAudienceProfilesRequestFailure());
    }
}
function* asyncDeleteAudienceProfiles(data: CategoryActions.deleteAudienceProfilesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_AUDIENCE_PROFILES.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.deleteAudienceProfilesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.deleteAudienceProfilesRequestFailure());
    }
}
function* asyncGetSingleAudienceProfiles(data: CategoryActions.getSingleAudienceProfilesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_AUDIENCE_PROFILES.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getSingleAudienceProfilesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getSingleAudienceProfilesRequestFailure());
    }
}
function* asyncGetCollectionsList() {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COLLECTIONS_LIST.url}`);
        if (response.status === 200) {
            yield put(CategoryActions.getCollectionsListRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getCollectionsListRequestFailure());
    }
}
function* asyncAddCollection(data: CategoryActions.addCollectionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_COLLECTION.url, data.payload);
        if (response.status === 200) {
            yield put(CategoryActions.addCollectionRequestSuccess(response.data.result?.collectionId));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.addCollectionRequestFailure());
    }
}
function* asyncGetCollection(data: CategoryActions.getCollectionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COLLECTION.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getCollectionRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getCollectionRequestFailure());
    }
}
function* asyncGetCollectionVideos(data: CategoryActions.getCollectionVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COLLECTION_VIDEOS.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(CategoryActions.getCollectionVideosRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.getCollectionVideosRequestFailure());
    }
}
function* asyncAddCollectionVideos(data: CategoryActions.addCollectionVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.ADD_COLLECTION_VIDEOS.url}/${data.payload?.collectionId}/${data.payload?.presentationId}`);
        if (response.status === 200) {
            yield put(CategoryActions.addCollectionVideosRequestSuccess(data.payload ? data.payload.presentationId : ""));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.addCollectionVideosRequestFailure());
    }
}
function* asyncRemoveCollectionVideos(data: CategoryActions.removeCollectionVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.REMOVE_COLLECTION_VIDEOS.url}/${data.payload?.collectionId}/${data.payload?.presentationId}`);
        if (response.status === 200) {
            yield put(CategoryActions.removeCollectionVideosRequestSuccess(data.payload ? data.payload.presentationId : ""));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.removeCollectionVideosRequestFailure());
    }
}
function* asyncRemoveCollection(data: CategoryActions.removeCollectionRequestAction) {
    try {
        console.log("data--> ", data)
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.REMOVE_COLLECTION.url}/${data.payload?.collectionId}`);
        if (response.status === 200) {
            yield put(CategoryActions.removeCollectionRequestSuccess(data.payload ? data.payload.collectionId : ""));
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.removeCollectionRequestFailure());
    }
}
function* asyncUpdateCollection(data: CategoryActions.updateCollectionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_COLLECTION_VIDEOS.url}/${data.payload?.collectionId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(CategoryActions.updateCollectionRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateCollectionRequestFailure());
    }
}
function* asyncUpdateCollectionVideoOrder(data: CategoryActions.updateCollectionVideoOrderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_COLLECTION_VIDEOS_ORDER.url}/${data.payload?.collectionId}`, data.payload?.videoIds);
        if (response.status === 200) {
            yield put(CategoryActions.updateCollectionVideoOrderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CategoryActions.updateCollectionVideoOrderRequestFailure());
    }
}
export function* CategorySaga() {
    yield all([
        takeEvery(Constants.getCategoryRequestActionType, asyncGetCategory),
        takeEvery(Constants.getAllCategoryRequestActionType, asyncGetAllCategory),
        takeEvery(Constants.deleteCategoryRequestActionType, asyncDeleteCategory),
        takeEvery(Constants.saveCategoryRequestActionType, asyncSaveCategory),
        takeEvery(Constants.updateCategoryRequestActionType, asyncUpdateCategory),
        takeEvery(Constants.updateSpotLightRowFlagRequestActionType, asyncUpdateSpotLightRowFlag),
        takeEvery(Constants.getSingleCategoryRequestActionType, asyncGetSingleCategory),
        takeEvery(Constants.getVideoTagRequestActionType, asyncGetVideoTag),
        takeEvery(Constants.saveVideoTagRequestActionType, asyncSaveVideoTag),
        takeEvery(Constants.updateVideoTagRequestActionType, asyncUpdateVideoTag),
        takeEvery(Constants.deleteVideoTagRequestActionType, asyncDeleteVideoTag),
        takeEvery(Constants.getSingleVideoTagRequestActionType, asyncGetSingleVideoTag),
        takeEvery(Constants.getContentTypeRequestActionType, asyncGetContentType),
        takeEvery(Constants.addContentTypeRequestActionType, asyncAddContentType),
        takeEvery(Constants.updateContentTypeRequestActionType, asyncUpdateContentType),
        takeEvery(Constants.deleteContentTypeRequestActionType, asyncDeleteContentType),
        takeEvery(Constants.getSingleContentTypeRequestActionType, asyncGetSingleContentType),
        takeEvery(Constants.getModuleRequestActionType, asyncGetModule),
        takeEvery(Constants.addModuleRequestActionType, asyncAddModule),
        takeEvery(Constants.updateModuleRequestActionType, asyncUpdateModule),
        takeEvery(Constants.deleteModuleRequestActionType, asyncDeleteModule),
        takeEvery(Constants.getSingleModuleRequestActionType, asyncGetSingleModule),
        takeEvery(Constants.getCategoriesListRequestActionType, asyncGetCategoriesList),
        takeEvery(Constants.getModulesListRequestActionType, asyncGetModulesList),
        takeEvery(Constants.getAudienceProfilesRequestActionType, asyncGetAudienceProfiles),
        takeEvery(Constants.addAudienceProfilesRequestActionType, asyncAddAudienceProfiles),
        takeEvery(Constants.updateAudienceProfilesRequestActionType, asyncUpdateAudienceProfiles),
        takeEvery(Constants.deleteAudienceProfilesRequestActionType, asyncDeleteAudienceProfiles),
        takeEvery(Constants.getSingleAudienceProfilesRequestActionType, asyncGetSingleAudienceProfiles),
        takeEvery(Constants.getCollectionsListRequestActionType, asyncGetCollectionsList),
        takeEvery(Constants.addCollectionRequestActionType, asyncAddCollection),
        takeEvery(Constants.getCollectionRequestActionType, asyncGetCollection),
        takeEvery(Constants.getCollectionVideosRequestActionType, asyncGetCollectionVideos),
        takeEvery(Constants.addCollectionVideosRequestActionType, asyncAddCollectionVideos),
        takeEvery(Constants.removeCollectionVideosRequestActionType, asyncRemoveCollectionVideos),
        takeEvery(Constants.removeCollectionRequestActionType, asyncRemoveCollection),
        takeEvery(Constants.updateCollectionRequestActionType, asyncUpdateCollection),
        takeEvery(Constants.updateCollectionVideoOrderRequestActionType, asyncUpdateCollectionVideoOrder)
    ]);
}
