import React, { useEffect, useState, useRef } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm, Controller } from 'react-hook-form';
import FormSelector from 'src/components/common/formSelector';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch } from 'react-redux';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import { useSelector } from 'react-redux';
import { defaultDateFormatter, successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import ToastMessage from 'src/components/ToastContainer';
import Select from 'react-select';
import { Link, useLocation } from 'react-router-dom';
import { convertHourMinuteToSecond } from 'src/components/utilities/utils';
import { useLoginActions } from 'src/store/login/actions';
import { customFilterOption } from 'src/components/utilities/utils';
import moment from 'moment';
type locationState = {
  channelId: string;
};
const AddNewVideoModal = ({ channelName, eventTitle, setNewVideoData }: any) => {
  const dispatch = useDispatch();
  const loginAction = useLoginActions(dispatch);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [finalChannels, setFinalChannels] = useState<any>();
  const videoAction = useVideosActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const [source, SetSource] = useState(2);
  const userData = localStorage.getItem('userData');
  const finalUserData = userData && JSON.parse(userData);
  const { channelId } = (location.state as locationState) || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
    getValues
  } = useForm();

  const {
    channelList,
    isVideoUploaded,
    message,
    isLiveStreamError,
    isAddLiveStreamLoading,
    liveStreamId,
    liveMedia,
    productAccess,
    defaultDateFormat
  } = useSelector((state: RootState) => {
    return {
      channelList: state.channel.channelList,
      isVideoUploaded: state.videos.isVideoUploaded,
      message: state.videos.message,
      isLiveStreamError: state.videos.isLiveStreamError,
      isAddLiveStreamLoading: state.videos.isAddLiveStreamLoading,
      liveStreamId: state.videos.liveStreamId,
      liveMedia: state.videos.liveMedia,
      productAccess: state.videos.isProductAccess,
      defaultDateFormat: state.login.defaultDateFormat
    };
  });

  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);

  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);

  const onSubmit = (data: any) => {
    if (data) {
      setNewVideoData({
        date: data.date,
        time: data.time,
        duration: convertHourMinuteToSecond(data.hour, data.minute)
      });
    }
    const finalData = {
      title: data.title,
      channelId: data.channelId.value,
      liveStreamId: data.liveMedia,
      date: defaultDateFormatter(data.date),
      time: data.time,
      duration: convertHourMinuteToSecond(data.hour, data.minute),
      useLiveStreamDefaultSettings: data.useLiveStreamDefaultSettings,
      autoPublishOnDemand: data.autoPublishOnDemand,
      cloudRecording: data.cloudRecording,
      videoSourceId: source,
      useLiveMeeting: data.useLiveMeeting,
      onDemandCaptionEnable: data.onDemandCaptionEnable
    };
    videoAction.addLiveStreamRequest(finalData);
  };
  useEffect(() => {
    if (isVideoUploaded) {
      successMsg(message);
      videoAction.resetVideoLibraryRequest();
    }
  }, [isVideoUploaded]);
  useEffect(() => {
    if (isLiveStreamError) {
      errorMsg('Error');
      videoAction.resetVideoLibraryRequest();
    }
  }, [isLiveStreamError]);
  const handleCancelClick = () => {
    navigate('/channels');
  };
  useEffect(() => {
    if (isVideoUploaded) {
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isVideoUploaded]);

  useEffect(() => {
    videoAction.getLiveMediaRequest();
  }, []);

  useEffect(() => {
    videoAction.getProductAccessRequest();
    loginAction.getDefaultdateFormatRequest();
  }, []);

  const watchLiveMedia = watch('liveMedia');

  useEffect(() => {
    const filterData = liveMedia && liveMedia.filter((item) => item.id === watchLiveMedia);
    if (filterData && filterData.length) {
      setValue('cloudRecording', filterData[0]?.cloudRecordingEnable);
      setValue('autoPublishOnDemand', filterData[0]?.autoPublishOnDemand);
    }
  }, [watchLiveMedia]);

  useEffect(() => {
    setValue('date', moment().format('YYYY-MM-DD'));
  }, []);

  useEffect(() => {
    if (channelList && channelName) {
      const selectedChannel = channelList && channelList.filter((item) => item.id === channelName);
      if (selectedChannel) {
        const defaultChannel = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].displayText : ''
        };
        setValue('channelId', defaultChannel);
        setValue('title', eventTitle);
      }
    }
  }, [channelList, channelName]);
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="addVideoModal"
      aria-labelledby="editMemberModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            {/* <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editMemberModalLabel">
              {`${heading} User Information`}
            </h5> */}
            <button
              ref={btnRef}
              //   onClick={handleCancleClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-4">
                {/* <FormInputField
                      label={'Channel'}
                      type={'text'}
                      fieldName={'channel'}
                      register={register}
                      errors={errors}
                      isRequired={true}
                    /> */}
                <label className="block text-sm font-medium mb-1">Channel</label>
                {channelList && (
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        filterOption={customFilterOption}
                        options={finalChannels}
                        value={value}
                        id="channelId"
                        onChange={(val) => onChange(val)}
                        isLoading={channelList.length ? false : true}
                      />
                    )}
                    name="channelId"
                    control={control}
                    rules={{ required: true }}
                  />
                )}
              </div>
              <div className="form-group mb-4">
                <FormInputField
                  label={'Video Title'}
                  type={'text'}
                  fieldName={'title'}
                  register={register}
                  errors={errors}
                  isRequired={true}
                />
              </div>
              <div className="form-group mb-4">
                <label className="block text-sm font-medium mb-1">Select a Video Source</label>
                <div className="flex">
                  <div
                    className={`border-2 w-60 h-32 flex justify-center items-center ${
                      source === 1 ? 'border-blue-400' : ''
                    }`}
                    onClick={() => SetSource(1)}>
                    <div className="flex justify-center items-center flex-col cursor-pointer">
                      <i className="fa fa-camera text-7xl"></i>
                      <h4 className="font-bold">Webcam</h4>
                    </div>
                  </div>
                  <div
                    className={`border-2 w-60 h-32 flex justify-center items-center ml-5 ${
                      source === 2 ? 'border-blue-400' : ''
                    }`}
                    onClick={() => SetSource(2)}>
                    <div className="flex justify-center items-center flex-col cursor-pointer">
                      <i className="fas fa-key fa-rotate-180 text-7xl"></i>
                      <h4 className="font-bold">Streaming Software</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:grid lg:grid-cols-2 gap-4">
                <div className="form-group mb-4">
                  <FormInputField
                    label={'Date'}
                    type={'date'}
                    fieldName={'date'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <FormInputField
                    label={'Time'}
                    type={'time'}
                    fieldName={'time'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="form-group mb-4">
                <label className="block text-sm font-medium mb-1">Duration (hh:mm)</label>
                <div className="md:flex items-center">
                  <div className="flex items-center">
                    <select className="input-style w-40" id="hour" {...register('hour')}>
                      {Array.from(Array(23).keys()).map((item) => {
                        return (
                          <option key={item} value={item}>
                            {item < 10 ? `0${item}` : item}
                          </option>
                        );
                      })}
                    </select>
                    <span className="ml-2">Hours</span>
                  </div>
                  <div className="flex items-center">
                    <select className="input-style w-40 md:ml-3" {...register('minute')}>
                      {Array.from({ length: Math.ceil(60 / 5) }, (_, index) => index * 5).map(
                        (item) => {
                          return (
                            <option key={item} value={item}>
                              {item <= 5 ? `0${item}` : item}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <span className="ml-2">Minutes</span>
                  </div>
                </div>
              </div>
              <div className="form-group mb-4">
                <FormSelector
                  label={'Live Media'}
                  fieldName={'liveMedia'}
                  register={register}
                  errors={errors}
                  isRequired={true}
                  data={
                    liveMedia &&
                    liveMedia.map((item) => {
                      return { id: item.id, name: item.mediaName };
                    })
                  }
                  options={'name'}
                />
              </div>

              <div className="form-group mb-4">
                <div>
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      id="useDefaultSetting"
                      {...register('useLiveStreamDefaultSettings')}
                      defaultChecked={true}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="flexCheckDefault">
                      Use Default Settings
                    </label>
                  </div>
                  <div className="flex">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        id="flexCheckChecked"
                        {...register('cloudRecording', {
                          onChange: (e) => {
                            setValue('autoPublishOnDemand', false);
                          }
                        })}
                        disabled={watch('useLiveStreamDefaultSettings')}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexCheckChecked">
                        Cloud Recording
                      </label>
                    </div>
                    <div className="form-check ml-6">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        id="flexCheckChecked"
                        {...register('autoPublishOnDemand')}
                        disabled={watch('useLiveStreamDefaultSettings') || !watch('cloudRecording')}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexCheckChecked">
                        Auto Publish OnDemand
                      </label>
                    </div>
                  </div>
                  {productAccess && productAccess.liveMeeting && source === 1 && (
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        id="useLiveMeeting"
                        {...register('useLiveMeeting')}
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexCheckDefault">
                        Use Low Latency Meeting
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <div
                  className={`flex items-center ${
                    productAccess && !productAccess.aiGeneratedCaptions ? 'ai-background' : ''
                  }`}>
                  <div className="mr-2 flex">
                    <label>OnDemand Captions AI</label>
                    {/* <i className="fa-solid fa-wand-magic-sparkles ai-icon"></i> */}
                  </div>
                  <div className="form-check form-switch pl-0 flex-items mb-2">
                    <div className="relative">
                      <input
                        disabled={
                          productAccess && !productAccess.aiGeneratedCaptions ? true : false
                        }
                        className="form-check-input switch-btn"
                        type="checkbox"
                        id="onDemandCaptionEnable"
                        {...register('onDemandCaptionEnable')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
                {productAccess && !productAccess.aiGeneratedCaptions && (
                  <p className="bg-purple-100 ml-2">
                    {/* <i className="fa-solid fa-wand-magic-sparkles"></i> */}
                    <span className="font-bold">New! </span>
                    <span className="font-medium">
                      Add Captions AI to any paid plan for a monthly fee.{' '}
                      <a
                        href={`${
                          defaultDateFormat.dateFormat === 'MM/dd/yyyy'
                            ? 'https://buy.stripe.com/5kA3fl3OpcIvcjC8wy'
                            : 'https://buy.stripe.com/8wMdTZdoZaAn5VeeUV'
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        className="underline text-indigo-700 cursor-pointer">
                        Get Captions AI
                      </a>
                    </span>
                  </p>
                )}
              </div>
              <div className="flex items-center mt-6">
                <button type="submit" className="px-6 btn-primary w-auto mr-2 ml-0 mt-0">
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleCancelClick}
                  className="mx-0 px-6 btn-primary discard-btn w-auto mt-0"
                  data-bs-dismiss="modal">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewVideoModal;
