import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/common/pagination';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import Select from 'react-select';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { CSVLink } from 'react-csv';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { useUserActions } from 'src/store/users/actions';
import { SelectModel } from 'src/models/videosModel';
import { GetAnalyticsMemberSearchParamModel } from 'src/models/analyticsModel';
import { secondsToTime, convertSecondToHourMinuteSecond } from 'src/components/utilities/utils';
import AreaChart from 'src/components/charts/areachart/AreaChart';
import { DownloadAnalyticsMemberModel } from 'src/models/analyticsModel';
import moment from 'moment';
import { useDashboardActions } from 'src/store/dashboard/actions';
import { customFilterOption } from 'src/components/utilities/utils';
const AnalyticsMember: React.FC = () => {
  const [downloadData, setDownloadData] = useState<Array<DownloadAnalyticsMemberModel>>();
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const dashboardAction = useDashboardActions(dispatch);
  const userAction = useUserActions(dispatch);
  const [finalMemberList, setFinalMemberList] = useState<SelectModel[]>();
  const [filterData, setFilterData] = useState<GetAnalyticsMemberSearchParamModel | null>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [memberActivityFilter, setMemberActivityFilter] = useState('This Month');
  const [memberChartType, setMemberChartType] = useState('Daily');
  const [granularityFilter, setGranularityFilter] = useState<number>(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm();
  const {
    analyticsVideo,
    paginationMeta,
    analyticsMemberSearch,
    isAnalyticsMemberLoading,
    memberList,
    analyticsMemberChart,
    memberActivity
  } = useSelector((state: RootState) => {
    return {
      analyticsVideo: state.analytics.analyticsVideo,
      paginationMeta: state.analytics.paginationMeta,
      analyticsMemberSearch: state.analytics.analyticsMemberSearch,
      isAnalyticsMemberLoading: state.analytics.isAnalyticsMemberLoading,
      memberList: state.users.memberList,
      analyticsMemberChart: state.analytics.analyticsMemberChart,
      memberActivity: state.dashboard.memberActivity
    };
  });

  const [areaChartData, setAreaChartData] = useState({
    labels: [''],
    datasets: [
      {
        fill: true,
        label: '',
        data: [''],
        borderColor: '',
        backgroundColor: ''
      }
    ]
  });

  useEffect(() => {
    if (analyticsMemberChart) {
      setAreaChartData({
        labels:
          analyticsMemberChart &&
          analyticsMemberChart.map(
            (data) => data.eventDate && moment(data.eventDate).format('YYYY-MM-DD')
          ),
        datasets: [
          {
            fill: true,
            label: 'Views',
            data: analyticsMemberChart && analyticsMemberChart.map((data) => data.viewCount),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)'
          },
          {
            fill: true,
            label: 'Logins',
            data: memberActivity && memberActivity.map((data: any) => data.memberCount),
            borderColor: 'rgb(8,68,242)',
            backgroundColor: 'rgb(8,68,242,0.5)'
          }
        ]
      });
    }
  }, [analyticsMemberChart, memberActivity]);

  useEffect(() => {
    if (memberList && memberList.length) {
      const options = memberList.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      const finalMemberOptions = [{ value: '', label: 'Select Member Types' }, ...options];
      setFinalMemberList(finalMemberOptions);
    }
  }, [memberList]);

  useEffect(() => {
    if (analyticsMemberSearch && analyticsMemberSearch.length) {
      const csvData =
        analyticsMemberSearch &&
        analyticsMemberSearch.map((analyticsVideo) => {
          return {
            userName: analyticsVideo.userName,
            email: analyticsVideo.email,
            liveViews: analyticsVideo.liveViews,
            onDemandViews: analyticsVideo.onDemandViews,
            totalTimeWatched: analyticsVideo.totalTimeWatched,
            totalViews: analyticsVideo.totalViews,
            percentageWatched: analyticsVideo.percentageWatched,
            firstView: defaultDateFormatter(analyticsVideo.firstView)
          };
        });
      if (csvData) {
        setDownloadData(csvData);
      }
    }
  }, [analyticsMemberSearch]);
  useEffect(() => {
    const watchFromDate = moment().startOf('month').format('YYYY-MM-DD');
    const watchToDate = moment().endOf('month').format('YYYY-MM-DD');
    const payloadData = {
      pageNumber: 1,
      pageSize: 20
    };
    analyticsAction.getAnalyticsMemberSearchRequest(payloadData);
    analyticsAction.getAnalyticsMemberChartRequest({
      watchFromDate,
      watchToDate,
      granularityFilter
    });
    dashboardAction.getMemberActivityRequest({
      memberValue: 0,
      watchFromDate,
      watchToDate,
      granularityFilter
    });
  }, []);

  useEffect(() => {
    if (showFilter && !memberList.length) {
      userAction.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
    }
  }, [showFilter]);

  const handlePageChange = (pageNumber: number) => {
    if (filterData) {
      const data = { ...filterData, pageNumber, pageSize: 20 };
      analyticsAction.getAnalyticsMemberSearchRequest(data);
    } else {
      const data = { pageNumber, pageSize: 20 };
      analyticsAction.getAnalyticsMemberSearchRequest(data);
    }
  };

  const handleFilterClick = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };
  const onSubmit = (data: any) => {
    const filterPayloadData = {
      fromDate: data.fromDate,
      toDate: data.toDate,
      memberId: data.memberType && data.memberType.value,
      email: data.email,
      memberName: data.memberName,
      pageNumber: 1,
      pageSize: 20
    };
    setFilterData(filterPayloadData);
    analyticsAction.getAnalyticsMemberSearchRequest(filterPayloadData);
  };

  const handleResetClick = () => {
    reset();
    setFilterData(null);
    setValue('memberType', '');
    const payloadData = {
      pageNumber: 1,
      pageSize: 20
    };

    analyticsAction.getAnalyticsMemberSearchRequest(payloadData);
  };

  const handleMemberActivityFilterClick = (data: string) => {
    setMemberActivityFilter(data);
    if (data === 'This Week') {
      const watchFromDate = moment().startOf('week').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('week').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsMemberChartRequest({
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      dashboardAction.getMemberActivityRequest({
        memberValue: 0,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      analyticsAction.getAnalyticsMemberSearchRequest({
        fromDate: watchFromDate,
        toDate: watchToDate
      });
    } else if (data === 'Last Week') {
      const watchFromDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      const watchToDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsMemberChartRequest({
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      dashboardAction.getMemberActivityRequest({
        memberValue: 0,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      analyticsAction.getAnalyticsMemberSearchRequest({
        fromDate: watchFromDate,
        toDate: watchToDate
      });
    } else if (data === 'This Month') {
      const watchFromDate = moment().startOf('month').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('month').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsMemberChartRequest({
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      dashboardAction.getMemberActivityRequest({
        memberValue: 0,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      analyticsAction.getAnalyticsMemberSearchRequest({
        fromDate: watchFromDate,
        toDate: watchToDate
      });
    } else if (data === 'Last Month') {
      const watchFromDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const watchToDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsMemberChartRequest({
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      dashboardAction.getMemberActivityRequest({
        memberValue: 0,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      analyticsAction.getAnalyticsMemberSearchRequest({
        fromDate: watchFromDate,
        toDate: watchToDate
      });
    } else if (data === 'This Year') {
      const watchFromDate = moment().startOf('year').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('year').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsMemberChartRequest({
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      dashboardAction.getMemberActivityRequest({
        memberValue: 0,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
      analyticsAction.getAnalyticsMemberSearchRequest({
        fromDate: watchFromDate,
        toDate: watchToDate
      });
    }
  };
  const getDarkBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 31, 31, 1)'
      : perc < 50
      ? 'rgba(255, 165, 31, 1)'
      : perc < 75
      ? 'rgba(31, 143, 255, 1)'
      : 'rgba(31, 255, 31, 1)';
  };
  const getLightBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 198, 184, 1)'
      : perc < 50
      ? 'rgba(255, 216, 158, 1)'
      : perc < 75
      ? 'rgba(191, 219, 254, 1)'
      : 'rgba(213, 255, 199, 1)';
  };
  return (
    <>
      <div className="flex justify-between">
        <h2 className="font-semibold ">Member Analytics</h2>
        <div className="flex">
          <div>
            {downloadData && (
              <CSVLink className="export" data={downloadData} filename={'AnalyticsMember.csv'}>
                <button type="button" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                  Download CSV
                </button>
              </CSVLink>
            )}
          </div>
          <div>
            <button
              type="button"
              className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
              onClick={handleFilterClick}>
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="mt-4 members-list">
        <div className={`dashboard-card my-5 ${showFilter ? '' : 'hidden'}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-3">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Member Name</label>
                  <input type="text" className="input-style" {...register('memberName')} />
                </div>

                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Member Email</label>
                  <input type="text" className="input-style" {...register('email')} />
                </div>
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">From Date</label>
                  <input type="date" className="input-style" {...register('fromDate')} />
                </div>

                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">To Date</label>
                  <input type="date" className="input-style" {...register('toDate')} />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Member Types </label>

                  {memberList && (
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          options={finalMemberList}
                          value={value}
                          filterOption={customFilterOption}
                          id="memberType"
                          onChange={(val) => onChange(val)}
                        />
                      )}
                      name="memberType"
                      control={control}
                    />
                  )}
                </div>
                <div className="flex justify-center items-center">
                  <button
                    type="submit"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Submit
                  </button>

                  <button
                    onClick={handleResetClick}
                    type="button"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className={`dashboard-card my-5 `}>
          <div className="flex items-center justify-end">
            {/* <div className="dropdown relative mr-3">
              <button
                className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                type="button"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="flex">{memberChartType}</span>
                <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                aria-labelledby="action-dropdown">
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => {
                      setMemberChartType('Daily');
                      setGranularityFilter(1);
                    }}>
                    <span>Daily</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => {
                      setMemberChartType('Monthly');
                      setGranularityFilter(2);
                    }}>
                    <span>Monthly</span>
                  </span>
                </li>
              </ul>
            </div> */}
            <div className="dropdown relative mr-3">
              <button
                className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                type="button"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="flex">{memberActivityFilter}</span>
                <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                aria-labelledby="action-dropdown">
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Week')}>
                    <span>This Week</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('Last Week')}>
                    <span>Last Week</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Month')}>
                    <span>This Month</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('Last Month')}>
                    <span>Last Month</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Year')}>
                    <span>This Year</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <AreaChart
              chartData={areaChartData}
              options={{
                scales: {
                  y: {
                    ticks: {
                      callback: (value: any) => {
                        return Math.floor(value);
                      }
                    },
                    beginAtZero: true
                  }
                }
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left"> Name </div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left"> Email</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Customer Id</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Company Id</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total Videos Watched</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total Live </div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total OnDemand</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Time Watched</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Average Time </div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Average Percentage</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">First Watch </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isAnalyticsMemberLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {analyticsMemberSearch && analyticsMemberSearch.length ? (
                            <>
                              {analyticsMemberSearch &&
                                analyticsMemberSearch.map((analytics, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.memberName}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.email}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.customerId}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.companyId}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.totalViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.liveViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.onDemandViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {analytics.totalTimeWatchedInFormat}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {analytics.averageTimeWatched &&
                                            convertSecondToHourMinuteSecond(
                                              analytics.averageTimeWatched
                                            )}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {analytics.percentageWatched}%
                                          <div
                                            className="w-full bg-blue-200 h-1 rounded-md mt-2"
                                            style={{
                                              backgroundColor: `${getLightBgColorStr(
                                                analytics.percentageWatched
                                              )}`
                                            }}>
                                            <div
                                              className="bg-blue-600 h-1 rounded-md"
                                              style={{
                                                width: `${analytics.percentageWatched}%`,
                                                backgroundColor: `${getDarkBgColorStr(
                                                  analytics.percentageWatched
                                                )}`
                                              }}></div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {analytics.firstView
                                            ? defaultDateFormatter(analytics.firstView)
                                            : ''}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center py-2" colSpan={11}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsMember;
