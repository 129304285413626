import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';

const RadarChart = ({ chartData }: any) => {
  return <Radar data={chartData} />;
};

export default RadarChart;
