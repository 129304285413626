import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const BPointProvider: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isPaymentUpdated, bPoint, message, error } = useSelector((state: RootState) => {
    return {
      isPaymentUpdated: state.setting.isPaymentUpdated,
      bPoint: state.setting.bPoint,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  const [showMerchantPassword, setShowMerchantPassword] = useState(false);

  useEffect(() => {
    if (bPoint) {
      reset(bPoint);
    }
  }, [bPoint]);

  useEffect(() => {
    settingAction.getBPointProviderRequest();
  }, []);

  useEffect(() => {
    if (isPaymentUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isPaymentUpdated]);

  const onSubmit = (data: any) => {
    if (bPoint == undefined || bPoint.id == undefined) {
      settingAction.addBPointProviderRequest(data);
    } else {
      const payloadData = {
        name: 'bPoint',
        updateData: [
          { op: 'replace', path: 'bPointEndPoint', value: data.bPointEndPoint },
          { op: 'replace', path: 'bPointPayURL', value: data.bPointPayURL },
          { op: 'replace', path: 'merchantShortName', value: data.merchantShortName },
          // { op: 'replace', path: 'merchantUserName', value: data.merchantUserName },
          // { op: 'replace', path: 'merchantPassword', value: data.merchantPassword },
          // { op: 'replace', path: 'merchantNumber', value: data.merchantNumber },
          { op: 'replace', path: 'receiptEmail', value: data.receiptEmail },
          { op: 'replace', path: 'billerCode', value: data.billerCode },
          { op: 'replace', path: 'currencyCode', value: data.currencyCode },
          { op: 'replace', path: 'paymentReference', value: data.paymentReference },
          { op: 'replace', path: 'masterCardEnabled', value: data.masterCardEnabled },
          { op: 'replace', path: 'visaCardEnabled', value: data.visaCardEnabled },
          {
            op: 'replace',
            path: 'americanExpressCardEnabled',
            value: data.americanExpressCardEnabled
          },
          { op: 'replace', path: 'dinersCardEnabled', value: data.dinersCardEnabled }
        ]
      };
      settingAction.updatePaymentRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payments</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/paymentProvider">Payments</Link>
          </li>
          <li>
            <strong>BPoint Details</strong>
          </li>
        </ol>
        <Link to={`/paymentProvider`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">BPoint Details</h4>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">BPoint End Point</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'bPointEndPoint'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">BPoint Pay URL</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'bPointPayURL'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Merchant Short Name</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'merchantShortName'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Merchant User Name</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'merchantUserName'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Merchant Password</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showMerchantPassword ? 'text' : 'password'}
              fieldName={'merchantPassword'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showMerchantPassword ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowMerchantPassword(!showMerchantPassword)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Merchant Number</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'password'}
              fieldName={'merchantNumber'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Receipt Email</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'receiptEmail'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Biller Code</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'billerCode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Currency</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'currencyCode'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Payment Reference</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'paymentReference'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enabled Master Card</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="masterCardEnabled"
              {...register('masterCardEnabled')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enabled Visa Card</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="visaCardEnabled"
              {...register('visaCardEnabled')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enabled American Express Card</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="americanExpressCardEnabled"
              {...register('americanExpressCardEnabled')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enabled Diners Card</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="dinersCardEnabled"
              {...register('dinersCardEnabled')}
            />
          </div>
        </div>
        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default BPointProvider;
