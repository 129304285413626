import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserInfoModel } from 'src/models/usersModel';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
import ToastMessage from 'src/components/ToastContainer';
import { errorMsg } from '../utilities/utils';

interface MFAScannerModalProps {
    onClose: () => void;
    userData: any;
}

const MFAScannerModal: React.FC<MFAScannerModalProps> = ({ onClose, userData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userActions = useUserActions(dispatch);
    const { isMfaUpdated, isQrGenerated, qrdata, message } = useSelector((state: RootState) => {
        return {
            isMfaUpdated: state.users.isMfaUpdated,
            isQrGenerated: state.users.isQrGenerated,
            qrdata: state.users.qrdata,
            message: state.users.message,
        };
    });
    const handleBack = () => {
        navigate('/account/MultiFactorAuthentication');
        onClose();
    };

    useEffect(() => {
        userActions.generateMFAQrRequest(userData.UserId)
    }, []);

    const handleContinueClick = () => {
        const data = {
            userId: userData.UserId,
            isMfaSetupEnabled: true,
            userRole: userData.role,
        }
        userActions.UserMfaSetupRequest(data);
    };

    useEffect(() => {
        if (isMfaUpdated) {
            updateMfaSetupStatus(true);
            navigate("/account/CodeVerification");
        }
        else if (message === "Fail")
            errorMsg("Error while updating authentication.");
    }, [isMfaUpdated]);

    const updateMfaSetupStatus = (isEnabled: boolean) => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
            const userData: UserInfoModel = JSON.parse(storedUserData);
            userData.isMfaSetupEnabled = isEnabled;
            localStorage.setItem('userData', JSON.stringify(userData));
        }
    };


    return (<>
        <ToastMessage />
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full m-4">
                <h1 className="font-black sm:text-2xl text-xl p-5 m-0 border-b">Use an authenticator app.</h1>

                <h3 className="font-black mb-4 px-4 pt-4">
                    Use a free authenticator app (such as <a className="cursor-pointer">Google Authenticator, Microsoft Authenticator,</a> or <a>Authy</a>) to scan this QR code to set up your account.
                </h3>

                <div className='m-auto w-auto flex items-center justify-center mb-5'>
                    <img className='h-60 w-60' id="dimg_OF4GZ-rtFo331e8P4bPR-Q4_205"
                        src={`${qrdata?.qrCodeImage}`} height="225" width="225" alt="Automated generation of custom QR codes" data-csiid="OF4GZ-rtFo331e8P4bPR-Q4_22" data-atf="1"></img>
                </div>
                <div className='p-4 border-t flex items-center justify-end gap-4'>
                    <button className="btn btn-primary m-0 h-10 font-bold tracking-widest px-3 py-1 w-auto" onClick={handleBack}>Cancel</button>
                    <button className="btn btn-primary m-0 h-10 font-bold tracking-widest px-3 py-1 w-auto" onClick={handleContinueClick} >Continue</button>
                </div>
            </div>
        </div>
    </>
    );
};

export default MFAScannerModal;