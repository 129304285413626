import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { RootState } from 'src/store/reducers/state';
import Modal from '../components/PackageModal';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { useLocation } from 'react-router-dom';
type locationState = {
  from: string;
  memberPackageId: string;
};
const Packages: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [modalFlag, setModalFlag] = useState('');
  const ref = useRef<HTMLButtonElement>(null);
  const [currentPackageId, setcurrentPackageId] = useState('');
  const { from, memberPackageId } = (location.state as locationState) || {};
  const monetizationAction = useMonetizationActions(dispatch);

  const {
    packagesList,
    packageLoading,
    isPackageAdded,
    isPackageUpdated,
    isPackageDeleted,
    isPackageMemberListAdded,
    isPackageMemberListDeleted
  } = useSelector((state: RootState) => {
    return {
      packagesList: state.monetization.packagesList,
      packageLoading: state.monetization.loadingPackage,
      isPackageAdded: state.monetization.isPackageAdded,
      isPackageUpdated: state.monetization.isPackageUpdated,
      isPackageDeleted: state.monetization.isPackageDeleted,
      isPackageMemberListAdded: state.monetization.isPackageMemberListAdded,
      isPackageMemberListDeleted: state.monetization.isPackageMemberListDeleted
    };
  });

  useEffect(() => {
    monetizationAction.getPackages();
  }, []);

  useEffect(() => {
    if (
      isPackageAdded ||
      isPackageUpdated ||
      isPackageDeleted ||
      isPackageMemberListAdded ||
      isPackageMemberListDeleted
    ) {
      successMsg('Success');
      monetizationAction.resetPackagePolicyDataStateRequest();
      monetizationAction.getPackages();
    }
  }, [
    isPackageAdded,
    isPackageUpdated,
    isPackageDeleted,
    isPackageMemberListAdded,
    isPackageMemberListDeleted
  ]);

  const handleDeletePackage = (packageId: string) => {
    monetizationAction.deletePackageRequest(packageId);
  };

  useEffect(() => {
    if (memberPackageId && from === 'memberlist') {
      setModalFlag('edit');
      setcurrentPackageId(memberPackageId);
      if (ref.current) {
        ref.current.click();
      }
    }
  }, [memberPackageId, from]);
  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
          <button
            ref={ref}
            type="button"
            className="action-menu hidden"
            data-bs-toggle="modal"
            data-bs-target="#editPackageModel"></button>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Packages</h2>
                <div className="md:flex items-center text-center">
                  <button
                    onClick={() => {
                      console.log('add');
                      setModalFlag('add');
                    }}
                    type="button"
                    className="btn-primary w-36 mt-0 mb-1"
                    data-bs-toggle="modal"
                    data-bs-target="#editPackageModel">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className={`overflow-x-auto-sm`}>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Linked Channel</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    {packageLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {packagesList.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {packagesList.map((packageRow, index) => {
                              return (
                                <tr key={index}>
                                  <td className="p-2">
                                    <div className="flex items-center">
                                      <div className="font-medium ">{`${packageRow.name}`}</div>
                                    </div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{packageRow.channelName}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">
                                      <div className="dropdown relative">
                                        <button
                                          className="dropdown-toggle"
                                          type="button"
                                          id="action-dropdown"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false">
                                          <img
                                            src="../../assets/img/dots-vertical.svg"
                                            className="w-5 h-5"
                                          />
                                        </button>
                                        <ul
                                          className="dropdown-menu dropdown-menu-ul"
                                          aria-labelledby="action-dropdown">
                                          <li>
                                            <button
                                              type="button"
                                              className="action-menu flex items-center"
                                              data-bs-toggle="modal"
                                              data-bs-target="#editPackageModel"
                                              onClick={() => {
                                                setModalFlag('edit');
                                                setcurrentPackageId(packageRow.id);
                                                monetizationAction.getPackageDetails(packageRow.id);
                                              }}>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                              </svg>
                                              <span>Edit</span>
                                            </button>
                                          </li>
                                          <li>
                                            <span className="action-menu flex items-center text-red-500 cursor-pointer">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="mr-2 w-4 h-4">
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                              </svg>
                                              <span
                                                onClick={() => handleDeletePackage(packageRow.id)}>
                                                Delete
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal modalFlag={modalFlag} currentPackageId={currentPackageId} />
      </div>
    </>
  );
};

export default Packages;
