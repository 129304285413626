import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddEmailListModal from './components/AddAccessListModal';
import DeleteModal from './components/DeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { RootState } from 'src/store/reducers/state';
const AccessList: React.FC = () => {
  const dispatch = useDispatch();
  const videoLibraryActions = useVideoLibraryActions(dispatch);
  const [registrationId, setRegistrationId] = useState<string>();
  const { registrationAccessList } = useSelector((state: RootState) => {
    return {
      registrationAccessList: state.videoLibrary.registrationAccessList
    };
  });

  useEffect(() => {
    videoLibraryActions.getRegistrationAccessListRequest();
  }, []);
  return (
    <>
      <div className="mt-4 members-list">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Registration Access List</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Video Library</Link>
            </li>
            <li>
              <strong>Access List</strong>
            </li>
          </ol>
        </div>
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative overflow-x-auto">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold "></h2>
                <div className="lg:flex items-center text-center">
                  <button
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#AddEmailListModal">
                    Add Email List
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Email</div>
                        </th>

                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {registrationAccessList &&
                        registrationAccessList.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td className="p-2">{item.email}</td>
                              <td
                                className="p-2"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal">
                                <i
                                  onClick={() => setRegistrationId(item.id)}
                                  className="fa fa-trash cursor-pointer"></i>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddEmailListModal />
      {registrationId && <DeleteModal registrationId={registrationId} />}
    </>
  );
};

export default AccessList;
