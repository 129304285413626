import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCourseActions } from 'src/store/course/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useNavigate } from 'react-router-dom';
const CourseSequence = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseAction = useCourseActions(dispatch);
  const [data, setData] = useState<any>([]);
  const { id } = useParams<string>();
  const [sequenceUpdated, setSequenceUpdated] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<{
    type: string;
    id: string | null;
  }>({
    type: '',
    id: null
  });

  const { courseSequences, isChapterAdded, isCheckpointAdded } = useSelector((state: RootState) => {
    return {
      courseSequences: state.course.courseSequences,
      isChapterAdded: state.course.isChapterAdded,
      isCheckpointAdded: state.course.isCheckpointAdded
    };
  });

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const sourceDroppableId = source.droppableId;
    const destinationDroppableId = destination.droppableId;

    const updatedContents = data.contents.map((chapter: any) => ({
      ...chapter,
      contentOrders: [...(chapter.contentOrders || [])]
    }));

    const sourceChapter = updatedContents.find(
      (chapter: any) =>
        chapter.chapterId === sourceDroppableId ||
        (!chapter.chapterId && sourceDroppableId === 'null')
    );

    const destinationChapter = updatedContents.find(
      (chapter: any) =>
        chapter.chapterId === destinationDroppableId ||
        (!chapter.chapterId && destinationDroppableId === 'null')
    );

    if (sourceChapter && destinationChapter) {
      // Remove the item from the source
      const [movedItem] = sourceChapter.contentOrders.splice(sourceIndex, 1);

      // Add the item to the destination
      destinationChapter.contentOrders.splice(destinationIndex, 0, movedItem);

      // Update state with the new structure
      setData({ ...data, contents: updatedContents });
      setSequenceUpdated(true);
    }
  };

  useEffect(() => {
    if (id) {
      courseAction.getCourseSequencesRequest(id);
    }
  }, [isChapterAdded, isCheckpointAdded]);

  useEffect(() => {
    if (courseSequences) {
      setData(courseSequences);
    }
  }, [courseSequences]);

  useEffect(() => {
    if (sequenceUpdated) {
      if (id) {
        courseAction.saveCourseSequencesRequest(data);
      }
      setSequenceUpdated(false);
    }
  }, [sequenceUpdated]);

  const toggleDropdown = (type: 'chapter' | 'content', id: string | null) => {
    setActiveDropdown((prev) =>
      prev.type === type && prev.id === id ? { type: '', id: null } : { type, id }
    );
  };

  const handleEditContent = (content: any) => {
    if (content.contentTypes == 1 || content.contentTypes == 2) {
      window.open(`/videos/${content.id}`, '_blank');
    } else {
      window.open(`/checkpoint/${id}`, '_blank');
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-menu')) {
      setActiveDropdown({ type: '', id: null });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="course-page">
        {data &&
          data.contents?.map((chapter: any) => (
            <Droppable key={chapter.chapterId || 'null'} droppableId={chapter.chapterId || 'null'}>
              {(provided) => (
                <div
                  className="chapter"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    margin: '10px',
                    padding: '10px',
                    border: chapter.chapterId && chapter.chapterName ? '1px solid gray' : 'none',
                    borderRadius: '4px',
                    position: 'relative'
                  }}>
                  {chapter.chapterId && chapter.chapterName && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <h4>{chapter.chapterName}</h4>
                      <button
                        onClick={() => toggleDropdown('chapter', chapter.chapterId)}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          fontSize: '20px'
                        }}>
                        ⋮
                      </button>
                    </div>
                  )}

                  {activeDropdown.type === 'chapter' && activeDropdown.id === chapter.chapterId && (
                    <div
                      className="dropdown-menu"
                      style={{
                        position: 'absolute',
                        top: '40px',
                        right: '10px',
                        background: 'white',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '4px',
                        zIndex: 100
                      }}>
                      <ul style={{ listStyle: 'none', margin: 0, padding: '10px' }}>
                        <li
                          style={{
                            padding: '5px 10px',
                            cursor: 'pointer'
                          }}
                          onClick={() => window.open(`/chapterlist/${id}`, '_blank')}>
                          Edit Chapter
                        </li>
                      </ul>
                    </div>
                  )}

                  {chapter.contentOrders &&
                    chapter.contentOrders.map((item: any, idx: number) => (
                      <Draggable key={item.id} draggableId={item?.id?.toString()} index={idx}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              padding: '10px',
                              margin: '5px 0',
                              backgroundColor: '#f8f9fa',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                            {item.name}
                            <button
                              onClick={() => toggleDropdown('content', item.id)}
                              style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '20px'
                              }}>
                              ⋮
                            </button>

                            {activeDropdown.type === 'content' && activeDropdown.id === item.id && (
                              <div
                                className="dropdown-menu"
                                style={{
                                  position: 'absolute',
                                  top: '40px',
                                  right: '10px',
                                  background: 'white',
                                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                  borderRadius: '4px',
                                  zIndex: 100
                                }}>
                                <ul style={{ listStyle: 'none', margin: 0, padding: '10px' }}>
                                  <li
                                    style={{
                                      padding: '5px 10px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => handleEditContent(item)}>
                                    Edit Content
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
      </div>
    </DragDropContext>
  );
};

export default CourseSequence;
