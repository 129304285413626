import React, { useEffect, useState } from 'react';
import Editor from 'src/components/Editor/DynamicEditor';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { EventActions, useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { ImageStringConverter } from 'src/components/utilities/utils';
export const EventRemainder = ({ eventReminderClicked }: any) => {
  const dispatch = useDispatch();
  const EventAction = useEventActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const [editorState, setEditorState] = useState<any>([{ editorContent: '' }]);
  const { eventId } = useParams<string>();
  const { eventReminder, isEventReminderUpdateLoading, mediaUrl } = useSelector(
    (state: RootState) => {
      return {
        eventReminder: state.event.eventReminder,
        isEventReminderUpdateLoading: state.event.isEventReminderUpdateLoading,
        mediaUrl: state.videos.mediaUrl
      };
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
    setValue
  } = useForm();
  const { fields, append } = useFieldArray({
    control,
    name: 'reminders'
  });
  useEffect(() => {
    eventId && EventAction.getEventReminderRequest(eventId);
  }, []);

  // useEffect(() => {
  //   if (eventReminder && Object.keys(eventReminder).length && eventReminderClicked) {
  //     videoActions.getMediaUrlRequest(eventReminder.emailReminderBody);
  //   }
  // }, [eventReminder, eventReminderClicked]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl);
    }
  }, [mediaUrl]);

  useEffect(() => {
    if (eventReminder && eventReminder.length) {
      eventReminder &&
        eventReminder?.forEach((item: any, index: any) => {
          append({
            eventId: item.eventId,
            emailReminderNumber: item.emailReminderNumber,
            emailReminderType: item.emailReminderType,
            emailReminder: item.emailReminder,
            emailRemiderTriggerInMinutes: item.emailRemiderTriggerInMinutes,
            emailReminderSubject: item.emailReminderSubject,
            emailReminderBody: item.emailReminderBody,
            id: item.id
          });
        });
    }
  }, [eventReminder]);
  const onSubmit = (data: any, index?: any) => {
    const reminder = data.reminders[index];
    if (eventId) {
      if (reminder.id) {
        const payloadData = {
          id: reminder.id,
          eventId: eventId,
          emailReminder: reminder.emailReminder,
          emailRemiderTriggerInMinutes: reminder.emailRemiderTriggerInMinutes,
          emailReminderSubject: reminder.emailReminderSubject,
          emailReminderBody: ImageStringConverter(reminder.emailReminderBody),
          emailReminderNumber: reminder.emailReminderNumber,
          emailReminderType: reminder.emailReminderType
        };
        EventAction.updateEventReminderRequest(payloadData);
      } else {
        const payloadData = {
          eventId: eventId,
          emailReminder: reminder.emailReminder,
          emailRemiderTriggerInMinutes: reminder.emailRemiderTriggerInMinutes,
          emailReminderSubject: reminder.emailReminderSubject,
          emailReminderBody: ImageStringConverter(reminder.emailReminderBody),
          emailReminderNumber: reminder.emailReminderNumber,
          emailReminderType: reminder.emailReminderType
        };
        EventAction.updateEventReminderRequest(payloadData);
      }
    }
  };

  const addNewReminder = () => {
    append({
      eventId: '',
      emailReminderNumber: fields && fields.length + 1,
      emailReminderType: 1,
      emailReminder: true,
      emailRemiderTriggerInMinutes: 60,
      emailReminderSubject: 'Reminder - %EventName%',
      emailReminderBody:
        "<div class='w-100 ck ck-content'><p>Dear %FirstName%,&nbsp;</p><p><br>This is a reminder that %EventName% is happening on %Date% at %Time%.</p><p>&nbsp;</p><p>Here's a quick rundown of the details:&nbsp;</p><p><br><strong>Title</strong>: %Title%&nbsp;<br><strong>Date</strong>: %Date%&nbsp;<br><strong>Time</strong>: %Time%&nbsp;<br><strong>Duration</strong>: %duration%&nbsp;<br><strong>Link</strong>: <a href=\"%HostUrl%\">%HostUrl%</a></p><p>&nbsp;</p><p><strong>Accessing the Event:</strong>&nbsp;</p><p>Click on the link above, and simply log in with your email address prior to the start time of the event.&nbsp;<br>Whilst it is recommended that you attend the event live, a recording will be available the next day and accessible via the same link above.</p><p>&nbsp;</p><p>Thank you and see you online soon!</p></div>"
    });
  };

  const handleReminderTypeChange = (index: any, value: any) => {
    const newValue =
      value == 1
        ? "<div class='w-100 ck ck-content'><p>Dear %FirstName%,&nbsp;</p><p><br>This is a reminder that %EventName% is happening on %Date% at %Time%.</p><p>&nbsp;</p><p>Here's a quick rundown of the details:&nbsp;</p><p><br><strong>Title</strong>: %Title%&nbsp;<br><strong>Date</strong>: %Date%&nbsp;<br><strong>Time</strong>: %Time%&nbsp;<br><strong>Duration</strong>: %duration%&nbsp;<br><strong>Link</strong>: <a href=\"%HostUrl%\">%HostUrl%</a></p><p>&nbsp;</p><p><strong>Accessing the Event:</strong>&nbsp;</p><p>Click on the link above, and simply log in with your email address prior to the start time of the event.&nbsp;<br>Whilst it is recommended that you attend the event live, a recording will be available the next day and accessible via the same link above.</p><p>&nbsp;</p><p>Thank you and see you online soon!</p></div>"
        : "<div class='w-100 ck ck-content'><p>Hi %FirstName%,</p><p><br>Thank you for your interest in our webinar %Title%.</p><p><br>We’re excited to let you know that it is now available on-demand. Whether you attended the live session or missed it, you can now access the full recording at your convenience.</p><p><br>Access the On-Demand Webinar:%HostUrl%</p><p><br>If you have any questions or need further assistance, feel free to reach out to us.</p><p><br>Thank you for being a part of our community. We look forward to your continued participation and hope to see you at our future events!</p></div>";

    const newSubjectValue =
      value == 1 ? 'Reminder - %EventName%' : 'Access Your Webinar On-Demand Now!';
    setValue(`reminders.${index}.emailReminderBody`, newValue);
    setValue(`reminders.${index}.emailReminderSubject`, newSubjectValue);
  };
  return (
    <div className="tab-pane fade " id="tabs-eventRemainder" role="tabpanel">
      <div className="shadow p-5 border">
        <div className="w-full h-full">
          <div className="w-full">
            {
              <>
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={() => addNewReminder()}
                      className="px-6 btn-primary w-40 mr-2 ml-0 mt-0">
                      Add Reminder
                    </button>
                  </div>

                  <div></div>
                  {fields &&
                    fields.map((item, index) => {
                      return (
                        <div key={item.id}>
                          <h1 className="font-bold text-lg mb-4">{`Reminder ${index + 1}`}</h1>
                          <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2  mb-4">
                            <div className={`form-group form-switch pl-0`}>
                              <label className="block mb-1">Turn on email reminder</label>
                              <input
                                type="checkbox"
                                className="form-check-input switch-btn"
                                id={`reminders.${index}.emailReminder`}
                                {...register(`reminders.${index}.emailReminder`)}
                              />
                            </div>
                            <div className={`form-group`}>
                              <label className="block mb-1">When to send</label>
                              <select
                                {...register(`reminders.${index}.emailReminderType`)}
                                className="input-style"
                                onChange={(e) => handleReminderTypeChange(index, e.target.value)}>
                                <option value={1}>Before</option>
                                <option value={2}>After</option>
                              </select>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2  mb-4">
                            <div className={`form-group`}>
                              <label className="block mb-1">Reminder sent</label>
                              <select
                                {...register(`reminders.${index}.emailRemiderTriggerInMinutes`)}
                                className="input-style">
                                <option value={60}>1 hours</option>
                                <option value={120}>2 hours</option>
                                <option value={240}>4 hours</option>
                                <option value={720}>12 hours</option>
                                <option value={1440}>24 hours</option>
                                <option value={2880}>48 hours</option>
                                <option value={4320}>72 hours</option>
                                <option value={5760}>96 hours</option>
                                <option value={10080}>1 week</option>
                              </select>
                            </div>
                            <div className={`form-group`}>
                              <label className="block mb-1">Email Reminder Subject</label>
                              <input
                                type="text"
                                id={`reminders.${index}.emailReminderSubject`}
                                className="input-style"
                                {...register(`reminders.${index}.emailReminderSubject`)}
                              />
                            </div>
                            <input
                              type="text"
                              id={`reminders.${index}.id`}
                              className="input-style hidden"
                              {...register(`reminders.${index}.id`)}
                            />
                          </div>
                          <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2  mb-4">
                            <div className={`form-group`}>
                              <label className="block mb-1">Email Reminder Body</label>
                              <Controller
                                name={`reminders.${index}.emailReminderBody`}
                                control={control}
                                // defaultValue={item.emailReminderBody}
                                render={({ field }) => (
                                  <Editor data={field.value} setValue={setValue} index={index} />
                                )}
                              />
                            </div>
                          </div>
                          <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                            <button
                              type="submit"
                              onClick={handleSubmit((data) => onSubmit(data, index))}
                              className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                              Save
                            </button>
                            <button
                              type="button"
                              className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                              data-bs-dismiss="modal">
                              Close
                            </button>
                            {isEventReminderUpdateLoading && (
                              <div className="ml-2">
                                <Loader />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </form>
              </>
              //)
            }
          </div>
        </div>
      </div>
    </div>
  );
};
