import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useMonetizationActions } from 'src/store/monetization/actions';
import {
  BundleModel,
  BundleDetailsModel,
  PatchJsonModel,
  PatchJsonData,
  MemberListModel
} from 'src/models/monetizationModel';
import { useUserActions } from 'src/store/users/actions';
import Loader from 'src/components/loader';
import Select, { ActionMeta } from 'react-select';
import makeAnimated from 'react-select/animated';
import { customFilterOption } from 'src/components/utilities/utils';
interface IProps {
  modalFlag: string;
  currentBundleId: string;
}

const BundleModal: React.FC<IProps> = ({ modalFlag, currentBundleId }) => {
  const dispatch = useDispatch();
  const monetizationAction = useMonetizationActions(dispatch);
  const userActions = useUserActions(dispatch);

  //get Member Lists and Channel Names
  const [finalMemberLists, setFinalMemberLists] = useState<any>();

  //Setting values to state variables
  const [heading, setHeading] = useState('Add');
  const [operation, setOperation] = useState('Add');
  const [attachedMembers, setattachedMembers] = useState<any>([]);

  const animatedComponents = makeAnimated();
  const btnRef = useRef<HTMLButtonElement>(null);
  //Getting values from RootState
  const {
    memberLists,
    bundleInfo,
    originalBundleInfo,
    isBundleAdded,
    loadingDetails,
    bundleDetails,
    loadingBundleMemberList,
    isBundleMemberListAdded,
    isBundleMemberListDeleted
  } = useSelector((state: RootState) => {
    return {
      memberLists: state.users.memberList,
      bundleInfo: state.monetization.bundleInfo,
      originalBundleInfo: state.monetization.bundleInfo,
      isBundleAdded: state.monetization.isBundleAdded,
      loadingDetails: state.monetization.loadingBundleDetails,
      bundleDetails: state.monetization.bundleDetails,
      loadingBundleMemberList: state.monetization.loadingBundleMemberList,
      isBundleMemberListAdded: state.monetization.isBundleMemberListAdded,
      isBundleMemberListDeleted: state.monetization.isBundleMemberListDeleted
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<BundleModel>();

  //on click of Add/Update Button
  const onSubmit = (data: BundleModel) => {
    if (modalFlag === 'add') {
      setattachedMembers([]);
      monetizationAction.addBundleRequest(data);
    } else if (modalFlag === 'edit') {
      const patchJsonModel: PatchJsonModel = {
        id: data.id,
        patchJsonData: convertModelToPatch(data)
      };
      if (patchJsonModel.patchJsonData.length > 0)
        monetizationAction.editBundleRequest(patchJsonModel);
    }
  };

  const convertModelToPatch = (data: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = originalBundleInfo;
      if (value[field] == data[field]) continue;

      const patchObjdal: PatchJsonData = {
        from: value[field],
        value: data[field],
        path: '/' + field,
        op: 'replace'
      };
      patchData.push(patchObjdal);
    }
    return patchData;
  };

  const onAttachMember = (option: any, actionMetadata: ActionMeta<any>) => {
    if (actionMetadata.action == 'remove-value') {
      const deleteMemberlistModel: MemberListModel = {
        id: actionMetadata.removedValue.value,
        entityId: currentBundleId,
        name: actionMetadata.removedValue.label
      };
      monetizationAction.deleteBundleMemberListRequest(deleteMemberlistModel);
    } else {
      const addMemberlistModel: MemberListModel = {
        id: actionMetadata.option.value,
        entityId: currentBundleId,
        name: actionMetadata.option.label
      };
      monetizationAction.addBundleMemberListRequest(addMemberlistModel);
    }
    setattachedMembers(option);
  };

  //On Cancel click
  const handleCancelClick = () => {
    /*monetizationAction.resetBundlePolicyDataStateRequest();
    reset({
      name: '',
      quantity: 0,
      memberDiscount: 0,
      discount: 0
    });*/
  };

  //Get the Details about the discount id for different discounts in Parent page
  useEffect(() => {
    if (currentBundleId.length > 0) monetizationAction.getBundleDetails(currentBundleId);
  }, [currentBundleId, isBundleMemberListAdded, isBundleMemberListDeleted]);

  useEffect(() => {
    if (!bundleDetails || !bundleDetails.memberlists) {
      setattachedMembers([]);
      return;
    }
    const selectedMembers = bundleDetails.memberlists.map((item) => ({
      value: item.id,
      label: item.name
    }));
    setattachedMembers(selectedMembers);
  }, [bundleDetails]);

  //Get the Channel and Memberlists details
  useEffect(() => {
    if (!memberLists || memberLists.length == 0) {
      userActions.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
    }
  }, []);

  useEffect(() => {
    if (modalFlag === 'edit') {
      reset(bundleInfo);
      setHeading('Edit');
      setOperation('Update');
    } else {
      setHeading('Add');
      setOperation('Add');
      reset({
        name: '',
        quantity: 0,
        memberDiscount: 0,
        discount: 0
      });
    }
  }, [bundleInfo, modalFlag]);

  //Whenever memberlist value changed, below code will be called
  useEffect(() => {
    if (memberLists && memberLists.length) {
      const tempArray = [...memberLists];
      const options = tempArray.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      setFinalMemberLists(options);
    }
  }, [memberLists]);

  useEffect(() => {
    if (isBundleAdded === true) {
      reset({
        name: '',
        quantity: 0,
        memberDiscount: 0,
        discount: 0
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isBundleAdded]);

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editBundleModel"
      aria-labelledby="editBundleModelLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editBundleModelLabel">
              {`${heading} Bundle Information`}
            </h5>
            <button
              onClick={handleCancelClick}
              type="button"
              ref={btnRef}
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            {loadingDetails ? (
              <Loader />
            ) : (
              <>
                {' '}
                <form>
                  <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Bundle Name </label>
                      <input
                        type="text"
                        className="input-style"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 250
                        })}
                      />
                      {errors.name?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                      {errors.name?.type === 'maxLength' && (
                        <p className="text-red-700"> Exceeded the limit</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Quantity </label>
                      <input
                        type="number"
                        className="input-style"
                        id="quantity"
                        {...register('quantity', {
                          required: true,
                          pattern: /^[0-9.]/
                        })}
                      />
                      {errors.quantity?.type === 'required' && (
                        <p className="text-red-700">Required Field</p>
                      )}
                      {errors.quantity?.type === 'pattern' && (
                        <p className="text-red-700">Only numbers allowed</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Member Discount (%) </label>
                      <input
                        type="number"
                        className="input-style"
                        id="memberDiscount"
                        {...register('memberDiscount', {
                          required: true,
                          max: 100,
                          min: 0,
                          pattern: /^[0-9.]/
                        })}
                      />
                      {errors.memberDiscount?.type === 'required' && (
                        <p className="text-red-700">Required Field</p>
                      )}
                      {errors.memberDiscount?.type === 'max' && (
                        <p className="text-red-700"> Member Discount cannot be greater than 100</p>
                      )}
                      {errors.memberDiscount?.type === 'min' && (
                        <p className="text-red-700"> Member iscount cannot be lesser than 0</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Discount (%) </label>
                      <input
                        type="number"
                        className="input-style"
                        id="discount"
                        {...register('discount', {
                          required: true,
                          max: 100,
                          min: 0,
                          pattern: /^[0-9.]/
                        })}
                      />
                      {errors.discount?.type === 'required' && (
                        <p className="text-red-700">Required Field</p>
                      )}
                      {errors.discount?.type === 'max' && (
                        <p className="text-red-700"> Discount cannot be greater than 100</p>
                      )}
                      {errors.discount?.type === 'min' && (
                        <p className="text-red-700"> Discount cannot be lesser than 0</p>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                    <button
                      type="button"
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)}>
                      {operation}
                    </button>
                    <button
                      onClick={handleCancelClick}
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </form>
              </>
            )}
            {modalFlag == 'edit' && (
              <>
                <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                  <div className={`form-group`}>
                    <label className="block mb-1"> Member Lists </label>
                    {loadingBundleMemberList ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {finalMemberLists && (
                          <Select
                            filterOption={customFilterOption}
                            value={attachedMembers}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={onAttachMember}
                            isMulti
                            options={finalMemberLists}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BundleModal;
