import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import FormInputField from 'src/components/common/formInputField';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useChannelActions } from 'src/store/channel/actions';
import Loader from 'src/components/loader';
import { Link, useParams } from 'react-router-dom';
import { customFilterOption } from 'src/components/utilities/utils';
import { useLoginActions } from 'src/store/login/actions';
type locationState = {
  channelIds: string;
  from: string;
};

const AddVideo = () => {
  const dispatch = useDispatch();
  const loginAction = useLoginActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = useParams<string>();
  const [finalChannels, setFinalChannels] = useState<any>();
  const [channelId, setChannelId] = useState();
  const [captions, setCaptions] = useState();
  const [title, setTitle] = useState();
  const { channelIds, from } = (location.state as locationState) || {};
  const videoActions = useVideosActions(dispatch);
  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();
  const {
    isVideoAdded,
    isVideoAddedLoading,
    channelList,
    productAccess,
    defaultDateFormat,
    videoId
  } = useSelector((state: RootState) => {
    return {
      isVideoAdded: state.videos.isVideoAdded,
      isVideoAddedLoading: state.videos.isVideoAddedLoading,
      channelList: state.channel.channelList,
      productAccess: state.videos.isProductAccess,
      defaultDateFormat: state.login.defaultDateFormat,
      videoId: state.videos.videoId
    };
  });

  useEffect(() => {
    if (channelList && channelIds) {
      const selectedChannel =
        channelList && channelList.filter((item: any) => item.id === channelIds);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].name : ''
        };
      }
      const newData = { channelId: dataRef };
      reset(newData);
    }
  }, [channelList, channelIds]);

  const onSubmit = (data: any) => {
    const finalData = {
      title: data.title,
      channelId: data.channelId.value,
      captions: data.captions,
      videoStatus: status,
      isSearchable: from == 'course' ? false : true
    };
    setChannelId(data.channelId.value);
    setTitle(data.title);
    setCaptions(data.captions);
    videoActions.addVideoRequest(finalData);
  };
  console.log(status);
  useEffect(() => {
    if (isVideoAdded) {
      videoActions.resetVideoLibraryRequest();
      if (status && status == '0') {
        navigate('/upload-video', {
          state: { channelId: channelId, title: title, captions: captions, from: from }
        });
      } else {
        navigate(`/record/${videoId}`, {
          state: { channelId: channelId, title: title, captions: captions }
        });
      }
    }
  }, [isVideoAdded]);

  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);

  useEffect(() => {
    videoActions.getProductAccessRequest();
    loginAction.getDefaultdateFormatRequest();
  }, []);

  return (
    <>
      {!isVideoAddedLoading ? (
        <div className="mt-6 min-h-screen">
          <div className="mb-6">
            <h2 className="font-semibold my-1">Video</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">Videos</Link>
              </li>
              <li>
                <strong>Add</strong>
              </li>
            </ol>
          </div>
          {/* <VideoSidebar /> */}
          <div className="add-video shadow h-fit">
            {/* Upload view */}

            <div className="video-upload lg:w-3/5 p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-4">
                  <FormInputField
                    label={'Title'}
                    type={'text'}
                    fieldName={'title'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="block text-sm font-medium mb-1">Channels</label>
                  {channelList && (
                    <Controller
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Select
                          filterOption={customFilterOption}
                          options={finalChannels}
                          value={value}
                          id="channelId"
                          onChange={(val: any) => onChange(val)}
                          isLoading={channelList.length ? false : true}
                        />
                      )}
                      name="channelId"
                      control={control}
                      rules={{ required: true }}
                    />
                  )}
                </div>

                <div
                  className={`flex items-center ${
                    productAccess && !productAccess.aiGeneratedCaptions ? 'ai-background' : ''
                  }`}>
                  <div className="mr-2 flex">
                    <label>OnDemand Captions AI</label>
                    {/* <i className="fa-solid fa-wand-magic-sparkles ai-icon"></i> */}
                  </div>
                  <div className="form-check form-switch pl-0 flex-items mb-2">
                    <div className="relative">
                      <input
                        disabled={
                          productAccess && !productAccess.aiGeneratedCaptions ? true : false
                        }
                        className="form-check-input switch-btn"
                        type="checkbox"
                        id="captions"
                        {...register('captions')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
                {productAccess && !productAccess.aiGeneratedCaptions && (
                  <p className="bg-purple-100 mt-1">
                    {/* <i className="fa-solid fa-wand-magic-sparkles"></i> */}
                    <span className="font-bold">New! </span>
                    <span className="font-medium">
                      Add Captions AI to any paid plan for a monthly fee.{' '}
                      <a
                        href={`${
                          defaultDateFormat.dateFormat === 'MM/dd/yyyy'
                            ? 'https://buy.stripe.com/5kA3fl3OpcIvcjC8wy'
                            : 'https://buy.stripe.com/8wMdTZdoZaAn5VeeUV'
                        }`}
                        rel="noreferrer"
                        target="_blank"
                        className="underline text-indigo-700 cursor-pointer">
                        Get Captions AI
                      </a>
                    </span>
                  </p>
                )}
                <div className="flex items-center mt-6">
                  <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate('/channels')}
                    className="mx-0 px-6 btn-primary discard-btn w-28  mt-0"
                    data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddVideo;
