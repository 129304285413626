export const getCategoryRequestActionType = 'GET_CATEGORY_REQUEST'
export const getCategorySuccessActionType = 'GET_CATEGORY_REQUEST_SUCCESS'
export const getCategoryFailureActionType = 'GET_CATEGORY_REQUEST_FAILURE'
export const getAllCategoryRequestActionType = 'GET_ALL_CATEGORY_REQUEST'
export const getAllCategorySuccessActionType = 'GET_ALL_CATEGORY_REQUEST_SUCCESS'
export const getAllCategoryFailureActionType = 'GET_ALL_CATEGORY_REQUEST_FAILURE'
export const getSingleCategoryRequestActionType = 'GET_SINGLE_CATEGORY_REQUEST'
export const getSingleCategorySuccessActionType = 'GET_SINGLE_CATEGORY_REQUEST_SUCCESS'
export const getSingleCategoryFailureActionType = 'GET_SINGLE_CATEGORY_REQUEST_FAILURE'
export const deleteCategoryRequestActionType = 'DELETE_CATEGORY_REQUEST'
export const deleteCategorySuccessActionType = 'DELETE_CATEGORY_REQUEST_SUCCESS'
export const deleteCategoryFailureActionType = 'DELETE_CATEGORY_REQUEST_FAILURE'
export const saveCategoryRequestActionType = 'SAVE_CATEGORY_REQUEST'
export const saveCategorySuccessActionType = 'SAVE_CATEGORY_REQUEST_SUCCESS'
export const saveCategoryFailureActionType = 'SAVE_CATEGORY_REQUEST_FAILURE'
export const updateCategoryRequestActionType = 'UPDATE_CATEGORY_REQUEST'
export const updateCategorySuccessActionType = 'UPDATE_CATEGORY_REQUEST_SUCCESS'
export const updateCategoryFailureActionType = 'UPDATE_CATEGORY_REQUEST_FAILURE'
export const updateSpotLightRowFlagRequestActionType = 'UPDATE_SPOTLIGHTROW_FLAG_REQUEST'
export const updateSpotLightRowFlagRequestSuccessActionType = 'UPDATE_SPOTLIGHTROW_FLAG_REQUEST_SUCCESS'
export const updateSpotLightRowFlagRequestFailureActionType = 'UPDATE_SPOTLIGHTROW_FLAG_REQUEST_FAILURE'
export const resetCategoryStateActionType = 'RESET_CATEGORY_STATE_REQUEST'
export const getVideoTagRequestActionType = 'GET_VIDEO_TAG_REQUEST'
export const getVideoTagSuccessActionType = 'GET_VIDEO_TAG_REQUEST_SUCCESS'
export const getVideoTagFailureActionType = 'GET_VIDEO_TAG_REQUEST_FAILURE'
export const saveVideoTagRequestActionType = 'SAVE_VIDEO_TAG_REQUEST'
export const saveVideoTagRequestSuccessActionType = 'SAVE_VIDEO_TAG_REQUEST_SUCCESS'
export const saveVideoTagRequestFailureActionType = 'SAVE_VIDEO_TAG_REQUEST_FAILURE'
export const updateVideoTagRequestActionType = 'UPDATE_VIDEO_TAG_REQUEST'
export const updateVideoTagRequestSuccessActionType = 'UPDATE_VIDEO_TAG_REQUEST_SUCCESS'
export const updateVideoTagRequestFailureActionType = 'UPDATE_VIDEO_TAG_REQUEST_FAILURE'
export const deleteVideoTagRequestActionType = 'DELETE_VIDEO_TAG_REQUEST'
export const deleteVideoTagRequestSuccessActionType = 'DELETE_VIDEO_TAG_REQUEST_SUCCESS'
export const deleteVideoTagRequestFailureActionType = 'DELETE_VIDEO_TAG_REQUEST_FAILURE'
export const getSingleVideoTagRequestActionType = 'GET_SINGLE_VIDEO_TAG_REQUEST'
export const getSingleVideoTagSuccessActionType = 'GET_SINGLE_VIDEO_TAG_REQUEST_SUCCESS'
export const getSingleVideoTagFailureActionType = 'GET_SINGLE_VIDEO_TAG_REQUEST_FAILURE'
export const getContentTypeRequestActionType = 'GET_CONTENT_TYPE_REQUEST'
export const getContentTypeRequestSuccessActionType = 'GET_CONTENT_TYPE_REQUEST_SUCCESS'
export const getContentTypeRequestFailureActionType = 'GET_CONTENT_TYPE_REQUEST_FAILURE'
export const addContentTypeRequestActionType = 'ADD_CONTENT_TYPE_REQUEST'
export const addContentTypeRequestSuccessActionType = 'ADD_CONTENT_TYPE_REQUEST_SUCCESS'
export const addContentTypeRequestFailureActionType = 'ADD_CONTENT_TYPE_REQUEST_FAILURE'
export const updateContentTypeRequestActionType = 'UPDATE_CONTENT_TYPE_REQUEST'
export const updateContentTypeRequestSuccessActionType = 'UPDATE_CONTENT_TYPE_REQUEST_SUCCESS'
export const updateContentTypeRequestFailureActionType = 'UPDATE_CONTENT_TYPE_REQUEST_FAILURE'
export const deleteContentTypeRequestActionType = 'DELETE_CONTENT_TYPE_REQUEST'
export const deleteContentTypeRequestSuccessActionType = 'DELETE_CONTENT_TYPE_REQUEST_SUCCESS'
export const deleteContentTypeRequestFailureActionType = 'DELETE_CONTENT_TYPE_REQUEST_FAILURE'
export const getSingleContentTypeRequestActionType = 'GET_SINGLE_CONTENT_TYPE_REQUEST'
export const getSingleContentTypeRequestSuccessActionType = 'GET_SINGLE_CONTENT_TYPE_REQUEST_SUCCESS'
export const getSingleContentTypeRequestFailureActionType = 'GET_SINGLE_CONTENT_TYPE_REQUEST_FAILURE'
export const getModuleRequestActionType = 'GET_MODULE_REQUEST'
export const getModuleRequestSuccessActionType = 'GET_MODULE_REQUEST_SUCCESS'
export const getModuleRequestFailureActionType = 'GET_MODULE_REQUEST_FAILURE'
export const addModuleRequestActionType = 'ADD_MODULE_REQUEST'
export const addModuleRequestSuccessActionType = 'ADD_MODULE_REQUEST_SUCCESS'
export const addModuleRequestFailureActionType = 'ADD_MODULE_REQUEST_FAILURE'
export const updateModuleRequestActionType = 'UPDATE_MODULE_REQUEST'
export const updateModuleRequestSuccessActionType = 'UPDATE_MODULE_REQUEST_SUCCESS'
export const updateModuleRequestFailureActionType = 'UPDATE_MODULE_REQUEST_FAILURE'
export const deleteModuleRequestActionType = 'DELETE_MODULE_REQUEST'
export const deleteModuleRequestSuccessActionType = 'DELETE_MODULE_REQUEST_SUCCESS'
export const deleteModuleRequestFailureActionType = 'DELETE_MODULE_REQUEST_FAILURE'
export const getSingleModuleRequestActionType = 'GET_SINGLE_MODULE_REQUEST'
export const getSingleModuleRequestSuccessActionType = 'GET_SINGLE_MODULE_REQUEST_SUCCESS'
export const getSingleModuleRequestFailureActionType = 'GET_SINGLE_MODULE_REQUEST_FAILURE'
export const getCategoriesListRequestActionType = 'GET_CATEGORIES_LIST_REQUEST'
export const getCategoriesListRequestSuccessActionType = 'GET_CATEGORIES_LIST_REQUEST_SUCCESS'
export const getCategoriesListRequestFailureActionType = 'GET_CATEGORIES_LIST_REQUEST_FAILURE'
export const getModulesListRequestActionType = 'GET_MODULES_LIST_REQUEST'
export const getModulesListRequestSuccessActionType = 'GET_MODULES_LIST_REQUEST_SUCCESS'
export const getModulesListRequestFailureActionType = 'GET_MODULES_LIST_REQUEST_FAILURE'
export const getAudienceProfilesRequestActionType = 'GET_AUDIENCE_PROFILES_REQUEST'
export const getAudienceProfilesRequestSuccessActionType = 'GET_AUDIENCE_PROFILES_REQUEST_SUCCESS'
export const getAudienceProfilesRequestFailureActionType = 'GET_AUDIENCE_PROFILES_REQUEST_FAILURE'
export const addAudienceProfilesRequestActionType = 'ADD_AUDIENCE_PROFILES_REQUEST'
export const addAudienceProfilesRequestSuccessActionType = 'ADD_AUDIENCE_PROFILES_REQUEST_SUCCESS'
export const addAudienceProfilesRequestFailureActionType = 'ADD_AUDIENCE_PROFILES_REQUEST_FAILURE'
export const updateAudienceProfilesRequestActionType = 'UPDATE_AUDIENCE_PROFILES_REQUEST'
export const updateAudienceProfilesRequestSuccessActionType = 'UPDATE_AUDIENCE_PROFILES_REQUEST_SUCCESS'
export const updateAudienceProfilesRequestFailureActionType = 'UPDATE_AUDIENCE_PROFILES_REQUEST_FAILURE'
export const deleteAudienceProfilesRequestActionType = 'DELETE_AUDIENCE_PROFILES_REQUEST'
export const deleteAudienceProfilesRequestSuccessActionType = 'DELETE_AUDIENCE_PROFILES_REQUEST_SUCCESS'
export const deleteAudienceProfilesRequestFailureActionType = 'DELETE_AUDIENCE_PROFILES_REQUEST_FAILURE'
export const getSingleAudienceProfilesRequestActionType = 'GET_SINGLE_AUDIENCE_PROFILES_REQUEST'
export const getSingleAudienceProfilesRequestSuccessActionType = 'GET_SINGLE_AUDIENCE_PROFILES_REQUEST_SUCCESS'
export const getSingleAudienceProfilesRequestFailureActionType = 'GET_SINGLE_AUDIENCE_PROFILES_REQUEST_FAILURE'
export const getCollectionsListRequestActionType = 'GET_COLLECTIONS_LIST_REQUEST'
export const getCollectionsListRequestSuccessActionType = 'GET_COLLECTIONS_LIST_REQUEST_SUCCESS'
export const getCollectionsListRequestFailureActionType = 'GET_COLLECTIONS_LIST_REQUEST_FAILURE'
export const getCollectionRequestActionType = 'GET_COLLECTION_REQUEST'
export const getCollectionRequestSuccessActionType = 'GET_COLLECTION_REQUEST_SUCCESS'
export const getCollectionRequestFailureActionType = 'GET_COLLECTION_REQUEST_FAILURE'
export const getCollectionVideosRequestActionType = 'GET_COLLECTION_VIDEOS_REQUEST'
export const getCollectionVideosRequestSuccessActionType = 'GET_COLLECTION_VIDEOS_REQUEST_SUCCESS'
export const getCollectionVideosRequestFailureActionType = 'GET_COLLECTION_VIDEOS_REQUEST_FAILURE'
export const addCollectionRequestActionType = 'ADD_COLLECTION_REQUEST'
export const addCollectionRequestSuccessActionType = 'ADD_COLLECTION_REQUEST_SUCCESS'
export const addCollectionRequestFailureActionType = 'ADD_COLLECTION_REQUEST_FAILURE'
export const addCollectionVideosRequestActionType = 'ADD_COLLECTION_VIDEOS_REQUEST'
export const addCollectionVideosRequestSuccessActionType = 'ADD_COLLECTION_VIDEOS_REQUEST_SUCCESS'
export const addCollectionVideosRequestFailureActionType = 'ADD_COLLECTION_VIDEOS_REQUEST_FAILURE'
export const removeCollectionVideosRequestActionType = 'REMOVE_COLLECTION_VIDEOS_REQUEST'
export const removeCollectionVideosRequestSuccessActionType = 'REMOVE_COLLECTION_VIDEOS_REQUEST_SUCCESS'
export const removeCollectionVideosRequestFailureActionType = 'REMOVE_COLLECTION_VIDEOS_REQUEST_FAILURE'
export const removeCollectionRequestActionType = 'REMOVE_COLLECTION_REQUEST'
export const removeCollectionRequestSuccessActionType = 'REMOVE_COLLECTION_REQUEST_SUCCESS'
export const removeCollectionRequestFailureActionType = 'REMOVE_COLLECTION_REQUEST_FAILURE'
export const updateCollectionRequestActionType = 'UPDATE_COLLECTION_REQUEST'
export const updateCollectionRequestSuccessActionType = 'UPDATE_COLLECTION_REQUEST_SUCCESS'
export const updateCollectionRequestFailureActionType = 'UPDATE_COLLECTION_REQUEST_FAILURE'
export const updateCollectionVideoOrderRequestActionType = 'UPDATE_COLLECTION_VIDEO_ORDER_REQUEST'
export const updateCollectionVideoOrderRequestSuccessActionType = 'UPDATE_COLLECTION_VIDEO_ORDER_REQUEST_SUCCESS'
export const updateCollectionVideoOrderRequestFailureActionType = 'UPDATE_COLLECTION_VIDEO_ORDER_REQUEST_FAILURE'