
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { CourseSequencesModel, ChapterModel, UpdateCourseDataModel, CourseModel, CourseModuleModel, CourseCreditTypeModel, CourseModelRequest, CourseVideoSequenceModel, CheckpointsModel, UpdateModel } from 'src/models/courseModel';
import { PatchJsonModel } from 'src/models/monetizationModel';
import { SearchModel } from 'src/models/commonModels';
import { PagingDataModel } from 'src/models/paginationModel';
export namespace CourseActions {

    export type getCourseRequestAction = Action<typeof Constants.getCourseRequestActionType, SearchModel>
    export type getCourseRequestSuccessAction = Action<typeof Constants.getCourseRequestSuccessActionType, PagingDataModel<CourseModel>>;
    export type getCourseRequestFailureAction = Action<typeof Constants.getCourseRequestFailureActionType, null>;

    export function getCourseRequest(P: SearchModel): getCourseRequestAction {
        return ActionCreator(Constants.getCourseRequestActionType, P);
    }
    export function getCourseRequestSuccess(P: PagingDataModel<CourseModel>): getCourseRequestSuccessAction {
        return ActionCreator(Constants.getCourseRequestSuccessActionType, P);
    }
    export function getCourseRequestFailure(): getCourseRequestFailureAction {
        return ActionCreator(Constants.getCourseRequestFailureActionType, null);
    }

    export type saveCourseRequestAction = Action<typeof Constants.saveCourseRequestActionType, CourseModelRequest>
    export type saveCourseRequestSuccessAction = Action<typeof Constants.saveCourseRequestSuccessActionType, any>;
    export type saveCourseRequestFailureAction = Action<typeof Constants.saveCourseRequestFailureActionType, any>;

    export function saveCourseRequest(P: CourseModelRequest): saveCourseRequestAction {
        return ActionCreator(Constants.saveCourseRequestActionType, P);
    }
    export function saveCourseRequestSuccess(R: any): saveCourseRequestSuccessAction {
        return ActionCreator(Constants.saveCourseRequestSuccessActionType, R);
    }
    export function saveCourseRequestFailure(P: any): saveCourseRequestFailureAction {
        return ActionCreator(Constants.saveCourseRequestFailureActionType, P);
    }

    export type getSingleCourseRequestAction = Action<typeof Constants.getSingleCourseRequestActionType, string>
    export type getSingleCourseRequestSuccessAction = Action<typeof Constants.getSingleCourseRequestSuccessActionType, any>;
    export type getSingleCourseRequestFailureAction = Action<typeof Constants.getSingleCourseRequestFailureActionType, null>;

    export function getSingleCourseRequest(P: any): getSingleCourseRequestAction {
        return ActionCreator(Constants.getSingleCourseRequestActionType, P);
    }
    export function getSingleCourseRequestSuccess(P: CourseModel): getSingleCourseRequestSuccessAction {
        return ActionCreator(Constants.getSingleCourseRequestSuccessActionType, P);
    }
    export function getSingleCourseRequestFailure(): getSingleCourseRequestFailureAction {
        return ActionCreator(Constants.getSingleCourseRequestFailureActionType, null);
    }

    export type deleteCourseRequestAction = Action<typeof Constants.deleteCourseRequestActionType, string>
    export type deleteCourseRequestSuccessAction = Action<typeof Constants.deleteCourseRequestSuccessActionType, null>;
    export type deleteCourseRequestFailureAction = Action<typeof Constants.deleteCourseRequestFailureActionType, null>;

    export function deleteCourseRequest(P: string): deleteCourseRequestAction {
        return ActionCreator(Constants.deleteCourseRequestActionType, P);
    }
    export function deleteCourseRequestSuccess(): deleteCourseRequestSuccessAction {
        return ActionCreator(Constants.deleteCourseRequestSuccessActionType, null);
    }
    export function deleteCourseRequestFailure(): deleteCourseRequestFailureAction {
        return ActionCreator(Constants.deleteCourseRequestFailureActionType, null);
    }

    export type updateCourseRequestAction = Action<typeof Constants.updateCourseRequestActionType, PatchJsonModel>
    export type updateCourseRequestSuccessAction = Action<typeof Constants.updateCourseRequestSuccessActionType, null>;
    export type updateCourseRequestFailureAction = Action<typeof Constants.updateCourseRequestFailureActionType, null>;

    export function updateCourseRequest(P: PatchJsonModel): updateCourseRequestAction {
        return ActionCreator(Constants.updateCourseRequestActionType, P);
    }
    export function updateCourseRequestSuccess(): updateCourseRequestSuccessAction {
        return ActionCreator(Constants.updateCourseRequestSuccessActionType, null);
    }
    export function updateCourseRequestFailure(): updateCourseRequestFailureAction {
        return ActionCreator(Constants.updateCourseRequestFailureActionType, null);
    }

    export type getCourseModuleRequestAction = Action<typeof Constants.getCourseModuleRequestActionType, null>
    export type getCourseModuleRequestSuccessAction = Action<typeof Constants.getCourseModuleRequestSuccessActionType, any>;
    export type getCourseModuleRequestFailureAction = Action<typeof Constants.getCourseModuleRequestFailureActionType, null>;

    export function getCourseModuleRequest(): getCourseModuleRequestAction {
        return ActionCreator(Constants.getCourseModuleRequestActionType, null);
    }
    export function getCourseModuleRequestSuccess(P: any): getCourseModuleRequestSuccessAction {
        return ActionCreator(Constants.getCourseModuleRequestSuccessActionType, P);
    }
    export function getCourseModuleRequestFailure(): getCourseModuleRequestFailureAction {
        return ActionCreator(Constants.getCourseModuleRequestFailureActionType, null);
    }

    export type addCourseModuleRequestAction = Action<typeof Constants.addCourseModuleRequestActionType, string>
    export type addCourseModuleRequestSuccessAction = Action<typeof Constants.addCourseModuleRequestSuccessActionType, null>;
    export type addCourseModuleRequestFailureAction = Action<typeof Constants.addCourseModuleRequestFailureActionType, null>;

    export function addCourseModuleRequest(P: string): addCourseModuleRequestAction {
        return ActionCreator(Constants.addCourseModuleRequestActionType, P);
    }
    export function addCourseModuleRequestSuccess(): addCourseModuleRequestSuccessAction {
        return ActionCreator(Constants.addCourseModuleRequestSuccessActionType, null);
    }
    export function addCourseModuleRequestFailure(): addCourseModuleRequestFailureAction {
        return ActionCreator(Constants.addCourseModuleRequestFailureActionType, null);
    }

    export type getCourseCreditTypeRequestAction = Action<typeof Constants.getCourseCreditTypeRequestActionType, null>
    export type getCourseCreditTypeRequestSuccessAction = Action<typeof Constants.getCourseCreditTypeRequestSuccessActionType, any>;
    export type getCourseCreditTypeRequestFailureAction = Action<typeof Constants.getCourseCreditTypeRequestFailureActionType, null>;

    export function getCourseCreditTypeRequest(): getCourseCreditTypeRequestAction {
        return ActionCreator(Constants.getCourseCreditTypeRequestActionType, null);
    }
    export function getCourseCreditTypeRequestSuccess(P: any[]): getCourseCreditTypeRequestSuccessAction {
        return ActionCreator(Constants.getCourseCreditTypeRequestSuccessActionType, P);
    }
    export function getCourseCreditTypeRequestFailure(): getCourseCreditTypeRequestFailureAction {
        return ActionCreator(Constants.getCourseCreditTypeRequestFailureActionType, null);
    }

    export type getCourseResultRequestAction = Action<typeof Constants.getCourseResultRequestActionType, string>
    export type getCourseResultRequestSuccessAction = Action<typeof Constants.getCourseResultRequestSuccessActionType, any>;
    export type getCourseResultRequestFailureAction = Action<typeof Constants.getCourseResultRequestFailureActionType, null>;

    export function getCourseResultRequest(P: any): getCourseResultRequestAction {
        return ActionCreator(Constants.getCourseResultRequestActionType, P);
    }
    export function getCourseResultRequestSuccess(P: any[]): getCourseResultRequestSuccessAction {
        return ActionCreator(Constants.getCourseResultRequestSuccessActionType, P);
    }
    export function getCourseResultRequestFailure(): getCourseResultRequestFailureAction {
        return ActionCreator(Constants.getCourseResultRequestFailureActionType, null);
    }

    export type getCourseVideosRequestAction = Action<typeof Constants.getCourseVideosRequestActionType, string>
    export type getCourseVideosRequestSuccessAction = Action<typeof Constants.getCourseVideosRequestSuccessActionType, any>;
    export type getCourseVideosRequestFailureAction = Action<typeof Constants.getCourseVideosRequestFailureActionType, null>;

    export function getCourseVideosRequest(P: any): getCourseVideosRequestAction {
        return ActionCreator(Constants.getCourseVideosRequestActionType, P);
    }
    export function getCourseVideosRequestSuccess(P: any[]): getCourseVideosRequestSuccessAction {
        return ActionCreator(Constants.getCourseVideosRequestSuccessActionType, P);
    }
    export function getCourseVideosRequestFailure(): getCourseVideosRequestFailureAction {
        return ActionCreator(Constants.getCourseVideosRequestFailureActionType, null);
    }

    export type saveCourseVideoSequenceRequestAction = Action<typeof Constants.addCourseVideoSequenceRequestActionType, CourseVideoSequenceModel>
    export type saveCourseVideoSequenceRequestSuccessAction = Action<typeof Constants.addCourseVideoSequenceRequestSuccessActionType, any>;
    export type saveCourseVideoSequenceRequestFailureAction = Action<typeof Constants.addCourseVideoSequenceRequestFailureActionType, null>;

    export function saveCourseVideoSequenceRequest(P: CourseVideoSequenceModel): saveCourseVideoSequenceRequestAction {
        return ActionCreator(Constants.addCourseVideoSequenceRequestActionType, P);
    }
    export function saveCourseVideoSequenceRequestSuccess(R: any): saveCourseVideoSequenceRequestSuccessAction {
        return ActionCreator(Constants.addCourseVideoSequenceRequestSuccessActionType, R);
    }
    export function saveCourseVideoSequenceRequestFailure(): saveCourseVideoSequenceRequestFailureAction {
        return ActionCreator(Constants.addCourseVideoSequenceRequestFailureActionType, null);
    }

    export type updateCourseOverviewRequestAction = Action<typeof Constants.updateCourseOverviewRequestActionType, UpdateCourseDataModel>
    export type updateCourseOverviewRequestSuccessAction = Action<typeof Constants.updateCourseOverviewRequestSuccessActionType, null>;
    export type updateCourseOverviewRequestFailureAction = Action<typeof Constants.updateCourseOverviewRequestFailureActionType, null>;

    export function updateCourseOverviewRequest(P: UpdateCourseDataModel): updateCourseOverviewRequestAction {
        return ActionCreator(Constants.updateCourseOverviewRequestActionType, P);
    }
    export function updateCourseOverviewRequestSuccess(): updateCourseOverviewRequestSuccessAction {
        return ActionCreator(Constants.updateCourseOverviewRequestSuccessActionType, null);
    }
    export function updateCourseOverviewRequestFailure(): updateCourseOverviewRequestFailureAction {
        return ActionCreator(Constants.updateCourseOverviewRequestFailureActionType, null);
    }

    export type updateCourseEducationRequestAction = Action<typeof Constants.updateCourseEducationRequestActionType, UpdateCourseDataModel>
    export type updateCourseEducationRequestSuccessAction = Action<typeof Constants.updateCourseEducationRequestSuccessActionType, null>;
    export type updateCourseEducationRequestFailureAction = Action<typeof Constants.updateCourseEducationRequestFailureActionType, null>;

    export function updateCourseEducationRequest(P: UpdateCourseDataModel): updateCourseEducationRequestAction {
        return ActionCreator(Constants.updateCourseEducationRequestActionType, P);
    }
    export function updateCourseEducationRequestSuccess(): updateCourseEducationRequestSuccessAction {
        return ActionCreator(Constants.updateCourseEducationRequestSuccessActionType, null);
    }
    export function updateCourseEducationRequestFailure(): updateCourseEducationRequestFailureAction {
        return ActionCreator(Constants.updateCourseEducationRequestFailureActionType, null);
    }

    export type updateCourseProgressRequestAction = Action<typeof Constants.updateCourseProgressRequestActionType, UpdateCourseDataModel>
    export type updateCourseProgressRequestSuccessAction = Action<typeof Constants.updateCourseProgressRequestSuccessActionType, null>;
    export type updateCourseProgressRequestFailureAction = Action<typeof Constants.updateCourseProgressRequestFailureActionType, null>;

    export function updateCourseProgressRequest(P: UpdateCourseDataModel): updateCourseProgressRequestAction {
        return ActionCreator(Constants.updateCourseProgressRequestActionType, P);
    }
    export function updateCourseProgressRequestSuccess(): updateCourseProgressRequestSuccessAction {
        return ActionCreator(Constants.updateCourseProgressRequestSuccessActionType, null);
    }
    export function updateCourseProgressRequestFailure(): updateCourseProgressRequestFailureAction {
        return ActionCreator(Constants.updateCourseProgressRequestFailureActionType, null);
    }

    export type getChaptersRequestAction = Action<typeof Constants.getChaptersRequestActionType, SearchModel>
    export type getChaptersRequestSuccessAction = Action<typeof Constants.getChaptersRequestSuccessActionType, PagingDataModel<ChapterModel>>;
    export type getChaptersRequestFailureAction = Action<typeof Constants.getChaptersRequestFailureActionType, null>;

    export function getChaptersRequest(P: SearchModel): getChaptersRequestAction {
        return ActionCreator(Constants.getChaptersRequestActionType, P);
    }
    export function getChaptersRequestSuccess(P: PagingDataModel<ChapterModel>): getChaptersRequestSuccessAction {
        return ActionCreator(Constants.getChaptersRequestSuccessActionType, P);
    }
    export function getChaptersRequestFailure(): getChaptersRequestFailureAction {
        return ActionCreator(Constants.getChaptersRequestFailureActionType, null);
    }

    export type addChapterRequestAction = Action<typeof Constants.addChapterRequestActionType, ChapterModel>
    export type addChapterRequestSuccessAction = Action<typeof Constants.addChapterRequestSuccessActionType, null>;
    export type addChapterRequestFailureAction = Action<typeof Constants.addChapterRequestFailureActionType, null>;

    export function addChapterRequest(P: ChapterModel): addChapterRequestAction {
        return ActionCreator(Constants.addChapterRequestActionType, P);
    }
    export function addChapterRequestSuccess(): addChapterRequestSuccessAction {
        return ActionCreator(Constants.addChapterRequestSuccessActionType, null);
    }
    export function addChapterRequestFailure(): addChapterRequestFailureAction {
        return ActionCreator(Constants.addChapterRequestFailureActionType, null);
    }

    export type updateChapterRequestAction = Action<typeof Constants.updateChapterRequestActionType, UpdateModel>
    export type updateChapterRequestSuccessAction = Action<typeof Constants.updateChapterRequestSuccessActionType, null>;
    export type updateChapterRequestFailureAction = Action<typeof Constants.updateChapterRequestFailureActionType, null>;

    export function updateChapterRequest(P: UpdateModel): updateChapterRequestAction {
        return ActionCreator(Constants.updateChapterRequestActionType, P);
    }
    export function updateChapterRequestSuccess(): updateChapterRequestSuccessAction {
        return ActionCreator(Constants.updateChapterRequestSuccessActionType, null);
    }
    export function updateChapterRequestFailure(): updateChapterRequestFailureAction {
        return ActionCreator(Constants.updateChapterRequestFailureActionType, null);
    }

    export type deleteChapterRequestAction = Action<typeof Constants.deleteChapterRequestActionType, string>
    export type deleteChapterRequestSuccessAction = Action<typeof Constants.deleteChapterRequestSuccessActionType, null>;
    export type deleteChapterRequestFailureAction = Action<typeof Constants.deleteChapterRequestFailureActionType, null>;

    export function deleteChapterRequest(P: string): deleteChapterRequestAction {
        return ActionCreator(Constants.deleteChapterRequestActionType, P);
    }
    export function deleteChapterRequestSuccess(): deleteChapterRequestSuccessAction {
        return ActionCreator(Constants.deleteChapterRequestSuccessActionType, null);
    }
    export function deleteChapterRequestFailure(): deleteChapterRequestFailureAction {
        return ActionCreator(Constants.deleteChapterRequestFailureActionType, null);
    }

    export type getSingleChapterRequestAction = Action<typeof Constants.getSingleChapterRequestActionType, string>
    export type getSingleChapterRequestSuccessAction = Action<typeof Constants.getSingleChapterRequestSuccessActionType, ChapterModel>;
    export type getSingleChapterRequestFailureAction = Action<typeof Constants.getSingleChapterRequestFailureActionType, null>;

    export function getSingleChapterRequest(P: string): getSingleChapterRequestAction {
        return ActionCreator(Constants.getSingleChapterRequestActionType, P);
    }
    export function getSingleChapterRequestSuccess(P: ChapterModel): getSingleChapterRequestSuccessAction {
        return ActionCreator(Constants.getSingleChapterRequestSuccessActionType, P);
    }
    export function getSingleChapterRequestFailure(): getSingleChapterRequestFailureAction {
        return ActionCreator(Constants.getSingleChapterRequestFailureActionType, null);
    }

    export type getCheckpointsRequestAction = Action<typeof Constants.getCheckpointsRequestActionType, SearchModel>
    export type getCheckpointsRequestSuccessAction = Action<typeof Constants.getCheckpointsRequestSuccessActionType, PagingDataModel<CheckpointsModel>>;
    export type getCheckpointsRequestFailureAction = Action<typeof Constants.getCheckpointsRequestFailureActionType, null>;

    export function getCheckpointsRequest(P: SearchModel): getCheckpointsRequestAction {
        return ActionCreator(Constants.getCheckpointsRequestActionType, P);
    }
    export function getCheckpointsRequestSuccess(P: PagingDataModel<CheckpointsModel>): getCheckpointsRequestSuccessAction {
        return ActionCreator(Constants.getCheckpointsRequestSuccessActionType, P);
    }
    export function getCheckpointsRequestFailure(): getCheckpointsRequestFailureAction {
        return ActionCreator(Constants.getCheckpointsRequestFailureActionType, null);
    }

    export type addCheckpointRequestAction = Action<typeof Constants.addCheckpointRequestActionType, CheckpointsModel>
    export type addCheckpointRequestSuccessAction = Action<typeof Constants.addCheckpointRequestSuccessActionType, null>;
    export type addCheckpointRequestFailureAction = Action<typeof Constants.addCheckpointRequestFailureActionType, null>;

    export function addCheckpointRequest(P: CheckpointsModel): addCheckpointRequestAction {
        return ActionCreator(Constants.addCheckpointRequestActionType, P);
    }
    export function addCheckpointRequestSuccess(): addCheckpointRequestSuccessAction {
        return ActionCreator(Constants.addCheckpointRequestSuccessActionType, null);
    }
    export function addCheckpointRequestFailure(): addCheckpointRequestFailureAction {
        return ActionCreator(Constants.addCheckpointRequestFailureActionType, null);
    }

    export type updateCheckpointRequestAction = Action<typeof Constants.updateCheckpointRequestActionType, UpdateModel>
    export type updateCheckpointRequestSuccessAction = Action<typeof Constants.updateCheckpointRequestSuccessActionType, null>;
    export type updateCheckpointRequestFailureAction = Action<typeof Constants.updateCheckpointRequestFailureActionType, null>;

    export function updateCheckpointRequest(P: UpdateModel): updateCheckpointRequestAction {
        return ActionCreator(Constants.updateCheckpointRequestActionType, P);
    }
    export function updateCheckpointRequestSuccess(): updateCheckpointRequestSuccessAction {
        return ActionCreator(Constants.updateCheckpointRequestSuccessActionType, null);
    }
    export function updateCheckpointRequestFailure(): updateCheckpointRequestFailureAction {
        return ActionCreator(Constants.updateCheckpointRequestFailureActionType, null);
    }

    export type deleteCheckpointRequestAction = Action<typeof Constants.deleteCheckpointRequestActionType, string>
    export type deleteCheckpointRequestSuccessAction = Action<typeof Constants.deleteCheckpointRequestSuccessActionType, null>;
    export type deleteCheckpointRequestFailureAction = Action<typeof Constants.deleteCheckpointRequestFailureActionType, null>;

    export function deleteCheckpointRequest(P: string): deleteCheckpointRequestAction {
        return ActionCreator(Constants.deleteCheckpointRequestActionType, P);
    }
    export function deleteCheckpointRequestSuccess(): deleteCheckpointRequestSuccessAction {
        return ActionCreator(Constants.deleteCheckpointRequestSuccessActionType, null);
    }
    export function deleteCheckpointRequestFailure(): deleteCheckpointRequestFailureAction {
        return ActionCreator(Constants.deleteCheckpointRequestFailureActionType, null);
    }

    export type getSingleCheckpointRequestAction = Action<typeof Constants.getSingleCheckpointRequestActionType, string>
    export type getSingleCheckpointRequestSuccessAction = Action<typeof Constants.getSingleCheckpointRequestSuccessActionType, CheckpointsModel>;
    export type getSingleCheckpointRequestFailureAction = Action<typeof Constants.getSingleCheckpointRequestFailureActionType, null>;

    export function getSingleCheckpointRequest(P: string): getSingleCheckpointRequestAction {
        return ActionCreator(Constants.getSingleCheckpointRequestActionType, P);
    }
    export function getSingleCheckpointRequestSuccess(P: CheckpointsModel): getSingleCheckpointRequestSuccessAction {
        return ActionCreator(Constants.getSingleCheckpointRequestSuccessActionType, P);
    }
    export function getSingleCheckpointRequestFailure(): getSingleCheckpointRequestFailureAction {
        return ActionCreator(Constants.getSingleCheckpointRequestFailureActionType, null);
    }

    export type getCourseSequencesRequestAction = Action<typeof Constants.getCourseSequencesRequestActionType, string>
    export type getCourseSequencesRequestSuccessAction = Action<typeof Constants.getCourseSequencesRequestSuccessActionType, CourseSequencesModel>;
    export type getCourseSequencesRequestFailureAction = Action<typeof Constants.getCourseSequencesRequestFailureActionType, null>;

    export function getCourseSequencesRequest(P: string): getCourseSequencesRequestAction {
        return ActionCreator(Constants.getCourseSequencesRequestActionType, P);
    }
    export function getCourseSequencesRequestSuccess(P: CourseSequencesModel): getCourseSequencesRequestSuccessAction {
        return ActionCreator(Constants.getCourseSequencesRequestSuccessActionType, P);
    }
    export function getCourseSequencesRequestFailure(): getCourseSequencesRequestFailureAction {
        return ActionCreator(Constants.getCourseSequencesRequestFailureActionType, null);
    }

    export type saveCourseSequencesRequestAction = Action<typeof Constants.saveCourseSequencesRequestActionType, CourseSequencesModel>
    export type saveCourseSequencesRequestSuccessAction = Action<typeof Constants.saveCourseSequencesRequestSuccessActionType, null>;
    export type saveCourseSequencesRequestFailureAction = Action<typeof Constants.saveCourseSequencesRequestFailureActionType, null>;

    export function saveCourseSequencesRequest(P: CourseSequencesModel): saveCourseSequencesRequestAction {
        return ActionCreator(Constants.saveCourseSequencesRequestActionType, P);
    }
    export function saveCourseSequencesRequestSuccess(): saveCourseSequencesRequestSuccessAction {
        return ActionCreator(Constants.saveCourseSequencesRequestSuccessActionType, null);
    }
    export function saveCourseSequencesRequestFailure(): saveCourseSequencesRequestFailureAction {
        return ActionCreator(Constants.saveCourseSequencesRequestFailureActionType, null);
    }

    export type resetCourseStateRequestAction = Action<typeof Constants.resetCourseStateActionType, null>

    export function resetCourseStateRequest(): resetCourseStateRequestAction {
        return ActionCreator(Constants.resetCourseStateActionType, null);
    }
}

export type CourseActions = Omit<typeof CourseActions, 'Type'>;
export const useCourseActions = (dispatch: Dispatch) => {
    const { ...actions } = CourseActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as CourseActions;
};