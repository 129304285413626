import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useMonetizationActions } from 'src/store/monetization/actions';
import { RootState } from 'src/store/reducers/state';
import { Link, useParams } from 'react-router-dom';

const SubscriptionsSubscribers: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const monetizationAction = useMonetizationActions(dispatch);

  const { subscribersList, loading } = useSelector((state: RootState) => {
    return {
      subscribersList: state.monetization.subscriptionSubscribersList,
            loading: state.monetization.loadingSubscriptionSubscribers,
        }
  });

  useEffect(() => {
    if (id) {
      monetizationAction.getSubscriptionSubscribers(id);
    }
  }, []);

  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Subscribers</h2>
              </div>
              <div className="p-3">
                <div>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">User Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Stripe Subscription Id</div>
                        </th>
                      </tr>
                    </thead>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {' '}
                        {subscribersList.length ? (
                          <tbody className="text-sm divide-y divide-gray-100">
                            {subscribersList.map((row, index) => {
                              return (
                                <tr key={index}>
                                  <td className="p-2">
                                    <div className="font-medium ">{row.username}</div>
                                  </td>
                                  <td className="p-2">
                                    <div className="text-left">{row.subscriptionId}</div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <tr>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    )
}

export default SubscriptionsSubscribers;
