import React, { useState, useEffect, useRef } from 'react';
import '../../styles.scss';
import FormInputField from 'src/components/common/formInputField';
import { useForm, Controller } from 'react-hook-form';
import { errorMsg } from 'src/components/utilities/utils';
import { RootState } from 'src/store/reducers/state';
import Select from 'react-select';
import { SelectModel } from 'src/models/videosModel';
import { useDispatch, useSelector } from 'react-redux';
import { useChannelActions } from 'src/store/channel/actions';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import { customFilterOption } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
interface IProps {
  id: string;
}
const ChannelInfoTab: React.FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const HeroInputFile = useRef<HTMLInputElement>(null);
  const [videoTypeValue, setVideoTypeValue] = useState<number>(1);
  const [channelTypeValue, setChannelTypeValue] = useState<number>(1);
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const [heroImageUrl, setHeroImageUrl] = useState<string>();
  const [removeHeroImage, setRemoveHeroImage] = useState<boolean>(false);
  const [heroImageName, setHeroImageName] = useState<string>('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
    watch
  } = useForm();

  const {
    singleChannel,
    isChannelSavedLoading,
    isSingleChannelLoading,
    channelList,
    isChannelUpdated
  } = useSelector((state: RootState) => {
    return {
      singleChannel: state.channel.singleChannel,
      isChannelSavedLoading: state.channel.isChannelSavedLoading,
      isSingleChannelLoading: state.channel.isSingleChannelLoading,
      channelList: state.channel.channelList,
      isChannelUpdated: state.channel.isChannelUpdated
    };
  });
  useEffect(() => {
    channelActions.getSingleChannelRequest(id);
    channelActions.getChannelListRequest();
  }, []);
  useEffect(() => {
    if (channelList && channelList.length) {
      const filteredChannel = channelList.filter((channel) => channel.id !== singleChannel.id);
      const filteredOptions = filteredChannel.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels([
        {
          value: '',
          label: 'Select Channel'
        },
        ...filteredOptions
      ]);
    }
  }, [channelList, singleChannel]);

  useEffect(() => {
    if (singleChannel) {
      const selectedChannel =
        channelList && channelList.filter((item) => item.id === singleChannel.parentId);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].displayText : ''
        };
      }
      const finalData = { ...singleChannel, parentId: dataRef };
      reset(finalData);
      setVideoTypeValue(singleChannel.priceType);
      setChannelTypeValue(singleChannel.channelType);
      setHeroImageUrl(singleChannel.highlightImageUrl);
    }
  }, [singleChannel, channelList]);
  const onSubmit = (data: any) => {
    const payloadData = {
      channelId: data.id,
      updateData: [
        { op: 'replace', path: 'name', value: data.name },
        { op: 'replace', path: 'animateVideoCardHome', value: data.animateVideoCardHome },
        { op: 'replace', path: 'authorisationRequired', value: data.authorisationRequired },
        { op: 'replace', path: 'channelCost', value: data.channelCost },
        { op: 'replace', path: 'channelType', value: channelTypeValue },
        { op: 'replace', path: 'priceType', value: videoTypeValue },
        { op: 'replace', path: 'isSpotlight', value: data.isSpotlight },
        { op: 'replace', path: 'heroDescription', value: data.heroDescription },
        { op: 'replace', path: 'homeCardType', value: data.homeCardType },
        { op: 'replace', path: 'privateChannel', value: data.privateChannel },
        { op: 'replace', path: 'publishtoLibrary', value: data.publishtoLibrary },
        { op: 'replace', path: 'useExternalSales', value: data.useExternalSales },
        { op: 'replace', path: 'externalSalesPageUrl', value: data.externalSalesPageUrl },
        { op: 'replace', path: 'videoCost', value: data.videoCost },
        { op: 'replace', path: 'parentId', value: data.parentId ? data.parentId.value : '' },
        { op: 'replace', path: 'displayVideoTitle', value: data.displayVideoTitle },
        { op: 'replace', path: 'highlightImageUrl', value: heroImageUrl },
        { op: 'replace', path: 'meetingId', value: data.meetingId },
        { op: 'replace', path: 'meetingPassword', value: data.meetingPassword }
      ]
    };
    channelActions.updateChannelRequest(payloadData);
  };

  const handleSelectHeroImageClick = () => {
    if (HeroInputFile.current) {
      HeroInputFile.current.click();
    }
  };
  const handleHeroImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      setHeroImageName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/channelhero?channelId=${id}&`,
        setHeroImageUrl,
        'channelImagesUrl'
      );
    }
  };

  useEffect(() => {
    if (isChannelUpdated) {
      setHeroImageName('');
      setRemoveHeroImage(false);
    }
  }, [isChannelUpdated]);

  const useExternalSales = watch('useExternalSales');
  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  return (
    <div className="tab-pane fade show active" id="tabs-channelInfo" role="tabpanel">
      {isChannelSavedLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-4 ml-auto mb-3">
          Save Styles
        </button>
        {isSingleChannelLoading && <Loader />}
        <div className="p-5">
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Name
              </label>
              <p>
                The name of your channel that will appear on headings, menus and labels throughout
                your video channel.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2 ">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative  w-full">
                    <input type="text" className="input-style" id="name" {...register('name')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Channel Type
              </label>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <div className="flex ">
                      <div className="flex items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="channelType"
                            value={channelTypeValue}
                            onChange={() => setChannelTypeValue(1)}
                            checked={channelTypeValue === 1 ? true : false}
                          />
                          <label className="w-16" htmlFor="paid">
                            Video
                          </label>
                        </div>
                      </div>
                      {/* <div className="flex items-center">
                        <div className="form-check ml-2">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="channelType"
                            value={channelTypeValue}
                            onChange={() => setChannelTypeValue(2)}
                            checked={channelTypeValue === 2 ? true : false}
                          />
                          <label className="w-16" htmlFor="free">
                            Zoom
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${channelTypeValue === 2 ? '' : 'hidden'}`}>
            <div className="md:flex items-center mb-8">
              <div className="xl:w-4/12 lg:w-1/2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Zoom Account
                </label>
              </div>
              <div className="xl:w-8/12 lg:w-1/2 ">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                    <div className="relative  w-full">
                      <input
                        type="text"
                        className="input-style"
                        id="zoomAccount"
                        {...register('zoomAccount')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="xl:w-4/12 lg:w-1/2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Meeting Id
                </label>
              </div>
              <div className="xl:w-8/12 lg:w-1/2 ">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                    <div className="relative  w-full">
                      <input
                        type="text"
                        className="input-style"
                        id="meetingId"
                        {...register('meetingId')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-8">
              <div className="xl:w-4/12 lg:w-1/2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Meeting Password
                </label>
              </div>
              <div className="xl:w-8/12 lg:w-1/2 ">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                    <div className="relative  w-full">
                      <input
                        type="text"
                        className="input-style"
                        id="meetingPassword"
                        {...register('meetingPassword')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Video Type
              </label>
              <p>
                Sets the default type of videos uploaded to this channel. Paid videos will require
                purchase, Free videos can be viewer by all users, Locked views will be visible on
                the channel but display a locked icon. An Access List, Package or subscription can
                allow member access to Paid or Locked videos.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <div className="flex ">
                      <div className="flex items-center">
                        <div className="form-check">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="videoType"
                            value={videoTypeValue}
                            onChange={() => setVideoTypeValue(1)}
                            checked={videoTypeValue === 1 ? true : false}
                          />
                          <label className="w-16" htmlFor="paid">
                            Paid
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="form-check ml-2">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="videoType"
                            value={videoTypeValue}
                            onChange={() => setVideoTypeValue(2)}
                            checked={videoTypeValue === 2 ? true : false}
                          />
                          <label className="w-16" htmlFor="free">
                            Free
                          </label>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="form-check  ml-2">
                          <input
                            className="form-check-input radio-btn"
                            type="radio"
                            name="videoType"
                            value={videoTypeValue}
                            onChange={() => setVideoTypeValue(3)}
                            checked={videoTypeValue === 3 ? true : false}
                          />
                          <label className="w-16" htmlFor="locked">
                            Locked
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Channel Security
              </label>
              <p>
                Sets the default level of access on the channel. Authentication Required requires a
                member to login/register before previewing videos within this channel. Private
                Channels will not appear on your Video Channel unless the user is part of a Access
                List or Package linked to the channel.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:ml-10 ml-5 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="authorisationRequired"
                    {...register('authorisationRequired')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
                <h6 className="ml-4">Authentication Required</h6>
              </div>
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:ml-10 ml-5 relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="privateChannel"
                    {...register('privateChannel')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
                <h6 className="ml-4">Private Channel</h6>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Publish to Video Library
              </label>
              <p>
                This channel and its videos will be published and visible on your main video
                library. If turned off then viewers will need access to the channel page link to
                access the content. Best used for an upcoming online conference where you only want
                access via the event channel.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      {...register('publishtoLibrary')}
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Video Price
              </label>
              <p>
                Set the list video price of an individual video that is upload to a Paid channel.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="input-style"
                      id="videoCost"
                      {...register('videoCost')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Channel Price
              </label>
              <p>
                Sets the list price for a channel, allowing a member to purchase the entire channel
                and its videos. This includes child channels and videos.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="input-style"
                      id="channelCost"
                      {...register('channelCost')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Use External Sales
              </label>
              <p>Use an External Sales page to buy channel product.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="useExternalSales"
                      {...register('useExternalSales')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {useExternalSales && (
            <div className="md:flex items-center mb-8">
              <div className="xl:w-4/12 lg:w-1/2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  External Sales Page
                </label>
              </div>
              <div className="xl:w-8/12 lg:w-1/2 ">
                <div className="flex items-center">
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                    <div className="relative  w-full">
                      <input
                        type="text"
                        className="input-style"
                        id="externalSalesPageUrl"
                        {...register('externalSalesPageUrl')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Spotlight Channel
              </label>
              <p>
                A way to highlight popular or new content, by displaying the channel on your
                homepage in a row of video thumbnails. The order of spotlight channels can be edited
                under Spotlight Channels menu.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="isSpotlight"
                      {...register('isSpotlight')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Parent
              </label>
              <p>Selects the parent of the channel to display your channels in a logical order.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative w-full">
                    {finalChannels && Object.keys(finalChannels).length && (
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <Select
                            filterOption={customFilterOption}
                            options={finalChannels}
                            value={value}
                            id="parentId"
                            onChange={(val) => onChange(val)}
                          />
                        )}
                        name="parentId"
                        control={control}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Home Card Type
              </label>
              <p>
                Select the video card style that will be used on your video channel homepage.
                Minamilist is smaller. Default displays more information.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative w-full">
                    <select
                      id="homeCardType"
                      {...register('homeCardType')}
                      className="border border-gray-300 input-style">
                      <option value={0}>Default</option>
                      <option value={1}>Minimalist</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Animate Video Card(Home)
              </label>
              <p>
                Video card hover effect on the video channel homepage, when the user positions the
                computer cursor over the video card.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="animateVideoCardHome"
                      {...register('animateVideoCardHome')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Video Title
              </label>
              <p>Display the video title on the video cards in the highlight reel.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayVideoTitle"
                      {...register('displayVideoTitle')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Hero Image
              </label>
              <p>
                When this channel is selected for main library highlight reel, the hero image will
                be used.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="lg:ml-10 md:ml-10 ml-0">
                {singleChannel &&
                  singleChannel.highlightImageUrl?.length !== 0 &&
                  singleChannel.highlightImageUrl !== null && (
                    <>
                      <img
                        src={`${singleChannel && singleChannel.highlightImageUrl}`}
                        className={`ChannelImageHeight ${removeHeroImage ? 'hidden' : ''}`}
                      />
                    </>
                  )}
                {heroImageUrl && (
                  <span
                    onClick={() => {
                      setHeroImageUrl(''), setRemoveHeroImage(true), setHeroImageName('');
                    }}
                    className="cursor-pointer site-clr">
                    Remove
                  </span>
                )}
              </div>
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <label
                      onClick={handleSelectHeroImageClick}
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                      Upload
                    </label>
                    <span className="ml-2">{heroImageName ? heroImageName : 'No file chosen'}</span>
                    <input
                      ref={HeroInputFile}
                      type="file"
                      onChange={(e) => handleHeroImageUpload(e.target.files)}
                      className="hidden"
                      onClick={onInputClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Hero Description
              </label>
              <p>A brief description of the channel that can be used on the hero image.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <div className="relative w-full">
                    <textarea
                      rows={4}
                      cols={50}
                      className="input-style"
                      id="heroDescription"
                      {...register('heroDescription')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChannelInfoTab;
