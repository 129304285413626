import React, { useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';

const UpdateApiV2 = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();
  const { singleApiV2, isApiV2Updated } = useSelector((state: RootState) => {
    return {
      singleApiV2: state.setting.singleApiV2,
      isApiV2Updated: state.setting.isApiV2Updated
    };
  });
  const onSubmit = (data: any) => {
    const payloadData = {
      id: singleApiV2.id,
      updateData: [
        { op: 'replace', path: 'name', value: data.name },
        { op: 'replace', path: 'role', value: data.role }
      ]
    };
    settingAction.updateApiV2Request(payloadData);
  };

  useEffect(() => {
    if (isApiV2Updated) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isApiV2Updated]);

  useEffect(() => {
    if (singleApiV2) {
      reset(singleApiV2);
    }
  }, [singleApiV2]);
  const handleCloseClick = () => {
    reset({
      name: '',
      role: ''
    });
  };
  return (
    <div>
      <div
        className="modal fade"
        id="UpdateApiV2"
        aria-labelledby="AddNewTagLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddNewTagLabel">
                Update ApiV2
              </h5>

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Key Name'}
                    type={'text'}
                    fieldName={'name'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <label className='className="block mb-1'>Key Permission</label>
                  <select className="input-style" {...register('role')}>
                    <option value={0}>Full Access</option>
                    <option value={1}>Read/Write</option>
                    <option value={2}>Read</option>
                  </select>
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateApiV2;
