import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useSettingActions } from 'src/store/settings/actions';
import { SelectModel } from 'src/models/videosModel';
import { stat } from 'fs';
interface IProps {
  addFlag: boolean,
  providerId: string,
  mappingType: string
}
const AttributeMappingTemplateModel: React.FC<IProps> = ({ addFlag, providerId, mappingType}) => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();
  const {
    attributeMappingColumnList,
    attributeMappingTypeList,
    isAttributeMappingSaved,
    isAttributeMappingUpdate,
    singleAttributeMapping,
    isAttributeMappingDeleted
  } = useSelector((state: RootState) => {
    return {
      attributeMappingColumnList: state.setting.AttributeMappingColumnList,
      attributeMappingTypeList: state.setting.AttributeMappingTypeList,
      isAttributeMappingSaved: state.setting.isAttributeMappingSaved,
      isAttributeMappingUpdate: state.setting.isAttributeMappingUpdate,
      singleAttributeMapping: state.setting.singleAttributeMapping,
      isAttributeMappingDeleted: state.setting.isAttributeMappingDeleted,
    };
  });
  
  useEffect(() => {
    handleCloseClick();
    if (isAttributeMappingSaved || isAttributeMappingUpdate || isAttributeMappingDeleted) {
      if (ref.current) {
        ref.current.click();
        if(providerId){
          settingAction.getAttributeMappingRequest({providerId: providerId, mappingType:mappingType });
        }
      }
    }
  }, [isAttributeMappingSaved, isAttributeMappingUpdate || isAttributeMappingDeleted]);

  useEffect(() => {
    if (singleAttributeMapping) {
      reset(singleAttributeMapping);
    }
  }, [singleAttributeMapping]);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleCloseClick = () => {

    reset({
      providerId: providerId,
      propertyName: '',
      propertyValue: '',
      dynamicColumn: false,
      mappingType: mappingType
    });
    setIsChecked(false)
  };
  
  useEffect(() => {
    if (singleAttributeMapping?.dynamicColumn) {
      setIsChecked(singleAttributeMapping?.dynamicColumn)
    }
    else {
      setIsChecked(false)
    }
  }, [singleAttributeMapping])
  
  const [finalDynamicColumnList, setFinalDynamicColumnList] = useState<SelectModel[]>();
  const [selectedDynamicColumn, setSelectedDynamicColumn] = useState<string>("");

  // const [finalMappingTypeList, setFinalMappingTypeList] = useState<SelectModel[]>();
  // const [selectedMappingType, setSelectedMappingType] = useState<string>("");

  useEffect(() => {
    if (attributeMappingColumnList?.length) {
      setSelectedDynamicColumn(singleAttributeMapping?.propertyValue)
      setValue('propertyValue', singleAttributeMapping?.propertyValue, { shouldValidate: true })
    }
    // if (attributeMappingTypeList?.length) {
    //   setSelectedMappingType(singleAttributeMapping?.mappingType)
    //   setValue('mappingType', singleAttributeMapping?.mappingType, { shouldValidate: true })
    // }
  }, [singleAttributeMapping])

  useEffect(() => {
    if (attributeMappingColumnList) {
      const options = attributeMappingColumnList.map((item: any) => ({
        value: item.columnName,
        label: item.displayName
      }));
      const finalDynamicColumnOptions = [{ value: '', label: 'Select Dynamic Column' }, ...options];
      setFinalDynamicColumnList(finalDynamicColumnOptions);
    }
  }, [attributeMappingColumnList]);

  // useEffect(() => {
  //   console.log('attributeMappingTypeList', attributeMappingTypeList);
  //   if (attributeMappingTypeList) {
  //     const options = attributeMappingTypeList.map((item: any) => ({
  //       value: item.id,
  //       label: item.text
  //     }));
  //     const finalMappingTypeOptions = [{ value: '', label: 'Select Mapping Type' }, ...options];
  //     setFinalMappingTypeList(finalMappingTypeOptions);
  //   }
  // }, [attributeMappingTypeList]);

  const onSubmit = (data: any) => {
    if (addFlag) {
      data.providerId = providerId;
      data.mappingType = mappingType;
      settingAction.addAttributeMappingRequest(data);
    } else {
      const payloadData = {
        id: data.id,
        updateData: [
          { op: 'replace', path: 'providerId', value: providerId },
          { op: 'replace', path: 'propertyName', value: data.propertyName },
          { op: 'replace', path: 'propertyValue', value: data.propertyValue },
          { op: 'replace', path: 'dynamicColumn', value: data.dynamicColumn },
          { op: 'replace', path: 'mappingType', value: mappingType }
        ]
      };
      settingAction.updateAttributeMappingRequest(payloadData);
    }
  };
  //console.log('mappingType:'+ mappingType);
  return (
    <div>
      <div
        className="modal fade"
        id="AddCETemplate"
        aria-labelledby="AddCETemplateLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
            { mappingType == '1' ? (<h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddCETemplateLabel">
                  CE Credit Mapping
              </h5>) : (<h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddCETemplateLabel">
                  Member Response Mapping
              </h5>)}

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Property Name'}
                    type={'text'}
                    fieldName={'propertyName'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                <div className={`form-group mb-3 flex items-center`}>
                  <div className="md:w-1/3">
                    <label>Dynamic Column</label>
                  </div>
                  <div className="md:w-8/12">
                    <div className="flex items-center">
                      <div className="form-check form-switch pl-0 flex-items mb-2">
                        <div className="relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            id="dynamicColumn"
                            {...register('dynamicColumn')}
                            onChange={handleCheckboxChange}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                          <i className="fas fa-times checkbox-cross"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {
                   isChecked ? (<div className={`form-group mb-3`}>
                    <label>Property Value (Dynamic column)</label><br/>
                    <label className="text-red-500">* pick any column from list to replace the column value in JSON</label>
                    <select className="input-style"
                    {...register('propertyValue')}
                    value={selectedDynamicColumn}
                    onChange={(e) => setSelectedDynamicColumn(e.target.value)}>
                    {finalDynamicColumnList?.map((method, idx) => (
                      <option value={method.value} key={idx}>{method.label}</option>
                    ))}
                  </select>
                  </div> ) : 
                  ( <div className={`form-group mb-3`}>
                    <FormInputField
                      label={'Property Value (plain text value)'}
                      type={'text'}
                      fieldName={'propertyValue'}
                      register={register}
                      errors={errors}
                      isRequired={true}
                    />
                  </div>)
                }
                
                {/* <div className={`form-group mb-3`}>
                    <label>Mapping Type</label><br/>
                    <select className="input-style"
                    {...register('mappingType')}
                    value={selectedMappingType}
                    onChange={(e) => setSelectedMappingType(e.target.value)}>
                    {finalMappingTypeList?.map((method, idx) => (
                      <option value={method.value} key={idx}>{method.label}</option>
                    ))}
                  </select>
                  </div> */}

                

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttributeMappingTemplateModel;
