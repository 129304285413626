import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useForm, Controller } from 'react-hook-form';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from 'src/components/ToastContainer';
import RegistrationFields from '../components/RegistrationFields';
import ImagesStyles from '../components/ImagesStyles';
import EmailConfirmation from '../components/EmailConfirmation';
import EventSidebar from '../components/EventSidebar';
import { useNavigate } from 'react-router-dom';
import Editor from 'src/components/Editor';
import { EventRemainder } from '../components/EventRemainder';
import { PatchJsonData, PatchJsonModel } from 'src/models/eventModel';
import { useChannelActions } from 'src/store/channel/actions';
import { SelectModel } from 'src/models/videosModel';
import Select from 'react-select';
import { TimeZones } from 'src/components/utilities/timeZone';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { successMsg } from 'src/components/utilities/utils';
import { minutesToHourMinutes } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import {
  ImageStringConverter,
  miliSecondsToHourMinute,
  getDateAndTime
} from 'src/components/utilities/utils';
import { customFilterOption } from 'src/components/utilities/utils';
import AddNewVideoModal from '../components/AddNewVideoModal';
const EditEvent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { eventId } = useParams<string>();
  const videoAction = useVideosActions(dispatch);
  const [eventReminderClicked, setEventReminderClicked] = useState(false);
  const [eventConfirmationClicked, setEventConfirmationClicked] = useState(false);
  const [editEventClicked, setEditEventClicked] = useState(false);
  const [descriptionEditorState, setDescriptionEditorState] = useState<string>('');
  const [customPrivacyEditorState, setCustomPrivacyEditorState] = useState<string>('');
  const [eventType, setEventType] = useState<number | string>('');
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const eventActions = useEventActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const [currentFormData, setCurrentFormData] = useState<any>([]);
  const [eventInDaysData, setEventInDaysData] = useState<number>(0);
  const [startTime, setStartTime] = useState<string>('00:00');
  const [selectedVideoId, setSelectedVideoId] = useState('');
  const [newVideoData, setNewVideoData] = useState<any>();
  const {
    channels,
    singleEvent,
    isEventSaved,
    isEventUpdated,
    channelList,
    eventVideo,
    isEventVideoLoading,
    isEventReminderUpdated,
    isEventLayoutUpdate,
    isEmailSettingUpdated,
    mediaUrl,
    liveStreamId,
    isVideoUploaded,
    defaultDateFormat
  } = useSelector((state: RootState) => {
    return {
      singleEvent: state.event.singleEvent,
      isEventSaved: state.event.isEventSaved,
      isEventUpdated: state.event.isEventUpdated,
      channels: state.channel.channels,
      channelList: state.channel.channelList,
      eventVideo: state.event.eventVideo,
      isEventVideoLoading: state.event.isEventVideoLoading,
      isEventReminderUpdated: state.event.isEventReminderUpdated,
      isEventLayoutUpdate: state.event.isEventLayoutUpdated,
      isEmailSettingUpdated: state.event.isEmailSettingUpdated,
      mediaUrl: state.videos.mediaUrl,
      liveStreamId: state.videos.liveStreamId,
      isVideoUploaded: state.videos.isVideoUploaded,
      defaultDateFormat: state.login.defaultDateFormat
    };
  });

  useEffect(() => {
    if (isVideoUploaded) {
      if (newVideoData) {
        setValue('duration', miliSecondsToHourMinute(newVideoData.duration));
        setValue('displayDate', newVideoData.date);
        setStartTime(newVideoData.time);
      }
      setValue('videoTitle', liveStreamId.videoName);
      setSelectedVideoId(liveStreamId.videoId);
    }
  }, [isVideoUploaded]);

  useEffect(() => {
    if (singleEvent && Object.keys(singleEvent).length && editEventClicked) {
      videoAction.getMediaUrlRequest(singleEvent.description);
    }
  }, [singleEvent, editEventClicked]);

  useEffect(() => {
    if (mediaUrl) {
      setDescriptionEditorState(mediaUrl);
    }
  }, [mediaUrl]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
    setValue
  } = useForm();

  useEffect(() => {
    if (!channels || channels.length == 0) {
      channelAction.getChannelRequest();
    }
  }, []);
  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);
  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      const finalChannelOptions = [{ value: '', label: 'Select Channel' }, ...options];
      setFinalChannels(finalChannelOptions);
    }
  }, [channelList]);

  useEffect(() => {
    channelAction.getFlattenedChannel();
  }, [channels]);
  useEffect(() => {
    if (eventId) {
      eventActions.getSingleEventRequest(eventId);
    }
  }, [eventId, isEventSaved, isEventUpdated]);

  useEffect(() => {
    if (isEventSaved === true) {
      reset({
        name: '',
        title: '',
        eventType: 0,
        selectedChannel: '',
        description: '',
        displayDate: '',
        displayTime: '',
        timeZoneId: '',
        timezoneFormat: 0,
        eventInDays: true,
        duration: 0,
        registrationHeading: '',
        eventRegistrationSuccessMessage: '',
        loginHeaderText: '',
        isPrivacyStatment: true,
        privacyStatmentValue: '',
        memberRegistrationRequired: true,
        isVideoChannelMembers: true,
        isPasswordRequired: true,
        displayRegistrationForm: true,
        isEventOverview: true
      });
    }
  }, [isEventSaved]);

  const onChange = (e: any) => {
    setStartTime(moment(e).format('HH:mm'));
  };

  useEffect(() => {
    if (singleEvent) {
      reset({
        ...singleEvent,
        displayDate: singleEvent.displayDate
          ? moment(singleEvent.displayDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : '',
        duration: singleEvent.eventInDays
          ? singleEvent.duration && moment.duration(singleEvent.duration, 'minutes').days()
          : singleEvent.duration && minutesToHourMinutes(singleEvent.duration),
        timeZoneId:
          singleEvent.timeZoneId === null ? defaultDateFormat.timeZoneId : singleEvent.timeZoneId
      });

      setStartTime(singleEvent.displayTime);
      setEventType(singleEvent.eventType);
      setEventInDaysData(singleEvent.eventInDays ? 1 : 0);
      setDescriptionEditorState(singleEvent.description ? singleEvent.description : '');
      setCustomPrivacyEditorState(singleEvent.privacyStatmentValue);
      setSelectedVideoId(singleEvent.selectedVideo);
      const tempCurrentData: any = {};
      (tempCurrentData['description'] = singleEvent.description),
        (tempCurrentData['displayRegistrationForm'] = singleEvent.displayRegistrationForm),
        (tempCurrentData['duration'] = singleEvent.eventInDays
          ? singleEvent.duration && moment.duration(singleEvent.duration, 'minutes').days()
          : singleEvent.duration && minutesToHourMinutes(singleEvent.duration)),
        (tempCurrentData['eventRegistrationSuccessMessage'] =
          singleEvent.eventRegistrationSuccessMessage),
        (tempCurrentData['eventType'] = singleEvent.eventType),
        (tempCurrentData['displayEventOverview'] = singleEvent.displayEventOverview),
        (tempCurrentData['isPasswordRequired'] = singleEvent.isPasswordRequired),
        (tempCurrentData['isPrivacyStatment'] = singleEvent.isPrivacyStatment),
        (tempCurrentData['isVideoChannelMembers'] = singleEvent.isVideoChannelMembers),
        (tempCurrentData['loginHeaderText'] = singleEvent.loginHeaderText),
        (tempCurrentData['memberRegistrationRequired'] = singleEvent.memberRegistrationRequired),
        (tempCurrentData['name'] = singleEvent.name),
        (tempCurrentData['privacyStatmentValue'] = singleEvent.privacyStatmentValue),
        (tempCurrentData['registrationHeaderText'] = singleEvent.registrationHeaderText),
        (tempCurrentData['selectedChannel'] = singleEvent.selectedChannel),
        (tempCurrentData['selectedPlayPage'] = singleEvent.selectedPlayPage),
        (tempCurrentData['selectedVideo'] = singleEvent.selectedVideo),
        (tempCurrentData['timeZoneId'] = singleEvent.timeZoneId),
        (tempCurrentData['timezoneFormat'] = singleEvent.timezoneFormat),
        (tempCurrentData['title'] = singleEvent.title),
        (tempCurrentData['videoTitle'] = singleEvent.videoTitle);
      setCurrentFormData(tempCurrentData);
    }
    if (eventId?.length == 0) {
      reset({
        name: '',
        title: '',
        eventType: 0,
        selectedChannel: '',
        description: '',
        displayDate: '',
        displayTime: '',
        timeZoneId: '',
        timezoneFormat: 0,
        eventInDays: true,
        duration: 0,
        registrationHeading: '',
        eventRegistrationSuccessMessage: '',
        loginHeaderText: '',
        isPrivacyStatment: true,
        privacyStatmentValue: '',
        memberRegistrationRequired: true,
        isVideoChannelMembers: true,
        isPasswordRequired: true,
        displayRegistrationForm: true,
        isEventOverview: true
      });
    }
  }, [singleEvent]);
  const handleCancelClick = () => {
    navigate('/events/event');
  };
  const onSubmit = (data: any) => {
    const changedFormData = {
      description: ImageStringConverter(descriptionEditorState),
      displayRegistrationForm: data.displayRegistrationForm,
      displayTime: startTime,
      duration: data.duration,
      displayDate: moment(data.displayDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      eventRegistrationSuccessMessage: data.eventRegistrationSuccessMessage,
      eventType: data.eventType,
      displayEventOverview: data.displayEventOverview,
      isPasswordRequired: data.isPasswordRequired,
      isPrivacyStatment: data.isPrivacyStatment,
      isVideoChannelMembers: data.isVideoChannelMembers,
      loginHeaderText: data.loginHeaderText,
      memberRegistrationRequired: data.memberRegistrationRequired,
      name: data.name,
      privacyStatmentValue: customPrivacyEditorState,
      registrationHeaderText: data.registrationHeaderText,
      selectedChannel: data.selectedChannel.value,
      selectedPlayPage: data.selectedPlayPage,
      selectedVideo: selectedVideoId,
      timeZoneId: data.timeZoneId,
      timezoneFormat: data.timezoneFormat,
      title: data.title
    };
    const patchJsonModel: PatchJsonModel = {
      id: data.id,
      patchJsonData: convertModelToPatch(changedFormData)
    };
    if (patchJsonModel.patchJsonData.length > 0) eventActions.updateEventRequest(patchJsonModel);
  };
  const convertModelToPatch = (data: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = currentFormData;
      // if (value[field] == data[field]) continue;

      const patchObjdal: PatchJsonData = {
        from: value[field],
        value: data[field],
        path: '/' + field,
        op: 'replace'
      };
      patchData.push(patchObjdal);
    }
    return patchData;
  };
  useEffect(() => {
    if (channelList && singleEvent) {
      const selectedChannel =
        channelList && channelList.filter((item) => item.id === singleEvent.selectedChannel);
      if (selectedChannel) {
        const defaultChannel = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].displayText : 'Select Channel'
        };
        setValue('selectedChannel', defaultChannel);
      }
    }
  }, [channelList, singleEvent]);

  useEffect(() => {
    if (isEventUpdated || isEventReminderUpdated || isEventLayoutUpdate || isEmailSettingUpdated) {
      successMsg('Success');
      eventActions.resetEventStateRequest();
    }
  }, [isEventUpdated, isEventReminderUpdated, isEventLayoutUpdate, isEmailSettingUpdated]);
  const handleRedirect = () => {
    navigate(`/events/event`);
  };
  const SelectVideoType = (value: number) => {
    if (value === 0) {
      return 'Paid';
    } else if (value === 1) {
      return 'Free';
    } else if (value === 2) {
      return 'Locked';
    }
  };
  const channelId = watch('selectedChannel');
  const handleChannelChange = (channelId: string) => {
    eventActions.getEventVideoRequest({
      channelId
    });
  };

  const handleEventType = (eventType: string) => {
    setEventType(eventType);
    if (channelId && channelId?.value !== '' && eventType != '1') {
      eventActions.getEventVideoRequest({
        channelId: channelId && channelId.value
      });
    }
  };

  const handleVideoClick = (videoData: any) => {
    if (videoData) {
      setValue('videoTitle', videoData.title);
      setValue('duration', moment(videoData.duration, "hh:mm:ss").format("HH:mm"));
      const dateAndTime = getDateAndTime(videoData.recordDate);
      if (dateAndTime) {
        setValue('displayDate', dateAndTime.formattedDate);
        setStartTime(dateAndTime.formattedTime);
      }
      setSelectedVideoId(videoData.id);
    }
  };

  const handleRemoveVideoClick = (videoData: any) => {
    setValue('videoTitle', null);
    setSelectedVideoId('');
    setValue('duration', '');
    setValue('displayDate', '');
    setStartTime('');
  };

  useEffect(() => {
    if (channelId && channelId?.value !== '' && eventType != '1') {
      eventActions.getEventVideoRequest({
        channelId: channelId && channelId.value
      });
    }
  }, [channelId]);

  return (
    <>
      <ToastMessage />
      <div className="video-categories">
        <div className="mb-2 cursor-pointer p-1" onClick={() => handleRedirect()}>
          <i className="fas fa-arrow-left"></i>
          <span className="ml-2">Go Back</span>
        </div>
        <div className="mb-6 flex justify-between">
          <div>
            <h2 className="font-semibold my-1">Edit Event</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/events/event">Event</Link>
              </li>
              <li>
                <strong>Edit Event</strong>
              </li>
            </ol>
          </div>
          <div>
            {singleEvent && (
              <button
                type="button"
                onClick={() => window.open(`${singleEvent && singleEvent.eventURL}`, '_blank')}
                className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                Launch
              </button>
            )}
          </div>
        </div>
        <div className="lg:flex">
          <EventSidebar
            setEventReminderClicked={setEventReminderClicked}
            setEventConfirmationClicked={setEventConfirmationClicked}
            setEditEventClicked={setEditEventClicked}
          />
          <div className="tab-content w-full shadow border" id="tabs-tabContentVertical">
            {
              <>
                <div className="tab-pane fade show active" id="tabs-eventInfo" role="tabpanel">
                  <div className="shadow p-5 border">
                    <div className="flex justify-center h-full">
                      <div className="xl:flex w-full">
                        {
                          // loadingDetails ? (
                          //   <Loader />
                          // ) : (
                          <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="flex justify-end">
                                <button
                                  type="submit"
                                  className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                                  Save
                                </button>
                              </div>
                              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Name</label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="name"
                                    {...register('name')}
                                  />
                                  {errors.title?.type === 'required' && (
                                    <p className="text-red-700"> Required Field</p>
                                  )}
                                  {errors.title?.type === 'maxLength' && (
                                    <p className="text-red-700"> Exceeded the limit</p>
                                  )}
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">Title Required</label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="title"
                                    {...register('title')}
                                  />
                                  {errors.title?.type === 'required' && (
                                    <p className="text-red-700"> Required Field</p>
                                  )}
                                  {errors.title?.type === 'maxLength' && (
                                    <p className="text-red-700"> Exceeded the limit</p>
                                  )}
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Select Event Type</label>
                                  <select
                                    className="w-full input-style"
                                    {...register('eventType')}
                                    onChange={(event) => {
                                      handleEventType(event.target.value);
                                    }}>
                                    <option value={0} key={0}>
                                      Select Event Type
                                    </option>
                                    <option value={1} key={1}>
                                      Channel
                                    </option>
                                    <option value={2} key={2}>
                                      Video Player
                                    </option>
                                    <option value={3} key={3}>
                                      Play Page
                                    </option>
                                  </select>
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1"> Channel </label>

                                  {channelList && (
                                    <Controller
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState: { error }
                                      }) => (
                                        <Select
                                          options={finalChannels}
                                          filterOption={customFilterOption}
                                          value={value}
                                          id="selectedChannel"
                                          onChange={(val) => {
                                            onChange(val), handleChannelChange(val.value);
                                          }}
                                          isLoading={channelList.length ? false : true}
                                        />
                                      )}
                                      name="selectedChannel"
                                      control={control}
                                      rules={{ required: true }}
                                    />
                                  )}
                                </div>
                              </div>
                              {eventType != '1' && (
                                <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                  <div className={`form-group`}>
                                    <label className="block mb-1">Selected Video</label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="input-style"
                                      id="videoTitle"
                                      {...register('videoTitle')}
                                    />
                                    {errors.title?.type === 'required' && (
                                      <p className="text-red-700"> Required Field</p>
                                    )}
                                    {errors.title?.type === 'maxLength' && (
                                      <p className="text-red-700"> Exceeded the limit</p>
                                    )}
                                  </div>
                                  <button
                                    type="button"
                                    className="btn-primary w-auto borderonly-btn mb-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#addVideoModal">
                                    Add New
                                  </button>
                                </div>
                              )}

                              {eventType != '1' && (
                                <div className="max-h-96 overflow-x-auto">
                                  <table className="table-auto w-full border">
                                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                                      <tr>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">
                                            Presentation Title
                                          </div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Channel</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Views</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Date</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Time</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Type</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Price</div>
                                        </th>
                                        <th className="p-2">
                                          <div className="font-semibold text-left">Actions</div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="text-sm divide-y divide-gray-100">
                                      {isEventVideoLoading ? (
                                        <tr>
                                          <td>
                                            <Loader />
                                          </td>
                                        </tr>
                                      ) : (
                                        <>
                                          {eventVideo && eventVideo.length ? (
                                            <>
                                              {eventVideo &&
                                                eventVideo.map((item: any) => {
                                                  return (
                                                    <tr key={item.id}>
                                                      <td className="p-2">{item.title}</td>
                                                      <td className="p-2">{item.channelName}</td>
                                                      <td className="p-2"></td>
                                                      <td className="p-2">
                                                        {moment(item.recordDate).format(
                                                          'DD/MM/YYYY'
                                                        )}
                                                      </td>
                                                      <td className="p-2">
                                                        {moment(item.recordDate).format('hh:mm A')}
                                                      </td>
                                                      <td className="p-2">
                                                        {SelectVideoType(item.videoType)}
                                                      </td>
                                                      <td className="p-2">{item.videoCost}</td>
                                                      <td className="">
                                                        {item.id != selectedVideoId ? (
                                                          <button
                                                            onClick={() => handleVideoClick(item)}
                                                            type="button"
                                                            className="btn-primary w-auto borderonly-btn mb-2">
                                                            Add
                                                          </button>
                                                        ) : (
                                                          <button
                                                            onClick={() =>
                                                              handleRemoveVideoClick(item)
                                                            }
                                                            type="button"
                                                            className="btn-danger w-auto borderonly-btn mb-2">
                                                            Remove
                                                          </button>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                            </>
                                          ) : (
                                            <tr>
                                              <td className="text-center py-2" colSpan={9}>
                                                No Data Found
                                              </td>
                                            </tr>
                                          )}
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              )}

                              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 mt-4">
                                <div className={`form-group`}>
                                  <label className="block text-sm font-medium mb-1">
                                    Description
                                  </label>
                                  <Editor
                                    editorState={descriptionEditorState}
                                    setEditorState={setDescriptionEditorState}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Event Date</label>
                                  <input
                                    type="date"
                                    className="input-style"
                                    id="displayDate"
                                    {...register('displayDate')}
                                  />
                                </div>
                                <div className={`form-group event-timepicker`}>
                                  <label className="block mb-1">Start Time</label>

                                  <TimePicker
                                    className="w-full"
                                    onChange={(e) => onChange(e)}
                                    value={startTime ? moment(startTime, 'HH:mm') : undefined}
                                    showSecond={false}
                                    use12Hours={true}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Timezone</label>
                                  <select
                                    className="w-full input-style"
                                    {...register('timeZoneId')}>
                                    {TimeZones &&
                                      TimeZones.map((timezone, index) => {
                                        return (
                                          <option key={index} value={timezone.value}>
                                            {timezone.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">Timezone Format</label>
                                  <select
                                    className="w-full input-style "
                                    {...register('timezoneFormat')}>
                                    <option value={0}>Select Timezone Format</option>
                                    <option value={1}>Abbreviation</option>
                                    <option value={2}>GMT</option>
                                  </select>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className="flex items-center">
                                  <div className="flex items-center">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input radio-btn"
                                        type="radio"
                                        checked={eventInDaysData === 0 ? true : false}
                                        onChange={() => {
                                          setEventInDaysData(0), setValue('duration', '01:00');
                                        }}
                                      />
                                      <label className="w-16" htmlFor="singleday">
                                        Single Day
                                      </label>
                                    </div>
                                  </div>
                                  <div className="flex items-center ml-2">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input radio-btn"
                                        type="radio"
                                        checked={eventInDaysData === 1 ? true : false}
                                        onChange={() => {
                                          setEventInDaysData(1), setValue('duration', '1');
                                        }}
                                      />
                                      <label className="w-16" htmlFor="multipldays">
                                        Multiple Days
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">
                                    Duration {eventInDaysData ? '(no.of days)' : '(hh:mm)'}
                                  </label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="duration"
                                    {...register('duration')}
                                    placeholder={eventInDaysData ? '' : 'hh:mm'}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Registration Heading</label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="registrationHeaderText"
                                    {...register('registrationHeaderText')}
                                  />
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">
                                    Registration Confirmation Message
                                  </label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="eventRegistrationSuccessMessage"
                                    {...register('eventRegistrationSuccessMessage')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Login Header</label>
                                  <input
                                    type="text"
                                    className="input-style"
                                    id="loginHeaderText"
                                    {...register('loginHeaderText')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Enable Privacy Statement</label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="isPrivacyStatment"
                                    {...register('isPrivacyStatment')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 privacyStatement">
                                <div className={`form-group`}>
                                  <label className="block text-sm font-medium mb-1">
                                    Enter Custom Privacy Statement
                                  </label>
                                  <Editor
                                    editorState={customPrivacyEditorState}
                                    setEditorState={setCustomPrivacyEditorState}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch">
                                <div className={`form-group`}>
                                  <label className="block mb-1">All members must register</label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="memberRegistrationRequired"
                                    {...register('memberRegistrationRequired')}
                                  />
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">
                                    Registrations Are Video Channel Members
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="isVideoChannelMembers"
                                    {...register('isVideoChannelMembers')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch">
                                <div className={`form-group`}>
                                  <label className="block mb-1">
                                    Password Is Required On Registration
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="isPasswordRequired"
                                    {...register('isPasswordRequired')}
                                  />
                                </div>
                                <div className={`form-group`}>
                                  <label className="block mb-1">Display Registration Form</label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="displayRegistrationForm"
                                    {...register('displayRegistrationForm')}
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch">
                                <div className={`form-group`}>
                                  <label className="block mb-1">Display Event Overview</label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn"
                                    id="displayEventOverview"
                                    {...register('displayEventOverview')}
                                  />
                                </div>
                              </div>
                              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                                <button
                                  type="submit"
                                  className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                                  Save
                                </button>
                                <button
                                  onClick={handleCancelClick}
                                  type="button"
                                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                                  data-bs-dismiss="modal">
                                  Close
                                </button>
                              </div>
                            </form>
                          </>
                          //)
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <RegistrationFields />
                <ImagesStyles />
                <EmailConfirmation eventConfirmationClicked={eventConfirmationClicked} />
                <EventRemainder eventReminderClicked={eventReminderClicked} />
              </>
            }
          </div>
        </div>
        <AddNewVideoModal
          channelName={singleEvent.selectedChannel}
          eventTitle={singleEvent.title}
          setNewVideoData={setNewVideoData}
        />
      </div>
    </>
  );
};

export default EditEvent;
