import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const EzidebitProvider: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isPaymentUpdated, ezidebit, message, error } = useSelector((state: RootState) => {
    return {
      isPaymentUpdated: state.setting.isPaymentUpdated,
      ezidebit: state.setting.ezidebit,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  useEffect(() => {
    if (ezidebit) {
      reset(ezidebit);
    }
  }, [ezidebit]);

  useEffect(() => {
    settingAction.getEziDebitProviderRequest();
  }, []);

  useEffect(() => {
    if (isPaymentUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isPaymentUpdated]);

  const onSubmit = (data: any) => {
    if (ezidebit == undefined || ezidebit.id == undefined) {
      settingAction.addEzidebitProviderRequest(data);
    } else {
      const payloadData = {
        name: 'ezidebit',
        updateData: [
          { op: 'replace', path: 'digitalKey', value: data.digitalKey },
          { op: 'replace', path: 'publicKey', value: data.publicKey },
          { op: 'replace', path: 'isLive', value: data.isLive }
        ]
      };
      settingAction.updatePaymentRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payments</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/paymentProvider">Payments</Link>
          </li>
          <li>
            <strong>Ezidebit Provider</strong>
          </li>
        </ol>
        <Link to={`/paymentProvider`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">Ezidebit Provider</h4>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Digital Key</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'digitalKey'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Public Key</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'publicKey'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Enable live mode</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="isLive"
              {...register('isLive')}
            />
          </div>
        </div>

        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EzidebitProvider;
