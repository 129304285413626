import { all, put, takeEvery } from 'redux-saga/effects';
import { AnalyticsActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';
import moment from 'moment';
import { ActivityData } from 'src/components/admin/analytics/course/ActivityData';
function* asyncGetAnalyticsVideo(data: AnalyticsActions.getAnalyticsVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_ANALYTICS_VIDEO.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnalyticsVideoRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsVideoRequestFailure());
    }
}
function* asyncGetAnalyticsVideoDownload(data: AnalyticsActions.getAnalyticsVideoDownloadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_ANALYTICS_VIDEO.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnalyticsVideoDownloadRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsVideoDownloadRequestFailure());
    }
}

function* asyncGetAnalyticsVideoChart(data: AnalyticsActions.getAnalyticsVideoChartRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_ANALYTICS_VIDEO_CHART.url}?PageNumber=1&WatchedFromDate=${data.payload?.videoFromDate}&WatchedToDate=${data.payload?.videoToDate}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnalyticsVideoChartRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsVideoChartRequestFailure());
    }
}

function* asyncGetAnalyticsMember(data: AnalyticsActions.getAnalyticsMembersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ANALYTICS_MEMBER.url}?pageNo=${data.payload?.pageNo}&memberId=${data.payload?.memberId ? data.payload?.memberId : ''}&pageSize=${data.payload?.pageSize ? data.payload?.pageSize : 10}&email=${data.payload?.email ? data.payload?.email : ''}&memberName=${data.payload?.memberName ? data.payload?.memberName : ''}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnalyticsMembersRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsMembersRequestFailure());
    }
}

function* asyncGetSalesOrder(data: AnalyticsActions.getSalesOrderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_SALES_ORDERS.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSalesOrderRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSalesOrderRequestFailure());
    }
}

function* asyncDownloadSalesOrder(data: AnalyticsActions.downloadSalesOrderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_SALES_ORDERS.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.downloadSalesOrderRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.downloadSalesOrderRequestFailure());
    }
}

function* asyncGetAnalyticsCourse(data: AnalyticsActions.getCourseAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_ANALYTICS_COURSE.url}?memberid=${data.payload?.id}&pageNumber=${data.payload?.pageNumber}&pageSize=10`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getCourseAnalyticsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getCourseAnalyticsRequestFailure());
    }
}
function* asyncGetAnalyticsMemberChart(data: AnalyticsActions.getAnalyticsMemberChartRequestAction) {
    try {
        if (data.payload) {
            const response: AxiosResponse = yield ApiService.postData(URLS.GET_MEMBER_VIEWS.url, {
                watchFromDate: data.payload.watchFromDate ? data.payload.watchFromDate : '',
                watchToDate: data.payload.watchToDate ? data.payload.watchToDate : '',
                pageNumber: 1,
                pageSize: 30,
                granularityFilter: data.payload.granularityFilter
            });
            if (response.status === 200) {
                yield put(AnalyticsActions.getAnalyticsMemberChartRequestSuccess(response.data.result));
            }
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsMemberChartRequestFailure());
    }
}
function* asyncGetCourseTotal() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_COURSE_WIDGETS.url);
        if (response.status === 200) {
            yield put(AnalyticsActions.getCourseTotalRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getCourseTotalRequestFailure());
    }
}
function* asyncGetCourseResults(data: AnalyticsActions.getCourseResultsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COURSE_RESULTS.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getCourseResultsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getCourseResultsRequestFailure());
    }
}
function* asyncGetViewActivity(data: AnalyticsActions.getViewActivityRequestAction) {
    try {
        // const response: AxiosResponse = yield ApiService.getData(``);
        // if (response.status === 200) {
        //     yield put(AnalyticsActions.getViewActivityRequestSuccess(response.data.result));
        // }
        yield put(AnalyticsActions.getViewActivityRequestSuccess(ActivityData));
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getViewActivityRequestFailure());
    }
}
function* asyncGetViewSubmission(data: AnalyticsActions.getViewSubmissionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_VIEW_SUBMISSIONS.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getViewSubmissionRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getViewSubmissionRequestFailure());
    }
}
function* asyncGetQuizTotal(data: AnalyticsActions.getQuizTotalRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_QUIZ_TOTAL.url);
        if (response.status === 200) {
            yield put(AnalyticsActions.getQuizTotalRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getQuizTotalRequestFailure());
    }
}
function* asyncGetQuizAnalytics(data: AnalyticsActions.getQuizAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUIZ_ANALYTICS.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getQuizAnalyticsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getQuizAnalyticsRequestFailure());
    }
}
function* asyncGetQuizQuestions(data: AnalyticsActions.getQuizQuestionsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUIZ_QUESTIONS.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getQuizQuestionsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getQuizQuestionsRequestFailure());
    }
}
function* asyncGetSingleCourseResult(data: AnalyticsActions.getSingleCourseResultRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_COURSE_RESULT.url}?courseid=${data.payload?.id}&memberFilter=${data.payload?.querySearch}&pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSingleCourseResultRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSingleCourseResultRequestFailure());
    }
}

function* asyncGetRecentCourseActivity(data: AnalyticsActions.getRecentCourseActivityRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_RECENT_COURSE_ACTIVITY.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getRecentCourseActivityRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getRecentCourseActivityRequestFailure());
    }
}

function* asyncGetRecentGrade(data: AnalyticsActions.getRecentGradeRequestAction) {
    try {
        // const response: AxiosResponse = yield ApiService.getData(``);
        // if (response.status === 200) {
        //     yield put(AnalyticsActions.getRecentGradeRequestSuccess(response.data.result));
        // }
        yield put(AnalyticsActions.getRecentGradeRequestSuccess(ActivityData));
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getRecentGradeRequestFailure());
    }
}

function* asyncGetSingleQuizQuestion(data: AnalyticsActions.getSingleQuizQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUIZ_ANSWERS_ANALYTICS.url}/${data.payload?.id}/answers?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSingleQuizQuestionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSingleQuizQuestionRequestFailure());
    }
}

function* asyncGetQuestionByQuizId(data: AnalyticsActions.getQuestionByQuizIdRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUESTION_BY_QUIZ_ID_ANALYTICS.url}/${data.payload?.id}/questions?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getQuestionByQuizIdRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getQuestionByQuizIdRequestFailure());
    }
}
function* asyncGetPollAnalytics(data: AnalyticsActions.getPollAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_POLL_ANALYTICS.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}&pollType=${data.payload?.pollType ? data.payload?.pollType : -1}&channelName=${data.payload?.channelName ? data.payload?.channelName : ''}&pollTitle=${data.payload?.pollTitle ? data.payload?.pollTitle : ''}&videoTitle=${data.payload?.videoTitle ? data.payload?.videoTitle : ''}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getPollAnalyticsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getPollAnalyticsRequestFailure());
    }
}
function* asyncGetPollQuestion(data: AnalyticsActions.getPollQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_POLL_QUESTIONS.url}?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getPollQuestionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getPollQuestionRequestFailure());
    }
}
function* asyncGetSinglePoll(data: AnalyticsActions.getSinglePollRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_POLLS.url}/${data.payload?.id}/answers?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSinglePollRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSinglePollRequestFailure());
    }
}
function* asyncGetSinglePollQuestion(data: AnalyticsActions.getSinglePollQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_POLL_QUESTION.url}/${data.payload?.id}/questions?pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSinglePollQuestionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getPollQuestionRequestFailure());
    }
}

function* asyncGetAnswerQuestion(data: AnalyticsActions.getAnswerQuestionRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COURSE_MEMBER_QUESTION.url}?courseid=${data.payload?.courseId}&memberid=${data.payload?.memberId}&pageNumber=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnswerQuestionRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnswerQuestionRequestFailure());
    }
}

function* asyncGetSalesOrderTotal() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SALES.url);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSalesOrderTotalRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSalesOrderTotalRequestFailure());
    }
}

function* asyncGetSingleQuizTotal(data: AnalyticsActions.getSingleQuizTotalRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_QUIZ_WIDGETS.url}?quizid=${data.payload}`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSingleQuizTotalRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSingleQuizTotalRequestFailure());
    }
}

function* asyncGetAnalyticsMemberSearch(data: AnalyticsActions.getAnalyticsMemberSearchRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.GET_ANALYTICS_MEMBER_SEARCH.url}`, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getAnalyticsMemberSearchRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getAnalyticsMemberSearchRequestFailure());
    }
}

function* asyncGetQuizQuestionData(data: AnalyticsActions.getQuizQuestionDataRequestAction) {
    try {
        // const hardcodedChoices =
        // {
        //     question: ' Which of the following are different domain of leadership?',
        //     choices: [
        //         {
        //             id: 1,
        //             answerText: 'Leading other individuals',
        //             votedPercentage: 50,
        //             votedCount: 50,
        //             isAnswer: false
        //         },
        //         { id: 2, answerText: 'Leading groups', votedPercentage: 20, votedCount: 10, isAnswer: true },
        //         {
        //             id: 3,
        //             answerText: 'Planning, organizing and controlling resources',
        //             votedPercentage: 20,
        //             votedCount: 10,
        //             isAnswer: false
        //         },
        //         {
        //             id: 4,
        //             answerText: 'Leading organizations',
        //             votedPercentage: 20,
        //             votedCount: 10,
        //             isAnswer: false
        //         }
        //     ]
        // }
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_QUESTION_RESULT_BY_QUIZ_ID_ANALYTICS.url}/${data.payload?.id}/result`);
        if (response.status === 200) {
            yield put(AnalyticsActions.getQuizQuestionDataRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getQuizQuestionDataRequestFailure());
    }
}

function* asyncGetPollWidget() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_POLL_WIDGETS.url);
        if (response.status === 200) {
            yield put(AnalyticsActions.getPollWidgetRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getPollWidgetRequestFailure());
    }
}

function* asyncGetSingleVideoAnalytics(data: AnalyticsActions.getSingleVideoAnalyticsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_SINGLE_VIDEO_ANALYTICS.url, data.payload);
        if (response.status === 200) {
            yield put(AnalyticsActions.getSingleVideoAnalyticsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(AnalyticsActions.getSingleVideoAnalyticsRequestFailure());
    }
}
export function* analyticsSaga() {
    yield all([
        takeEvery(Constants.getAnalyticsVideoRequestActionType, asyncGetAnalyticsVideo),
        takeEvery(Constants.getAnalyticsVideoDownloadRequestActionType, asyncGetAnalyticsVideoDownload),
        takeEvery(Constants.getAnalyticsVideoChartRequestActionType, asyncGetAnalyticsVideoChart),
        takeEvery(Constants.getAnalyticsMembersRequestActionType, asyncGetAnalyticsMember),
        takeEvery(Constants.getSalesOrderRequestActionType, asyncGetSalesOrder),
        takeEvery(Constants.downloadSalesOrderRequestActionType, asyncDownloadSalesOrder),
        takeEvery(Constants.getCourseAnalyticsRequestActionType, asyncGetAnalyticsCourse),
        takeEvery(Constants.getAnalyticsMemberChartRequestActionType, asyncGetAnalyticsMemberChart),
        takeEvery(Constants.getCourseTotalRequestActionType, asyncGetCourseTotal),
        takeEvery(Constants.getCourseResultsRequestActionType, asyncGetCourseResults),
        takeEvery(Constants.getViewActivityRequestActionType, asyncGetViewActivity),
        takeEvery(Constants.getViewSubmissionRequestActionType, asyncGetViewSubmission),
        takeEvery(Constants.getQuizTotalRequestActionType, asyncGetQuizTotal),
        takeEvery(Constants.getQuizAnalyticsRequestActionType, asyncGetQuizAnalytics),
        takeEvery(Constants.getQuizQuestionsRequestActionType, asyncGetQuizQuestions),
        takeEvery(Constants.getSingleCourseResultRequestActionType, asyncGetSingleCourseResult),
        takeEvery(Constants.getRecentCourseActivityRequestActionType, asyncGetRecentCourseActivity),
        takeEvery(Constants.getRecentGradeRequestActionType, asyncGetRecentGrade),
        takeEvery(Constants.getSingleQuizQuestionRequestActionType, asyncGetSingleQuizQuestion),
        takeEvery(Constants.getQuestionByQuizIdRequestActionType, asyncGetQuestionByQuizId),
        takeEvery(Constants.getPollAnalyticsRequestActionType, asyncGetPollAnalytics),
        takeEvery(Constants.getPollQuestionRequestActionType, asyncGetPollQuestion),
        takeEvery(Constants.getSinglePollRequestActionType, asyncGetSinglePoll),
        takeEvery(Constants.getSinglePollQuestionRequestActionType, asyncGetSinglePollQuestion),
        takeEvery(Constants.getAnswerQuestionRequestActionType, asyncGetAnswerQuestion),
        takeEvery(Constants.getSalesOrderTotalRequestActionType, asyncGetSalesOrderTotal),
        takeEvery(Constants.getSingleQuizTotalRequestActionType, asyncGetSingleQuizTotal),
        takeEvery(Constants.getAnalyticsMemberSearchRequestActionType, asyncGetAnalyticsMemberSearch),
        takeEvery(Constants.getQuizQuestionDataRequestActionType, asyncGetQuizQuestionData),
        takeEvery(Constants.getPollWidgetRequestActionType, asyncGetPollWidget),
        takeEvery(Constants.getSingleVideoAnalyticsRequestActionType, asyncGetSingleVideoAnalytics),
    ]);
}
