import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useUserActions } from 'src/store/users/actions';
import { useParams, useNavigate } from 'react-router-dom';
import UploadModal from '../components/UploadModal';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg, errorMsg, defaultDateFormatter } from 'src/components/utilities/utils';

const MemberListDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const userAction = useUserActions(dispatch);
  const [search, setSearch] = useState('');
  const [searchFlag, setSearchFlag] = useState(3);
  const [searchTitle, setSearchTitle] = useState(3);

  const TITLES = ['Email', 'Domain', 'Username'];

  const {
    singleMemberList,
    searchedData,
    isEmailDomainUploaded,
    isEmailUploaded,
    isUserNameUploaded,
    isMemberListDetailsDeleted,
    isEmailDomainAlreadyExist,
    isEmailAlreadyExist,
    isUserNameAlreadyExist,
    error,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      singleMemberList: state.users.singleMemberList,
      searchedData: state.users.searchedData,
      isEmailDomainUploaded: state.users.isEmailDomainUploaded,
      isEmailDomainAlreadyExist: state.users.isEmailDomainAlreadyExist,
      isEmailAlreadyExist: state.users.isEmailAlreadyExist,
      isUserNameAlreadyExist: state.users.isUserNameAlreadyExist,
      isEmailUploaded: state.users.isEmailUploaded,
      isUserNameUploaded: state.users.isUserNameUploaded,
      isMemberListDetailsDeleted: state.users.isMemberListDetailsDeleted,
      error: state.users.error,
      paginationMeta: state.users.paginationMeta
    };
  });
  useEffect(() => {
    if (id) {
      userAction.getSingleMemberListRequest(id);
    }
  }, [id]);
  const handleSearchClick = () => {
    if (searchFlag === 0 && id) {
      userAction.searchMemberListDetailsRequest({
        memberlistId: id,
        type: searchFlag,
        filter: search,
        pageNumber: 1,
        pageSize: 50
      });
    } else if (searchFlag === 1 && id) {
      userAction.searchMemberListDetailsRequest({
        memberlistId: id,
        type: searchFlag,
        filter: search,
        pageNumber: 1,
        pageSize: 50
      });
    } else if (searchFlag === 2 && id) {
      userAction.searchMemberListDetailsRequest({
        memberlistId: id,
        type: searchFlag,
        filter: search,
        pageNumber: 1,
        pageSize: 50
      });
    } else if ((searchFlag === 0 || searchFlag === 1 || searchFlag === 2) && id) {
      userAction.searchMemberListDetailsRequest({
        memberlistId: id,
        type: searchFlag,
        filter: '',
        pageNumber: 1,
        pageSize: 50
      });
    }
    setSearchTitle(searchFlag);
  };

  const handleDeleteClick = (deleteId: string) => {
    if (id) {
      userAction.deleteMemberListDetailsRequest({
        memberlistId: id,
        id: deleteId,
        type: searchFlag === 0 ? 'email' : searchFlag === 1 ? 'emaildomain' : 'username'
      });
    }
  };

  useEffect(() => {
    if (
      (isEmailDomainUploaded && !isEmailDomainAlreadyExist) ||
      (isEmailUploaded && !isEmailAlreadyExist) ||
      (isUserNameUploaded && !isUserNameAlreadyExist)
    ) {
      successMsg('Uploaded Successfully');
      if (id) {
        userAction.getSingleMemberListRequest(id);
      }
    } else if (isEmailDomainAlreadyExist || isEmailAlreadyExist || isUserNameAlreadyExist) {
      successMsg('Data Already Exists.');
    } else if (isMemberListDetailsDeleted) {
      successMsg('Data Deleted successfully.');
    }
    userAction.resetMemberRequest();
  }, [
    isEmailDomainUploaded,
    isEmailDomainAlreadyExist,
    isEmailAlreadyExist,
    isUserNameAlreadyExist,
    isEmailUploaded,
    isUserNameUploaded,
    isMemberListDetailsDeleted
  ]);

  useEffect(() => {
    if (isMemberListDetailsDeleted) {
      if (searchFlag === 0 && id) {
        userAction.searchMemberListDetailsRequest({
          memberlistId: id,
          type: searchFlag,
          filter: search,
          pageNumber: 1,
          pageSize: 50
        });
      } else if (searchFlag === 1 && id) {
        userAction.searchMemberListDetailsRequest({
          memberlistId: id,
          type: searchFlag,
          filter: search,
          pageNumber: 1,
          pageSize: 50
        });
      } else if (searchFlag === 2 && id) {
        userAction.searchMemberListDetailsRequest({
          memberlistId: id,
          type: searchFlag,
          filter: search,
          pageNumber: 1,
          pageSize: 50
        });
      }
    } else if ((searchFlag === 0 || searchFlag === 1 || searchFlag === 2) && id) {
      userAction.searchMemberListDetailsRequest({
        memberlistId: id,
        type: searchFlag,
        filter: '',
        pageNumber: 1,
        pageSize: 50
      });
    }
  }, [isMemberListDetailsDeleted]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      userAction.resetMemberRequest();
    }
  }, [error]);

  // const handlePageChange = (pageNumber: number) => {
  //   userAction.searchMemberListDetailsRequest({
  //     memberlistId: id ?? "",
  //     type: searchFlag,
  //     filter: userNames, pageNumber, pageSize: 50
  //   });
  // };

  const handlePoliciesClick = (policiesId: string) => {
    navigate('/monetization/discounts', { state: { from: 'memberlist', policiesId } });
  };

  const handleMemberPackagesClick = (memberPackageId: string) => {
    navigate('/monetization/packages', { state: { from: 'memberlist', memberPackageId } });
  };
  return (
    <>
      <ToastMessage />
      <div className="member-details mt-4">
        <div className="lg:mr-20 mt-2">
          {singleMemberList && (
            <div className="dashboard-card">
              <div className="mt-5">
                <h2 className="font-semibold border-b">
                  Member List Name : {singleMemberList.name}{' '}
                </h2>
                <div className="flex items-center justify-between">
                  <ul>
                    <li className="flex gap-2 my-3">
                      <label className="font-semibold">Emails: </label>
                      {singleMemberList.memberEmailsCount}
                    </li>
                    <li className="flex gap-2 my-3">
                      <label className="font-semibold">Usernames: </label>
                      {singleMemberList.memberUsernamesCount}
                    </li>
                    <li className="flex gap-2 my-3">
                      <label className="font-semibold">Domains: </label>
                      {singleMemberList.memberEmailDomainsCount}
                    </li>
                  </ul>
                  <ul>
                    <li className="flex gap-2 my-3">
                      <label className="font-semibold">Created On:</label>
                      {singleMemberList.createdDate
                        ? defaultDateFormatter(singleMemberList.createdDate)
                        : ''}
                    </li>
                    <li className="flex gap-2 my-3">
                      <label className="font-semibold">Last Modified On:</label>
                      {singleMemberList.lastModifiedDate
                        ? defaultDateFormatter(singleMemberList.lastModifiedDate)
                        : ''}
                    </li>
                    <li className="flex gap-2 my-3">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#Upload"
                        className="btn-primary w-auto whitespace-nowrap m-0 mr-5 px-8">
                        Upload
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              <div className="mt-4">
                <div className="flex items-strached my-5 w-full">
                  <div className="dropdown relative w-6/12 md:w-4/12 xl:w-2/12	">
                    <button
                      className="dropdown-toggle mt-0 font-semibold text-sm md:text-base text-indigo-500 focus:outline-none border rounded-l-lg border-indigo-500 flex items-center justify-center px-2 md:px-4 h-full w-full gap-2"
                      type="button"
                      id="action-dropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {searchFlag != 3 ? TITLES[searchFlag] : 'Type'}
                      <i className="fa-solid fa-chevron-down text-xs"></i>
                    </button>

                    <ul
                      className="dropdown-menu dropdown-menu-ul cm-dropdown-menu-ul w-full"
                      aria-labelledby="action-dropdown">
                      <li>
                        <button
                          className="inline-block px-6 py-2.5 font-medium flex items-center"
                          type="button"
                          onClick={() => setSearchFlag(0)}>
                          Email
                        </button>
                      </li>
                      <li>
                        <button
                          className="inline-block px-6 py-2.5 font-medium flex items-center"
                          type="button"
                          onClick={() => setSearchFlag(2)}>
                          Username
                        </button>
                      </li>
                      <li>
                        <button
                          className="inline-block px-6 py-2.5 font-medium flex items-center"
                          type="button"
                          onClick={() => setSearchFlag(1)}>
                          Domain
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="input-group flex w-full">
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      className="flex text-sm font-medium border-gray-300 text-gary-300 focus:border-gray-300 border relative border-x-0 focus:border-x-0 w-full "
                      placeholder="Search"
                    />
                  </div>
                  <div className="w-6/12 md:w-4/12 xl:w-2/12">
                    <button
                      type="button"
                      className="flex items-center justify-center font-semibold text-sm md:text-base text-indigo-500 focus:outline-none border rounded-r-lg border-indigo-500 mt-0 px-3 hover:bg-indigo-500 hover:text-white h-full w-full"
                      onClick={handleSearchClick}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <hr />

              <div className={`mt-5 ${searchTitle === 3 ? 'hidden' : ''}`}>
                <table className="table-bordered w-full">
                  <thead>
                    <tr>
                      <th className="text-left">{TITLES[searchTitle]}</th>
                      <th className="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedData && Object.keys(searchedData).length ? (
                      <>
                        {searchedData &&
                          Object.entries(searchedData).map((item, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-left pl-2">{item[1]}</td>
                                <td
                                  className="text-left cursor-pointer pl-2"
                                  onClick={() => handleDeleteClick(item[0])}>
                                  Delete
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td className="text-left pl-2">No Data Found</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              <table className="w-full mt-10">
                <thead>
                  <tr>
                    <th className="text-left">Discount Policies</th>
                    <th className="text-left">Member Packages</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {singleMemberList &&
                        singleMemberList.discountPolicies?.map((item: any) => {
                          return (
                            <div
                              className="cursor-pointer"
                              key={item.id}
                              onClick={() => handlePoliciesClick(item.id)}>
                              {item.name}
                            </div>
                          );
                        })}
                    </td>
                    <td className="memberlist-detail-table">
                      {singleMemberList &&
                        singleMemberList.memberPackage?.map((item: any) => {
                          return (
                            <div
                              className="cursor-pointer"
                              key={item.id}
                              onClick={() => handleMemberPackagesClick(item.id)}>
                              {item.name}
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <UploadModal />
    </>
  );
};

export default MemberListDetails;
