export const getSiteIdentityRequestActionType = 'GET_SITE_IDENTITY_REQUEST'
export const getSiteIdentityRequestSuccessActionType = 'GET_SITE_IDENTITY_REQUEST_SUCCESS'
export const getSiteIdentityRequestFailureActionType = 'GET_SITE_IDENTITY_REQUEST_FAILURE'
export const updateAppearanceRequestActionType = 'UPDATE_APPEARANCE_REQUEST'
export const updateAppearanceRequestSuccessActionType = 'UPDATE_APPEARANCE_REQUEST_SUCCESS'
export const updateAppearanceRequestFailureActionType = 'UPDATE_APPEARANCE_REQUEST_FAILURE'
export const getColorsRequestActionType = 'GET_COLORS_REQUEST'
export const getColorsRequestSuccessActionType = 'GET_COLORS_REQUEST_SUCCESS'
export const getColorsRequestFailureActionType = 'GET_COLORS_REQUEST_FAILURE'
export const getFontsRequestActionType = 'GET_FONTS_REQUEST'
export const getFontsRequestSuccessActionType = 'GET_FONTS_REQUEST_SUCCESS'
export const getFontsRequestFailureActionType = 'GET_FONTS_REQUEST_FAILURE'
export const getUserProfileRequestActionType = 'GET_USER_PROFILE_REQUEST'
export const getUserProfileRequestSuccessActionType = 'GET_USER_PROFILE_REQUEST_SUCCESS'
export const getUserProfileRequestFailureActionType = 'GET_USER_PROFILE_REQUEST_FAILURE'
export const getVideoCardRequestActionType = 'GET_VIDEO_CARD_REQUEST'
export const getVideoCardRequestSuccessActionType = 'GET_VIDEO_CARD_REQUEST_SUCCESS'
export const getVideoCardRequestFailureActionType = 'GET_VIDEO_CARD_REQUEST_FAILURE'
export const resetAppearanceStateRequestActionType = 'RESET_APPEARANCE_STATE_REQUEST'

export const getPlayPageVideoRequestActionType = 'GET_PLAY_PAGE_VIDEO_REQUEST'
export const getPlayPageVideoRequestSuccessActionType = 'GET_PLAY_PAGE_VIDEO_REQUEST_SUCCESS'
export const getPlayPageVideoRequestFailureActionType = 'GET_PLAY_PAGE_VIDEO_REQUEST_FAILURE'

export const getPlayPageNavigationRequestActionType = 'GET_PLAY_PAGE_NAVIGATION_REQUEST'
export const getPlayPageNavigationRequestSuccessActionType = 'GET_PLAY_PAGE_NAVIGATION_REQUEST_SUCCESS'
export const getPlayPageNavigationRequestFailureActionType = 'GET_PLAY_PAGE_NAVIGATION_REQUEST_FAILURE'

export const getChatStyleRequestActionType = 'GET_CHAT_STYLE_REQUEST'
export const getChatStyleRequestSuccessActionType = 'GET_CHAT_STYLE_REQUEST_SUCCESS'
export const getChatStyleRequestFailureActionType = 'GET_CHAT_STYLE_REQUEST_FAILURE'

export const updateChatStyleRequestActionType = 'UPDATE_CHAT_STYLE_REQUEST'
export const updateChatStyleRequestSuccessActionType = 'UPDATE_CHAT_STYLE_REQUEST_SUCCESS'
export const updateChatStyleRequestFailureActionType = 'UPDATE_CHAT_STYLE_REQUEST_FAILURE'

export const getNavigationRequestActionType = 'GET_NAVIGATION_REQUEST'
export const getNavigationRequestSuccessActionType = 'GET_NAVIGATION_REQUEST_SUCCESS'
export const getNavigationRequestFailureActionType = 'GET_NAVIGATION_REQUEST_FAILURE'

export const saveNavigationRequestActionType = 'SAVE_NAVIGATION_REQUEST'
export const saveNavigationRequestSuccessActionType = 'SAVE_NAVIGATION_REQUEST_SUCCESS'
export const saveNavigationRequestFailureActionType = 'SAVE_NAVIGATION_REQUEST_FAILURE'

export const getSingleNavigationRequestActionType = 'GET_SINGLE_NAVIGATION_REQUEST'
export const getSingleNavigationRequestSuccessActionType = 'GET_SINGLE_NAVIGATION_REQUEST_SUCCESS'
export const getSingleNavigationRequestFailureActionType = 'GET_SINGLE_NAVIGATION_REQUEST_FAILURE'

export const updateNavigationRequestActionType = 'UPDATE_NAVIGATION_REQUEST'
export const updateNavigationRequestSuccessActionType = 'UPDATE_NAVIGATION_REQUEST_SUCCESS'
export const updateNavigationRequestFailureActionType = 'UPDATE_NAVIGATION_REQUEST_FAILURE'

export const deleteNavigationRequestActionType = 'DELETE_NAVIGATION_REQUEST'
export const deleteNavigationRequestSuccessActionType = 'DELETE_NAVIGATION_REQUEST_SUCCESS'
export const deleteNavigationRequestFailureActionType = 'DELETE_NAVIGATION_REQUEST_FAILURE'

export const getSearchSettingRequestActionType = 'GET_SEARCH_SETTING_REQUEST'
export const getSearchSettingRequestSuccessActionType = 'GET_SEARCH_SETTING_REQUEST_SUCCESS'
export const getSearchSettingRequestFailureActionType = 'GET_SEARCH_SETTING_REQUEST_FAILURE'

export const getPlayPageSettingRequestActionType = 'GET_PLAY_PAGE_SETTING_REQUEST'
export const getPlayPageSettingRequestSuccessActionType = 'GET_PLAY_PAGE_SETTING_REQUEST_SUCCESS'
export const getPlayPageSettingRequestFailureActionType = 'GET_PLAY_PAGE_SETTING_REQUEST_FAILURE'

export const getAdvanceSettingRequestActionType = 'GET_ADVANCE_SETTING_REQUEST'
export const getAdvanceSettingRequestSuccessActionType = 'GET_ADVANCE_SETTING_REQUEST_SUCCESS'
export const getAdvanceSettingRequestFailureActionType = 'GET_ADVANCE_SETTING_REQUEST_FAILURE'

export const getFilterSettingRequestActionType = 'GET_FILTER_SETTING_REQUEST'
export const getFilterSettingRequestSuccessActionType = 'GET_FILTER_SETTING_REQUEST_SUCCESS'
export const getFilterSettingRequestFailureActionType = 'GET_FILTER_SETTING_REQUEST_FAILURE'