import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Pagination from 'src/components/common/pagination';
interface IProps {
  memberId: string;
}

const CourseVideoAnalytics: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const { paginationMeta, analyticsCourse } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.analytics.paginationMeta,
      analyticsCourse: state.analytics.analyticsCourse
    };
  });
  const handlePageChange = (pageNumber: number | string) => {
    const data = { pageNumber, id: memberId };
    analyticsAction.getCourseAnalyticsRequest(data);
  };

  return (
    <div className="my-6">
      <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
          <div className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold ">Course Results</h2>
          </div>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold   bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">COURSE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">COURSE STATUS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">COMPLETION DATE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">PASS RATE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">SCORE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">CREDITS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">CREDITS SENT</div>
                    </th>
                  </tr>
                </thead>
                {analyticsCourse && analyticsCourse.length ? (
                  <tbody className="text-sm divide-y divide-gray-100">
                    {analyticsCourse &&
                      analyticsCourse.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-2">
                              <div className="text-left">{item.courseName}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.couseStatus}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">
                                {item.completionDate
                                  ? defaultDateFormatter(item.completionDate)
                                  : ''}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">{item.courseCompletionPercentage}%</div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">{item.score}%</div>
                            </td>

                            <td className="p-2">
                              <div className="text-center">{item.creditsAchieved}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.creditsSent ? (
                                  <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="text-center">No data Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                <Pagination
                  currentPage={paginationMeta.pageNumber}
                  totalCount={paginationMeta.totalCount}
                  pageSize={paginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseVideoAnalytics;
