import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/common/pagination';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import Select from 'react-select';
import { useChannelActions } from 'src/store/channel/actions';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { CSVLink } from 'react-csv';
import LineChart from 'src/components/charts/linechart/LineChart';
import { DownloadAnalyticsModel } from 'src/models/analyticsModel';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { SelectModel } from 'src/models/videosModel';
import { useNavigate } from 'react-router-dom';
import { convertSecondToHourMinuteSecond } from 'src/components/utilities/utils';
import { getrandomcolor } from 'src/components/utilities/utils';
import StackedBarChart from 'src/components/charts/stackedchart/StackedBarChart';
import { FilterAnalyticsModel } from 'src/models/analyticsModel';
import moment from 'moment';
import { customFilterOption } from 'src/components/utilities/utils';

interface OpenInNewTabOrSameTabLinkProps {
  analyticsId: string;
}

const OpenInNewTabOrSameTabLink = ({ analyticsId }: OpenInNewTabOrSameTabLinkProps) => {
  const navigate = useNavigate();

  const handleLeftClick = (e: any) => {
    e.preventDefault();
    navigate(`/videos/analytics/${analyticsId}`);
  };

  const handleRightClick = (e: any) => {
    e.preventDefault();
    window.open(`/videos/analytics/${analyticsId}`);
  };

  return (
    <div className="">
      <button
        id={`click-me-${analyticsId}`}
        onContextMenu={handleRightClick}
        onClick={handleLeftClick}>
        View
      </button>
    </div>
  );
};

const AnalyticsVideo: React.FC = () => {
  const [downloadData, setDownloadData] = useState<Array<DownloadAnalyticsModel>>();
  const [filterData, setFilterData] = useState<FilterAnalyticsModel | null>();
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const channelActions = useChannelActions(dispatch);

  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [analyticSessionFilter, setAnalyticSessionFilter] = useState('This Month');
  const [videoAnalyticsChartType, setVideoAnalyticsChartType] = useState('Monthly');
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    watch
  } = useForm();
  const {
    analyticsVideo,
    isAnalyticsVideoLoading,
    analyticsVideoChart,
    channelList,
    analyticVideoPaginationMeta,
    analyticsVideoDownload
  } = useSelector((state: RootState) => {
    return {
      analyticsVideo: state.analytics.analyticsVideo,
      isAnalyticsVideoLoading: state.analytics.isAnalyticsVideoLoading,
      analyticsVideoChart: state.analytics.analyticsVideoChart,
      channelList: state.channel.channelList,
      analyticVideoPaginationMeta: state.analytics.analyticVideoPaginationMeta,
      analyticsVideoDownload: state.analytics.analyticsVideoDownload
    };
  });

  const [lineChartData, setLineChartData] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '',
        backgroundColor: ''
      }
    ]
  });
  const [horizontalChartData, setHorizontalChartData] = useState({
    labels: [''],
    datasets: [
      {
        axis: '',
        label: '',
        data: [1],
        backgroundColor: '',
        barThickness: 12,
        datalabels: {
          color: '#FFCE56'
        }
      }
    ]
  });
  useEffect(() => {
    if (analyticsVideoChart && Object.keys(analyticsVideoChart).length) {
      const randomColor = getrandomcolor(analyticsVideoChart?.data.length);
      const labelData: any = [];
      analyticsVideoChart &&
        analyticsVideoChart?.data.map((item, index) => {
          labelData.push({
            label: item.title,
            data: item && item.value.map((data) => data),
            borderColor: randomColor[index],
            backgroundColor: randomColor[index]
          });
        });
      setLineChartData({
        labels: analyticsVideoChart && analyticsVideoChart.xName.map((data) => data),
        datasets: labelData
      });
    }
  }, [analyticsVideoChart]);

  useEffect(() => {
    if (analyticsVideoDownload && analyticsVideoDownload.length) {
      const csvData =
        analyticsVideoDownload &&
        analyticsVideoDownload.map((analyticsVideo) => {
          return {
            channelName: analyticsVideo.channelName,
            parentChannel: analyticsVideo.parentChannelName,
            videoTitle: analyticsVideo.videoTitle,
            channelType: analyticsVideo.channelType,
            videoCreateDate: defaultDateFormatter(analyticsVideo.videoCreateDate),
            onDemandViews: analyticsVideo.onDemandViews,
            liveViews: analyticsVideo.liveViews,
            totalViews: analyticsVideo.totalViews,
            timeWatchedSec: analyticsVideo.timeWatchedSec,
            averageWatchedSec: analyticsVideo.averageWatchedSec
          };
        });
      if (csvData) {
        setDownloadData(csvData);
      }
    }
  }, [analyticsVideoDownload]);
  useEffect(() => {
    const payloadData = {
      pageNumber: 1,
      pageSize: 20
    };
    analyticsAction.getAnalyticsVideoRequest(payloadData);
    const videoFromDate = moment().startOf('month').format('YYYY-MM-DD');
    const videoToDate = moment().endOf('month').format('YYYY-MM-DD');
    analyticsAction.getAnalyticsVideoChartRequest({
      videoFromDate,
      videoToDate
    });
  }, []);

  useEffect(() => {
    if (!channelList.length && showFilter) {
      channelActions.getChannelListRequest();
    }
  }, [showFilter]);
  const handlePageChange = (pageNumber: number) => {
    if (filterData) {
      const data = { ...filterData, pageNumber, pageSize: 20 };
      analyticsAction.getAnalyticsVideoRequest(data);
    } else {
      const data = { pageNumber, pageSize: 20 };
      analyticsAction.getAnalyticsVideoRequest(data);
    }
  };

  const handleFilterClick = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
      analyticsAction.getAnalyticsVideoDownloadRequest({ pageNumber: 1, pageSize: 10000 });
    }
  };
  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);

  const videoTypeOptions = [
    {
      value: 'Not Set',
      label: 'Not Set'
    },
    {
      value: 'Locked',
      label: 'Locked'
    },
    {
      value: 'Paid',
      label: 'Paid'
    },
    {
      value: 'Free',
      label: 'Free'
    },
    {
      value: 'Authentication Required',
      label: 'Authentication Required'
    }
  ];

  const onSubmit = (data: any) => {
    let finalChannelId: any = [];
    const channelIds =
      data.channelIds && data.channelIds?.map((channel: SelectModel) => channel.value);

    if (channelIds) {
      const subChannels =
        channelList && channelList.filter((channel) => channelIds.includes(channel.parentId));
      console.log('ids', channelIds);
      const subChannelsIds = subChannels && subChannels.map((item) => item.id);
      console.log('subChannelsIds', subChannelsIds);
      if (channelIds || subChannelsIds) {
        if (childFlag) {
          finalChannelId = [...channelIds, ...subChannelsIds];
        } else {
          finalChannelId = [...channelIds];
        }
      }
    }

    const videoType =
      data.videoType && data.videoType?.map((videoType: SelectModel) => videoType.value);
    const videoTypeString = videoType && videoType.toString();
    const filterPayloadData = {
      ...data,
      channelIds: finalChannelId,
      videoType: videoTypeString,
      pageNumber: 1,
      pageSize: 20
    };
    setFilterData(filterPayloadData);
    if (data.dateWatchedFromDate && data.dateWatchedToDate) {
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate: data.dateWatchedFromDate,
        videoToDate: data.dateWatchedToDate
      });
    }
    analyticsAction.getAnalyticsVideoRequest(filterPayloadData);
    analyticsAction.getAnalyticsVideoDownloadRequest({
      pageNumber: 1,
      pageSize: 10000,
      dateWatchedFromDate: data.dateWatchedFromDate,
      dateWatchedToDate: data.dateWatchedToDate
    });
  };

  const handleResetClick = () => {
    setDownloadData([]);
    setFilterData(null);
    reset();
    setValue('channelIds', '');
    setValue('videoType', '');
    const payloadData = {
      pageNumber: 1,
      pageSize: 20
    };
    analyticsAction.getAnalyticsVideoRequest(payloadData);
    analyticsAction.getAnalyticsVideoDownloadRequest({ pageNumber: 1, pageSize: 10000 });
  };
  const options = {
    scales: {
      xAxis: {
        grid: {
          display: false
        }
      },
      yAxis: {
        grid: {
          display: false
        },
        min: 0
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const
      }
    }
  };

  useEffect(() => {
    if (analyticsVideo && analyticsVideo.length) {
      setHorizontalChartData({
        labels:
          analyticsVideo &&
          analyticsVideo?.map((data) =>
            data.videoTitle?.length > 50
              ? `${data.videoTitle?.slice(0, 50)}...`
              : data.videoTitle?.slice(0, 50)
          ),
        datasets: [
          {
            axis: 'y',
            label: 'Live Views',
            data: analyticsVideo && analyticsVideo?.map((data) => data.liveViews),
            backgroundColor: '#00D2DB',
            barThickness: 15,
            datalabels: {
              color: 'white'
            }
          },
          {
            axis: 'y',
            label: 'OnDemand Views',
            data: analyticsVideo && analyticsVideo?.map((data) => data.onDemandViews),
            backgroundColor: '#1A71FF',
            barThickness: 15,
            datalabels: {
              color: 'white'
            }
          }
        ]
      });
    }
  }, [analyticsVideo]);

  const handleMemberActivityFilterClick = (data: string) => {
    setAnalyticSessionFilter(data);
    if (data === 'This Week') {
      const videoFromDate = moment().startOf('week').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('week').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate,
        videoToDate
      });
      analyticsAction.getAnalyticsVideoRequest({
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
      analyticsAction.getAnalyticsVideoDownloadRequest({
        pageNumber: 1,
        pageSize: 10000,
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
    } else if (data === 'Last Week') {
      const videoFromDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      const videoToDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate,
        videoToDate
      });
      analyticsAction.getAnalyticsVideoRequest({
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
      analyticsAction.getAnalyticsVideoDownloadRequest({
        pageNumber: 1,
        pageSize: 10000,
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
    } else if (data === 'This Month') {
      const videoFromDate = moment().startOf('month').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('month').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate,
        videoToDate
      });
      analyticsAction.getAnalyticsVideoRequest({
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
      analyticsAction.getAnalyticsVideoDownloadRequest({
        pageNumber: 1,
        pageSize: 10000,
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
    } else if (data === 'Last Month') {
      const videoFromDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const videoToDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate,
        videoToDate
      });
      analyticsAction.getAnalyticsVideoRequest({
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
      analyticsAction.getAnalyticsVideoDownloadRequest({
        pageNumber: 1,
        pageSize: 10000,
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
    } else if (data === 'This Year') {
      const videoFromDate = moment().startOf('year').format('YYYY-MM-DD');
      const videoToDate = moment().endOf('year').format('YYYY-MM-DD');
      analyticsAction.getAnalyticsVideoChartRequest({
        videoFromDate,
        videoToDate
      });
      analyticsAction.getAnalyticsVideoRequest({
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
      analyticsAction.getAnalyticsVideoDownloadRequest({
        pageNumber: 1,
        pageSize: 10000,
        dateWatchedFromDate: videoFromDate,
        dateWatchedToDate: videoToDate
      });
    }
  };

  const handleVideoAnalyticChartType = (data: string) => {
    setVideoAnalyticsChartType(data);
  };

  const childFlag = watch('child');

  return (
    <>
      <div className="flex">
        <h2 className="font-semibold ">Video Analytics</h2>
        <button
          type="button"
          className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
          onClick={handleFilterClick}>
          <i className="fa fa-filter" aria-hidden="true"></i>
        </button>
      </div>
      <div className="mt-4 members-list">
        <div className={`dashboard-card my-5 ${showFilter ? '' : 'hidden'}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
              <div className={`form-group`}>
                <h5 className="font-semibold md:mb-4 mb-2">Video Date</h5>
                <label className="block text-sm font-medium mb-1"> From </label>
                <input type="date" className="input-style" {...register('videoFromDate')} />
              </div>

              <div className={`form-group`}>
                <h5 className="font-semibold md:mb-4 mb-2 hidden md:block md:invisible">
                  Video Date
                </h5>
                <label className="block text-sm font-medium mb-1">To </label>
                <input type="date" className="input-style" {...register('videoToDate')} />
              </div>

              <div className={`form-group`}>
                <h5 className="font-semibold md:mb-4 mb-2">Watched Date</h5>
                <label className="block text-sm font-medium mb-1"> From </label>
                <input type="date" className="input-style" {...register('dateWatchedFromDate')} />
              </div>

              <div className={`form-group`}>
                <h5 className="font-semibold md:mb-4 mb-2 hidden md:block md:invisible">
                  Watched Date
                </h5>
                <label className="block text-sm font-medium mb-1">To </label>
                <input type="date" className="input-style" {...register('dateWatchedToDate')} />
              </div>
            </div>

            <div className="mt-3">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Video Title </label>
                  <input type="text" className="input-style" {...register('videoTitle')} />
                </div>

                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Video Type </label>

                  {videoTypeOptions && Object.keys(videoTypeOptions).length && (
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          filterOption={customFilterOption}
                          options={videoTypeOptions}
                          isMulti
                          value={value}
                          id="videoType"
                          onChange={(val) => onChange(val)}
                        />
                      )}
                      name="videoType"
                      control={control}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Channel </label>

                  {finalChannels && Object.keys(finalChannels).length && (
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          filterOption={customFilterOption}
                          options={finalChannels}
                          isMulti
                          value={value}
                          id="channelIds"
                          onChange={(val) => onChange(val)}
                        />
                      )}
                      name="channelIds"
                      control={control}
                    />
                  )}
                </div>
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Include Child Channels</label>
                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 ">
                    <div className="relative">
                      <input
                        className="form-check-input switch-btn"
                        type="checkbox"
                        role="switch"
                        id="child"
                        {...register('child')}
                      />
                      <i className="fas fa-check checkbox-tick hidden"></i>
                      <i className="fas fa-times checkbox-cross"></i>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  {isAnalyticsVideoLoading && (
                    <span className="mr-2">
                      <Loader />
                    </span>
                  )}
                  <button
                    type="submit"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Submit
                  </button>
                  {downloadData && downloadData?.length ? (
                    <CSVLink
                      className="export"
                      data={downloadData}
                      filename={'Analytics-Videos.csv'}>
                      <button
                        type="button"
                        className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                        Download
                      </button>
                    </CSVLink>
                  ) : (
                    <button
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Preparing Data To Download
                    </button>
                  )}
                  <button
                    onClick={handleResetClick}
                    type="button"
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className={`dashboard-card my-5 `}>
          <div className="flex items-center justify-end">
            {/* <div className="dropdown relative mr-3">
              <button
                className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                type="button"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="flex">{videoAnalyticsChartType}</span>
                <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                aria-labelledby="action-dropdown">
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleVideoAnalyticChartType('Monthly')}>
                    <span>Monthly</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleVideoAnalyticChartType('Daily')}>
                    <span>Daily</span>
                  </span>
                </li>
              </ul> 
                  </div> */}
            <div className="dropdown relative mr-3">
              <button
                className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                type="button"
                id="action-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <span className="flex">{analyticSessionFilter}</span>
                <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
              </button>
              <ul
                className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                aria-labelledby="action-dropdown">
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Week')}>
                    <span>This Week</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('Last Week')}>
                    <span>Last Week</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Month')}>
                    <span>This Month</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('Last Month')}>
                    <span>Last Month</span>
                  </span>
                </li>
                <li>
                  <span
                    className="action-menu flex items-center"
                    onClick={() => handleMemberActivityFilterClick('This Year')}>
                    <span>This Year</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <LineChart options={options} chartData={lineChartData} />
          </div>
        </div>
        <div className={`dashboard-card my-5 `}>
          {isAnalyticsVideoLoading ? (
            <Loader />
          ) : (
            <StackedBarChart
              chartData={horizontalChartData}
              options={{
                indexAxis: 'y',
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index'
                },
                scales: {
                  x: {
                    stacked: true
                  },
                  y: {
                    stacked: true,
                    grid: {
                      display: false
                    }
                  }
                },
                plugins: {
                  datalabels: {
                    display: function (context: any) {
                      return context.dataset.data[context.dataIndex] !== 0;
                    }
                  },
                  tooltip: {
                    callbacks: {
                      afterBody: (data: any) => {
                        return 'Total: ' + (data[0].raw + data[1].raw);
                      }
                    }
                  }
                }
              }}
              height={800}
            />
          )}
        </div>
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Video Title</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Channel</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Video Type</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Video Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total Views</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Live Views</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Ondemand Views</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total Time Watched</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Average Time Watched</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Quick Link</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isAnalyticsVideoLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {analyticsVideo && analyticsVideo.length ? (
                            <>
                              {analyticsVideo &&
                                analyticsVideo.map((analytics, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.videoTitle}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left flex flex-col">
                                          <span>{`${analytics.channelName}`}</span>
                                          {analytics.parentChannelName && (
                                            <span
                                              className="text-left p-2 italic video-view-badge"
                                              style={{ width: 'fit-content' }}>
                                              Parent: {analytics.parentChannelName}
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.channelType}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {analytics.videoCreateDate
                                            ? defaultDateFormatter(analytics.videoCreateDate)
                                            : ''}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.totalViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.liveViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{analytics.onDemandViews}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {convertSecondToHourMinuteSecond(
                                            analytics.timeWatchedSec
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {convertSecondToHourMinuteSecond(
                                            analytics.averageWatchedSec
                                          )}
                                        </div>
                                      </td>
                                      <td className="p-2 cursor-pointer">
                                        {
                                          <OpenInNewTabOrSameTabLink
                                            analyticsId={analytics.videoId}
                                          />
                                        }
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center py-2" colSpan={9}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!analyticVideoPaginationMeta &&
                    !!Object.keys(analyticVideoPaginationMeta).length && (
                      <Pagination
                        currentPage={analyticVideoPaginationMeta.pageNumber}
                        totalCount={analyticVideoPaginationMeta.totalCount}
                        pageSize={analyticVideoPaginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsVideo;
