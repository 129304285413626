
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { PatchChannelOrderModel, GetHighlightReelModel, ChannelModel, ChannelLayoutModel, GetChannelModel, ChannelStylesModel, ChannelHightlightReelModel, GetChannelListModel, singleLevelArray } from 'src/models/channelModel';
export namespace ChannelActions {

    export type saveChannelRequestAction = Action<typeof Constants.saveChannelRequestActionType, ChannelModel>
    export type saveChannelRequestSuccessAction = Action<typeof Constants.saveChannelSuccessActionType, null>;
    export type saveChannelRequestFailureAction = Action<typeof Constants.saveChannelFailureActionType, null>;

    export function saveChannelRequest(P: ChannelModel): saveChannelRequestAction {
        return ActionCreator(Constants.saveChannelRequestActionType, P);
    }
    export function saveChannelRequestSuccess(): saveChannelRequestSuccessAction {
        return ActionCreator(Constants.saveChannelSuccessActionType, null);
    }
    export function saveChannelRequestFailure(): saveChannelRequestFailureAction {
        return ActionCreator(Constants.saveChannelFailureActionType, null);
    }

    export type getSingleChannelRequestAction = Action<typeof Constants.getSingleChannelRequestActionType, string>
    export type getSingleChannelRequestSuccessAction = Action<typeof Constants.getSingleChannelSuccessActionType, ChannelModel>;
    export type getSingleChannelRequestFailureAction = Action<typeof Constants.getSingleChannelFailureActionType, null>;

    export function getSingleChannelRequest(P: string): getSingleChannelRequestAction {
        return ActionCreator(Constants.getSingleChannelRequestActionType, P);
    }
    export function getSingleChannelRequestSuccess(P: ChannelModel): getSingleChannelRequestSuccessAction {
        return ActionCreator(Constants.getSingleChannelSuccessActionType, P);
    }
    export function getSingleChannelRequestFailure(): getSingleChannelRequestFailureAction {
        return ActionCreator(Constants.getSingleChannelFailureActionType, null);
    }

    export type updateChannelRequestAction = Action<typeof Constants.updateChannelRequestActionType, any>
    export type updateChannelRequestSuccessAction = Action<typeof Constants.updateChannelSuccessActionType, null>;
    export type updateChannelRequestFailureAction = Action<typeof Constants.updateChannelFailureActionType, null>;

    export function updateChannelRequest(P: any): updateChannelRequestAction {
        return ActionCreator(Constants.updateChannelRequestActionType, P);
    }
    export function updateChannelRequestSuccess(): updateChannelRequestSuccessAction {
        return ActionCreator(Constants.updateChannelSuccessActionType, null);
    }
    export function updateChannelRequestFailure(): updateChannelRequestFailureAction {
        return ActionCreator(Constants.updateChannelFailureActionType, null);
    }

    export type deleteChannelRequestAction = Action<typeof Constants.deleteChannelRequestActionType, string>
    export type deleteChannelRequestSuccessAction = Action<typeof Constants.deleteChannelSuccessActionType, null>;
    export type deleteChannelRequestFailureAction = Action<typeof Constants.deleteChannelFailureActionType, null>;

    export function deleteChannelRequest(P: string): deleteChannelRequestAction {
        return ActionCreator(Constants.deleteChannelRequestActionType, P);
    }
    export function deleteChannelRequestSuccess(): deleteChannelRequestSuccessAction {
        return ActionCreator(Constants.deleteChannelSuccessActionType, null);
    }
    export function deleteChannelRequestFailure(): deleteChannelRequestFailureAction {
        return ActionCreator(Constants.deleteChannelFailureActionType, null);
    }

    export type getChannelLayoutRequestAction = Action<typeof Constants.getChannelLayoutRequestActionType, string>
    export type getChannelLayoutRequestSuccessAction = Action<typeof Constants.getChannelLayoutSuccessActionType, ChannelLayoutModel>;
    export type getChannelLayoutRequestFailureAction = Action<typeof Constants.getChannelLayoutFailureActionType, null>;

    export function getChannelLayoutRequest(P: string): getChannelLayoutRequestAction {
        return ActionCreator(Constants.getChannelLayoutRequestActionType, P);
    }
    export function getChannelLayoutRequestSuccess(P: ChannelLayoutModel): getChannelLayoutRequestSuccessAction {
        return ActionCreator(Constants.getChannelLayoutSuccessActionType, P);
    }
    export function getChannelLayoutRequestFailure(): getChannelLayoutRequestFailureAction {
        return ActionCreator(Constants.getChannelLayoutFailureActionType, null);
    }

    export type updateChannelLayoutRequestAction = Action<typeof Constants.updateChannelLayoutRequestActionType, any>
    export type updateChannelLayoutRequestSuccessAction = Action<typeof Constants.updateChannelLayoutSuccessActionType, null>;
    export type updateChannelLayoutRequestFailureAction = Action<typeof Constants.updateChannelLayoutFailureActionType, null>;

    export function updateChannelLayoutRequest(P: any): updateChannelLayoutRequestAction {
        return ActionCreator(Constants.updateChannelLayoutRequestActionType, P);
    }
    export function updateChannelLayoutRequestSuccess(): updateChannelLayoutRequestSuccessAction {
        return ActionCreator(Constants.updateChannelLayoutSuccessActionType, null);
    }
    export function updateChannelLayoutRequestFailure(): updateChannelLayoutRequestFailureAction {
        return ActionCreator(Constants.updateChannelLayoutFailureActionType, null);
    }

    export type getChannelRequestAction = Action<typeof Constants.getChannelRequestActionType, null>
    export type getChannelRequestSuccessAction = Action<typeof Constants.getChannelSuccessActionType, GetChannelModel[]>;
    export type getChannelRequestFailureAction = Action<typeof Constants.getChannelFailureActionType, null>;

    export function getChannelRequest(): getChannelRequestAction {
        return ActionCreator(Constants.getChannelRequestActionType, null);
    }
    export function getChannelRequestSuccess(P: GetChannelModel[]): getChannelRequestSuccessAction {
        return ActionCreator(Constants.getChannelSuccessActionType, P);
    }
    export function getChannelRequestFailure(): getChannelRequestFailureAction {
        return ActionCreator(Constants.getChannelFailureActionType, null);
    }

    export type getSubChannelRequestAction = Action<typeof Constants.getSubChannelRequestActionType, GetChannelModel[]>

    export function getSubChannelRequest(P: GetChannelModel[]): getSubChannelRequestAction {
        return ActionCreator(Constants.getSubChannelRequestActionType, P);
    }

    export type setChannelIdRequestAction = Action<typeof Constants.setChannelIDRequestActionType, string>

    export function setChannelIdRequest(P: string): setChannelIdRequestAction {
        return ActionCreator(Constants.setChannelIDRequestActionType, P);
    }

    export type resetSubChannelRequestAction = Action<typeof Constants.resetSubChannelRequestActionType, null>

    export function resetSubChannelRequest(): resetSubChannelRequestAction {
        return ActionCreator(Constants.resetSubChannelRequestActionType, null);
    }

    export type resetChannelStateRequestAction = Action<typeof Constants.resetChannelStateRequestActionType, null>

    export function resetChannelStateRequest(): resetChannelStateRequestAction {
        return ActionCreator(Constants.resetChannelStateRequestActionType, null);
    }

    export type addNewChannelFlagRequestAction = Action<typeof Constants.addNewChannelFlagRequestActionType, null>

    export function addNewChannelFlagRequest(): addNewChannelFlagRequestAction {
        return ActionCreator(Constants.addNewChannelFlagRequestActionType, null);
    }

    export type resetAddNewChannelFlagRequestAction = Action<typeof Constants.resetAddNewChannelFlagRequestActionType, null>

    export function resetAddNewChannelFlagRequest(): resetAddNewChannelFlagRequestAction {
        return ActionCreator(Constants.resetAddNewChannelFlagRequestActionType, null);
    }

    export type getFlattenedChannelAction = Action<typeof Constants.getFlattenedChannel, null>;

    export function getFlattenedChannel(): any {
        return ActionCreator(Constants.getFlattenedChannel, null);
    }

    export type saveChannelStylesRequestAction = Action<typeof Constants.saveChannelStylesRequestActionType, ChannelStylesModel>
    export type saveChannelStylesRequestSuccessAction = Action<typeof Constants.saveChannelStylesRequestSuccessActionType, null>;
    export type saveChannelStylesRequestFailureAction = Action<typeof Constants.saveChannelStylesRequestFailureActionType, null>;

    export function saveChannelStylesRequest(P: ChannelStylesModel): saveChannelStylesRequestAction {
        return ActionCreator(Constants.saveChannelStylesRequestActionType, P);
    }
    export function saveChannelStylesRequestSuccess(): saveChannelStylesRequestSuccessAction {
        return ActionCreator(Constants.saveChannelStylesRequestSuccessActionType, null);
    }
    export function saveChannelStylesRequestFailure(): saveChannelStylesRequestFailureAction {
        return ActionCreator(Constants.saveChannelStylesRequestFailureActionType, null);
    }

    export type getChannelStylesRequestAction = Action<typeof Constants.getChannelStylesRequestActionType, null>
    export type getChannelStylesRequestSuccessAction = Action<typeof Constants.getChannelStylesRequestSuccessActionType, ChannelStylesModel>;
    export type getChannelStylesRequestFailureAction = Action<typeof Constants.getChannelStylesRequestFailureActionType, null>;

    export function getChannelStylesRequest(): getChannelStylesRequestAction {
        return ActionCreator(Constants.getChannelStylesRequestActionType, null);
    }
    export function getChannelStylesRequestSuccess(P: ChannelStylesModel): getChannelStylesRequestSuccessAction {
        return ActionCreator(Constants.getChannelStylesRequestSuccessActionType, P);
    }
    export function getChannelStylesRequestFailure(): getChannelStylesRequestFailureAction {
        return ActionCreator(Constants.getChannelStylesRequestFailureActionType, null);
    }

    export type saveChannelHightlightReelRequestAction = Action<typeof Constants.saveChannelHightlightReelRequestActionType, ChannelHightlightReelModel>
    export type saveChannelHightlightReelRequestSuccessAction = Action<typeof Constants.saveChannelHightlightReelSuccessActionType, null>;
    export type saveChannelHightlightReelRequestFailureAction = Action<typeof Constants.saveChannelHightlightReelFailureActionType, null>;

    export function saveChannelHightlightReelRequest(P: ChannelHightlightReelModel): saveChannelHightlightReelRequestAction {
        return ActionCreator(Constants.saveChannelHightlightReelRequestActionType, P);
    }
    export function saveChannelHightlightReelRequestSuccess(): saveChannelHightlightReelRequestSuccessAction {
        return ActionCreator(Constants.saveChannelHightlightReelSuccessActionType, null);
    }
    export function saveChannelHightlightReelRequestFailure(): saveChannelHightlightReelRequestFailureAction {
        return ActionCreator(Constants.saveChannelHightlightReelFailureActionType, null);
    }

    export type getChannelHightlightReelRequestAction = Action<typeof Constants.getChannelHightlightReelRequestActionType, null>
    export type getChannelHightlightReelRequestSuccessAction = Action<typeof Constants.getChannelHightlightReelSuccessActionType, ChannelHightlightReelModel>;
    export type getChannelHightlightReelRequestFailureAction = Action<typeof Constants.getChannelHightlightReelFailureActionType, null>;

    export function getChannelHightlightReelRequest(): getChannelHightlightReelRequestAction {
        return ActionCreator(Constants.getChannelHightlightReelRequestActionType, null);
    }
    export function getChannelHightlightReelRequestSuccess(P: ChannelHightlightReelModel): getChannelHightlightReelRequestSuccessAction {
        return ActionCreator(Constants.getChannelHightlightReelSuccessActionType, P);
    }
    export function getChannelHightlightReelRequestFailure(): getChannelHightlightReelRequestFailureAction {
        return ActionCreator(Constants.getChannelHightlightReelFailureActionType, null);
    }

    export type getChannelListRequestAction = Action<typeof Constants.getChannelListRequestActionType, null>
    export type getChannelListRequestSuccessAction = Action<typeof Constants.getChannelListSuccessActionType, GetChannelListModel[]>;
    export type getChannelListRequestFailureAction = Action<typeof Constants.getChannelListFailureActionType, null>;

    export function getChannelListRequest(): getChannelListRequestAction {
        return ActionCreator(Constants.getChannelListRequestActionType, null);
    }
    export function getChannelListRequestSuccess(P: GetChannelListModel[]): getChannelListRequestSuccessAction {
        return ActionCreator(Constants.getChannelListSuccessActionType, P);
    }
    export function getChannelListRequestFailure(): getChannelListRequestFailureAction {
        return ActionCreator(Constants.getChannelListFailureActionType, null);
    }

    export type sortedChannelRequestAction = Action<typeof Constants.sortedChannelRequestActionType, PatchChannelOrderModel>
    export type sortedChannelRequestSuccessAction = Action<typeof Constants.sortedChannelSuccessActionType, null>;
    export type sortedChannelRequestFailureAction = Action<typeof Constants.sortedChannelFailureActionType, null>;

    export function sortedChannelRequest(P: PatchChannelOrderModel): sortedChannelRequestAction {
        return ActionCreator(Constants.sortedChannelRequestActionType, P);
    }
    export function sortedChannelRequestSuccess(): sortedChannelRequestSuccessAction {
        return ActionCreator(Constants.sortedChannelSuccessActionType, null);
    }
    export function sortedChannelRequestFailure(): sortedChannelRequestFailureAction {
        return ActionCreator(Constants.sortedChannelFailureActionType, null);
    }


    export type addHighlightReelVideoRequestAction = Action<typeof Constants.addHighlightReelVideoRequestActionType, string>
    export type addHighlightReelVideoRequestSuccessAction = Action<typeof Constants.addHighlightReelVideoRequestSuccessActionType, null>;
    export type addHighlightReelVideoRequestFailureAction = Action<typeof Constants.addHighlightReelVideoRequestFailureActionType, null>;

    export function addHighlightReelVideoRequest(P: string): addHighlightReelVideoRequestAction {
        return ActionCreator(Constants.addHighlightReelVideoRequestActionType, P);
    }
    export function addHighlightReelVideoRequestSuccess(): addHighlightReelVideoRequestSuccessAction {
        return ActionCreator(Constants.addHighlightReelVideoRequestSuccessActionType, null);
    }
    export function addHighlightReelVideoRequestFailure(): addHighlightReelVideoRequestFailureAction {
        return ActionCreator(Constants.addHighlightReelVideoRequestFailureActionType, null);
    }

    export type deleteHighlightReelVideoRequestAction = Action<typeof Constants.deleteHighlightReelVideoRequestActionType, string>
    export type deleteHighlightReelVideoRequestSuccessAction = Action<typeof Constants.deleteHighlightReelVideoRequestSuccessActionType, null>;
    export type deleteHighlightReelVideoRequestFailureAction = Action<typeof Constants.deleteHighlightReelVideoRequestFailureActionType, null>;

    export function deleteHighlightReelVideoRequest(P: string): deleteHighlightReelVideoRequestAction {
        return ActionCreator(Constants.deleteHighlightReelVideoRequestActionType, P);
    }
    export function deleteHighlightReelVideoRequestSuccess(): deleteHighlightReelVideoRequestSuccessAction {
        return ActionCreator(Constants.deleteHighlightReelVideoRequestSuccessActionType, null);
    }
    export function deleteHighlightReelVideoRequestFailure(): deleteHighlightReelVideoRequestFailureAction {
        return ActionCreator(Constants.deleteHighlightReelVideoRequestFailureActionType, null);
    }

    export type getHighlightReelVideoRequestAction = Action<typeof Constants.getHighlightReelVideoRequestActionType, string>
    export type getHighlightReelVideoRequestSuccessAction = Action<typeof Constants.getHighlightReelVideoRequestSuccessActionType, GetHighlightReelModel[]>;
    export type getHighlightReelVideoRequestFailureAction = Action<typeof Constants.getHighlightReelVideoRequestFailureActionType, null>;

    export function getHighlightReelVideoRequest(P: string): getHighlightReelVideoRequestAction {
        return ActionCreator(Constants.getHighlightReelVideoRequestActionType, P);
    }
    export function getHighlightReelVideoRequestSuccess(P: GetHighlightReelModel[]): getHighlightReelVideoRequestSuccessAction {
        return ActionCreator(Constants.getHighlightReelVideoRequestSuccessActionType, P);
    }
    export function getHighlightReelVideoRequestFailure(): getHighlightReelVideoRequestFailureAction {
        return ActionCreator(Constants.getHighlightReelVideoRequestFailureActionType, null);
    }

}

export type ChannelActions = Omit<typeof ChannelActions, 'Type'>;
export const useChannelActions = (dispatch: Dispatch) => {
    const { ...actions } = ChannelActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as ChannelActions;
};