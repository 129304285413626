import React from 'react';
import { MemberInvoiceModel } from 'src/models/usersModel';
import { defaultDateFormatter } from 'src/components/utilities/utils';

interface IProps {
  invoiceData: MemberInvoiceModel | undefined;
}
const InvoiceDetailModal: React.FC<IProps> = ({ invoiceData }) => {
  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="invoiceDetailModal"
      aria-labelledby="invoiceDetailModalLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-col flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="invoiceDetailModalLabel">
              {`Order ${invoiceData && invoiceData.refnumber}`}
            </h5>
            <table className="table-auto w-full border mt-2">
              <thead className="text-xs font-semibold bg-gray-50">
                <tr className="uppercase">
                  <th className="p-2">
                    <div className="font-semibold text-left">Date</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Order Total</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Total After Discount</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                <tr>
                  <td className="p-2">
                    <div className="text-left">
                      {invoiceData && invoiceData.orderdate ? defaultDateFormatter(invoiceData.orderdate) : ""}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-left">{invoiceData && invoiceData.ordertotal}</div>
                  </td>
                  <td className="p-2">
                    <div className="text-left">
                      {invoiceData && invoiceData.orderincdiscounttotal}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table-auto w-full border mt-5">
              <thead className="text-xs font-semibold bg-gray-50">
                <tr className="uppercase">
                  <th className="p-2">
                    <div className="font-semibold text-left">Description</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Channel ID</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Video ID </div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Total</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">After Discount</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {invoiceData &&
                  invoiceData.items.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="p-2">
                          <div className="text-left">{item.description}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.channelid}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.videoid}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.costex}</div>
                        </td>
                        <td className="p-2">
                          <div className="text-left">{item.membercostex}</div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <button
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailModal;
