import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import MultipleChoiceModal from './MultipleChoiceModal';
import OpenTextModal from './OpenTextModal';
import QuizViewResultModel from './QuizViewResultModel';
import MultipleChoiceResultModal from './MultipleViewResultModel';
import SurveyModal from './SurveyModal';
import QuizModal from './QuizModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import BarChart from 'src/components/charts/barchart/BarChart';
import { useVideosActions } from 'src/store/videos/actions';
import { successMsg } from 'src/components/utilities/utils';
import ResultComponent from './ResultComponent';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
import ToastMessage from 'src/components/ToastContainer';
import StarRatingModal from './StarRatingModal';
import { CSVLink } from 'react-csv';
import Loader from 'src/components/loader';
import axios from 'axios';
import { errorMsg } from 'src/components/utilities/utils';
import DeletePollModal from './DeletePollModal';
const InteractivityCreatePoll = () => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const quizRef = useRef<HTMLButtonElement>(null);
  const multipleChoiceRef = useRef<HTMLButtonElement>(null);
  const starRatingRef = useRef<HTMLButtonElement>(null);
  const openTextRef = useRef<HTMLButtonElement>(null);
  const surveyRef = useRef<HTMLButtonElement>(null);
  const [downloadData, setDownloadData] = useState<any>();
  const [modalEditFlag, setModalEditFlag] = useState<boolean>(false);
  const [displayResult, setDisplayResult] = useState<boolean>(false);
  const [pollId, setPollId] = useState<string>();
  const [downloading, setDownloading] = useState(false);
  const [pollType, setPollType] = useState<number>();
  const [chartData, setChartData] = useState<any>();
  const [resultData, setResultData] = useState<any>();
  const [barChartData, setBarChartData] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [''],
        backgroundColor: ['#4285F4']
      }
    ]
  });

  const {
    singleVideo,
    polls,
    pollsResult,
    isPollResultLoading,
    isPollSaved,
    message,
    error,
    isPollDeleted,
    isPollStatusUpdated
  } = useSelector((state: RootState) => {
    return {
      singleVideo: state.videos.singleVideo,
      polls: state.videos.polls,
      pollsResult: state.videos.pollsResult,
      isPollResultLoading: state.videos.isPollResultLoading,
      isPollSaved: state.videos.isPollSaved,
      message: state.videos.message,
      error: state.videos.error,
      isPollDeleted: state.videos.isPollDeleted,
      isPollStatusUpdated: state.videos.isPollStatusUpdated
    };
  });
  const getVideoStatus = (status: number) => {
    if (status === 0) {
      return 'Standby';
    } else if (status === 1) {
      return 'Live';
    } else if (status === 2) {
      return 'OnDemand';
    } else if (status === 3) {
      return 'Stopped';
    } else if (status === 4) {
      return 'Processing';
    } else if (status === 5) {
      return 'Offline';
    }
  };
  const { id } = useParams<string>();

  useEffect(() => {
    if (id) {
      videoAction.getPollRequest(id);
      videoAction.getSingleVideosRequest(id);
    }
  }, [id]);

  useEffect(() => {
    if (isPollSaved || isPollDeleted || isPollStatusUpdated) {
      id && videoAction.getPollRequest(id);
    }
  }, [isPollSaved, isPollDeleted, isPollStatusUpdated]);

  const handlePollType = (pollType: number) => {
    if (pollType === 0) {
      return 'fa fa-trophy';
    } else if (pollType === 1) {
      return 'fa fa-th-list';
    } else if (pollType === 3) {
      return 'fa fa-star';
    } else if (pollType === 2) {
      return 'fa fa-question';
    } else if (pollType === 4) {
      return 'fas fa-poll-h';
    }
  };

  const handlePollTypeName = (pollType: number) => {
    if (pollType === 0) {
      return 'Quiz';
    } else if (pollType === 1) {
      return 'Multiple Choice';
    } else if (pollType === 3) {
      return 'Star Rating';
    } else if (pollType === 2) {
      return 'Open Text';
    } else if (pollType === 4) {
      return 'Survey';
    }
  };

  const handlePollResultClick = (pollId: string) => {
    setDisplayResult(true);
    videoAction.getPollsResultRequest(pollId);
  };

  const handleViewQuizResultClick = (pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
  };

  useEffect(() => {
    setBarChartData({
      labels: chartData && chartData.ratingForChartResultLabels['1']?.map((data: any) => data),
      datasets: [
        {
          label: '',
          data: chartData && chartData.ratingForChartResultValues['1']?.map((data: any) => data),
          backgroundColor: ['#4285F4']
        }
      ]
    });
  }, [chartData]);
  useEffect(() => {
    if (pollsResult) {
      if (pollsResult.pollType === 3) {
        setChartData(pollsResult);
      } else {
        setResultData(pollsResult);
      }
    }
  }, [pollsResult]);

  useEffect(() => {
    if (isPollSaved || isPollDeleted || isPollStatusUpdated) {
      successMsg(message);
      videoAction.resetVideoLibraryRequest();
    }
  }, [isPollSaved, isPollDeleted, isPollStatusUpdated]);

  useEffect(() => {
    if (polls && polls.length && singleVideo) {
      const pollId = polls.map((poll) => `${poll.pollId}`).join(',');
      const quizName = polls.map((poll) => `${poll.pollTitle}`).join(',');
      const csvData = [
        {
          PollId: pollId,
          QuizName: quizName,
          VideoId: singleVideo.id,
          VideoName: singleVideo.title,
          Duration: singleVideo.duration
        }
      ];
      if (csvData) {
        setDownloadData(csvData);
      }
    }
  }, [polls, singleVideo]);

  const handleEditClick = (pollType: number, pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
    if (pollType === 0) {
      if (quizRef.current) {
        quizRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 1) {
      if (multipleChoiceRef.current) {
        multipleChoiceRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 3) {
      if (starRatingRef.current) {
        starRatingRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 2) {
      if (openTextRef.current) {
        openTextRef.current.click();
        setModalEditFlag(true);
      }
    } else if (pollType === 4) {
      if (surveyRef.current) {
        surveyRef.current.click();
        setModalEditFlag(true);
      }
    }
  };
  const handleDownloadQuizResultClick = (pollId: string) => {
    setDownloading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_API_URL}/v2/video/polls/${pollId}/download/quiz?videoId=${id}`
      )
      .then((response: any) => {
        downloadExcel(response.data.result);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setDownloading(false);
      });
  };
  const downloadExcel = (result: any) => {
    if (result) {
      const decodedData = atob(result.filestream);

      const uint8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uint8Array[i] = decodedData.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const fileName = result.filename;
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      videoAction.resetVideoLibraryRequest();
    }
  }, [error]);

  const handlePollStatus = (pollId: string, status: any) => {
    const payloadData = {
      pollId: pollId,
      updateData: [{ op: 'replace', path: 'activePollStatus', value: status ? 1 : null }]
    };
    videoAction.updatePollStatusRequest(payloadData);
  };

  const defaultPollData = [0, 1, 3, 2, 4];

  const handleModalName = (pollType: number) => {
    if (pollType === 0) {
      return 'QuizModal';
    } else if (pollType === 1) {
      return 'MultipleChoiceModal';
    } else if (pollType === 3) {
      return 'StarRatingModal';
    } else if (pollType === 2) {
      return 'OpenTextModal';
    } else if (pollType === 4) {
      return 'SurveyModal';
    }
  };

  const handleRefreshClick = (pollId: string) => {
    videoAction.getPollsResultRequest(pollId);
  };

  const downloadAllClick = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/v2/video/polls/download/all?videoId=${id}`)
      .then((response: any) => {
        downloadExcel(response.data.result);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setDownloading(false);
      });
  };
  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Polls</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Engage</Link>
          </li>
          <li>
            <strong>Polls</strong>
          </li>
          <li>
            <strong>{singleVideo && singleVideo.title}</strong>
          </li>
          <li>
            <strong>{singleVideo && getVideoStatus(singleVideo.videoStatus)}</strong>
          </li>
        </ol>
      </div>
      <div className="dashboard-card my-5 mb-10 py-4">
        <Link to={`/videos/${id}`}>
          <i className="fa fa-arrow-left mr-1"></i> Back to Video
        </Link>
        <div className="lg:grid lg:grid-cols-2 gap-6 mt-3">
          <div className="border rounded-md">
            <div className="flex items-center justify-between border-b px-5 py-4">
              <h2 className="font-semibold mb-4">List</h2>
              <div>
                <div className="md:flex items-center">
                  <button
                    type="button"
                    onClick={() => downloadAllClick()}
                    className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                    Download All
                  </button>

                  <div className="dropdown relative">
                    <button
                      className="dropdown-toggle border input-style mx-auto flex-items btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn"
                      type="button"
                      id="action-dropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Create Poll
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-ul w-32 create-poll-menu"
                      aria-labelledby="action-dropdown">
                      <li className="cursor-pointer" onClick={() => setPollType(0)}>
                        <span
                          ref={quizRef}
                          className="action-menu"
                          data-bs-toggle="modal"
                          data-bs-target="#QuizModal">
                          Quiz
                        </span>
                      </li>
                      <li className="cursor-pointer" onClick={() => setPollType(1)}>
                        <span
                          ref={multipleChoiceRef}
                          className="action-menu"
                          data-bs-toggle="modal"
                          data-bs-target="#MultipleChoiceModal">
                          Multiple Choice
                        </span>
                      </li>
                      <li className="cursor-pointer">
                        <span
                          ref={starRatingRef}
                          className="action-menu"
                          data-bs-toggle="modal"
                          data-bs-target="#StarRatingModal"
                          onClick={() => setPollType(3)}>
                          Star Rating
                        </span>
                      </li>
                      <li className="cursor-pointer" onClick={() => setPollType(2)}>
                        <span
                          ref={openTextRef}
                          className="action-menu"
                          data-bs-toggle="modal"
                          data-bs-target="#OpenTextModal">
                          Open Text
                        </span>
                      </li>
                      <li className="cursor-pointer" onClick={() => setPollType(4)}>
                        <span
                          ref={surveyRef}
                          className="action-menu"
                          data-bs-toggle="modal"
                          data-bs-target="#SurveyModal">
                          Survey
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center px-5 py-4">
              <h5 className="font-semibold mb-3">Embed Polls & Quizzes into your Video</h5>
              {polls && polls.length ? (
                <>
                  {polls &&
                    polls.map((poll) => {
                      return (
                        <div
                          key={poll.pollId}
                          className="flex p-4 border rounded-md mb-2 justify-between cursor-pointer">
                          <div
                            onClick={() => handlePollResultClick(poll.pollId)}
                            className="site-clr flex items-center">
                            <p className="gd-info mr-4">
                              <i className={`${handlePollType(poll.pollType)}`}></i>
                            </p>
                            <div className="text-left">
                              <span className="font-semibold">
                                {handlePollTypeName(poll.pollType)}
                              </span>
                              <p>{poll.pollTitle}</p>
                            </div>
                          </div>

                          <div className="flex">
                            <div className="form-check form-switch pl-0 mr-3 relative">
                              <input
                                onChange={(e) => handlePollStatus(poll.pollId, e.target.checked)}
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                checked={poll.activePollStatus === null ? false : true}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                            </div>
                            <span>
                              <div className="text-left mt-2">
                                <div className="dropdown relative">
                                  <button
                                    className="dropdown-toggle"
                                    type="button"
                                    id="action-dropdown"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <img
                                      src="../../assets/img/dots-vertical.svg"
                                      className="w-5 h-5"
                                    />
                                  </button>
                                  <ul
                                    className="dropdown-menu dropdown-menu-ul"
                                    aria-labelledby="action-dropdown">
                                    <li onClick={() => handleEditClick(poll.pollType, poll.pollId)}>
                                      <button
                                        type="button"
                                        className="action-menu flex items-center">
                                        <i className="fas fa-edit w-6 text-left"></i>
                                        <span>Edit</span>
                                      </button>
                                    </li>
                                    <li
                                      onClick={() => setPollId(poll.pollId)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#DeletePollModal">
                                      <span className="action-menu flex items-center cursor-pointer text-red-500">
                                        <i className="fas fa-trash w-6"></i>
                                        <span>Delete</span>
                                      </span>
                                    </li>
                                    {poll.pollType === 0 && (
                                      <li
                                        onClick={() => handleDownloadQuizResultClick(poll.pollId)}>
                                        <button
                                          type="button"
                                          className="action-menu flex items-center">
                                          <i className="fas fa-play w-6 text-left"></i>
                                          <span>Download Results</span>
                                        </button>
                                      </li>
                                    )}

                                    {poll.pollType === 0 ? (
                                      <li onClick={() => handleViewQuizResultClick(poll.pollId)}>
                                        <button
                                          type="button"
                                          className="action-menu flex items-center"
                                          data-bs-toggle="modal"
                                          data-bs-target="#QuizResultModal">
                                          <i className="fas fa-play w-6 text-left"></i>
                                          <span>View Result</span>
                                        </button>
                                      </li>
                                    ) : poll.pollType === 1 ? (
                                      <li onClick={() => handleViewQuizResultClick(poll.pollId)}>
                                        <button
                                          type="button"
                                          className="action-menu flex items-center"
                                          data-bs-toggle="modal"
                                          data-bs-target="#MultipleChoiceResultModal">
                                          <i className="fas fa-play w-6 text-left"></i>
                                          <span>View Result</span>
                                        </button>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {defaultPollData &&
                    defaultPollData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => setPollType(item)}
                          data-bs-toggle="modal"
                          data-bs-target={`#${handleModalName(item)}`}
                          className="flex p-4 border rounded-md mb-2 justify-between cursor-pointer">
                          <div className="site-clr flex items-center">
                            <p className="gd-info mr-4">
                              <i className={`${handlePollType(item)}`}></i>
                            </p>
                            <div className="text-left">
                              <span className="font-semibold">{handlePollTypeName(item)}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
          <div className="border rounded-md">
            <div className="flex items-center justify-between border-b px-5 py-4 flex-col">
              <h2 className="font-semibold mb-4">Live Results</h2>

              {isPollResultLoading ? (
                <Loader />
              ) : (
                <div className={`w-full ${displayResult ? '' : 'hidden'}`}>
                  <div className="flex w-full justify-end mb-2">
                    <i
                      onClick={() => handleRefreshClick(pollsResult.pollId)}
                      className="fa fa-refresh pull-left cursor-pointer"></i>
                  </div>
                  <div>
                    {pollsResult && pollsResult.pollType === 3 ? (
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <h5 className="font-bold">1. {pollsResult.pollTitle}</h5>
                          </div>
                          <div className="badge-pollAttended flex justify-center">
                            {pollsResult?.quizzes?.pollAttended}
                          </div>
                        </div>
                        <p>
                          Max Value : {pollsResult?.quizzes?.questions[0]?.choices[0]?.answerText}
                        </p>
                        <BarChart
                          chartData={barChartData}
                          options={{
                            indexAxis: 'y',
                            responsive: true
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <ResultComponent resultData={resultData} />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="text-center px-5 py-4"></div>
          </div>
        </div>
      </div>
      {id && (
        <>
          <QuizModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <MultipleChoiceModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <StarRatingModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <OpenTextModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <SurveyModal
            videoId={id}
            singleVideo={singleVideo}
            pollType={pollType}
            modalEditFlag={modalEditFlag}
            setModalEditFlag={setModalEditFlag}
          />
          <QuizViewResultModel />
          <MultipleChoiceResultModal />
          <DeletePollModal pollId={pollId} />
        </>
      )}
    </div>
  );
};

export default InteractivityCreatePoll;
