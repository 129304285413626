export const PieChartData: any = [
  {
    id: 1,
    name: 'Video',
    year: 2019,
    userGain: 72
  },
  {
    id: 2,
    name: 'Channel',
    year: 2019,
    userGain: 28
  },
  {
    id: 3,
    name: 'Course',
    year: 2019,
    userGain: 28
  },
  {
    id: 4,
    name: 'Subscription',
    year: 2019,
    userGain: 14
  }
];
