import React, { useState, useEffect, useRef } from 'react';
import '../../styles.scss';
import { useForm } from 'react-hook-form';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import '../../../../../../node_modules/lc-color-picker/lc_color_picker.min.js';
interface IProps {
  id: string;
}
const ChannelSpotLightRow: React.FC<IProps> = ({ id }) => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const ThumbnailInputFile = useRef<HTMLInputElement>(null);
  const [channelThumbnail, setChannelThumbnail] = useState<string>('');
  const [removeChannelThumbnail, setRemoveChannelThumbnail] = useState<boolean>(false);
  const [channelThumbnailName, setChannelThumbanilName] = useState<string>('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset
  } = useForm();
  const { singleChannel, isChannelSavedLoading, isChannelUpdated } = useSelector(
    (state: RootState) => {
      return {
        singleChannel: state.channel.singleChannel,
        isChannelSavedLoading: state.channel.isChannelSavedLoading,
        isChannelUpdated: state.channel.isChannelUpdated
      };
    }
  );
  const onSubmit = (data: any) => {
    const payloadData = {
      channelId: data.id,
      updateData: [
        { op: 'replace', path: 'displayAsSpotlightChannel', value: data.displayAsSpotlightChannel },
        { op: 'replace', path: 'fontColor', value: data.fontColor },
        { op: 'replace', path: 'background', value: localStorage.getItem('backgroundColor') },
        { op: 'replace', path: 'backgroundAnimation', value: data.backgroundAnimation },
        { op: 'replace', path: 'displayFont', value: data.displayFont },
        { op: 'replace', path: 'channelThumbnail', value: channelThumbnail }
      ]
    };
    channelActions.updateChannelRequest(payloadData);
  };

  useEffect(() => {
    if (singleChannel) {
      reset(singleChannel);
      setChannelThumbnail(singleChannel.channelThumbnail);
      localStorage.setItem('backgroundColor', singleChannel.background);
    }
  }, [singleChannel]);
  const handleThumbnailImageClick = () => {
    if (ThumbnailInputFile.current) {
      ThumbnailInputFile.current.click();
    }
  };
  const handleThumbnailImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      setChannelThumbanilName(selectedFile[0].name);
      uploadImageToS3(
        selectedFile[0],
        `v2/channel/upload/channelthumbnail?channelId=${id}&`,
        setChannelThumbnail,
        'channelImagesUrl'
      );
    }
  };
  useEffect(() => {
    if (isChannelUpdated) {
      setChannelThumbanilName('');
      setRemoveChannelThumbnail(false);
      channelActions.getSingleChannelRequest(id);
    }
  }, [isChannelUpdated]);
  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `new lc_color_picker('#background',{on_change :${function (
      new_value: string
    ) {
      localStorage.setItem('backgroundColor', new_value);
    }}})`;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="tab-pane fade" id="tabs-spotlightrows" role="tabpanel">
      <form onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-4 ml-auto mb-3">
          Save Styles
        </button>
        {isChannelSavedLoading && <Loader />}
        <div className="p-5">
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Highlight Channel
              </label>
              <p>
                When selected, the Channel will appear in Spotlight Rows if Channels has been
                selected. The spotlight row will display a list of cards for each of the highlighted
                channels on the video library, allowing a user to see and select one of the
                highlighted channels from the card list.
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayAsSpotlightChannel"
                      {...register('displayAsSpotlightChannel')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Font Color
              </label>
              <p>
                Select the font color that will appear for the channel name displayed on the video
                card.
              </p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <input type="color" id="fontColor" {...register('fontColor')} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Background Color
              </label>
              <p>
                Select a color or gradient overlay for your video card, or use as an alternative to
                the background image.
              </p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <input type="text" id="background" {...register('background')} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Color Animation
              </label>
              <p>Animate the color overlay when gradient colors are selected.</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="backgroundAnimation"
                      {...register('backgroundAnimation')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Font
              </label>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayFont"
                      {...register('displayFont')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Channel Thumbnail
              </label>
              <p>Display a background image on your video card. Recommended Size: 220x180px</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="ml-10">
                {singleChannel &&
                  singleChannel.channelThumbnail?.length !== 0 &&
                  singleChannel.channelThumbnail !== null && (
                    <>
                      <img
                        src={`${singleChannel && singleChannel.channelThumbnail}`}
                        className={`ChannelImageHeight ${removeChannelThumbnail ? 'hidden' : ''}`}
                      />
                    </>
                  )}
                {channelThumbnail && (
                  <span
                    onClick={() => {
                      setChannelThumbnail(''),
                        setRemoveChannelThumbnail(true),
                        setChannelThumbanilName('');
                    }}
                    className="cursor-pointer site-clr">
                    Remove
                  </span>
                )}
              </div>
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <label
                    onClick={handleThumbnailImageClick}
                    className="mx-0 px-6 btn-primary discard-btn w-28 mt-0 cursor-pointer">
                    Upload
                  </label>
                  <span className="ml-2">
                    {channelThumbnailName ? channelThumbnailName : 'No file chosen'}
                  </span>
                  <input
                    ref={ThumbnailInputFile}
                    type="file"
                    onChange={(e) => handleThumbnailImageUpload(e.target.files)}
                    className="hidden"
                    onClick={onInputClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChannelSpotLightRow;
