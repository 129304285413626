import React, { useEffect, useState, useRef } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useParams } from 'react-router-dom';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  EventRegistrationImagesViewModel,
  PatchJsonModel,
  PatchJsonData
} from 'src/models/eventModel';
import { useForm } from 'react-hook-form';
import SelectNewThumbModal from '../../videos/components/SelectNewThumbModal';
import { uploadImageToS3WithReturnData } from 'src/components/utilities/utils';
const ImagesStyles: React.FC = () => {
  const dispatch = useDispatch();
  const eventActions = useEventActions(dispatch);
  const inputEventRegistrationImageFile = useRef<HTMLInputElement>(null);
  const inputBannerImageFile = useRef<HTMLInputElement>(null);
  const inputBodyBackgroundImageFile = useRef<HTMLInputElement>(null);
  const { eventId } = useParams<string>();
  const [eventRegistrationLogo, setEventRegistrationLogo] = useState<any>(null);
  const [bannerImage, setBannerImage] = useState<any>(null);
  const [bodyBackgroundImage, setBodyBackgroundImage] = useState<any>(null);
  const [eventUploadLogo, setEventUploadLogo] = useState<any>(null);
  const [bannerUploadLogo, setBannerUploadLogo] = useState<any>(null);
  const [bodyBackgroundUploadImage, setBodyBackgroundUploadImage] = useState<any>(null);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<any>(null);
  const [bannerBackgroundColor, setBannerBackgroundColor] = useState<any>(null);
  const [bodyBackgroundColor, setBodyBackgroundColor] = useState<any>(null);
  const [formButtonColor, setFormButtonColor] = useState<any>(null);
  const [logoWidth, setLogoWidth] = useState<any>(0);
  const [h1Color, setH1Color] = useState<any>(null);
  const [formHeadingFontColor, setFormHeadingFontColor] = useState<any>(null);
  const [formLabelFontColor, setFormLabelFontColor] = useState<any>(null);
  const [eventLogoUploadReturnData, setEventLogoUploadReturnData] = useState<any>(null);
  const [bannerImageUploadReturnData, setBannerImageUploadReturnData] = useState<any>(null);
  const [bodyImageUploadReturnData, setBodyImageUploadReturnData] = useState<any>(null);
  const [headerBackgroundColorChecked, setHeaderBackgroundColorChecked] = useState<any>(null);
  const [bannerBackgroundColorChecked, setBannerBackgroundColorChecked] = useState<any>(null);
  const [bodyBackgroundColorChecked, setBodyBackgroundColorChecked] = useState<any>(null);
  const [h1ColorChecked, seth1ColorChecked] = useState<any>(null);
  const [formHeadingFontColorChecked, setFormHeadingFontColorChecked] = useState<any>(null);
  const [formLabelFontColorChecked, setFormLabelFontColorChecked] = useState<any>(null);
  const [formButtonColorChecked, setFormButtonColorChecked] = useState<any>(null);
  const { register, handleSubmit, reset, setValue } = useForm<EventRegistrationImagesViewModel>();
  const handleSelectNewClick = (inputName: any) => {
    if (inputName.current) {
      inputName.current.click();
    }
  };
  const { eventRegistrationImagesView, isEventSaved } = useSelector((state: RootState) => {
    return {
      eventRegistrationImagesView: state.event.singleEvent.eventRegistrationField
        ? state.event.singleEvent.eventRegistrationField.eventRegistrationImagesViewModel
        : null,
      isEventSaved: state.event.isEventSaved,
      isEventUpdated: state.event.isEventUpdated
    };
  });

  useEffect(() => {
    if (isEventSaved === true) {
      reset({
        uploadBannerUrl: '',
        uploadBannerUrlS3: '',
        uploadLogoUrl: '',
        uploadLogoUrlS3: '',
        emailOnRegistration: true,
        emailSubject: '',
        emailBody: '',
        displayHeader: true,
        headerBackgroundColor: '',
        bannerBackgroundColor: '',
        bodyBackgroundColor: '',
        formButtonColor: '',
        h1Color: '',
        customFontLoaderCode: '',
        headingFontFamilyCss: '',
        sendICSAttachment: true,
        logoWidth: '',
        customCSS: '',
        formHeadingFontSize: 0,
        formHeadingFontColor: '',
        formHeadingFontFamily: '',
        formLabelFontSize: 0,
        formLabelFontColor: '',
        formLabelFontFamily: ''
      });
    }
  }, [isEventSaved]);
  useEffect(() => {
    if (eventRegistrationImagesView) {
      reset(eventRegistrationImagesView);
      setEventRegistrationLogo(
        eventRegistrationImagesView.uploadLogoUrl
          ? eventRegistrationImagesView.uploadLogoUrl
          : '../../assets/img/no_image.png'
      );
      setEventLogoUploadReturnData(eventRegistrationImagesView.uploadLogoUrl);
      setBannerImage(
        eventRegistrationImagesView.uploadBannerUrl
          ? eventRegistrationImagesView.uploadBannerUrl
          : '../../assets/img/no_image.png'
      );
      setBannerImageUploadReturnData(eventRegistrationImagesView.uploadBannerUrl);
      setBodyBackgroundImage(
        eventRegistrationImagesView.bodyBackgroundImageUrl
          ? eventRegistrationImagesView.bodyBackgroundImageUrl
          : '../../assets/img/no_image.png'
      );
      setBodyImageUploadReturnData(eventRegistrationImagesView.bodyBackgroundImageUrl);
      setLogoWidth(eventRegistrationImagesView.logoWidth);
      setHeaderBackgroundColor(eventRegistrationImagesView.headerBackgroundColor);
      setHeaderBackgroundColorChecked(
        eventRegistrationImagesView.headerBackgroundColor == null ? true : false
      );
      setBannerBackgroundColor(eventRegistrationImagesView.bannerBackgroundColor);
      setBannerBackgroundColorChecked(
        eventRegistrationImagesView.bannerBackgroundColor == null ? true : false
      );
      setBodyBackgroundColor(eventRegistrationImagesView.bodyBackgroundColor);
      setBodyBackgroundColorChecked(
        eventRegistrationImagesView.bodyBackgroundColor == null ? true : false
      );
      setFormButtonColor(eventRegistrationImagesView.formButtonColor);
      setFormButtonColorChecked(eventRegistrationImagesView.formButtonColor == null ? true : false);
      setH1Color(eventRegistrationImagesView.h1Color);
      seth1ColorChecked(eventRegistrationImagesView.h1Color == null ? true : false);
      setFormHeadingFontColor(eventRegistrationImagesView.formHeadingFontColor);
      setFormHeadingFontColorChecked(
        eventRegistrationImagesView.formHeadingFontColor == null ? true : false
      );
      setFormLabelFontColor(eventRegistrationImagesView.formLabelFontColor);
      setFormLabelFontColorChecked(
        eventRegistrationImagesView.formLabelFontColor == null ? true : false
      );
    }
    if (eventId?.length == 0) {
      reset({
        uploadBannerUrl: '',
        uploadBannerUrlS3: '',
        uploadLogoUrl: '',
        uploadLogoUrlS3: '',
        emailOnRegistration: true,
        emailSubject: '',
        emailBody: '',
        displayHeader: true,
        headerBackgroundColor: '',
        bannerBackgroundColor: '',
        bodyBackgroundColor: '',
        formButtonColor: '',
        h1Color: '',
        customFontLoaderCode: '',
        headingFontFamilyCss: '',
        sendICSAttachment: true,
        logoWidth: '',
        customCSS: '',
        formHeadingFontSize: 0,
        formHeadingFontColor: '',
        formHeadingFontFamily: '',
        formLabelFontSize: 0,
        formLabelFontColor: '',
        formLabelFontFamily: ''
      });
    }
  }, [eventRegistrationImagesView]);

  const onImageChange = (event: any, setVariable: any, setFiletype: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    setFiletype(file);
    reader.onloadend = () => {
      setVariable(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onSubmit = async (data: any) => {
    const payloadData = {
      id: data.id,
      eventId: data.eventId,
      displayHeader: data.displayHeader,
      headerBackgroundColor: data.headerBackgroundColor,
      bannerBackgroundColor: data.bannerBackgroundColor,
      bodyBackgroundColor: data.bodyBackgroundColor,
      formButtonColor: data.formButtonColor,
      h1Color: data.h1Color,
      customFontLoaderCode: data.customFontLoaderCode,
      headingFontFamilyCss: data.headingFontFamilyCss,
      logoWidth: data.logoWidth,
      customCSS: data.customCSS,
      formHeadingFontSize: data.formHeadingFontSize,
      formHeadingFontColor: data.formHeadingFontColor,
      formHeadingFontFamily: data.formHeadingFontFamily,
      formLabelFontSize: data.formLabelFontSize,
      formLabelFontColor: data.formLabelFontColor,
      formLabelFontFamily: data.formLabelFontFamily,
      uploadLogoUrl: eventLogoUploadReturnData,
      uploadBannerUrl: bannerImageUploadReturnData,
      bodyBackgroundImageUrl: bodyImageUploadReturnData
    };
    eventActions.updateEventLayoutRequest(payloadData);
  };

  const removeSelectedImage = (selectedCurrentImage: any) => {
    selectedCurrentImage('https://app.webcastclouddev.com/Areas/Admin/Content/no_image.png');
  };
  const handleChangeColor = (colorCodeCheck: any, setColorCodeVariable: any, from: any) => {
    if (from === 'headerBackgroundColor') {
      setHeaderBackgroundColorChecked(colorCodeCheck);
    }
    if (from === 'bannerBackgroundColor') {
      setBannerBackgroundColorChecked(colorCodeCheck);
    }
    if (from === 'bodyBackgroundColor') {
      setBodyBackgroundColorChecked(colorCodeCheck);
    }
    if (from === 'h1Color') {
      seth1ColorChecked(colorCodeCheck);
    }
    if (from === 'formHeadingFontColor') {
      setFormHeadingFontColorChecked(colorCodeCheck);
    }
    if (from === 'formLabelFontColor') {
      setFormLabelFontColorChecked(colorCodeCheck);
    }
    if (from === 'formButtonColor') {
      setFormButtonColorChecked(colorCodeCheck);
    }
    if (colorCodeCheck && from === 'headerBackgroundColor') {
      setValue('headerBackgroundColor', null);
    } else if (colorCodeCheck && from === 'bannerBackgroundColor') {
      setValue('bannerBackgroundColor', null);
    } else if (colorCodeCheck && from === 'bodyBackgroundColor') {
      setValue('bodyBackgroundColor', null);
    } else if (colorCodeCheck && from === 'h1Color') {
      setValue('h1Color', null);
    } else if (colorCodeCheck && from === 'formHeadingFontColor') {
      setValue('formHeadingFontColor', null);
    } else if (colorCodeCheck && from === 'formLabelFontColor') {
      setValue('formLabelFontColor', null);
    } else if (colorCodeCheck && from === 'formButtonColor') {
      setValue('formButtonColor', null);
    }
    if (colorCodeCheck) setColorCodeVariable(null);
    else setColorCodeVariable(null);
  };
  const isBackgroundColorTransparent = (checkedValue: any) => {
    if (checkedValue === null) return true;
    else return false;
  };
  const handleLogoImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      const reader = new FileReader();
      const file = selectedFile[0];
      reader.onloadend = () => {
        setEventRegistrationLogo(reader.result);
      };
      setEventUploadLogo(selectedFile[0].name);
      reader.readAsDataURL(file);
      if (selectedFile) {
        await uploadImageToS3WithReturnData(
          selectedFile[0],
          `v2/event/upload/logo?eventId=${eventId}`,
          (imageurl: any) => {
            setEventLogoUploadReturnData(imageurl);
          }
        );
      }
    }
  };

  const handleBannerImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      const reader = new FileReader();
      const file = selectedFile[0];
      reader.onloadend = () => {
        setBannerImage(reader.result);
      };
      setBannerUploadLogo(selectedFile[0].name);
      reader.readAsDataURL(file);
      if (selectedFile) {
        await uploadImageToS3WithReturnData(
          selectedFile[0],
          `v2/event/upload/bannerimage?eventId=${eventId}`,
          (imageurl: any) => {
            setBannerImageUploadReturnData(imageurl);
          }
        );
      }
    }
  };
  const handleBodyBackgroundImageUpload = async (selectedFile: any) => {
    if (selectedFile) {
      const reader = new FileReader();
      const file = selectedFile[0];
      reader.onloadend = () => {
        setBodyBackgroundImage(reader.result);
      };
      setBodyBackgroundUploadImage(selectedFile[0].name);
      reader.readAsDataURL(file);
      if (selectedFile) {
        await uploadImageToS3WithReturnData(
          selectedFile[0],
          `v2/event/upload/backgroundimage?eventId=${eventId}`,
          (imageurl: any) => {
            setBodyImageUploadReturnData(imageurl);
          }
        );
      }
    }
  };

  return (
    <>
      <div className="tab-pane fade " id="tabs-imagesStyles" role="tabpanel">
        <div className="shadow p-5 border">
          <div className="flex justify-center h-full">
            <div className=" w-full">
              {
                // loadingDetails ? (
                //   <Loader />
                // ) : (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <h2 className="font-semibold">Heading Style</h2>
                      <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 mt-5">
                        <div className={`form-group mb-4`}>
                          <label className="block mb-1">Event registration logo</label>
                          {eventRegistrationImagesView && (
                            <img
                              src={eventRegistrationLogo}
                              width="250px"
                              height="42px"
                              className="event-logo-img"
                            />
                          )}
                        </div>
                        <div className="flex flex-wrap items-center py-4 rounded-b-md">
                          <div className="mr-2">
                            <label
                              onClick={() => handleSelectNewClick(inputEventRegistrationImageFile)}
                              className="btn-primary cursor-pointer w-28 mx-0">
                              Upload
                            </label>
                            <input
                              ref={inputEventRegistrationImageFile}
                              type="file"
                              onChange={(e) => handleLogoImageUpload(e.target.files)}
                              className="hidden"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removeSelectedImage(setEventRegistrationLogo)}
                            className="mx-0 px-6 btn-primary discard-btn w-28 mx-0">
                            Remove
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <label className="block mb-1">Logo Width</label>
                          <div className="flex">
                            <input
                              type="range"
                              min={0}
                              max={500}
                              id="logoWidth"
                              {...register('logoWidth', {
                                onChange: (e: any) => {
                                  setLogoWidth(e.target.value);
                                }
                              })}
                            />
                            <span className="ml-1"> {logoWidth}</span>
                          </div>
                        </div>
                        <div className={`form-group form-switch pl-0`}>
                          <label className="block mb-1">Display Video Channel Header</label>
                          <input
                            type="checkbox"
                            className="form-check-input switch-btn"
                            {...register('displayHeader')}
                          />
                        </div>
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">Header Background Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(
                                      event.target.checked,
                                      setHeaderBackgroundColor,
                                      'headerBackgroundColor'
                                    );
                                  }}
                                  checked={headerBackgroundColorChecked}
                                />{' '}
                                Transparent
                              </label>
                              {!headerBackgroundColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('headerBackgroundColor')}
                                  value={headerBackgroundColor}
                                  onChange={(event) => setHeaderBackgroundColor(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group mb-4`}>
                          <label className="block mb-1">Banner Image</label>
                          <label className="block text-sm font-medium mb-1">
                            (Recommended size:1400x400px)
                          </label>
                          {eventRegistrationImagesView && (
                            <img src={bannerImage} className="w-100 event-banner-img" />
                          )}
                        </div>
                        <div className="flex flex-wrap items-center py-4 rounded-b-md">
                          <div className="mr-2">
                            <label
                              onClick={() => handleSelectNewClick(inputBannerImageFile)}
                              className="btn-primary cursor-pointer w-28 mx-0">
                              Upload
                            </label>
                            <input
                              ref={inputBannerImageFile}
                              type="file"
                              onChange={(e) => handleBannerImageUpload(e.target.files)}
                              className="hidden"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removeSelectedImage(setBannerImage)}
                            className="mx-0 px-6 btn-primary discard-btn w-28 mx-0">
                            Remove
                          </button>
                        </div>
                      </div>
                      <div className={`form-group`}>
                        <fieldset>
                          <label className="block mb-1">Banner Background Color</label>
                          <div className="flex items-center">
                            <label className="block mb-1">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(event) => {
                                  handleChangeColor(
                                    event.target.checked,
                                    setBannerBackgroundColor,
                                    'bannerBackgroundColor'
                                  );
                                }}
                                checked={bannerBackgroundColorChecked}
                              />{' '}
                              Transparent
                            </label>
                            {!bannerBackgroundColorChecked && (
                              <input
                                type="color"
                                className="ml-1"
                                {...register('bannerBackgroundColor')}
                                onChange={(event) => setBannerBackgroundColor(event.target.value)}
                              />
                            )}
                          </div>
                        </fieldset>
                      </div>
                    </div>

                    <div className="mt-5">
                      <h2 className="font-semibold">Body Style</h2>

                      <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 mt-5">
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">Body Background Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(
                                      event.target.checked,
                                      setBodyBackgroundColor,
                                      'bodyBackgroundColor'
                                    );
                                  }}
                                  checked={bodyBackgroundColorChecked}
                                />{' '}
                                Transparent
                              </label>
                              {!bodyBackgroundColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('bodyBackgroundColor')}
                                  onChange={(event) => setBodyBackgroundColor(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group mb-4`}>
                          <label className="block mb-1">Body Background Image</label>
                          {eventRegistrationImagesView && (
                            <img src={bodyBackgroundImage} className="event-back-img" />
                          )}
                        </div>
                        <div className="flex flex-wrap items-center py-4 rounded-b-md">
                          <div className="mr-2">
                            <label
                              onClick={() => handleSelectNewClick(inputBodyBackgroundImageFile)}
                              className="btn-primary cursor-pointer w-28 mx-0">
                              Upload
                            </label>
                            <input
                              ref={inputBodyBackgroundImageFile}
                              type="file"
                              onChange={(e) => handleBodyBackgroundImageUpload(e.target.files)}
                              className="hidden"
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() => removeSelectedImage(setBodyBackgroundImage)}
                            className="mx-0 px-6 btn-primary discard-btn w-28 mx-0">
                            Remove
                          </button>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">H1 Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(event.target.checked, setH1Color, 'h1Color');
                                  }}
                                  checked={h1ColorChecked}
                                />{' '}
                                Transparent
                              </label>

                              {!h1ColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('h1Color')}
                                  onChange={(event) => setH1Color(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                        <div className={`form-group`}>
                          <label className="block mb-1">Custom Font Loader Code</label>
                          <input
                            type="text"
                            className="input-style"
                            {...register('customFontLoaderCode')}
                          />
                        </div>
                      </div>
                      <div className={`form-group`}>
                        <label className="block mb-1">Heading Font Family Css</label>
                        <input
                          type="text"
                          className="input-style"
                          {...register('headingFontFamilyCss')}
                        />
                      </div>
                    </div>

                    <div className="mt-5">
                      <h2 className="font-semibold">Form Style</h2>
                      <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <label className="block mb-1">Form Heading Font Size</label>
                          <select
                            id="formHeadingFontSize"
                            {...register('formHeadingFontSize')}
                            className="input-style">
                            {Array.from({ length: 500 }, (v, i) => i + 1).map((item) => {
                              return <option key={item} value={item}>{`${item}px`}</option>;
                            })}
                          </select>
                        </div>
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">Form Heading Font Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(
                                      event.target.checked,
                                      setFormHeadingFontColor,
                                      'formHeadingFontColor'
                                    );
                                  }}
                                  checked={formHeadingFontColorChecked}
                                />{' '}
                                Transparent
                              </label>
                              {!formHeadingFontColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('formHeadingFontColor')}
                                  onChange={(event) => setFormHeadingFontColor(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                        <div className={`form-group`}>
                          <label className="block mb-1">Form Heading Font Family</label>
                          <input
                            type="text"
                            className="input-style"
                            {...register('formHeadingFontFamily')}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <label className="block mb-1">Form Label Font Size</label>
                          <select {...register('formLabelFontSize')} className="input-style">
                            {Array.from({ length: 500 }, (v, i) => i + 1).map((item) => {
                              return <option key={item} value={item}>{`${item}px`}</option>;
                            })}
                          </select>
                        </div>
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">Form Label Font Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(
                                      event.target.checked,
                                      setFormLabelFontColor,
                                      'formLabelFontColor'
                                    );
                                  }}
                                  checked={formLabelFontColorChecked}
                                />{' '}
                                Transparent
                              </label>
                              {!formLabelFontColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('formLabelFontColor')}
                                  onChange={(event) => setFormLabelFontColor(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                        <div className={`form-group`}>
                          <label className="block mb-1">Form Label Font Family</label>
                          <input
                            type="text"
                            className="input-style"
                            {...register('formLabelFontFamily')}
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                        <div className={`form-group`}>
                          <fieldset>
                            <label className="block mb-1">Form Button Color</label>
                            <div className="flex items-center">
                              <label className="block mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(event) => {
                                    handleChangeColor(
                                      event.target.checked,
                                      setFormButtonColor,
                                      'formButtonColor'
                                    );
                                  }}
                                  checked={formButtonColorChecked}
                                />{' '}
                                Transparent
                              </label>
                              {!formButtonColorChecked && (
                                <input
                                  type="color"
                                  className="ml-1"
                                  {...register('formButtonColor')}
                                  onChange={(event) => setFormButtonColor(event.target.value)}
                                />
                              )}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <h2 className="font-semibold">Custom CSS</h2>
                      <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 mt-5">
                        <div className={`form-group mb-4`}>
                          <label className="block mb-1">Custom CSS</label>
                          <textarea className="input-style" {...register('customCSS')}></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                      <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                        Save
                      </button>
                      <button
                        type="button"
                        className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                        data-bs-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </form>
                </>
                //)
              }
            </div>
            <SelectNewThumbModal />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImagesStyles;
