import React, { useState, useEffect } from 'react';
import '../styles.scss';
import AddNavigationMenu from '../../videos/components/AddNavigationMenu';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import DeleteOrganisationModal from './DeleteOrganization/DeleteOrganisationModal';
import Editor from 'src/components/Editor';
import RegistrationOptions from './components/RegistrationOptions';
import RegistrationFields from './components/RegistrationFields';
import EmailConfirmation from './components/EmailConfirmation';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
const Registration = () => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const [editorState, setEditorState] = useState<string>('');
  const [videoLayout, setVideoLayout] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();
  const {
    authentication,
    isAuthenticationSaved,
    message,
    isRegistrationOptionAdded,
    isRegistrationOptionDeleted,
    isStandardFieldSaved
  } = useSelector((state: RootState) => {
    return {
      authentication: state.videoLibrary.authentication,
      isAuthenticationSaved: state.videoLibrary.isAuthenticationSaved,
      message: state.videoLibrary.message,
      isRegistrationOptionAdded: state.videoLibrary.isRegistrationOptionAdded,
      isRegistrationOptionDeleted: state.videoLibrary.isRegistrationOptionDeleted,
      isStandardFieldSaved: state.videoLibrary.isStandardFieldSaved
    };
  });
  useEffect(() => {
    if (authentication) {
      reset(authentication);
    }
  }, [authentication]);

  useEffect(() => {
    videoLibraryAction.getAuthenticationRequestRequest();
  }, []);

  useEffect(() => {
    if (
      isAuthenticationSaved ||
      isRegistrationOptionAdded ||
      isRegistrationOptionDeleted ||
      isStandardFieldSaved
    ) {
      successMsg(message);
      videoLibraryAction.resetVideoLibraryStateRequest();
    }
  }, [
    isAuthenticationSaved,
    isRegistrationOptionAdded,
    isRegistrationOptionDeleted,
    isStandardFieldSaved
  ]);

  const onSubmit = (data: any) => {
    videoLibraryAction.saveAuthenticationRequest(data);
  };
  return (
    <div>
      <ToastMessage />
      <div className="highlight-reel">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Registrations</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Video Library</Link>
            </li>
            <li>
              <strong>Registrations</strong>
            </li>
          </ol>
        </div>

        <div className="lg:flex">
          <div
            className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
              videoLayout ? 'hide-lists' : 'show-lists'
            }`}>
            <div className="mobile-dropdown" onClick={() => setVideoLayout(!videoLayout)}>
              <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
                <p>Registrations</p>
                <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
              </div>
            </div>
            <ul
              className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mt-4 mobile-layout-ul"
              id="tabs-tabVertical"
              role="tablist">
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabs-authentication"
                  className="nav-link my-0 tabs-link pl-3 flex items-center active"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-authentication"
                  role="tab"
                  aria-controls="tabs-authentication"
                  aria-selected="false">
                  Authentication
                </a>
              </li>
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabs-registerOptions"
                  className="
                  nav-link my-0 tabs-link pl-3 flex items-center"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-registerOptions"
                  role="tab"
                  aria-controls="tabs-registerOptions"
                  aria-selected="false">
                  Registration Options
                </a>
              </li>
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabs-registerFields"
                  className="
                  nav-link my-0 tabs-link pl-3 flex items-center"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-registerFields"
                  role="tab"
                  aria-controls="tabs-registerFields"
                  aria-selected="false">
                  Registration Fields
                </a>
              </li>
              <li className="nav-item flex-grow" role="presentation">
                <a
                  href="#tabsemailConfirmation"
                  className="
                  nav-link my-0 tabs-link pl-3 flex items-center"
                  data-bs-toggle="pill"
                  data-bs-target="#tabsemailConfirmation"
                  role="tab"
                  aria-controls="tabsemailConfirmation"
                  aria-selected="false">
                  Email Confirmation
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content lg:w-4/5 shadow border" id="tabs-tabContentVertical">
            <div className="tab-pane fade show active" id="tabs-authentication" role="tabpanel">
              <div className="md:p-5 p-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div id="videoAccordion">
                    <div className="accordion-item bg-white shadow border-0 mb-3">
                      <h2 className="accordion-header mb-0" id="branding">
                        <button
                          className="accordion-button
                          collapsed
                          accordion-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne">
                          Local Authentication Settings
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        data-bs-parent="#videoAccordion"
                        className="accordion-collapse border-0 collapse">
                        <div className="accordion-body">
                          <div className="p-5">
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Private Video Channel
                                </label>
                                <p>
                                  Make your main Video Library private. Require your viewers to
                                  login or register before browsing your video channel. If false,
                                  Anonymous users can browse your video library before registration
                                  or login.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="privateVideoChannel"
                                        {...register('privateVideoChannel')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Local Registration
                                </label>
                                <p>
                                  Allow your viewers to use the in-built registration page to
                                  Register and create a profile to access your video channel.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="localRegistration"
                                        {...register('localRegistration')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Local Login
                                </label>
                                <p>
                                  Allow your viewers to login to your video channel using the local
                                  directory.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="localLogin"
                                        {...register('localLogin')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Email is Username
                                </label>
                                <p>
                                  The viewers email address is used for registration and login,
                                  rather than a different username.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="emailIsUsername"
                                        {...register('emailIsUsername')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Require Password
                                </label>
                                <p>
                                  A user is required to set a password for their profile on
                                  registration and use this at time of Login if using the inbuilt
                                  local Login and Registration form.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="requiredPassword"
                                        {...register('requiredPassword')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-white shadow border-0 my-3">
                      <h2 className="accordion-header mb-0" id="headingTwo">
                        <button
                          className="accordion-button
                        collapsed
                        accordion-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo">
                          Integration Authentication Settings
                        </button>
                      </h2>

                      <div
                        id="collapseTwo"
                        className="accordion-collapse border-0 collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#videoAccordion">
                        <div className="accordion-body">
                          <div className="p-5">
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  iMIS Login Form
                                </label>
                                <p>
                                  Use the integrated iMIS Login form on the main account login page,
                                  rather than sending users to an iMIS website to login. Not
                                  recommended if using iMIS Single Sign-On.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="imisLoginForm"
                                        {...register('imisLoginForm')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Integration Provider Login Button
                                </label>
                                <p>
                                  Display a Login button on your video library that uses your
                                  selected Directory/CRM/AMS Integration provider from Settings
                                  Integration.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="integrationProviderLoginButton"
                                        {...register('integrationProviderLoginButton')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Single Sign-On (SSO) Login
                                </label>
                                <p>
                                  Add Single Sign-On form to Login page, if not using 3rd party SSO
                                  page for Login with return URL.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="ssoLogin"
                                        {...register('ssoLogin')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Login URL
                                </label>
                                <p>
                                  Custom Login URL when using an the API or 3rd party Integration
                                  for Login page.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <FormInputField
                                  label={''}
                                  type={'text'}
                                  fieldName={'loginUrl'}
                                  register={register}
                                  errors={errors}
                                  isRequired={false}
                                />
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Logout URL
                                </label>
                                <p>Logout URL to logoff 3rd party integration website.</p>
                              </div>
                              <div className="md:w-8/12">
                                <FormInputField
                                  label={''}
                                  type={'text'}
                                  fieldName={'logoutUrl'}
                                  register={register}
                                  errors={''}
                                  isRequired={false}
                                />
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Login Button Text
                                </label>
                                <p>
                                  Label for the Login Button on main navigation. ie. Members Login
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <FormInputField
                                  label={''}
                                  type={'text'}
                                  fieldName={'loginButtonText'}
                                  register={register}
                                  errors={''}
                                  isRequired={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item bg-white shadow border-0 my-3">
                      <h2 className="accordion-header mb-0" id="headingThree">
                        <button
                          className="accordion-button
                          collapsed
                          accordion-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree">
                          Advanced Authentication Settings
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#videoAccordion">
                        <div className="accordion-body">
                          <div className="p-5">
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Enable Product Registration
                                </label>
                                <p>
                                  After first-time Registration or returned login, if a user has not
                                  previously purchased a Product, they will be sent directly to the
                                  Products page to complete a purchase before accessing the video
                                  channel. This is commonly used for Event Registration ticketing.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="enableProductRegistration"
                                        {...register('enableProductRegistration')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Hide Local Authentication on Main Navigation
                                </label>
                                <p>
                                  The Local Registration & Login buttons will be hidden from the
                                  main navigation.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="hideLocalAuthentication"
                                        {...register('hideLocalAuthentication')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Hide Local Registration Button on Main Navigation
                                </label>
                                <p>
                                  The Local Registration button will be hidden from the main
                                  navigation.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="hideLocalRegistration"
                                        {...register('hideLocalRegistration')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Hide Local Login Button on Main Navigation
                                </label>
                                <p>
                                  The Local Login button will be hidden from the main navigation.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="hideLocalButton"
                                        {...register('hideLocalButton')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Restricted Access List
                                </label>
                                <p>
                                  Only a specified list of users can login to your video channel.
                                  Your restricted access list can be set under Video Channel Access
                                  List
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="restrictedAccessList"
                                        {...register('restrictedAccessList')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Welcome Modal
                                </label>
                              </div>
                              <div className="md:w-8/12">
                                <FormInputField
                                  label={''}
                                  type={'text'}
                                  fieldName={'welcomeModal'}
                                  register={register}
                                  errors={''}
                                  isRequired={false}
                                />
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Display First Time Only
                                </label>
                              </div>
                              <div className="md:w-8/12">
                                <div className="flex items-center">
                                  <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                    <div className="relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="displayFirstTime"
                                        {...register('displayFirstTime')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="md:flex items-center mb-8">
                              <div className="md:w-4/12">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Welcome Modal Size
                                </label>
                              </div>
                              <div className="md:w-8/12">
                                <FormInputField
                                  label={''}
                                  type={'text'}
                                  fieldName={'welcomeModalSize'}
                                  register={register}
                                  errors={''}
                                  isRequired={false}
                                />
                              </div>
                            </div>

                            <div className="md:flex  mb-8">
                              <div className="md:w-4/12 md:pr-4">
                                <label
                                  className="form-check-label inline-block font-semibold switch-label mr-5"
                                  htmlFor="Credit">
                                  Welcome Modal Message
                                </label>
                                <p>
                                  Set custom message on login modal. Use %%LoginUrl%% or
                                  %%LoginBtn%% to add login link/button within the custom html.
                                </p>
                              </div>
                              <div className="md:w-8/12">
                                <Editor editorState={editorState} setEditorState={setEditorState} />
                              </div>
                            </div>
                            <div>
                              <h2 className="mb-5">New Member Messages</h2>
                              <div className="md:flex items-center mb-8">
                                <div className="md:w-4/12">
                                  <label
                                    className="form-check-label inline-block font-semibold switch-label mr-5"
                                    htmlFor="Credit">
                                    Terms of Use
                                  </label>
                                </div>
                                <div className="md:w-8/12">
                                  <div className="flex items-center">
                                    <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                                      <div className="relative">
                                        <input
                                          className="form-check-input switch-btn"
                                          type="checkbox"
                                          role="switch"
                                          id="termsOfUse"
                                          {...register('termsOfUse')}
                                        />
                                        <i className="fas fa-check checkbox-tick hidden"></i>
                                        <i className="fas fa-times checkbox-cross"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="md:flex  mb-8">
                                <div className="md:w-4/12">
                                  <label
                                    className="form-check-label inline-block font-semibold switch-label mr-5"
                                    htmlFor="Credit">
                                    Terms of Use Text
                                  </label>
                                </div>
                                <div className="md:w-8/12">
                                  <Editor
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 borderonly-btn">
                    Save
                  </button>
                </form>
              </div>
            </div>
            <RegistrationOptions />
            <RegistrationFields />
            <EmailConfirmation />
          </div>
        </div>
      </div>

      {/* Modal */}
      <DeleteOrganisationModal />
    </div>
  );
};

export default Registration;
