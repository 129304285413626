import React from 'react';
import { Link } from 'react-router-dom';
import AddEmailModal from './AddEmail/AddEmailModal';
import DeleteEmailModal from './DeleteEmail/DeleteEmailModal';

const RegistrationAceessList = () => {
  return (
    <div>
      <div className="mb-6">
        <h2 className="font-semibold my-1">Registration Access List</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">Video Library</Link>
          </li>
          <li>
            <strong>Access List</strong>
          </li>
        </ol>
      </div>

      <div>
        <div className="flex items-center justify-between mt-8 py-4">
          <h6 className="font-semibold mb-0">Access List</h6>
          <div>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#AddemailModal"
              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
              Add Email List
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="table-auto w-full border">
            <thead className="text-xs font-semibold uppercase bg-gray-100">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Actions</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              <tr>
                <td className="p-2">jdavis@webcastcloud.com.au</td>
                <td className="p-2">
                  <span
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteEmailModal">
                    <i className="fas fa-trash text-red-500"></i>
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-2">dean@webcastcloud.com.au</td>
                <td className="p-2">
                  <span
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#DeleteEmailModal">
                    <i className="fas fa-trash text-red-500"></i>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Modals  */}
      <AddEmailModal />
      <DeleteEmailModal />
    </div>
  );
};

export default RegistrationAceessList;
