import React, { useEffect, useState } from 'react';
import '../styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Modal from '../components/Modal';
import { useUserActions } from 'src/store/users/actions';
import MemberVideoAnalytics from '../components/MemberVideoAnalytics';
import UserActivityTimeline from '../components/UserActivityTimeline';
import CourseVideoAnalytics from '../components/CourseVideoAnalytics';
import MemberAnalytics from '../components/MemberAnalytics';
import MemberInvoices from '../components/MemberInvoices';
import Registrations from '../components/Registrations';
import Security from '../components/Security';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { RolesModel, SubscriptionProductModel } from 'src/models/usersModel';
import UploadProfileModal from '../components/UploadProfileModal';
import { useLocation, useParams } from 'react-router-dom';
import ToastMessage from 'src/components/ToastContainer';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
type locationState = {
  memberId: string;
};
const MemberDetails: React.FC = () => {
  const [videoLayout, setVideoLayout] = useState(true);
  const [modalFlag, setModalFlag] = useState('');
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const analyticsAction = useAnalyticsActions(dispatch);
  const location = useLocation();
  const { id } = useParams<string>();
  const { memberId } = (location.state as locationState) || {};
  const [memberListData, setMemberListData] = useState<any>([]);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionProductModel[]>([]);
  const [showAllSubscription, setShowAllSubscription] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const {
    memberInvoice,
    subscriptionProducts,
    memberListByMemberId,
    memberDetail,
    isMemberUpdated,
    memberCountData,
    isMemberProfileUpdated,
    error
  } = useSelector((state: RootState) => {
    return {
      memberVideoanalytics: state.users.memberVideoanalytics,
      memberInvoice: state.users.memberInvoice,
      subscriptionProducts: state.users.subscriptionProducts,
      memberListByMemberId: state.users.memberListByMemberId,
      userActivityTimeLine: state.users.userActivityTimeLine,
      memberDetail: state.users.memberDetail,
      isMemberUpdated: state.users.isMemberUpdated,
      memberCountData: state.users.memberCountData,
      isMemberProfileUpdated: state.users.isMemberProfileUpdated,
      error: state.users.error
    };
  });
  const handleEditClick = (id: string) => {
    setModalFlag('edit');
    userAction.getMemberDetailRequest(id);
  };

  useEffect(() => {
    if (id) {
      userAction.getMemberVideoAnalyticsRequest({ memberId: memberId, pageNumber: 1 });
      userAction.getMemberDetailRequest(id);
      userAction.getMemberInvoicesRequest({ id: memberId, pageNumber: 1, pageSize: 5 });
      userAction.getSubscriptionProductsRequest(memberId);
      userAction.getMemberListByMemberIdRequest(memberId);
      userAction.getUserActivityTimeLineRequest({
        memberId: memberId,
        pageNumber: 1,
        pageSize: 10
      });
      userAction.getMemberCountDataRequest(memberId);
    }
  }, [isMemberUpdated]);

  useEffect(() => {
    memberListByMemberId.map((item: any, index: number) => {
      if (index < 20) {
        setMemberListData((prev: any) => [...prev, item]);
      }
    });
    return () => {
      setMemberListData([]);
    };
  }, [memberListByMemberId]);

  useEffect(() => {
    if (subscriptionProducts && subscriptionProducts.length) {
      subscriptionProducts.map((item, index) => {
        if (index < 2) {
          setSubscriptionData((prev: SubscriptionProductModel[]) => [...prev, item]);
        }
      });
      return () => {
        setSubscriptionData([]);
      };
    }
  }, [subscriptionProducts]);

  const handleShowAll = () => {
    setMemberListData(showAll ? memberListByMemberId.slice(0, 20) : memberListByMemberId);
    setShowAll((val) => !val);
  };

  const handleShowAllSubscription = () => {
    setSubscriptionData(
      showAllSubscription ? subscriptionProducts.slice(0, 2) : subscriptionProducts
    );
    setShowAllSubscription((val) => !val);
  };

  const getRolesNames = (memberDetail: RolesModel[]) => {
    if (memberDetail) {
      const rolesName = memberDetail.map((item: any) => item.name);
      return rolesName.toString();
    }
  };

  useEffect(() => {
    if (isMemberProfileUpdated) {
      successMsg('Updated Successfully');
      if (id) {
        userAction.getMemberDetailRequest(id);
      }
      userAction.resetMemberRequest();
    }
  }, [isMemberProfileUpdated]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      userAction.resetMemberRequest();
    }
  }, [error]);

  const handleSecurityClick = () => {
    userAction.getLoginHistoryRequest({ id: memberId, pageNumber: 1 });
  };

  const handleAnalyticsClick = () => {
    const data = { pageNumber: 1, id: memberId };
    analyticsAction.getCourseAnalyticsRequest(data);
    const payloadData = { memberId, pageNo: 1, pageSize: 20 };
    analyticsAction.getAnalyticsMembersRequest(payloadData);
  };

  const handleRegistrationClick = () => {
    userAction.getMemberEventRegistrationRequest(memberId);
  };
  return (
    <>
      <ToastMessage />
      <div className="member mt-4">
        <div className="xl:flex justify-center">
          <div className="xl:w-1/4 xl:mr-5 mt-2">
            {memberDetail && (
              <div className="dashboard-card">
                <div className="text-center">
                  {/* <input type="file" onChange={(e) => uploadUserProfile(e.target.files)} /> */}
                  <div className="profile-name p-8 flex justify-center">
                    <div className="w-24 h-24 flex-shrink-0 mr-2 sm:mr-3 justify-center">
                      <img
                        className="rounded-full"
                        src={
                          memberDetail.profileURL
                            ? memberDetail.profileURL
                            : '../../assets/img/user-png.png'
                        }
                        style={{
                          height: '100%',
                          width: '100%'
                        }}
                      />
                    </div>
                  </div>
                  <div className={`form-group mb-4`}>
                    <div className="flex justify-center">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#uploadMemberModal"
                        type="button"
                        className="mx-0 px-6 btn-primary w-28 mt-0 mr-2">
                        Browse
                      </button>
                    </div>
                  </div>
                  <p className="font-semibold mb-2">
                    {memberDetail.firstName} {memberDetail.lastName}
                  </p>
                  <p className="font-semibold mb-2">{memberDetail.userName}</p>
                  {memberDetail?.roles?.map((value) => (
                    <span
                      className="theme-light-clr py-1 px-2 site-clr font-semibold rounded-sm m-1"
                      key={value.id}>
                      <small>
                        <>{value.name}</>
                      </small>
                    </span>
                  ))}
                </div>

                <div className="flex-items px-4 mt-6">
                  <div className="flex leading-tight items-center">
                    <span className="theme-light-clr h-7 w-7 flex items-center justify-center px-1 py-2 mr-3 rounded-md">
                      <img src="../../assets/img/tick-mark.svg" className="w-4 h-4" />
                    </span>
                    <div className="leading-tight">
                      <h4 className="mb-0 font-semibold text-lg">
                        {memberCountData && memberCountData.totalSales}
                      </h4>
                      <small>Total Sales</small>
                    </div>
                  </div>
                  <div className="flex leading-tight items-center">
                    <span className="theme-light-clr h-7 w-7 flex items-center justify-center px-1 py-2 mr-3 rounded-md">
                      <img src="../../assets/img/project-done.svg" className="w-4 h-4" />
                    </span>
                    <div className="leading-tight">
                      <h4 className="mb-0 font-semibold text-lg">
                        {memberCountData && memberCountData.totalViews}
                      </h4>
                      <small>Total Views</small>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <h2 className="font-semibold border-b">Details</h2>
                  <ul>
                    <li className="my-3">
                      <label className="font-semibold">Security Provider: </label>{' '}
                      {memberDetail.sourceName}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Username: </label> {memberDetail.userName}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Email: </label> {memberDetail.email}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Suffix: </label> {memberDetail.suffix}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Status: </label>{' '}
                      <span className="text-green-500 bg-green-100 px-2 py-1 rounded-sm font-semibold">
                        {memberDetail.active === true ? 'Active' : 'Inactive'}
                      </span>
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Role: </label>
                      <>{getRolesNames(memberDetail.roles)}</>
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Phone: </label> {memberDetail.phoneNumber}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Customer ID: </label>{' '}
                      {memberDetail?.customerId}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Company: </label> {memberDetail.company}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Company ID: </label> {memberDetail.companyId}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Registration ID: </label>{' '}
                      {memberDetail.registrationId}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Job Title: </label> {memberDetail.jobTitle}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Biography: </label>
                      {memberDetail?.biography?.length && (
                        <>
                          {showFullText
                            ? `${memberDetail?.biography} `
                            : `${memberDetail?.biography?.slice(0, 60)} `}
                        </>
                      )}

                      {memberDetail?.biography?.length > 60 && (
                        <>
                          {!showFullText ? (
                            <span className="font-bold cursor-pointer" onClick={toggleText}>
                              Show more
                            </span>
                          ) : (
                            <span className="font-bold cursor-pointer" onClick={toggleText}>
                              Show less
                            </span>
                          )}
                        </>
                      )}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Credit Prefrence: </label>
                      {memberDetail.cePreference ? 'True' : 'False'}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Integration UID: </label>{' '}
                      {memberDetail.integrationUId}
                    </li>
                    <li className="my-3">
                      <label className="font-semibold">Credit Prefrence Last Updated: </label>
                      {memberDetail.cePreferenceUpdatedDate
                        ? defaultDateFormatter(memberDetail.cePreferenceUpdatedDate)
                        : ''}
                    </li>
                    {/* <li className="my-3">
                    <label className="font-semibold">Country: </label>
                    {memberDetail.Country}
                  </li> */}
                  </ul>
                  <div className="flex justify-center pb-6">
                    {memberDetail.active === true && (
                      <button
                        type="button"
                        onClick={() => handleEditClick(memberDetail.customerId)}
                        className="btn-primary w-auto ml-0 mr-3 px-5"
                        data-bs-toggle="modal"
                        data-bs-target="#editMemberModal">
                        Edit
                      </button>
                    )}
                    {memberDetail.active !== true && (
                      <button type="button" className="btn-primary w-auto mx-0 suspend-btn">
                        Suspended
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="xl:block lg:flex xl:gap-0 lg:gap-4">
              <div className="dashboard-card my-5 xl:w-auto lg:w-1/2 member-list">
                <div className="flex-items">
                  <span className="theme-light-clr py-1 px-2 site-clr font-semibold rounded-sm">
                    Members Lists
                  </span>
                  {memberListByMemberId && memberListByMemberId.length > 20 && (
                    <span className="cursor-pointer" onClick={() => handleShowAll()}>
                      {showAll ? 'Show Less' : 'Show All'}
                    </span>
                  )}
                </div>
                <ul className="list-disc pl-4 mt-4">
                  {memberListData &&
                    memberListData.map((item: any) => {
                      return <li key={item.id}>{item.name}</li>;
                    })}
                </ul>
              </div>
              <div className="dashboard-card my-5 xl:w-auto lg:w-1/2 member-list">
                <div className="flex-items">
                  <span className="theme-light-clr py-1 px-2 site-clr font-semibold rounded-sm">
                    Subscription Products
                  </span>
                  {subscriptionProducts && subscriptionProducts.length > 2 && (
                    <span className="cursor-pointer" onClick={() => handleShowAllSubscription()}>
                      {showAllSubscription ? 'Show Less' : 'Show All'}
                    </span>
                  )}
                </div>
                <ul className="list-disc pl-4 mt-4">
                  {subscriptionData &&
                    subscriptionData.map((item) => {
                      return (
                        <div key={item.id}>
                          <li className="font-semibold">{item.subscriptionName}</li>
                          <span className="text-xs">
                            {item.amount} {item.currency}/month
                          </span>
                        </div>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>

          <div className="xl:w-3/4">
            <div className={`${videoLayout ? 'hide-lists' : 'show-lists'}`}>
              <div className="mobile-dropdown" onClick={() => setVideoLayout(!videoLayout)}>
                <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
                  <p>Open Tabs</p>
                  <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
                </div>
              </div>
              <ul
                className="nav nav-tabs flex md:flex-row overflow-x-auto list-none border-b-0 pl-0 mb-4 mobile-layout-ul"
                id="tabs-tab"
                role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    href="#tab-accounts"
                    className="
                nav-link
                tabs-link
                active
    "
                    id="tab-accounts-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-accounts"
                    role="tab"
                    aria-controls="tab-accounts"
                    aria-selected="true">
                    <span className="flex items-center">
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-4 h-4 mr-1">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>{' '}
                      <span>Account</span>
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation" onClick={() => handleSecurityClick()}>
                  <a
                    href="#tabs-security"
                    className="
                nav-link
                tabs-link
               "
                    id="tabs-security-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-security"
                    role="tab"
                    aria-controls="tabs-security"
                    aria-selected="false">
                    <span className="flex items-center">
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 w-4 h-4">
                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                      </svg>{' '}
                      <span>Security</span>
                    </span>
                  </a>
                </li>
                <li className="nav-item" role="presentation" onClick={() => handleAnalyticsClick()}>
                  <a
                    href="#tab-analytics"
                    className="
                nav-link
                tabs-link
               "
                    id="tab-analytics-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tab-analytics"
                    role="tab"
                    aria-controls="tab-analytics"
                    aria-selected="false">
                    <span className="flex items-center">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        className="w-4 h-4 mr-1"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_759_82074"
                          maskUnits="userSpaceOnUse"
                          x="2"
                          y="0"
                          width="14"
                          height="18">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.475 1.725C14.7 1.5 15 1.425 15.3 1.575C15.6 1.725 15.75 1.95 15.75 2.25V11.25C15.75 11.475 15.675 11.625 15.525 11.775C15.375 11.925 14.4 12.75 12 12.75C10.8253 12.75 9.84157 12.368 8.93154 12.0146C8.85385 11.9845 8.77669 11.9545 8.7 11.925C8.52739 11.8675 8.35754 11.8072 8.18885 11.7473C7.47803 11.495 6.788 11.25 6 11.25C4.8 11.25 4.05 11.475 3.75 11.625V16.5C3.75 16.95 3.45 17.25 3 17.25C2.55 17.25 2.25 16.95 2.25 16.5V11.25V2.25C2.25 2.025 2.325 1.875 2.475 1.725C2.625 1.575 3.6 0.75 6 0.75C7.275 0.75 8.325 1.125 9.3 1.575C9.47261 1.63254 9.64246 1.69283 9.81114 1.75271C10.522 2.00505 11.212 2.25 12 2.25C13.8 2.25 14.475 1.725 14.475 1.725ZM12 11.25C13.2 11.25 13.95 11.025 14.25 10.875V3.45C13.725 3.6 12.975 3.75 12 3.75C10.725 3.75 9.675 3.375 8.7 2.925C8.52739 2.86746 8.35754 2.80717 8.18886 2.74729C7.47803 2.49495 6.788 2.25 6 2.25C4.8 2.25 4.05 2.475 3.75 2.625V10.05C4.275 9.9 5.025 9.75 6 9.75C7.275 9.75 8.325 10.125 9.3 10.575C9.47261 10.6325 9.64247 10.6928 9.81115 10.7527C10.522 11.005 11.212 11.25 12 11.25Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_759_82074)">
                          <rect width="18" height="18" fill="currentcolor" />
                        </g>
                      </svg>

                      <span>Analytics</span>
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => handleRegistrationClick()}>
                  <a
                    href="#tabs-notification"
                    className="
                nav-link
                tabs-link
                "
                    id="tabs-notification-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-notification"
                    role="tab"
                    aria-controls="tabs-notification"
                    aria-selected="false">
                    <span className="flex items-center">
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 w-4 h-4">
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                      </svg>{' '}
                      <span>Registrations</span>
                    </span>
                  </a>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <a
                    href="#tabs-connection"
                    className="
                nav-link
                tabs-link
              "
                    id="tabs-connection-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-connection"
                    role="tab"
                    aria-controls="tabs-connection"
                    aria-selected="false">
                    <span className="flex items-center">
                      {' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 w-4 h-4">
                        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                      </svg>{' '}
                      <span>Connection</span>
                    </span>
                  </a>
                </li> */}
              </ul>
              <div className="tab-content" id="tabs-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="tab-accounts"
                  role="tabpanel"
                  aria-labelledby="tab-accounts-tab">
                  <MemberVideoAnalytics memberId={memberId} />
                  <MemberInvoices memberId={memberId} />
                  <UserActivityTimeline memberId={memberId} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tabs-security"
                  role="tabpanel"
                  aria-labelledby="tabs-security-tab">
                  <Security memberId={memberId} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tab-analytics"
                  role="tabpanel"
                  aria-labelledby="tabs-security-tab">
                  <MemberAnalytics memberId={memberId} />
                  <CourseVideoAnalytics memberId={memberId} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tabs-security"
                  role="tabpanel"
                  aria-labelledby="tabs-security-tab">
                  Tab 4 content
                </div>

                <div
                  className="tab-pane fade"
                  id="tabs-notification"
                  role="tabpanel"
                  aria-labelledby="tabs-notification-tab">
                  <Registrations memberId={memberId} />
                </div>
                <div
                  className="tab-pane fade"
                  id="tabs-connection"
                  role="tabpanel"
                  aria-labelledby="tabs-connection-tab">
                  Tab 5 content
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        <Modal memberDetails={memberDetail} modalFlag="edit" setModalFlag={() => 'edit'} />
        <UploadProfileModal memberId={id} />
      </div>
    </>
  );
};

export default MemberDetails;
