import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormInputField from 'src/components/common/formInputField';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
import Select from 'react-select';
import { SelectModel } from 'src/models/videosModel';
import { AddNavigationModel } from 'src/models/appearanceModel';
interface IProps {
  navigationAddFlag?: AddNavigationModel;
}

const defaultProps: IProps = {
  navigationAddFlag: {
    saveFlag: "",
    navigationId: ""
  }
}

const AddNavigationMenu: React.FC<IProps> = ({ navigationAddFlag }) => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const [selectedMethod, setSelectedMethod] = useState<string>()
  const [finalChannelList, setFinalChannelList] = useState<SelectModel[]>();
  const [selectedChannel, setSelectedChannel] = useState<string>('');
  const [linkTarget, setselectedLinkTarget] = useState<string>('');
  const [methods, setMethods] = useState([{ id: 0, name: "Link" }, { id: 1, name: "Channel" }, { id: 2, name: "Category" }])
  const btnRef = useRef<HTMLButtonElement>(null);


  const { channels, categoriesList } = useSelector((state: RootState) => {
    return {
      channels: state.channel.channels,
      categoriesList: state.category.categoriesList
    };
  });

  const { isNavigationAdded, isNavigationUpdated, singleNavigations } = useSelector((state: RootState) => {
    return {
      isNavigationAdded: state.appearance.isNavigationAdded,
      singleNavigations: state.appearance.singleNavigations,
      isNavigationUpdated: state.appearance.isNavigationUpdated
    };
  });


  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    if (channels && channels.length) {
      const options = channels.map((item: any) => ({
        value: item.id,
        label: item.title
      }));
      const finalChannelOptions = [{ value: '', label: 'Select Channel' }, ...options];
      setFinalChannelList(finalChannelOptions);
    }
  }, [channels]);

  useEffect(() => {
    if (methods) {
      setSelectedMethod(methods[0].id.toString());
    }
  }, [])

  useEffect(() => {
    if (isNavigationAdded || isNavigationUpdated) {
      reset({
        module: ''
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
      appearanceAction.resetAppearanceStateRequest();
      appearanceAction.getNavigationRequest();
    }
  }, [isNavigationAdded, isNavigationUpdated]);

  useEffect(() => {
    if (singleNavigations) {
      reset(singleNavigations);
      setSelectedChannel(singleNavigations.selectedChannel || '');
      setSelectedMethod(singleNavigations.method?.toString() || '');
      setselectedLinkTarget(singleNavigations.linkTarget?.toString() || '');
    }
  }, [singleNavigations])



  const onSubmit = (data: any) => {
    if (navigationAddFlag?.saveFlag === 'Add') {
      let payload;
      if (data.selectedChannel) {
        payload = { ...data, selectedChannel: selectedChannel }
      } else {
        payload = { ...data }
      }
      appearanceAction.saveNavigationRequest(payload);
    } else {
      const payloadData = {
        navigationId: data.id,
        updateData: [
          { op: 'replace', path: 'linkName', value: watch('linkName') },
          { op: 'replace', path: 'linkURL', value: watch('linkURL') },
          { op: 'replace', path: 'linkTarget', value: watch('linkTarget') },
          { op: 'replace', path: 'method', value: watch('method') },
          {
            op: 'replace',
            path: 'childrenCategories',
            value: watch('childrenCategories')
          },
          { op: 'replace', path: 'addChildren', value: watch('addChildren') },
          { op: 'replace', path: 'autheticationRequired', value: watch('autheticationRequired') },
          { op: 'replace', path: 'selectedCategory', value: watch('selectedCategory') },
          { op: 'replace', path: 'selectedChannel', value: watch('selectedChannel') }
        ]
      };
      appearanceAction.updateNavigationRequest(payloadData);
    }
  };

  const handleChangeMethod = (methodValue: any) => {
    setSelectedMethod(methodValue.target.value)
  }

  const handleCloseClick = () => {
    reset({
      linkName: "",
      method: "0",
      linkURL: "",
      selectedChannel: "",
      selectedCategory: "",
      linkTarget: "_Self",
      autheticationRequired: "",
      addChildren: false,
      childrenCategories: ""
    });
    setSelectedMethod("0");
  };

  return (
    <div>
      <div
        className="modal fade"
        id="AddnavigationModal"
        aria-labelledby="AddnavigationModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddnavigationModalLabel">
                {navigationAddFlag?.saveFlag === 'Add' ? "Add" : "Edit"} Navigation Link
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCloseClick()}
                ref={btnRef}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body relative py-4 px-10">

                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Link Name'}
                    type={'text'}
                    fieldName={'linkName'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <label className="font-semobold mb-1">Method</label>
                  <select className="input-style"
                    {...register('method')}
                    value={selectedMethod}
                    onChange={(e) => handleChangeMethod(e)}>
                    {methods.map((method, idx) => (
                      <option value={method.id} key={idx}>{method.name}</option>
                    ))}
                  </select>
                </div>
                {
                  selectedMethod === "0" ? (
                    <div className={`form-group mb-3`}>
                      <FormInputField
                        label={'Link URL'}
                        type={'text'}
                        fieldName={'linkURL'}
                        register={register}
                        errors={errors}
                        isRequired={true}
                      />
                    </div>
                  ) : selectedMethod === "1" ? (
                    <div className={`form-group mb-3`}>
                      <label className="font-semobold mb-1">Selected Channel</label>
                      <select className="input-style"
                        {...register('selectedChannel')}
                        value={selectedChannel}
                        onChange={(e) => setSelectedChannel(e.target.value)}>
                        {finalChannelList?.map((method, idx) => (
                          <option value={method.value} key={idx}>{method.label}</option>
                        ))}
                      </select>
                    </div>
                  ) : selectedMethod === "2" ? (
                    <div className={`form-group mb-3`}>
                      <label className="font-semobold mb-1">Selected Category</label>
                      {categoriesList && categoriesList?.categories.length && (
                        <select className="input-style" {...register('selectedCategory')} >
                          {categoriesList.categories.map((item, idx) => (
                            <option value={item.id} key={idx}>{item.category}</option>
                          ))}
                        </select>
                      )}
                    </div>
                  ) : ""
                }
                <div className={`form-group mb-3`}>
                  <label className="font-semobold mb-1">Link Target</label>
                  <select className="input-style" {...register('linkTarget')} value={linkTarget} onChange={(e) => setselectedLinkTarget(e.target.value)}>
                    <option value={"_Self"}>_self</option>
                    <option value={"_Blank"}>_blank</option>
                  </select>
                </div>
                <div className={`form-group mb-3`}>
                  <div className="form-check flex items-center justify-start">
                    <input
                      className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                      type="checkbox"
                      {...register('autheticationRequired')}
                    />
                    <label className="ml-1">Authetication Required</label>
                  </div>
                </div>
                <div className={`form-group mb-3`}>
                  <div className="form-check flex items-center justify-start">
                    <input
                      className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                      type="checkbox"
                      {...register('addChildren')}
                    />
                    <label className="ml-1">Add Children</label>
                  </div>
                </div>
                {watch("addChildren") ? (
                  <div className={`form-group mb-3`}>
                    <label className="font-semobold mb-1">Selected Category</label>
                    {categoriesList && categoriesList?.categories.length && (
                      <select className="input-style" {...register('childrenCategories')} >
                        {categoriesList.categories.map((item, idx) => (
                          <option value={item.id} key={idx}>{item.category}</option>
                        ))}
                      </select>
                    )}
                  </div>
                ) : ""}

              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                <button
                  type="submit"
                  className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                  {navigationAddFlag?.saveFlag === 'Add' ? "Add" : "Save Changes"}
                </button>
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal"
                  onClick={() => handleCloseClick()}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  );
};
AddNavigationMenu.defaultProps = defaultProps;

export default AddNavigationMenu;
