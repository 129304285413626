import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const LivePayProvider: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isPaymentUpdated, livePay, message, error } = useSelector((state: RootState) => {
    return {
      isPaymentUpdated: state.setting.isPaymentUpdated,
      livePay: state.setting.livePay,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  const [showClientSecret, setShowClientSecret] = useState(false);

  useEffect(() => {
    if (livePay) {
      reset(livePay);
    }
  }, [livePay]);

  useEffect(() => {
    settingAction.getLivePayProviderRequest();
  }, []);

  useEffect(() => {
    if (isPaymentUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isPaymentUpdated]);

  const onSubmit = (data: any) => {
    if (livePay == undefined || livePay.livePayId == undefined) {
      settingAction.addLivePayProviderRequest(data);
    } else {
      const payloadData = {
        name: 'LivePay',
        updateData: [
          { op: 'replace', path: 'testing', value: data.testing },
          // { op: 'replace', path: 'showCurrencySelect', value: data.showCurrencySelect },
          { op: 'replace', path: 'livePayId', value: data.livePayId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'facilitatorPercent', value: data.facilitatorPercent },
          { op: 'replace', path: 'referencePrefix', value: data.referencePrefix },
          { op: 'replace', path: 'notes', value: data.notes }
        ]
      };
      settingAction.updatePaymentRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payments</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/paymentProvider">Payments</Link>
          </li>
          <li>
            <strong>Livepay Configuration</strong>
          </li>
        </ol>
        <Link to={`/paymentProvider`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">Livepay Configuration</h4>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Testing</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="testing"
              {...register('testing')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Show Currency Selector</label>
          </div>
          <div className="md:w-8/12">
            <input
              type="checkbox"
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              id="showCurrencySelect"
              {...register('showCurrencySelect')}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">LivePay ID</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'livePayId'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Client Secret</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showClientSecret ? 'text' : 'password'}
              fieldName={'clientSecret'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showClientSecret ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowClientSecret(!showClientSecret)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Facilitator Percent</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'facilitatorPercent'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Reference Prefix</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'referencePrefix'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Notes</label>
          </div>
          <div className="md:w-8/12">
            <textarea className="input-style" {...register('notes')} />
          </div>
        </div>
        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default LivePayProvider;
