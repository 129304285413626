import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import 'rc-time-picker/assets/index.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';

const MultipleChoiceResultModal = () => {
  const { register, reset } = useForm();

  const { pollsResult, singleVideo } = useSelector((state: RootState) => {
    return {
      pollsResult: state.videos.pollsResult,
      singleVideo: state.videos.singleVideo
    };
  });

  useEffect(() => {
    if (pollsResult) {
      const resetData = {
        displayDuration: pollsResult.displayDuration,
        videoDuration: singleVideo.duration
      };
      reset(resetData);
    }
  }, [pollsResult]);
  return (
    <div>
      <div
        className="modal fade"
        id="MultipleChoiceResultModal"
        aria-labelledby="MultipleChoiceResultModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="MultipleChoiceResultModalLabel">
                Multiple Choice
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form className="px-6">
              <div className="modal-body relative py-4 border-b">
                <select
                  disabled
                  {...register('pollType')}
                  className="input-style w-full mb-5 quiz-disable">
                  <option value={1}>Multiple Choice</option>
                </select>

                <div className={`form-group mb-3 mr-3 w-1/2`}>
                  <label className="block text-sm font-medium mb-1">Video Duration</label>
                  <input
                    disabled
                    type="text"
                    className="input-style quiz-disable"
                    id="videoDuration"
                    {...register('videoDuration')}
                  />
                </div>

                <div className={`form-group mb-3 w-1/2 mr-3`}>
                  <label className="block text-sm font-medium mb-1">
                    Poll Display Time (hh:mm:ss)
                  </label>
                  <input
                    disabled
                    type="text"
                    className="input-style quiz-disable"
                    id="displayDuration"
                    {...register('displayDuration')}
                  />
                </div>

                <div className="mt-3 px-0">
                  <div className="border rounded-md">
                    <div className="flex items-center justify-between border-b px-5 py-4">
                      <p className="font-semibold mb-0">Questions</p>
                    </div>

                    <div id="QuestionResultDiv" className="px-4 py-4">
                      {pollsResult &&
                        pollsResult?.quizzes?.questions?.map((item: any) => {
                          return (
                            <div className="mt-4" key={item.id}>
                              <div className="mt-1">
                                <div>
                                  <div className="flex justify-between">
                                    <div>
                                      <h5 className="font-bold">
                                        {item.position}. {item.questionText}
                                      </h5>
                                    </div>
                                    <div className="badge-pollAttended flex justify-center">
                                      {pollsResult?.quizzes?.pollAttended}
                                    </div>
                                  </div>
                                  {item.choices &&
                                    item.choices.map((choice: any) => {
                                      return (
                                        <div key={choice.id}>
                                          <div
                                            className={`px-2 py-3 ${
                                              choice.isAnswer ? 'pollResult-background' : ''
                                            }`}>
                                            <span className="">
                                              {choice.answerText} &nbsp;&nbsp;(
                                              {choice.votedPercentage}%, {choice.votedCount} Votes)
                                            </span>
                                            <div
                                              className={`w-full h-3 rounded-full mt-4 bg-gray-100`}>
                                              <div
                                                className={` bg-green-400 text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                                                style={{
                                                  width: `${choice.votedPercentage}%`
                                                }}></div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div className="form-check flex items-center justify-start">
                                  <input
                                    disabled
                                    checked={item.hasMultipleAnswers}
                                    className="form-check-input check-box-input focus:shadow-none transition duration-200 bg-no-repeat bg-center bg-contain float-left checked:bg-blue-600 checked:border-blue-600"
                                    type="checkbox"
                                  />
                                  <label className="ml-1">Allow to select Multiple Answer</label>
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleChoiceResultModal;
