import React, { useState, useRef, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { CategoryModel } from 'src/models/categoryModel';
import { AddCategoryModel } from 'src/models/categoryModel';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import { errorMsg } from 'src/components/utilities/utils';
import { successMsg } from 'src/components/utilities/utils';
import '../../../../../../node_modules/lc-color-picker/lc_color_picker.min.js';
import Select from 'react-select';
import { customFilterOption } from 'src/components/utilities/utils';
interface IProps {
  categoryAddFlag: AddCategoryModel;
}
const AddNewCategory: React.FC<IProps> = ({ categoryAddFlag }) => {
  const dispatch = useDispatch();
  const BackgroundInputFile = useRef<HTMLInputElement>(null);
  const categoryActions = useCategoryActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [isUploadToS3Loading, setIsUploadToS3Loading] = useState<boolean>();
  const [isUploadToS3Success, setIsUploadToS3Success] = useState<string>('');
  const [finalCategory, setFinalCategory] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch
  } = useForm();
  const { isCategorySavedLoading, isCategorySaved, singleCategory, isCategoryUpdated, category } =
    useSelector((state: RootState) => {
      return {
        isCategorySavedLoading: state.category.isCategorySavedLoading,
        isCategorySaved: state.category.isCategorySaved,
        singleCategory: state.category.singleCategory,
        isCategoryUpdated: state.category.isCategoryUpdated,
        category: state.category.allCategory
      };
    });
  const onSubmit = (data: any) => {
    if (categoryAddFlag?.saveFlag === 'Add') {
      const finalPayload = {
        ...data,
        parentCategoryId: data.parentCategoryId?.value,
        background: (data.background != null ? localStorage.getItem('backgroundColor'): null),
        //parentCategoryName: data.parentCategoryId.label
      };
      categoryActions.saveCategoryRequest(finalPayload);
    } else {
      const payloadData = {
        categoryId: data.id,
        updateData: [
          { op: 'replace', path: 'category', value: data.category },
          { op: 'replace', path: 'description', value: data.description },
          { op: 'replace', path: 'background', value: localStorage.getItem('backgroundColor') },
          { op: 'replace', path: 'backgroundAnimation', value: data.backgroundAnimation },
          { op: 'replace', path: 'fontColor', value: data.fontColor },
          {
            op: 'replace',
            path: 'displayAsSpotlightCategory',
            value: data.displayAsSpotlightCategory
          },
          { op: 'replace', path: 'parentCategoryId', value: data.parentCategoryId.value }
        ]
      };
      categoryActions.updateCategoryRequest(payloadData);
    }
  };
  useEffect(() => {
    if (isCategorySaved || isCategoryUpdated) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isCategorySaved, isCategoryUpdated]);

  useEffect(() => {
    if (category && category.length) {
      if (categoryAddFlag?.saveFlag !== 'Add') {
        const filteredCategory = category.filter((category) => category.id !== singleCategory.id);
        const filteredOptions = filteredCategory.map((item) => ({
          value: item.id,
          label: item.category
        }));

        setFinalCategory([
          {
            value: '',
            label: 'Select Category'
          },
          ...filteredOptions
        ]);
      } else {
        const options = category.map((item) => ({
          value: item.id,
          label: item.category
        }));
        setFinalCategory([
          {
            value: '',
            label: 'Select Category'
          },
          ...options
        ]);
      }
    }
  }, [category, singleCategory]);

  useEffect(() => {
    if (singleCategory && category && category.length) {
      if (categoryAddFlag?.saveFlag !== 'Add') {
        const selectedCategory =
          category && category.filter((item) => item.id === singleCategory.parentCategoryId);
        let dataRef;
        if (selectedCategory) {
          dataRef = {
            value: selectedCategory[0] ? selectedCategory[0].id : '',
            label: selectedCategory[0] ? selectedCategory[0].category : ''
          };
        }
        reset({ ...singleCategory, parentCategoryId: dataRef });
        setBackgroundImageUrl(singleCategory.thumbnailImageUrl);
        localStorage.setItem('backgroundColor', singleCategory.background);
      }
    }
  }, [singleCategory, category]);
  const handleCloseClick = () => {
    reset({
      category: '',
      description: '',
      displayAsSpotlightCategory: false,
      fontColor: '',
      background: '',
      backgroundAnimation: false
    });
    setIsUploadToS3Success('');
    localStorage.setItem('backgroundColor', '#FFFFFF');
    setValue('backgroundColor', '#FFFFFF');
  };
  const handleSelectBackgroundImageClick = () => {
    if (BackgroundInputFile.current) {
      BackgroundInputFile.current.click();
    }
  };
  const handleBackgroundImageUpload = async (selectedFile: any) => {
    setIsUploadToS3Success('');
    if (selectedFile) {
      uploadImageToS3(
        selectedFile[0],
        `v2/categories/upload/thumbnail/${categoryAddFlag.categoryId}?`,
        setBackgroundImageUrl,
        'setting',
        setIsUploadToS3Loading,
        setIsUploadToS3Success
      );
    }
  };
  const onInputClick = (event: any) => {
    event.target.value = '';
  };
  const handleRemoveBackgroundImage = () => {
    const cId = singleCategory && singleCategory.id;
    const payloadData = {
      categoryId: cId,
      updateData: [{ op: 'replace', path: 'thumbnailImageUrl', value: '' }]
    };
    categoryActions.updateCategoryRequest(payloadData);
  };
  useEffect(() => {
    categoryActions.getAllCategoryRequest({ pageNumber: 1 });
  }, []);
  useEffect(() => {
    if (isUploadToS3Success === 'fail') {
      errorMsg('File has not been uploaded successfully');
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);

  useEffect(() => {
    if (isUploadToS3Success === 'success') {
      successMsg('File uploaded successfully');
      categoryActions.getSingleCategoryRequest(singleCategory.id);
      setIsUploadToS3Success('');
    }
  }, [isUploadToS3Success]);
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `new lc_color_picker('#background',{on_change :${function (
      new_value: string
    ) {
      localStorage.setItem('backgroundColor', new_value);
    }}})`;
    document.body.appendChild(script);
  }, []);
  return (
    <div>
      <div className="channel-settings">
        <div
          className="modal fade"
          id="AddNewCategory"
          aria-labelledby="SettingsModalTitle"
          aria-modal="true"
          role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header flex-items px-5 pt-5">
                <h5 className="font-bold">
                  {categoryAddFlag.saveFlag === 'Add' ? 'Add' : 'Update'} Category
                </h5>
                <button
                  onClick={handleCloseClick}
                  ref={ref}
                  type="button"
                  className="btn-close ml-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"></button>
              </div>
              <div className="modal-body bg-white relative p-5">
                <div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={`form-group mb-3`}>
                      <FormInputField
                        label={'Name'}
                        type={'text'}
                        fieldName={'category'}
                        register={register}
                        errors={errors}
                        isRequired={true}
                      />
                    </div>
                    <div className={`form-group mb-3`}>
                      <label className="block mb-1"> Parent Category </label>
                      {finalCategory && Object.keys(finalCategory).length && (
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error }
                          }) => (
                            <Select
                              filterOption={customFilterOption}
                              options={finalCategory}
                              value={value}
                              id="parentCategoryId"
                              onChange={(val) => onChange(val)}
                            />
                          )}
                          name="parentCategoryId"
                          control={control}
                        />
                      )}
                    </div>

                    <div className={`form-group mb-3`}>
                      <FormInputField
                        label={'Description'}
                        type={'text'}
                        fieldName={'description'}
                        register={register}
                        errors={errors}
                        isRequired={false}
                      />
                    </div>

                    <div className="form-group mb-3 flex-items">
                      <div>
                        <label
                          className="form-check-label inline-block switch-label mr-5"
                          htmlFor="Credit">
                          Highlight Category
                        </label>
                      </div>
                      <div>
                        <div className="flex items-center">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="displayAsSpotlightCategory"
                                {...register('displayAsSpotlightCategory')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`form-group mb-3 flex items-center`}>
                      <div className="md:w-1/3">
                        <label>Font Color</label>
                      </div>
                      <div className="md:w-2/3 text-right">
                        <input
                          placeholder="#000000"
                          type="color"
                          id="fontColor"
                          {...register('fontColor')}
                        />
                      </div>
                    </div>

                    <div className={`form-group mb-3 flex items-center`}>
                      <div className="md:w-1/3">
                        <label>Background Color</label>
                      </div>
                      <div className="md:w-2/3 text-right">
                        <input type="text" id="background" {...register('background')} />
                      </div>
                    </div>

                    <div className="form-group mb-3 flex-items">
                      <div>
                        <label
                          className="form-check-label inline-block switch-label mr-5"
                          htmlFor="Credit">
                          Background Animation
                        </label>
                      </div>
                      <div>
                        <div className="flex items-center">
                          <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                            <div className="relative">
                              <input
                                className="form-check-input switch-btn"
                                type="checkbox"
                                role="switch"
                                id="backgroundAnimation"
                                {...register('backgroundAnimation')}
                              />
                              <i className="fas fa-check checkbox-tick hidden"></i>
                              <i className="fas fa-times checkbox-cross"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {categoryAddFlag.saveFlag !== 'Add' && (
                      <div className="flex justify-between mb-4 thumbnail-main">
                        <label className="mb-1 mr-5">Background Image</label>

                        <div className="relative channel-setting-image flex justify-center w-9/12">
                          {singleCategory && singleCategory.thumbnailImageUrl ? (
                            <img
                              className="w-full"
                              src={`${singleCategory && singleCategory.thumbnailImageUrl}`}
                            />
                          ) : (
                            <div className="w-full h-36 border-2"></div>
                          )}

                          <div className="flex flex-wrap justify-center items-center rounded-b-md absolute inset-0 btns-main">
                            <div className="flex flex-col items-center">
                              <div className="flex">
                                <div
                                  className={`video-upload-btn mr-2 ${
                                    singleCategory.thumbnailImageUrl ? '' : 'hidden'
                                  }`}>
                                  <input
                                    ref={BackgroundInputFile}
                                    type="file"
                                    onChange={(e) => handleBackgroundImageUpload(e.target.files)}
                                    className="hidden"
                                    onClick={onInputClick}
                                  />
                                  <label
                                    onClick={() => {
                                      setBackgroundImageUrl(''), handleRemoveBackgroundImage();
                                    }}
                                    className={`btn-primary cursor-pointer mx-0 mt-0 `}>
                                    Remove
                                  </label>
                                </div>

                                <button
                                  type="button"
                                  onClick={handleSelectBackgroundImageClick}
                                  className="mx-0 px-6 btn-primary w-28 mt-0">
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                      <button
                        type="submit"
                        className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                        Save Changes
                      </button>
                      <button
                        onClick={() => handleCloseClick()}
                        type="button"
                        className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                        data-bs-dismiss="modal">
                        Close
                      </button>
                      {(isCategorySavedLoading || isUploadToS3Loading) && <Loader />}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCategory;
