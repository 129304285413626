import { all, put, takeEvery } from 'redux-saga/effects';
import { EventActions } from './actions';
import { AxiosResponse } from 'axios';
import { URLS } from 'src/components/utilities/endpoints';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'

function* asyncSaveEvent(data: EventActions.saveEventRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_EVENTS.url + `/${data.payload}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.saveEventRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.saveEventRequestFailure());
    }
}
function* asyncUpdateEvent(data: EventActions.updateEventRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_EVENT.url}/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.status === 200) {
            yield put(EventActions.updateEventRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateEventRequestFailure());
    }
}
function* asyncUpdateEventLayout(data: EventActions.updateEventLayoutRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_EVENTS.url + `/${data.payload?.eventId}/layout/${data.payload?.id}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateEventLayoutRequestSuccess(response.data));
        }
    } catch (error) {
        yield put(EventActions.updateEventLayoutRequestFailure());
    }
}
function* asyncGetEvent(data: EventActions.getEventRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_EVENTS.url, data.payload);
        if (response.status === 200) {
            yield put(EventActions.getEventRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEventRequestFailure());
    }
}
function* asyncGetSingleEvent(data: EventActions.getSingleEventRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.SINGLE_GET_EVENTS.url + `/${data.payload}`);
        if (response.status === 200) {
            yield put(EventActions.getSingleEventRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getSingleEventRequestFailure());
    }
}
function* asyncGetEmailSetting(data: EventActions.getEmailSettingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(EventActions.getEmailSettingRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEmailSettingRequestFailure());
    }
}
function* asyncUpdateEmailSetting(data: EventActions.updateEmailSettingRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.PATCH_EVENT_EMAIL_SETTINGS.url, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateEmailSettingRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateEmailSettingRequestFailure());
    }
}
function* asyncGetEventLayout(data: EventActions.getEventLayoutRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(EventActions.getEventLayoutRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEventLayoutRequestFailure());
    }
}
function* asyncGetEventRegistrations(data: EventActions.getEventRegistrationsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.SINGLE_GET_EVENTS.url + `/${data.payload}` + '/registrations');
        if (response.status === 200) {
            yield put(EventActions.getEventRegistrationsRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEventRegistrationsRequestFailure());
    }
}
function* asyncDeleteEventRegistrations(data: EventActions.deleteEventRegistrationsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(URLS.DELETE_EVENT_REGISTERED_USERS.url + `${data.payload.eventid}/registrations/` + `${data.payload.memberid}`);
        if (response.status === 200) {
            yield put(EventActions.deleteEventRegistrationsRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.deleteEventRegistrationsRequestFailure());
    }
}
function* asyncGetRegistrationsDownload(data: EventActions.getRegistrationsDownloadRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_DOWNLOAD_USERS.url}/${data.payload}/registrationsdownload`);
        if (response.status === 200) {
            yield put(EventActions.getRegistrationsDownloadRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getRegistrationsDownloadRequestFailure());
    }
}
function* asyncGetRegistrationField(data: EventActions.getRegistrationFieldRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(EventActions.getRegistrationFieldRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getRegistrationFieldRequestFailure());
    }
}
function* asyncSaveRegistrationField(data: EventActions.saveRegistrationFieldRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_REGISTRATION_FIELDS.url, data.payload);
        if (response.status === 200) {
            yield put(EventActions.saveRegistrationFieldRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.saveRegistrationFieldRequestFailure());
    }
}
function* asyncDeleteRegistrationField(data: EventActions.deleteRegistrationFieldRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(URLS.DELETE_REGISTRATION_FIELDS.url + `/${data.payload}`);
        if (response.status === 200) {
            yield put(EventActions.deleteRegistrationFieldRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.deleteRegistrationFieldRequestFailure());
    }
}
function* asyncUpdateIsRequired(data: EventActions.updateIsRequiredRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.PATCH_IS_REQUIRED_REGISTRATION_FIELD.url + `/${data.payload.registration_field_id}/${data.payload.isrequired}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateIsRequiredRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateIsRequiredRequestFailure());
    }
}
function* asyncUpdateIsEnable(data: EventActions.updateIsEnableRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.PATCH_IS_ENABLE_REGISTRATION_FIELD.url + `/${data.payload.registration_field_id}/${data.payload.isEnable}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateIsEnableRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateIsEnableRequestFailure());
    }
}
function* asyncUpdateIsPasscodeRequired(data: EventActions.updateIsPasscodeRequiredRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.PATCH_IS_PASSCODE_REQUIRED_REGISTRATION_FIELD.url + `/${data.payload.registration_field_id}/${data.payload.isPasscodeRequired}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateIsPasscodeRequiredRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateIsPasscodeRequiredRequestFailure());
    }
}
function* asyncImportRegistration(data: EventActions.importRegistrationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/event/${data.payload.id}/uploadregistrations`, data.payload.csvdata);
        if (response.status === 200) {
            yield put(EventActions.importRegistrationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.importRegistrationRequestFailure());
    }
}
function* asyncGetEventVideo(data: EventActions.getEventVideoRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.GET_EVENT_VIDEOS.url, data.payload);
        if (response.status === 200) {
            yield put(EventActions.getEventVideoRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEventVideoRequestFailure());
    }
}
function* asyncGetEventReminder(data: EventActions.getEventReminderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_EVENT_REMINDER.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(EventActions.getEventReminderRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.getEventReminderRequestFailure());
    }
}

function* asyncUpdateEventReminder(data: EventActions.updateEventReminderRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_EVENT_REMINDER.url}/${data.payload?.emailReminderNumber}`, data.payload);
        if (response.status === 200) {
            yield put(EventActions.updateEventReminderRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(EventActions.updateEventReminderRequestFailure());
    }
}
export function* eventSaga() {
    yield all([
        takeEvery(Constants.saveEventRequestActionType, asyncSaveEvent),
        takeEvery(Constants.updateEventRequestActionType, asyncUpdateEvent),
        takeEvery(Constants.updateEventLayoutRequestActionType, asyncUpdateEventLayout),
        takeEvery(Constants.getEventRequestActionType, asyncGetEvent),
        takeEvery(Constants.getSingleEventRequestActionType, asyncGetSingleEvent),
        takeEvery(Constants.getEmailSettingRequestActionType, asyncGetEmailSetting),
        takeEvery(Constants.updateEmailSettingRequestActionType, asyncUpdateEmailSetting),
        takeEvery(Constants.getEventLayoutRequestActionType, asyncGetEventLayout),
        takeEvery(Constants.getEventRegistrationsRequestActionType, asyncGetEventRegistrations),
        takeEvery(Constants.deleteEventRegistrationsRequestActionType, asyncDeleteEventRegistrations),
        takeEvery(Constants.getRegistrationsDownloadRequestActionType, asyncGetRegistrationsDownload),
        takeEvery(Constants.getRegistrationFieldRequestActionType, asyncGetRegistrationField),
        takeEvery(Constants.saveRegistrationFieldRequestActionType, asyncSaveRegistrationField),
        takeEvery(Constants.deleteRegistrationFieldRequestActionType, asyncDeleteRegistrationField),
        takeEvery(Constants.updateIsRequiredRequestActionType, asyncUpdateIsRequired),
        takeEvery(Constants.updateIsEnableRequestActionType, asyncUpdateIsEnable),
        takeEvery(Constants.updatePasscodeIsRequiredRequestActionType, asyncUpdateIsPasscodeRequired),
        takeEvery(Constants.importRegistrationRequestActionType, asyncImportRegistration),
        takeEvery(Constants.getEventVideoRequestActionType, asyncGetEventVideo),
        takeEvery(Constants.getEventReminderRequestActionType, asyncGetEventReminder),
        takeEvery(Constants.updateEventReminderRequestActionType, asyncUpdateEventReminder),
    ]);
}
