import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
const VideoList = ({
  videos,
  handleEditClick,
  handleDelteClick,
  isSearchVideoLoading,
  setVideoId
}: any) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const ref = useRef<any>(null);
  const [rightClickVideoId, setRightClickVideoId] = useState('');
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const handleAnalyticsClick = (videoId: string) => {
    window.open(`/videos/analytics/${videoId}`, '_blank');
  };

  const handleDeleteClick = (videoId: string) => {
    videoAction.deleteVideoRequest(videoId);
  };

  const handlePreviewClick = (previewUrl: string, videoId: string) => {
    window.open(`${previewUrl}${videoId}`, '_blank');
  };

  const openInNewTabClick = (videoId: string) => {
    window.open(`/videos/${videoId}`, '_blank');
  };

  const handleRightClick = (e: any, videoId: string) => {
    e.preventDefault();
    setRightClickVideoId(videoId);
  };
  useEffect(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, [rightClickVideoId]);
  return (
    <>
      <h6 className="font-bold mb-4 mt-7">Videos</h6>
      {!isSearchVideoLoading ? (
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          {videos && videos.length ? (
            videos.map((video: any, index: any) => {
              return (
                <>
                  <div
                    onContextMenu={(e) => handleRightClick(e, video.id)}
                    key={index}
                    className="box shadow rounded-md video-card relative overflow-hidden">
                    <div onDoubleClick={() => handleEditClick(video.id)}>
                      <div className="bg-gray-100 h-44 flex-items justify-center">
                        <img src={video.thumbnailUrl} className="w-full h-full object-cover" />
                      </div>
                      <div className="px-4 py-2">
                        <div className="flex justify-between">
                          <h6 className="font-semibold box-text-main mr-3 hover:text-gray-900 cursor-pointer">
                            {video.title}
                          </h6>
                          <span className="whitespace-nowrap hidden">50.34 MB</span>
                        </div>
                      </div>
                    </div>
                    {rightClickVideoId === video.id && (
                      <div className="absolute top-3 right-3">
                        <div className="flex justify-center">
                          <div>
                            <div className="dropdown relative">
                              <span
                                ref={ref}
                                className="
                                
        dropdown-toggle
        transition
        duration-150
        ease-in-out cursor-pointer"
                                id="ActionButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i className="fas fa-ellipsis-v"></i>
                              </span>
                              <ul
                                className="dropdown-menu action-dropdown"
                                aria-labelledby="ActionButton">
                                <li onClick={() => openInNewTabClick(video.id)}>
                                  <span className="dropdown-item flex-items cursor-pointer">
                                    <i className="fas fa-edit w-6"></i>
                                    <span>Open in new tab</span>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Dropdown */}
                    <div className="absolute top-3 right-3">
                      <div className="flex justify-center">
                        <div>
                          <div className="dropdown relative">
                            <span
                              className="
                            dropdown-toggle
                            transition
                            duration-150
                            ease-in-out cursor-pointer"
                              id="ActionButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <i className="fas fa-ellipsis-v"></i>
                            </span>
                            <ul
                              className="dropdown-menu action-dropdown"
                              aria-labelledby="ActionButton">
                              <li onClick={() => handlePreviewClick(video.previewUrl, video.id)}>
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-eye w-6"></i>
                                  <span>Preview</span>
                                </span>
                              </li>
                              <li
                                onClick={() => setVideoId(video.id)}
                                data-bs-toggle="modal"
                                data-bs-target="#ShareVideoModal">
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-user-plus w-6"></i>
                                  <span>Share</span>
                                </span>
                              </li>
                              <li onClick={() => handleAnalyticsClick(video.id)}>
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-chart-bar w-6"></i>
                                  <span>Analytics</span>
                                </span>
                              </li>
                              <li onClick={() => handleEditClick(video.id)}>
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-edit w-6"></i>
                                  <span>Edit</span>
                                </span>
                              </li>
                              <li>
                                <span className="dropdown-item flex-items text-red-500 cursor-pointer">
                                  <i className="fas fa-trash w-6"></i>
                                  <span onClick={() => handleDeleteClick(video.id)}>Delete</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <h4>No Data Found</h4>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default VideoList;
