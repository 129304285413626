import React, { useEffect, useState, useRef } from 'react';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import Pagination from 'src/components/common/pagination';
import { CategoryData } from 'src/models/categoryModel';
import { useDispatch, useSelector } from 'react-redux';
interface IProps {
  categoryId: CategoryData;
  tabFlag: number | undefined;
  selectedTagId: CategoryData;
  selectedContentTypeId: CategoryData;
  selectedModuleId: CategoryData;
}
const ViewAllVideos: React.FC<IProps> = ({
  categoryId,
  tabFlag,
  selectedTagId,
  selectedContentTypeId,
  selectedModuleId
}) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [fieldName, setFieldName] = useState<string>();
  const [headingName, setHeadingName] = useState<string>();
  const btnRef = useRef<HTMLButtonElement>(null);
  const [filterData, setFilterData] = useState<any>();
  const handleEditClick = (videoId: string) => {
    if (btnRef.current) {
      btnRef.current.click();
    }
    window.open(`/videos/${videoId}`, '_blank');
  };

  const {
    videos,
    isSearchVideoLoading,
    paginationMeta,
    isCategoryDeleted,
    isVideoTagDeleted,
    isContentTypeDeleted,
    message,
    isModuleDeleted
  } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.videos.paginationMeta,
      videos: state.videos.videos,
      isSearchVideoLoading: state.videos.isSearchVideoLoading,
      isCategoryDeleted: state.category.isCategoryDeleted,
      isVideoTagDeleted: state.category.isVideoTagDeleted,
      isContentTypeDeleted: state.category.isContentTypeDeleted,
      message: state.category.message,
      isModuleDeleted: state.category.isModuleDeleted
    };
  });

  useEffect(() => {
    if (tabFlag === 0 && message !== 'Record has been deleted') {
      setFieldName('Category');
      const payloadData = {
        category: categoryId.id,
        pageNumber: 1
      };
      setFilterData(payloadData);
      setHeadingName(categoryId.name);
      videoAction.searchVideoRequest(payloadData);
    } else if (tabFlag === 1 && message !== 'Record has been deleted') {
      setFieldName('Tag');
      const payloadData = {
        tag: selectedTagId.id,
        pageNumber: 1
      };
      setFilterData(payloadData);
      setHeadingName(selectedTagId.name);
      videoAction.searchVideoRequest(payloadData);
    } else if (tabFlag === 2 && message !== 'Record has been deleted') {
      setFieldName('Content Type');
      const payloadData = {
        contentType: selectedContentTypeId.id,
        pageNumber: 1
      };
      setFilterData(payloadData);
      setHeadingName(selectedContentTypeId.name);
      videoAction.searchVideoRequest(payloadData);
    } else if (tabFlag === 3 && message !== 'Record has been deleted') {
      setFieldName('Module');
      const payloadData = {
        module: selectedModuleId.id,
        pageNumber: 1
      };
      setFilterData(payloadData);
      setHeadingName(selectedModuleId.name);
      videoAction.searchVideoRequest(payloadData);
    }
  }, [isCategoryDeleted, isVideoTagDeleted, isContentTypeDeleted, isModuleDeleted]);

  const handlePageChange = (pageNumber: number | string) => {
    const data = { ...filterData, pageNumber };
    videoAction.searchVideoRequest(data);
  };

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="categoryList"
      aria-labelledby="categoryListLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="categoryListLabel">
              {headingName}
            </h5>

            <button
              ref={btnRef}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>

          <div className="modal-body relative py-4 px-10">
            <p>
              This {fieldName} is linked with following videos. Please unlink it from the following
              videos to delete it.
            </p>
            <div className="tab-pane">
              <div className="flex flex-col">
                <div className="inline-block w-full overflow-auto">
                  <table className="min-w-full border-spacing border-separate">
                    <thead className="font-bold">
                      <tr className="mb-5">
                        <th scope="col" className="text-sm px-6 py-4 text-left">
                          VIDEO NAME
                        </th>

                        <th scope="col" className="text-sm px-6 py-4 text-left">
                          ACTIONS
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {!isSearchVideoLoading ? (
                        <>
                          {videos.length ? (
                            videos &&
                            videos.map((videos: any, index: any) => {
                              return (
                                <tr key={index} className="shadow rounded-md">
                                  <td
                                    onDoubleClick={() => handleEditClick(videos.id)}
                                    className="text-sm font-light px-6 py-4">
                                    <div className="flex items-center">
                                      <img
                                        src={`${videos.thumbnailUrl}`}
                                        className="w-14 h-14 object-contain"
                                      />
                                      <div className="ml-2">{videos.title}</div>
                                    </div>
                                  </td>

                                  <td className="text-sm font-light px-6 py-4">
                                    <div>
                                      <div className="flex justify-center">
                                        <div>
                                          <div className="dropdown relative">
                                            <a
                                              className="
                                        dropdown-toggle
                                        transition
                                        duration-150
                                        ease-in-out"
                                              href="#"
                                              type="button"
                                              id="ActionButton"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false">
                                              <i className="fas fa-ellipsis-v"></i>
                                            </a>
                                            <ul
                                              className="dropdown-menu action-dropdown"
                                              aria-labelledby="ActionButton">
                                              <li>
                                                <span
                                                  className="dropdown-item flex-items cursor-pointer"
                                                  onClick={() => handleEditClick(videos.id)}>
                                                  <i className="fas fa-edit w-6"></i>
                                                  <span>Edit</span>
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td className="px-6">No Video Data Found</td>
                            </tr>
                          )}
                        </>
                      ) : (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllVideos;
