import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import AddNewAction from './AddNewAction';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const CallToAction = () => {
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const [editFlag, setEditFlag] = useState('add');
  const videoLibraryAction = useVideosActions(dispatch);
  const { callToAction, isCallToActionSaved, isCallToActionLoading, message, error } = useSelector(
    (state: RootState) => {
      return {
        callToAction: state.videos.callToAction,
        isCallToActionSaved: state.videos.isCallToActionSaved,
        isCallToActionLoading: state.videos.isCallToActionLoading,
        message: state.videos.message,
        error: state.videos.error
      };
    }
  );

  useEffect(() => {
    if (id) {
      videoLibraryAction.getCallToActionRequest(id);
    }
  }, [isCallToActionSaved]);

  const handleDeleteCallToActionClick = (cId: string) => {
    videoLibraryAction.deleteCallToActionRequest(cId);
    videoLibraryAction.resetVideoLibraryRequest();
  };

  useEffect(() => {
    if (isCallToActionSaved) {
      successMsg(message);
      videoLibraryAction.resetVideoLibraryRequest();
    }
  }, [isCallToActionSaved]);

  const handleEditCallToAction = (cId: string) => {
    setEditFlag('edit');
    videoLibraryAction.getSingleCallToActionRequest(cId);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      videoLibraryAction.resetVideoLibraryRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <Link to={`/videos/${id}`}>
        <i className="fa fa-arrow-left mr-1"></i> Go Back
      </Link>
      <div className="shadow bg-white my-5 p-4">
        <div className="text-right">
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#AddlivestreamModal"
            className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
            Add Call-To-Action
          </button>
        </div>

        <div className="overflow-x-auto mt-4">
          <table className="table-auto w-full border">
            <thead className="text-xs font-semibold bg-gray-50">
              <tr className="uppercase">
                <th className="p-2">
                  <div className="font-semibold text-left">Action Type</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Action Time</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Link URL</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left">Button Text</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left"></div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-left"></div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {callToAction &&
                callToAction.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="p-2">
                        <div className="text-left">{item.actionType}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{item.actionTime}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{item.linkText}</div>
                      </td>
                      <td className="p-2">
                        <div className="text-left">{item.buttonText}</div>
                      </td>
                      <td
                        onClick={() => handleEditCallToAction(item.id)}
                        className="p-2"
                        data-bs-toggle="modal"
                        data-bs-target="#AddlivestreamModal">
                        <div className="text-left">
                          <i className="fa fa-edit"></i>
                        </div>
                      </td>
                      <td className="p-2" onClick={() => handleDeleteCallToActionClick(item.id)}>
                        <div className="text-left">
                          <i className="fa fa-remove"></i>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <AddNewAction videoId={id} editFlag={editFlag} />
    </div>
  );
};

export default CallToAction;
