import React, { useState, useEffect } from 'react';
import '../styles.scss';
import { Link } from 'react-router-dom';
import UserProfile from './components/UserProfile';
import PlayPage from './components/PlayPage';
import ChatStyle from './components/ChatStyle';
import Navigation from './components/Navigation';
import SideMenu from './components/SideMenu';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import Brand from './components/Brand';
import ColorBackground from './components/ColorBackground';
import Fonts from './components/Fonts';
import VideoCard from './components/VideoCard';
import SearchSettings from './components/SearchSettings';
import { useAppearanceActions } from 'src/store/appearance/actions';
const VideoLibrary = () => {
  const dispatch = useDispatch();
  const appearanceAction = useAppearanceActions(dispatch);
  const [videoLayout, setVideoLayout] = useState(true);

  const { message, isAppearanceUpdated, isNavigationAdded } = useSelector((state: RootState) => {
    return {
      message: state.appearance.message,
      isAppearanceUpdated: state.appearance.isAppearanceUpdated,
      isNavigationAdded: state.appearance.isNavigationAdded
    };
  });

  useEffect(() => {
    if (isAppearanceUpdated || isNavigationAdded) {
      successMsg(message);
      appearanceAction.resetAppearanceStateRequest();
    }
  }, [isAppearanceUpdated, isNavigationAdded]);

  return (
    <div>
      <ToastMessage />
      <div className="highlight-reel">
        <div className="mb-6">
          <h2 className="font-semibold my-1">Customize</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Appearance</Link>
            </li>
            <li>
              <strong>Customize</strong>
            </li>
          </ol>
        </div>

        <div className="lg:flex">
          <div
            className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
              videoLayout ? 'hide-lists' : 'show-lists'
            }`}>
            <div className="mobile-dropdown" onClick={() => setVideoLayout(!videoLayout)}>
              <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
                <p>Video Channel Layout</p>
                <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
              </div>
            </div>
            <SideMenu />
          </div>
          <div className="tab-content lg:w-4/5 shadow border" id="tabs-tabContentVertical">
            <Brand />
            <ColorBackground />
            <Fonts />
            <VideoCard />
            <SearchSettings />
            <UserProfile />
            <PlayPage />
            <ChatStyle />
            <Navigation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoLibrary;
