import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import DeleteTagModal from '../DeleteTag';
import Pagination from 'src/components/common/pagination';
import { CategoryData } from 'src/models/categoryModel';
import AddNewTag from '../AddNewTag';
import { DebounceInput } from 'react-debounce-input';
import Loader from 'src/components/loader';
interface IProps {
  setSelectedTagId: (value: CategoryData) => void;
  setTabFlag: (value: number) => void;
}
const VideoTags: React.FC<IProps> = ({ setSelectedTagId, setTabFlag }) => {
  const dispatch = useDispatch();
  const categoryActions = useCategoryActions(dispatch);
  const [tagAddFlag, setTagAddFlag] = useState<string>('Add');
  const [searchValue, setSearchValue] = useState<string>('');
  const [tagId, setTagId] = useState<string>('');
  const { tags, isVideoTagSaved, isVideoTagUpdated, isVideoTagDeleted, tagsPaginationMeta } =
    useSelector((state: RootState) => {
      return {
        tags: state.category.tags,
        isVideoTagSaved: state.category.isVideoTagSaved,
        isVideoTagUpdated: state.category.isVideoTagUpdated,
        isVideoTagDeleted: state.category.isVideoTagDeleted,
        tagsPaginationMeta: state.category.tagsPaginationMeta
      };
    });

  useEffect(() => {
    if (isVideoTagSaved || isVideoTagUpdated || isVideoTagDeleted) {
      categoryActions.getVideoTagRequest({ pageNumber: 1 });
    }
  }, [isVideoTagSaved, isVideoTagUpdated, isVideoTagDeleted]);

  const handleEditClick = (tagId: string) => {
    categoryActions.getSingleVideoTagRequest(tagId);
    setTagAddFlag('Edit');
  };
  const handlePageChange = (pageNumber: number) => {
    const data = { querySearch: searchValue, pageNumber };
    categoryActions.getVideoTagRequest(data);
  };
  const handleDeleteClick = (data: CategoryData) => {
    setTagId(data.id);
    setSelectedTagId({ id: data.id, name: data.name });
    setTabFlag(1);
  };
  const handleSearchTag = (e: string) => {
    setSearchValue(e);
    categoryActions.getVideoTagRequest({ querySearch: e, pageNumber: 1 });
  };
  return (
    <>
      <div className="tab-pane fade " id="tabs-videoTags" role="tabpanel">
        <div className="py-5 px-7">
          <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
            <h2 className="font-semibold "></h2>
            <div className="md:flex items-center text-center">
              <div className="form-group md:flex items-center text-center">
                <label className="mr-1 font-semibold">Search:</label>
                <DebounceInput
                  placeholder="Search"
                  minLength={1}
                  className="input-style w-48 mr-3 mb-1"
                  debounceTimeout={500}
                  onChange={(event) => handleSearchTag(event.target.value)}
                />
              </div>
              <button
                onClick={() => setTagAddFlag('Add')}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#AddNewTag"
                className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                Add
              </button>
            </div>
          </div>
          <div className="overflow-x-auto-sm">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-100">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Tag Name</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-left">Tag Description</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left invisible">Action</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {tags &&
                  tags.map((tags) => {
                    return (
                      <tr key={tags.id}>
                        <td className="p-2">{tags.tag}</td>
                        <td className="p-2">{tags.description}</td>
                        <td className="p-2">
                          <div className="dropdown relative">
                            <a
                              className="
                              dropdown-toggle
                              transition
                              duration-150
                              ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                              href="#"
                              type="button"
                              id="ActionButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <i className="fas fa-ellipsis-v"></i>
                            </a>
                            <ul
                              className="dropdown-menu action-dropdown"
                              aria-labelledby="ActionButton">
                              <li
                                data-bs-toggle="modal"
                                data-bs-target="#AddNewTag"
                                onClick={() => handleEditClick(tags.id)}>
                                <span className="dropdown-item flex-items cursor-pointer">
                                  <i className="fas fa-edit w-6"></i>
                                  <span>Edit</span>
                                </span>
                              </li>
                              <li
                                onClick={() => handleDeleteClick({ id: tags.id, name: tags.tag })}>
                                <span
                                  className="dropdown-item flex-items cursor-pointer text-red-500"
                                  data-bs-toggle="modal"
                                  data-bs-target="#DeleteTagModal">
                                  <i className="fas fa-trash w-6"></i>
                                  <span>Delete</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!!tagsPaginationMeta && !!Object.keys(tagsPaginationMeta).length && (
              <Pagination
                currentPage={tagsPaginationMeta.pageNumber}
                totalCount={tagsPaginationMeta.totalCount}
                pageSize={tagsPaginationMeta.pageSize}
                onPageChange={(page: any) => handlePageChange(page)}
              />
            )}
          </div>
        </div>
      </div>
      <AddNewTag tagAddFlag={tagAddFlag} />
      <DeleteTagModal tagId={tagId} />
    </>
  );
};

export default VideoTags;
