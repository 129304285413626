import { EventActions } from "./actions";
import { RootState } from "../reducers/state";
import { EventStateModel, EventModel, EmailSettingModal, EventLayoutModal, EventRegistrationModel, RegistrationFieldModel, RegistrationModel, GetEventReminderModal } from "src/models/eventModel";
import { Actions } from "src/models/eventModel";
import { PaginationModel } from "src/models/paginationModel";
import * as Constants from './constants'
const initialState: RootState.EventModelState = {
    events: [],
    singleEvent: {} as EventModel,
    emailSetting: {} as EmailSettingModal,
    eventLayout: {} as EventLayoutModal,
    paginationMeta: {} as PaginationModel,
    eventRegistration: {} as EventRegistrationModel,
    registration: {} as RegistrationModel,
    registrationField: {} as RegistrationFieldModel,
    eventReminder: [],
    registrationsDownload: [],
    eventVideo: [],
    eventID: "",
    message: '',
    isEventSaved: false,
    isEventUpdated: false,
    isEventDeleted: false,
    isEventSavedLoading: false,
    isEmailSettingUpdated: false,
    isRegistrationFieldUpdated: false,
    isEventLayoutUpdated: false,
    isEventRegistrationDeleted: false,
    isRegistrationFieldSaved: false,
    isRegistrationFieldDeleted: false,
    isRegistrationImported: false,
    isEventVideoLoading: false,
    isEventReminderUpdated: false,
    isEventReminderUpdateLoading: false,
    success: false,
    error: false,
    loading: false,
};

function EventReducer(state: EventStateModel = initialState, action: Actions): EventStateModel {
    switch (action.type) {
        case Constants.saveEventRequestActionType: return saveEventAction(state);
        case Constants.saveEventRequestSuccessActionType: return saveEventSuccessAction(state, action);
        case Constants.saveEventRequestFailureActionType: return saveEventFailureAction(state);

        case Constants.updateEventRequestActionType: return updateEventAction(state);
        case Constants.updateEventRequestSuccessActionType: return updateEventSuccessAction(state);
        case Constants.updateEventRequestFailureActionType: return updateEventFailureAction(state);

        case Constants.getEventRequestActionType: return getEventAction(state);
        case Constants.getEventRequestSuccessActionType: return getEventSuccessAction(state, action);
        case Constants.getEventRequestFailureActionType: return getEventFailureAction(state);

        case Constants.getSingleEventRequestActionType: return getSingleEventAction(state);
        case Constants.getSingleEventRequestSuccessActionType: return getSingleEventSuccessAction(state, action);
        case Constants.getSingleEventRequestFailureActionType: return getSingleEventFailureAction(state);

        case Constants.getEmailSettingRequestActionType: return getEmailSettingAction(state);
        case Constants.getEmailSettingRequestSuccessActionType: return getEmailSettingSuccessAction(state, action);
        case Constants.getEmailSettingRequestFailureActionType: return getEmailSettingFailureAction(state);

        case Constants.updateEmailSettingRequestActionType: return updateEmailSettingAction(state);
        case Constants.updateEmailSettingRequestSuccessActionType: return updateEmailSettingSuccessAction(state);
        case Constants.updateEmailSettingRequestFailureActionType: return updateEmailSettingFailureAction(state);

        case Constants.getEventLayoutRequestActionType: return getEventLayoutAction(state);
        case Constants.getEventLayoutRequestSuccessActionType: return getEventLayoutSuccessAction(state, action);
        case Constants.getEventLayoutRequestFailureActionType: return getEventLayoutFailureAction(state);

        case Constants.updateEventLayoutRequestActionType: return updateEventLayoutAction(state);
        case Constants.updateEventLayoutRequestSuccessActionType: return updateEventLayoutSuccessAction(state);
        case Constants.updateEventLayoutRequestFailureActionType: return updateEventLayoutFailureAction(state);

        case Constants.getEventRegistrationsRequestActionType: return getEventRegistrationsAction(state);
        case Constants.getEventRegistrationsRequestSuccessActionType: return getEventRegistrationsSuccessAction(state, action);
        case Constants.getEventRegistrationsRequestFailureActionType: return getEventRegistrationsFailureAction(state);

        case Constants.deleteEventRegistrationsRequestActionType: return deleteEventRegistrationsAction(state);
        case Constants.deleteEventRegistrationsRequestSuccessActionType: return deleteEventRegistrationsSuccessAction(state);
        case Constants.deleteEventRegistrationsRequestFailureActionType: return deleteEventRegistrationsFailureAction(state);

        case Constants.getRegistrationFieldRequestActionType: return getRegistrationFieldAction(state);
        case Constants.getRegistrationFieldRequestSuccessActionType: return getRegistrationFieldSuccessAction(state, action);
        case Constants.getRegistrationFieldRequestFailureActionType: return getRegistrationFieldFailureAction(state);

        case Constants.saveRegistrationFieldRequestActionType: return saveRegistrationFieldAction(state);
        case Constants.saveRegistrationFieldRequestSuccessActionType: return saveRegistrationFieldSuccessAction(state);
        case Constants.saveRegistrationFieldRequestFailureActionType: return saveRegistrationFieldFailureAction(state);

        case Constants.deleteRegistrationFieldRequestActionType: return deleteRegistrationFieldAction(state);
        case Constants.deleteRegistrationFieldRequestSuccessActionType: return deleteRegistrationFieldSuccessAction(state);
        case Constants.deleteRegistrationFieldRequestFailureActionType: return deleteRegistrationFieldFailureAction(state);

        case Constants.updateIsRequiredRequestActionType: return updateIsRequiredRequestAction(state);
        case Constants.updateIsRequiredRequestSuccessActionType: return updateIsRequiredRequestSuccessAction(state, action);
        case Constants.updateIsRequiredRequestFailureActionType: return updateIsRequiredRequestFailureAction(state);

        case Constants.updateIsEnableRequestActionType: return updateIsEnableRequestAction(state);
        case Constants.updateIsEnableRequestSuccessActionType: return updateIsEnableRequestSuccessAction(state, action);
        case Constants.updateIsEnableRequestFailureActionType: return updateIsEnableRequestFailureAction(state);

        case Constants.updatePasscodeIsRequiredRequestActionType: return updateIsPasscodeRequiredRequestAction(state);
        case Constants.updatePasscodeIsRequiredRequestSuccessActionType: return updateIsPasscodeRequiredRequestSuccessAction(state, action);
        case Constants.updatePasscodeIsRequiredRequestFailureActionType: return updateIsPasscodeRequiredRequestFailureAction(state);

        case Constants.importRegistrationRequestActionType: return importRegistrationAction(state);
        case Constants.importRegistrationRequestSuccessActionType: return importRegistrationSuccessAction(state);
        case Constants.importRegistrationRequestFailureActionType: return importRegistrationFailureAction(state);

        case Constants.getRegistrationsDownloadRequestActionType: return getRegistrationsDownloadRequestAction(state);
        case Constants.getRegistrationsDownloadRequestSuccessActionType: return getRegistrationsDownloadRequestSuccessAction(state, action);
        case Constants.getRegistrationsDownloadRequestFailureActionType: return getRegistrationsDownloadRequestFailureAction(state);

        case Constants.getEventVideoRequestActionType: return getEventVideoRequestAction(state);
        case Constants.getEventVideoRequestSuccessActionType: return getEventVideoRequestSuccessAction(state, action);
        case Constants.getEventVideoRequestFailureActionType: return getEventVideoRequestFailureAction(state);

        case Constants.getEventReminderRequestActionType: return getEventReminderRequestAction(state);
        case Constants.getEventReminderRequestSuccessActionType: return getEventReminderRequestSuccessAction(state, action);
        case Constants.getEventReminderRequestFailureActionType: return getEventReminderFailureAction(state);

        case Constants.updateEventReminderRequestActionType: return updateEventReminderRequestAction(state);
        case Constants.updateEventReminderRequestSuccessActionType: return updateEventReminderRequestSuccessAction(state, action);
        case Constants.updateEventReminderRequestFailureActionType: return updateEventReminderFailureAction(state);

        case Constants.resetEventStateRequestActionType: return resetEventStateRequestAction(state);

        default: return state;
    }

    function saveEventAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventSaved: false
        };
    }

    function saveEventSuccessAction(state: EventStateModel, action: EventActions.saveEventRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                eventID: action.payload.result,
                isEventSaved: true
            };
        }
        return state;
    }
    function saveEventFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventSaved: false
        }
    }
    function updateEventAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventUpdated: false
        };
    }
    function updateEventSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEventUpdated: true
        };
    }
    function updateEventFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEventUpdated: false
        }
    }
    function getEventAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventSaved: false,
        };
    }
    function getEventSuccessAction(state: EventStateModel, action: EventActions.getEventRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                events: action.payload.data,
                isEventSaved: false,
                paginationMeta: action.payload.paginationMeta,
                loading: false
            };
        }
        return state;
    }
    function getEventFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getSingleEventAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSingleEventSuccessAction(state: EventStateModel, action: EventActions.getSingleEventRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                singleEvent: action.payload.result,
                loading: false
            };
        }
        return state;
    }
    function getSingleEventFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false,
            loading: false
        }
    }
    function getEmailSettingAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEmailSettingSuccessAction(state: EventStateModel, action: EventActions.getEmailSettingRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                emailSetting: action.payload
            };
        }
        return state;
    }
    function getEmailSettingFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false
        }
    }

    function updateEmailSettingAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEmailSettingUpdated: false
        };
    }
    function updateEmailSettingSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEmailSettingUpdated: true
        };
    }
    function updateEmailSettingFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEmailSettingUpdated: false
        }
    }
    function getEventLayoutAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEventLayoutSuccessAction(state: EventStateModel, action: EventActions.getEventLayoutRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                eventLayout: action.payload
            };
        }
        return state;
    }
    function getEventLayoutFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false
        }
    }

    function updateEventLayoutAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventLayoutUpdated: false
        };
    }
    function updateEventLayoutSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEventLayoutUpdated: true
        };
    }
    function updateEventLayoutFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEventLayoutUpdated: false
        }
    }
    function getEventRegistrationsAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEventRegistrationsSuccessAction(state: EventStateModel, action: EventActions.getEventRegistrationsRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                registration: action.payload.result,
                loading: false
            };
        }
        return state;
    }
    function getEventRegistrationsFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false
        }
    }
    function deleteEventRegistrationsAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventRegistrationDeleted: false,
        };
    }
    function deleteEventRegistrationsSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false,
            isEventRegistrationDeleted: true
        };
    }
    function deleteEventRegistrationsFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventRegistrationDeleted: false,
            loading: false
        }
    }
    function getRegistrationFieldAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRegistrationFieldSuccessAction(state: EventStateModel, action: EventActions.getRegistrationFieldRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                //registrations: action.payload as any
            };
        }
        return state;
    }
    function getRegistrationFieldFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            success: false
        }
    }
    function saveRegistrationFieldAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isRegistrationFieldSaved: false
        };
    }
    function saveRegistrationFieldSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isRegistrationFieldSaved: true
        };
    }
    function saveRegistrationFieldFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isRegistrationFieldSaved: false
        }
    }
    function deleteRegistrationFieldAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isRegistrationFieldDeleted: false
        };
    }
    function deleteRegistrationFieldSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            message: 'Success',
            isRegistrationFieldDeleted: true
        };
    }
    function deleteRegistrationFieldFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isRegistrationFieldDeleted: false
        }
    }

    function updateIsRequiredRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventUpdated: false
        };
    }
    function updateIsRequiredRequestSuccessAction(state: EventStateModel, action: EventActions.updateIsRequiredRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                message: "Success",
                isRegistrationFieldUpdated: true
            };
        }
        return state;
    }
    function updateIsRequiredRequestFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventUpdated: false
        }
    }

    function updateIsEnableRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventUpdated: false
        };
    }
    function updateIsEnableRequestSuccessAction(state: EventStateModel, action: EventActions.updateIsEnableRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                message: "Success",
                isRegistrationFieldUpdated: true
            };
        }
        return state;
    }
    function updateIsEnableRequestFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventUpdated: false
        }
    }

    function updateIsPasscodeRequiredRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isEventUpdated: false
        };
    }
    function updateIsPasscodeRequiredRequestSuccessAction(state: EventStateModel, action: EventActions.updateIsPasscodeRequiredeRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                message: "Success",
                isRegistrationFieldUpdated: true
            };
        }
        return state;
    }
    function updateIsPasscodeRequiredRequestFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventUpdated: false
        }
    }
    function resetEventStateRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventUpdated: false,
            isEventRegistrationDeleted: false,
            isRegistrationFieldUpdated: false,
            isEventReminderUpdated: false,
            isRegistrationImported: false
        };
    }
    function importRegistrationAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
            isRegistrationImported: false
        };
    }
    function importRegistrationSuccessAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            message: 'Success',
            isRegistrationImported: true
        };
    }
    function importRegistrationFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isRegistrationImported: false
        }
    }
    function getRegistrationsDownloadRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRegistrationsDownloadRequestSuccessAction(state: EventStateModel, action: EventActions.getRegistrationsDownloadRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                registrationsDownload: action.payload
            };
        }
        return state;
    }
    function getRegistrationsDownloadRequestFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getEventVideoRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventVideoLoading: true,
        };
    }
    function getEventVideoRequestSuccessAction(state: EventStateModel, action: EventActions.getEventVideoRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                isEventVideoLoading: false,
                eventVideo: action.payload
            };
        }
        return state;
    }
    function getEventVideoRequestFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventVideoLoading: false
        }
    }
    function getEventReminderRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getEventReminderRequestSuccessAction(state: EventStateModel, action: EventActions.getEventReminderRequestSuccessAction): EventStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                eventReminder: action.payload
            };
        }
        return state;
    }
    function getEventReminderFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateEventReminderRequestAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventReminderUpdateLoading: true,
        };
    }
    function updateEventReminderRequestSuccessAction(state: EventStateModel, action: EventActions.updateEventReminderRequestSuccessAction): EventStateModel {
        return {
            ...state,
            isEventReminderUpdated: true,
            isEventReminderUpdateLoading: false,
        };
    }
    function updateEventReminderFailureAction(state: EventStateModel): EventStateModel {
        return {
            ...state,
            isEventReminderUpdated: false,
            isEventReminderUpdateLoading: false
        }
    }
}

export default EventReducer
