import React from 'react';

const Analytics = () => {
  return (
    <div
      className="tab-pane fade"
      id="tabs-analyticsView"
      role="tabpanel"
      aria-labelledby="tabs-analytics-view">
      <div className="p-5">Analytics</div>
    </div>
  );
};

export default Analytics;
