import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/common/pagination';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import Select from 'react-select';
import { useChannelActions } from 'src/store/channel/actions';
import { CSVLink } from 'react-csv';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import { SelectModel } from 'src/models/videosModel';
import { defaultDateFormatter, getTransactionStatus } from 'src/components/utilities/utils';
import { numberWithCommas } from 'src/components/utilities/utils';
import { SalesOrderModel } from 'src/models/dashboardModel';
import { customFilterOption } from 'src/components/utilities/utils';
const SalesOrders: React.FC = () => {
  interface FilterProp {
    pageNumber: number;
    orderFromDate?: Date;
    orderToDate?: Date;
    channelId?: string;
    search?: string;
    productType?: number;
    status?: number;
  }

  const [downloadData, setDownloadData] = useState<any>();
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const channelActions = useChannelActions(dispatch);
  const [channelType, setChannelType] = useState('');
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterProp>({
    pageNumber: 1
  });
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm();
  const {
    paginationMeta,
    channelList,
    salesOrder,
    isSalesOrderLoading,
    salesOrderTotal,
    salesOrderDownload
  } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.analytics.paginationMeta,
      channelList: state.channel.channelList,
      salesOrder: state.analytics.salesOrder,
      isSalesOrderLoading: state.analytics.isSalesOrderLoading,
      salesOrderTotal: state.analytics.salesOrderTotal,
      salesOrderDownload: state.analytics.salesOrderDownload
    };
  });

  const onDownloadClick = () => {
    analyticsAction.downloadSalesOrderRequest({ ...filter, pageSize: paginationMeta.totalCount });
  };

  useEffect(() => {
    const csvData =
      salesOrderDownload &&
      salesOrderDownload.map((salesOrder) => {
        return {
          id: salesOrder.id,
          refNo: salesOrder.refNo,
          customerId: salesOrder.customerId,
          userId: salesOrder.userId,
          firstName: salesOrder.firstName,
          lastname: salesOrder.lastName,
          email: salesOrder.email,
          subTotal: salesOrder.subTotal,
          total: salesOrder.total,
          discountCode: salesOrder.discountCode,
          status: salesOrder.status,
          createdDate: defaultDateFormatter(salesOrder.createdDate),
          orderDate: defaultDateFormatter(salesOrder.orderDate)
        };
      });
    csvData && setDownloadData(csvData as any);
  }, [salesOrderDownload]);

  useEffect(() => {
    if (downloadData && (downloadData as SalesOrderModel[]).length) {
      document.getElementById('lnkDownloadBtn')?.click();
    }
  }, [downloadData]);

  useEffect(() => {
    analyticsAction.getSalesOrderTotalRequest();
  }, []);

  useEffect(() => {
    if (showFilter && !channelList.length) {
      channelActions.getChannelListRequest();
    }
  }, [showFilter]);
  useEffect(() => {
    analyticsAction.getSalesOrderRequest(filter);
  }, [filter]);

  const handlePageChange = (pageNumber: number) => {
    setFilter({
      ...filter,
      pageNumber
    });
  };

  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);
  const handleFilterClick = () => {
    if (showFilter) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  };

  const onSubmit = (data: any) => {
    setFilter({
      ...data,
      channelId: channelType,
      pageNumber: 1,
      status: parseInt(data.status),
      productType: parseInt(data.productType)
    });
  };

  const handleResetClick = () => {
    reset();
    setValue('channelId', '');
    setFilter({
      pageNumber: 1
    });
  };
  return (
    <>
      <div className="mt-4 members-list">
        <div className="flex">
          <h2 className="font-semibold mb-4">Sales Orders</h2>
          <button
            type="button"
            className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn"
            onClick={handleFilterClick}>
            <i className="fa fa-filter" aria-hidden="true"></i>
          </button>
        </div>
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {salesOrderTotal && numberWithCommas(salesOrderTotal.totalSales)}
                  </span>
                  <span className="mb-2">Total Sales</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                      <i className="fas fa-cart-arrow-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {salesOrderTotal && numberWithCommas(salesOrderTotal.salesThisYear)}
                  </span>
                  <span className="mb-2">Sales This Year</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                      <i className="fa fa-check-square-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {salesOrderTotal && numberWithCommas(salesOrderTotal.totalVideoSales)}
                  </span>
                  <span className="mb-2">Total Video Sales</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                      <i className="fa fa-list-alt" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {salesOrderTotal && numberWithCommas(salesOrderTotal.totalChannelSales)}
                  </span>
                  <span className="mb-2">Total Channel Sales</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                      <i className="fa fa-check-circle-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`dashboard-card my-5 ${showFilter ? '' : 'hidden'}`}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 ">
              <h2 className="font-semibold mb-4">Filters</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-8">
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1"> Order Date Start </label>
                <input type="date" className="input-style" {...register('orderFromDate')} />
              </div>

              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1">Order Date End </label>
                <input type="date" className="input-style" {...register('orderToDate')} />
              </div>
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1"> Status </label>
                <select className="input-style" {...register('status')}>
                  <option value={0}>All</option>
                  <option value={1}>Open</option>
                  <option value={2}>Complete</option>
                  <option value={4}>Refunded</option>
                </select>
              </div>

              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1">Search </label>
                <input type="text" className="input-style" {...register('search')} />
              </div>
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1"> Channel </label>

                {channelList && (
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }
                    }) => (
                      <Select
                        options={finalChannels}
                        filterOption={customFilterOption}
                        value={value}
                        id="channelId"
                        onChange={(val) => {
                          setChannelType(val.value);
                          onChange(val);
                        }}
                      />
                    )}
                    name="channelId"
                    control={control}
                  />
                )}
              </div>
            </div>

            <div className="mt-3">
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1">Product Type </label>
                  <select className="input-style" {...register('productType')}>
                    <option value={0}>All</option>
                    <option value={1}>Channel</option>
                    <option value={2}>Video</option>
                  </select>
                </div>
                <div className="flex justify-between items-center mt-5">
                  <div className="flex items-center">
                    <button
                      type="submit"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Submit
                    </button>
                    <button
                      onClick={handleResetClick}
                      type="button"
                      className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                      Reset
                    </button>
                  </div>
                  <div className="flex items-center">
                    {downloadData && (
                      <>
                        <CSVLink
                          id="lnkDownloadBtn"
                          className="export d-none"
                          data={downloadData}
                          filename={'Sales-Order.csv'}></CSVLink>
                        <button
                          type="button"
                          onClick={onDownloadClick}
                          className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                          Download
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* <div className={`dashboard-card my-5 `}>
          <LineChart options={options} chartData={lineChartData} />
        </div> */}
        <div>
          <h2 className="font-semibold mb-4">Transactions</h2>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">RefNo</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Customer</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Email</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Sub Total</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Total</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Discount Code</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Status</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Created Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Order Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Customer ID</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isSalesOrderLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {salesOrder.length ? (
                            <>
                              {salesOrder &&
                                salesOrder.map((salesOrder, index) => {
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        className="p-2 
                                    accordion-button
                                    collapsed"
                                        data-bs-toggle="collapse"
                                        data-bs-target={'#collapse' + salesOrder.id}
                                        aria-expanded="false"
                                        aria-controls={'collapse' + salesOrder.id}>
                                        <td className="p-2">
                                          <div className="text-left">{salesOrder.refNo}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{salesOrder.customer}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{salesOrder.email}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            ${salesOrder.subTotal.toFixed(2)}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            ${salesOrder.total.toFixed(2)}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{salesOrder.discountCode}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {' '}
                                            <span
                                              className={`${getTransactionStatus(
                                                salesOrder.status
                                              )} text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300`}>
                                              {salesOrder.status}
                                            </span>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {salesOrder.createdDate
                                              ? defaultDateFormatter(salesOrder.createdDate)
                                              : ''}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {salesOrder.orderDate
                                              ? defaultDateFormatter(salesOrder.orderDate)
                                              : ''}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{salesOrder.customerId}</div>
                                        </td>
                                      </tr>
                                      <tr
                                        id={'collapse' + salesOrder.id}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionSalesOrder">
                                        <td
                                          style={{ padding: '10px', font: 'caption' }}
                                          className="text-center"
                                          colSpan={2}>
                                          <strong>Description</strong>
                                        </td>
                                        <td
                                          style={{ padding: '10px', font: 'caption' }}
                                          className="text-center"
                                          colSpan={2}>
                                          <strong>Channel Name</strong>
                                        </td>
                                        <td
                                          style={{ padding: '10px', font: 'caption' }}
                                          className="text-center"
                                          colSpan={2}>
                                          <strong>Quantity</strong>
                                        </td>
                                        <td
                                          style={{ padding: '10px', font: 'caption' }}
                                          className="text-center"
                                          colSpan={2}>
                                          <strong>Amount</strong>
                                        </td>
                                        <td
                                          style={{ padding: '10px', font: 'caption' }}
                                          className="text-center"
                                          colSpan={2}>
                                          <strong>Comment</strong>
                                        </td>
                                      </tr>
                                      {salesOrder.itemsOrdered &&
                                        salesOrder.itemsOrdered.map((orderItem, index) => {
                                          return (
                                            <>
                                              <tr
                                                id={'collapse' + salesOrder.id}
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionSalesOrder">
                                                <td
                                                  style={{ padding: '10px' }}
                                                  className="text-center"
                                                  colSpan={2}>
                                                  {orderItem.description}
                                                </td>
                                                <td
                                                  style={{ padding: '10px' }}
                                                  className="text-center"
                                                  colSpan={2}>
                                                  {orderItem.channelName}
                                                </td>
                                                <td
                                                  style={{ padding: '10px' }}
                                                  className="text-center"
                                                  colSpan={2}>
                                                  {orderItem.quantity}
                                                </td>
                                                <td
                                                  style={{ padding: '10px' }}
                                                  className="text-center"
                                                  colSpan={2}>
                                                  ${orderItem.amount.toFixed(2)}
                                                </td>
                                                <td
                                                  style={{ padding: '10px' }}
                                                  className="text-center"
                                                  colSpan={2}>
                                                  {orderItem.comment}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td className="text-center py-2" colSpan={10}>
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesOrders;
