import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { useParams } from 'react-router-dom';
interface IProps {
  openLiveViewer: boolean;
}
const LiveViewerModal: React.FC<IProps> = ({ openLiveViewer }) => {
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [pollPopout, setPollPopout] = useState<boolean>(false);
  const rootUrl = process.env.REACT_APP_BASE_API_URL;
  const { id } = useParams<string>();

  const { liveViewers } = useSelector((state: RootState) => {
    return {
      liveViewers: state.videos.liveViewers
    };
  });

  useEffect(() => {
    if (pollPopout) {
      window.open(
        `${
          window.location.toString().includes('localhost')
            ? `http://localhost:3000/videos/analytics/${id}`
            : `${rootUrl}/videos/analytics/9cb652d7-4184-4a39-9ede-f7287690fab2`
        }`,
        'Pop Out',
        'width=400px,height=700px'
      );
      setPollPopout(false);
    }
  }, [pollPopout]);

  useEffect(() => {
    const interval = setInterval(() => {
      id && videoAction.getLiveViewersRequest(id);
      id && videoAction.getLiveViewersCountRequest(id);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={`w-2/6 ${openLiveViewer ? '' : 'hidden'} relative`}>
        <div className="modal-content h-full poll-popup-max overflow-scroll live-studio-border">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md mt-4">
            <i className="fa fa-2x right-menu-head-icon fa-line-chart live-studio-heading"></i>
            <h5
              className="text-xl font-semibold leading-normal text-center w-full live-studio-heading"
              id="PollModalLabel">
              Live Viewers
            </h5>
            <i
              onClick={() => setPollPopout(true)}
              className="fas fa-external-link-alt live-studio-heading"></i>
          </div>

          <div className="flex justify-center mt-4">
            <div className="modal-body relative py-4 px-10">
              {liveViewers && liveViewers.length ? (
                <>
                  {liveViewers &&
                    liveViewers.map((item) => {
                      return (
                        <div key={item.userid}>
                          <strong>{item.fullName}</strong>
                          <div className="text-blue-600">{item.email}</div>

                          <hr className="mt-3" />
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="p-4 text-center">
                  <p>No Questions</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveViewerModal;
