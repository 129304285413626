import React, { useEffect, useState, } from 'react';

import { Link , useParams, useNavigate, useLocation } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';


const QuickBooksProvider: React.FC = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const connected = query.get('connected');
  const navigate = useNavigate();
  const removeQueryParam = (param: string) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete(param);
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();
 
  const { isPaymentUpdated, quickBooks, qbauthurl, loading, message, error } = useSelector((state: RootState) => {
    return {
      isPaymentUpdated: state.setting.isPaymentUpdated,
      loading : state.setting.loading,
      quickBooks: state.setting.quickBooks,
      qbauthurl: state.setting.QBAuthURL,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  useEffect(() => {
    if (quickBooks) {
      reset(quickBooks);
    }
  }, [quickBooks]);

  

  useEffect(() => {
    
    settingAction.getQuickBooksProviderRequest();
  }, []);

  useEffect(() => {
    if (isPaymentUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isPaymentUpdated]);

  useEffect(() => {
    if(connected) {
          if (connected == 'true') {
            successMsg("Quickbooks app connected successfully.");
            removeQueryParam('connected');
          } else{
            errorMsg("Problem in connecting Quickbooks app.");
          }
          removeQueryParam('connected');
        }
  }, [connected]);


  const onSubmit = (data: any) => {
    if (quickBooks == undefined || quickBooks.id == undefined) {
      settingAction.addQuickBooksProviderRequest(data);
    } else {
      const payloadData = {
        name: 'QuickBooks',
        updateData: [
          { op: 'replace', path: 'realmId', value: data.realmId },
          { op: 'replace', path: 'scope', value: data.scope }
        ]
      };
      settingAction.updatePaymentRequest(payloadData);
    }
  };
  const handleConnectClick = () => {
      if(quickBooks && quickBooks.realmId) {
      settingAction.getConnectQuickBooksProviderRequest(quickBooks.realmId);
    }
  };
  useEffect(() => {
    if (qbauthurl && qbauthurl !== '') {
      window.location.href = qbauthurl;
    }
  }, [qbauthurl]);

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      {(loading) && <Loader />}
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payments</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/paymentProvider">Payments</Link>
          </li>
          <li>
            <strong>QuickBooks Configuration</strong>
          </li>
        </ol>
        <Link to={`/paymentProvider`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">QuickBooks Configuration</h4>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Realm ID</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'realmId'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Scope</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'scope'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="flex items-center">
            <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
              Save
            </button>
            <button type="button" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0 borderonly-btn" onClick={handleConnectClick}>
            Connect
            </button>
            <div className="flex flex-col mr-0 ml-3 mt-0">
                    <span className="font-semibold">
                    Token generated on: {quickBooks && quickBooks.tokenDate} 
                    </span>
             </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuickBooksProvider;
