import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const WicketIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { wicketIntegration, isIntegrationUpdated, message, error } = useSelector(
    (state: RootState) => {
      return {
        wicketIntegration: state.setting.wicketIntegration,
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        message: state.setting.message,
        error: state.setting.error
      };
    }
  );

  const [showClientSecret, setShowClientSecret] = useState(false);
  const [showApiSecretKey, setShowApiSecretKey] = useState(false);

  useEffect(() => {
    if (wicketIntegration) {
      reset(wicketIntegration);
    }
  }, [wicketIntegration]);

  useEffect(() => {
    settingAction.getWicketIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    if (wicketIntegration == undefined || wicketIntegration.id == undefined) {
      settingAction.addWicketIntegrationRequest(data);
    } else {
      const payloadData = {
        name: 'Wicket',
        updateData: [
          { op: 'replace', path: 'baseUrl', value: data.baseUrl },
          { op: 'replace', path: 'clientId', value: data.clientId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'apiHostName', value: data.apiHostName },
          { op: 'replace', path: 'apiUser', value: data.apiUser },
          { op: 'replace', path: 'apiSecretKey', value: data.apiSecretKey },
          { op: 'replace', path: 'memberInfoApi', value: data.memberInfoApi },
          { op: 'replace', path: 'membershipApi', value: data.membershipApi },
          { op: 'replace', path: 'writeBackCEActivity', value: data.writeBackCEActivity },
          { op: 'replace', path: 'serviceId', value: data.serviceId }
        ]
      };
      settingAction.updateIntegrationRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> Wicket Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5 w-5/6">
        <div className="dashboard-card p-5 mt-2">
          <h4 className="text-lg">OAuth2 SSO Details</h4>

          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Base Url</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'baseUrl'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Id</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'clientId'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Secret</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showClientSecret ? 'text' : 'password'}
                fieldName={'clientSecret'}
                register={register}
                errors={''}
                isRequired={true}
              />
              <i
                className={`fas ${
                  showClientSecret ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowClientSecret(!showClientSecret)}></i>
            </div>
          </div>
        </div>
        <div className="dashboard-card p-5 mt-2">
          <h4 className="text-lg">API Details</h4>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">API Host Name</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'apiHostName'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">API User</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'apiUser'}
                register={register}
                errors={''}
                isRequired={true}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">API Secret Key</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showApiSecretKey ? 'text' : 'password'}
                fieldName={'apiSecretKey'}
                register={register}
                errors={''}
                isRequired={true}
              />
              <i
                className={`fas ${
                  showApiSecretKey ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowApiSecretKey(!showApiSecretKey)}></i>
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Member Info API</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'memberInfoApi'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Membership API</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'membershipApi'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-8 mt-6">
            <div className="md:w-4/12">
              <label className="font-semibold">Service Id</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'serviceId'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
        </div>
        <div className="dashboard-card p-5 mt-2">
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Write Back CE Activity</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="writeBackCEActivity"
                      {...register('writeBackCEActivity')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-card p-5 mt-2">
          <div className="flex items-center">
            <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WicketIntegration;
