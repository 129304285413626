import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useSettingActions } from 'src/store/settings/actions';
import { SelectModel } from 'src/models/videosModel';
interface IProps {
  addFlag: boolean | undefined;
}
const EducationPointsTemplateModel: React.FC<IProps> = ({ addFlag }) => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();
  const {
    getCeEducationPoints,
    isEducationPointsSaved,
    isEducationPointsUpdate,
    singleEducationPoints,
    isEducationPointsDeleted
  } = useSelector((state: RootState) => {
    return {
      getCeEducationPoints: state.setting.getCeEducationPoints,
      isEducationPointsSaved: state.setting.isEducationPointsSaved,
      isEducationPointsUpdate: state.setting.isEducationPointsUpdate,
      singleEducationPoints: state.setting.singleEducationPoints,
      isEducationPointsDeleted: state.setting.isEducationPointsDeleted
    };
  });

  const [finalCreditTypeList, setFinalCreditTypeList] = useState<SelectModel[]>();
  const [selectedCreditType, setSelectedCreditType] = useState<string>("");

  useEffect(() => {
    if (singleEducationPoints?.creditTypes?.length) {
      setSelectedCreditType(singleEducationPoints?.creditTypes[0]?.id)
      setValue('creditTypes', singleEducationPoints?.creditTypes[0]?.id, { shouldValidate: true })
    }
  }, [singleEducationPoints])

  useEffect(() => {
    if (getCeEducationPoints && getCeEducationPoints.creditTypeList) {
      const options = getCeEducationPoints.creditTypeList.map((item: any) => ({
        value: item.id,
        label: item.type
      }));
      const finalCreditTypeOptions = [{ value: '', label: 'Select Credit Type' }, ...options];
      setFinalCreditTypeList(finalCreditTypeOptions);
    }
  }, [getCeEducationPoints.creditTypeList]);

  useEffect(() => {
    if (isEducationPointsSaved || isEducationPointsUpdate || isEducationPointsDeleted) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
        settingAction.getEducationPointsRequest();
      }
    }
  }, [isEducationPointsSaved, isEducationPointsUpdate || isEducationPointsDeleted]);

  useEffect(() => {
    if (singleEducationPoints) {
      reset(singleEducationPoints);
    }
  }, [singleEducationPoints]);

  const handleCloseClick = () => {
    reset({
      title: '',
      assessmentType: 0,
      passRate: 0,
      allowreTakeQuiz: false,
      creditTypes: null
    });
    setSelectedCreditType('');
  };
  const onSubmit = (data: any) => {
    if (addFlag) {
      settingAction.saveEducationPointsRequest(data);
    } else {
      const credittype = data.creditTypes == '' ? null : data.creditTypes;
      const payloadData = {
        id: data.id,
        updateData: [
          { op: 'replace', path: 'title', value: data.title },
          { op: 'replace', path: 'assessmentType', value: data.assessmentType },
          { op: 'replace', path: 'passRate', value: data.passRate },
          { op: 'replace', path: 'allowreTakeQuiz', value: data.allowreTakeQuiz },
          { op: 'replace', path: 'creditTypes', value: credittype }
        ]
      };
      settingAction.updateEducationPointsRequest(payloadData);
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="AddCETemplate"
        aria-labelledby="AddCETemplateLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddCETemplateLabel">
                Education Points Template
              </h5>

              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Title'}
                    type={'text'}
                    fieldName={'title'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <label>Assessment Type</label>
                  <select className="w-full input-style" {...register('assessmentType')}>
                    <option value={0}>Select Type</option>
                    <option value={1}>Quiz</option>
                    <option value={2}>Duration Watched</option>
                  </select>
                </div>

                <div className={`form-group mb-3`}>
                  <FormInputField
                    label={'Pass Rate(%)'}
                    type={'text'}
                    fieldName={'passRate'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                  />
                </div>

                <div className={`form-group mb-3 flex items-center`}>
                  <div className="md:w-1/3">
                    <label>Allow Retake Quiz</label>
                  </div>
                  <div className="md:w-2/3 ">
                    <input
                      {...register('allowreTakeQuiz')}
                      type="checkbox"
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    />
                  </div>
                </div>

                <div className={`form-group mb-3`}>
                  <label>Credit Type</label>
                  <select className="input-style"
                    {...register('creditTypes')}
                    value={selectedCreditType}
                    onChange={(e) => setSelectedCreditType(e.target.value)}>
                    {finalCreditTypeList?.map((method, idx) => (
                      <option value={method.value} key={idx}>{method.label}</option>
                    ))}
                  </select>
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationPointsTemplateModel;
