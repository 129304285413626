/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState, useRef } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import '../styles.scss';
import { useChannelActions } from 'src/store/channel/actions';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import InterActivity from '../components/InterActivity';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { msToTime, successMsg } from 'src/components/utilities/utils';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';
import Select from 'react-select';
import { SelectModel } from 'src/models/videosModel';
import AddSpeaker from '../addSpeaker';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { errorMsg } from 'src/components/utilities/utils';
import { changeDateFormatter, changeDateFormatter_US } from 'src/components/utilities/utils';
import Resources from '../components/Resources';
import Accessibility from '../components/Accessibility';
import PlayPage from '../components/PlayPage';
import AccessPrice from '../components/AccessPrice';
import Analytics from '../components/Analytics';
import { customFilterOption } from 'src/components/utilities/utils';
import MediaInfo from '../components/MediaInfo';
import VideoInfoUploadModal from '../components/VideoInfoUploadModal';
import SelectNewThumbModal from '../components/SelectNewThumbModal';
import ShareVideoModal from '../components/ShareVideoModal';
import { useCategoryActions } from 'src/store/category/actions';
import QuestionsModal from '../components/QuestionsModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useSettingActions } from 'src/store/settings/actions';
import { useUserActions } from 'src/store/users/actions';
import 'react-tagsinput/react-tagsinput.css';
import Editor from 'src/components/Editor';
import { ImageStringConverter } from 'src/components/utilities/utils';
import { toLowerCase } from 'video.js/dist/types/utils/str';
import PDFViewerModal from '../components/PDFViewerModal';
const Videos: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
    getValues
  } = useForm<any>({
    defaultValues: {
      speaker: [{ speaker: '' }]
    }
  });
  let datePickerRef: any = useRef(null);
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const settingAction = useSettingActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const userActions = useUserActions(dispatch);
  const categoryAction = useCategoryActions(dispatch);
  const navigate = useNavigate();
  const [defaultChannel, setDefaultChannel] = useState<SelectModel>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  const [clipboard, setClipboard] = useState<boolean>(false);
  const [finalContent, setFinalContent] = useState<SelectModel[]>();
  const [finalModule, setFinalModule] = useState<SelectModel[]>();
  const [finaleducationPoints, setFinalEducationPoints] = useState<SelectModel[]>();
  const [speakerName, setSelectedSpeakerName] = useState<string>();
  const [finalSpeaker, setFinalSpeaker] = useState<SelectModel[]>();
  const InputFile = useRef<any>();
  const [editorState, setEditorState] = useState<string>('');
  const [showVideoInfo, setShowVideoInfo] = useState(true);
  const defaultDateFormat = localStorage.getItem('defaultDateFormat');

  const { fields, append, remove } = useFieldArray({
    name: 'speaker',
    control
  });
  const {
    singleVideo,
    channelList,
    message,
    isVideoEdited,
    isEditVideoError,
    isVideoEditedLoading,
    isSingleVideoLoading,
    speakers,
    isSavedLoading,
    isSaved,
    memberId,
    isResourceAdded,
    isResourceUpdated,
    isMemberListAccessSaved,
    isMemberListPriceSaved,
    isMemberListModeratorSaved,
    roles,
    isRedirectToLoginSaved,
    isAuthKeySaved,
    isResourceDeleted,
    isPlayPageUpdated,
    educationPoints,
    isThumbnailUpdated,
    isAccessibilityUpdated,
    singleChannel,
    isDiscountCodeAdded,
    categoriesList,
    modulesList,
    mediaUrl,
    isLiveStreamSourceUpdated
  } = useSelector((state: RootState) => {
    return {
      mediaUrl: state.videos.mediaUrl,
      singleVideo: state.videos.singleVideo,
      channelList: state.channel.channelList,
      message: state.videos.message,
      isVideoEdited: state.videos.isVideoEdited,
      isEditVideoError: state.videos.isEditVideoError,
      isVideoEditedLoading: state.videos.isVideoEditedLoading,
      isSingleVideoLoading: state.videos.isSingleVideoLoading,
      speakers: state.videos.speakers,
      isSavedLoading: state.videos.isSavedLoading,
      isSaved: state.videos.isSaved,
      memberId: state.videos.memberId,
      isResourceAdded: state.videos.isResourceAdded,
      isResourceUpdated: state.videos.isResourceUpdated,
      isResourceDeleted: state.videos.isResourceDeleted,
      isMemberListAccessSaved: state.videos.isMemberListAccessSaved,
      isMemberListPriceSaved: state.videos.isMemberListPriceSaved,
      isMemberListModeratorSaved: state.videos.isMemberListModeratorSaved,
      roles: state.users.roles,
      isRedirectToLoginSaved: state.videos.isRedirectToLoginSaved,
      isAuthKeySaved: state.videos.isAuthKeySaved,
      isPlayPageUpdated: state.videos.isPlayPageUpdated,
      educationPoints: state.setting.educationPoints,
      isThumbnailUpdated: state.videos.isThumbnailUpdated,
      isAccessibilityUpdated: state.videos.isAccessibilityUpdated,
      singleChannel: state.channel.singleChannel,
      isDiscountCodeAdded: state.videos.isDiscountCodeAdded,
      categoriesList: state.category.categoriesList,
      modulesList: state.category.modulesList,
      isLiveStreamSourceUpdated: state.videos.isLiveStreamSourceUpdated
    };
  });

  useEffect(() => {
    if (
      isVideoEdited ||
      isSaved ||
      isResourceAdded ||
      isResourceUpdated ||
      isMemberListAccessSaved ||
      isMemberListPriceSaved ||
      isMemberListModeratorSaved ||
      isResourceDeleted ||
      isPlayPageUpdated ||
      isThumbnailUpdated ||
      isAccessibilityUpdated ||
      isDiscountCodeAdded ||
      isLiveStreamSourceUpdated
    ) {
      successMsg(message);
      videoActions.resetVideoLibraryRequest();
    }
  }, [
    isVideoEdited,
    isSaved,
    isResourceAdded,
    isResourceUpdated,
    isMemberListAccessSaved,
    isMemberListPriceSaved,
    isMemberListModeratorSaved,
    isResourceDeleted,
    isPlayPageUpdated,
    isThumbnailUpdated,
    isAccessibilityUpdated,
    isDiscountCodeAdded,
    isLiveStreamSourceUpdated
  ]);

  const onSubmit = (data: any) => {
    const finalTags = data.tags.map((item: any) => item.value);
    const finalCategories = data.categories.map((item: any) => item.value);
    const finalSpeakers = data.speakerIds.map((item: any) => item.value);
    let finalDate = '';
    if (defaultDateFormat == 'dd/MM/yyyy') finalDate = changeDateFormatter(data.displayDate);
    else if (defaultDateFormat == 'MM/dd/yyyy')
      finalDate = changeDateFormatter_US(data.displayDate);

    const finalData = {
      id: data.id,
      pointAllocation: data.pointAllocation.value,
      title: data.title,
      videoPoints: data.videoPoints,
      videoStatus: parseInt(data.videoStatus),
      inheritPrice: data.inheritPrice,
      isSequence: data.isSequence,
      sequenceNo: data.sequenceNo,
      privateVideo: data.privateVideo,
      videoCost: parseInt(data.videoCost) ?? 0,
      redirectToLoginUrlEnabled: data.redirectToLoginUrlEnabled,
      thumbnailUrl: data.thumbnailUrl,
      isCaptionEnabled: data.isCaptionEnabled,
      displayDate: finalDate,
      displayTime: data.displayTime,
      videoType: data.videoType,
      categories: finalCategories,
      speakerIds: finalSpeakers,
      tags: finalTags,
      description: ImageStringConverter(editorState),
      channelId: data.channelId.value,
      contentTypes: data.contentTypes.value ? [data.contentTypes.value] : [],
      modules: data.modules.value ? [data.modules.value] : []
    };
    videoActions.editVideoRequest(finalData);
  };

  useEffect(() => {
    if (memberId) {
      setValue('speakerIds', [...getValues('speakerIds'), { value: memberId, label: speakerName }]);
      const speakerId = roles && roles.filter((role) => role.name.toUpperCase() === 'SPEAKER');
      if (speakerId && speakerId.length) {
        videoActions.getSpeakersRequest(speakerId[0]?.id);
      }
    }
  }, [memberId, getValues]);
  useEffect(() => {
    if (categoriesList && Object.keys(categoriesList).length) {
      const finalData = categoriesList.contenttypes.map((contentType) => {
        return { value: contentType.id, label: contentType.contentType };
      });
      const finalContentType = [{ value: '', label: 'Select...' }, ...finalData];
      setFinalContent(finalContentType);
    }
  }, [categoriesList]);

  useEffect(() => {
    if (modulesList && Object.keys(modulesList).length) {
      const finalData = modulesList.modules.map((videoModule) => {
        return { value: videoModule.id, label: videoModule.module };
      });
      const finalVideoModule = [{ value: '', label: 'Select...' }, ...finalData];
      setFinalModule(finalVideoModule);
    }
  }, [modulesList]);

  useEffect(() => {
    if (educationPoints && Object.keys(educationPoints).length) {
      const finalData =
        educationPoints &&
        educationPoints.map((item) => {
          return { value: item.id, label: item.title };
        });
      const finalEducationPoints = [{ value: '', label: 'Select...' }, ...finalData];
      setFinalEducationPoints(finalEducationPoints);
    }
  }, [educationPoints]);

  useEffect(() => {
    if (Object.keys(singleVideo).length) {
      videoActions.getMediaUrlRequest(singleVideo.description);
    }
  }, [singleVideo]);

  useEffect(() => {
    if (mediaUrl) {
      setEditorState(mediaUrl ? mediaUrl : '');
    }
  }, [mediaUrl]);
  useEffect(() => {
    if (singleVideo) {
      const selectedChannel =
        channelList && channelList.filter((item: any) => item.id === singleVideo.channelId);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].name : ''
        };
        setDefaultChannel(dataRef);
      }
      singleVideo.description &&
        setEditorState(singleVideo.description ? singleVideo.description : '');
      let tagsData;
      if (singleVideo.tags) {
        tagsData = singleVideo.tags.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      let categoryData;
      if (singleVideo.categories) {
        categoryData = singleVideo.categories.map((item) => {
          return {
            value: item.id,
            label: item.name
          };
        });
      }
      let displayDate;
      if (singleVideo.displayDate) {
        displayDate = moment(singleVideo.displayDate, singleVideo.defaultDateFormat.toUpperCase());
      }

      const newData = {
        ...singleVideo,
        channelId: dataRef,
        tags: tagsData,
        categories: categoryData,
        displayDate: displayDate,
        duration: singleVideo.duration,
        contentTypes: singleVideo.contentTypes && {
          value: singleVideo.contentTypes[0]?.id ? singleVideo.contentTypes[0]?.id : '',
          label: singleVideo.contentTypes[0]?.name ? singleVideo.contentTypes[0]?.name : 'Select...'
        },
        modules: singleVideo.modules && {
          value: singleVideo.modules[0]?.id ? singleVideo.modules[0]?.id : '',
          label: singleVideo.modules[0]?.name ? singleVideo.modules[0]?.name : 'Select...'
        },
        pointAllocation: {
          value: singleVideo.pointAllocationId ? singleVideo.pointAllocationId : '',
          label: singleVideo.pointAllocation ? singleVideo.pointAllocation : 'Select...'
        }
      };
      reset(newData);
    }
  }, [singleVideo, channelList]);

  useEffect(() => {
    if (!channelList.length) {
      channelAction.getChannelListRequest();
    }
    if (!roles.length) {
      userActions.getRolesRequest();
    }
    if (!educationPoints.length) {
      settingAction.getEducationPointsRequest();
    }
    categoryAction.getCategoriesListRequest();
    categoryAction.getModulesListRequest();
  }, []);
  useEffect(() => {
    if (roles.length && !speakers.length) {
      const speakerId = roles.filter((role) => role.name.toUpperCase() === 'SPEAKER');
      if (speakerId && speakerId.length) {
        videoActions.getSpeakersRequest(speakerId[0]?.id);
      }
    }
  }, [roles]);
  const handleAnalyticsClick = () => {
    navigate(`/videos/analytics/${id}`);
  };
  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);

  useEffect(() => {
    if (id) {
      videoActions.getSingleVideosRequest(id);
    }
  }, [id]);

  useEffect(() => {
    if (isVideoEdited || isThumbnailUpdated) {
      if (id) {
        videoActions.getSingleVideosRequest(id);
      }
    }
  }, [isVideoEdited, isThumbnailUpdated]);

  useEffect(() => {
    if (isRedirectToLoginSaved) {
      videoActions.resetRedirectToLoginRequest();
    }
  }, [isRedirectToLoginSaved]);

  useEffect(() => {
    if (isAuthKeySaved && id) {
      videoActions.getSingleVideosRequest(id);
      videoActions.resetAuthKeyRequest();
    }
  }, [isAuthKeySaved]);
  const handleVideoThumbnailUpload = async () => {
    if (selectedFile) {
      uploadImageToS3(selectedFile[0], `v2/video/upload/thumbnail?videoId=${id}&`);
    }
  };

  useEffect(() => {
    if (speakers) {
      const finalspeaker = speakers.map((speaker) => {
        return { value: speaker.id, label: `${speaker.firstName} ${speaker.lastName}` };
      });
      setFinalSpeaker(finalspeaker);
    }
  }, [speakers]);

  useEffect(() => {
    if (clipboard) {
      successMsg('Copied To Clipboard');
      setClipboard(false);
    }
  }, [clipboard]);

  useEffect(() => {
    if (isEditVideoError) {
      errorMsg('Error');
      videoActions.resetVideoLibraryRequest();
    }
  }, [isEditVideoError]);

  const handleSelectNewClick = () => {
    InputFile.current.click();
  };
  const sequenceFlag = watch('isSequence');
  const inheritPrice = watch('inheritPrice');

  const handleVideoInfoClick = () => {
    if (id) {
      videoActions.getSingleVideosRequest(id);
    }
  };

  const handleInteractivityClick = () => {
    if (id) {
      videoActions.getInterActivityRequest(id);
      videoActions.getPollsTemplatesRequest();
      videoActions.getPollRequest(id);
    }
  };

  const handleResourcesClick = () => {
    if (id) {
      const payloadData = { id, pageNumber: 1 };
      videoActions.getResourcesRequest(payloadData);
    }
  };

  const handlePreviewClick = () => {
    if (id) {
      videoActions.getVideoMediaRequest(id);
    }
  };
  const handleAccessibilityClick = () => {
    if (id) {
      videoActions.getAccessibilityRequest(id);
    }
  };

  const handlePlayPageClick = () => {
    if (id) {
      videoActions.getPlayPageRequestRequest(id);
    }
  };
  const handleAccessClick = () => {
    if (id) {
      const payloadData = { id, pageNumber: 1, pageSize: 10 };
      userAction.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
      userAction.getMembersRequest({ pageSize: 9999 });
      videoActions.getMemberListAccessRequestRequest(id);
      videoActions.getMemberListPriceRequestRequest(id);
      videoActions.getModeratorRequest(id);

      videoActions.getDiscountCodeRequest(payloadData);
    }
  };

  const handleMediaInfoClick = () => {
    if (id) {
      videoActions.getVideoMediaRequest(id);
    }
  };
  const handleRedirect = (channelId: string) => {
    navigate(`/channels/${channelId}`);
  };

  useEffect(() => {
    if (singleVideo && Object.keys(singleVideo).length) {
      channelAction.getSingleChannelRequest(singleVideo.channelId);
    }
  }, [singleVideo]);

  useEffect(() => {
    if (
      singleVideo &&
      Object.keys(singleVideo).length &&
      singleChannel &&
      Object.keys(singleChannel)
    ) {
      singleVideo.inheritPrice && setValue('videoType', SelectVideoType(singleChannel.priceType));
      singleVideo.inheritPrice &&
        setValue(
          'videoCost',
          singleChannel.videoCost < 0 || singleChannel.videoCost == null
            ? 0
            : singleChannel.videoCost
        );
    }
  }, [singleVideo, singleChannel]);

  const SelectVideoType = (value: number) => {
    if (value === 1) {
      return 'Paid';
    } else if (value === 2) {
      return 'Free';
    } else if (value === 3) {
      return 'Locked';
    }
  };

  useEffect(() => {
    if (inheritPrice) {
      setValue(
        'videoCost',
        singleChannel && (singleChannel.videoCost < 0 || singleChannel.videoCost == null)
          ? 0
          : singleChannel.videoCost
      );
    } else {
      setValue('videoCost', singleVideo && singleVideo.videoCost);
    }
  }, [inheritPrice]);

  return (
    <>
      <ToastMessage />
      <div className="videos-view">
        {singleVideo && (
          <div
            className="mb-2 cursor-pointer p-1"
            onClick={() => handleRedirect(singleVideo.channelId)}>
            <i className="fas fa-arrow-left"></i>
            <span className="ml-2">Go Back</span>
          </div>
        )}
        <div className="pl-0">
          <div className="lg:flex videoEdit-tabs">
            <div
              className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
                showVideoInfo ? 'hide-lists' : 'show-lists'
              }`}>
              <div className="mobile-dropdown" onClick={() => setShowVideoInfo(!showVideoInfo)}>
                <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
                  <p>Video Information</p>
                  <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
                </div>
              </div>
              <ul
                className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-auto mobile-layout-ul"
                id="tabs-tabVertical"
                role="tablist">
                <li
                  className="nav-item flex-grow"
                  role="presentation"
                  onClick={handleVideoInfoClick}>
                  <a
                    href="#tabs-videoInfoView"
                    className="nav-link my-0 tabs-link pl-3 flex items-center active"
                    id="tabs-videoInfo-view"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-videoInfoView"
                    role="tab"
                    aria-controls="tabs-videoInfoView"
                    aria-selected="false">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_777_82771"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="3"
                        width="20"
                        height="14">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M19.5833 5.08337C19.3333 4.91671 19 5.00004 18.75 5.16671L14.1667 8.41671V5.83337C14.1667 4.41671 13.0833 3.33337 11.6667 3.33337H2.5C1.08333 3.33337 0 4.41671 0 5.83337V14.1667C0 15.5834 1.08333 16.6667 2.5 16.6667H11.6667C13.0833 16.6667 14.1667 15.5834 14.1667 14.1667V11.5834L18.6667 14.8334C18.8333 14.9167 19 15 19.1667 15C19.3333 15 19.4167 15 19.5833 14.9167C19.8333 14.75 20 14.5 20 14.1667V5.83337C20 5.50004 19.8333 5.25004 19.5833 5.08337ZM12.5 14.1667C12.5 14.6667 12.1667 15 11.6667 15H2.5C2 15 1.66667 14.6667 1.66667 14.1667V5.83337C1.66667 5.33337 2 5.00004 2.5 5.00004H11.6667C12.1667 5.00004 12.5 5.33337 12.5 5.83337V14.1667ZM14.75 10L18.3333 12.5834V7.41671L14.75 10Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_777_82771)">
                        <rect width="20" height="20" fill="currentcolor" />
                      </g>
                    </svg>
                    Content Info
                  </a>
                </li>
                {singleVideo && singleVideo.videoContentType !== 1 && (
                  <li
                    onClick={handleInteractivityClick}
                    className="nav-item flex-grow"
                    role="presentation">
                    <a
                      href="#tabs-interactivityView"
                      className="
nav-link
my-0 tabs-link pl-3 flex items-center
"
                      id="tabs-interactivity-view"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-interactivityView"
                      role="tab"
                      aria-controls="tabs-interactivityView"
                      aria-selected="false">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_777_82921"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="2"
                          width="20"
                          height="16">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 2.5C16.263 2.5 19.7704 9.33333 19.9374 9.66667C20.0209 9.83333 20.0209 10.1667 19.9374 10.3333C19.7704 10.6667 16.263 17.5 10 17.5C4.10489 17.5 0.651244 11.446 0.126531 10.5262L0.12652 10.5261C0.0937772 10.4688 0.072441 10.4314 0.0626305 10.4167C-0.0208768 10.1667 -0.0208768 9.91667 0.0626305 9.66667C0.229645 9.33333 3.73695 2.5 10 2.5ZM1.73278 10C2.56785 11.3333 5.49061 15.8333 10 15.8333C14.5094 15.8333 17.4321 11.3333 18.2672 10C17.4321 8.66667 14.5094 4.16667 10 4.16667C5.49061 4.16667 2.48434 8.66667 1.73278 10ZM10 6.66667C8.16284 6.66667 6.65971 8.16667 6.65971 10C6.65971 11.8333 8.16284 13.3333 10 13.3333C11.8372 13.3333 13.3403 11.8333 13.3403 10C13.3403 8.16667 11.8372 6.66667 10 6.66667ZM8.32985 10C8.32985 10.9167 9.08142 11.6667 10 11.6667C10.9186 11.6667 11.6701 10.9167 11.6701 10C11.6701 9.08333 10.9186 8.33333 10 8.33333C9.08142 8.33333 8.32985 9.08333 8.32985 10Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_777_82921)">
                          <rect width="20" height="20" fill="currentcolor" />
                        </g>
                      </svg>
                      Interactivity
                    </a>
                  </li>
                )}

                <li
                  onClick={handleResourcesClick}
                  className="nav-item flex-grow"
                  role="presentation">
                  <a
                    href="#tabs-resourcesView"
                    className="
nav-link
my-0 tabs-link pl-3 flex items-center

"
                    id="tabs-resources-view"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-resourcesView"
                    role="tab"
                    aria-controls="tabs-resourcesView"
                    aria-selected="true">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_1189_92756"
                        maskUnits="userSpaceOnUse"
                        x="2"
                        y="0"
                        width="16"
                        height="20">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.4167 7.16683C17.375 7.12516 17.3542 7.0835 17.3333 7.04183C17.3125 7.00016 17.2917 6.9585 17.25 6.91683L11.4167 1.0835C11.3333 1.00016 11.25 0.916829 11.1667 0.916829C11.0833 0.833496 10.9167 0.833496 10.8333 0.833496H5C3.58333 0.833496 2.5 1.91683 2.5 3.3335V16.6668C2.5 18.0835 3.58333 19.1668 5 19.1668H15C16.4167 19.1668 17.5 18.0835 17.5 16.6668V7.50016C17.5 7.41683 17.5 7.25016 17.4167 7.16683ZM11.6667 3.66683L14.6667 6.66683H11.6667V3.66683ZM5 17.5002H15C15.5 17.5002 15.8333 17.1668 15.8333 16.6668V8.3335H10.8333C10.3333 8.3335 10 8.00016 10 7.50016V2.50016H5C4.5 2.50016 4.16667 2.8335 4.16667 3.3335V16.6668C4.16667 17.1668 4.5 17.5002 5 17.5002Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1189_92756)">
                        <rect width="20" height="20" fill="currentcolor" />
                      </g>
                    </svg>
                    Resources
                  </a>
                </li>
                {singleVideo && singleVideo.videoContentType !== 1 && (
                  <li
                    onClick={handleAccessibilityClick}
                    className="nav-item flex-grow"
                    role="presentation">
                    <a
                      href="#tabs-accessibilityView"
                      className="
nav-link
my-0 tabs-link pl-3 flex items-center

"
                      id="tabs-accessibility-view"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-accessibilityView"
                      role="tab"
                      aria-controls="tabs-accessibilityView"
                      aria-selected="true">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_1189_92810"
                          maskUnits="userSpaceOnUse"
                          x="1"
                          y="0"
                          width="18"
                          height="20">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.6665 8.33333C1.6665 3.75 5.4165 0 9.99984 0C14.5832 0 18.3332 3.75 18.3332 8.33333C18.3332 14.5 10.7498 19.6667 10.4998 19.8333C10.3332 19.9167 10.1665 20 9.99984 20C9.83317 20 9.6665 19.9167 9.49984 19.8333C9.24984 19.6667 1.6665 14.5 1.6665 8.33333ZM3.33317 8.33333C3.33317 12.8333 8.4165 17 9.99984 18.1667C11.5832 17 16.6665 12.8333 16.6665 8.33333C16.6665 4.66667 13.6665 1.66667 9.99984 1.66667C6.33317 1.66667 3.33317 4.66667 3.33317 8.33333ZM9.99984 5C8.1665 5 6.6665 6.5 6.6665 8.33333C6.6665 10.1667 8.1665 11.6667 9.99984 11.6667C11.8332 11.6667 13.3332 10.1667 13.3332 8.33333C13.3332 6.5 11.8332 5 9.99984 5ZM8.33317 8.33333C8.33317 9.25 9.08317 10 9.99984 10C10.9165 10 11.6665 9.25 11.6665 8.33333C11.6665 7.41667 10.9165 6.66667 9.99984 6.66667C9.08317 6.66667 8.33317 7.41667 8.33317 8.33333Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_1189_92810)">
                          <rect width="20" height="20" fill="currentcolor" />
                        </g>
                      </svg>
                      Accessibility
                    </a>
                  </li>
                )}
                {singleVideo && singleVideo.videoContentType !== 1 && (
                  <li
                    onClick={handlePlayPageClick}
                    className="nav-item flex-grow"
                    role="presentation">
                    <a
                      href="#tabs-playpageView"
                      className="
nav-link
my-0 tabs-link pl-3 flex items-center

"
                      id="tabs-playpage-view"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-playpageView"
                      role="tab"
                      aria-controls="tabs-playpageView"
                      aria-selected="true">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_777_82873"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="20">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.833374 10C0.833374 4.91671 4.91671 0.833374 10 0.833374C15.0834 0.833374 19.1667 4.91671 19.1667 10C19.1667 15.0834 15.0834 19.1667 10 19.1667C4.91671 19.1667 0.833374 15.0834 0.833374 10ZM2.50004 10C2.50004 14.1667 5.83337 17.5 10 17.5C14.1667 17.5 17.5 14.1667 17.5 10C17.5 5.83337 14.1667 2.50004 10 2.50004C5.83337 2.50004 2.50004 5.83337 2.50004 10ZM13.8334 9.33337L8.83337 6.00004C8.58337 5.83337 8.25004 5.83337 8.00004 6.00004C7.66671 6.08337 7.50004 6.33337 7.50004 6.66671V13.3334C7.50004 13.6667 7.66671 13.9167 7.91671 14.0834C8.08337 14.1667 8.16671 14.1667 8.33337 14.1667C8.50004 14.1667 8.66671 14.0834 8.83337 14L13.8334 10.6667C14.0834 10.5 14.1667 10.25 14.1667 10C14.1667 9.75004 14 9.50004 13.8334 9.33337ZM11.8334 10L9.16671 8.25004V11.75L11.8334 10Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_777_82873)">
                          <rect width="20" height="20" fill="currentcolor" />
                        </g>
                      </svg>
                      Play Page
                    </a>
                  </li>
                )}

                <li onClick={handleAccessClick} className="nav-item flex-grow" role="presentation">
                  <a
                    href="#tabs-accessView"
                    className="
nav-link
my-0 tabs-link pl-3 flex items-center

"
                    id="tabs-access-view"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-accessView"
                    role="tab"
                    aria-controls="tabs-accessView"
                    aria-selected="true">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_777_82749"
                        maskUnits="userSpaceOnUse"
                        x="4"
                        y="0"
                        width="12"
                        height="20">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.0833 9.16667H10.8333V5H14.1666C14.6666 5 15 4.66667 15 4.16667C15 3.66667 14.6666 3.33333 14.1666 3.33333H10.8333V0.833333C10.8333 0.333333 10.5 0 9.99996 0C9.49996 0 9.16663 0.333333 9.16663 0.833333V3.33333H7.91663C5.83329 3.33333 4.16663 5 4.16663 7.08333C4.16663 9.16667 5.83329 10.8333 7.91663 10.8333H9.16663V15H4.99996C4.49996 15 4.16663 15.3333 4.16663 15.8333C4.16663 16.3333 4.49996 16.6667 4.99996 16.6667H9.16663V19.1667C9.16663 19.6667 9.49996 20 9.99996 20C10.5 20 10.8333 19.6667 10.8333 19.1667V16.6667H12.0833C14.1666 16.6667 15.8333 15 15.8333 12.9167C15.8333 10.8333 14.1666 9.16667 12.0833 9.16667ZM7.91663 9.16667C6.74996 9.16667 5.83329 8.25 5.83329 7.08333C5.83329 5.91667 6.74996 5 7.91663 5H9.16663V9.16667H7.91663ZM10.8333 15H12.0833C13.25 15 14.1666 14.0833 14.1666 12.9167C14.1666 11.75 13.25 10.8333 12.0833 10.8333H10.8333V15Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_777_82749)">
                        <rect width="20" height="20" fill="currentcolor" />
                      </g>
                    </svg>
                    Access/Pricing
                  </a>
                </li>

                {singleVideo && singleVideo.videoContentType !== 1 && (
                  <li
                    className="nav-item flex-grow"
                    role="presentation"
                    onClick={handleAnalyticsClick}>
                    <a
                      href="#tabs-analyticsView"
                      className="nav-link my-0 tabs-link pl-3 flex items-center"
                      id="tabs-analytics-view"
                      data-bs-toggle="pill"
                      data-bs-target="#tabs-analyticsView"
                      role="tab"
                      aria-controls="tabs-analyticsView"
                      aria-selected="true">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        className="w-4 h-4 mr-2"
                        xmlns="http://www.w3.org/2000/svg">
                        <mask
                          id="mask0_820_82950"
                          maskUnits="userSpaceOnUse"
                          x="4"
                          y="2"
                          width="12"
                          height="16">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 2.55664C14.5 2.55664 14.1666 2.88997 14.1666 3.38997V16.7233C14.1666 17.2233 14.5 17.5566 15 17.5566C15.5 17.5566 15.8333 17.2233 15.8333 16.7233V3.38997C15.8333 2.88997 15.5 2.55664 15 2.55664ZM9.99996 7.55664C10.5 7.55664 10.8333 7.88997 10.8333 8.38997V16.7233C10.8333 17.2233 10.5 17.5566 9.99996 17.5566C9.49996 17.5566 9.16663 17.2233 9.16663 16.7233V8.38997C9.16663 7.88997 9.49996 7.55664 9.99996 7.55664ZM4.16663 13.39C4.16663 12.89 4.49996 12.5566 4.99996 12.5566C5.49996 12.5566 5.83329 12.89 5.83329 13.39V16.7233C5.83329 17.2233 5.49996 17.5566 4.99996 17.5566C4.49996 17.5566 4.16663 17.2233 4.16663 16.7233V13.39Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_820_82950)">
                          <rect y="0.0566406" width="20" height="20" fill="currentcolor" />
                        </g>
                      </svg>
                      Analytics
                    </a>
                  </li>
                )}
                <li
                  onClick={handleMediaInfoClick}
                  className="nav-item flex-grow"
                  role="presentation">
                  <a
                    href="#tabs-mediainfoView"
                    className="
nav-link
my-0 tabs-link pl-3 flex items-center

"
                    id="tabs-mediainfo-view"
                    data-bs-toggle="pill"
                    data-bs-target="#tabs-mediainfoView"
                    role="tab"
                    aria-controls="tabs-mediainfoView"
                    aria-selected="true">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_777_82897"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="1"
                        width="18"
                        height="18">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18 1.83329C17.8333 1.66663 17.5833 1.66663 17.3333 1.66663L7.33329 3.33329C6.99996 3.41663 6.66663 3.74996 6.66663 4.16663V13.3333H4.16663C2.74996 13.3333 1.66663 14.4166 1.66663 15.8333C1.66663 17.25 2.74996 18.3333 4.16663 18.3333H5.83329C7.24996 18.3333 8.33329 17.25 8.33329 15.8333V14.1666V4.83329L16.6666 3.41663V11.6666H14.1666C12.75 11.6666 11.6666 12.75 11.6666 14.1666C11.6666 15.5833 12.75 16.6666 14.1666 16.6666H15.8333C17.25 16.6666 18.3333 15.5833 18.3333 14.1666V12.5V2.49996C18.3333 2.24996 18.25 1.99996 18 1.83329ZM6.66663 15.8333C6.66663 16.3333 6.33329 16.6666 5.83329 16.6666H4.16663C3.66663 16.6666 3.33329 16.3333 3.33329 15.8333C3.33329 15.3333 3.66663 15 4.16663 15H6.66663V15.8333ZM15.8333 15C16.3333 15 16.6666 14.6666 16.6666 14.1666V13.3333H14.1666C13.6666 13.3333 13.3333 13.6666 13.3333 14.1666C13.3333 14.6666 13.6666 15 14.1666 15H15.8333Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_777_82897)">
                        <rect width="20" height="20" fill="currentcolor" />
                      </g>
                    </svg>
                    Media Info
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content lg:w-4/5 shadow border">
              <div
                className="tab-pane fade show active"
                id="tabs-videoInfoView"
                role="tabpanel"
                aria-labelledby="tabs-videoInfo-view">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex items-center justify-end ml-auto border-b py-1">
                    {singleVideo && singleVideo.videoContentType === 1 ? (
                      <button
                        type="button"
                        className="btn-primary w-auto mt-0 mr-2 ml-0 borderonly-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#pdfViewer"
                        onClick={() => handlePreviewClick()}>
                        Preview
                      </button>
                    ) : (
                      <Link
                        to={`/videos/analytics/${id}`}
                        className="btn-primary w-auto mt-0 mr-2 ml-0 borderonly-btn">
                        Analytics
                      </Link>
                    )}

                    <button type="button" className="btn-primary w-auto mt-0 mr-2 ml-0 discard-btn">
                      Cancel
                    </button>
                    <button type="submit" className="btn-primary w-auto mt-0 mr-2 ml-0">
                      Save
                    </button>
                    <span className="pr-2" data-bs-toggle="modal" data-bs-target="#ShareVideoModal">
                      <img
                        src="../../assets/img/icons/share.svg"
                        className="cursor-pointer w-5 h-5"
                      />
                    </span>
                  </div>
                  {(isVideoEditedLoading || isSingleVideoLoading) && <Loader />}
                  <>
                    {' '}
                    {singleVideo && (
                      <>
                        <div className="content-header">
                          <div className="edit-options lg:flex">
                            <div className="lg:w-1/2 p-4 pb-0">
                              <div className={`form-group mb-4`}>
                                <FormInputField
                                  label={'Title'}
                                  type={'text'}
                                  fieldName={'title'}
                                  register={register}
                                  errors={errors}
                                  isRequired={true}
                                />
                              </div>
                              <div className={`form-group mb-4`}>
                                <label className="block text-sm font-medium mb-1">Channel</label>
                                {defaultChannel && Object.keys(defaultChannel).length && (
                                  <Controller
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                      fieldState: { error }
                                    }) => (
                                      <Select
                                        options={finalChannels}
                                        value={value}
                                        filterOption={customFilterOption}
                                        id="channelId"
                                        onChange={(val) => onChange(val)}
                                      />
                                    )}
                                    name="channelId"
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue={defaultChannel && defaultChannel}
                                  />
                                )}
                              </div>

                              <div className="lg:grid lg:grid-cols-3 gap-4">
                                <div className="form-group mb-4 w-full">
                                  <label className="block text-sm font-medium mb-1">
                                    Type <span className="text-red-500">*</span>
                                  </label>
                                  <select
                                    disabled={inheritPrice ? true : false}
                                    className="border input-style"
                                    id="videoType"
                                    {...register('videoType')}>
                                    <option value="">Select</option>
                                    <option value="Free">Free</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Locked">Locked</option>
                                  </select>
                                </div>
                                <div className="flex items-center">
                                  <div className={`form-group mb-4 w-full`}>
                                    <label className="block text-sm font-medium mb-1">Price</label>

                                    <input
                                      disabled={inheritPrice ? true : false}
                                      className="input-style"
                                      type="text"
                                      id="videoCost"
                                      {...register('videoCost')}
                                    />
                                  </div>
                                </div>
                                <div className="flex mt-3 ml-3 w-56 items-center">
                                  <div className="form-check form-switch pl-0 relative">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="inheritPrice"
                                      {...register('inheritPrice', {
                                        onChange: (e) => {
                                          e.target.value &&
                                            setValue(
                                              'videoType',
                                              SelectVideoType(singleChannel.priceType)
                                            );
                                        }
                                      })}
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                  <label
                                    className="form-check-label inline-block ml-1 whitespace-nowrap"
                                    htmlFor="Inherit Type">
                                    Inherit
                                  </label>
                                </div>
                              </div>

                              <div className={`form-group mb-4`}>
                                <label className="block text-sm font-medium mb-1">Tags</label>

                                {categoriesList && (
                                  <Controller
                                    render={({ field: { onChange, value } }) => (
                                      <Select
                                        options={
                                          categoriesList &&
                                          categoriesList.tags?.map((videoTag) => {
                                            return { value: videoTag.id, label: videoTag.tag };
                                          })
                                        }
                                        value={value}
                                        id="tags"
                                        filterOption={customFilterOption}
                                        onChange={(val) => onChange(val)}
                                        isMulti
                                        isLoading={
                                          Object.keys(categoriesList).length ? false : true
                                        }
                                      />
                                    )}
                                    name="tags"
                                    control={control}
                                  />
                                )}
                              </div>
                              <div className={`form-group mb-4`}>
                                <label className="block text-sm font-medium mb-1">Category</label>

                                {categoriesList && (
                                  <Controller
                                    render={({ field: { onChange, value } }) => (
                                      <Select
                                        options={
                                          categoriesList &&
                                          categoriesList.categories?.map((category) => {
                                            return {
                                              value: category.id,
                                              label: category.category
                                            };
                                          })
                                        }
                                        value={value}
                                        filterOption={customFilterOption}
                                        id="categories"
                                        onChange={(val) => onChange(val)}
                                        isMulti
                                        isLoading={
                                          Object.keys(categoriesList).length ? false : true
                                        }
                                      />
                                    )}
                                    name="categories"
                                    control={control}
                                  />
                                )}
                              </div>

                              <div className="lg:grid lg:grid-cols-2 gap-4">
                                <div className="form-group mb-4">
                                  <label className="block text-sm font-medium mb-1">
                                    Content Type
                                  </label>
                                  {categoriesList && (
                                    <Controller
                                      render={({ field: { onChange, value } }) => (
                                        <Select
                                          options={finalContent}
                                          value={value}
                                          filterOption={customFilterOption}
                                          id="contentTypes"
                                          onChange={(val) => onChange(val)}
                                          isLoading={
                                            Object.keys(categoriesList).length ? false : true
                                          }
                                        />
                                      )}
                                      name="contentTypes"
                                      control={control}
                                    />
                                  )}
                                </div>
                                <div className="form-group mb-4">
                                  <label className="block text-sm font-medium mb-1">Module</label>
                                  {modulesList && (
                                    <Controller
                                      render={({ field: { onChange, value } }) => (
                                        <Select
                                          options={finalModule}
                                          value={value}
                                          filterOption={customFilterOption}
                                          id="modules"
                                          onChange={(val) => onChange(val)}
                                          isLoading={Object.keys(modulesList).length ? false : true}
                                        />
                                      )}
                                      name="modules"
                                      control={control}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="lg:grid lg:grid-cols-2 gap-4">
                                <div className={`form-group mb-4`}>
                                  <FormInputField
                                    label={'Credits'}
                                    type={'text'}
                                    fieldName={'videoPoints'}
                                    register={register}
                                    errors={errors}
                                    isRequired={false}
                                  />
                                </div>
                                <div className={`form-group mb-4`}>
                                  <label className="block mb-1"> CE Activity Template </label>
                                  {educationPoints && (
                                    <Controller
                                      render={({ field: { onChange, value } }) => (
                                        <Select
                                          options={finaleducationPoints}
                                          filterOption={customFilterOption}
                                          value={value}
                                          className="w-full mr-2"
                                          id="pointAllocation"
                                          onChange={(val) => onChange(val)}
                                          isLoading={educationPoints.length ? false : true}
                                        />
                                      )}
                                      name="pointAllocation"
                                      control={control}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className={`form-group mb-4`}>
                                <label className="block mb-1"> Speakers </label>

                                <div className=" flex items-center">
                                  {speakers && (
                                    <Controller
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                        fieldState: { error }
                                      }) => (
                                        <Select
                                          options={finalSpeaker}
                                          value={value}
                                          className="w-full mr-2"
                                          filterOption={customFilterOption}
                                          id="speakerIds"
                                          isMulti
                                          onChange={(val) => onChange(val)}
                                          isLoading={speakers.length ? false : true}
                                        />
                                      )}
                                      name="speakerIds"
                                      control={control}
                                    />
                                  )}

                                  <button
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#AddSpeakerModal"
                                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                                    <i className="fas fa-plus pr-1"></i> Add
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="lg:w-1/2 p-4 pb-0">
                              <div className="lg:grid lg:grid-cols-2 gap-4">
                                <div className={`form-group mb-4`}>
                                  <div className="form-group mb-4">
                                    <label className="block text-sm font-medium mb-1">
                                      Status <span className="text-red-500">*</span>
                                    </label>
                                    <select
                                      className="border input-style"
                                      id="videoStatus"
                                      {...register('videoStatus')}>
                                      <option value="">Select</option>
                                      <option value={0}>Standby</option>
                                      <option value={1}>Live</option>
                                      <option value={2}>OnDemand</option>
                                      <option value={3}>Stopped</option>
                                      <option value={4}>Processing</option>
                                      <option value={5}>Offline</option>
                                      {singleVideo.videoStatus === 6 && (
                                        <option value={6}>Green Room</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="flex mt-3 ml-3 w-56 items-center mb-5">
                                  <div className="form-check form-switch pl-0 relative">
                                    <input
                                      className="form-check-input switch-btn"
                                      type="checkbox"
                                      role="switch"
                                      id="privateVideo"
                                      {...register('privateVideo')}
                                    />
                                    <i className="fas fa-check checkbox-tick hidden"></i>
                                    <i className="fas fa-times checkbox-cross"></i>
                                  </div>
                                  <label
                                    className="form-check-label inline-block ml-1 whitespace-nowrap"
                                    htmlFor="Credit">
                                    Private Video
                                  </label>
                                </div>
                              </div>

                              <div className={`form-group mb-4 thumbnail-main`}>
                                <label className="mb-1 font-semibold"> Thumbnail </label>
                                <div className="relative thumb-wrap">
                                  <img src={`${singleVideo.thumbnailUrl}`} />
                                  <div className="flex flex-wrap justify-center items-center pb-4 rounded-b-md absolute inset-0 btns-main">
                                    <div className="video-upload-btn mr-2">
                                      <input
                                        ref={InputFile}
                                        type="file"
                                        className="hidden"
                                        onChange={(e) => setSelectedFile(e.target.files)}
                                      />
                                      <label
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectThumbnailModal"
                                        className="btn-primary cursor-pointer mx-0 mt-0">
                                        Select New
                                      </label>
                                    </div>

                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#uploadVideoModal"
                                      type="button"
                                      className="mx-0 px-6 btn-primary w-28 mt-0 mr-2">
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="lg:grid lg:grid-cols-2 gap-4">
                                <div className={`form-group mb-4`}>
                                  <label className="block text-sm font-medium mb-1">
                                    Date
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <Controller
                                    name={'displayDate'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                      return (
                                        <div className="relative">
                                          <DatePicker
                                            placeholderText={singleVideo.defaultDateFormat?.toUpperCase()}
                                            selected={value && new Date(Date.parse(value))}
                                            dateFormat={singleVideo.defaultDateFormat}
                                            className="input-style"
                                            onChange={(val: any) => onChange(val)}
                                            ref={(r: any) => (datePickerRef = r)}
                                          />
                                          <i
                                            onClick={() => {
                                              if (datePickerRef) {
                                                if (!datePickerRef.state.open) {
                                                  datePickerRef?.setOpen(true);
                                                }
                                              }
                                            }}
                                            className="fas fa-calendar-week absolute top-3 right-3"
                                          />
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                                <div className={`form-group mb-4`}>
                                  <FormInputField
                                    label={'Time'}
                                    type={'time'}
                                    fieldName={'displayTime'}
                                    register={register}
                                    errors={errors}
                                    isRequired={false}
                                  />
                                </div>
                              </div>
                              <div className="flex form-group mb-4">
                                <div className="form-check form-switch pl-0 mr-3 relative">
                                  <FormInputField
                                    label={'Duration'}
                                    type={'text'}
                                    fieldName={'duration'}
                                    register={register}
                                    errors={errors}
                                    isRequired={false}
                                    disable={true}
                                  />
                                </div>

                                <div className="flex">
                                  <div className="flex items-center mt-4 w-48">
                                    <label
                                      className="form-check-label inline-block mr-2"
                                      htmlFor="Credit">
                                      Sequence
                                    </label>
                                    <div className="form-check form-switch pl-0 mr-3 relative">
                                      <input
                                        className="form-check-input switch-btn"
                                        type="checkbox"
                                        role="switch"
                                        id="isSequence"
                                        {...register('isSequence')}
                                      />
                                      <i className="fas fa-check checkbox-tick hidden"></i>
                                      <i className="fas fa-times checkbox-cross"></i>
                                    </div>
                                  </div>
                                  {sequenceFlag && (
                                    <div className={`form-group mb-4 w-full`}>
                                      <FormInputField
                                        label={'Number'}
                                        type={'number'}
                                        fieldName={'sequenceNo'}
                                        register={register}
                                        errors={errors}
                                        isRequired={false}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-5">
                          <div className={`form-group mb-4`}>
                            <label className="block text-sm font-medium mb-1">
                              Video Description
                            </label>
                            <Editor editorState={editorState} setEditorState={setEditorState} />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </form>
              </div>
              {id && (
                <>
                  <InterActivity videoId={id} isSavedLoading={isSavedLoading} />
                  <Resources videoId={id} />

                  <Accessibility videoId={id} isSavedLoading={isSavedLoading} />
                  <PlayPage videoId={id} isSavedLoading={isSavedLoading} />
                  <AccessPrice videoId={id} />
                  <Analytics />
                  <MediaInfo videoId={id} />
                  <VideoInfoUploadModal videoId={id} />
                  <SelectNewThumbModal singleVideo={singleVideo} />
                  <ShareVideoModal
                    singleVideo={singleVideo}
                    videoId={id}
                    setClipboard={setClipboard}
                  />
                  <QuestionsModal videoId={id} />
                </>
              )}
            </div>
          </div>
        </div>

        {/* Add new Speaker Modal */}
        <AddSpeaker setSelectedSpeakerName={setSelectedSpeakerName} />
        <PDFViewerModal />
      </div>
    </>
  );
};

export default Videos;
