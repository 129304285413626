import React, { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import PieChart from 'src/components/charts/piechart/PieChart';
import RadarChart from 'src/components/charts/radarchart/RadarChart';
import { useDashboardActions } from 'src/store/dashboard/actions';
import { useUserActions } from 'src/store/users/actions';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import LineChart from 'src/components/charts/linechart/LineChart';
import StackedBarChart from 'src/components/charts/stackedchart/StackedBarChart';
import MultiTypeChart from 'src/components/charts/multiTypeChart/MultitypeChart';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import { MemberModel } from 'src/models/usersModel';
import { useNavigate } from 'react-router-dom';
import {
  secondsToMinutes,
  DateToHourMinutes,
  getDayName,
  getDate,
  changeDateFormat,
  defaultDateFormatter,
  numberWithCommas
} from 'src/components/utilities/utils';
import moment from 'moment';
import Loader from 'src/components/loader';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [value, onChange] = useState<any>([new Date(), new Date()]);
  const [popularVideo, setPopularVideo] = useState<any>([new Date(), new Date()]);
  const [changeYearData, setChangeYearData] = useState(new Date().getFullYear());
  const [popularVideoFilter, setPopularVideoFilter] = useState('All Time');
  const [memberActivityFilter, setMemberActivityFilter] = useState('This Month');
  const [memberValue, setMemberValue] = useState<number>(0);
  const dispatch = useDispatch();
  const dashboardActions = useDashboardActions(dispatch);
  const userAction = useUserActions(dispatch);
  const [totalSales, setTotalSales] = useState<number>();
  const [copyFlag, setCopyFlag] = useState<boolean>(false);
  const [memberFilter, setMemberFilter] = useState('createdDate');
  const [granularityFilter, setGranularityFilter] = useState<number>(1);
  const [memberChartType, setMemberChartType] = useState('Daily');
  const currentDate = new Date();
  const {
    salesOrder,
    topPayingCustomer,
    developerMeetup,
    salesRatio,
    mostPopularVideo,
    memberActivity,
    sales,
    totalDashboardData,
    salesOrderStats,
    previousYearSalesOrder,
    members,
    paginationMeta,
    topSellingProduct,
    isSalesOrderDataLoading,
    isSalesOrderStatsLoading,
    isSalesLoading,
    isMemberActivityLoading,
    isPopularVideoLoading,
    defaultDateFormat
  } = useSelector((state: RootState) => {
    return {
      salesOrder: state.dashboard.salesOrder,
      topPayingCustomer: state.dashboard.topPayingCustomer,
      developerMeetup: state.dashboard.developerMeetup,
      userTimeline: state.dashboard.userTimeline,
      salesRatio: state.dashboard.salesRatio,
      mostPopularVideo: state.dashboard.mostPopularVideo,
      memberActivity: state.dashboard.memberActivity,
      sales: state.dashboard.sales,
      totalDashboardData: state.dashboard.totalDashboardData,
      salesOrderStats: state.dashboard.salesOrderStats,
      previousYearSalesOrder: state.dashboard.previousYearSalesOrder,
      members: state.users.members,
      paginationMeta: state.users.paginationMeta,
      topSellingProduct: state.dashboard.topSellingProduct,
      isSalesOrderDataLoading: state.dashboard.isSalesOrderDataLoading,
      isSalesOrderStatsLoading: state.dashboard.isSalesOrderStatsLoading,
      isSalesLoading: state.dashboard.isSalesLoading,
      isMemberActivityLoading: state.dashboard.isMemberActivityLoading,
      isPopularVideoLoading: state.dashboard.isPopularVideoLoading,
      defaultDateFormat: state.login.defaultDateFormat
    };
  });

  const [barChartData, setBarChartData] = useState({
    labels: salesOrder && salesOrder.map((data: any) => data.month),
    datasets: [
      {
        type: '',
        fill: false,
        label: 'Current Year',
        data: salesOrder && salesOrder.map((data: any) => data.totalAmount),
        backgroundColor: ['#008080'],
        borderWidth: 0,
        borderColor: '',
        order: 1
      }
    ]
  });
  const [pieData, setPieData] = useState({
    labels: salesOrderStats && salesOrderStats.map((data: any) => data.name),
    datasets: [
      {
        label: '',
        data: [1],
        backgroundColor: [''],
        borderColor: ['']
      }
    ]
  });

  const [salesOrderStatsData, setSalesOrderStatsData] = useState({
    labels: [''],
    datasets: [
      {
        label: '',
        data: [1],
        backgroundColor: [''],
        borderColor: [''],
        pointRadius: 5
      }
    ]
  });

  const [radarData, setRadarData] = useState({
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Sales',
        data: [0],
        backgroundColor: 'rgb(0,207,232)'
      },
      {
        label: 'Views',
        data: [0],
        backgroundColor: 'rgb(115,104,240)'
      }
    ]
  });

  const [horizontalChartData, setHorizontalChartData] = useState({
    labels: [''],
    datasets: [
      {
        axis: '',
        label: '',
        data: [1],
        backgroundColor: '',
        barThickness: 12,
        datalabels: {
          color: '#FFCE56'
        }
      }
    ]
  });

  const [areaChartData, setAreaChartData] = useState({
    labels: memberActivity && memberActivity.map((data: any) => data.eventDate),
    datasets: [
      {
        fill: true,
        label: 'Visits',
        data: memberActivity && memberActivity.map((data: any) => data.viewCount),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: '#FFFFFF'
      }
    ]
  });

  useEffect(() => {
    if (salesOrder || previousYearSalesOrder) {
      setBarChartData({
        labels: salesOrder && salesOrder.map((data: any) => data.month),
        datasets: [
          {
            type: 'bar' as const,
            fill: false,
            label: 'Current Year',
            data: salesOrder && salesOrder.map((data: any) => data.totalAmount),
            backgroundColor: ['#008080'],
            borderWidth: 2,
            borderColor: 'white',
            order: 1
          },
          {
            type: 'line' as const,
            fill: false,
            label: 'Prior Year',
            data:
              previousYearSalesOrder && previousYearSalesOrder.map((data: any) => data.totalAmount),
            backgroundColor: ['#A020F0'],
            borderWidth: 2,
            borderColor: '#A020F0',
            order: 0
          }
        ]
      });
    }
    if (salesOrderStats) {
      setPieData({
        labels: salesOrderStats && salesOrderStats.map((data) => data.name),
        datasets: [
          {
            label: 'Sales',
            data: salesOrderStats && salesOrderStats.map((data) => data.total),
            backgroundColor: ['transparent', '#FDE802', '#28C76F', '#2A71d0'],
            borderColor: ['transparent']
          }
        ]
      });
    }
    if (mostPopularVideo) {
      setHorizontalChartData({
        labels: mostPopularVideo && mostPopularVideo.videoNames?.map((data) => data),
        datasets: [
          {
            axis: 'y',
            label: 'Live Views',
            data: mostPopularVideo && mostPopularVideo.liveViews?.map((data) => data),
            backgroundColor: '#00D2DB',
            barThickness: 15,
            datalabels: {
              color: 'white'
            }
          },
          {
            axis: 'y',
            label: 'OnDemand Views',
            data: mostPopularVideo && mostPopularVideo.views?.map((data) => data),
            backgroundColor: '#1A71FF',
            barThickness: 15,
            datalabels: {
              color: 'white'
            }
          }
        ]
      });
    }
    if (memberActivity) {
      setAreaChartData({
        labels:
          memberActivity &&
          memberActivity.map((data: any) =>
            memberValue === 0
              ? data.activityDate && moment(data.activityDate).format('YYYY-MM-DD')
              : data.eventDate && moment(data.eventDate).format('YYYY-MM-DD')
          ),
        datasets: [
          {
            fill: true,
            label: memberValue === 0 ? 'Logins' : 'Views',
            data:
              memberActivity &&
              memberActivity.map((data: any) =>
                memberValue === 0 ? data.memberCount : data.viewCount
              ),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: '#FFFFFF'
          }
        ]
      });
    }
    if (sales && sales.length) {
      setRadarData({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
          {
            label: 'Sales',
            data: sales && sales[0]?.data,
            backgroundColor: 'rgb(0,207,232)'
          },
          {
            label: 'Visits',
            data: sales && sales[1]?.data,
            backgroundColor: 'rgb(115,104,240)'
          }
        ]
      });
    } else {
      setRadarData({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
          {
            label: 'Sales',
            data: [1],
            backgroundColor: 'rgb(0,207,232)'
          },
          {
            label: 'Views',
            data: [1],
            backgroundColor: 'rgb(115,104,240)'
          }
        ]
      });
    }
  }, [
    salesOrder,
    salesRatio,
    mostPopularVideo,
    memberActivity,
    sales,
    salesOrderStats,
    previousYearSalesOrder,
    memberValue
  ]);

  useEffect(() => {
    dashboardActions.getSalesOrderRequest({ year: new Date().getFullYear().toString() });
    dashboardActions.getTopPayingCustomerRequest();
    dashboardActions.getDeveloperMeetupRequest();
    dashboardActions.getUserTimeLineRequest();
    dashboardActions.getSalesRatioRequest();
    const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
    dashboardActions.getMostPopularVideoRequest({
      fromDate: ''
    });
    dashboardActions.getSalesRequest();
    dashboardActions.getTotalDashboardDataRequest();
    dashboardActions.getSalesOrderStatsRequest(new Date().getFullYear().toString());
    dashboardActions.getPreviousYearSalesOrderRequest({
      year: moment().subtract(1, 'year').startOf('year').format('YYYY')
    });
    userAction.getMembersRequest({ SortFieldName: 'createdDate', PageSize: 10 });
  }, []);

  const handleYearClick = (data: any, previousYear: any) => {
    dashboardActions.getSalesOrderRequest(data);
    dashboardActions.getSalesOrderStatsRequest(data.year);
    dashboardActions.getPreviousYearSalesOrderRequest(previousYear);
    setChangeYearData(data.year);
  };

  const handleSearchMemberActivity = (data: any) => {
    setPopularVideo(data);
    dashboardActions.getSearchMemberActivityRequest(data);
  };
  useEffect(() => {
    if (salesOrderStats) {
      setSalesOrderStatsData({
        labels: salesOrderStats && salesOrderStats.map((data) => data.name),
        datasets: [
          {
            label: '',
            data: salesOrderStats && salesOrderStats.map((data) => data.total),
            backgroundColor: ['transparent', '#FDE802', '#28C76F', '#2A71d0'],
            borderColor: ['transparent'],
            pointRadius: 5
          }
        ]
      });
      const sum =
        salesOrderStats &&
        salesOrderStats.map((sale) => sale.total).reduce((partialSum, a) => partialSum + a, 0);
      setTotalSales(sum);
    }
  }, [salesOrderStats]);

  const handlePopularFilterClick = (data: string) => {
    setPopularVideoFilter(data);
    if (data === 'This Week') {
      const startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
      dashboardActions.getMostPopularVideoRequest({
        fromDate: startOfWeek
      });
    } else if (data === 'Last Week') {
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      dashboardActions.getMostPopularVideoRequest({
        fromDate: startOfLastWeek
      });
    } else if (data === 'This Month') {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      dashboardActions.getMostPopularVideoRequest({
        fromDate: startOfMonth
      });
    } else if (data === 'Last Month') {
      const lastMonthFirstDate = moment()
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      dashboardActions.getMostPopularVideoRequest({
        fromDate: lastMonthFirstDate
      });
    } else if (data === 'This Year') {
      const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
      dashboardActions.getMostPopularVideoRequest({
        fromDate: startOfYear
      });
    } else {
      dashboardActions.getMostPopularVideoRequest({
        fromDate: ''
      });
    }
  };

  const handleMemberActivityFilterClick = (data: string) => {
    setMemberActivityFilter(data);
    if (data === 'This Week') {
      const watchFromDate = moment().startOf('week').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('week').format('YYYY-MM-DD');
      dashboardActions.getMemberActivityRequest({
        memberValue,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
    } else if (data === 'Last Week') {
      const watchFromDate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
      const watchToDate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
      dashboardActions.getMemberActivityRequest({
        memberValue,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
    } else if (data === 'This Month') {
      const watchFromDate = moment().startOf('month').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('month').format('YYYY-MM-DD');
      dashboardActions.getMemberActivityRequest({
        memberValue,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
    } else if (data === 'Last Month') {
      const watchFromDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      const watchToDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      dashboardActions.getMemberActivityRequest({
        memberValue,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
    } else if (data === 'This Year') {
      const watchFromDate = moment().startOf('year').format('YYYY-MM-DD');
      const watchToDate = moment().endOf('year').format('YYYY-MM-DD');
      dashboardActions.getMemberActivityRequest({
        memberValue,
        watchFromDate,
        watchToDate,
        granularityFilter
      });
    }
  };

  useEffect(() => {
    if (copyFlag) {
      successMsg('copied to clipboard');
      setCopyFlag(false);
    }
  }, [copyFlag]);

  const handlePageChange = (pageNumber: number) => {
    userAction.getMembersRequest({ SortFieldName: memberFilter, pageNumber, PageSize: 10 });
  };

  const handleMemberFilterChange = (data: string) => {
    setMemberFilter(data);
    userAction.getMembersRequest({ SortFieldName: data, PageSize: 10 });
  };
  const options = {
    scales: {
      xAxis: {
        grid: {
          display: false
        }
      },
      yAxis: {
        grid: {
          display: false
        },
        ticks: {
          precision: 0
        }
      }
    },
    responsive: true
  };

  useEffect(() => {
    handleMemberActivityFilterClick(memberActivityFilter);
  }, [memberValue]);

  useEffect(() => {
    const orderFromDate = moment([changeYearData]).startOf('year').format('YYYY-MM-DD');
    const orderToDate = moment().endOf('month').format('YYYY-MM-DD');
    dashboardActions.getTopSellingProductsRequest({ orderFromDate, orderToDate, allRecord: true });
  }, [changeYearData]);

  const handleMemberDetailClick = (member: MemberModel, memberId: string) => {
    navigate(`/member/${memberId}`, {
      state: { memberId: memberId, memberDetail: member }
    });
  };

  return (
    <>
      <ToastMessage />

      <div className="dashboard">
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {totalDashboardData && totalDashboardData.totalMembers}
                  </span>
                  <span className="mb-2">Total Members</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                      <img src={`../../assets/img/icons/dashboard1.svg`} className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {totalDashboardData && totalDashboardData.totalChannels}
                  </span>
                  <span className="mb-2">Total Channels</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                      <img src={`../../assets/img/icons/dashboard2.svg`} className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {totalDashboardData && totalDashboardData.totalVideos}
                  </span>
                  <span className="mb-2">Total Videos</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                      <img src={`../../assets/img/icons/dashboard3.svg`} className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {totalDashboardData && totalDashboardData.currentStorage} GB
                  </span>
                  <span className="mb-2">Current Storage</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                      <img src={`../../assets/img/icons/dashboard4.svg`} className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:flex sales-order-box">
          <div className="pr-5 xl:w-9/12 w-full">
            <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 w-full xl:mb-0 mb-4 h-full">
              <div className="lg:flex h-full justify-center">
                <div className="lg:border-r lg:w-3/5">
                  <div className="p-4">
                    <div className="custom-h-card">
                      <h2 className="font-semibold">Sales Orders</h2>
                    </div>
                    {isSalesOrderDataLoading ? (
                      <Loader />
                    ) : (
                      <MultiTypeChart
                        chartData={barChartData}
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="lg:w-2/5">
                  <div className="p-4">
                    <div className="custom-h-card flex justify-between">
                      <p className="text-2xl font-semibold text-center">
                        ${totalSales && numberWithCommas(totalSales)}
                      </p>
                      <div className="dropdown relative">
                        <button
                          className="dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown"
                          type="button"
                          id="action-dropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <span className="flex">{changeYearData}</span>
                          <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                        </button>
                        <ul
                          className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                          aria-labelledby="action-dropdown">
                          <li>
                            <span
                              className="action-menu flex items-center"
                              onClick={() =>
                                handleYearClick(
                                  { year: moment().year() },
                                  { year: moment().year() - 1 }
                                )
                              }>
                              <span>{moment().year()}</span>
                            </span>
                          </li>
                          <li>
                            <span
                              className="action-menu flex items-center"
                              onClick={() =>
                                handleYearClick(
                                  { year: moment().year() - 1 },
                                  { year: moment().year() - 2 }
                                )
                              }>
                              <span>{moment().year() - 1}</span>
                            </span>
                          </li>
                          <li>
                            <span
                              className="action-menu flex items-center"
                              onClick={() =>
                                handleYearClick(
                                  { year: moment().year() - 2 },
                                  { year: moment().year() - 3 }
                                )
                              }>
                              <span>{moment().year() - 2}</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="text-center">
                      {isSalesOrderStatsLoading ? (
                        <Loader />
                      ) : (
                        <LineChart
                          height={200}
                          chartData={salesOrderStatsData}
                          options={{
                            scales: {
                              y: {
                                beginAtZero: true
                              }
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="xl:w-1/4 w-full bg-white rounded-lg shadow-card lg:mb-0 p-4 pr-3.5">
            <div>
              {developerMeetup &&
              developerMeetup.length &&
              new Date(developerMeetup[0]?.videoDateTime) >= currentDate ? (
                <>
                  <h2 className="font-semibold">Upcoming Live</h2>
                  <img
                    src={developerMeetup && developerMeetup[0]?.videoThumbnailURL}
                    className="w-full h-40 object-cover mt-3 mb-5 shadow-lg rounded-md"
                  />
                  <div>
                    <div className="flex items-center">
                      <h6 className="grid text-lg font-semibold border-r px-2">
                        <span>
                          {developerMeetup && getDayName(developerMeetup[0]?.videoDateTime)}
                        </span>
                        <span>{developerMeetup && getDate(developerMeetup[0]?.videoDateTime)}</span>
                      </h6>
                      <div className="ml-2">
                        <Link to={`/videos/${developerMeetup[0]?.videoId}`}>
                          <h6 className="text-lg">
                            {developerMeetup && developerMeetup[0]?.title}
                          </h6>
                        </Link>
                      </div>
                    </div>
                    <div className="flex items-center mt-4">
                      <div className="mr-3">
                        <div className="flex items-center justify-center">
                          <span className="sales-bg w-10 h-10 rounded-lg flex items-center">
                            <i
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  developerMeetup && developerMeetup[0].playUrl
                                );
                                setCopyFlag(true);
                              }}
                              className="color fa fa-copy ml-3 text-lg cursor-pointer"></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <p>
                          {developerMeetup && getDayName(developerMeetup[0]?.videoDateTime)},
                          <span className="ml-1">
                            {developerMeetup[0] &&
                              changeDateFormat(
                                developerMeetup && developerMeetup[0]?.videoDateTime
                              )}
                          </span>
                        </p>
                        <p>
                          <small className="text-xs">
                            Start Time:{' '}
                            {developerMeetup &&
                              DateToHourMinutes(developerMeetup[0]?.videoDateTime)}
                            <span className="ml-1">
                              Duration:{' '}
                              {developerMeetup && secondsToMinutes(developerMeetup[0]?.duration)}{' '}
                              min
                            </span>
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <h2 className="font-semibold">No Upcoming Live Streams</h2>
              )}
            </div>
          </div>
        </div>
        <div className="lg:flex gap-4 mt-5">
          <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative lg:w-4/12 w-full lg:mb-0 mb-4">
            <div className="p-4">
              <h6 className="font-semibold ">Sales Ratio</h6>
              <p className="text-gray-400">Spending on various content</p>
              <div className="w-full mx-auto">
                <PieChart chartData={pieData} />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative lg:w-4/12 w-full lg:mb-0 mb-4">
            <div className="p-4">
              <h6 className="font-semibold ">Top Selling Products</h6>
              <div className="w-full mx-auto mt-4">
                <table className="table-auto w-full border">
                  <thead className="text-xs font-semibold uppercase bg-gray-50">
                    <tr>
                      <th className="p-2">
                        <div className="font-semibold text-left">Item Name</div>
                      </th>
                      <th className="p-2">
                        <div className="font-semibold text-left">Total Sales</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {topSellingProduct &&
                      topSellingProduct.map((item, index) => {
                        return (
                          index < 10 && (
                            <>
                              <tr key={index}>
                                <td className="p-2">
                                  <div className="text-left">{item.productName}</div>
                                </td>
                                <td className="p-2">
                                  <div className="text-left">{item.totalSales}</div>
                                </td>
                              </tr>
                            </>
                          )
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative lg:w-4/12 w-full lg:mb-0 mb-4">
            <div className="p-4">
              <h6 className="font-semibold ">Sales</h6>
              <p className="text-gray-400">Last 6 months</p>
              {isSalesLoading ? <Loader /> : <RadarChart chartData={radarData} />}
            </div>
            <div className="absolute top-4 right-3">
              <div className="flex justify-center">
                <div>
                  <div className="dropdown relative">
                    <a
                      className="
                    dropdown-toggle
                    transition
                    duration-150
                    ease-in-out"
                      href="#"
                      type="button"
                      id="ActionButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </a>
                    <ul className="dropdown-menu action-dropdown" aria-labelledby="ActionButton">
                      <li>
                        <a className="dropdown-item flex-items" href="#">
                          <span>Option</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item flex-items" href="#">
                          <span>Option1</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative mt-5">
          <div className="p-4">
            <h6 className="font-semibold mb-2">Most Popular Videos</h6>
            <div className="flex items-center justify-end">
              <div className="dropdown relative mr-3">
                <button
                  className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                  type="button"
                  id="action-dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <span className="flex">{popularVideoFilter}</span>
                  <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                  aria-labelledby="action-dropdown">
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('This Week')}>
                      <span>This Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('Last Week')}>
                      <span>Last Week</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('This Month')}>
                      <span>This Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('Last Month')}>
                      <span>Last Month</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('This Year')}>
                      <span>This Year</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="action-menu flex items-center"
                      onClick={() => handlePopularFilterClick('All Time')}>
                      <span>All Time</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              {isPopularVideoLoading ? (
                <Loader />
              ) : (
                <StackedBarChart
                  chartData={horizontalChartData}
                  height={20}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    interaction: {
                      mode: 'index'
                    },
                    scales: {
                      x: {
                        stacked: true
                      },
                      y: {
                        stacked: true,
                        grid: {
                          display: false
                        }
                      }
                    },
                    plugins: {
                      datalabels: {
                        display: function (context: any) {
                          return context.dataset.data[context.dataIndex] !== 0;
                        }
                      },
                      tooltip: {
                        callbacks: {
                          afterBody: (data: any) => {
                            return 'Total: ' + (data[0].raw + data[1].raw);
                          }
                        }
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative mt-5">
          <div className="p-4">
            <h6 className="font-semibold my-4">Member Activity</h6>
            <div className="flex items-center mb-5 justify-between">
              <div>
                <div className="flex items-center">
                  <p
                    className="mr-3"
                    onClick={() => {
                      setMemberValue(0);
                    }}>
                    <span
                      className={`${
                        memberValue === 0 ? 'bg-blue-600' : 'bg-gray-200'
                      } small-circle mr-2 cursor-pointer`}></span>
                    <span>Member Logins</span>
                  </p>
                  <p
                    className="mr-3"
                    onClick={() => {
                      setMemberValue(1);
                    }}>
                    <span
                      className={`${
                        memberValue === 1 ? 'bg-blue-600' : 'bg-gray-200'
                      } small-circle mr-2 cursor-pointer`}></span>
                    <span>Member Views</span>
                  </p>
                </div>
              </div>
              <span>
                <div className="flex items-center justify-end">
                  <div className="dropdown relative mr-3">
                    <button
                      className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                      type="button"
                      id="action-dropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <span className="flex">{memberChartType}</span>
                      <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                      aria-labelledby="action-dropdown">
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => {
                            setMemberChartType('Daily');
                            setGranularityFilter(1);
                          }}>
                          <span>Daily</span>
                        </span>
                      </li>
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => {
                            setMemberChartType('Monthly');
                            setGranularityFilter(2);
                          }}>
                          <span>Monthly</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown relative mr-3">
                    <button
                      className=" whitespace-nowrap dropdown-toggle border input-style w-28 mx-auto flex-items export-dropdown p-2"
                      type="button"
                      id="action-dropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <span className="flex">{memberActivityFilter}</span>
                      <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-ul w-32 cursor-pointer"
                      aria-labelledby="action-dropdown">
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => handleMemberActivityFilterClick('This Week')}>
                          <span>This Week</span>
                        </span>
                      </li>
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => handleMemberActivityFilterClick('Last Week')}>
                          <span>Last Week</span>
                        </span>
                      </li>
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => handleMemberActivityFilterClick('This Month')}>
                          <span>This Month</span>
                        </span>
                      </li>
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => handleMemberActivityFilterClick('Last Month')}>
                          <span>Last Month</span>
                        </span>
                      </li>
                      <li>
                        <span
                          className="action-menu flex items-center"
                          onClick={() => handleMemberActivityFilterClick('This Year')}>
                          <span>This Year</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
            <div>
              {isMemberActivityLoading ? (
                <Loader />
              ) : (
                <LineChart height={100} options={options} chartData={areaChartData} />
              )}
            </div>
          </div>
        </div>

        <div className="w-full md:mr-5 bg-white shadow-card rounded-lg lg:mb-0 mb-4 mt-5">
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Latest Members</h2>
                <div className="flex items-center">
                  <div className="dropdown relative">
                    <button
                      className="dropdown-toggle border input-style w-auto mr-3 flex-items export-dropdown"
                      type="button"
                      id="action-dropdown"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <span className="flex">
                        {/* <img src="../../assets/img/export.svg" className="w-4 h-4 mr-2" /> */}
                        {memberFilter === 'createdDate'
                          ? 'Newest Members'
                          : memberFilter === 'lastLoginDate'
                          ? 'Most Recent Members'
                          : 'Newest Members'}
                      </span>
                      <img src="../../assets/img/down-icon.svg" className="w-4 h-4 ml-2" />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-ul w-full cursor-pointer"
                      aria-labelledby="action-dropdown">
                      <li onClick={() => handleMemberFilterChange('createdDate')}>
                        <div className="action-menu flex items-center">
                          <span>Newest Members</span>
                        </div>
                      </li>
                      <li onClick={() => handleMemberFilterChange('lastLoginDate')}>
                        <div className="action-menu flex items-center">
                          <span>Most Recent Members</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Full Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Email</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Joined Date</div>
                        </th>

                        <th className="p-2">
                          <div className="font-semibold text-left">Source</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Last Login</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {members &&
                        members.map((member) => {
                          return (
                            <tr key={member.id}>
                              <td className="p-2">
                                <div className="flex items-center">
                                  <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                                    <img
                                      className="rounded-full"
                                      src={
                                        member.profileURL
                                          ? member.profileURL
                                          : '../../assets/img/user-png.png'
                                      }
                                      style={{ height: '40px' }}
                                      width="40"
                                      height="40"
                                    />
                                  </div>
                                  <div className="font-medium ">{`${member.firstName} ${member.lastName} `}</div>
                                </div>
                              </td>
                              <td className="p-3">
                                <div className="text-left">{member.email}</div>
                              </td>
                              <td className="p-3">
                                <div className="text-left">
                                  {member.createdDate
                                    ? defaultDateFormatter(member.createdDate)
                                    : ''}
                                </div>
                              </td>
                              <td className="p-3">
                                <div className="text-left">{member.sourceName}</div>
                              </td>
                              <td className="p-3">
                                <div className="text-left">
                                  {member.lastLoginDate == null
                                    ? ''
                                    : defaultDateFormatter(member.lastLoginDate)}
                                </div>
                              </td>
                              <td className="p-2">
                                <div className="text-left">
                                  <div className="dropdown relative">
                                    <button
                                      className="dropdown-toggle"
                                      type="button"
                                      id="action-dropdown"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <img
                                        src="../../assets/img/dots-vertical.svg"
                                        className="w-5 h-5"
                                      />
                                    </button>
                                    <ul
                                      className="dropdown-menu dropdown-menu-ul"
                                      aria-labelledby="action-dropdown">
                                      <li
                                        onClick={() => handleMemberDetailClick(member, member.id)}>
                                        <button className="action-menu flex items-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="mr-2 w-4 h-4">
                                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                            <polyline points="14 2 14 8 20 8"></polyline>
                                            <line x1="16" y1="13" x2="8" y2="13"></line>
                                            <line x1="16" y1="17" x2="8" y2="17"></line>
                                            <polyline points="10 9 9 9 8 9"></polyline>
                                          </svg>
                                          <span>Details</span>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {!!paginationMeta && !!Object.keys(paginationMeta).length && members && (
                    <Pagination
                      currentPage={paginationMeta.pageNumber}
                      totalCount={paginationMeta.totalCount}
                      pageSize={paginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:flex gap-4 mt-5">
          <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 relative w-full">
            <div className="absolute top-4 right-5">
              <div className="flex justify-center">
                <div>
                  <div className="dropdown relative">
                    <a
                      className="
                    dropdown-toggle
                    transition
                    duration-150
                    ease-in-out"
                      href="#"
                      type="button"
                      id="ActionButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      <i className="fas fa-ellipsis-v"></i>
                    </a>
                    <ul className="dropdown-menu action-dropdown" aria-labelledby="ActionButton">
                      <li>
                        <a className="dropdown-item flex-items" href="#">
                          <span>Option</span>
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item flex-items" href="#">
                          <span>Option1</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Dashboard);
