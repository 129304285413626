import '../styles.scss';
import { useRef } from 'react';
import VideoCategories from '../../videos/videoCategories';
import SpotlightRows from '../spotlightRows';
import HighlightReel from '../highlightReel';
import AdBanner from './components/AdBanner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useEffect } from 'react';
import { successMsg } from 'src/components/utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { useCategoryActions } from 'src/store/category/actions';
import { useNavigate, useParams } from 'react-router-dom';
const Layout = () => {
  const navigate = useNavigate();
  const heroRef = useRef<HTMLAnchorElement>(null);
  const bannerRef = useRef<HTMLAnchorElement>(null);
  const recommendationRef = useRef<HTMLAnchorElement>(null);
  const categoryRef = useRef<HTMLAnchorElement>(null);
  const { tab } = useParams<string>();
  const dispatch = useDispatch();
  const videoLibraryAction = useVideoLibraryActions(dispatch);
  const categoryActions = useCategoryActions(dispatch);
  const {
    isLayoutStyleUpdated,
    message,
    isAdBannerUpdated,
    isLayoutContentUpdated,
    isSpotlightChannelDeleted
  } = useSelector((state: RootState) => {
    return {
      isLayoutStyleUpdated: state.videoLibrary.isLayoutStyleUpdated,
      message: state.videoLibrary.message,
      isAdBannerUpdated: state.videoLibrary.isAdBannerUpdated,
      isLayoutContentUpdated: state.videoLibrary.isLayoutContentUpdated,
      isSpotlightChannelDeleted: state.videoLibrary.isSpotlightChannelDeleted
    };
  });

  useEffect(() => {
    if (
      isLayoutStyleUpdated ||
      isAdBannerUpdated ||
      isLayoutContentUpdated ||
      isSpotlightChannelDeleted
    ) {
      successMsg(message);
      videoLibraryAction.resetVideoLibraryStateRequest();
    }
  }, [isLayoutStyleUpdated, isAdBannerUpdated, isLayoutContentUpdated, isSpotlightChannelDeleted]);

  const handleAdBannerClick = () => {
    videoLibraryAction.getAdBannerRequest();
    navigate('/layout/AdBanner');
  };
  const handleRecommendationClick = () => {
    videoLibraryAction.getSpotlightChannelRequest();
    videoLibraryAction.getRecommendationRequest();
    navigate('/layout/Recommendation');
  };

  const handleCategoriesClick = () => {
    navigate('/layout/Categories');
    categoryActions.getCategoryRequest({ pageNumber: 1 });
  };

  useEffect(() => {
    if (tab === 'heroSection') {
      if (heroRef.current) {
        heroRef.current.click();
      }
    } else if (tab === 'AdBanner') {
      if (bannerRef.current) {
        bannerRef.current.click();
      }
    } else if (tab === 'Recommendation') {
      if (recommendationRef.current) {
        recommendationRef.current.click();
      }
    } else if (tab === 'Categories') {
      if (categoryRef.current) {
        categoryRef.current.click();
      }
    }
  }, []);

  return (
    <div>
      <ToastMessage />
      <div className="custom-theme">
        <div className="md:flex items-center justify-between mb-4">
          <div className="flex flex-wrap items-center">
            <h2 className="font-semibold mr-3">Layout</h2>
            <div className="breadcrumbs">
              <span className="site-clr">
                <img
                  src="../../assets/img/icons/breadcrumb-home.svg"
                  className="w-4 h-4 cursor-pointer inline-block"
                />
              </span>
              <span className="mx-2">/</span>
              <span className="cursor-pointer site-clr">Video Library</span>
              <span className="mx-2">/</span>
              <span className="cursor-pointer">Layout</span>
            </div>
          </div>
        </div>
        <div>
          <div>
            <ul
              className="nav nav-tabs horizontal-tabs-top flex md:flex-row overflow-auto list-none border-b-0 p-2 mb-4 bg-white shadow-card rounded-lg"
              id="tabs-tab"
              role="tablist">
              <li
                onClick={() => navigate('/layout/heroSection')}
                className="nav-item"
                role="presentation">
                <a
                  ref={heroRef}
                  href="#tab-accounts"
                  className="
                  nav-link
                  tabs-link
                  active"
                  id="tab-accounts-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-accounts"
                  role="tab"
                  aria-controls="tab-accounts"
                  aria-selected="true">
                  <span className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="h-4 w-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_1076_92631"
                        maskUnits="userSpaceOnUse"
                        x="1"
                        y="0"
                        width="14"
                        height="16">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.3999 5.47488L8.39992 0.815773C8.13325 0.616097 7.79992 0.616097 7.59992 0.815773L1.59992 5.47488C1.39992 5.608 1.33325 5.80767 1.33325 6.00735V13.3288C1.33325 14.4603 2.19992 15.3256 3.33325 15.3256H12.6666C13.7999 15.3256 14.6666 14.4603 14.6666 13.3288V6.00735C14.6666 5.80767 14.5999 5.608 14.3999 5.47488ZM9.33325 8.6697V13.9944H6.66658V8.6697H9.33325ZM12.6666 13.9944C13.0666 13.9944 13.3333 13.7282 13.3333 13.3288V6.34014L7.99992 2.21351L2.66659 6.34014V13.3288C2.66659 13.7282 2.93325 13.9944 3.33325 13.9944H5.33325V8.00411C5.33325 7.60476 5.59992 7.33853 5.99992 7.33853H9.99992C10.3999 7.33853 10.6666 7.60476 10.6666 8.00411V13.9944H12.6666Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1076_92631)">
                        <rect width="16" height="15.9922" fill="currentcolor" />
                      </g>
                    </svg>
                    <span>Hero Section</span>
                    <i className="fas fa-chevron-down ml-3"></i>
                  </span>
                </a>
              </li>
              <li onClick={handleAdBannerClick} className="nav-item" role="presentation">
                <a
                  ref={bannerRef}
                  href="#tabs-security"
                  className="
                nav-link
                tabs-link
               "
                  id="tabs-security-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-security"
                  role="tab"
                  aria-controls="tabs-security"
                  aria-selected="false">
                  <span className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="h-4 w-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_1076_92624"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="16"
                        height="16">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.86675 0.400098L14.2001 3.06547C14.9334 3.39864 15.3334 4.06499 15.3334 4.79796V11.1282C15.3334 11.9278 14.8667 12.5942 14.2001 12.9274L8.86675 15.5927C8.60008 15.726 8.33341 15.7926 8.00008 15.7926C7.73341 15.7926 7.40008 15.726 7.13341 15.5927L1.80008 12.9274C1.06675 12.5942 0.666748 11.8612 0.666748 11.1282V4.79796C0.666748 4.06499 1.06675 3.39864 1.73341 3.06547L7.06675 0.400098C7.66675 0.133561 8.33341 0.133561 8.86675 0.400098ZM8.00008 1.46625C7.93341 1.46625 7.80008 1.46625 7.73341 1.53288L2.80008 3.99835L8.00008 6.59709L13.2001 3.99835L8.26675 1.53288C8.20008 1.46625 8.13341 1.46625 8.00008 1.46625ZM2.33341 11.7946C2.13341 11.6613 2.00008 11.3948 2.00008 11.1949V5.0645L7.33342 7.72988V14.26L2.33341 11.7946ZM8.66675 14.26L13.6001 11.7946C13.8667 11.6613 14.0001 11.4614 14.0001 11.1949V5.0645L8.66675 7.72988V14.26Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1076_92624)">
                        <rect width="16" height="15.9922" fill="currentcolor" />
                      </g>
                    </svg>

                    <span>Ad Banner</span>
                    <i className="fas fa-chevron-down ml-3"></i>
                  </span>
                </a>
              </li>
              <li onClick={handleRecommendationClick} className="nav-item" role="presentation">
                <a
                  ref={recommendationRef}
                  href="#tab-analytics"
                  className="
                nav-link
                tabs-link
               "
                  id="tab-analytics-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-analytics"
                  role="tab"
                  aria-controls="tab-analytics"
                  aria-selected="false">
                  <span className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="h-4 w-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_1076_92619"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="16"
                        height="16">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.73341 8.58785L1.06675 5.25234C0.800081 5.11892 0.666748 4.91879 0.666748 4.65195C0.666748 4.38511 0.800081 4.18498 1.00008 4.05156L7.66675 0.716048C7.86675 0.649338 8.06675 0.649338 8.26675 0.716048L14.9334 4.05156C15.2001 4.18498 15.3334 4.38511 15.3334 4.65195C15.3334 4.91879 15.2001 5.11892 14.9334 5.25234L8.26675 8.58785C8.20008 8.65456 8.13341 8.65456 8.00008 8.65456C7.86675 8.65456 7.80008 8.65456 7.73341 8.58785ZM13.2001 4.65195L8.00008 2.05025L2.80008 4.65195L8.00008 7.25364L13.2001 4.65195ZM15.2667 11.0561C15.4001 11.3897 15.2667 11.7899 15.0001 11.9234L8.33341 15.2589C8.20008 15.3256 8.13341 15.3256 8.00008 15.3256C7.86675 15.3256 7.80008 15.3256 7.73341 15.2589L1.06675 11.9234C0.733415 11.7899 0.600081 11.3897 0.800081 11.0561C0.933415 10.7226 1.33341 10.5891 1.66675 10.7893L8.06675 13.9914L14.4667 10.7893C14.6667 10.5891 15.0667 10.7226 15.2667 11.0561ZM15.0001 8.58785C15.2667 8.45443 15.4001 8.05416 15.2667 7.72061C15.0667 7.38706 14.6667 7.25364 14.4667 7.45377L8.06675 10.6559L1.66675 7.45377C1.33341 7.25364 0.933415 7.38706 0.800081 7.72061C0.600081 8.05416 0.733415 8.45443 1.06675 8.58785L7.73341 11.9234C7.80008 11.9901 7.86675 11.9901 8.00008 11.9901C8.13341 11.9901 8.20008 11.9901 8.33341 11.9234L15.0001 8.58785Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1076_92619)">
                        <rect width="16" height="15.9922" fill="currentcolor" />
                      </g>
                    </svg>
                    <span>Recommendations & Rows</span>
                    <i className="fas fa-chevron-down ml-3"></i>
                  </span>
                </a>
              </li>
              <li onClick={() => handleCategoriesClick()} className="nav-item" role="presentation">
                <a
                  ref={categoryRef}
                  href="#tabs-notification"
                  className="nav-link tabs-link"
                  id="tabs-notification-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tabs-notification"
                  role="tab"
                  aria-controls="tabs-notification"
                  aria-selected="false">
                  <span className="flex items-center">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="h-4 w-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg">
                      <mask
                        id="mask0_1076_92614"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="16"
                        height="16">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.1332 4.46417L8.4665 11.1276C8.33317 11.2609 8.19984 11.3275 7.99984 11.3275H5.33317C4.93317 11.3275 4.6665 11.061 4.6665 10.6612V7.99579C4.6665 7.79589 4.73317 7.66262 4.8665 7.52935L11.5332 0.865919C11.7998 0.599381 12.1998 0.599381 12.4665 0.865919L15.1332 3.53129C15.3998 3.79783 15.3998 4.19764 15.1332 4.46417ZM13.9998 9.79492V13.3265C13.9998 14.4593 13.1332 15.3256 11.9998 15.3256H2.6665C1.53317 15.3256 0.666504 14.4593 0.666504 13.3265V3.99773C0.666504 2.86495 1.53317 1.9987 2.6665 1.9987H6.19984C6.59984 1.9987 6.8665 2.26524 6.8665 2.66505C6.8665 3.06485 6.59984 3.33139 6.19984 3.33139H2.6665C2.2665 3.33139 1.99984 3.59793 1.99984 3.99773V13.3265C1.99984 13.7263 2.2665 13.9929 2.6665 13.9929H11.9998C12.3998 13.9929 12.6665 13.7263 12.6665 13.3265V9.79492C12.6665 9.39511 12.9332 9.12858 13.3332 9.12858C13.7332 9.12858 13.9998 9.39511 13.9998 9.79492ZM11.9998 2.26524L13.7332 3.99773L7.73317 9.99482H5.99984V8.26233L11.9998 2.26524Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1076_92614)">
                        <rect width="16" height="15.9922" fill="currentcolor" />
                      </g>
                    </svg>

                    <span>Categories</span>
                    <i className="fas fa-chevron-down ml-3"></i>
                  </span>
                </a>
              </li>
            </ul>
            <div className="tab-content" id="tabs-tabContent">
              <HighlightReel />
              <div
                className="tab-pane fade"
                id="tab-analytics"
                role="tabpanel"
                aria-labelledby="tabs-security-tab">
                <SpotlightRows />
              </div>
              <div
                className="tab-pane fade"
                id="tabs-security"
                role="tabpanel"
                aria-labelledby="tabs-security-tab">
                <AdBanner />
              </div>

              <div
                className="tab-pane fade"
                id="tabs-notification"
                role="tabpanel"
                aria-labelledby="tabs-notification-tab">
                <VideoCategories />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
