export const getDashboardRequestActionType = 'GET_DASHBOARD_REQUEST'
export const getDashboardSuccessActionType = 'GET_DASHBOARD_REQUEST_SUCCESS'
export const getDashboardFailureActionType = 'GET_DASHBOARD_REQUEST_FAILURE'
export const getSalesOrderDataRequestActionType = 'GET_SALES_ORDER_DATA_REQUEST'
export const getSalesOrderDataSuccessActionType = 'GET_SALES_ORDER_DATA_REQUEST_SUCCESS'
export const getSalesOrderDataFailureActionType = 'GET_SALES_ORDER_DATA_REQUEST_FAILURE'
export const getTopPayingCustomerRequestActionType = 'GET_TOP_PAYING_CUSTOMER_REQUEST'
export const getTopPayingCustomerRequestSuccessActionType = 'GET_TOP_PAYING_CUSTOMER_REQUEST_SUCCESS'
export const getTopPayingCustomerRequestFailureActionType = 'GET_TOP_PAYING_CUSTOMER_REQUEST_FAILURE'
export const getDeveloperMeetupRequestActionType = 'GET_DEVELOPER_MEETUP_REQUEST'
export const getDeveloperMeetupRequestSuccessActionType = 'GET_DEVELOPER_MEETUP_REQUEST_SUCCESS'
export const getDeveloperMeetupRequestFailureActionType = 'GET_DEVELOPER_MEETUP_REQUEST_FAILURE'
export const getUserTimeLineRequestActionType = 'GET_USER_TIMELINE_REQUEST'
export const getUserTimeLineRequestSuccessActionType = 'GET_USER_TIMELINE_REQUEST_SUCCESS'
export const getUserTimeLineRequestFailureActionType = 'GET_USER_TIMELINE_REQUEST_FAILURE'
export const getSalesRatioRequestActionType = 'GET_SALES_RATIO_REQUEST'
export const getSalesRatioRequestSuccessActionType = 'GET_SALES_RATIO_REQUEST_SUCCESS'
export const getSalesRatioRequestFailureActionType = 'GET_SALES_RATIO_REQUEST_FAILURE'
export const getMostPopularVideoRequestActionType = 'GET_MOST_POPULAR_VIDEO_REQUEST'
export const getMostPopularVideoRequestSuccessActionType = 'GET_MOST_POPULAR_VIDEO_REQUEST_SUCCESS'
export const getMostPopularVideoRequestFailureActionType = 'GET_MOST_POPULAR_VIDEO_REQUEST_FAILURE'
export const getMemberActivityRequestActionType = 'GET_MEMBER_ACTIVITY_REQUEST'
export const getMemberActivityRequestSuccessActionType = 'GET_MEMBER_ACTIVITY_REQUEST_SUCCESS'
export const getMemberActivityRequestFailureActionType = 'GET_MEMBER_ACTIVITY_REQUEST_FAILURE'
export const getWhatsNewRequestActionType = 'GET_WHATS_NEW_REQUEST'
export const getWhatsNewRequestSuccessActionType = 'GET_WHATS_NEW_REQUEST_SUCCESS'
export const getWhatsNewRequestFailureActionType = 'GET_WHATS_NEW_REQUEST_FAILURE'
export const getSalesRequestActionType = 'GET_SALES_REQUEST'
export const getSalesRequestSuccessActionType = 'GET_SALES_REQUEST_SUCCESS'
export const getSalesRequestFailureActionType = 'GET_SALES_REQUEST_FAILURE'
export const getSearchedMostPopularVideoRequestActionType = 'GET_SEARCHED_MOST_POPULAR_VIDEO_REQUEST'
export const getSearchedMostPopularVideoRequestSuccessActionType = 'GET_SEARCHED_MOST_POPULAR_VIDEO_REQUEST_SUCCESS'
export const getSearchedMostPopularVideoRequestFailureActionType = 'GET_SEARCHED_MOST_POPULAR_VIDEO_REQUEST_FAILURE'
export const getSearchMemberActivityRequestActionType = 'GET_SEARCH_MEMBER_ACTIVITY_REQUEST'
export const getSearchMemberActivityRequestSuccessActionType = 'GET_SEARCH_MEMBER_ACTIVITY_REQUEST_SUCCESS'
export const getSearchMemberActivityRequestFailureActionType = 'GET_SEARCH_MEMBER_ACTIVITY_REQUEST_FAILURE'
export const getTotalDashboardDataRequestActionType = 'GET_TOTAL_DASHBOARD_DATA_REQUEST'
export const getTotalDashboardDataRequestSuccessActionType = 'GET_TOTAL_DASHBOARD_DATA_REQUEST_SUCCESS'
export const getTotalDashboardDataRequestFailureActionType = 'GET_TOTAL_DASHBOARD_DATA_REQUEST_FAILURE'
export const saveCustomizeThemeRequestActionType = 'SAVE_CUSTOMIZE_THEME_REQUEST'
export const saveCustomizeThemeRequestSuccessActionType = 'SAVE_CUSTOMIZE_THEME_REQUEST_SUCCESS'
export const saveCustomizeThemeRequestFailureActionType = 'SAVE_CUSTOMIZE_THEME_REQUEST_FAILURE'
export const getSalesOrderStatsDataRequestActionType = 'GET_SALES_ORDER_STATS_DATA_REQUEST'
export const getSalesOrderStatsDataRequestSuccessActionType = 'GET_SALES_ORDER_STATS_DATA_REQUEST_SUCCESS'
export const getSalesOrderStatsDataRequestFailureActionType = 'GET_SALES_ORDER_STATS_DATA_REQUEST_FAILURE'
export const getPreviousYearSalesOrderDataRequestActionType = 'GET_PREVIOUS_YEAR_SALES_ORDER_DATA_REQUEST'
export const getPreviousYearSalesOrderDataRequestSuccessActionType = 'GET_PREVIOUS_YEAR_SALES_ORDER_DATA_REQUEST_SUCCESS'
export const getPreviousYearSalesOrderDataRequestFailureActionType = 'GET_PREVIOUS_YEAR_SALES_ORDER_DATA_REQUEST_FAILURE'
export const getTopSellingProductsRequestActionType = 'GET_TOP_SELLING_PRODUCTS_REQUEST'
export const getTopSellingProductsRequestSuccessActionType = 'GET_TOP_SELLING_PRODUCTS_REQUEST_SUCCESS'
export const getTopSellingProductsRequestFailureActionType = 'GET_TOP_SELLING_PRODUCTS_REQUEST_FAILURE'