import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
interface IProps {
  isVideoContentTypeModalOpen: boolean;
  setIsVideoContentTypeModalOpen: (open: boolean) => void;
  handleResourceUploadClick: () => void;
}

const VideoContentTypeModal: React.FC<IProps> = ({
  isVideoContentTypeModalOpen,
  setIsVideoContentTypeModalOpen,
  handleResourceUploadClick
}) => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [role, setRole] = useState<string>('');
  const handleAddLiveStreamClick = () => {
    navigate(`/addlivestream`, { state: { channelId: id } });
  };
  const handleVideoUploadClick = (status: number) => {
    navigate(`/add-video/${status}`, { state: { channelIds: id } });
  };
  const handleRecordingClick = (status: number) => {
    if (role === 'Super Admin') {
      navigate(`/add-video/${status}`, { state: { channelIds: id } });
    }
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    setRole(finalData.role);
  }, []);
  return (
    <div>
      <div
        className={`videoViews modal fade ${isVideoContentTypeModalOpen ? 'show' : ''}`}
        id="videoContentTypeModal"
        role="dialog"
        aria-labelledby="videoContentTypeLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="videoContentTypeLabel">
                Add New
              </h5>
              <button
                onClick={() => setIsVideoContentTypeModalOpen(false)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="wrapper flex  mt-8 mb-8 ">
                <div className="box-wrapper" onClick={() => handleVideoUploadClick(0)}>
                  <i className="fa-solid fa-video fa-3x"></i>
                  <h6>Video</h6>
                </div>
                <div className="box-wrapper" onClick={handleResourceUploadClick}>
                  <i className="fa-solid fa-file fa-3x"></i>
                  <div className="flex gap-2">
                    <h6>Resource</h6>
                    <button type="button" className="btn-primary discard-btn mt-1 pt-1 pb-1">
                      BETA
                    </button>
                  </div>
                </div>
              </div>
              <div className="wrapper flex  mt-8 mb-8 ">
                <div
                  style={{ backgroundColor: `${role !== 'Super Admin' ? 'lightgrey' : ''}` }}
                  className={`box-wrapper ${
                    role !== 'Super Admin' ? 'opacity-50 cursor-not-allowed bg-zinc-300' : ''
                  }`}
                  onClick={() => handleRecordingClick(7)}>
                  <i className="fa-solid fas fa-record-vinyl fa-3x"></i>
                  <h6>Recording</h6>
                </div>

                <div className="box-wrapper" onClick={handleAddLiveStreamClick}>
                  <i className="fa-solid fas fa-broadcast-tower fa-3x"></i>
                  <h6>Live Stream</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoContentTypeModal;
