import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import Loader from 'src/components/loader';
import { useParams } from 'react-router-dom';
import moment from 'moment';
const DiscountCodeModel = ({ discountCodeEditFlag }: any) => {
  const dispatch = useDispatch();
  const videoActions = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();
  const { isDiscountCodeAdded, singleDiscountCode } = useSelector((state: RootState) => {
    return {
      isDiscountCodeAdded: state.videos.isDiscountCodeAdded,
      singleDiscountCode: state.videos.singleDiscountCode
    };
  });
  useEffect(() => {
    if (isDiscountCodeAdded) {
      if (ref.current) {
        ref.current.click();
        handleCloseClick();
      }
    }
  }, [isDiscountCodeAdded]);

  const onSubmit = (data: any) => {
    if (discountCodeEditFlag) {
      const payloadData = {
        discountCodeId: data.id,
        updateData: [
          { op: 'replace', path: 'discountCode', value: data.discountCode },
          { op: 'replace', path: 'discountPercentage', value: data.discountPercentage },
          { op: 'replace', path: 'expiryDate', value: data.expiryDate }
        ]
      };
      videoActions.updateDiscountCodeRequest(payloadData);
    } else {
      if (id) {
        const payloadData = {
          videoId: id,
          discountCode: data.discountCode,
          discountPercentage: data.discountPercentage,
          expiryDate: data.expiryDate ? data.expiryDate : ''
        };
        videoActions.saveDiscountCodeRequest(payloadData);
      }
    }
  };

  const handleCloseClick = () => {
    reset({
      discountCode: '',
      discountPercentage: '',
      expiryDate: ''
    });
  };
  useEffect(() => {
    if (singleDiscountCode && discountCodeEditFlag) {
      reset(singleDiscountCode);
      setValue('expiryDate', moment(singleDiscountCode.expiryDate).format('YYYY-MM-DD'));
    }
  }, [singleDiscountCode]);

  useEffect(() => {
    if (isDiscountCodeAdded) {
      const payloadData = { id, pageNumber: 1, pageSize: 10 };
      id && videoActions.getDiscountCodeRequest(payloadData);
    }
  }, [isDiscountCodeAdded]);
  return (
    <div>
      <div
        className="modal fade"
        id="AddDiscountCode"
        aria-labelledby="AddDiscountCodeLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddDiscountCodeLabel">
                {discountCodeEditFlag ? 'Update' : 'Add'} Discount Code
              </h5>
              <button
                onClick={() => handleCloseClick()}
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm  mb-1 font-extrabold">Discount Code</label>
                  <p>
                    The discount code is used at checkout. It will apply the % to the price on the
                    checkout page.
                  </p>
                  <FormInputField
                    label={''}
                    type={'text'}
                    fieldName={'discountCode'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>

                <div className={`form-group mb-3`}>
                  <label className="block text-sm  mb-1 font-extrabold">Discount (%)</label>
                  {/* <FormInputField
                    label={''}
                    type={'text'}
                    fieldName={'discountPercentage'}
                    register={register}
                    errors={errors}
                    isRequired={false}
                    pattern="/^\d*\.?\d*$/"
                  /> */}
                  <input
                    type="text"
                    className="input-style"
                    {...register('discountPercentage', {
                      required: true,
                      pattern: /^\d*\.?\d*$/
                    })}
                  />
                  {errors.discountPercentage?.type === 'required' && (
                    <span className="text-red-500 text-xs flex item-center error">
                      Required Field
                    </span>
                  )}
                  {errors.discountPercentage?.type === 'pattern' && (
                    <span className="text-red-500 text-xs flex item-center error">
                      Only numbers are allowed
                    </span>
                  )}
                </div>

                <div className={`form-group mb-3`}>
                  <label className="block text-sm  mb-1 font-extrabold">Expire By</label>
                  <p>If the date is past due, the discount is no longer valid.</p>
                  <input type="date" className="input-style" {...register('expiryDate')} />
                </div>

                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    onClick={() => handleCloseClick()}
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountCodeModel;
