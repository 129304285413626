import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';

import { DiscountPolicyModel, PatchJsonModel, DiscountCodeModel, MemberListModel, PackageModel, VoucherModel, BundleModel, SubscriptionModel } from 'src/models/monetizationModel';

export namespace MonetizationActions {

    export type getDiscountPolicyAction = Action<typeof Constants.getDiscountPoliciesActionType, null>
    export type getDiscountPolicySuccessAction = Action<typeof Constants.getDiscountPoliciesSuccessActionType, any>
    export type getDiscountPolicyFailureAction = Action<typeof Constants.getDiscountsPoliciesFailureActionType, null>

    export function getDiscountPolicies(): getDiscountPolicyAction {
        return ActionCreator(Constants.getDiscountPoliciesActionType, null);
    }

    export function getDiscountPoliciesFailure(result : any): getDiscountPolicyFailureAction {
        return ActionCreator(Constants.getDiscountsPoliciesFailureActionType, result);
    }

    export function getDiscountPoliciesSuccess(data : any): getDiscountPolicySuccessAction {
        return ActionCreator(Constants.getDiscountPoliciesSuccessActionType, data);
    }

    export type getDiscountDetailsAction = Action<typeof Constants.getDiscountDetailRequestActionType, string>;
    export type getDiscountDetailsSuccessAction = Action<typeof Constants.getDiscountDetailRequestSuccessActionType, any>
    export type getDiscountDetailsFailureAction = Action<typeof Constants.getDiscountDetailRequestFailureActionType, null>

    export function getDiscountDetails(D: string): getDiscountDetailsAction {
        return ActionCreator(Constants.getDiscountDetailRequestActionType, D);
    }

    export function getDiscountDetailsSuccess (D: any): getDiscountDetailsSuccessAction {
        return ActionCreator(Constants.getDiscountDetailRequestSuccessActionType, D);
    }

    export function getDiscountDetailsFailure(): getDiscountDetailsFailureAction {
        return ActionCreator(Constants.getDiscountDetailRequestFailureActionType, null);
    }

    export type addDiscountPolicyRequestAction = Action<typeof Constants.addDiscountPolicyRequestActionType, DiscountPolicyModel>
    export type addDiscountPolicyRequestSuccessAction = Action<typeof Constants.addDiscountPolicyRequestSuccessActionType, null>;
    export type addDiscountPolicyRequestFailureAction = Action<typeof Constants.addDiscountPolicyRequestFailureActionType, null>;

    export function addDiscountPolicyRequest(D: DiscountPolicyModel): addDiscountPolicyRequestAction {
        return ActionCreator(Constants.addDiscountPolicyRequestActionType, D);
    }
    export function addDiscountPolicyRequestSuccess(): any {
        return ActionCreator(Constants.addDiscountPolicyRequestSuccessActionType, null);
    }
    export function addDiscountPolicyRequestFailure(): any {
        return ActionCreator(Constants.addDiscountPolicyRequestFailureActionType, null);
    }

    export type deleteDiscountRequestAction = Action<typeof Constants.deleteDiscountPolicyRequestActionType , string>
    export type deleteDiscountRequestSuccessAction = Action<typeof Constants.deleteDiscountPolicyRequestSuccessActionType, null>
    export type deleteDiscountRequestFailureAction = Action<typeof Constants.deleteDiscountPolicyRequestFailureActionType, null>

    export function deleteDiscountRequest(P: string) : deleteDiscountRequestAction {
        return ActionCreator(Constants.deleteDiscountPolicyRequestActionType, P);
    }

    export function deleteDiscountRequestSuccess() : deleteDiscountRequestSuccessAction {
        return ActionCreator(Constants.deleteDiscountPolicyRequestSuccessActionType, null);
    }

    export function deleteDiscountRequestFailure() : deleteDiscountRequestFailureAction {
        return ActionCreator(Constants.deleteDiscountPolicyRequestFailureActionType, null);
    }

    export type editDiscountRequestAction = Action<typeof Constants.editDiscountPolicyRequestActionType, PatchJsonModel>;
    export type editDiscountRequestSuccessAction = Action<typeof Constants.editDiscountPolicyRequestSuccessActionType, any>;
    export type editDiscountRequestFailureAction = Action<typeof Constants.editDiscountPolicyRequestFailureActionType, null>;

    export function editDiscountRequest(P: PatchJsonModel) : editDiscountRequestAction {
        return ActionCreator(Constants.editDiscountPolicyRequestActionType, P);
    }
    export function editDiscountRequestSuccess(data : any) : editDiscountRequestSuccessAction {
        return ActionCreator(Constants.editDiscountPolicyRequestSuccessActionType, data);
    }
    export function editDiscountRequestFailure() : editDiscountRequestFailureAction {
        return ActionCreator(Constants.editDiscountPolicyRequestFailureActionType, null);
    }

    export type addDiscountCodeRequestAction = Action<typeof Constants.addDiscountCodeRequestActionType, DiscountCodeModel>;
    export type addDiscountCodeRequestSuccessAction = Action<typeof Constants.addDiscountCodeRequestSuccessActionType, any>;
    export type addDiscountCodeRequestFailureAction = Action<typeof Constants.addDiscountCodeRequestFailureActionType, null>;

    export function addDiscountCodeRequest(P: DiscountCodeModel): addDiscountCodeRequestAction {
        return ActionCreator(Constants.addDiscountCodeRequestActionType, P);
    }

    export function addDiscountCodeRequestSuccess(data: any) : addDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.addDiscountCodeRequestSuccessActionType, data);
    }
    export function addDiscountCodeRequestFailure() : addDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.addDiscountCodeRequestFailureActionType,null);
    }
    
    export type deleteDiscountCodeRequestAction = Action<typeof Constants.deleteDiscountCodeRequestActionType, DiscountCodeModel>;
    export type deleteDiscountCodeRequestSuccessAction = Action<typeof Constants.deleteDiscountCodeRequestSuccessActionType, any>;
    export type deleteDiscountCodeRequestFailureAction = Action<typeof Constants.deleteDiscountCodeRequestFailureActionType, null>;

    export function deleteDiscountCodeRequest(P: DiscountCodeModel) : deleteDiscountCodeRequestAction {
        return ActionCreator(Constants.deleteDiscountCodeRequestActionType, P);
    }
    export function deleteDiscountCodeRequestSuccess(data: any) : deleteDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.deleteDiscountCodeRequestSuccessActionType, data);
    }
    export function deleteDiscountCodeRequestFailure() : deleteDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.deleteDiscountCodeRequestFailureActionType, null);
    }   

    export type resetDiscountPolicyRequestAction = Action<typeof Constants.resetDiscountPolicyStateActionType, null>
    export function resetDiscountPolicyRequest(): resetDiscountPolicyRequestAction {
        return ActionCreator(Constants.resetDiscountPolicyStateActionType, null);
    }   

    export type resetDiscountPolicyDataStateRequestAction = Action<typeof Constants.resetDiscountPolicyDataStateActionType, null>
    export function resetDiscountPolicyDataStateRequest(): resetDiscountPolicyDataStateRequestAction {
        return ActionCreator(Constants.resetDiscountPolicyDataStateActionType, null);
    }

    export type addDiscountMemberListRequestAction = Action<typeof Constants.addDiscountMemberListRequestActionType, MemberListModel>;
    export type addDiscountMemberListRequestSuccessAction = Action<typeof Constants.addDiscountMemberListRequestSuccessActionType, any>;
    export type addDiscountMemberListRequestFailureAction = Action<typeof Constants.addDiscountMemberListRequestFailureActionType, null>;

    export function addDiscountMemberListRequest(P: MemberListModel) : addDiscountMemberListRequestAction {
        return ActionCreator(Constants.addDiscountMemberListRequestActionType, P);
    }
    export function addDiscountMemberListRequestSuccess(data: any) : addDiscountMemberListRequestSuccessAction {
        return ActionCreator(Constants.addDiscountMemberListRequestSuccessActionType, data);
    }
    export function addDiscountMemberListRequestFailure() : addDiscountMemberListRequestFailureAction {
        return ActionCreator(Constants.addDiscountMemberListRequestFailureActionType, null);
    }
    
    export type deleteDiscountMemberListRequestAction = Action<typeof Constants.deleteDiscountMemberListRequestActionType, MemberListModel>;
    export type deleteDiscountMemberListRequestSuccessAction = Action<typeof Constants.deleteDiscountMemberListRequestSuccessActionType, any>;
    export type deleteDiscountMemberListRequestFailureAction = Action<typeof Constants.deleteDiscountMemberListRequestFailureActionType, null>;

    export function deleteDiscountMemberListRequest(P: MemberListModel) : deleteDiscountMemberListRequestAction {
        return ActionCreator(Constants.deleteDiscountMemberListRequestActionType, P);
    }
    export function deleteDiscountMemberListRequestSuccess(data: any) : deleteDiscountMemberListRequestSuccessAction {
        return ActionCreator(Constants.deleteDiscountMemberListRequestSuccessActionType, data);
    }
    export function deleteDiscountMemberListRequestFailure() : deleteDiscountMemberListRequestFailureAction {
        return ActionCreator(Constants.deleteDiscountMemberListRequestFailureActionType, null);
    }

    //Packages

    export type getPackageAction = Action<typeof Constants.getPackagesActionType, null>
    export type getPackageSuccessAction = Action<typeof Constants.getPackagesSuccessActionType, any>
    export type getPackageFailureAction = Action<typeof Constants.getPackagesFailureActionType, null>

    export function getPackages(): getPackageAction {
        return ActionCreator(Constants.getPackagesActionType, null);
    }
    export function getPackagesSuccess(data : any): getPackageSuccessAction {
        return ActionCreator(Constants.getPackagesSuccessActionType, data);
    }
    export function getPackagesFailure(result : any): getPackageFailureAction {
        return ActionCreator(Constants.getPackagesFailureActionType, result);
    }
    

    export type getPackageDetailsAction = Action<typeof Constants.getPackageDetailsRequestActionType, string>;
    export type getPackageDetailsSuccessAction = Action<typeof Constants.getPackageDetailsRequestSuccessActionType, any>
    export type getPackageDetailsFailureAction = Action<typeof Constants.getPackageDetailsRequestFailureActionType, null>

    export function getPackageDetails(D: string): getPackageDetailsAction {
        return ActionCreator(Constants.getPackageDetailsRequestActionType, D);
    }

    export function getPackageDetailsSuccess (D: any): getPackageDetailsSuccessAction {
        return ActionCreator(Constants.getPackageDetailsRequestSuccessActionType, D);
    }

    export function getPackageDetailsFailure(): getPackageDetailsFailureAction {
        return ActionCreator(Constants.getPackageDetailsRequestFailureActionType, null);
    }

    export type addPackageRequestAction = Action<typeof Constants.addPackageRequestActionType, PackageModel>
    export type addPackageRequestSuccessAction = Action<typeof Constants.addPackageRequestSuccessActionType, null>;
    export type addPackageRequestFailureAction = Action<typeof Constants.addPackageRequestFailureActionType, null>;

    export function addPackageRequest(D: PackageModel): addPackageRequestAction {
        return ActionCreator(Constants.addPackageRequestActionType, D);
    }
    export function addPackageRequestSuccess(): any {
        return ActionCreator(Constants.addPackageRequestSuccessActionType, null);
    }
    export function addPackageRequestFailure(): any {
        return ActionCreator(Constants.addPackageRequestFailureActionType, null);
    }

    export type editPackageRequestAction = Action<typeof Constants.editPackageRequestActionType, PatchJsonModel>;
    export type editPackageRequestSuccessAction = Action<typeof Constants.editPackageRequestSuccessActionType, any>;
    export type editPackageRequestFailureAction = Action<typeof Constants.editPackageRequestFailureActionType, null>;

    export function editPackageRequest(P: PatchJsonModel) : editPackageRequestAction {
        return ActionCreator(Constants.editPackageRequestActionType, P);
    }
    export function editPackageRequestSuccess(data : any) : editPackageRequestSuccessAction {
        return ActionCreator(Constants.editPackageRequestSuccessActionType, data);
    }
    export function editPackageRequestFailure() : editPackageRequestFailureAction {
        return ActionCreator(Constants.editPackageRequestFailureActionType, null);
    }

    export type deletePackageRequestAction = Action<typeof Constants.deletePackageRequestActionType , string>
    export type deletePackageRequestSuccessAction = Action<typeof Constants.deletePackageRequestSuccessActionType, null>
    export type deletePackageRequestFailureAction = Action<typeof Constants.deletePackageRequestFailureActionType, null>

    export function deletePackageRequest(P: string) : deletePackageRequestAction {
        return ActionCreator(Constants.deletePackageRequestActionType, P);
    }

    export function deletePackageRequestSuccess() : deletePackageRequestSuccessAction {
        return ActionCreator(Constants.deletePackageRequestSuccessActionType, null);
    }

    export function deletePackageRequestFailure() : deletePackageRequestFailureAction {
        return ActionCreator(Constants.deletePackageRequestFailureActionType, null);
    }
    
    export type resetPackagePolicyRequestAction = Action<typeof Constants.resetPackagePolicyStateActionType, null>
    export function resetPackagePolicyRequest(): resetPackagePolicyRequestAction {
        return ActionCreator(Constants.resetPackagePolicyStateActionType, null);
    }   

    export type resetPackagePolicyDataStateRequestAction = Action<typeof Constants.resetPackagePolicyDataStateActionType, null>
    export function resetPackagePolicyDataStateRequest(): resetPackagePolicyDataStateRequestAction {
        return ActionCreator(Constants.resetPackagePolicyDataStateActionType, null);
    }

    export type addPackageMemberListRequestAction = Action<typeof Constants.addPackageMemberListRequestActionType, MemberListModel>;
    export type addPackageMemberListRequestSuccessAction = Action<typeof Constants.addPackageMemberListRequestSuccessActionType, any>;
    export type addPackageMemberListRequestFailureAction = Action<typeof Constants.addPackageMemberListRequestFailureActionType, null>;

    export function addPackageMemberListRequest(P: MemberListModel) : addPackageMemberListRequestAction {
        return ActionCreator(Constants.addPackageMemberListRequestActionType, P);
    }
    export function addPackageMemberListRequestSuccess(data: any) : addPackageMemberListRequestSuccessAction {
        return ActionCreator(Constants.addPackageMemberListRequestSuccessActionType, data);
    }
    export function addPackageMemberListRequestFailure() : addPackageMemberListRequestFailureAction {
        return ActionCreator(Constants.addPackageMemberListRequestFailureActionType, null);
    }
    
    export type deletePackageMemberListRequestAction = Action<typeof Constants.deletePackageMemberListRequestActionType, MemberListModel>;
    export type deletePackageMemberListRequestSuccessAction = Action<typeof Constants.deletePackageMemberListRequestSuccessActionType, any>;
    export type deletePackageMemberListRequestFailureAction = Action<typeof Constants.deletePackageMemberListRequestFailureActionType, null>;

    export function deletePackageMemberListRequest(P: MemberListModel) : deletePackageMemberListRequestAction {
        return ActionCreator(Constants.deletePackageMemberListRequestActionType, P);
    }
    export function deletePackageMemberListRequestSuccess(data: any) : deletePackageMemberListRequestSuccessAction {
        return ActionCreator(Constants.deletePackageMemberListRequestSuccessActionType, data);
    }
    export function deletePackageMemberListRequestFailure() : deletePackageMemberListRequestFailureAction {
        return ActionCreator(Constants.deletePackageMemberListRequestFailureActionType, null);
    }
    
    
    //Vouchers
    export type getVoucherAction = Action<typeof Constants.getVouchersActionType, null>
    export type getVoucherSuccessAction = Action<typeof Constants.getVouchersSuccessActionType, any>
    export type getVoucherFailureAction = Action<typeof Constants.getVouchersFailureActionType, null>

    export function getVouchers(): getVoucherAction {
        return ActionCreator(Constants.getVouchersActionType, null);
    }
    export function getVouchersSuccess(data : any): getVoucherSuccessAction {
        return ActionCreator(Constants.getVouchersSuccessActionType, data);
    }
    export function getVouchersFailure(result : any): getVoucherFailureAction {
        return ActionCreator(Constants.getVouchersFailureActionType, result);
    }

    export type getVoucherDetailsAction = Action<typeof Constants.getVoucherDetailsRequestActionType, string>;
    export type getVoucherDetailsSuccessAction = Action<typeof Constants.getVoucherDetailsRequestSuccessActionType, any>
    export type getVoucherDetailsFailureAction = Action<typeof Constants.getVoucherDetailsRequestFailureActionType, null>

    export function getVoucherDetails(D: string): getVoucherDetailsAction {
        return ActionCreator(Constants.getVoucherDetailsRequestActionType, D);
    }

    export function getVoucherDetailsSuccess (D: any): getVoucherDetailsSuccessAction {
        return ActionCreator(Constants.getVoucherDetailsRequestSuccessActionType, D);
    }

    export function getVoucherDetailsFailure(): getVoucherDetailsFailureAction {
        return ActionCreator(Constants.getVoucherDetailsRequestFailureActionType, null);
    }

    export type addVoucherRequestAction = Action<typeof Constants.addVoucherRequestActionType, VoucherModel>
    export type addVoucherRequestSuccessAction = Action<typeof Constants.addVoucherRequestSuccessActionType, null>;
    export type addVoucherRequestFailureAction = Action<typeof Constants.addVoucherRequestFailureActionType, null>;

    export function addVoucherRequest(D: VoucherModel): addVoucherRequestAction {
        return ActionCreator(Constants.addVoucherRequestActionType, D);
    }
    export function addVoucherRequestSuccess(): any {
        return ActionCreator(Constants.addVoucherRequestSuccessActionType, null);
    }
    export function addVoucherRequestFailure(): any {
        return ActionCreator(Constants.addVoucherRequestFailureActionType, null);
    }

    export type editVoucherRequestAction = Action<typeof Constants.editVoucherRequestActionType, PatchJsonModel>;
    export type editVoucherRequestSuccessAction = Action<typeof Constants.editVoucherRequestSuccessActionType, any>;
    export type editVoucherRequestFailureAction = Action<typeof Constants.editVoucherRequestFailureActionType, null>;

    export function editVoucherRequest(P: PatchJsonModel) : editVoucherRequestAction {
        return ActionCreator(Constants.editVoucherRequestActionType, P);
    }
    export function editVoucherRequestSuccess(data : any) : editVoucherRequestSuccessAction {
        return ActionCreator(Constants.editVoucherRequestSuccessActionType, data);
    }
    export function editVoucherRequestFailure() : editVoucherRequestFailureAction {
        return ActionCreator(Constants.editVoucherRequestFailureActionType, null);
    }

    export type deleteVoucherRequestAction = Action<typeof Constants.deleteVoucherRequestActionType , string>
    export type deleteVoucherRequestSuccessAction = Action<typeof Constants.deleteVoucherRequestSuccessActionType, null>
    export type deleteVoucherRequestFailureAction = Action<typeof Constants.deleteVoucherRequestFailureActionType, null>

    export function deleteVoucherRequest(P: string) : deleteVoucherRequestAction {
        return ActionCreator(Constants.deleteVoucherRequestActionType, P);
    }

    export function deleteVoucherRequestSuccess() : deleteVoucherRequestSuccessAction {
        return ActionCreator(Constants.deleteVoucherRequestSuccessActionType, null);
    }

    export function deleteVoucherRequestFailure() : deleteVoucherRequestFailureAction {
        return ActionCreator(Constants.deleteVoucherRequestFailureActionType, null);
    }
    
    export type resetVoucherPolicyRequestAction = Action<typeof Constants.resetVoucherPolicyStateActionType, null>
    export function resetVoucherPolicyRequest(): resetVoucherPolicyRequestAction {
        return ActionCreator(Constants.resetVoucherPolicyStateActionType, null);
    }   

    export type resetVoucherPolicyDataStateRequestAction = Action<typeof Constants.resetVoucherPolicyDataStateActionType, null>
    export function resetVoucherPolicyDataStateRequest(): resetVoucherPolicyDataStateRequestAction {
        return ActionCreator(Constants.resetVoucherPolicyDataStateActionType, null);
    }

    export type editVoucherStatusRequestAction = Action<typeof Constants.updateVoucherStatusRequestActionType, any>;
    export type editVoucherStatusRequestSuccessAction = Action<typeof Constants.updateVoucherStatusRequestSuccessActionType, any>;
    export type editVoucherStatusRequestFailureAction = Action<typeof Constants.updateVoucherStatusRequestFailureActionType, null>;

    export function editVoucherStatusRequest(P: any, ) : editVoucherStatusRequestAction {
        return ActionCreator(Constants.updateVoucherStatusRequestActionType, P);
    }
    export function editVoucherStatusRequestSuccess(data: any) : editVoucherStatusRequestSuccessAction {
        return ActionCreator(Constants.updateVoucherStatusRequestSuccessActionType, data);
    }
    export function editVoucherStatusRequestFailure() : editVoucherStatusRequestFailureAction {
        return ActionCreator(Constants.updateVoucherStatusRequestFailureActionType, null);
    }
    
    export type getVoucherUsageRequestAction = Action<typeof Constants.getVoucherUsageRequestActionType, string>;
    export type getVoucherUsageRequestSuccessAction = Action<typeof Constants.getVoucherUsageRequestSuccessActionType, any>;
    export type getVoucherUsageRequestFailureAction = Action<typeof Constants.getVoucherUsageRequestFailureActionType, null>;

    export function getVoucherUsage(voucherId: string) : getVoucherUsageRequestAction {
        return ActionCreator(Constants.getVoucherUsageRequestActionType, voucherId);
    }
    export function getVoucherUsageSuccess(data: any) : getVoucherUsageRequestSuccessAction {
        return ActionCreator(Constants.getVoucherUsageRequestSuccessActionType, data);
    }
    export function getVoucherUsageFailure() : getVoucherUsageRequestFailureAction {
        return ActionCreator(Constants.getVoucherUsageRequestFailureActionType, null);
    }

    //Bundles
    export type getBundlesAction = Action<typeof Constants.getBundlesActionType, null>
    export type getBundlesSuccessAction = Action<typeof Constants.getBundlesSuccessActionType, any>
    export type getBundlesFailureAction = Action<typeof Constants.getBundlesFailureActionType, null>

    export function getBundles(): getBundlesAction {
        return ActionCreator(Constants.getBundlesActionType, null);
    }
    export function getBundlesSuccess(data : any): getBundlesSuccessAction {
        return ActionCreator(Constants.getBundlesSuccessActionType, data);
    }
    export function getBundlesFailure(result : any): getBundlesFailureAction {
        return ActionCreator(Constants.getBundlesFailureActionType, result);
    }    

    export type getBundleDetailsAction = Action<typeof Constants.getBundleDetailsRequestActionType, string>;
    export type getBundleDetailsSuccessAction = Action<typeof Constants.getBundleDetailsRequestSuccessActionType, any>
    export type getBundleDetailsFailureAction = Action<typeof Constants.getBundleDetailsRequestFailureActionType, null>

    export function getBundleDetails(D: string): getBundleDetailsAction {
        return ActionCreator(Constants.getBundleDetailsRequestActionType, D);
    }

    export function getBundleDetailsSuccess (D: any): getBundleDetailsSuccessAction {
        return ActionCreator(Constants.getBundleDetailsRequestSuccessActionType, D);
    }

    export function getBundleDetailsFailure(): getBundleDetailsFailureAction {
        return ActionCreator(Constants.getBundleDetailsRequestFailureActionType, null);
    }

    export type addBundleRequestAction = Action<typeof Constants.addBundleRequestActionType, BundleModel>
    export type adBundleRequestSuccessAction = Action<typeof Constants.addBundleRequestSuccessActionType, null>;
    export type addBundleRequestFailureAction = Action<typeof Constants.addBundleRequestFailureActionType, null>;

    export function addBundleRequest(D: BundleModel): addBundleRequestAction {
        return ActionCreator(Constants.addBundleRequestActionType, D);
    }
    export function addBundleRequestSuccess(): any {
        return ActionCreator(Constants.addBundleRequestSuccessActionType, null);
    }
    export function addBundleRequestFailure(): any {
        return ActionCreator(Constants.addBundleRequestFailureActionType, null);
    }

    export type editBundleRequestAction = Action<typeof Constants.editBundleRequestActionType, PatchJsonModel>;
    export type editBundleRequestSuccessAction = Action<typeof Constants.editBundleRequestSuccessActionType, any>;
    export type editBundleRequestFailureAction = Action<typeof Constants.editBundleRequestFailureActionType, null>;

    export function editBundleRequest(P: PatchJsonModel) : editBundleRequestAction {
        return ActionCreator(Constants.editBundleRequestActionType, P);
    }
    export function editBundleRequestSuccess(data : any) : editBundleRequestSuccessAction {
        return ActionCreator(Constants.editBundleRequestSuccessActionType, data);
    }
    export function editBundleRequestFailure() : editBundleRequestFailureAction {
        return ActionCreator(Constants.editBundleRequestFailureActionType, null);
    }

    export type deleteBundleRequestAction = Action<typeof Constants.deleteBundleRequestActionType , string>
    export type deleteBundleRequestSuccessAction = Action<typeof Constants.deleteBundleRequestSuccessActionType, null>
    export type deleteBundleRequestFailureAction = Action<typeof Constants.deleteBundleRequestFailureActionType, null>

    export function deleteBundleRequest(P: string) : deleteBundleRequestAction {
        return ActionCreator(Constants.deleteBundleRequestActionType, P);
    }

    export function deleteBundleRequestSuccess() : deleteBundleRequestSuccessAction {
        return ActionCreator(Constants.deleteBundleRequestSuccessActionType, null);
    }

    export function deleteBundleRequestFailure() : deleteBundleRequestFailureAction {
        return ActionCreator(Constants.deleteBundleRequestFailureActionType, null);
    }
    
    export type resetBundlePolicyRequestAction = Action<typeof Constants.resetBundlePolicyStateActionType, null>
    export function resetBundlePolicyRequest(): resetBundlePolicyRequestAction {
        return ActionCreator(Constants.resetBundlePolicyStateActionType, null);
    }   

    export type resetBundlePolicyDataStateRequestAction = Action<typeof Constants.resetBundlePolicyDataStateActionType, null>
    export function resetBundlePolicyDataStateRequest(): resetBundlePolicyDataStateRequestAction {
        return ActionCreator(Constants.resetBundlePolicyDataStateActionType, null);
    }

    export type addBundleMemberListRequestAction = Action<typeof Constants.addBundleMemberListRequestActionType, MemberListModel>;
    export type addBundleMemberListRequestSuccessAction = Action<typeof Constants.addBundleMemberListRequestSuccessActionType, any>;
    export type addBundleMemberListRequestFailureAction = Action<typeof Constants.addBundleMemberListRequestFailureActionType, null>;

    export function addBundleMemberListRequest(P: MemberListModel) : addBundleMemberListRequestAction {
        return ActionCreator(Constants.addBundleMemberListRequestActionType, P);
    }
    export function addBundleMemberListRequestSuccess(data: any) : addBundleMemberListRequestSuccessAction {
        return ActionCreator(Constants.addBundleMemberListRequestSuccessActionType, data);
    }
    export function addBundleMemberListRequestFailure() : addBundleMemberListRequestFailureAction {
        return ActionCreator(Constants.addBundleMemberListRequestFailureActionType, null);
    }
    
    export type deleteBundleMemberListRequestAction = Action<typeof Constants.deleteBundleMemberListRequestActionType, MemberListModel>;
    export type deleteBundleMemberListRequestSuccessAction = Action<typeof Constants.deleteBundleMemberListRequestSuccessActionType, any>;
    export type deleteBundleMemberListRequestFailureAction = Action<typeof Constants.deleteBundleMemberListRequestFailureActionType, null>;

    export function deleteBundleMemberListRequest(P: MemberListModel) : deleteBundleMemberListRequestAction {
        return ActionCreator(Constants.deleteBundleMemberListRequestActionType, P);
    }
    export function deleteBundleMemberListRequestSuccess(data: any) : deleteBundleMemberListRequestSuccessAction {
        return ActionCreator(Constants.deleteBundleMemberListRequestSuccessActionType, data);
    }
    export function deleteBundleMemberListRequestFailure() : deleteBundleMemberListRequestFailureAction {
        return ActionCreator(Constants.deleteBundleMemberListRequestFailureActionType, null);
    }
    //Subscription
    export type getSubscriptionsAction = Action<typeof Constants.getSubscriptionsActionType, null>
    export type getSubscriptionsSuccessAction = Action<typeof Constants.getSubscriptionsSuccessActionType, any>
    export type getSubscriptionsFailureAction = Action<typeof Constants.getSubscriptionsFailureActionType, null>

    export function getSubscriptions(): getSubscriptionsAction {
        return ActionCreator(Constants.getSubscriptionsActionType, null);
    }
    export function getSubscriptionsSuccess(data : any): getSubscriptionsSuccessAction {
        return ActionCreator(Constants.getSubscriptionsSuccessActionType, data);
    }
    export function getSubscriptionsFailure(result : any): getSubscriptionsFailureAction {
        return ActionCreator(Constants.getSubscriptionsFailureActionType, result);
    }

    export type getSubscriptionDetailsAction = Action<typeof Constants.getSubscriptionDetailsRequestActionType, string>;
    export type getSubscriptionDetailsSuccessAction = Action<typeof Constants.getSubscriptionDetailsRequestSuccessActionType, any>
    export type getSubscriptionDetailsFailureAction = Action<typeof Constants.getSubscriptionDetailsRequestFailureActionType, null>

    export function getSubscriptionDetails(D: string): getSubscriptionDetailsAction {
        return ActionCreator(Constants.getSubscriptionDetailsRequestActionType, D);
    }

    export function getSubscriptionDetailsSuccess (D: any): getSubscriptionDetailsSuccessAction {
        return ActionCreator(Constants.getSubscriptionDetailsRequestSuccessActionType, D);
    }
    
    export function getSubscriptionDetailsFailure(): getSubscriptionDetailsFailureAction {
        return ActionCreator(Constants.getSubscriptionDetailsRequestFailureActionType, null);
    }

    export type addSubscriptionRequestAction = Action<typeof Constants.addSubscriptionRequestActionType, SubscriptionModel>
    export type addSubscriptionRequestSuccessAction = Action<typeof Constants.addSubscriptionRequestSuccessActionType, null>;
    export type addSubscriptionRequestFailureAction = Action<typeof Constants.addSubscriptionRequestFailureActionType, null>;

    export function addSubscriptionRequest(D: SubscriptionModel): addSubscriptionRequestAction {
        return ActionCreator(Constants.addSubscriptionRequestActionType, D);
    }
    export function addSubscriptionRequestSuccess(): any {
        return ActionCreator(Constants.addSubscriptionRequestSuccessActionType, null);
    }
    export function addSubscriptionRequestFailure(): any {
        return ActionCreator(Constants.addSubscriptionRequestFailureActionType, null);
    }

    export type editSubscriptionRequestAction = Action<typeof Constants.editSubscriptionRequestActionType, PatchJsonModel>;
    export type editSubscriptionRequestSuccessAction = Action<typeof Constants.editSubscriptionRequestSuccessActionType, any>;
    export type editSubscriptionRequestFailureAction = Action<typeof Constants.editSubscriptionRequestFailureActionType, null>;

    export function editSubscriptionRequest(P: PatchJsonModel) : editSubscriptionRequestAction {
        return ActionCreator(Constants.editSubscriptionRequestActionType, P);
    }
    export function editSubscriptionRequestSuccess(data : any) : editSubscriptionRequestSuccessAction {
        return ActionCreator(Constants.editSubscriptionRequestSuccessActionType, data);
    }
    export function editSubscriptionRequestFailure() : editSubscriptionRequestFailureAction {
        return ActionCreator(Constants.editSubscriptionRequestFailureActionType, null);
    }

    export type deleteSubscriptionRequestAction = Action<typeof Constants.deleteSubscriptionRequestActionType , string>
    export type deleteSubscriptionRequestSuccessAction = Action<typeof Constants.deleteSubscriptionRequestSuccessActionType, null>
    export type deleteSubscriptionRequestFailureAction = Action<typeof Constants.deleteSubscriptionRequestFailureActionType, null>

    export function deleteSubscriptionRequest(P: string) : deleteSubscriptionRequestAction {
        return ActionCreator(Constants.deleteSubscriptionRequestActionType, P);
    }

    export function deleteSubscriptionRequestSuccess() : deleteSubscriptionRequestSuccessAction {
        return ActionCreator(Constants.deleteSubscriptionRequestSuccessActionType, null);
    }

    export function deleteSubscriptionRequestFailure() : deleteSubscriptionRequestFailureAction {
        return ActionCreator(Constants.deleteSubscriptionRequestFailureActionType, null);
    }
    

    export type getSubscriptionSubscribersAction = Action<typeof Constants.getSubscriptionSubscribersRequestActionType, string>;
    export type getSubscriptionSubscribersSuccessAction = Action<typeof Constants.getSubscriptionSubscribersRequestSuccessActionType, any>
    export type getSubscriptionSubscribersFailureAction = Action<typeof Constants.getSubscriptionSubscribersRequestFailureActionType, null>

    export function getSubscriptionSubscribers(D: string): getSubscriptionSubscribersAction {
        return ActionCreator(Constants.getSubscriptionSubscribersRequestActionType, D);
    }

    export function getSubscriptionSubscribersSuccess (D: any): getSubscriptionSubscribersSuccessAction {
        return ActionCreator(Constants.getSubscriptionSubscribersRequestSuccessActionType, D);
    }    
    
    export function getSubscriptionSubscribersFailure(): getSubscriptionSubscribersFailureAction {
        return ActionCreator(Constants.getSubscriptionSubscribersRequestFailureActionType, null);
    }

    export type resetSubscriptionRequestAction = Action<typeof Constants.resetSubscriptionStateActionType, null>
    export function resetSubscriptionRequest(): resetSubscriptionRequestAction {
        return ActionCreator(Constants.resetSubscriptionStateActionType, null);
    }   

    export type resetSubscriptionDataStateRequestAction = Action<typeof Constants.resetSubscriptionDataStateActionType, null>
    export function resetSubscriptionDataStateRequest(): resetSubscriptionDataStateRequestAction {
        return ActionCreator(Constants.resetSubscriptionDataStateActionType, null);
    }
}

export type MonetizationActions = Omit<typeof MonetizationActions, 'Type'>;
export const useMonetizationActions = (dispatch: Dispatch) => {
    const { ...actions } = MonetizationActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as MonetizationActions;
};