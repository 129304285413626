import { handleActions } from 'redux-actions';
import { VideoLibraryActions } from './actions';
import { RootState } from '../reducers/state';
import { PaginationModel } from 'src/models/paginationModel';
import { RecommendationModel, LayoutContentModel, AuthenticationModel, StandardFieldModel, VideoLibraryStateModel, LayoutStyleModel, HighlightReelDataModel, AdBannerModel, SpotlightChannelModel } from 'src/models/videoLibraryModel';
import { Actions } from 'src/models/videoLibraryModel';
import * as Constants from './constants'
const initialState: RootState.VideoLibraryModelState = {
    videoAnalytics: [],
    spotLightRowVideo: [],
    paginationMeta: {} as PaginationModel,
    totalRecords: [],
    authentication: {} as AuthenticationModel,
    standardField: {} as StandardFieldModel,
    layoutStyle: {} as LayoutStyleModel,
    layoutContent: {} as LayoutContentModel,
    recommendations: {} as RecommendationModel,
    registrationAccessList: [],
    customField: [],
    highlightReelData: {} as HighlightReelDataModel,
    adBanner: {} as AdBannerModel,
    spotlightChannel: [],
    registrationOption: [],
    isSpotlightChannelAdded: false,
    isHighLightReelSaved: false,
    isAuthenticationSaved: false,
    isAuthenticationSavedLoading: false,
    isRegistrationOptionAdded: false,
    isRegistrationOptionAddedLoading: false,
    isRegistrationOptionDeleted: false,
    isStandardFieldSaved: false,
    isStandardFieldSavedLoading: false,
    isCustomFieldAdded: false,
    isCustomFieldAddedLoading: false,
    isCustomFieldDeleted: false,
    isVideoLibraryLayoutUpdated: false,
    isRegistrationAccessListAdded: false,
    isLayoutStyleUpdated: false,
    isSpotlightOrderUpdated: false,
    isRecommendationUpdated: false,
    isAdBannerUpdated: false,
    isSpotlightChannelDeleted: false,
    isLayoutContentUpdated: false,
    isLayoutStyleUpdateLoading: false,
    isLayoutContentUpdateLoading: false,
    isAdBannerUpdateLoading: false,
    isLayoutContentLoading: false,
    message: '',
    error: false,
    loading: false,
    isSaved: false,
    success: false
};


function videoLibraryReducer(state: VideoLibraryStateModel = initialState, action: Actions): VideoLibraryStateModel {
    switch (action.type) {
        case Constants.getSpotlightRowRequestActionType: return getSpotLightRowAction(state);
        case Constants.getSpotlightRowRequestSuccessActionType: return getSpotLightRowSuccessAction(state, action);
        case Constants.getSpotlightRowRequestFailureActionType: return getSpotLightRowFailureAction(state);
        case Constants.saveHighlightReelRequestActionType: return saveHighlightReelAction(state);
        case Constants.saveHighlightReelRequestSuccessActionType: return saveHighlightReelSuccessAction(state);
        case Constants.saveHighlightReelRequestFailureActionType: return saveHighlightReelFailureAction(state);
        case Constants.getTotalRecordsRequestActionType: return getTotalRecordsAction(state);
        case Constants.getTotalRecordsRequestSuccessActionType: return getTotalRecordsSuccessAction(state, action);
        case Constants.getTotalRecordsRequestFailureActionType: return getTotalRecordsFailureAction(state);
        case Constants.getAuthenticationRequestActionType: return getAuthenticationAction(state);
        case Constants.getAuthenticationRequestSuccessActionType: return getAuthenticationSuccessAction(state, action);
        case Constants.getAuthenticationRequestFailureActionType: return getAuthenticationFailureAction(state);
        case Constants.saveAuthenticationRequestActionType: return saveAuthenticationAction(state);
        case Constants.saveAuthenticationRequestSuccessActionType: return saveAuthenticationSuccessAction(state);
        case Constants.saveAuthenticationRequestFailureActionType: return saveAuthenticationFailureAction(state);
        case Constants.resetVideoLibraryStateRequestActionType: return resetVideoLibraryStateAction(state);
        case Constants.getRegistrationOptionsRequestActionType: return getRegistrationOptionsAction(state);
        case Constants.getRegistrationOptionsRequestSuccessActionType: return getRegistrationOptionsSuccessAction(state, action);
        case Constants.getRegistrationOptionsRequestFailureActionType: return getRegistrationOptionsFailureAction(state);
        case Constants.addRegistrationOptionsRequestActionType: return addRegistrationOptionsAction(state);
        case Constants.addRegistrationOptionsRequestSuccessActionType: return addRegistrationOptionsSuccessAction(state);
        case Constants.addRegistrationOptionsRequestFailureActionType: return addRegistrationOptionsFailureAction(state);
        case Constants.deleteRegistrationOptionRequestActionType: return deleteRegistrationOptionsAction(state);
        case Constants.deleteRegistrationOptionRequestSuccessActionType: return deleteRegistrationOptionsSuccessAction(state);
        case Constants.deleteRegistrationOptionRequestFailureActionType: return deleteRegistrationOptionsFailureAction(state);
        case Constants.getStandardFieldsRequestActionType: return getStandardFieldsAction(state);
        case Constants.getStandardFieldsRequestSuccessActionType: return getStandardFieldsSuccessAction(state, action);
        case Constants.getStandardFieldsRequestFailureActionType: return getStandardFieldsFailureAction(state);
        case Constants.saveStandardFieldsRequestActionType: return saveStandardFieldsAction(state);
        case Constants.saveStandardFieldsRequestSuccessActionType: return saveStandardFieldsSuccessAction(state);
        case Constants.saveStandardFieldsRequestFailureActionType: return saveStandardFieldsFailureAction(state);
        case Constants.getCustomFieldsRequestActionType: return getCustomFieldsAction(state);
        case Constants.getCustomFieldsRequestSuccessActionType: return getCustomFieldsSuccessAction(state, action);
        case Constants.getCustomFieldsRequestFailureActionType: return getCustomFieldsFailureAction(state);
        case Constants.addCustomFieldsRequestActionType: return addCustomFieldsAction(state);
        case Constants.addCustomFieldsRequestSuccessActionType: return addCustomFieldsSuccessAction(state);
        case Constants.addCustomFieldsRequestFailureActionType: return addCustomFieldsFailureAction(state);
        case Constants.deleteCustomFieldsRequestActionType: return deleteCustomFieldsAction(state);
        case Constants.deleteCustomFieldsRequestSuccessActionType: return deleteCustomFieldsSuccessAction(state);
        case Constants.deleteCustomFieldsRequestFailureActionType: return deleteCustomFieldsFailureAction(state);
        case Constants.updateVideoLibraryLayoutRequestActionType: return updateVideoLibraryLayoutAction(state);
        case Constants.updateVideoLibraryLayoutRequestSuccessActionType: return updateVideoLibraryLayoutSuccessAction(state);
        case Constants.updateVideoLibraryLayoutRequestFailureActionType: return updateVideoLibraryLayoutFailureAction(state);
        case Constants.getRegistrationAccessListRequestActionType: return getRegistrationAccessListAction(state);
        case Constants.getRegistrationAccessListRequestSuccessActionType: return getRegistrationAccessListSuccessAction(state, action);
        case Constants.getRegistrationAccessListRequestFailureActionType: return getRegistrationAccessListFailureAction(state);
        case Constants.addRegistrationAccessListRequestActionType: return addRegistrationAccessListAction(state);
        case Constants.addRegistrationAccessListRequestSuccessActionType: return addRegistrationAccessListSuccessAction(state);
        case Constants.addRegistrationAccessListRequestFailureActionType: return addRegistrationAccessListFailureAction(state);
        case Constants.deleteRegistrationAccessListRequestActionType: return deleteRegistrationAccessListAction(state);
        case Constants.deleteRegistrationAccessListRequestSuccessActionType: return deleteRegistrationAccessListSuccessAction(state);
        case Constants.deleteRegistrationAccessListRequestFailureActionType: return deleteRegistrationAccessListFailureAction(state);
        case Constants.getLayoutStyleRequestActionType: return getLayoutStyleAction(state);
        case Constants.getLayoutStyleRequestSuccessActionType: return getLayoutStyleSuccessAction(state, action);
        case Constants.getLayoutStyleRequestFailureActionType: return getLayoutStyleFailureAction(state);
        case Constants.updateLayoutStyleRequestActionType: return updateLayoutStyleAction(state);
        case Constants.updateLayoutStyleRequestSuccessActionType: return updateLayoutStyleSuccessAction(state);
        case Constants.updateLayoutStyleRequestFailureActionType: return updateLayoutStyleFailureAction(state);
        case Constants.getRecommendationRequestActionType: return getRecommendationAction(state);
        case Constants.getRecommendationRequestSuccessActionType: return getRecommendationSuccessAction(state, action);
        case Constants.getRecommendationRequestFailureActionType: return getRecommendationFailureAction(state);
        case Constants.updateRecommendationRequestActionType: return updateRecommendationAction(state);
        case Constants.updateRecommendationRequestSuccessActionType: return updateRecommendationSuccessAction(state);
        case Constants.updateRecommendationRequestFailureActionType: return updateRecommendationFailureAction(state);
        case Constants.getHightlightReelDataRequestActionType: return getHightlightReelDataAction(state);
        case Constants.getHightlightReelDataRequestSuccessActionType: return getHightlightReelDataSuccessAction(state, action);
        case Constants.getHightlightReelDataRequestFailureActionType: return getHightlightReelDataFailureAction(state);
        case Constants.getLayoutContentRequestActionType: return getLayoutContentAction(state);
        case Constants.getLayoutContentRequestSuccessActionType: return getLayoutContentSuccessAction(state, action);
        case Constants.getLayoutContentRequestFailureActionType: return getLayoutContentFailureAction(state);
        case Constants.getAdBannerRequestActionType: return getAdBannerAction(state);
        case Constants.getAdBannerRequestSuccessActionType: return getAdBannerSuccessAction(state, action);
        case Constants.getAdBannerRequestFailureActionType: return getAdBannerFailureAction(state);
        case Constants.updateAdBannerRequestActionType: return updateAdBannerAction(state);
        case Constants.updateAdBannerRequestSuccessActionType: return updateAdBannerSuccessAction(state);
        case Constants.updateAdBannerRequestFailureActionType: return updateAdBannerFailureAction(state);
        case Constants.getSpotlightChannelRequestActionType: return getSpotlightChannelAction(state);
        case Constants.getSpotlightChannelRequestSuccessActionType: return getSpotlightChannelSuccessAction(state, action);
        case Constants.getSpotlightChannelRequestFailureActionType: return getSpotlightChannelFailureAction(state);
        case Constants.deleteSpotlightChannelRequestActionType: return deleteSpotlightChannelAction(state);
        case Constants.deleteSpotlightChannelRequestSuccessActionType: return deleteSpotlightChannelSuccessAction(state);
        case Constants.deleteSpotlightChannelRequestFailureActionType: return deleteSpotlightChannelFailureAction(state);
        case Constants.updateSpotlightSettingOrderRequestActionType: return updateSpotlightSettingOrderAction(state);
        case Constants.updateSpotlightSettingOrderRequestSuccessActionType: return updateSpotlightSettingOrderSuccessAction(state);
        case Constants.updateSpotlightSettingOrderRequestFailureActionType: return updateSpotlightSettingOrderFailureAction(state);
        case Constants.updateLayoutContentRequestActionType: return updateLayoutContentAction(state);
        case Constants.updateLayoutContentRequestSuccessActionType: return updateLayoutContentSuccessAction(state);
        case Constants.updateLayoutContentRequestFailureActionType: return updateLayoutContentFailureAction(state);
        case Constants.addSpotlightChannelRequestActionType: return addSpotlightChannelAction(state);
        case Constants.addSpotlightChannelRequestSuccessActionType: return addSpotlightChannelSuccessAction(state);
        case Constants.addSpotlightChannelRequestFailureActionType: return addSpotlightChannelFailureAction(state);
        default: return state;
    }

    function getSpotLightRowAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSpotLightRowSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getSpotLightRowRequestSuccessAction): VideoLibraryStateModel {

        if (action.payload) {
            return {
                ...state,
                spotLightRowVideo: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSpotLightRowFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }

    function saveHighlightReelAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function saveHighlightReelSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {

        return {
            ...state,
            isHighLightReelSaved: true,
            loading: false
        };
    }
    function saveHighlightReelFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isHighLightReelSaved: false,
            loading: false
        }
    }

    function getTotalRecordsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getTotalRecordsSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getTotalRecordsRequestSuccessAction): VideoLibraryStateModel {

        if (action.payload) {
            return {
                ...state,
                totalRecords: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getTotalRecordsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getAuthenticationAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAuthenticationSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getAuthenticationRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                authentication: action.payload,
            };
        }
        return state;
    }
    function getAuthenticationFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveAuthenticationAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAuthenticationSavedLoading: true,
        };
    }
    function saveAuthenticationSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAuthenticationSavedLoading: false,
            isAuthenticationSaved: true,
        };
    }
    function saveAuthenticationFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAuthenticationSavedLoading: false,
            isAuthenticationSaved: false,
            message: "Success"
        }
    }
    function resetVideoLibraryStateAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAuthenticationSaved: false,
            isRegistrationOptionDeleted: false,
            isRegistrationOptionAdded: false,
            isStandardFieldSaved: false,
            isVideoLibraryLayoutUpdated: false,
            isLayoutStyleUpdated: false,
            isRecommendationUpdated: false,
            isAdBannerUpdated: false,
            isSpotlightChannelDeleted: false,
            isLayoutContentUpdated: false,
            isSpotlightChannelAdded: false,
            isSpotlightOrderUpdated: false
        }
    }
    function getRegistrationOptionsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRegistrationOptionsSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getRegistrationOptionsRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                registrationOption: action.payload,
            };
        }
        return state;
    }
    function getRegistrationOptionsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addRegistrationOptionsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationOptionAddedLoading: true,
        };
    }
    function addRegistrationOptionsSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationOptionAdded: true,
            isRegistrationOptionAddedLoading: false,
            message: 'Success'
        };
    }
    function addRegistrationOptionsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationOptionAdded: false,
            isRegistrationOptionAddedLoading: false
        }
    }
    function deleteRegistrationOptionsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteRegistrationOptionsSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationOptionDeleted: true,
            message: 'Success',
            loading: false,
        };
    }
    function deleteRegistrationOptionsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationOptionDeleted: false,
            loading: false
        }
    }
    function getStandardFieldsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getStandardFieldsSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getStandardFieldsRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                standardField: action.payload,
            };
        }
        return state;
    }
    function getStandardFieldsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function saveStandardFieldsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isStandardFieldSavedLoading: true,
        };
    }
    function saveStandardFieldsSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isStandardFieldSaved: true,
            isStandardFieldSavedLoading: false,
            message: "Success"
        };
    }
    function saveStandardFieldsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isStandardFieldSaved: false,
            isStandardFieldSavedLoading: false,
        }
    }
    function getCustomFieldsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getCustomFieldsSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getCustomFieldsRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                customField: action.payload,
            };
        }
        return state;
    }
    function getCustomFieldsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addCustomFieldsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isCustomFieldAddedLoading: true,
        };
    }
    function addCustomFieldsSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isCustomFieldAdded: true,
            isCustomFieldAddedLoading: false,
        };
    }
    function addCustomFieldsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isCustomFieldAdded: false,
            isCustomFieldAddedLoading: false
        }
    }
    function deleteCustomFieldsAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteCustomFieldsSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isCustomFieldDeleted: true,
            loading: false,
        };
    }
    function deleteCustomFieldsFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isCustomFieldDeleted: false,
            loading: false
        }
    }

    function updateVideoLibraryLayoutAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateVideoLibraryLayoutSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isVideoLibraryLayoutUpdated: true,
            loading: false
        };
    }
    function updateVideoLibraryLayoutFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isVideoLibraryLayoutUpdated: false,
            loading: false
        }
    }

    function getRegistrationAccessListAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRegistrationAccessListSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getRegistrationAccessListRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                registrationAccessList: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getRegistrationAccessListFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function addRegistrationAccessListAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addRegistrationAccessListSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationAccessListAdded: true,
            message: "Success",
            loading: false
        };
    }
    function addRegistrationAccessListFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationAccessListAdded: false,
            loading: false
        }
    }
    function deleteRegistrationAccessListAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteRegistrationAccessListSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationAccessListAdded: true,
            message: "Success",
            loading: false
        };
    }
    function deleteRegistrationAccessListFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRegistrationAccessListAdded: false,
            loading: false
        }
    }
    function getLayoutStyleAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getLayoutStyleSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getLayoutStyleRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                layoutStyle: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getLayoutStyleFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateLayoutStyleAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutStyleUpdateLoading: true,
        };
    }
    function updateLayoutStyleSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutStyleUpdated: true,
            message: "Success",
            isLayoutStyleUpdateLoading: false
        };
    }
    function updateLayoutStyleFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutStyleUpdated: false,
            isLayoutStyleUpdateLoading: false
        }
    }
    function getRecommendationAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getRecommendationSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getRecommendationRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                recommendations: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getRecommendationFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateRecommendationAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateRecommendationSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRecommendationUpdated: true,
            message: "Success",
            loading: false
        };
    }
    function updateRecommendationFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isRecommendationUpdated: false,
            loading: false
        }
    }
    function getHightlightReelDataAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getHightlightReelDataSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getHightlightReelDataRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                highlightReelData: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getHightlightReelDataFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function getLayoutContentAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutContentLoading: true,
        };
    }
    function getLayoutContentSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getLayoutContentRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                layoutContent: action.payload,
                isLayoutContentLoading: false
            };
        }
        return state;
    }
    function getLayoutContentFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutContentLoading: false
        }
    }
    function getAdBannerAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getAdBannerSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getAdBannerRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                adBanner: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getAdBannerFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function updateAdBannerAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAdBannerUpdateLoading: true,
        };
    }
    function updateAdBannerSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAdBannerUpdated: true,
            message: "Success",
            isAdBannerUpdateLoading: false
        };
    }
    function updateAdBannerFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isAdBannerUpdated: false,
            isAdBannerUpdateLoading: false
        }
    }
    function getSpotlightChannelAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function getSpotlightChannelSuccessAction(state: VideoLibraryStateModel, action: VideoLibraryActions.getSpotlightChannelRequestSuccessAction): VideoLibraryStateModel {
        if (action.payload) {
            return {
                ...state,
                spotlightChannel: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getSpotlightChannelFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: false
        }
    }
    function deleteSpotlightChannelAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function deleteSpotlightChannelSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightChannelDeleted: true,
            message: "Success",
            loading: false
        };
    }
    function deleteSpotlightChannelFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightChannelDeleted: false,
            loading: false
        }
    }
    function updateSpotlightSettingOrderAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function updateSpotlightSettingOrderSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightOrderUpdated: true,
            message: "Success",
            loading: false
        };
    }
    function updateSpotlightSettingOrderFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightOrderUpdated: false,
            loading: false
        }
    }
    function updateLayoutContentAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutContentUpdateLoading: true,
        };
    }
    function updateLayoutContentSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutContentUpdated: true,
            message: "Success",
            isLayoutContentUpdateLoading: false
        };
    }
    function updateLayoutContentFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isLayoutContentUpdated: false,
            isLayoutContentUpdateLoading: false
        }
    }
    function addSpotlightChannelAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            loading: true,
        };
    }
    function addSpotlightChannelSuccessAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightChannelAdded: true,
            message: "Success",
            loading: false
        };
    }
    function addSpotlightChannelFailureAction(state: VideoLibraryStateModel): VideoLibraryStateModel {
        return {
            ...state,
            isSpotlightChannelAdded: false,
            loading: false
        }
    }
}

export default videoLibraryReducer
