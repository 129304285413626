import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useAppearanceActions } from 'src/store/appearance/actions';
import { RootState } from 'src/store/reducers/state';
interface IProps {
    navigationId: string;
}

const DeleteNavigationModal: React.FC<IProps> = ({ navigationId }) => {
    const ref = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const appearanceAction = useAppearanceActions(dispatch);
    const { isNavigationDeleted } = useSelector((state: RootState) => {
        return {
            isNavigationDeleted: state.appearance.isNavigationDeleted,
        };
    });

    const handleDeleteClick = () => {
        appearanceAction.deleteNavigationRequest(navigationId);
    };

    useEffect(() => {
        if (isNavigationDeleted) {
            if (ref.current) {
                ref.current.click();
            }
        }
        appearanceAction.resetAppearanceStateRequest();
        appearanceAction.getNavigationRequest();
    }, [isNavigationDeleted]);

    return (
        <>
            <div>
                <div
                    className="modal fade"
                    id="DeleteNavigationModal"
                    aria-labelledby="DeleteNavigationModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
                        <div className="modal-content">
                            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
                                <h5
                                    className="text-xl font-semibold leading-normal text-center w-full"
                                    id="DeleteNavigationModalLabel">
                                    Delete Navigation
                                </h5>
                                <button
                                    ref={ref}
                                    type="button"
                                    className="dismiss-btn"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="modal-body relative py-4 px-10">
                                <form>
                                    <div className={`form-group mb-3 text-center`}>
                                        <p>
                                            <strong>Are you sure you want to delete this Navigation?</strong>
                                        </p>
                                    </div>
                                </form>
                            </div>

                            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                                <button
                                    onClick={handleDeleteClick}
                                    type="button"
                                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 borderonly-btn">
                                    Delete
                                </button>
                                <button
                                    type="button"
                                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                                    data-bs-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteNavigationModal;