import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEventActions } from 'src/store/event/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { useNavigate } from 'react-router-dom';
import { errorMsg } from 'src/components/utilities/utils';
interface IProps {
  eventName: string;
}
const AddNewEvent: React.FC<IProps> = ({ eventName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventActions = useEventActions(dispatch);
  const [eventNameDuplicateErr, setEventNameDuplicateErr] = useState<boolean>(false);
  const ref = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const { eventId, events, isEventSaved, isEventSavedLoading } = useSelector((state: RootState) => {
    return {
      eventId: state.event.eventID,
      events: state.event.events,
      isEventSaved: state.event.isEventSaved,
      isEventSavedLoading: state.event.isEventSavedLoading
    };
  });
  const onSubmit = (data: any) => {
    if (events && events.length > 0) {
      events.forEach((eventData: any) => {
        if (eventData.name == data.eventname) {
          errorMsg('Given name is already exists');
          setEventNameDuplicateErr(true);
        }
      });
    }
    if (data.eventname && eventNameDuplicateErr === false)
      eventActions.saveEventRequest(data.eventname);
  };
  useEffect(() => {
    if (isEventSavedLoading) {
      reset({
        eventname: ''
      });
    }
  }, [isEventSavedLoading]);

  useEffect(() => {
    if (isEventSaved === true) {
      reset({
        eventname: ''
      });
      if (ref.current) {
        ref.current.click();
        navigate(`/event/edit/${eventId}`);
      }
    }
  }, [isEventSaved]);
  return (
    <div>
      <div
        className="modal fade"
        id="AddNewEvent"
        aria-labelledby="AddNewEventLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="AddNewEventLabel">
                Add Event
              </h5>
              {isEventSavedLoading && <Loader />}
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">Name</label>
                  <input
                    type="text"
                    className="input-style"
                    {...register('eventname', {
                      required: true
                    })}
                    name="eventname"
                  />
                  {errors.eventname?.type === 'required' && (
                    <p className="text-red-700"> Required Field</p>
                  )}
                  {eventNameDuplicateErr === true && (
                    <p className="text-red-700"> Given name is already exists </p>
                  )}
                </div>
                <div className="modal-footer mt-5 flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewEvent;
