import React, { useEffect } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
interface IProps {
  memberId: string;
}

const Security: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const userAction = useUserActions(dispatch);
  const { loginHistory, loginHistoryPaginationMeta } = useSelector((state: RootState) => {
    return {
      loginHistory: state.users.loginHistory,
      loginHistoryPaginationMeta: state.users.loginHistoryPaginationMeta
    };
  });

  const handlePageChange = (pageNumber: number) => {
    userAction.getLoginHistoryRequest({ id: memberId, pageNumber });
  };

  return (
    <div className="my-6">
      <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
          <div className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold ">Login History</h2>
          </div>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold   bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Last Login Date/Time</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">IP Address</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Browser</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Successful?</div>
                    </th>
                  </tr>
                </thead>
                {loginHistory && loginHistory.length ? (
                  <tbody className="text-sm divide-y divide-gray-100">
                    {loginHistory &&
                      loginHistory.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-2">
                              <div className="text-left">
                                {item.loginDate && defaultDateFormatter(item.loginDate)}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.ipAddress}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.browser}</div>
                            </td>
                            <td className="p-2">
                              <div className="">
                                <div className="w-10 text-center ">
                                  <i
                                    className={`${
                                      item.isSuccess ? 'fa fa-check ' : 'fa fa-times '
                                    }text-danger text-lg ${
                                      item.isSuccess ? 'text-green-500' : 'text-red-700'
                                    }`}></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="text-center">No data Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {!!loginHistoryPaginationMeta && !!Object.keys(loginHistoryPaginationMeta).length && (
                <Pagination
                  currentPage={loginHistoryPaginationMeta.pageNumber}
                  totalCount={loginHistoryPaginationMeta.totalCount}
                  pageSize={loginHistoryPaginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
