import React from 'react';
import { Link } from 'react-router-dom';
const UnauthorizedAccess: React.FC = () => {
  return (
    <div className="flex justify-center items-center not-found">
      <div className="text-center">
        <div className="py-3">
          <h6 className="text-2xl font-bold text-black dark:text-[#ccc] mb-1">
            Oops! Something went wrong
          </h6>
          <p className="text-gray-500 dark:text-[#ccc] mb-3">
            {`We're sorry, but it seems that you don't have permission to access the requested
            resource. If you believe this is an error or if you need assistance, please contact our
            support team at`}{' '}
            <b className="font-bold text-black">support@webcastcloud.com</b>.
          </p>
          <Link to={`/channels`} className="btn-primary w-28 text-white hover:text-white mt-0">
            Go Back
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
