import { createAction } from "redux-actions"
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { DeleteMemberListData, SearchMemberListData, UploadMemberListData, UploadMemberProfileModel, UserActivityTimelineModel, GetUserActivityTimelineModel, MemberCountDataModel, MemberModel, StripeSubscriptionModel, MemberVideoAnalyticsModel, SubscriptionProductModel, MemberEventRegistrationModel, LoginHistoryModel, MemberListModel, SingleMemberListModel, FilterUserModel, memberSubscription, RolesModel, StatusModel, UsersModel, UserMfaSetup, MfaQr, VerifyMfaCode } from "src/models/usersModel";
import { PagingDataModel } from 'src/models/paginationModel';
import { Action, ActionCreator } from 'src/models/actions';
import { AddDataModel, SearchModel } from 'src/models/commonModels';
export namespace UsersActions {
    //Get Users data
    export type getUserRequestAction = Action<typeof Constants.getUserRequestActionType, null>
    export type getUserRequestSuccessAction = Action<typeof Constants.getUserRequestSuccessActionType, any>;
    export type getUserRequestFailureAction = Action<typeof Constants.getUserRequestFailureActionType, null>;

    export function getUserRequest(): getUserRequestAction {
        return ActionCreator(Constants.getUserRequestActionType, null);
    }
    export function getUserRequestSuccess(P: any): getUserRequestSuccessAction {
        return ActionCreator(Constants.getUserRequestSuccessActionType, P);
    }
    export function getUserRequestFailure(): getUserRequestFailureAction {
        return ActionCreator(Constants.getUserRequestFailureActionType, null);
    }
    export type getUserRoleRequestAction = Action<typeof Constants.getUserRoleRequestActionType, null>
    export type getUserRoleRequestSuccessAction = Action<typeof Constants.getUserRoleRequestSuccessActionType, RolesModel[]>;
    export type getUserRoleRequestFailureAction = Action<typeof Constants.getUserRoleRequestFailureActionType, null>;

    export function getUserRoleRequest(): getUserRoleRequestAction {
        return ActionCreator(Constants.getUserRoleRequestActionType, null);
    }
    export function getUserRoleRequestSuccess(P: RolesModel[]): getUserRoleRequestSuccessAction {
        return ActionCreator(Constants.getUserRoleRequestSuccessActionType, P);
    }
    export function getUserRoleRequestFailure(): getUserRoleRequestFailureAction {
        return ActionCreator(Constants.getUserRoleRequestFailureActionType, null);
    }

    export type getUserDetailsRequestAction = Action<typeof Constants.getUserDetailsRequestActionType, string>
    export type getUserDetailsRequestSuccessAction = Action<typeof Constants.getUserDetailsRequestSuccessActionType, UsersModel>;
    export type getUserDetailsRequestFailureAction = Action<typeof Constants.getUserDetailsRequestFailureActionType, null>;

    export function getUserDetailsRequest(P: string): getUserDetailsRequestAction {
        return ActionCreator(Constants.getUserDetailsRequestActionType, P);
    }
    export function getUserDetailsRequestSuccess(P: UsersModel): getUserDetailsRequestSuccessAction {
        return ActionCreator(Constants.getUserDetailsRequestSuccessActionType, P);
    }
    export function getUserDetailsRequestFailure(): getUserDetailsRequestFailureAction {
        return ActionCreator(Constants.getUserDetailsRequestFailureActionType, null);
    }

    export type addUserRequestAction = Action<typeof Constants.addUserRequestActionType, UsersModel>
    export type addUserRequestSuccessAction = Action<typeof Constants.addUserRequestSuccessActionType, null>;
    export type addUserRequestFailureAction = Action<typeof Constants.addUserRequestFailureActionType, null>;

    export function addUserRequest(P: UsersModel): addUserRequestAction {
        return ActionCreator(Constants.addUserRequestActionType, P);
    }
    export function addUserRequestSuccess(): addUserRequestSuccessAction {
        return ActionCreator(Constants.addUserRequestSuccessActionType, null);
    }
    export function addUserRequestFailure(): addUserRequestFailureAction {
        return ActionCreator(Constants.addUserRequestFailureActionType, null);
    }

    export type getMembersRequestAction = Action<typeof Constants.getMembersRequestActionType, FilterUserModel>
    export type getMembersRequestSuccessAction = Action<typeof Constants.getMembersRequestSuccessActionType, PagingDataModel<MemberModel>>;
    export type getMembersRequestFailureAction = Action<typeof Constants.getMembersRequestFailureActionType, null>;

    export function getMembersRequest(P: FilterUserModel): getMembersRequestAction {
        return ActionCreator(Constants.getMembersRequestActionType, P);
    }
    export function getMembersRequestSuccess(P: PagingDataModel<MemberModel>): getMembersRequestSuccessAction {
        return ActionCreator(Constants.getMembersRequestSuccessActionType, P);
    }
    export function getMembersRequestFailure(): getMembersRequestFailureAction {
        return ActionCreator(Constants.getMembersRequestFailureActionType, null);
    }

    export type addMembersRequestAction = Action<typeof Constants.addMembersRequestActionType, MemberModel>
    export type addMembersRequestSuccessAction = Action<typeof Constants.addMembersRequestSuccessActionType, null>;
    export type addMembersRequestFailureAction = Action<typeof Constants.addMembersRequestFailureActionType, null>;

    export function addMembersRequest(P: MemberModel): addMembersRequestAction {
        return ActionCreator(Constants.addMembersRequestActionType, P);
    }
    export function addMembersRequestSuccess(): addMembersRequestSuccessAction {
        return ActionCreator(Constants.addMembersRequestSuccessActionType, null);
    }
    export function addMembersRequestFailure(): addMembersRequestFailureAction {
        return ActionCreator(Constants.addMembersRequestFailureActionType, null);
    }


    export type getMemberDetailRequestAction = Action<typeof Constants.getMembersDetailRequestActionType, string>
    export type getMemberDetailRequestSuccessAction = Action<typeof Constants.getMembersDetailRequestSuccessActionType, MemberModel>;
    export type getMemberDetailRequestFailureAction = Action<typeof Constants.getMembersDetailRequestFailureActionType, null>;

    export function getMemberDetailRequest(P: string): getMemberDetailRequestAction {
        return ActionCreator(Constants.getMembersDetailRequestActionType, P);
    }
    export function getMemberDetailRequestSuccess(P: MemberModel): getMemberDetailRequestSuccessAction {
        return ActionCreator(Constants.getMembersDetailRequestSuccessActionType, P);
    }
    export function getMemberDetailRequestFailure(): getMemberDetailRequestFailureAction {
        return ActionCreator(Constants.getMembersDetailRequestFailureActionType, null);
    }

    export type UpdateUserRequestAction = Action<typeof Constants.UpdateUserRequestActionType, UsersModel>
    export type UpdateUserRequestSuccessAction = Action<typeof Constants.UpdateUserRequestSuccessActionType, UsersModel>;
    export type UpdateUserRequestFailureAction = Action<typeof Constants.UpdateUserRequestFailureActionType, null>;

    export function UpdateUserRequest(P: UsersModel): UpdateUserRequestAction {
        return ActionCreator(Constants.UpdateUserRequestActionType, P);
    }
    export function UpdateUserRequestSuccess(P: UsersModel): UpdateUserRequestSuccessAction {
        return ActionCreator(Constants.UpdateUserRequestSuccessActionType, P);
    }
    export function UpdateUserRequestFailure(): UpdateUserRequestFailureAction {
        return ActionCreator(Constants.UpdateUserRequestFailureActionType, null);
    }

    export type UserMfaSetupRequestAction = Action<typeof Constants.userMfaSetupRequestActionType, UserMfaSetup>
    export type UserMfaSetupRequestSuccessAction = Action<typeof Constants.userMfaSetupRequestSuccessActionType, UserMfaSetup>;
    export type UserMfaSetupRequestFailureAction = Action<typeof Constants.userMfaSetupRequestFailureActionType, null>;

    export function UserMfaSetupRequest(P: UserMfaSetup): UserMfaSetupRequestAction {
        return ActionCreator(Constants.userMfaSetupRequestActionType, P);
    }
    export function UserMfaSetupRequestSuccess(P: UserMfaSetup): UserMfaSetupRequestSuccessAction {
        return ActionCreator(Constants.userMfaSetupRequestSuccessActionType, P);
    }
    export function UserMfaSetupRequestFailure(): UserMfaSetupRequestFailureAction {
        return ActionCreator(Constants.userMfaSetupRequestFailureActionType, null);
    }

    export type GenerateMFAQrRequestAction = Action<typeof Constants.generateMFAQrRequestActionType, string>
    export type GenerateMFAQrRequestSuccessAction = Action<typeof Constants.generateMFAQrRequestSuccessActionType, MfaQr>;
    export type GenerateMFAQrRequestFailureAction = Action<typeof Constants.generateMFAQrRequestFailureActionType, null>;

    export function generateMFAQrRequest(P: string): GenerateMFAQrRequestAction {
        return ActionCreator(Constants.generateMFAQrRequestActionType, P);
    }
    export function generateMFAQrRequestSuccess(P: MfaQr): GenerateMFAQrRequestSuccessAction {
        return ActionCreator(Constants.generateMFAQrRequestSuccessActionType, P);
    }
    export function generateMFAQrRequestFailure(): GenerateMFAQrRequestFailureAction {
        return ActionCreator(Constants.generateMFAQrRequestFailureActionType, null);
    }

    export type VerifyMfaCodeRequestAction = Action<typeof Constants.verifyMfaCodeRequestActionType, VerifyMfaCode>
    export type VerifyMfaCodeRequestSuccessAction = Action<typeof Constants.verifyMfaCodeRequestSuccessActionType, string>;
    export type VerifyMfaCodeRequestFailureAction = Action<typeof Constants.verifyMfaCodeRequestFailureActionType, null>;

    export function VerifyMfaCodeRequest(P: VerifyMfaCode): VerifyMfaCodeRequestAction {
        return ActionCreator(Constants.verifyMfaCodeRequestActionType, P);
    }
    export function VerifyMfaCodeRequestSuccess(P: string): VerifyMfaCodeRequestSuccessAction {
        return ActionCreator(Constants.verifyMfaCodeRequestSuccessActionType, P);
    }
    export function VerifyMfaCodeRequestFailure(): VerifyMfaCodeRequestFailureAction {
        return ActionCreator(Constants.verifyMfaCodeRequestFailureActionType, null);
    }

    export type resetMFAState = Action<typeof Constants.resetMFAStateRequestActionType, null>;

    export function resetMFAStateRequest(): resetMFAState {
        return ActionCreator(Constants.resetMFAStateRequestActionType, null);
    }

    export type editMemberRequestAction = Action<typeof Constants.editMemberRequestActionType, MemberModel>
    export type editMemberRequestSuccessAction = Action<typeof Constants.editMemberRequestSuccessActionType, null>;
    export type editMemberRequestFailureAction = Action<typeof Constants.editMemberRequestFailureActionType, null>;

    export function editMemberRequest(P: MemberModel): editMemberRequestAction {
        return ActionCreator(Constants.editMemberRequestActionType, P);
    }
    export function editMemberRequestSuccess(): editMemberRequestSuccessAction {
        return ActionCreator(Constants.editMemberRequestSuccessActionType, null);
    }
    export function editMemberRequestFailure(): editMemberRequestFailureAction {
        return ActionCreator(Constants.editMemberRequestFailureActionType, null);
    }

    export type deleteMemberRequestAction = Action<typeof Constants.deleteMembersRequestActionType, string>
    export type deleteMemberRequestSuccessAction = Action<typeof Constants.deleteMembersRequestSuccessActionType, null>;
    export type deleteMemberRequestFailureAction = Action<typeof Constants.deleteMembersRequestFailureActionType, null>;

    export function deleteMemberRequest(P: string): deleteMemberRequestAction {
        return ActionCreator(Constants.deleteMembersRequestActionType, P);
    }
    export function deleteMemberRequestSuccess(): deleteMemberRequestSuccessAction {
        return ActionCreator(Constants.deleteMembersRequestSuccessActionType, null);
    }
    export function deleteMemberRequestFailure(): deleteMemberRequestFailureAction {
        return ActionCreator(Constants.deleteMembersRequestFailureActionType, null);
    }

    export type uploadProfileRequestAction = Action<typeof Constants.uploadProfileRequestActionType, MemberModel>
    export type uploadProfileRequestSuccessAction = Action<typeof Constants.uploadProfileRequestSuccessActionType, null>;
    export type uploadProfileRequestFailureAction = Action<typeof Constants.uploadProfileRequestFailureActionType, null>;

    export function uploadProfileRequest(P: MemberModel): uploadProfileRequestAction {
        return ActionCreator(Constants.uploadProfileRequestActionType, P);
    }
    export function uploadProfileRequestSuccess(): uploadProfileRequestSuccessAction {
        return ActionCreator(Constants.uploadProfileRequestSuccessActionType, null);
    }
    export function uploadProfileRequestFailure(): uploadProfileRequestFailureAction {
        return ActionCreator(Constants.uploadProfileRequestFailureActionType, null);
    }

    export type updateCreditPreferenceRequestAction = Action<typeof Constants.updateCreditPreferencesRequestActionType, MemberModel>
    export type updateCreditPreferenceRequestSuccessAction = Action<typeof Constants.updateCreditPreferencesRequestSuccessActionType, null>;
    export type updateCreditPreferenceRequestFailureAction = Action<typeof Constants.updateCreditPreferencesRequestFailureActionType, null>;

    export function updateCreditPreferenceRequest(P: MemberModel): updateCreditPreferenceRequestAction {
        return ActionCreator(Constants.updateCreditPreferencesRequestActionType, P);
    }
    export function updateCreditPreferenceRequestSuccess(): updateCreditPreferenceRequestSuccessAction {
        return ActionCreator(Constants.updateCreditPreferencesRequestSuccessActionType, null);
    }
    export function updateCreditPreferenceRequestFailure(): updateCreditPreferenceRequestFailureAction {
        return ActionCreator(Constants.updateCreditPreferencesRequestFailureActionType, null);
    }

    export type getStripeSubscriptionRequestAction = Action<typeof Constants.getStripeSubscriptionRequestActionType, null>
    export type getStripeSubscriptionRequestSuccessAction = Action<typeof Constants.getStripeSubscriptionRequestSuccessActionType, StripeSubscriptionModel>;
    export type getStripeSubscriptionRequestFailureAction = Action<typeof Constants.getStripeSubscriptionRequestFailureActionType, null>;

    export function getStripeSubscriptionRequest(): getStripeSubscriptionRequestAction {
        return ActionCreator(Constants.getStripeSubscriptionRequestActionType, null);
    }
    export function getStripeSubscriptionRequestSuccess(P: StripeSubscriptionModel): getStripeSubscriptionRequestSuccessAction {
        return ActionCreator(Constants.getStripeSubscriptionRequestSuccessActionType, P);
    }
    export function getStripeSubscriptionRequestFailure(): getStripeSubscriptionRequestFailureAction {
        return ActionCreator(Constants.getStripeSubscriptionRequestFailureActionType, null);
    }

    export type cancelStripeSubscriptionRequestAction = Action<typeof Constants.cancelStripeSubscriptionRequestActionType, null>
    export type cancelStripeSubscriptionRequestSuccessAction = Action<typeof Constants.cancelStripeSubscriptionSuccessActionType, null>;
    export type cancelStripeSubscriptionRequestFailureAction = Action<typeof Constants.cancelStripeSubscriptionFailureActionType, null>;

    export function cancelStripeSubscriptionRequest(): cancelStripeSubscriptionRequestAction {
        return ActionCreator(Constants.cancelStripeSubscriptionRequestActionType, null);
    }
    export function cancelStripeSubscriptionRequestSuccess(): cancelStripeSubscriptionRequestSuccessAction {
        return ActionCreator(Constants.cancelStripeSubscriptionSuccessActionType, null);
    }
    export function cancelStripeSubscriptionRequestFailure(): cancelStripeSubscriptionRequestFailureAction {
        return ActionCreator(Constants.cancelStripeSubscriptionFailureActionType, null);
    }

    export type checkUserNameAvailableRequestAction = Action<typeof Constants.checkUserNameAvailableRequestActionType, string>
    export type checkUserNameAvailableRequestSuccessAction = Action<typeof Constants.checkUserNameAvailableRequestSuccessActionType, null>;
    export type checkUserNameAvailableRequestFailureAction = Action<typeof Constants.checkUserNameAvailableRequestFailureActionType, null>;

    export function checkUserNameAvailableRequest(P: string): checkUserNameAvailableRequestAction {
        return ActionCreator(Constants.checkUserNameAvailableRequestActionType, P);
    }
    export function checkUserNameAvailableRequestSuccess(): checkUserNameAvailableRequestSuccessAction {
        return ActionCreator(Constants.checkUserNameAvailableRequestSuccessActionType, null);
    }
    export function checkUserNameAvailableRequestFailure(): checkUserNameAvailableRequestFailureAction {
        return ActionCreator(Constants.checkUserNameAvailableRequestFailureActionType, null);
    }

    export type getMemberVideoAnalyticsRequestAction = Action<typeof Constants.getMemberVideoAnalyticsRequestActionType, any>
    export type getMemberVideoAnalyticsRequestSuccessAction = Action<typeof Constants.getMemberVideoAnalyticsRequestSuccessActionType, PagingDataModel<MemberVideoAnalyticsModel>>;
    export type getMemberVideoAnalyticsRequestFailureAction = Action<typeof Constants.getMemberVideoAnalyticsRequestFailureActionType, null>;

    export function getMemberVideoAnalyticsRequest(P: any): getMemberVideoAnalyticsRequestAction {
        return ActionCreator(Constants.getMemberVideoAnalyticsRequestActionType, P);
    }
    export function getMemberVideoAnalyticsRequestSuccess(P: PagingDataModel<MemberVideoAnalyticsModel>): getMemberVideoAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getMemberVideoAnalyticsRequestSuccessActionType, P);
    }
    export function getMemberVideoAnalyticsRequestFailure(): getMemberVideoAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getMemberVideoAnalyticsRequestFailureActionType, null);
    }
    export type getMemberSubscriptionRequestAction = Action<typeof Constants.getMemberSubscriptionRequestActionType, string>
    export type getMemberSubscriptionRequestSuccessAction = Action<typeof Constants.getMemberSubscriptionRequestSuccessActionType, memberSubscription[]>;
    export type getMemberSubscriptionRequestFailureAction = Action<typeof Constants.getMemberSubscriptionRequestFailureActionType, null>;

    export function getMemberSubscriptionRequest(P: string): getMemberSubscriptionRequestAction {
        return ActionCreator(Constants.getMemberSubscriptionRequestActionType, P);
    }
    export function getMemberSubscriptionRequestSuccess(P: memberSubscription[]): getMemberSubscriptionRequestSuccessAction {
        return ActionCreator(Constants.getMemberSubscriptionRequestSuccessActionType, P);
    }
    export function getMemberSubscriptionRequestFailure(): getMemberSubscriptionRequestFailureAction {
        return ActionCreator(Constants.getMemberSubscriptionRequestFailureActionType, null);
    }

    export type getUserProfileRequestAction = Action<typeof Constants.getUserProfileRequestActionType, string>
    export type getUserProfileRequestSuccessAction = Action<typeof Constants.getUserProfileRequestSuccessActionType, any>;
    export type getUserProfileRequestFailureAction = Action<typeof Constants.getUserProfileRequestFailureActionType, null>;

    export function getUserProfileRequest(P: string): getUserProfileRequestAction {
        return ActionCreator(Constants.getUserProfileRequestActionType, P);
    }
    export function getUserProfileRequestSuccess(P: string): getUserProfileRequestSuccessAction {
        return ActionCreator(Constants.getUserProfileRequestSuccessActionType, P);
    }
    export function getUserProfileRequestFailure(): getUserProfileRequestFailureAction {
        return ActionCreator(Constants.getUserProfileRequestFailureActionType, null);
    }

    export type getMemberInvoicesRequestAction = Action<typeof Constants.getMemberInvoicesRequestActionType, SearchModel>
    export type getMemberInvoicesRequestSuccessAction = Action<typeof Constants.getMemberInvoicesRequestSuccessActionType, any>;
    export type getMemberInvoicesRequestFailureAction = Action<typeof Constants.getMemberInvoicesRequestFailureActionType, null>;

    export function getMemberInvoicesRequest(P: SearchModel): getMemberInvoicesRequestAction {
        return ActionCreator(Constants.getMemberInvoicesRequestActionType, P);
    }
    export function getMemberInvoicesRequestSuccess(P: any): getMemberInvoicesRequestSuccessAction {
        return ActionCreator(Constants.getMemberInvoicesRequestSuccessActionType, P);
    }
    export function getMemberInvoicesRequestFailure(): getMemberInvoicesRequestFailureAction {
        return ActionCreator(Constants.getMemberInvoicesRequestFailureActionType, null);
    }

    export type getTotalMembersRequestAction = Action<typeof Constants.getTotalMembersRequestActionType, null>
    export type getTotalMembersRequestSuccessAction = Action<typeof Constants.getTotalMembersRequestSuccessActionType, any>;
    export type getTotalMembersRequestFailureAction = Action<typeof Constants.getTotalMembersRequestFailureActionType, null>;

    export function getTotalMembersRequest(): getTotalMembersRequestAction {
        return ActionCreator(Constants.getTotalMembersRequestActionType, null);
    }
    export function getTotalMembersRequestSuccess(P: any): getTotalMembersRequestSuccessAction {
        return ActionCreator(Constants.getTotalMembersRequestSuccessActionType, P);
    }
    export function getTotalMembersRequestFailure(): getTotalMembersRequestFailureAction {
        return ActionCreator(Constants.getTotalMembersRequestFailureActionType, null);
    }

    export type getSubscriptionProductsRequestAction = Action<typeof Constants.getSubscriptionProductsRequestActionType, string>
    export type getSubscriptionProductsRequestSuccessAction = Action<typeof Constants.getSubscriptionProductsRequestSuccessActionType, SubscriptionProductModel[]>;
    export type getSubscriptionProductsRequestFailureAction = Action<typeof Constants.getSubscriptionProductsRequestFailureActionType, null>;

    export function getSubscriptionProductsRequest(P: string): getSubscriptionProductsRequestAction {
        return ActionCreator(Constants.getSubscriptionProductsRequestActionType, P);
    }
    export function getSubscriptionProductsRequestSuccess(P: SubscriptionProductModel[]): getSubscriptionProductsRequestSuccessAction {
        return ActionCreator(Constants.getSubscriptionProductsRequestSuccessActionType, P);
    }
    export function getSubscriptionProductsRequestFailure(): getSubscriptionProductsRequestFailureAction {
        return ActionCreator(Constants.getSubscriptionProductsRequestFailureActionType, null);
    }

    export type deleteMemberListRequestAction = Action<typeof Constants.deleteMemberListRequestActionType, string>
    export type deleteMemberListRequestSuccessAction = Action<typeof Constants.deleteMemberListRequestSuccessActionType, string>;
    export type deleteMemberListRequestFailureAction = Action<typeof Constants.deleteMemberListRequestFailureActionType, null>;

    export function deleteMemberListRequest(P: string): deleteMemberListRequestAction {
        return ActionCreator(Constants.deleteMemberListRequestActionType, P);
    }
    export function deleteMemberListRequestSuccess(P: string): deleteMemberListRequestSuccessAction {
        return ActionCreator(Constants.deleteMemberListRequestSuccessActionType, P);
    }
    export function deleteMemberListRequestFailure(): deleteMemberListRequestFailureAction {
        return ActionCreator(Constants.deleteMemberListRequestFailureActionType, null);
    }

    export type getMemberListRequestAction = Action<typeof Constants.getMemberListRequestActionType, SearchModel>
    export type getMemberListRequestSuccessAction = Action<typeof Constants.getMemberListRequestSuccessActionType, PagingDataModel<MemberListModel>>;
    export type getMemberListRequestFailureAction = Action<typeof Constants.getMemberListRequestFailureActionType, null>;

    export function getMemberListRequest(P: SearchModel): getMemberListRequestAction {
        return ActionCreator(Constants.getMemberListRequestActionType, P);
    }
    export function getMemberListRequestSuccess(P: PagingDataModel<MemberListModel>): getMemberListRequestSuccessAction {
        return ActionCreator(Constants.getMemberListRequestSuccessActionType, P);
    }
    export function getMemberListRequestFailure(): getMemberListRequestFailureAction {
        return ActionCreator(Constants.getMemberListRequestFailureActionType, null);
    }

    export type getUserActivityTimeLineRequestAction = Action<typeof Constants.getUserActivityTimeLineRequestActionType, GetUserActivityTimelineModel>
    export type getUserActivityTimeLineRequestSuccessAction = Action<typeof Constants.getUserActivityTimeLineRequestSuccessActionType, PagingDataModel<UserActivityTimelineModel>>;
    export type getUserActivityTimeLineRequestFailureAction = Action<typeof Constants.getUserActivityTimeLineRequestFailureActionType, null>;

    export function getUserActivityTimeLineRequest(P: GetUserActivityTimelineModel): getUserActivityTimeLineRequestAction {
        return ActionCreator(Constants.getUserActivityTimeLineRequestActionType, P);
    }
    export function getUserActivityTimeLineRequestSuccess(P: PagingDataModel<UserActivityTimelineModel>): getUserActivityTimeLineRequestSuccessAction {
        return ActionCreator(Constants.getUserActivityTimeLineRequestSuccessActionType, P);
    }
    export function getUserActivityTimeLineRequestFailure(): getUserActivityTimeLineRequestFailureAction {
        return ActionCreator(Constants.getUserActivityTimeLineRequestFailureActionType, null);
    }

    export type saveMemberListRequestAction = Action<typeof Constants.saveMemberListRequestActionType, MemberListModel>
    export type saveMemberListRequestSuccessAction = Action<typeof Constants.saveMemberListRequestSuccessActionType, null>;
    export type saveMemberListRequestFailureAction = Action<typeof Constants.saveMemberListRequestFailureActionType, null>;

    export function saveMemberListRequest(P: MemberListModel): saveMemberListRequestAction {
        return ActionCreator(Constants.saveMemberListRequestActionType, P);
    }
    export function saveMemberListRequestSuccess(): saveMemberListRequestSuccessAction {
        return ActionCreator(Constants.saveMemberListRequestSuccessActionType, null);
    }
    export function saveMemberListRequestFailure(): saveMemberListRequestFailureAction {
        return ActionCreator(Constants.saveMemberListRequestFailureActionType, null);
    }

    export type getSingleMemberListRequestAction = Action<typeof Constants.getSingleMemberListRequestActionType, string>
    export type getSingleMemberListRequestSuccessAction = Action<typeof Constants.getSingleMemberListRequestSuccessActionType, SingleMemberListModel>;
    export type getSingleMemberListRequestFailureAction = Action<typeof Constants.getSingleMemberListRequestFailureActionType, null>;

    export function getSingleMemberListRequest(P: string): getSingleMemberListRequestAction {
        return ActionCreator(Constants.getSingleMemberListRequestActionType, P);
    }
    export function getSingleMemberListRequestSuccess(P: SingleMemberListModel): getSingleMemberListRequestSuccessAction {
        return ActionCreator(Constants.getSingleMemberListRequestSuccessActionType, P);
    }
    export function getSingleMemberListRequestFailure(): getSingleMemberListRequestFailureAction {
        return ActionCreator(Constants.getSingleMemberListRequestFailureActionType, null);
    }

    export type updateMemberListRequestAction = Action<typeof Constants.updateMemberListRequestActionType, MemberListModel>
    export type updateMemberListRequestSuccessAction = Action<typeof Constants.updateMemberListRequestSuccessActionType, null>;
    export type updateMemberListRequestFailureAction = Action<typeof Constants.updateMemberListRequestFailureActionType, null>;

    export function updateMemberListRequest(P: MemberListModel): updateMemberListRequestAction {
        return ActionCreator(Constants.updateMemberListRequestActionType, P);
    }
    export function updateMemberListRequestSuccess(): updateMemberListRequestSuccessAction {
        return ActionCreator(Constants.updateMemberListRequestSuccessActionType, null);
    }
    export function updateMemberListRequestFailure(): updateMemberListRequestFailureAction {
        return ActionCreator(Constants.updateMemberListRequestFailureActionType, null);
    }

    export type saveMemberListTypeRequestAction = Action<typeof Constants.saveMemberListTypeRequestActionType, any>
    export type saveMemberListTypeRequestSuccessAction = Action<typeof Constants.saveMemberListTypeRequestSuccessActionType, null>;
    export type saveMemberListTypeRequestFailureAction = Action<typeof Constants.saveMemberListTypeRequestFailureActionType, null>;

    export function saveMemberListTypeRequest(P: any): saveMemberListTypeRequestAction {
        return ActionCreator(Constants.saveMemberListTypeRequestActionType, P);
    }
    export function saveMemberListTypeRequestSuccess(): saveMemberListTypeRequestSuccessAction {
        return ActionCreator(Constants.saveMemberListTypeRequestSuccessActionType, null);
    }
    export function saveMemberListTypeRequestFailure(): saveMemberListTypeRequestFailureAction {
        return ActionCreator(Constants.saveMemberListTypeRequestFailureActionType, null);
    }

    export type searchMemberRequestAction = Action<typeof Constants.searchMemberRequestActionType, string>
    export type searchMemberRequestSuccessAction = Action<typeof Constants.searchMemberRequestSuccessActionType, PagingDataModel<MemberModel>>;
    export type searchMemberRequestFailureAction = Action<typeof Constants.searchMemberRequestFailureActionType, null>;

    export function searchMemberRequest(P: string): searchMemberRequestAction {
        return ActionCreator(Constants.searchMemberRequestActionType, P);
    }
    export function searchMemberRequestSuccess(P: PagingDataModel<MemberModel>): searchMemberRequestSuccessAction {
        return ActionCreator(Constants.searchMemberRequestSuccessActionType, P);
    }
    export function searchMemberRequestFailure(): searchMemberRequestFailureAction {
        return ActionCreator(Constants.searchMemberRequestFailureActionType, null);
    }

    export type getRolesRequestAction = Action<typeof Constants.getRolesRequestActionType, null>
    export type getRolesRequestSuccessAction = Action<typeof Constants.getRolesRequestSuccessActionType, RolesModel[]>;
    export type getRolesRequestFailureAction = Action<typeof Constants.getRolesRequestFailureActionType, null>;

    export function getRolesRequest(): getRolesRequestAction {
        return ActionCreator(Constants.getRolesRequestActionType, null);
    }
    export function getRolesRequestSuccess(P: RolesModel[]): getRolesRequestSuccessAction {
        return ActionCreator(Constants.getRolesRequestSuccessActionType, P);
    }
    export function getRolesRequestFailure(): getRolesRequestFailureAction {
        return ActionCreator(Constants.getRolesRequestFailureActionType, null);
    }

    export type getStatusRequestAction = Action<typeof Constants.getStatusRequestActionType, null>
    export type getStatusRequestSuccessAction = Action<typeof Constants.getStatusRequestSuccessActionType, StatusModel[]>;
    export type getStatusRequestFailureAction = Action<typeof Constants.getStatusRequestFailureActionType, null>;

    export function getStatusRequest(): getStatusRequestAction {
        return ActionCreator(Constants.getStatusRequestActionType, null);
    }
    export function getStatusRequestSuccess(P: StatusModel[]): getStatusRequestSuccessAction {
        return ActionCreator(Constants.getStatusRequestSuccessActionType, P);
    }
    export function getStatusRequestFailure(): getStatusRequestFailureAction {
        return ActionCreator(Constants.getStatusRequestFailureActionType, null);
    }

    export type getLoginHistoryRequestAction = Action<typeof Constants.getLoginHistoryRequestActionType, SearchModel>
    export type getLoginHistoryRequestSuccessAction = Action<typeof Constants.getLoginHistoryRequestSuccessActionType, PagingDataModel<LoginHistoryModel>>;
    export type getLoginHistoryRequestFailureAction = Action<typeof Constants.getLoginHistoryRequestFailureActionType, null>;

    export function getLoginHistoryRequest(P: SearchModel): getLoginHistoryRequestAction {
        return ActionCreator(Constants.getLoginHistoryRequestActionType, P);
    }
    export function getLoginHistoryRequestSuccess(P: PagingDataModel<LoginHistoryModel>): getLoginHistoryRequestSuccessAction {
        return ActionCreator(Constants.getLoginHistoryRequestSuccessActionType, P);
    }
    export function getLoginHistoryRequestFailure(): getLoginHistoryRequestFailureAction {
        return ActionCreator(Constants.getLoginHistoryRequestFailureActionType, null);
    }

    export type getMemberListByMemberIdRequestAction = Action<typeof Constants.getMemberListByMemberIdRequestActionType, string>
    export type getMemberListByMemberIdRequestSuccessAction = Action<typeof Constants.getMemberListByMemberIdRequestSuccessActionType, MemberListModel[]>;
    export type getMemberListByMemberIdRequestFailureAction = Action<typeof Constants.getMemberListByMemberIdRequestFailureActionType, null>;

    export function getMemberListByMemberIdRequest(P: string): getMemberListByMemberIdRequestAction {
        return ActionCreator(Constants.getMemberListByMemberIdRequestActionType, P);
    }
    export function getMemberListByMemberIdRequestSuccess(P: MemberListModel[]): getMemberListByMemberIdRequestSuccessAction {
        return ActionCreator(Constants.getMemberListByMemberIdRequestSuccessActionType, P);
    }
    export function getMemberListByMemberIdRequestFailure(): getMemberListByMemberIdRequestFailureAction {
        return ActionCreator(Constants.getMemberListByMemberIdRequestFailureActionType, null);
    }

    export type getMemberEventRegistrationRequestAction = Action<typeof Constants.getMemberEventRegistrationRequestActionType, string>
    export type getMemberEventRegistrationRequestSuccessAction = Action<typeof Constants.getMemberEventRegistrationRequestSuccessActionType, MemberEventRegistrationModel[]>;
    export type getMemberEventRegistrationRequestFailureAction = Action<typeof Constants.getMemberEventRegistrationRequestFailureActionType, null>;

    export function getMemberEventRegistrationRequest(P: string): getMemberEventRegistrationRequestAction {
        return ActionCreator(Constants.getMemberEventRegistrationRequestActionType, P);
    }
    export function getMemberEventRegistrationRequestSuccess(P: MemberEventRegistrationModel[]): getMemberEventRegistrationRequestSuccessAction {
        return ActionCreator(Constants.getMemberEventRegistrationRequestSuccessActionType, P);
    }
    export function getMemberEventRegistrationRequestFailure(): getMemberEventRegistrationRequestFailureAction {
        return ActionCreator(Constants.getMemberEventRegistrationRequestFailureActionType, null);
    }

    export type getMemberCountDataRequestAction = Action<typeof Constants.getMemberCountDataRequestActionType, string>
    export type getMemberCountDataRequestSuccessAction = Action<typeof Constants.getMemberCountDataRequestSuccessActionType, MemberCountDataModel>;
    export type getMemberCountDataRequestFailureAction = Action<typeof Constants.getMemberCountDataRequestFailureActionType, null>;

    export function getMemberCountDataRequest(P: string): getMemberCountDataRequestAction {
        return ActionCreator(Constants.getMemberCountDataRequestActionType, P);
    }
    export function getMemberCountDataRequestSuccess(P: MemberCountDataModel): getMemberCountDataRequestSuccessAction {
        return ActionCreator(Constants.getMemberCountDataRequestSuccessActionType, P);
    }
    export function getMemberCountDataRequestFailure(): getMemberCountDataRequestFailureAction {
        return ActionCreator(Constants.getMemberCountDataRequestFailureActionType, null);
    }

    export type updateMemberProfileRequestAction = Action<typeof Constants.updateMemberProfileRequestActionType, UploadMemberProfileModel>
    export type updateMemberProfileRequestSuccessAction = Action<typeof Constants.updateMemberProfileRequestSuccessActionType, null>;
    export type updateMemberProfileRequestFailureAction = Action<typeof Constants.updateMemberProfileRequestFailureActionType, null>;

    export function updateMemberProfileRequest(P: UploadMemberProfileModel): updateMemberProfileRequestAction {
        return ActionCreator(Constants.updateMemberProfileRequestActionType, P);
    }
    export function updateMemberProfileRequestSuccess(): updateMemberProfileRequestSuccessAction {
        return ActionCreator(Constants.updateMemberProfileRequestSuccessActionType, null);
    }
    export function updateMemberProfileRequestFailure(): updateMemberProfileRequestFailureAction {
        return ActionCreator(Constants.updateMemberProfileRequestFailureActionType, null);
    }

    export type resetMemberRequestAction = Action<typeof Constants.resetMemberStateActionType, null>

    export function resetMemberRequest(): resetMemberRequestAction {
        return ActionCreator(Constants.resetMemberStateActionType, null);
    }
    export type resetUserRequestAction = Action<typeof Constants.resetUserStateActionType, null>

    export function resetUserRequest(): resetUserRequestAction {
        return ActionCreator(Constants.resetUserStateActionType, null);
    }

    export type resetMemberDataStateRequestAction = Action<typeof Constants.resetMemberDataStateActionType, null>

    export function resetMemberDataStateRequest(): resetMemberDataStateRequestAction {
        return ActionCreator(Constants.resetMemberDataStateActionType, null);
    }
    export type resetUserDataStateRequestAction = Action<typeof Constants.resetUserDataStateActionType, null>

    export function resetUserDataStateRequest(): resetUserDataStateRequestAction {
        return ActionCreator(Constants.resetUserDataStateActionType, null);
    }

    export type uploadEmailDomainRequestAction = Action<typeof Constants.uploadEmailDomainRequestActionType, UploadMemberListData>
    export type uploadEmailDomainRequestSuccessAction = Action<typeof Constants.uploadEmailDomainRequestSuccessActionType, null>;
    export type uploadEmailDomainRequestDataExistAction = Action<typeof Constants.uploadEmailDomainRequestDataExistActionType, null>;
    export type uploadEmailDomainRequestFailureAction = Action<typeof Constants.uploadEmailDomainRequestFailureActionType, null>;

    export function uploadEmailDomainRequest(P: UploadMemberListData): uploadEmailDomainRequestAction {
        return ActionCreator(Constants.uploadEmailDomainRequestActionType, P);
    }
    export function uploadEmailDomainRequestSuccess(): uploadEmailDomainRequestSuccessAction {
        return ActionCreator(Constants.uploadEmailDomainRequestSuccessActionType, null);
    }
    export function uploadEmailDomainRequestDataExistAction(): uploadEmailDomainRequestDataExistAction {
        return ActionCreator(Constants.uploadEmailDomainRequestDataExistActionType, null);
    }
    export function uploadEmailDomainRequestFailure(): uploadEmailDomainRequestFailureAction {
        return ActionCreator(Constants.uploadEmailDomainRequestFailureActionType, null);
    }

    export type uploadEmailRequestAction = Action<typeof Constants.uploadEmailRequestActionType, UploadMemberListData>
    export type uploadEmailRequestSuccessAction = Action<typeof Constants.uploadEmailRequestSuccessActionType, null>;
    export type uploadEmailRequestDataExistAction = Action<typeof Constants.uploadEmailRequestDataExistActionType, null>;
    export type uploadEmailRequestFailureAction = Action<typeof Constants.uploadEmailRequestFailureActionType, null>;

    export function uploadEmailRequest(P: UploadMemberListData): uploadEmailRequestAction {
        return ActionCreator(Constants.uploadEmailRequestActionType, P);
    }
    export function uploadEmailRequestSuccess(): uploadEmailRequestSuccessAction {
        return ActionCreator(Constants.uploadEmailRequestSuccessActionType, null);
    }
    export function uploadEmailRequestDataExistAction(): uploadEmailRequestDataExistAction {
        return ActionCreator(Constants.uploadEmailRequestDataExistActionType, null);
    }
    export function uploadEmailRequestFailure(): uploadEmailRequestFailureAction {
        return ActionCreator(Constants.uploadEmailRequestFailureActionType, null);
    }

    export type uploadUserNameRequestAction = Action<typeof Constants.uploadUserNameRequestActionType, UploadMemberListData>
    export type uploadUserNameRequestSuccessAction = Action<typeof Constants.uploadUserNameRequestSuccessActionType, null>;
    export type uploadUserNameRequestDataExistAction = Action<typeof Constants.uploadUserNameRequestDataExistActionType, null>;
    export type uploadUserNameRequestFailureAction = Action<typeof Constants.uploadUserNameRequestFailureActionType, null>;

    export function uploadUserNameRequest(P: UploadMemberListData): uploadUserNameRequestAction {
        return ActionCreator(Constants.uploadUserNameRequestActionType, P);
    }
    export function uploadUserNameRequestSuccess(): uploadUserNameRequestSuccessAction {
        return ActionCreator(Constants.uploadUserNameRequestSuccessActionType, null);
    }
    export function uploadUserNameRequestDataExistAction(): uploadUserNameRequestDataExistAction {
        return ActionCreator(Constants.uploadUserNameRequestDataExistActionType, null);
    }
    export function uploadUserNameRequestFailure(): uploadUserNameRequestFailureAction {
        return ActionCreator(Constants.uploadUserNameRequestFailureActionType, null);
    }

    export type searchMemberListDetailsRequestAction = Action<typeof Constants.searchMemberListDetailsRequestActionType, SearchMemberListData>
    export type searchMemberListDetailsRequestSuccessAction = Action<typeof Constants.searchMemberListDetailsRequestSuccessActionType, PagingDataModel<UploadMemberListData>>;
    export type searchMemberListDetailsRequestFailureAction = Action<typeof Constants.searchMemberListDetailsRequestFailureActionType, null>;

    export function searchMemberListDetailsRequest(P: SearchMemberListData): searchMemberListDetailsRequestAction {
        return ActionCreator(Constants.searchMemberListDetailsRequestActionType, P);
    }
    export function searchMemberListDetailsRequestSuccess(P: PagingDataModel<UploadMemberListData>): searchMemberListDetailsRequestSuccessAction {
        return ActionCreator(Constants.searchMemberListDetailsRequestSuccessActionType, P);
    }
    export function searchMemberListDetailsRequestFailure(): searchMemberListDetailsRequestFailureAction {
        return ActionCreator(Constants.searchMemberListDetailsRequestFailureActionType, null);
    }

    export type deleteMemberListDetailsRequestAction = Action<typeof Constants.deleteMemberListDetailsRequestActionType, DeleteMemberListData>
    export type deleteMemberListDetailsRequestSuccessAction = Action<typeof Constants.deleteMemberListDetailsRequestSuccessActionType, null>;
    export type deleteMemberListDetailsRequestFailureAction = Action<typeof Constants.deleteMemberListDetailsRequestFailureActionType, null>;

    export function deleteMemberListDetailsRequest(P: DeleteMemberListData): deleteMemberListDetailsRequestAction {
        return ActionCreator(Constants.deleteMemberListDetailsRequestActionType, P);
    }
    export function deleteMemberListDetailsRequestSuccess(): deleteMemberListDetailsRequestSuccessAction {
        return ActionCreator(Constants.deleteMemberListDetailsRequestSuccessActionType, null);
    }
    export function deleteMemberListDetailsRequestFailure(): deleteMemberListDetailsRequestFailureAction {
        return ActionCreator(Constants.deleteMemberListDetailsRequestFailureActionType, null);
    }

    export type changePasswordRequestAction = Action<typeof Constants.changePasswordRequestActionType, UsersModel>
    export type changePasswordRequestSuccessAction = Action<typeof Constants.changePasswordRequestSuccessActionType, any>;
    export type changePasswordRequestFailureAction = Action<typeof Constants.changePasswordRequestFailureActionType, null>;

    export function changePasswordRequest(P: UsersModel): changePasswordRequestAction {
        return ActionCreator(Constants.changePasswordRequestActionType, P);
    }
    export function changePasswordRequestSuccess(P: any): changePasswordRequestSuccessAction {
        return ActionCreator(Constants.changePasswordRequestSuccessActionType, P);
    }
    export function changePasswordRequestFailure(): changePasswordRequestFailureAction {
        return ActionCreator(Constants.changePasswordRequestFailureActionType, null);
    }

    export type reset = Action<typeof Constants.reset, null>;

    export function reset(): reset {
        return ActionCreator(Constants.reset, null);
    }
}

export type UsersActions = Omit<typeof UsersActions, 'Type'>;
export const useUserActions = (dispatch: Dispatch) => {
    const { ...actions } = UsersActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as UsersActions;
};