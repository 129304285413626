import React, { useEffect } from 'react';
import Pagination from 'src/components/common/pagination';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { defaultDateFormatter, formatTimeHHMMSS } from 'src/components/utilities/utils';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Loader from 'src/components/loader';

interface IProps {
  memberId: string;
}

const MemberAnalytics: React.FC<IProps> = ({ memberId }) => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const userAction = useUserActions(dispatch);
  const { paginationMeta, analyticsMember } = useSelector((state: RootState) => {
    return {
      paginationMeta: state.analytics.paginationMeta,
      analyticsMember: state.analytics.analyticsMember
    };
  });
  const handlePageChange = (pageNumber: number) => {
    const data = { pageNo: pageNumber, memberId, pageSize: 20 };
    analyticsAction.getAnalyticsMembersRequest(data);
  };

  const getDarkBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 31, 31, 1)'
      : perc < 50
      ? 'rgba(255, 165, 31, 1)'
      : perc < 75
      ? 'rgba(31, 143, 255, 1)'
      : 'rgba(31, 255, 31, 1)';
  };
  const getLightBgColorStr = (perc: any) => {
    return perc < 25
      ? 'rgba(255, 198, 184, 1)'
      : perc < 50
      ? 'rgba(255, 216, 158, 1)'
      : perc < 75
      ? 'rgba(191, 219, 254, 1)'
      : 'rgba(213, 255, 199, 1)';
  };
  return (
    <div className="my-6">
      <div className="flex flex-col justify-center h-full">
        <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
          <div className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold ">Video Analytics</h2>
          </div>
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">TITLE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">LIVE</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">ONDEMAND</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">TOTAL VIEWS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">
                        TOTAL TIME <span className="block">HH:MM:SS</span>
                      </div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">WATCHED %</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">FIRST WATCH</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">LAST WATCH</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">CHANNEL</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">CREDITS</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">CREDITS SENT</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-center">
                        <p>ONDEMAND MILESTONES</p>
                        <div className="flex gap-2">
                          <div className="w-10 mx-auto text-center">25%</div>
                          <div className="w-10 mx-auto text-center">50%</div>
                          <div className="w-10 mx-auto text-center">75%</div>
                          <div className="w-10 mx-auto text-center">90%</div>
                          <div className="w-10 mx-auto text-center">100%</div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {analyticsMember && analyticsMember.length ? (
                  <tbody className="text-sm divide-y divide-gray-100">
                    {analyticsMember &&
                      analyticsMember.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="p-2">
                              <div className="text-left">{item.videoName}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.liveViews}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.onDemandViews}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">{item.liveViews + item.onDemandViews}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {formatTimeHHMMSS(item.totalTimeWatched)}
                              </div>
                            </td>

                            <td className="p-2">
                              <div className="text-left">
                                {item.percentageWatched}%
                                <div
                                  className="w-full bg-blue-200 h-1 rounded-md mt-2"
                                  style={{
                                    backgroundColor: `${getLightBgColorStr(item.percentageWatched)}`
                                  }}>
                                  <div
                                    className="bg-blue-600 h-1 rounded-md"
                                    style={{
                                      width: `${item.percentageWatched}%`,
                                      backgroundColor: `${getDarkBgColorStr(
                                        item.percentageWatched
                                      )}`
                                    }}></div>
                                </div>
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.firstView ? defaultDateFormatter(item.firstView) : ''}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left">
                                {item.lastView ? defaultDateFormatter(item.lastView) : ''}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-left flex flex-col">
                                <span>{`${item.channel}`}</span>
                                {item.parentChannel && (
                                  <span
                                    className="text-left p-2 italic video-view-badge"
                                    style={{ width: 'fit-content' }}>
                                    Parent: {item.parentChannel}
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">{item.pointsAchieved}</div>
                            </td>
                            <td className="p-2">
                              <div className="text-center">
                                {item.creditsSent ? (
                                  <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                            <td className="p-2">
                              <div className="flex gap-2">
                                {item.milestone25 ? (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                  </div>
                                ) : (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                  </div>
                                )}
                                {item.milestone50 ? (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                  </div>
                                ) : (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                  </div>
                                )}
                                {item.milestone75 ? (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                  </div>
                                ) : (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                  </div>
                                )}
                                {item.milestone90 ? (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                  </div>
                                ) : (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                  </div>
                                )}
                                {item.milestone100 ? (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                  </div>
                                ) : (
                                  <div className="w-10 mx-auto text-center">
                                    <i className="fa fa-times text-danger text-lg text-red-700"></i>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="p-2">
                        <div className="text-center">No data Found</div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
              {!!paginationMeta && !!Object.keys(paginationMeta).length && analyticsMember && (
                <Pagination
                  currentPage={paginationMeta.pageNumber}
                  totalCount={paginationMeta.totalCount}
                  pageSize={paginationMeta.pageSize}
                  onPageChange={(page: any) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberAnalytics;
