import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const StripeProvider: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { isPaymentUpdated, stripe, message, error } = useSelector((state: RootState) => {
    return {
      isPaymentUpdated: state.setting.isPaymentUpdated,
      stripe: state.setting.stripe,
      message: state.setting.message,
      error: state.setting.error
    };
  });

  const [showApiKey, setShowApiKey] = useState(false);
  const [showWebhookSecret, setShowWebhookSecret] = useState(false);

  useEffect(() => {
    if (stripe) {
      reset(stripe);
    }
  }, [stripe]);

  useEffect(() => {
    settingAction.getStripeProviderRequest();
  }, []);

  useEffect(() => {
    if (isPaymentUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isPaymentUpdated]);

  const onSubmit = (data: any) => {
    if (stripe == undefined || stripe.id == undefined) {
      settingAction.addStripeProviderRequest(data);
    } else {
      const payloadData = {
        name: 'Stripe',
        updateData: [
          { op: 'replace', path: 'publishableApiKey', value: data.publishableApiKey },
          { op: 'replace', path: 'apiKey', value: data.apiKey },
          { op: 'replace', path: 'webhookSecret', value: data.webhookSecret },
          { op: 'replace', path: 'createProduct', value: data.createProduct }
        ]
      };
      settingAction.updatePaymentRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Payments</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/paymentProvider">Payments</Link>
          </li>
          <li>
            <strong>Stripe Configuration</strong>
          </li>
        </ol>
        <Link to={`/paymentProvider`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="dashboard-card p-5">
        <h4 className="text-lg">Stripe Configuration</h4>

        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Publishable Key</label>
          </div>
          <div className="md:w-8/12">
            <FormInputField
              label={''}
              type={'text'}
              fieldName={'publishableApiKey'}
              register={register}
              errors={''}
              isRequired={false}
            />
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Secret Key</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showApiKey ? 'text' : 'password'}
              fieldName={'apiKey'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showApiKey ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowApiKey(!showApiKey)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Webhook Secret</label>
          </div>
          <div className="md:w-8/12 relative">
            <FormInputField
              label={''}
              type={showWebhookSecret ? 'text' : 'password'}
              fieldName={'webhookSecret'}
              register={register}
              errors={''}
              isRequired={false}
            />
            <i
              className={`fas ${
                showWebhookSecret ? 'fa-eye-slash' : 'fa-eye'
              } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
              onClick={() => setShowWebhookSecret(!showWebhookSecret)}></i>
          </div>
        </div>
        <div className="md:flex items-center mb-8 mt-6">
          <div className="md:w-4/12">
            <label className="font-semibold">Create Product for One-Time Transactions</label>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex-items mb-2">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    id="createProduct"
                    {...register('createProduct')}
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default StripeProvider;
