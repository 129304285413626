import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { AnalyticsVideoModel, AnswerQuestionModal } from 'src/models/analyticsModel';
import { Action, ActionCreator } from 'src/models/actions';
import { PagingDataModel } from 'src/models/paginationModel';
import { ViewSubmissionFilterModel, GetSingleVideoAnalyticModel, SingleVideoAnalyticsModel, AnswerQuestionViewModel, PollWidgetModel, QuizQuestionsResultsModel, PollAnalyticFilterModel, GetAnalyticsMemberSearchParamModel, GetAnalyticSessionModel, SingleQuizTotalModal, SalesOrderTotalModal, SinglePollQuestionModel, SinglePollModel, PollQuestionModel, PollAnalyticModel, QuestionByQuizModel, SingleQuizQuestion, GetAnalyticsMemberChartModel, RecentGradeModel, RecentCourseActivity, SingleCourseResult, QuizQuestionModel, QuizAnalyticsModel, QuizTotalModel, ViewSubmissionModel, ActivityModel, CourseResultModel, CourseTotalModel, GetAnalyticsPayloadDataModel, AnalyticsMemberModel, SalesOrderModel, GetAnalyticsMemberParamModel, CourseAnalyticsModel, AnalyticsVideoChartModel, AnalyticsMemberChartModel } from 'src/models/analyticsModel';
import { SearchModel } from 'src/models/commonModels';
export namespace AnalyticsActions {

    export type getAnalyticsVideoRequestAction = Action<typeof Constants.getAnalyticsVideoRequestActionType, GetAnalyticsPayloadDataModel>
    export type getAnalyticsVideoRequestSuccessAction = Action<typeof Constants.getAnalyticsVideoSuccessActionType, PagingDataModel<AnalyticsVideoModel>>;
    export type getAnalyticsVideoRequestFailureAction = Action<typeof Constants.getAnalyticsVideoFailureActionType, null>;

    export function getAnalyticsVideoRequest(P: GetAnalyticsPayloadDataModel): getAnalyticsVideoRequestAction {
        return ActionCreator(Constants.getAnalyticsVideoRequestActionType, P);
    }
    export function getAnalyticsVideoRequestSuccess(P: PagingDataModel<AnalyticsVideoModel>): getAnalyticsVideoRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsVideoSuccessActionType, P);
    }
    export function getAnalyticsVideoRequestFailure(): getAnalyticsVideoRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsVideoFailureActionType, null);
    }

    export type getAnalyticsVideoDownloadRequestAction = Action<typeof Constants.getAnalyticsVideoDownloadRequestActionType, GetAnalyticsPayloadDataModel>
    export type getAnalyticsVideoDownloadRequestSuccessAction = Action<typeof Constants.getAnalyticsVideoDownloadSuccessActionType, AnalyticsVideoModel[]>;
    export type getAnalyticsVideoDownloadRequestFailureAction = Action<typeof Constants.getAnalyticsVideoDownloadFailureActionType, null>;

    export function getAnalyticsVideoDownloadRequest(P: GetAnalyticsPayloadDataModel): getAnalyticsVideoDownloadRequestAction {
        return ActionCreator(Constants.getAnalyticsVideoDownloadRequestActionType, P);
    }
    export function getAnalyticsVideoDownloadRequestSuccess(P: AnalyticsVideoModel[]): getAnalyticsVideoDownloadRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsVideoDownloadSuccessActionType, P);
    }
    export function getAnalyticsVideoDownloadRequestFailure(): getAnalyticsVideoDownloadRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsVideoDownloadFailureActionType, null);
    }

    export type getAnalyticsVideoChartRequestAction = Action<typeof Constants.getAnalyticsVideoChartRequestActionType, GetAnalyticSessionModel>
    export type getAnalyticsVideoChartRequestSuccessAction = Action<typeof Constants.getAnalyticsVideoChartRequestSuccessActionType, AnalyticsVideoChartModel>;
    export type getAnalyticsVideoChartRequestFailureAction = Action<typeof Constants.getAnalyticsVideoChartRequestFailureActionType, null>;

    export function getAnalyticsVideoChartRequest(P: GetAnalyticSessionModel): getAnalyticsVideoChartRequestAction {
        return ActionCreator(Constants.getAnalyticsVideoChartRequestActionType, P);
    }
    export function getAnalyticsVideoChartRequestSuccess(P: AnalyticsVideoChartModel): getAnalyticsVideoChartRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsVideoChartRequestSuccessActionType, P);
    }
    export function getAnalyticsVideoChartRequestFailure(): getAnalyticsVideoChartRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsVideoChartRequestFailureActionType, null);
    }

    export type getAnalyticsMembersRequestAction = Action<typeof Constants.getAnalyticsMembersRequestActionType, GetAnalyticsMemberParamModel>
    export type getAnalyticsMembersRequestSuccessAction = Action<typeof Constants.getAnalyticsMembersRequestSuccessActionType, PagingDataModel<AnalyticsMemberModel>>;
    export type getAnalyticsMembersRequestFailureAction = Action<typeof Constants.getAnalyticsMembersRequestFailureActionType, null>;

    export function getAnalyticsMembersRequest(P: GetAnalyticsMemberParamModel): getAnalyticsMembersRequestAction {
        return ActionCreator(Constants.getAnalyticsMembersRequestActionType, P);
    }
    export function getAnalyticsMembersRequestSuccess(P: PagingDataModel<AnalyticsMemberModel>): getAnalyticsMembersRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsMembersRequestSuccessActionType, P);
    }
    export function getAnalyticsMembersRequestFailure(): getAnalyticsMembersRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsMembersRequestFailureActionType, null);
    }

    export type getSalesOrderRequestAction = Action<typeof Constants.getSalesOrderRequestActionType, any>
    export type getSalesOrderRequestSuccessAction = Action<typeof Constants.getSalesOrderRequestSuccessActionType, PagingDataModel<SalesOrderModel>>;
    export type getSalesOrderRequestFailureAction = Action<typeof Constants.getSalesOrderRequestFailureActionType, null>;

    export function getSalesOrderRequest(P: any): getSalesOrderRequestAction {
        return ActionCreator(Constants.getSalesOrderRequestActionType, P);
    }
    export function getSalesOrderRequestSuccess(P: PagingDataModel<SalesOrderModel>): getSalesOrderRequestSuccessAction {
        return ActionCreator(Constants.getSalesOrderRequestSuccessActionType, P);
    }
    export function getSalesOrderRequestFailure(): getSalesOrderRequestFailureAction {
        return ActionCreator(Constants.getSalesOrderRequestFailureActionType, null);
    }

    export type downloadSalesOrderRequestAction = Action<typeof Constants.downloadSalesOrderRequestActionType, any>
    export type downloadSalesOrderRequestSuccessAction = Action<typeof Constants.downloadSalesOrderRequestSuccessActionType, PagingDataModel<SalesOrderModel>>;
    export type downloadSalesOrderRequestFailureAction = Action<typeof Constants.downloadSalesOrderRequestFailureActionType, null>;

    export function downloadSalesOrderRequest(P: any): downloadSalesOrderRequestAction {
        return ActionCreator(Constants.downloadSalesOrderRequestActionType, P);
    }
    export function downloadSalesOrderRequestSuccess(P: PagingDataModel<SalesOrderModel>): downloadSalesOrderRequestSuccessAction {
        return ActionCreator(Constants.downloadSalesOrderRequestSuccessActionType, P);
    }
    export function downloadSalesOrderRequestFailure(): downloadSalesOrderRequestFailureAction {
        return ActionCreator(Constants.downloadSalesOrderRequestFailureActionType, null);
    }

    export type getCourseAnalyticsRequestAction = Action<typeof Constants.getCourseAnalyticsRequestActionType, SearchModel>
    export type getCourseAnalyticsRequestSuccessAction = Action<typeof Constants.getCourseAnalyticsRequestSuccessActionType, PagingDataModel<CourseAnalyticsModel>>;
    export type getCourseAnalyticsRequestFailureAction = Action<typeof Constants.getCourseAnalyticsRequestFailureActionType, null>;

    export function getCourseAnalyticsRequest(P: SearchModel): getCourseAnalyticsRequestAction {
        return ActionCreator(Constants.getCourseAnalyticsRequestActionType, P);
    }
    export function getCourseAnalyticsRequestSuccess(P: PagingDataModel<CourseAnalyticsModel>): getCourseAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getCourseAnalyticsRequestSuccessActionType, P);
    }
    export function getCourseAnalyticsRequestFailure(): getCourseAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getCourseAnalyticsRequestFailureActionType, null);
    }

    export type getAnalyticsMemberChartRequestAction = Action<typeof Constants.getAnalyticsMemberChartRequestActionType, GetAnalyticsMemberChartModel>
    export type getAnalyticsMemberChartRequestSuccessAction = Action<typeof Constants.getAnalyticsMemberChartRequestSuccessActionType, AnalyticsMemberChartModel[]>;
    export type getAnalyticsMemberChartRequestFailureAction = Action<typeof Constants.getAnalyticsMemberChartRequestFailureActionType, null>;

    export function getAnalyticsMemberChartRequest(P: GetAnalyticsMemberChartModel): getAnalyticsMemberChartRequestAction {
        return ActionCreator(Constants.getAnalyticsMemberChartRequestActionType, P);
    }
    export function getAnalyticsMemberChartRequestSuccess(P: AnalyticsMemberChartModel[]): getAnalyticsMemberChartRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsMemberChartRequestSuccessActionType, P);
    }
    export function getAnalyticsMemberChartRequestFailure(): getAnalyticsMemberChartRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsMemberChartRequestFailureActionType, null);
    }

    export type getCourseTotalRequestAction = Action<typeof Constants.getCourseTotalRequestActionType, null>
    export type getCourseTotalRequestSuccessAction = Action<typeof Constants.getCourseTotalRequestSuccessActionType, CourseTotalModel>;
    export type getCourseTotalRequestFailureAction = Action<typeof Constants.getCourseTotalRequestFailureActionType, null>;

    export function getCourseTotalRequest(): getCourseTotalRequestAction {
        return ActionCreator(Constants.getCourseTotalRequestActionType, null);
    }
    export function getCourseTotalRequestSuccess(P: CourseTotalModel): getCourseTotalRequestSuccessAction {
        return ActionCreator(Constants.getCourseTotalRequestSuccessActionType, P);
    }
    export function getCourseTotalRequestFailure(): getCourseTotalRequestFailureAction {
        return ActionCreator(Constants.getCourseTotalRequestFailureActionType, null);
    }

    export type getCourseResultsRequestAction = Action<typeof Constants.getCourseResultsRequestActionType, SearchModel>
    export type getCourseResultsRequestSuccessAction = Action<typeof Constants.getCourseResultsRequestSuccessActionType, PagingDataModel<CourseResultModel>>;
    export type getCourseResultsRequestFailureAction = Action<typeof Constants.getCourseResultsRequestFailureActionType, null>;

    export function getCourseResultsRequest(P: SearchModel): getCourseResultsRequestAction {
        return ActionCreator(Constants.getCourseResultsRequestActionType, P);
    }
    export function getCourseResultsRequestSuccess(P: PagingDataModel<CourseResultModel>): getCourseResultsRequestSuccessAction {
        return ActionCreator(Constants.getCourseResultsRequestSuccessActionType, P);
    }
    export function getCourseResultsRequestFailure(): getCourseResultsRequestFailureAction {
        return ActionCreator(Constants.getCourseResultsRequestFailureActionType, null);
    }

    export type getViewActivityRequestAction = Action<typeof Constants.getViewActivityRequestActionType, null>
    export type getViewActivityRequestSuccessAction = Action<typeof Constants.getViewActivityRequestSuccessActionType, ActivityModel[]>;
    export type getViewActivityRequestFailureAction = Action<typeof Constants.getViewActivityRequestFailureActionType, null>;

    export function getViewActivityRequest(): getViewActivityRequestAction {
        return ActionCreator(Constants.getViewActivityRequestActionType, null);
    }
    export function getViewActivityRequestSuccess(P: ActivityModel[]): getViewActivityRequestSuccessAction {
        return ActionCreator(Constants.getViewActivityRequestSuccessActionType, P);
    }
    export function getViewActivityRequestFailure(): getViewActivityRequestFailureAction {
        return ActionCreator(Constants.getViewActivityRequestFailureActionType, null);
    }

    export type getViewSubmissionRequestAction = Action<typeof Constants.getViewSubmissionRequestActionType, ViewSubmissionFilterModel>
    export type getViewSubmissionRequestSuccessAction = Action<typeof Constants.getViewSubmissionRequestSuccessActionType, ViewSubmissionModel[]>;
    export type getViewSubmissionRequestFailureAction = Action<typeof Constants.getViewSubmissionRequestFailureActionType, null>;

    export function getViewSubmissionRequest(P: ViewSubmissionFilterModel): getViewSubmissionRequestAction {
        return ActionCreator(Constants.getViewSubmissionRequestActionType, P);
    }
    export function getViewSubmissionRequestSuccess(P: ViewSubmissionModel[]): getViewSubmissionRequestSuccessAction {
        return ActionCreator(Constants.getViewSubmissionRequestSuccessActionType, P);
    }
    export function getViewSubmissionRequestFailure(): getViewSubmissionRequestFailureAction {
        return ActionCreator(Constants.getViewSubmissionRequestFailureActionType, null);
    }

    export type getQuizTotalRequestAction = Action<typeof Constants.getQuizTotalRequestActionType, null>
    export type getQuizTotalRequestSuccessAction = Action<typeof Constants.getQuizTotalRequestSuccessActionType, QuizTotalModel>;
    export type getQuizTotalRequestFailureAction = Action<typeof Constants.getQuizTotalRequestFailureActionType, null>;

    export function getQuizTotalRequest(): getQuizTotalRequestAction {
        return ActionCreator(Constants.getQuizTotalRequestActionType, null);
    }
    export function getQuizTotalRequestSuccess(P: QuizTotalModel): getQuizTotalRequestSuccessAction {
        return ActionCreator(Constants.getQuizTotalRequestSuccessActionType, P);
    }
    export function getQuizTotalRequestFailure(): getQuizTotalRequestFailureAction {
        return ActionCreator(Constants.getQuizTotalRequestFailureActionType, null);
    }

    export type getQuizAnalyticsRequestAction = Action<typeof Constants.getQuizAnalyticsRequestActionType, SearchModel>
    export type getQuizAnalyticsRequestSuccessAction = Action<typeof Constants.getQuizAnalyticsRequestSuccessActionType, PagingDataModel<QuizAnalyticsModel>>;
    export type getQuizAnalyticsRequestFailureAction = Action<typeof Constants.getQuizAnalyticsRequestFailureActionType, null>;

    export function getQuizAnalyticsRequest(P: SearchModel): getQuizAnalyticsRequestAction {
        return ActionCreator(Constants.getQuizAnalyticsRequestActionType, P);
    }
    export function getQuizAnalyticsRequestSuccess(P: PagingDataModel<QuizAnalyticsModel>): getQuizAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getQuizAnalyticsRequestSuccessActionType, P);
    }
    export function getQuizAnalyticsRequestFailure(): getQuizAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getQuizAnalyticsRequestFailureActionType, null);
    }

    export type getQuizQuestionsRequestAction = Action<typeof Constants.getQuizQuestionsRequestActionType, SearchModel>
    export type getQuizQuestionsRequestSuccessAction = Action<typeof Constants.getQuizQuestionsRequestSuccessActionType, PagingDataModel<QuizQuestionModel>>;
    export type getQuizQuestionsRequestFailureAction = Action<typeof Constants.getQuizQuestionsRequestFailureActionType, null>;

    export function getQuizQuestionsRequest(P: SearchModel): getQuizQuestionsRequestAction {
        return ActionCreator(Constants.getQuizQuestionsRequestActionType, P);
    }
    export function getQuizQuestionsRequestSuccess(P: PagingDataModel<QuizQuestionModel>): getQuizQuestionsRequestSuccessAction {
        return ActionCreator(Constants.getQuizQuestionsRequestSuccessActionType, P);
    }
    export function getQuizQuestionsRequestFailure(): getQuizQuestionsRequestFailureAction {
        return ActionCreator(Constants.getQuizQuestionsRequestFailureActionType, null);
    }

    export type getSingleCourseResultRequestAction = Action<typeof Constants.getSingleCourseResultRequestActionType, SearchModel>
    export type getSingleCourseResultRequestSuccessAction = Action<typeof Constants.getSingleCourseResultRequestSuccessActionType, PagingDataModel<SingleCourseResult>>;
    export type getSingleCourseResultRequestFailureAction = Action<typeof Constants.getSingleCourseResultRequestFailureActionType, null>;

    export function getSingleCourseResultRequest(P: SearchModel): getSingleCourseResultRequestAction {
        return ActionCreator(Constants.getSingleCourseResultRequestActionType, P);
    }
    export function getSingleCourseResultRequestSuccess(P: PagingDataModel<SingleCourseResult>): getSingleCourseResultRequestSuccessAction {
        return ActionCreator(Constants.getSingleCourseResultRequestSuccessActionType, P);
    }
    export function getSingleCourseResultRequestFailure(): getSingleCourseResultRequestFailureAction {
        return ActionCreator(Constants.getSingleCourseResultRequestFailureActionType, null);
    }

    export type getRecentCourseActivityRequestAction = Action<typeof Constants.getRecentCourseActivityRequestActionType, SearchModel>
    export type getRecentCourseActivityRequestSuccessAction = Action<typeof Constants.getRecentCourseActivityRequestSuccessActionType, PagingDataModel<RecentCourseActivity>>;
    export type getRecentCourseActivityRequestFailureAction = Action<typeof Constants.getRecentCourseActivityRequestFailureActionType, null>;

    export function getRecentCourseActivityRequest(P: SearchModel): getRecentCourseActivityRequestAction {
        return ActionCreator(Constants.getRecentCourseActivityRequestActionType, P);
    }
    export function getRecentCourseActivityRequestSuccess(P: PagingDataModel<RecentCourseActivity>): getRecentCourseActivityRequestSuccessAction {
        return ActionCreator(Constants.getRecentCourseActivityRequestSuccessActionType, P);
    }
    export function getRecentCourseActivityRequestFailure(): getRecentCourseActivityRequestFailureAction {
        return ActionCreator(Constants.getRecentCourseActivityRequestFailureActionType, null);
    }

    export type getRecentGradeRequestAction = Action<typeof Constants.getRecentGradeRequestActionType, null>
    export type getRecentGradeRequestSuccessAction = Action<typeof Constants.getRecentGradeRequestSuccessActionType, RecentGradeModel[]>;
    export type getRecentGradeRequestFailureAction = Action<typeof Constants.getRecentGradeRequestFailureActionType, null>;

    export function getRecentGradeRequest(): getRecentGradeRequestAction {
        return ActionCreator(Constants.getRecentGradeRequestActionType, null);
    }
    export function getRecentGradeRequestSuccess(P: RecentGradeModel[]): getRecentGradeRequestSuccessAction {
        return ActionCreator(Constants.getRecentGradeRequestSuccessActionType, P);
    }
    export function getRecentGradeRequestFailure(): getRecentGradeRequestFailureAction {
        return ActionCreator(Constants.getRecentGradeRequestFailureActionType, null);
    }

    export type getSingleQuizQuestionRequestAction = Action<typeof Constants.getSingleQuizQuestionRequestActionType, SearchModel>
    export type getSingleQuizQuestionRequestSuccessAction = Action<typeof Constants.getSingleQuizQuestionRequestSuccessActionType, PagingDataModel<SingleQuizQuestion>>;
    export type getSingleQuizQuestionRequestFailureAction = Action<typeof Constants.getSingleQuizQuestionRequestFailureActionType, null>;

    export function getSingleQuizQuestionRequest(P: SearchModel): getSingleQuizQuestionRequestAction {
        return ActionCreator(Constants.getSingleQuizQuestionRequestActionType, P);
    }
    export function getSingleQuizQuestionRequestSuccess(P: PagingDataModel<SingleQuizQuestion>): getSingleQuizQuestionRequestSuccessAction {
        return ActionCreator(Constants.getSingleQuizQuestionRequestSuccessActionType, P);
    }
    export function getSingleQuizQuestionRequestFailure(): getSingleQuizQuestionRequestFailureAction {
        return ActionCreator(Constants.getSingleQuizQuestionRequestFailureActionType, null);
    }

    export type getQuestionByQuizIdRequestAction = Action<typeof Constants.getQuestionByQuizIdRequestActionType, SearchModel>
    export type getQuestionByQuizIdRequestSuccessAction = Action<typeof Constants.getQuestionByQuizIdRequestSuccessActionType, PagingDataModel<QuestionByQuizModel>>;
    export type getQuestionByQuizIdRequestFailureAction = Action<typeof Constants.getQuestionByQuizIdRequestFailureActionType, null>;

    export function getQuestionByQuizIdRequest(P: SearchModel): getQuestionByQuizIdRequestAction {
        return ActionCreator(Constants.getQuestionByQuizIdRequestActionType, P);
    }
    export function getQuestionByQuizIdRequestSuccess(P: PagingDataModel<QuestionByQuizModel>): getQuestionByQuizIdRequestSuccessAction {
        return ActionCreator(Constants.getQuestionByQuizIdRequestSuccessActionType, P);
    }
    export function getQuestionByQuizIdRequestFailure(): getQuestionByQuizIdRequestFailureAction {
        return ActionCreator(Constants.getQuestionByQuizIdRequestFailureActionType, null);
    }

    export type getPollAnalyticsRequestAction = Action<typeof Constants.getPollAnalyticsRequestActionType, PollAnalyticFilterModel>
    export type getPollAnalyticsRequestSuccessAction = Action<typeof Constants.getPollAnalyticsRequestSuccessActionType, PagingDataModel<PollAnalyticModel>>;
    export type getPollAnalyticsRequestFailureAction = Action<typeof Constants.getPollAnalyticsRequestFailureActionType, null>;

    export function getPollAnalyticsRequest(P: PollAnalyticFilterModel): getPollAnalyticsRequestAction {
        return ActionCreator(Constants.getPollAnalyticsRequestActionType, P);
    }
    export function getPollAnalyticsRequestSuccess(P: PagingDataModel<PollAnalyticModel>): getPollAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getPollAnalyticsRequestSuccessActionType, P);
    }
    export function getPollAnalyticsRequestFailure(): getPollAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getPollAnalyticsRequestFailureActionType, null);
    }

    export type getPollQuestionRequestAction = Action<typeof Constants.getPollQuestionRequestActionType, SearchModel>
    export type getPollQuestionRequestSuccessAction = Action<typeof Constants.getPollQuestionRequestSuccessActionType, PagingDataModel<PollQuestionModel>>;
    export type getPollQuestionRequestFailureAction = Action<typeof Constants.getPollQuestionRequestFailureActionType, null>;

    export function getPollQuestionRequest(P: SearchModel): getPollQuestionRequestAction {
        return ActionCreator(Constants.getPollQuestionRequestActionType, P);
    }
    export function getPollQuestionRequestSuccess(P: PagingDataModel<PollQuestionModel>): getPollQuestionRequestSuccessAction {
        return ActionCreator(Constants.getPollQuestionRequestSuccessActionType, P);
    }
    export function getPollQuestionRequestFailure(): getPollQuestionRequestFailureAction {
        return ActionCreator(Constants.getPollQuestionRequestFailureActionType, null);
    }

    export type getSinglePollRequestAction = Action<typeof Constants.getSinglePollRequestActionType, SearchModel>
    export type getSinglePollRequestSuccessAction = Action<typeof Constants.getSinglePollRequestSuccessActionType, PagingDataModel<SinglePollModel>>;
    export type getSinglePollRequestFailureAction = Action<typeof Constants.getSinglePollRequestFailureActionType, null>;

    export function getSinglePollRequest(P: SearchModel): getSinglePollRequestAction {
        return ActionCreator(Constants.getSinglePollRequestActionType, P);
    }
    export function getSinglePollRequestSuccess(P: PagingDataModel<SinglePollModel>): getSinglePollRequestSuccessAction {
        return ActionCreator(Constants.getSinglePollRequestSuccessActionType, P);
    }
    export function getSinglePollRequestFailure(): getSinglePollRequestFailureAction {
        return ActionCreator(Constants.getSinglePollRequestFailureActionType, null);
    }

    export type getSinglePollQuestionRequestAction = Action<typeof Constants.getSinglePollQuestionRequestActionType, SearchModel>
    export type getSinglePollQuestionRequestSuccessAction = Action<typeof Constants.getSinglePollQuestionRequestSuccessActionType, PagingDataModel<SinglePollQuestionModel>>;
    export type getSinglePollQuestionRequestFailureAction = Action<typeof Constants.getSinglePollQuestionRequestFailureActionType, null>;

    export function getSinglePollQuestionRequest(P: SearchModel): getSinglePollQuestionRequestAction {
        return ActionCreator(Constants.getSinglePollQuestionRequestActionType, P);
    }
    export function getSinglePollQuestionRequestSuccess(P: PagingDataModel<SinglePollQuestionModel>): getSinglePollQuestionRequestSuccessAction {
        return ActionCreator(Constants.getSinglePollQuestionRequestSuccessActionType, P);
    }
    export function getSinglePollQuestionRequestFailure(): getSinglePollQuestionRequestFailureAction {
        return ActionCreator(Constants.getSinglePollQuestionRequestFailureActionType, null);
    }

    export type getAnswerQuestionRequestAction = Action<typeof Constants.getAnswerQuestionRequestActionType, AnswerQuestionViewModel>
    export type getAnswerQuestionRequestSuccessAction = Action<typeof Constants.getAnswerQuestionRequestSuccessActionType, PagingDataModel<AnswerQuestionModal>>;
    export type getAnswerQuestionRequestFailureAction = Action<typeof Constants.getAnswerQuestionRequestFailureActionType, null>;

    export function getAnswerQuestionRequest(P: AnswerQuestionViewModel): getAnswerQuestionRequestAction {
        return ActionCreator(Constants.getAnswerQuestionRequestActionType, P);
    }
    export function getAnswerQuestionRequestSuccess(P: PagingDataModel<AnswerQuestionModal>): getAnswerQuestionRequestSuccessAction {
        return ActionCreator(Constants.getAnswerQuestionRequestSuccessActionType, P);
    }
    export function getAnswerQuestionRequestFailure(): getAnswerQuestionRequestFailureAction {
        return ActionCreator(Constants.getAnswerQuestionRequestFailureActionType, null);
    }

    export type getSalesOrderTotalRequestAction = Action<typeof Constants.getSalesOrderTotalRequestActionType, null>
    export type getSalesOrderTotalRequestSuccessAction = Action<typeof Constants.getSalesOrderTotalRequestSuccessActionType, SalesOrderTotalModal>;
    export type getSalesOrderTotalRequestFailureAction = Action<typeof Constants.getSalesOrderTotalRequestFailureActionType, null>;

    export function getSalesOrderTotalRequest(): getSalesOrderTotalRequestAction {
        return ActionCreator(Constants.getSalesOrderTotalRequestActionType, null);
    }
    export function getSalesOrderTotalRequestSuccess(P: SalesOrderTotalModal): getSalesOrderTotalRequestSuccessAction {
        return ActionCreator(Constants.getSalesOrderTotalRequestSuccessActionType, P);
    }
    export function getSalesOrderTotalRequestFailure(): getSalesOrderTotalRequestFailureAction {
        return ActionCreator(Constants.getSalesOrderTotalRequestFailureActionType, null);
    }

    export type getSingleQuizTotalRequestAction = Action<typeof Constants.getSingleQuizTotalRequestActionType, string>
    export type getSingleQuizTotalRequestSuccessAction = Action<typeof Constants.getSingleQuizTotalRequestSuccessActionType, SingleQuizTotalModal>;
    export type getSingleQuizTotalRequestFailureAction = Action<typeof Constants.getSingleQuizTotalRequestFailureActionType, null>;

    export function getSingleQuizTotalRequest(P: string): getSingleQuizTotalRequestAction {
        return ActionCreator(Constants.getSingleQuizTotalRequestActionType, P);
    }
    export function getSingleQuizTotalRequestSuccess(P: SingleQuizTotalModal): getSingleQuizTotalRequestSuccessAction {
        return ActionCreator(Constants.getSingleQuizTotalRequestSuccessActionType, P);
    }
    export function getSingleQuizTotalRequestFailure(): getSingleQuizTotalRequestFailureAction {
        return ActionCreator(Constants.getSingleQuizTotalRequestFailureActionType, null);
    }

    export type getAnalyticsMemberSearchRequestAction = Action<typeof Constants.getAnalyticsMemberSearchRequestActionType, GetAnalyticsMemberSearchParamModel>
    export type getAnalyticsMemberSearchRequestSuccessAction = Action<typeof Constants.getAnalyticsMemberSearchRequestSuccessActionType, PagingDataModel<AnalyticsMemberModel>>;
    export type getAnalyticsMemberSearchRequestFailureAction = Action<typeof Constants.getAnalyticsMemberSearchRequestFailureActionType, null>;

    export function getAnalyticsMemberSearchRequest(P: GetAnalyticsMemberSearchParamModel): getAnalyticsMemberSearchRequestAction {
        return ActionCreator(Constants.getAnalyticsMemberSearchRequestActionType, P);
    }
    export function getAnalyticsMemberSearchRequestSuccess(P: PagingDataModel<AnalyticsMemberModel>): getAnalyticsMemberSearchRequestSuccessAction {
        return ActionCreator(Constants.getAnalyticsMemberSearchRequestSuccessActionType, P);
    }
    export function getAnalyticsMemberSearchRequestFailure(): getAnalyticsMemberSearchRequestFailureAction {
        return ActionCreator(Constants.getAnalyticsMemberSearchRequestFailureActionType, null);
    }

    export type getQuizQuestionDataRequestAction = Action<typeof Constants.getQuizQuestionDataRequestActionType, SearchModel>
    export type getQuizQuestionDataRequestSuccessAction = Action<typeof Constants.getQuizQuestionDataRequestSuccessActionType, QuizQuestionsResultsModel>;
    export type getQuizQuestionDataRequestFailureAction = Action<typeof Constants.getQuizQuestionDataRequestFailureActionType, null>;

    export function getQuizQuestionDataRequest(P: SearchModel): getQuizQuestionDataRequestAction {
        return ActionCreator(Constants.getQuizQuestionDataRequestActionType, P);
    }
    export function getQuizQuestionDataRequestSuccess(P: QuizQuestionsResultsModel): getQuizQuestionDataRequestSuccessAction {
        return ActionCreator(Constants.getQuizQuestionDataRequestSuccessActionType, P);
    }
    export function getQuizQuestionDataRequestFailure(): getQuizQuestionDataRequestFailureAction {
        return ActionCreator(Constants.getQuizQuestionDataRequestFailureActionType, null);
    }

    export type getPollWidgetRequestAction = Action<typeof Constants.getPollWidgetRequestActionType, null>
    export type getPollWidgetRequestSuccessAction = Action<typeof Constants.getPollWidgetRequestSuccessActionType, PollWidgetModel>;
    export type getPollWidgetRequestFailureAction = Action<typeof Constants.getPollWidgetRequestFailureActionType, null>;

    export function getPollWidgetRequest(): getPollWidgetRequestAction {
        return ActionCreator(Constants.getPollWidgetRequestActionType, null);
    }
    export function getPollWidgetRequestSuccess(P: PollWidgetModel): getPollWidgetRequestSuccessAction {
        return ActionCreator(Constants.getPollWidgetRequestSuccessActionType, P);
    }
    export function getPollWidgetRequestFailure(): getPollWidgetRequestFailureAction {
        return ActionCreator(Constants.getPollWidgetRequestFailureActionType, null);
    }

    export type getSingleVideoAnalyticsRequestAction = Action<typeof Constants.getSingleVideoAnalyticsRequestActionType, GetSingleVideoAnalyticModel>
    export type getSingleVideoAnalyticsRequestSuccessAction = Action<typeof Constants.getSingleVideoAnalyticsRequestSuccessActionType, PagingDataModel<SingleVideoAnalyticsModel>>;
    export type getSingleVideoAnalyticsRequestFailureAction = Action<typeof Constants.getSingleVideoAnalyticsRequestFailureActionType, null>;

    export function getSingleVideoAnalyticsRequest(P: GetSingleVideoAnalyticModel): getSingleVideoAnalyticsRequestAction {
        return ActionCreator(Constants.getSingleVideoAnalyticsRequestActionType, P);
    }
    export function getSingleVideoAnalyticsRequestSuccess(P: PagingDataModel<SingleVideoAnalyticsModel>): getSingleVideoAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getSingleVideoAnalyticsRequestSuccessActionType, P);
    }
    export function getSingleVideoAnalyticsRequestFailure(): getSingleVideoAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getSingleVideoAnalyticsRequestFailureActionType, null);
    }
}

export type AnalyticsActions = Omit<typeof AnalyticsActions, 'Type'>;
export const useAnalyticsActions = (dispatch: Dispatch) => {
    const { ...actions } = AnalyticsActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as AnalyticsActions;
};