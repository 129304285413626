import React, { useEffect, useState, useRef } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageToS3 } from 'src/components/utilities/utils';
import Loader from 'src/components/loader';

interface IProps {
  videoId: string;
  isSavedLoading: boolean;
}

const PlayPage: React.FC<IProps> = ({ videoId, isSavedLoading }) => {
  const dispatch = useDispatch();
  const videoLibraryAction = useVideosActions(dispatch);
  const [playerTemplateId, setPlayerTemplateId] = useState<string>();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>('');
  const [backgroundImageName, setBackgroundImageName] = useState<string>('');
  const [logoImageUrl, setLogoImageUrl] = useState<string>('');
  const [logoImageName, setLogoImageName] = useState<string>('');
  const [removeLogo, setRemoveLogo] = useState<boolean>(false);
  const [removeBackground, setRemoveBackground] = useState<boolean>(false);
  const InputFile = useRef<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch
  } = useForm();
  const { playPage, isGetPlayPageLoading, isPlayPageUpdated, singleVideo } = useSelector(
    (state: RootState) => {
      return {
        playPage: state.videos.playPage,
        isGetPlayPageLoading: state.videos.isGetPlayPageLoading,
        isPlayPageUpdated: state.videos.isPlayPageUpdated,
        singleVideo: state.videos.singleVideo
      };
    }
  );
  const handleSelectNewClick = () => {
    InputFile.current.click();
  };

  const onSubmit = (data: any) => {
    const payloadData = {
      id: videoId,
      updateData: [
        { op: 'replace', path: 'playerBackgroundColor', value: data.playerBackgroundColor },
        { op: 'replace', path: 'playerButtonColor', value: data.playerButtonColor },
        { op: 'replace', path: 'playerCustomCSS', value: data.playerCustomCSS },
        { op: 'replace', path: 'playerMediaControlBar', value: data.playerMediaControlBar },
        { op: 'replace', path: 'playerSettingsColor', value: data.playerSettingsColor },
        { op: 'replace', path: 'playerTemplateId', value: data.playerTemplateId },
        { op: 'replace', path: 'playerTheaterMode', value: data.playerTheaterMode },
        { op: 'replace', path: 'playerTheaterModeOn', value: data.playerTheaterModeOn },
        { op: 'replace', path: 'playerBackgroundImageFilePath', value: backgroundImageUrl },
        { op: 'replace', path: 'playerLogoFilePath', value: logoImageUrl }
      ]
    };
    videoLibraryAction.updatePlayPageRequest(payloadData);
  };

  useEffect(() => {
    if (isPlayPageUpdated) {
      videoLibraryAction.getPlayPageRequestRequest(videoId);
      setBackgroundImageName('');
      setLogoImageName('');
      setRemoveLogo(false);
      setRemoveBackground(false);
    }
  }, [isPlayPageUpdated]);
  useEffect(() => {
    if (playPage) {
      reset(playPage);
      setPlayerTemplateId(playPage.playerTemplateId?.toString());
      handleVideoPlayerClick(playPage.playerTemplateId?.toString());
      setBackgroundImageUrl(playPage.playerBackgroundImageFilePath);
      setLogoImageUrl(playPage.playerLogoFilePath);
    }
  }, [playPage]);
  useEffect(() => {
    if (playPage) {
      if (playPage.playerTemplateId === null) {
        setPlayerTemplateId('0');
      }
    }
  }, [playPage]);
  const disableFlag = watch('playerTemplateId');

  const handleVideoPlayerClick = (e: string) => {
    setValue('playerTemplateId', parseInt(e));
    setPlayerTemplateId(e);
  };
  const handleBackgroundImageUpload = async (selectedFile: any) => {
    setBackgroundImageName(selectedFile[0].name);
    if (selectedFile) {
      uploadImageToS3(
        selectedFile[0],
        `v2/video/upload/playerbackground?videoId=${videoId}&`,
        setBackgroundImageUrl
      );
    }
  };
  const handleLogoImageUpload = async (selectedFile: any) => {
    setLogoImageName(selectedFile[0].name);
    if (selectedFile) {
      uploadImageToS3(
        selectedFile[0],
        `v2/video/upload/playerlogo?videoId=${videoId}&`,
        setLogoImageUrl
      );
    }
  };
  return (
    <div
      className="tab-pane fade"
      id="tabs-playpageView"
      role="tabpanel"
      aria-labelledby="tabs-playpage-view">
      <form onSubmit={handleSubmit(onSubmit)}>
        {(isSavedLoading || isGetPlayPageLoading) && <Loader />}
        <div className="p-5">
          <div className="flex justify-between">
            <div className="font-bold text-lg">{singleVideo?.title}</div>
            <button type="submit" className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
              Save
            </button>
          </div>
          <h2 className="mb-8"> Video Player</h2>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold mb-1">Video Player Template</label>
              <div className="description">
                <p>
                  Use the video player template to select another player for this video only.
                  Default Player will use the site wide player that is selected in Layout &gt; Video
                  Library &gt; Play Page &gt; Video Player.{' '}
                </p>
                <p></p>
              </div>
            </div>
            <div className="md:w-2/3">
              <select
                className="input-style"
                value={playerTemplateId}
                onChange={(e) => handleVideoPlayerClick(e.target.value)}>
                <option value={'0'}>Default</option>
                <option value={'1'}>Video Mode</option>
                <option value={'2'}>Stage Mode</option>
              </select>
            </div>
          </div>
          <div className="mb-5">
            <h2>Video Player Settings</h2>
            <p>
              Use the following video player settings to override selected values from your video
              player template selected above. The settings will apply to this video only. If blank,
              it will use the settings from the selected template.{' '}
            </p>
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Background Image</label>
            </div>
            {playerTemplateId === '0' ? (
              <p>Not Uploaded</p>
            ) : (
              <div className="md:w-2/3">
                {playPage && playPage.playerBackgroundImageFilePath?.length !== 0 && (
                  <>
                    <img
                      src={`${playPage && playPage.playerBackgroundImageFilePath}`}
                      className={`mediaPlayerBackgroundImageHeight ${
                        removeBackground ? 'hidden' : ''
                      }`}
                    />
                  </>
                )}
                {backgroundImageUrl && (
                  <span
                    onClick={() => {
                      setBackgroundImageUrl(''),
                        setRemoveBackground(true),
                        setBackgroundImageName('');
                    }}
                    className="cursor-pointer site-clr">
                    Remove
                  </span>
                )}

                <div className="video-upload-btn flex-items">
                  <div className="flex items-center">
                    <label
                      onClick={handleSelectNewClick}
                      className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                      Choose File
                    </label>
                    <span className="ml-2">
                      {backgroundImageName ? backgroundImageName : 'No file chosen'}
                    </span>
                  </div>
                  <input
                    disabled={playerTemplateId === '0' ? true : false}
                    ref={InputFile}
                    type="file"
                    onChange={(e) => handleBackgroundImageUpload(e.target.files)}
                    className="hidden"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Background Color</label>
            </div>
            <div className="md:w-2/3">
              <input
                disabled={playerTemplateId === '0' ? true : false}
                placeholder="#000000"
                type="color"
                id="playerBackgroundColor"
                {...register('playerBackgroundColor')}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Button Color</label>
            </div>
            <div className="md:w-2/3">
              <input
                disabled={playerTemplateId === '0' ? true : false}
                placeholder="#ffffff"
                type="color"
                id="playerButtonColor"
                {...register('playerButtonColor')}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Media Control Bar</label>
            </div>
            <div className="md:w-2/3">
              <input
                disabled={playerTemplateId === '0' ? true : false}
                placeholder="#1a92ff"
                type="color"
                id="playerMediaControlBar"
                {...register('playerMediaControlBar')}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Video Player Setting Color</label>
            </div>
            <div className="md:w-2/3">
              <input
                disabled={playerTemplateId === '0' ? true : false}
                placeholder="#ffffff"
                type="color"
                id="playerSettingsColor"
                {...register('playerSettingsColor')}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">
                Logo Overlay
                <br />
                <small>Recommended Size: 150x120px</small>
              </label>
            </div>
            {playerTemplateId === '0' ? (
              <p>Not Uploaded</p>
            ) : (
              <div className="md:w-2/3">
                {playPage && playPage.playerLogoFilePath?.length !== 0 && (
                  <>
                    <img
                      src={`${playPage && playPage.playerLogoFilePath}`}
                      className={`mediaPlayerBackgroundImageHeight ${removeLogo ? 'hidden' : ''}`}
                    />
                  </>
                )}
                {logoImageUrl && (
                  <span
                    onClick={() => {
                      setLogoImageUrl(''), setRemoveLogo(true), setLogoImageName('');
                    }}
                    className="cursor-pointer site-clr">
                    Remove
                  </span>
                )}
                <div className="video-upload-btn flex-items">
                  <div className="flex items-center">
                    <label
                      htmlFor="uploadPhoto"
                      className="btn-primary cursor-pointer w-auto mx-0 mt-1">
                      Choose File
                    </label>
                    <span className="ml-2">{logoImageName ? logoImageName : 'No file chosen'}</span>
                  </div>
                  <input
                    type="file"
                    id="uploadPhoto"
                    className="hidden"
                    onChange={(e) => handleLogoImageUpload(e.target.files)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="md:flex items-center mb-5">
            <div className="md:w-1/3">
              <label className="font-semibold">Custom Css</label>
            </div>
            <div className="md:w-2/3">
              <input
                disabled={playerTemplateId === '0' ? true : false}
                className="input-style mr-5"
                placeholder=""
                type="text"
                id="playerCustomCSS"
                {...register('playerCustomCSS')}
              />
            </div>
          </div>
          <div className={`${playerTemplateId === '2' ? 'hidden' : ''}`}>
            <div className={`md:flex items-center mb-5 `}>
              <div className="md:w-1/3">
                <label className="font-semibold">Theater Mode</label>
              </div>
              <div className="md:w-2/3">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                  <div className="relative">
                    <input
                      disabled={playerTemplateId === '0' ? true : false}
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="playerTheaterMode"
                      {...register('playerTheaterMode')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex items-center mb-5">
              <div className="md:w-1/3">
                <label className="font-semibold">Turn On Theatre Mode</label>
              </div>
              <div className="md:w-2/3">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                  <div className="relative">
                    <input
                      disabled={playerTemplateId === '0' ? true : false}
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="playerTheaterModeOn"
                      {...register('playerTheaterModeOn')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PlayPage;
