import React, { useEffect, useRef } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';

const ChatStyle = () => {
  const iframeRef = useRef<any>(null);
  const { chatStyle } = useSelector((state: RootState) => {
    return {
      chatStyle: state.appearance.chatStyle
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleColorChange = (color: string, from: string) => {
    if (from === 'bodyBackground') {
      chgChatColor('--chat-box-bg', color);
    } else if (from === 'leftMessageBackground') {
      chgChatColor('--msg-l-bg', color);
    } else if (from === 'leftMessageTextColor') {
      chgChatColor('--msg-l-color', color);
    } else if (from === 'rightMessageBackground') {
      chgChatColor('--msg-r-bg', color);
    } else if (from === 'rightMessageTextColor') {
      chgChatColor('--msg-r-color', color);
    } else if (from === 'chatBorderColor') {
      chgChatColor('--chat-box-border-color', color);
    } else if (from === 'popupBackgroundColor') {
      chgChatColor('--chat-popup-bg-color', color);
    } else if (from === 'popupHoverColor') {
      chgChatColor('--chat-popup-hov-bg-color', color);
    } else if (from === 'popupColor') {
      chgChatColor('--chat-popup-text-color', color);
    } else if (from === 'notificationBackgroundColor') {
      chgChatColor('--chat-notification-bg-color', color);
    } else if (from === 'notificationTextColor') {
      chgChatColor('--chat-notification-color', color);
    }
  };

  const chgChatColor = (propertyName: string, color: string) => {
    const chatObj = document.querySelector('#chatFrame') as any;
    chatObj.contentWindow.postMessage(
      JSON.stringify({ action: 'setcolor', text: propertyName, value: color }),
      '*'
    );
  };
  return (
    <div className="tab-pane fade" id="tabs-chatStyle" role="tabpanel">
      <div className="md:p-5 p-3 acc">
        <div className="lg:flex gap-6">
          <div className="accordion md:p-4 md:w-2/3" id="videoAccordion">
            <div className="accordion-item bg-white shadow border-0 mb-3">
              <h2 className="accordion-header mb-0" id="branding">
                <button
                  className="accordion-button
                collapsed
                accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne">
                  Chat Message Style
                </button>
              </h2>
              <div
                id="collapseOne"
                data-bs-parent="#videoAccordion"
                className="accordion-collapse border-0 collapse">
                <div className="accordion-body p-5">
                  <div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Chat Body Background</label>
                        <p>Chat Body Background</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) => handleColorChange(e.target.value, 'bodyBackground')}
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Left Message Background Color</label>
                        <p>Chat message left background color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'leftMessageBackground')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Left Message Text Color</label>
                        <p>Chat message left text color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'leftMessageTextColor')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Right Message Background Color</label>
                        <p>Chat message right background color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'rightMessageBackground')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Right Message Text Color</label>
                        <p>Chat message right text color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'rightMessageTextColor')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Chat Border Color</label>
                        <p>Chat border color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) => handleColorChange(e.target.value, 'chatBorderColor')}
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 my-3">
              <h2 className="accordion-header mb-0" id="headingTwo">
                <button
                  className="accordion-button
              collapsed
              accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                  Chat Popup Menu Style
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse border-0 collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#videoAccordion">
                <div className="accordion-body p-5">
                  <div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Setting popup background color</label>
                        <p>Setting popup background color</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'popupBackgroundColor')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">
                          Setting popup Hover background color
                        </label>
                        <p>Setting popup Hover background color</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) => handleColorChange(e.target.value, 'popupHoverColor')}
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Setting popup color</label>
                        <p>Setting popup color</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) => handleColorChange(e.target.value, 'popupColor')}
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-white shadow border-0 my-3">
              <h2 className="accordion-header mb-0" id="headingThree">
                <button
                  className="accordion-button
              collapsed
              accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                  Chat Notification
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse border-0 collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#videoAccordion">
                <div className="accordion-body p-5">
                  <div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Notification background color</label>
                        <p>Set the notification background color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'notificationBackgroundColor')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                    <div className="md:flex items-center mb-5">
                      <div className="md:w-1/3 pr-5">
                        <label className="font-semibold">Notification text color</label>
                        <p>Set the notification text color.</p>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          onChange={(e) =>
                            handleColorChange(e.target.value, 'notificationTextColor')
                          }
                          placeholder="#000000"
                          type="color"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:flex items-center mb-5">
              <div className="md:w-1/3 pr-5">
                <label className="font-semibold">Custom Styles</label>
                <p>custom chat css styles.</p>
              </div>
              <div className="md:w-2/3">
                <textarea rows={6} cols={100} className="input-style"></textarea>
              </div>
            </div>
          </div>
          <div className="md:w-2/5">
            <div className="feature-div">
              <iframe
                ref={iframeRef}
                id="chatFrame"
                src={`${process.env.REACT_APP_WCC_CHAT}?v=1.01&amp;isdemo=true`}
                width="100%"
                height="400px"
                allow="autoplay"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatStyle;
