import React, { useState, useEffect } from 'react';
import '../../styles.scss';
import { useForm } from 'react-hook-form';
import { useChannelActions } from 'src/store/channel/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { useVideosActions } from 'src/store/videos/actions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
const ChannelHightlightReelsTab = () => {
  const dispatch = useDispatch();
  const channelActions = useChannelActions(dispatch);
  const videoActions = useVideosActions(dispatch);
  const { id } = useParams<string>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
    watch
  } = useForm();
  const { singleChannel, isChannelSavedLoading, videos, highlightReelVideo } = useSelector(
    (state: RootState) => {
      return {
        singleChannel: state.channel.singleChannel,
        isChannelSavedLoading: state.channel.isChannelSavedLoading,
        videos: state.videos.videos,
        highlightReelVideo: state.channel.highlightReelVideo
      };
    }
  );
  const onSubmit = (data: any) => {
    const payloadData = {
      channelId: data.id,
      updateData: [
        { op: 'replace', path: 'highlightReelFlag', value: data.highlightReelFlag },
        {
          op: 'replace',
          path: 'displayHighlightreelOnParent',
          value: data.displayHighlightreelOnParent
        },
        {
          op: 'replace',
          path: 'highlightReelBackgroundColor',
          value: data.highlightReelBackgroundColor
        },
        { op: 'replace', path: 'highLightReelType', value: data.highLightReelType },
        { op: 'replace', path: 'indicatorColorSelector', value: data.indicatorColorSelector },
        { op: 'replace', path: 'indicatorColor', value: data.indicatorColor },
        { op: 'replace', path: 'displayVideoTitle', value: data.displayVideoTitle },
        { op: 'replace', path: 'highlightReelH1Text', value: data.highlightReelH1Text },
        { op: 'replace', path: 'highlightReelH1FontSize', value: data.highlightReelH1FontSize },
        { op: 'replace', path: 'highlightReelH1FontColor', value: data.highlightReelH1FontColor },
        { op: 'replace', path: 'highlightReelH2Text', value: data.highlightReelH2Text },
        { op: 'replace', path: 'highlightReelH2FontSize', value: data.highlightReelH2FontSize },
        { op: 'replace', path: 'highlightReelH2FontColor', value: data.highlightReelH2FontColor }
      ]
    };
    channelActions.updateChannelRequest(payloadData);
  };
  useEffect(() => {
    if (singleChannel) {
      reset(singleChannel);
    }
  }, [singleChannel]);
  const handleCheckClick = (value: string) => {
    if (value === 'highlightReelBackgroundColor') {
      setValue('highlightReelBackgroundColor', '#FFFFFF');
    } else if (value === 'indicatorColorSelector') {
      setValue('indicatorColorSelector', '#FFFFFF');
    }
  };

  useEffect(() => {
    const payloadData = {
      channelId: id,
      pageNumber: 1
    };
    id && videoActions.searchVideoRequest(payloadData);
  }, []);
  const SelectVideoType = (value: number) => {
    if (value === 0) {
      return 'Paid';
    } else if (value === 1) {
      return 'Free';
    } else if (value === 2) {
      return 'Locked';
    }
  };

  useEffect(() => {
    id && channelActions.getHighlightReelVideoRequest(id);
  }, []);
  const highlightReelFlag = watch('highlightReelFlag');
  return (
    <div className="tab-pane fade" id="tabs-channelHightlightReels" role="tabpanel">
      <form onSubmit={handleSubmit(onSubmit)}>
        <button type="submit" className="btn-primary w-auto mx-4 ml-auto mb-3">
          Save Styles
        </button>
        {isChannelSavedLoading && <Loader />}
        <div className="p-5">
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Highlight Reel Flag
              </label>
              <p>Add a highlight reel / carousel of videos to your channel page.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="highlightReelFlag"
                      {...register('highlightReelFlag')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${highlightReelFlag ? '' : 'hidden'} mb-2  `}>
            <div className="max-h-96 overflow-x-auto">
              <table className="table-auto w-full border">
                <thead className="text-xs font-semibold uppercase bg-gray-50">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Presentation Title</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Channel</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Views</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Date</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Time</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Type</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Price</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {videos &&
                    videos.map((item: any) => {
                      return (
                        <tr key={item.id}>
                          <td className="p-2">{item.title}</td>
                          <td className="p-2">{item.channelName}</td>
                          <td className="p-2"></td>
                          <td className="p-2">{moment(item.recordDate).format('DD/MM/YYYY')}</td>
                          <td className="p-2">{moment(item.recordDate).format('hh:mm A')}</td>
                          <td className="p-2">{SelectVideoType(item.videoType)}</td>
                          <td className="p-2">{item.videoCost}</td>
                          <td className="">
                            <button
                              type="button"
                              className="btn-primary w-auto borderonly-btn mb-2">
                              Add
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="md:flex items-center mb-8 mt-5">
              <div className="xl:w-4/12 lg:w-1/2">
                <label
                  className="form-check-label inline-block font-semibold switch-label mr-5"
                  htmlFor="Credit">
                  Highlight Reel Videos
                </label>
              </div>
              <div className="xl:w-8/12 lg:w-1/2">
                <div className="flex items-center">
                  <div className={` mb-2 overflow-x-auto`}>
                    <div>
                      <table className="table-auto w-full border">
                        <thead className="text-xs font-semibold uppercase bg-gray-50">
                          <tr>
                            <th className="p-2">
                              <div className="font-semibold text-left">Presentation Title</div>
                            </th>
                            <th className="p-2">
                              <div className="font-semibold ">Remove Row</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-100">
                          {highlightReelVideo &&
                            highlightReelVideo.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td className="p-2">{item.name}</td>
                                  <td className="p-2 text-left">
                                    <button
                                      type="button"
                                      className="btn-primary w-auto borderonly-btn">
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Highlight Reel on Parent
              </label>
              <p>Display highlight reel videos on parent channel.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayHighlightreelOnParent"
                      {...register('displayHighlightreelOnParent')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Highlight Reel Background Color
              </label>
              <p>Select the background color of the channel highlight reel.</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div>
                    <div className="flex items-center mb-1">
                      <input
                        onClick={() => handleCheckClick('highlightReelBackgroundColor')}
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Transparent
                      </label>
                    </div>

                    <div className="md:w-2/3">
                      <input
                        type="color"
                        id="highlightReelBackgroundColor"
                        {...register('highlightReelBackgroundColor')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Highlight Reel Type
              </label>
              <p>Highlight Reel Type</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0 w-full">
                  <select
                    id="highLightReelType"
                    {...register('highLightReelType', {
                      required: false
                    })}
                    className="border border-gray-300 input-style">
                    <option value={0}>Select</option>
                    <option value={1}>Glide</option>
                    <option value={2}>Large</option>
                    <option value={3}>Small</option>
                    <option value={4}>3D</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Indicator Menu
              </label>
              <p>Display carousel indicator dots on the highlight reel.</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="indicatorColor"
                      {...register('indicatorColor')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Indicator Color Selector
              </label>
              <p>Set the color for the the carousel indicators when the item is selected.</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div>
                    <div className="flex items-center mb-1">
                      <input
                        onClick={() => handleCheckClick('indicatorColorSelector')}
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Transparent
                      </label>
                    </div>

                    <div className="md:w-2/3">
                      <input
                        type="color"
                        id="indicatorColorSelector"
                        {...register('indicatorColorSelector')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                Display Video Title
              </label>
              <p>Display the video title on the video cards in the highlight reel.</p>
            </div>

            <div className="xl:w-8/12 lg:w-1/2">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 lg:mx-10 md:mx-5 mx-0">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      role="switch"
                      id="displayVideoTitle"
                      {...register('displayVideoTitle')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                H1
              </label>
              <p>Set the heading title for the highlight reel.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2 flex items-center lg:mx-10 md:mx-5 mx-0">
              <div className="md:w-1/2">
                <select
                  id="highlightReelH1Text"
                  {...register('highlightReelH1Text', {
                    required: false
                  })}
                  className="border border-gray-300 input-style">
                  <option value={0}>Select Display Text</option>
                  <option value={1}>Video Title</option>
                  <option value={2}>Speaker Name</option>
                </select>
              </div>
              <div className="md:w-1/2 ml-2">
                <input
                  type="text"
                  placeholder="Set Font Size"
                  id="highlightReelH1FontSize"
                  {...register('highlightReelH1FontSize')}
                  className="border border-gray-300 input-style"
                />
              </div>
              <div className="ml-2">
                <input
                  type="color"
                  placeholder="Select Color"
                  id="highlightReelH1FontColor"
                  {...register('highlightReelH1FontColor')}
                // className="border border-gray-300 input-style"
                />
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-8">
            <div className="xl:w-4/12 lg:w-1/2">
              <label
                className="form-check-label inline-block font-semibold switch-label mr-5"
                htmlFor="Credit">
                H2
              </label>
              <p>Set the sub-heading for the highlight reel.</p>
            </div>
            <div className="xl:w-8/12 lg:w-1/2 flex items-center lg:mx-10 md:mx-5 mx-0">
              <div className="md:w-1/2">
                <select
                  id="highlightReelH2Text"
                  {...register('highlightReelH2Text', {
                    required: false
                  })}
                  className="border border-gray-300 input-style">
                  <option value={0}>Select Display Text</option>
                  <option value={1}>Video Title</option>
                  <option value={2}>Speaker Name</option>
                </select>
              </div>
              <div className="md:w-1/2 ml-2">
                <input
                  type="text"
                  placeholder="Set Font Size"
                  id="highlightReelH2FontSize"
                  {...register('highlightReelH2FontSize')}
                  className="border border-gray-300 input-style"
                />
              </div>
              <div className="ml-2">
                <input
                  type="color"
                  placeholder="Select Color"
                  id="highlightReelH2FontColor"
                  {...register('highlightReelH2FontColor')}
                // className="border border-gray-300 input-style"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChannelHightlightReelsTab;
