import { all, put, takeEvery } from 'redux-saga/effects';
import { AppearanceActions } from './actions';
import { AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetSiteIdentity() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_SITE_IDENTITY.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getSiteIdentityRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getSiteIdentityRequestFailure());
    }
}

function* asyncUpdateAppearance(data: AppearanceActions.updateAppearanceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(URLS.UPDATE_APPEARANCE.url, data.payload);
        if (response.status === 200) {
            yield put(AppearanceActions.updateAppearanceRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.updateAppearanceRequestFailure());
    }
}

function* asyncGetColor() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_COLOR.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getColorsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getColorsRequestFailure());
    }
}

function* asyncGetFont() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_FONT.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getFontsRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getFontsRequestFailure());
    }
}

function* asyncGetUserProfile() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_USER_PROFILE.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getUserProfileRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getUserProfileRequestFailure());
    }
}

function* asyncGetVideoCard() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_VIDEO_CARDS.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getVideoCardRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getVideoCardRequestFailure());
    }
}

function* asyncGetPlayPageVideo() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PLAY_PAGE_VIDEO.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getPlayPageVideoRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getPlayPageVideoRequestFailure());
    }
}

function* asyncGetPlayPageNavigation() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PLAY_PAGE_NAVIGATION.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getPlayPageNavigationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getPlayPageNavigationRequestFailure());
    }
}

function* asyncGetChatStyle() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHAT_STYLE.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getChatStyleRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getChatStyleRequestFailure());
    }
}

function* asyncGetNavigation() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_NAVIGATION.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getNavigationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getNavigationRequestFailure());
    }
}

function* asyncSaveNavigation(data: AppearanceActions.saveNavigationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_NAVIGATION.url, data.payload);
        if (response.status === 200) {
            yield put(AppearanceActions.saveNavigationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.saveNavigationRequestFailure());
    }
}

function* asyncGetSingleNavigation(data: AppearanceActions.getSingleNavigationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_SINGLE_NAVIGATION.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(AppearanceActions.getSingleNavigationRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getSingleNavigationRequestFailure());
    }
}

function* asyncUpdateNavigation(data: AppearanceActions.updateNavigationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_NAVIGATION.url}/${data.payload?.navigationId}`, data.payload?.updateData);
        if (response.status === 200) {
            yield put(AppearanceActions.updateNavigationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.updateNavigationRequestFailure());
    }
}

function* asyncDeleteNavigation(data: AppearanceActions.deleteNavigationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_NAVIGATION.url}/${data.payload}`);
        if (response.status === 200) {
            yield put(AppearanceActions.deleteNavigationRequestSuccess(response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.deleteNavigationRequestFailure());
    }
}

function* asyncGetSearchSetting() {
    try {
        const response: AxiosResponse = yield ApiService.getData('');
        if (response.status === 200) {
            yield put(AppearanceActions.getSearchSettingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getSearchSettingRequestFailure());
    }
}

function* asyncGetPlayPageSetting() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_PLAY_PAGE_SETTING.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getPlayPageSettingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getPlayPageSettingRequestFailure());
    }
}

function* asyncGetAdvanceSetting() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_ADVANCE_SETTING.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getAdvanceSettingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getAdvanceSettingRequestFailure());
    }
}

function* asyncGetFilterSetting() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_FILTER_SETTINGS.url);
        if (response.status === 200) {
            yield put(AppearanceActions.getFilterSettingRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(AppearanceActions.getFilterSettingRequestFailure());
    }
}

export function* appearanceSaga() {
    yield all([
        takeEvery(Constants.getSiteIdentityRequestActionType, asyncGetSiteIdentity),
        takeEvery(Constants.updateAppearanceRequestActionType, asyncUpdateAppearance),
        takeEvery(Constants.getColorsRequestActionType, asyncGetColor),
        takeEvery(Constants.getFontsRequestActionType, asyncGetFont),
        takeEvery(Constants.getUserProfileRequestActionType, asyncGetUserProfile),
        takeEvery(Constants.getVideoCardRequestActionType, asyncGetVideoCard),
        takeEvery(Constants.getPlayPageVideoRequestActionType, asyncGetPlayPageVideo),
        takeEvery(Constants.getPlayPageVideoRequestActionType, asyncGetPlayPageNavigation),
        takeEvery(Constants.getChatStyleRequestActionType, asyncGetChatStyle),
        takeEvery(Constants.getNavigationRequestActionType, asyncGetNavigation),
        takeEvery(Constants.saveNavigationRequestActionType, asyncSaveNavigation),
        takeEvery(Constants.getSingleNavigationRequestActionType, asyncGetSingleNavigation),
        takeEvery(Constants.updateNavigationRequestActionType, asyncUpdateNavigation),
        takeEvery(Constants.deleteNavigationRequestActionType, asyncDeleteNavigation),
        takeEvery(Constants.getSearchSettingRequestActionType, asyncGetSearchSetting),
        takeEvery(Constants.getPlayPageSettingRequestActionType, asyncGetPlayPageSetting),
        takeEvery(Constants.getAdvanceSettingRequestActionType, asyncGetAdvanceSetting),
        takeEvery(Constants.getFilterSettingRequestActionType, asyncGetFilterSetting),
    ]);
}
