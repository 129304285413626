import React, { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useMonetizationActions } from 'src/store/monetization/actions';
import Select, { ActionMeta } from 'react-select';
import {
  SubscriptionModel,
  PatchJsonModel,
  PatchJsonData,
  MemberListModel
} from 'src/models/monetizationModel';
import Loader from 'src/components/loader';
import { SelectModel } from 'src/models/videosModel';
import { customFilterOption } from 'src/components/utilities/utils';
import { useChannelActions } from 'src/store/channel/actions';

interface IProps {
  modalFlag: string;
  currentSubscriptionId: string;
}

const SubscriptionModal: React.FC<IProps> = ({ modalFlag, currentSubscriptionId }) => {
  const dispatch = useDispatch();
  const monetizationAction = useMonetizationActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const [finalChannels, setFinalChannels] = useState<SelectModel[]>();
  //Setting values to state variables
  const [heading, setHeading] = useState('Add');
  const [operation, setOperation] = useState('Add');
  const btnRef = useRef<HTMLButtonElement>(null);
  //Getting values from RootState
  const {
    isSubscriptionAdded,
    channels,
    subscriptionInfo,
    originalSubscriptionInfo,
    loadingDetails,
    channelList
  } = useSelector((state: RootState) => {
    return {
      isSubscriptionAdded: state.monetization.isSubscriptionAdded,
      channels: state.channel.channels,
      subscriptionInfo: state.monetization.subscriptionInfo,
      originalSubscriptionInfo: state.monetization.subscriptionInfo,
      loadingDetails: state.monetization.loadingSubscriptionDetails,
      channelList: state.channel.channelList
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<SubscriptionModel>();

  //on click of Add/Update Button
  const onSubmit = (data: any) => {
    const payloadData = { ...data, channelId: data.channelId.value };
    if (modalFlag === 'add') {
      monetizationAction.addSubscriptionRequest(payloadData);
    } else if (modalFlag === 'edit') {
      const patchJsonModel: PatchJsonModel = {
        id: data.id,
        patchJsonData: convertModelToPatch(data)
      };
      if (patchJsonModel.patchJsonData.length > 0)
        monetizationAction.editSubscriptionRequest(patchJsonModel);
    }
  };

  const convertModelToPatch = (data: any) => {
    const patchData = [];
    for (const field of Object.keys(data)) {
      const value: any = originalSubscriptionInfo;
      if (value[field] == data[field]) continue;

      const patchObjdal: PatchJsonData = {
        from: value[field],
        value: data[field],
        path: '/' + field,
        op: 'replace'
      };
      patchData.push(patchObjdal);
    }
    return patchData;
  };

  //On Cancel click
  const handleCancelClick = () => {
    /*monetizationAction.resetSubscriptionDataStateRequest();
    reset({
      name: '',
      channelName: '',
      interval: 0,
      amount: 0,
      currency: '',
      subscribersCount: 0,
    });*/
  };

  const currency = [
    { value: 'AUD', label: 'AUD' },
    { value: 'USD', label: 'USD' }
  ];

  const interval = [
    { value: '1', label: 'Day' },
    { value: '2', label: 'Week' },
    { value: '3', label: 'Month' },
    { value: '4', label: 'Year' }
  ];

  //Get the Details about the Subscription id for different Subscription in Parent page
  useEffect(() => {
    if (currentSubscriptionId.length)
      monetizationAction.getSubscriptionDetails(currentSubscriptionId);
  }, [currentSubscriptionId]);

  //Get the Channel and Memberlists details
  useEffect(() => {
    if (!channels || channels.length == 0) {
      channelAction.getChannelRequest();
    }
  }, []);

  useEffect(() => {
    if (modalFlag === 'edit') {
      reset(subscriptionInfo);
      setHeading('Edit');
      setOperation('Update');
    } else {
      setHeading('Add');
      setOperation('Add');
      reset({
        name: '',
        channelName: '',
        interval: 0,
        amount: 0,
        currency: '',
        subscribersCount: 0
      });
    }
  }, [subscriptionInfo, modalFlag]);

  //Whenever channels value changed, below code will be called
  useEffect(() => {
    channelAction.getFlattenedChannel();
  }, [channels]);

  //Whenever memberlist value changed, below code will be called
  useEffect(() => {
    if (isSubscriptionAdded === true) {
      reset({
        name: '',
        channelName: '',
        interval: 0,
        amount: 0,
        currency: '',
        subscribersCount: 0
      });
      if (btnRef.current) {
        btnRef.current.click();
      }
    }
  }, [isSubscriptionAdded]);

  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);

  useEffect(() => {
    if (channelList) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels([
        {
          value: '',
          label: 'Select Channel'
        },
        ...options
      ]);
    }
  }, [channelList]);

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="editSubscriptionModel"
      aria-labelledby="editSubscriptionModelLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full"
              id="editSubscriptionModelLabel">
              {`${heading} Subscription Information`}
            </h5>
            <button
              onClick={handleCancelClick}
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div className="modal-body relative py-4 px-10">
            {loadingDetails ? (
              <Loader />
            ) : (
              <>
                {' '}
                <form>
                  <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4 form-switch">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Active </label>
                      <input
                        type="checkbox"
                        className="form-check-input switch-btn"
                        id="active"
                        {...register('active', {
                          required: true
                        })}
                      />
                    </div>
                  </div>
                  <div className="grid  xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Name </label>
                      <input
                        type="text"
                        className="input-style"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 250
                        })}
                      />
                      {errors.name?.type === 'required' && (
                        <p className="text-red-700">Required Field</p>
                      )}
                      {errors.name?.type === 'maxLength' && (
                        <p className="text-red-700">Max. length reached</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1"> Channel </label>
                      {modalFlag == 'edit' && (
                        <>
                          <input
                            type="text"
                            className="input-style"
                            disabled
                            id="channelName"
                            {...register('channelName', {})}
                          />
                        </>
                      )}
                      {modalFlag == 'add' && (
                        <>
                          {finalChannels && Object.keys(finalChannels).length && (
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, ref },
                                fieldState: { error }
                              }) => (
                                <Select
                                  filterOption={customFilterOption}
                                  options={finalChannels}
                                  value={value}
                                  id="channelId"
                                  onChange={(val) => onChange(val)}
                                />
                              )}
                              name="channelId"
                              control={control}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Amount </label>
                      {modalFlag == 'edit' && (
                        <>
                          <input
                            type="number"
                            className="input-style"
                            id="amount"
                            disabled
                            {...register('amount', {})}
                          />
                        </>
                      )}
                      {modalFlag == 'add' && (
                        <>
                          <input
                            type="number"
                            className="input-style"
                            id="amount"
                            {...register('amount', {
                              required: true,
                              maxLength: 30,
                              pattern: /^[0-9.]/
                            })}
                          />
                          {errors.amount?.type === 'required' && (
                            <p className="text-red-700">Required Field</p>
                          )}
                          {errors.amount?.type === 'maxLength' && (
                            <p className="text-red-700">Max. length reached</p>
                          )}
                          {errors.amount?.type === 'pattern' && (
                            <p className="text-red-700">Only numbers are allowed</p>
                          )}
                        </>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1"> Currency </label>
                      {modalFlag == 'edit' && (
                        <>
                          <input
                            type="text"
                            className="input-style"
                            disabled
                            id="currency"
                            {...register('currency', {
                              required: true,
                              maxLength: 80
                            })}
                          />
                          {errors.currency?.type === 'required' && (
                            <p className="text-red-700">Required Field</p>
                          )}
                          {errors.currency?.type === 'maxLength' && (
                            <p className="text-red-700">Max. length reached</p>
                          )}
                        </>
                      )}
                      {modalFlag == 'add' && (
                        <>
                          {/* <Select options={currency} /> */}
                          <select {...register('currency')}>
                            {currency &&
                              currency.map((values: any, index: any) => (
                                <option value={values.label} key={values.name}>
                                  {' '}
                                  {values.label}{' '}
                                </option>
                              ))}
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="grid xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1"> Interval </label>
                      {/* {modalFlag == 'edit' &&
                        <>
                          <input
                            type="text"
                            className="input-style"
                            disabled
                            id="interval"
                            {...register('interval', {
                            })}
                          />
                        </>} */}
                      {/* {modalFlag == 'add' && */}
                      <>
                        <select {...register('interval')}>
                          {interval &&
                            interval.map((values: any, index: any) => (
                              <option value={values.value} key={values.name}>
                                {values.label}
                              </option>
                            ))}
                        </select>
                      </>
                      {/* } */}
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 rounded-b-md">
                    <button
                      type="button"
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)}>
                      {operation}
                    </button>
                    <button
                      onClick={handleCancelClick}
                      type="button"
                      ref={btnRef}
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionModal;
