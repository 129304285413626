export const SendLoginRequestActionType = 'SEND_LOGIN_REQUEST'
export const SendLoginRequestSuccessActionType = 'SEND_LOGIN_REQUEST_SUCCESS'
export const SendLoginRequestFailureActionType = 'SEND_LOGIN_REQUEST_FAILURE'

export const SendSSOLoginRequestActionType = 'SEND_SSO_LOGIN_REQUEST'
export const SendSSOLoginRequestSuccessActionType = 'SEND_SSO_LOGIN_REQUEST_SUCCESS'
export const SendSSOLoginRequestFailureActionType = 'SEND_SSO_LOGIN_REQUEST_FAILURE'

export const SetCallbackLinkActionType = 'SET_CALLBACK_LINK_REQUEST'
export const RemoveCallbackLinkActionType = 'REMOVE_CALLBACK_LINK_REQUEST'

export const ResetLoginStateRequestActionType = 'RESET_LOGIN_STATE_REQUEST'
export const SendLogoutRequestActionType = 'SEND_LOGOUT_REQUEST'


export const GetDomainListRequestActionType = 'GET_DOMAIN_LIST_REQUEST'
export const GetDomainListRequestSuccessActionType = 'GET_DOMAIN_LIST_REQUEST_SUCCESS'
export const GetDomainListRequestFailureActionType = 'GET_DOMAIN_LIST_REQUEST_FAILURE'

export const forgetRestPasswordRequestActionType = 'FORGET_RESET_PASSWORD_REQUEST'
export const forgetRestPasswordRequestSuccessActionType = 'FORGET_RESET_PASSWORD_REQUEST_SUCCESS'
export const forgetRestPasswordRequestFailureActionType = 'FORGET_RESET_PASSWORD_REQUEST_FAILURE'

export const resetPasswordRequestActionType = 'RESET_PASSWORD_REQUEST'
export const resetPasswordRequestSuccessActionType = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const resetPasswordRequestFailureActionType = 'RESET_PASSWORD_REQUEST_FAILURE'

export const getDefaultdateFormatRequestActionType = 'GET_DEFAULT_DATE_FORMAT_REQUEST'
export const getDefaultdateFormatRequestSuccessActionType = 'GET_DEFAULT_DATE_FORMAT_REQUEST_SUCCESS'
export const getDefaultdateFormatRequestFailureActionType = 'GET_DEFAULT_DATE_FORMAT_REQUEST_FAILURE'

export const reset = 'RESET'
