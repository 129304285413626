import React, { useState, useRef } from 'react';

interface IProps {
  onFileUpload: any;
}
const UploadCourseModal: React.FC<IProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<any>('');
  const btnRef = useRef<HTMLButtonElement>(null);
  const handleCourseThumbnailUpload = async () => {
    if (selectedFile) {
      onFileUpload(selectedFile);      
    }
    if (btnRef.current) {
      btnRef.current.click();
    }
  };
  return (
    <div>
      <div
        className="modal fade"
        id="uploadCourseModal"
        aria-labelledby="uploadCourseModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5 
                className="text-xl font-semibold leading-normal text-center w-full"
                id="uploadCourseModalLabel">
                Upload Thumbnail
              </h5>
              <button
                type="button"
                ref={btnRef}
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form>
                <label className="font-semibold">Select Thumbnail:</label>
                <div className={`form-group my-5`}>
                  <div className="input-style p-0 speaker-browse-file flex items-center">
                    <input
                      id="uploadFile"
                      className="pl-2"
                      placeholder={`${selectedFile && selectedFile[0].name}`}
                    />
                    <div className="fileUpload btn btn-primary mt-0 ml-auto mr-0 px-1 w-28">
                      <span>Select File</span>
                      <input
                        id="uploadBtn"
                        type="file"
                        className="upload"
                        onChange={(e) => setSelectedFile(e.target.files)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                onClick={handleCourseThumbnailUpload}
                type="button"
                className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                Save Changes
              </button>
              {/* <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCourseModal;
