import React, { useEffect, useState } from 'react';
import { useVideosActions } from 'src/store/videos/actions';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import ResourceModal from './ResourceModal';
import DeleteResourceModal from './DeleteResourceModal';
const Resources = ({ videoId }: any) => {
  const dispatch = useDispatch();
  const [selectedClickFlag, setSelectedClickFlag] = useState<any>();
  const [resourceID, setResourceId] = useState<any>();
  const videoLibraryAction = useVideosActions(dispatch);
  const {
    resources,
    isResourceAdded,
    isGetResourceLoading,
    isResourceDeleted,
    isResourceUpdated,
    singleVideo
  } = useSelector((state: RootState) => {
    return {
      resources: state.videos.resources,
      isResourceAdded: state.videos.isResourceAdded,
      isGetResourceLoading: state.videos.isGetResourceLoading,
      isResourceDeleted: state.videos.isResourceDeleted,
      isResourceUpdated: state.videos.isResourceUpdated,
      singleVideo: state.videos.singleVideo
    };
  });
  useEffect(() => {
    if (isResourceAdded || isResourceDeleted || isResourceUpdated) {
      const payloadData = { id: videoId, pageNumber: 1 };
      videoLibraryAction.getResourcesRequest(payloadData);
    }
  }, [isResourceAdded, isResourceDeleted, isResourceUpdated]);

  const handleEditResourceClick = (flag: string, resourceId?: string) => {
    setSelectedClickFlag({ flag: flag, resourceId: resourceId });
  };
  const handleDeleteResource = (resourceId: string) => {
    setResourceId(resourceId);
  };
  return (
    <>
      <div
        className="tab-pane fade"
        id="tabs-resourcesView"
        role="tabpanel"
        aria-labelledby="tabs-resources-view">
        <div className="p-5">
          <div className="flex border-b ">
            <div className="font-bold text-lg ml-2">{singleVideo?.title}</div>
            <button
              onClick={() => handleEditResourceClick('add')}
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#AddNewResource"
              className="btn-primary w-auto mt-0 ml-auto mr-0 mb-5 borderonly-btn">
              Add New Resources
            </button>
          </div>

          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase bg-gray-50"></thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {resources &&
                resources.map((resources) => {
                  return (
                    <tr key={resources.id} className="border-b">
                      <td className="p-3">
                        <div className="text-left">{resources.name}</div>
                      </td>
                      <td className="p-3">
                        <div className="text-left">
                          {resources.attachmentType === 'Download' ? (
                            'Download'
                          ) : (
                            <i className={`${resources.attachmentIconClass}`}></i>
                          )}
                        </div>
                      </td>
                      <td className="p-3">
                        <div className="text-right">
                          <div className="dropdown relative">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              id="action-dropdown"
                              data-bs-toggle="dropdown"
                              aria-expanded="false">
                              <img src="../../assets/img/dots-vertical.svg" className="w-5 h-5" />
                            </button>
                            <ul
                              className="dropdown-menu dropdown-menu-ul"
                              aria-labelledby="action-dropdown">
                              <li onClick={() => handleEditResourceClick('edit', resources.id)}>
                                <button
                                  type="button"
                                  className="action-menu flex items-center"
                                  data-bs-toggle="modal"
                                  data-bs-target="#AddNewResource">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="mr-2 w-4 h-4">
                                    <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                    <rect x="1" y="3" width="22" height="5"></rect>
                                    <line x1="10" y1="12" x2="14" y2="12"></line>
                                  </svg>
                                  <span>Edit</span>
                                </button>
                              </li>
                              <li onClick={() => handleDeleteResource(resources.id)}>
                                <span
                                  className="action-menu flex items-center cursor-pointer"
                                  data-bs-toggle="modal"
                                  data-bs-target="#DeleteResourceModal">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="mr-2 w-4 h-4">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                  </svg>
                                  <span>Delete</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {isGetResourceLoading && (
                <tr>
                  <td>
                    <Loader />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ResourceModal videoId={videoId} selectedClickFlag={selectedClickFlag} />
      <DeleteResourceModal resourceID={resourceID} />
    </>
  );
};

export default Resources;
