import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideosActions } from 'src/store/videos/actions';
import { SelectModel } from 'src/models/videosModel';
import Loader from 'src/components/loader';
import { MemberModel } from 'src/models/usersModel';
import { MemberListModel } from 'src/models/monetizationModel';
import Select from 'react-select';
import DiscountCodeModel from './DiscountCodeModal';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { customFilterOption } from 'src/components/utilities/utils';
import Pagination from 'src/components/common/pagination';
import DatePicker from 'react-datepicker';
import FormInputField from 'src/components/common/formInputField';
interface IProps {
  videoId: string;
}
const AccessPrice: React.FC<IProps> = ({ videoId }) => {
  const dispatch = useDispatch();
  const [finalMemberList, setFinalMemberList] = useState<SelectModel[]>();
  const [finalModerators, setFinalModerators] = useState<SelectModel[]>();
  const [discountCodeEditFlag, setDiscountCodeEditFlag] = useState<boolean>(false);
  const [expire, setExpire] = useState<any>(1);
  const [role, setRole] = useState<string>('');
  const videoActions = useVideosActions(dispatch);
  let datePickerRef: any = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control
  } = useForm();

  const {
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    setValue: setValue1,
    control: control1
  } = useForm();

  const {
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
    control: control2
  } = useForm();
  const {
    memberList,
    memberListAccess,
    isSavedLoading,
    memberListPrice,
    moderators,
    isMemberListAccessSaved,
    isMemberListPriceSaved,
    isMemberListModeratorSaved,
    isGetMemberAccessLoading,
    isGetMemberAccessPriceLoading,
    isGetModeratorLoading,
    members,
    discountCode,
    isDiscountCodeLoading,
    paginationMeta,
    singleVideo
  } = useSelector((state: RootState) => {
    return {
      memberList: state.users.memberList,
      memberListAccess: state.videos.memberListAccess,
      isSavedLoading: state.videos.isSavedLoading,
      memberListPrice: state.videos.memberListPrice,
      moderators: state.videos.moderators,
      isMemberListAccessSaved: state.videos.isMemberListAccessSaved,
      isMemberListPriceSaved: state.videos.isMemberListPriceSaved,
      isMemberListModeratorSaved: state.videos.isMemberListModeratorSaved,
      isGetMemberAccessLoading: state.videos.isGetMemberAccessLoading,
      isGetMemberAccessPriceLoading: state.videos.isGetMemberAccessPriceLoading,
      isGetModeratorLoading: state.videos.isGetModeratorLoading,
      members: state.users.members,
      discountCode: state.videos.discountCode,
      isDiscountCodeLoading: state.videos.isDiscountCodeLoading,
      paginationMeta: state.videos.paginationMeta,
      singleVideo: state.videos.singleVideo
    };
  });
  useEffect(() => {
    if (memberList && memberList.length) {
      if (memberList && memberList.length && memberListAccess.length) {
        const finalFilteredData = memberList.filter(function (memberList: MemberListModel) {
          return !memberListAccess.find(function (memberListAccess) {
            return memberListAccess.memberListId == memberList.id;
          });
        });
        const options = finalFilteredData.map((item: MemberListModel) => ({
          value: item.id,
          label: item.name
        }));
        setFinalMemberList(options);
      } else {
        const options = memberList.map((item: MemberListModel) => ({
          value: item.id,
          label: item.name
        }));
        setFinalMemberList(options);
      }
    }
  }, [memberList, memberListAccess]);
  useEffect(() => {
    if (isMemberListAccessSaved) {
      videoActions.getMemberListAccessRequestRequest(videoId);
    }
  }, [isMemberListAccessSaved]);

  const deleteMemberListAccess = (memberId: number) => {
    videoActions.deleteMemberListAccessRequestRequest(memberId);
  };
  const onSubmit = (data: any) => {
    if (data) {
      setValue('memberList', '');
      setValue('price', '');
      const memberIds = data.memberList.map((item: SelectModel) => item.value);
      const finalData = {
        memberListIds: memberIds,
        cost: data.price ? parseInt(data.price) : 0,
        videoId
      };
      videoActions.saveMemberListPriceRequest(finalData);
    }
  };
  useEffect(() => {
    if (isMemberListPriceSaved) {
      videoActions.getMemberListPriceRequestRequest(videoId);
    }
  }, [isMemberListPriceSaved]);

  useEffect(() => {
    if (isMemberListModeratorSaved) {
      videoActions.getModeratorRequest(videoId);
    }
  }, [isMemberListModeratorSaved]);
  const handleMemberListPriceDelete = (memberlistId: number) => {
    videoActions.deleteMemberListPriceRequest(memberlistId);
  };

  useEffect(() => {
    if (members) {
      if (members && members.length && moderators) {
        const finalFilteredData = members.filter(function (members: MemberModel) {
          return !moderators.find(function (moderators) {
            return moderators.memberId == members.id;
          });
        });
        const finalModerators = finalFilteredData.map((members) => {
          return {
            value: members.id,
            label: `${members.firstName} ${members.lastName} (${members.userName})`
          };
        });
        setFinalModerators(finalModerators);
      } else {
        const finalModerators = members.map((members) => {
          return {
            value: members.id,
            label: `${members.firstName} ${members.lastName} (${members.userName})`
          };
        });
        setFinalModerators(finalModerators);
      }
    }
  }, [members, moderators]);

  const onSubmit1 = (data: any) => {
    if (data) {
      setValue1('moderators', '');
      const memberIds = data.moderators.map((item: SelectModel) => item.value);
      const finalData = { memberIds: memberIds, videoId };
      videoActions.saveModeratorsRequest(finalData);
    }
  };

  const handleDeleteModeratorClick = (memberId: string) => {
    videoActions.deleteModeratorsRequest(memberId);
  };

  const handleMemberListAccessSubmit = (data: any) => {
    setValue2('memberList', '');
    const memberIds = data.memberList.map((item: SelectModel) => item.value);
    const finalData = { memberListIds: memberIds, videoId };
    videoActions.saveMemberListAccessRequestRequest(finalData);
  };

  const handleEditClick = (discountId: number | undefined) => {
    if (discountId) {
      videoActions.getSingleDiscountCodeRequest(discountId);
      setDiscountCodeEditFlag(true);
    }
  };

  const handleDeleteClick = (discountId: number | undefined) => {
    if (discountId) {
      videoActions.deleteDiscountCodesRequest(discountId);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    const payloadData = { id: videoId, pageNumber, pageSize: 10 };
    videoActions.getDiscountCodeRequest(payloadData);
  };

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    const finalData = userData && JSON.parse(userData);
    setRole(finalData.role);
  }, []);
  return (
    <div
      className="tab-pane fade"
      id="tabs-accessView"
      role="tabpanel"
      aria-labelledby="tabs-access-view">
      <div className="p-5 pr-20">
        <div className="mb-8">
          <div className="font-bold text-lg mb-2">{singleVideo?.title}</div>
          <label className="font-semibold">Access</label>
          <p className="my-4">
            Attach one or more Member Lists for access to view this video. This will provide these
            members access to watch this video if it is set to Paid, Locked or Private.
          </p>

          <form
            onSubmit={handleSubmit2(handleMemberListAccessSubmit)}
            className="mb-5 flex items-center">
            {memberList && (
              <Controller
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <Select
                    filterOption={customFilterOption}
                    options={finalMemberList}
                    value={value}
                    id="memberList"
                    className="w-full"
                    isMulti
                    onChange={(val) => onChange(val)}
                    isLoading={memberList.length ? false : true}
                  />
                )}
                name="memberList"
                control={control2}
                rules={{ required: true }}
              />
            )}
            {errors2.memberList?.type === 'required' && (
              <span className="text-red-500 text-xs flex item-center error">Required Field</span>
            )}
            <button
              type="submit"
              className="btn-primary w-auto whitespace-nowrap mt-0 ml-5 borderonly-btn">
              Add
            </button>
          </form>
          <div className="mt-5">
            <table className="table-bordered w-1/2">
              <thead>
                <tr>
                  <th>List Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {memberListAccess &&
                  memberListAccess.map((member) => {
                    return (
                      <tr key={member.id}>
                        <td>{member.name}</td>
                        <td
                          className="cursor-pointer"
                          onClick={() => deleteMemberListAccess(member.id)}>
                          Detach
                        </td>
                      </tr>
                    );
                  })}
                {isGetMemberAccessLoading && (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="my-8">
          <label className="font-semibold">Pricing</label>
          <p className="my-4">
            Set Pricing tiers for different members but assigning a price for each member list
            below. When your members login, they will see the price set for their member list. On
            Video Info tab, ensure that your video is set to Paid, Disable Inheritance is selected
            and a video price is set for non-members.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="mb-5 flex items-center">
            {memberList && (
              <Controller
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <Select
                    filterOption={customFilterOption}
                    options={finalMemberList}
                    value={value}
                    id="memberList"
                    className="w-full"
                    isMulti
                    onChange={(val) => onChange(val)}
                    isLoading={memberList.length ? false : true}
                  />
                )}
                name="memberList"
                control={control}
                rules={{ required: true }}
              />
            )}
            {errors.memberList?.type === 'required' && (
              <span className="text-red-500 text-xs flex item-center error">Required Field</span>
            )}
            <input
              type="text"
              className="input-style w-48 mx-5"
              placeholder="0.00"
              {...register('price')}
            />
            <button
              type="submit"
              className="btn-primary w-auto whitespace-nowrap mt-0 borderonly-btn">
              Add
            </button>
          </form>
          <div className="mt-5">
            <table className="table-bordered w-1/2">
              <thead>
                <tr>
                  <th>List Name</th>
                  <th>Cost</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {memberListPrice &&
                  memberListPrice.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.cost}</td>
                        <td
                          className="cursor-pointer"
                          onClick={() => handleMemberListPriceDelete(item.id)}>
                          remove
                        </td>
                      </tr>
                    );
                  })}
                {isGetMemberAccessPriceLoading && (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {role === 'Super Admin' && (
          <div className="my-8">
            <label className="font-semibold">Expiry</label>
            <div className="flex items-center">
              <label
                className="form-check-label inline-block switch-label lg:w-8/12 mr-5"
                htmlFor="Credit">
                Expire access to content automatically
              </label>
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex justify-between items-center mb-2">
                  <div className="relative">
                    <input className="form-check-input switch-btn" type="checkbox" role="switch" />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="mb-5 flex items-center">
              <div className={`form-group`}>
                <label className="block text-sm font-medium mb-1">Expire content</label>
                <select
                  className="input-style w-96"
                  {...register('expire')}
                  onChange={(e) => setExpire(e.target.value)}>
                  <option value={1}>When user purchases content</option>
                  <option value={2}>Specific time and date</option>
                </select>
              </div>
              {expire == 1 ? (
                <>
                  {' '}
                  <div className={`form-group ml-3`}>
                    <label className="block text-sm font-medium mb-1">after</label>
                    <input type="text" className="input-style" />
                  </div>
                  <div className={`form-group ml-3`}>
                    <label className="block text-sm font-medium mb-1 invisible">after</label>
                    <select className="input-style w-64" {...register('pollType')}>
                      <option value="">Day(s)</option>
                      <option value={0}>Week(s)</option>
                      <option value={1}>Month(s)</option>
                      <option value={1}>Year(s)</option>
                    </select>
                  </div>
                </>
              ) : (
                <>
                  {' '}
                  <div className={`form-group`}>
                    <label className="block text-sm font-medium mb-1 ml-4">On</label>
                    <Controller
                      name={'displayDate'}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <div className="relative">
                            <DatePicker
                              placeholderText={singleVideo.defaultDateFormat?.toUpperCase()}
                              selected={value && new Date(Date.parse(value))}
                              dateFormat={singleVideo.defaultDateFormat}
                              className="input-style w-80 ml-6"
                              onChange={(val: any) => onChange(val)}
                              ref={(r: any) => (datePickerRef = r)}
                            />
                            <i
                              onClick={() => {
                                if (datePickerRef) {
                                  if (!datePickerRef.state.open) {
                                    datePickerRef?.setOpen(true);
                                  }
                                }
                              }}
                              className="fas fa-calendar-week absolute top-3 right-3"
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                  <div className={`form-group mt-5 ml-6`}>
                    <FormInputField
                      label={''}
                      type={'time'}
                      fieldName={'displayTime'}
                      register={register}
                      errors={errors}
                      isRequired={false}
                    />
                  </div>
                </>
              )}
            </form>
          </div>
        )}

        <div className="mb-5">
          <label className="font-semibold">Moderators</label>
          <p className="my-4">
            Attach one or more Members to grant permission to moderator the video. A moderator will
            be able to moderate chat conversations and other permissions related to this video.
          </p>
          <form onSubmit={handleSubmit1(onSubmit1)} className="mb-5 flex items-center">
            {finalModerators && (
              <Controller
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <Select
                    filterOption={customFilterOption}
                    menuPlacement="top"
                    className="w-full"
                    options={finalModerators}
                    value={value}
                    id="moderators"
                    isMulti
                    onChange={(val) => onChange(val)}
                    isLoading={members.length ? false : true}
                  />
                )}
                name="moderators"
                control={control1}
                rules={{ required: true }}
              />
            )}
            {errors1.moderators?.type === 'required' && (
              <span className="text-red-500 text-xs flex item-center error">Required Field</span>
            )}
            <button
              type="submit"
              className="btn-primary w-auto whitespace-nowrap mt-0 ml-5 borderonly-btn">
              Add
            </button>
          </form>

          <div className="mt-5">
            <table className="table-bordered w-1/2">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {moderators &&
                  moderators.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.firstName}</td>
                        <td
                          className="cursor-pointer"
                          onClick={() => handleDeleteModeratorClick(item.id)}>
                          remove
                        </td>
                      </tr>
                    );
                  })}
                {isGetModeratorLoading && (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mb-5">
          <label className="font-semibold">Discount Codes</label>
          <p className="my-4">
            The discount code will provide a percent discount on the price of the video. One or more
            discount codes can be applied.
          </p>

          <div className="mt-5">
            <div className="flex justify-end mb-2">
              <button
                onClick={() => setDiscountCodeEditFlag(false)}
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#AddDiscountCode"
                className="btn-primary w-auto mt-0 mr-2 ml-0 borderonly-btn">
                Add
              </button>
            </div>
            <div className="">
              <table className="table-auto w-full border">
                <thead className="text-xs font-semibold uppercase bg-gray-100">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Discount Code</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Discount Percentage</div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Expiry Date </div>
                    </th>
                    <th className="p-2">
                      <div className="font-semibold text-left">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100">
                  {isDiscountCodeLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {discountCode && discountCode.length ? (
                        <>
                          {discountCode &&
                            discountCode.map((item) => {
                              return (
                                <tr key={item.id}>
                                  <td className="p-2">{item.discountCode}</td>
                                  <td className="p-2">{item.discountPercentage}</td>
                                  <td className="p-2">
                                    {item.expiryDate ? defaultDateFormatter(item.expiryDate) : ''}
                                  </td>
                                  <td className="p-2">
                                    <div className="dropdown relative">
                                      <a
                                        className="
                                  dropdown-toggle
                                  transition
                                  duration-150
                                  ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                        href="#"
                                        type="button"
                                        id="ActionButton"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <i className="fas fa-ellipsis-v"></i>
                                      </a>
                                      <ul
                                        className="dropdown-menu action-dropdown"
                                        aria-labelledby="ActionButton">
                                        <li
                                          onClick={() => handleEditClick(item.id)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#AddDiscountCode">
                                          <span className="dropdown-item flex-items cursor-pointer">
                                            <i className="fas fa-edit w-6"></i>
                                            <span>Edit</span>
                                          </span>
                                        </li>
                                        <li onClick={() => handleDeleteClick(item.id)}>
                                          <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                            <i className="fas fa-trash w-6"></i>
                                            <span>Delete</span>
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {' '}
                          <tr>
                            <td className="text-center py-2" colSpan={9}>
                              No Data Found
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {!!paginationMeta &&
                !!Object.keys(paginationMeta).length &&
                paginationMeta?.totalCount !== 0 && (
                  <Pagination
                    currentPage={paginationMeta.pageNumber}
                    totalCount={paginationMeta.totalCount}
                    pageSize={paginationMeta.pageSize}
                    onPageChange={(page: any) => handlePageChange(page)}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <DiscountCodeModel discountCodeEditFlag={discountCodeEditFlag} />
    </div>
  );
};

export default AccessPrice;
