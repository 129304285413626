export const URLS = {
    //CHANNELS
    SAVE_CHANNEL: { url: 'v2/channel' },
    GET_SINGLE_CHANNEL: { url: 'v2/channel' },
    UPDATE_CHANNEL: { url: 'v2/channel' },
    DELETE_CHANNEL: { url: 'v2/channel' },
    GET_CHANNEL_LAYOUT: { url: 'v2/channel/layout' },
    UPDATE_CHANNEL_LAYOUT: { url: 'v2/channel' },
    GET_CHANNELS: { url: 'v2/channel' },
    GET_CHANNELS_LIST: { url: 'v2/channel/list' },
    SORT_CHANNEL: { url: 'v2/channel/order' },
    //MEMBERS
    GET_MEMBERS: { url: 'v2/member' },
    GET_MEMBERS_ROLES: { url: 'v2/member/roles' },
    ADD_MEMBERS: { url: 'v2/member' },
    GET_MEMBERS_DETAILS: { url: 'v2/member' },
    EDIT_MEMBERS_DETAILS: { url: 'v2/member' },
    GET_FILTER_MEMBER: { url: 'v2/member' },
    GET_MEMBERS_VIDEO_ANALYTICS: { url: 'v2/member/videoanalytics' },
    GET_MEMBERS_SUBSCRIPTION: { url: 'v2/member/subscriptions' },
    CHECK_USER_NAME_AVAILABLE: { url: 'v2/member/checkusernameavailable' },
    DELETE_MEMBER: { url: 'v2/member' },
    DELETE_MEMBER_LIST: { url: 'v2/member/memberlist' },
    GET_MEMBER_LIST: { url: 'v2/member/memberlist' },
    SAVE_MEMBER_LIST: { url: 'v2/member/memberlist' },
    UPDATE_MEMBER_LIST: { url: 'v2/member/memberlist' },
    GET_SINGLE_MEMBER_LIST: { url: 'v2/member/memberlist' },
    SEARCH_MEMBER_LIST: { url: 'v2/member' },
    GET_MEMBER_WIDGETS_ANALYSIS: { url: 'v2/analytics/widgets/members' },
    GET_MEMBER_SUBSCRIPTION: { url: 'v2/member/subscriptions' },
    GET_MEMBERS_LOGIN_HISTORY: { url: 'v2/analytics/member/loginhistory' },
    GET_MEMBERS_EVENT_REGISTRATION: { url: 'v2/member/eventregistrations' },
    GET_MEMBERS_COUNT_DATA: { url: 'v2/analytics/widgets/member/membervideoanalytics' },
    GET_MEMBERS_INVOICE: { url: 'v2/member/purchases' },
    GET_MEMBER_ACTIVITY_TIMELINE: { url: 'v2/analytics/member/activity' },
    UPDATE_MEMBER_PROFILE: { url: 'v2/member/update/profileurl' },
    SEARCH_MEMBER_LIST_DETAILS: { url: 'v2/member/memberlist' },
    DELETE_MEMBER_LIST_DETAILS: { url: 'v2/member/memberlist' },
    UPLOAD_MEMBER_LIST_DETAILS: { url: 'v2/member/memberlist' },
    //EVENTS
    GET_EVENTS: { url: 'v2/event' },
    SINGLE_GET_EVENTS: { url: 'v2/event' },
    UPDATE_EVENT: { url: 'v2/event' },
    ADD_REGISTRATION_FIELDS: { url: 'v2/event/registrationfield' },
    DELETE_REGISTRATION_FIELDS: { url: 'v2/event/registrationfield' },
    DELETE_EVENT_REGISTERED_USERS: { url: 'v2/event/' },
    PATCH_EVENT_EMAIL_SETTINGS: { url: 'v2/event/emailsettings' },
    PATCH_IS_REQUIRED_REGISTRATION_FIELD: { url: 'v2/event/registrationfieldflags/required' },
    PATCH_IS_ENABLE_REGISTRATION_FIELD: { url: 'v2/event/registrationfieldflags/enable' },
    PATCH_IS_PASSCODE_REQUIRED_REGISTRATION_FIELD: { url: 'v2/event/registrationfieldflags/passcoderequired' },
    GET_EVENT_VIDEOS: { url: 'v2/video/search' },
    GET_EVENT_REMINDER: { url: 'v2/event/emailsettings/reminder' },
    UPDATE_EVENT_REMINDER: { url: 'v2/event/emailsettings/reminder' },
    //USERS
    GET_USERS: { url: 'v2/users' },
    GET_USER_DETAILS: { url: 'v2/users' },
    ADD_USERS: { url: 'v2/users' },
    UPDATE_USERS: { url: 'v2/users' },
    GET_ROLES: { url: 'v2/users/roles' },
    CHANGE_PASSWORD: { url: 'v2/users/changePassword' },
    RESET_PASSWORD: { url: 'v2/users/forgetPassword' },
    GENERATE_MFA_QR: { url: 'v2/users/mfa/generateqrcode' },
    VERIFY_MFA_CODE: { url: 'v2/users/mfa/verifycode' },
    MFA_SETUP: { url: 'v2/users/mfa/MfaSetup' },
    //Analytics
    GET_ANALYTICS_VIDEO: { url: 'v2/analytics/video' },
    GET_ANALYTICS_COURSE: { url: 'v2/analytics/member/course' },
    GET_ANALYTICS_MEMBER: { url: 'v2/analytics/member' },
    GET_ANALYTICS_MEMBER_SEARCH: { url: 'v2/analytics/member/search' },
    GET_SALES_ORDERS: { url: 'v2/analytics/salesorder' },
    GET_ANALYTICS_VIDEO_CHART: { url: 'v2/analytics/sessions' },
    GET_COURSE_RESULTS: { url: 'v2/analytics/course' },
    GET_SINGLE_COURSE_RESULT: { url: 'v2/analytics/member/course' },
    GET_QUIZ_TOTAL: { url: 'v2/analytics/widgets/quizzes' },
    GET_QUIZ_QUESTIONS: { url: 'v2/analytics/quiz/questions' },
    GET_QUIZ_ANALYTICS: { url: 'v2/analytics/quiz' },
    GET_QUIZ_ANSWERS_ANALYTICS: { url: 'v2/analytics/quiz' },
    GET_QUESTION_BY_QUIZ_ID_ANALYTICS: { url: 'v2/analytics/quiz' },
    GET_QUESTION_RESULT_BY_QUIZ_ID_ANALYTICS: { url: 'v2/analytics/quiz' },
    GET_RECENT_COURSE_ACTIVITY: { url: 'v2/analytics/quiz/courses' },
    GET_POLL_ANALYTICS: { url: 'v2/analytics/poll' },
    GET_POLL_QUESTIONS: { url: 'v2/analytics/poll/questions' },
    GET_SINGLE_POLLS: { url: 'v2/analytics/poll' },
    GET_SINGLE_POLL_QUESTION: { url: 'v2/analytics/poll' },
    GET_SINGLE_MEMBER_COURSE_VIEW: { url: 'v2/analytics/single-member-course' },
    GET_DOWNLOAD_USERS: { url: 'v2/event' },
    GET_SINGLE_QUIZ_WIDGETS: { url: 'v2/analytics/widgets/quiz/questions' },
    GET_SINGLE_VIDEO_ANALYTICS: { url: 'v2/analytics/widgets/productsales' },
    GET_COURSE_WIDGETS: { url: 'v2/analytics/course/summary' },
    GET_POLL_WIDGETS: { url: 'v2/analytics/widgets/polls' },
    GET_COURSE_MEMBER_QUESTION: { url: 'v2/analytics/course/member/questions' },
    GET_VIEW_SUBMISSIONS: { url: 'v2/analytics/course/activity' },
    //Categories
    GET_CATEGORIES: { url: 'v2/categories' },
    GET_SINGLE_CATEGORIES: { url: 'v2/categories' },
    SAVE_CATEGORIES: { url: 'v2/categories' },
    UPDATE_CATEGORIES: { url: 'v2/categories' },
    DELETE_CATEGORIES: { url: 'v2/categories' },
    UPDATE_SPOTLIGHTROW_FLAG: { url: 'v2/categories' },
    GET_TAGS: { url: 'v2/categories/tags' },
    SAVE_TAGS: { url: 'v2/categories/tags' },
    DELETE_TAGS: { url: 'v2/categories/tags' },
    UPDATE_TAGS: { url: 'v2/categories/tags' },
    GET_SINGLE_TAGS: { url: 'v2/categories/tags' },
    GET_CONTENT_TYPE: { url: 'v2/categories/contenttypes' },
    GET_SINGLE_CONTENT_TYPE: { url: 'v2/categories/contenttypes' },
    SAVE_CONTENT_TYPE: { url: 'v2/categories/contenttypes' },
    UPDATE_CONTENT_TYPE: { url: 'v2/categories/contenttypes' },
    DELETE_CONTENT_TYPE: { url: 'v2/categories/contenttypes' },
    GET_MODULE: { url: 'v2/categories/modules' },
    ADD_MODULE: { url: 'v2/categories/modules' },
    UPDATE_MODULE: { url: 'v2/categories/modules' },
    DELETE_MODULE: { url: 'v2/categories/modules' },
    GFT_SINGLE_MODULE: { url: 'v2/categories/modules' },
    //COLLECTIONS
    GET_COLLECTIONS_LIST: { url: 'v2/categories/collections' },
    GET_COLLECTION: { url: 'v2/categories/collections' },
    GET_COLLECTION_VIDEOS: { url: 'v2/categories/collections/list' },
    ADD_COLLECTION: { url: 'v2/categories/collections' },
    ADD_COLLECTION_VIDEOS: { url: 'v2/categories/collections' },
    REMOVE_COLLECTION_VIDEOS: { url: 'v2/categories/collections' },
    REMOVE_COLLECTION: { url: 'v2/categories/collections' },
    UPDATE_COLLECTION_VIDEOS: { url: 'v2/categories/collections' },
    UPDATE_COLLECTION_VIDEOS_ORDER: { url: 'v2/categories/collections/order' },
    //VIDEO
    GET_QUESTIONS: { url: 'v2/video/question' },
    GET_LIVE_MEDIA_URL: { url: 'v2/video/livemedia' },
    UPDATE_VIDEO_THUMBNAIL: { url: 'v2/video/update/thumbnail' },
    UPDATE_VIDEO_ANALYTICS: { url: 'v2/analytics/video/update' },
    GET_VIDEO_ANALYTICS: { url: 'v2/member/videoanalytics' },
    GET_VIDEOS: { url: 'v2/video/list' },
    GET_POLLS: { url: 'v2/video/polls' },
    GET_POLLS_RESULT: { url: 'v2/video/pollresult' },
    SAVE_POLLS: { url: 'v2/video/polls' },
    UPDATE_POLLS: { url: 'v2/video/polls' },
    GET_SINGLE_VIDEO: { url: 'v2/video' },
    ADD_VIDEO: { url: 'v2/video' },
    EDIT_VIDEO: { url: 'v2/video' },
    DELETE_VIDEO: { url: 'v2/video' },
    GET_SPOTLIGHTROW: { url: 'v2/video/spotlightrow' },
    SHARE_VIDEO: { url: 'v2/video/share' },
    GET_VIDEO_TAGS: { url: 'v2/categories/tags' },
    GET_VIDEO_CATEGORIES: { url: 'v2/video/categories' },
    GET_VIDEO_SPEAKERS: { url: 'v2/member' },
    ADD_VIDEO_SPEAKERS: { url: 'v2/member' },
    ADD_LIVESTREAM: { url: 'v2/video' },
    GET_INTERACTIVTIY: { url: 'v2/video/interactivity' },
    UPDATE_INTERACTIVTIY: { url: 'v2/video/interactivity' },
    GET_ACCESSIBILITY: { url: 'v2/video/accessibility' },
    UPDATE_ACCESSIBILITY: { url: 'v2/video/accessibility' },
    GET_PLAY_PAGE: { url: 'v2/video/playersettings' },
    UPDATE_PLAY_PAGE: { url: 'v2/video/playersettings' },
    GET_MEMBERLIST_ACCESS: { url: 'v2/video/memberlistaccess' },
    SAVE_MEMBERLIST_ACCESS: { url: 'v2/video/memberlistaccess' },
    DELETE_MEMBERLIST_ACCESS: { url: 'v2/video/memberlistaccess' },
    GET_RESOURCES: { url: 'v2/video/resources' },
    ADD_RESOURCES: { url: 'v2/video/resources' },
    SAVE_SLIDE: { url: 'v2/video/slides' },
    EDIT_RESOURCES: { url: 'v2/video/resources' },
    GET_SINGLE_RESOURCES: { url: 'v2/video/resources/id' },
    ADD_MEMBERLIST_PRICE: { url: 'v2/video/memberlistprice' },
    GET_MEMBERLIST_PRICE: { url: 'v2/video/memberlistprice' },
    DELETE_MEMBERLIST_PRICE: { url: 'v2/video/memberlistprice' },
    SAVE_MODERATORS: { url: 'v2/video/moderator' },
    GET_MODERATORS: { url: 'v2/video/moderator' },
    DELETE_MODERATORS: { url: 'v2/video/moderator' },
    SAVE_CALL_TO_ACTION: { url: 'v2/video/cta' },
    UPDATE_CALL_TO_ACTION: { url: 'v2/video/cta' },
    GET_CALL_TO_ACTION: { url: 'v2/video/cta' },
    DELETE_CALL_TO_ACTION: { url: 'v2/video/cta' },
    GET_POLLS_TEMPLATES: { url: 'v2/video/polls/templates' },
    SAVE_POLLS_TEMPLATES: { url: 'v2/video/polls/templates' },
    GET_VIDEO_MEDIA: { url: 'v2/video/media' },
    SAVE_VIDEO_MEDIA: { url: 'v2/video/media/secondary' },
    GET_VIDEO_SESSION: { url: 'v2/video/viewingsessions' },
    GET_MEMBER_VIDEO_ANALYTICS: { url: 'v2/analytics/video/totals' },
    GET_AUTH_KEY: { url: 'v2/video/sharetoken' },
    UPDATE_REDIRECT_TO_LOGIN: { url: 'v2/video/redirecttologin' },
    GET_THUMB_SLIDE: { url: 'v2/video/slides' },
    GET_EDIT_VIDEO_ANALYTICS: { url: 'v2/analytics/video/sessioncount' },
    GET_LIVE_MEDIA: { url: 'v2/video/livestreamsurls' },
    GET_DISCOUNT_CODE: { url: 'v2/video/discountcode' },
    SAVE_DISCOUNT_CODE: { url: 'v2/video/discountcode' },
    UPDATE_DISCOUNT_CODE: { url: 'v2/video/discountcode' },
    DELETE_DISCOUNT_CODE: { url: 'v2/video/discountcode' },
    DELETE_POLL: { url: 'v2/video/polls' },
    UPDATE_POLL_STATUS: { url: 'v2/video/polls' },
    UPDATE_QUESTION: { url: 'v2/video/question' },
    UPDATE_VIDEO_STATUS: { url: 'v2/video/videostatus' },
    GET_VIDEO_STATUS: { url: 'v2/video/videostatus' },
    SEARCH_VIDEO: { url: 'v2/video/admin/search' },
    GET_VIDEO_MODULE: { url: 'v2/video/modules' },
    GET_CATEGORIES_LIST: { url: 'v2/categories/list' },
    GET_MODULES_LIST: { url: 'v2/categories/moduleslist' },
    GET_AUDIENCE_PROFILES: { url: 'v2/categories/audienceprofiles' },
    ADD_AUDIENCE_PROFILES: { url: 'v2/categories/audienceprofiles' },
    UPDATE_AUDIENCE_PROFILES: { url: 'v2/categories/audienceprofiles' },
    DELETE_AUDIENCE_PROFILES: { url: 'v2/categories/audienceprofiles' },
    GET_VIDEO_SLIDES: { url: 'v2/video/slides' },
    UPDATE_VIDEO_SLIDES: { url: 'v2/video/slides' },
    START_LIVE_MEETING: { url: 'v2/broadcast/livemeeting/start' },
    STOP_LIVE_MEETING: { url: 'v2/broadcast/livemeeting/stop' },
    ENTER_LIVE_STUDIO: { url: 'v2/broadcast/livemeeting/enter' },
    GET_VIDEO_ANALYTICS_TABLE: { url: 'v2/analytics/video' },
    REMOVE_MEDIA_URL: { url: 'v2/htmleditor/removemediaurl' },
    GET_MEDIA_URL: { url: 'v2/htmleditor/mediaurl' },
    GET_LIVE_VIEWERS: { url: 'v2/analytics/video' },
    UPDATE_LIVE_STREAM_SOURCE: { url: 'v2/video/media/updatelivestreamtype' },
    UPLOAD_RESOURCE_FILE: { url: 'v2/video/resource/upload' },
    GET_RESOURCE_DETAIL: { url: 'v2/video/resource' },
    GET_ARCHIVE_URL: { url: '/v2/video/media/archive' },
    //Dashboard
    GET_MOST_POPULAR_VIDEO: { url: 'v2/analytics/widgets/mostpopularvideos' },
    GET_SALES: { url: 'v2/analytics/widgets/sales' },
    GET_AGGREGATE: { url: 'v2/analytics/widgets/videos' },
    GET_SALES_ORDER_STATS: { url: 'v2/analytics/widgets/salesorder' },
    GET_SALES_ORDER: { url: 'v2/analytics/widgets/salesorderbymonth' },
    GET_DEVELOPER_MEETUP: { url: 'v2/video/videolistdetails' },
    GET_MEMBER_VIEWS: { url: 'v2/analytics/viewingsessions/count' },
    GET_MEMBER_LOGINS: { url: 'v2/analytics/member/loginhistory/count' },
    GET_TOP_SELLING_PRODUCT: { url: 'v2/analytics/widgets/productsales' },
    //Setting
    GET_LIVESTREAM_URL: { url: 'v2/settings/liveStream' },
    UPDATE_LIVESTREAM_URL: { url: 'v2/settings/liveStream' },
    GET_API_V2: { url: 'v2/settings/apicredentials/apiv2/external' },
    GET_SINGLE_API_V2: { url: 'v2/settings/apicredentials/apiv2' },
    ADD_API_V2: { url: 'v2/settings/apicredentials/apiv2' },
    UPDATE_API_V2: { url: 'v2/settings/apicredentials/apiv2' },
    GET_EDUCATION_POINTS: { url: 'v2/settings/creditactivity/templates' },
    SAVE_EDUCATION_POINTS: { url: 'v2/settings/creditactivity' },
    GET_SINGLE_EDUCATION_POINTS: { url: 'v2/settings/creditactivity' },
    UPDATE_EDUCATION_POINTS: { url: 'v2/settings/creditactivity' },
    DELETE_EDUCATION_POINTS: { url: 'v2/settings/creditactivity' },
    GET_CE_POINTS: { url: 'v2/settings/creditactivity/educationsettings' },
    SAVE_CE_POINTS: { url: 'v2/settings/creditactivity/educationsettings' },
    GET_ECOMMERCE: { url: 'v2/settings/ecommerce/businessinfo' },
    SAVE_ECOMMERCE: { url: 'v2/settings/ecommerce/businessinfo' },
    GET_INTEGRATION: { url: 'v2/settings/integrations' },
    ENABLE_INTEGRATION: { url: 'v2/settings/integrations' },
    GET_IMIS: { url: 'v2/settings/integrations/imis' },
    ADD_IMIS: { url: 'v2/settings/integrations/imis' },
    GET_MEMBER_EVOLUTION: { url: 'v2/settings/integrations/memberevolution' },
    ADD_MEMBER_EVOLUTION: { url: 'v2/settings/integrations/memberevolution' },
    GET_WICKET: { url: 'v2/settings/integrations/wicket' },
    ADD_WICKET: { url: 'v2/settings/integrations/wicket' },
    GET_DYNAMICS365: { url: 'v2/settings/integrations/dynamics365' },
    ADD_DYNAMICS365: { url: 'v2/settings/integrations/dynamics365' },
    GET_MICROSOFTAZURE: { url: 'v2/settings/integrations/microsoftazure' },
    ADD_MICROSOFTAZURE: { url: 'v2/settings/integrations/microsoftazure' },
    GET_NOVIAMS: { url: 'v2/settings/integrations/noviams' },
    ADD_NOVIAMS: { url: 'v2/settings/integrations/noviams' },
    GET_OAUTH2: { url: 'v2/settings/integrations/oauth2' },
    ADD_OAUTH2: { url: 'v2/settings/integrations/oauth2' },
    GET_MEMBERCLICKS: { url: 'v2/settings/integrations/memberclicks' },
    ADD_MEMBERCLICKS: { url: 'v2/settings/integrations/memberclicks' },
    UPDATE_INTEGRATIONS: { url: 'v2/settings/integrations' },
    GET_NAYLOR: { url: 'v2/settings/integrations/naylor' },
    GET_PERSONIFY: { url: 'v2/settings/integrations/personify' },
    ADD_PERSONIFY: { url: 'v2/settings/integrations/personify' },
    GET_SPARGO: { url: 'v2/settings/integrations/spargo' },
    GET_MEMBES: { url: 'v2/settings/integrations/membes' },
    ADD_MEMBES: { url: 'v2/settings/integrations/membes' },
    GET_GOOGLE: { url: 'v2/settings/integrations/google' },
    GET_WEBCOURSE: { url: 'v2/settings/integrations/webcourseworks' },
    GET_ATTRIBUTEMAPPING: { url: 'v2/settings/integrations/attributemapping/all' },
    GET_SINGLE_ATTRIBUTEMAPPING: { url: 'v2/settings/integrations/attributemapping' },
    ADD_ATTRIBUTEMAPPING: { url: 'v2/settings/integrations/attributemapping' },
    UPDATE_ATTRIBUTEMAPPING: { url: 'v2/settings/integrations/attributemapping' },
    DELETE_ATTRIBUTEMAPPING: { url: 'v2/settings/integrations/attributemapping' },
    GET_ATTRIBUTEMAPPING_COLUMNLIST: { url: 'v2/settings/integrations/attributemapping/columnlist' },
    GET_ATTRIBUTEMAPPING_TYPELIST: { url: 'v2/settings/integrations/attributemapping/mappingtypes' },
    GET_PAYMENT_PROVIDER: { url: 'v2/settings/payments' },
    ENABLE_PAYMENT_PROVIDER: { url: 'v2/settings/payments' },
    GET_BPOINT: { url: 'v2/settings/payments/bPoint' },
    ADD_BPOINT: { url: 'v2/settings/payments/bPoint' },
    UPDATE_PAYMENT: { url: 'v2/settings/payments' },
    GET_PAYPAL: { url: 'v2/settings/payments/paypal' },
    ADD_PAYPAL: { url: 'v2/settings/payments/paypal' },
    GET_STRIPE: { url: 'v2/settings/payments/stripe' },
    ADD_STRIPE: { url: 'v2/settings/payments/stripe' },
    GET_LIVEPAY: { url: 'v2/settings/payments/livepay' },
    ADD_LIVEPAY: { url: 'v2/settings/payments/livepay' },
    GET_PAYPAL_PAYFLOW: { url: 'v2/settings/payments/payflow' },
    ADD_PAYPAL_PAYFLOW: { url: 'v2/settings/payments/payflow' },
    GET_EZIDEBIT: { url: 'v2/settings/payments/ezidebit' },
    ADD_EZIDEBIT: { url: 'v2/settings/payments/ezidebit' },
    GET_QUICKBOOKS: { url: 'v2/settings/payments/quickbooks' },
    ADD_QUICKBOOKS: { url: 'v2/settings/payments/quickbooks' },
    GET_CONNECT_QUICKBOOKS: { url: 'v2/quickbooks/oauth' },
    GET_ZOOM: { url: 'v2/settings/integrations/zoom' },
    GET_SAML: { url: 'v2/settings/integrations/SAML' },
    UPDATE_SAML: { url: 'v2/settings/integrations/SAML' },
    GET_CURRENCY: { url: 'v2/settings/ecommerce/currency/all' },
    UPDATE_CURRENCY: { url: 'v2/settings/ecommerce/currency' },
    GET_SELECTED_CURRENCY: { url: 'v2/settings/ecommerce/currency' },
    GET_ECOMMERCE_SETTING: { url: 'v2/settings/ecommerce' },
    UPDATE_ECOMMERCE_SETTING: { url: 'v2/settings/ecommerce' },

    //Video Library
    GET_BRANDING: { url: 'v2/video/layout/videolibrary/branding' },
    GET_COLORS: { url: 'v2/video/layout/videolibrary/colors' },
    GET_FONTS: { url: 'v2/video/layout/videolibrary/fonts' },
    GET_VIDEO_CARD: { url: 'v2/video/layout/videolibrary/videocards' },
    GET_HEADING: { url: 'v2/video/layout/videolibrary/headings' },
    GET_FILTER_SETTING: { url: 'v2/video/layout/videolibrary/filtersettings' },
    //Courses
    UPDATE_COURSE_URL: { url: 'v2/course' },
    //Chapter
    GET_CHAPTERS: { url: 'v2/courses/chapters' },
    ADD_CHAPTERS: { url: 'v2/courses/chapters' },
    UPDATE_CHAPTERS: { url: 'v2/courses/chapters' },
    DELETE_CHAPTERS: { url: 'v2/courses/chapters' },
    GET_CHECKPOINTS: { url: 'v2/courses/checkpoints' },
    ADD_CHECKPOINTS: { url: 'v2/courses/checkpoints' },
    UPDATE_CHECKPOINTS: { url: 'v2/courses/checkpoints' },
    DELETE_CHECKPOINTS: { url: 'v2/courses/checkpoints' },
    GET_COURSE_SEQUENCES: { url: 'v2/courses/sequences/order' },
    SAVE_COURSE_SEQUENCES: { url: 'v2/courses/sequences/order' },

    //Broadcast
    START_MEDIA_CHANNEL: { url: 'v2/livestream/startmediachannel' },
    START_MEDIA_STREAM: { url: 'v2/livestream/startmediastream' },
    STOP_MEDIA_STREAM: { url: 'v2/livestream/stopmediastream' },
    MEDIA_CHANNEL_STATUS: { url: 'v2/livestream/mediachannelstatus' },
    //Appearance
    GET_SITE_IDENTITY: { url: 'v2/layout/branding' },
    UPDATE_APPEARANCE: { url: 'v2/layout/videolibrary' },
    GET_COLOR: { url: 'v2/layout/colors' },
    GET_FONT: { url: 'v2/layout/fonts' },
    GET_USER_PROFILE: { url: 'v2/layout/userProfile' },
    GET_VIDEO_CARDS: { url: 'v2/layout/videocards' },
    GET_PLAY_PAGE_VIDEO: { url: "v2/layout/playpage/videoplayer" },
    GET_PLAY_PAGE_NAVIGATION: { url: "v2/layout/playpage/navigation" },
    GET_CHAT_STYLE: { url: "v2/layout/chatstyle/customcss" },
    GET_NAVIGATION: { url: "v2/layout/navigation" },
    SAVE_NAVIGATION: { url: "v2/layout/navigation" },
    GET_SINGLE_NAVIGATION: { url: "v2/layout/navigation" },
    UPDATE_NAVIGATION: { url: "v2/layout/navigation" },
    DELETE_NAVIGATION: { url: "v2/layout/navigation" },
    GET_PLAY_PAGE_SETTING: { url: "v2/layout/playpage/settings" },
    GET_LAYOUT_STYLE: { url: "v2/layout/highlightreel/style" },
    UPDATE_LAYOUT_STYLE: { url: "v2/layout/highlightreel/style" },
    GET_LAYOUT_CONTENT: { url: "v2/layout/highlightreel/content" },
    GET_HIGHLIGHTREEL_DATA: { url: "v2/layout/highlightreel/content" },
    GET_ADBANNER: { url: "v2/layout/adbanner" },
    UPDATE_ADBANNER: { url: "v2/layout/adbanner" },
    GET_SPOTLIGHT_CHANNEL: { url: "v2/layout/spotlights/settings/contents" },
    GET_RECOMMENDATIONS: { url: "v2/layout/spotlights/settings" },
    UPDATE_SETTING_ORDER: { url: "v2/layout/spotlights/settings/order" },
    UPDATE_LAYOUT_CONTENT: { url: "v2/layout/highlightreel/content" },
    DELETE_SPOTLIGHT_CHANNEL: { url: "v2/layout/spotlights/settings" },
    GET_ADVANCE_SETTING: { url: "v2/layout/advancesettings" },
    GET_FILTER_SETTINGS: { url: "v2/layout/filtersettings" },
    ADD_SPOTLIGHT_CHANNEL: { url: "v2/layout/spotlights/settings" },
    //ACCOUNT
    GET_ACCOUNT_PRODUCTS: { url: "v2/account/products" },
    GET_ACCOUNT_INFO: { url: 'v2/account/info' },
    //LOGIN
    LOGIN: { url: 'v2/auth/adminlogin' },
    REFRESH_TOKEN: { url: 'v2/auth/refreshtoken' },
    SSOLOGIN: { url: 'v2/auth/adminlogin/sso' },
}
