import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';

const PersonifyIntegration: React.FC = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm();

  const { personifyIntegration, isIntegrationUpdated, message, error } = useSelector(
    (state: RootState) => {
      return {
        personifyIntegration: state.setting.personifyIntegration,
        isIntegrationUpdated: state.setting.isIntegrationUpdated,
        message: state.setting.message,
        error: state.setting.error
      };
    }
  );

  const [showVendorPassword, setShowVendorPassword] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [showDataServicesPassword, setShowDataServicesPassword] = useState(false);

  useEffect(() => {
    if (personifyIntegration) {
      reset(personifyIntegration);
    }
  }, [personifyIntegration]);

  useEffect(() => {
    settingAction.getPersonifyIntegrationRequest();
  }, []);

  useEffect(() => {
    if (isIntegrationUpdated) {
      successMsg(message);
      settingAction.resetSettingStateRequest();
    }
  }, [isIntegrationUpdated]);

  const onSubmit = (data: any) => {
    if (personifyIntegration == undefined || personifyIntegration.id == undefined) {
      settingAction.addPersonifyIntegrationRequest(data);
    } else {
      const payloadData = {
        name: 'Personify',
        updateData: [
          {
            op: 'replace',
            path: 'personifyWebServiceAddress',
            value: data.personifyWebServiceAddress
          },
          { op: 'replace', path: 'vendorUserName', value: data.vendorUserName },
          { op: 'replace', path: 'vendorPassword', value: data.vendorPassword },
          { op: 'replace', path: 'vendorBlock', value: data.vendorBlock },
          { op: 'replace', path: 'vendorIdentifier', value: data.vendorIdentifier },
          { op: 'replace', path: 'hostloginUrl', value: data.hostloginUrl },
          { op: 'replace', path: 'dataServiceApiEndpoint', value: data.dataServiceApiEndpoint },
          {
            op: 'replace',
            path: 'dataServiceApiAuthUserName',
            value: data.dataServiceApiAuthUserName
          },
          {
            op: 'replace',
            path: 'dataServiceApiAuthPassword',
            value: data.dataServiceApiAuthPassword
          },
          { op: 'replace', path: 'isMemberTypes', value: data.isMemberTypes },
          { op: 'replace', path: 'isCommitteeSections', value: data.isCommitteeSections },
          { op: 'replace', path: 'productCodes', value: data.productCodes },
          {
            op: 'replace',
            path: 'enableProductExpiryDateCheck',
            value: data.enableProductExpiryDateCheck
          },
          { op: 'replace', path: 'enableCheckCompanyId', value: data.enableCheckCompanyId },
          { op: 'replace', path: 'oAuth2', value: data.oAuth2 },
          { op: 'replace', path: 'baseUrl', value: data.baseUrl },
          { op: 'replace', path: 'clientId', value: data.clientId },
          { op: 'replace', path: 'clientSecret', value: data.clientSecret },
          { op: 'replace', path: 'memberDetailMethod', value: data.memberDetailMethod }
        ]
      };
      settingAction.updateIntegrationRequest(payloadData);
    }
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <div>
      <ToastMessage />
      <div className="mb-6">
        <h2 className="font-semibold my-1">Integration</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/integrations">Integration</Link>
          </li>
          <li>
            <strong> Personify Integration</strong>
          </li>
        </ol>
        <Link to={`/integrations`}>
          <i className="fas fa-arrow-left mt-5"></i>
          <span className="ml-2">Go Back</span>
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="text-base font-bold">Personify Integration</h3>
        </div>
        <div className="dashboard-card p-5 mt-2">
          <h4 className="text-lg">Personify SSO Web Service</h4>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">SSO Webservice URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'personifyWebServiceAddress'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Vendor Username</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'vendorUserName'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Vendor Password</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showVendorPassword ? 'text' : 'password'}
                fieldName={'vendorPassword'}
                register={register}
                errors={''}
                isRequired={false}
              />
              <i
                className={`fas ${
                  showVendorPassword ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowVendorPassword(!showVendorPassword)}></i>
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Vendor Block</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'vendorBlock'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Vendor Identifier</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'vendorIdentifier'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">SSO Login Url</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'hostloginUrl'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
        </div>
        <div className="dashboard-card p-5 mt-2">
          <h4 className="text-lg">OAuth SSO Details</h4>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">SSO OAuth2.0</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="oAuth2"
                      {...register('oAuth2')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">SSO OAuth2 Base URL</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'baseUrl'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Id</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'clientId'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Client Secret</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showClientSecret ? 'text' : 'password'}
                fieldName={'clientSecret'}
                register={register}
                errors={''}
                isRequired={false}
              />
              <i
                className={`fas ${
                  showClientSecret ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowClientSecret(!showClientSecret)}></i>
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Member Detail Method</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'memberDetailMethod'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
        </div>

        <div className="dashboard-card p-5 mt-5">
          <h4 className="text-lg">Personify Data Services</h4>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Data Services API Endpoint</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'dataServiceApiEndpoint'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Data Services Username</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'dataServiceApiAuthUserName'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Data Services Password</label>
            </div>
            <div className="md:w-8/12 relative">
              <FormInputField
                label={''}
                type={showDataServicesPassword ? 'text' : 'password'}
                fieldName={'dataServiceApiAuthPassword'}
                register={register}
                errors={''}
                isRequired={false}
              />
              <i
                className={`fas ${
                  showDataServicesPassword ? 'fa-eye-slash' : 'fa-eye'
                } cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2`}
                onClick={() => setShowDataServicesPassword(!showDataServicesPassword)}></i>
            </div>
          </div>
          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Member Types</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="isMemberTypes"
                      {...register('isMemberTypes')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Committee/Sections</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="isCommitteeSections"
                      {...register('isCommitteeSections')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-card p-5 mt-5">
          <h4 className="text-lg">Personify Products</h4>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Product Codes</label>
            </div>
            <div className="md:w-8/12">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'productCodes'}
                register={register}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Product Expiry Date Check</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="enableProductExpiryDateCheck"
                      {...register('enableProductExpiryDateCheck')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:flex items-center mb-3 mt-3">
            <div className="md:w-4/12">
              <label className="font-semibold">Company ID Product Check</label>
            </div>
            <div className="md:w-8/12">
              <div className="flex items-center">
                <div className="form-check form-switch pl-0 flex-items mb-2">
                  <div className="relative">
                    <input
                      className="form-check-input switch-btn"
                      type="checkbox"
                      id="enableCheckCompanyId"
                      {...register('enableCheckCompanyId')}
                    />
                    <i className="fas fa-check checkbox-tick hidden"></i>
                    <i className="fas fa-times checkbox-cross"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <button type="submit" className="px-6 btn-primary w-28 mr-0 ml-3 mt-0">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonifyIntegration;
