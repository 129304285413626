import React, { useEffect, useState } from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSettingActions } from 'src/store/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import ToastMessage from 'src/components/ToastContainer';
import UpdateApiV2 from './components/UpdateApiV2';
import { successMsg } from 'src/components/utilities/utils';
import { errorMsg } from 'src/components/utilities/utils';
const APIConfig = () => {
  const dispatch = useDispatch();
  const settingAction = useSettingActions(dispatch);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const { apiV2, isApiV2Saved, message, isApiV2Deleted, isGetApiV2Loading, isApiV2Updated, error } =
    useSelector((state: RootState) => {
      return {
        apiV2: state.setting.apiV2,
        isApiV2Saved: state.setting.isApiV2Saved,
        message: state.setting.message,
        isApiV2Deleted: state.setting.isApiV2Deleted,
        isGetApiV2Loading: state.setting.isGetApiV2Loading,
        isApiV2Updated: state.setting.isApiV2Updated,
        error: state.setting.error
      };
    });

  useEffect(() => {
    if (isApiV2Saved || isApiV2Deleted || isApiV2Updated) {
      successMsg(message);
      resetFields();
      settingAction.resetSettingStateRequest();
      settingAction.getApiV2Request();
    }
  }, [isApiV2Saved, isApiV2Deleted, isApiV2Updated]);

  useEffect(() => {
    settingAction.getApiV2Request();
  }, []);

  const onSubmit = (data: any) => {
    settingAction.addApiV2Request(data);
  };

  const resetFields = () => {
    reset({
      name: '',
      role: 0
    });
  };

  const handleDeleteClick = (id: string) => {
    settingAction.deleteApiV2Request(id);
  };

  const handleEditClick = (id: string) => {
    settingAction.getSingleApiV2Request(id);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      settingAction.resetSettingStateRequest();
    }
  }, [error]);

  return (
    <>
      <ToastMessage />
      <div className="highlight-reel">
        <div className="mb-6">
          <h2 className="font-semibold my-1">API Configs</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/api-config">API & Integrations</Link>
            </li>
            <li>
              <strong>API Configs</strong>
            </li>
          </ol>
        </div>

        <div className="lg:flex">
          <div className="tab-content lg:w-full shadow border">
            <div className="tab-pane fade show active">
              <div className="md:p-5 p-3">
                <div>
                  <p className="font-semibold mb-5">WCC External API Configuration</p>
                  <form onSubmit={handleSubmit(onSubmit)} className="flex items-center ">
                    <div className="flex items-center mb-5">
                      <div className="mr-3">
                        <FormInputField
                          label={'API Key Name'}
                          type={'text'}
                          fieldName={'name'}
                          register={register}
                          errors={errors}
                          isRequired={false}
                        />
                      </div>
                      <div className="mr-3">
                        <label className='className="block mb-1'>Key Permission</label>
                        <select className="input-style" {...register('role')}>
                          <option value={0}>Full Access</option>
                          <option value={1}>Read/Write</option>
                          <option value={2}>Read</option>
                        </select>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 borderonly-btn">
                      Add New Key
                    </button>
                  </form>
                  <div className="overflow-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-100">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Client ID</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Client Secret</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left"> Access</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left"></div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isGetApiV2Loading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {apiV2 &&
                              apiV2.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td className="p-2">{item.name}</td>
                                    <td className="p-2">{item.clientId}</td>
                                    <td className="p-2">{item.clientSecret}</td>
                                    <td className="p-2">{item.roleName}</td>
                                    <td className="p-2">
                                      <td className="p-2">
                                        <div className="dropdown relative">
                                          <a
                                            className="
                              dropdown-toggle
                              transition
                              duration-150
                              ease-in-out border h-8 w-8 flex items-center justify-center rounded-md"
                                            href="#"
                                            type="button"
                                            id="ActionButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <i className="fas fa-ellipsis-v"></i>
                                          </a>
                                          <ul
                                            className="dropdown-menu action-dropdown"
                                            aria-labelledby="ActionButton">
                                            <li
                                              onClick={() => handleEditClick(item.id)}
                                              data-bs-toggle="modal"
                                              data-bs-target="#UpdateApiV2">
                                              <span className="dropdown-item flex-items cursor-pointer">
                                                <i className="fas fa-edit w-6"></i>
                                                <span>Edit</span>
                                              </span>
                                            </li>
                                            <li onClick={() => handleDeleteClick(item.id)}>
                                              <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                                <i className="fas fa-trash w-6"></i>
                                                <span>Delete</span>
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdateApiV2 />
    </>
  );
};

export default APIConfig;
