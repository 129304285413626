import React from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmResetPassword: React.FC = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate(`/login`);
    };

    return (
        <>
            <section className="bg-gray-100 h-screen">
                <div className="container px-6 h-full mx-auto">
                    <div className="flex justify-center items-center h-full">
                        <div className="xl:w-6/12 lg:w-6/12 md:w-8/12 w-12/12">
                            <div className="block bg-white shadow-lg">
                                <div className="lg:flex-wrap g-0">
                                    <div className=" px-4 md:px-0">
                                        <div className="md:px-8 px-5 py-16">
                                            <div className="flex justify-center">
                                                <img
                                                    className="w-64 mb-8"
                                                    src="../../assets/img/webcastcloud-logo.png"
                                                    alt="logo"
                                                />
                                            </div>
                                            <h3 className="text-2xl font-semibold mb-2 mb-10  flex justify-center">Reset Password Confirmation</h3>
                                            <form>
                                                <div className="mb-4">
                                                    <label className="mb-1 block flex justify-center">Your Password has now been Reset.</label>
                                                </div>
                                                <div className="flex items-center justify-end text-center mb-4 mt-6">
                                                    <button className="btn-primary m-0 px-16" onClick={handleLogin}>
                                                        Login
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ConfirmResetPassword;