import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Logs = () => {
  const navigate = useNavigate();
  const handleLogClick = (e: string) => {
    if (e === '1') {
      navigate('/videoLog');
    } else if (e === '2') {
      navigate('/channelLog');
    } else if (e === '3') {
      navigate('/analyticsLog');
    }
  };
  return (
    <>
      <div className="mb-5">
        <h2 className="font-semibold my-1">Logs</h2>
        <ol className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="active">
            <strong>Logs</strong>
          </li>
        </ol>
      </div>
      <div className="dashboard-card p-3">
        <form>
          <div className="form-group mb-4 w-1/2">
            <select className="input-style" onChange={(e) => handleLogClick(e.target.value)}>
              <option value={0}>Select Log</option>
              <option value={1}>Video</option>
              <option value={2}>Channel</option>
              <option value={3}>Queued Analytics</option>
            </select>
          </div>
        </form>
      </div>
    </>
  );
};

export default Logs;
