import React from 'react';
import FormInputField from 'src/components/common/formInputField';
import { useForm } from 'react-hook-form';

const EmailConfirmation = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  return (
    <div className="tab-pane fade" id="tabsemailConfirmation" role="tabpanel">
      <div className="md:p-5 p-3">
        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Send email on Registration
            </label>
            <p>
              An email will be sent to users when they register on the main video site. By default,
              the default email template will be used.
            </p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="Questions"
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:flex items-center mb-8">
          <div className="md:w-4/12">
            <label
              className="form-check-label inline-block font-semibold switch-label mr-5"
              htmlFor="Credit">
              Custom Email Registration
            </label>
            <p>
              If selected, the confirmation email sent to a user when they register on the main
              video site will use your custom registration message in the email subject and body
              below.
            </p>
          </div>
          <div className="md:w-8/12">
            <div className="flex items-center">
              <div className="form-check form-switch pl-0 flex justify-between items-center mb-2 md:mx-10 mx-5">
                <div className="relative">
                  <input
                    className="form-check-input switch-btn"
                    type="checkbox"
                    role="switch"
                    id="Questions"
                  />
                  <i className="fas fa-check checkbox-tick hidden"></i>
                  <i className="fas fa-times checkbox-cross"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" className="btn-primary w-auto my-3 mr-3 ml-0 borderonly-btn">
          Save
        </button>
        <div className="border">
          <div className="p-4 border-b">
            <h2>Compose Email Template</h2>
            <button type="button" className="btn-primary w-auto mt-3 mr-3 ml-0 borderonly-btn">
              Launch Demo Email Template
            </button>
          </div>

          <div className="p-5">
            <div>
              <FormInputField
                label={'Subject'}
                type={'text'}
                fieldName={'subject'}
                register={register}
                placeholder={'Thanks for Registering for App DEV Video Library'}
                errors={''}
                isRequired={false}
              />
            </div>

            <div className="mt-5">
              <FormInputField
                label={''}
                type={'text'}
                fieldName={'editor'}
                register={register}
                placeholder={'Editor'}
                errors={''}
                isRequired={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
