import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useEffect, useRef, useState } from 'react';
import { useCategoryActions } from 'src/store/category/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { CollectionVideoModel } from 'src/models/categoryModel';
import { useForm } from 'react-hook-form';
import DeleteCollectionModal from './DeleteCollectionModal';
const Collections: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [collectionsId, setCollectionsId] = useState('');
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const categoryActions = useCategoryActions(dispatch);
  const { collectionList, loading, isCollectionSaved, isCollectionDeleted, collectionId } =
    useSelector((state: RootState) => {
      return {
        collectionList: state.category.collectionList,
        loading: state.category.loading,
        isCollectionSaved: state.category.isCollectionSaved,
        isCollectionDeleted: state.category.isCollectionDeleted,
        collectionId: state.category.collectionId
      };
    });
  useEffect(() => {
    categoryActions.getCollectionsListRequest();
  }, []);

  useEffect(() => {
    if (isCollectionSaved || isCollectionDeleted) {
      categoryActions.getCollectionsListRequest();
      categoryActions.resetCategoryStateRequest();
    }
  }, [isCollectionSaved, isCollectionDeleted]);

  const handleDoubleClick = (id: string) => {
    navigate(`/collections/${id}`);
  };

  const handleEditClick = (id: string) => {
    navigate(`/collections/${id}`);
  };

  const handleDeleteClick = (id: string) => {
    setCollectionsId(id);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  const ref = useRef<HTMLButtonElement>(null);

  const onSubmit = (data: any) => {
    categoryActions.addCollectionRequest(data);
    reset({
      collection: ''
    });
    handleCloseModal();
  };

  useEffect(() => {
    if (isCollectionSaved) {
      handleCloseModal();
      navigate(`/collections/${collectionId}`);
      setShowModal(false);
    }
  }, [isCollectionSaved]);

  useEffect(() => {
    reset({
      collection: ''
    });
  }, [isCollectionSaved]);

  return (
    <div className="flex flex-col px-4 py-3 rounded ">
      <div className="flex flex-col md:flex-row md:items-center justify-between w-full border-b border-gray-300 pb-3 gap-3 lg:gap-0">
        <div className="form-group flex items-center gap-3">
          <label className="block text-xl font-bold mb-1">Collections</label>
        </div>
        <div className="flex items-center gap-3 justify-end">
          <button
            type="button"
            className="btn-primary mt-0 w-auto mx-0 lg:mx-auto"
            data-bs-toggle="modal"
            data-bs-target="#AddNewCollection"
            onClick={handleShowModal}>
            Add New
          </button>
        </div>
      </div>
      <div className="tab-content-box shadow mt-4 p-4">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div id="tabs-list" className="w-full fade show active overflow-x-auto">
              <div className="flex flex-col gap-3 tab-box">
                <div className="bg-gray-100 flex">
                  <div className="p-3 text-left font-semibold text-gray-600 header-wrap">
                    Thumbnail
                  </div>
                  <div className="p-3 text-left font-semibold text-gray-600 header-wrap">
                    Collection Name
                  </div>
                  <div className="p-3 text-left font-semibold text-gray-600 header-wrap">
                    Description
                  </div>
                  <div className="p-3 text-left font-semibold text-gray-600 header-wrap">
                    Actions
                  </div>
                </div>
                <div className="flex flex-col gap-3 table-body">
                  {collectionList && collectionList.length == 0 ? (
                    <div className="empty-list-msg"> No Collections added.</div>
                  ) : (
                    <>
                      {collectionList.map((collection: any) => (
                        <div key={collection.id} className="flex shadow rounded-md cursor-pointer">
                          <div
                            className="p-3 custom-blank body-cell"
                            onDoubleClick={() => handleDoubleClick(collection.id)}>
                            <div className="flex items-center">
                              {collection.thumbnailUrl ? (
                                <img
                                  src={collection.thumbnailUrl}
                                  className="w-44 h-24 object-contain rounded border border-gray-200"
                                />
                              ) : (
                                <img
                                  src={`../../assets/img/dummy-image.svg`}
                                  className="w-44 h-24 object-contain p-3 rounded bg-gray-200 border-0"
                                />
                              )}
                            </div>
                          </div>

                          <div
                            className="p-3 body-cell"
                            onDoubleClick={() => handleDoubleClick(collection.id)}>
                            <span className="font-medium text-gray-900">
                              {collection.collection}
                            </span>
                          </div>

                          <div
                            className="p-3 body-cell"
                            onDoubleClick={() => handleDoubleClick(collection.id)}>
                            <span className="font-medium text-gray-900">
                              {collection.description}
                            </span>
                          </div>
                          <td className="text-sm font-light px-4 py-3 whitespace-nowrap body-cell">
                            <div className="flex items-center justify-center flex-1">
                              <div className="flex items-center justify-center">
                                <div>
                                  <div className="dropdown relative">
                                    <a
                                      className="
                                                                            dropdown-toggle
                                                                            transition
                                                                            duration-150
                                                                            ease-in-out w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-200 focus:bg-gray-200"
                                      href="#"
                                      type="button"
                                      id="ActionButton"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <ul
                                      className="dropdown-menu action-dropdown"
                                      aria-labelledby="ActionButton">
                                      <li>
                                        <span
                                          className="dropdown-item flex-items cursor-pointer"
                                          onClick={() => handleEditClick(collection.id)}>
                                          <i className="fas fa-edit w-6"></i>
                                          <span
                                            data-bs-toggle="modal"
                                            data-bs-target="#SettingsModal">
                                            Edit
                                          </span>
                                        </span>
                                      </li>
                                      <li
                                        onClick={() => handleDeleteClick(collection.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#DeleteCollectionModal">
                                        <span className="dropdown-item flex-items cursor-pointer text-red-500">
                                          <i className="fas fa-trash w-6"></i>
                                          <span>Delete</span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center model-main">
          <div className="bg-white w-4/12">
            <div className="relative modal-header p-4 rounded-t-md">
              <div className="border-b pb-3 w-full">
                <h5
                  className="text-xl font-semibold leading-normal text-center w-full"
                  id="AddNewEventLabel">
                  Create New Collection
                </h5>
              </div>
              <button
                ref={ref}
                type="button"
                className="dismiss-btn"
                onClick={() => setShowModal(false)}></button>
            </div>
            <div className="modal-body relative py-4 px-10">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`form-group mb-3`}>
                  <label className="block text-sm font-medium mb-1">Collection Name</label>
                  <input
                    type="text"
                    className="input-style"
                    {...register('collection', {
                      required: true
                    })}
                    name="collection"
                  />
                </div>

                <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center px-4 pb-2 rounded-b-md border-t mt-3 pt-3">
                  <button
                    type="submit"
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    Save Changes
                  </button>
                  <button
                    type="button"
                    className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                    onClick={() => setShowModal(false)}>
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <DeleteCollectionModal collectionId={collectionsId} />
    </div>
  );
};
export default Collections;
