import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/common/pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useUserActions } from 'src/store/users/actions';
import { RootState } from 'src/store/reducers/state';
import { useNavigate } from 'react-router-dom';
import { defaultDateFormatter, successMsg } from 'src/components/utilities/utils';
import Modal from '../components/Modal';
import ToastMessage from 'src/components/ToastContainer';
import { ToastContainer, toast } from 'react-toastify';
import TotalMember from '../components/TotalMember';
import { useForm, Controller } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'src/components/loader';
import { CSVLink } from 'react-csv';
import { MemberModel } from 'src/models/usersModel';
import { errorMsg } from 'src/components/utilities/utils';
import { FilterUserModel } from 'src/models/usersModel';
import { MemberCSVModel } from 'src/models/usersModel';
import { customFilterOption } from 'src/components/utilities/utils';
import Select from 'react-select';
const Members: React.FC = () => {
  const dispatch = useDispatch();
  const [modalFlag, setModalFlag] = useState('');
  const userAction = useUserActions(dispatch);
  const [finalMemberList, setFinalMemberList] = useState<any>();
  const [memberData, setMemberData] = useState<MemberModel>();
  const navigate = useNavigate();
  const [downloadData, setDownloadData] = useState<MemberCSVModel[]>();
  const [filterData, setFilterData] = useState<FilterUserModel | null>();
  const {
    members,
    memberDetail,
    memberListPaginationMeta,
    isMemberAdded,
    message,
    totalMembers,
    isMemberDeleted,
    isMemberUpdated,
    memberList,
    roles,
    status,
    memberlistLoading,
    error
  } = useSelector((state: RootState) => {
    return {
      members: state.users.members,
      memberDetail: state.users.memberDetail,
      memberListPaginationMeta: state.users.memberListPaginationMeta,
      isMemberAdded: state.users.isMemberAdded,
      message: state.users.message,
      totalMembers: state.users.totalMembers,
      isMemberDeleted: state.users.isMemberDeleted,
      isMemberUpdated: state.users.isMemberUpdated,
      memberList: state.users.memberList,
      roles: state.users.roles,
      status: state.users.status,
      memberlistLoading: state.users.memberlistLoading,
      error: state.users.error
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm();
  useEffect(() => {
    if (memberList && memberList.length) {
      const options = memberList.map((item: any) => ({
        value: item.id,
        label: item.name
      }));
      const finalMemberOptions = [{ value: '', label: 'Select...' }, ...options];
      setFinalMemberList(finalMemberOptions);
    }
  }, [memberList]);

  useEffect(() => {
    userAction.getMembersRequest({ pageNumber: 1 });
    userAction.getTotalMembersRequest();
    userAction.getMemberListRequest({ pageNumber: 1, pageSize: 10000 });
  }, [isMemberDeleted, isMemberAdded, isMemberUpdated]);

  useEffect(() => {
    userAction.getRolesRequest();
    userAction.getStatusRequest();
  }, []);
  const handleMemberDetailClick = (member: MemberModel, memberId: string) => {
    setMemberData(member);
    navigate(`/member/${memberId}`, {
      state: { memberId: memberId, memberDetail: member }
    });
  };
  const handleEditClick = (member: MemberModel) => {
    setModalFlag('edit');
    setMemberData(member);
    // userAction.getMemberDetailRequest(id);
  };
  const handlePageChange = (pageNumber: number) => {
    const data = { ...filterData, pageNumber };
    userAction.getMembersRequest(data);
  };
  useEffect(() => {
    if (isMemberAdded || isMemberDeleted || isMemberUpdated) {
      successMsg(message);
      userAction.resetMemberRequest();
    }
  }, [isMemberAdded, isMemberDeleted, isMemberUpdated]);

  useEffect(() => {
    if (members.length) {
      const csvData =
        members &&
        members.map((members) => {
          return {
            firstName: members.firstName,
            lastName: members.lastName,
            userName: members.userName,
            email: members.email,
            customerId: members.customerId,
            companyId: members.companyId,
            title: members.title,
            profileURL: members.profileURL
          };
        });
      setDownloadData(csvData);
    }
  }, [members]);

  const handleDeleteClick = (memberId: string) => {
    userAction.deleteMemberRequest(memberId);
  };
  const handleSearch = (e: any) => {
    userAction.searchMemberRequest(e.target.value);
  };

  useEffect(() => {
    if (error) {
      errorMsg('Error');
      userAction.resetMemberRequest();
      userAction.resetMemberDataStateRequest();
    }
  }, [error]);
  const onSubmit = (data: any) => {
    const filterPayloadData = {
      ...data,
      pageNumber: 1,
      MemberListId: data.MemberListId?.value ? data.MemberListId.value : 0
    };
    setFilterData(filterPayloadData);
    userAction.getMembersRequest(filterPayloadData);
  };

  const handleResetClick = () => {
    setFilterData(null);
    reset();
    setValue('MemberListId', '');
    userAction.getMembersRequest({ pageNumber: 1 });
  };
  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <TotalMember totalMembers={totalMembers} />
        <div className="dashboard-card my-5">
          <h2 className="font-semibold mb-4">Filters</h2>
          <div className="mb-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-2">
                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Role </label>
                  <select {...register('RoleId')} className="input-style">
                    <option value="">Select Role</option>
                    {roles &&
                      roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Member List </label>
                  {memberList && (
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          filterOption={customFilterOption}
                          options={finalMemberList}
                          value={value}
                          id="MemberListId"
                          onChange={(val) => onChange(val)}
                          isLoading={memberList.length ? false : true}
                        />
                      )}
                      name="MemberListId"
                      control={control}
                    />
                  )}
                </div>

                <div className={`form-group`}>
                  <label className="block text-sm font-medium mb-1"> Status </label>
                  <select className="input-style" {...register('Active')}>
                    <option value="">Select Status</option>
                    {status &&
                      status.map((status) => {
                        return (
                          <option key={status.id} value={status.value}>
                            {status.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="flex justify-center items-center">
                  <button type="submit" className="btn-primary w-auto mt-5 borderonly-btn ml-2">
                    Submit
                  </button>
                  <button
                    onClick={handleResetClick}
                    type="button"
                    className="btn-primary w-auto mt-5 ml-0 borderonly-btn ml-2">
                    Reset
                  </button>
                  <div className="dropdown relative mt-5 ml-2">
                    {downloadData && (
                      <CSVLink className="export" data={downloadData} filename={'my-file.csv'}>
                        <button
                          className="dropdown-toggle border input-style w-32 mx-auto md:mr-3 flex-items export-dropdown"
                          type="button">
                          <span className="flex">
                            <img src="../../assets/img/export.svg" className="w-4 h-4 mr-2" />
                            Export{' '}
                          </span>
                        </button>
                      </CSVLink>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative overflow-x-auto">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold "></h2>
                <div className="lg:flex items-center text-center">
                  <div className="form-group md:flex items-center text-center">
                    <label className="mr-1 font-semibold">Search:</label>
                    <input
                      onChange={(e) => handleSearch(e)}
                      className="input-style w-48 mr-3 mb-1"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <button
                    onClick={() => setModalFlag('add')}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#editMemberModal">
                    Add New User
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div>
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">User</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Role</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Email</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Customer ID</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Company ID</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Source</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Created</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Last Updated</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Last Login</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {memberlistLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {members.length ? (
                            <>
                              {members &&
                                members.map((member, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3">
                                            <img
                                              className="rounded-full"
                                              src={
                                                member.profileURL
                                                  ? member.profileURL
                                                  : '../../assets/img/user-png.png'
                                              }
                                              style={{ height: '40px' }}
                                              width="40"
                                              height="40"
                                            />
                                          </div>
                                          <div className="font-medium ">{`${member.firstName} ${member.lastName} `}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {member.roles.map((obj) => {
                                            return (
                                              <div
                                                key={obj.name}
                                                className="text-left custm-category">
                                                {obj.name}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{member.email}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{member.customerId}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{member.companyId}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">{member.sourceName}</div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {member.createdDate
                                            ? defaultDateFormatter(member.createdDate)
                                            : ''}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {member.lastUpdatedDate
                                            ? defaultDateFormatter(member.lastUpdatedDate)
                                            : ''}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          {member.lastLoginDate
                                            ? defaultDateFormatter(member.lastLoginDate)
                                            : ''}
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="text-left">
                                          <div className="dropdown relative">
                                            <button
                                              className="dropdown-toggle"
                                              type="button"
                                              id="action-dropdown"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false">
                                              <img
                                                src="../../assets/img/dots-vertical.svg"
                                                className="w-5 h-5"
                                              />
                                            </button>
                                            <ul
                                              className="dropdown-menu dropdown-menu-ul"
                                              aria-labelledby="action-dropdown">
                                              <li
                                                onClick={() =>
                                                  handleMemberDetailClick(member, member.id)
                                                }>
                                                <button className="action-menu flex items-center">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="mr-2 w-4 h-4">
                                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                    <polyline points="14 2 14 8 20 8"></polyline>
                                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                                    <polyline points="10 9 9 9 8 9"></polyline>
                                                  </svg>
                                                  <span>Details</span>
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  type="button"
                                                  onClick={() => handleEditClick(member)}
                                                  className="action-menu flex items-center"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#editMemberModal">
                                                  <i className="fas fa-edit w-6 text-left"></i>
                                                  <span>Edit</span>
                                                </button>
                                              </li>
                                              <li>
                                                <span
                                                  className="action-menu flex items-center cursor-pointer text-red-500"
                                                  onClick={() => handleDeleteClick(member.id)}>
                                                  <i className="fas fa-trash w-6"></i>
                                                  <span>Delete</span>
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!!memberListPaginationMeta && !!Object.keys(memberListPaginationMeta).length && (
                    <Pagination
                      currentPage={memberListPaginationMeta.pageNumber}
                      totalCount={memberListPaginationMeta.totalCount}
                      pageSize={memberListPaginationMeta.pageSize}
                      onPageChange={(page: any) => handlePageChange(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal memberDetails={memberData} modalFlag={modalFlag} setModalFlag={setModalFlag} />
      </div>
    </>
  );
};

export default Members;
