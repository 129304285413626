import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { PaginationState } from 'src/models/paginationModel';
import { DOTS, usePagination } from './usePagination';
import classnames from 'classnames';
const Pagination: React.FC<PaginationState> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize
}) => {
  const paginationRange: any = usePagination({ currentPage, totalCount, siblingCount, pageSize });

  if (currentPage === 0 || (paginationRange && paginationRange?.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange && paginationRange[paginationRange?.length - 1];
  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex items-center justify-between sm:hidden">
        <button
          type="button"
          onClick={onPrevious}
          className="relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md hover:bg-gray-50">
          Previous
        </button>
        <button
          type="button"
          onClick={onNext}
          className="ml-3 relative inline-flex items-center px-4 py-2  text-sm font-medium rounded-md hover:bg-gray-50">
          Next
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm"></p>
        </div>
        <div className="flex items-center">
          <button className={`paging-btn mr-1 ${currentPage === 1 ? 'hidden' : ''}`} onClick={onPrevious}>
            <span
              className={classnames('sr-only', {
                disabled: currentPage === 1
              })}
            >
              Previous
            </span>
            <ChevronLeftIcon className="h-5 w-5 site-clr" aria-hidden="true" />
          </button>
          <nav
            className="relative z-0 inline-flex bg-gray-100 -space-x-px rounded-full"
            aria-label="Pagination">
            {paginationRange &&
              paginationRange.map((pageNumber: number | string, idx: number) => {
                if (pageNumber === DOTS) {
                  return (
                    <li key={idx} className="pagination-item dots">
                      &#8230;
                    </li>
                  );
                }
                return (
                  <li
                    key={idx}
                    className={`${pageNumber === currentPage
                      ? 'z-10 bg-primary hover:bg-blue-500 hover:text-white rounded-full text-white'
                      : ''
                      } paging-link hover:bg-transparent cursor-pointer hover:text-black`}
                    onClick={() => onPageChange(pageNumber)}>
                    {pageNumber}
                  </li>
                );
              })}
          </nav>
          <button className={`paging-btn ml-1 ${currentPage === lastPage ? "hidden" : ""}`} onClick={onNext}>
            <span
              className={classnames('sr-only', {
                disabled: currentPage === lastPage
              })}
            >
              Next
            </span>
            <ChevronRightIcon className="h-5 w-5 site-clr" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
