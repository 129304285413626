import { all, put, takeEvery } from 'redux-saga/effects';
import { CourseActions } from './actions';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetCourses(data: CourseActions.getCourseRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/courses?pageNo=${data.payload?.pageNumber}&pageSize=${data.payload?.pageSize}&CourseName=${data.payload?.querySearch}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseRequestFailure());
    }
}
function* asyncSaveCourses(data: CourseActions.saveCourseRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData('v2/courses', data.payload);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.saveCourseRequestSuccess(response.data));
        }
    } catch (error) {
        const err = error as any
        console.log(error);
        yield put(CourseActions.saveCourseRequestFailure(error));
        if (err.response) {
            yield put(CourseActions.saveCourseRequestFailure(err.response.data.errors[0]));
        } else {
            yield put(CourseActions.saveCourseRequestFailure(error));
        }
    }
}
function* asyncGetSingleCourse(data: CourseActions.getSingleCourseRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/courses/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getSingleCourseRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getSingleCourseRequestFailure());
    }
}
function* asyncDeleteCourses(data: CourseActions.deleteCourseRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`v2/courses/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.deleteCourseRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.deleteCourseRequestFailure());
    }
}
function* asyncUpdateCourses(data: CourseActions.updateCourseRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`v2/courses/${data.payload?.id}`, data.payload?.patchJsonData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateCourseRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateCourseRequestFailure());
    }
}
function* asyncGetCoursesModule() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/courses/module');
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseModuleRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseModuleRequestFailure());
    }
}
function* asyncAddCourseModule(data: CourseActions.addCourseModuleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postDataTextType('v2/courses/module', `"${data.payload}"`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.addCourseModuleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.addCourseModuleRequestFailure());
    }
}
function* asyncGetCoursesCreditType() {
    try {
        const response: AxiosResponse = yield ApiService.getData('v2/courses/credittype');
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseCreditTypeRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseCreditTypeRequestFailure());
    }
}
function* asyncGetCourseResult(data: CourseActions.getCourseResultRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/courses/${data.payload}/courseresult`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseResultRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseResultRequestFailure());
    }
}
function* asyncGetCourseVideos(data: CourseActions.getCourseVideosRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`v2/courses/${data.payload}/videos`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseVideosRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseVideosRequestFailure());
    }
}
function* asyncSaveCourseVideoSequence(data: CourseActions.saveCourseVideoSequenceRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`v2/courses/videosequence?courseId=${data.payload?.id}`, data.payload?.videoIds);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.saveCourseVideoSequenceRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.saveCourseVideoSequenceRequestFailure());
    }
}
function* asyncUpdateCourseOverview(data: CourseActions.updateCourseOverviewRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_COURSE_URL.url}/${data.payload?.id}/overview`, data.payload?.updateData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateCourseOverviewRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateCourseOverviewRequestFailure());
    }
}
function* asyncUpdateCourseEducation(data: CourseActions.updateCourseEducationRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_COURSE_URL.url}/${data.payload?.id}/education`, data.payload?.updateData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateCourseEducationRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateCourseEducationRequestFailure());
    }
}
function* asyncUpdateCourseProgress(data: CourseActions.updateCourseProgressRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_COURSE_URL.url}/${data.payload?.id}/progress`, data.payload?.updateData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateCourseProgressRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateCourseProgressRequestFailure());
    }
}
function* asyncGetChapters(data: CourseActions.getChaptersRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHAPTERS.url}?CourseId=${data.payload?.id}&pageNo=${data.payload?.pageNumber}&pageSize=20`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getChaptersRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getChaptersRequestFailure());
    }
}
function* asyncAddChapter(data: CourseActions.addChapterRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_CHAPTERS.url, data.payload);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.addChapterRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.addChapterRequestFailure());
    }
}
function* asyncUpdateChapter(data: CourseActions.updateChapterRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CHAPTERS.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateChapterRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateChapterRequestFailure());
    }
}
function* asyncDeleteChapter(data: CourseActions.deleteChapterRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CHAPTERS.url}/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.deleteChapterRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.deleteChapterRequestFailure());
    }
}
function* asyncGetSingleChapter(data: CourseActions.getSingleChapterRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHAPTERS.url}/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getSingleChapterRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getSingleChapterRequestFailure());
    }
}
function* asyncGetCheckpoints(data: CourseActions.getCheckpointsRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHECKPOINTS.url}?CourseId=${data.payload?.id}&pageNo=${data.payload?.pageNumber}&pageSize=20`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCheckpointsRequestSuccess({ data: response.data.result, paginationMeta: { pageNumber: response.data.pageNumber, pageSize: response.data.pageSize, totalCount: response.data.totalCount } }));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCheckpointsRequestFailure());
    }
}
function* asyncAddCheckpoint(data: CourseActions.addCheckpointRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.ADD_CHECKPOINTS.url, data.payload);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.addCheckpointRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.addCheckpointRequestFailure());
    }
}
function* asyncUpdateCheckpoint(data: CourseActions.updateCheckpointRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.UPDATE_CHECKPOINTS.url}/${data.payload?.id}`, data.payload?.updateData);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.updateCheckpointRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.updateCheckpointRequestFailure());
    }
}
function* asyncDeleteCheckpoint(data: CourseActions.deleteCheckpointRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.DELETE_CHECKPOINTS.url}/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.deleteCheckpointRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.deleteCheckpointRequestFailure());
    }
}
function* asyncGetSingleCheckpoints(data: CourseActions.getSingleCheckpointRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_CHECKPOINTS.url}/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getSingleCheckpointRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getSingleCheckpointRequestFailure());
    }
}
function* asyncGetCourseSequences(data: CourseActions.getCourseSequencesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.GET_COURSE_SEQUENCES.url}/${data.payload}`);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.getCourseSequencesRequestSuccess(response.data.result));
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.getCourseSequencesRequestFailure());
    }
}
function* asyncSaveCourseSequences(data: CourseActions.saveCourseSequencesRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.patchData(`${URLS.SAVE_COURSE_SEQUENCES.url}/${data.payload?.courseId}`, data.payload?.contents);
        if (response.data.statusCode === 200) {
            yield put(CourseActions.saveCourseSequencesRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(CourseActions.saveCourseSequencesRequestFailure());
    }
}
export function* courseSaga() {
    yield all([
        takeEvery(Constants.getCourseRequestActionType, asyncGetCourses),
        takeEvery(Constants.saveCourseRequestActionType, asyncSaveCourses),
        takeEvery(Constants.getSingleCourseRequestActionType, asyncGetSingleCourse),
        takeEvery(Constants.deleteCourseRequestActionType, asyncDeleteCourses),
        takeEvery(Constants.updateCourseRequestActionType, asyncUpdateCourses),
        takeEvery(Constants.getCourseModuleRequestActionType, asyncGetCoursesModule),
        takeEvery(Constants.addCourseModuleRequestActionType, asyncAddCourseModule),
        takeEvery(Constants.getCourseCreditTypeRequestActionType, asyncGetCoursesCreditType),
        takeEvery(Constants.getCourseResultRequestActionType, asyncGetCourseResult),
        takeEvery(Constants.getCourseVideosRequestActionType, asyncGetCourseVideos),
        takeEvery(Constants.addCourseVideoSequenceRequestActionType, asyncSaveCourseVideoSequence),
        takeEvery(Constants.updateCourseOverviewRequestActionType, asyncUpdateCourseOverview),
        takeEvery(Constants.updateCourseEducationRequestActionType, asyncUpdateCourseEducation),
        takeEvery(Constants.updateCourseProgressRequestActionType, asyncUpdateCourseProgress),
        takeEvery(Constants.getChaptersRequestActionType, asyncGetChapters),
        takeEvery(Constants.addChapterRequestActionType, asyncAddChapter),
        takeEvery(Constants.updateChapterRequestActionType, asyncUpdateChapter),
        takeEvery(Constants.deleteChapterRequestActionType, asyncDeleteChapter),
        takeEvery(Constants.getSingleChapterRequestActionType, asyncGetSingleChapter),
        takeEvery(Constants.getCheckpointsRequestActionType, asyncGetCheckpoints),
        takeEvery(Constants.addCheckpointRequestActionType, asyncAddCheckpoint),
        takeEvery(Constants.updateCheckpointRequestActionType, asyncUpdateCheckpoint),
        takeEvery(Constants.deleteCheckpointRequestActionType, asyncDeleteCheckpoint),
        takeEvery(Constants.getSingleCheckpointRequestActionType, asyncGetSingleCheckpoints),
        takeEvery(Constants.getCourseSequencesRequestActionType, asyncGetCourseSequences),
        takeEvery(Constants.saveCourseSequencesRequestActionType, asyncSaveCourseSequences),
    ]);
}
