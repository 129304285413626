import { createAction } from "redux-actions"
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { MemberModel } from "src/models/usersModel";
import { GetLiveStreamModel, getQuestionModel, UpdateLiveStreamSourceModel, VideoAnalyticsFilterModel, VideoAnalyticsTableModel, VideoAnalyticsPagingDataModel, EnterLiveMeetingModel, StartLiveMeetingModel, LiveMeetingModel, SaveSlideModel, UpdateVideoSlideModel, VideoSlideModel, VideoStatus, VideoModuleModel, UpdateVideoStatusModel, UpdateQuestion, UpdatePollStatus, UpdateDiscountCodeModel, DiscountCodeModel, LiveMediaModel, LiveEventUrlModel, UpdateThumbnailModel, GetLiveViewModal, VideoModel, AddVideoModel, AccessibilityModel, UpdateCallToActionModel, VideoAnalyticsChartDataModel, RedirectToLoginModel, VideoAnalyticsDataModel, UpdatePlayPageModel, UpdateResourceModel, UpdateAccessibiblityModel, UpdateInteractivityModel, SlidesModel, QuestionModel, OpenTextModel, StarRatingModel, MultiChoiceModel, QuizModel, VideoMediaModel, PollModel, GetPollsModel, PollsTemplateModel, MemberListPriceModel, VideoViewingSessionModel, CallToActionModel, ModeratorModel, SaveMemberListAccessModel, ResourcesModel, PlayPageModel, MemberListAccess, ChannelModel, LiveStreamModel, InterActivityModel, AddSpeaker, AddCompleteUploadModel, SearchVideoModel, NavigationMenuModel, SaveChannelSettingModel, TotalRecordsModel, ProductAccessLiveMeetingModel, LiveViewersModel, AddResourceUploadModel, UpdateResourceUploadModel } from "src/models/videosModel";
import { PagingDataModel } from 'src/models/paginationModel';
import { Action, ActionCreator } from 'src/models/actions';
import { AddDataModel, SearchModel } from 'src/models/commonModels';
import { SingleVideoModel } from "src/models/videosModel";
export namespace VideosActions {

    export type getVideoAnalyticsRequestAction = Action<typeof Constants.getVideoAnalyticsRequestActionType, null>
    export type getVideoAnalyticsRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsRequestSuccessActionType, null>;
    export type getVideoAnalyticsRequestFailureAction = Action<typeof Constants.getVideoAnalyticsRequestFailureActionType, null>;

    export function getVideoAnalyticsRequest(): getVideoAnalyticsRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsRequestActionType, null);
    }
    export function getVideoAnalyticsRequestSuccess(): getVideoAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsRequestSuccessActionType, null);
    }
    export function getVideoAnalyticsRequestFailure(): getVideoAnalyticsRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsRequestFailureActionType, null);
    }

    export type getVideosRequestAction = Action<typeof Constants.getVideosRequestActionType, SearchModel>
    export type getVideosRequestSuccessAction = Action<typeof Constants.getVideosRequestSuccessActionType, PagingDataModel<VideoModel>>;
    export type getVideosRequestFailureAction = Action<typeof Constants.getVideosRequestFailureActionType, null>;

    export function getVideosRequest(P: SearchModel): getVideosRequestAction {
        return ActionCreator(Constants.getVideosRequestActionType, P);
    }
    export function getVideosRequestSuccess(P: PagingDataModel<VideoModel>): getVideosRequestSuccessAction {
        return ActionCreator(Constants.getVideosRequestSuccessActionType, P);
    }
    export function getVideosRequestFailure(): getVideosRequestFailureAction {
        return ActionCreator(Constants.getVideosRequestFailureActionType, null);
    }

    export type getSingleVideoRequestAction = Action<typeof Constants.getSingleVideoRequestActionType, string>
    export type getSingleVideoRequestSuccessAction = Action<typeof Constants.getSingleVideoRequestSuccessActionType, SingleVideoModel>;
    export type getSingleVideoRequestFailureAction = Action<typeof Constants.getSingleVideoRequestFailureActionType, null>;

    export function getSingleVideosRequest(P: string): getSingleVideoRequestAction {
        return ActionCreator(Constants.getSingleVideoRequestActionType, P);
    }
    export function getSingleVideosRequestSuccess(P: SingleVideoModel): getSingleVideoRequestSuccessAction {
        return ActionCreator(Constants.getSingleVideoRequestSuccessActionType, P);
    }
    export function getSingleVideosRequestFailure(): getSingleVideoRequestFailureAction {
        return ActionCreator(Constants.getSingleVideoRequestFailureActionType, null);
    }

    export type addVideoRequestAction = Action<typeof Constants.addVideoRequestActionType, AddVideoModel>
    export type addVideoRequestSuccessAction = Action<typeof Constants.addVideoRequestSuccessActionType, string>;
    export type addVideoRequestFailureAction = Action<typeof Constants.addVideoRequestFailureActionType, null>;

    export function addVideoRequest(P: AddVideoModel): addVideoRequestAction {
        return ActionCreator(Constants.addVideoRequestActionType, P);
    }
    export function addVideoRequestSuccess(P: string): addVideoRequestSuccessAction {
        return ActionCreator(Constants.addVideoRequestSuccessActionType, P);
    }
    export function addVideoRequestFailure(): addVideoRequestFailureAction {
        return ActionCreator(Constants.addVideoRequestFailureActionType, null);
    }


    export type editVideoRequestAction = Action<typeof Constants.editVideoRequestActionType, any>
    export type editVideoRequestSuccessAction = Action<typeof Constants.editVideoRequestSuccessActionType, null>;
    export type editVideoRequestFailureAction = Action<typeof Constants.editVideoRequestFailureActionType, null>;

    export function editVideoRequest(P: any): editVideoRequestAction {
        return ActionCreator(Constants.editVideoRequestActionType, P);
    }
    export function editVideoRequestSuccess(): editVideoRequestSuccessAction {
        return ActionCreator(Constants.editVideoRequestSuccessActionType, null);
    }
    export function editVideoRequestFailure(): editVideoRequestFailureAction {
        return ActionCreator(Constants.editVideoRequestFailureActionType, null);
    }

    export type deleteVideoRequestAction = Action<typeof Constants.deleteVideoRequestActionType, string>
    export type deleteVideoRequestSuccessAction = Action<typeof Constants.deleteVideoRequestSuccessActionType, null>;
    export type deleteVideoRequestFailureAction = Action<typeof Constants.deleteVideoRequestFailureActionType, null>;

    export function deleteVideoRequest(P: string): deleteVideoRequestAction {
        return ActionCreator(Constants.deleteVideoRequestActionType, P);
    }
    export function deleteVideoRequestSuccess(): deleteVideoRequestSuccessAction {
        return ActionCreator(Constants.deleteVideoRequestSuccessActionType, null);
    }
    export function deleteVideoRequestFailure(): deleteVideoRequestFailureAction {
        return ActionCreator(Constants.deleteVideoRequestFailureActionType, null);
    }

    export type uploadVideoRequestAction = Action<typeof Constants.uploadVideoRequestActionType, any>
    export type uploadVideoRequestSuccessAction = Action<typeof Constants.uploadVideoRequestSuccessActionType, any>;
    export type uploadVideoRequestFailureAction = Action<typeof Constants.uploadVideoRequestFailureActionType, null>;

    export function uploadVideoRequest(P: any): uploadVideoRequestAction {
        return ActionCreator(Constants.uploadVideoRequestActionType, P);
    }
    export function uploadVideoRequestSuccess(P: any): uploadVideoRequestSuccessAction {
        return ActionCreator(Constants.uploadVideoRequestSuccessActionType, P);
    }
    export function uploadVideoRequestFailure(): uploadVideoRequestFailureAction {
        return ActionCreator(Constants.uploadVideoRequestFailureActionType, null);
    }

    export type addLiveStreamRequestAction = Action<typeof Constants.addLiveStreamRequestActionType, LiveStreamModel>
    export type addLiveStreamRequestSuccessAction = Action<typeof Constants.addLiveStreamSuccessActionType, GetLiveStreamModel>;
    export type addLiveStreamRequestFailureAction = Action<typeof Constants.addLiveStreamFailureActionType, null>;

    export function addLiveStreamRequest(P: LiveStreamModel): addLiveStreamRequestAction {
        return ActionCreator(Constants.addLiveStreamRequestActionType, P);
    }
    export function addLiveStreamRequestSuccess(P: GetLiveStreamModel): addLiveStreamRequestSuccessAction {
        return ActionCreator(Constants.addLiveStreamSuccessActionType, P);
    }
    export function addLiveStreamRequestFailure(): addLiveStreamRequestFailureAction {
        return ActionCreator(Constants.addLiveStreamFailureActionType, null);
    }

    export type addCompleteUploadRequestAction = Action<typeof Constants.addCompleteUploadRequestActionType, AddCompleteUploadModel>
    export type addCompleteUploadRequestSuccessAction = Action<typeof Constants.addCompleteUploadRequestSuccessActionType, null>;
    export type addCompleteUploadRequestFailureAction = Action<typeof Constants.addCompleteUploadRequestFailureActionType, null>;

    export function addCompleteUploadRequest(P: AddCompleteUploadModel): addCompleteUploadRequestAction {
        return ActionCreator(Constants.addCompleteUploadRequestActionType, P);
    }
    export function addCompleteUploadRequestSuccess(): addCompleteUploadRequestSuccessAction {
        return ActionCreator(Constants.addCompleteUploadRequestSuccessActionType, null);
    }
    export function addCompleteUploadRequestFailure(): addCompleteUploadRequestFailureAction {
        return ActionCreator(Constants.addCompleteUploadRequestFailureActionType, null);
    }

    export type addResourceUploadRequestAction = Action<typeof Constants.addResourceUploadRequestActionType, AddResourceUploadModel>
    export type addResourceUploadRequestSuccessAction = Action<typeof Constants.addResourceUploadRequestSuccessActionType, AddResourceUploadModel>;
    export type addResourceUploadRequestFailureAction = Action<typeof Constants.addResourceUploadRequestFailureActionType, null>;

    export function addResourceUploadRequest(P: AddResourceUploadModel): addResourceUploadRequestAction {
        return ActionCreator(Constants.addResourceUploadRequestActionType, P);
    }
    export function addResourceUploadRequestSuccess(P: AddResourceUploadModel): addResourceUploadRequestSuccessAction {
        return ActionCreator(Constants.addResourceUploadRequestSuccessActionType, P);
    }
    export function addResourceUploadRequestFailure(): addResourceUploadRequestFailureAction {
        return ActionCreator(Constants.addResourceUploadRequestFailureActionType, null);
    }


    export type getResourceDetailRequestAction = Action<typeof Constants.getResourceDetailRequestActionType, string>
    export type getResourceDetailRequestSuccessAction = Action<typeof Constants.getResourceDetailRequestSuccessActionType, string>;
    export type getResourceDetailRequestFailureAction = Action<typeof Constants.getResourceDetailRequestFailureActionType, null>;

    export function getResourceDetailRequest(P: string): getResourceDetailRequestAction {
        return ActionCreator(Constants.getResourceDetailRequestActionType, P);
    }
    export function getResourceDetailRequestSuccess(P: string): getResourceDetailRequestSuccessAction {
        return ActionCreator(Constants.getResourceDetailRequestSuccessActionType, P);
    }
    export function getResourceDetailRequestFailure(): getResourceDetailRequestFailureAction {
        return ActionCreator(Constants.getResourceDetailRequestFailureActionType, null);
    }

    export type resetResourceUploadRequestAction = Action<typeof Constants.resetResourceUploadActionType, null>
    export function resetResourceUploadActionRequest(): resetResourceUploadRequestAction {
        return ActionCreator(Constants.resetResourceUploadActionType, null);
    }

    export type cancelUploadRequestAction = Action<typeof Constants.cancelUploadRequestActionType, any>
    export type cancelUploadRequestSuccessAction = Action<typeof Constants.cancelUploadRequestSuccessActionType, null>;
    export type cancelUploadRequestFailureAction = Action<typeof Constants.cancelUploadRequestFailureActionType, null>;

    export function cancelUploadRequest(P: any): cancelUploadRequestAction {
        return ActionCreator(Constants.cancelUploadRequestActionType, P);
    }
    export function cancelUploadRequestSuccess(): cancelUploadRequestSuccessAction {
        return ActionCreator(Constants.cancelUploadRequestSuccessActionType, null);
    }
    export function cancelUploadRequestFailure(): cancelUploadRequestFailureAction {
        return ActionCreator(Constants.cancelUploadRequestFailureActionType, null);
    }


    export type testUploadRequestAction = Action<typeof Constants.testUploadRequestActionType, any>
    export type testUploadRequestSuccessAction = Action<typeof Constants.testUploadRequestSuccessActionType, null>;
    export type testUploadRequestFailureAction = Action<typeof Constants.testUploadRequestFailureActionType, null>;

    export function testUploadRequest(P: any): testUploadRequestAction {
        return ActionCreator(Constants.testUploadRequestActionType, P);
    }
    export function testUploadRequestSuccess(): testUploadRequestSuccessAction {
        return ActionCreator(Constants.testUploadRequestSuccessActionType, null);
    }
    export function testUploadRequestFailure(): testUploadRequestFailureAction {
        return ActionCreator(Constants.testUploadRequestFailureActionType, null);
    }

    export type getuploadPartUrlRequestAction = Action<typeof Constants.getuploadPartUrlRequestActionType, any>
    export type getuploadPartUrlRequestSuccessAction = Action<typeof Constants.getuploadPartUrlRequestSuccessActionType, string>;
    export type getuploadPartUrlRequestFailureAction = Action<typeof Constants.getuploadPartUrlRequestFailureActionType, null>;

    export function getuploadPartUrlRequest(P: any): getuploadPartUrlRequestAction {
        return ActionCreator(Constants.getuploadPartUrlRequestActionType, P);
    }
    export function getuploadPartUrlRequestSuccess(P: string): getuploadPartUrlRequestSuccessAction {
        return ActionCreator(Constants.getuploadPartUrlRequestSuccessActionType, P);
    }
    export function getuploadPartUrlRequestFailure(): getuploadPartUrlRequestFailureAction {
        return ActionCreator(Constants.getuploadPartUrlRequestFailureActionType, null);
    }

    export type addSpeakerRequestAction = Action<typeof Constants.addSpeakerRequestActionType, AddSpeaker>
    export type addSpeakerRequestSuccessAction = Action<typeof Constants.addSpeakerRequestSuccessActionType, string>;
    export type addSpeakerRequestFailureAction = Action<typeof Constants.addSpeakerRequestFailureActionType, null>;

    export function addSpeakerRequest(P: AddSpeaker): addSpeakerRequestAction {
        return ActionCreator(Constants.addSpeakerRequestActionType, P);
    }
    export function addSpeakerRequestSuccess(P: string): addSpeakerRequestSuccessAction {
        return ActionCreator(Constants.addSpeakerRequestSuccessActionType, P);
    }
    export function addSpeakerRequestFailure(): addSpeakerRequestFailureAction {
        return ActionCreator(Constants.addSpeakerRequestFailureActionType, null);
    }

    export type searchVideoRequestAction = Action<typeof Constants.searchVideoRequestActionType, SearchVideoModel>
    export type searchVideoRequestSuccessAction = Action<typeof Constants.searchVideoRequestSuccessActionType, PagingDataModel<VideoModel>>;
    export type searchVideoRequestFailureAction = Action<typeof Constants.searchVideoRequestFailureActionType, null>;

    export function searchVideoRequest(P: SearchVideoModel): searchVideoRequestAction {
        return ActionCreator(Constants.searchVideoRequestActionType, P);
    }
    export function searchVideoRequestSuccess(P: PagingDataModel<VideoModel>): searchVideoRequestSuccessAction {
        return ActionCreator(Constants.searchVideoRequestSuccessActionType, P);
    }
    export function searchVideoRequestFailure(): searchVideoRequestFailureAction {
        return ActionCreator(Constants.searchVideoRequestFailureActionType, null);
    }

    export type getVideoSlidesRequestAction = Action<typeof Constants.getVideoSlidesRequestActionType, SearchVideoModel>
    export type getVideoSlidesRequestSuccessAction = Action<typeof Constants.getVideoSlidesRequestSuccessActionType, PagingDataModel<VideoSlideModel>>;
    export type getVideoSlidesRequestFailureAction = Action<typeof Constants.getVideoSlidesRequestFailureActionType, null>;

    export function getVideoSlidesRequest(P: SearchVideoModel): getVideoSlidesRequestAction {
        return ActionCreator(Constants.getVideoSlidesRequestActionType, P);
    }
    export function getVideoSlidesRequestSuccess(P: PagingDataModel<VideoSlideModel>): getVideoSlidesRequestSuccessAction {
        return ActionCreator(Constants.getVideoSlidesRequestSuccessActionType, P);
    }
    export function getVideoSlidesRequestFailure(): getVideoSlidesRequestFailureAction {
        return ActionCreator(Constants.getVideoSlidesRequestFailureActionType, null);
    }

    export type saveVideoSlidesRequestAction = Action<typeof Constants.saveVideoSlidesRequestActionType, string>
    export type saveVideoSlidesRequestSuccessAction = Action<typeof Constants.saveVideoSlidesRequestSuccessActionType, null>;
    export type saveVideoSlidesRequestFailureAction = Action<typeof Constants.saveVideoSlidesRequestFailureActionType, null>;

    export function saveVideoSlidesRequest(P: string): saveVideoSlidesRequestAction {
        return ActionCreator(Constants.saveVideoSlidesRequestActionType, P);
    }
    export function saveVideoSlidesRequestSuccess(): saveVideoSlidesRequestSuccessAction {
        return ActionCreator(Constants.saveVideoSlidesRequestSuccessActionType, null);
    }
    export function saveVideoSlidesRequestFailure(): saveVideoSlidesRequestFailureAction {
        return ActionCreator(Constants.saveVideoSlidesRequestFailureActionType, null);
    }


    export type updateVideoSlidesRequestAction = Action<typeof Constants.updateVideoSlidesRequestActionType, UpdateVideoSlideModel>
    export type updateVideoSlidesRequestSuccessAction = Action<typeof Constants.updateVideoSlidesRequestSuccessActionType, null>;
    export type updateVideoSlidesRequestFailureAction = Action<typeof Constants.updateVideoSlidesRequestFailureActionType, null>;

    export function updateVideoSlidesRequest(P: UpdateVideoSlideModel): updateVideoSlidesRequestAction {
        return ActionCreator(Constants.updateVideoSlidesRequestActionType, P);
    }
    export function updateVideoSlidesRequestSuccess(): updateVideoSlidesRequestSuccessAction {
        return ActionCreator(Constants.updateVideoSlidesRequestSuccessActionType, null);
    }
    export function updateVideoSlidesRequestFailure(): updateVideoSlidesRequestFailureAction {
        return ActionCreator(Constants.updateVideoSlidesRequestFailureActionType, null);
    }

    export type shareVideoRequestAction = Action<typeof Constants.shareVideoRequestActionType, string>
    export type shareVideoRequestSuccessAction = Action<typeof Constants.shareVideoRequestSuccessActionType, null>;
    export type shareVideoRequestFailureAction = Action<typeof Constants.shareVideoRequestFailureActionType, null>;

    export function shareVideoRequest(P: string): shareVideoRequestAction {
        return ActionCreator(Constants.shareVideoRequestActionType, P);
    }
    export function shareVideoRequestSuccess(): shareVideoRequestSuccessAction {
        return ActionCreator(Constants.shareVideoRequestSuccessActionType, null);
    }
    export function shareVideoRequestFailure(): shareVideoRequestFailureAction {
        return ActionCreator(Constants.shareVideoRequestFailureActionType, null);
    }

    export type getSpeakersRequestAction = Action<typeof Constants.getSpeakersRequestActionType, string>
    export type getSpeakersRequestSuccessAction = Action<typeof Constants.getSpeakersRequestSuccessActionType, MemberModel[]>;
    export type getSpeakersRequestFailureAction = Action<typeof Constants.getSpeakersRequestFailureActionType, null>;

    export function getSpeakersRequest(P: string): getSpeakersRequestAction {
        return ActionCreator(Constants.getSpeakersRequestActionType, P);
    }
    export function getSpeakersRequestSuccess(P: MemberModel[]): getSpeakersRequestSuccessAction {
        return ActionCreator(Constants.getSpeakersRequestSuccessActionType, P);
    }
    export function getSpeakersRequestFailure(): getSpeakersRequestFailureAction {
        return ActionCreator(Constants.getSpeakersRequestFailureActionType, null);
    }


    export type getInterActivityRequestAction = Action<typeof Constants.getInterActivityRequestActionType, string>
    export type getInterActivityRequestSuccessAction = Action<typeof Constants.getInterActivityRequestSuccessActionType, InterActivityModel>;
    export type getInterActivityRequestFailureAction = Action<typeof Constants.getInterActivityRequestFailureActionType, null>;

    export function getInterActivityRequest(P: string): getInterActivityRequestAction {
        return ActionCreator(Constants.getInterActivityRequestActionType, P);
    }
    export function getInterActivityRequestSuccess(P: InterActivityModel): getInterActivityRequestSuccessAction {
        return ActionCreator(Constants.getInterActivityRequestSuccessActionType, P);
    }
    export function getInterActivityRequestFailure(): getInterActivityRequestFailureAction {
        return ActionCreator(Constants.getInterActivityRequestFailureActionType, null);
    }

    export type updateInterActivityRequestAction = Action<typeof Constants.updateInterActivityRequestActionType, UpdateInteractivityModel>
    export type updateInterActivityRequestSuccessAction = Action<typeof Constants.updateInterActivityRequestSuccessActionType, null>;
    export type updateInterActivityRequestFailureAction = Action<typeof Constants.updateInterActivityRequestFailureActionType, null>;

    export function updateInterActivityRequest(P: UpdateInteractivityModel): updateInterActivityRequestAction {
        return ActionCreator(Constants.updateInterActivityRequestActionType, P);
    }
    export function updateInterActivityRequestSuccess(): updateInterActivityRequestSuccessAction {
        return ActionCreator(Constants.updateInterActivityRequestSuccessActionType, null);
    }
    export function updateInterActivityRequestFailure(): updateInterActivityRequestFailureAction {
        return ActionCreator(Constants.updateInterActivityRequestFailureActionType, null);
    }

    export type getAccessibilityRequestAction = Action<typeof Constants.getAccessibilityRequestActionType, string>
    export type getAccessibilityRequestSuccessAction = Action<typeof Constants.getAccessibilityRequestSuccessActionType, AccessibilityModel>;
    export type getAccessibilityRequestFailureAction = Action<typeof Constants.getAccessibilityRequestFailureActionType, null>;

    export function getAccessibilityRequest(P: string): getAccessibilityRequestAction {
        return ActionCreator(Constants.getAccessibilityRequestActionType, P);
    }
    export function getAccessibilityRequestSuccess(P: AccessibilityModel): getAccessibilityRequestSuccessAction {
        return ActionCreator(Constants.getAccessibilityRequestSuccessActionType, P);
    }
    export function getAccessibilityRequestFailure(): getAccessibilityRequestFailureAction {
        return ActionCreator(Constants.getAccessibilityRequestFailureActionType, null);
    }

    export type updateAccessibilityRequestAction = Action<typeof Constants.updateAccessibilityRequestActionType, UpdateAccessibiblityModel>
    export type updateAccessibilityRequestSuccessAction = Action<typeof Constants.updateAccessibilityRequestSuccessActionType, null>;
    export type updateAccessibilityRequestFailureAction = Action<typeof Constants.updateAccessibilityRequestFailureActionType, null>;

    export function updateAccessibilityRequest(P: UpdateAccessibiblityModel): updateAccessibilityRequestAction {
        return ActionCreator(Constants.updateAccessibilityRequestActionType, P);
    }
    export function updateAccessibilityRequestSuccess(): updateAccessibilityRequestSuccessAction {
        return ActionCreator(Constants.updateAccessibilityRequestSuccessActionType, null);
    }
    export function updateAccessibilityRequestFailure(): updateAccessibilityRequestFailureAction {
        return ActionCreator(Constants.updateAccessibilityRequestFailureActionType, null);
    }

    export type getPlayPageRequestAction = Action<typeof Constants.getPlayPageRequestActionType, string>
    export type getPlayPageRequestSuccessAction = Action<typeof Constants.getPlayPageRequestSuccessActionType, PlayPageModel>;
    export type getPlayPageRequestFailureAction = Action<typeof Constants.getPlayPageRequestFailureActionType, null>;

    export function getPlayPageRequestRequest(P: string): getPlayPageRequestAction {
        return ActionCreator(Constants.getPlayPageRequestActionType, P);
    }
    export function getPlayPageRequestRequestSuccess(P: PlayPageModel): getPlayPageRequestSuccessAction {
        return ActionCreator(Constants.getPlayPageRequestSuccessActionType, P);
    }
    export function getPlayPageRequestRequestFailure(): getPlayPageRequestFailureAction {
        return ActionCreator(Constants.getPlayPageRequestFailureActionType, null);
    }

    export type updatePlayPageRequestAction = Action<typeof Constants.updatePlayPageRequestActionType, UpdatePlayPageModel>
    export type updatePlayPageRequestSuccessAction = Action<typeof Constants.updatePlayPageRequestSuccessActionType, null>;
    export type updatePlayPageRequestFailureAction = Action<typeof Constants.updatePlayPageRequestFailureActionType, null>;

    export function updatePlayPageRequest(P: UpdatePlayPageModel): updatePlayPageRequestAction {
        return ActionCreator(Constants.updatePlayPageRequestActionType, P);
    }
    export function updatePlayPageRequestSuccess(): updatePlayPageRequestSuccessAction {
        return ActionCreator(Constants.updatePlayPageRequestSuccessActionType, null);
    }
    export function updatePlayPageRequestFailure(): updatePlayPageRequestFailureAction {
        return ActionCreator(Constants.updatePlayPageRequestFailureActionType, null);
    }

    export type getMemberListAccessRequestAction = Action<typeof Constants.getMemberListAccessRequestActionType, string>
    export type getMemberListAccessRequestSuccessAction = Action<typeof Constants.getMemberListAccessSuccessActionType, MemberListAccess[]>;
    export type getMemberListAccessRequestFailureAction = Action<typeof Constants.getMemberListAccessRequestFailureActionType, null>;

    export function getMemberListAccessRequestRequest(P: string): getMemberListAccessRequestAction {
        return ActionCreator(Constants.getMemberListAccessRequestActionType, P);
    }
    export function getMemberListAccessRequestRequestSuccess(P: MemberListAccess[]): getMemberListAccessRequestSuccessAction {
        return ActionCreator(Constants.getMemberListAccessSuccessActionType, P);
    }
    export function getMemberListAccessRequestRequestFailure(): getMemberListAccessRequestFailureAction {
        return ActionCreator(Constants.getMemberListAccessRequestFailureActionType, null);
    }

    export type saveMemberListAccessRequestAction = Action<typeof Constants.saveMemberListAccessRequestActionType, any>
    export type saveMemberListAccessRequestSuccessAction = Action<typeof Constants.saveMemberListAccessSuccessActionType, null>;
    export type saveMemberListAccessRequestFailureAction = Action<typeof Constants.saveMemberListAccessRequestFailureActionType, null>;

    export function saveMemberListAccessRequestRequest(P: any): saveMemberListAccessRequestAction {
        return ActionCreator(Constants.saveMemberListAccessRequestActionType, P);
    }
    export function saveMemberListAccessRequestRequestSuccess(): saveMemberListAccessRequestSuccessAction {
        return ActionCreator(Constants.saveMemberListAccessSuccessActionType, null);
    }
    export function saveMemberListAccessRequestRequestFailure(): saveMemberListAccessRequestFailureAction {
        return ActionCreator(Constants.saveMemberListAccessRequestFailureActionType, null);
    }

    export type deleteMemberListAccessRequestAction = Action<typeof Constants.deleteMemberListAccessRequestActionType, number>
    export type deleteMemberListAccessRequestSuccessAction = Action<typeof Constants.deleteMemberListAccessSuccessActionType, null>;
    export type deleteMemberListAccessRequestFailureAction = Action<typeof Constants.deleteMemberListAccessRequestFailureActionType, null>;

    export function deleteMemberListAccessRequestRequest(P: number): deleteMemberListAccessRequestAction {
        return ActionCreator(Constants.deleteMemberListAccessRequestActionType, P);
    }
    export function deleteMemberListAccessRequestRequestSuccess(): deleteMemberListAccessRequestSuccessAction {
        return ActionCreator(Constants.deleteMemberListAccessSuccessActionType, null);
    }
    export function deleteMemberListAccessRequestRequestFailure(): deleteMemberListAccessRequestFailureAction {
        return ActionCreator(Constants.deleteMemberListAccessRequestFailureActionType, null);
    }

    export type getResourcesRequestAction = Action<typeof Constants.getResourcesRequestActionType, SearchVideoModel>
    export type getResourcesRequestSuccessAction = Action<typeof Constants.getResourcesRequestSuccessActionType, PagingDataModel<ResourcesModel>>;
    export type getResourcesRequestFailureAction = Action<typeof Constants.getResourcesRequestFailureActionType, null>;

    export function getResourcesRequest(P: SearchVideoModel): getResourcesRequestAction {
        return ActionCreator(Constants.getResourcesRequestActionType, P);
    }
    export function getResourcesRequestSuccess(P: PagingDataModel<ResourcesModel>): getResourcesRequestSuccessAction {
        return ActionCreator(Constants.getResourcesRequestSuccessActionType, P);
    }
    export function getResourcesRequestFailure(): getResourcesRequestFailureAction {
        return ActionCreator(Constants.getResourcesRequestFailureActionType, null);
    }

    export type addResourcesRequestAction = Action<typeof Constants.addResourcesRequestActionType, ResourcesModel>
    export type addResourcesRequestSuccessAction = Action<typeof Constants.addResourcesRequestSuccessActionType, string>;
    export type addResourcesRequestFailureAction = Action<typeof Constants.addResourcesRequestFailureActionType, null>;

    export function addResourcesRequest(P: ResourcesModel): addResourcesRequestAction {
        return ActionCreator(Constants.addResourcesRequestActionType, P);
    }
    export function addResourcesRequestSuccess(P: string): addResourcesRequestSuccessAction {
        return ActionCreator(Constants.addResourcesRequestSuccessActionType, P);
    }
    export function addResourcesRequestFailure(): addResourcesRequestFailureAction {
        return ActionCreator(Constants.addResourcesRequestFailureActionType, null);
    }

    export type editResourcesRequestAction = Action<typeof Constants.editResourcesRequestActionType, UpdateResourceModel>
    export type editResourcesRequestSuccessAction = Action<typeof Constants.editResourcesRequestSuccessActionType, null>;
    export type editResourcesRequestFailureAction = Action<typeof Constants.editResourcesRequestFailureActionType, null>;

    export function editResourcesRequest(P: UpdateResourceModel): editResourcesRequestAction {
        return ActionCreator(Constants.editResourcesRequestActionType, P);
    }
    export function editResourcesRequestSuccess(): editResourcesRequestSuccessAction {
        return ActionCreator(Constants.editResourcesRequestSuccessActionType, null);
    }
    export function editResourcesRequestFailure(): editResourcesRequestFailureAction {
        return ActionCreator(Constants.editResourcesRequestFailureActionType, null);
    }

    export type getSingleResourcesRequestAction = Action<typeof Constants.getSingleResourcesRequestActionType, string>
    export type getSingleResourcesSuccessAction = Action<typeof Constants.getSingleResourcesRequestSuccessActionType, ResourcesModel>;
    export type getSingleResourcesFailureAction = Action<typeof Constants.getSingleResourcesRequestFailureActionType, null>;

    export function getSingleResourcesRequest(P: string): getSingleResourcesRequestAction {
        return ActionCreator(Constants.getSingleResourcesRequestActionType, P);
    }
    export function getSingleResourcesRequestSuccess(P: ResourcesModel): getSingleResourcesSuccessAction {
        return ActionCreator(Constants.getSingleResourcesRequestSuccessActionType, P);
    }
    export function getSingleResourcesRequestFailure(): getSingleResourcesFailureAction {
        return ActionCreator(Constants.getSingleResourcesRequestFailureActionType, null);
    }

    export type deleteResourcesRequestAction = Action<typeof Constants.deleteResourcesRequestActionType, string>
    export type deleteResourcesSuccessAction = Action<typeof Constants.deleteResourcesRequestSuccessActionType, null>;
    export type deleteResourcesFailureAction = Action<typeof Constants.deleteResourcesRequestFailureActionType, null>;

    export function deleteResourcesRequest(P: string): deleteResourcesRequestAction {
        return ActionCreator(Constants.deleteResourcesRequestActionType, P);
    }
    export function deleteResourcesRequestSuccess(): deleteResourcesSuccessAction {
        return ActionCreator(Constants.deleteResourcesRequestSuccessActionType, null);
    }
    export function deleteResourcesRequestFailure(): deleteResourcesFailureAction {
        return ActionCreator(Constants.deleteResourcesRequestFailureActionType, null);
    }

    export type getMemberListPriceRequestAction = Action<typeof Constants.getMemberListPriceRequestActionType, string>
    export type getMemberListPriceSuccessAction = Action<typeof Constants.getMemberListPriceSuccessActionType, PagingDataModel<MemberListPriceModel>>;
    export type getMemberListPriceFailureAction = Action<typeof Constants.getMemberListPriceRequestFailureActionType, null>;

    export function getMemberListPriceRequestRequest(P: string): getMemberListPriceRequestAction {
        return ActionCreator(Constants.getMemberListPriceRequestActionType, P);
    }
    export function getMemberListPriceRequestSuccess(P: PagingDataModel<MemberListPriceModel>): getMemberListPriceSuccessAction {
        return ActionCreator(Constants.getMemberListPriceSuccessActionType, P);
    }
    export function getMemberListPriceRequestFailure(): getMemberListPriceFailureAction {
        return ActionCreator(Constants.getMemberListPriceRequestFailureActionType, null);
    }

    export type saveMemberListPriceRequestAction = Action<typeof Constants.saveMemberListPriceRequestActionType, any>
    export type saveMemberListPriceSuccessAction = Action<typeof Constants.saveMemberListPriceRequestSuccessActionType, null>;
    export type saveMemberListPriceFailureAction = Action<typeof Constants.saveMemberListPriceRequestFailureActionType, null>;

    export function saveMemberListPriceRequest(P: any): saveMemberListPriceRequestAction {
        return ActionCreator(Constants.saveMemberListPriceRequestActionType, P);
    }
    export function saveMemberListPriceRequestSuccess(): saveMemberListPriceSuccessAction {
        return ActionCreator(Constants.saveMemberListPriceRequestSuccessActionType, null);
    }
    export function saveMemberListPriceRequestFailure(): saveMemberListPriceFailureAction {
        return ActionCreator(Constants.saveMemberListPriceRequestFailureActionType, null);
    }

    export type deleteMemberListPriceRequestAction = Action<typeof Constants.deleteMemberListPriceRequestActionType, number>
    export type deleteMemberListPriceSuccessAction = Action<typeof Constants.deleteMemberListPriceRequestSuccessActionType, null>;
    export type deleteMemberListPriceFailureAction = Action<typeof Constants.deleteMemberListPriceRequestFailureActionType, null>;

    export function deleteMemberListPriceRequest(P: number): deleteMemberListPriceRequestAction {
        return ActionCreator(Constants.deleteMemberListPriceRequestActionType, P);
    }
    export function deleteMemberListPriceRequestSuccess(): deleteMemberListPriceSuccessAction {
        return ActionCreator(Constants.deleteMemberListPriceRequestSuccessActionType, null);
    }
    export function deleteMemberListPriceRequestFailure(): deleteMemberListPriceFailureAction {
        return ActionCreator(Constants.deleteMemberListPriceRequestFailureActionType, null);
    }

    export type getModeratorRequestAction = Action<typeof Constants.getModeratorRequestActionType, string>
    export type getModeratorSuccessAction = Action<typeof Constants.getModeratorRequestSuccessActionType, PagingDataModel<ModeratorModel>>;
    export type getModeratorFailureAction = Action<typeof Constants.getModeratorRequestFailureActionType, null>;

    export function getModeratorRequest(P: string): getModeratorRequestAction {
        return ActionCreator(Constants.getModeratorRequestActionType, P);
    }
    export function getModeratorRequestSuccess(P: PagingDataModel<ModeratorModel>): getModeratorSuccessAction {
        return ActionCreator(Constants.getModeratorRequestSuccessActionType, P);
    }
    export function getModeratorRequestFailure(): getModeratorFailureAction {
        return ActionCreator(Constants.getModeratorRequestFailureActionType, null);
    }

    export type saveModeratorsRequestAction = Action<typeof Constants.saveModeratorsRequestActionType, any>
    export type saveModeratorsSuccessAction = Action<typeof Constants.saveModeratorsRequestSuccessActionType, null>;
    export type saveModeratorsFailureAction = Action<typeof Constants.saveModeratorsRequestFailureActionType, null>;

    export function saveModeratorsRequest(P: any): saveModeratorsRequestAction {
        return ActionCreator(Constants.saveModeratorsRequestActionType, P);
    }
    export function saveModeratorsRequestSuccess(): saveModeratorsSuccessAction {
        return ActionCreator(Constants.saveModeratorsRequestSuccessActionType, null);
    }
    export function saveModeratorsRequestFailure(): saveModeratorsFailureAction {
        return ActionCreator(Constants.saveModeratorsRequestFailureActionType, null);
    }

    export type deleteModeratorsRequestAction = Action<typeof Constants.deleteModeratorsRequestActionType, any>
    export type deleteModeratorsSuccessAction = Action<typeof Constants.deleteModeratorsRequestSuccessActionType, null>;
    export type deleteModeratorsFailureAction = Action<typeof Constants.deleteModeratorsRequestFailureActionType, null>;

    export function deleteModeratorsRequest(P: any): deleteModeratorsRequestAction {
        return ActionCreator(Constants.deleteModeratorsRequestActionType, P);
    }
    export function deleteModeratorsRequestSuccess(): deleteModeratorsSuccessAction {
        return ActionCreator(Constants.deleteModeratorsRequestSuccessActionType, null);
    }
    export function deleteModeratorsRequestFailure(): deleteModeratorsFailureAction {
        return ActionCreator(Constants.deleteModeratorsRequestFailureActionType, null);
    }

    export type getCallToActionRequestAction = Action<typeof Constants.getCallToActionRequestActionType, string>
    export type getCallToActionSuccessAction = Action<typeof Constants.getCallToActionRequestSuccessActionType, PagingDataModel<CallToActionModel>>;
    export type getCallToActionFailureAction = Action<typeof Constants.getCallToActionRequestFailureActionType, null>;

    export function getCallToActionRequest(P: string): getCallToActionRequestAction {
        return ActionCreator(Constants.getCallToActionRequestActionType, P);
    }
    export function getCallToActionRequestSuccess(P: PagingDataModel<CallToActionModel>): getCallToActionSuccessAction {
        return ActionCreator(Constants.getCallToActionRequestSuccessActionType, P);
    }
    export function getCallToActionRequestFailure(): getCallToActionFailureAction {
        return ActionCreator(Constants.getCallToActionRequestFailureActionType, null);
    }

    export type saveCallToActionRequestAction = Action<typeof Constants.saveCallToActionRequestActionType, CallToActionModel>
    export type saveCallToActionSuccessAction = Action<typeof Constants.saveCallToActionRequestSuccessActionType, null>;
    export type saveCallToActionFailureAction = Action<typeof Constants.saveCallToActionRequestFailureActionType, null>;

    export function saveCallToActionRequest(P: CallToActionModel): saveCallToActionRequestAction {
        return ActionCreator(Constants.saveCallToActionRequestActionType, P);
    }
    export function saveCallToActionRequestSuccess(): saveCallToActionSuccessAction {
        return ActionCreator(Constants.saveCallToActionRequestSuccessActionType, null);
    }
    export function saveCallToActionRequestFailure(): saveCallToActionFailureAction {
        return ActionCreator(Constants.saveCallToActionRequestFailureActionType, null);
    }

    export type deleteCallToActionRequestAction = Action<typeof Constants.deleteCallToActionRequestActionType, string>
    export type deleteCallToActionSuccessAction = Action<typeof Constants.deleteCallToActionRequestSuccessActionType, null>;
    export type deleteCallToActionFailureAction = Action<typeof Constants.deleteCallToActionRequestFailureActionType, null>;

    export function deleteCallToActionRequest(P: string): deleteCallToActionRequestAction {
        return ActionCreator(Constants.deleteCallToActionRequestActionType, P);
    }
    export function deleteCallToActionRequestSuccess(): deleteCallToActionSuccessAction {
        return ActionCreator(Constants.deleteCallToActionRequestSuccessActionType, null);
    }
    export function deleteCallToActionRequestFailure(): deleteCallToActionFailureAction {
        return ActionCreator(Constants.deleteCallToActionRequestFailureActionType, null);
    }

    export type getSingleCallToActionRequestAction = Action<typeof Constants.getSingleCallToActionRequestActionType, string>
    export type getSingleCallToActionSuccessAction = Action<typeof Constants.getSingleCallToActionRequestSuccessActionType, CallToActionModel>;
    export type getSingleCallToActionFailureAction = Action<typeof Constants.getSingleCallToActionRequestFailureActionType, null>;

    export function getSingleCallToActionRequest(P: string): getSingleCallToActionRequestAction {
        return ActionCreator(Constants.getSingleCallToActionRequestActionType, P);
    }
    export function getSingleCallToActionRequestSuccess(P: CallToActionModel): getSingleCallToActionSuccessAction {
        return ActionCreator(Constants.getSingleCallToActionRequestSuccessActionType, P);
    }
    export function getSingleCallToActionRequestFailure(): getSingleCallToActionFailureAction {
        return ActionCreator(Constants.getSingleCallToActionRequestFailureActionType, null);
    }

    export type updateCallToActionRequestAction = Action<typeof Constants.updateCallToActionRequestActionType, UpdateCallToActionModel>
    export type updateCallToActionSuccessAction = Action<typeof Constants.updateCallToActionRequestSuccessActionType, null>;
    export type updateCallToActionFailureAction = Action<typeof Constants.updateCallToActionRequestFailureActionType, null>;

    export function updateCallToActionRequest(P: UpdateCallToActionModel): updateCallToActionRequestAction {
        return ActionCreator(Constants.updateCallToActionRequestActionType, P);
    }
    export function updateCallToActionRequestSuccess(): updateCallToActionSuccessAction {
        return ActionCreator(Constants.updateCallToActionRequestSuccessActionType, null);
    }
    export function updateCallToActionRequestFailure(): updateCallToActionFailureAction {
        return ActionCreator(Constants.updateCallToActionRequestFailureActionType, null);
    }

    export type getVideoViewingSessionRequestAction = Action<typeof Constants.getVideoViewingSessionRequestActionType, any>
    export type getVideoViewingSessionSuccessAction = Action<typeof Constants.getVideoViewingSessionRequestSuccessActionType, PagingDataModel<VideoViewingSessionModel>>;
    export type getVideoViewingSessionFailureAction = Action<typeof Constants.getVideoViewingSessionRequestFailureActionType, null>;

    export function getVideoViewingSessionRequest(P: any): getVideoViewingSessionRequestAction {
        return ActionCreator(Constants.getVideoViewingSessionRequestActionType, P);
    }
    export function getVideoViewingSessionRequestSuccess(P: PagingDataModel<VideoViewingSessionModel>): getVideoViewingSessionSuccessAction {
        return ActionCreator(Constants.getVideoViewingSessionRequestSuccessActionType, P);
    }
    export function getVideoViewingSessionRequestFailure(): getVideoViewingSessionFailureAction {
        return ActionCreator(Constants.getVideoViewingSessionRequestFailureActionType, null);
    }

    export type saveSlidesRequestAction = Action<typeof Constants.saveSlidesRequestActionType, SaveSlideModel>
    export type saveSlidesSuccessAction = Action<typeof Constants.saveSlidesRequestSuccessActionType, null>;
    export type saveSlidesFailureAction = Action<typeof Constants.saveSlidesRequestFailureActionType, null>;

    export function saveSlidesRequest(P: SaveSlideModel): saveSlidesRequestAction {
        return ActionCreator(Constants.saveSlidesRequestActionType, P);
    }
    export function saveSlidesRequestSuccess(): saveSlidesSuccessAction {
        return ActionCreator(Constants.saveSlidesRequestSuccessActionType, null);
    }
    export function saveSlidesRequestFailure(): saveSlidesFailureAction {
        return ActionCreator(Constants.saveSlidesRequestFailureActionType, null);
    }

    export type getSlidesRequestAction = Action<typeof Constants.getSlidesRequestActionType, string>
    export type getSlidesSuccessAction = Action<typeof Constants.getSlidesRequestSuccessActionType, PagingDataModel<SlidesModel>>;
    export type getSlidesFailureAction = Action<typeof Constants.getSlidesRequestFailureActionType, null>;

    export function getSlidesRequest(P: string): getSlidesRequestAction {
        return ActionCreator(Constants.getSlidesRequestActionType, P);
    }
    export function getSlidesRequestSuccess(P: PagingDataModel<SlidesModel>): getSlidesSuccessAction {
        return ActionCreator(Constants.getSlidesRequestSuccessActionType, P);
    }
    export function getSlidesRequestFailure(): getSlidesFailureAction {
        return ActionCreator(Constants.getSlidesRequestFailureActionType, null);
    }

    export type updateSlidesRequestAction = Action<typeof Constants.updateSlidesRequestActionType, string>
    export type updateSlidesSuccessAction = Action<typeof Constants.updateSlidesRequestSuccessActionType, null>;
    export type updateSlidesFailureAction = Action<typeof Constants.updateSlidesRequestFailureActionType, null>;

    export function updateSlidesRequest(P: string): updateSlidesRequestAction {
        return ActionCreator(Constants.updateSlidesRequestActionType, P);
    }
    export function updateSlidesRequestSuccess(): updateSlidesSuccessAction {
        return ActionCreator(Constants.updateSlidesRequestSuccessActionType, null);
    }
    export function updateSlidesRequestFailure(): updateSlidesFailureAction {
        return ActionCreator(Constants.updateSlidesRequestFailureActionType, null);
    }

    export type savePollRequestAction = Action<typeof Constants.savePollRequestActionType, PollModel>
    export type savePollRequestSuccessAction = Action<typeof Constants.savePollRequestSuccessActionType, null>;
    export type savePollRequestFailureAction = Action<typeof Constants.savePollRequestFailureActionType, null>;

    export function savePollRequest(P: PollModel): savePollRequestAction {
        return ActionCreator(Constants.savePollRequestActionType, P);
    }
    export function savePollRequestSuccess(): savePollRequestSuccessAction {
        return ActionCreator(Constants.savePollRequestSuccessActionType, null);
    }
    export function savePollRequestFailure(): savePollRequestFailureAction {
        return ActionCreator(Constants.savePollRequestFailureActionType, null);
    }

    export type getPollRequestAction = Action<typeof Constants.getPollRequestActionType, string>
    export type getPollSuccessAction = Action<typeof Constants.getPollRequestSuccessActionType, PagingDataModel<GetPollsModel>>;
    export type getPollFailureAction = Action<typeof Constants.getPollRequestFailureActionType, null>;

    export function getPollRequest(P: string): getPollRequestAction {
        return ActionCreator(Constants.getPollRequestActionType, P);
    }
    export function getPollRequestSuccess(P: PagingDataModel<GetPollsModel>): getPollSuccessAction {
        return ActionCreator(Constants.getPollRequestSuccessActionType, P);
    }
    export function getPollRequestFailure(): getPollFailureAction {
        return ActionCreator(Constants.getPollRequestFailureActionType, null);
    }

    export type updatePollRequestAction = Action<typeof Constants.updatePollRequestActionType, PollModel>
    export type updatePollRequestSuccessAction = Action<typeof Constants.updatePollRequestSuccessActionType, null>;
    export type updatePollRequestFailureAction = Action<typeof Constants.updatePollRequestFailureActionType, null>;

    export function updatePollRequest(P: PollModel): updatePollRequestAction {
        return ActionCreator(Constants.updatePollRequestActionType, P);
    }
    export function updatePollRequestSuccess(): updatePollRequestSuccessAction {
        return ActionCreator(Constants.updatePollRequestSuccessActionType, null);
    }
    export function updatePollRequestFailure(): updatePollRequestFailureAction {
        return ActionCreator(Constants.updatePollRequestFailureActionType, null);
    }

    export type getPollsTemplatesRequestAction = Action<typeof Constants.getPollsTemplatesRequestActionType, null>
    export type getPollsTemplatesSuccessAction = Action<typeof Constants.getPollsTemplatesSuccessActionType, PollsTemplateModel[]>;
    export type getPollsTemplatesFailureAction = Action<typeof Constants.getPollsTemplatesFailureActionType, null>;

    export function getPollsTemplatesRequest(): getPollsTemplatesRequestAction {
        return ActionCreator(Constants.getPollsTemplatesRequestActionType, null);
    }
    export function getPollsTemplatesRequestSuccess(P: PollsTemplateModel[]): getPollsTemplatesSuccessAction {
        return ActionCreator(Constants.getPollsTemplatesSuccessActionType, P);
    }
    export function getPollsTemplatesRequestFailure(): getPollsTemplatesFailureAction {
        return ActionCreator(Constants.getPollsTemplatesFailureActionType, null);
    }

    export type saveQuestionRequestAction = Action<typeof Constants.saveQuestionRequestActionType, QuestionModel>
    export type saveQuestionSuccessAction = Action<typeof Constants.saveQuestionRequestSuccessActionType, null>;
    export type saveQuestionFailureAction = Action<typeof Constants.saveQuestionRequestFailureActionType, null>;

    export function saveQuestionRequest(P: QuestionModel): saveQuestionRequestAction {
        return ActionCreator(Constants.saveQuestionRequestActionType, P);
    }
    export function saveQuestionRequestSuccess(): saveQuestionSuccessAction {
        return ActionCreator(Constants.saveQuestionRequestSuccessActionType, null);
    }
    export function saveQuestionRequestFailure(): saveQuestionFailureAction {
        return ActionCreator(Constants.saveQuestionRequestFailureActionType, null);
    }

    export type updateQuestionRequestAction = Action<typeof Constants.updateQuestionRequestActionType, UpdateQuestion>
    export type updateQuestionSuccessAction = Action<typeof Constants.updateQuestionRequestSuccessActionType, null>;
    export type updateQuestionFailureAction = Action<typeof Constants.updateQuestionRequestFailureActionType, null>;

    export function updateQuestionRequest(P: UpdateQuestion): updateQuestionRequestAction {
        return ActionCreator(Constants.updateQuestionRequestActionType, P);
    }
    export function updateQuestionRequestSuccess(): updateQuestionSuccessAction {
        return ActionCreator(Constants.updateQuestionRequestSuccessActionType, null);
    }
    export function updateQuestionRequestFailure(): updateQuestionFailureAction {
        return ActionCreator(Constants.updateQuestionRequestFailureActionType, null);
    }

    export type getQuestionRequestAction = Action<typeof Constants.getQuestionRequestActionType, getQuestionModel>
    export type getQuestionSuccessAction = Action<typeof Constants.getQuestionRequestSuccessActionType, PagingDataModel<QuestionModel>>;
    export type getQuestionFailureAction = Action<typeof Constants.getQuestionRequestFailureActionType, null>;

    export function getQuestionRequest(P: getQuestionModel): getQuestionRequestAction {
        return ActionCreator(Constants.getQuestionRequestActionType, P);
    }
    export function getQuestionRequestSuccess(P: PagingDataModel<QuestionModel>): getQuestionSuccessAction {
        return ActionCreator(Constants.getQuestionRequestSuccessActionType, P);
    }
    export function getQuestionRequestFailure(): getQuestionFailureAction {
        return ActionCreator(Constants.getQuestionRequestFailureActionType, null);
    }

    export type getVideoMediaRequestAction = Action<typeof Constants.getVideoMediaRequestActionType, string>
    export type getVideoMediaSuccessAction = Action<typeof Constants.getVideoMediaRequestSuccessActionType, VideoMediaModel>;
    export type getVideoMediaFailureAction = Action<typeof Constants.getVideoMediaRequestFailureActionType, null>;

    export function getVideoMediaRequest(P: string): getVideoMediaRequestAction {
        return ActionCreator(Constants.getVideoMediaRequestActionType, P);
    }
    export function getVideoMediaRequestSuccess(P: VideoMediaModel): getVideoMediaSuccessAction {
        return ActionCreator(Constants.getVideoMediaRequestSuccessActionType, P);
    }
    export function getVideoMediaRequestFailure(): getVideoMediaFailureAction {
        return ActionCreator(Constants.getVideoMediaRequestFailureActionType, null);
    }

    export type saveVideoMediaRequestAction = Action<typeof Constants.saveVideoMediaRequestActionType, VideoMediaModel>
    export type saveVideoMediaSuccessAction = Action<typeof Constants.saveVideoMediaRequestSuccessActionType, null>;
    export type saveVideoMediaFailureAction = Action<typeof Constants.saveVideoMediaRequestFailureActionType, null>;

    export function saveVideoMediaRequest(P: VideoMediaModel): saveVideoMediaRequestAction {
        return ActionCreator(Constants.saveVideoMediaRequestActionType, P);
    }
    export function saveVideoMediaRequestSuccess(): saveVideoMediaSuccessAction {
        return ActionCreator(Constants.saveVideoMediaRequestSuccessActionType, null);
    }
    export function saveVideoMediaRequestFailure(): saveVideoMediaFailureAction {
        return ActionCreator(Constants.saveVideoMediaRequestFailureActionType, null);
    }

    export type updateVideoMediaRequestAction = Action<typeof Constants.updateVideoMediaRequestActionType, VideoMediaModel>
    export type updateVideoMediaSuccessAction = Action<typeof Constants.updateVideoMediaRequestSuccessActionType, null>;
    export type updateVideoMediaFailureAction = Action<typeof Constants.updateVideoMediaRequestFailureActionType, null>;

    export function updateVideoMediaRequest(P: VideoMediaModel): updateVideoMediaRequestAction {
        return ActionCreator(Constants.updateVideoMediaRequestActionType, P);
    }
    export function updateVideoMediaRequestSuccess(): updateVideoMediaSuccessAction {
        return ActionCreator(Constants.updateVideoMediaRequestSuccessActionType, null);
    }
    export function updateVideoMediaRequestFailure(): updateVideoMediaFailureAction {
        return ActionCreator(Constants.updateVideoMediaRequestFailureActionType, null);
    }

    export type getPollsResultRequestAction = Action<typeof Constants.getPollsResultRequestActionType, string>
    export type getPollsResultSuccessAction = Action<typeof Constants.getPollsResultRequestSuccessActionType, PollModel>;
    export type getPollsResultFailureAction = Action<typeof Constants.getPollsResultRequestFailureActionType, null>;

    export function getPollsResultRequest(P: string): getPollsResultRequestAction {
        return ActionCreator(Constants.getPollsResultRequestActionType, P);
    }
    export function getPollsResultRequestSuccess(P: PollModel): getPollsResultSuccessAction {
        return ActionCreator(Constants.getPollsResultRequestSuccessActionType, P);
    }
    export function getPollsResultRequestFailure(): getPollsResultFailureAction {
        return ActionCreator(Constants.getPollsResultRequestFailureActionType, null);
    }

    export type saveQuizRequestAction = Action<typeof Constants.saveQuizRequestActionType, QuizModel>
    export type saveQuizRequestSuccessAction = Action<typeof Constants.saveQuizRequestSuccessActionType, null>;
    export type saveQuizRequestFailureAction = Action<typeof Constants.saveQuizRequestFailureActionType, null>;

    export function saveQuizRequest(P: QuizModel): saveQuizRequestAction {
        return ActionCreator(Constants.saveQuizRequestActionType, P);
    }
    export function saveQuizRequestSuccess(): saveQuizRequestSuccessAction {
        return ActionCreator(Constants.saveQuizRequestSuccessActionType, null);
    }
    export function saveQuizRequestFailure(): saveQuizRequestFailureAction {
        return ActionCreator(Constants.saveQuizRequestFailureActionType, null);
    }

    export type getQuizRequestAction = Action<typeof Constants.getQuizRequestActionType, string>
    export type getQuizRequestSuccessAction = Action<typeof Constants.getQuizRequestSuccessActionType, QuizModel>;
    export type getQuizRequestFailureAction = Action<typeof Constants.getQuizRequestFailureActionType, null>;

    export function getQuizRequest(P: string): getQuizRequestAction {
        return ActionCreator(Constants.getQuizRequestActionType, P);
    }
    export function getQuizRequestSuccess(P: QuizModel): getQuizRequestSuccessAction {
        return ActionCreator(Constants.getQuizRequestSuccessActionType, P);
    }
    export function getQuizRequestFailure(): getQuizRequestFailureAction {
        return ActionCreator(Constants.getQuizRequestFailureActionType, null);
    }

    export type saveMultiChoiceRequestAction = Action<typeof Constants.saveMultiChoiceRequestActionType, MultiChoiceModel>
    export type saveMultiChoiceRequestSuccessAction = Action<typeof Constants.saveMultiChoiceRequestSuccessActionType, null>;
    export type saveMultiChoiceRequestFailureAction = Action<typeof Constants.saveMultiChoiceRequestFailureActionType, null>;

    export function saveMultiChoiceRequest(P: MultiChoiceModel): saveMultiChoiceRequestAction {
        return ActionCreator(Constants.saveMultiChoiceRequestActionType, P);
    }
    export function saveMultiChoiceRequestSuccess(): saveMultiChoiceRequestSuccessAction {
        return ActionCreator(Constants.saveMultiChoiceRequestSuccessActionType, null);
    }
    export function saveMultiChoiceRequestFailure(): saveMultiChoiceRequestFailureAction {
        return ActionCreator(Constants.saveMultiChoiceRequestFailureActionType, null);
    }

    export type getMultiChoiceRequestAction = Action<typeof Constants.getMultiChoiceRequestActionType, string>
    export type getMultiChoiceRequestSuccessAction = Action<typeof Constants.getMultiChoiceRequestSuccessActionType, QuizModel>;
    export type getMultiChoiceRequestFailureAction = Action<typeof Constants.getMultiChoiceRequestFailureActionType, null>;

    export function getMultiChoiceRequest(P: string): getMultiChoiceRequestAction {
        return ActionCreator(Constants.getMultiChoiceRequestActionType, P);
    }
    export function getMultiChoiceRequestSuccess(P: QuizModel): getMultiChoiceRequestSuccessAction {
        return ActionCreator(Constants.getMultiChoiceRequestSuccessActionType, P);
    }
    export function getMultiChoiceRequestFailure(): getMultiChoiceRequestFailureAction {
        return ActionCreator(Constants.getMultiChoiceRequestFailureActionType, null);
    }

    export type saveStarRatingRequestAction = Action<typeof Constants.saveStarRatingRequestActionType, StarRatingModel>
    export type saveStarRatingRequestSuccessAction = Action<typeof Constants.saveStarRatingRequestSuccessActionType, null>;
    export type saveStarRatingRequestFailureAction = Action<typeof Constants.saveStarRatingRequestFailureActionType, null>;

    export function saveStarRatingRequest(P: StarRatingModel): saveStarRatingRequestAction {
        return ActionCreator(Constants.saveStarRatingRequestActionType, P);
    }
    export function saveStarRatingRequestSuccess(): saveStarRatingRequestSuccessAction {
        return ActionCreator(Constants.saveStarRatingRequestSuccessActionType, null);
    }
    export function saveStarRatingRequestFailure(): saveStarRatingRequestFailureAction {
        return ActionCreator(Constants.saveStarRatingRequestFailureActionType, null);
    }

    export type getStarRatingRequestAction = Action<typeof Constants.getStarRatingRequestActionType, string>
    export type getStarRatingRequestSuccessAction = Action<typeof Constants.getStarRatingRequestSuccessActionType, StarRatingModel>;
    export type getStarRatingRequestFailureAction = Action<typeof Constants.getStarRatingRequestFailureActionType, null>;

    export function getStarRatingRequest(P: string): getStarRatingRequestAction {
        return ActionCreator(Constants.getStarRatingRequestActionType, P);
    }
    export function getStarRatingRequestSuccess(P: StarRatingModel): getStarRatingRequestSuccessAction {
        return ActionCreator(Constants.getStarRatingRequestSuccessActionType, P);
    }
    export function getStarRatingRequestFailure(): getStarRatingRequestFailureAction {
        return ActionCreator(Constants.getStarRatingRequestFailureActionType, null);
    }

    export type saveOpenTextRequestAction = Action<typeof Constants.saveOpenTextRequestActionType, OpenTextModel>
    export type saveOpenTextRequestSuccessAction = Action<typeof Constants.saveOpenTextRequestSuccessActionType, null>;
    export type saveOpenTextRequestFailureAction = Action<typeof Constants.saveOpenTextRequestFailureActionType, null>;

    export function saveOpenTextRequest(P: OpenTextModel): saveOpenTextRequestAction {
        return ActionCreator(Constants.saveOpenTextRequestActionType, P);
    }
    export function saveOpenTextRequestSuccess(): saveOpenTextRequestSuccessAction {
        return ActionCreator(Constants.saveOpenTextRequestSuccessActionType, null);
    }
    export function saveOpenTextRequestFailure(): saveOpenTextRequestFailureAction {
        return ActionCreator(Constants.saveOpenTextRequestFailureActionType, null);
    }

    export type getOpenTextRequestAction = Action<typeof Constants.getOpenTextRequestActionType, string>
    export type getOpenTextRequestSuccessAction = Action<typeof Constants.getOpenTextRequestSuccessActionType, OpenTextModel>;
    export type getOpenTextRequestFailureAction = Action<typeof Constants.getOpenTextRequestFailureActionType, null>;

    export function getOpenTextRequest(P: string): getOpenTextRequestAction {
        return ActionCreator(Constants.getOpenTextRequestActionType, P);
    }
    export function getOpenTextRequestSuccess(P: OpenTextModel): getOpenTextRequestSuccessAction {
        return ActionCreator(Constants.getOpenTextRequestSuccessActionType, P);
    }
    export function getOpenTextRequestFailure(): getOpenTextRequestFailureAction {
        return ActionCreator(Constants.getOpenTextRequestFailureActionType, null);
    }

    export type getVideoAnalyticsDataRequestAction = Action<typeof Constants.getVideoAnalyticsDataRequestActionType, string>
    export type getVideoAnalyticsDataRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsDataRequestSuccessActionType, VideoAnalyticsDataModel>;
    export type getVideoAnalyticsDataRequestFailureAction = Action<typeof Constants.getVideoAnalyticsDataRequestFailureActionType, null>;

    export function getVideoAnalyticsDataRequest(P: string): getVideoAnalyticsDataRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsDataRequestActionType, P);
    }
    export function getVideoAnalyticsDataRequestSuccess(P: VideoAnalyticsDataModel): getVideoAnalyticsDataRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsDataRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsDataRequestFailure(): getVideoAnalyticsDataRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsDataRequestFailureActionType, null);
    }

    export type getVideoAnalyticsChartDataRequestAction = Action<typeof Constants.getVideoAnalyticsChartDataRequestActionType, string>
    export type getVideoAnalyticsChartDataRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsChartDataRequestSuccessActionType, VideoAnalyticsChartDataModel[]>;
    export type getVideoAnalyticsChartDataRequestFailureAction = Action<typeof Constants.getVideoAnalyticsChartDataRequestFailureActionType, null>;

    export function getVideoAnalyticsChartDataRequest(P: string): getVideoAnalyticsChartDataRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsChartDataRequestActionType, P);
    }
    export function getVideoAnalyticsChartDataRequestSuccess(P: VideoAnalyticsChartDataModel[]): getVideoAnalyticsChartDataRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsChartDataRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsChartDataRequestFailure(): getVideoAnalyticsChartDataRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsChartDataRequestFailureActionType, null);
    }

    export type generateAuthKeyRequestAction = Action<typeof Constants.generateAuthKeyRequestActionType, string>
    export type generateAuthKeyRequestSuccessAction = Action<typeof Constants.generateAuthKeyRequestSuccessActionType, string>;
    export type generateAuthKeyRequestFailureAction = Action<typeof Constants.generateAuthKeyRequestFailureActionType, null>;

    export function generateAuthKeyRequest(P: string): generateAuthKeyRequestAction {
        return ActionCreator(Constants.generateAuthKeyRequestActionType, P);
    }
    export function generateAuthKeyRequestSuccess(P: string): generateAuthKeyRequestSuccessAction {
        return ActionCreator(Constants.generateAuthKeyRequestSuccessActionType, P);
    }
    export function generateAuthKeyRequestFailure(): generateAuthKeyRequestFailureAction {
        return ActionCreator(Constants.generateAuthKeyRequestFailureActionType, null);
    }

    export type resetRedirectToLoginRequestAction = Action<typeof Constants.resetRedirectToLoginRequestActionType, null>

    export function resetRedirectToLoginRequest(): resetRedirectToLoginRequestAction {
        return ActionCreator(Constants.resetRedirectToLoginRequestActionType, null);
    }

    export type resetAuthKeyRequestAction = Action<typeof Constants.resetAuthKeyRequestActionType, null>

    export function resetAuthKeyRequest(): resetAuthKeyRequestAction {
        return ActionCreator(Constants.resetAuthKeyRequestActionType, null);
    }

    export type updateRedirectToLoginRequestAction = Action<typeof Constants.updateRedirectToLoginRequestActionType, RedirectToLoginModel>
    export type updateRedirectToLoginRequestSuccessAction = Action<typeof Constants.updateRedirectToLoginRequestSuccessActionType, null>;
    export type updateRedirectToLoginRequestFailureAction = Action<typeof Constants.updateRedirectToLoginRequestFailureActionType, null>;

    export function updateRedirectToLoginRequest(P: RedirectToLoginModel): updateRedirectToLoginRequestAction {
        return ActionCreator(Constants.updateRedirectToLoginRequestActionType, P);
    }
    export function updateRedirectToLoginRequestSuccess(): updateRedirectToLoginRequestSuccessAction {
        return ActionCreator(Constants.updateRedirectToLoginRequestSuccessActionType, null);
    }
    export function updateRedirectToLoginRequestFailure(): updateRedirectToLoginRequestFailureAction {
        return ActionCreator(Constants.updateRedirectToLoginRequestFailureActionType, null);
    }

    export type resetVideoLibraryStateRequestAction = Action<typeof Constants.resetVideoLibraryStateRequestActionType, null>
    export function resetVideoLibraryRequest(): resetVideoLibraryStateRequestAction {
        return ActionCreator(Constants.resetVideoLibraryStateRequestActionType, null);
    }

    export type savePollTemplateRequestAction = Action<typeof Constants.savePollTemplateRequestActionType, any>
    export type savePollTemplateRequestSuccessAction = Action<typeof Constants.savePollTemplateRequestSuccessActionType, null>;
    export type savePollTemplateRequestFailureAction = Action<typeof Constants.savePollTemplateRequestFailureActionType, null>;

    export function savePollTemplateRequest(P: any): savePollTemplateRequestAction {

        return ActionCreator(Constants.savePollTemplateRequestActionType, P);
    }

    export function savePollTemplateRequestSuccess(): savePollTemplateRequestSuccessAction {
        return ActionCreator(Constants.savePollTemplateRequestSuccessActionType, null);
    }
    export function savePollTemplateRequestFailure(): savePollTemplateRequestFailureAction {
        return ActionCreator(Constants.savePollTemplateRequestFailureActionType, null);
    }

    export type updateVideoAnalyticsRequestAction = Action<typeof Constants.updateVideoAnalyticsRequestActionType, string>
    export type updateVideoAnalyticsRequestSuccessAction = Action<typeof Constants.updateVideoAnalyticsRequestSuccessActionType, null>;
    export type updateVideoAnalyticsRequestFailureAction = Action<typeof Constants.updateVideoAnalyticsRequestFailureActionType, null>;

    export function updateVideoAnalyticsRequest(P: string): updateVideoAnalyticsRequestAction {
        return ActionCreator(Constants.updateVideoAnalyticsRequestActionType, P);
    }
    export function updateVideoAnalyticsRequestSuccess(): updateVideoAnalyticsRequestSuccessAction {
        return ActionCreator(Constants.updateVideoAnalyticsRequestSuccessActionType, null);
    }
    export function updateVideoAnalyticsRequestFailure(): updateVideoAnalyticsRequestFailureAction {
        return ActionCreator(Constants.updateVideoAnalyticsRequestFailureActionType, null);
    }

    export type getVideoAnalyticsLiveViewsRequestAction = Action<typeof Constants.getVideoAnalyticsLiveViewsRequestActionType, null>
    export type getVideoAnalyticsLiveViewsRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsLiveViewsRequestSuccessActionType, GetLiveViewModal[]>;
    export type getVideoAnalyticsLiveViewsRequestFailureAction = Action<typeof Constants.getVideoAnalyticsLiveViewsRequestFailureActionType, null>;

    export function getVideoAnalyticsLiveViewsRequest(): getVideoAnalyticsLiveViewsRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsRequestActionType, null);
    }
    export function getVideoAnalyticsLiveViewsRequestSuccess(P: GetLiveViewModal[]): getVideoAnalyticsLiveViewsRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsLiveViewsRequestFailure(): getVideoAnalyticsLiveViewsRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsRequestFailureActionType, null);
    }

    export type getVideoAnalyticsLiveViewsChartDataRequestAction = Action<typeof Constants.getVideoAnalyticsLiveViewsChartDataRequestActionType, null>
    export type getVideoAnalyticsLiveViewsChartDataRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsLiveViewsChartDataRequestSuccessActionType, any>;
    export type getVideoAnalyticsLiveViewsChartDataRequestFailureAction = Action<typeof Constants.getVideoAnalyticsLiveViewsChartDataRequestFailureActionType, null>;

    export function getVideoAnalyticsLiveViewsChartDataRequest(): getVideoAnalyticsLiveViewsChartDataRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsChartDataRequestActionType, null);
    }
    export function getVideoAnalyticsLiveViewsChartDataRequestSuccess(P: any): getVideoAnalyticsLiveViewsChartDataRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsChartDataRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsLiveViewsChartDataRequestFailure(): getVideoAnalyticsLiveViewsChartDataRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsLiveViewsChartDataRequestFailureActionType, null);
    }

    export type updateVideoThumbnailRequestAction = Action<typeof Constants.updateVideoThumbnailRequestActionType, UpdateThumbnailModel>
    export type updateVideoThumbnailRequestSuccessAction = Action<typeof Constants.updateVideoThumbnailRequestSuccessActionType, null>;
    export type updateVideoThumbnailRequestFailureAction = Action<typeof Constants.updateVideoThumbnailRequestFailureActionType, null>;

    export function updateVideoThumbnailRequest(P: UpdateThumbnailModel): updateVideoThumbnailRequestAction {
        return ActionCreator(Constants.updateVideoThumbnailRequestActionType, P);
    }
    export function updateVideoThumbnailRequestSuccess(): updateVideoThumbnailRequestSuccessAction {
        return ActionCreator(Constants.updateVideoThumbnailRequestSuccessActionType, null);
    }
    export function updateVideoThumbnailRequestFailure(): updateVideoThumbnailRequestFailureAction {
        return ActionCreator(Constants.updateVideoThumbnailRequestFailureActionType, null);
    }

    export type getLiveMediaRequestAction = Action<typeof Constants.getLiveMediaRequestActionType, null>
    export type getLiveMediaRequestSuccessAction = Action<typeof Constants.getLiveMediaRequestSuccessActionType, LiveMediaModel[]>;
    export type getLiveMediaRequestFailureAction = Action<typeof Constants.getLiveMediaRequestFailureActionType, null>;

    export function getLiveMediaRequest(): getLiveMediaRequestAction {
        return ActionCreator(Constants.getLiveMediaRequestActionType, null);
    }
    export function getLiveMediaRequestSuccess(P: LiveMediaModel[]): getLiveMediaRequestSuccessAction {
        return ActionCreator(Constants.getLiveMediaRequestSuccessActionType, P);
    }
    export function getLiveMediaRequestFailure(): getLiveMediaRequestFailureAction {
        return ActionCreator(Constants.getLiveMediaRequestFailureActionType, null);
    }

    export type resetAttachmentIdRequestAction = Action<typeof Constants.resetAttachmentIdRequestActionType, null>
    export function resetAttachmentIdRequest(): resetAttachmentIdRequestAction {
        return ActionCreator(Constants.resetAttachmentIdRequestActionType, null);
    }

    export type getLiveEventUrlRequestAction = Action<typeof Constants.getLiveEventUrlRequestActionType, string>
    export type getLiveEventUrlRequestSuccessAction = Action<typeof Constants.getLiveEventUrlRequestSuccessActionType, LiveEventUrlModel>;
    export type getLiveEventUrlRequestFailureAction = Action<typeof Constants.getLiveEventUrlRequestFailureActionType, null>;

    export function getLiveEventUrlRequest(P: string): getLiveEventUrlRequestAction {
        return ActionCreator(Constants.getLiveEventUrlRequestActionType, P);
    }
    export function getLiveEventUrlRequestSuccess(P: LiveEventUrlModel): getLiveEventUrlRequestSuccessAction {
        return ActionCreator(Constants.getLiveEventUrlRequestSuccessActionType, P);
    }
    export function getLiveEventUrlRequestFailure(): getLiveEventUrlRequestFailureAction {
        return ActionCreator(Constants.getLiveEventUrlRequestFailureActionType, null);
    }

    export type deletePollRequestAction = Action<typeof Constants.deletePollRequestActionType, string>
    export type deletePollRequestSuccessAction = Action<typeof Constants.deletePollRequestSuccessActionType, null>;
    export type deletePollRequestFailureAction = Action<typeof Constants.deletePollRequestFailureActionType, null>;

    export function deletePollRequest(P: string): deletePollRequestAction {
        return ActionCreator(Constants.deletePollRequestActionType, P);
    }
    export function deletePollRequestSuccess(): deletePollRequestSuccessAction {
        return ActionCreator(Constants.deletePollRequestSuccessActionType, null);
    }
    export function deletePollRequestFailure(): deletePollRequestFailureAction {
        return ActionCreator(Constants.deletePollRequestFailureActionType, null);
    }

    export type updatePollStatusRequestAction = Action<typeof Constants.updatePollStatusRequestActionType, UpdatePollStatus>
    export type updatePollStatusRequestSuccessAction = Action<typeof Constants.updatePollStatusRequestSuccessActionType, null>;
    export type updatePollStatusRequestFailureAction = Action<typeof Constants.updatePollStatusRequestFailureActionType, null>;

    export function updatePollStatusRequest(P: UpdatePollStatus): updatePollStatusRequestAction {
        return ActionCreator(Constants.updatePollStatusRequestActionType, P);
    }
    export function updatePollStatusRequestSuccess(): updatePollStatusRequestSuccessAction {
        return ActionCreator(Constants.updatePollStatusRequestSuccessActionType, null);
    }
    export function updatePollStatusRequestFailure(): updatePollStatusRequestFailureAction {
        return ActionCreator(Constants.updatePollStatusRequestFailureActionType, null);
    }

    export type getDiscountCodeRequestAction = Action<typeof Constants.getDiscountCodeRequestActionType, SearchModel>
    export type getDiscountCodeRequestSuccessAction = Action<typeof Constants.getDiscountCodeRequestSuccessActionType, PagingDataModel<DiscountCodeModel>>;
    export type getDiscountCodeRequestFailureAction = Action<typeof Constants.getDiscountCodeRequestFailureActionType, null>;

    export function getDiscountCodeRequest(P: SearchModel): getDiscountCodeRequestAction {
        return ActionCreator(Constants.getDiscountCodeRequestActionType, P);
    }
    export function getDiscountCodeRequestSuccess(P: PagingDataModel<DiscountCodeModel>): getDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.getDiscountCodeRequestSuccessActionType, P);
    }
    export function getDiscountCodeRequestFailure(): getDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.getDiscountCodeRequestFailureActionType, null);
    }

    export type saveDiscountCodeRequestAction = Action<typeof Constants.saveDiscountCodeRequestActionType, DiscountCodeModel>
    export type saveDiscountCodeRequestSuccessAction = Action<typeof Constants.saveDiscountCodeRequestSuccessActionType, null>;
    export type saveDiscountCodeRequestFailureAction = Action<typeof Constants.saveDiscountCodeRequestFailureActionType, null>;

    export function saveDiscountCodeRequest(P: DiscountCodeModel): saveDiscountCodeRequestAction {
        return ActionCreator(Constants.saveDiscountCodeRequestActionType, P);
    }
    export function saveDiscountCodeRequestSuccess(): saveDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.saveDiscountCodeRequestSuccessActionType, null);
    }
    export function saveDiscountCodeRequestFailure(): saveDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.saveDiscountCodeRequestFailureActionType, null);
    }

    export type updateDiscountCodeRequestAction = Action<typeof Constants.updateDiscountCodeRequestActionType, UpdateDiscountCodeModel>
    export type updateDiscountCodeRequestSuccessAction = Action<typeof Constants.updateDiscountCodeRequestSuccessActionType, null>;
    export type updateDiscountCodeRequestFailureAction = Action<typeof Constants.updateDiscountCodeRequestFailureActionType, null>;

    export function updateDiscountCodeRequest(P: UpdateDiscountCodeModel): updateDiscountCodeRequestAction {
        return ActionCreator(Constants.updateDiscountCodeRequestActionType, P);
    }
    export function updateDiscountCodeRequestSuccess(): updateDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.updateDiscountCodeRequestSuccessActionType, null);
    }
    export function updateDiscountCodeRequestFailure(): updateDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.updateDiscountCodeRequestFailureActionType, null);
    }

    export type deleteDiscountCodesRequestAction = Action<typeof Constants.deleteDiscountCodesRequestActionType, number>
    export type deleteDiscountCodesRequestSuccessAction = Action<typeof Constants.deleteDiscountCodesRequestSuccessActionType, null>;
    export type deleteDiscountCodesRequestFailureAction = Action<typeof Constants.deleteDiscountCodesRequestFailureActionType, null>;

    export function deleteDiscountCodesRequest(P: number): deleteDiscountCodesRequestAction {
        return ActionCreator(Constants.deleteDiscountCodesRequestActionType, P);
    }
    export function deleteDiscountCodesRequestSuccess(): deleteDiscountCodesRequestSuccessAction {
        return ActionCreator(Constants.deleteDiscountCodesRequestSuccessActionType, null);
    }
    export function deleteDiscountCodesRequestFailure(): deleteDiscountCodesRequestFailureAction {
        return ActionCreator(Constants.deleteDiscountCodesRequestFailureActionType, null);
    }

    export type getSingleDiscountCodeRequestAction = Action<typeof Constants.getSingleDiscountCodeRequestActionType, number>
    export type getSingleDiscountCodeRequestSuccessAction = Action<typeof Constants.getSingleDiscountCodeRequestSuccessActionType, DiscountCodeModel>;
    export type getSingleDiscountCodeRequestFailureAction = Action<typeof Constants.getSingleDiscountCodeRequestFailureActionType, null>;

    export function getSingleDiscountCodeRequest(P: number): getSingleDiscountCodeRequestAction {
        return ActionCreator(Constants.getSingleDiscountCodeRequestActionType, P);
    }
    export function getSingleDiscountCodeRequestSuccess(P: DiscountCodeModel): getSingleDiscountCodeRequestSuccessAction {
        return ActionCreator(Constants.getSingleDiscountCodeRequestSuccessActionType, P);
    }
    export function getSingleDiscountCodeRequestFailure(): getSingleDiscountCodeRequestFailureAction {
        return ActionCreator(Constants.getSingleDiscountCodeRequestFailureActionType, null);
    }


    export type startMediaChannelRequestAction = Action<typeof Constants.startMediaChannelRequestActionType, any>
    export type startMediaChannelRequestSuccessAction = Action<typeof Constants.startMediaChannelRequestSuccessActionType, null>;
    export type startMediaChannelRequestFailureAction = Action<typeof Constants.startMediaChannelRequestFailureActionType, null>;

    export function startMediaChannelRequest(P: any): startMediaChannelRequestAction {
        return ActionCreator(Constants.startMediaChannelRequestActionType, P);
    }
    export function startMediaChannelRequestSuccess(): startMediaChannelRequestSuccessAction {
        return ActionCreator(Constants.startMediaChannelRequestSuccessActionType, null);
    }
    export function startMediaChannelRequestFailure(): startMediaChannelRequestFailureAction {
        return ActionCreator(Constants.startMediaChannelRequestFailureActionType, null);
    }

    export type startMediaStreamRequestAction = Action<typeof Constants.startMediaStreamRequestActionType, any>
    export type startMediaStreamRequestSuccessAction = Action<typeof Constants.startMediaStreamRequestSuccessActionType, any>;
    export type startMediaStreamRequestFailureAction = Action<typeof Constants.startMediaStreamRequestFailureActionType, null>;

    export function startMediaStreamRequest(P: any): startMediaStreamRequestAction {
        return ActionCreator(Constants.startMediaStreamRequestActionType, P);
    }
    export function startMediaStreamRequestSuccess(P: any): startMediaStreamRequestSuccessAction {
        return ActionCreator(Constants.startMediaStreamRequestSuccessActionType, P);
    }
    export function startMediaStreamRequestFailure(): startMediaStreamRequestFailureAction {
        return ActionCreator(Constants.startMediaStreamRequestFailureActionType, null);
    }

    export type stopMediaStreamRequestAction = Action<typeof Constants.stopMediaStreamRequestActionType, any>
    export type stopMediaStreamRequestSuccessAction = Action<typeof Constants.stopMediaStreamRequestSuccessActionType, any>;
    export type stopMediaStreamRequestFailureAction = Action<typeof Constants.stopMediaStreamRequestFailureActionType, null>;

    export function stopMediaStreamRequest(P: any): stopMediaStreamRequestAction {
        return ActionCreator(Constants.stopMediaStreamRequestActionType, P);
    }
    export function stopMediaStreamRequestSuccess(P: any): stopMediaStreamRequestSuccessAction {
        return ActionCreator(Constants.stopMediaStreamRequestSuccessActionType, P);
    }
    export function stopMediaStreamRequestFailure(): stopMediaStreamRequestFailureAction {
        return ActionCreator(Constants.stopMediaStreamRequestFailureActionType, null);
    }

    export type mediaChannelStatusRequestAction = Action<typeof Constants.mediaChannelStatusRequestActionType, any>
    export type mediaChannelStatusRequestSuccessAction = Action<typeof Constants.mediaChannelStatusRequestSuccessActionType, any>;
    export type mediaChannelStatusRequestFailureAction = Action<typeof Constants.mediaChannelStatusRequestFailureActionType, null>;

    export function mediaChannelStatusRequest(P: any): mediaChannelStatusRequestAction {
        return ActionCreator(Constants.mediaChannelStatusRequestActionType, P);
    }
    export function mediaChannelStatusRequestSuccess(P: any): mediaChannelStatusRequestSuccessAction {
        return ActionCreator(Constants.mediaChannelStatusRequestSuccessActionType, P);
    }
    export function mediaChannelStatusRequestFailure(): mediaChannelStatusRequestFailureAction {
        return ActionCreator(Constants.mediaChannelStatusRequestFailureActionType, null);
    }

    export type updateVideoStatusRequestAction = Action<typeof Constants.updateVideoStatusRequestActionType, UpdateVideoStatusModel>
    export type updateVideoStatusRequestSuccessAction = Action<typeof Constants.updateVideoStatusRequestSuccessActionType, null>;
    export type updateVideoStatusRequestFailureAction = Action<typeof Constants.updateVideoStatusRequestFailureActionType, null>;

    export function updateVideoStatusRequest(P: UpdateVideoStatusModel): updateVideoStatusRequestAction {
        return ActionCreator(Constants.updateVideoStatusRequestActionType, P);
    }
    export function updateVideoStatusRequestSuccess(): updateVideoStatusRequestSuccessAction {
        return ActionCreator(Constants.updateVideoStatusRequestSuccessActionType, null);
    }
    export function updateVideoStatusRequestFailure(): updateVideoStatusRequestFailureAction {
        return ActionCreator(Constants.updateVideoStatusRequestFailureActionType, null);
    }

    export type getVideoStatusRequestAction = Action<typeof Constants.getVideoStatusRequestActionType, string>
    export type getVideoStatusRequestSuccessAction = Action<typeof Constants.getVideoStatusRequestSuccessActionType, VideoStatus>;
    export type getVideoStatusRequestFailureAction = Action<typeof Constants.getVideoStatusRequestFailureActionType, null>;

    export function getVideoStatusRequest(P: string): getVideoStatusRequestAction {
        return ActionCreator(Constants.getVideoStatusRequestActionType, P);
    }
    export function getVideoStatusRequestSuccess(P: VideoStatus): getVideoStatusRequestSuccessAction {
        return ActionCreator(Constants.getVideoStatusRequestSuccessActionType, P);
    }
    export function getVideoStatusRequestFailure(): getVideoStatusRequestFailureAction {
        return ActionCreator(Constants.getVideoStatusRequestFailureActionType, null);
    }

    export type getVideoModulesRequestAction = Action<typeof Constants.getVideoModulesRequestActionType, null>
    export type getVideoModulesRequestSuccessAction = Action<typeof Constants.getVideoModulesRequestSuccessActionType, VideoModuleModel[]>;
    export type getVideoModulesRequestFailureAction = Action<typeof Constants.getVideoModulesRequestFailureActionType, null>;

    export function getVideoModulesRequest(): getVideoModulesRequestAction {
        return ActionCreator(Constants.getVideoModulesRequestActionType, null);
    }
    export function getVideoModulesRequestSuccess(P: VideoModuleModel[]): getVideoModulesRequestSuccessAction {
        return ActionCreator(Constants.getVideoModulesRequestSuccessActionType, P);
    }
    export function getVideoModulesRequestFailure(): getVideoModulesRequestFailureAction {
        return ActionCreator(Constants.getVideoModulesRequestFailureActionType, null);
    }

    export type startLiveMeetingRequestAction = Action<typeof Constants.startLiveMeetingRequestActionType, StartLiveMeetingModel>
    export type startLiveMeetingRequestSuccessAction = Action<typeof Constants.startLiveMeetingRequestSuccessActionType, LiveMeetingModel>;
    export type startLiveMeetingRequestFailureAction = Action<typeof Constants.startLiveMeetingRequestFailureActionType, null>;

    export function startLiveMeetingRequest(P: StartLiveMeetingModel): startLiveMeetingRequestAction {
        return ActionCreator(Constants.startLiveMeetingRequestActionType, P);
    }
    export function startLiveMeetingRequestSuccess(P: LiveMeetingModel): startLiveMeetingRequestSuccessAction {
        return ActionCreator(Constants.startLiveMeetingRequestSuccessActionType, P);
    }
    export function startLiveMeetingRequestFailure(): startLiveMeetingRequestFailureAction {
        return ActionCreator(Constants.startLiveMeetingRequestFailureActionType, null);
    }

    export type endLiveMeetingRequestAction = Action<typeof Constants.endLiveMeetingRequestActionType, StartLiveMeetingModel>
    export type endLiveMeetingRequestSuccessAction = Action<typeof Constants.endLiveMeetingRequestSuccessActionType, LiveMeetingModel>;
    export type endLiveMeetingRequestFailureAction = Action<typeof Constants.endLiveMeetingRequestFailureActionType, null>;

    export function endLiveMeetingRequest(P: StartLiveMeetingModel): endLiveMeetingRequestAction {
        return ActionCreator(Constants.endLiveMeetingRequestActionType, P);
    }
    export function endLiveMeetingRequestSuccess(P: LiveMeetingModel): endLiveMeetingRequestSuccessAction {
        return ActionCreator(Constants.endLiveMeetingRequestSuccessActionType, P);
    }
    export function endLiveMeetingRequestFailure(): endLiveMeetingRequestFailureAction {
        return ActionCreator(Constants.endLiveMeetingRequestFailureActionType, null);
    }

    export type enterLiveStudioRequestAction = Action<typeof Constants.enterLiveStudioRequestActionType, StartLiveMeetingModel>
    export type enterLiveStudioRequestSuccessAction = Action<typeof Constants.enterLiveStudioRequestSuccessActionType, EnterLiveMeetingModel>;
    export type enterLiveStudioRequestFailureAction = Action<typeof Constants.enterLiveStudioRequestFailureActionType, null>;

    export function enterLiveStudioRequest(P: StartLiveMeetingModel): enterLiveStudioRequestAction {
        return ActionCreator(Constants.enterLiveStudioRequestActionType, P);
    }
    export function enterLiveStudioRequestSuccess(P: EnterLiveMeetingModel): enterLiveStudioRequestSuccessAction {
        return ActionCreator(Constants.enterLiveStudioRequestSuccessActionType, P);
    }
    export function enterLiveStudioRequestFailure(): enterLiveStudioRequestFailureAction {
        return ActionCreator(Constants.enterLiveStudioRequestFailureActionType, null);
    }

    export type resetEnterLiveStudioRequestAction = Action<typeof Constants.resetEnterLiveStudioRequestActionType, null>
    export function resetEnterLiveStudioRequest(): resetEnterLiveStudioRequestAction {
        return ActionCreator(Constants.resetEnterLiveStudioRequestActionType, null);
    }

    export type getProductAccessRequestAction = Action<typeof Constants.getProductAccessRequestActionType, null>
    export type getProductAccessRequestSuccessAction = Action<typeof Constants.getProductAccessRequestSuccessActionType, ProductAccessLiveMeetingModel>;
    export type getProductAccessRequestFailureAction = Action<typeof Constants.getProductAccessRequestFailureActionType, null>;

    export function getProductAccessRequest(): getProductAccessRequestAction {
        return ActionCreator(Constants.getProductAccessRequestActionType, null);
    }
    export function getProductAccessRequestSuccess(P: ProductAccessLiveMeetingModel): getProductAccessRequestSuccessAction {
        return ActionCreator(Constants.getProductAccessRequestSuccessActionType, P);
    }
    export function getProductAccessRequestFailure(): getProductAccessRequestFailureAction {
        return ActionCreator(Constants.getProductAccessRequestFailureActionType, null);
    }

    export type getVideoAnalyticsTableRequestAction = Action<typeof Constants.getVideoAnalyticsTableRequestActionType, VideoAnalyticsFilterModel>
    export type getVideoAnalyticsTableRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsTableRequestSuccessActionType, PagingDataModel<VideoAnalyticsTableModel>>;
    export type getVideoAnalyticsTableRequestFailureAction = Action<typeof Constants.getVideoAnalyticsTableRequestFailureActionType, null>;

    export function getVideoAnalyticsTableRequest(P: VideoAnalyticsFilterModel): getVideoAnalyticsTableRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsTableRequestActionType, P);
    }
    export function getVideoAnalyticsTableRequestSuccess(P: PagingDataModel<VideoAnalyticsTableModel>): getVideoAnalyticsTableRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsTableRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsTableRequestFailure(): getVideoAnalyticsTableRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsTableRequestFailureActionType, null);
    }

    export type getVideoAnalyticsTableDownloadRequestAction = Action<typeof Constants.getVideoAnalyticsTableDownloadRequestActionType, VideoAnalyticsFilterModel>
    export type getVideoAnalyticsTableDownloadRequestSuccessAction = Action<typeof Constants.getVideoAnalyticsTableDownloadRequestSuccessActionType, VideoAnalyticsTableModel[]>;
    export type getVideoAnalyticsTableDownloadRequestFailureAction = Action<typeof Constants.getVideoAnalyticsTableDownloadRequestFailureActionType, null>;

    export function getVideoAnalyticsTableDownloadRequest(P: VideoAnalyticsFilterModel): getVideoAnalyticsTableDownloadRequestAction {
        return ActionCreator(Constants.getVideoAnalyticsTableDownloadRequestActionType, P);
    }
    export function getVideoAnalyticsTableDownloadRequestSuccess(P: VideoAnalyticsTableModel[]): getVideoAnalyticsTableDownloadRequestSuccessAction {
        return ActionCreator(Constants.getVideoAnalyticsTableDownloadRequestSuccessActionType, P);
    }
    export function getVideoAnalyticsTableDownloadRequestFailure(): getVideoAnalyticsTableDownloadRequestFailureAction {
        return ActionCreator(Constants.getVideoAnalyticsTableDownloadRequestFailureActionType, null);
    }
    export type removeMediaUrlRequestAction = Action<typeof Constants.removeMediaUrlRequestActionType, string>
    export type removeMediaUrlRequestSuccessAction = Action<typeof Constants.removeMediaUrlRequestSuccessActionType, string>;
    export type removeMediaUrlRequestFailureAction = Action<typeof Constants.removeMediaUrlRequestFailureActionType, null>;

    export function removeMediaUrlRequest(P: string): removeMediaUrlRequestAction {
        return ActionCreator(Constants.removeMediaUrlRequestActionType, P);
    }
    export function removeMediaUrlRequestSuccess(P: string): removeMediaUrlRequestSuccessAction {
        return ActionCreator(Constants.removeMediaUrlRequestSuccessActionType, P);
    }
    export function removeMediaUrlRequestFailure(): removeMediaUrlRequestFailureAction {
        return ActionCreator(Constants.removeMediaUrlRequestFailureActionType, null);
    }

    export type getMediaUrlRequestAction = Action<typeof Constants.getMediaUrlRequestActionType, string>
    export type getMediaUrlRequestSuccessAction = Action<typeof Constants.getMediaUrlRequestSuccessActionType, string>;
    export type getMediaUrlRequestFailureAction = Action<typeof Constants.getMediaUrlRequestFailureActionType, null>;

    export function getMediaUrlRequest(P: string): getMediaUrlRequestAction {
        return ActionCreator(Constants.getMediaUrlRequestActionType, P);
    }
    export function getMediaUrlRequestSuccess(P: string): getMediaUrlRequestSuccessAction {
        return ActionCreator(Constants.getMediaUrlRequestSuccessActionType, P);
    }
    export function getMediaUrlRequestFailure(): getMediaUrlRequestFailureAction {
        return ActionCreator(Constants.getMediaUrlRequestFailureActionType, null);
    }

    export type getLiveViewersRequestAction = Action<typeof Constants.getLiveViewersRequestActionType, string>
    export type getLiveViewersRequestSuccessAction = Action<typeof Constants.getLiveViewersRequestSuccessActionType, LiveViewersModel[]>;
    export type getLiveViewersRequestFailureAction = Action<typeof Constants.getLiveViewersRequestFailureActionType, null>;

    export function getLiveViewersRequest(P: string): getLiveViewersRequestAction {
        return ActionCreator(Constants.getLiveViewersRequestActionType, P);
    }
    export function getLiveViewersRequestSuccess(P: LiveViewersModel[]): getLiveViewersRequestSuccessAction {
        return ActionCreator(Constants.getLiveViewersRequestSuccessActionType, P);
    }
    export function getLiveViewersRequestFailure(): getLiveViewersRequestFailureAction {
        return ActionCreator(Constants.getLiveViewersRequestFailureActionType, null);
    }

    export type getLiveViewersCountRequestAction = Action<typeof Constants.getLiveViewersCountRequestActionType, string>
    export type getLiveViewersCountRequestSuccessAction = Action<typeof Constants.getLiveViewersCountRequestSuccessActionType, number>;
    export type getLiveViewersCountRequestFailureAction = Action<typeof Constants.getLiveViewersCountRequestFailureActionType, null>;

    export function getLiveViewersCountRequest(P: string): getLiveViewersCountRequestAction {
        return ActionCreator(Constants.getLiveViewersCountRequestActionType, P);
    }
    export function getLiveViewersCountRequestSuccess(P: number): getLiveViewersCountRequestSuccessAction {
        return ActionCreator(Constants.getLiveViewersCountRequestSuccessActionType, P);
    }
    export function getLiveViewersCountRequestFailure(): getLiveViewersCountRequestFailureAction {
        return ActionCreator(Constants.getLiveViewersCountRequestFailureActionType, null);
    }

    export type updateLiveStreamSourceRequestAction = Action<typeof Constants.updateLiveStreamSourceRequestActionType, UpdateLiveStreamSourceModel>
    export type updateLiveStreamSourceRequestSuccessAction = Action<typeof Constants.updateLiveStreamSourceRequestSuccessActionType, null>;
    export type updateLiveStreamSourceRequestFailureAction = Action<typeof Constants.updateLiveStreamSourceRequestFailureActionType, null>;

    export function updateLiveStreamSourceRequest(P: UpdateLiveStreamSourceModel): updateLiveStreamSourceRequestAction {
        return ActionCreator(Constants.updateLiveStreamSourceRequestActionType, P);
    }
    export function updateLiveStreamSourceRequestSuccess(): updateLiveStreamSourceRequestSuccessAction {
        return ActionCreator(Constants.updateLiveStreamSourceRequestSuccessActionType, null);
    }
    export function updateLiveStreamSourceRequestFailure(): updateLiveStreamSourceRequestFailureAction {
        return ActionCreator(Constants.updateLiveStreamSourceRequestFailureActionType, null);
    }

    export type getArchiveUrlRequestAction = Action<typeof Constants.getArchiveUrlRequestActionType, string>
    export type getArchiveUrlRequestSuccessAction = Action<typeof Constants.getArchiveUrlRequestSuccessActionType, string>;
    export type getArchiveUrlRequestFailureAction = Action<typeof Constants.getArchiveUrlRequestFailureActionType, null>;

    export function getArchiveUrlRequest(P: string): getArchiveUrlRequestAction {
        return ActionCreator(Constants.getArchiveUrlRequestActionType, P);
    }
    export function getArchiveUrlRequestSuccess(P: string): getArchiveUrlRequestSuccessAction {
        return ActionCreator(Constants.getArchiveUrlRequestSuccessActionType, P);
    }
    export function getArchiveUrlRequestFailure(): getArchiveUrlRequestFailureAction {
        return ActionCreator(Constants.getArchiveUrlRequestFailureActionType, null);
    }

}

export type VideosActions = Omit<typeof VideosActions, 'Type'>;
export const useVideosActions = (dispatch: Dispatch) => {
    const { ...actions } = VideosActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as VideosActions;
};