import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalyticsActions } from 'src/store/analytics/actions';
import Loader from 'src/components/loader';
import { defaultDateFormatter } from 'src/components/utilities/utils';
import { useParams, Link } from 'react-router-dom';
import Pagination from 'src/components/common/pagination';
import { DebounceInput } from 'react-debounce-input';
import { formatDate } from 'src/components/utilities/utils';
const SingleAnalyticsCourse: React.FC = () => {
  const dispatch = useDispatch();
  const analyticsAction = useAnalyticsActions(dispatch);
  const { id } = useParams<string>();
  const {
    viewActivity,
    singleCourseResult,
    isSingleCourseResultLoading,
    recentGrade,
    paginationMeta
  } = useSelector((state: RootState) => {
    return {
      viewActivity: state.analytics.viewActivity,
      singleCourseResult: state.analytics.singleCourseResult,
      isSingleCourseResultLoading: state.analytics.isSingleCourseResultLoading,
      recentGrade: state.analytics.recentGrade,
      paginationMeta: state.analytics.paginationMeta
    };
  });
  const [searchValue, setSearchValue] = useState<string>('');
  const [barChartData, setBarChartData] = useState({
    labels: recentGrade && recentGrade.map((data) => data.month),
    datasets: [
      {
        label: 'Total Range',
        data: recentGrade && recentGrade.map((data) => data.range),
        backgroundColor: ['#2A71d0']
      }
    ]
  });

  useEffect(() => {
    setBarChartData({
      labels: recentGrade && recentGrade.map((data) => data.month),
      datasets: [
        {
          label: 'Total Range',
          data: recentGrade && recentGrade.map((data) => data.range),
          backgroundColor: ['#82B5D0']
        },
        {
          label: '25th-75th Percentile',
          data: recentGrade && recentGrade.map((data) => data.percentile),
          backgroundColor: ['#1A92FF']
        },
        {
          label: 'Median',
          data: recentGrade && recentGrade.map((data) => data.median),
          backgroundColor: ['#ADD8E6']
        }
      ]
    });
  }, [recentGrade]);

  useEffect(() => {
    if (id) {
      analyticsAction.getSingleCourseResultRequest({
        id,
        querySearch: '',
        pageNumber: 1,
        pageSize: 10
      });
    }
    analyticsAction.getRecentGradeRequest();
  }, [id]);

  const handlePageChange = (pageNumber: number) => {
    analyticsAction.getSingleCourseResultRequest({
      id,
      querySearch: searchValue,
      pageNumber,
      pageSize: 10
    });
  };

  const handleSearchCourse = (e: string) => {
    setSearchValue(e);
    analyticsAction.getSingleCourseResultRequest({
      id,
      querySearch: e,
      pageNumber: 1,
      pageSize: 10
    });
  };
  return (
    <>
      <div className="dashboard">
        <Link to={`/analytic/course`}>
          <i className="fas fa-arrow-left mt-2 mb-2"></i>
          <span className="ml-2">Go Back</span>
        </Link>
        <div className="flex">
          <h2 className="font-semibold ">Course Analytics</h2>
        </div>
        <div className="mt-4 members-list">
          {/* <div className={`dashboard-card my-5 `}>
            <BarChart chartData={barChartData} />
          </div> */}

          <div className="mt-5">
            <div className=" py-4 border-b border-gray-100 md:flex justify-between items-center">
              <h2 className="font-semibold ">
                {singleCourseResult && singleCourseResult.length
                  ? `${singleCourseResult && singleCourseResult[0]?.courseName}`
                  : ''}
              </h2>
              <div className="md:flex items-center text-center">
                <div className="form-group md:flex items-center text-center">
                  <label className="mr-1 font-semibold">Search:</label>
                  <DebounceInput
                    placeholder="Search"
                    minLength={1}
                    className="input-style w-48 mr-3 mb-1"
                    debounceTimeout={500}
                    onChange={(event) => handleSearchCourse(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center h-full">
              <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
                <div className="p-3">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border">
                      <thead className="text-xs font-semibold uppercase bg-gray-50">
                        <tr>
                          <th className="p-2">
                            <div className="font-semibold text-left">Member</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Pass Rate</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credits Available</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credits Achieved</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credit Type</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Credits Sent</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Score %</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Points</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Points Earned</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Videos</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Videos Attended</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Questions</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Answered</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Total Correct</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Completion Date</div>
                          </th>
                          <th className="p-2">
                            <div className="font-semibold text-left">Submitted Date</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-gray-100">
                        {isSingleCourseResultLoading ? (
                          <tr>
                            <td>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {singleCourseResult && singleCourseResult.length ? (
                              <>
                                {singleCourseResult &&
                                  singleCourseResult.map((item) => {
                                    return (
                                      <tr key={item.id}>
                                        <td className="p-2">
                                          <div className="text-left">{item.userName}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.courseCompletionPercentage}%
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.creditsAvailable}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.creditsAchieved}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.courseCreditTypes}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.creditsSent ? (
                                              <i className="fa fa-check text-danger text-lg text-green-500"></i>
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.score}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.pointsPossible}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.pointsEarned}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalVideos}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.totalVideosAttended}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalQuestions}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{item.totalAnswered}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.totalCorrectAnswered}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.completionDate
                                              ? formatDate(item.completionDate)
                                              : ''}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {item.submittedDate
                                              ? formatDate(item.submittedDate)
                                              : ''}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </>
                            ) : (
                              <tr>
                                <td>No Data Found</td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>

                    {!!paginationMeta && !!Object.keys(paginationMeta).length && (
                      <Pagination
                        currentPage={paginationMeta.pageNumber}
                        totalCount={paginationMeta.totalCount}
                        pageSize={paginationMeta.pageSize}
                        onPageChange={(page: any) => handlePageChange(page)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleAnalyticsCourse;
