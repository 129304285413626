import React, { useEffect, useState } from 'react';
import { useVideosActions, VideosActions } from 'src/store/videos/actions';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import { useDispatch, useSelector } from 'react-redux';
import { defaultDateFormatter } from 'src/components/utilities/utils';
interface IProps {
  videoId: string;
}
const QuestionsModal: React.FC<IProps> = ({ videoId }) => {
  const { id } = useParams<string>();
  const dispatch = useDispatch();
  const videoAction = useVideosActions(dispatch);
  const [tab, setTab] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const { questions } = useSelector((state: RootState) => {
    return {
      questions: state.videos.questions
    };
  });

  const handleOptionClick = (questionId: string, value: number) => {
    setSelectedValue(value);
    if (id) {
      const payloadData = {
        questionId,
        updateData: value
      };
      videoAction.updateQuestionRequest(payloadData);
    }
  };
  useEffect(() => {
    if (selectedValue) {
      const timer = setTimeout(() => {
        id && videoAction.getQuestionRequest({ videoId: id, questionType: tab });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [selectedValue]);
  return (
    <div>
      <div
        className="modal fade"
        id="QuestionsModal"
        aria-labelledby="QuestionsModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="QuestionsModalLabel">
                Questions
              </h5>
              <button
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <ul
              id="tabs-tab"
              role="tablist"
              className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(0), id && videoAction.getQuestionRequest({ videoId: id, questionType: 0 });
                }}>
                <span
                  className={`${
                    tab === 0 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  New
                </span>
              </li>
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(1), id && videoAction.getQuestionRequest({ videoId: id, questionType: 1 });
                }}>
                <span
                  className={`${
                    tab === 1 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  Approved
                </span>
              </li>
              <li
                className="me-2 cursor-pointer"
                onClick={() => {
                  setTab(2), id && videoAction.getQuestionRequest({ videoId: id, questionType: 2 });
                }}>
                <span
                  className={`${
                    tab === 2 ? 'text-blue-600 bg-gray-100 dark:bg-gray-800' : ''
                  }nav-link tabs-link inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300`}>
                  Archived
                </span>
              </li>
            </ul>

            <div className="modal-body relative py-4 px-10">
              {questions && questions.length ? (
                <>
                  {questions &&
                    questions.map((item) => {
                      return (
                        <div key={item.id}>
                          <div className="flex justify-between">
                            <strong>{item.usernameInput}</strong>
                            <div className="text-left">
                              <div className="dropdown relative">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  id="action-dropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <img
                                    src="../../assets/img/dots-vertical.svg"
                                    className="w-5 h-5"
                                  />
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-ul"
                                  aria-labelledby="action-dropdown">
                                  <li onClick={() => handleOptionClick(item.id, 1)}>
                                    <button className="action-menu flex items-center">
                                      <span>Approved</span>
                                    </button>
                                  </li>
                                  <li onClick={() => handleOptionClick(item.id, 2)}>
                                    <button className="action-menu flex items-center">
                                      <span>Archived</span>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="text-blue-600">{item.userEmailInput}</div>
                          <small className="float-right">
                            {item.createdUTC ? defaultDateFormatter(item.createdUTC) : ''}
                          </small>
                          <p>{item.message}</p>
                          <hr className="mt-3" />
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="p-4 text-center">
                  <p>No Questions Find</p>
                </div>
              )}
            </div>

            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
              <button
                type="button"
                className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsModal;
