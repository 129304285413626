import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import './styles.scss';
import { useVideosActions } from 'src/store/videos/actions';
import { getVideoStatus, getVideoSource } from 'src/components/utilities/utils';
interface Screen2Props {
  isLiveLoading: boolean;
  setIsLiveLoading: any;
}
interface dataProps {
  serverUrl: string;
  streamKey: string;
}

const Screen2: React.FC<Screen2Props> = ({ setIsLiveLoading }) => {
  const [data, setData] = useState<dataProps>({ serverUrl: '', streamKey: '' });
  const dispatch = useDispatch();
  const { id } = useParams<string>();
  const videoAction = useVideosActions(dispatch);
  const localData = localStorage.getItem('userData');
  const userData = localData && JSON.parse(localData);
  const { liveEventUrl, mediaChannelStatus, videoStatus, singleVideo } = useSelector(
    (state: RootState) => {
      return {
        liveEventUrl: state.videos.liveEventUrl,
        mediaChannelStatus: state.videos.mediaChannelStatus,
        videoStatus: state.videos.videoStatus,
        singleVideo: state.videos.singleVideo
      };
    }
  );
  const handleEnterLiveStudioClick = () => {
    id && videoAction.enterLiveStudioRequest({ videoId: id });
  };
  useEffect(() => {
    const interval = setTimeout(() => {
      setIsLiveLoading(false);
    }, 7000);
    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    if (liveEventUrl) {
      setData({ serverUrl: liveEventUrl.rtmpIngestURL, streamKey: liveEventUrl.streamKey });
    }
  }, [liveEventUrl]);

  return (
    <div>
      <div className="ibox-content ">
        <div id="rightMenuContentMainDiv" className="">
          <div
            id="subData2"
            className="flex justify-center items-center flex-col text-sm font-normal">
            {mediaChannelStatus && mediaChannelStatus === 'STARTING' && (
              <div>
                <h3 className="text-black text-base font-semibold text-center">
                  Preparing your Live Stream Channel
                </h3>
                <div className="flex justify-center items-center flex-col italic">
                  <div>This can take around 30-40 seconds. Please do not</div>
                  <div className="flex justify-center items-center flex-col">
                    refresh your page.
                  </div>
                </div>

                <section className="talign-center">
                  <div className="spinner spinner--steps icon-spinner" aria-hidden="true"></div>
                </section>
              </div>
            )}
            {mediaChannelStatus === 'RUNNING' && getVideoStatus(videoStatus) === 'Standby' && (
              <div id="subData3" className="max-w-lg">
                <div className="text-left">
                  {getVideoSource(singleVideo.videoSourceId) === 'StreamingSoftware' && (
                    <div>
                      Copy the <b>Server URL</b> and <b>Stream Key</b> below into your broadcast
                      application.
                      <br />
                      When you are ready, select <b>Go Live</b> first from your broadcast software (
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406439082127-Live-Stream-to-webcastcloud-Using-Zoom"
                        rel="noreferrer"
                        target="_blank">
                        Zoom
                      </a>
                      ,
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406438785167-Create-a-Live-Stream-with-any-RTMP-source-"
                        rel="noreferrer"
                        target="_blank">
                        OBS Studio
                      </a>
                      ,
                      <a
                        href="https://support.webcastcloud.com/hc/en-us/articles/4406452711567-Live-Stream-to-webcastcloud-Using-StreamYard"
                        rel="noreferrer"
                        target="_blank">
                        StreamYard
                      </a>{' '}
                      etc).
                      <br />
                      Once your applications starts pushing your live stream to webcastcloud, you
                      can then select <b>Go Live</b> from here. Your viewers will then be watching
                      you live stream online.
                    </div>
                  )}

                  <br />
                  {userData?.role != 'Studio Guest' ? (
                    <>
                      {' '}
                      <h5 className="text-base mb-2 text-black-600">
                        Media Live settings - information only. When using Webcam in Live Studio, no
                        action is required
                      </h5>
                      <h3 className="text-base font-semibold text-black mb-2">
                        Primary Live Media
                      </h3>
                      <div className="row form-group">
                        <div className="col-12">
                          <span className="text-xs font-normal">Server URL</span>
                          <div className="primary-media-wrapper">
                            <input
                              type="text"
                              className="primary-media-input"
                              id="txtPrimaryStreamURL"
                              value={data.serverUrl}
                              readOnly
                            />
                            <CopyToClipboard text={data.serverUrl}>
                              <button className="primary-media-btn">
                                <i className="fa fa-copy"></i>
                                <span>Copy</span>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                        <div className="col-12">
                          <span className="text-xs font-normal">Stream Key</span>
                          <div className="primary-media-wrapper">
                            <input
                              type="text"
                              className="primary-media-input"
                              id="txtPrimaryName"
                              value={data.streamKey}
                              readOnly
                            />
                            <CopyToClipboard text={data.streamKey}>
                              <button className="primary-media-btn">
                                <i className="fa fa-copy"></i>
                                <span>Copy</span>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <a
                        className="btn start-live-stream pull-right green-background"
                        onClick={handleEnterLiveStudioClick}>
                        Enter Green Room <span id="count" className="d-none"></span>
                      </a>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Screen2;
