import React, { useState } from 'react';

const ChannelSideMenu = () => {
  const [showVideoInfo, setShowVideoInfo] = useState(true);

  return (
    <div
      className={`border pl-0 lg:w-1/5 lg:mr-5 shadow ${
        showVideoInfo ? 'hide-lists' : 'show-lists'
      }`}>
      <div className="mobile-dropdown" onClick={() => setShowVideoInfo(!showVideoInfo)}>
        <div className="border p-2 rounded-lg m-2 cursor-pointer flex align-items-center justify-between">
          <p>Video Channel Layout</p>
          <i className="fas fa-chevron-down text-gray-500 mt-1"></i>
        </div>
      </div>
      <ul
        className="nav nav-tabs flex lg:flex-col lg:flex-wrap list-none pl-0 overflow-hidden mobile-layout-ul"
        id="tabs-tabVertical"
        role="tablist">
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-channelInfo"
            className="nav-link my-0 tabs-link pl-3 flex items-center active"
            data-bs-toggle="pill"
            data-bs-target="#tabs-channelInfo"
            role="tab"
            aria-controls="tabs-channelInfo"
            aria-selected="false">
            Channel Info
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-channelStyles"
            className="nav-link my-0 tabs-link pl-3 flex items-center "
            data-bs-toggle="pill"
            data-bs-target="#tabs-channelStyles"
            role="tab"
            aria-controls="tabs-channelStyles"
            aria-selected="false">
            Channel Styles
          </a>
        </li>

        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-channelHightlightReels"
            className="
        nav-link my-0 tabs-link pl-3 flex items-center"
            data-bs-toggle="pill"
            data-bs-target="#tabs-channelHightlightReels"
            role="tab"
            aria-controls="tabs-channelHightlightReels"
            aria-selected="false">
            Highlight Reel
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-channelMenu"
            className="
        nav-link my-0 tabs-link pl-3 flex items-center"
            data-bs-toggle="pill"
            data-bs-target="#tabs-channelMenu"
            role="tab"
            aria-controls="tabs-channelMenu"
            aria-selected="false">
            Channel Menu
          </a>
        </li>
        <li className="nav-item flex-grow" role="presentation">
          <a
            href="#tabs-spotlightrows"
            className="
        nav-link my-0 tabs-link pl-3 flex items-center"
            data-bs-toggle="pill"
            data-bs-target="#tabs-spotlightrows"
            role="tab"
            aria-controls="tabs-spotlightrows"
            aria-selected="false">
            Spotlight Row
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ChannelSideMenu;
