import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormInputField from 'src/components/common/formInputField';
import { QuestionArrayModel, ScrollQuestionModel } from 'src/models/videosModel';
import { useVideosActions } from 'src/store/videos/actions';
import { SingleVideoModel } from 'src/models/videosModel';
import { RootState } from 'src/store/reducers/state';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { SubtractSeconds } from 'src/components/utilities/utils';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/components/loader';
import { videoStatusEnum } from 'src/components/utilities/utils';
interface IProps {
  videoId: string;
  singleVideo: SingleVideoModel;
  pollType: number | undefined;
  modalEditFlag: boolean;
  setModalEditFlag: (value: boolean) => void;
}
const QuizModal: React.FC<IProps> = ({
  videoId,
  singleVideo,
  pollType,
  modalEditFlag,
  setModalEditFlag
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLButtonElement>(null);
  const videoActions = useVideosActions(dispatch);
  const [questionArray, setQuestionArray] = useState<Array<QuestionArrayModel>>([
    { type: 'qt1', qId: 1, option: [], description: false }
  ]);
  const [questionId, setQuestionId] = useState(2);
  const [questionCount, setQuestionCount] = useState(1);
  const [scrollQuestion, setScrollQuestion] = useState<ScrollQuestionModel[]>([]);
  const [displayResultData, setDisplayResultData] = useState(false);
  const [actionTimeError, setActionTimeError] = useState<boolean>(false);
  const [answerSelectError, setAnswerSelectError] = useState<boolean>(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState<boolean>(false);
  const [addOptionQuestionId, setAddOptionQuestionId] = useState<number>();
  const [totalPoints, setTotalPoints] = useState<number>(1);
  const [time, setTime] = useState<any>('00:00:00');
  const onChange = (e: any) => {
    setTime(moment(e).format('HH:mm:ss'));
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    unregister
  } = useForm();

  const { isPollSaved, pollsResult, isPollSavedLoading } = useSelector((state: RootState) => {
    return {
      isPollSaved: state.videos.isPollSaved,
      pollsResult: state.videos.pollsResult,
      isPollSavedLoading: state.videos.isPollSavedLoading
    };
  });

  useEffect(() => {
    if (singleVideo) {
      const durationData = singleVideo.duration;
      setValue('duration', durationData);
      setValue('totalpoints', 0);
      setValue('PassRate', 0);
    }
  }, [singleVideo]);

  useEffect(() => {
    setValue('pollType', pollType);
  }, [pollType]);

  const handleAddOptionClick = (qId: number) => {
    const selectedQuestionId = qId;
    const finalData = questionArray.map((item: QuestionArrayModel) => {
      if (item.qId === selectedQuestionId && item.type === 'qt1') {
        item.option.push({ option: `option_${qId++}`, isAnswer: false });
      }
      return item;
    });
    setQuestionArray(finalData);
  };

  const handleCloseQuestion = (id: number) => {
    const filteredData = questionArray.filter((item: QuestionArrayModel) => item.qId !== id);
    unregister([`question_${id}`, `points_${id}`, `description_${id}`]);
    setQuestionArray(filteredData);
    const scrollQuestionData = scrollQuestion.filter(
      (question: ScrollQuestionModel) => question.id !== id
    );
    setScrollQuestion(scrollQuestionData);
  };

  const handleAddQuestionClick = () => {
    setIsDataSubmitted(false);
    setQuestionId((x) => x + 1);
    setQuestionArray([
      ...questionArray,
      { type: 'qt1', qId: questionId, option: [], description: false }
    ]);
    setQuestionCount((x) => x + 1);
  };
  const handleAddOpenTextQuestion = () => {
    setQuestionId((x) => x + 1);
    setQuestionArray([
      ...questionArray,
      { type: 'qt2', qId: questionId, option: [], description: false, isTextAnswer: false }
    ]);
    setQuestionCount((x) => x + 1);
  };

  const handleDescriptionClick = (qId: number) => {
    const finalData = questionArray.map((item: QuestionArrayModel) => {
      if (item.qId === qId) {
        item['description'] = true;
      }
      return item;
    });
    setQuestionArray(finalData);
  };
  const onSubmit = (data: any) => {
    setIsDataSubmitted(true);
    if (
      time <= singleVideo.duration ||
      singleVideo.videoStatus === videoStatusEnum.Standby ||
      singleVideo.videoStatus === videoStatusEnum.Live ||
      singleVideo.videoStatus === videoStatusEnum.Stopped
    ) {
      setActionTimeError(false);
      const question = [];
      for (let i = 1; i <= questionCount; i++) {
        if (data[`question_${i}`]) {
          const choiceData = questionArray.filter((item) => item.qId === i);
          const choiceArray = [];
          if (choiceData[0].type === 'qt1') {
            for (let j = 0; j <= choiceData[0].option.length; j++) {
              if (data[`option_${i}_${j}`]) {
                const datas = {
                  position: j + 1,
                  answerText: data[`option_${i}_${j}`],
                  isNewOption: true,
                  isAnswer: choiceData[0].option[j].isAnswer
                };
                choiceArray.push(datas);
              }
            }
          }
          const finalQuestionData = {
            position: i,
            description: data[`description_${i}`] ? data[`description_${i}`] : '',
            questionText: data[`question_${i}`],
            points: data[`points_${i}`] ? data[`points_${i}`] : 1,
            allowResourceUpload: data[`allowResourceUpload_${i}`]
              ? data[`allowResourceUpload_${i}`]
              : false,
            isNewQuestion: true,
            quizOpenTextQuestion: choiceData[0].type === 'qt2' ? true : false,
            choices: choiceArray,
            isTextAnswer: data[`isTextAnswer_${i}`] ? data[`isTextAnswer_${i}`] : false,
            textAnswer: data[`text_answer_${i}`]
          };
          question.push(finalQuestionData);
        }
      }
      const payloadData = {
        videoId,
        pollTitle: data.quizname,
        pollType: data.pollType,
        pollDisplayTimeString: time,
        displayResults: displayResultData,
        displayResultsTimer: data.displayResultsTimer,
        quizzes: {
          name: data.quizname,
          questions: question
        }
      };
      if (modalEditFlag) {
        const payloadData = {
          videoId,
          activePollStatus: pollsResult?.activePollStatus,
          pollTitle: data.quizname,
          pollType: data.pollType,
          pollDisplayTimeString: time,
          displayResults: displayResultData,
          displayResultsTimer: data.displayResultsTimer,
          quizzes: {
            id: pollsResult.quizzes.id,
            name: data.quizname,
            questions: question
          }
        };
        const updatePayloadData = { ...payloadData, pollId: pollsResult.pollId };
        !answerSelectError && videoActions.updatePollRequest(updatePayloadData);
      } else {
        !answerSelectError && videoActions.savePollRequest(payloadData);
      }
    } else {
      setActionTimeError(true);
    }
  };

  const handleCloseClick = () => {
    setTime('00:01:00');
    setActionTimeError(false);
    setQuestionArray([{ type: 'qt1', qId: 1, option: [], description: false }]);
    reset({
      question_1: '',
      pollType: 0,
      pollDisplayTimeString: '',
      displayResults: false,
      displayResultsTimer: '',
      quizname: '',
      points_1: 1
    });
    setModalEditFlag(false);
    setScrollQuestion([]);
    setDisplayResultData(false);
    setTotalPoints(1);
    setIsDataSubmitted(false);
  };

  useEffect(() => {
    if (isPollSaved) {
      if (ref.current) {
        ref.current.click();
      }
      handleCloseClick();
    }
  }, [isPollSaved]);

  const handleIsAnsweClick = (qId: number, optionId: number) => {
    const finalData = questionArray.map((item: QuestionArrayModel) => {
      if (item.qId === qId) {
        item.option.map((option, index) => {
          option['isAnswer'] = false;
        });
        item.option[optionId]['isAnswer'] = true;
      }
      return item;
    });
    setQuestionArray(finalData);
  };

  const handleIsAnswerTextClick = (qId: number) => {
    const finalData = questionArray.map((item: QuestionArrayModel) => {
      if (item.qId === qId && item.type === 'qt2') {
        if (item['isTextAnswer']) {
          item['isTextAnswer'] = false;
        } else {
          item['isTextAnswer'] = true;
        }
      }
      return item;
    });
    setQuestionArray(finalData);
  };

  //Data reset for get single Quiz

  useEffect(() => {
    if (pollsResult && modalEditFlag) {
      const scrollQuestionReset: Array<ScrollQuestionModel> = [];
      const questionArr: any = [];
      const resetAnswerData: any = {};
      const totalPoints: Array<number> = [];
      const questionData = pollsResult?.quizzes?.questions?.map((item: any, index: number) => {
        if (!item.quizOpenTextQuestion) {
          const choiceData: any = [];
          item.choices &&
            item.choices?.map((item: any, idx: number) => {
              choiceData.push({ option: `option_${index + 1}`, isAnswer: item.isAnswer });
              resetAnswerData[`option_${index + 1}_${idx}`] = item.answerText;
            });

          //question array created for edit
          questionArr.push({
            type: 'qt1',
            qId: index + 1,
            option: choiceData,
            description: item.description === '' ? false : true
          });

          //resetData
          resetAnswerData[`question_${index + 1}`] = item.questionText;
          resetAnswerData[`points_${index + 1}`] = item.points;
          resetAnswerData[`description_${index + 1}`] = item.description;
        } else if (item.quizOpenTextQuestion) {
          questionArr.push({
            type: 'qt2',
            qId: index + 1,
            description: item.description === '' ? false : true,
            allowResourceUpload: item.allowResourceUpload,
            isTextAnswer: item.isTextAnswer
          });
          //resetData
          resetAnswerData[`question_${index + 1}`] = item.questionText;
          resetAnswerData[`points_${index + 1}`] = item.points;
          resetAnswerData[`description_${index + 1}`] = item.description;
          resetAnswerData[`text_answer_${index + 1}`] = item.textAnswer;
        }

        scrollQuestionReset.push({ name: item.questionText, id: index + 1 });
        setScrollQuestion(scrollQuestionReset);
        totalPoints.push(item.points);
      });
      setQuestionArray(questionArr);
      const finalPoints = totalPoints.reduce((point1, point2) => point1 + point2, 0);
      setTotalPoints(finalPoints);
      const resetData = {
        quizname: pollsResult.pollTitle,
        pollDisplayTimeString: pollsResult.pollDisplayTime,
        // displayResults: pollsResult.displayResults,
        displayResultsTimer: pollsResult.displayResultsTimer,
        ...resetAnswerData
      };
      setDisplayResultData(pollsResult.displayResults);
      setQuestionCount(questionArr.length);
      setQuestionId(questionArr.length + 1);
      setTime(pollsResult.pollDisplayTime ? pollsResult.pollDisplayTime : '00:00:00');
      reset(resetData);
    }
  }, [pollsResult]);

  const watchAllFields = watch();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('question')) {
        const questionText: Array<ScrollQuestionModel> = [];
        questionArray.map((item) => {
          questionText.push({ name: watch(`question_${item.qId}`), id: item.qId });
        });
        setScrollQuestion(questionText);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchAllFields]);

  const handleDisplayAtTheEndClick = () => {
    if (singleVideo) {
      setTime(SubtractSeconds(singleVideo.duration));
      setActionTimeError(false);
    }
  };

  useEffect(() => {
    if (!modalEditFlag) {
      handleCloseClick();
    }
  }, [modalEditFlag]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('points') || name?.includes('question')) {
        const questionText: Array<string> = [];
        questionArray.map((item) => {
          watch(`points_${item.qId}`) !== '' && questionText.push(watch(`points_${item.qId}`));
        });
        if (questionText.length) {
          const sum = questionText.reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);
          setTotalPoints(sum);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watchAllFields]);

  useEffect(() => {
    if (singleVideo.videoStatus && !modalEditFlag) {
      setTime('00:01:00');
    }
  }, [singleVideo]);

  useEffect(() => {
    const conditionArray: Array<boolean> = [];
    if (totalPoints === 0) {
      setAnswerSelectError(false);
    } else {
      if (questionArray && totalPoints > 0) {
        const questionTypeOne: Array<QuestionArrayModel> =
          questionArray && questionArray.filter((item) => item.type === 'qt1');
        const answerData: any = {};
        questionTypeOne.map((question, qIndex) => {
          if (question.type === 'qt1') {
            answerData[`answer_${qIndex}`] = [];
            question.option &&
              question.option.map((item) => {
                answerData[`answer_${qIndex}`].push(item.isAnswer);
              });
          }
        });
        for (let i = 0; i < Object.values(answerData).length; i++) {
          if (answerData[`answer_${i}`]?.includes(true)) {
            conditionArray.push(false);
          } else {
            conditionArray.push(true);
          }
        }
      }
      if (conditionArray?.includes(true)) {
        setAnswerSelectError(true);
      } else {
        setAnswerSelectError(false);
      }
    }
  }, [questionArray, totalPoints]);

  return (
    <div>
      <div
        className="modal fade"
        id="QuizModal"
        aria-labelledby="QuizModalLabel"
        aria-hidden="true">
        <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
          <div className="modal-content">
            <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 rounded-t-md">
              <h5
                className="text-xl font-semibold leading-normal text-center w-full"
                id="QuizModalLabel">
                Quiz
                <span>
                  {actionTimeError && (
                    <p className="text-red-500 text-xs error">
                      Poll Display Time can not be greater than Video Duration
                    </p>
                  )}
                </span>
              </h5>
              <button
                ref={ref}
                onClick={handleCloseClick}
                type="button"
                className="dismiss-btn"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form className="px-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body relative py-4 border-b">
                <select disabled className="input-style w-full mb-5" {...register('pollType')}>
                  <option value={0}>Quiz</option>
                  <option value={1}>Multiple Choice</option>
                  <option value={2}>Open Text</option>
                  <option value={3}>Star Rating</option>
                </select>
                {singleVideo.videoStatus !== 0 &&
                  singleVideo.videoStatus !== 1 &&
                  singleVideo.videoStatus !== 3 && (
                    <>
                      <div className="flex items-center justify-center">
                        <div className={`form-group mb-3 w-full mr-3`}>
                          <label className="block text-sm font-medium mb-1">Video Duration</label>
                          <input
                            disabled
                            type="text"
                            className="input-style quiz-disable"
                            id="duration"
                            {...register('duration')}
                          />
                        </div>

                        <div className={`form-group mb-3 w-full ml-3 flex items-center`}>
                          <div>
                            <label className="block text-sm font-medium mb-1">
                              Poll Display Time (hh:mm:ss)
                            </label>
                            <TimePicker
                              onChange={(e) => onChange(e)}
                              value={time ? moment(time, 'HH:mm:ss') : undefined}
                            />
                          </div>
                          <div className="ml-2 mt-3">
                            {singleVideo &&
                              singleVideo.videoStatus !== 0 &&
                              singleVideo.videoStatus !== 1 && (
                                <div className="mt-3  mr-3">
                                  <button
                                    onClick={handleDisplayAtTheEndClick}
                                    type="button"
                                    className="btn-primary w-auto mt-3 mr-auto ml-0 borderonly-btn whitespace-nowrap">
                                    Display at end
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {singleVideo &&
                        (singleVideo.videoStatus === 0 || singleVideo.videoStatus === 1) && (
                          <div className="flex items-center justify-center">
                            <div className={`form-group mb-3 w-full mr-3`}>
                              <label className="block text-sm font-medium mb-1">
                                Display Result to User
                              </label>
                              <div className="form-check form-switch pl-0 mr-3 relative">
                                <input
                                  onChange={(e) => setDisplayResultData(e.target.checked)}
                                  className="form-check-input switch-btn"
                                  type="checkbox"
                                  role="switch"
                                  id="displayResults"
                                  checked={displayResultData}
                                />
                                <i className="fas fa-check checkbox-tick hidden"></i>
                              </div>
                            </div>

                            <div
                              className={`form-group mb-3 w-full ${
                                displayResultData ? '' : 'hidden'
                              }`}>
                              <label className="block text-sm font-medium mb-1">
                                Display Result Timer
                              </label>
                              <select className="input-style" {...register('displayResultsTimer')}>
                                <option value={15}>15</option>
                                <option value={30}>30</option>
                                <option value={45}>45</option>
                                <option value={60}>60</option>
                                <option value={90}>90</option>
                                <option value={120}>120</option>
                              </select>
                            </div>
                          </div>
                        )}
                    </>
                  )}

                <div className="flex justify-end">
                  <p className="font-bold">
                    Total Question Points:<span className="ml-1">{totalPoints}</span>
                  </p>
                </div>

                <div className="lg:grid lg:grid-cols-2 gap-6 mt-3">
                  <div className="border rounded-md">
                    <div className="px-4 py-3">
                      <div className={`form-group mb-3 w-full`}>
                        <FormInputField
                          label={'Quiz Name'}
                          type={'text'}
                          fieldName={'quizname'}
                          register={register}
                          errors={errors}
                          isRequired={true}
                        />
                      </div>

                      <div className="p-6 rounded-md shadow border bg-white max-w-sm flex flex-col">
                        <>
                          <ul>
                            {scrollQuestion &&
                              scrollQuestion.map((item: ScrollQuestionModel) => {
                                return (
                                  <li key={item.id} className="quiz-question-list">
                                    <a href={`#Question${item.id}`} data-abc="true" id="quetext1">
                                      {item.name}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </>
                      </div>
                    </div>
                  </div>
                  <div className="border rounded-md">
                    <div className="flex items-center justify-between border-b px-5 py-4">
                      <p className="font-semibold mb-0">Questions</p>
                      <button
                        type="button"
                        id="actions-dropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="btn-primary w-auto mt-0 mr-0 ml-auto borderonly-btn">
                        Add Question
                      </button>
                      <ul
                        className="dropdown-menu dropdown-menu-ul w-32"
                        aria-labelledby="actions-dropdown">
                        <li className="cursor-pointer" onClick={handleAddQuestionClick}>
                          <span className="action-menu">Add Question</span>
                        </li>
                        <li className="cursor-pointer" onClick={handleAddOpenTextQuestion}>
                          <span className="action-menu">Add Open Text Question</span>
                        </li>
                      </ul>
                    </div>

                    {questionArray &&
                      questionArray?.map((item, index) => {
                        return (
                          <div
                            id={`Question${index + 1}`}
                            key={index}
                            className={`p-4 questions-side-card `}>
                            <div className="flex-items">
                              <label className="font-semibold">Question {item.qId}</label>

                              <div className="dropdown relative">
                                <button
                                  className="dropdown-toggle border-0 input-style mx-auto flex-items"
                                  type="button"
                                  id="action-dropdown"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                  <i className="fas fa-chevron-down"></i>
                                </button>
                                <ul
                                  className="dropdown-menu dropdown-menu-ul w-32"
                                  aria-labelledby="action-dropdown">
                                  <li
                                    className="cursor-pointer"
                                    onClick={() => handleDescriptionClick(item.qId)}>
                                    <span className="action-menu">Add description</span>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className={`form-group mb-3 w-full relative`}>
                              <i
                                id={`${item.qId}`}
                                className="fas fa-times absolute right-0 top-4 cursor-pointer"
                                onClick={() => handleCloseQuestion(item.qId)}></i>
                              <input
                                type="text"
                                className="input-style pl-0"
                                placeholder="What would you like to ask ?"
                                {...register(`question_${item.qId}`)}
                              />
                            </div>
                            {item.description && (
                              <div className={`form-group mb-3 w-full`}>
                                <input
                                  type="text"
                                  className="input-style pl-0"
                                  placeholder="Description (for participants)"
                                  {...register(`description_${item.qId}`)}
                                />
                              </div>
                            )}
                            {item.type === 'qt1' && (
                              <>
                                {' '}
                                <button
                                  id={`addOption_${item.qId}`}
                                  type="button"
                                  className="btn-primary w-auto mx-0 ml-auto"
                                  onClick={() => handleAddOptionClick(item.qId)}>
                                  <i className="fas fa-plus"></i>
                                </button>
                              </>
                            )}
                            {item.option &&
                              item.option.map((option, index) => {
                                return (
                                  <div key={index} className={`form-group mb-3 w-full`}>
                                    <div className="flex items-center mr-3">
                                      <div className="form-check">
                                        <input
                                          id={`is_Answer_${option.option}_${index}`}
                                          onClick={() => handleIsAnsweClick(item.qId, index)}
                                          name={`is_Answer_${option.option}`}
                                          className="form-check-input radio-btn"
                                          type="radio"
                                          checked={option.isAnswer}
                                        />
                                      </div>
                                      <input
                                        type="text"
                                        className="input-style pl-0 ml-3"
                                        placeholder="Add Option"
                                        id={`${option.option}_${index}`}
                                        {...register(`${option.option}_${index}`)}
                                      />
                                    </div>
                                  </div>
                                );
                              })}

                            {item.type !== 'qt1' && (
                              <>
                                <div className="lg:flex my-5 justify-between">
                                  <div className="lg:w-1/2 pb-0">
                                    <h6 className="font-bold">Allow Resource Upload</h6>
                                  </div>
                                  <div className="lg:w-1/2 pb-0">
                                    <div className="form-check form-switch pl-0 flex items-center justify-end">
                                      <div className="relative">
                                        <input
                                          className="form-check-input switch-btn"
                                          type="checkbox"
                                          role="switch"
                                          id="allowResourceUpload"
                                          defaultChecked={item && item.allowResourceUpload}
                                          {...register(`allowResourceUpload_${item.qId}`)}
                                        />
                                        <i className="fas fa-check checkbox-tick hidden"></i>
                                        <i className="fas fa-times checkbox-cross"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="lg:flex my-5 justify-between">
                                  <div className="lg:w-1/2 pb-0">
                                    <h6 className="font-bold">Text Answer</h6>
                                  </div>
                                  <div className="lg:w-1/2 pb-0">
                                    <div className="form-check form-switch pl-0 flex items-center justify-end">
                                      <div className="relative">
                                        <input
                                          className="form-check-input switch-btn"
                                          type="checkbox"
                                          role="switch"
                                          id="isTextAnswer"
                                          defaultChecked={item && item.isTextAnswer}
                                          {...register(`isTextAnswer_${item.qId}`, {
                                            onChange: () => {
                                              handleIsAnswerTextClick(item.qId);
                                            }
                                          })}
                                        />
                                        <i className="fas fa-check checkbox-tick hidden"></i>
                                        <i className="fas fa-times checkbox-cross"></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.isTextAnswer && (
                                  <div className={`form-group mb-3 w-full relative`}>
                                    <input
                                      type="text"
                                      className="input-style pl-0"
                                      placeholder="Enter Answer"
                                      {...register(`text_answer_${item.qId}`)}
                                    />
                                  </div>
                                )}
                              </>
                            )}

                            <div className="flex items-center mr-3">
                              <label className="font-semibold">Points</label>
                              <input
                                type="text"
                                className="input-style pl-0 w-1/2 ml-2"
                                defaultValue={1}
                                {...register(`points_${item.qId}`)}
                              />
                            </div>
                          </div>
                        );
                      })}

                    {/* end */}
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-1">
                {answerSelectError && isDataSubmitted && (
                  <p className="text-red-500 text-xs error">Please select Answer in Question</p>
                )}
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 py-6 rounded-b-md">
                <button
                  disabled={isPollSavedLoading}
                  type="submit"
                  className={`px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0 ${
                    isPollSavedLoading ? 'opacity-25' : ''
                  }`}>
                  Save
                </button>
                <button
                  onClick={handleCloseClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-auto whitespace-nowrap mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
                <span className="ml-1"> {isPollSavedLoading && <Loader />}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizModal;
