import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { useVideoLibraryActions } from 'src/store/videoLibrary/actions';
import { ContentResultModel } from 'src/models/videoLibraryModel';
import Loader from 'src/components/loader';
import Pagination from 'src/components/common/pagination';
import { PaginationModel } from 'src/models/paginationModel';
const Content = () => {
  const dispatch = useDispatch();
  const libraryActions = useVideoLibraryActions(dispatch);
  const [selectedChannel, setSelectedChannel] = useState<ContentResultModel[]>([]);
  const [highlightReelOn, setHighlightReelOn] = useState<string>('0');
  const [paginationMeta, setPaginationMeta] = useState<PaginationModel>();
  const {
    totalRecords,
    layoutContent,
    highlightReelData,
    isLayoutContentUpdateLoading,
    isLayoutContentLoading
  } = useSelector((state: RootState) => {
    return {
      totalRecords: state.videoLibrary.totalRecords,
      layoutContent: state.videoLibrary.layoutContent,
      highlightReelData: state.videoLibrary.highlightReelData,
      isLayoutContentUpdateLoading: state.videoLibrary.isLayoutContentUpdateLoading,
      isLayoutContentLoading: state.videoLibrary.isLayoutContentLoading
    };
  });
  useEffect(() => {
    libraryActions.getTotalRecordsRequest();
  }, []);
  const handleOptionChange = (value: string) => {
    setHighlightReelOn(value);
    libraryActions.getHightlightReelDataRequest({
      highlightreelon: value,
      pageNumber: 1,
      pageSize: 20
    });
  };
  useEffect(() => {
    if (Object.keys(layoutContent).length) {
      libraryActions.getHightlightReelDataRequest({
        highlightreelon: layoutContent.highLightReelsOn,
        pageNumber: 1,
        pageSize: 20
      });
      setHighlightReelOn(layoutContent.highLightReelsOn);
    }
  }, [layoutContent]);

  useEffect(() => {
    if (highlightReelData) {
      setSelectedChannel(highlightReelData.highLightReels);
      highlightReelData.channelVideoList &&
        setPaginationMeta({
          pageNumber: highlightReelData.channelVideoList.pageNumber,
          pageSize: highlightReelData.channelVideoList.pageSize,
          totalCount: highlightReelData.channelVideoList.totalCount
        });
    }
  }, [highlightReelData]);

  const handleSelectClick = (selectedData: ContentResultModel) => {
    const check =
      selectedChannel &&
      selectedChannel.filter((item) => item.channelId === selectedData.channelId);
    if (check && check.length === 0) {
      setSelectedChannel((data: any) => [...data, selectedData]);
    }
  };

  const handleRemoveClick = (selectedData: ContentResultModel) => {
    const filteredData =
      selectedChannel &&
      selectedChannel.filter((item) => item.channelId !== selectedData.channelId);
    filteredData && setSelectedChannel(filteredData);
  };

  const handleUpdateChannel = () => {
    const highlightreels =
      selectedChannel &&
      selectedChannel.map((item, index) => {
        return {
          channelId: item.channelId,
          ord: index,
          videoId: item.videoId
        };
      });
    const payloadData = {
      highLightReelsOn: highlightReelOn,
      highLightReels: highlightreels
    };
    payloadData && libraryActions.updateLayoutContentRequest(payloadData);
  };

  const handlePageChange = (pageNumber: number) => {
    libraryActions.getHightlightReelDataRequest({
      highlightreelon: highlightReelOn,
      pageNumber,
      pageSize: 20
    });
  };

  return (
    <div
      className="tab-pane fade"
      id="tabs-recentView"
      role="tabpanel"
      aria-labelledby="tabs-recent-view">
      <div className="md:p-4 p-2">
        {(isLayoutContentUpdateLoading || isLayoutContentLoading) && <Loader />}
        <button onClick={handleUpdateChannel} className="btn-primary w-auto mx-0 ml-auto mb-4 mt-0">
          Save
        </button>

        <div className="flex items-center justify-between">
          <h6 className="font-semibold">Highlight Reel On</h6>
          <div className={`form-group`}>
            <select
              className="w-56 input-style"
              onChange={(e) => handleOptionChange(e.target.value)}>
              {layoutContent &&
                layoutContent?.highLightReelOnList?.map((item, index) => {
                  return (
                    <option
                      key={index}
                      selected={parseInt(layoutContent.highLightReelsOn) === parseInt(item.value)}
                      value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <h6 className="font-semibold mt-6">Highlight Reel Background</h6>
        <p>Set the background color to your main highlight reel background.</p>
        <div className="mt-6">
          <div className="overflow-x-auto mt-4">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-100">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Channel</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {highlightReelData &&
                  highlightReelData?.channelVideoList?.result?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-3">{item.channelName}</td>
                        <td className="p-2 flex justify-center">
                          <div>
                            <button
                              onClick={() => handleSelectClick(item)}
                              type="button"
                              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                              Select
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!!paginationMeta && !!Object.keys(paginationMeta).length && (
              <Pagination
                currentPage={paginationMeta.pageNumber}
                totalCount={paginationMeta.totalCount}
                pageSize={paginationMeta.pageSize}
                onPageChange={(page: any) => handlePageChange(page)}
              />
            )}
          </div>
        </div>

        <div className="mt-6">
          <div className="overflow-x-auto mt-4">
            <table className="table-auto w-full border">
              <thead className="text-xs font-semibold uppercase bg-gray-100">
                <tr>
                  <th className="p-2">
                    <div className="font-semibold text-left">Channel</div>
                  </th>
                  <th className="p-2">
                    <div className="font-semibold text-center">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm divide-y divide-gray-100">
                {selectedChannel &&
                  selectedChannel?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="p-3">{item.channelName}</td>
                        <td className="p-2 flex justify-center">
                          <div>
                            <button
                              onClick={() => handleRemoveClick(item)}
                              type="button"
                              className="btn-primary w-auto mt-0 mr-3 ml-0 borderonly-btn">
                              Remove
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
