import { useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useVideosActions } from 'src/store/videos/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import FormInputField from 'src/components/common/formInputField';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useChannelActions } from 'src/store/channel/actions';
import Loader from 'src/components/loader';
import { Link } from 'react-router-dom';
import { customFilterOption } from 'src/components/utilities/utils';
import { useLoginActions } from 'src/store/login/actions';
import { FileDrop } from 'react-file-drop';
import ToastMessage from 'src/components/ToastContainer';
type locationState = {
  channelIds: string;
  from: string;
};

const AddResource = () => {
  const dispatch = useDispatch();
  const [resourceName, setResourceName] = useState<string>('');
  const [resourceType, setResourceType] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File>();
  const fileInputRef = useRef<any>(null);
  const loginAction = useLoginActions(dispatch);
  const channelAction = useChannelActions(dispatch);
  const navigate = useNavigate();
  const location = useLocation();
  const [finalChannels, setFinalChannels] = useState<any>();
  const { channelIds, from } = (location.state as locationState) || {};
  const videoActions = useVideosActions(dispatch);
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    channelAction.getChannelListRequest();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();

  const { isVideoAddedLoading, channelList, isResourceUploaded, videoId } = useSelector(
    (state: RootState) => {
      return {
        isVideoAddedLoading: state.videos.isVideoAddedLoading,
        channelList: state.channel.channelList,
        isResourceUploaded: state.videos.isResourceUploaded,
        videoId: state.videos.presentationId
      };
    }
  );

  useEffect(() => {
    if (channelList && channelIds) {
      const selectedChannel =
        channelList && channelList.filter((item: any) => item.id === channelIds);
      let dataRef;
      if (selectedChannel) {
        dataRef = {
          value: selectedChannel[0] ? selectedChannel[0].id : '',
          label: selectedChannel[0] ? selectedChannel[0].name : ''
        };
      }
      const newData = { channelId: dataRef };
      reset(newData);
    }
  }, [channelList, channelIds]);

  const onSubmit = (data: any) => {
    const finalData = {
      filename: resourceName.slice(0, -4),
      filetype: resourceType,
      title: data.title,
      channelid: data.channelId.value,
      presentationId: '',
      isSearchable: from == 'course' ? false : true
    };
    videoActions.addResourceUploadRequest(finalData);
  };

  useEffect(() => {
    if (isResourceUploaded) {
      fetchSignedURL();
    }
  }, [isResourceUploaded]);

  const fetchSignedURL = () => {
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    const apiPath = '/v2/video/resource/upload';
    const fileName = resourceName.slice(0, -4);
    const fileType = resourceType;
    const bearerToken = localStorage.getItem('token');

    const apiUrl = `${rootUrl}${apiPath}?fileName=${fileName}&fileType=${fileType}&videoId=${videoId}`;

    fetch(apiUrl, {
      method: 'Get',
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(async (data) => {
        const preSignedUrl = data.result.signedUrl;
        await uploadFileWithProgress(preSignedUrl);
        if (from == 'course') {
          const returnUrl = sessionStorage.getItem('returnUrl');
          navigate(`${returnUrl}`);
          sessionStorage.removeItem('returnUrl');
        } else {
          navigate(`/videos/${videoId}`);
        }
        videoActions.resetResourceUploadActionRequest();
      })
      .catch((error) => {
        // Handle errors during the fetch
        console.error('Error during fetch:', error);
      });
  };

  const uploadFileWithProgress = (url: any) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', resourceType);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(percentCompleted);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };

      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(uploadedFile);
    });
  };

  useEffect(() => {
    if (channelList && channelList.length) {
      const options = channelList.map((item) => ({
        value: item.id,
        label: item.displayText
      }));
      setFinalChannels(options);
    }
  }, [channelList]);

  useEffect(() => {
    videoActions.getProductAccessRequest();
    loginAction.getDefaultdateFormatRequest();
  }, []);

  const onFileInputChange = (event: any) => {
    setUploadedFile(event.target.files[0]);
    setResourceName(event.target.files[0].name);
    setResourceType(event.target.files[0].type);
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const handleDropClick = (files: any) => {
    setUploadedFile(files && files[0]);
    setResourceName(files[0].name);
    setResourceType(files[0].type);
  };

  const handleRemoveClick = () => {
    fileInputRef.current.value = '';
    setResourceName('');
  };

  return (
    <>
      <ToastMessage />
      {!isVideoAddedLoading ? (
        <div className="mt-6 min-h-screen">
          <div className="mb-6">
            <h2 className="font-semibold my-1">Resource</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">Resource</Link>
              </li>
              <li>
                <strong>Add</strong>
              </li>
            </ol>
          </div>
          {/* <VideoSidebar /> */}
          <div className="add-video shadow h-fit">
            {/* Upload view */}

            <div className="video-upload lg:w-3/5 p-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-4">
                  <FormInputField
                    label={'Title'}
                    type={'text'}
                    fieldName={'title'}
                    register={register}
                    errors={errors}
                    isRequired={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="block text-sm font-medium mb-1">Channels</label>
                  {channelList && (
                    <Controller
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Select
                          filterOption={customFilterOption}
                          options={finalChannels}
                          value={value}
                          id="channelId"
                          onChange={(val: any) => onChange(val)}
                          isLoading={channelList.length ? false : true}
                        />
                      )}
                      name="channelId"
                      control={control}
                      rules={{ required: true }}
                    />
                  )}
                </div>
                <div className="form-group mb-4">
                  <label className="block text-sm font-medium mb-1">Upload Resource</label>
                  <input
                    onChange={onFileInputChange}
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                  />
                  <div className="browse-file-dropzone">
                    <FileDrop
                      onTargetClick={onTargetClick}
                      onDrop={(files, event) => handleDropClick(files)}>
                      Drop files here or Click to Upload
                    </FileDrop>
                  </div>
                </div>
                <div className="video-progress mt-7">
                  <div className="flex items-center">
                    {resourceName !== '' && (
                      <div className={`form-group mb-2 w-1/2 mr-2`}>
                        <input
                          type="text"
                          className="input-style"
                          placeholder={`${resourceName && resourceName}`}
                        />
                      </div>
                    )}

                    {resourceName !== '' && (
                      <button
                        type="button"
                        onClick={handleRemoveClick}
                        className="px-6 btn-primary w-28 ml-2 mt-0">
                        Remove
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex items-center mt-6 whitespace-nowrap">
                  {uploadProgress !== 0 && resourceName !== '' && (
                    <div
                      className={`w-full ${
                        uploadProgress === 100 ? 'bg-green-100' : 'bg-purple-100'
                      } h-3 rounded-full mt-4`}>
                      <div
                        className={` ${
                          uploadProgress === 100 ? 'bg-green-400' : 'bg-purple-600'
                        } text-white text-xs h-3 font-medium text-center leading-none rounded-full`}
                        style={{ width: `${Math.floor(uploadProgress)}%` }}>
                        {Math.floor(uploadProgress)}%
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center mt-6">
                  <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-0">
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate('/channels')}
                    className="mx-0 px-6 btn-primary discard-btn w-28  mt-0"
                    data-bs-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddResource;
