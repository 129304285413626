import React from 'react';
import { useForm } from 'react-hook-form';
import FormFieldError from '../formFieldError';
import * as AppConstants from '../../utilities/constants';

interface IProps {
  label: string;
  fieldName: string;
  register: any;
  errors: any;
  isRequired: boolean;
  data?: any[];
  options?: string;
  handleChange?: any;
}

const FormSelector: React.FC<IProps> = ({
  label,
  fieldName,
  register,
  errors,
  isRequired,
  data,
  options,
  handleChange
}) => {
  return (
    <>
      <label className="block text-sm font-medium mb-1">
        {label} {isRequired ? <span className="text-red-500">*</span> : ''}
      </label>
      <select
        className="border border-gray-300 input-style"
        id={fieldName}
        {...register(fieldName, {
          required: isRequired,
          onchange: (e: any) => handleChange && handleChange(e)
        })}>
        <option value="">Select</option>
        {data && data.length ? (
          data.map((category, index) => {
            return (
              <option key={index} value={category.id}>
                {category[`${options}`]}
              </option>
            );
          })
        ) : (
          <>
            <option value="">Loading...</option>
          </>
        )}
      </select>
      {errors[fieldName]?.type === 'required' && (
        <FormFieldError message={AppConstants.requiredField} />
      )}
    </>
  );
};

export default FormSelector;
